import React from 'react';
import {IconSize} from '../../../Icon';
import {Icons} from '../../../icons';
import styles from './BreadcrumbDivider.module.scss';

export const BreadcrumbDivider = () => (
    <div className={styles.breadCrumbDivider}>
        <Icons.ChevronRight size={IconSize.SIZE_2} />
    </div>
);
