import {map, mergeMap} from 'rxjs';
import {logInfo, pageRerender} from '../rxjs';
import {configSelect2} from './configSelect2';
import {findSelect2ElementsOnce} from './Observable';

export const loadSelect2AutocompleteType = () =>
    pageRerender()
        .pipe(
            logInfo('Select2AutocompleteType:Page render..'),
            mergeMap(() => findSelect2ElementsOnce()),
            logInfo('Select2AutocompleteType:Configure..'),
            map(configSelect2),
            logInfo('Select2AutocompleteType:Configured..')
        )
        .subscribe();
