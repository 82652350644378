import React, {ReactNode} from 'react';
import classNames from 'classnames/bind';
import styles from './ModalContent.module.scss';

const cx = classNames.bind(styles);

export interface ModalContentProps {
    children: ReactNode;
    theme?: 'gray';
}

export const ModalContent = ({children, theme}: ModalContentProps) => {
    const classes = cx('modalContent', {
        'modalContent--themeGray': theme === 'gray',
    });
    return <div className={classes}>{children}</div>;
};
