import {QueryResult, useApolloClient} from '@apollo/client';
import {ResultData} from '@growthbase/graphql';
import {useCallbackRef, useWrapFunctionWithErrorHandler, useWrapFunctionWithVoidErrorHandler} from '@growthbase/spa';
import {idFromIri, iriFromId} from '@growthbase/routing';
import {useRef} from 'react';
import {DocumentNode} from 'graphql/index';
import {AddToQueryOptions, PositieType, useAddToQueryCache} from './AppoloCacheMutator/useAddToQueryCache';
import {useRemoveFromQueryCache} from './AppoloCacheMutator/useRemoveFromQueryCache';
import {useApplyInvalidatedEntityFields} from './AppoloCacheMutator/useApplyInvalidatedEntityFields';
import {useChangeOrderInQueryCache} from './AppoloCacheMutator/useChangeOrderInQueryCache';
import {EntityOrderChangedSchemaDto} from './MessageListener/useExternalEntityChangeOrder';
import {ChangeType} from './useHandleExternalEntityChanges';
import {determineDefaultActionType} from './helpers';
import {BaseNode} from './baseNode';
import {ListPositie} from './MessageListener/useExternalEntityAdded';

/* eslint-disable no-shadow */

export enum QueryCacheUpdateType {
    ignore,
    add,
    remove,
    /**
     * !!! all queries that using the fields will trigger a refresh.
     */
    invalidateFields,
    /**
     * Refreshes the hole list with existing variables.
     */
    refetch,
    /**
     * Only refetch the given entity.
     */
    refetchEntity,
    /**
     * Reorder entity
     */
    reorderEntitiy,
    /**
     * Do the default action for the given change type.
     */
    default,
}

type EntityOrderChangedSchemaDtoWithType = {
    type: QueryCacheUpdateType.reorderEntitiy;
    messageType: ChangeType;
} & EntityOrderChangedSchemaDto;

type CommonUpdateQueryCacheParams<TNode extends BaseNode> = {
    type:
        | QueryCacheUpdateType.add
        | QueryCacheUpdateType.refetch
        | QueryCacheUpdateType.refetchEntity
        | QueryCacheUpdateType.invalidateFields
        | QueryCacheUpdateType.remove
        | QueryCacheUpdateType.ignore
        | QueryCacheUpdateType.default;
    messageType: ChangeType;
    id: string;
    typename: string;
    node: Partial<TNode>;
    fields: (keyof TNode)[];
    positie?: ListPositie | null;
};

export type ApplyQueryCacheUpdateParams<TNode extends BaseNode> =
    | CommonUpdateQueryCacheParams<TNode>
    | EntityOrderChangedSchemaDtoWithType;

export interface ApplyQueryCacheOptions<TNode extends BaseNode> {
    iri?: string;
    /**
     * The query cache to mutate the results of.
     */
    result: QueryResult<ResultData<TNode>>;
    /**
     * Positie of new entities top or bottom of the list.
     *
     * Defaults to bottom.
     *
     * TODO: Move this option to AddToQueryOptions. (Lots of changes in the code base, Maybe AI can do this for us?)
     */
    positie?: PositieType;

    addToQueryOptions?: AddToQueryOptions<TNode>;

    /**
     * When update type Add the full node is fetched and added to the query result.
     */
    fetchEntity: (variables: {id: string}) => Promise<{node?: TNode | null}>;
}

type FetchResult<TNode> = {node?: TNode | null};
type PromiseRecord<TNode> = Record<string, Promise<FetchResult<TNode>> | undefined>;

export const useConvertFetchEntity = <TNode extends BaseNode>(
    /**
     * These are generated by graphql-codegen.
     */
    refetch: (variables: {id: string}) => {
        query: DocumentNode;
        variables: unknown;
    }
): ((variables: {id: string}) => Promise<{node?: TNode | null}>) => {
    const client = useApolloClient();
    const fetching = useRef<PromiseRecord<TNode>>({});
    return (variables: {id: string}): Promise<FetchResult<TNode>> => {
        const currentElement = fetching.current[variables.id];
        if (currentElement) {
            return currentElement;
        }
        const promise = client
            .query(refetch(variables))
            .then(({data}) => (data?.node ? {node: data.node} : {node: null}))
            .finally(() => {
                delete fetching.current[variables.id];
            });
        fetching.current[variables.id] = promise;
        return promise;
    };
};

export const useApplyQueryCacheUpdate = <TNode extends BaseNode>({
    result,
    fetchEntity,
    positie = PositieType.after,
    iri,
    addToQueryOptions,
}: ApplyQueryCacheOptions<TNode>) => {
    const {refetch} = result;
    const fetch = useWrapFunctionWithVoidErrorHandler(refetch);
    const fetchEntityWrapped = useWrapFunctionWithErrorHandler(fetchEntity, null);
    const addToQuery = useAddToQueryCache(result.updateQuery, addToQueryOptions);
    const changeOrder = useChangeOrderInQueryCache(result.updateQuery);
    const removeFromQuery = useRemoveFromQueryCache<TNode, unknown>(result.updateQuery);
    const invalidate = useApplyInvalidatedEntityFields();
    return useCallbackRef((params: ApplyQueryCacheUpdateParams<TNode>): void => {
        const id = idFromIri(params.id);

        params.type =
            params.type === QueryCacheUpdateType.default ? determineDefaultActionType(params.messageType) : params.type;

        switch (params.type) {
            case QueryCacheUpdateType.add:
                fetchEntityWrapped({
                    id: iri ? iriFromId(iri)(id) : id,
                }).then((result) => {
                    const fetched = result?.node ?? (params.node as TNode);
                    const {positie: placement} = params;

                    if (placement?.placedAfterId) {
                        addToQuery(fetched, PositieType.after, placement.placedAfterId);
                        return;
                    }
                    if (placement?.placedBeforeId) {
                        addToQuery(fetched, PositieType.before, placement.placedBeforeId);
                        return;
                    }
                    addToQuery(fetched, positie);
                });
                break;
            case QueryCacheUpdateType.remove:
                removeFromQuery(id);
                break;
            case QueryCacheUpdateType.refetch:
                fetch();
                break;
            case QueryCacheUpdateType.invalidateFields:
                // @ts-expect-error The data is passed to this, only the type does not support this...
                invalidate(params);
                break;
            case QueryCacheUpdateType.reorderEntitiy:
                changeOrder(id, params.positie.placedBeforeId, params.positie.placedAfterId);
                break;
            case QueryCacheUpdateType.refetchEntity:
                fetchEntityWrapped({
                    id: iri ? iriFromId(iri)(id) : id,
                });
                break;
            case QueryCacheUpdateType.ignore:
            default:
                break;
        }
    });
};
