import {TaakPlanningButton} from '@growthbase/taken';
import {useField} from 'formik';
import {FC} from 'react';
import {
    FormikFieldProps,
    PopupControlsContext,
    useMemoryPopupControls,
    NullableISOStringDateRange,
    BaseButtonProps,
} from '@growthbase/design-components';

export interface TaakPlanningInputProps extends FormikFieldProps<NullableISOStringDateRange> {
    asText?: boolean;
    Button?: FC<BaseButtonProps>;
    textType?: 'deadline' | 'begindatum';
}

export const TaakPlanningInput: FC<TaakPlanningInputProps> = ({
    disabled,
    readonly,
    asText,
    Button,
    textType,
    field: {name},
}) => {
    const [
        {value},
        ,
        {setValue},
    ] = useField<NullableISOStringDateRange>(name);
    const controls = useMemoryPopupControls(false);
    return (
        <PopupControlsContext.Provider value={controls}>
            <TaakPlanningButton
                value={value}
                onSubmit={(newValue) => {
                    setValue(newValue);
                    return Promise.resolve();
                }}
                disabled={disabled ?? false}
                readonly={readonly ?? false}
                asText={asText}
                AddButton={Button}
                textType={textType}
            />
        </PopupControlsContext.Provider>
    );
};
