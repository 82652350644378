import {
    getPluginType,
    usePlateEditorRef,
    BlockToolbarButton,
    ELEMENT_H2,
    ELEMENT_H1,
    ELEMENT_H3,
    ELEMENT_BLOCKQUOTE,
} from '@udecode/plate';
import {useTranslation} from 'react-i18next';
import {Icons} from '../../../icons';

export const BasicElementToolbarButtons = () => {
    const {t} = useTranslation('spa.nl');
    const editor = usePlateEditorRef();
    if (editor === null) {
        return null;
    }
    return (
        <>
            <BlockToolbarButton
                tooltip={{content: t('BasicElementToolbarButtons.heading_1')}}
                type={getPluginType(editor, ELEMENT_H1)}
                icon={<Icons.FormatHeading1 />}
            />
            <BlockToolbarButton
                tooltip={{content: t('BasicElementToolbarButtons.heading_2')}}
                type={getPluginType(editor, ELEMENT_H2)}
                icon={<Icons.FormatHeading2 />}
            />
            <BlockToolbarButton
                tooltip={{content: t('BasicElementToolbarButtons.heading_3')}}
                type={getPluginType(editor, ELEMENT_H3)}
                icon={<Icons.FormatHeading3 />}
            />
            <BlockToolbarButton
                tooltip={{content: t('BasicElementToolbarButtons.quote')}}
                type={getPluginType(editor, ELEMENT_BLOCKQUOTE)}
                icon={<Icons.FormatQuote />}
            />
        </>
    );
};
