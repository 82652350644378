/**
 * @param target
 *   HTML-ID van het container-element
 */
export const usePortalDestination = (target: string) => {
    const portalDestination = document.getElementById(target);

    if (!portalDestination) {
        return null;
    }

    if (!portalDestination.classList.contains('react-portal')) {
        portalDestination.textContent = '';
        portalDestination.classList.add('react-portal');
    }

    return portalDestination;
};
