import {AsyncCombobox, Avatar, AvatarListItem} from '@growthbase/design-components';
import React, {useMemo} from 'react';
import {TComboboxItem} from '@udecode/plate-combobox/dist/types';

export const GebruikersMentionKey = 'gebruiker_mention';
export const GebruikersMentionTrigger = '@';

export interface ComboboxGebruiker {
    id: string;
    label: string;
    imgUrl: string | null;
    disabled?: boolean;
    externalId?: string;
}

export interface GebruikersComboboxProps {
    items: ComboboxGebruiker[];
}

const mapGebruikerToComboItem = (item: ComboboxGebruiker): TComboboxItem<ComboboxGebruiker> => ({
    key: item.id,
    text: item.label,
    disabled: item.disabled,
    data: item,
});

export const GebruikersCombobox = ({items}: GebruikersComboboxProps) => (
    <AsyncCombobox<TComboboxItem<ComboboxGebruiker>>
        pluginKey={GebruikersMentionKey}
        trigger={GebruikersMentionTrigger}
        items={useMemo(() => items.map(mapGebruikerToComboItem), [items])}
    >
        {({data}) => {
            const {imgUrl, label} = data;
            return (
                <AvatarListItem
                    disableActiveItemFocus
                    renderAvatar={(props) => <Avatar {...props} naam={label} imageUrl={imgUrl} />}
                    data={data}
                >
                    {label}
                </AvatarListItem>
            );
        }}
    </AsyncCombobox>
);
