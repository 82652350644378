export interface OverviewItemData {
    id: string;
    naam: string;
}

// eslint-disable-next-line no-shadow
export enum ViewType {
    Tabel = 'Tabel',
    Cards = 'Cards',
}

export interface OverviewFilters {
    searchQuery: string | null;
}
