import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {
    DefaultFormModalFooter,
    Loader,
    Modal,
    ModalContent,
    PopupHeader,
    StyledSelectInput,
    StyledSelectOption,
    useCreateStrictForm,
} from '@growthbase/design-components';
import {
    createFormValue,
    LijsttemplateAlsLijstToevoegenFormSchemaDto,
    useLijsttemplateAlsLijstToevoegenFormSchema,
} from './LijsttemplateAlsLijstToevoegenFormSchema';

export interface LijsttemplateAlsLijstToevoegenFormProps {
    onSubmit: (values: LijsttemplateAlsLijstToevoegenFormSchemaDto) => Promise<void>;
    options: StyledSelectOption<string>[];
    optionsLoading: boolean;
}

export const LijsttemplateAlsLijstToevoegenForm: FC<LijsttemplateAlsLijstToevoegenFormProps> = ({
    onSubmit,
    options,
    optionsLoading,
}) => {
    const {t} = useTranslation('spa_form.nl');
    const {
        formik,
        Form,
        Fields: {LijsttemplateField},
        formProps,
    } = useCreateStrictForm<LijsttemplateAlsLijstToevoegenFormSchemaDto>({
        onSubmit,
        enableLockPopupAndRoutingWhenDirty: true,
        createInitialValuesAfterSubmit: true,
        createInitialValues: createFormValue,
        schema: useLijsttemplateAlsLijstToevoegenFormSchema(),
    });

    const Lijsttemplate = LijsttemplateField.useComponent(StyledSelectInput);

    return (
        <Modal>
            <PopupHeader title={t('bordtemplate.LijsttemplateAlsLijstToevoegenForm.PopupHeader')} />
            <ModalContent>
                {optionsLoading ? (
                    <>
                        <Loader small />
                    </>
                ) : (
                    <Form {...formProps}>
                        <Lijsttemplate
                            options={options}
                            label={t('bordtemplate.LijsttemplateAlsLijstToevoegenForm.LijsttemplateFieldLabel')}
                            hideWarningOnFocusLost
                        />
                    </Form>
                )}
            </ModalContent>
            <DefaultFormModalFooter formik={formik} />
        </Modal>
    );
};
