import {
    useGetPlanningDataQuery,
    usePlanningZetBeschikbareActies,
    usePlanningSetActiveAgenda,
    usePlanningSetActiveViewGroup,
    usePlanningSetAgendas,
    usePlanningSetGroupBy,
    usePlanningSetPreferredViewForGroup,
    usePlanningSetTimePreference,
} from '@growthbase/planning';
import {useTranslation} from 'react-i18next';
import {denormalizeAgendas} from '../Tabs/AgendaTab/denormalizeAgendas';
import {AgendaGroupBy} from '../Views/AgendaView/AgendaViewsFormats';

export const useLoadPersistentPlanningState = (lijstWeergaveEnabled = true, timeRegistrationsEnabled = false) => {
    const {t} = useTranslation('spa.nl');
    const setAgendas = usePlanningSetAgendas();
    const setActiveAgenda = usePlanningSetActiveAgenda();
    const setTimePreference = usePlanningSetTimePreference();
    const setGroupBy = usePlanningSetGroupBy();
    const zetBeschikbareActies = usePlanningZetBeschikbareActies();
    const setPreferredView = usePlanningSetPreferredViewForGroup();
    const setActiveViewGroup = usePlanningSetActiveViewGroup();

    useGetPlanningDataQuery({
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (!data || !data.IngelogdeGebruiker) {
                return;
            }

            zetBeschikbareActies(data.IngelogdeGebruiker);

            const {beschikbareActies} = data.IngelogdeGebruiker;

            const agendas = denormalizeAgendas(t, data);

            if (beschikbareActies.planningUrenRegistratiesBetaIsBeschikbaar) {
                const myAgendasGroup = agendas.find((group) => group.id === 'my-calendars');
                if (myAgendasGroup && myAgendasGroup.items[0]) {
                    agendas
                        .find((group) => group.id === 'my-calendars')
                        ?.items.push({
                            id: 'time-registrations',
                            title: t('planning.urenRegistraties'),
                            color: '#015b5a',
                            enabled: timeRegistrationsEnabled,
                            employeeId: myAgendasGroup.items[0].employeeId,
                        });
                }
            }

            const flatAgendas = agendas.flatMap((agenda) => agenda.items);

            setAgendas({
                agendas,
                localOnly: true,
            });

            const beginTijd = data.Planning_Weergave_Voorkeuren?.dagBeginTijd ?? '08:00';
            const eindTijd = data.Planning_Weergave_Voorkeuren?.dagEindTijd ?? '18:00';
            const eersteDag = data.Planning_Weergave_Voorkeuren?.eersteDag ?? '1';
            const laatsteDag = data.Planning_Weergave_Voorkeuren?.laatsteDag ?? '5';

            setTimePreference({
                timeStart: beginTijd,
                timeEnd: eindTijd,
                dayStart: eersteDag,
                dayEnd: laatsteDag,
                localOnly: true,
            });

            if (data.Planning_Weergave_Voorkeuren?.actieveAgendaId) {
                const agendaId = data.Planning_Weergave_Voorkeuren.actieveAgendaId.toString();
                const agenda = flatAgendas.find((findAgenda) => findAgenda.id === agendaId);
                if (agenda) {
                    setActiveAgenda({agenda, localOnly: true});
                }
            }

            if (data.Planning_Weergave_Voorkeuren?.groepeerOp) {
                AgendaGroupBy.forEach((groupBy) => {
                    if (groupBy.groupBy === data.Planning_Weergave_Voorkeuren?.groepeerOp) {
                        setGroupBy({
                            groupBy: groupBy.groupBy,
                            localOnly: true,
                        });
                    }
                });
            }

            if (data.Planning_Weergave_Voorkeuren?.weergaveInstellingen) {
                data.Planning_Weergave_Voorkeuren?.weergaveInstellingen.forEach((formatInstellingen) => {
                    setPreferredView({
                        viewId: formatInstellingen.weergaveType,
                        groupId: formatInstellingen.weergave,
                        localOnly: true,
                    });
                });
            }

            let weergave = data.Planning_Weergave_Voorkeuren?.weergave ?? 'agenda';

            if (weergave === 'lijst' && !lijstWeergaveEnabled) {
                weergave = 'agenda';
            }

            if (weergave === 'lijst') {
                window.location.reload();
            }

            setActiveViewGroup({
                groupId: weergave,
                localOnly: true,
            });
        },
    });
};
