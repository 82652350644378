import {ReactNode, useEffect, useMemo, useRef} from 'react';
import {FloatingPortal} from '@floating-ui/react-dom-interactions';
import {createPortal} from 'react-dom';
import {throttle} from 'lodash';
import {noop, Subscription} from 'rxjs';

export interface PlaceholderPortalProps {
    children: ReactNode;
    placeholder: Element;
    placeholderChildren?: ReactNode;
    widthFrom?: 'placeholder' | 'element' | 'auto';
    heightFrom?: 'placeholder' | 'element' | 'auto';
    calculateSizeKey?: string;
}

export const PlaceholderPortal = ({
    children,
    placeholderChildren,
    placeholder,
    widthFrom = 'auto',
    heightFrom = 'auto',
    calculateSizeKey,
}: PlaceholderPortalProps) => {
    const portalWrapper = useRef<HTMLDivElement>(null);
    const placeholderElement = useRef<HTMLDivElement>(null);
    const modal = placeholder.closest<HTMLDivElement>('.modal-body');
    const root = modal || document.querySelector<HTMLElement>('.content-wrapper') || document.body;

    useEffect(
        () => {
            if (!portalWrapper.current || !placeholderElement.current || !root) {
                return noop;
            }

            const heightElements =
                heightFrom === 'placeholder'
                    ? {
                          from: placeholderElement.current,
                          to: portalWrapper.current,
                      }
                    : {
                          from: portalWrapper.current,
                          to: placeholderElement.current,
                      };

            const widthElements =
                widthFrom === 'placeholder'
                    ? {
                          from: placeholderElement.current,
                          to: portalWrapper.current,
                      }
                    : {
                          from: portalWrapper.current,
                          to: placeholderElement.current,
                      };

            const calculate = () => {
                if (widthFrom !== 'auto') {
                    widthElements.to.style.width = `${widthElements.from.clientWidth}px`;
                }

                if (heightFrom !== 'auto') {
                    heightElements.to.style.height = `${heightElements.from.clientHeight}px`;
                }

                if (!placeholderElement.current || !portalWrapper.current) {
                    return;
                }

                const boundingRect = placeholderElement.current.getBoundingClientRect();
                const boundingRoot = root.getBoundingClientRect();
                portalWrapper.current.style.position = 'absolute';
                portalWrapper.current.style.left = `${boundingRect.left - boundingRoot.left}px`;
                portalWrapper.current.style.top = `${boundingRect.top - boundingRoot.top}px`;
                portalWrapper.current.style.zIndex = !modal ? '100' : 'initial';
            };

            const throttledCalculate = throttle(calculate, 100);
            calculate();
            window.addEventListener('resize', throttledCalculate);
            const subscription = new Subscription();
            subscription.add(() => {
                window.removeEventListener('resize', throttledCalculate);
            });
            return () => subscription.unsubscribe();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [heightFrom, modal, root, widthFrom, calculateSizeKey]
    );

    const portal = useMemo(
        () =>
            createPortal(
                <>
                    {placeholderChildren}
                    <div ref={placeholderElement} />
                    <FloatingPortal root={root}>
                        <div ref={portalWrapper} data-placeholderportal="true" style={{display: 'inline-block'}}>
                            {children}
                        </div>
                    </FloatingPortal>
                </>,
                placeholder
            ),
        [children, placeholder, root, placeholderChildren]
    );

    if (!root) {
        return <></>;
    }

    return <>{portal}</>;
};
