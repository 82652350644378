import {DNDContainerUserData, DNDItemUserData} from '@growthbase/keys';

export type LijstDragType = 'Lijst';
export const LijstDragTypeValue: LijstDragType = 'Lijst';

export interface ContainerData extends DNDContainerUserData {
    titel: string;
}

export interface ItemData extends DNDItemUserData {
    titel: string;
}

// eslint-disable-next-line no-shadow
export enum OverviewType {
    Overzicht,
    ToonAlles,
    Volgend,
    Voltooid,
    Archief,
}

// eslint-disable-next-line no-shadow
export enum ViewType {
    Kanban = 'Kanban',
    Lijsten = 'Lijsten',
}

export interface TaakFilters {
    overviewType: OverviewType;
    searchQuery: string | null;
    deelnemers: string[] | null;
}
