import * as yup from 'yup';

// Automatically generated by 'make frontend-routes-dump'
// This is in version control because clientbox container needs TS code

export const RoutesSchema = yup
    .object({
        calendar: yup
            .object({
                modal_event: yup.string().required(), // /calendar/modal/calendar/:calendarId/event/:eventId
            })
            .label('calendar')
            .required(),
        relation: yup
            .object({
                index: yup.string().required(), // /relation/
                read: yup.string().required(), // /relation/:id/:name
            })
            .label('relation')
            .required(),
        relatie: yup
            .object({
                taken_van_relatie: yup.string().required(), // /relation/:relatieId/:relatieUuid/:naam/taken
            })
            .label('relatie')
            .required(),
        order: yup
            .object({
                index: yup.string().required(), // /order/
                read: yup.string().required(), // /order/:order/:name/details
                template_read: yup.string().required(), // /order/template/:order/:name/details
            })
            .label('order')
            .required(),
        opdracht: yup
            .object({
                borden: yup.string().required(), // /order/:opdrachtId/:opdrachtnummer/:opdrachtUuid/taken
                bord: yup.string().required(), // /opdracht/:opdrachtId/:opdrachtUuid/bord/:bordUuid
            })
            .label('opdracht')
            .required(),
        planning: yup
            .object({
                opdracht: yup.string().required(), // /order/:opdrachtId/:opdrachtnummer/planning
                ticket: yup.string().required(), // /ticket/:ticket/:name/planning
                index: yup.string().required(), // /planning
            })
            .label('planning')
            .required(),
        subscription: yup
            .object({
                index: yup.string().required(), // /subscription/
                read: yup.string().required(), // /subscription/:id/:name
            })
            .label('subscription')
            .required(),
        registration: yup
            .object({
                time_create: yup.string().required(), // /registration/time/create
                time_stop: yup.string().required(), // /registration/time/:id/stop
            })
            .label('registration')
            .required(),
        ticket: yup
            .object({
                view: yup.string().required(), // /ticket/view
                index: yup.string().required(), // /ticket/
                borden: yup.string().required(), // /ticket/:ticketId/:ticketnummer/:ticketUuid/borden
                bord: yup.string().required(), // /ticket/:ticketId/:ticketnummer/:ticketUuid/borden/:bordUuid
                taak_aanmaken: yup.string().required(), // /ticket/:ticketId/:ticketnummer/:ticketUuid/taak-aanmaken
                dashboard_read: yup.string().required(), // /ticket/dashboard/:ticket/:name
                read: yup.string().required(), // /ticket/:ticket/:name
            })
            .label('ticket')
            .required(),
        taken: yup
            .object({
                mijn_taken: yup.string().required(), // /taken/mijntaken
                mijn_taken_volgend: yup.string().required(), // /taken/mijntaken/volgend
                mijn_taken_archief: yup.string().required(), // /taken/mijntaken/archief
                mijn_taken_voltooid: yup.string().required(), // /taken/mijntaken/voltooid
                overzicht: yup.string().required(), // /taken/overzicht
                voltooid: yup.string().required(), // /taken/overzicht/voltooid
                archief: yup.string().required(), // /taken/overzicht/archief
                snooze: yup.string().required(), // /taken/overzicht/snoozed
                edit: yup.string().required(), // /taken/:id
                editActivities: yup.string().required(), // /taken/:id/activiteiten
                create: yup.string().required(), // /taken/aanmaken
                lijst_bewerken: yup.string().required(), // /taken/lijst/:lijstId/bewerken
                prive: yup.string().required(), // /taken/prive
            })
            .label('taken')
            .required(),
        takeninstellingen: yup
            .object({
                index: yup.string().required(), // /taken/instellingen
                algemeen: yup.string().required(), // /taken/instellingen/algemeen
                algemeen_bewerken: yup.string().required(), // /taken/instellingen/algemeen/bewerken
                mijnvoorkeuren: yup.string().required(), // /taken/instellingen/mijnvoorkeuren
                mijnvoorkeuren_bewerken: yup.string().required(), // /taken/instellingen/mijnvoorkeuren/bewerken
            })
            .label('takeninstellingen')
            .required(),
        ureninstellingen: yup
            .object({
                bewerken: yup.string().required(), // /uren/instellingen/bewerken
            })
            .label('ureninstellingen')
            .required(),
        abonnement: yup
            .object({
                borden: yup.string().required(), // /abonnement/:abonnementId/:abonnementnummer/:abonnementUuid/borden
                bord: yup.string().required(), // /abonnement/:abonnementId/:abonnementnummer/:abonnementUuid/borden/:bordUuid
            })
            .label('abonnement')
            .required(),
        abonnementtemplates: yup
            .object({
                overview: yup.string().required(), // /abonnement/:abonnementId/:abonnementnummer/:abonnementUuid/takentemplates
                view: yup.string().required(), // /abonnement/:abonnementUuid/takentemplates/:bordtemplateId
            })
            .label('abonnementtemplates')
            .required(),
        bord: yup
            .object({
                overview: yup.string().required(), // /taken-borden
                mijnborden: yup.string().required(), // /taken-borden/mijnborden
                archief: yup.string().required(), // /taken-borden/archief
                aanmaken: yup.string().required(), // /taken-borden/aanmaken
                view: yup.string().required(), // /taken-borden/:bordId
                bewerken: yup.string().required(), // /taken-borden/:bordId/bewerken
                activiteiten: yup.string().required(), // /taken-borden/:bordId/activiteiten
                lijst_aanmaken: yup.string().required(), // /taken-borden/:bordId/lijst/aanmaken
                lijst_bewerken: yup.string().required(), // /taken-borden/:bordId/lijst/:lijstId/bewerken
                lijsttemplate_als_lijst_toevoegen: yup.string().required(), // /taken-borden/:bordId/lijst/template/toevoegen
            })
            .label('bord')
            .required(),
        syncApi: yup
            .object({
                ignore: yup.string().required(), // /sync/api/ignore
                search: yup.string().required(), // /sync/api
                sync: yup.string().required(), // /sync/api/sync
            })
            .label('syncApi')
            .required(),
        developer: yup
            .object({
                sync: yup.string().required(), // /developer/sync
                models: yup.string().required(), // /developer/models
                email: yup.string().required(), // /developer/email
                models_detail: yup.string().required(), // /developer/models/:name
                projections: yup.string().required(), // /developer/projections
                logs: yup.string().required(), // /developer/logs
                config: yup.string().required(), // /developer/config
                log: yup.string().required(), // /developer/logs/:id
                replayprocesses: yup.string().required(), // /developer/replayprocesses
                replayprofiles: yup.string().required(), // /developer/replayprofiles
                projections_dashboard: yup.string().required(), // /developer/projections/dashboard
                process_detail: yup.string().required(), // /developer/process/:id
                replay_profile_detail: yup.string().required(), // /developer/replayprofiel/:id
                projections_detail: yup.string().required(), // /developer/projections/:id
                health: yup.string().required(), // /developer/health
            })
            .label('developer')
            .required(),
        bestandsbeheer: yup
            .object({
                overview: yup.string().required(), // /bestanden
                view: yup.string().required(), // /bestand/:bestandId
            })
            .label('bestandsbeheer')
            .required(),
        spa: yup
            .object({
                menu_save: yup.string().required(), // /spa/ssr/menu
            })
            .label('spa')
            .required(),
        agenda: yup
            .object({
                index: yup.string().required(), // /agenda
            })
            .label('agenda')
            .required(),
        growthbase: yup
            .object({
                abonnement: yup.string().required(), // /growthbase/abonnement/:abonnementUuid
                order: yup.string().required(), // /growthbase/opdracht/:orderUuid
                ticket: yup.string().required(), // /growthbase/ticket/:ticketUuid
                bedrijf: yup.string().required(), // /growthbase/bedrijf/:relation
                persoon: yup.string().required(), // /growthbase/persoon/:relation
            })
            .label('growthbase')
            .required(),
        bordtemplate: yup
            .object({
                create: yup.string().required(), // /bordtemplate/aanmaken
                bewerken: yup.string().required(), // /bordtemplate/:bordtemplateId/bewerken
                overview: yup.string().required(), // /bordtemplate/overview
                bekijken: yup.string().required(), // /bordtemplate/:bordtemplateId/lijsten
                lijsttoevoegen: yup.string().required(), // /bordtemplate/:bordtemplateId/toevoegen-op-basis-van-lijsttemplate
                bordtoevoegen: yup.string().required(), // /bordtemplate/toevoegen-op-basis-van-bordtemplate
                lijstaanmaken: yup.string().required(), // /bordtemplate/:bordtemplateId/lijst/aanmaken
            })
            .label('bordtemplate')
            .required(),
        lijsttemplate: yup
            .object({
                aanmaken: yup.string().required(), // /lijsttemplate/aanmaken
                bewerken: yup.string().required(), // /lijsttemplate/:lijsttemplateId/bewerken
                bekijken: yup.string().required(), // /lijsttemplate/:lijsttemplateId
                overview: yup.string().required(), // /lijsttemplate
            })
            .label('lijsttemplate')
            .required(),
        taaktemplate: yup
            .object({
                aanmaken: yup.string().required(), // /taaktemplate/aanmaken
                bewerken: yup.string().required(), // /taaktemplate/:taaktemplateId/bewerken
                overview: yup.string().required(), // /taaktemplate
            })
            .label('taaktemplate')
            .required(),
    })
    .label('routes')
    .required();
