import {
    AgendaViewConnected,
    CapaciteitViewConnected,
    PlanningViewConnected,
    useCurrentView,
} from '@growthbase/planning';
import {Loader} from '@growthbase/design-components';

export const PlanningViews = () => {
    const currentView = useCurrentView();
    return (
        <>
            {currentView.group === 'lijst' && <Loader fixed />}
            {currentView.group === 'planning' && <PlanningViewConnected />}
            {currentView.group === 'agenda' && <AgendaViewConnected />}
            {currentView.group === 'capaciteit' && <CapaciteitViewConnected />}
        </>
    );
};
