import {
    IUseBordRouteViewFactoryFragment,
    useLadingsPaginaBordConnectedQuery,
    useResolveConnections,
} from '@growthbase/graphql';
import {useEffect} from 'react';
import {DefaultKoppelbaarItem, useHandleError} from '@growthbase/spa';
import {useNavigate} from 'react-router';
import {NavigateOptions} from '@growthbase/routing';

/* eslint-disable react/no-unstable-nested-components */

export interface RedirectToIndexOptions {
    route?: (bord: IUseBordRouteViewFactoryFragment, options?: NavigateOptions) => string;
}

export function useLegacyRedirectToIndex({route}: RedirectToIndexOptions) {
    const onderdeelVan = DefaultKoppelbaarItem.useValue();
    const result = useLadingsPaginaBordConnectedQuery({
        variables: {
            type: onderdeelVan?.type,
            id: onderdeelVan?.id,
        },
        onError: useHandleError(),
        skip: !onderdeelVan,
    });

    const items = useResolveConnections(result.data?.items);

    const navigate = useNavigate();

    useEffect(() => {
        const inbox = items.find((item) => item.inbox?.isInbox) || items[0];
        if (inbox && route) {
            navigate(route(inbox));
        }
    }, [items, navigate, onderdeelVan, route]);

    return result.loading;
}
