import {ValueType, ValueTypeInterface, FactoryProps, FieldInstance} from '../contract';
import {createFieldInstance} from '../Utils';

export const ConditionValueType: ValueTypeInterface<FieldInstance> = {
    defaultValue: (props: FactoryProps): FieldInstance => createFieldInstance(props, props.fields[0]),
    type: ValueType.condition,
    isEmpty: (value, options: FactoryProps): boolean => {
        const {fields} = options;
        const field = fields.find(({name}) => name === value.name);
        if (!field) {
            throw new Error(`Field ${value.name} not found`);
        }
        return field.valueType.isEmpty(value.value, options);
    },
    convertToNewValueType: ({fields, previousValue}) => {
        if (previousValue.type === ValueType.conditions) {
            const previous = previousValue.value as FieldInstance[];
            if (previous.length === 1) {
                return previous[0];
            }
        }
        if (previousValue.type === ValueType.condition) {
            return previousValue.value as FieldInstance;
        }
        return {
            ...previousValue,
            id: Math.random().toString(36).substring(7),
        };
    },
};
