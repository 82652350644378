import {ReactNode, useContext} from 'react';
import {Form, FormikProvider, FormikContextType, FormikProps} from 'formik';
import classNames from 'classnames/bind';
import {StrictFormState, StrictFormStateContext} from '../Hook';
import styles from './FormikForm.module.scss';
import {NestedForm} from './NestedForm';

const cx = classNames.bind(styles);

export interface FormikFormProp<T> {
    formik: FormikContextType<T>;
    children: ((props: FormikProps<T>) => ReactNode) | ReactNode;
    state: StrictFormState;
}

export function FormikForm<T>({formik, state, children}: FormikFormProp<T>) {
    const classes = cx({
        form: true,
    });
    const hasParent = !!useContext(StrictFormStateContext);
    const content = typeof children === 'function' ? children(formik) : children;
    return (
        <StrictFormStateContext.Provider value={state}>
            <FormikProvider value={formik}>
                {hasParent ? (
                    <NestedForm className={classes} form={formik}>
                        {content}
                    </NestedForm>
                ) : (
                    <Form className={classes} placeholder="">
                        {content}
                    </Form>
                )}
            </FormikProvider>
        </StrictFormStateContext.Provider>
    );
}
