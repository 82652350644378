/* eslint-disable no-use-before-define */

import React, {FC, Ref, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {Tooltip} from '@growthbase/design-components';
import {FullNestedName, InstellingField, InstellingFieldType} from '../../../types';
import {Reason} from '../../../FieldControlledByService';
import {InstellingenId, useFieldIsOverridden, useInstellingenField} from '../../../redux';

export type ComponentResolver = (type: InstellingFieldType, name: string, depth: number) => FC<InstellingInputProps>;

export type InstellingInputProps<T extends InstellingField = InstellingField> = T & {
    override: Reason | null;
    id: InstellingenId;
    resolveComponent: ComponentResolver;
    depth: number;
};

export type InstellingInputWrapperProps = {
    id: InstellingenId;
    name: FullNestedName;
    resolveComponent: ComponentResolver;
    depth: number;
};

export const InstellingInputWrapper = ({id, name, resolveComponent, depth}: InstellingInputWrapperProps) => {
    const {t} = useTranslation('DesignComponents.nl');
    const field = useInstellingenField(id, name);
    const override = useFieldIsOverridden(id, name);
    const ref = useRef<Element>();

    const props: InstellingInputProps = {
        id,
        resolveComponent,
        ...field,
        name,
        override,
        depth: depth + 1,
    };

    const Component = field.FormComponent ?? resolveComponent(field.type, name, props.depth);
    const node = field.renderInputComponent ? <>{field.renderInputComponent(props)}</> : <Component {...props} />;
    if (!override) {
        return node;
    }
    if (override?.hide || field.hide) {
        return null;
    }
    return (
        <Tooltip
            content={t('InstellingenForm.beheerdDoorOptie', {
                label: override.label,
            })}
        >
            <div ref={ref as Ref<HTMLDivElement>}>{node}xx</div>
        </Tooltip>
    );
};
