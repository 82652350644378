import {ReactNode} from 'react';
import classNames from 'classnames/bind';
import {CSSProperties} from 'styled-components';
import styles from './Wrapper.module.scss';

const cx = classNames.bind(styles);

export interface WrapperProps {
    children: ReactNode;
    backgroundColor: string;
    disabled?: boolean;
}

export const Wrapper = ({children, backgroundColor, disabled}: WrapperProps) => {
    const className = cx('wrapper', {
        'wrapper--disabled': disabled,
    });

    const style: CSSProperties = {
        backgroundColor: !disabled ? backgroundColor : undefined,
    };

    return (
        <div className={className}>
            <div className={cx('wrapper__background')} style={style} />
            <div className={cx('wrapper__content')}>{children}</div>
        </div>
    );
};
