import {RenderSwimlaneCardProps, Swimlane} from '@growthbase/design-components';
import {DNDContainerUserData, onDropItemHandler, onRemoveItemHandler} from '@growthbase/keys';
import {PaginationHelpers} from '@growthbase/spa';
import {ReactNode} from 'react';
import {TaakDragType} from '../TaakTabel';
import {Footer} from './Components/Footer';
import {Header} from './Components/Header';
import {Kaart} from './Components/Kaart/Kaart';

export interface TaakKaart {
    id: string;
}

export interface TakenswimlaneProps<
    TKaart extends TaakKaart = TaakKaart,
    TContainerData extends DNDContainerUserData = DNDContainerUserData
> {
    onDrop: onDropItemHandler<TKaart, TContainerData>;
    onRemove?: onRemoveItemHandler<TKaart>;
    userData: TContainerData;
    pagination: PaginationHelpers<TKaart>;
    header?: ReactNode;
    footer?: ReactNode;
    children: (props: RenderSwimlaneCardProps<TKaart>) => ReactNode;
    placeholder?: boolean;
}

export function Takenswimlane<
    TKaart extends TaakKaart = TaakKaart,
    TContainerData extends DNDContainerUserData = DNDContainerUserData
>({children, ...rest}: TakenswimlaneProps<TKaart, TContainerData>) {
    return (
        <Swimlane<TaakDragType, TKaart, TContainerData> type="Taak" {...rest}>
            {children}
        </Swimlane>
    );
}

Takenswimlane.Header = Header;
Takenswimlane.Footer = Footer;
Takenswimlane.Kaart = Kaart;
