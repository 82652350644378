import {RelatieSchema, RelatieValue} from '@growthbase/relaties';
import {TFunction} from 'i18next';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {SchemaOf} from 'yup';
import {
    AvatarRowFieldItem,
    NullableISOStringDateRange,
    OpgemaakteTekst,
    opgemaakteTekstAsText,
    OpgemaakteTekstSchema,
} from '@growthbase/design-components';
import {TaakPlanningSchema} from '../TaakPlanningInput';
import {IDeelnemer} from '../TakenFilters/Component/Deelnemers/Deelnemers';
import {KoppelbaarItem, NullableKoppelbaarItemSchema} from '../Koppelen';

export type MaakTaakAanTaakFormDTO = {
    titel: OpgemaakteTekst;
    deelnemers: AvatarRowFieldItem[];
    relatie: RelatieValue;
    isPrive: boolean;
    planning: NullableISOStringDateRange;
    omschrijving: OpgemaakteTekst;
    onderdeelVan: KoppelbaarItem | null;
};

export const TaakCreateFormSchema = (t: TFunction): SchemaOf<MaakTaakAanTaakFormDTO> =>
    yup
        .object({
            relatie: RelatieSchema(t),
            titel: OpgemaakteTekstSchema(t)
                .test(function TestMinLength() {
                    const {path, createError, parent: value} = this;
                    const titel = opgemaakteTekstAsText(value.titel).trim();
                    if (titel.length > 0) {
                        return true;
                    }
                    return createError({path, message: t('taak.titelLength', {ns: 'spa_form.nl'}) ?? ''});
                })
                .label(t('titel', {ns: 'spa_form.nl'})),
            onderdeelVan: NullableKoppelbaarItemSchema(t),
            planning: TaakPlanningSchema(t),
            isPrive: yup
                .boolean()
                .label(t('prive', {ns: 'Taken.nl'}))
                .required(),
            omschrijving: OpgemaakteTekstSchema(t),
            deelnemers: yup.array().label(t('deelnemers', {ns: 'spa_form.nl'})) as SchemaOf<IDeelnemer[]>,
        })
        .label(t('taakformulier', {ns: 'spa_form.nl'}))
        .required();

export const createTaakInitialFormValues = (): MaakTaakAanTaakFormDTO => ({
    deelnemers: [],
    relatie: {},
    planning: {
        from: null,
        to: null,
    },
    isPrive: false,
    onderdeelVan: null,
    omschrijving: {
        elements: [
            {
                type: 'p',
                children: [
                    {
                        text: '',
                    },
                ],
            },
        ],
    },
    titel: {
        elements: [
            {
                type: 'p',
                children: [
                    {
                        text: '',
                    },
                ],
            },
        ],
    },
});

export const useTaakCreateSchema = (): SchemaOf<MaakTaakAanTaakFormDTO> => {
    const {t} = useTranslation();
    return useMemo(() => TaakCreateFormSchema(t), [t]);
};
