import {To} from 'react-router-dom';
import {ReactNode} from 'react';
import {useAddBreadCrumb} from '../../hooks/useBreadcrumbContext';

export interface AddBreadcrumbProps {
    children: ReactNode;
    to?: To | false;
    order?: number;
    asPageTitle?: boolean;
    excludeForChildren?: boolean;
}

export const AddBreadcrumb = ({children, excludeForChildren, asPageTitle = true, to, order}: AddBreadcrumbProps) => {
    useAddBreadCrumb({
        title: children,
        to: to === false ? undefined : to,
        order,
        asPageTitle,
        excludeForChildren,
    });
    return null;
};
