import {BestandThumbnailConnected} from '@growthbase/bestandsbeheer';
import {
    Avatar,
    Card,
    CardListItem,
    Font,
    Padding,
    ReadonlyOpgemaakteTekst,
    RelativeTime,
    Stack,
    useIsMobile,
    useNormalizeOpgemaakteTekstGraphQLResult,
    useOpgemaakteTekstIsEmpty,
} from '@growthbase/design-components';
import {IActiviteitenListFragment} from '@growthbase/graphql';
import {useDateObject} from '@growthbase/spa';
import {FC, memo} from 'react';

export interface ActiviteitenListItemProps {
    fragment: IActiviteitenListFragment;
}

export const ActiviteitenListItem: FC<ActiviteitenListItemProps> = memo(({fragment}) => {
    const time = useDateObject(fragment.at);

    const textActivity = fragment.type === 'TaakReactieactiviteit' || fragment.type === 'Taakomschrijvingactiviteit';
    const bijlageActivity = fragment.type === 'TaakBijlageActiviteit';

    const nieuweTekst = useNormalizeOpgemaakteTekstGraphQLResult(textActivity ? fragment.nieuweTekst : null);
    const vorigeTekst = useNormalizeOpgemaakteTekstGraphQLResult(textActivity ? fragment.vorigeTekst : null);
    const opgemaakteTextIsEmpty = useOpgemaakteTekstIsEmpty();
    const isMobile = useIsMobile();

    const cardHeader = (
        <Card.Header disableMinHeight>
            <Stack gap="small" justify horizontal={!isMobile}>
                <span data-testid="Omschrijving">
                    <strong>{fragment.gebruiker?.naam}</strong> {fragment.description.interpolated}
                </span>
                <Font.Info>
                    <RelativeTime time={time} />
                </Font.Info>
            </Stack>
        </Card.Header>
    );

    if (bijlageActivity) {
        const bestand = fragment.bijlage?.bestand;
        return (
            <CardListItem
                testId="Activity"
                reference={
                    fragment.gebruiker && (
                        <Avatar {...fragment.gebruiker} imageUrl={fragment.gebruiker.imageUrl ?? null} />
                    )
                }
            >
                <Card>
                    {cardHeader}
                    {bestand && (
                        <Card.Body>
                            <div style={{width: 70}}>
                                <BestandThumbnailConnected fragment={bestand} />
                            </div>
                        </Card.Body>
                    )}
                </Card>
            </CardListItem>
        );
    }

    if (textActivity) {
        return (
            <CardListItem
                testId="Activity"
                reference={
                    fragment.gebruiker && (
                        <Avatar {...fragment.gebruiker} imageUrl={fragment.gebruiker.imageUrl ?? null} />
                    )
                }
            >
                <Card>
                    {cardHeader}
                    {nieuweTekst && (
                        <Card.Body>
                            <Padding paddingSize="medium">
                                <ReadonlyOpgemaakteTekst value={nieuweTekst} />
                            </Padding>
                        </Card.Body>
                    )}
                    {vorigeTekst && !opgemaakteTextIsEmpty(vorigeTekst) && (
                        <Card.Body>
                            <Padding paddingSize="medium">
                                <Font.LineTrough>
                                    <ReadonlyOpgemaakteTekst value={vorigeTekst} />
                                </Font.LineTrough>
                            </Padding>
                        </Card.Body>
                    )}
                </Card>
            </CardListItem>
        );
    }

    return (
        <CardListItem
            testId="Activity"
            reference={
                fragment.gebruiker && <Avatar {...fragment.gebruiker} imageUrl={fragment.gebruiker.imageUrl ?? null} />
            }
        >
            <Card>{cardHeader}</Card>
        </CardListItem>
    );
});

ActiviteitenListItem.displayName = 'ActiviteitenListItem';
