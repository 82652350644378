import React, {useMemo} from 'react';
import {BoxSelectInput, StyledSelectOption, useCustomFieldComponent} from '@growthbase/design-components';
import {InstellingInputProps} from './InstellingInputWrapper';
import {ArrayValue} from '../../../types';

export const ArrayInstellingInput = (props: InstellingInputProps) => {
    const {override, label, name, options} = props as InstellingInputProps<ArrayValue>;
    const extraProps: Record<string, unknown> = {};
    if (override) {
        extraProps.disabled = true;
        if (override.useValue) {
            extraProps.value = override.value;
        }
    }
    const Component = useCustomFieldComponent(BoxSelectInput);
    return (
        <Component
            name={name}
            options={useMemo(
                () =>
                    Object.entries(options).map(
                        ([v, l]): StyledSelectOption<string | number> => ({
                            label: l,
                            value: v,
                        })
                    ),
                [options]
            )}
            label={label}
            labelHidden
            disabled={!!override}
            {...extraProps}
        />
    );
};
