import {RoutesDTO, useDefaultValueTreeId} from '@growthbase/spa';
import {useCallback} from 'react';
import {BACKGROUND_ROUTE_KEY, BACKGROUND_TREE_ID} from '../Schema/backgroundRouteSchema';
import {createURL, relativeURL} from '../Util';
import {NavigateOptions} from '../Value';
import {useBackgroundRoute} from './useBackgroundRoute';
import {useCurrentHref} from './useCurrentHref';
import {useRoutes} from './useRoutes';

export function createUseUrlFactory<TParams, TState>(
    getRoute: (routes: RoutesDTO, params?: TParams) => string,
    defaultOptions: NavigateOptions<TState> = {}
) {
    return () => {
        const treeId = useDefaultValueTreeId();
        const routes = useRoutes();
        const currentHref = useCurrentHref();
        const [backgroundRoute] = useBackgroundRoute();
        return useCallback(
            (params?: TParams, options: NavigateOptions<TState> = {}): string => {
                const {withBackground} = {
                    ...defaultOptions,
                    ...options,
                };
                const route = getRoute(routes, params);
                const withParams = createURL(route);
                if (withBackground) {
                    withParams.searchParams.append(BACKGROUND_ROUTE_KEY, backgroundRoute ?? currentHref);
                    withParams.searchParams.append(BACKGROUND_TREE_ID, treeId);
                }
                return relativeURL(withParams);
            },
            [routes, backgroundRoute, currentHref, treeId]
        );
    };
}

export const useUrlFactory = createUseUrlFactory<string, never>((routes, params) => {
    if (!params) {
        return null;
    }
    const [section, ...rest] = params.split('_');

    // @ts-expect-error We are just checking if the route exists
    if (!routes[section]) {
        return null;
    }
    // @ts-expect-error We are just checking if the route exist
    return routes[section][rest.join('_')] ?? null;
});
