export const idFromIri = (id: string): string => id.split('/').pop() as string;
export const assertIdFromIri = (id?: string | null): string => {
    if (!id) {
        throw new Error('Missing id');
    }

    return idFromIri(id);
};

export const iriFromId = (prefix: string) => (id: string) => `/${prefix}/${encodeURIComponent(idFromIri(id))}`;

export const compareIds = (id1?: string, id2?: string) => id1 && id2 && idFromIri(id1) === idFromIri(id2);
