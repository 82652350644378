import {Stack, StyledSelect} from '@growthbase/design-components';

export type opdrachtFilterTypes = 'all' | 'budget' | 'nacalculatie';

export type OpdrachtSelectieItem = {
    label: string;
    value: string;
};

export type OpdrachtTypeItem = {
    label: string;
    value: opdrachtFilterTypes;
};

export interface OpdrachtSelectieProps {
    loading?: boolean;
    opdrachten: OpdrachtSelectieItem[];
    value: string;
    onChange?: (value: string) => void;
    activeType?: OpdrachtTypeItem['value'];
    onChangeType?: (value: OpdrachtTypeItem['value']) => void;
}

const opdrachtTypes: OpdrachtTypeItem[] = [
    {value: 'all', label: 'Alle typen'},
    {value: 'budget', label: 'Budget'},
    {value: 'nacalculatie', label: 'Nacalculatie'},
];

export const OpdrachtSelectie = ({
    opdrachten,
    value,
    onChange,
    onChangeType,
    activeType = opdrachtTypes[0].value,
    loading = false,
}: OpdrachtSelectieProps) => (
    <Stack gap="small" horizontal>
        <StyledSelect<OpdrachtTypeItem['value']>
            onChange={onChangeType}
            options={opdrachtTypes}
            readonly={false}
            disabled={false}
            value={activeType}
        />
        <StyledSelect<OpdrachtSelectieItem['value']>
            onChange={onChange}
            options={opdrachten}
            readonly={false}
            disabled={loading}
            value={value}
        />
    </Stack>
);
