import React, {Ref, FC} from 'react';
import {ButtonHandler, ButtonLink, Buttons, usePopup} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';
import {ExternalEntityWithLabel} from '@growthbase/clientbox';
import {RelatieLink} from '../RelatieLink';

export interface RelatieButtonContentProps {
    readonly?: boolean;
    disabled?: boolean;

    persoon?: ExternalEntityWithLabel;
    bedrijf?: ExternalEntityWithLabel;

    onPersoonEdit: ButtonHandler;
    onPersoonRemove: ButtonHandler;

    onBedrijfEdit: ButtonHandler;
    onBedrijfRemove: ButtonHandler;

    onPersoonBedrijfEdit: ButtonHandler;
    singleValue?: boolean;
    textSize?: 'small' | 'default';
    iconsSize?: 'small' | 'default' | 'medium';

    reference?: Ref<HTMLDivElement>;
}

export const RelatieButtonContent: FC<RelatieButtonContentProps> = ({
    onBedrijfEdit,
    onBedrijfRemove,
    onPersoonEdit,
    onPersoonRemove,
    onPersoonBedrijfEdit,
    bedrijf,
    persoon,
    readonly,
    disabled,
    singleValue,
    reference,
    textSize,
    iconsSize = 'medium',
}) => {
    const {t} = useTranslation('spa_form.nl');
    const {isOpen} = usePopup();

    const persoonLink = persoon && (
        <RelatieLink
            readonly={readonly}
            disabled={disabled}
            onRemove={onPersoonRemove}
            onEdit={onPersoonEdit}
            relatie={persoon}
            size={textSize}
        />
    );

    const bedrijfLink = bedrijf && (
        <RelatieLink
            readonly={readonly}
            disabled={disabled}
            onRemove={onBedrijfRemove}
            onEdit={onBedrijfEdit}
            relatie={bedrijf}
            size={textSize}
        />
    );

    if (singleValue && bedrijf) {
        return (
            <div data-testid="RelatieButton" ref={reference as Ref<HTMLDivElement>}>
                <div>{bedrijfLink}</div>
            </div>
        );
    }

    if (singleValue && persoon) {
        return (
            <div data-testid="RelatieButton" ref={reference as Ref<HTMLDivElement>}>
                <div>{persoonLink}</div>
            </div>
        );
    }

    if (persoon && bedrijf) {
        return (
            <div data-testid="RelatieButton" ref={reference as Ref<HTMLDivElement>}>
                <div>{persoonLink}</div>
                <div>{bedrijfLink}</div>
            </div>
        );
    }

    if (singleValue && readonly) {
        return <></>;
    }

    if (persoon) {
        return (
            <div data-testid="RelatieButton" ref={reference as Ref<HTMLDivElement>}>
                {persoonLink}
                {!readonly && (
                    <div>
                        <ButtonLink disabled={disabled} readonly={readonly} onClick={onBedrijfEdit} size={textSize}>
                            {t('relatie.bedrijfToevoegen')}
                        </ButtonLink>
                    </div>
                )}
            </div>
        );
    }

    if (bedrijf) {
        return (
            <div data-testid="RelatieButton" ref={reference as Ref<HTMLDivElement>}>
                {bedrijfLink}
                {!readonly && (
                    <div>
                        <ButtonLink disabled={disabled} readonly={readonly} onClick={onPersoonEdit} size={textSize}>
                            {t('relatie.persoonToevoegen')}
                        </ButtonLink>
                    </div>
                )}
            </div>
        );
    }

    if (readonly) {
        return null;
    }

    // Relatie button
    const label = t('relatie.relatieToevoegen');
    return (
        <Buttons.PersonIconDotted
            ref={reference}
            onClick={onPersoonBedrijfEdit}
            disabled={disabled}
            readonly={readonly}
            size={iconsSize}
            ariaLabel={label}
            tooltip={label}
            active={isOpen}
        />
    );
};
