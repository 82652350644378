import {useCallbackRef} from '@growthbase/spa';
import {PlanningStoreState, usePlanningSetDate} from '@growthbase/planning';
import moment from 'moment';
import {useSelector} from 'react-redux';

export const useOnChangeDate = () => {
    const setDate = usePlanningSetDate();
    const {date: storedDate} = useSelector((state: PlanningStoreState) => state.planning);

    return useCallbackRef((newDate: Date) => {
        const currentDate = new Date();
        if (!storedDate || !moment(storedDate).isSame(newDate, 'day')) {
            newDate.setHours(currentDate.getHours());
            setDate({date: newDate});
        }
    });
};
