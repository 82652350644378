import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {
    OpgemaakteTekst,
    OpgemaakteTekstInput,
    SingleValueFieldForm,
    useInitialValuesOpgemaakteTekst,
    useOpgemaakteTekstSchema,
} from '@growthbase/design-components';

export interface OmschrijvingFieldProps {
    readonly?: boolean;
    value?: OpgemaakteTekst;
    onSubmit: (value: OpgemaakteTekst) => Promise<void>;
}

export const OmschrijvingField: FC<OmschrijvingFieldProps> = ({onSubmit, value, readonly}) => {
    const {t} = useTranslation('spa_form.nl');
    return (
        <SingleValueFieldForm
            readonly={readonly}
            disabled={!value}
            schema={useOpgemaakteTekstSchema()}
            testfieldName="OmschrijvingField"
            onSubmit={onSubmit}
            createInitialValues={useInitialValuesOpgemaakteTekst(value)}
            label={t('taak.omschrijving')}
            placeholder={t('taak.omschrijvingPlaceholder')}
            component={OpgemaakteTekstInput}
        />
    );
};
