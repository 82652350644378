import {useTypedSearchParams} from '@growthbase/routing';
import {useMemo} from 'react';
import {SearchInput, useSearchFormSchema} from '../SearchFormSchema';

export const useSearchField = (name: string) =>
    useTypedSearchParams<SearchInput>(
        name,
        useSearchFormSchema(),
        useMemo(() => ({query: null}), [])
    );
