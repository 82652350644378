import {Buttons} from '@growthbase/design-components';
import React, {FC, PropsWithChildren} from 'react';

export const ThreeStateBool: FC<
    {
        /**
         * For example
         *
         * Ignored
         * undefined all entities that are ignored and not ignored
         * 0 all entities that are not ignored
         * 1 all entities that are ignored
         */
        value: number | undefined;
        setValue(newValue: number | undefined): void;
    } & PropsWithChildren
> = ({value, setValue, children}) => {
    const update = (newValue: number): void => {
        if (value === undefined) {
            setValue(newValue === 1 ? 0 : 1);
            return;
        }
        if (newValue !== value) {
            setValue(undefined);
            return;
        }
        setValue(newValue);
    };
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                height: '100%',
                gap: 10,
            }}
        >
            {children}
            <Buttons.Check onClick={() => update(1)} active={value === undefined || value === 1} />
            <Buttons.Cross onClick={() => update(0)} active={value === undefined || value === 0} />
        </div>
    );
};
