import {FC, useCallback} from 'react';
import {ITaakBestemmingTextLabelFragment, useTaakBestemmingTextLabelUpdateLazyQuery} from '@growthbase/graphql';
import {ButtonLink, ViewConfig} from '@growthbase/design-components';
import {useExternalEntityFieldsInvalidated} from '@growthbase/cache';
import {compareIds} from '@growthbase/routing';
import {BestemmingTextLabel} from './BestemmingTextLabel';
import {VerplaatsButton} from '../VerplaatsButton';

export interface BestemmingTextLabelConnectedProps extends ViewConfig {
    fragment?: ITaakBestemmingTextLabelFragment | null;
}

export const BestemmingTextLabelConnected: FC<BestemmingTextLabelConnectedProps> = ({fragment, disabled, readonly}) => {
    const lijst = fragment?.lijstInBord?.lijst;
    const bord = lijst?.bord;
    const renderBestemmingLijstLink = useCallback(
        () => (
            <VerplaatsButton Button={ButtonLink} disabled={disabled} readonly={readonly} fragment={fragment ?? null} />
        ),
        [disabled, fragment, readonly]
    );

    const [update] = useTaakBestemmingTextLabelUpdateLazyQuery();
    useExternalEntityFieldsInvalidated(({typename, id}): void => {
        if (typename !== 'LijstInBordVanTaakEdge' && !compareIds(id, fragment?.id)) {
            return;
        }
        update({
            variables: {
                id,
            },
        });
    });
    if (!lijst || !bord) {
        return null;
    }
    return (
        <BestemmingTextLabel
            bord={bord.naam}
            bordId={bord.id}
            lijst={lijst.titel}
            renderBestemmingLijstLink={renderBestemmingLijstLink}
        />
    );
};
