import {ViewConfig} from '@growthbase/design-components';
import {ILijsttemplateSwimlaneFragment} from '@growthbase/graphql';
import {PaginationHelpers} from '@growthbase/spa';
import React from 'react';
import {onDropItemHandler, onRemoveItemHandler} from '@growthbase/keys';
import {TakenswimlaneConnected} from '@growthbase/takentemplates-taaktemplate';
import {MenuConnected} from '../../../Menu';
import {ContainerData, Filters} from '../../types';
import {SwimlaneView} from './SwimlaneView';
import {TakenVanLijstSwimlaneConnected} from './Components/TakenVanLijstSwimlane/TakenVanLijstSwimlaneConnected';
import {TemplateMenuConnected} from '../../../TemplateMenu';

export interface SwimlaneViewConnectedProps<
    TLijst extends ILijsttemplateSwimlaneFragment = ILijsttemplateSwimlaneFragment,
    TContainerData extends ContainerData = ContainerData
> extends ViewConfig {
    pagination: PaginationHelpers<TLijst>;
    userData: TContainerData;
    onDrop: onDropItemHandler<TLijst, TContainerData>;
    onRemove?: onRemoveItemHandler<TLijst>;
    filters: Filters;
}

export function SwimlaneViewConnected<
    TLijst extends ILijsttemplateSwimlaneFragment = ILijsttemplateSwimlaneFragment,
    TContainerData extends ContainerData = ContainerData
>({readonly, disabled, filters, ...props}: SwimlaneViewConnectedProps<TLijst, TContainerData>) {
    const viewConfig = {disabled, readonly};
    return (
        <SwimlaneView<TLijst, TContainerData> {...props}>
            {({anchor, item, dragging, hoover}) => {
                const herbruikbaar = item.herbruikbaarheid?.herbruikbaar ?? true;

                if (dragging) {
                    return <div>Dragging 123</div>;
                }

                return (
                    <TakenVanLijstSwimlaneConnected
                        fragment={item}
                        dragging={dragging}
                        hoover={hoover}
                        header={
                            <TakenswimlaneConnected.Header
                                fragment={item}
                                anchor={anchor}
                                menu={
                                    herbruikbaar ? (
                                        <TemplateMenuConnected bordtemplateId={props.userData.id} fragment={item} />
                                    ) : (
                                        <MenuConnected fragment={item} />
                                    )
                                }
                            />
                        }
                        filters={filters}
                        {...viewConfig}
                    />
                );
            }}
        </SwimlaneView>
    );
}
