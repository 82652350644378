import {useMemo} from 'react';
import {HiddenInstelling, InstellingFieldsInput} from '@growthbase/instellingen';
import {TaakKopierenFormValues} from '@growthbase/taken';
import {useTranslation} from 'react-i18next';
import {useTaakBijlagesEnabled} from '@growthbase/taak-bijlage';

export const useTaakKopierenOpties = (titel: string) => {
    const {t} = useTranslation('spa_form.nl');
    const {t: TNew} = useTranslation('Taken.nl');
    const bijlagesEnabled = useTaakBijlagesEnabled();
    return useMemo(
        (): InstellingFieldsInput<TaakKopierenFormValues> => ({
            title: {
                label: t('titel'),
                defaultValue: t('taak.copyTitle', {titel}) ?? '',
            },
            activiteiten: {
                label: t('taak.activiteiten'),
                defaultValue: false,
            },
            properties: {
                label: t('whatDoYouWantToCopy'),
                fields: {
                    alles: {
                        control: {
                            omschrijving: {when: true, value: true},
                            planning: {when: true, value: true},
                            deelnemers: {when: true, value: true},
                            relatie: {when: true, value: true},
                            volgers: {when: true, value: true},
                            gekoppeldeItem: {when: true, value: true},
                            reacties: {when: true, value: true},
                            mijnDag: {when: true, value: true},
                            bijlages: {when: true, value: bijlagesEnabled},
                        },
                        label: t('everything'),
                        defaultValue: false,
                        exclude: true,
                    },
                    omschrijving: {
                        label: t('taak.omschrijving'),
                        defaultValue: true,
                    },
                    gekoppeldeItem: {
                        label: t('taak.gekoppeldeItem'),
                        defaultValue: true,
                    },
                    relatie: {
                        label: t('taak.relatie'),
                        defaultValue: true,
                    },
                    planning: {
                        label: t('taak.planning'),
                        defaultValue: false,
                    },
                    deelnemers: {
                        label: t('taak.deelnemers'),
                        defaultValue: false,
                    },
                    bijlages: {
                        label: TNew('Bijlage.title'),
                        defaultValue: false,
                        ViewComponent: bijlagesEnabled ? undefined : HiddenInstelling,
                        InputComponent: bijlagesEnabled ? undefined : HiddenInstelling,
                    },
                    volgers: {
                        label: t('taak.volgers'),
                        defaultValue: false,
                    },
                    reacties: {
                        label: t('taak.reacties'),
                        defaultValue: false,
                    },
                    mijnDag: {
                        label: t('taak.mijnDag'),
                        defaultValue: false,
                    },
                },
            },
        }),
        [t, TNew, titel, bijlagesEnabled]
    );
};
