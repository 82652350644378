import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {CalendarSelectionItem, GroupedCalendarSelectionItem} from '@growthbase/design-components';
import moment from 'moment';
import {WritableDraft} from 'immer/dist/types/types-external';
import {createDispatchHook} from './createDispatchHook';
import {PlanningAgendaViewFormats} from '../Views/AgendaView/AgendaViewsFormats';
import {CapacityViewFormats} from '../Views/CapaciteitView/CapaciteitViewFormats';
import {GroupByType, ViewFormat} from '../ViewFormat';
import {PlanningViews} from '../Views/PlanningView/PlanningViewFormats';
import {AgendaAgendaViewFormats} from '../../../agenda/src/Agenda/AgendaViewsFormats';
import {ListViewFormat} from '../Views';
import {IGebruikerBeschikbareActiesFragment} from '../generated/generated';

export type PlanningViewType = 'planning' | 'capaciteit' | 'agenda' | 'lijst' | 'agenda-agenda';

type DefaultPayload = {
    localOnly?: boolean;
};

type PayloadSetDate = {
    date: Date;
} & DefaultPayload;

type PayloadChangeView = {
    view: PlanningViewType;
} & DefaultPayload;

type PayloadSetGroupBy = {
    groupBy: GroupByType;
} & DefaultPayload;

type PayloadSetActiveAgenda = {
    agenda: CalendarSelectionItem;
} & DefaultPayload;

type PayloadSetTime = {
    timeStart: string;
    timeEnd: string;
    dayStart: string;
    dayEnd: string;
} & DefaultPayload;

type PayloadSetAgendas = {
    agendas: GroupedCalendarSelectionItem[];
} & DefaultPayload;

type PayloadSetActiveAgendas = {
    activeAgendas: string[];
} & DefaultPayload;

export type PlanningContextState = {
    activeAgendaIds: string[];
    activeEmployeeIds: string[];

    activePlanningView?: string;
    activeAgendaView?: string;
    activeTab?: string;
    hash?: string;
    socketId: string | null;
    opdrachtenSidebarEnabled?: boolean;
    urenRegistratiesEnabled?: boolean;
    urenRegistratiesInPlanning?: boolean;
    viewSettingsLoaded: boolean;
    agendas: GroupedCalendarSelectionItem[];
    draggingBudgetForResource?: string;
    views: ViewFormat[];
    date?: Date;
    viewedDateRange?: {start: Date; end: Date};
    settings: {
        timeStart: string;
        timeEnd: string;
        dayStart: string;
        dayEnd: string;
    };
    opdracht?: {
        id: string;
        nummer: string;
        relatie: string;
        onderwerp: string;
    };
    ticket?: {
        id: string;
        onderwerp: string;
        nummer: string;
        relatie: string;
    };
    dienst?: {
        id: string;
    };
    restrictEventCreationInPast: boolean;
    creationDateRestrictions: {start: Date; end: Date}[];
};

export const defaultPlanningState: PlanningContextState = {
    activeAgendaIds: [],
    activeEmployeeIds: [],
    views: [
        ...PlanningAgendaViewFormats,
        ...PlanningViews,
        ...CapacityViewFormats,
        ...AgendaAgendaViewFormats,
        ListViewFormat,
    ],
    activeAgendaView: 'agenda-agenda-week',
    socketId: null,
    opdrachtenSidebarEnabled: false,
    settings: {
        timeStart: '08:00',
        timeEnd: '18:00',
        dayStart: '1',
        dayEnd: '5',
    },
    viewSettingsLoaded: false,
    agendas: [],
    creationDateRestrictions: [],
    restrictEventCreationInPast: false,
};

const createRandomHash = () =>
    Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

const applyTimeSettings = (
    format: ViewFormat,
    timeStart: string,
    timeEnd: string,
    dayStart: string,
    dayEnd: string
) => {
    if (format.view?.timeline) {
        format.view.timeline.startTime = timeStart;
        format.view.timeline.endTime = timeEnd;
    }
    if (format.view?.schedule?.startDay) {
        const endDay = parseInt(dayEnd, 10);
        format.view.schedule.startDay = parseInt(dayStart, 10);
        format.view.schedule.endDay = endDay === 7 ? 0 : endDay;
    }
    return format;
};

const applyActiveAgendas = (state: WritableDraft<PlanningContextState>) => {
    state.agendas.forEach((agendaGroup: GroupedCalendarSelectionItem) => {
        agendaGroup.items.forEach((agenda: CalendarSelectionItem) => {
            if (agenda.enabled && !state.activeAgendaIds.includes(agenda.id)) {
                state.activeAgendaIds.push(agenda.id);
                state.activeEmployeeIds.push(agenda.employeeId);
            } else if (!agenda.enabled && state.activeAgendaIds.includes(agenda.id)) {
                const index = state.activeAgendaIds.indexOf(agenda.id);
                state.activeAgendaIds.splice(index, 1);
                const employeeIndex = state.activeEmployeeIds.indexOf(agenda.employeeId);
                state.activeEmployeeIds.splice(employeeIndex, 1);
            }
        });
    });
};

const PlanningSlice = createSlice({
    name: 'Planning',
    initialState: defaultPlanningState,
    reducers: {
        startDraggingBudgetForResource(state, action: PayloadAction<{resource: string}>) {
            state.draggingBudgetForResource = action.payload.resource;
        },
        stopDraggingBudgetForResource(state) {
            state.draggingBudgetForResource = undefined;
        },
        zetBeschikbareActies(state, action: PayloadAction<IGebruikerBeschikbareActiesFragment>) {
            state.opdrachtenSidebarEnabled = action.payload.beschikbareActies.planningOpdrachtenSidebarIsBeschikbaar;
            state.urenRegistratiesEnabled = action.payload.beschikbareActies.planningUrenRegistratiesIsBeschikbaar;
            state.urenRegistratiesInPlanning =
                action.payload.beschikbareActies.planningUrenRegistratiesBetaIsBeschikbaar;
        },
        setSocketId(state, action: PayloadAction<{socketId: string}>) {
            state.socketId = action.payload.socketId;
        },

        setTimePreference(state, action: PayloadAction<PayloadSetTime>) {
            state.settings = action.payload;
            state.views = state.views.map((view) =>
                applyTimeSettings(
                    view,
                    action.payload.timeStart,
                    action.payload.timeEnd,
                    action.payload.dayStart,
                    action.payload.dayEnd
                )
            );
            state.viewSettingsLoaded = true;
        },

        refresh(state) {
            state.hash = createRandomHash();
        },

        setDate(state, action: PayloadAction<PayloadSetDate>) {
            state.date = action.payload.date;
        },

        setActiveAgenda(state, action: PayloadAction<PayloadSetActiveAgenda>) {
            state.agendas.forEach((group) => {
                group.items.forEach((item) => {
                    item.selected = item.id === action.payload.agenda.id;
                });
            });
            applyActiveAgendas(state);
        },

        setAgendas(state, action: PayloadAction<PayloadSetAgendas>) {
            const selectedAgenda = action.payload.agendas.flatMap((group) => group.items).find((item) => item.selected);
            state.agendas = action.payload.agendas;
            if (!selectedAgenda && state.viewSettingsLoaded) {
                state.agendas[0].items[0].enabled = true;
            }
            applyActiveAgendas(state);
        },

        setGroupBy(state, action: PayloadAction<PayloadSetGroupBy>) {
            const {payload} = action;
            const {groupBy} = payload;
            state.views.forEach((view) => {
                if (view.groupBy) {
                    view.groupBy = groupBy;
                }
            });
        },

        setViewPreferences(state, action: PayloadAction<PayloadChangeView>) {
            const foundView = state.views.find((view) => view.id === action.payload.view);
            if (foundView) {
                foundView.preferred = true;
            }
        },

        setActiveViewGroup(state, action: PayloadAction<{groupId: string; localOnly?: boolean}>) {
            const {payload} = action;
            const {groupId} = payload;
            state.views.forEach((setView) => {
                setView.active = setView.group === groupId && setView.preferred;
            });
        },

        setPreferredViewForGroup(
            state,
            action: PayloadAction<{groupId: string; viewId: string; localOnly?: boolean; activate?: boolean}>
        ) {
            const {payload} = action;
            const {groupId, viewId} = payload;

            if (groupId === 'agenda-agenda') {
                state.activeAgendaView = payload.viewId;
                return;
            }

            state.views.forEach((view) => {
                if (payload.activate) {
                    view.active = view.id === viewId;
                }
                if (view.group === groupId) {
                    view.preferred = view.id === viewId;
                }
            });

            // Ensure that all groups have a preferred view
            const groups = state.views.map((view) => view.group);
            groups.forEach((group) => {
                const groupViews = state.views.filter((view) => view.group === group);
                const preferredView = groupViews.find((view) => view.preferred);
                if (!preferredView) {
                    groupViews[0].preferred = true;
                }
            });
        },

        setActiveAgendas(state, action: PayloadAction<PayloadSetActiveAgendas>) {
            const activeIds = action.payload.activeAgendas;
            state.agendas.forEach((group) => {
                group.enabled = !group.items.some((item) => !activeIds.includes(item.id));
                group.items.forEach((item) => {
                    item.enabled = activeIds.includes(item.id);
                });
            });
            if (!activeIds && state.viewSettingsLoaded) {
                state.agendas[0].items[0].enabled = true;
            }
            applyActiveAgendas(state);
        },
        setDienst(state, action: PayloadAction<{dienst: PlanningContextState['dienst']}>) {
            state.dienst = action.payload.dienst;
        },
        setEventContext(
            state,
            action: PayloadAction<{context: Pick<PlanningContextState, 'opdracht' | 'dienst' | 'ticket'>}>
        ) {
            const {context} = action.payload;
            state.opdracht = context.opdracht;
            state.ticket = context.ticket;
            state.dienst = context.dienst;
        },
        initPlanningPopup(state) {
            state.restrictEventCreationInPast = true;
        },
        setViewedDateRange(state, action: PayloadAction<{start: Date; end: Date}>) {
            state.viewedDateRange = {
                start: action.payload.start,
                end: action.payload.end,
            };
            state.creationDateRestrictions = state.restrictEventCreationInPast
                ? [
                      {
                          start: moment().startOf('year').toDate(),
                          end: moment().startOf('day').subtract(1, 'second').toDate(),
                      },
                  ]
                : [];
        },
        setActiveTab(state, action: PayloadAction<{tab: string}>) {
            state.activeTab = action.payload.tab;
        },
    },
});

export interface PlanningStoreState {
    planning: PlanningContextState;
}

export const PlanningActions = PlanningSlice.actions;
export const PlanningReducer = PlanningSlice.reducer;

export const usePlanningSetSocketId = createDispatchHook(PlanningActions.setSocketId);
export const usePlanningSetTimePreference = createDispatchHook(PlanningActions.setTimePreference);
export const usePlanningRefresh = createDispatchHook(PlanningActions.refresh);
export const usePlanningSetGroupBy = createDispatchHook(PlanningActions.setGroupBy);
export const usePlanningSetActiveAgendas = createDispatchHook(PlanningActions.setActiveAgendas);
export const usePlanningZetBeschikbareActies = createDispatchHook(PlanningActions.zetBeschikbareActies);
export const usePlanningSetEventContext = createDispatchHook(PlanningActions.setEventContext);
export const usePlanningInitPlanningPopup = createDispatchHook(PlanningActions.initPlanningPopup);
export const usePlanningSetDienst = createDispatchHook(PlanningActions.setDienst);
export const usePlanningSetDate = createDispatchHook(PlanningActions.setDate);
export const usePlanningSetActiveAgenda = createDispatchHook(PlanningActions.setActiveAgenda);
export const usePlanningSetAgendas = createDispatchHook(PlanningActions.setAgendas);
export const usePlanningStartDraggingBudgetForResource = createDispatchHook(
    PlanningActions.startDraggingBudgetForResource
);
export const usePlanningStopDraggingBudgetForResource = createDispatchHook(
    PlanningActions.stopDraggingBudgetForResource
);

// View actions
export const usePlanningSetViewedDateRange = createDispatchHook(PlanningActions.setViewedDateRange);
export const usePlanningSetActiveViewGroup = createDispatchHook(PlanningActions.setActiveViewGroup);
export const usePlanningSetPreferredViewForGroup = createDispatchHook(PlanningActions.setPreferredViewForGroup);
export const usePlanningSetActiveTab = createDispatchHook(PlanningActions.setActiveTab);
