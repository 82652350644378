import {Modifiers} from '@apollo/client/cache/core/types/common';
import {getStoreKeyName} from '@apollo/client/utilities';
import {useApolloClient} from '@apollo/client';
import {useCallbackRef} from '@growthbase/spa';
import {ExternalEntityFieldsInvalidatedArguments} from '../MessageListener/useExternalEntityFieldsInvalidated';
import {BaseNode} from '../baseNode';

export const useApplyInvalidatedEntityFields = () => {
    const apollo = useApolloClient();
    return useCallbackRef((normalized: ExternalEntityFieldsInvalidatedArguments<BaseNode>) => {
        const fields = normalized.invalidation.reduce((modifier: Modifiers, field) => {
            modifier[getStoreKeyName(field.name, field.args)] = (value, options) => options.DELETE;
            return modifier;
        }, {} as Modifiers);

        apollo.cache.modify({
            id: `${normalized.typename}:${normalized.id}`,
            fields,
            broadcast: true,
        });
    });
};
