import {FC} from 'react';
import {FieldComponentProps, FieldInstance} from '../../contract';
import {Wrapper} from '../Wrapper';
import {Conditions} from '../Conditions';

export const FieldConditions: FC<FieldComponentProps<FieldInstance[]>> = ({
    nestingLevel,
    label,
    fields,
    onChange,
    value,
}) => (
    <Wrapper label={label} nestingLevel={nestingLevel}>
        <Conditions nestingLevel={nestingLevel} fields={fields} onChange={onChange} values={value} />
    </Wrapper>
);
