import {forwardRef, Ref} from 'react';
import classNames from 'classnames/bind';
import {capitalize} from 'lodash';
import styles from './ButtonLink.module.scss';
import {BaseButtonProps} from '../../Button';
import {Tooltip} from '../../Tooltip';

const cx = classNames.bind(styles);

export interface ButtonLinkProps extends BaseButtonProps {
    fat?: boolean;
    padding?: 'vertical' | 'horizontal' | 'both';
    color?: 'default' | 'primary' | 'placeholder';
    size?: 'small' | 'default';
    inheritStyle?: boolean;
    block?: boolean;
    href?: string | null;
}

export const ButtonLink = forwardRef(
    (
        {
            ariaLabel,
            disabled,
            readonly,
            color,
            onClick,
            onMouseDown,
            children,
            fat,
            size,
            padding,
            inheritStyle,
            tooltip,
            block,
            href,
        }: ButtonLinkProps,
        ref
    ) => {
        const className = cx(
            'buttonLink',
            `buttonLink--color${capitalize(color)}`,
            `buttonLink--padding${capitalize(padding)}`,
            !inheritStyle && {
                'buttonLink--fat': fat,
                'buttonLink--disabled': disabled,
                'buttonLink--readonly': readonly,
                'buttonLink--size': size,
                'buttonLink--block': block,
            },
            {
                'buttonLink--inheritStyle': inheritStyle,
            }
        );

        let button = (
            <button
                className={className}
                ref={ref as Ref<HTMLButtonElement>}
                type="button"
                aria-label={ariaLabel ?? undefined}
                onClick={disabled ? undefined : onClick}
                onMouseDown={disabled ? undefined : onMouseDown}
            >
                {children}
            </button>
        );

        if (href) {
            button = (
                <a
                    className={className}
                    ref={ref as Ref<HTMLAnchorElement>}
                    onClick={disabled ? undefined : onClick}
                    onMouseDown={disabled ? undefined : onMouseDown}
                    href={href}
                >
                    {children}
                </a>
            );
        }

        if (tooltip) {
            return (
                <Tooltip content={tooltip}>
                    <span ref={ref as Ref<HTMLElement>}>{button}</span>
                </Tooltip>
            );
        }

        return button;
    }
);

ButtonLink.displayName = 'ButtonLink';
