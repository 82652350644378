import axios, {AxiosInstance} from 'axios';
import {catchError, Observable, tap, throwError} from 'rxjs';
import {ExtractWarningFromResponseService} from '../ErrorHandling/ExtractWarningFromResponseService';
import {HttpClientInterface, RequestOptions} from './HttpClientInterface';

export class AxiosHttpClient implements HttpClientInterface {
    private warningService?: ExtractWarningFromResponseService;

    constructor(private client: AxiosInstance) {}

    public setWarningService(service: ExtractWarningFromResponseService): void {
        this.warningService = service;
    }

    public post<TResponse>(url: string, body: unknown, options: RequestOptions = {}): Observable<TResponse> {
        return this.decorateStream<TResponse>(
            new Observable((subscriber) => {
                const source = axios.CancelToken.source();
                this.client
                    .post(url, body, {
                        cancelToken: source.token,
                        headers: options.headers,
                    })
                    .then(({data}) => subscriber.next(data as TResponse))
                    .catch((e) => subscriber.error(e))
                    .finally(() => subscriber.complete());
                return () => {
                    source.cancel();
                };
            })
        );
    }

    public get<TResponse>(url: string, query?: Record<string, number | string | boolean>): Observable<TResponse> {
        return this.decorateStream(
            new Observable((subscriber) => {
                const source = axios.CancelToken.source();
                this.client
                    .get(url, {
                        cancelToken: source.token,
                        params: query,
                    })
                    .then(({data}) => subscriber.next(data as TResponse))
                    .catch((e) => subscriber.error(e))
                    .finally(() => subscriber.complete());
                return () => {
                    source.cancel();
                };
            })
        );
    }

    private decorateStream<TResponse>(input: Observable<TResponse>): Observable<TResponse> {
        const {warningService} = this;
        if (!warningService) {
            return input;
        }
        return input.pipe(
            tap((result) => warningService.extract(result)),
            catchError((error) => {
                warningService.error(error);
                return throwError(error);
            })
        );
    }
}
