import {useEffect, FC} from 'react';
import {useBackgroundRoute} from '../Hook/useBackgroundRoute';

export interface SetBackgroundRouteProps {
    defaultBackgroundRoute: string;
}

/**
 * When route is active but there is no background, it will set the background route.
 */
export const SetBackgroundRoute: FC<SetBackgroundRouteProps> = ({defaultBackgroundRoute}) => {
    const [backgroundRoute, setBackgroundRoute] = useBackgroundRoute();
    useEffect(() => {
        if (backgroundRoute) {
            return;
        }
        setBackgroundRoute(defaultBackgroundRoute);
    }, [backgroundRoute, defaultBackgroundRoute, setBackgroundRoute]);

    return null;
};
