import React, {FC, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {
    DefaultFormModalFooter,
    Modal,
    ModalContent,
    PopupHeader,
    TextFieldComponent,
    useCreateStrictForm,
} from '@growthbase/design-components';
import {EditFormSchemaDto, useLijsttemplateEditSchema} from './EditFormSchema';

export interface EditFormProps {
    value: EditFormSchemaDto;
    onSubmit: (values: EditFormSchemaDto) => Promise<void>;
}

export const EditForm: FC<EditFormProps> = ({onSubmit, value}) => {
    const {t} = useTranslation('spa_form.nl');

    const {
        formik,
        Form,
        Fields: {NaamField},
        formProps,
    } = useCreateStrictForm<EditFormSchemaDto>({
        onSubmit,
        enableLockPopupAndRoutingWhenDirty: true,
        createInitialValues: useCallback(() => value, [value]),
        schema: useLijsttemplateEditSchema(),
    });

    return (
        <Form {...formProps}>
            <Modal>
                <PopupHeader title={t('lijsttemplate.tabel.aanpassen')} />
                <ModalContent theme="gray">
                    <NaamField component={TextFieldComponent} label={t('lijsttemplate.tabel.naam')} />
                </ModalContent>
                <DefaultFormModalFooter formik={formik} />
            </Modal>
        </Form>
    );
};
