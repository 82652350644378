import React, {Fragment} from 'react';
import {Helmet} from 'react-helmet-async';
import {useDebouncedValue} from '@growthbase/spa';
import styles from './Breadcrumbs.module.scss';
import {useBreadcrumbs} from '../../hooks/useBreadcrumbContext';
import {BreadcrumbDivider} from '../BreadcrumbDivider/BreadcrumbDivider';
import {BreadcrumbLink} from '../BreadcrumbLink';

export const Breadcrumbs = () => {
    let breadcrumbs = useDebouncedValue(useBreadcrumbs(), 50);
    breadcrumbs = breadcrumbs.filter(
        (breadcrumb, index) => !breadcrumb.excludeForChildren || breadcrumbs.length - 1 === index
    );
    return (
        <div className={styles.breadcrumbs} aria-label="Breadcrumbs">
            {breadcrumbs.map((item, index) => {
                if (!item.title) {
                    return null;
                }
                if (index + 1 === breadcrumbs.length) {
                    return (
                        <Fragment key={index}>
                            <BreadcrumbLink {...item} isLast>
                                {item.title}
                            </BreadcrumbLink>
                            {item.asPageTitle && (
                                <Helmet defaultTitle={breadcrumbs.map((breadcrumb) => breadcrumb.title).join(' - ')} />
                            )}
                        </Fragment>
                    );
                }
                return (
                    <React.Fragment key={index}>
                        <BreadcrumbLink {...item}>{item.title}</BreadcrumbLink>
                        <BreadcrumbDivider />
                    </React.Fragment>
                );
            })}
        </div>
    );
};
