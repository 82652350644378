import React from 'react';
import {ModalContent, Tab, Tabs, TabsRendererMarginLeft} from '@growthbase/design-components';
import {InstellingInputProps, InstellingInputWrapper, ComponentResolver} from './InstellingInputWrapper';
import {FullNestedName, NestedValue} from '../../../types';
import {InstellingenId, useInstellingenField} from '../../../redux';
import {HiddenInstelling} from '../../HiddenInstelling';

const WrappedTab = ({
    name,
    id,
    resolveComponent,
}: {
    id: InstellingenId;
    resolveComponent: ComponentResolver;
    name: FullNestedName;
}) => {
    const field = useInstellingenField(id, name);
    /**
     * Beetje vies, maar werkt wel.
     */
    if (field.FormComponent === HiddenInstelling) {
        return null;
    }
    return (
        <Tab value={field.name} title={field.label}>
            <ModalContent theme="gray">
                <InstellingInputWrapper depth={-1} resolveComponent={resolveComponent} id={id} key={name} name={name} />
            </ModalContent>
        </Tab>
    );
};

export const NestedTabInstellingInput = (props: InstellingInputProps) => {
    const {fields, name, resolveComponent, id} = props as InstellingInputProps<NestedValue>;

    const field = useInstellingenField(id, fields[0]);
    return (
        <Tabs query={{name, defaultValue: field.name}} render={TabsRendererMarginLeft}>
            {fields.map((f) => (
                <WrappedTab resolveComponent={resolveComponent} id={id} key={f} name={f} />
            ))}
        </Tabs>
    );
};
