import {FC} from 'react';
import {ITaaktemplateTitelFieldFragment, useWijzigTemplatetaakTitelFieldMutation} from '@growthbase/graphql';
import {useCallbackRef} from '@growthbase/spa';
import {TitelField} from './TitelField';

export interface TaaktitelFieldConnectedProps {
    fragment?: ITaaktemplateTitelFieldFragment;
    readonly?: boolean;
}

export const TitelFieldConnected: FC<TaaktitelFieldConnectedProps> = ({fragment, readonly}) => {
    const [mutate] = useWijzigTemplatetaakTitelFieldMutation();

    const onSubmit = useCallbackRef(
        (title: string): Promise<void> =>
            mutate({
                variables: {
                    input: {
                        id: fragment?.id ?? '',
                        titel: title,
                    },
                },
            }).then()
    );

    if (!fragment) {
        return null;
    }

    return <TitelField onSubmit={onSubmit} titel={fragment.titel} readonly={readonly} />;
};
