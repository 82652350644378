import {ResultData} from '@growthbase/graphql';
import {idFromIri} from '@growthbase/routing';
import {findIndex} from 'lodash';
import {useCallbackRef, useLogger} from '@growthbase/spa';
import {UpdateQuery} from './useAddToQueryCache';
import {BaseNode} from '../baseNode';

export const useRemoveFromQueryCache = <TNode extends BaseNode, TVariables>(
    updateQuery: UpdateQuery<TNode, TVariables>
) => {
    const logger = useLogger('useRemoveFromQueryCache');
    return useCallbackRef((nodeId: string) => {
        nodeId = idFromIri(nodeId);
        updateQuery((cached): ResultData<TNode> => {
            const typed: ResultData<TNode> = cached ?? {
                items: {
                    totalCount: 0,
                    pageInfo: {
                        hasNextPage: false,
                        hasPreviousPage: false,
                        endCursor: null,
                        startCursor: null,
                    },
                    edges: [],
                },
            };
            const edges = (typed?.items?.edges ?? []) as Array<{
                node: {id: string};
            }>;
            const pageInfo = typed?.items?.pageInfo;
            if (!edges || !pageInfo || edges.length === 0) {
                logger.info(`Node "${nodeId}" could not be removed because list is empty.`);
                return typed;
            }
            const found = findIndex(edges, (edge) => idFromIri(edge.node.id) === nodeId);
            if (found === -1) {
                logger.info(`Node "${nodeId}" already removed from list.`);
                return typed;
            }
            const removedList = [...edges];
            removedList.splice(found, 1);
            return {
                ...typed,
                items: {
                    ...typed?.items,
                    pageInfo,
                    totalCount: (typed?.items?.totalCount ?? 0) - 1,
                    edges: removedList,
                },
            } as ResultData<TNode>;
        });
    });
};
