import React from 'react';
import {InstellingenForm, InstellingenFormProps, InstellingenView, InstellingenViewProps} from './Components';

export interface InstellingenProps<T extends object> extends InstellingenFormProps<T>, InstellingenViewProps<T> {
    view?: boolean;
}

export function Instellingen<T extends object>({view, ...props}: InstellingenProps<T>) {
    return view ? <InstellingenView {...props} /> : <InstellingenForm {...props} />;
}
