import {Id, toast} from 'react-toastify';
import {Logger} from 'ts-log';
import {IsReplayingClient} from './IsReplayingClient';
import {ReplayingMessage} from './ReplayingMessage';

export class IsReplayingService {
    private replaying = false;

    private id?: Id;

    constructor(private client: IsReplayingClient, private logger: Logger) {}

    public isReplaying(): boolean {
        return this.replaying;
    }

    public startMonitoring(): void {
        this.monitor();
    }

    private startNextUpdate(): 'startMonitoring' {
        setTimeout(() => this.monitor(), this.replaying ? 5000 : 60 * 1000);
        return 'startMonitoring';
    }

    private async monitor(): Promise<'startMonitoring'> {
        try {
            const data = await this.client.fetch();
            const fragment = data?.huidigProfiel?.isReplaying;
            if (!fragment) {
                return this.startNextUpdate();
            }
            this.replaying = fragment.isReplaying;
            if (!this.id && fragment.isReplaying) {
                this.id = toast(<ReplayingMessage fragment={fragment} />, {
                    type: 'warning',
                    autoClose: false,
                    closeOnClick: true,
                    position: 'top-center',
                    isLoading: true,
                });
            }
            const {id} = this;
            if (!id) {
                return this.startNextUpdate();
            }
            if (!fragment.isReplaying) {
                toast.dismiss(id);
                this.id = undefined;
                return this.startNextUpdate();
            }
            toast.update(id, {
                render: <ReplayingMessage fragment={fragment} />,
                autoClose: false,
            });
            return this.startNextUpdate();
        } catch (e) {
            this.logger.error(e);
            return this.startNextUpdate();
        }
    }
}
