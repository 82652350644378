export const formatTime = (seconds: number) => {
    let minutes = Math.floor(Math.abs(seconds) / 60);
    const hours = Math.floor(minutes / 60);
    minutes %= 60;
    const sign = seconds < 0 ? '-' : '';
    if (minutes === 0) {
        return `${sign + hours} uur`;
    }
    if (hours === 0) {
        return `${sign + minutes} min`;
    }
    return `${sign + hours} uur ${minutes} min`;
};

export const formatTimeDigital = (seconds: number, leadingZero = true) => {
    let minutes = Math.floor(Math.abs(seconds) / 60);
    const hours = Math.floor(minutes / 60);
    minutes %= 60;
    const pad = leadingZero ? '0' : '';
    return `${hours.toString().padStart(2, pad)}:${minutes.toString().padStart(2, '0')}`;
};

export const formatDurationDigital = (seconds: number, leadingZero = true) => {
    let minutes = Math.floor(Math.abs(seconds) / 60);
    const hours = Math.floor(minutes / 60);
    minutes %= 60;
    return `${hours.toString()}:${minutes.toString().padStart(2, '0')}`;
};

export const formatShortTime = (seconds: number) => {
    let minutes = Math.floor(Math.abs(seconds) / 60);
    const hours = Math.floor(minutes / 60);
    minutes %= 60;

    if (minutes === 0) {
        return `${hours}u`;
    }
    if (hours === 0) {
        return `< 1u`;
    }
    return `${hours}u`;
};
