import {PaginationHelpers} from '@growthbase/spa';
import React from 'react';
import {onDropItemHandler, onRemoveItemHandler} from '@growthbase/keys';
import {ContainerData, ItemData} from '../../types';
import {RenderSortableTakenTabelLijstType, SortableTakenTabelLijsten} from './Components';

export interface TabelProps<TLijst extends ItemData = ItemData, TContainerData extends ContainerData = ContainerData> {
    pagination: PaginationHelpers<TLijst>;
    userData: TContainerData;
    children: RenderSortableTakenTabelLijstType<TLijst>;
    onDrop: onDropItemHandler<TLijst, TContainerData>;
    onRemove?: onRemoveItemHandler<TLijst>;
}

export function Tabel<TLijst extends ItemData = ItemData, TContainerData extends ContainerData = ContainerData>({
    children,
    pagination,
    onRemove,
    userData,
    onDrop,
}: TabelProps<TLijst, TContainerData>) {
    return (
        <SortableTakenTabelLijsten
            onRemove={onRemove}
            onDrop={onDrop}
            lijsten={pagination.connections}
            userData={userData}
            renderLijst={children}
        />
    );
}
