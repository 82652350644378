import {mergeMap} from 'rxjs';
import {logInfo, pageRerender} from '../rxjs';
import {logger} from '../Utils';
import {apply} from './apply';
import {findCopyInputValueExtensionsOnce} from './Observable';

export const loadCopyInputValueExtension = () =>
    pageRerender()
        .pipe(
            logInfo('CopyInputValueExtension:Page render..'),
            mergeMap(() => findCopyInputValueExtensionsOnce()),
            logInfo('CopyInputValueExtension:Apply conditions..'),
            mergeMap(apply(logger.withPrefix('CopyInputValueExtension'))),
            logInfo('CopyInputValueExtension:Conditions applied..')
        )
        .subscribe();
