import {useMemo} from 'react';
import {createParagraph, createText} from '../Factory';
import {removeNullAndFalsePropertiesArray} from '../Normalize/RemoveNullAndFalseProperties';
import {OpgemaakteTekstElement} from './useOpgemaakteTekstSchema';

export const normalizeValues = (inputValue: OpgemaakteTekstElement[]) => {
    if (!inputValue || inputValue.length === 0) {
        return [createParagraph([createText('')])];
    }
    return removeNullAndFalsePropertiesArray(inputValue);
};

export const useNormalizeValues = (inputValue: OpgemaakteTekstElement[]) =>
    useMemo(() => normalizeValues(inputValue), [inputValue]);
