import {RoutesDTO} from '@growthbase/spa';
import {useCallback, useMemo} from 'react';
import {generatePath} from 'react-router-dom';
import {RouteHooks, NavigateOptions, RouteConfig} from '../Value';
import {createUseNavigateFactory} from './createUseNavigateFactory';
import {createUseUrlFactory} from './createUseUrlFactory';
import {useCurrentHref} from './useCurrentHref';

export const isSpaRoutes = new Map<string, string[]>();

export function createRouteHook<TParams, TState>(
    useUrlFactory: () => (params?: TParams, options?: NavigateOptions<TState>) => string,
    isBackgroundRoute: boolean
): RouteHooks<TParams, TState> {
    const useNavigateFactory = createUseNavigateFactory(useUrlFactory);
    const useUrl = (params?: TParams, options?: NavigateOptions<TState>): string => {
        const url = useUrlFactory();
        return useMemo(() => url(params, options), [url, params, options]);
    };
    const useOptionalUrl = (params?: TParams | null, options?: NavigateOptions<TState>) => {
        const url = useUrlFactory();
        return useMemo(() => {
            if (!params) {
                return null;
            }
            return url(params, options);
        }, [url, params, options]);
    };
    return {
        useUrlFactory,
        useUrl,
        useOptionalUrl,
        useNavigateFactory,
        useNavigate: (params?: TParams, options?: NavigateOptions<TState>) => {
            const callback = useNavigateFactory();
            return useCallback(() => callback(params, options), [callback, params, options]);
        },
        useTabRouting: (params?: TParams, options?: NavigateOptions<TState>) => {
            const to = useUrl(params, options);
            return useMemo(
                () => ({
                    to,
                    replace: isBackgroundRoute,
                }),
                [to]
            );
        },
        useIsActive: (params?: TParams) => {
            const url = useUrlFactory();
            const location = useCurrentHref();
            return useMemo(() => {
                const current = url(params);
                return current === location;
            }, [url, location, params]);
        },
    };
}

export function createRouteHooksFromRoutes<
    TRoutesKey extends keyof RoutesDTO,
    TRouteKey extends keyof RoutesDTO[TRoutesKey],
    TParams,
    TState
>(
    routesKey: TRoutesKey,
    routeKey: TRouteKey,
    config: RouteConfig<TParams, TState, keyof RoutesDTO[TRoutesKey]>
): RouteHooks<TParams, TState> {
    const value = isSpaRoutes.get(routesKey.toString()) || [];
    value.push(routeKey.toString());
    isSpaRoutes.set(routesKey.toString(), value);

    const useUrlFactory = createUseUrlFactory<TParams, TState>(
        (routes, params?: TParams) => {
            const route = routes[routesKey][routeKey];
            if (typeof route !== 'string') {
                throw new Error(`Missing ${routesKey}.${routeKey.toString()} from routes config`);
            }
            // @ts-expect-error - TS doesn't know that the route params are optional.
            return config.createRoute ? config.createRoute(route, params) : generatePath(route, params || {});
        },
        {
            withBackground: !!config.defaultBackgroundRoute,
        }
    );
    return createRouteHook(useUrlFactory, !!config.defaultBackgroundRoute);
}
