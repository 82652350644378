import {ILogLevel} from '@growthbase/graphql';
import {useState} from 'react';

const defaultLevels = [
    ILogLevel.Alert,
    ILogLevel.Critical,
    ILogLevel.Emergency,
    ILogLevel.Error,
    ILogLevel.Info,
    ILogLevel.Notice,
    ILogLevel.Warning,
];

export const useLogLevelState = () => useState<ILogLevel[]>(defaultLevels);
