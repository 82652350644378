import {opgemaakteTekstAsText} from '@growthbase/design-components';
import {IMaakBordAanTakenBordInput} from '@growthbase/graphql';
import {idFromIri} from '@growthbase/routing';
import {EditFormDTO} from './Hook/useEditSchema';

export const mapDTOToGraphQL = (id: string, dto: EditFormDTO): IMaakBordAanTakenBordInput => ({
    naam: opgemaakteTekstAsText(dto.naam),
    id,
    omschrijving: dto.omschrijving,
    item: dto.onderdeelVan
        ? {
              id: dto.onderdeelVan.id,
              type: dto.onderdeelVan.type,
          }
        : undefined,
    zichtbaarVoor: dto.zichtbaarVoor,
    deelnemers: dto.deelnemers.map((deelnemer) => idFromIri(deelnemer.id)),
});
