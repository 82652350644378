import {AddBreadcrumb, Loader} from '@growthbase/design-components';
import {OpdrachtRoutes, Pagina, PaginaProps} from '@growthbase/opdrachten-opdrachten';
import {useTranslation} from 'react-i18next';
import {FC, PropsWithChildren} from 'react';

export interface BordenPaginaProps extends PropsWithChildren, Omit<PaginaProps, 'titel'> {
    titel?: string;
}

export const BordenPagina: FC<BordenPaginaProps> = ({titel, children, ...rest}) => {
    const {t} = useTranslation('OpdrachtenTaken.nl');
    return (
        <Pagina
            {...rest}
            titel={
                titel ??
                t('BordenPagina.titel', {
                    opdrachtNaam: rest.opdrachtNaam,
                })
            }
        >
            <AddBreadcrumb to={OpdrachtRoutes.borden.useUrl(rest)}>{t('BordenPagina.breadcrumb')}</AddBreadcrumb>
            {children ?? <Loader center />}
        </Pagina>
    );
};
