import {useCallbackRef, useSwitch} from '@growthbase/spa';

export type ButtonHandler = () => unknown | Promise<unknown> | (() => void);
export type OptionalButtonHandler = ButtonHandler | undefined | null | false;

export interface HandleClickStateOptions<T = ButtonHandler | undefined> {
    onClick?: T;
    loading?: boolean;
    disabled?: boolean;
}

/**
 * This state can be passed down to subcomponents. A single button can disable all other buttons as wel.
 *
 * Disabled the button during promise processing.
 *
 */
export const useHandleClickState = <T = ButtonHandler>({loading, onClick, disabled}: HandleClickStateOptions<T>) => {
    const [disabledByClick, disable, enable] = useSwitch(false);
    const disableOnClick = useCallbackRef((...args: unknown[]): unknown => {
        disable();
        try {
            // @ts-expect-error This is correct :).
            return Promise.resolve(onClick(...args)).finally(enable);
        } catch (e) {
            enable();
            throw e;
        }
    });
    disabled = disabled || disabledByClick;
    loading = loading || disabledByClick;
    return {disabled, loading, onClick: (!onClick ? undefined : disableOnClick) as T | undefined};
};
