import {FC, useMemo} from 'react';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import {DateTimePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {FieldComponentProps} from '../../contract';

export const FieldDate: FC<FieldComponentProps<Date>> = ({onChange, value}) => {
    const instance = useMemo(() => moment(new Date(value)), [value]);
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker value={instance} onChange={(e) => e && onChange(e.toDate())} />
        </LocalizationProvider>
    );
};
