import {ActionsMenu, ActionsMenuButton} from '@growthbase/design-components';
import {useOr} from '@growthbase/spa';
import {ReactNode} from 'react';
import {VerwijderButtonConnectedProps} from './Components';

export interface ButtonsMenuProps {
    availableActions: {
        verwijderTaakUitLijsttemplate: boolean;
    };
    renderVerwijderenButton: (props: Omit<VerwijderButtonConnectedProps, 'fragment'>) => ReactNode;
}

export const Menu = ({renderVerwijderenButton, availableActions}: ButtonsMenuProps) => (
    <ActionsMenu visible={useOr(availableActions, ['verwijderTaakUitLijsttemplate'])}>
        {availableActions.verwijderTaakUitLijsttemplate &&
            renderVerwijderenButton({
                Button: ActionsMenuButton,
            })}
    </ActionsMenu>
);
