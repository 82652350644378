import React, {useCallback, useState} from 'react';
import {EventPopupTarget} from '@growthbase/design-components';
import {PlanningView} from './PlanningView';
import {useSocketChanges} from '../../_hooks/useSocketChanges';
import {EventPopupConnected} from '../../EventPopup/EventPopupConnected';
import {usePlanningData} from '../../_hooks/usePlanningData';
import {PlanningContextMenuConnected} from '../../Components';
import {useOnEventCreate} from '../../_hooks/useOnEventCreate';
import {useExtendDefaultEvent} from '../../_hooks/useExtendDefaultEvent';
import {AgendaModuleType} from '../../_hooks';

export const PlanningViewConnected = ({module}: {module?: AgendaModuleType}) => {
    useSocketChanges();

    const {loading, onRequestData, normalizedData} = usePlanningData(true);

    const [popupTarget, setPopupTarget] = useState<EventPopupTarget | null>(null);

    const onShowPopupForEvent = useCallback((args: EventPopupTarget) => {
        if (args.externalEventId.indexOf('e-') === 0) {
            setPopupTarget(args);
        }
    }, []);

    const {onEventCreate, isCreatingEvent} = useOnEventCreate(normalizedData);
    const extendDefaultEventCallback = useExtendDefaultEvent();

    return (
        <>
            {popupTarget && (
                <EventPopupConnected paddingLeft target={popupTarget} onClose={() => setPopupTarget(null)} />
            )}
            <PlanningView
                module={module}
                onRequestData={onRequestData}
                data={normalizedData}
                loading={loading || isCreatingEvent}
                onEventCreate={onEventCreate}
                extendDefaultEvent={extendDefaultEventCallback}
                onShowPopupForEvent={onShowPopupForEvent}
                onClosePopupForEvent={() => setPopupTarget(null)}
                contextMenu={<PlanningContextMenuConnected />}
            />
        </>
    );
};
