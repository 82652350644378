import {createContext, useCallback, useContext, useMemo} from 'react';
import {Subject} from 'rxjs';

export interface IsActiveEvent {
    field: string;
    active: boolean;
}

export interface StrictFormState {
    onActiveChange: Subject<IsActiveEvent>;
    onReset: Subject<unknown>;
}

export const StrictFormStateContext = createContext<StrictFormState | null>(null);

export function useCreateStrictFormState(): StrictFormState {
    return useMemo(
        () => ({
            onActiveChange: new Subject<IsActiveEvent>(),
            onReset: new Subject<unknown>(),
        }),
        []
    );
}

export const useActiveChangeDispatcher = (name: string) => {
    const state = useContext(StrictFormStateContext);
    return useCallback(
        (isActive: boolean) =>
            state?.onActiveChange?.next({
                field: name,
                active: isActive,
            }),
        [name, state]
    );
};
