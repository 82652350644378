import {LegacyRef, ReactNode} from 'react';
import {BaseButtonProps, Icons, IconSize} from '@growthbase/design-components';
import classNames from 'classnames/bind';
import styles from './BoxButton.module.scss';

const cx = classNames.bind(styles);

export interface BoxButtonProps extends BaseButtonProps {
    children?: ReactNode;
    onClear?: () => void;
    value?: string;
}

export const BoxButton = ({children, value, disabled, readonly, onClick, onClear, reference}: BoxButtonProps) => {
    const className = cx('boxButton', {
        'boxButton--hasValue': value,
        'boxButton--disabled': disabled,
        'boxButton--clearable': onClear,
        'boxButton--readonly': readonly,
    });

    return (
        <div className={className} ref={reference as LegacyRef<HTMLDivElement>}>
            <button onClick={onClick} className={cx('boxButton__labelButton')} type="button">
                {children}
            </button>
            <div className={cx('boxButton__value')}>{value}</div>
            {onClear && value && (
                <button className={cx('boxButton__clear')} type="button" onClick={onClear}>
                    <Icons.Cross size={IconSize.SIZE_2} />
                </button>
            )}
        </div>
    );
};
