import {FC, MouseEventHandler, ReactNode, useCallback} from 'react';
import {TaakRelatieButtonConnected} from '../Relaties/TaakRelatieButtonConnected';
import {TaakPlanningButtonConnected} from '../TaakPlanningButton';
import {TaakEditForm} from './TaakEditForm';
import {TaaktitelFieldConnected, TaaktitelFieldConnectedProps} from '../TaaktitelField';
import {TaakomschrijvingFieldConnected} from '../TaakomschrijvingField';
import {KoppelAanTaakButtonConnected} from '../Koppelen/KoppelAanTaakButton/KoppelAanTaakButtonConnected';
import {DeelnemersConnected} from '../Deelnemers/DeelnemersConnected';
import {TaakEditFragment} from '../TaakEditModal';

export type TaakEditFormConnectedProps = {
    fragment: TaakEditFragment;
    bijlages: ReactNode;
    voegBijlageToe?: MouseEventHandler<HTMLElement>;
};

const RenderTitel = (props: TaaktitelFieldConnectedProps) => <TaaktitelFieldConnected {...props} />;

export const TaakEditFormConnected: FC<TaakEditFormConnectedProps> = ({fragment, bijlages, voegBijlageToe}) => (
    <TaakEditForm
        fragment={fragment}
        renderTitelField={RenderTitel}
        OmschrijvingsFields={TaakomschrijvingFieldConnected}
        TaakPlanningButton={TaakPlanningButtonConnected}
        Deelnemers={DeelnemersConnected}
        RelatieButton={TaakRelatieButtonConnected}
        renderKoppelButton={useCallback(
            (props) => fragment && <KoppelAanTaakButtonConnected {...props} fragment={fragment} />,
            [fragment]
        )}
        bijlages={bijlages}
        voegBijlageToe={voegBijlageToe}
    />
);
