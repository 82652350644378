import {
    ApplyQueryCacheOptions,
    ApplyQueryCacheUpdateParams,
    QueryCacheUpdateType,
    useApplyQueryCacheUpdate,
} from './useApplyQueryCacheUpdate';
import {DetermineActionParams, useHandleExternalEntityChanges} from './useHandleExternalEntityChanges';
import {determineDefaultActionType} from './helpers';
import {BaseNode} from './baseNode';

export interface EntityChangeOptions<TNode extends BaseNode> extends ApplyQueryCacheOptions<TNode> {
    determineAction: (args: DetermineActionParams<TNode>, defaultAction: QueryCacheUpdateType) => QueryCacheUpdateType;
}

/**
 * Combines entity added, changed and removed hooks and determine by a given function to update the query cache.
 */
export const useUpdateQueryCacheFromExternalEntityChanges = <TNode extends BaseNode>({
    determineAction,
    ...options
}: EntityChangeOptions<TNode>) => {
    const apply = useApplyQueryCacheUpdate<TNode>(options);
    useHandleExternalEntityChanges((params: DetermineActionParams<TNode>): void =>
        apply({
            ...params,
            type: determineAction(params, determineDefaultActionType(params.type)),
            messageType: params.type,
        } as ApplyQueryCacheUpdateParams<TNode>)
    );
};
