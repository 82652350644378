import React, {ReactNode, useMemo} from 'react';
import {useBoolean} from 'react-use';
import {autoUpdate, flip, useFloating} from '@floating-ui/react-dom-interactions';
import {arrow} from '@floating-ui/react';
import classNames from 'classnames/bind';
import styles from './SidenavPopup.module.scss';
import {BaseButtonProps} from '../../Button';
import {SidenavPopupPortal} from '../SidenavPopupPortal';

const cx = classNames.bind(styles);

export interface SidenavPopupButtonProps {
    isOpen: boolean;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
}

export type renderSidenavPopupButton = (props: SidenavPopupButtonProps) => ReactNode;

export interface SidenavPopupProps extends BaseButtonProps {
    children?: ReactNode;
    testId?: string;
    open?: boolean;
    renderButton: renderSidenavPopupButton;
    theme?: 'light' | 'dark';
}

export const SidenavPopup = ({children, renderButton, testId, open = false, theme}: SidenavPopupProps) => {
    const [buttonHovered, setButtonHovered] = useBoolean(false);
    const [popupHovered, setPopupHovered] = useBoolean(false);
    const isOpen = buttonHovered || popupHovered || open;
    const arrowRef = React.useRef<HTMLDivElement>(null);

    const {x, y, strategy, reference, floating, middlewareData} = useFloating({
        open: isOpen,
        middleware: [flip(), arrow({element: arrowRef})],
        placement: 'right-start',
        whileElementsMounted: autoUpdate,
    });

    const {y: arrowY} = middlewareData?.arrow || {x: 0, y: 0};

    const classes = cx('sidenavPopup', `sidenavPopup--${theme ?? 'dark'}`);

    return (
        <>
            <span ref={reference} style={{display: 'inline-block'}}>
                {useMemo(
                    () =>
                        renderButton({
                            onMouseEnter: () => setButtonHovered(true),
                            onMouseLeave: () => setButtonHovered(false),
                            isOpen,
                        }),
                    [renderButton, setButtonHovered, isOpen]
                )}
            </span>

            <SidenavPopupPortal>
                {isOpen && (
                    <div
                        ref={floating}
                        className={classes}
                        style={{position: strategy, top: y ?? 0, left: x ?? 0}}
                        data-testid={testId}
                        onMouseEnter={() => setPopupHovered(true)}
                        onMouseLeave={() => setPopupHovered(false)}
                    >
                        {theme !== 'light' && (
                            <div className={styles.sidenavPopup__arrow} ref={arrowRef} style={{top: arrowY}} />
                        )}
                        <div className={styles.sidenavPopup__popup}>{children}</div>
                    </div>
                )}
            </SidenavPopupPortal>
        </>
    );
};
