import React from 'react';
import {ActionsMenuButton, PageActionsMenu} from '@growthbase/design-components';
import {
    ActionsMenuLijsttemplateAlsLijstToevoegenButton,
    ActionsMenuLijstToevoegenButton,
} from '@growthbase/takenlijst-lijst';

import {IBordActionBarFragment} from '@growthbase/graphql';
import {useHistoryBack} from '@growthbase/routing';
import {BewerkButton, VerwijderButtonConnected} from '../../../ActionMenu';

export interface ActionsBarProps {
    fragment: IBordActionBarFragment;
}

export const ActionsBar = ({fragment}: ActionsBarProps) => {
    const bordId = fragment.id;
    const goBack = useHistoryBack();
    return (
        <PageActionsMenu>
            <BewerkButton id={bordId} Button={ActionsMenuButton} globalText />
            <ActionsMenuLijstToevoegenButton bordId={bordId} />
            <ActionsMenuLijsttemplateAlsLijstToevoegenButton bordId={bordId} />
            <VerwijderButtonConnected
                onDeleted={goBack}
                fragment={fragment}
                Button={ActionsMenuButton}
                readonly={false}
                disabled={false}
            />
        </PageActionsMenu>
    );
};
