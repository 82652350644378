import {BaseButtonProps, Buttons} from '@growthbase/design-components';
import {IKopieerTaakButtonFragment, useKopieerTaakButtonTaakMutation} from '@growthbase/graphql';
import {FC, useCallback} from 'react';
import {v4} from 'uuid';
import {KopieerTaakButton} from './KopieerTaakButton';

export interface KopieerTaakButtonConnectedProps {
    fragment?: IKopieerTaakButtonFragment;
    onCreated?: (id: string) => void;
    Button: FC<BaseButtonProps>;
}

export const KopieerTaakButtonConnected: FC<KopieerTaakButtonConnectedProps> = ({
    Button = Buttons.Copy,
    fragment,
    onCreated,
}) => {
    const [taak] = useKopieerTaakButtonTaakMutation();
    return (
        <KopieerTaakButton
            Button={Button}
            fragment={fragment}
            onSubmit={useCallback(
                async (values): Promise<void> => {
                    if (!fragment) {
                        return;
                    }
                    const id = v4();

                    await taak({
                        variables: {
                            input: {
                                id: '00000000-0000-0000-0000-000000000000',
                                opties: {
                                    copyId: id,
                                    originalId: fragment.id,
                                    metActiviteiten: values.activiteiten,
                                },
                                titel: values.title,
                                clientMutationId: 'KopieerTaakButtonConnected',
                                teKopierenEigenschappen: {
                                    ...values.properties,
                                    relatie: values.properties.relatie
                                        ? {idVanGekopieerdeRelatieverwijzing: v4()}
                                        : null,
                                },
                            },
                        },
                    });
                    if (onCreated) {
                        onCreated(id);
                    }
                },
                [
                    taak,
                    fragment,
                    onCreated,
                ]
            )}
        />
    );
};
