import {useContext} from 'react';
import {useHref, useLocation} from 'react-router-dom';
import {BackgroundRouteContext} from '../BackgroundRouteContext';

export const useCurrentHref = () => {
    const backgroundLocation = useContext(BackgroundRouteContext);
    const location = useLocation();

    return useHref(backgroundLocation ?? location);
};
