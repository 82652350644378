import moment from 'moment';
import classNames from 'classnames/bind';
import {capitalize} from 'lodash';
import styles from './CalendarDate.module.scss';

const cx = classNames.bind(styles);

export interface CalendarDateProps {
    date: Date;
    small?: boolean;
}

export const CalendarDate = ({date, small}: CalendarDateProps) => {
    const classes = cx('calendarDate');
    const format: string = small ? 'ddd D' : 'dddd D MMMM';
    return <div className={classes}>{capitalize(moment(date).format(format))}</div>;
};
