import {comboboxStore} from '@udecode/plate';
import {useThrottle} from 'react-use';

export const useAsyncComboBox = (key: string) => {
    const id = comboboxStore.use.activeId();
    const open = comboboxStore.use.isOpen();
    const text = comboboxStore.use.text();
    const enabled = open && id === key;
    const throttledText = useThrottle(enabled ? text : null, 100);
    return {
        open: enabled,
        text: throttledText && throttledText.trim() !== '' ? throttledText : null,
    };
};
