import {isEqual, noop} from 'lodash';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useLogger} from '@growthbase/spa';
import {ActiveItemRegionApiContext} from '../ActiveItemRegionContainer';
import {
    ActiveItemData,
    ActiveItemSource,
    ActiveItemType,
    ItemPayload,
    useActivate,
    useDeactivate,
} from '../Redux/activeReducer';

export interface SetItemActiveApi<TData extends ActiveItemData = ActiveItemData> {
    deactivate(): void;

    activate(data: TData): () => void;

    /**
     * If this hooks has an active item.
     */
    iHaveActiveItemSet: boolean;

    type: ActiveItemType;
    source: ActiveItemSource;
}

export interface SetITemActiveOptions {
    type: ActiveItemType;
    source: ActiveItemSource;
}

export function useSetItemActive<TData extends ActiveItemData = ActiveItemData>({
    type,
    source,
}: SetITemActiveOptions): SetItemActiveApi<TData> {
    const context = useContext(ActiveItemRegionApiContext);
    const activate = useActivate();
    const deactivate = useDeactivate();
    const logger = useLogger('useSetItemActive');
    const deSelect = useRef<(() => void) | null>(null);

    const immutableRef = useRef<unknown>(null);
    const [isActive, setIsActive] = useState(false);
    const setActive = useCallback(
        (data: ActiveItemData): (() => void) => {
            if (!context) {
                logger.info('Context not found');
                return noop;
            }
            if (isActive && isEqual(immutableRef.current, data)) {
                return noop;
            }
            immutableRef.current = data;
            const payload: ItemPayload = {
                data,
                source,
                type,
                treeId: context.treeId,
                nestingLevel: context.nestingLevel,
            };
            deSelect.current?.();
            deSelect.current = () => {
                setIsActive(false);
                deactivate(payload);
                deSelect.current = null;
            };
            setIsActive(true);
            activate(payload);
            return () => {
                deSelect.current?.();
            };
        },
        [
            context,
            isActive,
            source,
            type,
            activate,
            logger,
            deactivate,
        ]
    );
    useEffect(() => {
        if (!isActive) {
            return noop;
        }
        return () => deSelect.current?.();
    }, [isActive, deSelect]);
    const unset = useCallback((): void => deSelect.current?.(), [deSelect]);
    return {deactivate: unset, activate: setActive, iHaveActiveItemSet: isActive, type, source};
}
