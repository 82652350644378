import {ITaakTabelLijstConnectedFragment} from '@growthbase/graphql';
import {DNDItemData} from '@growthbase/keys';
import React, {FC, memo} from 'react';
import {LijstMenuConnected} from '../../../../../LijstMenu';
import {LijstDragType, TaakFilters} from '../../../../types';
import {TaakTabelLijstConnected} from '../TaakTabelLijst';
import {TakenTabelVanLijstConnected} from '../TakenTabelVanLijst';
import {SortableTakenTabelLijst} from './SortableTakenTabelLijst';

interface SortableTakenTabelLijstConnectedProps {
    item: DNDItemData<ITaakTabelLijstConnectedFragment, LijstDragType>;
    filters: TaakFilters;
}

export const SortableTakenTabelLijstConnected: FC<SortableTakenTabelLijstConnectedProps> = memo(({item, filters}) => {
    if (!item.userData.availableActions.kanLijstVerplaatsen) {
        return <TaakTabelLijstConnected fragment={item.userData} filters={filters} />;
    }
    return (
        <SortableTakenTabelLijst
            renderTabel={(props) => (
                <TakenTabelVanLijstConnected {...props} filters={filters} fragment={item.userData} />
            )}
            menu={<LijstMenuConnected fragment={item.userData} />}
            item={item}
        />
    );
});

SortableTakenTabelLijstConnected.displayName = 'SortableTakenTabelLijstConnected';
