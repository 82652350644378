import {
    ApolloCache,
    defaultDataIdFromObject,
    FieldMergeFunction,
    InMemoryCache,
    NormalizedCacheObject,
} from '@apollo/client';
import introspectionResult from './generated/introspection.json';
import {FieldMergePlaceholderEdges} from './mergePlaceholders';
import {PossibleTypes} from './PosibleTypes';

export const idFromIri = (id: string): string => id.split('/').pop() as string;

const defaultBoolField = (defaultValue: boolean) => ({
    read(value = defaultValue) {
        return value;
    },
    merge(existing?: boolean, incoming?: boolean) {
        return incoming ?? defaultValue;
    },
});

const mergeIncomingPreserveOld: FieldMergeFunction = (existing: object = {}, incoming: object = {}) => ({
    ...existing,
    ...incoming,
});

/**
 * Merge two lists of objects, based on their id field.
 */
const mergeList: FieldMergeFunction = (existing: {id: string}[] = [], incoming: {id: string}[] = []) => {
    const result = incoming.map((item) => {
        const existingItem = existing.find((e) => e.id === item.id);
        if (existingItem) {
            return {...existingItem, ...item};
        }
        return item;
    });
    return result;
};

export const createGraphQLCache = (): ApolloCache<NormalizedCacheObject> =>
    new InMemoryCache({
        dataIdFromObject(responseObject) {
            // Remove iri part from it's, graphql type is enough.
            if (responseObject && typeof responseObject.id === 'string') {
                responseObject = {...responseObject, id: idFromIri(responseObject.id)};
            }
            // eslint-disable-next-line no-underscore-dangle
            if (responseObject && PossibleTypes.Relatieverwijzing.includes(responseObject.__typename ?? '')) {
                return `Relatieverwijzing:${responseObject.id}`;
            }
            return defaultDataIdFromObject(responseObject);
        },

        typePolicies: {
            TaakConnection: {
                merge: FieldMergePlaceholderEdges,
            },
            TaakEdge: {
                fields: {
                    placeholderPosition: defaultBoolField(false),
                },
            },
            TaaktemplateEdge: {
                fields: {
                    placeholderPosition: defaultBoolField(false),
                },
            },
            Node: {
                fields: {
                    isLocked: defaultBoolField(false),
                },
            },
            AvailableTaakActions: {
                merge: mergeIncomingPreserveOld,
            },
            AvailableBedrijfActions: {
                merge: mergeIncomingPreserveOld,
            },
            AvailableGebruikerActions: {
                merge: mergeIncomingPreserveOld,
            },
            Takentemplate_Lijsttemplate_AvailableLijsttemplateActions: {
                merge: mergeIncomingPreserveOld,
            },
            TakenBord_AvailableBordActions: {
                merge: mergeIncomingPreserveOld,
            },
            Taak: {
                fields: {
                    bijlages: {
                        merge: mergeList,
                    },
                },
            },
        },
        possibleTypes: introspectionResult.possibleTypes,
    });
