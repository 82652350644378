import React from 'react';
import {CreateRouteFunction, createRoutes, idFromIri} from '@growthbase/routing';
import {generatePath} from 'react-router-dom';
import {OpdrachtPlanningPageConnectedProps, OpdrachtPlanningPageConnected} from '@growthbase/opdrachten-planning';
import {TicketPlanningPageConnected, TicketPlanningPageConnectedProps} from '@growthbase/tickets-planning';
import {PlanningConnected} from './PlanningConnected';

export type OpdrachtRouteParams = {
    opdrachtId: string;
    opdrachtnummer: string;
};

export type TicketRouteParams = {
    ticket: string;
    name: string;
};

const generateOpdrachtRouteWithLegacyId: CreateRouteFunction<OpdrachtRouteParams> = (route: string, params) =>
    generatePath(route, {
        opdrachtnummer: idFromIri(params?.opdrachtnummer ?? ''),
        opdrachtId: idFromIri(params?.opdrachtId ?? ''),
    });

const generateTicketRouteWithLegacyId: CreateRouteFunction<TicketRouteParams> = (route: string, params) =>
    generatePath(route, {
        name: idFromIri(params?.name ?? ''),
        ticket: idFromIri(params?.ticket ?? ''),
    });

export const PlanningRoutes = createRoutes('planning', {
    /**
     * With legacy id.
     */
    index: {
        Element: () => <PlanningConnected />,
    },
    opdracht: {
        Element: (props: OpdrachtPlanningPageConnectedProps) => <OpdrachtPlanningPageConnected {...props} />,
        createRoute: generateOpdrachtRouteWithLegacyId,
    },
    ticket: {
        Element: (props: TicketPlanningPageConnectedProps) => <TicketPlanningPageConnected {...props} />,
        createRoute: generateTicketRouteWithLegacyId,
    },
});
