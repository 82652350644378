import {useCallback, useRef} from 'react';
import {ActiveItemData, ActiveItemSource, ActiveItemType, useSetItemActive} from '../../Active';
import {useTraversableInput} from './useTraversableInput';
import {useTraversableList} from './useTraversableList';

export interface TraversableOverview<TData extends ActiveItemData = ActiveItemData> {
    items: TData[];
    source: ActiveItemSource;
    type: ActiveItemType;
}

/**
 * Convenience function to use traversable list in combination with a search field.
 *
 * Don't add too much logic to this.
 */
export function useTraversableOverview<
    TData extends ActiveItemData = ActiveItemData,
    TElement extends HTMLElement = HTMLElement
>({items, type, source}: TraversableOverview<TData>) {
    const api = useSetItemActive({
        type,
        source,
    });

    const active = useTraversableList({
        ...api,
        items,
    });

    const topRef = useRef<TElement | null>(null);

    useTraversableInput({
        ...api,
        ref: topRef,
        position: 'start',
        items,
    });

    return {
        ...api,
        topRef,
        active,
        setTopRef: useCallback(
            (node: TElement | null) => {
                topRef.current = node;
            },
            [topRef]
        ),
    };
}
