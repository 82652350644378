import React from 'react';
import {DefaultKoppelbaarItem} from '@growthbase/spa';
import {Loader} from '@growthbase/design-components';
import {LandingsPaginaBord} from './LandingsPaginaBord';
import {useLegacyRedirectToIndex} from './Hook/useLegacyRedirectToIndex';
import {BordenOnderdeelVan} from '../Overview';

/* eslint-disable react/no-unstable-nested-components */

export function LandingsPaginaBordConnected() {
    const onderdeelVan = DefaultKoppelbaarItem.useValue() as BordenOnderdeelVan | null;
    const loading = useLegacyRedirectToIndex({
        route: onderdeelVan?.route,
    });
    if (loading) {
        return <Loader center />;
    }
    return (
        <>
            <LandingsPaginaBord />
        </>
    );
}
