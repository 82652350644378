import {WatchQueryOptions} from '@apollo/client/core/watchQueryOptions';
import {ResultData} from '@growthbase/graphql';
import {idFromIri} from '@growthbase/routing';
import {findIndex} from 'lodash';
import {useCallbackRef, useLogger} from '@growthbase/spa';
import {BaseNode} from '../baseNode';

/* eslint-disable no-shadow */

export enum PositieType {
    'before' = 'before',
    'after' = 'after',
    'alphabetically' = 'alphabetically',
}

export type UpdateQuery<TNode extends BaseNode, TVariables> = (
    mapFn: (
        previousQueryResult: ResultData<TNode> | null,
        options: Pick<WatchQueryOptions<TVariables, ResultData<TNode> | null>, 'variables'>
    ) => ResultData<TNode>
) => void;

export interface AddToQueryOptions<TNode extends BaseNode> {
    /**
     * If there is a next page, we won't append the item.
     */
    appendItemFromNextPage?: boolean;

    edgeTypeName?: string;
    nodeTypeName?: string;

    placeholderPosition?: boolean;

    orderBy?: keyof TNode;
}

export const useAddToQueryCache = <TNode extends BaseNode, TVariables>(
    updateQuery: UpdateQuery<TNode, TVariables>,
    {
        appendItemFromNextPage = false,
        edgeTypeName,
        nodeTypeName,
        placeholderPosition = false,
        orderBy = 'id',
    }: AddToQueryOptions<TNode> = {}
) => {
    const logger = useLogger('useAddToQueryCache');
    return useCallbackRef((node: TNode, positie: PositieType, targetCacheId?: string) => {
        updateQuery((cached): ResultData<TNode> => {
            const edgeData = {
                __typename: edgeTypeName,
                placeholderPosition,
            };

            const typed: ResultData<TNode> = cached ?? {
                items: {
                    totalCount: 0,
                    pageInfo: {
                        hasNextPage: false,
                        hasPreviousPage: false,
                        endCursor: null,
                        startCursor: null,
                    },
                    edges: [],
                },
            };
            const edges = (typed?.items?.edges ?? []) as Array<{
                node: {
                    id: string;
                    __typename?: string;
                } & TNode;
                __typename?: string;
            }>;
            const pageInfo = typed?.items?.pageInfo;
            if (!pageInfo) {
                return {
                    ...(cached ?? {}),
                    items: {
                        totalCount: 1,
                        pageInfo: {
                            hasNextPage: false,
                            hasPreviousPage: false,
                            endCursor: null,
                            startCursor: null,
                        },
                        edges: [
                            {
                                node: {
                                    __typename: nodeTypeName,
                                    ...node,
                                },
                                ...edgeData,
                            },
                        ],
                    },
                } as ResultData<TNode>;
            }
            const id = idFromIri(node.id);
            const found = findIndex(edges, (edge) => idFromIri(edge.node.id) === id);
            if (found !== -1) {
                logger.info(`Node "${node.id}" already in list of document.`);
                return typed;
            }

            let targetPosition: number;

            if (targetCacheId) {
                targetPosition = findIndex(edges, (edge) => idFromIri(edge.node.id) === idFromIri(targetCacheId));
                if (targetPosition === -1) {
                    logger.warn(`Missing target "${targetCacheId}" for AddToQuery.`);
                    return typed;
                }
                if (positie === PositieType.after) {
                    targetPosition += 1;
                }
            } else {
                switch (positie) {
                    case PositieType.alphabetically:
                        if (!orderBy || orderBy === 'id') {
                            logger.warn(`Missing orderBy "${targetCacheId}" for AddToQuery.`);
                            return typed;
                        }
                        targetPosition = edges.findIndex(({node: n}) => node[orderBy] < n[orderBy]);
                        break;
                    case PositieType.before:
                        targetPosition = 0;
                        break;
                    case PositieType.after:
                        if (typed.items?.pageInfo?.hasNextPage && !appendItemFromNextPage) {
                            logger.warn(
                                `Ignoring because item is on next page for target "${targetCacheId}" AddToQuery.`
                            );
                            return typed;
                        }
                        targetPosition = edges.length;
                        break;
                    default:
                        throw new Error(`Unknown positie "${positie}"`);
                }
            }
            const addedList = [...edges];
            addedList.splice(targetPosition, 0, {
                node: {
                    __typename: nodeTypeName,
                    ...node,
                },
                ...edgeData,
            });
            return {
                ...typed,
                items: {
                    ...typed?.items,
                    pageInfo,
                    totalCount: (typed?.items?.totalCount ?? 0) + 1,
                    edges: addedList,
                },
            } as ResultData<TNode>;
        });
    });
};
