import React, {ReactNode, FC} from 'react';

import classNames from 'classnames/bind';
import styles from './Comment.module.scss';
import {Avatar} from '../../Avatar';
import {RelativeTime} from '../../RelativeTime/RelativeTime';

const cx = classNames.bind(styles);

export interface CommentProps {
    name: string;
    avatarUrl?: string | null;
    date?: Date;
    children: ReactNode;
    menuContent?: ReactNode;
}

export const Comment: FC<CommentProps> = ({name, avatarUrl, date, children, menuContent}) => {
    const classes = cx('comment');
    return (
        <div className={classes} role="row" data-testid="Comment">
            <div className={cx('comment__avatar')}>
                <Avatar naam={name} imageUrl={avatarUrl} size="bigger" />
            </div>
            <div className={cx('comment__body')}>
                <div className={cx('comment__name')} data-testid="CommentName">
                    {name}
                </div>
                {date && (
                    <div className={cx('comment__date')} data-testid="CommentDate">
                        <RelativeTime time={date} />
                    </div>
                )}
                <div className={cx('comment__text')} data-testid="CommentText">
                    {children}
                </div>
            </div>
            {menuContent && <div className={cx('comment__menu')}>{menuContent}</div>}
        </div>
    );
};
