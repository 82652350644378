import {
    AddBreadcrumb,
    Loader,
    SearchForm,
    Stack,
    useGebruikerHeeftToegang,
    useSearchField,
} from '@growthbase/design-components';
import {IBordViewLijstFragment, IStandaardWeergaveLijsten, useBordViewQuery} from '@growthbase/graphql';
import {idFromIri, usePaginationSearchParams} from '@growthbase/routing';
import {
    DeelnemersFilterConnected,
    OverzichtSelect,
    useDeelnemersFilterState,
    useOverzichtSelectState,
} from '@growthbase/taken';
import {OverviewConnected, ViewSelect, TaakFilters, useViewSelectState} from '@growthbase/takenlijst-lijst';
import {usePollInterval} from '@growthbase/websockets';
import React, {useMemo, FC} from 'react';
import {useTranslation} from 'react-i18next';
import {DefaultKoppelbaarItem} from '@growthbase/spa';
import {useAddLogbookItems} from '@growthbase/activiteiten';
import {View} from './View';
import {useDrop} from './Hook/useDrop';
import {useEntityChanges} from './Hook/useLijstEntityChanges';
import {BordRoutes} from '../BordRoutes';
import {BordSelectConnected} from '../BordSelect';
import {ActionsBar, GeenItemsAanwezig} from './Components';
import {BordenOnderdeelVan} from '../Overview';

export interface ViewConnectedProps {
    bordId: string;
    showBreadcrumb?: boolean;
}

export const ViewConnected: FC<ViewConnectedProps> = ({showBreadcrumb = true, bordId}) => {
    const {t} = useTranslation('TakenlijstBord.nl');

    const {pagination, usePagination} = usePaginationSearchParams<IBordViewLijstFragment>('lijsten');
    const result = useBordViewQuery({
        pollInterval: usePollInterval(),
        variables: {
            ...pagination,
            bordId: idFromIri(bordId),
            id: idFromIri(bordId),
        },
    });
    useEntityChanges({bordId, result});

    const [
        overzicht,
        setOverzicht,
    ] = useOverzichtSelectState();
    const [
        deelnemers,
        setDeelnemers,
    ] = useDeelnemersFilterState();
    const [
        searchQuery,
        setSearchQuery,
    ] = useSearchField('taak');
    const taakFilters = useMemo(
        (): TaakFilters => ({
            deelnemers,
            searchQuery: searchQuery.query,
            overviewType: overzicht,
        }),
        [
            deelnemers,
            searchQuery,
            overzicht,
        ]
    );

    const gebruikerWeergaveInstellingen = result?.data?.ingelogdeGebruiker?.gebruikerWeergaveInstellingen ?? {
        standaardWeergaveLijsten: IStandaardWeergaveLijsten.LaatstGebruiktWeergeven,
        laatstGebruikteWeergaveLijsten: true,
    };

    const [
        view,
        changeView,
    ] = useViewSelectState({
        bordId,
        ...gebruikerWeergaveInstellingen,
    });

    const takenBord = result?.data?.takenBord;
    const userData = useMemo(() => (takenBord ? {id: bordId, titel: takenBord.naam} : null), [bordId, takenBord]);
    const pager = usePagination(result);
    const onDrop = useDrop(result);
    const bordUrl = BordRoutes.bewerken.useUrl({bordId});

    const defaultItem = DefaultKoppelbaarItem.useValue() as BordenOnderdeelVan | null;

    useAddLogbookItems(useMemo(() => pager.connections.map((c) => c.id), [pager.connections]));

    useGebruikerHeeftToegang({
        toegang: !!result?.data?.takenBord,
        enabled: !result.loading,
    });

    if (!takenBord || !userData) {
        return <Loader center />;
    }

    return (
        <>
            {showBreadcrumb && (
                <AddBreadcrumb order={10} to={bordUrl}>
                    {t('breadcrumb.bordnaam', {bordnaam: takenBord?.naam ?? ''})}
                </AddBreadcrumb>
            )}
            <View
                header={
                    <Stack gap="tiny" horizontal>
                        <Stack.Item>
                            <SearchForm
                                key="search"
                                disableAutofocus
                                loading={result.loading}
                                onChange={setSearchQuery}
                                value={searchQuery}
                            />
                        </Stack.Item>
                        {defaultItem && (
                            <Stack.Item size={150}>
                                <BordSelectConnected key="borden" onderdeelVan={defaultItem} />
                            </Stack.Item>
                        )}
                        <Stack.Item pushItems="before">
                            <ViewSelect key="view" value={view} onChange={changeView} />
                        </Stack.Item>
                        <Stack.Item>
                            <OverzichtSelect key="overzicht" value={overzicht} onChange={setOverzicht} />
                        </Stack.Item>
                        <Stack.Item>
                            <DeelnemersFilterConnected
                                key="deelnemers"
                                deelnemers={deelnemers}
                                setDeelnemers={setDeelnemers}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <ActionsBar fragment={takenBord} />
                        </Stack.Item>
                    </Stack>
                }
            >
                {pager.totalCount === 0 && !pager.isLoading ? (
                    <GeenItemsAanwezig bordId={bordId} />
                ) : (
                    <OverviewConnected
                        userData={userData}
                        pagination={pager}
                        onDrop={onDrop}
                        filters={taakFilters}
                        view={view}
                        readonly={false}
                        disabled={false}
                    />
                )}
            </View>
        </>
    );
};
