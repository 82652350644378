import {IPlanningSideBarConnectedDienstFragment} from '@growthbase/planning';
import {useMemo} from 'react';

export const useDienstenNormalizer = () =>
    useMemo(
        () => (diensten: Array<IPlanningSideBarConnectedDienstFragment | null> | null | undefined) => {
            if (!diensten) {
                return [];
            }

            return diensten.map((dienstEdge) => ({
                id: dienstEdge?.id ?? '',
                titel: dienstEdge?.titel ?? '',
            }));
        },
        []
    );
