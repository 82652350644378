import {TFunction} from 'i18next';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {InferType, SchemaOf} from 'yup';

export const LijsttemplateAlsLijstToevoegenFormSchema = (t: TFunction) =>
    yup
        .object({
            lijsttemplate: yup.string().required(),
        })
        .required();

export type LijsttemplateAlsLijstToevoegenFormSchemaDto = InferType<
    ReturnType<typeof LijsttemplateAlsLijstToevoegenFormSchema>
>;

export const createFormValue = (): LijsttemplateAlsLijstToevoegenFormSchemaDto => ({
    lijsttemplate: '',
});

export const useLijsttemplateAlsLijstToevoegenFormSchema =
    (): SchemaOf<LijsttemplateAlsLijstToevoegenFormSchemaDto> => {
        const {t} = useTranslation();
        return useMemo(() => LijsttemplateAlsLijstToevoegenFormSchema(t), [t]);
    };
