import {FC, PropsWithChildren, ReactNode} from 'react';
import {useSuccessiveCallbacks} from '@growthbase/spa';
import {useMemoryPopupControls} from '../Hook';
import {PopupControlsContext} from '../PopupControls';
import {BaseButtonProps} from '../../Button';

export interface ModalFormButtonFormProps<T> {
    onSubmit: (values: T) => Promise<void>;
}

export interface ModalFormButtonProps<T> extends PropsWithChildren {
    Button: FC<BaseButtonProps>;
    onSubmit: (values: T) => Promise<void>;
    renderForm: (props: ModalFormButtonFormProps<T>) => ReactNode;
}

export function ModalFormButton<T>({Button, onSubmit, renderForm, children}: ModalFormButtonProps<T>) {
    const controls = useMemoryPopupControls(false);
    const onSubmitAndClose = useSuccessiveCallbacks(onSubmit, controls.close);
    return (
        <PopupControlsContext.Provider value={controls}>
            <Button onClick={controls.open}>{children}</Button>
            {controls.isOpen
                ? renderForm({
                      onSubmit: onSubmitAndClose,
                  })
                : null}
        </PopupControlsContext.Provider>
    );
}
