import {DragOverEvent} from '@dnd-kit/core';
import {useCallback} from 'react';
import {useLogger} from '@growthbase/spa';
import {useOnDragOver} from '../DNDReducer';
import {DNDContainerId, DNDData} from '../Value';

export const useOnDragOverEvent = () => {
    const dragOver = useOnDragOver();
    const logger = useLogger('useOnDragOverEvent');
    return useCallback(
        (event: DragOverEvent): void => {
            const {over, delta} = event;
            try {
                const data = (over?.data?.current ?? null) as DNDData | undefined;
                if (!data) {
                    dragOver({
                        overId: null,
                        overContainerId: null,
                        position: null,
                    });
                    return;
                }
                const position = delta.y < 0 ? 'before' : 'after';
                switch (data.type) {
                    case 'item': {
                        const parentContainerId = (over?.data?.current?.sortable?.containerId ??
                            null) as DNDContainerId | null;
                        if (parentContainerId === null) {
                            logger.error('Drag over Error: Parent container is not found', 'Event:', event);
                            return;
                        }
                        dragOver({
                            overId: data.id,
                            overContainerId: parentContainerId,
                            position,
                        });
                        break;
                    }
                    case 'container':
                        dragOver({
                            overId: null,
                            overContainerId: data.id,
                            position,
                        });
                        break;
                    default:
                        logger.error('Drag over Error: Unknown type', 'Event:', event);
                }
            } catch (e) {
                logger.error('Drag over Error:', e, 'Event:', event);
            }
        },
        [dragOver, logger]
    );
};
