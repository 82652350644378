import React, {FC} from 'react';
import {ActionsMenu, ActionsMenuButton} from '@growthbase/design-components';
import {ReactiesTwigConnectorTranslationsDto} from '../Reacties/ReactiesTwigInputSchema';

export interface ReactieMenuProps {
    deleteUrl?: string;
    editUrl?: string;
    translations: ReactiesTwigConnectorTranslationsDto;
}

export const ReactieMenu: FC<ReactieMenuProps> = ({deleteUrl, editUrl, translations}) => (
    <ActionsMenu visible>
        {editUrl && (
            <ActionsMenuButton
                data-modal-size="modal-md"
                data-action={editUrl}
                data-modal-title={translations.edit_title}
                className="app-edit-button"
            >
                {translations.edit}
            </ActionsMenuButton>
        )}
        {deleteUrl && (
            <ActionsMenuButton
                data-modal-size="modal-md"
                data-action={deleteUrl}
                data-modal-title={translations.delete_title}
                data-text={translations.delete_text}
                className="app-delete-button"
            >
                {translations.delete}
            </ActionsMenuButton>
        )}
    </ActionsMenu>
);
