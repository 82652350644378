import {FC, InputHTMLAttributes, useCallback} from 'react';
import classNames from 'classnames/bind';
import ReactSimpleTimeField from 'react-simple-timefield';
import styles from './TimeField.module.scss';

const cx = classNames.bind(styles);

/**
 * Value in the format of hh:mm
 */
export interface TimeFieldProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    disableRoundedCorners?: boolean;
    value: string;
    disabled?: boolean;
    onChange?: (value: string) => void;
}

export const TimeField: FC<TimeFieldProps> = ({disableRoundedCorners, value, disabled, onChange, ...props}) => {
    const classes = cx('timeField', {
        'timeField--disableRoundedCorners': disableRoundedCorners,
        'timeField--hideValue': !value,
    });

    const handleChange = useCallback(
        (_: unknown, changedValue: string | undefined) => {
            if (!changedValue || !onChange) {
                return;
            }
            onChange(changedValue);
        },
        [onChange]
    );

    return (
        <div className={classes}>
            <ReactSimpleTimeField
                input={<input disabled={disabled} {...props} />}
                style={{width: '100%'}}
                value={value ?? ''}
                onChange={handleChange}
            />
        </div>
    );
};
