import {ReactNode} from 'react';
import classNames from 'classnames/bind';
import styles from './Info.module.scss';
import {TextColorMode} from '../../CalendarEvent';

const cx = classNames.bind(styles);

export interface InfoProps {
    children: ReactNode;
    colorMode: TextColorMode;
}

export const Info = ({children, colorMode}: InfoProps) => {
    const className = cx('info', {
        'info--light': colorMode === TextColorMode.LIGHT,
        'info--dark': colorMode === TextColorMode.DARK,
    });

    return <div className={className}>{children}</div>;
};
