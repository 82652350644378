import {AuthorizerCallback, Channel} from 'pusher-js/with-encryption';

export class PusherAuthorizer {
    constructor(private authUrl: string, private channel: Channel) {}

    public authorize(socketId: string, callback: AuthorizerCallback): void {
        fetch(this.authUrl, {
            method: 'POST',
            credentials: 'include',
            headers: new Headers({'Content-Type': 'application/json'}),
            body: JSON.stringify({
                socket_id: socketId,
                channel_name: this.channel.name,
            }),
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`Received ${res.status} from ${this.authUrl}`);
                }
                return res.json();
            })
            .then((data) => {
                callback(null, data);
            })
            .catch((err) => {
                callback(new Error(`Error calling auth endpoint: ${err}`), null);
            });
    }
}
