import {ReactiesTwigConnectorTranslationsDto} from '../ReactiesTwigInputSchema';

/**
 * Return button text based on result count and loading state
 */
export const buttonText = (
    loading: boolean,
    showing: boolean,
    translations: ReactiesTwigConnectorTranslationsDto
): string => {
    if (loading) {
        return translations.loading;
    }

    if (showing) {
        return `${translations.react} -`;
    }

    return `${translations.react} +`;
};
