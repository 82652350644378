import {FC, memo} from 'react';
import * as svgs from './svg';
import {Icon, IconProps} from '../Icon/Icon';

const createIcon = (name: string, SVG: FC): FC<IconProps> => {
    const Created = memo((props: IconProps) => (
        <Icon {...props}>
            <SVG />
        </Icon>
    ));
    Created.displayName = `${name}Icon`;
    return Created;
};

export type SVGTypes = typeof svgs;

export type IconComponents = {
    [TName in keyof SVGTypes as `${Capitalize<TName & string>}`]: FC<IconProps>;
};

export const Icons = Object.fromEntries(
    Object.entries(svgs).map(([name, svg]) => [name, createIcon(name, svg)])
) as IconComponents;
