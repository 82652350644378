import {ButtonGroup, TableHeader, TableHeaderSection} from '@growthbase/design-components';
import {RenderTaakTabelType} from '@growthbase/taken';
import {FC, ReactNode} from 'react';

export interface TaakTabelLijstProps {
    titel: string;
    renderTabel: RenderTaakTabelType;
    menu: ReactNode;
}

export const TaakTabelLijst: FC<TaakTabelLijstProps> = ({renderTabel, titel, menu}) => (
    <>
        {renderTabel({
            header: (
                <TableHeader>
                    <TableHeaderSection testId={titel}>{titel}</TableHeaderSection>
                    <TableHeaderSection>
                        <ButtonGroup disableRightMargin>{menu}</ButtonGroup>
                    </TableHeaderSection>
                </TableHeader>
            ),
            filters: false,
        })}
    </>
);
