import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {InstellingenKopieerForm} from '@growthbase/instellingen';
import {useBordKopierenOpties} from './Hook/useBordKopierenOpties';

export interface BordtemplateKopierenFormValues {
    naam: string;
    activiteiten: boolean;
    taken: boolean;
}

export interface BordKopierenFormProps {
    titel: string;
    onSubmit: (values: BordtemplateKopierenFormValues) => Promise<void>;
}

export const KopieerForm: FC<BordKopierenFormProps> = ({onSubmit, titel}) => {
    const {t} = useTranslation('Bordtemplate.nl');
    return (
        <InstellingenKopieerForm<BordtemplateKopierenFormValues>
            titel={t('Kopieren.copyBordtemplate')}
            fields={useBordKopierenOpties(titel)}
            onSubmit={onSubmit}
        />
    );
};
