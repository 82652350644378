import {createOpgemaakteTekstEditorFragment} from '@growthbase/design-components';
import {IActiviteitenListFragment} from '@growthbase/graphql';
import {usePaginationFixedResult} from '@growthbase/spa';
import {faker} from '@faker-js/faker';
import {ActiviteitenList} from './ActiviteitenList';

faker.seed(5);

export const listStub: IActiviteitenListFragment[] = [
    {
        type: 'Taakactiviteit',
        id: 'id1',
        targetId: '',
        description: {
            interpolated: 'hi!!',
        },
        gebruiker: {
            id: '1',
            naam: 'John Smith',
            imageUrl: faker.image.avatar(),
        },
        at: new Date().toISOString(),
    },
    {
        type: 'Taakactiviteit',
        id: 'id2',
        targetId: '',
        description: {
            interpolated: 'Bye!!',
        },
        gebruiker: {
            id: '2',
            naam: 'John Smith',
            imageUrl: faker.image.avatar(),
        },
        at: new Date().toISOString(),
    },
    {
        type: 'TaakReactieactiviteit',
        id: 'id3',
        targetId: '',
        description: {
            interpolated: 'heeft een reactie bewerkt',
        },
        gebruiker: {
            id: '2',
            naam: 'John Smith',
            imageUrl: 'https://i.pravatar.cc/150?u=2',
        },
        at: new Date().toISOString(),
        nieuweTekst: createOpgemaakteTekstEditorFragment(),
        vorigeTekst: createOpgemaakteTekstEditorFragment(),
    },

    {
        type: 'TaakReactieactiviteit',
        id: 'id4',
        targetId: '',
        description: {
            interpolated: 'heeft een reactie toegevoegd',
        },
        gebruiker: {
            id: '2',
            naam: 'John Smith',
            imageUrl: faker.image.avatar(),
        },
        at: new Date().toISOString(),
        nieuweTekst: createOpgemaakteTekstEditorFragment(),
        vorigeTekst: createOpgemaakteTekstEditorFragment(),
    },
];

export function ActiviteitenListStub() {
    return <ActiviteitenList helpers={usePaginationFixedResult<IActiviteitenListFragment>(listStub)} />;
}
