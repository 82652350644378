import {KeysProvider, Keys} from '@growthbase/keys';
import {FC, ReactNode} from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames/bind';
import {ScrollContext, useUniqueClassname} from '../../PaginationInfiniteScroll';
import styles from './Modal.module.scss';
import './Modal.scss';
import {usePopup} from '../../Popups';

/* eslint-disable no-shadow */

export enum ModalSize {
    SIZE_L,
    SIZE_XL,
    SIZE_XXL,
}
export interface ModalProps {
    testId?: string;
    children: ReactNode;
    size?: ModalSize;
    autoHeight?: boolean;
    noBackground?: boolean;
    expanded?: boolean;
}

const cx = classNames.bind(styles);

export const Modal: FC<ModalProps> = ({children, testId, noBackground, size, expanded, autoHeight = true}) => {
    const classes = cx('modal', {
        'modal--autoHeight': autoHeight,
        'modal--sizeL': size === ModalSize.SIZE_L,
        'modal--sizeXL': size === ModalSize.SIZE_XL,
        'modal--sizeXXL': size === ModalSize.SIZE_XXL,
        'modal--noBackground': noBackground,
        'modal--expanded': expanded,
    });
    const {isOpen, close} = usePopup();
    const id = useUniqueClassname();
    return (
        <ReactModal
            testId={testId}
            ariaHideApp={false}
            isOpen={isOpen}
            onRequestClose={close}
            shouldCloseOnEsc={false}
            className={classes}
            overlayClassName={cx('modal__overlay', id)}
        >
            <KeysProvider name={testId ?? 'Popup'}>
                <ScrollContext.Provider value={id}>
                    <Keys debug="Model: close" keys="escape" callback={close} />
                    <div className={cx('modal__content')}>{children}</div>
                </ScrollContext.Provider>
            </KeysProvider>
        </ReactModal>
    );
};
