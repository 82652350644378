import {useCallback, FC, ReactNode} from 'react';
import {useTabQueryValue} from '../Hook/useTabQueryValue';
import {TabQueryInterface} from '../TabInterface';

export interface QueryTabLinkProps {
    tab: TabQueryInterface;
    children?: ReactNode;
}

export const QueryTabLink: FC<QueryTabLinkProps> = ({tab, children}) => {
    const {value} = tab;
    const [, setValue] = useTabQueryValue();
    const goTo = useCallback(() => {
        setValue(value);
    }, [value, setValue]);

    return (
        <div role="tab" tabIndex={0} onClick={goTo}>
            {children}
        </div>
    );
};
