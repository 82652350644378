import {usePaginationFixedResult} from '@growthbase/spa';
import React, {FC, useMemo} from 'react';
import {
    IStatisticsFiltersInput,
    IStatisticsTableCountFragment,
    IStatisticsTableTimingFragment,
    IStatisticsType,
    useStatisticsTableQuery,
} from '@growthbase/graphql';
import {Stack} from '@growthbase/design-components';
import {LineChart} from '@mui/x-charts';
import {useStatisticsTableConvert} from './Hook';
import {StatisticsTable} from './StatisticsTable';
import {useChartData} from './Hook/useChartData';

export interface StatisticsTableConnectedProps {
    actief?: boolean;
    projectionId?: string;
    profielId?: string;
    processId?: number;
    tag?: string;
}

export const StatisticsTableConnected: FC<StatisticsTableConnectedProps> = ({
    tag,
    actief,
    processId,
    projectionId,
    profielId,
}) => {
    const filters: IStatisticsFiltersInput = {
        tag,
        actief,
        processId,
        projectionId,
        profielId,
        pagination: {
            last: 200,
        },
    };

    const query = useStatisticsTableQuery({
        variables: {
            filters,
            filtersDuration: {
                type: IStatisticsType.TimingDuration,
                ...filters,
            },
            filtersIncrements: {
                type: IStatisticsType.Increment,
                ...filters,
            },
        },
        pollInterval: 2000,
    });
    const {data} = query;
    const pagination = usePaginationFixedResult<IStatisticsTableTimingFragment | IStatisticsTableCountFragment>(
        useMemo(() => {
            if (!data) {
                return [];
            }
            return [...data.projectionTimingStatistics, ...data.projectionCountStatistics];
        }, [data])
    );
    const converted = useStatisticsTableConvert(pagination);

    return (
        <Stack>
            <Stack horizontal>
                <LineChart {...useChartData(data?.projectionStatisticIncrements)} width={1000} height={600} />
                <LineChart {...useChartData(data?.projectionStatisticDuration)} width={1000} height={600} />
            </Stack>
            <StatisticsTable pagination={converted} />
        </Stack>
    );
};
