import React, {FC, MouseEventHandler, ReactNode} from 'react';
import {DropEvent, FileRejection, useDropzone} from 'react-dropzone';
import {CSSProperties} from 'styled-components';
import {useHashedMemorize} from '@growthbase/spa';
import styles from './DropZone.module.scss';

export interface DropZoneChildren {
    openInput?: MouseEventHandler<HTMLElement>;
}

export interface DropZoneProps {
    children: (props: DropZoneChildren) => ReactNode;
    dropText: ReactNode;
    onDrop?: (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => void;
}

export const DropZone: FC<DropZoneProps> = ({children, dropText, onDrop}) => {
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});
    const {onClick, role, tabIndex, onDragEnter, style, ...rest} = getRootProps();

    const memorizedStyle = useHashedMemorize({flexGrow: 1, position: 'relative', ...(style ?? {})});

    return (
        <div style={memorizedStyle as CSSProperties} onDragEnter={onDragEnter} {...(isDragActive ? rest : {})}>
            <input {...getInputProps()} />
            {children({openInput: onClick})}
            {isDragActive && (
                <div className={styles.DropZone}>
                    <div className={styles.DropZoneText}>{dropText}</div>
                </div>
            )}
        </div>
    );
};
