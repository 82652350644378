import {ReactNode, useCallback, FC} from 'react';
import {PopupHeader, PopupHeaderProps} from '../../Popups';
import {Modal, ModalSize, ModalContent} from '../Modal';
import {Tabs, TabsProps, TabsRendererProps} from '../../Tabs';

export interface TabsModalProps extends Omit<TabsProps, 'render'> {
    PopupHeader?: FC<PopupHeaderProps>;
    renderPopupHeader?: (props: PopupHeaderProps) => ReactNode;
    popupheader?: ReactNode;
    testId?: string;
}

/**
 * Utility component combining a popup with tabs.
 */
export const TabsModal: FC<TabsModalProps> = ({
    testId,
    PopupHeader: DefaultPopupHeader = PopupHeader,
    renderPopupHeader,
    popupheader,
    ...props
}) => {
    const PopupTabsRenderer: FC<TabsRendererProps> = useCallback(
        ({content, header}) => (
            <Modal testId={testId} size={ModalSize.SIZE_L}>
                {popupheader ??
                    (renderPopupHeader ? (
                        renderPopupHeader({children: header})
                    ) : (
                        <DefaultPopupHeader>{header}</DefaultPopupHeader>
                    ))}
                <ModalContent>{content}</ModalContent>
            </Modal>
        ),
        [testId, popupheader, renderPopupHeader, DefaultPopupHeader]
    );

    return <Tabs {...props} sizeSmall render={PopupTabsRenderer} />;
};
