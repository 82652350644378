import {FormikProps} from 'formik';
import React, {PropsWithChildren} from 'react';

interface Props<Values> extends PropsWithChildren {
    form: FormikProps<Values>;
    className?: string;
}

export function NestedForm<Values>({children, className, form}: Props<Values>) {
    return (
        <div className={className} role="form">
            {children}
        </div>
    );
}
