import {FC} from 'react';
import {useGebruikerHeeftToegang} from '@growthbase/design-components';
import {useCreateBordFormPermissionsQuery, useCreateTemplateBordFormMutation} from '@growthbase/graphql';
import {DefaultKoppelbaarItem, useCallbackRef} from '@growthbase/spa';
import {BordtemplateRoutes} from '@growthbase/takentemplates-bordtemplate';
import {AbonnementTemplateRoutes} from '@growthbase/abonnementen-takentemplates';
import {CreateForm} from './CreateForm';
import {mapDTOToGraphQL} from './mapDTOToGraphQL';
import {CreateFormDTO} from './Hook/useCreateSchema';

export const CreateFormConnected: FC = () => {
    const [mutateAsync] = useCreateTemplateBordFormMutation();
    const {data} = useCreateBordFormPermissionsQuery();
    const defaultItem = DefaultKoppelbaarItem.useValue();
    const abonnementId = defaultItem?.type === 'Abonnement' ? defaultItem.id : null;

    const view = BordtemplateRoutes.bekijken.useNavigateFactory();
    const goAbonnementen = AbonnementTemplateRoutes.view.useNavigateFactory();
    const onSubmit = useCallbackRef((value: CreateFormDTO) =>
        mutateAsync({
            variables: {
                input: {
                    abonnementId,
                    ...mapDTOToGraphQL(value),
                },
            },
        })
            .then((mutation) => {
                const createdBord = mutation?.data;
                const bordtemplateId = createdBord?.MaakBordtemplate?.bordtemplate?.id;
                if (bordtemplateId) {
                    if (abonnementId) {
                        goAbonnementen({
                            abonnementUuid: abonnementId,
                            bordtemplateId,
                        });
                        return Promise.resolve();
                    }
                    view({bordtemplateId});
                    return Promise.resolve();
                }
                return Promise.reject();
            })
            .then(() => undefined)
    );

    const toegang = useGebruikerHeeftToegang({
        toegang: data?.ingelogdeGebruiker?.availableActions?.kanBordAanmaken,
    });

    if (!data || !toegang) {
        return null;
    }

    return <CreateForm onSubmit={onSubmit} />;
};
