import {isEqual} from 'lodash';
import {SchemaOf} from 'yup';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useContext, useMemo} from 'react';
import {useCallbackRef, useLogger} from '@growthbase/spa';
import {BackgroundRouteContext} from '../BackgroundRouteContext';
import {BACKGROUND_ROUTE_KEY} from '../Schema/backgroundRouteSchema';
import {createURL, relativeURL} from '../Util';
import {useCurrentSearchParams} from './useCurrentSearchParams';

const ARRAY_SEPARATOR = ':';

interface StateFromSearch<T> {
    normalized: T;
    raw: Record<string, string>;
    other: Record<string, string>;
}

const useStateFromSearchParams = <T extends object>(
    pre: string,
    schema: SchemaOf<T>,
    defaults?: T
): StateFromSearch<T> => {
    const logger = useLogger('useStateFromSearchParams');
    const searchParams = useCurrentSearchParams();
    return useMemo(() => {
        const params: Record<string, unknown> = {};
        const other: Record<string, string> = {};
        const raw: Record<string, string> = {};

        if (defaults) {
            Object.assign(params, defaults);
        }
        searchParams.forEach((value, key) => {
            if (key.indexOf(pre) !== 0) {
                other[key] = value;
                return;
            }
            raw[key] = value;
            params[key.replace(pre, '')] = value;
        });

        for (const key in params) {
            if (key in params) {
                const value = params[key];
                if (key.indexOf(ARRAY_SEPARATOR) !== -1) {
                    const [k, index] = key.split(ARRAY_SEPARATOR);
                    if (!(params[k] instanceof Array)) {
                        params[k] = [];
                    }
                    // @ts-expect-error The array is created above.
                    params[k][parseInt(index, 10)] = value;
                }
            }
        }

        try {
            const normalized = schema.cast(params) as T;
            return {
                normalized,
                raw,
                other,
            };
        } catch (e) {
            logger.warn(e);
            return {
                normalized: defaults || ({} as T),
                raw,
                other,
            };
        }
    }, [schema, searchParams, defaults, pre, logger]);
};

export const useIsBackgroundRoute = () => !!useContext(BackgroundRouteContext);

export const useTypedSearchParamsUrlFactory = <T extends object>(
    prefix: string,
    schema: SchemaOf<T>,
    generateUrl: () => string,
    defaults?: T,
    includeDefaults = false
): [(value: T) => string, (value: T) => void] => {
    const navigate = useNavigate();

    const pre = `${prefix}_`;
    const createUrl = useCallbackRef((value: T): string => {
        const params: Record<string, string> = {};

        Object.entries(value).forEach(([key, v]) => {
            // No point in showing "?foobar=undefined" or "?foobar=null".
            if (v === undefined || v === null) {
                return;
            }
            // Exclude defaults
            if (!includeDefaults && defaults && (defaults as Record<string, string>)[key] === v) {
                return;
            }
            if (v instanceof Array) {
                v.forEach((c, i) => {
                    params[`${pre + key}${ARRAY_SEPARATOR}${i}`] = `${c}`;
                });
            } else {
                params[pre + key] = `${v}`;
            }
        });
        const url = createURL(generateUrl());
        Object.entries(params).forEach(([key, val]) => {
            url.searchParams.set(key, val);
        });
        return relativeURL(url);
    });

    return [createUrl, useCallbackRef((value: T) => navigate(createUrl(value)))];
};

/**
 *
 *
 * When this is used inside the background, the background query is updated, not the actual document query.
 */
export const useTypedSearchParams = <T extends object>(
    prefix: string,
    schema: SchemaOf<T>,
    defaults?: T,
    includeDefaults = false
): [T, (value: T) => void] => {
    const pre = `${prefix}_`;
    const isBackgroundRoute = useIsBackgroundRoute();
    const [actual, setSearchParams] = useSearchParams();
    const {raw, other, normalized} = useStateFromSearchParams(pre, schema, defaults);

    const setNormalized = useCallbackRef((value: T): void => {
        const params: Record<string, string> = {};

        Object.entries(value).forEach(([key, v]) => {
            // No point in showing "?foobar=undefined" or "?foobar=null".
            if (v === undefined || v === null) {
                return;
            }
            // Exclude defaults
            if (!includeDefaults && defaults && (defaults as Record<string, string>)[key] === v) {
                return;
            }
            if (v instanceof Array) {
                v.forEach((c, i) => {
                    params[`${pre + key}${ARRAY_SEPARATOR}${i}`] = `${c}`;
                });
            } else {
                params[pre + key] = `${v}`;
            }
        });

        if (isEqual(raw, params)) {
            return;
        }

        const combined = {
            ...other,
            ...params,
        };
        if (!isBackgroundRoute) {
            setSearchParams(combined, {
                replace: true,
            });
            return;
        }

        const route = actual.get(BACKGROUND_ROUTE_KEY);
        if (!route) {
            return;
        }
        const url = createURL(route);
        Object.entries(value).forEach(([key, v]) => {
            url.searchParams.set(key, v);
        });
        const newBackground = relativeURL(url);
        if (route === newBackground) {
            return;
        }
        actual.set(BACKGROUND_ROUTE_KEY, newBackground);
        setSearchParams(actual, {
            replace: true,
        });
    });

    return [normalized as T, setNormalized];
};
