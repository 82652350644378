import {FC} from 'react';
import {useField} from 'formik';
import {TextFieldComponentNullableProps, TextFieldComponentProps} from '.';
import {TextFieldBig} from './TextFieldBig';

export const TextFieldBigComponent: FC<TextFieldComponentProps> = ({
    meta,
    field: {onChange, ...field},
    form,
    ...props
}) => {
    const [{value}, , {setValue}] = useField(field.name);
    return <TextFieldBig {...field} {...props} value={value ?? ''} onBlur={props.onBlur} onChange={setValue} />;
};

export const TextFieldBigComponentNullable: FC<TextFieldComponentNullableProps> = ({
    meta,
    field: {onChange, ...field},
    form,
    ...props
}) => {
    const [{value}, , {setValue}] = useField(field.name);
    return <TextFieldBig {...field} {...props} value={value ?? ''} onBlur={props.onBlur} onChange={setValue} />;
};
