import {AddBreadcrumb, Loader} from '@growthbase/design-components';
import React, {FC} from 'react';
import {ObjectInspector} from 'react-inspector';
import {useModelData} from './hook/useModelData';

type Model = {
    name: string;
    shortName: string;
    shared: boolean;
    distinct: boolean;
    abstraction: boolean;
    reposities: {
        repository: string;
        index?: string;
        mapping?: {
            mappings: unknown;
            settings: unknown;
        };
        isValid?: boolean;
    }[];
    factories: {factory: string}[];
    codeMapping: unknown;
};

export interface ModelPageProps {
    name: string;
}

export const ModelDetails: FC<ModelPageProps> = ({name}) => {
    const pagination = useModelData(name);
    if (pagination.connections.length === 0) {
        return <Loader center />;
    }
    const data = pagination.connections[0] as unknown as Model;
    return (
        <>
            <AddBreadcrumb>Model {data.shortName}</AddBreadcrumb>
            <h1>{data.name}</h1>
            <p>Short Name: {data.shortName}</p>
            <p>Shared between profiles: {data.shared ? 'Yes' : 'No'}</p>
            <p>Distinct per projection: {data.distinct ? 'Yes' : 'No'}</p>
            <p>Abstraction: {data.abstraction ? 'Yes' : 'No'}</p>

            <h2>Repositories</h2>
            <ul>
                {data.reposities.map((repo, index) => (
                    <li key={index}>
                        <p>Repository: {repo.repository}</p>
                        {repo.index && <p>Index: {repo.index}</p>}
                        {typeof repo.isValid === 'boolean' && (
                            <p>IsValid (Actual mapping matches code): {repo.isValid ? 'Yes' : 'No'}</p>
                        )}
                        {repo.mapping && (
                            <p>
                                Mapping: <ObjectInspector expandLevel={10} data={repo.mapping} />
                            </p>
                        )}
                    </li>
                ))}
            </ul>

            <h2>Factories</h2>
            <ul>
                {data.factories.map((factory, index) => (
                    <li key={index}>
                        <p>Factory: {factory.factory}</p>
                    </li>
                ))}
            </ul>

            <h2>Code Mapping</h2>
            <ObjectInspector expandLevel={10} data={data.codeMapping} />
        </>
    );
};
