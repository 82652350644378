import {MemoryPaginationHelpers} from '@growthbase/spa';
import {useCallback} from 'react';
import {placeItemFromPlacement} from '../utils';
import {DNDContainerUserData, DNDItemUserData, onDropItemHandler, onRemoveItemHandler} from '../Value';

export interface DragPaginationHelpers<
    TItemUserData extends DNDItemUserData,
    TContainerUserData extends DNDContainerUserData
> {
    onDrop: onDropItemHandler<TItemUserData, TContainerUserData>;
    onRemove: onRemoveItemHandler<TItemUserData>;
}

export const useDragPaginationHelpers = <
    TItemUserData extends DNDItemUserData,
    TContainerUserData extends DNDContainerUserData
>({
    setNodes,
    connections,
}: MemoryPaginationHelpers<TItemUserData>): DragPaginationHelpers<TItemUserData, TContainerUserData> => ({
    onDrop: useCallback(
        (event) => {
            setNodes(
                placeItemFromPlacement({
                    items: connections,
                    placement: event.placement,
                    item: event.item,
                })
            );
            return Promise.resolve();
        },
        [connections, setNodes]
    ),
    onRemove: useCallback(
        (item: TItemUserData) => {
            setNodes(connections.filter((i) => i.id !== item.id));
            return Promise.resolve();
        },
        [connections, setNodes]
    ),
});
