import {PlanningStoreState} from '@growthbase/planning';
import {useMemo} from 'react';
import {shallowEqual, useSelector} from 'react-redux';

export const useCalendarIdsToRetrieve = () => {
    const {agendas} = useSelector((state: PlanningStoreState) => state.planning, shallowEqual);

    return useMemo(
        () => agendas.flatMap((agenda) => agenda.items.filter((item) => item.enabled).map((item) => item.id)),
        [agendas]
    );
};
