import {ReactNode} from 'react';
import {CSSProperties} from 'styled-components';
import {capitalize} from 'lodash';
import {Spacing} from '../Spacing';
import {StackItem} from './StackItem';
import {StackDivider} from './components/StackDivider';

export interface StackProps {
    children: ReactNode;
    horizontal?: boolean;
    gap?: Spacing | number;
    wrap?: boolean;
    justify?: boolean;
    align?: 'start' | 'end' | 'center';
    stretch?: boolean;
    testId?: string;
    inline?: boolean;
    style?: CSSProperties;
}

const StackParent = ({
    children,
    horizontal = false,
    gap = 0,
    wrap,
    justify,
    align,
    stretch,
    testId,
    inline,
    style,
}: StackProps) => {
    style = {
        display: 'flex',
        flexWrap: wrap ? 'wrap' : 'nowrap',
        flexDirection: horizontal ? 'row' : 'column',
        justifyContent: justify ? 'space-between' : 'none',
        alignItems: align?.replace('start', 'flex-start').replace('end', 'flex-end'),
        gap: typeof gap === 'number' ? `${gap}px` : `var(--spacing${capitalize(gap)})`,
        height: stretch ? '100%' : undefined,
        maxWidth: '100%',
        ...style,
    };

    if (!horizontal && !inline) {
        style.height = '100%';
    } else if (!inline) {
        style.width = '100%';
    }

    return (
        <div style={style} data-direction={!horizontal ? 'vertical' : 'horizontal'} data-testid={testId}>
            {children}
        </div>
    );
};

export const Stack = Object.assign(StackParent, {
    Item: StackItem,
    Divider: StackDivider,
});
