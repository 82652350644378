import * as React from 'react';
import {FC} from 'react';
import {HealthInterface} from './Health';
import {CronHealthContent, SymfonyKernelContent} from './Components';
import {VersionsContent} from './Components/VersionContent';

export interface HealthContentProps {
    value: HealthInterface;
}

export const HealthContent: FC<HealthContentProps> = ({value}) => {
    if (value.name === 'crons') {
        return <CronHealthContent value={value} />;
    }
    if (value.name === 'SymfonyKernel') {
        // @ts-expect-error TODO: verify this
        return <SymfonyKernelContent value={value} />;
    }
    if (value.name === 'Growthbase' || value.name === 'Clientbox') {
        // @ts-expect-error TODO: verify this
        return <VersionsContent value={value} />;
    }
    return null;
};
