import {PaginationHelpers, useCallbackRef, usePaginationMapper} from '@growthbase/spa';
import {ILogsTableFragment} from '@growthbase/graphql';
import moment from 'moment';
import {ObjectInspector} from 'react-inspector';
import {Information, Stack, TextColorByHash} from '@growthbase/design-components';
import {DeveloperRoutes} from '@growthbase/health';
import {Link} from 'react-router-dom';
import React from 'react';
import {LogsTableDTO} from '../LogsTable';
import {FixEventButtonsConnected} from '../../FixEventButton/FixEventButtonConnected';
import {FixEventInput} from '../../FixEventButton/Components/FixEventForm';

export const dutchFormat = 'DD-MM-YYYY HH:mm:ss';

export const useLogsTableConverter = (pagination: PaginationHelpers<ILogsTableFragment>) => {
    const urlFromProjection = DeveloperRoutes.projections_detail.useUrlFactory();
    const urlFromProcess = DeveloperRoutes.process_detail.useUrlFactory();
    const urlFromProfiel = DeveloperRoutes.replay_profile_detail.useUrlFactory();
    return usePaginationMapper(
        pagination,
        useCallbackRef(
            ({
                message,
                tag,
                domainMessage,
                process: {
                    id: processId,
                    reason,
                    profiel: {profielId, name, projection},
                },
                level,
                timestamp,
                metadata,
                id,
            }: ILogsTableFragment): LogsTableDTO => {
                // interpolate message {value} form metadata
                const interpolatedMessage = metadata
                    ? message.replace(/{([^}]+)}/g, (match, value) => metadata[value] ?? match)
                    : message;

                const data: Record<string, unknown> = {};
                let event: FixEventInput | undefined;
                if (metadata) {
                    data.metadata = metadata;
                }
                if (domainMessage) {
                    data.domainMessage = domainMessage;
                    event = {
                        profielId: domainMessage.metadata?.profiel_id,
                        aggregate: domainMessage.metadata?.aggregateClass,
                        uuid: domainMessage.id,
                        playhead: domainMessage.playhead,
                        persist: true,
                        update: JSON.stringify(domainMessage.payload?.data, null, 2),
                    };
                }

                const projectionId = projection?.id;
                return {
                    id: id.toString(),
                    info: (
                        <Stack stretch>
                            <Stack.Item grow>
                                <Stack horizontal stretch>
                                    <Stack.Item grow>
                                        <TextColorByHash hash={processId.toString()}>{id}</TextColorByHash>
                                    </Stack.Item>
                                    <Stack.Item>{level}</Stack.Item>
                                </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                <Link
                                    to={urlFromProcess({
                                        id: processId,
                                    })}
                                >
                                    {processId}
                                </Link>{' '}
                                {reason}
                            </Stack.Item>
                            <Stack.Item>{timestamp ? moment(timestamp).format(dutchFormat) : '-'}</Stack.Item>
                        </Stack>
                    ),
                    context: (
                        <Stack stretch>
                            <Stack.Item>
                                {projectionId && (
                                    <Stack justify horizontal gap="medium">
                                        <Link
                                            to={urlFromProjection({
                                                id: projection?.id,
                                            })}
                                        >
                                            {projectionId}
                                        </Link>
                                    </Stack>
                                )}
                            </Stack.Item>

                            <Stack.Item>
                                <TextColorByHash>{tag}</TextColorByHash>
                            </Stack.Item>
                            <Stack.Item>
                                <Link to={urlFromProfiel({id: profielId})}>{name}</Link>{' '}
                                <Information>({profielId})</Information>
                            </Stack.Item>
                        </Stack>
                    ),
                    message: (
                        <Stack>
                            <Stack.Item>{interpolatedMessage}</Stack.Item>
                            {Object.keys(data).length !== 0 && <ObjectInspector data={data} />}
                            {event && <FixEventButtonsConnected values={event} />}
                        </Stack>
                    ),
                };
            }
        )
    );
};
