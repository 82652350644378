import {ILijsttemplateSortableTabelFragment} from '@growthbase/graphql';
import {DNDItemData} from '@growthbase/keys';
import React, {FC, memo} from 'react';
import {SortableTabelConnected as SortableTaakTabelConnected} from '@growthbase/takentemplates-taaktemplate';
import {noop} from 'lodash';
import {LijsttemplateDragType, Filters} from '../../../../types';
import {TabelConnected} from '../Tabel';
import {SortableTabel} from './SortableTabel';
import {MenuConnected} from '../../../../../Menu';
import {TemplateMenuConnected} from '../../../../../TemplateMenu';

interface SortableTakenTabelLijstConnectedProps {
    item: DNDItemData<ILijsttemplateSortableTabelFragment, LijsttemplateDragType>;
    filters: Filters;
    bordtemplateId: string;
}

export const SortableTabelConnected: FC<SortableTakenTabelLijstConnectedProps> = memo(
    ({item, filters, bordtemplateId}) => {
        const herbruikbaar = item?.userData?.herbruikbaarheid?.herbruikbaar;
        return (
            <SortableTabel<ILijsttemplateSortableTabelFragment>
                renderTabel={(props) => {
                    if (herbruikbaar) {
                        return <TabelConnected header={props.header} fragment={item.userData} filters={filters} />;
                    }
                    return (
                        <SortableTaakTabelConnected header={props.header} filters={filters} fragment={item.userData} />
                    );
                }}
                menu={
                    herbruikbaar ? (
                        <TemplateMenuConnected bordtemplateId={bordtemplateId} fragment={item.userData} />
                    ) : (
                        <MenuConnected onCopied={noop} fragment={item.userData} />
                    )
                }
                item={item}
            />
        );
    }
);

SortableTabelConnected.displayName = 'SortableTabelConnected';
