import {ConversationComment, OptionalButtonHandler, ReadonlyOpgemaakteTekst} from '@growthbase/design-components';
import {useDateObject} from '@growthbase/spa';
import {ReactNode, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useToggle} from 'react-use';
import {ReactieFields, ReactieInhoudForm} from '../ReactieInhoudForm/ReactieInhoudForm';

export interface TaakReactieProps {
    initialEdit?: boolean;
    readonly?: boolean;
    gebruiker?: {
        naam: string;
        image?: string | null | undefined;
    } | null;
    aanmaakdatum: string;
    value: ReactieFields;
    renderMenu: (props: {onEdit?: OptionalButtonHandler}) => ReactNode;
    onSubmit: (value: ReactieFields) => Promise<void>;
}

export const TaakReactie = ({
    readonly,
    initialEdit,
    aanmaakdatum,
    gebruiker,
    renderMenu,
    value,
    onSubmit,
}: TaakReactieProps) => {
    const [editEnabled, setEditEnabled] = useToggle(initialEdit ?? false);
    const {t} = useTranslation('spa_form.nl');

    const handleOnSubmit = useCallback(
        (commentValue: ReactieFields) => onSubmit(commentValue).then(() => setEditEnabled(false)),
        [onSubmit, setEditEnabled]
    );

    return (
        <ConversationComment
            avatarUrl={gebruiker?.image}
            date={useDateObject(aanmaakdatum)}
            menuContent={!readonly && renderMenu({onEdit: setEditEnabled})}
            name={gebruiker?.naam ?? t('unknown')}
        >
            {!readonly && editEnabled ? (
                <ReactieInhoudForm autoFocus value={value} onSubmit={handleOnSubmit} />
            ) : (
                <ReadonlyOpgemaakteTekst value={value.tekst} />
            )}
        </ConversationComment>
    );
};
