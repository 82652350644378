import {useCreateMutationHandler, useNormalizeOpgemaakteTekstGraphQLResult} from '@growthbase/design-components';
import {ITaakReactieConnectedFragment, useTaakReactieConnectedPasInhoudAanMutation} from '@growthbase/graphql';
import {useCallback, useMemo} from 'react';
import {ReactieMenuConnected} from '../ReactieMenu/ReactieMenuConnected';
import {TaakReactie} from './TaakReactie';

export interface TaakReactieConnectedProps {
    disabled?: boolean;
    readonly?: boolean;
    fragment: ITaakReactieConnectedFragment;
}

export const TaakReactieConnected = ({fragment, readonly, disabled}: TaakReactieConnectedProps) => {
    const [mutation] = useTaakReactieConnectedPasInhoudAanMutation();
    const pasInhoudAan = useCreateMutationHandler(mutation);
    const deNormalized = useNormalizeOpgemaakteTekstGraphQLResult(fragment.tekst);
    return (
        <TaakReactie
            renderMenu={useCallback(
                (props) => (
                    <ReactieMenuConnected {...props} fragment={fragment} readonly={readonly} disabled={disabled} />
                ),
                [fragment, readonly, disabled]
            )}
            value={useMemo(
                () => ({
                    tekst: deNormalized,
                }),
                [deNormalized]
            )}
            aanmaakdatum={fragment.aanmaakdatum}
            gebruiker={fragment.gebruiker}
            onSubmit={useCallback(
                ({tekst}) =>
                    pasInhoudAan({
                        taakId: fragment.taakId,
                        id: fragment.id,
                        inhoud: {
                            opgemaakteTekst: tekst,
                        },
                    }),
                [pasInhoudAan, fragment]
            )}
        />
    );
};
