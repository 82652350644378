import {
    DefaultFormModalFooter,
    Field,
    Modal,
    ModalContent,
    PopupHeader,
    TabContentWrapper,
    Stack,
    SingleLineOpgemaakteTekstInput,
    OpgemaakteTekstInput,
} from '@growthbase/design-components';
import React, {FC, PropsWithChildren} from 'react';
import {useTranslation} from 'react-i18next';
import {useCreateForm, UseCreateFormOptions} from './Hook/useCreateForm';

export interface CreateFormProps extends UseCreateFormOptions, PropsWithChildren {}

export const CreateForm: FC<CreateFormProps> = ({
    children,

    ...rest
}) => {
    const {t} = useTranslation('TakentemplateTaak.nl');
    const {Form, Fields, formik, formProps} = useCreateForm(rest);
    const {TitelField, OmschrijvingField} = Fields;

    const Titel = TitelField.useComponent(SingleLineOpgemaakteTekstInput);
    const Omschrijving = OmschrijvingField.useComponent(OpgemaakteTekstInput);

    return (
        <Modal testId="TaaktemplateCreateModal">
            <Form {...formProps}>
                <PopupHeader title={t('add')} />
                <ModalContent theme="gray">
                    <TabContentWrapper title="x">
                        <Stack gap="small">
                            <Stack.Item>
                                <Field label={t('titel')} compact disableMargin>
                                    <Titel autoFocus controls={false} borderVisible variant="default" />
                                </Field>
                            </Stack.Item>
                            <Stack.Item>
                                <Omschrijving
                                    label={t('omschrijving')}
                                    placeholder={t('omschrijvingPlaceholder')}
                                    floatingToolbar={false}
                                />
                            </Stack.Item>
                        </Stack>
                        {children}
                    </TabContentWrapper>
                </ModalContent>
                <DefaultFormModalFooter formik={formik} />
            </Form>
        </Modal>
    );
};
