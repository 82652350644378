import React, {FC, memo, useMemo} from 'react';
import {withErrorBoundary} from 'react-error-boundary';
import {useSendHandleError} from '../Hook';
import {ShowError} from './ShowError';
import 'react-toastify/dist/ReactToastify.css';

export interface SpaErrorBoundaryProps {
    children: React.ComponentType;
}

/**
 * Config error handler.
 */
export const SpaErrorBoundary: FC<SpaErrorBoundaryProps> = memo(({children}) => {
    const onError = useSendHandleError();
    const ComponentWithErrorBoundary = useMemo(
        () =>
            withErrorBoundary(children, {
                fallbackRender: ShowError,
                onError,
            }),
        [children, onError]
    );
    return <ComponentWithErrorBoundary />;
});
SpaErrorBoundary.displayName = 'SpaErrorBoundary';
