import moment from 'moment';

export interface DateTimeRangeDisplayProps {
    from: Date | string;
    to: Date | string;
}

export const DateTimeRangeDisplay = ({from, to}: DateTimeRangeDisplayProps) => {
    const fromDate = moment(from);
    const toDate = moment(to);

    if (fromDate.isSame(toDate, 'day')) {
        return (
            <>
                <strong>{fromDate.format('dddd D MMMM')}</strong>
                <div>
                    {fromDate.format('HH:mm')} - {toDate.format('HH:mm')}
                </div>
            </>
        );
    }

    return (
        <>
            <div>
                {fromDate.format('dddd D MMMM')} - {toDate.format('dddd D MMMM')}
            </div>
            <div>
                {fromDate.format('HH:mm')} - {toDate.format('HH:mm')}
            </div>
        </>
    );
};
