import {Resource} from 'i18next';
import * as yup from 'yup';
import {InferType, SchemaOf} from 'yup';
import {AnyObject} from 'yup/lib/object';
import {ErrorContext} from '../ErrorHandling';
import {RoutesSchema} from './RoutesSchema';

export type RoutesDTO = InferType<typeof RoutesSchema>;

export interface ClientApplicationConfigDTO {
    routes: RoutesDTO;
    errorRoute: string;
    graphqlRoutes: {[key: string]: string};
    defaultErrorContext: ErrorContext;
    locale: string;
    environment: string;
    translations: Resource;
    indexRoute?: string | null;
    baseRoute: string;
    boostrapEnabled: boolean;
    takenEnabled: boolean;
    planningEnabled: boolean;
    agendaEnabled: boolean;
    voyagerGraphQLRoute: string;
    graphiqlGraphQLRoute: string;
    defaultReduxStateContext?: unknown;
    additionalProperties: AnyObject;
}

export const ClientApplicationConfigDTOSchema: SchemaOf<ClientApplicationConfigDTO> = yup
    .object({
        routes: RoutesSchema,
        boostrapEnabled: yup.boolean().required(),
        takenEnabled: yup.boolean().required(),
        planningEnabled: yup.boolean().required(),
        agendaEnabled: yup.boolean().required(),
        errorRoute: yup.string().required(),
        graphqlRoutes: yup.object<AnyObject>().required(),
        indexRoute: yup.string().nullable(),
        baseRoute: yup.string().required(),
        locale: yup.string().required(),
        environment: yup.string().required(),
        defaultErrorContext: yup.object<AnyObject>().required(),
        translations: yup.object<AnyObject>().required(),
        voyagerGraphQLRoute: yup.string().required(),
        graphiqlGraphQLRoute: yup.string().required(),
        defaultReduxStateContext: yup.object<AnyObject>().nullable(),
        additionalProperties: yup.object<AnyObject>().required(),
    })
    .label('ClientApplicationConfigDTO')
    .required();
