import type {ProcessedResult} from 'select2';
import {ConfiguredElement} from '../rxjs';
import {Select2ConfigDTO, Select2ItemsDTOSchema} from './DTO';

/* eslint-disable no-shadow */

export const configSelect2 = ({element, dto}: ConfiguredElement<Select2ConfigDTO>): unknown => {
    const elementJQuery = $(element).closest('.input-group') as JQuery;
    $(element).select2({
        allowClear: true,
        multiple: dto.multiple,
        dropdownParent: elementJQuery,
        ajax: {
            url: dto.url,
            dataType: 'json',
            type: 'GET',
            delay: 100,
            data(queryParams) {
                return $.extend(
                    {
                        query: queryParams.term,
                    },
                    {
                        excludeIds: dto.excludeIds,
                    }
                );
            },
            processResults(data) {
                return {
                    results: Select2ItemsDTOSchema.validateSync(
                        data.results.map((data: Record<string, unknown>) => ({
                            ...data,
                            text: data.name,
                        }))
                    ),
                } as ProcessedResult;
            },
            error(error: unknown) {
                console.error(error);
            },
        },
    });
    return {
        name: elementJQuery.attr('name'),
    };
};
