import {
    OpgemaakteTekst,
    OpgemaakteTekstInput,
    OpgemaakteTekstInputProps,
    OpgemaakteTekstSchema,
    removeNullAndFalsePropertiesArray,
    SingleFieldForm,
} from '@growthbase/design-components';
import {TFunction} from 'i18next';
import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {SchemaOf} from 'yup';

export interface ReactieFields {
    tekst: OpgemaakteTekst;
}

export const ReactieFieldSchema = (t: TFunction): SchemaOf<ReactieFields> =>
    yup
        .object({
            tekst: OpgemaakteTekstSchema(t),
        })
        .required();

export const useReactieFieldSchema = () => {
    const {t} = useTranslation();
    return useMemo(() => ReactieFieldSchema(t), [t]);
};

export interface ReactieInhoudFormProps {
    value?: ReactieFields;
    autoFocus?: boolean;
    onSubmit: (value: ReactieFields) => Promise<void>;
}

export const ReactieInhoudForm = ({value, onSubmit, autoFocus}: ReactieInhoudFormProps) => {
    const {t} = useTranslation('spa_form.nl');

    return (
        <SingleFieldForm
            autoFocus={autoFocus}
            autoSubmit={false}
            disabled={!value}
            schema={useReactieFieldSchema()}
            name="tekst"
            testfieldName="TaakReactieOpgemaakteTekst"
            disableInlineEditable
            createInitialValuesAfterSubmit
            onSubmit={onSubmit}
            createInitialValues={useCallback(
                () => ({
                    tekst: {
                        elements: removeNullAndFalsePropertiesArray(value?.tekst?.elements ?? []),
                    },
                }),
                [value]
            )}
            controlsPosition="bottom"
            extraFieldProps={{disableToolbar: true, isFocussed: true, testId: 'reactie'} as OpgemaakteTekstInputProps}
            placeholder={t('taak.reactiePlaceholder')}
            component={OpgemaakteTekstInput}
        />
    );
};
