import {useTypedSearchParams} from '@growthbase/routing';
import {useCallback, useContext, useMemo} from 'react';
import {string, object} from 'yup';
import {TabInterface} from '../TabInterface';
import {TabsContext} from '../TabsContext';

const schema = object({
    tab: string(),
});

export const useTabQueryValue = (): [string | null, (value: string) => void] => {
    const {query, onQueryChange} = useContext(TabsContext);

    if (!query) {
        throw new Error('Should define query options in Tabs');
    }
    const [result, setResult] = useTypedSearchParams(
        query.name,
        schema,
        useMemo(
            () => ({
                tab: query.defaultValue,
            }),
            [query.defaultValue]
        )
    );

    return [
        result.tab ?? null,
        useCallback(
            (value) => {
                if (onQueryChange) {
                    onQueryChange(value);
                }
                setResult({
                    tab: value,
                });
            },
            [setResult, onQueryChange]
        ),
    ];
};

export const useTabQueryLink = (tab: TabInterface): [string | null, (value: string) => void] => {
    const {query} = useContext(TabsContext);

    if (!query) {
        throw new Error('Should define query options in Tabs');
    }
    const [result, setResult] = useTypedSearchParams(
        query.name,
        schema,
        useMemo(
            () => ({
                tab: query.defaultValue,
            }),
            [query.defaultValue]
        )
    );
    return [
        result.tab ?? null,
        useCallback(
            (value) =>
                setResult({
                    tab: value,
                }),
            [setResult]
        ),
    ];
};
