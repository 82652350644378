export interface NeighbourPlacement {
    before?: string;
    after?: string;
}

/**
 * The is for calculating the placement of an item in a list when selecting it from a dropdown.
 *
 * - Otherwise we place the item before or after the target.
 */
export const calculatePlacementForIndex = (array: string[], item: string, targetIndex: number): NeighbourPlacement => {
    const currentIndex = array.indexOf(item);

    if (targetIndex < 0) {
        throw new Error('Invalid input');
    }

    if (currentIndex === -1) {
        if (targetIndex > array.length) {
            throw new Error('Invalid input');
        }
        if (array.length === 0) {
            return {};
        }
        if (targetIndex === 0) {
            return {
                before: array[targetIndex],
            };
        }

        if (targetIndex === array.length) {
            return {
                after: array[targetIndex - 1],
            };
        }

        if (targetIndex === 0) {
            return {
                before: array[targetIndex],
            };
        }

        return {
            before: array[targetIndex],
            after: array[targetIndex - 1],
        };
    }

    if (targetIndex >= array.length) {
        throw new Error('Invalid input');
    }

    const result: NeighbourPlacement = {};

    if (array[targetIndex] === item) {
        return {
            before: array[targetIndex + 1],
            after: array[targetIndex - 1],
        };
    }

    if (currentIndex < targetIndex) {
        result.after = array[targetIndex];
        if (targetIndex < array.length - 1) {
            result.before = array[targetIndex + 1];
        }
    } else if (currentIndex > targetIndex) {
        result.before = array[targetIndex];
        if (targetIndex > 0) {
            result.after = array[targetIndex - 1];
        }
    }

    return result;
};

/**
 * The last place is a fake bottom.
 */
export const calculatePlacementWithFakeBottom = (
    list: string[],
    item: string,
    placement: number
): NeighbourPlacement => {
    const realList = list.slice(0, -1);
    if (placement === realList.length) {
        return {
            after: realList[placement - 1],
        };
    }
    return calculatePlacementForIndex(realList, item, placement);
};

/**
 * The last place is a fake bottom.
 */
export const calculatePlacement = (
    list: string[],
    fakeBottom: boolean,
    item: string,
    placement: number
): NeighbourPlacement => {
    if (fakeBottom) {
        return calculatePlacementWithFakeBottom(list, item, placement);
    }
    return calculatePlacementForIndex(list, item, placement);
};
