import React, {ReactNode, useCallback, useEffect, FC} from 'react';
import classNames from 'classnames/bind';
import {useToggle} from 'react-use';
import styles from './SidenavLink.module.scss';
import {useSidenavContext} from '../Sidenav/useSidenavContext';
import {SidenavLinkTitle} from '../SidenavLinkTitle';
import {SidenavPopupLink} from '../SidenavPopupLink';
import {useIsActiveRoute} from './Hook/useIsActiveRoute';
import {BaseButtonProps} from '../../Button';
import {AnchorOrLink, AnchorOrLinkValue} from '../../AnchorOrLink';
import {IconProps, IconSize} from '../../Icon';
import {SidenavCounterPopup, SidenavCounterPopupButtonProps} from '../SidenavCounterPopup';
import {Padding} from '../../Layout';
import {IconButton} from '../../Buttons';
import {Icons} from '../../icons';

const cx = classNames.bind(styles);

export interface SidenavLinkProps extends BaseButtonProps, AnchorOrLinkValue {
    subnavContent?: ReactNode;
    count?: ReactNode;
    countPopupContent?: ReactNode;
    Icon: FC<IconProps>;
    id?: string;
    active?: boolean;
    nestedRoutes?: AnchorOrLinkValue[];
}

export const SidenavLink = ({
    children,
    Icon,
    subnavContent,
    to,
    href,
    id,
    count,
    countPopupContent,
    active: activeFromServer,
    nestedRoutes,
}: SidenavLinkProps) => {
    const {isExpanded} = useSidenavContext();

    const {isActive, isNestedActive} = useIsActiveRoute({
        to,
        href,
        activeFromServer,
        nestedRoutes,
    });

    const [subnavIsVisible, toggleSubnavVisibility] = useToggle(isNestedActive);
    const handleToggleClick = useCallback(() => {
        toggleSubnavVisibility();
    }, [toggleSubnavVisibility]);

    const classes = cx('sidenavLink', {
        'sidenavLink--subnavVisible': subnavIsVisible,
        'sidenavLink--expanded': isExpanded,
        'sidenavLink--active': isActive,
    });

    useEffect(() => {
        if (isActive && subnavContent) {
            toggleSubnavVisibility(true);
        }
    }, [subnavContent, isActive, toggleSubnavVisibility]);

    const renderCounterButton = (props: SidenavCounterPopupButtonProps) => (
        <div className={cx('sidenavLink__badge')}>{count}</div>
    );

    if (!isExpanded) {
        return (
            <SidenavPopupLink
                Icon={Icon}
                to={to}
                href={href}
                active={isActive}
                id={id}
                badgeContent={
                    <SidenavCounterPopup renderButton={renderCounterButton}>{countPopupContent}</SidenavCounterPopup>
                }
            >
                <Padding>
                    <SidenavLinkTitle alignWithSubnav={!!subnavContent}>{children}</SidenavLinkTitle>
                    {subnavContent && <div className={styles.sidenavLink__subnav}>{subnavContent}</div>}
                </Padding>
            </SidenavPopupLink>
        );
    }

    return (
        <div className={classes} id={`SidenavLink--${id}`}>
            <div className={styles.sidenavLink__inner}>
                <AnchorOrLink to={to} href={href} className={styles.sidenavLink__link}>
                    <Icon className={styles.sidenavLink__icon} size={IconSize.SIZE_5} />
                    <SidenavLinkTitle>{children}</SidenavLinkTitle>
                </AnchorOrLink>
                {subnavContent && (
                    <div className={styles.sidenavLink__subnavTrigger}>
                        <IconButton
                            color="inherit"
                            Icon={subnavIsVisible ? Icons.MinusCircleOutline : Icons.PlusCircleOutline}
                            iconSize={IconSize.SIZE_5}
                            className={styles.sidenavLink__expandButton}
                            onClick={handleToggleClick}
                        />
                    </div>
                )}
            </div>

            {countPopupContent ? (
                <SidenavCounterPopup renderButton={renderCounterButton}>{countPopupContent}</SidenavCounterPopup>
            ) : (
                <div className={cx('sidenavLink__badge')}>{count}</div>
            )}

            {subnavIsVisible && <div className={styles.sidenavLink__subnav}>{subnavContent}</div>}
        </div>
    );
};
