import {debounceTime, distinctUntilChanged, fromEvent, interval, map, merge, mergeMap, of} from 'rxjs';
import {Logger} from '../Utils';
import {ConfiguredElement} from '../rxjs';
import {CopyInputValueConfigDTO} from './DTO/CopyInputValueConfigDTO';

export const apply =
    (logger: Logger) =>
    ({element, dto}: ConfiguredElement<CopyInputValueConfigDTO>) => {
        const sourceInput = document.getElementsByName(dto.sourceInput)[0] as HTMLInputElement;

        return of(sourceInput).pipe(
            mergeMap((nextElement) =>
                merge(
                    of(1),
                    fromEvent(nextElement, 'change'),
                    fromEvent(nextElement, 'blur'),
                    fromEvent(nextElement, 'input'),
                    interval(300)
                ).pipe(
                    map(() => nextElement.value),
                    distinctUntilChanged()
                )
            ),
            debounceTime(1),
            map(() => {
                element.textContent = sourceInput.value;

                return {
                    date: element.textContent,
                    dto,
                    sourceInput,
                };
            })
        );
    };
