import {IStatisticsGraphFragment} from '@growthbase/graphql';
import {LineChartProps} from '@mui/x-charts/LineChart/LineChart';
import {useMemo} from 'react';
import {DatasetType} from '@mui/x-charts/models/seriesType/config';

export const useChartData = (values?: IStatisticsGraphFragment[]): LineChartProps =>
    useMemo((): LineChartProps => {
        if (!values) {
            return {
                dataset: [],
                series: [],
            };
        }

        const result: Record<number, Record<string, unknown>> = {};

        values.forEach((value) => {
            if (!result[value.timestamp]) {
                result[value.timestamp] = {
                    id: value.timestamp,
                    timestamp: value.timestamp * 1000,
                };
            }
            result[value.timestamp][value.name] = value.value;
        });

        const groups = Object.keys(
            values.reduce((acc, value) => {
                acc[value.name] = true;
                return acc;
            }, {} as Record<string, boolean>)
        );
        const dataset = Object.values(result) as unknown as DatasetType;

        return {
            dataset,
            xAxis: [
                {
                    dataKey: 'timestamp',
                    valueFormatter: (value) => new Date(value).toLocaleTimeString(),
                    scaleType: 'time',
                },
            ],
            series: groups.map((group) => ({
                dataKey: group,
                type: 'line',
                label: group,
            })),
        };
    }, [values]);
