import * as yup from 'yup';
import {SchemaOf} from 'yup';

export interface CopyInputValueConfigDTO {
    sourceInput: string;
}

export const CopyInputValueConfigDTOSchema: SchemaOf<CopyInputValueConfigDTO> = yup
    .object({
        sourceInput: yup.string().required(),
    })
    .label('CopyInputValueConfigDTO')
    .required();
