import {Buttons, SearchForm, Stack} from '@growthbase/design-components';
import {FC, ReactNode, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {DeelnemersFilterConnected} from '@growthbase/taken';
import {BordtemplateRoutes} from '@growthbase/takentemplates-bordtemplate';
import {OverviewFilters} from '../../types';

export interface HeaderProps {
    filter: OverviewFilters;
    loading: boolean;
    setFilter: (filter: OverviewFilters) => void;
    selectBord: ReactNode;
    DeelnemerFilter?: typeof DeelnemersFilterConnected;
}

export const Header: FC<HeaderProps> = ({
    setFilter,
    filter,
    loading,
    selectBord,
    DeelnemerFilter = DeelnemersFilterConnected,
}) => {
    const {t} = useTranslation('spa-form.nl');
    return (
        <Stack gap="tiny" horizontal>
            <Stack.Item pushItems="after">
                <SearchForm
                    loading={loading}
                    value={useMemo(
                        () => ({
                            query: filter.searchQuery,
                        }),
                        [filter.searchQuery]
                    )}
                    onChange={useCallback(
                        (value) =>
                            setFilter({
                                ...filter,
                                searchQuery: value.query,
                            }),
                        [filter, setFilter]
                    )}
                />
            </Stack.Item>
            <Stack.Item>{selectBord}</Stack.Item>
            <Stack.Item>
                <Buttons.Add
                    key="add"
                    onClick={BordtemplateRoutes.create.useNavigate()}
                    tooltip={t('aanmaken')}
                    ariaLabel={t('aanmaken')}
                />
            </Stack.Item>
        </Stack>
    );
};
