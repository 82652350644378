import {
    BaseButtonProps,
    Buttons,
    DatePicker,
    HoverIcon,
    IconSize,
    NullableISOStringDate,
    PopupAnchorProps,
} from '@growthbase/design-components';
import {FC, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useNow} from '@growthbase/spa';
import {useDateText} from './hook/useDateText';
import {ToolTipContent} from './components/ToolTipContent';

export interface SnoozeButtonProps {
    disabled: boolean;
    readonly?: boolean;
    asText?: boolean;
    value?: NullableISOStringDate;
    onSubmit: (range: NullableISOStringDate) => Promise<void>;
    Button?: FC<BaseButtonProps>;
    ModifyButton?: FC<BaseButtonProps>;
    asActions?: boolean;
}

export const SnoozeButton: FC<SnoozeButtonProps> = ({
    value,
    onSubmit,
    disabled,
    readonly,
    asText,
    asActions,
    Button: ButtonCalendarAdd = Buttons.SnoozeIconDotted,
    ModifyButton: ButtonCalendarModify = Buttons.SnoozeIconDotted,
}) => {
    const {t} = useTranslation('Taken.nl');
    const currentDateTime = useNow();

    const text = useDateText(asActions, asText ? value : undefined);

    const haveData = !!value?.date;

    const DefaultButton = haveData ? ButtonCalendarModify : ButtonCalendarAdd;

    const button = useCallback(
        ({reference, controls: {open, isOpen}}: PopupAnchorProps) => {
            if (!asText && readonly) {
                return null;
            }
            const showAsText = asText && text;
            const Button = showAsText ? Buttons.Link : DefaultButton;
            const tooltip = haveData ? <ToolTipContent date={value} /> : t('SnoozeButton.snooze');
            const rendered = (
                <Button
                    ariaLabel={t('SnoozeButton.snooze')}
                    ref={reference}
                    active={isOpen}
                    onClick={open}
                    tooltip={tooltip}
                    iconSize={IconSize.SIZE_4}
                >
                    {text}
                </Button>
            );
            if (!showAsText || readonly) {
                return rendered;
            }
            return <HoverIcon>{rendered}</HoverIcon>;
        },
        [
            asText,
            readonly,
            text,
            DefaultButton,
            t,
            haveData,
            value,
        ]
    );

    if (readonly && !haveData) {
        return null;
    }

    return (
        <DatePicker
            renderAnchor={button}
            disabled={disabled}
            readonly={readonly}
            value={value}
            labels={{
                dateLabel: t('SnoozeButton.snoozeTot') ?? '',
            }}
            header={t('SnoozeButton.snoozeTaak')}
            onSubmit={onSubmit}
            minimumDate={{
                date: () => {
                    const date = currentDateTime();
                    date.setSeconds(date.getSeconds() - 15);
                    return date;
                },
                errorMessage: t('SnoozeButton.SnoozeTotMoetInDeToekomstLiggen') ?? '',
            }}
        />
    );
};
