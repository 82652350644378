import {useFetchBestandLazyQuery} from '@growthbase/graphql';
import {idFromIri} from '@growthbase/routing';
import {useCallbackRef, useLogger} from '@growthbase/spa';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

/* eslint-disable */

export const useDownloadBestandFactory = () => {
    const {t} = useTranslation('Bestandsbeheer.nl');
    const [fetchBestand] = useFetchBestandLazyQuery();
    const logger = useLogger('useDownloadBestandFactory');

    // Definieer een callback functie om de download te starten
    const download = useCallback(
        async (bestandId: string, name: string) => {
            const abortController = new AbortController();
            const toastId = toast(t('downloading', {name}), {
                autoClose: false,
                progress: 0,
                onClose: () => {
                    abortController.abort();
                },
            });

            const error = (e: unknown) => {
                if (e) {
                    logger.error(e);
                }
                toast.update(toastId, {
                    type: 'error',
                    autoClose: 5000,
                    render: t('downloadError', {name}),
                });
            };

            try {
                const fetchBestandResult = await fetchBestand({
                    variables: {
                        id: idFromIri(bestandId),
                    },
                    fetchPolicy: 'network-only',
                    nextFetchPolicy: 'network-only',
                });

                const bestand = fetchBestandResult.data?.bestand;
                if (!bestand) {
                    error('Bestand not found');
                    return;
                }

                const {url} = bestand;
                if (!url) {
                    error('Bestand url not found');
                    return;
                }

                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/octet-stream',
                    },
                    signal: abortController.signal,
                });

                if (!response.ok || !response.body || !response.headers) {
                    error(
                        new Error(
                            `Download failed with status ${response.status} and statusText ${response.statusText}`
                        )
                    );
                    return;
                }

                const reader = response.body.getReader();
                const length = response.headers.get('Content-Length');
                const contentLength = length ? parseInt(length, 10) : 0;
                let receivedLength = 0;
                const chunks = [];

                while (true) {
                    const {done, value} = await reader.read();
                    if (done) {
                        break;
                    }
                    if (!value) {
                        continue;
                    }
                    if (abortController.signal.aborted) {
                        // Clean up the partial download
                        reader.cancel();
                        throw new Error('Download aborted');
                    }

                    chunks.push(value);
                    receivedLength += value.length;

                    const progress = receivedLength / contentLength;
                    toast.update(toastId, {
                        progress,
                    });
                }

                const blob = new Blob(chunks);
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
                window.URL.revokeObjectURL(downloadUrl);

                toast.done(toastId);
            } catch (e) {
                if (!abortController.signal.aborted) {
                    error(e);
                    return;
                }
                toast(t('downloadAborted', {name}));
            }
        },
        [logger, fetchBestand, t]
    );

    return download;
};

export const useDownloadBestand = (bestandId: string, name: string) => {
    const download = useDownloadBestandFactory();
    return useCallbackRef(() => download(bestandId, name));
};
