/* eslint-disable no-use-before-define */

import {OpgemaakteTekstElement} from '../Hook/useOpgemaakteTekstSchema';

export const removeNullAndFalsePropertiesArray = (value: OpgemaakteTekstElement[] = []): OpgemaakteTekstElement[] =>
    value.map(removeNullAndFalseProperties);

export const removeNullAndFalseProperties = (value: OpgemaakteTekstElement): OpgemaakteTekstElement => {
    const fixed = Object.fromEntries(
        Object.entries(value)
            .filter(([k, v]) => v != null && v !== false && k !== '__typename')
            .map(([k, v]) => {
                if (k === 'children') {
                    return [k, removeNullAndFalsePropertiesArray(v ?? [])];
                }
                return [k, v];
            })
    ) as OpgemaakteTekstElement;
    if (fixed && 'type' in fixed && fixed.type) {
        // @ts-expect-error Some examples of the type are not correct
        if (fixed.type === 'text') {
            // @ts-expect-error Some examples of the type are not correct
            delete fixed.type;
        } else {
            fixed.children ??= [
                {
                    text: '',
                },
            ];
        }
    }

    return fixed;
};
