import {TFunction} from 'i18next';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {SchemaOf} from 'yup';

export const TitelSchema = (t: TFunction) =>
    yup
        .string()
        .required()
        .label(t('titel', {ns: 'spa_form.nl'}))
        // TODO: This validation is not the same as the backend.
        .min(1)
        .max(1000);

export const useTitelSchema = (): SchemaOf<string> => {
    const {t} = useTranslation();
    return useMemo(() => TitelSchema(t), [t]);
};
