import {CSSVariable, CSSVariableSchema} from '@growthbase/design-components';
import * as yup from 'yup';
import {SchemaOf} from 'yup';
import {MenuChildLink, MenuChildSchema} from './MenuChild';
import {MenuItem, MenuItemSchema} from './MenuItem';

export interface NavigationData {
    top: MenuItem[];
    center: MenuItem[];
    bottom: MenuChildLink[];
    theme: {
        logoUrl: string;
        scrollableTheme: 'light' | 'dark';
        variables: CSSVariable[];
    };
    user: {
        name: string;
        avatarUrl: string | null;
    };

    profile: {
        name: string;
    };
}

export const NavigationDataSchema = (): SchemaOf<NavigationData> =>
    yup
        .object({
            top: yup.array(MenuItemSchema.required()).required(),
            center: yup.array(MenuItemSchema.required()).required(),
            bottom: yup.array(MenuChildSchema.required()).required(),
            user: yup.object({
                name: yup.string().required(),
                avatarUrl: yup.string().optional().nullable(),
            }),
            profile: yup.object({
                name: yup.string().required(),
            }),
            theme: yup.object({
                variables: yup.array(CSSVariableSchema.required()).required(),
                scrollableTheme: yup.string().oneOf(['light', 'dark']).required(),
                logoUrl: yup.string().required(),
            }),
        })
        .label('NavigationData')
        .required() as SchemaOf<NavigationData>;
