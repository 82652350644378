import {useConvertFetchEntity, useExternalEntityChanged} from '@growthbase/cache';

import {ITaakBijlagesFragment, refetchFetchThumbnailQuery} from '@growthbase/graphql';

import {compareIds} from '@growthbase/routing';

export interface EntityChangesParams {
    bestandId?: string | null;
}

export const useEntityChanges = ({bestandId}: EntityChangesParams) => {
    const updateCache = useConvertFetchEntity(refetchFetchThumbnailQuery);

    /**
     * This will update the thumbnail etc when upload is done
     */
    useExternalEntityChanged<ITaakBijlagesFragment>((options) => {
        const {id, typename} = options;
        if (typename !== 'Bestand') {
            return;
        }

        if (!bestandId || !compareIds(bestandId, id)) {
            return;
        }

        updateCache({
            id,
        });
    });
};
