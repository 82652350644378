import {useTypedSearchParams} from '@growthbase/routing';
import {useMemo} from 'react';
import * as yup from 'yup';
import {SchemaOf} from 'yup';
import {useLocalStorage} from 'react-use';
import {useCallbackRef} from '@growthbase/spa';
import {IStandaardWeergaveLijsten} from '@growthbase/graphql';
import {ViewType} from '../../../types';

interface Filters {
    overzicht: ViewType;
}

const Schema = (): SchemaOf<Filters> =>
    yup
        .object({
            overzicht: yup.mixed().oneOf(Object.keys(ViewType)).required(),
        })
        .required();

const useFilterSchema = (): SchemaOf<Filters> => useMemo(() => Schema(), []);

export interface ViewSelectStateOptions {
    bordId: string;
    standaardWeergaveLijsten: IStandaardWeergaveLijsten;
    laatstGebruikteWeergaveLijsten: boolean;
}

export const useViewSelectState = ({
    bordId,
    laatstGebruikteWeergaveLijsten,
    standaardWeergaveLijsten,
}: ViewSelectStateOptions): [ViewType, (overzicht: ViewType) => void] => {
    const initialView =
        standaardWeergaveLijsten === IStandaardWeergaveLijsten.Kanban ? ViewType.Kanban : ViewType.Lijsten;
    const [useLastView, setLastViewType] = useLocalStorage<ViewType>(`bord-view`, initialView);

    const [overzichtFromLocalStorage, setOverzichtFromLocalStorage] = useLocalStorage<ViewType>(
        `bord-view:${bordId}`,
        laatstGebruikteWeergaveLijsten ? useLastView ?? initialView : initialView
    );
    const [{overzicht}, setFilters] = useTypedSearchParams<Filters>(
        'view',
        useFilterSchema(),
        useMemo(
            () => ({
                overzicht:
                    standaardWeergaveLijsten === IStandaardWeergaveLijsten.LaatstGebruiktWeergeven
                        ? overzichtFromLocalStorage ?? initialView
                        : initialView,
            }),
            [initialView, overzichtFromLocalStorage, standaardWeergaveLijsten]
        ),
        true
    );
    const result = overzicht !== ViewType.Lijsten && overzicht !== ViewType.Kanban ? initialView : overzicht;
    return [
        result,
        useCallbackRef((newOverzicht: ViewType) => {
            setFilters({overzicht: newOverzicht});
            setLastViewType(newOverzicht);
            setOverzichtFromLocalStorage(newOverzicht);
        }),
    ];
};
