import {QueryResult} from '@apollo/client/react/types/types';
import {
    determineActionFilterForTypes,
    determineActionForList,
    PositieType,
    useConvertFetchEntity,
    useUpdateQueryCacheFromExternalEntityChanges,
} from '@growthbase/cache';
import {
    IBordtemplateMetLijstenBordLijstFragment,
    IBordtemplateMetLijstenQuery,
    IBordtemplateMetLijstenQueryVariables,
    refetchBordtemplateMetLijstenSingleQuery,
} from '@growthbase/graphql';
import {idFromIri} from '@growthbase/routing';

export interface EntityChangesParams {
    bordId: string;
    result: QueryResult<IBordtemplateMetLijstenQuery, IBordtemplateMetLijstenQueryVariables>;
}

export const useEntityChanges = ({result, bordId}: EntityChangesParams) =>
    useUpdateQueryCacheFromExternalEntityChanges<IBordtemplateMetLijstenBordLijstFragment>({
        // @ts-expect-error: result is not assignable to type
        result,
        determineAction: (params, defaultAction) => {
            const action = determineActionFilterForTypes(defaultAction, params, 'Lijsttemplate');
            return determineActionForList(action, params, idFromIri(bordId));
        },
        positie: PositieType.after,
        addToQueryOptions: {
            edgeTypeName: 'TaaktemplateEdge',
            nodeTypeName: 'Taaktemplate',
        },
        fetchEntity: useConvertFetchEntity(refetchBordtemplateMetLijstenSingleQuery),
    });
