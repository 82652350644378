import {last} from 'lodash';
import {useContext, useMemo} from 'react';
import {ActiveItemRegionApiContext} from '../ActiveItemRegionContainer';
import {ActiveItemSource, ActiveItemType, ActiveItemData, ActiveItem, useActiveTrees} from '../Redux/activeReducer';

export interface ActiveItemFilters<TData extends ActiveItemData = ActiveItemData> {
    type?: ActiveItemType;
    source?: ActiveItemSource;
    data?: TData;
}

/**
 * The parent can convert children items.
 */
export function useActiveItem<TData extends ActiveItemData = ActiveItemData>({
    type,
    source,
    data,
}: ActiveItemFilters = {}): ActiveItem<TData> | null {
    const context = useContext(ActiveItemRegionApiContext);
    const trees = useActiveTrees();
    const containers = useMemo(() => trees[context?.treeId]?.containers ?? [], [trees, context?.treeId]);
    return useMemo(() => {
        if (!context) {
            return null;
        }
        /* eslint-disable no-continue */
        /**
         * From specific components to larger components.
         */
        for (let i = context.nestingLevel; i < containers.length; i += 1) {
            const container = containers[i];
            const {selections, fromChildren} = container;
            const item = last(selections) ?? fromChildren;
            if (!item) {
                continue;
            }
            if (source && item.source !== source) {
                continue;
            }
            if (type && item.type !== type) {
                continue;
            }
            if (data && item.data.id !== data.id) {
                continue;
            }
            return item as ActiveItem<TData>;
        }
        return null;
    }, [context, containers, source, type, data]);
}
