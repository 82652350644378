import {IBijlageFragment, useVerwijderBijlageMutation} from '@growthbase/graphql';
import {FC} from 'react';
import {BestandThumbnailConnected, useDownloadBestand} from '@growthbase/bestandsbeheer';
import {Bijlage} from './Bijlage';
import {useEntityChanges} from './Hook/useEntityChanges';

export interface BijlageConnectedProps {
    fragment: IBijlageFragment;
}

export const BijlageConnected: FC<BijlageConnectedProps> = ({fragment}) => {
    const {id: bijlageId, naam, createdAt, bestand, taakId} = fragment;
    const download = useDownloadBestand(bestand.id, naam);

    const [verwijder] = useVerwijderBijlageMutation();

    useEntityChanges({
        bestandId: bestand.id,
    });

    return (
        <Bijlage
            uploaded={bestand.uploaded}
            bytes={bestand.bytes ?? null}
            naam={naam}
            bestandId={bestand.id}
            date={createdAt}
            kanVerwijderen={fragment.availableActions.kanVerwijderen}
            preview={<BestandThumbnailConnected fragment={bestand} />}
            download={download}
            onDelete={async () => {
                await verwijder({
                    variables: {
                        input: {
                            taakId,
                            bijlageId,
                        },
                    },
                });
            }}
        />
    );
};
