import {usePaginationFixedResult} from '@growthbase/spa';
import {useMemo, FC} from 'react';
import {MenuStub} from '../Menu/MenuStub';
import {SortableTabel, TaakTabelRow} from './SortableTabel';

export const SortableTabelStub: FC<{enableAdd?: boolean}> = ({enableAdd = true}) => {
    const data = useMemo(
        (): TaakTabelRow[] => [
            {
                id: '/Taken/260fb9cb-e5f4-4ea8-bcfd-65fb3a71dfc3',
                titel: 'Taak 1',
            },
            {
                id: '/Taken/822d4974-d550-4e95-840c-8f40cb75e810',
                titel: 'Taak 2',
            },
            {
                id: '/Taken/2e9b58e3-473f-445c-8a34-e4aa3a6de2c6',
                titel: 'Taak 3',
            },
        ],
        []
    );
    return (
        <SortableTabel
            renderMenu={(row) => <MenuStub titel={row.titel} />}
            enableTakenToevoegen
            onDrop={() => Promise.resolve()}
            pagination={usePaginationFixedResult(data)}
            lijsttemplateId="12"
            header={null}
            onSubmit={() => Promise.resolve()}
        />
    );
};
