import {ReactNode, FC} from 'react';
import classNames from 'classnames/bind';
import styles from './LogbookListItem.module.scss';

const cx = classNames.bind(styles);

export interface ActivityListItemProps {
    children: ReactNode;
}

export const LogbookListItem: FC<ActivityListItemProps> = ({children}) => (
    <div className={cx('LogbookListItem')}>{children}</div>
);
