import React, {FC} from 'react';
import {Instellingen, PageInstellingInput} from '@growthbase/instellingen';
import {Button, Stack} from '@growthbase/design-components';
import {useCallbackRef} from '@growthbase/spa';
import moment from 'moment';
import {DTO, useFields} from './Hook/useFields';

export interface EmailFormulier {
    dto: DTO;
    onSubmit: (values: DTO) => Promise<void>;
    view?: boolean;
}

export const EmailFormulier: FC<EmailFormulier> = ({dto, view, onSubmit, ...props}) => {
    const fields = useFields();
    const vandaag = useCallbackRef(() =>
        onSubmit({
            van: moment().startOf('day').toISOString(),
            tot: moment().endOf('day').toISOString(),
        }).then()
    );
    const afgelopen5Minuten = useCallbackRef(() =>
        onSubmit({
            van: moment().subtract(30, 'minutes').toISOString(),
            tot: moment().toISOString(),
        }).then()
    );

    return (
        <Stack>
            <div>Periode van laatst verzonden mail:</div>
            <Instellingen<DTO>
                view={view}
                values={dto}
                fields={fields}
                titel="Email"
                onSubmit={onSubmit}
                renderRootInputComponent={(rest) => (
                    <PageInstellingInput {...rest} buttonText="Verzenden">
                        <Button onClick={vandaag}>Mail verzenden van afgelopen dag</Button>
                        <Button onClick={afgelopen5Minuten}>Mail verzenden van afgelopen 30 minuten</Button>
                    </PageInstellingInput>
                )}
            />
        </Stack>
    );
};
