import React from 'react';
import {stubTrue, constant} from 'lodash/fp';
import {Combobox, getMentionOnSelectItem} from '@udecode/plate';

import {TComboboxItem} from '@udecode/plate-combobox/dist/types';
import styles from './AsyncComboBox.module.scss';

/* eslint-disable react/no-unstable-nested-components */

export interface AsyncComboboxProps<T extends TComboboxItem = TComboboxItem> {
    pluginKey: string;
    items: T[];
    trigger: string;
    children: (item: T) => React.ReactNode;
}

const noFilter = constant(stubTrue);

export const AsyncCombobox = <T extends TComboboxItem = TComboboxItem>({
    pluginKey,
    items,
    trigger,
    children,
}: AsyncComboboxProps<T>) => (
    <Combobox
        classNames={{
            root: styles.asyncComboBox__root,
            item: styles.asyncComboBox__item,
            highlightedItem: styles.asyncComboBox__highlight,
        }}
        onRenderItem={({item}, index) => <>{children(item as T)}</>}
        id={pluginKey}
        trigger={trigger}
        filter={noFilter}
        onSelectItem={getMentionOnSelectItem({key: pluginKey})}
        items={items}
        controlled
    />
);
