import React, {FC, PropsWithChildren} from 'react';
import {Link, To} from 'react-router-dom';

export interface AnchorOrLinkValue {
    to: To | null;
    href: string | null;
}

export interface AnchorOrLinkProps extends PropsWithChildren, AnchorOrLinkValue {
    className?: string;
    onMouseLeave?: () => void;
    onMouseEnter?: () => void;
    id?: string;
}

export const removeLegacyContainers = () => {
    const elements = document.getElementsByClassName('content-wrapper');
    for (const element of Array.from(elements)) {
        if (!element.classList.contains('spa-content')) {
            element.remove();
        } else if (element instanceof HTMLDivElement) {
            element.style.display = 'block';
        }
    }
    const headers = document.getElementsByClassName('sub-header');
    for (const element of Array.from(headers)) {
        element.remove();
    }
};

export const AnchorOrLink: FC<AnchorOrLinkProps> = ({
    href,
    className,
    to,
    id,
    children,
    onMouseLeave,
    onMouseEnter,
}) => {
    if (to) {
        return (
            <Link
                to={to}
                id={id}
                className={className}
                onMouseDown={removeLegacyContainers}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                {children}
            </Link>
        );
    }
    if (href) {
        return (
            <a href={href} id={id} className={className} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                {children}
            </a>
        );
    }
    return <div className={className}>{children}</div>;
};
