import React, {useEffect, useState} from 'react';
import {MemoryPopupProvider, usePopup} from '@growthbase/design-components';
import {useCallbackRef} from '@growthbase/spa';
import {PlanningPopupTwigConnectorInputDtoSchema} from './PlanningPopupInputSchema';
import {PlanningPopupConnected} from './PlanningPopupConnected';

export const PlanningPopupTwigConnectorContent = () => {
    const [opdrachtId, setOpdrachtId] = useState<string | null>(null);
    const {open, onClose} = usePopup();

    useEffect(() => {
        document.addEventListener('click', (event): void => {
            if (!(event.target instanceof HTMLElement)) {
                return;
            }
            if (event.target.getAttribute('data-react-trigger') !== 'planning-popup') {
                return;
            }
            if (!event.target.hasAttribute('data-react-data')) {
                return;
            }
            const dataString = event.target.getAttribute('data-react-data');
            if (!dataString) {
                return;
            }
            const data = JSON.parse(dataString);
            const syncData = PlanningPopupTwigConnectorInputDtoSchema.validateSync(data);

            setOpdrachtId(syncData.opdrachtId);
            open();
        });
    }, [open]);

    const onCloseHandler = useCallbackRef(() => {
        setOpdrachtId(null);
    });

    useEffect(() => {
        const subscription = onClose.subscribe(onCloseHandler);
        return () => subscription.unsubscribe();
    }, [onCloseHandler, onClose]);

    if (!opdrachtId) {
        return null;
    }

    return <PlanningPopupConnected params={{opdrachtId, opdrachtnummer: '123'}} />;
};

export const PlanningPopupTwigConnector = () => (
    <MemoryPopupProvider expanded>
        <PlanningPopupTwigConnectorContent />
    </MemoryPopupProvider>
);
