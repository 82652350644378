import React, {FC} from 'react';
import {FilterableTakenTableConnected} from '@growthbase/taken';
import {DefaultRelatieValue} from '@growthbase/spa';
import {useRelatieTakenOverzichtQuery} from '@growthbase/graphql';
import {useTranslation} from 'react-i18next';
import {useNormalizeRelatieValues} from '../../RelatieButton';

export interface OverzichtConnectedProps {
    relatie: string;
}

export const OverzichtConnected: FC<OverzichtConnectedProps> = ({relatie}) => {
    const {t} = useTranslation('Relaties.nl');

    const {data} = useRelatieTakenOverzichtQuery({
        variables: {
            id: relatie,
        },
    });
    const normalized = useNormalizeRelatieValues(data);
    if (!data) {
        return null;
    }
    return (
        <DefaultRelatieValue.Provider id="OverzichtConnected" value={normalized}>
            <FilterableTakenTableConnected relatie={relatie} title={t('TakenVanRelatie')} />
        </DefaultRelatieValue.Provider>
    );
};
