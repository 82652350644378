import {ActionsMenuButton} from '@growthbase/design-components';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {BordRoutes} from '@growthbase/takenlijst/bord';

export interface ActionsMenuLijstenToevoegenButtonProps {
    bordId: string;
}

export const ActionsMenuLijstToevoegenButton = ({bordId}: ActionsMenuLijstenToevoegenButtonProps) => {
    const onClick = BordRoutes.lijst_aanmaken.useNavigate({
        bordId,
    });
    const {t} = useTranslation('TakenlijstLijst.nl');
    return <ActionsMenuButton onClick={onClick}>{t('lijstAanmaken')}</ActionsMenuButton>;
};
