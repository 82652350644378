import {ValueType, ValueTypeInterface, FactoryProps, FieldInstance} from '../contract';
import {createFieldInstance} from '../Utils';

export const ConditionsValueType: ValueTypeInterface<FieldInstance[]> = {
    defaultValue: (props: FactoryProps): FieldInstance[] => [createFieldInstance(props, props.fields[0])],
    type: ValueType.conditions,
    isEmpty: (value: FieldInstance[], options: FactoryProps): boolean => {
        const {fields} = options;
        for (const condition of value) {
            const field = fields.find(({name}) => name === condition.name);
            if (!field) {
                throw new Error(`Field ${condition.name} not found`);
            }
            if (!field.valueType.isEmpty(condition.value, options)) {
                return false;
            }
        }
        return true;
    },
    convertToNewValueType: ({fields, previousValue}) => {
        if (previousValue.type === ValueType.conditions) {
            return previousValue.value as FieldInstance[];
        }
        if (previousValue.type === ValueType.condition) {
            return [previousValue.value as FieldInstance];
        }
        return [
            {
                ...previousValue,
                id: Math.random().toString(36).substring(7),
            },
        ];
    },
};
