import {FC, ReactNode} from 'react';
import {ButtonProps, Buttons, FormikFieldProps} from '@growthbase/design-components';
import {useField} from 'formik';

export interface ButtonFormElementProps extends FormikFieldProps<boolean> {
    tooltip?: ReactNode;
    Button?: FC<ButtonProps>;
}

export const ButtonFormElement: FC<ButtonFormElementProps> = ({
    field: {...field},
    disabled,
    readonly,
    onBlur,
    Button = Buttons.CheckIconDotted,
    tooltip,
}) => {
    const [, {value}, {setTouched, setValue}] = useField(field.name);
    return (
        <Button
            {...field}
            active={value || false}
            readonly={readonly}
            disabled={disabled}
            tooltip={tooltip}
            onClick={() => {
                setValue(!value);
                setTouched(true);
                if (onBlur) {
                    onBlur();
                }
            }}
        />
    );
};
