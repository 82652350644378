import {ViewFormat} from '@growthbase/planning';

export const AgendaDayView: ViewFormat = {
    group: 'agenda-agenda',
    id: 'agenda-agenda-dag',
    label: 'planning.dag',
    smallDates: true,
    view: {
        schedule: {
            type: 'day',
            size: 1,
            startTime: '05:00',
            endTime: '22:00',
            days: false,
        },
    },
};
export const AgendaWeekView: ViewFormat = {
    group: 'agenda-agenda',
    id: 'agenda-agenda-week',
    label: 'planning.week',
    smallDates: true,
    view: {
        schedule: {
            type: 'week',
            startDay: 1,
            endDay: 5,
            startTime: '05:00',
            endTime: '22:00',
            days: true,
        },
    },
};
export const AgendaMonthView: ViewFormat = {
    group: 'agenda-agenda',
    id: 'agenda-agenda-maand',
    label: 'planning.maand',
    smallDates: true,
    active: true,
    preferred: true,
    autoPlan: true,
    view: {
        calendar: {
            weekNumbers: false,
            outerDays: true,
            type: 'month',
        },
    },
};

export const AgendaAgendaViewFormats: ViewFormat[] = [AgendaDayView, AgendaWeekView, AgendaMonthView];
