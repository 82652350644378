import {useCallback, useMemo} from 'react';
import {Observable, Subject} from 'rxjs';
import {CloseEvent} from '../PopupControls';

export function useOnCloseSubject(): [Observable<CloseEvent>, () => boolean] {
    const onClose: Subject<CloseEvent> = useMemo(() => new Subject<CloseEvent>(), []);

    const isCanceled = useCallback(() => {
        const event: CloseEvent = {
            cancel: false,
        };
        onClose.next(event);
        return event.cancel;
    }, [onClose]);

    return [onClose, isCanceled];
}
