import {ReactNode, useMemo} from 'react';
import {DropZoneChildren, DropZone} from '@growthbase/design-elements';
import {DND, DNDActiveState, onDropItemHandler, RenderDraggableItem, RenderDraggableItemProps} from '@growthbase/keys';

import {useTranslation} from 'react-i18next';

import {useCallbackRef} from '@growthbase/spa';
import {toast} from 'react-toastify';

export const BijlageDNDName = 'TaakBijlage' as const;

export interface Bijlage {
    id: string;
    availableActions: {
        kanPositieVeranderen: boolean;
    };
}

export interface BijlageContainer {
    id: string;
    taakId: string;
}

export interface BijlagesProps<T extends Bijlage = Bijlage> {
    taakId: string;
    bijlages: T[];
    kanToevoegen: boolean;
    renderBijlage: (bijlage: T) => ReactNode;
    onDrop: (acceptedFiles: File[]) => void;
    onMove: onDropItemHandler<T, BijlageContainer>;
    children: (
        props: DropZoneChildren & {
            bijlages: ReactNode;
            kanToevoegen: boolean;
        }
    ) => ReactNode;
}

function acceptBijlage<T extends Bijlage>(
    accept: DNDActiveState
): accept is DNDActiveState<typeof BijlageDNDName, T, BijlageContainer> {
    const {draggedItem} = accept;
    return (draggedItem as T)?.availableActions?.kanPositieVeranderen ?? false;
}

export function Bijlages<T extends Bijlage>({
    renderBijlage: render,
    children,
    onDrop,
    bijlages,
    taakId,
    onMove,
    kanToevoegen,
}: BijlagesProps<T>) {
    const {t} = useTranslation('TaakBijlage.nl');

    const renderBijlage: RenderDraggableItem<typeof BijlageDNDName, T> = ({
        item: {userData: bijlage},
        dragging,
        anchor,
        element,
    }: RenderDraggableItemProps<typeof BijlageDNDName, T> & {
        dragging?: boolean;
    }) => (
        <div {...element} {...anchor}>
            {render(bijlage)}
        </div>
    );

    const bijlagesDND = (
        <DND.Container<typeof BijlageDNDName, T, BijlageContainer>
            userData={useMemo(
                () => ({
                    id: `bijlage-${taakId}`,
                    taakId,
                }),
                [taakId]
            )}
            userDataType={BijlageDNDName}
            renderDraggedItem={renderBijlage}
            items={bijlages}
            onDrop={onMove}
            accept={acceptBijlage}
            // Moving bijlages from 1 taak to a different taak is not supported
            onRemove={useCallbackRef((item: T) => {
                toast.warning(`Removed ${item.id} from ${taakId}`);
                return Promise.reject(new Error('Not supported yet'));
            })}
        >
            {({items: draggedItems, element}) => (
                <div {...element}>
                    {draggedItems.map((item) => (
                        <DND.Item item={item} key={item.id}>
                            {renderBijlage}
                        </DND.Item>
                    ))}
                </div>
            )}
        </DND.Container>
    );

    return (
        <DropZone dropText={t('dropText')} onDrop={onDrop}>
            {({openInput}) =>
                children({
                    openInput,
                    kanToevoegen,
                    bijlages: <div style={{marginBottom: 'var(--spacingDefault)'}}>{bijlagesDND}</div>,
                })
            }
        </DropZone>
    );
}
