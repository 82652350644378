import {ILijsttemplateLijstMenuFragment} from '@growthbase/graphql';
import {FC} from 'react';
import {ActionsMenuButton} from '@growthbase/design-components';
import {Menu} from './Menu';
import {VerwijderenButtonConnected, BewerkButton} from './Components';
import {KopieerButtonConnected} from '../Kopieren';

export interface MenuConnectedProps {
    fragment: ILijsttemplateLijstMenuFragment;
    onCopied?: (id: string) => void;
}

export const MenuConnected: FC<MenuConnectedProps> = ({fragment, onCopied}) => (
    <Menu
        verwijderLijstMenuButton={<VerwijderenButtonConnected Button={ActionsMenuButton} fragment={fragment} />}
        bewerkLijstMenuButton={<BewerkButton Button={ActionsMenuButton} id={fragment.id} />}
        kopierButton={<KopieerButtonConnected onCopied={onCopied} Button={ActionsMenuButton} fragment={fragment} />}
    />
);
