import * as React from 'react';
import {FC} from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {ILogLevel} from '@growthbase/graphql';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    ILogLevel.Emergency,
    ILogLevel.Critical,
    ILogLevel.Error,
    ILogLevel.Alert,
    ILogLevel.Warning,
    ILogLevel.Notice,
    ILogLevel.Info,
    ILogLevel.Debug,
];

export interface LogLevelSelectProps {
    onChange: (value: ILogLevel[]) => void;
    value: ILogLevel[];
}

export const LogLevelSelect: FC<LogLevelSelectProps> = ({onChange, value: input}) => {
    const handleChange = (event: SelectChangeEvent<typeof input>) => {
        const {
            target: {value},
        } = event;
        onChange(
            typeof value === 'string'
                ? value.split(',').map(
                      (val) =>
                          // @ts-expect-error This is fine.
                          ILogLevel[val]
                  )
                : value
        );
    };

    return (
        <div>
            <FormControl sx={{m: 1, width: 300}}>
                <InputLabel hidden id="LogLevel label">
                    LogLevel
                </InputLabel>
                <Select
                    labelId="LogLevel label"
                    multiple
                    value={input}
                    onChange={handleChange}
                    input={<OutlinedInput label="LogLovel" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    {names.map((name) => (
                        <MenuItem key={name} value={name}>
                            <Checkbox checked={input.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};
