import {useField} from 'formik';
import {FC} from 'react';
import {FormikFieldProps} from '../FieldWrapper';
import {TimeField} from './TimeField';

export interface TimeFieldFormikComponentProps extends FormikFieldProps<string> {
    initialValue?: string;
}

export const TimeFieldFormikComponent: FC<TimeFieldFormikComponentProps> = ({
    field,
    form,
    meta,
    initialValue,
    ...props
}) => {
    const [, , {setValue}] = useField(field.name);
    return <TimeField {...field} onChange={setValue} />;
};
