import {ButtonGroup, IconButton, Icons, IconSize, TableHeader, TableHeaderSection} from '@growthbase/design-components';
import {DND, DNDItemData, useOnDragMoveButtonHandlers} from '@growthbase/keys';
import {ReactNode} from 'react';
import {RenderTabelType} from '@growthbase/takentemplates-taaktemplate';
import {LijsttemplateDragType, ItemData} from '../../../../types';
import styles from './SortableTabel.module.scss';

export interface SortableTakenTabelLijstProps<T extends ItemData> {
    item: DNDItemData<T, LijsttemplateDragType>;
    renderTabel: RenderTabelType;
    menu: ReactNode;
}

export function SortableTabel<T extends ItemData>({renderTabel, item, menu}: SortableTakenTabelLijstProps<T>) {
    const {onUp, onDown} = useOnDragMoveButtonHandlers<ItemData>({
        itemId: item.userData.id,
        type: item.userDataType,
    });
    return (
        <DND.Item disabled={!(onUp || onDown)} item={item}>
            {({element, anchor, dragging}) => (
                <div {...element} style={{opacity: dragging ? 0.5 : 1}}>
                    {renderTabel({
                        header: (
                            <TableHeader className={styles.SortableTakenTabelLijst__header}>
                                <div {...anchor} className={styles.SortableTakenTabelLijst__draghandle}>
                                    <TableHeaderSection grow testId={item.userData.naam}>
                                        {item.userData.naam}
                                    </TableHeaderSection>
                                </div>
                                <TableHeaderSection>
                                    <ButtonGroup disableRightMargin>
                                        {onUp && (
                                            <IconButton
                                                Icon={Icons.ArrowUp}
                                                iconSize={IconSize.SIZE_3}
                                                color="gray-light"
                                                onClick={onUp}
                                            />
                                        )}
                                        {onDown && (
                                            <IconButton
                                                Icon={Icons.ArrowDown}
                                                iconSize={IconSize.SIZE_3}
                                                color="gray-light"
                                                onClick={onDown}
                                            />
                                        )}
                                        {menu}
                                    </ButtonGroup>
                                </TableHeaderSection>
                            </TableHeader>
                        ),

                        filters: false,
                    })}
                </div>
            )}
        </DND.Item>
    );
}
