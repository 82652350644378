import {createMentionPlugin} from '@udecode/plate';
import {TComboboxItem} from '@udecode/plate-combobox/dist/types';
import {FC} from 'react';
import {OnderdeelVanMention} from './OnderdeelVanMention';
import {
    ComboboxOnderdeelVanItem,
    OnderdeelVanComboboxConnected,
    OnderdeelVanComboboxStub,
    OnderdeelVanMentionKey,
    OnderdeelVanMentionTrigger,
} from './OnderdeelVanCombobox';

const createOnderdeelVanMentionPlugin = (searchBar: FC) =>
    createMentionPlugin({
        key: OnderdeelVanMentionKey,
        component: OnderdeelVanMention,
        renderAfterEditable: searchBar,
        options: {
            createMentionNode: (mention) => ({
                item: (mention as TComboboxItem<ComboboxOnderdeelVanItem>).data,
                children: [{text: ''}],
                type: OnderdeelVanMentionKey,
                onderdeelVanId: mention.key,
            }),
            id: OnderdeelVanMentionKey,
            trigger: OnderdeelVanMentionTrigger,
        },
    });

export const createOnderdeelVanMentionPluginStub = () => createOnderdeelVanMentionPlugin(OnderdeelVanComboboxStub);
export const createOnderdeelVanMentionPluginConnected = () =>
    createOnderdeelVanMentionPlugin(OnderdeelVanComboboxConnected);
