import {HiddenInstelling, Instellingen, InstellingFieldsInput, TextAreaInstellingInput} from '@growthbase/instellingen';
import {FC, useMemo} from 'react';
import {IFixEventstreamType} from '@growthbase/graphql';

export type FixEventInput = {
    profielId: string;
    aggregate: string;
    uuid: string;
    playhead: number;
    persist: boolean;
    update: string;
};

export const fixEventInputFields: InstellingFieldsInput<FixEventInput> = {
    aggregate: {
        label: 'Aggregate',
        defaultValue: '',
    },
    uuid: {
        label: 'UUID',
        defaultValue: '',
    },
    playhead: {
        label: 'Playhead',
        defaultValue: 0,
    },
    profielId: {
        label: 'Profiel ID',
        defaultValue: '',
    },
    persist: {
        label: 'Persist (Om automatisch bij productie uit te voeren)',
        defaultValue: true,
    },
    update: {
        label: 'Update',
        defaultValue: '',
        InputComponent: TextAreaInstellingInput,
    },
};

export interface FixEventFormProps {
    onSubmit: (values: FixEventInput) => Promise<void>;
    values: FixEventInput;
    type: IFixEventstreamType;
}

export const FixEventForm: FC<FixEventFormProps> = ({onSubmit, values, type}) => (
    <Instellingen<FixEventInput>
        titel="Replay opties"
        view={false}
        fields={useMemo(
            () => ({
                ...fixEventInputFields,
                update: {
                    label: 'Update',
                    defaultValue: '',
                    InputComponent: type === IFixEventstreamType.Deprecate ? HiddenInstelling : TextAreaInstellingInput,
                },
            }),
            [type]
        )}
        onSubmit={onSubmit}
        values={values}
    />
);
