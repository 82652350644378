import {useLogger} from '@growthbase/spa';
import {FC} from 'react';
import {useApolloClient} from '@apollo/client';
import {useDispatch} from 'react-redux';
import {
    useApplyModifyCacheFromEntityChanged,
    useExternalEntityAdded,
    useExternalEntityChanged,
    useExternalEntityFieldsInvalidated,
    useExternalEntityRemoved,
} from '../Hook';
import {useExternalEntityChangeOrder} from '../Hook/MessageListener/useExternalEntityChangeOrder';

const useWrapWithType =
    <TArgs extends object>(type: string, dispatch: (object: TArgs & {type: string}) => void) =>
    (args: TArgs) =>
        dispatch({
            type,
            ...args,
        });

export const CacheUpdate: FC = () => {
    const apollo = useApolloClient();
    const dispatch = useDispatch();
    useApplyModifyCacheFromEntityChanged(apollo);

    const logger = useLogger('CacheUpdate');

    const log = (args: {type: string} & object) => {
        dispatch({
            ...args,
            type: `client_entity_${args.type}`,
        });
        logger.debug('Client entity message:', args);
    };

    useExternalEntityAdded(useWrapWithType('added', log));
    useExternalEntityRemoved(useWrapWithType('removed', log));
    useExternalEntityChanged(useWrapWithType('changed', log));
    useExternalEntityFieldsInvalidated(useWrapWithType('invalidate', log));
    useExternalEntityChangeOrder(useWrapWithType('change_order', log));

    return null;
};
