import React, {Fragment, ReactNode} from 'react';
import {ShowOnRowHover, Stack} from '@growthbase/design-components';
import {PositionType} from './Item';

export interface ListStyleOptions {
    divider?: boolean;
    cellMinWidth?: number;
}

export interface StyleOptions {
    start?: ListStyleOptions;
    end?: ListStyleOptions;
}

export interface OrderApi {
    /**
     * Always add the children, if it should not render give false or null to the
     * node.
     *
     * @param node
     * @param target
     */
    add(node: ReactNode, target: PositionType | boolean): void;
}
// eslint-disable-next-line default-param-last
export const createOrderedItems = (options: StyleOptions = {}, factory: (api: OrderApi) => void) => {
    const start: ReactNode[] = [];
    const end: ReactNode[] = [];

    // The items are always added in the same order, so the key stays the same.
    let key = 0;
    const add = (item: ReactNode, targetIntput: PositionType | boolean) => {
        const resolve = (): PositionType => {
            if (targetIntput === true) {
                return PositionType.start;
            }
            if (targetIntput === false) {
                return PositionType.showOnHooverEnd;
            }
            return targetIntput;
        };
        const target = resolve();
        key += 1;
        if (!item) {
            return;
        }
        let list: ReactNode[];
        let listOptions: ListStyleOptions;
        switch (target) {
            case PositionType.start:
                list = start;
                listOptions = options.start ?? {};
                break;
            case PositionType.showOnHooverEnd:
                item = <ShowOnRowHover>{item}</ShowOnRowHover>;
                list = end;
                listOptions = options.end ?? {};
                break;
            case PositionType.end:
                list = end;
                listOptions = options.end ?? {};
                break;
            case PositionType.none:
                return;
            default:
                throw new Error('Not supported position type');
        }
        list.push(
            <Fragment key={key.toString()}>
                {!!(listOptions.divider && list.length) && <Stack.Divider />}
                <Stack.Item shrink pushItems={list.length === 0 && PositionType.end === target ? 'before' : undefined}>
                    {item}
                </Stack.Item>
            </Fragment>
        );
    };

    factory({add});

    if (options.start?.divider && start.length && options.end?.divider && end.length) {
        end.push(<Stack.Divider />);
    }

    return [...start, ...end];
};
