import {Center, Columns, Flex, useTableFormFooter} from '@growthbase/design-components';
import {ITaakTabelFragment} from '@growthbase/graphql';
import {useKeys} from '@growthbase/keys';
import {RelatieInputConnected, RelatieInputConnectedProps} from '@growthbase/relaties';
import React, {FC, PropsWithChildren} from 'react';
import {useTranslation} from 'react-i18next';
import {DeelnemersInputConnected} from '@growthbase/gebruikers';
import {CreateTaakFormProps, useCreateTaakForm} from '../../TaakCreateForm/Hook/useCreateTaakForm';
import {MaakTaakAanTaakFormDTO} from '../../TaakCreateForm';
import {TaakPlanningInput} from '../../TaakPlanningInput';
import {KoppelButtonInputConnected} from '../../Koppelen';

export interface AddTaakFooterOptions extends CreateTaakFormProps {
    columns: Columns<ITaakTabelFragment>;
    OnderdeelVanComponent?: typeof KoppelButtonInputConnected;
}

const RelatieInputInlineConnected = (props: RelatieInputConnectedProps) => <RelatieInputConnected inline {...props} />;

const CenterCell: FC<PropsWithChildren> = ({children}) => (
    <Flex.Column>
        <Flex.Item>
            <div style={{height: 40}}>
                <Center vertical>{children}</Center>
            </div>
        </Flex.Item>
    </Flex.Column>
);

export const useAddTaakFooterForm = ({
    columns,
    OnderdeelVanComponent = KoppelButtonInputConnected,
    ...rest
}: AddTaakFooterOptions) => {
    const {t} = useTranslation('spa_form.nl');

    const {
        Form,
        Fields: {DeelnemersField, PlanningField, OnderdeelVanField, RelatieField},
        formik,
        formProps,
        renderTitel,
        render,
    } = useCreateTaakForm({
        autoSubmit: false,
        createInitialValuesAfterSubmit: true,
        doNoWaitForSubmit: true,
        ...rest,
    });
    const Deelnemers = DeelnemersField.useComponent(DeelnemersInputConnected);
    const Planning = PlanningField.useComponent(TaakPlanningInput);

    const {close, isOpen, ...other} = useTableFormFooter<ITaakTabelFragment, MaakTaakAanTaakFormDTO>({
        columns,
        formik,
        fields: {
            titel: (
                <Flex.Column smallGap>
                    <Flex.Item>
                        {renderTitel({
                            variant: 'text',
                            autoFocus: true,
                            hideWarningOnFocusLost: true,
                        })}
                    </Flex.Item>
                    {(formik.values.relatie.bedrijf || formik.values.relatie.persoon) && (
                        <Flex.Item>
                            <RelatieField component={RelatieInputInlineConnected} />
                        </Flex.Item>
                    )}
                    {formik.values.onderdeelVan && (
                        <Flex.Item>
                            <OnderdeelVanField component={OnderdeelVanComponent} />
                        </Flex.Item>
                    )}
                </Flex.Column>
            ),
            deelnemers: (
                <CenterCell>
                    <Deelnemers stackSize={4} size="small" />
                </CenterCell>
            ),
            // @ts-expect-error This is dynamic column
            deadline: (
                <CenterCell>
                    <Planning asText />
                </CenterCell>
            ),
        },
        addText: t('taak.add'),
    });

    useKeys('TaakTabel: close footer form', 'Escape', close, {
        enabled: isOpen,
    });

    return {Form, render, formProps, close, ...other};
};
