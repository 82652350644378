import React, {ReactNode} from 'react';
import styles from './SidenavSection.module.scss';
import {Scrollable, ScrollableTheme} from '../../Scrollable';

export interface SidenavSectionProps {
    children: ReactNode;
    scrollable?: boolean;
    scrollableTheme?: ScrollableTheme;
}

export const SidenavSection = ({children, scrollable, scrollableTheme}: SidenavSectionProps) => {
    if (scrollable) {
        return (
            <div className={styles.sidenavSection__scrollWrapper}>
                <Scrollable theme={scrollableTheme} maxHeight={-1} fillParent>
                    <div className={`${styles.sidenavSection} ${styles.sidenavSection}`}>{children}</div>
                </Scrollable>
            </div>
        );
    }

    return <div className={styles.sidenavSection}>{children}</div>;
};
