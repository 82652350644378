import {ButtonProps, Buttons} from '@growthbase/design-components';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {BordRoutes} from '@growthbase/takenlijst/bord';

export interface BewerkButtonProps {
    id: string;
    Button?: FC<ButtonProps>;
    globalText?: boolean;
}

export const BewerkButton: FC<BewerkButtonProps> = ({id, globalText, Button = Buttons.EditPencil}) => {
    const {t} = useTranslation('TakenlijstBord.nl');
    const title = globalText ? t('bordBewerken') : t('bewerken');
    return (
        <Button
            key="add"
            onClick={BordRoutes.bewerken.useNavigate({
                bordId: id,
            })}
            tooltip={title}
            ariaLabel={title}
        >
            {title}
        </Button>
    );
};
