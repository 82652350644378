import React, {FC, ReactNode} from 'react';

export interface TabsRendererProps {
    header: ReactNode;
    content: ReactNode;
}

export const TabsRenderer: FC<TabsRendererProps> = ({header, content}) => (
    <>
        {header}
        {content}
    </>
);
