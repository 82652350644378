/* eslint-disable no-underscore-dangle */

import {FieldMergeFunction, StoreObject} from '@apollo/client';

export interface Edge {
    placeholderPosition?: boolean;

    node?: {
        __ref: string;
    };
}

export interface Connection extends StoreObject {
    edges?: (Edge | null)[];
}

export type OC = Connection | undefined;

type PreservedItem = {placeholder: Edge; refsBefore: string[]; refsAfter: string[]};

function findPlaceholder(edges: (Edge | null)[], incoming: OC): PreservedItem[] {
    const preserve: Array<{
        placeholder: Edge;
        refsBefore: string[];
        refsAfter: string[];
    }> = [];
    const refsBefore: string[] = [];
    let i = 0;
    for (const edge of edges) {
        if (edge?.placeholderPosition) {
            const foundInIncoming = incoming?.edges?.find((e) => e?.node?.__ref === edge?.node?.__ref);
            if (!foundInIncoming) {
                preserve.push({
                    refsBefore: [...refsBefore],
                    placeholder: edge,
                    refsAfter: [...edges].splice(i + 1).map((e) => e?.node?.__ref ?? ''),
                });
            }
        }
        refsBefore.push(edge?.node?.__ref ?? '');
        i += 1;
    }
    return preserve;
}

function addPreservedToIncomingEdges(incoming: Connection, preserve: PreservedItem[]) {
    const newEdges = [...(incoming.edges ?? [])];
    // splice the preserve items into the incoming edges
    for (const {placeholder, refsBefore: before, refsAfter: after} of preserve) {
        const maxOffset = Math.max(before.length, after.length);
        let added = false;
        for (let offset = 0; offset < maxOffset; offset += 1) {
            const beforeRef = before[before.length - offset - 1];
            const afterRef = after[offset];
            if (beforeRef) {
                const index = newEdges.findIndex((e) => e?.node?.__ref === beforeRef);
                if (index >= 0) {
                    added = true;
                    newEdges.splice(index + 1, 0, placeholder);
                    break;
                }
            }
            if (afterRef) {
                const index = newEdges.findIndex((e) => e?.node?.__ref === afterRef);
                if (index >= 0) {
                    added = true;
                    newEdges.splice(index, 0, placeholder);
                    break;
                }
            }
        }
        if (!added) {
            newEdges.push(placeholder);
        }
    }
    return newEdges;
}

/**
 * Preserve placeholderPosition items during refresh, so they don't disappear. Keep the placeholderPosition items
 * close to the node they previously belonged to.
 */
export function preservePlaceholderEdges(existing: OC, incoming: OC, mergeObjects: (e: OC, i: OC) => OC): OC {
    if (!existing) {
        return mergeObjects(existing, incoming);
    }
    const edges = existing.edges ?? [];
    const preserve = findPlaceholder(edges, incoming);
    if (preserve.length === 0) {
        return mergeObjects(existing, incoming);
    }
    if (!incoming) {
        incoming = {
            edges: [],
        };
    }
    const newEdges = addPreservedToIncomingEdges(incoming, preserve);
    return mergeObjects(existing, {
        ...incoming,
        edges: newEdges,
    });
}

export const FieldMergePlaceholderEdges: FieldMergeFunction = (inc, exl, {mergeObjects}) =>
    // @ts-expect-error We don't mind this type error, we do not accept anything else.
    preservePlaceholderEdges(inc, exl, mergeObjects);
