import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useRequestUploadUrlMutation, useUploadKlaarMutation} from '@growthbase/graphql';
import axios from 'axios';
import {toast} from 'react-toastify';
import {v4} from 'uuid';
import {useLogger} from '@growthbase/spa';

export interface UploadBijlagesOptions {
    taakId: string;
}

export const useUploadBijlages = ({taakId}: UploadBijlagesOptions) => {
    const {t} = useTranslation('TaakBijlage.nl');
    const logger = useLogger('useUploadBijlages');

    const [mutate] = useRequestUploadUrlMutation();
    const [klaar] = useUploadKlaarMutation();

    const onDrop = useCallback(
        async (acceptedFiles: File[]) => {
            const uploadFile = async (file: File) => {
                const bijlageId = v4();
                const toastId = toast(t('upload', {name: file.name}), {
                    autoClose: false,
                    progress: 0,
                });

                const bestandId = v4();
                const {data} = await mutate({
                    variables: {
                        input: {
                            naam: file.name,
                            bestandId,
                            bijlageId,
                            taakId,
                        },
                    },
                });

                if (!data) {
                    toast.update(toastId, {
                        type: 'error',
                        autoClose: 5000,
                        render: t('uploadError', {name: file.name}),
                    });
                    return;
                }
                const {uploadUrl} = data.MaakBijlageAan;
                await axios
                    .request({
                        method: 'PUT',
                        url: uploadUrl,
                        data: file,
                        onUploadProgress: (p): void => {
                            const progress = p.total !== undefined ? p.loaded / p.total : null;
                            if (progress === null || !toastId) {
                                return;
                            }
                            // check if we already displayed a toast
                            toast.update(toastId, {progress});
                        },
                    })
                    .finally(async () => {
                        if (!toastId) {
                            throw new Error('toastId is not set');
                        }
                        const {data: klaarData} = await klaar({variables: {input: {bestandId}}});
                        if (!klaarData) {
                            toast.update(toastId, {
                                type: 'error',
                                autoClose: 5000,
                                render: t('uploadError', {name: file.name}),
                            });
                            return;
                        }
                        const {isSuccessful} = klaarData.UploadKlaar;
                        if (!isSuccessful) {
                            toast.update(toastId, {
                                type: 'error',
                                autoClose: 5000,
                                render: t('uploadError', {name: file.name}),
                            });
                            return;
                        }
                        toast.done(toastId);
                    });
            };

            for (const file of acceptedFiles) {
                // eslint-disable-next-line no-await-in-loop
                await uploadFile(file).catch((e) => {
                    logger.error(e);
                });
            }
        },
        [taakId, t, mutate, klaar, logger]
    );

    return {onDrop};
};
