import {CreateRouteFunction, createRoutes, PageFromMenu, RouteElementProps} from '@growthbase/routing';
import {generatePath} from 'react-router-dom';
import React from 'react';
import {RoutePopupProvider} from '@growthbase/design-components';
import {ModelDetails} from './Models/ModelDetails';
import {SyncPage} from './SyncPage';
import {ModelsTable} from './Models/ModelsTable';
import {EmailFormulierConnected} from './Email/EmailFormulierConnected';
import {HealthPage} from './Health';
import {ProjectionsPage, ProjectionDetails} from './Projections';
import {LogsTableConnected} from './Projections/LogsTable';
import {ProcessDetails} from './Projections/ProcessDetails';
import {ProcessesTable} from './Projections/ProcessesPage';
import {ProfielenTable} from './Projections/ProfielenPage';
import {ProfielDetails} from './Projections/ProfielDetails';
import {TotalStats} from './Projections/TotalStats/TotalStats';
import {ApplicationLogsTableConnected} from './Logs/ApplicationLogsTable';
import {ApplicationLogConnected} from './Logs/ApplicationLog/ApplicationLogConnected';
import {DashboardConfig} from './DashboardConfig';

const generateModelDetailRoute: CreateRouteFunction<{
    name: string;
}> = (route: string, params) =>
    generatePath(route, {
        name: encodeURI(params?.name ?? 'unknown'),
    });

const generateLogRoute: CreateRouteFunction<{
    id: string;
}> = (route: string, params) =>
    generatePath(route, {
        id: encodeURI(params?.id ?? 'unknown'),
    });

const generateProjectionsDetailRoute: CreateRouteFunction<{
    id: string;
}> = (route: string, params) =>
    generatePath(route, {
        id: encodeURI(params?.id ?? 'unknown'),
    });

export const DeveloperRoutes = createRoutes('developer', {
    health: {
        Element: () => (
            <PageFromMenu sidebar="remove">
                <HealthPage />
            </PageFromMenu>
        ),
    },
    models: {
        Element: () => (
            <PageFromMenu sidebar="remove">
                <ModelsTable />
            </PageFromMenu>
        ),
    },
    email: {
        Element: () => (
            <PageFromMenu sidebar="remove">
                <EmailFormulierConnected />
            </PageFromMenu>
        ),
    },
    models_detail: {
        createRoute: generateModelDetailRoute,
        Element: ({params}: RouteElementProps<{name: string}>) => (
            <PageFromMenu sidebar="remove">
                <ModelDetails name={params?.name ?? 'unknown'} />
            </PageFromMenu>
        ),
    },
    projections: {
        Element: () => (
            <PageFromMenu sidebar="remove">
                <TotalStats />
                <ProjectionsPage />
            </PageFromMenu>
        ),
    },
    replayprocesses: {
        Element: () => (
            <PageFromMenu sidebar="remove">
                <TotalStats />
                <ProcessesTable />
            </PageFromMenu>
        ),
    },
    projections_detail: {
        createRoute: generateProjectionsDetailRoute,
        Element: ({params}: RouteElementProps<{id: string}>) => (
            <PageFromMenu sidebar="remove">
                <ProjectionDetails id={params?.id ?? 'unknown'} />
            </PageFromMenu>
        ),
    },
    process_detail: {
        createRoute: generateProjectionsDetailRoute,
        Element: ({params}: RouteElementProps<{id: string}>) => (
            <PageFromMenu sidebar="remove">
                <ProcessDetails id={parseInt(params?.id ?? '', 10)} />
            </PageFromMenu>
        ),
    },
    projections_dashboard: {
        Element: () => (
            <PageFromMenu sidebar="remove">
                <TotalStats />
                <LogsTableConnected />
            </PageFromMenu>
        ),
    },
    sync: {
        Element: () => (
            <PageFromMenu sidebar="remove">
                <SyncPage />
            </PageFromMenu>
        ),
    },
    replay_profile_detail: {
        createRoute: generateProjectionsDetailRoute,
        Element: ({params}: RouteElementProps<{id: string}>) => (
            <PageFromMenu sidebar="remove">
                <ProfielDetails id={params?.id ?? ''} />
            </PageFromMenu>
        ),
    },
    replayprofiles: {
        Element: () => (
            <PageFromMenu sidebar="remove">
                <ProfielenTable />
            </PageFromMenu>
        ),
    },
    logs: {
        Element: () => (
            <PageFromMenu sidebar="remove">
                <ApplicationLogsTableConnected />
            </PageFromMenu>
        ),
    },
    log: {
        defaultBackgroundRoute: 'logs',
        createRoute: generateLogRoute,
        Element: ({params}: RouteElementProps<{id: string}>) => (
            <RoutePopupProvider>
                <ApplicationLogConnected id={params?.id ?? '-'} />
            </RoutePopupProvider>
        ),
    },
    config: {
        Element: () => (
            <PageFromMenu sidebar="remove">
                <DashboardConfig />
            </PageFromMenu>
        ),
    },
});
