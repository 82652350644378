import {FC} from 'react';
import {IconButton, Icons, IconSize} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';
import {useCallbackRef} from '@growthbase/spa';
import {useBoolean} from 'react-use';

export interface PriveFieldProps {
    isPrivate: boolean;
    onSubmit: (value: boolean) => Promise<void>;
}

export const PriveField: FC<PriveFieldProps> = ({onSubmit, isPrivate}) => {
    const {t} = useTranslation('Taken.nl');

    const [loading, setLoading] = useBoolean(false);

    const handleOnClick = useCallbackRef(() => {
        setLoading(true);

        onSubmit(!isPrivate).then(() => {
            setLoading(false);
        });
    });

    return (
        <IconButton
            testId="TaakPriveButton"
            Icon={Icons.Lock}
            iconSize={IconSize.SIZE_5}
            onClick={handleOnClick}
            ariaLabel={t('taken.TaakMijnDagButton.label')}
            active={isPrivate}
            loading={loading}
        />
    );
};
