import React, {FC, ReactNode, useCallback} from 'react';
import {ConfirmModal} from '../ConfirmModal/ConfirmModal';
import {useMemoryPopupControls} from '../../../Popups';
import {BaseButtonProps, ButtonHandler} from '../../../Button';

export interface ConfirmModalButtonProps {
    header: ReactNode;
    children: ReactNode;
    popupButtonContent: ReactNode;
    onSubmit: ButtonHandler;
    disabled?: boolean;
    renderOpenButton: (props: BaseButtonProps) => ReactNode;
}

export const ConfirmModalButton: FC<ConfirmModalButtonProps> = ({
    onSubmit,
    renderOpenButton,
    disabled,
    children,
    popupButtonContent,
    ...modelProps
}) => {
    const [submitting, setSubmitting] = React.useState(false);
    const helpers = useMemoryPopupControls(false);

    const onSubmitAndCloseModel = useCallback(() => {
        setSubmitting(true);
        return Promise.resolve(onSubmit())
            .then(() => {
                setSubmitting(false);
                helpers.close();
            })
            .catch((error) => {
                setSubmitting(false);
                return Promise.reject(error);
            });
    }, [helpers, onSubmit, setSubmitting]);
    return (
        <>
            {renderOpenButton({onClick: helpers.open, disabled: submitting || disabled})}
            <ConfirmModal
                loading={submitting}
                onSubmit={onSubmitAndCloseModel}
                disabled={submitting || disabled}
                helpers={helpers}
                buttonContent={popupButtonContent}
                {...modelProps}
            >
                {children}
            </ConfirmModal>
        </>
    );
};
