import {FC} from 'react';
import {BaseButtonProps, Button as DefaultButton} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';
import {LijsttemplateRoutes} from '@growthbase/takentemplates-lijsttemplate';

export interface BewerkButtonProps {
    id: string;
    Button?: FC<BaseButtonProps>;
}

export const BewerkButton: FC<BewerkButtonProps> = ({id, Button = DefaultButton}) => {
    const {t} = useTranslation('Lijsttemplate.nl');
    return <Button onClick={LijsttemplateRoutes.bewerken.useNavigate({lijsttemplateId: id})}>{t('Bewerken')}</Button>;
};
