import React, {FC, PropsWithChildren, ReactNode, useMemo} from 'react';
import {AddBreadcrumb, NotFound, Page, TabButton, TabsHeader, TabsRenderer} from '@growthbase/design-components';
import {MenuItemDTO, useActiveMenuItemBreadcrumb} from '@growthbase/routing';
import {DynamicPosition, useDynamicContent} from '@growthbase/dynamiccontent';

export interface PageFromMenuProps extends PropsWithChildren {
    sidebar: ReactNode | 'preserve' | 'remove';
    renderPage?: typeof TabsRenderer;
    forceId?: string;
}

const TabButtonWithCounter = ({child, active}: {child: MenuItemDTO; active: boolean}) => {
    const badges = useDynamicContent({
        position: DynamicPosition.menuBadge,
        route: child.routeName,
    });
    return (
        <TabButton tab={{title: child.label, to: child.route}} isActive={active}>
            {badges}
        </TabButton>
    );
};

export const PageFromMenu: FC<PageFromMenuProps> = ({children, renderPage, forceId, sidebar}) => {
    const {breadcrumbs, activeRoute} = useActiveMenuItemBreadcrumb({forceId});
    const last = breadcrumbs[breadcrumbs.length - 1];
    const tabs = useMemo(() => {
        const tabsNodes: ReactNode[] = [];
        for (const item of breadcrumbs) {
            const itemTabs = [];
            let anyActive = false;
            for (const child of item.items) {
                if (child.representation.tab) {
                    const active = breadcrumbs.includes(child);
                    if (active) {
                        anyActive = true;
                    }
                    itemTabs.push(<TabButtonWithCounter key={child.id} child={child} active={active} />);
                }
            }
            if (itemTabs.length && anyActive) {
                tabsNodes.push(<TabsHeader key={item.id}>{itemTabs}</TabsHeader>);
            }
        }
        return tabsNodes;
    }, [breadcrumbs]);
    if (!last) {
        return <NotFound />;
    }
    const breadcrumbNodes = [];
    for (const item of breadcrumbs) {
        if (!item.representation.ignoreInBreadcrumb) {
            breadcrumbNodes.push(
                <AddBreadcrumb key={item.id} to={last === item ? activeRoute : item.route}>
                    {item.label}
                </AddBreadcrumb>
            );
        }
    }
    if (tabs.length) {
        return (
            <>
                {breadcrumbNodes}
                <Page sidebar={sidebar} header={tabs}>
                    {children}
                </Page>
            </>
        );
    }
    return (
        <>
            {breadcrumbNodes}
            {renderPage ? (
                renderPage({content: children, header: last.label})
            ) : (
                <Page sidebar={sidebar}>{children}</Page>
            )}
        </>
    );
};
