import {FC, useCallback} from 'react';
import {
    ITaakMijnDagButtonFragment,
    useTaakMijnDagButtonVerwijderTaakMutation,
    useTaakMijnDagButtonVoegTaakToeMutation,
} from '@growthbase/graphql';
import {useWrapLockItem} from '@growthbase/locking';
import {TaakMijnDagButton} from './TaakMijnDagButton';

export interface TaakMijnDagButtonConnectedProps {
    fragment: ITaakMijnDagButtonFragment;
}

export const TaakMijnDagButtonConnected: FC<TaakMijnDagButtonConnectedProps> = ({fragment}) => {
    const taakIsVoorVandaag = fragment?.taakIsVoorVandaag ?? false;

    const [executeVoegToe, {loading: voegToeLoading}] = useTaakMijnDagButtonVoegTaakToeMutation({
        variables: {
            input: {
                id: fragment?.id ?? '',
            },
        },
    });

    const [executeVerwijder, {loading: verwijderLoading}] = useTaakMijnDagButtonVerwijderTaakMutation({
        variables: {
            input: {
                id: fragment?.id ?? '',
            },
        },
    });

    const handleClick = useCallback(() => {
        if (taakIsVoorVandaag) {
            return executeVerwijder();
        }
        return executeVoegToe();
    }, [taakIsVoorVandaag, executeVerwijder, executeVoegToe]);

    const onClick = useWrapLockItem(fragment, handleClick);
    if (fragment.gearchiveerd) {
        return null;
    }

    return (
        <TaakMijnDagButton
            begindatumIsBereikt={fragment?.planning?.begindatumIsBereikt ?? false}
            deadlineIsBereikt={fragment?.planning?.deadlineIsBereikt ?? false}
            loading={voegToeLoading || verwijderLoading}
            onClick={onClick}
            active={!!fragment?.taakIsVoorVandaag}
        />
    );
};
