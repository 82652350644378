import {FC, ReactElement, ReactNode} from 'react';
import classNames from 'classnames/bind';
import styles from './InputWrapper.module.scss';
import {Buttons} from '../Button';

const cx = classNames.bind(styles);

export interface InputWrapperProps {
    children?: ReactNode;
    iconRight?: ReactElement;
    iconLeft?: ReactElement;
    showClearButton?: boolean;
    onClear?: () => void;
}

export const InputWrapper: FC<InputWrapperProps> = ({iconLeft, iconRight, children, showClearButton, onClear}) => (
    <div className={cx('inputWrapper')}>
        {iconLeft && <div className={cx('inputWrapper__icon', 'inputWrapper__icon--left')}>{iconLeft}</div>}
        {children}
        <div className={cx('inputWrapper__buttonWrapper')}>
            {showClearButton && (
                <Buttons.CrossIconOnly
                    className={cx('inputWrapper__clearButton')}
                    size="tiny"
                    onClick={() => onClear && onClear()}
                />
            )}
        </div>
        {iconRight && <div className={cx('inputWrapper__icon', 'inputWrapper__icon--right')}>{iconRight}</div>}
    </div>
);
