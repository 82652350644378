import {DNDContainerUserData, DNDItemUserData} from '@growthbase/keys';

export type LijsttemplateDragType = 'Lijsttemplate';
export const LijstDragTypeValue: LijsttemplateDragType = 'Lijsttemplate';

export interface ContainerData extends DNDContainerUserData {
    titel: string;
}

export interface ItemData extends DNDItemUserData {
    naam: string;
}

// eslint-disable-next-line no-shadow
export enum ViewType {
    Tabel = 'Tabel',
    Swimlane = 'Swimlane',
}

export interface Filters {
    searchQuery: string | null;
}
