import {FC} from 'react';
import {
    ActionsMenuButton,
    ButtonHandler,
    ConfirmModalButton,
    ModalContent,
    PopupHeader,
} from '@growthbase/design-components';
import {Trans, useTranslation} from 'react-i18next';

export interface TemplateOntkoppelenMenuButtonProps {
    lijstTitel: string;
    onSubmit: ButtonHandler;
}

export const TemplateOntkoppelenMenuButton: FC<TemplateOntkoppelenMenuButtonProps> = ({lijstTitel, onSubmit}) => {
    const {t} = useTranslation('spa.nl');
    return (
        <>
            <ConfirmModalButton
                header={<PopupHeader title={t('lijsttemplate.TemplateOntkoppelenMenuButton.Bevestigmodal.Titel')} />}
                onSubmit={onSubmit}
                popupButtonContent={t('lijsttemplate.TemplateOntkoppelenMenuButton.Bevestigmodal.Bevestigknop')}
                renderOpenButton={(props) => (
                    <ActionsMenuButton onClick={props.onClick}>
                        {t('lijsttemplate.TemplateOntkoppelenMenuButton.Dropdownknop')}
                    </ActionsMenuButton>
                )}
            >
                <ModalContent>
                    <div>
                        <p>
                            <Trans
                                t={t}
                                components={{strong: <strong />}}
                                values={{titel: `<strong>${lijstTitel}</strong>`}}
                            >
                                lijsttemplate.TemplateOntkoppelenMenuButton.Bevestigmodal.Tekst
                            </Trans>
                        </p>
                    </div>
                </ModalContent>
            </ConfirmModalButton>
        </>
    );
};
