import * as yup from 'yup';
import {SchemaOf} from 'yup';
import {useMemo} from 'react';
import {TFunction} from 'i18next';
import {DatePickerValue, DatePickerValueObject} from '../types/DatePickerValue';

export type MinimumDateOptions = {
    date: () => Date;
    errorMessage: string | TFunction;
};

export interface DatePickerOptions {
    minimumDate?: MinimumDateOptions;
}

export const DatePickerValueSchema = ({minimumDate}: DatePickerOptions): SchemaOf<DatePickerValueObject> =>
    yup
        .object({
            date: yup.string().defined(),
            timeUsed: yup.boolean().defined(),
        })
        .test(function TestMinimumDate() {
            if (minimumDate === undefined) {
                return true;
            }
            const {path, createError, parent: value} = this;

            const typed = value[path] as DatePickerValueObject | null;

            if (!typed?.date) {
                return true;
            }

            const minimumDateForComparison = typed.timeUsed
                ? minimumDate.date()
                : new Date(minimumDate.date().toDateString());

            if (minimumDateForComparison > new Date(typed.date)) {
                return createError({path, message: minimumDate.errorMessage});
            }

            return true;
        })
        .defined();

export const NullableDatePickerValueSchema = (options: DatePickerOptions): SchemaOf<DatePickerValue> =>
    DatePickerValueSchema(options).defined().nullable();

export const useDatePickerValueSchema = (options: DatePickerOptions) =>
    useMemo(() => DatePickerValueSchema(options), [options]);
