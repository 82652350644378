import {FC, Fragment, ReactNode} from 'react';
import classNames from 'classnames/bind';
import {DebugRenderCounter} from '../../../DebugRender/DebugRenderCounter';
import styles from './PopupHeader.module.scss';
import {Buttons} from '../../../Button';
import {usePopup} from '../../Hook';
import {PopupHeaderItem} from '../PopupHeaderItem';
import {PopupTitle} from '../PopupTitle/PopupTitle';
import {useModalScrolled} from '../../../Modals/useModalScrolled';
import {useIsMobile} from '../../../Layout';

const cx = classNames.bind(styles);

export interface PopupHeaderProps {
    children?: ReactNode;
    title?: ReactNode;
    buttons?: ReactNode[];
    additionalActions?: ReactNode;
    small?: boolean;
    unclosable?: boolean;
    expandable?: boolean;
    sticky?: boolean;
}

export const PopupHeader: FC<PopupHeaderProps> = ({
    title,
    children,
    buttons = [],
    additionalActions,
    small,
    unclosable,
    expandable,
    sticky,
}) => {
    const {close, expand, collapse, isExpanded} = usePopup();
    const scrolled = useModalScrolled();
    const isMobile = useIsMobile();

    const classes = cx('popupHeader', {
        'popupHeader--small': small,
        'popupHeader--sticky': sticky,
        'popupHeader--scrolled': scrolled && isMobile,
    });

    return (
        <div className={classes}>
            <DebugRenderCounter>{title ?? 'PopupHeader'}</DebugRenderCounter>
            {title && <PopupTitle className={cx('popupHeader__title')}>{title}</PopupTitle>}
            {children}
            <div className={styles.popupHeader__actions}>
                {buttons.map((button, index) => (
                    <Fragment key={index}>
                        <PopupHeaderItem>{button}</PopupHeaderItem>
                    </Fragment>
                ))}
                {expandable && !isExpanded && (
                    <PopupHeaderItem>
                        <Buttons.ExpandIconOnly onClick={expand} size="medium" />
                    </PopupHeaderItem>
                )}
                {expandable && isExpanded && (
                    <PopupHeaderItem>
                        <Buttons.CollapseIconOnly onClick={collapse} size="medium" />
                    </PopupHeaderItem>
                )}
                {!unclosable && (
                    <PopupHeaderItem>
                        <Buttons.CrossIconOnly onClick={close} size="medium" />
                    </PopupHeaderItem>
                )}
            </div>
        </div>
    );
};
