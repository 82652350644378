import {BaseButtonProps} from '@growthbase/design-components';
import {memo, useCallback, FC} from 'react';
import {
    IVoltooiOfActiveerTaakButtonFragment,
    useVoltooiOfActiveerTaakButtonActiveerMutation,
    useVoltooiOfActiveerTaakButtonVoltooiMutation,
} from '@growthbase/graphql';
import {VoltooiOfActiveerTaakButton} from './VoltooiOfActiveerTaakButton';

export interface VoltooiOfActiveerTaakButtonConnectedProps {
    fragment?: IVoltooiOfActiveerTaakButtonFragment;
    SubmitButton?: FC<BaseButtonProps>;
}

export const VoltooiOfActiveerTaakButtonConnected: FC<VoltooiOfActiveerTaakButtonConnectedProps> = memo(
    ({fragment, SubmitButton}) => {
        const [activeer, {loading: loadingActiveer}] = useVoltooiOfActiveerTaakButtonActiveerMutation();
        const [voltooi, {loading: loadingVoltooi}] = useVoltooiOfActiveerTaakButtonVoltooiMutation();
        return (
            <VoltooiOfActiveerTaakButton
                SubmitButton={SubmitButton}
                voltooi={useCallback(
                    () =>
                        voltooi({
                            variables: {
                                input: {
                                    id: fragment?.id ?? '',
                                },
                            },
                        }),
                    [voltooi, fragment]
                )}
                activeer={useCallback(
                    () =>
                        activeer({
                            variables: {
                                input: {
                                    id: fragment?.id ?? '',
                                },
                            },
                        }),
                    [activeer, fragment]
                )}
                loading={loadingActiveer || loadingVoltooi || (fragment?.isLocked ?? false)}
                disabled={!fragment || loadingActiveer || loadingVoltooi}
                fragment={fragment}
            />
        );
    }
);
VoltooiOfActiveerTaakButtonConnected.displayName = 'VoltooiOfActiveerTaakButtonConnected';
