import {MbscEventUpdateEvent} from '@mobiscroll/react';
import {useAppendMessage, useCallbackRef} from '@growthbase/spa';
import {useGlobalModifierKeys} from '@growthbase/keys';
import {EventType, useZetDatumsVanUrenRegistratieLogMutation} from '@growthbase/planning';
import {useTranslation} from 'react-i18next';
import {getEventTitle} from './getEventTitle';
import {useIsSameDay} from './useIsSameDay';

export const useUpdateEventAction = (setLoading: (value: boolean) => void) => {
    const {alt} = useGlobalModifierKeys();
    const [zetUrenRegistratieDatums] = useZetDatumsVanUrenRegistratieLogMutation();
    const addMessage = useAppendMessage();
    const {t} = useTranslation('Planning.nl');
    const eventName = alt ? 'planningEventClone' : 'planningEventEdit';
    const isSameDay = useIsSameDay();

    return useCallbackRef((e: MbscEventUpdateEvent): boolean => {
        if (
            !e.event.id ||
            !e.event.start ||
            !e.event.end ||
            !e.event.resource ||
            !e.event.csrfToken ||
            !e.oldEvent ||
            !e.oldEvent.resource
        ) {
            return false;
        }

        if (
            e.event.timeRegistrationParent &&
            !isSameDay(new Date(e.event.start as string), new Date(e.oldEvent.start as string))
        ) {
            addMessage([
                {
                    id: 'modify-time-registration',
                    displayType: 'user',
                    type: 'error',
                    message: t('UrenRegistratieKanNietNaarEenAndereDagVerplaatstWorden'),
                },
            ]);
            return false;
        }

        setLoading(true);

        if (e.event.timeRegistrationParent) {
            addMessage([
                {
                    id: 'modify-time-registration',
                    displayType: 'user',
                    type: 'success',
                    message: 'planning.context.urenregistratie.success',
                    promise: {
                        promise: zetUrenRegistratieDatums({
                            variables: {
                                urenRegistratieLogId: e.event.id as string,
                                datums: {
                                    van: {
                                        iso8601: e.event.start.toString(),
                                        timezone: 'Europe/Amsterdam',
                                    },
                                    tot: {
                                        iso8601: e.event.end.toString(),
                                        timezone: 'Europe/Amsterdam',
                                    },
                                },
                            },
                            onCompleted: () => {
                                setLoading(false);
                            },
                        }),
                        pending:
                            t('UrenRegistratieXWordtBijgewerkt', {
                                naam: getEventTitle(e.event as EventType),
                            }) ?? '',
                        success:
                            t('UrenRegistratieXIsBijgewerkt', {
                                naam: getEventTitle(e.event as EventType),
                            }) ?? '',
                    },
                },
            ]);

            return true;
        }

        document.dispatchEvent(
            new CustomEvent(eventName, {
                detail: {
                    csrfToken: e.event.csrfToken,
                    resourceId: e.oldEvent.resource.toString(),
                    newResourceId: e.event.resource.toString(),
                    id: e.event.id.toString(),
                    start: e.event.start.toString(),
                    end: e.event.end.toString(),
                },
            })
        );

        return !alt;
    });
};
