import {FC, ReactNode} from 'react';
import classNames from 'classnames/bind';
import styles from './Field.module.scss';
import {FieldLabel} from '../FieldLabel';

const cx = classNames.bind(styles);

export interface BaseFieldProps {
    disableMargin?: boolean;
    label: ReactNode;
    compact?: boolean;
    inline?: boolean;
    children: ReactNode;
    hideLabel?: boolean;
}

export const Field: FC<BaseFieldProps> = ({label, children, compact, inline, disableMargin, hideLabel = false}) => {
    const classes = cx('field', {
        'field--disableMargin': disableMargin,
        'field--showLabel': !hideLabel,
        'field--inline': inline,
    });
    return (
        <div className={classes}>
            {label && (
                <FieldLabel compact={compact} asPlaceholder={hideLabel}>
                    {label}
                </FieldLabel>
            )}
            <div className={cx('field__content')}>{children}</div>
        </div>
    );
};
