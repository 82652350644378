import {map, mergeMap} from 'rxjs';
import {logInfo, pageRerender} from '../rxjs';
import {applyCondition} from './applyConditions';
import {findConditionElementsOnce} from './Observable';

export const laodConditionizeExtension = () =>
    pageRerender()
        .pipe(
            logInfo('ConditionizeExtension:Page render..'),
            mergeMap(() => findConditionElementsOnce()),
            logInfo('ConditionizeExtension:Apply conditions..'),
            map(applyCondition),
            logInfo('ConditionizeExtension:Conditions applied..')
        )
        .subscribe();
