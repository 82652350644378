import React, {FC, Fragment, useCallback} from 'react';
import {
    CardSelectOption,
    GroupLabel,
    PaddingHorizontal,
    PaddingVertical,
    Scrollable,
    Stack,
} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';
import {Dienst} from '../../Sidebars/PlanningSidebar/Dienst';
import {useRenderTime} from './Hook/useRenderTime';

export interface DienstSelectieGroep {
    diensten: Dienst[];
    label: string;
}

export interface DienstSelectieGroupedProps {
    activeDienstId?: string;
    groepen: DienstSelectieGroep[];
    keuzeAangepast: (value?: string) => void;
}

export const DienstSelectieGrouped: FC<DienstSelectieGroupedProps> = ({groepen, activeDienstId, keuzeAangepast}) => {
    const {t} = useTranslation('spa.nl');
    const renderTime = useRenderTime();

    const onChangeValue = useCallback(
        (value?: string) => {
            keuzeAangepast(value);
        },
        [keuzeAangepast]
    );

    return (
        <div style={{height: '100%'}}>
            <PaddingVertical height="100%">
                <Scrollable fillParent>
                    <Stack gap="default">
                        {groepen.map(({diensten, label}) => (
                            <Fragment key={label}>
                                <GroupLabel>{label}</GroupLabel>
                                <PaddingHorizontal>
                                    <Stack gap={6}>
                                        {diensten.map((dienst) => (
                                            <CardSelectOption
                                                key={dienst.id}
                                                value={dienst.id}
                                                onChangeValue={onChangeValue}
                                                title={dienst.titel}
                                                active={dienst.id === activeDienstId}
                                                subtitle={
                                                    dienst.resterendBudgetVoorOpdracht
                                                        ? t('Planning.Sidebar.DienstSelectie.Resterend', {
                                                              tijd: renderTime(
                                                                  dienst.resterendBudgetVoorOpdracht
                                                                      .resterendeTijdInSeconde
                                                              ),
                                                          }).toString()
                                                        : undefined
                                                }
                                            />
                                        ))}
                                    </Stack>
                                </PaddingHorizontal>
                            </Fragment>
                        ))}
                    </Stack>
                </Scrollable>
            </PaddingVertical>
        </div>
    );
};
