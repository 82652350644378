import {useLocale} from '@growthbase/spa';
import {useMemo, FC} from 'react';
import en from 'javascript-time-ago/locale/en.json';
import nl from 'javascript-time-ago/locale/nl.json';
import TimeAgo from 'javascript-time-ago';
import {useCounter, useInterval} from 'react-use';

TimeAgo.addLocale(en);
TimeAgo.addLocale(nl);

export interface RelativeTimeProps {
    time: Date;
}

export const RelativeTime: FC<RelativeTimeProps> = ({time}) => {
    const locale = useLocale();
    const [value, {inc}] = useCounter();
    useInterval(inc, 5);
    /* eslint-disable */
    const relative = useMemo(() => {
        const timeAgo = new TimeAgo(locale);
        return timeAgo.format(time);
    }, [time, locale, value]);

    return <>{relative}</>;
};
