import React, {ReactNode} from 'react';
import classNames from 'classnames/bind';
import {capitalize} from 'lodash';
import {BaseButtonProps} from '../../../../Button';
import {SidenavCounterPopup, SidenavCounterPopupButtonProps} from '../../../SidenavCounterPopup';
import styles from './CounterButton.module.scss';
import {useInSideNav, useSidenavContext} from '../../../Sidenav/useSidenavContext';

// eslint-disable-next-line no-shadow
export enum SidenavLinkCountColor {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
}

export interface CounterButtonProps extends BaseButtonProps {
    count: number | null | undefined;
    color?: SidenavLinkCountColor;
    popupContent?: ReactNode;
}

const cx = classNames.bind(styles);

export const CounterButton = ({
    count,
    color = SidenavLinkCountColor.TERTIARY,
    popupContent,
    ...buttonProps
}: CounterButtonProps) => {
    const {isExpanded} = useSidenavContext();
    const inSideNav = useInSideNav();

    const countClasses = cx('counterButton', `counterButton--color${capitalize(color)}`, {
        'counterButton--hidden': !count,
        'counterButton--button': !!popupContent,
        'counterButton--small': !isExpanded || !inSideNav,
    });

    const renderCounterButton = ({reference: ref, ...rest}: SidenavCounterPopupButtonProps) => (
        <button type="button" className={countClasses} {...rest} {...buttonProps} ref={ref}>
            {count}
        </button>
    );

    return (
        <>
            {!popupContent && <div className={countClasses}>{count}</div>}
            {popupContent && (
                <SidenavCounterPopup renderButton={renderCounterButton}>{popupContent}</SidenavCounterPopup>
            )}
        </>
    );
};
