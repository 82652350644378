import {ExternalLinkType, useExternalLink} from '@growthbase/clientbox';
import {useMemo} from 'react';
import {useSelected} from 'slate-react';
import classNames from 'classnames/bind';
import styles from './RelatieMention.module.scss';

type RelatieType = {
    id: string;
    label: string;
    externalId?: string | null;
};

export interface RelatieMentionReadonlyProps {
    relatie: RelatieType;
}

const cx = classNames.bind(styles);

export const RelatieMentionReadonly = ({relatie}: RelatieMentionReadonlyProps) => {
    const {label} = relatie;

    const link = useExternalLink(
        useMemo(
            () => ({
                // TODO: should know the relation type.
                linkType: ExternalLinkType.Persoon,
                ...relatie,
            }),
            [relatie]
        )
    );

    const selected = useSelected();

    const classes = cx('relatieMention', {
        'relatieMention--selected': selected,
    });

    if (link) {
        return (
            <a rel="noopener noreferrer" target="_blank" className={classes} href={link}>
                {label}
            </a>
        );
    }
    return (
        <span className={styles.RelatieMention} contentEditable={false}>
            {label}
        </span>
    );
};
