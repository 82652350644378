import React, {ReactNode, useMemo} from 'react';
import classNames from 'classnames';
import {createPortal} from 'react-dom';
import {useUniqueClassname} from '../../../PaginationInfiniteScroll';
import {useBreadcrumbs} from '../../../Breadcrumb';

export interface PageBreadcrumbsProps {
    children: ReactNode;
}

export const PageBreadcrumbs = ({children}: PageBreadcrumbsProps) => {
    const breadcrums = useBreadcrumbs();
    const hasBreadcrumb = breadcrums.length > 0;
    const destination = useMemo(() => {
        const portalDestination = document.getElementById('breadcrumb-container');
        if (!hasBreadcrumb) {
            return null;
        }
        if (!portalDestination) {
            return null;
        }
        if (!portalDestination.classList.contains('react-portal')) {
            portalDestination.textContent = '';
            portalDestination.classList.add('react-portal');
        }
        return portalDestination;
    }, [hasBreadcrumb]);

    const uniqueClassName = useUniqueClassname();

    const breadCrumbs = <div className={classNames(uniqueClassName)}>{children}</div>;

    if (!hasBreadcrumb) {
        return null;
    }

    if (destination) {
        return createPortal(breadCrumbs, destination);
    }

    return breadCrumbs;
};
