import {ThemeOptions} from '@mui/material/styles/createTheme';

export const Alert: ThemeOptions['components'] = {
    MuiAlert: {
        styleOverrides: {
            standardWarning: {
                border: '1px solid var(--colorWarning)',
            },
            standardError: {
                border: '1px solid var(--colorError)',
            },
        },
    },
};
