import {FC, memo, ReactNode} from 'react';
import classNames from 'classnames/bind';
import styles from './ScrollablePopupContent.module.scss';
import {Scrollable} from '../../Scrollable';

const cx = classNames.bind(styles);

export interface ScrollablePopupContentProps {
    children: ReactNode;
    header?: ReactNode;
    contentHeader?: ReactNode;
    contentFooter?: ReactNode;
    scrollable?: boolean;
    className?: string;
    smallPadding?: boolean;
    noPadding?: boolean;
    fullscreen?: boolean;
    fullWidth?: boolean;
}

export const ScrollablePopupContent: FC<ScrollablePopupContentProps> = memo(
    ({
        children,
        fullWidth,
        fullscreen,
        header,
        contentHeader,
        contentFooter,
        className = '',
        smallPadding,
        noPadding,
    }) => {
        const classes = cx('scrollablePopupContent', {
            'scrollablePopupContent--smallPadding': smallPadding,
            'scrollablePopupContent--noPadding': noPadding,
            'scrollablePopupContent--fullWidth': fullWidth,
            'scrollablePopupContent--fullscreen': fullscreen,
        });
        return (
            <div className={`${classes} ${className}`}>
                {header}
                <div className={cx('scrollablePopupContent__body')}>
                    {contentHeader && (
                        <div className={cx('scrollablePopupContent__contentHeader')}>{contentHeader}</div>
                    )}
                    <Scrollable>{children ?? ' '}</Scrollable>
                    {contentFooter && (
                        <div className={cx('scrollablePopupContent__contentFooter')}>{contentFooter}</div>
                    )}
                </div>
            </div>
        );
    }
);

ScrollablePopupContent.displayName = 'ScrollablePopupContent';
