import {opgemaakteTekstAsText} from '@growthbase/design-components';
import {IMaakBordAanTakenBordInput} from '@growthbase/graphql';
import {v4} from 'uuid';
import {idFromIri} from '@growthbase/routing';
import {CreateFormDTO} from './Hook/useCreateSchema';

export const mapDTOToGraphQL = (dto: CreateFormDTO): IMaakBordAanTakenBordInput => ({
    naam: opgemaakteTekstAsText(dto.naam),
    id: v4(),
    omschrijving: dto.omschrijving,
    item: dto.onderdeelVan
        ? {
              id: dto.onderdeelVan.id,
              type: dto.onderdeelVan.type,
          }
        : undefined,
    lijsten: dto.lijsten.map((lijst) => ({
        id: v4(),
        titel: lijst,
    })),
    zichtbaarVoor: dto.zichtbaarVoor,
    deelnemers: dto.deelnemers.map((deelnemer) => idFromIri(deelnemer.id)),
});
