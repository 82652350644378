import React, {ReactNode, useCallback, FC} from 'react';
import classNames from 'classnames/bind';
import {SidenavPopup, SidenavPopupButtonProps} from '../SidenavPopup';
import styles from './SidenavPopupLink.module.scss';
import {AnchorOrLink, AnchorOrLinkValue} from '../../AnchorOrLink';
import {IconProps, IconSize} from '../../Icon';

const cx = classNames.bind(styles);

export interface SidenavPopupLinkProps extends AnchorOrLinkValue {
    children?: ReactNode;
    Icon: FC<IconProps>;
    id?: string;
    active?: boolean;
    badgeContent?: ReactNode;
}

export const SidenavPopupLink = ({children, Icon, to, href, id, active, badgeContent}: SidenavPopupLinkProps) => {
    const renderButton = useCallback(
        ({isOpen, ...props}: SidenavPopupButtonProps) => {
            const classes = cx('sidenavPopupLink', {
                'sidenavPopupLink--active': isOpen || active,
            });
            return (
                <div className={classes}>
                    <AnchorOrLink
                        {...props}
                        href={href}
                        to={to}
                        id={id ? `SidenavPopupLink--${id}` : undefined}
                        className={cx('sidenavPopupLink__link')}
                    >
                        <Icon size={IconSize.SIZE_5} />
                    </AnchorOrLink>
                    {badgeContent && <div className={cx('sidenavPopupLink__badge')}>{badgeContent}</div>}
                </div>
            );
        },
        [
            active,
            href,
            to,
            id,
            Icon,
            badgeContent,
        ]
    );

    return <SidenavPopup renderButton={renderButton}>{children}</SidenavPopup>;
};
