import React, {FC} from 'react';
import classNames from 'classnames/bind';
import {BasicMarksToolbarButtons} from '../BasicMarksToolbarButtons/BasicMarksToolbarButtons';
import {ListToolbarButtons} from '../ListToolbarButtons/ListToolbarButtons';
import styles from './OpgemaaktetekstToolbar.module.scss';
import {BasicElementToolbarButtons} from '../BasicElementToolbarButtons/BasicElementToolbarButtons';

const cx = classNames.bind(styles);

export const OpgemaaktetekstToolbar: FC<{active?: boolean; isStatic?: boolean}> = ({active, isStatic}) => {
    const bar = (
        <div className={cx('toolbar__inner')}>
            <BasicMarksToolbarButtons />
            <BasicElementToolbarButtons />
            <ListToolbarButtons />
        </div>
    );

    if (!active) {
        return null;
    }

    return <div className={cx('toolbar', 'toolbar--active', {'toolbar--static': isStatic})}>{bar}</div>;
};
