import {FC} from 'react';
import {useMapFragmentToValue} from './hook/useMapFragmentToValue';
import {TaakPlanningButton} from './TaakPlanningButton';
import {TaakPlanningButtonConnectedProps} from './TaakPlanningButtonConnected';

export const TaakPlanningButtonStub: FC<TaakPlanningButtonConnectedProps> = ({
    AddButton,
    textType,
    disabled,
    asText,
    fragment,
}) => (
    <TaakPlanningButton
        AddButton={AddButton}
        value={useMapFragmentToValue(fragment)}
        asText={asText}
        disabled={disabled ?? false}
        readonly={false}
        textType={textType}
        onSubmit={() => Promise.resolve()}
    />
);
