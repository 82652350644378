import {ReactNode} from 'react';
import styles from './Body.module.scss';

export interface BodyProps {
    children: ReactNode;
    menu?: ReactNode;
}

export const Body = ({children, menu}: BodyProps) => (
    <div className={styles.body}>
        {menu && <div className={styles.body__menu}>{menu}</div>}
        {children}
    </div>
);
