import {createUseAdditionalProperties} from '@growthbase/spa';
import {LogFilter} from '../../ApplicationLogsTable/Components';

export interface Aggregation {
    name: string;
    filters: LogFilter[];
}

export interface AggregationSection {
    name: string;
    filters: LogFilter[];
    aggregations: Aggregation[];
}

export const useAggregationConfig = createUseAdditionalProperties<
    | {
          aggregations: AggregationSection[];
          profielId?: string | null;
      }
    | undefined
>('Aggregations');
