import {Autocomplete, TextField} from '@mui/material';
import {FormikFieldProps} from '@growthbase/design-components';
import {useField} from 'formik';
import {useCallback, useMemo} from 'react';
import {AutocompleteRenderInputParams} from '@mui/material/Autocomplete/Autocomplete';
import * as React from 'react';
import {useCallbackRef} from '@growthbase/spa';

export type FormikMuiAutocompleteProps<T> = FormikFieldProps<string> & {
    label: string;
    options: T[];
    getOptionKey?: (option: T) => string;
    getOptionLabel?: (option: T) => string;
    onSearchChange?: (value: string) => void;
    search: string | null;
};

export function FormikMuiAutocomplete<T extends {label: string; value: string}>({
    meta,
    field,
    onSearchChange,
    options,
    search,
    label,
    ...props
}: FormikMuiAutocompleteProps<T>) {
    const {name, value, ...fieldProps} = field;
    const [, {error, touched}, {setValue}] = useField(name);

    const throttledOnChange = useCallback(
        (e: unknown, changedValue: string) => {
            if (onSearchChange) {
                onSearchChange(changedValue);
            }
        },
        [onSearchChange]
    );

    const selectedOption = useMemo(() => options.find((option) => option.value === value), [options, value]);

    const onChangeCallback = useCallbackRef((event: unknown, changedValue: T | null) => {
        setValue(changedValue ? changedValue.value : null);
    });

    const renderInput = useCallback(
        (params: AutocompleteRenderInputParams) => (
            <TextField
                label={label}
                {...params}
                inputProps={{
                    ...params.inputProps,
                    style: {
                        paddingTop: 0,
                        paddingBottom: 0,
                    },
                }}
                error={Boolean(error && touched)}
            />
        ),
        [error, label, touched]
    );

    return (
        <Autocomplete
            {...props}
            {...fieldProps}
            options={options}
            onInputChange={throttledOnChange}
            value={selectedOption || null}
            multiple={false}
            onChange={onChangeCallback}
            renderInput={renderInput}
            isOptionEqualToValue={(option, optionValue) => optionValue && option.value === optionValue.value}
        />
    );
}
