import {useContextMenu} from 'react-contexify';
import {EventType} from '@growthbase/planning';
import {useCallbackRef} from '@growthbase/spa';

export const PLANNING_CONTEXT_MENU_ID = 'planning-context-menu';

export const usePlanningContextMenu = () => {
    const {show} = useContextMenu({
        id: PLANNING_CONTEXT_MENU_ID,
    });

    const openMenu = useCallbackRef((e: MouseEvent, event: EventType) => {
        show({
            event: e,
            props: {event},
        });
    });

    return {
        openMenu,
    };
};
