import {ApolloClient, NormalizedCacheObject} from '@apollo/client';
import {IIsReplayingQuery, IIsReplayingQueryVariables, IsReplayingDocument} from '@growthbase/graphql';

export class IsReplayingClient {
    constructor(private client: ApolloClient<NormalizedCacheObject>) {}

    public async fetch(): Promise<IIsReplayingQuery> {
        const result = await this.client.query<IIsReplayingQuery, IIsReplayingQueryVariables>({
            query: IsReplayingDocument,
            variables: {},
        });

        return result.data;
    }
}
