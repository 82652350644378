import {useEffect} from 'react';
import {EntityReferenceLinksRegistry, ReferenceLinkResolver} from '../EntityReferenceLinksRegistry';

export const useAddReferenceLinkResolver = (resolver: ReferenceLinkResolver): void =>
    useEffect(() => {
        EntityReferenceLinksRegistry.resolvers.unshift(resolver);
        EntityReferenceLinksRegistry.changeListeners.forEach((listener) => listener());
        return (): void => {
            EntityReferenceLinksRegistry.resolvers.splice(EntityReferenceLinksRegistry.resolvers.indexOf(resolver), 1);
        };
    }, [resolver]);
