import {useIsMobile} from '@growthbase/design-components';

import {ApolloError} from '@apollo/client';
import {PaginationHelpers} from '@growthbase/spa';
import React, {ReactNode, useMemo, FC} from 'react';
import classNames from 'classnames';
import {Column, useTable} from 'react-table';
import {identity} from 'rxjs';
import {DebugRenderCounter} from '../../DebugRender/DebugRenderCounter';
import {RenderApolloError} from '../../RenderApolloError/RenderApolloError';
import {MoreLoaderFooter} from './components/MoreLoaderFooter/MoreLoaderFooter';
import styles from './Table.module.scss';
import {TableBody, TableBodyProps, TableColumnHeader, TableColumnHeaderProps, TableFooterProps} from './components';
import {useCustomLayout} from './hooks';
import {TableInterface} from './TableInterface';
import {TableRowInterface} from './TableRowInterface';
import {NodeWrapper} from '../../NodeWrapper';
import {Stack} from '../../Layout';

export function defaultRenderBody<TRow extends TableRowInterface>(props: TableBodyProps<TRow>): ReactNode {
    return (
        <>
            <TableBody {...props} />
        </>
    );
}

export type RenderBody<TRow extends TableRowInterface = TableRowInterface> = (props: TableBodyProps<TRow>) => ReactNode;

export interface TableProps<TRow extends TableRowInterface> {
    testId?: string | null;
    error?: ApolloError;

    columns: ReadonlyArray<Column<TRow>>;
    pagination: PaginationHelpers<TRow>;

    /**
     * Allow custom elements for all sections.
     */
    ColumnHeader?: FC<TableColumnHeaderProps<TRow>>;

    bodyWrapper?: NodeWrapper;
    renderBody?: RenderBody<TRow>;
    Footer?: FC<TableFooterProps<TRow>>;

    header?: ReactNode;
    filters?: ReactNode;
    getRowId?: (originalRow: TRow) => string;

    emptyMessage?: ReactNode;
}

export const getRowIdById = ({id}: TableRowInterface) => id;

export function Table<TRow extends TableRowInterface>({
    error,
    columns,
    pagination,
    ColumnHeader = TableColumnHeader,
    renderBody = defaultRenderBody,
    Footer,
    header,
    filters,
    getRowId = getRowIdById,
    testId,
    emptyMessage,
    bodyWrapper = identity,
}: TableProps<TRow>) {
    const defaultColumn = useMemo(
        () => ({
            width: 100,
        }),
        []
    );
    const table: TableInterface<TRow> = useTable<TRow>(
        {
            getRowId,
            defaultColumn,
            columns,
            data: pagination.connections,
        },
        // @ts-expect-error This was already wrong in the original code
        useCustomLayout
    );
    const loader = pagination.isLoading && pagination.connections.length !== 0 ? <MoreLoaderFooter /> : null;
    const isMobile = useIsMobile();

    return (
        <Stack gap="default">
            <DebugRenderCounter>Table:</DebugRenderCounter>
            {filters && <Stack.Item>{filters}</Stack.Item>}
            <RenderApolloError error={error} />
            <div className={classNames([styles.table])} data-testid={testId ? `table-${testId}` : undefined}>
                {header}
                {!isMobile && <ColumnHeader table={table} />}
                {bodyWrapper(
                    renderBody({
                        getRowId,
                        pagination,
                        emptyMessage,
                        rows: table.rows,
                        prepareRow: table.prepareRow,
                        ...table.getTableProps(),
                    })
                )}
                <div className={styles.table__footerContainer}>{loader || (Footer && <Footer table={table} />)}</div>
            </div>
        </Stack>
    );
}
