import {useTranslation} from 'react-i18next';
import {GridLayout, NullableISOStringDate} from '@growthbase/design-components';
import {useDateText} from '../hook/useDateText';

export interface ToolTipContentProps {
    date: NullableISOStringDate;
}

export const ToolTipContent = ({date}: ToolTipContentProps) => {
    const {t} = useTranslation('Taken.nl');

    const datum = useDateText(false, date);

    return (
        <GridLayout columns={2} verticalGap={3} horizontalGap={10}>
            {date.date && (
                <>
                    <div>{t('SnoozeButton.snoozeTot')}:</div>
                    <div>{datum}</div>
                </>
            )}
        </GridLayout>
    );
};
