import {GraphQLServices} from '@growthbase/graphql';
import {IsReplayingService} from './isReplayingService';
import {IsReplayingClient} from './IsReplayingClient';
import {LoggingServices} from '../Logging';

export type IsReplayingDependencies = GraphQLServices & LoggingServices;

export interface IsReplayingServices {
    isReplayingService: IsReplayingService;
    isReplayingClient: IsReplayingClient;
}

export const createIsReplayingServices = ({graphqlClient, logger}: IsReplayingDependencies): IsReplayingServices => {
    const isReplayingClient = new IsReplayingClient(graphqlClient);
    const isReplayingService = new IsReplayingService(isReplayingClient, logger);
    return {
        isReplayingClient,
        isReplayingService,
    };
};
