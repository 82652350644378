import {useTranslation} from 'react-i18next';
import {useMemo, FC, ReactNode} from 'react';
import {
    Flex,
    Loader,
    ModalColumns,
    ModalContent,
    ModalSidebar,
    PaddingHorizontal,
    PaddingVertical,
    Tab,
    useIsMobile,
    Heading3,
} from '@growthbase/design-components';
import {TaakActiviteitenConnected} from '../TaakActiviteiten';
import {TaakEditFragment, TakenRoutes} from '..';
import {isITaakReactiesReactieTaakFragment, TaakReactiesConnected} from '../Reactie';

export interface EditModalTabsProps {
    id: string;
    editform: ReactNode;
    ActiviteitenList: typeof TaakActiviteitenConnected;
    Reacties: typeof TaakReactiesConnected;
    fragment?: TaakEditFragment;
}

export const EditModalTabs: FC<EditModalTabsProps> = ({id, editform, ActiviteitenList, fragment, Reacties}) => {
    const {t} = useTranslation('spa_form.nl');
    const params = useMemo(() => ({id}), [id]);
    const isMobile = useIsMobile();
    return (
        <>
            <Tab title={t('details')} {...TakenRoutes.edit.useTabRouting(params)}>
                <ModalColumns>
                    <ModalContent>{fragment ? editform : <Loader center />}</ModalContent>
                    {fragment && isITaakReactiesReactieTaakFragment(fragment) && (
                        <ModalSidebar>
                            <Reacties fragment={fragment} />
                        </ModalSidebar>
                    )}
                </ModalColumns>
            </Tab>
            <Tab title={t('activities')} {...TakenRoutes.editActivities.useTabRouting(params)}>
                <PaddingHorizontal paddingSize={isMobile ? 'large' : 'none'}>
                    <ModalContent>
                        <PaddingVertical paddingSize={isMobile ? 'large' : 'none'}>
                            <Flex.Column>
                                <Flex.Row>
                                    <Heading3>{fragment?.titel}</Heading3>
                                </Flex.Row>
                                <Flex.Row>
                                    <ActiviteitenList id={id} />
                                </Flex.Row>
                            </Flex.Column>
                        </PaddingVertical>
                    </ModalContent>
                </PaddingHorizontal>
            </Tab>
        </>
    );
};
