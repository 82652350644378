import React, {FC} from 'react';
import {
    Button,
    Modal,
    ModalContent,
    ModalFooter,
    Padding,
    PopupControls,
    PopupControlsContext,
    PopupHeader,
    useIsMobile,
} from '@growthbase/design-components';
import {Trans, useTranslation} from 'react-i18next';

interface PopupDeleteButtonProps {
    onSubmit: () => void;
    isSubmitting: boolean;
}

export interface VerwijderTaakConfirmModalProps {
    taaktitel: string;
    helpers: PopupControls;
    onSubmit: () => void;
    isSubmitting: boolean;
}

const PopupDeleteButton: FC<PopupDeleteButtonProps> = ({onSubmit, isSubmitting}) => {
    const {t} = useTranslation('spa.nl');
    return (
        <Button variant="danger" onClick={onSubmit} disabled={isSubmitting}>
            {t('form.delete')}
        </Button>
    );
};

export const VerwijderTaakConfirmModal: FC<VerwijderTaakConfirmModalProps> = ({
    taaktitel,
    helpers,
    onSubmit,
    isSubmitting,
}) => {
    const {t} = useTranslation('spa_form.nl');
    const isMobile = useIsMobile();

    return (
        <PopupControlsContext.Provider value={helpers}>
            <Modal testId="ConfirmModal">
                <PopupHeader title={t('taak.deleteTaskModaltitel')} />
                <ModalContent>
                    <Padding paddingSize={isMobile ? 'large' : 'none'}>
                        <div>
                            <Trans
                                t={t}
                                components={{strong: <strong />}}
                                values={{taaktitel: `<strong>${taaktitel}</strong>`}}
                            >
                                taak.confirmDelete
                            </Trans>
                        </div>
                    </Padding>
                </ModalContent>
                <ModalFooter
                    alignRight
                    buttons={<PopupDeleteButton onSubmit={onSubmit} isSubmitting={isSubmitting} />}
                />
            </Modal>
        </PopupControlsContext.Provider>
    );
};
