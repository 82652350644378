import {useTypedSearchParams} from '@growthbase/routing';
import * as yup from 'yup';

interface SlideshowParams {
    id?: string | undefined;
    bid?: string | undefined;
}

const schema = yup
    .object({
        id: yup.string().optional(),
        bid: yup.string().optional(),
    })
    .required();

const defaultParams = {};

export const useCurrentSlideShowState = () => useTypedSearchParams<SlideshowParams>('sb', schema, defaultParams);
