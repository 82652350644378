import {ReactNode} from 'react';
import {Center, Tooltip} from '@growthbase/design-components';
import {InfoIcon} from '@growthbase/design-tokens';
import {IconHolder} from '../../../design/elements/src/DataDisplay/IconHolder';

export interface TaakOmschrijvingTooltipProps {
    children: ReactNode;
    onShow?: () => void;
}

export const TaakOmschrijvingTooltip = ({children, onShow}: TaakOmschrijvingTooltipProps) => (
    <Tooltip content={children} onShow={onShow}>
        <Center vertical>
            <IconHolder icon={<InfoIcon />} />
        </Center>
    </Tooltip>
);
