import React, {ReactNode} from 'react';
import {IBestandType} from '@growthbase/graphql';
import {Paper} from '@mui/material';
import {Logger} from 'ts-log';
import {Bestand} from './Component/OverlayBestandsView/Hook/useBestandSlideshow';
import {LoadSVGViewer} from './Component/OverlayBestandsView/Component/SVGViewer';
import {LoadCodeViewer} from './Component/OverlayBestandsView/Component/CodeViewer';

/* eslint-disable */

/**
 * Using fancybox 3.5.7
 *
 * Url online documentation: https://fancyapps.com/fancybox/3/docs/
 * Working with https://obu.edu/_resources/ldp/galleries/fancybox/
 */
export class Fancybox {
    private viewing = '';

    private fancybox: ReactNode | null = null;

    constructor(
        private logger: Logger,
        private id: string,
        private bestanden: Bestand[],
        private onPageChange: (bestandId: string) => void,
        private onClose: () => void
    ) {}

    public create(): ReactNode {
        if (this.fancybox) {
            return this.fancybox;
        }
        const elements = this.bestanden.map(this.createElement.bind(this));
        return (this.fancybox = this.renderFancyboxContainer(elements));
    }

    /**
     * This simply clicks the element with the given bestandId.
     */
    public view(bestandId: string): Promise<void> {
        if (!this.fancybox) {
            throw new Error('Fancybox not created!');
        }
        const bestand = this.bestanden.find((b) => b.id === bestandId);
        if (!bestand) {
            this.logger.warn(`Bestand ${bestand} niet gevonden!`);
            return Promise.reject();
        }
        if (this.viewing === bestandId) {
            return Promise.resolve();
        }
        this.viewing = bestandId;
        const clickId = `view-${bestandId}`;
        const element = document.getElementById(clickId);
        if (element) {
            element.click();
            return Promise.resolve();
        }

        return new Promise((resolve, reject) => {
            const interval = setTimeout(() => {
                const found = document.getElementById(clickId);
                if (found) {
                    clearInterval(interval);
                    found.click();
                    resolve();
                    return;
                }
                reject();
            }, 100);
        });
    }

    private createElement(bestand: Bestand): ReactNode {
        const {path, type, id} = bestand;
        const clickId = `view-${id}`;

        const baseProps = {
            id: clickId,
            'data-fancybox': this.id,
            'data-caption': path,
            'data-bestand-id': id,
        };
        if (path.endsWith('.svg')) {
            return this.createSVGElement(baseProps, bestand);
        }
        switch (type) {
            case IBestandType.Pdf:
                return this.createPdfElement(baseProps, bestand);
            case IBestandType.Video:
                return this.createVideoElement(baseProps, bestand);
            case IBestandType.Afbeelding:
                return this.createImageElement(baseProps, bestand);
            case IBestandType.MicrosoftDocument:
                return this.createMicrosoftDocumentElement(baseProps, bestand);
            case IBestandType.Code:
                return this.createCodeElement(baseProps, bestand);
            default:
                return this.createDefaultElement(baseProps, bestand);
        }
    }

    private createSVGElement(baseProps: object, {id, path, url}: Bestand): ReactNode {
        return (
            <React.Fragment key={id}>
                <a {...baseProps} data-src={`#${id}`} href="javascript:;" data-width="100%" data-height="100%" />
                <div id={id}>
                    <Paper
                        style={{
                            padding: 15,
                        }}
                    >
                        <LoadSVGViewer url={url} path={path} />
                    </Paper>
                </div>
            </React.Fragment>
        );
    }

    private createPdfElement(baseProps: object, {id, path, url}: Bestand): ReactNode {
        return (
            <React.Fragment key={id}>
                <a
                    {...baseProps}
                    data-type="iframe"
                    data-src={`/pdfviewer/web/viewer.html?file=${encodeURIComponent(url)}`}
                    href={`#${id}`}
                >
                    {path}
                </a>
            </React.Fragment>
        );
    }

    private createVideoElement(baseProps: object, {id, path, url}: Bestand): ReactNode {
        return (
            <React.Fragment key={id}>
                <a {...baseProps} href={`#${id}`}>
                    {path}
                </a>
                <video controls autoPlay={false} id={id} src={url} style={{display: 'none'}} />
            </React.Fragment>
        );
    }

    private createImageElement(baseProps: object, {id, url}: Bestand): ReactNode {
        return <a key={id} href={url} {...baseProps} style={{display: 'none', background: 'white'}} />;
    }

    private createMicrosoftDocumentElement(baseProps: object, {id, path, url}: Bestand): ReactNode {
        return (
            <React.Fragment key={id}>
                <a
                    {...baseProps}
                    data-type="iframe"
                    data-src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`}
                    href={`#${id}`}
                >
                    {path}
                </a>
            </React.Fragment>
        );
    }

    private createCodeElement(baseProps: object, {id, path, url}: Bestand): ReactNode {
        return (
            <React.Fragment key={id}>
                <a {...baseProps} data-src={`#${id}`} href="javascript:;" data-width="100%" data-height="100%" />
                <div id={id}>
                    <Paper style={{padding: 50}}>
                        <LoadCodeViewer path={path} url={url} />
                    </Paper>
                </div>
            </React.Fragment>
        );
    }

    private createDefaultElement(baseProps: object, {id, path}: Bestand): ReactNode {
        return (
            <React.Fragment key={id}>
                <a {...baseProps} data-src={`#${id}`} href="javascript:;" data-width="100%" data-height="100%" />
                <div id={id}>
                    <Paper style={{padding: 15}}>
                        <h2>{path}</h2>
                    </Paper>
                </div>
            </React.Fragment>
        );
    }

    private renderFancyboxContainer(elements: ReactNode[]): ReactNode {
        return (
            <div style={{display: 'none'}} ref={this.setupFancybox.bind(this)}>
                {elements}
            </div>
        );
    }

    private setupFancybox(element: HTMLElement | null): void {
        if (!element) return;
        const encoded = JSON.stringify(this.id);
        // @ts-expect-error this exists :).
        $(`[data-fancybox=${encoded}]`).fancybox({
            afterShow: (instance: unknown, current: unknown) => {
                // @ts-expect-error this also exists :).
                const id = current.opts.$orig.data('bestand-id');
                if (!id) return;
                this.viewing = id;
                this.onPageChange(id);
            },
            afterClose: this.onClose,
        });
    }
}
