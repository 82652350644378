import {OptionalButtonHandler} from '@growthbase/design-components';
import {IReactieMenuConnectedFragment, useReactieMenuConnectedRemoveMutation} from '@growthbase/graphql';
import React, {useCallback} from 'react';
import {ReactieMenu} from './ReactieMenu';

export interface ReactieMenuProps {
    readonly?: boolean;
    disabled?: boolean;
    onEdit?: OptionalButtonHandler;
    fragment: IReactieMenuConnectedFragment;
}

export const ReactieMenuConnected = ({readonly, disabled, onEdit, fragment}: ReactieMenuProps) => {
    const [mutation] = useReactieMenuConnectedRemoveMutation();
    const enabled = !readonly && !disabled;
    const handler = useCallback(
        () =>
            mutation({
                variables: {
                    input: {
                        id: fragment.id,
                        taakId: fragment.taakId,
                        clientMutationId: 'ReactieMenuConnected',
                    },
                },
            }),
        [fragment, mutation]
    );
    const {availableActions} = fragment;
    return (
        <ReactieMenu
            readonly={readonly}
            disabled={disabled}
            onEdit={availableActions.kanWijzigen && enabled && onEdit}
            onDelete={enabled && availableActions.kanVerwijderen && handler}
        />
    );
};
