import React, {FC} from 'react';
import {useElements} from '@growthbase/clientbox';
import {createPortal} from 'react-dom';
import {TaakLinkConnected} from './TaakLink/TaakLinkConnected';

const Portal: FC<{
    element: Element;
}> = ({element}) => {
    const id = element.getAttribute('data-form-id');
    if (!id) {
        return null;
    }
    return createPortal(<TaakLinkConnected id={id} />, element);
};

export const TaakLinkFieldConnector: FC = () => {
    const elements = useElements('[data-form-type="TaakLink"]');
    return (
        <>
            {elements.map(({id, element}) => (
                <Portal key={id} element={element} />
            ))}
        </>
    );
};
