import {RouteElementProps} from '@growthbase/routing';
import {LoaderCenter, useGebruikerHeeftToegang} from '@growthbase/design-components';
import {useBordenBordPaginaQuery} from '@growthbase/graphql';
import {ViewConnected} from '@growthbase/takenlijst/bord';
import {DefaultKoppelbaarItem, DefaultRelatieValue} from '@growthbase/spa';
import {BordPagina} from './BordPagina';
import {useResolveOnderdeelVan} from '../BordenPagina/Hook/useResolveOnderdeelVan';

export type BordPaginaConnectedProps = RouteElementProps<{
    opdrachtId: string;
    opdrachtnummer: string;
    opdrachtUuid: string;
    bordUuid: string;
}>;

export const BordPaginaConnected = ({params}: BordPaginaConnectedProps) => {
    if (!params) {
        throw new Error('Logic exception.');
    }
    const bordId = params.bordUuid;
    const {data, loading} = useBordenBordPaginaQuery({
        variables: {
            id: params.opdrachtUuid,
            bordId,
        },
    });

    const bord = data?.takenBord;
    const toegang = useGebruikerHeeftToegang({
        enabled: !loading,
        toegang: bord?.availableActions?.kanBekijken,
    });
    const opdracht = data?.opdracht;

    const onderdeelVan = useResolveOnderdeelVan(params.opdrachtUuid, data?.opdracht);

    if (!toegang) {
        return <LoaderCenter />;
    }

    return (
        <DefaultKoppelbaarItem.Provider id="BordenPaginaConnected" value={onderdeelVan}>
            <DefaultRelatieValue.Provider id="BordenPaginaConnected" value={onderdeelVan?.relatie}>
                <BordPagina {...params} opdrachtNaam={opdracht?.titel ?? params.opdrachtnummer} bordNaam={bord?.naam}>
                    {bord && <ViewConnected bordId={bordId} />}
                </BordPagina>
            </DefaultRelatieValue.Provider>
        </DefaultKoppelbaarItem.Provider>
    );
};
