import {
    CalendarSelectionGrouped,
    CalendarSelectionItem,
    PaddingVertical,
    Scrollable,
} from '@growthbase/design-components';
import {useCallbackRef} from '@growthbase/spa';
import {PlanningStoreState, usePlanningSetActiveAgenda, usePlanningSetActiveAgendas} from '@growthbase/planning';
import {useSelector} from 'react-redux';

export const AgendaTab = () => {
    const {agendas, hash} = useSelector((state: PlanningStoreState) => state.planning);
    const setActiveAgendas = usePlanningSetActiveAgendas();
    const setActiveAgenda = usePlanningSetActiveAgenda();

    const onChangeEnabledState = useCallbackRef((items: CalendarSelectionItem[]) => {
        const activeItems = items.filter((item) => item.enabled).map((item) => item.id);
        const selectedItem = items.find((item) => item.selected);
        setActiveAgendas({activeAgendas: activeItems});

        if (selectedItem) {
            setActiveAgenda({agenda: selectedItem});
        }
    });

    return (
        <div style={{height: '100%'}}>
            <PaddingVertical height="100%">
                <Scrollable fillParent>
                    {!!agendas.length && (
                        <CalendarSelectionGrouped
                            key={hash}
                            calendars={agendas}
                            onChangeEnabledState={onChangeEnabledState}
                            selectedStateEnabled
                        />
                    )}
                </Scrollable>
            </PaddingVertical>
        </div>
    );
};
