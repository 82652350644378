import {
    BordenPaginaConnected,
    BordenPaginaConnectedProps,
    BordPaginaConnected,
    BordPaginaConnectedProps,
} from '@growthbase/opdrachten-taken';
import {createLegacyRoutes, CreateRouteFunction, createRoutes, idFromIri} from '@growthbase/routing';
import {generatePath} from 'react-router-dom';
import React from 'react';
import {assertClientboxIdentifier} from '@growthbase/clientbox';

const generateRoute: CreateRouteFunction<{
    opdrachtId: string;
    opdrachtnummer: string;
    opdrachtUuid: string;
}> = (route: string, params) => {
    if (!params) {
        throw new Error('Logic exception.');
    }
    const {opdrachtnummer, opdrachtUuid, opdrachtId} = params;
    return generatePath(route, {
        opdrachtId: assertClientboxIdentifier(opdrachtId),
        opdrachtnummer: opdrachtnummer ?? '-',
        opdrachtUuid: idFromIri(opdrachtUuid),
        order: assertClientboxIdentifier(opdrachtId),
        name: params?.opdrachtnummer ?? '-',
    });
};

const generateBordRoute: CreateRouteFunction<{
    opdrachtId: string;
    opdrachtnummer: string;
    opdrachtUuid: string;
    bordUuid: string;
}> = (route: string, params) => {
    if (!params) {
        throw new Error('Logic exception.');
    }
    const {opdrachtnummer, bordUuid, opdrachtUuid, opdrachtId} = params;
    return generatePath(route, {
        opdrachtId: assertClientboxIdentifier(opdrachtId),
        opdrachtnummer,
        opdrachtUuid: idFromIri(opdrachtUuid),
        bordUuid: idFromIri(bordUuid),
    });
};

export const OpdrachtRoutes = createRoutes('opdracht', {
    /**
     * With legacy id.
     */
    borden: {
        Element: (props: BordenPaginaConnectedProps) => <BordenPaginaConnected {...props} />,
        createRoute: generateRoute,
    },
    bord: {
        Element: (props: BordPaginaConnectedProps) => <BordPaginaConnected {...props} />,
        createRoute: generateBordRoute,
    },
});

export const OpdrachtLegacyRoutes = createLegacyRoutes('order', {
    index: {},
    read: {createRoute: generateRoute},
    template_read: {createRoute: generateRoute},
    order_planning_list: {createRoute: generateRoute},
});
