import {FC} from 'react';
import {IMarkeerAlsInboxButtonFragment, useMarkeerAlsInboxButtonMutation} from '@growthbase/graphql';
import {BaseButtonProps, Button as DefaultButton, ViewConfig} from '@growthbase/design-components';
import {idFromIri} from '@growthbase/routing';
import {MarkeerAlsInboxButton} from './MarkeerAlsInboxButton';

export interface MarkeerAlsInboxButtonConnectedProps extends ViewConfig {
    fragment?: IMarkeerAlsInboxButtonFragment;
    Button?: FC<BaseButtonProps>;
}

export const MarkeerAlsInboxButtonConnected: FC<MarkeerAlsInboxButtonConnectedProps> = ({
    fragment,
    Button = DefaultButton,
    disabled,
    readonly,
}) => {
    const inbox = fragment?.inbox;
    const [markeer, {loading}] = useMarkeerAlsInboxButtonMutation();
    return (
        <MarkeerAlsInboxButton
            isInbox={inbox?.isInbox ?? false}
            isGekoppeldAanBord={!!inbox}
            onClick={() => {
                if (!inbox) {
                    return Promise.resolve();
                }
                return markeer({
                    variables: {
                        input: {
                            lijstId: idFromIri(inbox.id),
                            id: idFromIri(inbox.bordId),
                        },
                    },
                }).then();
            }}
            isSubmitting={loading}
            Button={Button}
            disabled={disabled}
            readonly={readonly}
        />
    );
};
