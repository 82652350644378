import {LinkButton} from '@growthbase/design-components';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {SimpleMessage, useSnoozeMessage} from '@growthbase/spa';

export const ToastContent = ({message}: {message: SimpleMessage}) => {
    const snooze = useSnoozeMessage();
    const {t} = useTranslation('spa.nl');
    const {t: tr} = useTranslation('Spa.nl');
    return (
        <div>
            {message.message}&nbsp;{' '}
            {message.duplicates?.length && `(${tr('Aantal dubbel')}: ${message.duplicates.length})`}
            <LinkButton inheritStyle onClick={() => snooze(message)}>
                {t('snooze')}
            </LinkButton>
        </div>
    );
};
