import {ReactNode} from 'react';
import {useCurrentView} from '@growthbase/planning';
import classNames from 'classnames/bind';
import styles from './CalendarWrapper.module.scss';

const cx = classNames.bind(styles);

export interface CalendarWrapperProps {
    children: ReactNode;
}

export const CalendarWrapper = ({children}: CalendarWrapperProps) => {
    const currentView = useCurrentView();

    const classes = cx('calendarWrapper', {
        'calendarWrapper--viewCalendar': currentView && currentView.view?.calendar,
    });

    return <div className={classes}>{children}</div>;
};
