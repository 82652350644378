import {useField} from 'formik';
import {FC, useMemo} from 'react';
import {useCallbackRef} from '@growthbase/spa';
import {FormikFieldProps} from '../FieldWrapper';
import {Datepicker} from './Datepicker';
import {DatePickerValue} from './types/DatePickerValue';

export interface StringDatepickerFieldProps extends FormikFieldProps<string> {
    initialValue?: string;
    disableTime?: boolean;
}

export const StringDatepickerField: FC<StringDatepickerFieldProps> = ({
    field,
    form,
    disableTime = false,
    meta,
    initialValue,
    ...props
}) => {
    const [, {value}, {setValue}] = useField(field.name);

    const initValue = useMemo((): DatePickerValue | undefined => {
        if (!initialValue) {
            return undefined;
        }
        return {
            date: initialValue,
            timeUsed: !disableTime,
        };
    }, [disableTime, initialValue]);

    const val = useMemo(
        (): DatePickerValue => ({
            date: value,
            timeUsed: !disableTime,
        }),
        [disableTime, value]
    );

    return (
        <Datepicker
            value={val}
            initialValue={initValue}
            onChange={useCallbackRef((newValue: DatePickerValue) => {
                if (newValue === null) {
                    setValue(null);
                }
                setValue(newValue?.date);
            })}
        />
    );
};
