import {ReactNode} from 'react';
import {VoegTaakToeForm} from './Components/VoegTaakToeForm';

export interface FooterProps {
    form: ReactNode;
}

export function Footer({form}: FooterProps) {
    return <>{form}</>;
}

Footer.VoegTaakToeForm = VoegTaakToeForm;
