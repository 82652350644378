import {RoutePopupProvider} from '@growthbase/design-components';
import {CreateRouteFunction, createRoutes, PageFromMenu, RouteElementProps} from '@growthbase/routing';
import {generatePath} from 'react-router-dom';
import {BestandViewConnected} from './View/BestandViewConnected';

const generateBestandId: CreateRouteFunction<{
    bestandId: string;
}> = (route: string, params) =>
    generatePath(route, {
        bestandId: params?.bestandId ?? '',
    });

export const BestandsbeheerRoutes = createRoutes('bestandsbeheer', {
    overview: {
        Element: () => <PageFromMenu sidebar="remove">bestanden placeholder</PageFromMenu>,
    },
    view: {
        createRoute: generateBestandId,
        defaultBackgroundRoute: 'overview',
        Element: ({params}: RouteElementProps<{bestandId: string}>) => (
            <RoutePopupProvider>
                <BestandViewConnected bestandId={params?.bestandId ?? ''} />
            </RoutePopupProvider>
        ),
    },
});
