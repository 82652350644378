import React, {KeyboardEventHandler, ReactNode, FC} from 'react';
import classNames from 'classnames/bind';
import {useOptionalCallback} from '@growthbase/spa';
import {ActiveItemData, ActiveItemSource, ActiveItemType, useTraversableItemWithRef} from '@growthbase/keys';
import {useHandleClickState} from '../../Button';
import {AvatarProps} from '../Avatar';
import styles from './AvatarListItem.module.scss';

const cx = classNames.bind(styles);

export interface AvatarListItemProps<T extends ActiveItemData = ActiveItemData> {
    renderAvatar: (props: Partial<AvatarProps>) => ReactNode;
    children?: ReactNode;
    onClick?: () => void;
    selected?: boolean;
    disabled?: boolean;
    loading?: boolean;
    disableActiveItemFocus?: boolean;
    data: T;
}

export const AvatarListItem: FC<AvatarListItemProps> = ({
    children,
    renderAvatar,
    selected,
    data,
    disableActiveItemFocus,
    ...props
}) => {
    const {onClick, disabled, loading} = useHandleClickState(props);

    const onKeyPressed: KeyboardEventHandler<HTMLDivElement> = (e) => {
        if (e.key === 'Enter' && onClick && !disabled) {
            onClick();
        }
    };

    const {ref, isActive} = useTraversableItemWithRef<HTMLDivElement>({
        disabled: disabled || disableActiveItemFocus,
        data,
        source: ActiveItemSource.AvatarListItem,
        type: ActiveItemType.Unknown,
        onEnter: disabled ? undefined : onClick,
    });

    const actionButtonVisible = !!isActive;

    const handleRowClick = useOptionalCallback(onClick, !disabled);

    // Array with (conditional) css classes
    const classes = cx('avatarListItem', {
        'avatarListItem--isChosen': selected,
        'avatarListItem--disabled': disabled,
        'avatarListItem--focus': actionButtonVisible,
    });

    return (
        <div role="button" tabIndex={0} className={classes} onClick={handleRowClick} onKeyDown={onKeyPressed} ref={ref}>
            <div className={cx('avatarListItem__inner')}>
                <div className={cx('avatarListItem__avatar')}>
                    {renderAvatar({actionButtonVisible, disabled, loading, selected})}
                </div>
                <div className={cx('avatarListItem__content')}>
                    <div className={cx('avatarListItem__contentInner')}>{children}</div>
                </div>
            </div>
        </div>
    );
};
