import {Logger} from 'ts-log';

export interface LoggingServices {
    logger: Logger;
}

/* eslint-disable prefer-rest-params, no-console */

const globalWindow = window as unknown as {
    jsErrors?: string[];
    jsWarns?: string[];
    jsLogs?: string[];
    ms?: () => number;
};

const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key: string, value: unknown): unknown => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                return '[circularRef]';
            }
            seen.add(value);
        }
        return value;
    };
};

globalWindow.jsErrors = globalWindow.jsErrors || [];
globalWindow.jsWarns = globalWindow.jsWarns || [];
globalWindow.jsLogs = globalWindow.jsLogs || [];

/**
 * We wrap the logger, to support logging for behat.
 * Behat will change the console reference.
 */
class LoggerProxy implements Logger {
    [x: string]: unknown;

    debug(message?: unknown, ...optionalParams: unknown[]): void {
        console.debug(this.now(), message, ...optionalParams);
        globalWindow.jsLogs?.push(this.normalizeArguments('debug', arguments));
    }

    error(message?: unknown, ...optionalParams: unknown[]): void {
        console.error(this.now(), message, ...optionalParams);
        globalWindow.jsErrors?.push(this.normalizeArguments('error', arguments));
    }

    info(message?: unknown, ...optionalParams: unknown[]): void {
        console.info(this.now(), message, ...optionalParams);
        globalWindow.jsLogs?.push(this.normalizeArguments('info', arguments));
    }

    trace(message?: unknown, ...optionalParams: unknown[]): void {
        console.trace(this.now(), message, ...optionalParams);
        globalWindow.jsLogs?.push(this.normalizeArguments('trace', arguments));
    }

    warn(message?: unknown, ...optionalParams: unknown[]): void {
        console.warn(this.now(), message, ...optionalParams);
        globalWindow.jsWarns?.push(this.normalizeArguments('warn', arguments));
    }

    private normalizeArguments(type: string, args: IArguments): string {
        return `[LoggerProxy ${type}]: ${JSON.stringify(args, getCircularReplacer())}`;
    }

    private now(): string {
        return `${globalWindow.ms?.()}:`;
    }
}

export const createLoggingServices = (): LoggingServices => ({
    logger: new LoggerProxy(),
});
