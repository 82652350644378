import {Store} from 'redux';
import Pusher from 'pusher-js/with-encryption';
import {SocketsClient} from './hook/SocketsClient';
import {ListenerRegistry} from './ListenerRegistry';
import {SocketSlice, SocketStatus} from './Redux';

export class SocketService {
    constructor(private socketsClient: SocketsClient, private listeners: ListenerRegistry, private store: Store) {}

    public async connect() {
        const pushers = await this.socketsClient.fetch();
        // Listen to connection state changes
        pushers.forEach(([pusher, channel]) => {
            pusher.connection.bind('state_change', () => {
                const status = this.mapStatus(pusher);
                this.store.dispatch(
                    SocketSlice.actions.status({
                        channel: channel.name,
                        status,
                    })
                );
            });
            this.store.dispatch(
                SocketSlice.actions.connect({
                    channel: channel.name,
                })
            );
        });
        this.listeners.listen(pushers);
    }

    private mapStatus(pusher: Pusher): SocketStatus {
        if (pusher === null) {
            return SocketStatus.connecting;
        }
        /**
         * States:
         * initialized - initial state, never transitioned to
         * connecting - connection is being established
         * connected - connection has been fully established
         * disconnected - on requested disconnection
         * unavailable - after connection timeout or when there's no network
         * failed - when the connection strategy is not supported
         */
        switch (pusher.connection.state) {
            case 'initialized':
            case 'connecting':
                return SocketStatus.connecting;
            case 'connected':
                break;
            case 'disconnected':
            case 'unavailable':
            case 'failed':
                return SocketStatus.disconnected;
            default:
                return SocketStatus.connecting;
        }

        return SocketStatus.connected;
    }
}
