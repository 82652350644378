import {noop} from 'lodash';
import {useEffect, useState} from 'react';
import {useActiveContainerAndItem} from '../../DND';
import {ActiveItemData, ActiveItemSource, ActiveItemType} from '../Redux/activeReducer';
import {SetItemActiveApi, useSetItemActive} from './useSetItemActive';

export interface OnMouseHoverOptions<
    TData extends ActiveItemData = ActiveItemData,
    TElement extends HTMLElement = HTMLElement
> extends SetItemActiveApi<TData> {
    element: TElement | null;
    data: TData;
    disabled?: boolean;
}

export function useOnMouseHover<
    TData extends ActiveItemData = ActiveItemData,
    TElement extends HTMLElement = HTMLElement
>({element, data, disabled, activate, deactivate, iHaveActiveItemSet}: OnMouseHoverOptions<TData, TElement>) {
    const active = useActiveContainerAndItem();
    useEffect(() => {
        if (!element || active) {
            return noop;
        }
        /**
         * This will update the item, when we have the item set.
         *
         * This can cause for maximum depth error, you should make them immutable.
         */
        if (iHaveActiveItemSet) {
            activate(data);
        }
        const onMouseMove = () => {
            if (disabled) {
                return;
            }
            if (iHaveActiveItemSet) {
                return;
            }
            activate(data);
        };
        element.addEventListener('mousemove', onMouseMove);
        element.addEventListener('mouseleave', deactivate);
        return () => {
            element.removeEventListener('mousemove', onMouseMove);
            element.removeEventListener('mouseleave', deactivate);
        };
    }, [
        active,
        activate,
        data,
        deactivate,
        disabled,
        iHaveActiveItemSet,
        element,
    ]);
}

export interface OnMouseHoverWithRef<TData extends ActiveItemData = ActiveItemData> {
    data: TData;
    disabled?: boolean;
    type: ActiveItemType;
    source: ActiveItemSource;
}

export function useOnMouseHoverWithRef<
    TData extends ActiveItemData = ActiveItemData,
    T extends HTMLElement = HTMLElement
>({type, source, data, disabled}: OnMouseHoverWithRef<TData>) {
    const [element, ref] = useState<T | null>(null);
    const api = useSetItemActive({
        type,
        source,
    });
    useOnMouseHover<TData, T>({
        ...api,
        element,
        data,
        disabled,
    });
    return {ref, ...api};
}
