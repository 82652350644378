import {BudgetTab, useBudgettenQuery} from '@growthbase/planning';
import {useResolveConnections} from '@growthbase/graphql';
import {useState} from 'react';

export interface BudgetTabConnectedProps {
    employeeId: string;
}

export const BudgetTabConnected = ({employeeId}: BudgetTabConnectedProps) => {
    const [searchQuery, setSearchQuery] = useState<string>('');

    const {
        data: nextData,
        previousData,
        loading,
    } = useBudgettenQuery({
        variables: {
            medewerkerId: employeeId,
            searchQuery,
        },
    });

    const data = nextData || previousData;
    const budgetten = useResolveConnections(data?.ToegewezenBudgetten);

    return (
        <BudgetTab
            budgetten={budgetten}
            searchQuery={searchQuery}
            onSearchQueryChange={setSearchQuery}
            loading={loading}
        />
    );
};
