import {DroppableContainers} from '@dnd-kit/core/dist/store/types';
import {DNDContainerData, DNDData} from '../Value';

export type ContainerEntry = {
    node: DNDContainerData;
    element: HTMLElement | null;
};

export const convertDroppableContainers = (nodes: DroppableContainers) => {
    const result: ContainerEntry[] = [];
    for (const [, node] of nodes.entries()) {
        // @ts-expect-error no clue, but it works.
        const data = node?.current as unknown as DNDData;
        if (data?.type !== 'container') {
            // eslint-disable-next-line no-continue
            continue;
        }
        result.push({
            node: data,
            element: node?.node?.current ?? null,
        });
    }
    return result;
};
