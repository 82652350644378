import {ReactNode} from 'react';
import styled from 'styled-components';

export interface IconHolderProps {
    icon: ReactNode;
    size?: 'm' | 'l';
}

const sizeToFontSize = (size: IconHolderProps['size']): string => {
    switch (size) {
        case 'm':
            return '18px';
        case 'l':
            return '22px';
        default:
            return '18px';
    }
};

const IconHolderRoot = styled.div<{size?: IconHolderProps['size']}>`
    display: inline-flex;
    place-content: center;
    font-size: ${({size}) => sizeToFontSize(size)};
    border: var(--border);
    border-radius: 100px;
    width: 1em;
    height: 1em;
    background: var(--colorGrayLight);
    box-sizing: border-box;
    position: relative;

    svg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

export const IconHolder = ({icon, size}: IconHolderProps) => <IconHolderRoot size={size}>{icon}</IconHolderRoot>;
