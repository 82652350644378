import {Stack, ViewConfig} from '@growthbase/design-components';
import {PaginationHelpers} from '@growthbase/spa';
import {RenderViewType} from './Components/RenderViewType';
import {OverviewItemData, OverviewFilters, ViewType} from './types';
import {Header, ViewSelect, useViewSelectState} from './Components';

export interface OverviewProps<TBord extends OverviewItemData = OverviewItemData> extends ViewConfig {
    defaultView?: ViewType;
    pagination: PaginationHelpers<TBord>;
    filters: OverviewFilters;
    setFilter: (filter: OverviewFilters) => void;
    renderType: Record<ViewType, RenderViewType<TBord>>;
}

export function Overview<TBord extends OverviewItemData = OverviewItemData>({
    defaultView,
    renderType,
    filters,
    pagination,
    disabled,
    readonly,
    setFilter,
}: OverviewProps<TBord>) {
    const [overzicht, setOverzicht] = useViewSelectState({
        defaultView,
    });
    return (
        <Stack gap="default">
            <Header
                filter={filters}
                loading={pagination.isLoading}
                setFilter={setFilter}
                selectBord={<ViewSelect value={overzicht} onChange={setOverzicht} />}
            />
            <Stack.Item grow>
                {renderType[overzicht]({
                    pagination,
                    readonly,
                    disabled,
                    filters,
                })}
            </Stack.Item>
        </Stack>
    );
}
