import React, {FC, PropsWithChildren, ReactNode, useMemo} from 'react';
import {ApplicationServices, useLogger, useService} from '@growthbase/spa';
import {ErrorBoundary} from 'react-error-boundary';
import {DynamicPosition} from '../positions';

export interface PositionConfig {
    position: DynamicPosition;
    /**
     * Extra condition to match the route.
     */
    route?: string;
}

export interface DynamicContentConfig extends PositionConfig {
    Component: FC;
}

export interface DynamicContentServices {
    configs?: DynamicContentConfig[];
}

export interface UsePageTargetOptions extends PositionConfig {
    Wrapper?: FC<PropsWithChildren>;
}

export const useDynamicContent = ({position, route, Wrapper = React.Fragment}: UsePageTargetOptions): ReactNode => {
    const {configs} = useService<DynamicContentServices & ApplicationServices>();
    const logger = useLogger('useDynamicContent');
    const nodes = useMemo(
        () => (configs ?? []).filter((p) => p.position === position && route === p.route),
        [configs, position, route]
    );

    if (nodes.length === 0) {
        return null;
    }

    return (
        <ErrorBoundary onError={(error, info) => logger.error(error, info)} fallback={null}>
            <Wrapper>
                {nodes.map((node, index) => (
                    <node.Component key={index} />
                ))}
            </Wrapper>
        </ErrorBoundary>
    );
};
