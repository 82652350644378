import {useCallback, useMemo} from 'react';
import {PaginationHelpers} from '../Value';

export const usePaginationMapper = <TInput, TOutput>(
    pagination: PaginationHelpers<TInput>,
    mapper: (input: TInput) => TOutput
): PaginationHelpers<TOutput> => {
    const connections = useMemo(() => pagination.connections.map(mapper), [mapper, pagination.connections]);

    return {
        totalCount: pagination.totalCount,
        connections,
        loadMore: useCallback(
            async (more = 50) => {
                await pagination.loadMore(more);
            },
            [pagination]
        ),
        hasMore: pagination.hasMore,
        isLoading: pagination.isLoading,
    };
};
