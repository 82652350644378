import * as yup from 'yup';

export const ViewSettingsFormSchema = yup
    .object()
    .shape({
        from: yup.string().required(),
        to: yup.string().required(),
        dayFrom: yup.string().required(),
        dayTo: yup.string().required(),
    })
    .test(function test(value) {
        const {createError} = this;
        const {from, to} = value;
        if (from && to && new Date(`1/1/2022 ${from}`) > new Date(`1/1/2022 ${to}`)) {
            return createError({path: 'from', message: 'From must be before to'});
        }
        return true;
    })
    .test(function test(value) {
        const {createError} = this;
        const {dayFrom, dayTo} = value;
        if (dayFrom && dayTo && parseInt(dayFrom, 10) >= parseInt(dayTo, 10)) {
            return createError({path: 'dayFrom', message: 'Start day must be before start from'});
        }
        return true;
    });
