import {QueryCacheUpdateType} from '../useApplyQueryCacheUpdate';
import {ChangeType, DetermineActionParams} from '../useHandleExternalEntityChanges';
import {BaseNode} from '../baseNode';

/**
 * All these function are used to determine the action type for the given change.
 *
 *         determineAction: (params, action) => {
 *             const {typename} = params;
 *             if (typename !== 'TakenBord') {
 *                 return QueryCacheUpdateType.ignore;
 *             }
 *
 *             action = determineActionTypeWhenSearching(action, params, !!filters.searchQuery);
 *             action = determineActionTypeWhenGearchiveerd(action, params, archief);
 *             action = determineActionTypeWhenFiltered(action, params, !!onderdeelVan);
 *
 *             return action;
 *         },
 *
 *  TODO: create middleware of some sort to make this more readable. This getting is a mess.
 */

/**
 * Events from the server that can be used to update the query cache.
 *
 * This is a helper function to determine the default action type for the given change type.
 *
 * @param changeType
 */
export const determineDefaultActionType = (changeType: ChangeType): QueryCacheUpdateType => {
    switch (changeType) {
        case ChangeType.add:
            return QueryCacheUpdateType.add;
        case ChangeType.remove:
            return QueryCacheUpdateType.remove;
        case ChangeType.invalidate:
            return QueryCacheUpdateType.invalidateFields;
        case ChangeType.changed:
            return QueryCacheUpdateType.refetchEntity;
        case ChangeType.changeOrder:
            return QueryCacheUpdateType.reorderEntitiy;
        default:
            return QueryCacheUpdateType.ignore;
    }
};

export const determineActionFilterForTypes = <TNode extends BaseNode>(
    action: QueryCacheUpdateType,
    params: DetermineActionParams<TNode>,
    ...typenames: string[]
): QueryCacheUpdateType => {
    if (action === QueryCacheUpdateType.ignore) {
        return action;
    }
    const {typename} = params;
    if (!typenames.includes(typename)) {
        return QueryCacheUpdateType.ignore;
    }
    return action;
};

export const determineActionForList = <TNode extends BaseNode>(
    action: QueryCacheUpdateType,
    params: DetermineActionParams<TNode>,
    /**
     * If no listId is given we assume the entity is part of the list.
     */
    listId?: string | null
): QueryCacheUpdateType => {
    if (action === QueryCacheUpdateType.ignore) {
        return action;
    }
    const {type} = params;

    // We don't care about this for lists.
    if ([ChangeType.changed, ChangeType.invalidate].includes(type)) {
        return action;
    }
    const {positie} = params;
    if ((listId && !positie) || positie?.listId !== listId) {
        return QueryCacheUpdateType.ignore;
    }
    if (type === ChangeType.remove) {
        return QueryCacheUpdateType.remove;
    }
    if (type === ChangeType.add) {
        return QueryCacheUpdateType.add;
    }
    if (type === ChangeType.changeOrder) {
        return QueryCacheUpdateType.reorderEntitiy;
    }
    throw new Error(`Unknown type: ${type}`);
};

export const determineActionTypeWhenSearching = <TNode extends BaseNode>(
    action: QueryCacheUpdateType,
    params: DetermineActionParams<TNode>,
    searching: boolean
): QueryCacheUpdateType => {
    if (action === QueryCacheUpdateType.ignore) {
        return action;
    }
    if (!searching) {
        return action;
    }
    const {type} = params;
    switch (type) {
        case ChangeType.add:
        case ChangeType.changeOrder:
            return QueryCacheUpdateType.refetch;
        default:
            return action;
    }
};

interface BaseNodeWithGearchiveerd extends BaseNode {
    gearchiveerd?: boolean;
}

export const determineActionTypeWhenGearchiveerd = <TNode extends BaseNodeWithGearchiveerd>(
    action: QueryCacheUpdateType,
    params: DetermineActionParams<TNode>,
    gearchiveerdFilterIsEnabled: boolean | undefined | null
) => {
    if (action === QueryCacheUpdateType.ignore) {
        return action;
    }
    const {type} = params;
    if (type === ChangeType.changed) {
        const {fields, node} = params;
        const {gearchiveerd: nodeGearchiveerd} = node;
        if (fields.includes('gearchiveerd')) {
            action =
                gearchiveerdFilterIsEnabled && nodeGearchiveerd
                    ? QueryCacheUpdateType.add
                    : QueryCacheUpdateType.remove;
        }
    }
    return action;
};

/**
 * Should be last inline inside the determineAction function.
 *
 * @see {useUpdateFromExternalEntityChanges}
 *
 * When filtered on something you have no idea the entities belong to the current query cache.
 */
export const determineActionTypeWhenFiltered = <TNode extends BaseNode>(
    action: QueryCacheUpdateType,
    params: DetermineActionParams<TNode>,
    filtered: boolean
) => {
    if (action === QueryCacheUpdateType.ignore) {
        return action;
    }
    if (filtered && [QueryCacheUpdateType.add, QueryCacheUpdateType.reorderEntitiy].includes(action)) {
        return QueryCacheUpdateType.refetch;
    }
    return action;
};
