import {identity} from 'rxjs';
import {FactoryProps, Field, FieldInstance} from '../contract';

export const createFieldInstance = <T>(
    fields: FactoryProps,
    field: Field<T>,
    factory: (def: T) => T = identity,
    id: string = Math.random().toString(36).substring(7)
): FieldInstance => ({
    id,
    name: field.name,
    value: factory(field.valueType.defaultValue(fields)),
    type: field.valueType.type,
});
