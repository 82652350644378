import {PositieType, useAddMutationCacheUpdate, useChangeOrderInQueryCache} from '@growthbase/cache';

import {DropItemHandlerEvent} from '@growthbase/keys';
import {idFromIri, usePaginationSearchParams} from '@growthbase/routing';
import {ReactNode, useCallback} from 'react';
import {v4} from 'uuid';
import {
    ILijsttemplateTaakTemplateSortableTabelFragment,
    ITaaktemplateTabelFragment,
    useTaaktemplateTabelMaakTaaktemplateMutation,
    useTaaktemplateTabelQuery,
    useVeranderTaaktemplateVanPositieMutation,
} from '@growthbase/graphql';
import {ButtonGroup} from '@growthbase/design-components';
import {MenuConnected} from '../Menu';
import {useEntityChanges} from './Hook/useEntityChanges';
import {SortableTabel, TaakTabelRow} from './SortableTabel';
import {Filters} from '../RenderTableType';

export interface SortableTabelConnectedProps {
    fragment: ILijsttemplateTaakTemplateSortableTabelFragment;
    header: ReactNode;
    filters: Filters;
}

export function SortableTabelConnected({
    fragment: {id: lijsttemplateId},
    header,
    filters,
}: SortableTabelConnectedProps) {
    const {pagination, usePagination} = usePaginationSearchParams<ITaaktemplateTabelFragment>('taaktemplates');
    const result = useTaaktemplateTabelQuery({
        variables: {
            ...pagination,
            lijstId: idFromIri(lijsttemplateId),
            query: filters.searchQuery,
        },
    });

    useEntityChanges({
        result,
        searchQuery: filters.searchQuery,
        lijsttemplateId,
    });

    const [mutate] = useTaaktemplateTabelMaakTaaktemplateMutation({
        // @ts-expect-error TODO: fixme
        update: useAddMutationCacheUpdate(result, PositieType.after),
    });

    const changeOrderInCache = useChangeOrderInQueryCache(result.updateQuery);

    const [changeOrder] = useVeranderTaaktemplateVanPositieMutation();

    const onChangeOrder = useCallback(
        ({
            item: row,
            previousContainer,
            overContainer,
            placement: {itemThatIsBefore, itemThatIsAfter},
        }: DropItemHandlerEvent<TaakTabelRow>): Promise<void> => {
            if (lijsttemplateId === null) {
                throw new Error('lijsttemplateId is null');
            }
            changeOrderInCache(row.id, itemThatIsAfter?.id, itemThatIsBefore?.id);
            return changeOrder({
                variables: {
                    input: {
                        id: idFromIri(row.id),
                        nieuweLijstId: overContainer.id,
                        vorigeLijstId: previousContainer.id,
                        positieInNieuweLijst: {
                            onderTaakId: itemThatIsBefore ? idFromIri(itemThatIsBefore.id) : null,
                            bovenTaakId: itemThatIsAfter ? idFromIri(itemThatIsAfter.id) : null,
                        },
                    },
                },
            }).then(() => undefined);
        },
        [lijsttemplateId, changeOrderInCache, changeOrder]
    );

    return (
        <SortableTabel<ITaaktemplateTabelFragment>
            renderMenu={useCallback(
                (row) => (
                    <ButtonGroup disableRightMargin>
                        <MenuConnected fragment={row as ITaaktemplateTabelFragment} />
                    </ButtonGroup>
                ),
                []
            )}
            header={header}
            enableTakenToevoegen={lijsttemplateId !== null}
            pagination={usePagination(result)}
            lijsttemplateId={lijsttemplateId}
            onDrop={onChangeOrder}
            onSubmit={useCallback(
                (title) => {
                    const id = v4();
                    return mutate({
                        variables: {
                            input: {
                                id,
                                lijsttemplateId,
                                titel: title,
                            },
                        },
                    }).then(() => undefined);
                },
                [mutate, lijsttemplateId]
            )}
        />
    );
}
