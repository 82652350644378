import {MbscResource} from '@mobiscroll/react';
import {PlanningStoreState} from '@growthbase/planning';
import {useSelector} from 'react-redux';
import {useMemo} from 'react';

export const useLockOtherResourcesOnDragging = (resources: MbscResource[] | undefined) => {
    const {draggingBudgetForResource} = useSelector((state: PlanningStoreState) => state.planning);
    return useMemo(() => {
        if (!resources) {
            return resources;
        }

        return resources.map((resource) => ({
            ...resource,
            eventCreation: !draggingBudgetForResource || draggingBudgetForResource === resource.id,
        }));
    }, [draggingBudgetForResource, resources]);
};
