import {ILijsttemplateTaakButtonsMenuConnectedFragment} from '@growthbase/graphql';
import {Menu} from './Menu';
import {VerwijderButtonConnected} from './Components';

export interface ButtonsMenuConnectedProps {
    fragment: ILijsttemplateTaakButtonsMenuConnectedFragment;
}

export const MenuConnected = ({fragment}: ButtonsMenuConnectedProps) => (
    <Menu
        availableActions={{
            verwijderTaakUitLijsttemplate: fragment?.availableActions?.kanVerwijderen ?? false,
        }}
        renderVerwijderenButton={(props) => <VerwijderButtonConnected fragment={fragment} {...props} />}
    />
);
