import React from 'react';
import {IBordCardFragment} from '@growthbase/graphql';
import {ViewConfig} from '@growthbase/design-components';
import {Card} from './Card';
import {CardHeaderConnected} from './Components/CardHeader/CardHeaderConnected';
import {DeelnemersFieldConnected, OnderdeelVanFieldConnected} from '../../../../../EditForm/Components';

export interface CardProps extends ViewConfig {
    fragment: IBordCardFragment;
}

export function CardConnected({fragment, disabled, readonly}: CardProps) {
    return (
        <Card
            header={<CardHeaderConnected disabled={disabled} readonly={readonly} fragment={fragment} />}
            locatie={<OnderdeelVanFieldConnected disabled={disabled} readonly={readonly} fragment={fragment} />}
            deelnemers={
                <DeelnemersFieldConnected disabled={disabled} readonly={readonly} fragment={fragment} stackSize={3} />
            }
            hasDeelnemers={(fragment?.deelnemers && fragment.deelnemers.length > 0) || false}
            hasLocatie={!!fragment?.onderdeelVan}
        />
    );
}
