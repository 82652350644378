import {createRoutes, PageFromMenu} from '@growthbase/routing';
import React from 'react';
import {RoutePopupProvider} from '@growthbase/design-components';
import {LogbookConnected} from '@growthbase/activiteiten';
import {VoorkeurenFormulierConnected} from './VoorkeurenFormulier';
import {AlgemeenFormulierConnected} from './AlgemeenFormulier';
import {IndexPagina} from './IndexPagina/IndexPagina';

export const TakenInstellingenRoutes = createRoutes('takeninstellingen', {
    index: {
        Element: () => (
            <PageFromMenu sidebar={<LogbookConnected />}>
                <IndexPagina />
            </PageFromMenu>
        ),
    },
    algemeen: {
        Element: () => (
            <PageFromMenu sidebar={<LogbookConnected />}>
                {' '}
                <AlgemeenFormulierConnected view />
            </PageFromMenu>
        ),
    },
    algemeen_bewerken: {
        defaultBackgroundRoute: 'algemeen',
        Element: () => (
            <RoutePopupProvider>
                <AlgemeenFormulierConnected view={false} />
            </RoutePopupProvider>
        ),
    },
    mijnvoorkeuren: {
        Element: () => (
            <PageFromMenu sidebar={<LogbookConnected />}>
                <VoorkeurenFormulierConnected view />
            </PageFromMenu>
        ),
    },
    mijnvoorkeuren_bewerken: {
        defaultBackgroundRoute: 'mijnvoorkeuren',
        Element: () => (
            <RoutePopupProvider>
                <VoorkeurenFormulierConnected view={false} />
            </RoutePopupProvider>
        ),
    },
});
