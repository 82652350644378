import {createSelector} from '@reduxjs/toolkit';
import {useSelector} from 'react-redux';
import {DNDContainerUserData, DNDItemUserData, DNDActiveState, DNDStoreState} from '../Value';

const ActiveSelector = createSelector(
    (state: DNDStoreState): DNDActiveState | null => {
        const {active} = state.DND;
        if (!active) {
            return null;
        }
        const sourceContainer = state.DND.containers[active.sourceContainerId];
        if (!sourceContainer) {
            throw new Error(`sourceContainer ${active.sourceContainerId} not found`);
        }
        const dropContainerState = state.DND.containers[active.overContainerId] ?? null;
        const dropContainer: DNDContainerUserData | null = dropContainerState
            ? dropContainerState.container.userData
            : null;

        const itemOverState = active.itemOverId
            ? dropContainerState?.items.find((i) => i.id === active.itemOverId)
            : null;
        const draggedOverItem: DNDItemUserData | null = itemOverState ? itemOverState.userData : null;

        return {
            type: active.item.userDataType,
            draggedItem: active.item.userData,
            draggedOverItem,
            sourceContainer: sourceContainer.container.userData,
            draggedOverContainer: dropContainer,
        };
    },
    (state) => state
);

export const useActiveState = () => useSelector(ActiveSelector);
