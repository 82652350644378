import {formatTimeDigital, Line, Padding, Scrollable, SearchForm, Stack} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';
import {MedewerkerSwitch} from '../../Components';
import {DroppableBudget} from './DroppableBudget';
import {useSelectedAgenda} from '../../_hooks';
import {IToegewezenBudgetFragment} from '../../generated/generated';
import {useTotalBudget} from './useTotalBudget';

export interface BudgetTabProps {
    budgetten: IToegewezenBudgetFragment[];
    loading?: boolean;
    searchQuery?: string;
    onSearchQueryChange: (query: string) => void;
}

export const BudgetTab = ({budgetten, searchQuery = '', loading = false, onSearchQueryChange}: BudgetTabProps) => {
    const agenda = useSelectedAgenda();
    const {t} = useTranslation('Planning.nl');

    const totalBudget = useTotalBudget(budgetten);

    return (
        <Stack>
            <Stack.Item>
                <SearchForm
                    big
                    noBorders
                    loading={loading}
                    value={{query: searchQuery}}
                    onChange={(searchInput) => {
                        if (onSearchQueryChange) {
                            onSearchQueryChange(searchInput.query ?? '');
                        }
                    }}
                />
                <Line />
            </Stack.Item>
            {totalBudget > 0 && (
                <Stack.Item>
                    <Padding>
                        <Stack justify horizontal>
                            <div>{t('NogTePlannen')}:</div>
                            <div>{formatTimeDigital(totalBudget)}</div>
                        </Stack>
                    </Padding>
                    <Line />
                </Stack.Item>
            )}
            <Stack.Item grow>
                <Scrollable fillParent>
                    <Padding>
                        {agenda && budgetten.length === 0 && (
                            <div>{t('ErIsGeenBudgetMeerVoorXOmInTePlannen', {naam: agenda.title})}</div>
                        )}
                        <Stack gap="small">
                            {budgetten.map((budget, index) => (
                                <DroppableBudget key={index} budget={budget} />
                            ))}
                        </Stack>
                    </Padding>
                </Scrollable>
            </Stack.Item>
            <Stack.Item>
                <Line />
                <Padding>
                    <MedewerkerSwitch />
                </Padding>
            </Stack.Item>
        </Stack>
    );
};
