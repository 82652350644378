import React from 'react';
import classNames from 'classnames/bind';
import {useTranslation} from 'react-i18next';
import styles from './MedewerkerResource.module.scss';
import {formatTime} from '../formatTime';
import {Tooltip} from '../../../Tooltip';
import {GridLayout} from '../../../Layout';

const cx = classNames.bind(styles);

export interface Rooster {
    day: number;
    workSeconds: number;
    date: string;
    isWorkDay: boolean;
}

export interface ResourceTime {
    isEmpty: boolean;
    capacityInSeconds: number;
    timePlannedInSeconds: number;
    timeLeftOverInSeconds: number;
    resourceTimeAvailablePercentage: number;
}

/**
 * TODO: remove this interface, only define the properties that are used inside a prop type.
 */
export interface Medewerker {
    id: string;
    name: string;
    resourceTime: ResourceTime;
    weekNumberForStartDate: number;
}

export interface MedewerkerResourceProps {
    name: string;
    resourceTime: ResourceTime;
}

export const MedewerkerResource = ({name, resourceTime}: MedewerkerResourceProps) => {
    const classes = cx('medewerkerResource');
    const {t} = useTranslation('spa.nl');

    const TooltipConent = (
        <>
            <GridLayout columns={2} verticalGap={3} horizontalGap={10}>
                <div>Capaciteit:</div>
                <div>{formatTime(resourceTime.capacityInSeconds)}</div>
                <div>Geplanned:</div>
                <div>{formatTime(resourceTime.timePlannedInSeconds)}</div>
                <div>Resterend:</div>
                <div>{formatTime(resourceTime.timeLeftOverInSeconds)}</div>
            </GridLayout>
        </>
    );

    return (
        <div className={classes}>
            <div className={cx('medewerkerResource__inner')}>
                <div className={cx('medewerkerResource__name')}>{name}</div>
                {resourceTime.isEmpty && (
                    <span className={cx('medewerkerResource__empty')}>{t('capaciteit.geenRoosterIngesteld')}</span>
                )}
                {!resourceTime.isEmpty && (
                    <Tooltip content={TooltipConent}>
                        <span>{formatTime(resourceTime.capacityInSeconds)}</span>
                    </Tooltip>
                )}
            </div>
        </div>
    );
};
