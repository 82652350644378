import {ITaakomschrijvingFieldFragment, useTaakomschrijvingFieldMutation} from '@growthbase/graphql';
import {FC} from 'react';
import {useCreateMutationHandler} from '@growthbase/design-components';
import {TaakomschrijvingField} from './index';

export interface TaakomschrijvingFieldConnectedProps {
    fragment?: ITaakomschrijvingFieldFragment;
}

export const TaakomschrijvingFieldConnected: FC<TaakomschrijvingFieldConnectedProps> = ({fragment}) => (
    <TaakomschrijvingField
        onSubmit={useCreateMutationHandler(useTaakomschrijvingFieldMutation()[0])}
        fragment={fragment}
    />
);
