import {Stack} from '@growthbase/design-components';
import {useHTTPClient} from '@growthbase/spa';
import {useCallback, useEffect, useState, FC} from 'react';
import {useInterval} from 'react-use';
import {HealthInterface} from './Health';
import {HealthContent} from './HealthContent';

export const HealthPage: FC = () => {
    const client = useHTTPClient();

    const [infoCollection, setInfoCollection] = useState<HealthInterface[]>([]);
    const [error, setError] = useState<string | null>(null);

    const fetch = useCallback(() => {
        client
            .get<HealthInterface[]>('/system/info?history=true')
            .toPromise()
            .then((result) => {
                if (!result) {
                    return;
                }
                setError(null);
                setInfoCollection(result);
            })
            .catch((e) => {
                setError(`${e}`);
                setInfoCollection([]);
            });
    }, [client, setInfoCollection]);

    useInterval(fetch, 1000 * 2);

    useEffect(() => {
        fetch();
    }, [fetch]);

    if (error) {
        return <>Error: {error}</>;
    }
    return (
        <Stack gap="medium">
            {infoCollection.map((info) => (
                <Stack.Item key={info.name}>
                    <HealthContent value={info} />
                </Stack.Item>
            ))}
        </Stack>
    );
};
