import {ExternalLinkType, useExternalLink} from '@growthbase/clientbox';
import {MentionElementProps, Value} from '@udecode/plate';
import {useMemo} from 'react';
import {useSelected} from 'slate-react';
import classNames from 'classnames/bind';
import styles from './GebruikerMention.module.scss';

export interface Gebruiker {
    gebruiker: {
        id: string;
        label: string;
        externalId?: string;
    };
}

const cx = classNames.bind(styles);

export const GebruikerMention = (props: MentionElementProps<Value> & {element: Gebruiker}) => {
    const {
        attributes,
        children,
        element: {gebruiker, value},
        prefix,
    } = props;
    const {label} = gebruiker;
    const link = useExternalLink(
        useMemo(
            () => ({
                linkType: ExternalLinkType.Gebruiker,
                ...gebruiker,
            }),
            [gebruiker]
        )
    );

    const selected = useSelected();

    const classes = cx('gebruikerMention', {
        'gebruikerMention--selected': selected,
    });

    if (link) {
        return (
            <a
                {...attributes}
                rel="noopener noreferrer"
                target="_blank"
                data-slate-value={value}
                className={classes}
                contentEditable={false}
                href={link}
            >
                {prefix}
                {label}
                {children}
            </a>
        );
    }
    return (
        <span {...attributes} data-slate-value={value} className={styles.GebruikerMention} contentEditable={false}>
            {prefix}
            {label}
            {children}
        </span>
    );
};
