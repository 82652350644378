import React, {PropsWithChildren} from 'react';
import {FormikContextType} from 'formik/dist/types';
import {Buttons} from '../../../../Button';
import {ButtonGroup} from '../../../../Buttons';
import {PopupCancelButton} from '../../../../Popups';
import {ModalFooter} from './ModalFooter';

export interface DefaultFormModalFooterProps<T> extends PropsWithChildren {
    formik: FormikContextType<T>;
    readonly?: boolean;
    negativeMargin?: boolean;
}

export function DefaultFormModalFooter<T>({
    formik,
    readonly,
    negativeMargin,
    children,
}: DefaultFormModalFooterProps<T>) {
    return (
        <ModalFooter
            negativeMargin={negativeMargin}
            buttons={
                <ButtonGroup justify>
                    <PopupCancelButton disabled={formik.isSubmitting} />
                    {!readonly && <Buttons.Save formik={formik}>{children}</Buttons.Save>}
                </ButtonGroup>
            }
        />
    );
}
