import React from 'react';
import {DragOverlay} from '@dnd-kit/core';
import {useIsSSR} from '@growthbase/spa';
import {useActiveContainerAndItem} from '../Hook';
import {DNDDraggedItemOverlay} from './DNDDraggedItemOverlay';

export const DNDDraggedOverlay = () => {
    const isServer = useIsSSR();
    const active = useActiveContainerAndItem();
    if (isServer) {
        return null;
    }
    return (
        <DragOverlay>
            {active ? <DNDDraggedItemOverlay container={active.container} state={active.state} /> : null}
        </DragOverlay>
    );
};
