import {ControlField} from './types';

export class ControlFieldCollection {
    private fields: ControlField[];

    constructor(...fields: ControlField[]) {
        this.fields = fields;
    }

    public addField(field: ControlField): void {
        this.fields.push(field);
    }

    public get(name: string): ControlField {
        const field = this.fields.find((f) => f.name === name);
        if (!field) {
            throw new Error(`Field '${name}' not found`);
        }
        return field;
    }

    public getFields(): ControlField[] {
        return this.fields;
    }
}
