import {FC, ReactNode} from 'react';
import {Link, To} from 'react-router-dom';

import classNames from 'classnames/bind';
import styles from './TableEditLink.module.scss';

import {HoverIcon} from '../HoverIcon';

const cx = classNames.bind(styles);

export interface TableEditLinkProps {
    children: ReactNode;
    to: To;
    editable?: boolean;
    completed?: boolean;
}

export const TableEditLink: FC<TableEditLinkProps> = ({children, to, editable = true, completed}) => {
    const classes = cx('tableEditLink', {
        'tableEditLink--completed': completed,
    });
    return (
        <Link className={classes} to={to}>
            {editable ? <HoverIcon overflowEllipsis>{children}</HoverIcon> : children}
        </Link>
    );
};
