import {ClientMutationIdSchema, TitelSchema, UuidFieldSchema} from '@growthbase/design-components';
import {IWijzigTaaktitelTaakInput} from '@growthbase/graphql';
import {TFunction} from 'i18next';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {SchemaOf} from 'yup';

export const TaaktitelFieldSchema = (t: TFunction): SchemaOf<IWijzigTaaktitelTaakInput> =>
    yup
        .object({
            clientMutationId: ClientMutationIdSchema(t),
            id: UuidFieldSchema(t),
            titel: TitelSchema(t),
        })
        .required();

export const useTaaktitelFieldSchema = () => {
    const {t} = useTranslation();
    return useMemo(() => TaaktitelFieldSchema(t), [t]);
};
