import {useCallback} from 'react';
import {noop} from 'rxjs';
import {FetchResult} from '@apollo/client/link/core';
import {MutationFunctionOptions} from '@apollo/client/react/types/types';

export type MutationFunction<TData, TVariables> = (
    options?: MutationFunctionOptions<TData, TVariables>
) => Promise<FetchResult<TData>>;

export const useCreateMutationHandler = <TData, TVariables>(
    mutationFunction: MutationFunction<TData, {input: TVariables}>,
    handle: (input: TData) => void | Promise<void> = noop
) =>
    useCallback(
        (values: TVariables) =>
            mutationFunction({
                variables: {
                    input: values,
                },
            }).then((result) => {
                if (result.data) {
                    return handle(result.data);
                }
                return Promise.resolve();
            }),
        [mutationFunction, handle]
    );
