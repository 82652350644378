import {ReactNode, useMemo} from 'react';
import {eq} from 'lodash';
import {RadioSwitchItem} from '../RadioSwitchItem';
import {RadioSwitchWrapper} from '../RadioSwitchWrapper';

export interface RadioSwitchOption<T> {
    label: ReactNode;
    value: T;
    testId?: string;
}

export interface RadioSwitchProps<T> {
    value: T | null;
    isSelect?: (a: T, b: T) => boolean;
    items: RadioSwitchOption<T>[];
    onChange: (value: T) => void;
    square?: boolean;
    testId?: string;
}

export function RadioSwitch<T>({items, onChange, value, square, isSelect = eq, testId}: RadioSwitchProps<T>) {
    return (
        <RadioSwitchWrapper testId={testId}>
            {items.map((item, index) => (
                <RadioSwitchItem
                    square={square}
                    first={index === 0}
                    last={index === items.length - 1}
                    active={value !== null && value !== undefined && isSelect(item.value, value)}
                    key={index}
                    testId={item.testId}
                    onClick={() => onChange(item.value)}
                >
                    {item.label}
                </RadioSwitchItem>
            ))}
        </RadioSwitchWrapper>
    );
}

export interface DeprecatedRadioSwitchProps<T> {
    defaultItem: T | null;
    items: T[];
    onChange: (value: T) => void;
    renderLabel: (value: T) => ReactNode;
    square?: boolean;
    testId?: string;
}

/**
 * @deprecated Should not be used anymore, use RadioSwitch instead.
 */
export function DeprecatedRadioSwitch<T>({
    defaultItem,
    items,
    onChange,
    renderLabel,
    square,
    testId,
}: DeprecatedRadioSwitchProps<T>) {
    const options = useMemo(
        () =>
            items.map((item) => ({
                label: renderLabel(item),
                value: item,
            })),
        [items, renderLabel]
    );
    return <RadioSwitch<T> value={defaultItem} items={options} onChange={onChange} square={square} testId={testId} />;
}
