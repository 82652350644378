import {Button, Modal, ModalContent, ModalFooter, PopupHeader, usePopup} from '@growthbase/design-components';
import {IBestandType} from '@growthbase/graphql';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';

export interface BestandViewProps {
    type: IBestandType;
    path: string;
    url?: string | null;
    uploaded?: boolean;
}

export const BestandView: FC<BestandViewProps> = ({type, path, url, uploaded}) => {
    const {t} = useTranslation('Bestandsbeheer.nl');

    const {close} = usePopup();

    const renderContent = () => {
        if (!uploaded) {
            return <div>spinner</div>;
        }
        if (type === IBestandType.Pdf) {
            return <div>PDF</div>;
        }
        if (type === IBestandType.Video) {
            return <div>video</div>;
        }
        if (type === IBestandType.Afbeelding && url) {
            return <img src={url} alt={path} />;
        }
        if (type === IBestandType.Archief) {
            return <div>archief</div>;
        }
        return <div>unknown</div>;
    };

    return (
        <Modal>
            <PopupHeader title={path} />
            <ModalContent>{renderContent()}</ModalContent>
            <ModalFooter
                buttons={
                    <Button onClick={close} variant="primary">
                        {t('afsluiten')}
                    </Button>
                }
            />
        </Modal>
    );
};
