import {Buttons, IconSize} from '@growthbase/design-components';
import {
    IVoltooiButtonConnectedFragment,
    useActiveerEvenementMutation,
    useVoltooiEvenementMutation,
} from '@growthbase/planning';
import {useAppendMessage, useCallbackRef} from '@growthbase/spa';
import {useTranslation} from 'react-i18next';
import {useBoolean} from 'react-use';
import {useEffect} from 'react';

export interface VoltooiButtonProps {
    fragment: IVoltooiButtonConnectedFragment;
    title: string;
    onChange?: () => void;
}

export const VoltooiButtonConnected = ({fragment, title, onChange}: VoltooiButtonProps) => {
    const [voltooiMutation] = useVoltooiEvenementMutation();
    const [activeerMutation] = useActiveerEvenementMutation();
    const [loading, setLoading] = useBoolean(false);
    const addMessages = useAppendMessage();
    const {t} = useTranslation('Planning.nl');

    useEffect(() => {
        setLoading(false);
    }, [fragment, setLoading]);

    const voltooi = useCallbackRef(() => {
        setLoading(true);

        const mutation = voltooiMutation({
            variables: {
                evenementId: fragment.id,
            },
        }).then(() => {
            if (onChange) {
                onChange();
            }
        });

        addMessages([
            {
                id: 'voltooi-evenement',
                displayType: 'user',
                type: 'success',
                message: t('EvenementIsWordtVoltooid', {naam: title}) ?? '',
                promise: {
                    promise: mutation,
                    pending: t('EvenementXWordtVoltooid', {naam: title}) ?? '',
                },
            },
        ]);
    });

    const activeer = useCallbackRef(() => {
        setLoading(true);

        const mutation = activeerMutation({
            variables: {
                evenementId: fragment.id,
            },
        }).then(() => {
            if (onChange) {
                onChange();
            }
        });

        addMessages([
            {
                id: 'activeer-evenement',
                displayType: 'user',
                type: 'success',
                message: t('EvenementXIsGeactiveerd', {naam: title}) ?? '',
                promise: {
                    promise: mutation,
                    pending: t('EvenementXWordtGeactiveerd', {naam: title}) ?? '',
                },
            },
        ]);
    });

    if (!fragment.geschiktVoorUrenregistratie) {
        return <></>;
    }

    if (fragment.urenRegistratieId) {
        return (
            <Buttons.Check
                square
                size="medium"
                iconSize={IconSize.SIZE_3}
                onClick={activeer}
                loading={loading}
                active
            />
        );
    }

    return <Buttons.Check square size="medium" iconSize={IconSize.SIZE_3} onClick={voltooi} loading={loading} />;
};
