import {PopupControlsContext, useMemoryPopupControls} from '@growthbase/design-components';
import {useMemo} from 'react';
import {IKoppelButtonConnectedFragment} from '@growthbase/graphql';
import {KoppelbaarItem, KoppelbaarItemType} from '../../KoppelPopup';
import {KoppelButtonTypeProps} from './KoppelButtonComponentType';
import {KoppelButton} from './KoppelButton';
import {useNormalizeTaakOnderdeelVanFragment} from './Hook/useNormalizeTaakOnderdeelVanFragment';
import {useQueryKoppelGroepen} from './Hook/useQueryKoppelGroepen';

export interface KoppelButtonConnectedProps extends KoppelButtonTypeProps {
    fragment: IKoppelButtonConnectedFragment | null;
    onChange: (item: KoppelbaarItem) => Promise<void>;
    onRemove: () => Promise<void>;
    relatieverwijzingId?: string;
    readonly?: boolean;
    textSize?: 'small' | 'default';
    textColor?: 'default' | 'gray';
}

export const KoppelButtonConnected = ({fragment, open, readonly, ...props}: KoppelButtonConnectedProps) => {
    const helpers = useMemoryPopupControls(open);
    const item = useNormalizeTaakOnderdeelVanFragment(fragment);

    const type = item?.type;

    const kanBewerken = useMemo(() => {
        const availableActions = fragment?.availableActions;
        if (!availableActions) {
            return true;
        }
        if (readonly) {
            return false;
        }
        const {gekoppeldeAbonnementBewerken, gekoppeldeOpdrachtBewerken, gekoppeldeTicketBewerken} = availableActions;

        if (type === KoppelbaarItemType.Opdracht && !gekoppeldeOpdrachtBewerken) {
            return false;
        }

        if (type === KoppelbaarItemType.Abonnement && !gekoppeldeAbonnementBewerken) {
            return false;
        }

        return !(type === KoppelbaarItemType.Ticket && !gekoppeldeTicketBewerken);
    }, [fragment, readonly, type]);

    const {searchQuery, setSearchQuery, loading, relatiegroepen} = useQueryKoppelGroepen({
        skip: !helpers.isOpen || !kanBewerken,
        variables: {
            relatieverwijzingId: props.relatieverwijzingId,
        },
    });

    return (
        <PopupControlsContext.Provider value={helpers}>
            <KoppelButton
                {...props}
                relatiegroepen={relatiegroepen}
                readonly={readonly || !kanBewerken}
                loading={loading}
                query={searchQuery.query ?? null}
                item={item}
                onChangeQuery={setSearchQuery}
            />
        </PopupControlsContext.Provider>
    );
};
