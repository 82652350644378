import {
    IToggleDeelnameAanTaakFragment,
    useVerwijderMijAlsDeelnemerMutation,
    useVoegMijToeAlsDeelnemerMutation,
} from '@growthbase/graphql';
import {useHandleError} from '@growthbase/spa';
import {useCallback} from 'react';
import {compareIds, idFromIri} from '@growthbase/routing';
import {useLockItemHandler} from '@growthbase/locking';

export interface ToggleDeelnameAanTaakOptions {
    gebruikerId: string | null;
}

export interface ToggleDeelnameAanTaakResult {
    loading: boolean;
    toggle: (fragment: IToggleDeelnameAanTaakFragment) => boolean;
}

export const useToggleDeelnameAanTaak = ({gebruikerId}: ToggleDeelnameAanTaakOptions): ToggleDeelnameAanTaakResult => {
    const [neemDeel, {loading: loadingNeemDeel}] = useVoegMijToeAlsDeelnemerMutation();
    const [neemGeenDeel, {loading: loadingNeemGeenDeel}] = useVerwijderMijAlsDeelnemerMutation();
    const handleError = useHandleError();
    const loading = loadingNeemDeel || loadingNeemGeenDeel;
    const lock = useLockItemHandler();
    const toggle = useCallback(
        (taak: IToggleDeelnameAanTaakFragment) => {
            const {deelnemers, id} = taak;
            if (loading || !gebruikerId) {
                return false;
            }
            let includes = false;
            for (const deelnemer of deelnemers ?? []) {
                if (compareIds(deelnemer?.gebruiker?.id, gebruikerId)) {
                    includes = true;
                }
            }
            const mutationOptions = {
                variables: {
                    input: {
                        id: idFromIri(id),
                        gebruikerId: idFromIri(gebruikerId),
                    },
                },
            };
            lock(taak);
            const unlock = () => lock(taak, false);
            if (includes) {
                neemGeenDeel(mutationOptions).catch(handleError).finally(unlock);
            } else {
                neemDeel(mutationOptions).catch(handleError).finally(unlock);
            }
            return true;
        },
        [
            lock,
            gebruikerId,
            loading,
            handleError,
            neemDeel,
            neemGeenDeel,
        ]
    );

    return {
        toggle,
        loading,
    };
};
