import React, {ReactNode, FC} from 'react';

import classNames from 'classnames/bind';
import {capitalize} from 'lodash';
import {CSSProperties} from 'styled-components';
import styles from './Padding.module.scss';

export interface PaddingProps {
    children: ReactNode;
    direction?: 'horizontal' | 'vertical';
    paddingSize?: 'default' | 'medium' | 'small' | 'none' | 'large';
    height?: number | string;
    width?: number | string;
    style?: CSSProperties;
}

const cx = classNames.bind(styles);

export const Padding: FC<PaddingProps> = ({
    children,
    paddingSize = 'default',
    direction = 'both',
    width,
    height,
    style,
}) => {
    const className = cx(
        'padding',
        `padding--size${capitalize(paddingSize)}`,
        `padding--direction${capitalize(direction)}`
    );

    const dimensionsStyle: CSSProperties = {
        width,
        height,
        ...style,
    };

    return (
        <div className={className} style={dimensionsStyle}>
            {children}
        </div>
    );
};

export const PaddingHorizontal = (props: PaddingProps) => <Padding {...props} direction="horizontal" />;
export const PaddingVertical = (props: PaddingProps) => <Padding {...props} direction="vertical" />;
