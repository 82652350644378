import classNames from 'classnames';
import React, {FC, ReactNode} from 'react';

import styles from '../Table.module.scss';

export interface TableFooterFormProps {
    children: ReactNode;
}

export const TableFooterForm: FC<TableFooterFormProps> = ({children}) => (
    <div className={classNames(styles.table__footer, styles.table__form)}>{children}</div>
);
