import {useLocalStorage} from 'react-use';

export const useChangeOrientation = () => {
    const [orientation, setOrientation] = useLocalStorage('dashboard-orientation', 'bottom-right');
    const isBottom = orientation?.includes('bottom');
    const isRight = orientation?.includes('right');
    const changeVertical = () => {
        const horizontal = isRight ? 'right' : 'left';
        setOrientation((isBottom ? 'top-' : 'bottom-') + horizontal);
    };
    const changeHorizontal = () => {
        const vertical = isBottom ? 'bottom' : 'top';
        setOrientation(vertical + (isRight ? '-left' : '-right'));
    };
    return {
        orientation,
        isBottom,
        isRight,
        changeVertical,
        changeHorizontal,
    };
};
