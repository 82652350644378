import {BaseButtonProps, Buttons} from '@growthbase/design-components';
import {IBordKopieerButtonFragment, useBordKopieerButtonConnectedMutation} from '@growthbase/graphql';
import {FC, useCallback} from 'react';
import {v4} from 'uuid';
import {KopieerButton} from './KopieerButton';
import {BordKopierenFormValues, KopieerForm} from '../KopieerForm/KopieerForm';

export interface KopieerButtonConnectedProps {
    fragment: IBordKopieerButtonFragment;
    onCreated?: (id: string) => void;
    Button: FC<BaseButtonProps>;
}

export const KopieerButtonConnected: FC<KopieerButtonConnectedProps> = ({
    Button = Buttons.Copy,
    fragment,
    onCreated,
}) => {
    const [mutation] = useBordKopieerButtonConnectedMutation();
    return (
        <KopieerButton<BordKopierenFormValues>
            Button={Button}
            renderForm={({onSubmit}) => <KopieerForm onSubmit={onSubmit} titel={fragment.naam} />}
            onSubmit={useCallback(
                async (values): Promise<void> => {
                    if (!fragment) {
                        return;
                    }
                    const id = v4();

                    await mutation({
                        variables: {
                            input: {
                                id,
                                naam: values.naam,
                                opties: {
                                    copyId: id,
                                    originalId: fragment.id,
                                    metActiviteiten: values.activiteiten,
                                },
                                bordOpties: {
                                    deelnemers: values.deelnemers,
                                    omschrijving: values.omschrijving,
                                    gekoppeldeItem: values.gekoppeldeItem,
                                    lijsten: values.lijsten,
                                    zichtbaarVoor: values.zichtbaarVoor,
                                    taakFilter: values.taakFilter,
                                    taakOpties: values.taken
                                        ? {
                                              ...values.taak,
                                              relatie: values.taak.relatie
                                                  ? {idVanGekopieerdeRelatieverwijzing: v4()}
                                                  : null,
                                          }
                                        : null,
                                },
                            },
                        },
                    });
                    if (onCreated) {
                        onCreated(id);
                    }
                },
                [fragment, mutation, onCreated]
            )}
        />
    );
};
