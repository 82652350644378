import {useQueryPagination} from '@growthbase/spa';
import React, {FC, ReactNode, useMemo} from 'react';
import {
    ILogsTableFragment,
    ILogsOverviewQuery,
    ILogsOverviewQueryVariables,
    IOrder,
    useLogsOverviewQuery,
} from '@growthbase/graphql';
import {Stack} from '@growthbase/design-components';
import {Box, FormControlLabel, Tab, Tabs, TextField} from '@mui/material';
import {
    useMultilineStringSearchParam,
    useStateBooleanFromSearchParam,
    useStateNumberFromSearchParam,
    useStateStringFromSearchParam,
} from '@growthbase/routing';
import Checkbox from '@mui/material/Checkbox';
import {LogLevelSelect, useLogLevelState} from '../LogLevelSelect';
import {LogsTable, useLogsTableConverter} from '../LogsTable';
import {StatisticsTableConnected} from '../StatisticsTable';

export interface RenderFilterProps {
    tag?: string;
    actief?: boolean;
    running?: boolean;
    projectionId?: string;
    profielId?: string;
    processId?: number;
    hideFilters: true;
}

export interface LogsTableConnectedProps {
    hideActiefFilter?: boolean;
    hideRunningFilter?: boolean;
    projectionId?: string;
    hideProjectionFilter?: boolean;
    profielId?: string;
    hideProfielFilter?: boolean;
    processId?: number;
    infoContent?: ReactNode;
    renderProcess?: (process: RenderFilterProps) => ReactNode;
    renderProfielen?: (process: RenderFilterProps) => ReactNode;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const LogsTableConnected: FC<LogsTableConnectedProps> = ({
    renderProcess,
    infoContent,
    projectionId: initialProjectionId,
    profielId: initialProfielId,
    processId: initialProcessId,
    hideProfielFilter,
    hideProjectionFilter,
    hideActiefFilter,
    hideRunningFilter,
    renderProfielen,
}) => {
    const [includeQuery, setIncludeQuery, messageInclude] = useMultilineStringSearchParam('iq');
    const [excludeQuery, setExcludeQuery, messageExclude] = useMultilineStringSearchParam('eq');
    const [stateProcessId, setProcessId] = useStateNumberFromSearchParam('processId');
    const [tab, setTab] = useStateNumberFromSearchParam('tab', 0);
    const [actief, setActief] = useStateBooleanFromSearchParam('actief', hideActiefFilter ? undefined : true);
    const [running, setRunning] = useStateBooleanFromSearchParam('running');
    const [tag, setTag] = useStateStringFromSearchParam('tag');
    const [levels, setLevels] = useLogLevelState();
    const [stateProfielId, setProfielId] = useStateStringFromSearchParam('profielId');
    const [stateProjectionId, setProjectionId] = useStateStringFromSearchParam('projectionId');

    const processId = stateProcessId ?? initialProcessId;
    const projectionId = stateProjectionId ?? initialProjectionId;
    const profielId = stateProfielId ?? initialProfielId;

    const query = useLogsOverviewQuery({
        variables: {
            filters: {
                projectionId,
                profielId,
                running,
                tag,
                active: actief === undefined ? undefined : actief,
                processId,
                levels: levels.length === 0 ? undefined : levels,
                messageInclude,
                messageExclude,
                pagination: {
                    order: IOrder.Desc,
                    first: 50,
                },
            },
        },
    });
    const pagination = useQueryPagination<ILogsOverviewQuery, ILogsOverviewQueryVariables, ILogsTableFragment>({
        query,
        connectionsMapper: ({processLogs}): ILogsTableFragment[] => processLogs,
        fetchBefore: (variables) => ({
            filters: {
                pagination: {
                    after: variables.first,
                },
            },
        }),
        mergeBefore: (previousResult, fetchMoreResult) => ({
            processLogs: [...fetchMoreResult.processLogs, ...previousResult.processLogs],
        }),
        fetchAfter: (variables) => ({
            filters: {
                pagination: {
                    before: variables.last,
                },
            },
        }),
        mergeAfter: (previousResult, fetchMoreResult) => ({
            processLogs: [...previousResult.processLogs, ...fetchMoreResult.processLogs],
        }),
    });
    const converted = useLogsTableConverter(pagination);

    const renderValues = useMemo(
        (): RenderFilterProps => ({
            actief,
            tag,
            processId,
            projectionId,
            profielId,
            hideFilters: true,
        }),
        [actief, tag, processId, projectionId, profielId]
    );

    return (
        <Stack gap="medium">
            <Stack.Item>{infoContent}</Stack.Item>
            <Stack.Item>
                <Stack horizontal gap="tiny" align="center">
                    <Stack>
                        {!hideActiefFilter && (
                            <FormControlLabel
                                label="Actief"
                                labelPlacement="top"
                                control={
                                    <Checkbox
                                        checked={actief}
                                        value={actief}
                                        onChange={(e) => setActief(e.target.checked)}
                                    />
                                }
                            />
                        )}
                    </Stack>
                    <Stack>
                        {!hideRunningFilter && (
                            <FormControlLabel
                                label="Running"
                                labelPlacement="top"
                                control={
                                    <Checkbox
                                        checked={running}
                                        value={running}
                                        onChange={(e) => setRunning(e.target.checked)}
                                    />
                                }
                            />
                        )}
                    </Stack>
                    <Stack.Item grow>
                        <TextField
                            label="Include text"
                            placeholder="Include message parts"
                            multiline
                            fullWidth
                            value={includeQuery}
                            onChange={(e) => setIncludeQuery(e.target.value)}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <TextField
                            label="Exclude text"
                            placeholder="Exclude message parts"
                            multiline
                            fullWidth
                            value={excludeQuery}
                            onChange={(e) => setExcludeQuery(e.target.value)}
                        />
                    </Stack.Item>
                    {!initialProjectionId && !hideProjectionFilter && (
                        <Stack.Item grow>
                            <TextField
                                label="ProjectionId"
                                placeholder="ProjectionId"
                                fullWidth
                                value={projectionId}
                                onChange={(e) => setProjectionId(e.target.value)}
                            />
                        </Stack.Item>
                    )}
                    {!initialProcessId && (
                        <TextField
                            placeholder="ProcessId"
                            value={processId?.toString() ?? ''}
                            onChange={(val) => {
                                let number: number | undefined = parseInt(val.target.value, 10);
                                if (Number.isNaN(number)) {
                                    number = undefined;
                                }
                                setProcessId(number);
                            }}
                        />
                    )}
                    {!initialProfielId && !hideProfielFilter && (
                        <Stack.Item grow>
                            <TextField
                                label="ProfielId"
                                placeholder="ProfielId"
                                fullWidth
                                value={profielId}
                                onChange={(e) => setProfielId(e.target.value)}
                            />
                        </Stack.Item>
                    )}
                    <Stack.Item grow>
                        <TextField
                            label="Tag"
                            placeholder="Tag"
                            fullWidth
                            value={tag}
                            onChange={(e) => setTag(e.target.value)}
                        />
                    </Stack.Item>
                    <LogLevelSelect onChange={setLevels} value={levels} />
                </Stack>
            </Stack.Item>
            <Stack.Item grow>
                <Box sx={{width: '100%'}}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)}>
                            <Tab label="Logs" {...a11yProps(0)} />
                            <Tab label="Stats" {...a11yProps(1)} />
                            {renderProcess && <Tab label="Process" {...a11yProps(2)} />}
                            {renderProfielen && <Tab label="Profielen" {...a11yProps(3)} />}
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={tab ?? 0} index={0}>
                        <LogsTable pagination={converted} />
                    </CustomTabPanel>
                    <CustomTabPanel value={tab ?? 0} index={1}>
                        <StatisticsTableConnected
                            tag={tag}
                            actief={actief}
                            profielId={profielId}
                            processId={processId}
                            projectionId={projectionId}
                        />
                    </CustomTabPanel>
                    {renderProcess && (
                        <CustomTabPanel index={tab ?? 0} value={2}>
                            {renderProcess(renderValues)}
                        </CustomTabPanel>
                    )}
                    {renderProfielen && (
                        <CustomTabPanel index={tab ?? 0} value={3}>
                            {renderProfielen(renderValues)}
                        </CustomTabPanel>
                    )}
                </Box>
            </Stack.Item>
        </Stack>
    );
};
