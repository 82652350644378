import {IKoppelAanTaakButtonFragment} from '@growthbase/graphql';
import {PopupControlsContext, useMemoryPopupControls} from '@growthbase/design-components';
import {KoppelAanTaakButtonComponentProps} from './RenderKoppelAanTaakButtonType';
import {KoppelButtonConnected} from '../KoppelButton/KoppelButton/KoppelButtonConnected';
import {useKoppelAanTaakButtonChanges} from './Hook/useKoppelAanTaakButtonChanges';
import {useWijzigGekoppeldeItem} from './Hook/useWijzigGekoppeldeItem';

export interface KoppelAanTaakButtonConnectedProps extends KoppelAanTaakButtonComponentProps {
    fragment: IKoppelAanTaakButtonFragment;
    open?: boolean;
    textSize?: 'small' | 'default';
    textColor?: 'default' | 'gray';
}

export const KoppelAanTaakButtonConnected = ({fragment, open, ...props}: KoppelAanTaakButtonConnectedProps) => {
    const helpers = useMemoryPopupControls(open);
    useKoppelAanTaakButtonChanges(fragment.id);
    const {onChange, onRemove} = useWijzigGekoppeldeItem(fragment);

    return (
        <PopupControlsContext.Provider value={helpers}>
            <KoppelButtonConnected
                {...props}
                fragment={fragment}
                relatieverwijzingId={fragment?.relatie?.relatieverwijzing?.id}
                onChange={onChange}
                onRemove={onRemove}
            />
        </PopupControlsContext.Provider>
    );
};
