import {FC, ReactNode} from 'react';
import classNames from 'classnames/bind';
import styles from './PopupHeaderItem.module.scss';

const cx = classNames.bind(styles);

export interface PopupHeaderItem {
    children: ReactNode;
    marginRight?: boolean;
}

export const PopupHeaderItem: FC<PopupHeaderItem> = ({children, marginRight}) =>
    !children ? (
        <></>
    ) : (
        <div className={cx('popupHeaderItem', {'popupHeaderItem--marginRight': marginRight})}>{children}</div>
    );
