import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {AnyAction} from 'redux';

/**
 * Create dispatch hook with single argument.
 */
export const createDispatchHook =
    <TArg>(action: (args: TArg) => AnyAction) =>
    () => {
        const dispatch = useDispatch();
        return useCallback((arg: TArg) => dispatch(action(arg)), [dispatch]);
    };
