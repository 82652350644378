import {Column} from 'react-table';
import {Table} from '@growthbase/design-components';
import {PaginationHelpers} from '@growthbase/spa';
import {FC, PropsWithChildren, ReactNode} from 'react';

export interface StatisticsDTO {
    id: string;
    name: string;

    value: ReactNode;
    running: ReactNode;
}

const columns: ReadonlyArray<Column<StatisticsDTO>> = [
    {
        Header: 'Name',
        accessor: 'name',
        width: 200,
    },
    {
        Header: 'Value',
        accessor: 'value',
        width: 30,
    },
    {
        Header: 'Running',
        accessor: 'running',
        width: 10,
    },
];

export interface StatisticsTableProps extends PropsWithChildren {
    pagination: PaginationHelpers<StatisticsDTO>;
}

export const StatisticsTable: FC<StatisticsTableProps> = ({pagination, children}) => (
    <Table<StatisticsDTO> pagination={pagination} columns={columns} />
);
