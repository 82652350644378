import {createContext} from 'react';

export interface InteractionContext {
    /**
     * The component name.
     *
     * Used for debugging purposes.
     */
    name: string;

    /**
     * The parent context.
     */
    parent: InteractionContext | null;

    /**
     * If it has any children listening to keys/mouse events.
     */
    children: InteractionContext[];
}

export const InteractionStackContext = createContext<InteractionContext | null>(null);
