import {useCallbackRef, useDebouncedState} from '@growthbase/spa';
import {useField} from 'formik';
import {FC} from 'react';
import {useDeelnemersInputZoekenQuery, useResolveConnections} from '@growthbase/graphql';
import {
    AvatarRowFieldItem,
    AvatarSize,
    FormikFieldProps,
    useSetInputActiveFromBool,
    PopupControlsContext,
    useMemoryPopupControls,
} from '@growthbase/design-components';
import {Deelnemers} from '../Deelnemers';

export type DeelnemersValue = AvatarRowFieldItem[];

export interface DeelnemersInputConnectedProps extends FormikFieldProps<DeelnemersValue> {
    stackSize?: number;
    size?: AvatarSize;
}

export const DeelnemersInputConnected: FC<DeelnemersInputConnectedProps> = ({
    disabled,
    readonly,
    size,
    stackSize,
    onBlur,
    onFocus,
    field: {name},
}) => {
    const [
        {value},
        ,
        {setValue},
    ] = useField<DeelnemersValue>(name);
    const controls = useMemoryPopupControls(false);
    const {
        value: searchQuery,
        setValue: setSearchQuery,
        debouncedValue: searchQueryDebounced,
    } = useDebouncedState<string | null>(null, 400);

    const {data, loading} = useDeelnemersInputZoekenQuery({
        skip: !controls.isOpen || readonly,
        variables: {
            fulltextSearch: searchQueryDebounced,
        },
    });
    const deelnemers = useResolveConnections(data?.Gebruikers);
    const onToggle = useCallbackRef((clicked: AvatarRowFieldItem) => {
        const found = value.find((deelnemer) => deelnemer.id === clicked.id);
        if (found) {
            setValue(value.filter((deelnemer) => clicked.id !== deelnemer.id));
            return;
        }
        setValue([
            ...value,
            clicked,
        ]);
    });

    useSetInputActiveFromBool({
        onBlur,
        onFocus,
        isActive: controls.isOpen,
    });
    return (
        <PopupControlsContext.Provider value={controls}>
            <Deelnemers
                size={size}
                stackSize={stackSize}
                loading={loading}
                items={value}
                disabled={disabled}
                readonly={readonly}
                onRemove={onToggle}
                onAdd={onToggle}
                searchQuery={searchQuery}
                onChangeQuery={useCallbackRef((input) => {
                    setSearchQuery(input.query);
                })}
                popupItems={deelnemers}
            />
        </PopupControlsContext.Provider>
    );
};
