import {IStandaardWeergaveMijnDag, useMijnTakenCountWijzigMijnActieveTellerMutation} from '@growthbase/graphql';
import {useState} from 'react';

export const useWijzigMijnActieveTeller = (): ((
    nieuweOverzicht: IStandaardWeergaveMijnDag,
    current: IStandaardWeergaveMijnDag
) => Promise<void>) => {
    const [mutate] = useMijnTakenCountWijzigMijnActieveTellerMutation();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    return (nieuweOverzicht: IStandaardWeergaveMijnDag, current: IStandaardWeergaveMijnDag) => {
        if (isSubmitting || nieuweOverzicht === current) {
            return Promise.resolve();
        }

        setIsSubmitting(true);

        return mutate({
            variables: {
                nieuweOverzicht,
            },
        })
            .finally(() => {
                setIsSubmitting(false);
            })
            .then();
    };
};
