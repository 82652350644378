import {useTypedSearchParams} from '@growthbase/routing';
import {OverviewType} from '@growthbase/takenlijst-lijst';
import {useCallback, useMemo} from 'react';
import * as yup from 'yup';
import {SchemaOf} from 'yup';

interface Filters {
    overzicht: OverviewType;
}

const Schema = (): SchemaOf<Filters> =>
    yup
        .object({
            overzicht: yup
                .number()
                .oneOf(Object.keys(OverviewType) as unknown as OverviewType[])
                .required(),
        })
        .required();

const createEmptyFilterSchema = (): Filters => ({
    overzicht: OverviewType.Overzicht,
});

const useFilterSchema = (): SchemaOf<Filters> => useMemo(() => Schema(), []);

export const useOverzichtSelectState = (): [OverviewType, (overzicht: OverviewType) => void] => {
    const [{overzicht}, setFilters] = useTypedSearchParams<Filters>(
        'tf',
        useFilterSchema(),
        useMemo(createEmptyFilterSchema, [])
    );
    return [
        overzicht,
        useCallback((newOverzicht: OverviewType) => setFilters({overzicht: newOverzicht}), [setFilters]),
    ];
};
