import {BaseButtonProps, Buttons} from '@growthbase/design-components';
import {ILijsttemplateKopieerButtonFragment, useLijsttemplateKopieerButtonConnectedMutation} from '@growthbase/graphql';
import {FC} from 'react';
import {v4} from 'uuid';
import {LijsttemplateRoutes} from '@growthbase/takentemplates-lijsttemplate';
import {useCallbackRef} from '@growthbase/spa';
import {KopieerButton} from './KopieerButton';
import {BordtemplateKopierenFormValues, KopieerForm} from '../KopieerForm/KopieerForm';

export interface KopieerButtonConnectedProps {
    fragment: ILijsttemplateKopieerButtonFragment;
    onCopied?: (id: string) => void;
    Button: FC<BaseButtonProps>;
}

export const KopieerButtonConnected: FC<KopieerButtonConnectedProps> = ({
    Button = Buttons.Copy,
    fragment,
    onCopied,
}) => {
    const [mutation] = useLijsttemplateKopieerButtonConnectedMutation();
    const navigate = LijsttemplateRoutes.bekijken.useNavigateFactory();
    return (
        <KopieerButton<BordtemplateKopierenFormValues>
            Button={Button}
            renderForm={({onSubmit}) => <KopieerForm onSubmit={onSubmit} titel={fragment.naam} />}
            onSubmit={useCallbackRef(async (values): Promise<void> => {
                if (!fragment) {
                    return;
                }
                const id = v4();

                const result = await mutation({
                    variables: {
                        input: {
                            id,
                            naam: values.naam,
                            opties: {
                                copyId: id,
                                originalId: fragment.id,
                                metActiviteiten: values.activiteiten,
                            },
                            lijstOpties: {
                                taakOpties: values.taken
                                    ? {
                                          omschrijving: true,
                                      }
                                    : null,
                            },
                        },
                    },
                });
                if (onCopied) {
                    onCopied(id);
                } else if (result?.data?.KopieerLijsttemplate?.lijsttemplate?.herbruikbaarheid?.herbruikbaar) {
                    navigate({
                        lijsttemplateId: id,
                    });
                }
            })}
        />
    );
};
