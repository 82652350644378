import {MockedResponse} from '@apollo/client/testing/core/mocking/mockLink';
import {
    IMijnTakenOverzichtQuery,
    IMijnTakenOverzichtRowFragment,
    MijnTakenOverzichtDocument,
} from '@growthbase/graphql';
import {AllActionsAvailableStub} from '../TaakEditModal/AvailableActionsStub';

const createTaak = (id: string): IMijnTakenOverzichtRowFragment & {__typename: string} => ({
    __typename: 'Taak',
    id,
    isLocked: false,
    titel: `Taak ${id}`,
    availableActions: {
        ...AllActionsAvailableStub,
    },
    lijsten: [],
    opdracht: null,
    relatie: null,
    gearchiveerd: false,
    deelnemers: [],
    planning: null,
    reacties: null,
    volgers: [],
    voltooid: false,
    type: 'Taak',
    taakIsVoorVandaag: false,
    abonnement: null,
    ticket: null,
    priveVoorMij: false,
    heeftBijlages: {
        aantal: 0,
        heeftBijlages: false,
    },
    lijstInBord: {
        id,
        lijst: null,
    },
    heeftOmschrijving: false,
    TaakEditPopupHeader: 'Taak',
});

const mijnTakenOverzichtMock: MockedResponse<IMijnTakenOverzichtQuery> = {
    request: {
        query: MijnTakenOverzichtDocument,
        variables: {
            first: 50,
            fulltextSearch: null,
        },
    },
    result: {
        // @ts-expect-error - We're having added __typename to the mocked data.
        data: {
            ingelogdeGebruiker: {
                id: '1',
                availableActions: {
                    kanTaakAanmaken: true,
                },
                imageUrl: null,
                naam: 'Test Gebruiker',
            },
            items: {
                __typename: 'TaakConnection',
                pageInfo: {
                    hasNextPage: false,
                    hasPreviousPage: false,
                    endCursor: 'end',
                    startCursor: 'start',
                },
                totalCount: 7,
                edges: [
                    {
                        node: createTaak('1'),
                    },
                    {
                        node: createTaak('2'),
                    },
                    {
                        node: createTaak('3'),
                    },
                    {
                        node: createTaak('4'),
                    },
                    {
                        node: createTaak('5'),
                    },
                    {
                        node: createTaak('6'),
                    },
                    {
                        node: createTaak('7'),
                    },
                ],
            },
        },
    },
};

const mijnTakenOverzichtMockA: MockedResponse<IMijnTakenOverzichtQuery> = {
    request: {
        query: MijnTakenOverzichtDocument,
        variables: {
            first: 50,
            fulltextSearch: 'a',
        },
    },
    result: {
        // @ts-expect-error - We're having added __typename to the mocked data.
        data: {
            ingelogdeGebruiker: {
                id: '1',
                availableActions: {
                    kanTaakAanmaken: true,
                },
                imageUrl: null,
                naam: 'Test Gebruiker',
            },

            items: {
                __typename: 'TaakConnection',
                pageInfo: {
                    hasNextPage: false,
                    hasPreviousPage: false,
                    endCursor: 'end',
                    startCursor: 'start',
                },
                totalCount: 7,
                edges: [
                    {
                        node: createTaak('8'),
                    },
                    {
                        node: createTaak('9'),
                    },
                    {
                        node: createTaak('10'),
                    },
                    {
                        node: createTaak('11'),
                    },
                    {
                        node: createTaak('12'),
                    },
                    {
                        node: createTaak('13'),
                    },
                    {
                        node: createTaak('14'),
                    },
                ],
            },
        },
    },
};

export const mijnTakenOverzichtMocks = [mijnTakenOverzichtMock, mijnTakenOverzichtMockA];
