import {RouteElementProps} from '@growthbase/routing';
import {TaakCreateFormConnected, TaakCreateModal} from '@growthbase/taken';
import {RoutePopupProvider} from '@growthbase/design-components';

export type TaakAanmakenPaginaConnectedProps = RouteElementProps<{
    ticketUuid: string;
}>;

export const TaakAanmakenModalConnected = ({params}: TaakAanmakenPaginaConnectedProps) => {
    if (params === undefined) {
        throw new Error('Logic exception.');
    }

    return (
        <RoutePopupProvider>
            <TaakCreateModal
                CreateForm={TaakCreateFormConnected}
                overrideValues={{
                    ticket: params.ticketUuid,
                }}
            />
        </RoutePopupProvider>
    );
};
