import React, {FC} from 'react';
import {ButtonLink, Buttons} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';
import {RelatieLink} from '../RelatieLink';
import {RelatieButtonContentProps} from '../RelatieButtonContent';

export const RelatieButtonContentInline: FC<RelatieButtonContentProps> = ({
    onBedrijfEdit,
    onBedrijfRemove,
    onPersoonEdit,
    onPersoonRemove,
    onPersoonBedrijfEdit,
    bedrijf,
    persoon,
    readonly,
    disabled,
    singleValue,
    reference,
    textSize,
    iconsSize,
}) => {
    const {t} = useTranslation('spa_form.nl');

    const persoonLink = persoon && (
        <RelatieLink
            inline
            readonly={readonly}
            disabled={disabled}
            onRemove={onPersoonRemove}
            onEdit={onPersoonEdit}
            relatie={persoon}
            size={textSize}
        />
    );

    const bedrijfLink = bedrijf && (
        <RelatieLink
            inline
            readonly={readonly}
            disabled={disabled}
            onRemove={onBedrijfRemove}
            onEdit={onBedrijfEdit}
            relatie={bedrijf}
            size={textSize}
        />
    );

    if (singleValue && bedrijf) {
        return (
            <span data-testid="RelatieButton" ref={reference}>
                {bedrijfLink}
            </span>
        );
    }

    if (singleValue && persoon) {
        return (
            <span data-testid="RelatieButton" ref={reference}>
                {persoonLink}
            </span>
        );
    }

    if (persoon && bedrijf) {
        return (
            <span data-testid="RelatieButton" ref={reference}>
                {persoonLink}, {bedrijfLink}
            </span>
        );
    }

    if (singleValue && readonly) {
        return <></>;
    }

    if (persoon) {
        return (
            <span data-testid="RelatieButton" ref={reference}>
                {persoonLink}
                {!readonly && (
                    <>
                        {', '}
                        <ButtonLink
                            disabled={disabled}
                            readonly={readonly}
                            onClick={onBedrijfEdit}
                            color="placeholder"
                            size={textSize}
                        >
                            {t('relatie.bedrijfToevoegen')}
                        </ButtonLink>
                    </>
                )}
            </span>
        );
    }

    if (bedrijf) {
        return (
            <span data-testid="RelatieButton" ref={reference}>
                {bedrijfLink}
                {!readonly && (
                    <>
                        {', '}
                        <ButtonLink
                            disabled={disabled}
                            readonly={readonly}
                            onClick={onPersoonEdit}
                            color="placeholder"
                            size={textSize}
                        >
                            {t('relatie.persoonToevoegen')}
                        </ButtonLink>
                    </>
                )}
            </span>
        );
    }

    if (readonly) {
        return null;
    }

    // Relatie button
    const label = t('relatie.relatieToevoegen');
    return (
        <Buttons.PersonIconDotted
            onClick={onPersoonBedrijfEdit}
            disabled={disabled}
            readonly={readonly}
            ariaLabel={label}
            tooltip={label}
            size={iconsSize}
            ref={reference}
        />
    );
};
