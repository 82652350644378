import {Descendant} from 'slate';
import {OpgemaakteTekst} from '../Hook/useOpgemaakteTekstSchema';

export const descendantAsText = (value: Descendant): string => {
    if ('text' in value) {
        return value.text;
    }
    if ('children' in value && 'type' in value && value.type === 'p') {
        return (value.children as Descendant[])
            .map((val) => descendantAsText(val))
            .join(' ')
            .trim();
    }
    return '';
};

export const opgemaakteTekstAsText = (value: OpgemaakteTekst): string =>
    value.elements
        .map((element) => descendantAsText(element as Descendant))
        .join(' ')
        .trim();
