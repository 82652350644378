import classNames from 'classnames/bind';
import {forwardRef, Ref, FC} from 'react';
import {Link, To} from 'react-router-dom';
import styles from './IconButton.module.scss';
import {IconProps, IconSize, BaseButtonProps, useHandleClickState, Tooltip, Icons} from '../../../src';

export interface IconButtonProps extends BaseButtonProps {
    active?: boolean;
    to?: To;
    buttonType?: 'button' | 'submit' | 'reset';
    Icon: FC<IconProps>;
    iconSize?: IconSize;
    color?: 'black' | 'gray-light' | 'inherit';
    hidden?: boolean;
    loading?: boolean;
}

const cx = classNames.bind(styles);

export const IconButton = forwardRef(
    (
        {
            testId,
            ariaLabel,
            iconSize,
            Icon,
            to,
            className,
            children,
            active,
            buttonType,
            onMouseDown,
            showOnRowHover,
            tooltip,
            color = 'black',
            tabIndex,
            hidden,
            readonly = false,
            loading,
            ...rest
        }: IconButtonProps,
        ref
    ) => {
        rest.disabled = rest.disabled || readonly;

        const {disabled, onClick} = useHandleClickState(rest);

        const buttonClass = cx('iconButton', `iconButton--color-${color}`, {
            'iconButton--active': active,
            'iconButton--hidden': hidden,
            'iconButton--disabled': disabled,
            'iconButton--readonly': readonly,
        });

        if (loading) {
            return <Icons.LoadingAnimated size={iconSize} />;
        }

        if (to) {
            if (rest?.onClick) {
                throw new Error('to and onClick not supported');
            }
            return (
                <Link
                    data-testid={testId}
                    ref={tooltip ? undefined : (ref as Ref<HTMLAnchorElement>)}
                    aria-label={ariaLabel ?? undefined}
                    className={buttonClass}
                    to={to}
                >
                    <Icon size={iconSize} />
                    {children}
                </Link>
            );
        }

        const Button = (
            <button
                tabIndex={tabIndex}
                data-testid={testId}
                ref={tooltip ? undefined : (ref as Ref<HTMLButtonElement>)}
                // eslint-disable-next-line react/button-has-type
                type={buttonType === undefined ? 'button' : buttonType}
                aria-label={ariaLabel ?? undefined}
                className={buttonClass}
                onMouseDown={disabled ? undefined : onMouseDown}
                onClick={disabled ? undefined : onClick}
            >
                <Icon size={iconSize} />
            </button>
        );

        if (tooltip) {
            return (
                <Tooltip content={tooltip}>
                    <span ref={ref as Ref<HTMLElement>}>{Button}</span>
                </Tooltip>
            );
        }

        return Button;
    }
);

IconButton.displayName = 'IconButton';
