import {FC, ReactNode} from 'react';
import classNames from 'classnames/bind';
import styles from '../Tabs.module.scss';

const cx = classNames.bind(styles);

export interface TabsHeaderProps {
    children: ReactNode;
}

export const TabsHeader: FC<TabsHeaderProps> = ({children}) => (
    <div className={cx('tabs__header', 'tabs')}>{children}</div>
);
