import {useField} from 'formik';
import {FormikFieldProps} from '../FieldWrapper';
import {StyledSelect, StyledSelectOption} from './StyledSelect';

export type StyledSelectInputProps<T> = FormikFieldProps<T> & {
    options: StyledSelectOption<T>[];
    nullLabel?: string;
    onSearch?: (search: string) => Promise<StyledSelectOption<T>[]>;
};

export function StyledSelectInput<T>({
    meta,
    hasError,
    hasControls,
    form,
    field,
    options,
    ...props
}: StyledSelectInputProps<T>) {
    const [, , {setValue}] = useField(field.name);
    return (
        <StyledSelect<T>
            readonly={false}
            disabled={false}
            options={options}
            {...field}
            {...props}
            onChange={setValue}
        />
    );
}
