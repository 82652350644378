import {ExternalLinkType} from '@growthbase/clientbox';
import {opgemaakteTekstFindElementsByType, useRemoveNodesByType} from '@growthbase/design-components';
import {IRelatieType} from '@growthbase/graphql';
import {RelatiesComboboxItem, RelatiesMentionKey, RelatieValue} from '@growthbase/relaties';
import {FormikContextType} from 'formik';
import {useEffect} from 'react';
import {EditFormDTO} from './useEditSchema';

export interface StripRelatiesFromNaamProps {
    formik: FormikContextType<EditFormDTO>;
}

export const useStripRelatieFromNaam = ({formik}: StripRelatiesFromNaamProps) => {
    const removeNodesByType = useRemoveNodesByType();
    const {
        setFieldValue,
        values: {naam, relatie: relatieValue},
    } = formik;

    useEffect(() => {
        const all = opgemaakteTekstFindElementsByType(
            RelatiesMentionKey,
            naam
            // @ts-expect-error We almost sure this is not null
        ).map((found): RelatiesComboboxItem => found.relatie);
        if (all.length === 0) {
            return;
        }
        const relatie: RelatieValue = {
            ...relatieValue,
        };

        for (const item of all) {
            if (item.type !== 'relatie') {
                // eslint-disable-next-line no-continue
                continue;
            }
            if (item.relatieType === IRelatieType.Persoon) {
                relatie.persoon = {
                    id: item.id,
                    label: item.label,
                    linkType: ExternalLinkType.Persoon,
                };
            }
            if (item.relatieType === IRelatieType.Bedrijf) {
                relatie.bedrijf = {
                    id: item.id,
                    label: item.label,
                    linkType: ExternalLinkType.Bedrijf,
                };
            }
        }
        setFieldValue('relatie', relatie);
        removeNodesByType(RelatiesMentionKey);
    }, [removeNodesByType, setFieldValue, naam, relatieValue]);
};
