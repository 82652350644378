import {
    useExternalEntityChanged,
    useExternalEntityFieldsInvalidated,
    useExternalEntityRemoved,
} from '@growthbase/cache';
import {IStandaardWeergaveMijnDag, useMijnTakenCountQuery} from '@growthbase/graphql';
import {usePollInterval} from '@growthbase/websockets';
import {useKeyboardShortcuts} from '../../KeyboardShortcuts/useKeyboardShortcuts';

export const useMijnTakenCount = ({disabled}: {disabled: boolean}) => {
    const {data, refetch} = useMijnTakenCountQuery({
        pollInterval: usePollInterval(),
        skip: disabled,
    });

    /**
     * TODO: find useful place where we have access to the gebruikers ID, for now this is fine.
     */
    useKeyboardShortcuts({gebruikerId: data?.ingelogdeGebruiker?.id ?? null});

    useExternalEntityFieldsInvalidated<{deelnemers: unknown; id: string; actieveMijnTakenTeller: unknown}>((args) => {
        if (disabled) {
            return;
        }
        if (args.fields.includes('deelnemers')) {
            refetch();
        }

        if (args.fields.includes('actieveMijnTakenTeller')) {
            refetch();
        }
    });

    useExternalEntityRemoved<{id: string}>((args) => {
        if (disabled) {
            return;
        }
        if (args.typename === 'Taak') {
            refetch();
        }
    });

    useExternalEntityChanged<{gearchiveerd: unknown; voltooid: unknown; id: string}>((args) => {
        if (disabled) {
            return;
        }
        if (args.typename !== 'Taak') {
            return;
        }

        if (args.fields.includes('gearchiveerd')) {
            refetch();
        }
        if (args.fields.includes('voltooid')) {
            refetch();
        }
    });

    const mijnDagCount = data?.mijnDag?.totalCount ?? 0;
    const mijnTakenCount = data?.mijnTaken?.totalCount ?? 0;

    return {
        mijnDagCount,
        mijnTakenCount,
        actieveTeller: data?.ingelogdeGebruiker?.gebruikerWeergaveInstellingen?.standaardWeergaveMijnDag,
        count:
            data?.ingelogdeGebruiker?.gebruikerWeergaveInstellingen?.standaardWeergaveMijnDag ===
            IStandaardWeergaveMijnDag.Vandaag
                ? mijnDagCount
                : mijnTakenCount,
    };
};
