import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {useSelector} from 'react-redux';

// eslint-disable-next-line no-shadow
export enum SocketStatus {
    connecting = 'connecting',
    connected = 'connected',
    disconnected = 'disconnected',
}

export interface SocketChannel {
    status: SocketStatus;
    error: null | string;
}

export interface SocketState {
    status: SocketStatus;
    records: Record<string, SocketChannel>;
    errors: string[];
}

export interface SocketStoreState {
    sockets: SocketState;
}

const initialSocketState: SocketState = {
    records: {},
    errors: [],
    status: SocketStatus.disconnected,
};

const updateSocketState = (state: SocketState) => {
    const allConnected = Object.values(state.records).every((channel) => channel.status === SocketStatus.connected);
    // @ts-expect-error this is correct
    state.errors = Object.values(state.records)
        .filter((channel) => channel.error)
        .map((channel) => channel.error);
    state.status = allConnected ? SocketStatus.connected : SocketStatus.connecting;
};

export const SocketSlice = createSlice({
    name: 'sockets',
    initialState: initialSocketState,
    reducers: {
        connect: (state, {payload: {channel}}: PayloadAction<{channel: string}>) => {
            state.records[channel] = {
                status: SocketStatus.connecting,
                error: null,
            };
            updateSocketState(state);
        },
        status: (state, {payload: {channel, status}}: PayloadAction<{channel: string; status: SocketStatus}>) => {
            state.records[channel] = {
                status,
                error: null,
            };
            updateSocketState(state);
        },
        error: (state, {payload: {channel, error}}: PayloadAction<{channel: string; error: string}>) => {
            state.records[channel] = {
                status: SocketStatus.disconnected,
                error,
            };
            updateSocketState(state);
        },
    },
});

export const useSocketStatus = () => useSelector(({sockets: {status}}: SocketStoreState) => status);
