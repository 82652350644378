import {InstellingenForm, InstellingenFormProps, ModelnstellingInput} from '@growthbase/instellingen';
import React from 'react';

export function InstellingenKopieerForm<T extends object>(props: InstellingenFormProps<T>) {
    return (
        <InstellingenForm
            renderRootInputComponent={(p) => <ModelnstellingInput kopierenButtonText {...p} />}
            {...props}
        />
    );
}
