import {
    BaseButtonProps,
    Buttons,
    PopupAnchorProps,
    PopupControlsContext,
    useMemoryPopupControls,
    ViewConfig,
} from '@growthbase/design-components';
import {FC, PropsWithChildren, useCallback} from 'react';
import {ILijstVerplaatsButtonFragment} from '@growthbase/graphql';
import {VerplaatsenFormConnected} from '../VerplaatsenForm/VerplaatsenFormConnected';

export interface VerplaatsButtonProps extends ViewConfig, PropsWithChildren {
    fragment: ILijstVerplaatsButtonFragment | null;
    Button?: FC<BaseButtonProps>;
}

export const VerplaatsButton: FC<VerplaatsButtonProps> = ({
    fragment,
    Button = Buttons.ArrowUpRightIconDotted,
    disabled,
    readonly,
    children,
}) => {
    const provider = useMemoryPopupControls();
    const renderAnchor = useCallback(
        ({reference, controls: {open}}: PopupAnchorProps<Element>) => (
            <Button ref={reference} onClick={open} disabled={!fragment || disabled}>
                {children}
            </Button>
        ),
        [Button, fragment, disabled, children]
    );
    if (!fragment || !fragment.availableActions.kanLijstVerplaatsen) {
        return null;
    }
    if (!provider.isOpen) {
        return (
            <Button onClick={provider.open} disabled={!fragment || disabled}>
                {children}
            </Button>
        );
    }
    return (
        <PopupControlsContext.Provider value={provider}>
            <VerplaatsenFormConnected
                renderAnchor={renderAnchor}
                readonly={readonly}
                disabled={disabled}
                fragment={fragment}
            />
        </PopupControlsContext.Provider>
    );
};
