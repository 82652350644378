import {noop} from 'lodash';
import {useEffect, useState} from 'react';
import {ButtonHandler} from '@growthbase/design-components';
import {
    useActiveItem,
    useOnMouseHover,
    ActiveItemData,
    SetItemActiveApi,
    useSetItemActive,
    ActiveItemType,
    ActiveItemSource,
} from '../../Active';
import {useKeys} from '../../Keys';

export interface TraversableItemOptions<TElement extends HTMLElement = HTMLElement> extends SetItemActiveApi {
    element: TElement | null;
    data: ActiveItemData;
    disabled?: boolean;
    onEnter?: ButtonHandler;
}

export function useTraversableItem<T extends HTMLElement = HTMLElement>({
    element,
    disabled,
    data,
    onEnter,
    ...api
}: TraversableItemOptions<T>) {
    const {activate, source, type, deactivate} = api;
    useOnMouseHover({data, ...api, disabled, element});
    const isActive = useActiveItem({
        data,
        source,
        type,
    });
    useKeys<HTMLElement>(
        'useTraversableItem: select item',
        'enter',
        (keyboardEvent, hotsEvent) => {
            if (onEnter) {
                onEnter();
                keyboardEvent.preventDefault();
            }
        },
        {
            enableOnInput: true,
            onKeyDown: true,
            onKeyUp: false,
            enabled: !!(onEnter && isActive),
        }
    );
    useEffect(() => {
        if (element) {
            element.tabIndex = disabled ? -1 : 0;
        }
        if (disabled || !element) {
            return noop;
        }
        const focus = () => {
            if (disabled) {
                return;
            }
            activate(data);
        };
        element.addEventListener('focus', focus);
        element.addEventListener('blur', deactivate);
        return () => {
            element.removeEventListener('focus', focus);
            element.removeEventListener('blur', deactivate);
        };
    }, [activate, data, deactivate, disabled, element]);
    return {
        isActive,
    };
}

export interface TraversableItemWithRef {
    data: ActiveItemData;
    disabled?: boolean;
    type: ActiveItemType;
    source: ActiveItemSource;
    onEnter?: ButtonHandler;
}

export function useTraversableItemWithRef<T extends HTMLElement = HTMLElement>({
    type,
    source,
    data,
    disabled,
    onEnter,
}: TraversableItemWithRef) {
    const [element, ref] = useState<T | null>(null);
    const api = useSetItemActive({
        type,
        source,
    });
    const {isActive} = useTraversableItem<T>({
        ...api,
        element,
        data,
        disabled,
        onEnter,
    });
    return {ref, isActive, element, ...api};
}
