import React, {useEffect, useRef, FC} from 'react';

// Declare external JS functions
declare const TicketReaction: {
    initReactionField: (element: HTMLElement | null) => void;
};

export const ReactieTextArea: FC<{formHTML: string}> = ({formHTML}) => {
    const element = useRef(null);
    // Attach legacy js
    useEffect(() => {
        TicketReaction.initReactionField(element.current);
    }, [element]);

    /* eslint-disable-next-line react/no-danger */
    return <div dangerouslySetInnerHTML={{__html: formHTML}} ref={element} />;
};
