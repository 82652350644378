import React, {useCallback} from 'react';
import {AddBreadcrumb, Loader, Stack} from '@growthbase/design-components';
import {useProjectionsDetailsQuery} from '@growthbase/graphql';
import {LogsTableConnected, RenderFilterProps} from '../LogsTable';
import {CircularProgressWithLabel} from '../ProjectionsPage/Components/CircularProgressWithLabel';
import {ReplayButtonConnected} from '../ReplayButton/ReplayButtonConnected';
import {ProcessesTable} from '../ProcessesPage';
import {ProfielenTable} from '../ProfielenPage';

export interface ProjectionPageProps {
    id: string;
}

export const ProjectionDetails: React.FC<ProjectionPageProps> = ({id}) => {
    const query = useProjectionsDetailsQuery({
        variables: {
            id,
        },
        pollInterval: 1000,
    });
    const {data} = query;
    const dto = data?.projection;

    const renderProcess = useCallback(
        (filters: RenderFilterProps) => <ProcessesTable projectionId={id} {...filters} />,
        [id]
    );

    const renderProfielen = useCallback((filters: RenderFilterProps) => <ProfielenTable projectionId={id} />, [id]);

    if (!dto) {
        return <Loader center />;
    }

    const progress = dto.totalProjections > 0 ? (dto.activeProjections / dto.totalProjections) * 100 : 0;

    return (
        <Stack gap="medium">
            <AddBreadcrumb>{dto.id}</AddBreadcrumb>
            <h1>Projection {dto.id} </h1>
            <LogsTableConnected
                renderProcess={renderProcess}
                renderProfielen={renderProfielen}
                infoContent={
                    <Stack.Item grow>
                        <Stack>
                            <p>
                                <ReplayButtonConnected projectionId={id}>Replay projection</ReplayButtonConnected>
                            </p>
                            <p>Name: {dto.name}</p>
                            <p>
                                Actief: {dto.activeProjections}/{dto.totalProjections}{' '}
                                {dto.activeProjections !== 0 && <CircularProgressWithLabel value={progress} />}
                            </p>
                        </Stack>
                    </Stack.Item>
                }
                projectionId={id}
            />
        </Stack>
    );
};
