import {ITaakReactieCreateFormGebruikerFragment, useTaakReactieCreateFormCreateMutation} from '@growthbase/graphql';
import {useCallback} from 'react';
import {v4} from 'uuid';
import {TaakReactieCreateForm} from './TaakReactieCreateForm';

export interface TaakReactieCreateFormConnectedProps {
    taakId: string;
    fragment: ITaakReactieCreateFormGebruikerFragment;
}

export const TaakReactieCreateFormConnected = ({taakId, fragment}: TaakReactieCreateFormConnectedProps) => {
    const [mutation] = useTaakReactieCreateFormCreateMutation();

    return (
        <TaakReactieCreateForm
            gebruiker={fragment}
            onSubmit={useCallback(
                ({tekst}) =>
                    mutation({
                        variables: {
                            input: {
                                taakId,
                                id: v4(),
                                inhoud: {
                                    opgemaakteTekst: tekst,
                                },
                            },
                        },
                    }).then(() => undefined),
                [mutation, taakId]
            )}
        />
    );
};
