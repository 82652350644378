import {ReactNode, FC, PropsWithChildren} from 'react';
import {BordRoutes} from '@growthbase/takenlijst/bord';
import {Heading4, Stack, StyledLink} from '@growthbase/design-components';

export interface CardHeaderProps extends PropsWithChildren {
    id: string;
    actionMenu?: ReactNode;
}

export const CardHeader: FC<CardHeaderProps> = ({id, children, actionMenu}) => (
    <Stack justify align="start" horizontal>
        <Stack.Item shrink>
            <StyledLink to={BordRoutes.view.useUrl({bordId: id})}>
                <Heading4>{children}</Heading4>
            </StyledLink>
        </Stack.Item>
        <Stack.Item>{actionMenu}</Stack.Item>
    </Stack>
);
