import {TFunction} from 'i18next';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {SchemaOf} from 'yup';
import {OpgemaakteTekst, opgemaakteTekstAsText, OpgemaakteTekstSchema} from '@growthbase/design-components';
import {RelatieSchema} from '@growthbase/relaties';

export type EditFormDTO = {
    naam: OpgemaakteTekst;
};

export const EditFormSchema = (t: TFunction): SchemaOf<EditFormDTO> =>
    yup
        .object({
            relatie: RelatieSchema(t),
            naam: OpgemaakteTekstSchema(t)
                .test(function TestMinLength() {
                    const {path, createError, parent: value} = this;
                    const naam = opgemaakteTekstAsText(value.naam).trim();
                    if (naam.length > 0) {
                        return true;
                    }
                    return createError({path, message: t('naamLength', {ns: 'Bordtemplate.nl'}) ?? ''});
                })
                .label(t('naam', {ns: 'Bordtemplate.nl'})),
        })
        .label(t('formulier', {ns: 'Bordtemplate.nl'}))
        .required();

export const createInitialFormValues = (): EditFormDTO => ({
    naam: {
        elements: [
            {
                type: 'p',
                children: [
                    {
                        text: '',
                    },
                ],
            },
        ],
    },
});

export const useEditSchema = (): SchemaOf<EditFormDTO> => {
    const {t} = useTranslation();
    return useMemo(() => EditFormSchema(t), [t]);
};
