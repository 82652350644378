import {
    CollisionDetection,
    DndContext,
    KeyboardSensor,
    MouseSensor,
    pointerWithin,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {sortableKeyboardCoordinates} from '@dnd-kit/sortable';
import {useLogger} from '@growthbase/spa';
import {FC, memo, PropsWithChildren} from 'react';
import {memoize} from 'lodash';
import {
    defaultContext,
    DNDContextProvider,
    useOnDragEndEvent,
    useOnDragOverEvent,
    useOnDragCancelEvent,
    useOnDragStartEvent,
    useActiveState,
} from '../Hook';
import {DNDDraggedOverlay} from './DNDDraggedOverlay';
import {DNDActiveState, DNDContainerId} from '../Value';

export const DoCache: FC<PropsWithChildren> = memo(({children}) => <>{children}</>);
DoCache.displayName = 'DNDContextCache';

const GlobalAcceptCollisionDetection =
    (state: DNDActiveState | null): CollisionDetection =>
    (data) => {
        const found = pointerWithin(data);
        if (!state) {
            return found;
        }
        const {type, draggedItem, draggedOverItem, sourceContainer} = state;
        const accepts = memoize((id: DNDContainerId | undefined) => {
            if (!id) {
                return false;
            }
            const container = defaultContext.containers[id];
            if (!container) {
                return false;
            }

            if (container.userDataType !== type) {
                return false;
            }

            if (!container.accept) {
                return true;
            }

            return container.accept({
                draggedItem,
                draggedOverItem,
                draggedOverContainer: container.userData,
                type,
                sourceContainer,
            });
        });

        return found.filter((collision) => {
            const containerId = collision?.data?.droppableContainer?.data?.current?.sortable?.containerId?.toString();
            return accepts(containerId) || accepts(collision?.data?.droppableContainer?.id);
        });
    };

export function DNDContext({children}: PropsWithChildren) {
    const sensors = useSensors(
        useSensor(MouseSensor, {
            // Require the mouse to move by 10 pixels before activating
            activationConstraint: {
                distance: 10,
            },
        }),
        useSensor(TouchSensor, {
            // Press delay of 250ms, with tolerance of 5px of movement
            activationConstraint: {
                delay: 250,
                tolerance: 5,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    const logger = useLogger('DNDContext');
    const activeState = useActiveState();
    return (
        <DNDContextProvider value={defaultContext}>
            <DndContext
                sensors={sensors}
                onDragStart={useOnDragStartEvent()}
                onDragEnd={useOnDragEndEvent({
                    containers: defaultContext.containers,
                    logger,
                    activeState,
                })}
                onDragCancel={useOnDragCancelEvent()}
                onDragOver={useOnDragOverEvent()}
                collisionDetection={GlobalAcceptCollisionDetection(activeState)}
            >
                <DoCache>{children}</DoCache>
                <DNDDraggedOverlay />
            </DndContext>
        </DNDContextProvider>
    );
}
