import {memo, ReactNode, useEffect, useMemo, useState} from 'react';
import {GlobalModifierKeysContext} from './GlobalModifierKeysContext';

export interface GlobalModifierKeysContextProviderProps {
    children: ReactNode;
}

export const GlobalModifierKeysContextProvider = memo(({children}: GlobalModifierKeysContextProviderProps) => {
    const [alt, setAlt] = useState<boolean>(false);
    const [ctrl, setCtrl] = useState<boolean>(false);
    const [meta, setMeta] = useState<boolean>(false);
    const [shift, setShift] = useState<boolean>(false);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            setAlt(event.altKey);
            setCtrl(event.ctrlKey);
            setMeta(event.metaKey);
            setShift(event.shiftKey);
        };
        const handleKeyUp = () => {
            setAlt(false);
            setCtrl(false);
            setMeta(false);
            setShift(false);
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    const value = useMemo(() => ({alt, ctrl, meta, shift}), [alt, ctrl, meta, shift]);

    return <GlobalModifierKeysContext.Provider value={value}>{children}</GlobalModifierKeysContext.Provider>;
});

GlobalModifierKeysContextProvider.displayName = 'GlobalModifierKeysContextProvider';
