import {
    IBordOnderdeelVanFieldFragment,
    useBordOnderdeelVanFieldMutation,
    useBordOnderdeelVanFieldOntkoppelMutation,
} from '@growthbase/graphql';
import {PopupControlsContext, useMemoryPopupControls, ViewConfig} from '@growthbase/design-components';
import {KoppelButton, useQueryKoppelGroepen} from '@growthbase/taken';
import {useNormalizeBordOnderdeelVanFragment} from './Hook/useNormalizeBordOnderdeelVanFragment';

export interface OnderdeelVanFieldConnectedProps extends ViewConfig {
    fragment: IBordOnderdeelVanFieldFragment | null;
    open?: boolean;
    textSize?: 'small' | 'default';
    textColor?: 'default' | 'gray';
}

export const OnderdeelVanFieldConnected = ({fragment, open, readonly, ...props}: OnderdeelVanFieldConnectedProps) => {
    const helpers = useMemoryPopupControls(open);

    const item = useNormalizeBordOnderdeelVanFragment(fragment);
    const kanBewerken = fragment?.availableActions.kanBordKoppelen ?? false;

    const [mutateKoppel] = useBordOnderdeelVanFieldMutation({});
    const [mutateOntkoppel] = useBordOnderdeelVanFieldOntkoppelMutation({});

    const {searchQuery, setSearchQuery, loading, relatiegroepen} = useQueryKoppelGroepen({
        skip: !helpers.isOpen || !kanBewerken,
    });

    return (
        <PopupControlsContext.Provider value={helpers}>
            <KoppelButton
                {...props}
                onChange={async (changeItem) =>
                    mutateKoppel({
                        variables: {
                            input: {
                                id: fragment?.id ?? '',
                                item: {
                                    id: changeItem.id,
                                    type: changeItem.type,
                                },
                            },
                        },
                    }).then()
                }
                onRemove={async () =>
                    mutateOntkoppel({
                        variables: {
                            input: {
                                id: fragment?.id ?? '',
                            },
                        },
                    }).then()
                }
                relatiegroepen={relatiegroepen}
                readonly={readonly || !kanBewerken}
                loading={loading}
                query={searchQuery.query ?? null}
                item={item}
                onChangeQuery={setSearchQuery}
            />
        </PopupControlsContext.Provider>
    );
};
