import {useLogger} from '@growthbase/spa';
import {FC, memo, PropsWithChildren, useEffect, useMemo, useState} from 'react';
import {createPortal} from 'react-dom';
import classNames from 'classnames';
import {interval, noop} from 'rxjs';
import {ScrollContext, useUniqueClassname} from '../../PaginationInfiniteScroll';

export interface PagePortalProps extends PropsWithChildren {
    classNameTarget: string;
    className?: string;

    keepSearching?: boolean;
}

export const PagePortal: FC<PagePortalProps> = memo(({children, keepSearching = false, className, classNameTarget}) => {
    const logger = useLogger('PagePortal');
    const memoDestination = useMemo(() => {
        const portalDestination = document.querySelector(classNameTarget);
        if (!portalDestination) {
            logger.warn(`No element found for selector ${classNameTarget}.`);
            return null;
        }
        if (!portalDestination.classList.contains('react-portal')) {
            portalDestination.textContent = '';
            portalDestination.classList.add('react-portal');
        }
        logger.warn(`Portal target found ${classNameTarget}.`, portalDestination);
        return portalDestination;
    }, [classNameTarget, logger]);

    const [destination, setSearcherDestination] = useState(memoDestination);

    useEffect(() => {
        if (!keepSearching || destination) {
            return noop;
        }
        const subscription = interval(500).subscribe(() => {
            const portalDestination = document.querySelector(classNameTarget);
            if (!portalDestination) {
                return;
            }
            if (!portalDestination.classList.contains('react-portal')) {
                portalDestination.textContent = '';
                portalDestination.classList.add('react-portal');
            }
            logger.warn(`Portal target found ${classNameTarget}.`, portalDestination);
            setSearcherDestination(portalDestination);
        });
        return () => subscription.unsubscribe();
    }, [keepSearching, destination, setSearcherDestination, classNameTarget, logger]);

    const uniqueClassName = useUniqueClassname();
    const sideBar = (
        <div
            className={classNames(className, uniqueClassName)}
            style={{
                height: '100%',
            }}
        >
            <ScrollContext.Provider value={uniqueClassName}>{children}</ScrollContext.Provider>
        </div>
    );
    if (destination) {
        return createPortal(sideBar, destination);
    }
    return null;
});

PagePortal.displayName = 'PagePortal';
