import {useBackgroundRoute, useCurrentHref} from '@growthbase/routing';
import {useMemo, useRef} from 'react';
import {To} from 'react-router-dom';
import {useIsSSR} from '@growthbase/spa';
import {AnchorOrLinkValue} from '../../../AnchorOrLink';

const toAsHref = (to?: To | null) => {
    if (!to) {
        return null;
    }
    if (typeof to === 'string') {
        return to;
    }
    if (typeof to === 'object') {
        return to.pathname;
    }
    return null;
};

const isActive = (currentHref: string, {to, href}: AnchorOrLinkValue) => {
    const toPath = toAsHref(to);
    const path = toPath ?? href;
    if (!path) {
        return false;
    }

    return currentHref.startsWith(path);
};

export interface IsActiveRouteResult {
    isActive: boolean;
    isSelfActive: boolean;
    isNestedActive: boolean;
}

export const useIsActiveRoute = ({
    to,
    href,
    activeFromServer,
    nestedRoutes,
}: AnchorOrLinkValue & {activeFromServer?: boolean; nestedRoutes?: AnchorOrLinkValue[]}): IsActiveRouteResult => {
    const isSSR = useIsSSR();
    const currentHrefDom = useCurrentHref();
    const [backgroundRoute] = useBackgroundRoute();
    const currentHref = backgroundRoute ?? currentHrefDom;
    const initalRouteRef = useRef(currentHref);

    return useMemo(() => {
        if (isSSR) {
            return {
                isSelfActive: false,
                isNestedActive: false,
                isActive: false,
            };
        }
        if (activeFromServer && initalRouteRef.current === currentHref) {
            return {
                isSelfActive: true,
                isNestedActive: false,
                isActive: true,
            };
        }
        if (isActive(currentHref, {to, href})) {
            return {
                isSelfActive: true,
                isNestedActive: false,
                isActive: true,
            };
        }
        if (!nestedRoutes) {
            return {
                isSelfActive: false,
                isNestedActive: false,
                isActive: false,
            };
        }
        const nested = nestedRoutes.some((nestedRoute) => isActive(currentHref, nestedRoute));
        return {
            isSelfActive: false,
            isNestedActive: nested,
            isActive: nested,
        };
    }, [
        activeFromServer,
        currentHref,
        href,
        isSSR,
        nestedRoutes,
        to,
    ]);
};
