import {
    ITaakReactiesQuery,
    ITaakReactiesReactieFragment,
    ITaakReactiesReactieTaakFragment,
    useTaakReactiesQuery,
} from '@growthbase/graphql';
import {idFromIri, usePaginationSearchParams} from '@growthbase/routing';
import {usePollInterval} from '@growthbase/websockets';
import {TaakReactieConnected, AangemaaktDoorReactie} from '../TaakReactie';
import {TaakReactieCreateFormConnected} from '../TaakReactieCreateForm/TaakReactieCreateFormConnected';
import {useTaakReactiesEntityChanges} from './hook/useTaakReactiesEntityChanges';
import {TaakReacties} from './TaakReacties';
import {TaakEditFragment} from '../../../TaakEditModal';

export interface TaakReactiesConnectedProps {
    fragment: ITaakReactiesReactieTaakFragment;
}

export function isITaakReactiesReactieTaakFragment(
    fragment?: ITaakReactiesReactieTaakFragment | TaakEditFragment | null
): fragment is ITaakReactiesReactieTaakFragment {
    if (!fragment) {
        return false;
    }
    return (fragment as ITaakReactiesReactieTaakFragment).IsReactieType !== undefined;
}

export const TaakReactiesConnected = ({
    fragment: {
        id: taakId,
        availableActions: {kanReactiePlaatsen, kanReactiesBekijken},
        aangemaaktDoor,
    },
}: TaakReactiesConnectedProps) => {
    const id = idFromIri(taakId);
    const {pagination: paginationVariables, usePagination} = usePaginationSearchParams<
        ITaakReactiesReactieFragment,
        ITaakReactiesQuery
    >('tr');
    const result = useTaakReactiesQuery({
        pollInterval: usePollInterval(),
        variables: {
            ...paginationVariables,
            id,
        },
    });

    const pagination = usePagination(result);
    useTaakReactiesEntityChanges({result, taakId: id});

    if (!kanReactiesBekijken) {
        return null;
    }

    return (
        <TaakReacties
            aangemaaktDoor={
                aangemaaktDoor && (
                    <AangemaaktDoorReactie naam={aangemaaktDoor.naam} image={aangemaaktDoor.profileImageThumbnailUri} />
                )
            }
            CreateForm={TaakReactieCreateFormConnected}
            taakId={id}
            fragment={result.data}
            pagination={pagination}
            Reactie={TaakReactieConnected}
            readonly={!kanReactiePlaatsen}
        />
    );
};
