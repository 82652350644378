import React, {FC} from 'react';

export const TwoStateString: FC<{
    value: string | undefined;
    setValue(newValue: string | undefined): void;
    children: string;
}> = ({value, setValue, children}) => (
    <input
        placeholder={children}
        type="text"
        value={value ?? ''}
        onChange={({target}) => {
            if (target.value === '') {
                setValue(undefined);
            } else {
                setValue(target.value);
            }
        }}
    />
);
