import {
    ELEMENT_BLOCKQUOTE,
    ELEMENT_PARAGRAPH,
    ELEMENT_TODO_LI,
    isBlockAboveEmpty,
    isSelectionAtBlockStart,
    ResetNodePlugin,
} from '@udecode/plate';
import {MyPlatePlugin} from './plateTypes';

const resetBlockTypesCommonRule = {
    types: [ELEMENT_BLOCKQUOTE, ELEMENT_TODO_LI],
    defaultType: ELEMENT_PARAGRAPH,
};

export const resetBlockTypePlugin: Partial<MyPlatePlugin<ResetNodePlugin>> = {
    options: {
        rules: [
            {
                ...resetBlockTypesCommonRule,
                hotkey: 'Enter',
                predicate: isBlockAboveEmpty,
            },
            {
                ...resetBlockTypesCommonRule,
                hotkey: 'Backspace',
                predicate: isSelectionAtBlockStart,
            },
        ],
    },
};
