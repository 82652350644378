import {TFunction} from 'i18next';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {InferType, SchemaOf} from 'yup';
import {TitelSchema} from '@growthbase/design-components';
import {useMemo} from 'react';

export const EditFormSchema = (t: TFunction) =>
    yup
        .object({
            titel: TitelSchema(t).label(t('lijst.tabel.titel')),
        })
        .label(t('lijst.lijst', {ns: 'spa_form.nl'}))
        .required();

export type EditFormSchemaDto = InferType<ReturnType<typeof EditFormSchema>>;

export const useLijstEditFormSchema = (): SchemaOf<EditFormSchemaDto> => {
    const {t} = useTranslation();
    return useMemo(() => EditFormSchema(t), [t]);
};
