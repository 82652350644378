import * as yup from 'yup';
import {Route, RouteSchema} from './Route';

export interface MenuChildLink {
    type: 'link';
    label: string;
    path: string;
    active: boolean;
    route: Route | null;
}

export interface MenuCustom {
    type: 'custom';
    label: string;
    href: string;
    class: string;
    active: boolean;
    'data-modal-size'?: string;
    'data-modal-title'?: string;
    'data-text'?: string;
    'data-button-text'?: string;
    'data-button-class'?: string;
    'data-action'?: string;
}

export interface MenuNotYetSupported {
    type: 'notyetsupported';
    item: unknown;
}

export type MenuChild = MenuChildLink | MenuCustom | MenuNotYetSupported;

export const MenuChildLinkSchema = yup
    .object({
        type: yup.mixed<'link'>().oneOf(['link']).required(),
        label: yup.string().required(),
        active: yup.boolean().required(),
        path: yup.string().required(),
        route: RouteSchema.required(),
    })
    .label('MenuChildLinkSchema')
    .required() as yup.SchemaOf<MenuChildLink>;

export const MenuCustomSchema = yup
    .object({
        type: yup.mixed<'custom'>().oneOf(['custom']).required(),
        label: yup.string().required(),
        active: yup.boolean().required(),
        href: yup.string().required(),
        class: yup.string().required(),
        'data-modal-size': yup.string(),
        'data-modal-title': yup.string(),
        'data-text': yup.string(),
        'data-button-text': yup.string(),
        'data-button-class': yup.string(),
        'data-action': yup.string(),
    })
    .label('MenuHTMLSchema')
    .required() as yup.SchemaOf<MenuCustom>;

export const MenuNotYetSupportedSchema = yup
    .object({
        type: yup.mixed<'notyetsupported'>().oneOf(['notyetsupported']).required(),
        item: yup.mixed(),
    })
    .label('MenuNotYetSupportedSchema')
    .required() as yup.SchemaOf<MenuNotYetSupported>;

const schemas = [MenuChildLinkSchema, MenuCustomSchema, MenuNotYetSupportedSchema];

export const MenuChildSchema = yup
    .mixed<MenuChild>()
    .test((item) => !!schemas.find((schema) => schema.isValidSync(item))) as yup.SchemaOf<MenuChild>;
