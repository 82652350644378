import {useMemo} from 'react';
import {IMedewerkerFragment, IRoosterFragment} from '@growthbase/planning';
import {BackgroundEventType} from '../../Views';

export type ScheduleNormalizer = (rooster: IRoosterFragment, medewerker: IMedewerkerFragment) => BackgroundEventType[];

export const useNormalizeSchedules = (scheduleNormalizer: ScheduleNormalizer) =>
    useMemo(
        () =>
            (medewerkers: IMedewerkerFragment[]): BackgroundEventType[] => {
                let events: BackgroundEventType[] = [];

                medewerkers.forEach((medewerker) => {
                    medewerker.planning.rooster.forEach((rooster) => {
                        if (rooster !== null) {
                            events = events.concat(scheduleNormalizer(rooster, medewerker));
                        }
                    });
                });

                return events;
            },
        [scheduleNormalizer]
    );
