import React, {FC} from 'react';
import {IBordtemplateCardHeaderFragment} from '@growthbase/graphql';
import {ViewConfig} from '@growthbase/design-components';
import {CardHeader} from './CardHeader';
import {ActionMenuConnected} from '../../../../../../../ActionMenu';

export interface CardHeaderConnectedProps extends ViewConfig {
    fragment: IBordtemplateCardHeaderFragment;
}

export const CardHeaderConnected: FC<CardHeaderConnectedProps> = ({fragment, disabled, readonly}) => (
    <CardHeader
        id={fragment.id}
        actionMenu={
            readonly ? null : <ActionMenuConnected readonly={readonly} disabled={disabled} fragment={fragment} />
        }
    >
        {fragment.naam}
    </CardHeader>
);
