import {useContext} from 'react';
import {useIsBackgroundRoute} from '@growthbase/routing';
import {useCallbackRef} from '@growthbase/spa';
import {InteractionStackContext} from '../Component/InteractionStackContext';

export const useInteractionAllowed = () => {
    const currentStack = useContext(InteractionStackContext);
    const isBackgroundRoute = useIsBackgroundRoute();
    return useCallbackRef(() => {
        const children = currentStack?.children ?? [];
        return !isBackgroundRoute && children.length === 0;
    });
};
