import {useDragPaginationHelpers} from '@growthbase/keys';
import {useMemoryPagination} from '@growthbase/spa';
import {Takenswimlane, TakenswimlaneProps} from '@growthbase/taken';
import {range} from 'lodash';
import {FC, useMemo} from 'react';
import {Optional} from 'utility-types';
import {renderKaartStub} from './Components/Kaart';
import {Titel} from './Components/Kaart/Components/Titel';

export const TakenswimlaneStub: FC<Optional<TakenswimlaneProps> & {titel: string}> = (props) => {
    const newId = useMemo(() => Math.random().toString(), []);

    const pagination = useMemoryPagination(
        (total, from = 0) =>
            range(from, from + total).map((i) => ({
                id: `${newId}${i}`,
                name: `Card ${i}`,
                date: {
                    from: {
                        date: new Date().toISOString(),
                        timeUsed: false,
                    },
                    to: null,
                },
            })),
        10
    );
    const {titel} = props;

    return (
        <Takenswimlane
            header={<Takenswimlane.Header titel={titel} />}
            {...props}
            pagination={pagination}
            {...useDragPaginationHelpers(pagination)}
            userData={useMemo(() => ({id: newId}), [newId])}
        >
            {({item, anchor, dragging, hoover}) =>
                renderKaartStub({
                    anchor,
                    dragging,
                    hoover,
                    titel: <Titel disabled={false} readonly={false} id={item.id} titel={item.name} />,
                })
            }
        </Takenswimlane>
    );
};

export const renderTakenswimlaneStub = (props: Optional<TakenswimlaneProps & {titel: string}>) => (
    <TakenswimlaneStub titel="Test lijst" {...props} />
);
