import {useLijsttemplateTaakEditFormConnectedQuery} from '@growthbase/graphql';
import {FC} from 'react';
import {LoaderModal, RoutePopupProvider, useGebruikerHeeftToegang} from '@growthbase/design-components';
import {EditForm} from './EditForm';
import {OmschrijvingFieldConnected, TitelFieldConnected} from './Components';
import {MenuConnected} from '../Menu';

export interface EditFormConnectedProps {
    taaktemplateId: string;
    readonly?: boolean;
}

export const EditFormConnected: FC<EditFormConnectedProps> = ({taaktemplateId, readonly}) => {
    const {data, loading} = useLijsttemplateTaakEditFormConnectedQuery({
        variables: {
            id: taaktemplateId,
        },
    });

    const toegang = useGebruikerHeeftToegang({
        enabled: !loading,
        toegang: !!data?.node,
    });

    if (!toegang) {
        return null;
    }

    const fragment = data?.node;

    if (!fragment) {
        return <LoaderModal />;
    }

    return (
        <RoutePopupProvider>
            <EditForm
                taaktemplateId={taaktemplateId}
                menu={<MenuConnected fragment={fragment} />}
                readonly={readonly}
                renderTitelField={(props) => <TitelFieldConnected {...props} fragment={fragment} />}
                renderOmschrijvingField={(props) => <OmschrijvingFieldConnected {...props} fragment={fragment} />}
            />
        </RoutePopupProvider>
    );
};
