import React from 'react';
import classNames from 'classnames/bind';
import styles from './SidenavSeperator.module.scss';
import {useSidenavContext} from '../Sidenav/useSidenavContext';

const cx = classNames.bind(styles);

export const SidenavSeperator = () => {
    const {isExpanded} = useSidenavContext();
    const classes = cx('sidenavSeperator', {
        'sidenavSeperator--expanded': isExpanded,
    });
    return <div className={classes} />;
};
