import React, {ReactNode} from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import {useIsSSR} from '@growthbase/spa';
import styles from './Tooltip.module.scss';

export interface TooltipProps {
    children: ReactNode;
    content: ReactNode;
    disabled?: boolean;
    open?: boolean;
    onShow?: () => void;
}

export const Tooltip = ({children, onShow, open, ...props}: TooltipProps) => {
    const isServer = useIsSSR();

    if (isServer) {
        return null;
    }

    return (
        <Tippy
            touch={false}
            className={styles.tooltip}
            delay={200}
            interactive
            zIndex={10000005}
            onTrigger={onShow}
            visible={open}
            {...props}
        >
            <span>{children}</span>
        </Tippy>
    );
};

Tooltip.displayName = 'Tooltip';
