import {AvatarSize, PopupControlsContext, useMemoryPopupControls} from '@growthbase/design-components';
import {
    IDeelnemersVanTaakFragment,
    useResolveConnections,
    useTaakDeelnemersZoekenQuery,
    useVerwijderTaakDeelnemerMutation,
    useVoegTaakDeelnemerToeMutation,
} from '@growthbase/graphql';
import {useDebouncedState, useFilterEmptyValues, useWrapFunctionWithErrorHandler} from '@growthbase/spa';
import {useCallback, useMemo, FC} from 'react';
import {Deelnemers} from '@growthbase/gebruikers';

export interface DeelnemersConnectedProps {
    readonly?: boolean;
    disabled?: boolean;
    stackSize?: number;
    size?: AvatarSize;
    fragment?: IDeelnemersVanTaakFragment;
    kanDeelnemersBewerken?: boolean;
}

export const DeelnemersConnected: FC<DeelnemersConnectedProps> = ({fragment, disabled, readonly, ...rest}) => {
    readonly = readonly || !fragment?.availableActions?.kanDeelnemersBewerken;
    const taakId = fragment?.id ?? '';
    const {
        value: searchQuery,
        setValue: setSearchQuery,
        debouncedValue: searchQueryDebounced,
    } = useDebouncedState<string | null>(null, 400);

    const controls = useMemoryPopupControls(false, false, false, 'deelnemers');
    const {data, loading} = useTaakDeelnemersZoekenQuery({
        skip: !controls.isOpen || readonly,
        variables: {
            taakId,
            fulltextSearch: searchQueryDebounced,
        },
    });

    const voegToe = useWrapFunctionWithErrorHandler(useVoegTaakDeelnemerToeMutation()[0], undefined);
    const verwijder = useWrapFunctionWithErrorHandler(useVerwijderTaakDeelnemerMutation()[0], undefined);
    const deelnemers = useFilterEmptyValues(fragment?.deelnemers);
    const isPrive = fragment?.priveVoorMij ?? false;
    return (
        <PopupControlsContext.Provider value={controls}>
            <Deelnemers
                disabled={!fragment || disabled}
                readonly={readonly || isPrive}
                {...rest}
                items={useMemo(
                    () =>
                        deelnemers.map(({gebruiker}) => ({
                            ...gebruiker,
                            selected: true,
                        })),
                    [deelnemers]
                )}
                popupItems={useResolveConnections(data?.Gebruikers)}
                onRemove={useCallback(
                    ({id: gebruikerId}) =>
                        verwijder({
                            variables: {
                                input: {
                                    gebruikerId,
                                    id: taakId,
                                },
                            },
                        }),
                    [
                        taakId,
                        verwijder,
                    ]
                )}
                onAdd={useCallback(
                    ({id: gebruikerId}) =>
                        voegToe({
                            variables: {
                                input: {
                                    gebruikerId,
                                    id: taakId,
                                },
                            },
                        }),
                    [
                        taakId,
                        voegToe,
                    ]
                )}
                onChangeQuery={(input) => {
                    setSearchQuery(input.query);
                }}
                searchQuery={searchQuery}
                loading={loading}
            />
        </PopupControlsContext.Provider>
    );
};
