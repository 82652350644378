import {useField} from 'formik';
import {FC, ReactNode} from 'react';
import {
    FormikFieldProps,
    useSetInputActiveFromBool,
    PopupControlsContext,
    PopupControls,
} from '@growthbase/design-components';
import {RelatieValue} from '@growthbase/relaties';
import {KoppelButton, RenderKoppelButtonProps} from '../KoppelButton';
import {KoppelbaarItem, KoppelPopupRelatieGroupType} from '../../KoppelPopup';

export interface KoppelButtonInputProps extends FormikFieldProps<KoppelbaarItem | null> {
    setSearchQuery: (query: string | null) => void;
    searchQuery: string | null;
    loading: boolean;
    groepen: KoppelPopupRelatieGroupType[];
    popupControls: PopupControls;
    onChangeRelation?: (value: RelatieValue | null) => void;
    renderKoppelButton?: (props: RenderKoppelButtonProps) => ReactNode;
}

export const KoppelButtonInput: FC<KoppelButtonInputProps> = ({
    popupControls: controls,
    onChangeRelation,
    groepen,
    loading,
    disabled,
    searchQuery,
    setSearchQuery,
    readonly,
    onBlur,
    onFocus,
    renderKoppelButton,
    field: {name},
}) => {
    const [
        {value},
        ,
        {setValue},
    ] = useField<KoppelbaarItem | null>(name);
    useSetInputActiveFromBool({
        onBlur,
        onFocus,
        isActive: controls.isOpen,
    });
    return (
        <PopupControlsContext.Provider value={controls}>
            <KoppelButton
                relatiegroepen={groepen}
                readonly={readonly}
                renderKoppelButton={renderKoppelButton}
                loading={loading}
                disabled={disabled}
                query={searchQuery ?? null}
                item={value}
                onChangeRelation={onChangeRelation}
                onChange={(newVal): Promise<void> => {
                    setValue(newVal);
                    return Promise.resolve();
                }}
                onRemove={(): Promise<void> => {
                    setValue(null);
                    return Promise.resolve();
                }}
                onChangeQuery={(query) => setSearchQuery(query.query)}
            />
        </PopupControlsContext.Provider>
    );
};
