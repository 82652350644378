/* eslint-disable camelcase */
import {useExternalEntityAdded, useExternalEntityRemoved} from '@growthbase/cache';
import {ITakenBord_Deelnemer, useUseUpdateFromExternalEntityChangesLazyQuery} from '@growthbase/graphql';
import {idFromIri} from '@growthbase/routing';

export const useUpdateFromExternalEntityChanges = (id: string) => {
    id = idFromIri(id);
    const [query] = useUseUpdateFromExternalEntityChangesLazyQuery({
        variables: {
            id,
        },
    });
    useExternalEntityAdded<ITakenBord_Deelnemer>(({typename, node: {bordId}}) => {
        if (typename === 'TakenBord_Deelnemer' && id === bordId) {
            query();
        }
    });
    useExternalEntityRemoved<ITakenBord_Deelnemer>(({typename, node: {bordId}}) => {
        if (typename === 'TakenBord_Deelnemer' && id === bordId) {
            query();
        }
    });
};
