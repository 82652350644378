import React, {FC, ReactNode, useEffect, useId} from 'react';
import {ObjectInspector} from 'react-inspector';
import {useCreateStrictForm} from '@growthbase/design-components';
import {useCallbackRef} from '@growthbase/spa';
import {useUnmount} from 'react-use';
import {InstellingFieldsInput, InstellingFieldType, InstellingValues, NestedValue} from '../../types';

import {useDestroy, useOptionalInstellingenForm, useUpdateExternals} from '../../redux';
import {defaultFormComponent} from './defaultFormComponent';
import {ComponentResolver, InstellingInputProps, ModelnstellingInput} from './Components';
import {useCreateSchema, useNormalizeFields, useCreateInitialValues} from '../../Hook';

export interface InstellingenFormProps<T extends object> {
    titel: string;
    values?: Partial<T> | null;
    fields: InstellingFieldsInput<T>;
    onSubmit: (values: T) => Promise<void>;
    debug?: boolean;
    resolveInputComponent?: ComponentResolver | null;

    renderRootInputComponent?: (props: InstellingInputProps) => ReactNode;
    RootInputComponent?: FC<InstellingInputProps>;
}

export function InstellingenForm<T extends object>({
    onSubmit,
    fields: fieldsInput,
    titel,
    debug,
    resolveInputComponent: resolveComponentInput,
    values: valuesInput,
    renderRootInputComponent,
    RootInputComponent = ModelnstellingInput,
}: InstellingenFormProps<T>) {
    const id = useId();
    const state = useOptionalInstellingenForm(id);
    const fields = useNormalizeFields(fieldsInput);
    const {Form, formProps, formik} = useCreateStrictForm<InstellingValues>({
        onSubmit: useCallbackRef(() => {
            if (!state) {
                throw new Error('InstellingenForm not found');
            }
            return onSubmit(state.valuesWithOverrides as T);
        }),
        schema: useCreateSchema(fields),
        createInitialValues: useCreateInitialValues(fields, valuesInput),
    });
    const {values} = formik;

    const update = useUpdateExternals();
    useEffect(() => {
        update({id, fields: fields.getFields(), values});
    }, [id, fields, update, values]);

    const destroy = useDestroy();
    useUnmount(() => destroy(id));

    const resolveComponent = resolveComponentInput ?? defaultFormComponent;

    const rootValues: InstellingInputProps<NestedValue> = {
        fields: fields.rootFields().names(),
        resolveComponent,
        basePath: '',
        type: InstellingFieldType.nested,
        name: '',
        override: null,
        label: titel,
        id,
        input: undefined,
        parent: undefined,
        depth: 0,
    };

    if (!state) {
        return null;
    }

    return (
        <Form {...formProps}>
            {renderRootInputComponent ? renderRootInputComponent(rootValues) : <RootInputComponent {...rootValues} />}
            {debug && <ObjectInspector expandLevel={10} data={state.valuesWithOverrides} />}
        </Form>
    );
}
