import {IMedewerkerFragment, IRoosterFragment, BackgroundEventType} from '@growthbase/planning';
import moment from 'moment';
import {useMemo} from 'react';

export const useNormalizeSchedule = () =>
    useMemo(
        () =>
            (rooster: IRoosterFragment, medewerker: IMedewerkerFragment): BackgroundEventType[] => {
                const startOfDay = moment(rooster.start).startOf('day').toDate();
                const endOfDay = moment(rooster.end).subtract(1, 'second').endOf('day').toDate();

                if (!rooster.isWorkDay) {
                    return [
                        {
                            start: startOfDay,
                            end: endOfDay,
                            resource: medewerker.agenda.id,
                            background: 'var(--calendarUnavailableColor)',
                        },
                    ];
                }

                return [
                    {
                        start: startOfDay,
                        end: moment(rooster.start).toDate(),
                        resource: medewerker.agenda.id,
                        background: 'var(--calendarUnavailableColor)',
                    },
                    {
                        start: moment(rooster.end).toDate(),
                        end: endOfDay,
                        resource: medewerker.agenda.id,
                        background: 'var(--calendarUnavailableColor)',
                    },
                ];
            },
        []
    );
