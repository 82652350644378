import {Button, Buttons} from '@growthbase/design-components';
import {useCallbackRef, useHandleError, useHTTPClient} from '@growthbase/spa';
import React, {memo} from 'react';
import {toast} from 'react-toastify';
import type {DTO} from '../SyncPage';
import {ApiRoutes} from '../SyncRoutes';

export const IgnoreButton = memo(({row}: {row: DTO}) => {
    const client = useHTTPClient();
    const url = ApiRoutes.ignore.useUrl();
    const handleError = useHandleError();
    const RenderButton = row.ignored ? Buttons.Check : Button;
    return (
        <RenderButton
            active={row.ignored}
            onClick={useCallbackRef(() => {
                toast
                    .promise(
                        client
                            .post(url, {
                                id: row.id,
                                type: row.type,
                                ignored: !row.ignored,
                            })
                            .toPromise(),
                        {
                            success: 'Verwerkt',
                            pending: 'Aanpassen...',
                        }
                    )
                    .catch(handleError);
            })}
        />
    );
});
IgnoreButton.displayName = 'IgnoreButton';
