import React, {forwardRef, Ref} from 'react';
import {Avatar, AvatarProps} from '../Avatar';

export const AvatarButton = forwardRef((props: AvatarProps, ref) => (
    <div ref={ref as Ref<HTMLDivElement>}>
        <Avatar {...props} />
    </div>
));

AvatarButton.displayName = 'AvatarButton';
