import {ReactNode, FC} from 'react';
import classNames from 'classnames/bind';

import styles from '../Tabs.module.scss';

const cx = classNames.bind(styles);

export interface TabContentWrapperProps {
    title: string;
    children: ReactNode;
}

export const TabContentWrapper: FC<TabContentWrapperProps> = ({children, title}) => (
    <div data-test="Tab" data-testid={title} className={cx('tabs__contentWrapper')}>
        {children}
    </div>
);
