import {createGebruikerServices} from '@growthbase/gebruikers';
import {createWebsocketsReduxServices, createWebsocketsServices} from '@growthbase/websockets';
import {pipe} from 'lodash/fp';
import {createGraphQLServices} from '@growthbase/graphql';
import {
    ApplicationServiceDependencies,
    ApplicationServices,
    BaseApplicationServices,
    BaseDependencies,
    createWarningServices,
    createDTOsFromGlobals,
    createErrorHandlingServices,
    createHttpServices,
    createLoggingServices,
    createReduxService,
    createTranslationServices,
    SpaVariablesDTO,
    ExtractWarningFromResponseService,
    createIsReplayingServices,
} from '@growthbase/spa';
import {createRelatiesServices} from '@growthbase/relaties';
import {createKoppelServices, createTakenServices} from '@growthbase/taken';
import {createPlanningServices} from '@growthbase/planning';
import {createInstellingenServices} from '@growthbase/instellingen';
import {createDeveloperService} from '@growthbase/health';
import {createTaakUrenService} from '@growthbase/clientbox-uren';

export type ServiceFactory<TDeps, TReturn> = (deps: TDeps) => TReturn;

function combine<TDeps, TReturn>(serviceFactory: ServiceFactory<TDeps, TReturn>): (deps: TDeps) => TDeps & TReturn {
    return (deps) => ({...deps, ...serviceFactory(deps)});
}

export const createServices = <TVariables extends SpaVariablesDTO>(
    deps: BaseDependencies<TVariables>,
    createApplicationServices: ServiceFactory<BaseApplicationServices<TVariables>, ApplicationServiceDependencies>
): ApplicationServices<TVariables> => {
    const services = pipe(
        combine(createDTOsFromGlobals),
        combine(createLoggingServices),
        combine(createTranslationServices),
        combine(createHttpServices),

        combine(createErrorHandlingServices),
        combine(createApplicationServices),

        combine(createWarningServices),

        combine(createGraphQLServices),
        combine(createTaakUrenService),
        combine(createPlanningServices),
        combine(createDeveloperService),
        combine(createInstellingenServices),
        combine(createWebsocketsReduxServices),
        combine(createReduxService),
        combine(createWebsocketsServices),
        combine(createGebruikerServices),
        combine(createRelatiesServices),
        combine(createKoppelServices),
        combine(createTakenServices),
        combine(createIsReplayingServices)
    )(deps);

    ExtractWarningFromResponseService.store = services.store;
    ExtractWarningFromResponseService.logger = services.logger;

    return services;
};
