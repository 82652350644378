import {useCallback} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useBackgroundRoute} from './useBackgroundRoute';

declare global {
    interface Window {
        navigation?: {canGoBack: boolean};
    }
}

let nav: {canGoBack: boolean} = {
    canGoBack: true,
};

/**
 * Can't use window?.navigation this will cause an error on firefox and docker chrome browser.
 */
if (Object.hasOwnProperty.call(window, 'navigation')) {
    nav = window.navigation as {canGoBack: boolean};
}

export const useHistoryBack = () => {
    const navigate = useNavigate();
    const {key, pathname} = useLocation();
    const [backgroundRoute] = useBackgroundRoute();
    return useCallback(() => {
        if (key === 'default' || window.history.length === 1 || !nav.canGoBack) {
            if (backgroundRoute) {
                navigate(backgroundRoute, {
                    replace: true,
                });
                return;
            }
            const split = pathname.split('/');
            navigate(split.slice(0, -1).join('/'), {
                replace: true,
            });
        } else {
            navigate(-1);
        }
    }, [navigate, key, pathname, backgroundRoute]);
};
