export const useOr = <T>(has: T | undefined, keys: Array<keyof T>) => {
    if (!has) {
        return false;
    }
    for (const key of keys) {
        if (has[key]) {
            return true;
        }
    }
    return false;
};
