import {FC} from 'react';
import {IIsReplaying} from '@growthbase/graphql';
import {useTranslation} from 'react-i18next';

export const ReplayingMessage: FC<{fragment: IIsReplaying}> = ({fragment: {total, count}}) => {
    const {t} = useTranslation('Spa.nl');

    return (
        <p>
            {t('IsReplaying', {
                total,
                count,
            })}
        </p>
    );
};
