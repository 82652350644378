import React, {ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {ActiveItemSource, ActiveItemType, useTraversableOverview} from '@growthbase/keys';
import {Popup, PopupAnchorProps, PopupHeader, ScrollablePopupContent} from '../../Popups';
import {AvatarButton} from '../AvatarButton';
import {AvatarListItem} from '../AvatarListItem';
import {AvatarProps} from '../Avatar';
import {ActionButton, ActionButtonProps} from '../../Buttons';
import {SearchForm, SearchInput} from '../../SearchForm';
import {Padding} from '../../Layout';
import styles from '../../Popups/ListPopup/ListPopup.module.scss';

export interface AvatarPopupItem {
    id: string;
    selected?: boolean;
    naam: string;
    imageUrl?: string | null;
}

export interface AvatarPopupProps<T extends AvatarPopupItem = AvatarPopupItem> {
    testId?: string;
    renderAnchor: (props: PopupAnchorProps) => ReactNode;

    items: T[];
    title: string;

    avatarProps?: (item: T) => Partial<AvatarProps>;
    actionButtonProps?: (item: T) => Partial<ActionButtonProps>;
    onClickRow: (item: T) => void;
    readonly?: boolean;
    disabled?: boolean;

    onChangeQuery: (value: SearchInput) => void;
    searchQuery: string | null;
    loading: boolean;
}

function AvatarPopupInternal<T extends AvatarPopupItem = AvatarPopupItem>({
    items,
    title,
    onClickRow,
    actionButtonProps,
    readonly,
    disabled,
    avatarProps,
    onChangeQuery,
    searchQuery,
    loading,
}: Omit<AvatarPopupProps<T>, 'testId' | 'renderAnchor'>) {
    const {t} = useTranslation('spa.nl');
    const enabled = !readonly && !disabled;

    const {setTopRef} = useTraversableOverview({
        items,
        type: ActiveItemType.Unknown,
        source: ActiveItemSource.AvatarListItem,
    });

    return (
        <ScrollablePopupContent
            header={<PopupHeader title={title} small />}
            contentHeader={
                <Padding>
                    <SearchForm
                        innerRef={setTopRef}
                        onChange={onChangeQuery}
                        value={{
                            query: searchQuery,
                        }}
                        loading={loading}
                    />
                </Padding>
            }
            className={styles.listPopup}
            noPadding
        >
            <Padding>
                {!loading && items.length === 0 ? t('design.Avatar.AvatarPopup.GeenResultaten') : null}
                {items.map((item) => (
                    <AvatarListItem
                        key={item.id}
                        data={item}
                        disabled={disabled}
                        selected={item.selected ?? false}
                        onClick={() => onClickRow(item)}
                        renderAvatar={(props) => (
                            <AvatarButton
                                naam={item.naam}
                                readonly={readonly}
                                imageUrl={item.imageUrl}
                                {...props}
                                renderActionButton={
                                    enabled && props.selected
                                        ? (abp) => (
                                              <ActionButton
                                                  {...(actionButtonProps ? actionButtonProps(item) : {})}
                                                  active
                                                  {...abp}
                                              />
                                          )
                                        : undefined
                                }
                                {...(avatarProps ? avatarProps(item) : {})}
                            />
                        )}
                    >
                        {item.naam}
                    </AvatarListItem>
                ))}
            </Padding>
        </ScrollablePopupContent>
    );
}

export function AvatarPopup<T extends AvatarPopupItem = AvatarPopupItem>({
    renderAnchor,
    testId,
    ...internalProps
}: AvatarPopupProps<T>) {
    return (
        <Popup renderAnchor={renderAnchor} testId={testId} arrow>
            <AvatarPopupInternal {...internalProps} />
        </Popup>
    );
}
