import React, {ReactNode} from 'react';
import {DefaultFormModalFooter, Modal, ModalContent, PopupHeader} from '@growthbase/design-components';
import {useFormikContext} from 'formik';
import {NestedInstellingInput} from '@growthbase/instellingen';
import {useTranslation} from 'react-i18next';
import {InstellingInputProps} from './InstellingInputWrapper';
import {NestedValue} from '../../../types';

export interface ModelnstellingInputProps {
    buttonText?: ReactNode;
    kopierenButtonText?: boolean;
}

export const ModelnstellingInput = (props: InstellingInputProps & ModelnstellingInputProps) => {
    const {t} = useTranslation('spa_form.nl');

    const {label, buttonText, kopierenButtonText} = props as InstellingInputProps<NestedValue> &
        ModelnstellingInputProps;
    const formikContext = useFormikContext();
    return (
        <Modal>
            <PopupHeader title={label} />
            <ModalContent theme="gray">
                <NestedInstellingInput {...props} />
            </ModalContent>
            <DefaultFormModalFooter formik={formikContext}>
                {buttonText || kopierenButtonText ? t('copy') : undefined}
            </DefaultFormModalFooter>
        </Modal>
    );
};
