import {AnchorOrLinkValue} from '@growthbase/design-components';
import {useCallback, useMemo} from 'react';
import {useUrlFactory} from '@growthbase/routing';
import {Route} from '../Schema/Route';

export const useLinkPropsForRouteFactory = () => {
    const urlFactory = useUrlFactory();
    return useCallback(
        (nativeLink: string | null, route?: Route | null): AnchorOrLinkValue => {
            if (!route || route.withPageReload) {
                return {
                    to: null,
                    href: nativeLink,
                };
            }
            return {
                to: urlFactory(route.name, {withBackground: route.renderInForeground}),
                href: null,
            };
        },
        [urlFactory]
    );
};

export const useLinkPropsForRoute = (nativeLink: string | null, route?: Route | null): AnchorOrLinkValue => {
    const urlFactory = useLinkPropsForRouteFactory();
    return useMemo((): AnchorOrLinkValue => urlFactory(nativeLink, route), [nativeLink, route, urlFactory]);
};
