import {useContext, useEffect} from 'react';
import {EMPTY} from 'rxjs';
import {CloseEvent, PopupControlsContext} from '../PopupControls';

export function useOnCloseEvents(handler: (event: CloseEvent) => void): void {
    const context = useContext(PopupControlsContext);
    const observable = context?.onClose ?? EMPTY;
    useEffect(() => {
        const subscription = observable.subscribe(handler);
        return () => subscription.unsubscribe();
    }, [handler, observable]);
}
