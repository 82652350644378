import {
    IKoppelRelatieGegevens,
    IRelatieGroupsQuery,
    ITaakRelatieButtonFragment,
    useTaakRelatieButtonChangeMutation,
    useTaakRelatieButtonLazyQuery,
} from '@growthbase/graphql';
import {RelatieButtonConnected, RelatieButtonContentProps} from '@growthbase/relaties';
import {FC, useCallback} from 'react';

export interface TaakRelatieButtonProps {
    readonly?: boolean;
    disabled?: boolean;
    fragment: ITaakRelatieButtonFragment;
    singleValue?: boolean;
    ContentElement?: FC<RelatieButtonContentProps>;
}

export const TaakRelatieButtonConnected = ({
    readonly,
    disabled,
    fragment,
    singleValue,
    ContentElement,
}: TaakRelatieButtonProps) => {
    const [request] = useTaakRelatieButtonLazyQuery();
    const [mutate] = useTaakRelatieButtonChangeMutation();
    readonly = !fragment?.availableActions?.kanRelatieBewerken || readonly;
    const requestData = useCallback(
        (query: IRelatieGroupsQuery) =>
            request({
                variables: {
                    id: fragment.id,
                    query,
                },
            }).then(({data, error}) => {
                if (!data?.taak) {
                    return Promise.reject(error);
                }
                return data.taak.beschikbareRelaties;
            }),
        [fragment.id, request]
    );
    const onChange = useCallback(
        (change: IKoppelRelatieGegevens) =>
            mutate({
                variables: {
                    input: {
                        id: fragment.id,
                        clientMutationId: 'TaakRelatieButtonConnected',
                        koppeling: change,
                    },
                },
            }),
        [mutate, fragment.id]
    );
    if (readonly && !fragment.relatie) {
        return null;
    }
    return (
        <RelatieButtonConnected
            readonly={readonly}
            disabled={disabled}
            singleValue={singleValue}
            onChange={onChange}
            requestData={requestData}
            fragment={fragment.relatie?.relatieverwijzing}
            ContentElement={ContentElement}
        />
    );
};
