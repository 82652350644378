import {createContext, useContext} from 'react';
import {DNDContainerId, DNDContainerContext} from '../Value';

export interface DNDContextInterface {
    containers: Record<DNDContainerId, DNDContainerContext>;
}

export const defaultContext: DNDContextInterface = {
    containers: {},
};

const context = createContext<DNDContextInterface>(defaultContext);

export const useDNDContext = () => useContext(context);

export const DNDContextProvider = context.Provider;
