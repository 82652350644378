import classNames from 'classnames/bind';
import {forwardRef, ReactNode, Ref, RefAttributes, FC, MouseEventHandler} from 'react';
import {Link, To} from 'react-router-dom';
import {capitalize} from 'lodash';
import styles from './Button.module.scss';
import {IconProps, IconSize} from '../Icon';
import {Icons} from '../icons';
import {Tooltip} from '../Tooltip';
import {ButtonBadge} from './Components';
import {HandleClickStateOptions, useHandleClickState} from './Hook/useHandleClickState';
import {ViewConfig} from '../BaseFieldProps';

/* eslint-disable no-shadow */

export interface BaseButtonProps extends RefAttributes<unknown>, HandleClickStateOptions {
    reference?: Ref<unknown>;
    testId?: string;
    tabIndex?: number;
    ariaLabel?: string | null;
    active?: boolean;
    readonly?: boolean;
    onNativeClick?: MouseEventHandler<HTMLElement>;
    onMouseDown?: () => void;
    showOnRowHover?: boolean;
    /**
     * The text of the button.
     */
    children?: ReactNode;
    tooltip?: ReactNode;
    Icon?: FC<IconProps>;
    className?: string;
}

export type RenderButtonInterface = (props: {Button?: BaseButtonProps} & Partial<ViewConfig>) => ReactNode;

export interface ButtonProps extends BaseButtonProps {
    active?: boolean;
    variant?:
        | 'default'
        | 'primary'
        | 'danger'
        | 'dangerIcon'
        | 'iconOnly'
        | 'iconOnlyDanger'
        | 'iconOnlyPrimary'
        | 'iconOnlyDotted'
        | 'iconOnlySolid'
        | 'iconOnlyRound'
        | 'link';
    extraPadding?: boolean;
    size?: 'default' | 'medium' | 'small' | 'tiny' | 'larger';
    disableBorder?: boolean;
    to?: To;
    className?: string;
    badgeCount?: number;
    buttonType?: 'button' | 'submit' | 'reset';
    iconRight?: boolean;
    iconSize?: IconSize;
    autoHeight?: boolean;
    square?: boolean;
}

const cx = classNames.bind(styles);

export const Button = forwardRef(
    (
        {
            testId,
            ariaLabel,
            Icon,
            iconSize,
            to,
            className,
            children,
            active,
            variant,
            size,
            disableBorder,
            buttonType,
            iconRight,
            extraPadding,
            onMouseDown,
            showOnRowHover,
            tooltip,
            badgeCount,
            autoHeight,
            tabIndex,
            square,
            onNativeClick,
            ...rest
        }: ButtonProps,
        ref
    ) => {
        const {loading, disabled, onClick} = useHandleClickState(rest);

        const buttonClass = cx(className, `styledButton--size${capitalize(size)}`, `styledButton--${variant}`, {
            styledButton: true,
            [`styledButton--${variant}Active`]: active,
            'styledButton--active': active,
            'styledButton--noBorder': disableBorder,
            'styledButton--sizeSmall': size === 'small',
            'styledButton--sizeMedium': size === 'medium',
            'styledButton--sizeTiny': size === 'tiny',
            'styledButton--text': !Icon && children,
            'styledButton--iconText': Icon && children,
            'styledButton--disabled': disabled,
            'styledButton--loading': loading,
            'styledButton--badge': !!badgeCount,
            'styledButton--extraPadding': extraPadding,
            'styledButton--showOnRowHover': !!showOnRowHover,
            'styledButton--autoHeight': !!autoHeight,
            'styledButton--square': !!square,
        });

        if (!iconSize) {
            iconSize = size === 'default' ? IconSize.SIZE_4 : IconSize.SIZE_3;
            iconSize = size === 'medium' ? IconSize.SIZE_3 : iconSize;
            iconSize = size === 'small' ? IconSize.SIZE_2 : iconSize;
            iconSize = size === 'tiny' ? IconSize.SIZE_1 : iconSize;
        }

        if (to) {
            if (rest?.onClick) {
                throw new Error('to and onClick not supported');
            }
            return (
                <Link
                    data-testid={testId}
                    ref={tooltip ? undefined : (ref as Ref<HTMLAnchorElement>)}
                    aria-label={ariaLabel ?? undefined}
                    className={buttonClass}
                    to={to}
                >
                    {Icon && <Icon size={iconSize} />}
                    {children}
                </Link>
            );
        }

        const Button = (
            <button
                tabIndex={tabIndex}
                data-testid={testId}
                ref={ref as Ref<HTMLButtonElement>}
                // eslint-disable-next-line react/button-has-type
                type={buttonType === undefined ? 'button' : buttonType}
                aria-label={ariaLabel ?? undefined}
                className={buttonClass}
                onMouseDown={disabled ? undefined : onMouseDown}
                onClick={disabled ? undefined : onNativeClick ?? onClick}
            >
                {!iconRight && !loading && Icon && <Icon size={iconSize} />}
                {!iconRight && loading && <Icons.Loading size={iconSize} animation="spin" />}
                {children}
                {iconRight && !loading && Icon && <Icon size={iconSize} />}
                {iconRight && loading && <Icons.Loading size={iconSize} animation="spin" />}
                {!!badgeCount && <ButtonBadge className={styles.styledButton__badge}>{badgeCount}</ButtonBadge>}
            </button>
        );

        if (tooltip) {
            return <Tooltip content={tooltip}>{Button}</Tooltip>;
        }

        return Button;
    }
);

Button.displayName = 'Button';
