import {SearchForm, Stack, Table, TableHeader, TableHeaderSection, useSearchField} from '@growthbase/design-components';
import React, {FC} from 'react';
import {CellProps, Column} from 'react-table';
import {DeveloperRoutes} from '@growthbase/health';
import {IProjectionPageDataFragment, useProjectionsPageQuery} from '@growthbase/graphql';
import {usePaginationFixedResult} from '@growthbase/spa';
import {Link} from 'react-router-dom';
import {CircularProgressWithLabel} from './Components/CircularProgressWithLabel';
import {ReplayButtonConnected} from '../ReplayButton/ReplayButtonConnected';

export type ProjectionMethodArgument = {
    class: string;
    shortName: string;
};

export type ProjectionMethod = {
    method: string;
    arguments: ProjectionMethodArgument[];
};

export type Projector = {
    class: string;
    methods: ProjectionMethod[];
    events: string[];
};

export type DTO = IProjectionPageDataFragment;

const ActionsCell: FC<CellProps<DTO>> = ({row}) => {
    const progress =
        row.original.totalProjections > 0 ? (row.original.activeProjections / row.original.totalProjections) * 100 : 0;
    return (
        <Stack horizontal>
            {row.original.activeProjections !== 0 && <CircularProgressWithLabel value={progress} />}
        </Stack>
    );
};

const IdCell: FC<CellProps<DTO>> = ({row}) => (
    <Link to={DeveloperRoutes.projections_detail.useUrl({id: row.original.id})}>{row.original.id}</Link>
);

const NumberWhenNotZero = ({value}: {value?: number | null}) => <span>{value || ''}</span>;

const columns: ReadonlyArray<Column<DTO>> = [
    {
        Header: 'Id',
        accessor: 'id',
        Cell: IdCell,
        minWidth: 200,
    },
    {
        Header: 'Actief',
        accessor: 'activeProjections',
        minWidth: 10,
        Cell: NumberWhenNotZero,
    },
    {
        Header: 'Totaal',
        accessor: 'totalProjections',
        minWidth: 10,
    },
    {
        Header: 'Emergency',
        accessor: 'emergency',
        minWidth: 10,
        Cell: NumberWhenNotZero,
    },
    {
        Header: 'Errors',
        accessor: 'errors',
        minWidth: 10,
        Cell: NumberWhenNotZero,
    },
    {
        Header: 'Warnings',
        accessor: 'warnings',
        minWidth: 10,
        Cell: NumberWhenNotZero,
    },
    {
        Header: <div />,
        Cell: ActionsCell,
        id: 'actions',
        width: 'auto',
        minWidth: 90,
    },
];

export const ProjectionsPage: FC = () => {
    const [state, setState] = useSearchField('query');
    const {data, loading} = useProjectionsPageQuery({
        pollInterval: 1000,
        variables: {
            filters: {
                name: state.query,
            },
        },
    });
    const pagination = usePaginationFixedResult(data?.projections ?? []);
    return (
        <Table<DTO>
            header={
                <TableHeader>
                    <TableHeaderSection>
                        {data?.projections.length ?? ''} Projections{' '}
                        <ReplayButtonConnected>Replay all</ReplayButtonConnected>
                    </TableHeaderSection>

                    <TableHeaderSection>
                        <SearchForm loading={loading} value={state} onChange={setState} />
                    </TableHeaderSection>
                </TableHeader>
            }
            columns={columns}
            pagination={pagination}
        />
    );
};
