import {QueryResult} from '@apollo/client';
import {PaginationHelpers, PaginationInformation, useCallbackRef} from '@growthbase/spa';
import {useMemo} from 'react';
import {Node, ResultData, useResolveConnections} from '@growthbase/graphql';
import {paginationSearchParamsSchema} from '../Schema/paginationSearchParamsSchema';
import {useTypedSearchParams} from './useTypedSearchParams';

export type PartialQueryResult<TResult> = Pick<
    QueryResult<TResult>,
    'data' | 'loading' | 'updateQuery' | 'previousData' | 'fetchMore' | 'variables'
>;

export type Mapper<TNode extends Node, TData = ResultData<TNode>> = (
    data: TData | undefined | null
) => ResultData<TNode> | undefined | null;

export interface PaginationSearchHelpers<TNode extends Node, TData = ResultData<TNode>> {
    /**
     * Used for query input to the backend.
     */
    pagination: PaginationInformation;
    /**
     * Used creating the actual pagination helpers object controlling
     * the input.
     */
    usePagination: (query: PartialQueryResult<TData>, mapper?: Mapper<TNode, TData>) => PaginationHelpers<TNode>;
}

const ITEMS_PER_PAGE = 50;

export const isDefaultPagination = (info: PaginationInformation) => info.first === ITEMS_PER_PAGE;

export function identityMapItems<TNode extends Node, TData extends ResultData>(
    data: TData | undefined | null
): ResultData<TNode> | undefined | null {
    return data as unknown as ResultData<TNode>;
}

interface PaginationSearchParams {
    preserveItemsWhileLoading?: boolean;
    inverse?: boolean;
}

export function usePaginationSearchParams<TNode extends Node, TData extends ResultData<TNode> = ResultData<TNode>>(
    key: string,
    {preserveItemsWhileLoading = true, inverse = false}: PaginationSearchParams = {}
): PaginationSearchHelpers<TNode> {
    const [params, setSearchParams] = useTypedSearchParams(
        key,
        paginationSearchParamsSchema,
        useMemo(
            () => ({
                start: undefined,
                items: ITEMS_PER_PAGE,
                last: undefined,
            }),
            []
        )
    );
    const {items} = params;
    const pagination = useMemo(
        () => ({
            first: !inverse ? items : undefined,
            last: inverse ? items : undefined,
        }),
        [items, inverse]
    );

    return {
        pagination,
        // @ts-expect-error Grrr.
        usePagination: (
            query: PartialQueryResult<TData>,
            getItems: Mapper<TNode, TData> = identityMapItems<TNode, TData>
        ): PaginationHelpers<TNode> => {
            const {fetchMore, variables} = query;

            const pageInfo = getItems(query?.data)?.items?.pageInfo;
            const totalCount = getItems(query?.data)?.items?.totalCount ?? 0;
            const nextCursor = pageInfo?.startCursor;
            /* eslint-disable */
            const loadMore = useCallbackRef((more: number = ITEMS_PER_PAGE): Promise<void> => {
                const newPagination = {
                    start: !inverse ? nextCursor ?? undefined : undefined,
                    items: items + more,
                    last: inverse ? nextCursor ?? undefined : undefined,
                };
                setSearchParams(newPagination);
                return fetchMore({
                    variables: {...(variables ?? {}), ...newPagination},
                }).then(() => undefined);
            });

            const isLoading = query.loading;

            /* eslint-enable */
            const connections = useResolveConnections<TNode>(getItems(query.data)?.items);

            const previousConnections = useResolveConnections<TNode>(getItems(query.previousData)?.items);

            const hasMore = pageInfo?.hasNextPage || false;

            return useMemo(
                () => ({
                    totalCount,
                    loadMore,
                    connections: preserveItemsWhileLoading && isLoading ? previousConnections : connections,
                    isLoading,
                    hasMore,
                }),
                [
                    previousConnections,
                    totalCount,
                    loadMore,
                    hasMore,
                    connections,
                    isLoading,
                ]
            );
        },
    };
}
