import {isLegacyRoute, stripReloadDocument} from '@growthbase/routing';
import React, {forwardRef, ReactNode, Ref} from 'react';
import {Link, To} from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from './BreadcrumbLink.module.scss';

const cx = classNames.bind(styles);

export interface BreadcrumbLinkProps {
    children: ReactNode;
    to?: To;
    testId?: string;
    isLast?: boolean;
}

export const BreadcrumbLink = forwardRef(({children, to, testId, isLast}: BreadcrumbLinkProps, ref) => {
    const classes = cx('breadcrumbLink', {
        'breadcrumbLink--last': isLast,
        'breadcrumbLink--link': to,
    });

    if (isLegacyRoute(to)) {
        return (
            <a
                ref={ref as Ref<HTMLAnchorElement>}
                data-testid={testId}
                data-testrole="breadcrumb"
                className={classes}
                href={stripReloadDocument(to)}
            >
                {children}
            </a>
        );
    }

    if (to) {
        return (
            <Link
                ref={ref as Ref<HTMLAnchorElement>}
                data-testid={testId}
                data-testrole="breadcrumb"
                className={classes}
                to={to}
            >
                {children}
            </Link>
        );
    }

    return (
        <div ref={ref as Ref<HTMLDivElement>} data-testid={testId} data-testrole="breadcrumb" className={classes}>
            {children}
        </div>
    );
});

BreadcrumbLink.displayName = 'BreadcrumbLink';
