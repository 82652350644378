import {ReactNode} from 'react';
import {FloatingPortal} from '@floating-ui/react-dom-interactions';

export interface BodyPortalProps {
    children: ReactNode;
}

export const BodyPortal = ({children}: BodyPortalProps) => {
    const portalElement = document.body;
    return (
        <FloatingPortal id="body-portal" root={portalElement}>
            {children}
        </FloatingPortal>
    );
};
