import {RouteElementProps} from '@growthbase/routing';
// eslint-disable-next-line import/order
import {ActionbarConnected, LandingsPaginaBordConnected} from '@growthbase/takenlijst/bord';
import {useOpdrachtBordenConnectedQuery} from '@growthbase/graphql';
import {useGebruikerHeeftToegang} from '@growthbase/design-components';
import {DefaultKoppelbaarItem, DefaultRelatieValue} from '@growthbase/spa';
import {BordenPagina} from './BordenPagina';
import {useResolveOnderdeelVan} from './Hook/useResolveOnderdeelVan';

export type BordenPaginaConnectedProps = RouteElementProps<{
    opdrachtId: string;
    opdrachtnummer: string;
    opdrachtUuid: string;
}>;

export const BordenPaginaConnected = ({params}: BordenPaginaConnectedProps) => {
    if (params === undefined) {
        throw new Error('Logic exception.');
    }

    const {data, loading} = useOpdrachtBordenConnectedQuery({
        variables: {
            id: params.opdrachtUuid,
        },
    });

    const toegang = useGebruikerHeeftToegang({
        toegang:
            data?.ingelogdeGebruiker?.availableActions.kanBordenBekijken &&
            data?.opdracht?.availableActions?.volledigeGegevensBekijken,
        enabled: !loading,
    });
    const opdracht = data?.opdracht;
    const onderdeelVan = useResolveOnderdeelVan(params.opdrachtUuid, opdracht);
    if (!toegang) {
        return null;
    }

    return (
        <DefaultKoppelbaarItem.Provider id="BordenPaginaConnected" value={onderdeelVan}>
            <DefaultRelatieValue.Provider id="BordenPaginaConnected" value={onderdeelVan?.relatie}>
                <BordenPagina opdrachtNaam={opdracht?.titel ?? params.opdrachtnummer} {...params}>
                    <ActionbarConnected />
                    {opdracht && onderdeelVan && <LandingsPaginaBordConnected />}
                </BordenPagina>
            </DefaultRelatieValue.Provider>
        </DefaultKoppelbaarItem.Provider>
    );
};
