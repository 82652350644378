import {useTranslation} from 'react-i18next';
import {Tab, Tabs, TabsRendererMarginLeft} from '@growthbase/design-components';
import React from 'react';
import {AgendaTab, OpdrachtenTabConnected, PlanningStoreState, usePlanningSetActiveTab} from '@growthbase/planning';
import {useSelector} from 'react-redux';
import {useCallbackRef} from '@growthbase/spa';

export const Sidebar = () => {
    const {t} = useTranslation('spa.nl');
    const {opdrachtenSidebarEnabled} = useSelector((state: PlanningStoreState) => state.planning);
    const setActiveTab = usePlanningSetActiveTab();

    const onChangeQuery = useCallbackRef((value: string) => {
        setActiveTab({
            tab: value,
        });
    });

    return (
        <Tabs
            render={TabsRendererMarginLeft}
            query={{defaultValue: 'agenda', name: 'tab'}}
            onQueryChange={onChangeQuery}
        >
            <Tab title={t('planning.agendasTab')} value="agenda">
                <AgendaTab />
            </Tab>
            {opdrachtenSidebarEnabled && (
                <Tab title={t('planning.opdrachtenTab')} value="opdrachten">
                    <OpdrachtenTabConnected />
                </Tab>
            )}
        </Tabs>
    );
};
