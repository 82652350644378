import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {useSelector} from 'react-redux';
import {CapaciteitView} from './CapaciteitView';
import {normalizeData} from './normalizer/normalizeData';
import {CapacityEvent} from './CapacityEvent';
import {EventResource} from '../types';
import {useCapaciteitViewConnectedQuery} from '../../generated/generated';
import {useCalendarIdsToRetrieve} from '../../_hooks/useCalendarIdsToRetrieve';
import {PlanningStoreState} from '../../Redux';
import {AgendaModuleType} from '../../_hooks';

export const CapaciteitViewConnected = ({module}: {module?: AgendaModuleType}) => {
    const {viewedDateRange} = useSelector((state: PlanningStoreState) => state.planning);

    const [resources, setResources] = useState<EventResource[]>([]);
    const [events, setEvents] = useState<CapacityEvent[]>([]);

    const agendaIds = useCalendarIdsToRetrieve();

    const {data, loading} = useCapaciteitViewConnectedQuery({
        pollInterval: 5000,
        variables: {
            startDate: {
                iso8601: moment(viewedDateRange?.start).format(),
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
            endDate: {
                iso8601: moment(viewedDateRange?.end).format(),
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
            agendaIds,
        },
    });

    useEffect(() => {
        if (data) {
            const {resources: newResources, events: newEvents} = normalizeData(data);
            setResources(newResources);
            setEvents(newEvents);
        }
    }, [loading, data]);

    return <CapaciteitView resources={resources} events={events} loading={loading} module={module} />;
};
