import {ViewConfig} from '@growthbase/design-components';
import {IKaarttemplateConnectedFragment} from '@growthbase/graphql';
import {FC, HTMLAttributes, memo} from 'react';

import {TitelConnected} from './Components/Titel';
import {Kaart} from './Kaart';
import {MenuConnected} from '../../../Menu';

export interface KaartConnectedProps extends ViewConfig {
    anchor: HTMLAttributes<HTMLElement> | undefined;
    fragment: IKaarttemplateConnectedFragment;
    dragging?: boolean;
    hoover?: boolean;
}

export const KaartConnectedInner: FC<KaartConnectedProps> = ({
    anchor,
    disabled,
    readonly,
    dragging,
    hoover,
    ...rest
}) => (
    <Kaart
        titel={<TitelConnected {...rest} disabled={disabled} readonly={readonly} />}
        menu={!readonly && <MenuConnected {...rest} />}
        anchor={anchor}
        dragging={dragging}
        hoover={hoover}
    />
);

export const KaartConnected = memo(KaartConnectedInner);
