import {IconButton, Icons, IconSize} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';

export interface TaakMijnDagButtonProps {
    onClick?: () => void;
    active?: boolean;
    loading?: boolean;
    deadlineIsBereikt: boolean;
    begindatumIsBereikt: boolean;
}

export const TaakMijnDagButton = ({
    onClick,
    active,
    loading,
    deadlineIsBereikt,
    begindatumIsBereikt,
}: TaakMijnDagButtonProps) => {
    const {t} = useTranslation('spa.nl');

    const readonly = active && (deadlineIsBereikt || begindatumIsBereikt);

    let tooltip = active ? t('taken.TaakMijnDagButton.tooltipVerwijderen') : t('taken.TaakMijnDagButton.tooltip');

    if (begindatumIsBereikt) {
        tooltip = t('taken.TaakMijnDagButton.tooltipBegindatumIsBereikt');
    }
    if (deadlineIsBereikt) {
        tooltip = t('taken.TaakMijnDagButton.tooltipDeadlineIsBereikt');
    }

    return (
        <IconButton
            readonly={readonly}
            testId="TaakMijnDagButton"
            Icon={Icons.Sun}
            iconSize={IconSize.SIZE_5}
            onClick={onClick}
            ariaLabel={t('taken.TaakMijnDagButton.label')}
            active={active}
            tooltip={tooltip}
            loading={loading}
        />
    );
};
