import React from 'react';
import {DefaultFormModalFooter, Modal, PopupHeader} from '@growthbase/design-components';
import {useFormikContext} from 'formik';
import {InstellingInputProps} from './InstellingInputWrapper';
import {NestedValue} from '../../../types';
import {NestedTabInstellingInput} from './NestedTabInstellingInput';

export const ModelTabInstellingInput = (props: InstellingInputProps) => {
    const {label} = props as InstellingInputProps<NestedValue>;
    const formikContext = useFormikContext();
    return (
        <Modal>
            <PopupHeader title={label} />
            <NestedTabInstellingInput {...props} />
            <DefaultFormModalFooter formik={formikContext} />
        </Modal>
    );
};
