import {useMemo} from 'react';
import * as yup from 'yup';
import {SchemaOf} from 'yup';
import moment, {Moment} from 'moment';
import {TFunction} from 'i18next';
// noinspection ES6PreferShortImport
import {DatePickerValue} from '../../Form/Components/Datepicker/types/DatePickerValue';
// noinspection ES6PreferShortImport
import {
    MinimumDateOptions,
    NullableDatePickerValueSchema,
} from '../../Form/Components/Datepicker/hook/useDatePickerSchema';

export interface ISOStringDateRange {
    from: DatePickerValue;
    to: DatePickerValue;
}

type NullableMoment = Moment | null;
export const asMomentValues = (value: ISOStringDateRange): {to: NullableMoment; from: NullableMoment} => {
    let to = value.to ? moment(value.to.date) : null;
    let from = value.from ? moment(value.from.date) : null;
    if (to && !to.isValid()) {
        to = null;
    }
    if (from && !from.isValid()) {
        from = null;
    }
    return {to, from};
};

export const isSame = (first: Moment, second: Moment) =>
    first.isSame(second, 'day') && first.isSame(second, 'hours') && first.isSame(second, 'minutes');

export interface NullableISOStringDateRange {
    from: DatePickerValue | null;
    to: DatePickerValue | null;
}

export interface DateRangeOptions {
    fromLabel: string | TFunction;
    toLabel: string | TFunction;
    fromNotGreaterThanTo: string | TFunction;
    minimumEndDate?: MinimumDateOptions;
}

export const DateRangeSchema = ({
    fromLabel,
    fromNotGreaterThanTo,
    toLabel,
    minimumEndDate,
}: DateRangeOptions): SchemaOf<ISOStringDateRange> =>
    yup
        .object({
            from: NullableDatePickerValueSchema({}),
            to: NullableDatePickerValueSchema({minimumDate: minimumEndDate}),
        })
        .test(function TestDateRange() {
            const {path, createError, parent: value} = this;

            const typed = value as ISOStringDateRange;
            if (!value || !typed.to || !typed.from) {
                return true;
            }

            const to = moment(typed.to.date);
            const from = moment(typed.from.date);

            if (to.isSameOrAfter(from)) {
                return true;
            }
            return createError({path, message: fromNotGreaterThanTo});
        })
        .required() as SchemaOf<ISOStringDateRange>;

export const useDateRangeSchema = ({fromLabel, toLabel, fromNotGreaterThanTo, minimumEndDate}: DateRangeOptions) =>
    useMemo(
        () =>
            DateRangeSchema({
                fromLabel,
                toLabel,
                fromNotGreaterThanTo,
                minimumEndDate,
            }),
        [fromLabel, toLabel, fromNotGreaterThanTo, minimumEndDate]
    );
