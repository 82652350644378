import {RoutesDTO} from '@growthbase/spa';
import {createLegacyRouteHooks} from './Hook';
import {RouteHooks} from './Value';
import {LegacyCreateRoutesResults, LegacyRoutesConfig} from './Value/LegacyCreateRoutesResults';

export function createLegacyRoutes<
    TRoutesKey extends keyof RoutesDTO,
    TRoutes extends RoutesDTO[TRoutesKey],
    TConfigs extends LegacyRoutesConfig<TRoutes>
>(routeKey: TRoutesKey, configs: TConfigs): LegacyCreateRoutesResults<TConfigs> {
    const results: Record<string, RouteHooks<never, never>> = {};
    Object.entries(configs).forEach(([name, config]) => {
        const key = name as keyof RoutesDTO[TRoutesKey];
        // @ts-expect-error should fix type hint.
        results[name] = createLegacyRouteHooks<TRoutesKey, typeof key, never, never>(routeKey, key, config);
    });
    return {
        ...results,
    } as unknown as LegacyCreateRoutesResults<TConfigs>;
}
