import classNames from 'classnames/bind';
import {useCallback} from 'react';
import styles from './CalendarSelectionItem.module.scss';

const cx = classNames.bind(styles);

export interface CalendarSelectionItem {
    id: string;
    title: string;
    color: string;
    enabled?: boolean;
    selected?: boolean;
    // eslint-disable-next-line react/no-unused-prop-types
    selectable?: boolean;
    // eslint-disable-next-line react/no-unused-prop-types
    employeeId: string;
}

export interface CalendarSelectionItemProps extends CalendarSelectionItem {
    onClickLabel?: (id: string) => void;
    onClickBullet?: (id: string) => void;
    isGroup?: boolean;
}

export const CalendarSelectionItem = ({
    id,
    title,
    color,
    onClickBullet,
    onClickLabel,
    enabled,
    selected,
    isGroup,
}: CalendarSelectionItemProps) => {
    const style = {'--color': color} as React.CSSProperties;

    const onClickBulletHandler = useCallback(() => {
        if (onClickBullet) {
            onClickBullet(id);
        }
    }, [id, onClickBullet]);

    const onClickLabelHandler = useCallback(() => {
        if (onClickLabel) {
            onClickLabel(id);
        }
    }, [id, onClickLabel]);

    const classes = cx('calendarSelectionItem', {
        'calendarSelectionItem--enabled': enabled,
        'calendarSelectionItem--selected': selected,
        'calendarSelectionItem--group': isGroup,
    });

    return (
        <div className={classes} style={style}>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button type="button" className={cx('calendarSelectionItem__bulletButton')} onClick={onClickBulletHandler}>
                <div className={cx('calendarSelectionItem__bullet')} />
            </button>
            <button type="button" className={cx('calendarSelectionItem__label')} onClick={onClickLabelHandler}>
                {title}
            </button>
        </div>
    );
};
