import {
    BaseButtonProps,
    Buttons,
    DateRangePicker,
    HoverIcon,
    NullableISOStringDateRange,
    PopupAnchorProps,
} from '@growthbase/design-components';
import {useCallback, FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useNow} from '@growthbase/spa';
import {usePlanningText} from './hook/usePlanningText';
import {ToolTipContent} from './components/ToolTipContent';

export interface TaakPlanningButtonProps {
    disabled: boolean;
    readonly?: boolean;
    asText?: boolean;
    value?: NullableISOStringDateRange;
    onSubmit: (range: NullableISOStringDateRange) => Promise<void>;
    AddButton?: FC<BaseButtonProps>;
    ModifyButton?: FC<BaseButtonProps>;
    textType?: 'deadline' | 'begindatum';
}

export const TaakPlanningButton: FC<TaakPlanningButtonProps> = ({
    value,
    onSubmit,
    disabled,
    readonly,
    asText,
    AddButton: ButtonCalendarAdd = Buttons.CalendarPlusIconOnly,
    ModifyButton: ButtonCalendarModify = Buttons.CalendarIconDotted,
    textType,
}) => {
    const {t} = useTranslation('spa_form.nl');
    const currentDateTime = useNow();

    const text = usePlanningText(asText ? textType ?? 'deadline' : undefined, value);

    const haveData = !!(value?.from || value?.to);

    const DefaultButton = haveData ? ButtonCalendarModify : ButtonCalendarAdd;

    const button = useCallback(
        ({reference, controls: {open, isOpen}}: PopupAnchorProps) => {
            if (!asText && readonly) {
                return null;
            }
            const showAsText = asText && text;
            const Button = showAsText ? Buttons.Link : DefaultButton;
            const tooltip = haveData ? <ToolTipContent date={value} /> : t('taken.TaakPlanningButton.add');
            const rendered = (
                <Button
                    ariaLabel={t('taak.table.deadline')}
                    ref={reference}
                    active={isOpen}
                    onClick={open}
                    tooltip={tooltip}
                >
                    {text}
                </Button>
            );

            if (!showAsText || readonly) {
                return rendered;
            }

            return <HoverIcon>{rendered}</HoverIcon>;
        },
        [
            asText,
            readonly,
            text,
            DefaultButton,
            t,
            haveData,
            value,
        ]
    );

    if (readonly && !haveData) {
        return null;
    }

    return (
        <DateRangePicker
            renderAnchor={button}
            disabled={disabled}
            readonly={readonly}
            value={value}
            labels={{
                fromLabel: t('taken.TaakPlanningButton.from') ?? '',
                toLabel: t('taken.TaakPlanningButton.to') ?? '',
                fromNotGreaterThanTo: t('taken.TaakPlanningButton.fromNotGreaterThanTo') ?? '',
            }}
            header={t('taak.table.planning')}
            onSubmit={onSubmit}
            minimumEndDate={{
                date: () => {
                    const date = currentDateTime();
                    date.setSeconds(date.getSeconds() - 15);
                    return date;
                },
                errorMessage: t('taken.TaakPlanningButton.DeadlineMoetInDeToekomstLiggen') ?? '',
            }}
        />
    );
};
