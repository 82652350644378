import {createMentionPlugin} from '@udecode/plate';
import {TComboboxItem} from '@udecode/plate-combobox/dist/types';
import {FC} from 'react';
import {GebruikerMention} from './GebruikerMention';
import {
    ComboboxGebruiker,
    GebruikersComboboxConnected,
    GebruikersComboboxStub,
    GebruikersMentionKey,
    GebruikersMentionTrigger,
} from './GebruikersCombobox';

const createGebruikersMentionPlugin = (searchBar: FC) =>
    createMentionPlugin({
        key: GebruikersMentionKey,
        component: GebruikerMention,
        renderAfterEditable: searchBar,
        options: {
            createMentionNode: (mention) => ({
                gebruiker: (mention as TComboboxItem<ComboboxGebruiker>).data,
                children: [{text: ''}],
                type: GebruikersMentionKey,
                gebruikerId: mention.key,
            }),
            id: GebruikersMentionKey,
            trigger: GebruikersMentionTrigger,
        },
    });

export const createGebruikersMentionPluginStub = () => createGebruikersMentionPlugin(GebruikersComboboxStub);
export const createGebruikersMentionPluginConnected = () => createGebruikersMentionPlugin(GebruikersComboboxConnected);
