import React, {FC} from 'react';
import {ClientboxNavbarActionButton} from '@growthbase/design-components';
import {TakenRoutes} from '@growthbase/taken';
import {useTranslation} from 'react-i18next';

export const NavbarMaakTaakActionButtonConnected: FC = () => {
    const {t} = useTranslation('spa_form.nl');
    const create = TakenRoutes.create.useNavigate();
    return (
        <ClientboxNavbarActionButton target="taken-navbar-create-button" onClick={create} buttonText={t('taak.add')} />
    );
};
