import {FC, LegacyRef, ReactNode} from 'react';
import classNames from 'classnames/bind';
import styles from './SwimlaneBody.module.scss';

const cx = classNames.bind(styles);

export interface SwimlaneBodyProps {
    children: ReactNode;
    innerRef?: LegacyRef<never> | undefined;
}

export const SwimlaneBody: FC<SwimlaneBodyProps> = ({children, innerRef}) => (
    <div ref={innerRef} className={styles.swimlaneBody}>
        <div className={cx('swimlaneBody__content')}>{children}</div>
    </div>
);

SwimlaneBody.displayName = 'Body';
