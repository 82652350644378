import {KeyHandler} from 'hotkeys-js';
import {FC} from 'react';
import {useKeys, UseKeysOptions} from '../Hook';

export interface KeysProps extends UseKeysOptions {
    debug: string;
    keys: string;
    callback: KeyHandler;
}

export const Keys: FC<KeysProps> = ({callback, debug, keys, ...options}) => {
    useKeys(debug, keys, callback, options);
    return null;
};
