import {FC, ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {TabRouteInterface} from '../TabInterface';

export const RouteTabLink: FC<{
    children?: ReactNode;
    tab: TabRouteInterface;
}> = ({tab, children}) => {
    const {to, replace = true} = tab;
    return (
        <Link to={to} replace={replace}>
            {children}
        </Link>
    );
};
