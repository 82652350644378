import {ReactNode} from 'react';
import {Tab, Tabs, TabsRendererMarginLeft} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useCallbackRef} from '@growthbase/spa';
import {AgendaTab} from '../../Tabs';
import {PlanningStoreState, usePlanningSetActiveTab} from '../../Redux';
import {useCurrentView} from '../../_hooks';

export interface PlanningSidebarProps {
    opdrachtenTab?: ReactNode;
    budgetTab?: ReactNode;
}

export const PlanningSidebar = ({opdrachtenTab, budgetTab}: PlanningSidebarProps) => {
    const {t} = useTranslation('spa.nl');
    const currentView = useCurrentView();
    const {opdrachtenSidebarEnabled} = useSelector((state: PlanningStoreState) => state.planning);
    const toonOpdrachtenSidebar = currentView.group !== 'capaciteit' && opdrachtenSidebarEnabled;
    const setActiveTab = usePlanningSetActiveTab();

    const onChangeQuery = useCallbackRef((value: string) => {
        setActiveTab({
            tab: value,
        });
    });

    return (
        <Tabs
            render={TabsRendererMarginLeft}
            query={{defaultValue: 'agendas', name: 'sbo'}}
            onQueryChange={onChangeQuery}
        >
            <Tab title={t('planning.agendasTab')} value="agendas">
                <AgendaTab />
            </Tab>
            {toonOpdrachtenSidebar && (
                <Tab title={t('planning.opdrachtenTab')} value="opdrachten">
                    {opdrachtenTab}
                </Tab>
            )}
            {toonOpdrachtenSidebar && budgetTab && (
                <Tab title={t('planning.inboxTab')} value="inbox">
                    {budgetTab}
                </Tab>
            )}
        </Tabs>
    );
};
