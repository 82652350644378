import {ReactNode} from 'react';

export interface GridLayoutProps {
    columns: number;
    verticalGap?: number;
    horizontalGap?: number;
    children: ReactNode;
}

export const GridLayout = ({children, columns, verticalGap = 20, horizontalGap = 20}: GridLayoutProps) => {
    const styles = {
        grid: {
            display: 'grid',
            gridTemplateColumns: `repeat(${columns}, auto)`,
            columnGap: horizontalGap,
            rowGap: verticalGap,
        },
    };
    return <div style={styles.grid}>{children}</div>;
};
