import {FC} from 'react';
import {IBewerkLijstActionsMenuButtonFragment} from '@growthbase/graphql';
import {BewerkLijstActionsMenuButton} from './BewerkLijstActionsMenuButton';

export interface BewerkLijstActionsMenuButtonConnectedProps {
    fragment?: IBewerkLijstActionsMenuButtonFragment;
}

export const BewerkLijstActionsMenuButtonConnected: FC<BewerkLijstActionsMenuButtonConnectedProps> = ({fragment}) => {
    if (!fragment) {
        return null;
    }
    const {id, bord} = fragment;
    return <BewerkLijstActionsMenuButton lijstId={id} bordId={bord?.id ?? null} />;
};
