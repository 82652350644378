import React, {ReactNode, useEffect} from 'react';
import {
    Eventcalendar,
    localeNl,
    MbscCalendarEventData,
    MbscEventClickEvent,
    MbscPageLoadingEvent,
    momentTimezone,
} from '@mobiscroll/react';
import {useCallbackRef} from '@growthbase/spa';
import {
    CalendarDate,
    CalendarEvent,
    CalendarWrapper,
    EventPopupTarget,
    Icons,
    Loader,
} from '@growthbase/design-components';
import {useBoolean} from 'react-use';
import type {MbscCalendarDayData} from '@mobiscroll/react/dist/src/core/shared/calendar-view/calendar-day';
import moment from 'moment/moment';
import {EventcalendarBase} from '@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar';
import {
    MbscEventCreateEvent,
    MbscNewEventData,
} from '@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar.types.public';
import {MbscCalendarEvent} from '@mobiscroll/react/dist/src/core/shared/calendar-view/calendar-view.types';
import {useSelector} from 'react-redux';
import {DateType} from '@mobiscroll/react/dist/src/core/util/datetime';
import {useTranslation} from 'react-i18next';
import {PlanningViews} from './PlanningViewFormats';
import {EventBackground, EventResource, EventType} from '../types';
import {useUpdateEventAction} from '../../_hooks/useUpdateEventAction';
import {useSimpleMobiEventAction} from '../../_hooks/useSimpleEventAction';
import {PlanningContextMenu, usePlanningContextMenu} from '../../Components';
import {PlanningStoreState, usePlanningSetViewedDateRange} from '../../Redux';
import {useBackgroundEventForDisplay} from '../../_hooks/useBackgroundEventForDisplay';
import {useCurrentView, useRenderHeader, useOnChangeDate, AgendaModuleType} from '../../_hooks';
import {useApplyEventColors} from '../../_hooks/useApplyEventColors';

export interface StrokenPlanningData {
    events: EventType[];
    resources: EventResource[];
    backgroundEvents?: EventBackground[];
}

export interface PlanningViewProps {
    onRequestData?: (event: {startDate: Date; endDate: Date}) => void;
    data?: StrokenPlanningData;
    loading?: boolean;
    onEventCreate?: (args: MbscEventCreateEvent, inst: EventcalendarBase) => void;
    onShowPopupForEvent?: (args: EventPopupTarget) => void;
    onClosePopupForEvent?: () => void;
    extendDefaultEvent?: (args: MbscNewEventData) => MbscCalendarEvent;
    contextMenu?: ReactNode;
    module?: AgendaModuleType;
}

export const PlanningView = ({
    onRequestData,
    data,
    loading,
    onEventCreate,
    extendDefaultEvent,
    onShowPopupForEvent,
    onClosePopupForEvent,
    module,
    contextMenu = <PlanningContextMenu />,
}: PlanningViewProps) => {
    const {
        hash,
        date: selectedDate,
        creationDateRestrictions,
        viewedDateRange,
    } = useSelector((state: PlanningStoreState) => state.planning);

    const currentViewFormat = useCurrentView();
    const [localLoading, setLocalLoading] = useBoolean(true);
    const onEventEditCallback = useUpdateEventAction(setLocalLoading);
    const onOpenEvent = useSimpleMobiEventAction('planningEventOpenModal');

    const {openMenu} = usePlanningContextMenu();
    const setViewedDateRange = usePlanningSetViewedDateRange();
    const backgroundEvents = useBackgroundEventForDisplay(data?.backgroundEvents ?? []);
    const renderHeader = useRenderHeader(PlanningViews, module);
    const onDateChange = useOnChangeDate();
    const {t} = useTranslation('spa.nl');

    const invalidDates: DateType[] = creationDateRestrictions.map(({start, end}) => ({
        start: new Date(start),
        end: new Date(end),
    }));

    useEffect(() => {
        setLocalLoading(false);
    }, [data, setLocalLoading]);

    const onPageLoading = useCallbackRef((event: MbscPageLoadingEvent) => {
        setTimeout(() => {
            setViewedDateRange({
                start: event.firstDay,
                end: event.lastDay,
            });
        }, 0);
    });

    useEffect(() => {
        if (onRequestData && viewedDateRange) {
            onRequestData({
                startDate: viewedDateRange.start,
                endDate: viewedDateRange.end,
            });
        }
    }, [viewedDateRange, onRequestData, hash]);

    const renderDay = useCallbackRef(({date}: MbscCalendarDayData) => (
        <CalendarDate small={currentViewFormat.smallDates} date={date} />
    ));

    const renderEvent = useCallbackRef((event: MbscCalendarEventData) => {
        const {html, startDate, endDate, start, end, color, allDay} = event;
        const duration = moment.duration(moment(endDate.toISOString()).diff(moment(startDate.toISOString())));
        const durationString = moment.utc(duration.asMilliseconds()).format('H:mm');
        const info = allDay ? t('planning.heleDag') : `${start} - ${end} (${durationString} uur)`;
        return (
            <CalendarEvent info={info} backgroundColor={color}>
                {html}
            </CalendarEvent>
        );
    });

    const onEventClick = useCallbackRef((event: MbscEventClickEvent) => {
        const domEvent = event.domEvent as MouseEvent;
        if (onShowPopupForEvent && event.event.editable) {
            onShowPopupForEvent({
                eventDomElement: domEvent.currentTarget as HTMLElement,
                externalEventId: event.event.id as string,
            });
        }
    });

    const onEventDoubleClick = useCallbackRef((event: MbscEventClickEvent) => {
        onOpenEvent(event);
        if (onClosePopupForEvent) {
            onClosePopupForEvent();
        }
    });

    const onEventRightClick = useCallbackRef((event: MbscEventClickEvent) => {
        openMenu(event.domEvent as MouseEvent, event.event as EventType);
    });

    const events = useApplyEventColors(data?.events);

    return (
        <>
            {contextMenu}
            {(localLoading || loading) && <Loader fixed testId="planningLoading" />}
            <CalendarWrapper>
                <Eventcalendar
                    // Theme
                    theme="ios"
                    themeVariant="light"
                    height="100%"
                    renderHeader={renderHeader}
                    renderDay={renderDay}
                    renderScheduleEvent={renderEvent}
                    // Locale
                    selectedDate={selectedDate}
                    locale={localeNl}
                    returnFormat="jsdate"
                    timeFormat="HH:mm"
                    timezonePlugin={momentTimezone}
                    displayTimezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                    // Data
                    view={currentViewFormat.view}
                    data={events}
                    invalid={invalidDates}
                    resources={data?.resources}
                    colors={backgroundEvents}
                    extendDefaultEvent={extendDefaultEvent}
                    // Event listeners
                    onSelectedDateChange={(event) => onDateChange(event.date as Date)}
                    onEventCreate={onEventCreate}
                    onEventClick={onEventClick}
                    onEventUpdate={onEventEditCallback}
                    onEventDoubleClick={onEventDoubleClick}
                    onEventRightClick={onEventRightClick}
                    onPageLoading={onPageLoading}
                    // Drag, Drop, Resize
                    dragTimeStep={15}
                    dragToCreate={!loading}
                    dragToMove={!loading}
                    dragToResize={!loading}
                    clickToCreate={!loading}
                    externalDrop
                    actionableEvents
                    // @ts-expect-error string expected, react element given
                    todayText={<Icons.Calendar />}
                />
            </CalendarWrapper>
        </>
    );
};
