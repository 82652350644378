import {useToggleDeelnameAanTaakByKeyboard} from './ToggleDeelnameAanTaak/Hook/useToggleByKeyboard';

export interface KeyboardShortcutsOptions {
    gebruikerId: string | null;
}

export const useKeyboardShortcuts = ({gebruikerId}: KeyboardShortcutsOptions) => {
    const {loading} = useToggleDeelnameAanTaakByKeyboard({gebruikerId});

    return {
        loading,
    };
};
