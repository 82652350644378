import {useCallback} from 'react';
import {
    IBordtemplateMetLijstenBordLijstFragment,
    IBordtemplateMetLijstenQuery,
    IBordtemplateMetLijstenQueryVariables,
    useBordtemplateLijstDropMutation,
} from '@growthbase/graphql';
import {DropItemHandlerEvent} from '@growthbase/keys';
import {idFromIri} from '@growthbase/routing';
import {useDropQueryCacheHandlers} from '@growthbase/design-components';
import {QueryResult} from '@apollo/client';
import {BordMetLijstenData} from '../View';

export const useLijstDrop = (
    result: QueryResult<IBordtemplateMetLijstenQuery, IBordtemplateMetLijstenQueryVariables>
) => {
    const [mutation] = useBordtemplateLijstDropMutation();
    const onDrop = useCallback(
        (event: DropItemHandlerEvent<IBordtemplateMetLijstenBordLijstFragment, BordMetLijstenData>) => {
            const itemThatIsBeforeId = event.placement.itemThatIsBefore?.id;
            const itemThatIsAfterId = event.placement.itemThatIsAfter?.id;
            return mutation({
                variables: {
                    input: {
                        id: idFromIri(event.overContainer.id),
                        lijsttemplateId: idFromIri(event.item.id),
                        lijsttemplatepositie: {
                            bovenLijsttemplateId: itemThatIsAfterId ? idFromIri(itemThatIsAfterId) : undefined,
                            onderLijsttemplateId: itemThatIsBeforeId ? idFromIri(itemThatIsBeforeId) : undefined,
                        },
                    },
                },
            }).then(() => undefined);
        },
        [mutation]
    );
    return useDropQueryCacheHandlers({
        onDrop,
        edgeTypeName: 'Takentemplate_Lijsttemplate_LijsttemplateEdge',
        nodeTypeName: 'Takentemplate_Lijsttemplate_Lijsttemplate',
        updateQuery: result.updateQuery,
    }).onDrop;
};
