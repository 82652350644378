import {Action} from 'redux';
import {debounceTime, EMPTY, filter, merge, mergeMap, Observable} from 'rxjs';
import {
    IZetActieveAgendaMutation,
    IZetActieveAgendaMutationVariables,
    IZetActieveAgendasMutation,
    IZetActieveAgendasMutationVariables,
    IZetPlanningGroepeerOpMutation,
    IZetPlanningGroepeerOpMutationVariables,
    IZetPlanningTijdMutation,
    IZetPlanningTijdMutationVariables,
    IZetPlanningWeergaveMutation,
    IZetPlanningWeergaveMutationVariables,
    IZetPlanningWeergaveTypeMutation,
    IZetPlanningWeergaveTypeMutationVariables,
    PlanningActions,
    PlanningStoreState,
    ZetActieveAgendaDocument,
    ZetActieveAgendasDocument,
    ZetPlanningGroepeerOpDocument,
    ZetPlanningTijdDocument,
    ZetPlanningWeergaveDocument,
    ZetPlanningWeergaveTypeDocument,
} from '@growthbase/planning';
import {ApolloClient, NormalizedCacheObject} from '@apollo/client';

const apolloOptions = {context: {endpoint: 'planning'}} as const;

export const planningSaveStateEpic = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
    let socketId: string | null = null;

    return (actions$: Observable<Action>, state$: Observable<PlanningStoreState>): Observable<Action> =>
        merge(
            actions$.pipe(
                filter(PlanningActions.setSocketId.match),
                mergeMap(({payload, type}) => {
                    socketId = payload.socketId;
                    return EMPTY;
                })
            ),
            actions$.pipe(
                filter(PlanningActions.setTimePreference.match),
                filter(({payload}) => !payload.localOnly),
                mergeMap(({payload, type}) => {
                    apolloClient.mutate<IZetPlanningTijdMutation, IZetPlanningTijdMutationVariables>({
                        ...apolloOptions,
                        mutation: ZetPlanningTijdDocument,
                        variables: {
                            dagBeginTijd: payload.timeStart,
                            dagEindTijd: payload.timeEnd,
                            eersteDag: payload.dayStart,
                            laatsteDag: payload.dayEnd,
                            socketId,
                        },
                    });
                    return EMPTY;
                })
            ),
            actions$.pipe(
                filter(PlanningActions.setActiveViewGroup.match),
                filter(({payload}) => !payload.localOnly),
                filter(({payload}) => payload.groupId !== 'agenda-agenda'),
                debounceTime(1000),
                mergeMap(({payload, type}) => {
                    apolloClient
                        .mutate<IZetPlanningWeergaveMutation, IZetPlanningWeergaveMutationVariables>({
                            ...apolloOptions,
                            mutation: ZetPlanningWeergaveDocument,
                            variables: {
                                weergave: payload.groupId,
                                socketId,
                            },
                        })
                        .then(() => {
                            if (payload.groupId === 'lijst') {
                                window.location.reload();
                            }
                        });
                    return EMPTY;
                })
            ),
            actions$.pipe(
                filter(PlanningActions.setPreferredViewForGroup.match),
                filter(({payload}) => !payload.localOnly),
                filter(({payload}) => payload.groupId !== 'agenda-agenda'),
                debounceTime(1000),
                mergeMap(({payload, type}) => {
                    apolloClient.mutate<IZetPlanningWeergaveTypeMutation, IZetPlanningWeergaveTypeMutationVariables>({
                        ...apolloOptions,
                        mutation: ZetPlanningWeergaveTypeDocument,
                        variables: {
                            weergave: payload.groupId,
                            type: payload.viewId,
                            socketId,
                        },
                    });

                    return EMPTY;
                })
            ),
            actions$.pipe(
                filter(PlanningActions.setGroupBy.match),
                filter(({payload}) => !payload.localOnly),
                debounceTime(1000),
                mergeMap(({payload}) => {
                    apolloClient.mutate<IZetPlanningGroepeerOpMutation, IZetPlanningGroepeerOpMutationVariables>({
                        ...apolloOptions,
                        mutation: ZetPlanningGroepeerOpDocument,
                        variables: {
                            groepeerOp: payload.groupBy,
                            socketId,
                        },
                    });
                    return EMPTY;
                })
            ),
            actions$.pipe(
                filter(PlanningActions.setActiveAgenda.match),
                filter(({payload}) => !payload.localOnly),
                debounceTime(1000),
                mergeMap(({payload}) => {
                    apolloClient.mutate<IZetActieveAgendaMutation, IZetActieveAgendaMutationVariables>({
                        ...apolloOptions,
                        mutation: ZetActieveAgendaDocument,
                        variables: {
                            agendaId: payload.agenda.id,
                            socketId,
                        },
                    });
                    return EMPTY;
                })
            ),
            actions$.pipe(
                filter(PlanningActions.setActiveAgendas.match),
                filter(({payload}) => !payload.localOnly),
                debounceTime(1000),
                mergeMap(({payload, type}) => {
                    localStorage.setItem(
                        'planningTimeRegistrations',
                        payload.activeAgendas.includes('time-registrations') ? 'yes' : 'no'
                    );
                    apolloClient.mutate<IZetActieveAgendasMutation, IZetActieveAgendasMutationVariables>({
                        ...apolloOptions,
                        mutation: ZetActieveAgendasDocument,
                        variables: {
                            agendaIds: payload.activeAgendas,
                            socketId,
                        },
                    });
                    return EMPTY;
                })
            )
        );
};
