import {FC} from 'react';
import {
    IBordArchiveerButtonFragment,
    useBordArchiveerButtonArchiveerMutation,
    useBordArchiveerButtonDearchiveerMutation,
} from '@growthbase/graphql';
import {BaseButtonProps, Button as DefaultButton, ViewConfig} from '@growthbase/design-components';
import {ArchiveerButton} from './ArchiveerButton';

export interface ArchiveerButtonConnectedProps extends ViewConfig {
    fragment?: IBordArchiveerButtonFragment;
    Button?: FC<BaseButtonProps>;
}

export const ArchiveerButtonConnected: FC<ArchiveerButtonConnectedProps> = ({
    fragment,
    Button = DefaultButton,
    disabled,
    readonly,
}) => {
    const variables = {id: fragment?.id ?? ''};
    const [archive, {loading: loadingArchive}] = useBordArchiveerButtonArchiveerMutation({variables});
    const [unarchived, {loading: loadingUnarchived}] = useBordArchiveerButtonDearchiveerMutation({variables});
    return (
        <ArchiveerButton
            kanArchiveren={fragment?.availableActions.kanArchiveren ?? false}
            isArchived={fragment?.gearchiveerd}
            onClick={() => (fragment?.gearchiveerd ? unarchived().then() : archive().then())}
            isSubmitting={loadingArchive || loadingUnarchived}
            Button={Button}
            disabled={disabled}
            readonly={readonly}
        />
    );
};
