import {
    ITaakBijlageFragment,
    ITaakBijlagesFragment,
    useTaakBijlagesLazyQuery,
    useVeranderPositieVanBijlageMutation,
} from '@growthbase/graphql';
import {FC, ReactNode, useCallback, useMemo} from 'react';
import {Bestand, useBestandSlideshow} from '@growthbase/bestandsbeheer';
import {useCallbackRef} from '@growthbase/spa';
import {onDropItemHandler} from '@growthbase/keys';
import {idFromIri} from '@growthbase/routing';
import {DropZoneChildren} from '@growthbase/design-elements';
import {BijlageContainer, Bijlages} from './Bijlages';
import {useUploadBijlages} from './Hook/useUploadBijlages';
import {useEntityChanges} from './Hook/useEntityChanges';

import {BijlageConnected} from '../Bijlage';
import {useTaakBijlagesEnabled} from '../Hook/useTaakBijlagesEnabled';

export interface BijlagesConnectedProps {
    fragment?: ITaakBijlagesFragment | null;
    children: (props: DropZoneChildren & {bijlages: ReactNode; kanToevoegen: boolean}) => ReactNode;
}

export const BijlagesConnected: FC<BijlagesConnectedProps> = ({fragment, children}) => {
    const {id, bijlages} = fragment ?? {};
    const taakId = id ? idFromIri(id) : '';

    const enabled = useTaakBijlagesEnabled();
    const {onDrop} = useUploadBijlages({
        taakId,
    });

    const [query] = useTaakBijlagesLazyQuery({
        variables: {
            taakId,
        },
    });

    useEntityChanges({
        taakId,
    });

    const items = useMemo(() => bijlages ?? [], [bijlages]);
    const renderer = useCallbackRef((node: ITaakBijlageFragment) => <BijlageConnected key={node.id} fragment={node} />);

    const [verander] = useVeranderPositieVanBijlageMutation();
    const onMove: onDropItemHandler<ITaakBijlageFragment, BijlageContainer> = useCallbackRef(async (event) => {
        await verander({
            variables: {
                input: {
                    bijlageId: event.item.id,
                    taakId: event.overContainer.taakId,
                    boven: event.placement.itemThatIsAfter ? event.placement.itemThatIsAfter.id : null,
                    onder: event.placement.itemThatIsBefore ? event.placement.itemThatIsBefore.id : null,
                },
            },
        });
    });
    const {slideshow} = useBestandSlideshow({
        id: `Bijlages-${taakId}`,
        load: useCallback(
            async () =>
                query({
                    fetchPolicy: 'network-only',
                    nextFetchPolicy: 'network-only',
                }).then((result) => {
                    const fetched = result.data?.taak?.bijlages;
                    if (!fetched) {
                        return [];
                    }
                    return fetched.map(({bestand}) => bestand).filter((bestand) => !!bestand.url) as Bestand[];
                }),
            [query]
        ),
        render: (
            <Bijlages<ITaakBijlageFragment>
                onMove={onMove}
                taakId={taakId}
                renderBijlage={renderer}
                bijlages={items}
                onDrop={onDrop}
                kanToevoegen={fragment?.availableActions?.kanBijlageAanmaken ?? false}
            >
                {children}
            </Bijlages>
        ),
    });

    if (!enabled) {
        return (
            <>
                {children({
                    bijlages: null,
                    kanToevoegen: false,
                })}
            </>
        );
    }

    if (!fragment) {
        return (
            <>
                {children({
                    bijlages: null,
                    kanToevoegen: false,
                })}
            </>
        );
    }
    return <>{slideshow}</>;
};
