import {faker} from '@faker-js/faker';
import {IOpgemaakteTekstEditorFragment, ISlateJsType} from '@growthbase/graphql';
import {range} from 'lodash';
import {OpgemaakteTekst} from '../Hook/useOpgemaakteTekstSchema';
import {normalizeOpgemaakteTekstToGraphQLInput} from './OpgemaakteTekstExampleValue';

export * from './AsyncCombobox';
export * from './OpgemaakteTekstEditor';
export * from './OpgemaakteTekstInput';
export * from './OpgemaakteTekstExampleValue';
export * from './ReadonlyOpgemaakteTekst/ReadonlyOpgemaakteTekst';
export * from './useOpgemaakteTekstIsEmpty';

export const createOpgemaakteTekstEditorFragment = (count = 5): IOpgemaakteTekstEditorFragment => {
    faker.seed(1);

    const opgemaakteTekst: OpgemaakteTekst = {
        elements: range(0, count).map(() => ({
            type: ISlateJsType.P,
            children: [
                {
                    type: ISlateJsType.Text,
                    text: faker.lorem.paragraph(),
                },
            ],
        })),
    };

    return normalizeOpgemaakteTekstToGraphQLInput(opgemaakteTekst);
};
