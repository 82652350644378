import {FC, PropsWithChildren, useId} from 'react';
import {useRendersCount} from 'react-use';

const {search} = window.location;
const enabled = search.includes('debug');

export const DebugRenderCounter: FC<PropsWithChildren & {name?: string; alwaysVisible?: boolean}> = ({
    children,
    name,
    alwaysVisible,
}) => {
    if (!enabled && !alwaysVisible && !search.includes(`debug=&`) && !search.includes(`debug=${name}`)) {
        return null;
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const count = useRendersCount();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const id = useId();
    return (
        <>
            {children ?? name}:{count} ({id})
        </>
    );
};
