import {useKeys} from '@growthbase/keys';
import {MutableRefObject, RefObject, useCallback} from 'react';

export interface HandleResetOnEscapeKeyPressProps {
    onClear: () => void;
    activeQuery: boolean;
}

export const useHandleResetOnEscapeKeyPress = <T extends HTMLInputElement>({
    onClear,
    activeQuery,
}: HandleResetOnEscapeKeyPressProps): MutableRefObject<T | null> =>
    useKeys(
        'SearchForm: reset on escape key press',
        'escape',
        useCallback(
            (event: KeyboardEvent) => {
                if (!activeQuery) {
                    (event.target as HTMLInputElement).blur();
                    return;
                }
                event.stopPropagation();
                onClear();
            },
            [activeQuery, onClear]
        ),
        {
            enableOnInput: true,
        }
    ) as RefObject<T>;
