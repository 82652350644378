import {
    determineActionTypeWhenSearching,
    PositieType,
    QueryCacheUpdateType,
    useConvertFetchEntity,
    useUpdateQueryCacheFromExternalEntityChanges,
} from '@growthbase/cache';
import {
    IBordenTemplatesConnectedFragment,
    refetchBordentemplateConnectedEntryQuery,
    ResultData,
} from '@growthbase/graphql';
import {QueryResult} from '@apollo/client';
import {OverviewFilters} from '../types';

export const useUpdateFromExternalEntityChanges = ({
    result,
    filters,
}: {
    result: QueryResult<ResultData<IBordenTemplatesConnectedFragment>>;
    filters: OverviewFilters;
}) => {
    useUpdateQueryCacheFromExternalEntityChanges<IBordenTemplatesConnectedFragment>({
        result,
        determineAction: (params, action) => {
            const {typename} = params;
            if (typename !== 'Bordtemplate') {
                return QueryCacheUpdateType.ignore;
            }

            action = determineActionTypeWhenSearching(action, params, !!filters.searchQuery);

            return action;
        },
        positie: PositieType.alphabetically,
        fetchEntity: useConvertFetchEntity(refetchBordentemplateConnectedEntryQuery),
        addToQueryOptions: {
            edgeTypeName: 'BordtemplateEdge',
            nodeTypeName: 'Bordtemplate',
            orderBy: 'naam',
        },
    });
};
