import {useTranslation} from 'react-i18next';
import {GridLayout, ISOStringDateRange} from '@growthbase/design-components';
import {usePlanningText} from '../hook/usePlanningText';

export interface ToolTipContentProps {
    date: ISOStringDateRange;
}

export const ToolTipContent = ({date}: ToolTipContentProps) => {
    const {t} = useTranslation('spa.nl');

    const beginDatum = usePlanningText('begindatum', date);
    const deadline = usePlanningText('deadline', date);

    return (
        <GridLayout columns={2} verticalGap={3} horizontalGap={10}>
            {date.from && (
                <>
                    <div>{t('design.DateRangePicker.from')}:</div>
                    <div>{beginDatum}</div>
                </>
            )}
            {date.to && (
                <>
                    <div>{t('design.DateRangePicker.to')}:</div>
                    <div>{deadline}</div>
                </>
            )}
        </GridLayout>
    );
};
