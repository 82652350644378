import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {"context":{"endpoint":"planning"}} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type IActieveTijdRegistratie = {
  id: Scalars['ID'];
  secondsPassed: Scalars['Int'];
  taakId?: Maybe<Scalars['String']>;
  van: IDateTime;
};

export type IAfwijkendRooster = IRooster & {
  evenWerkweek: IWerkweek;
  id: Scalars['ID'];
  onevenWerkweek: IWerkweek;
};

export type IAgenda = {
  /** @deprecated TODO: Geen Engelse properties! */
  activeInPlanning: Scalars['Boolean'];
  agendaVanIngelogdeGebruiker: Scalars['Boolean'];
  /** De kleur van de agenda zoals ingesteld voor de huidig ingelogde gebruiker. */
  color: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type IBeschikbareActies = {
  planningOpdrachtenSidebarIsBeschikbaar: Scalars['Boolean'];
  planningUrenRegistratiesBetaIsBeschikbaar: Scalars['Boolean'];
  planningUrenRegistratiesIsBeschikbaar: Scalars['Boolean'];
};

export type IBudgetRegel = {
  dienst: IDienst;
  id: Scalars['ID'];
  onderwerp: Scalars['String'];
};

export type IBudgetRegelHouder = {
  code: Scalars['String'];
  id: Scalars['ID'];
  onderwerp: Scalars['String'];
  relatienaam: Scalars['String'];
};

export type IDag = {
  dag: IWeekdag;
  eindtijd: ITijd;
  pauzeduur: ITijd;
  starttijd: ITijd;
};

export type IDateTime = {
  iso8601: Scalars['String'];
};

export type IDateTimeInput = {
  iso8601: Scalars['String'];
  timezone: Scalars['String'];
};

export type IDateTimeRangeInput = {
  tot: IDateTimeInput;
  van: IDateTimeInput;
};

export type IDienst = {
  code: Scalars['String'];
  id: Scalars['ID'];
  resterendBudgetVoorOpdracht?: Maybe<IResterendBudgetVoorOpdracht>;
  titel: Scalars['String'];
};


export type IDienstResterendBudgetVoorOpdrachtArgs = {
  opdrachtId: Scalars['ID'];
};

export type IDienstConnection = {
  edges?: Maybe<Array<Maybe<IDienstEdge>>>;
  pageInfo: IDienstPageInfo;
  /** De totale hoeveelheid resultaten, ongeacht paginering; i.e. hoeveel items zijn er die voldoen aan de filters. */
  totalCount: Scalars['Int'];
};

export type IDienstEdge = {
  cursor: Scalars['String'];
  node?: Maybe<IDienst>;
};

export type IDienstPageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  startCursor?: Maybe<Scalars['String']>;
};

export type IEvenement = {
  agenda: IAgenda;
  agendaId: Scalars['ID'];
  csrfToken: Scalars['String'];
  dienst?: Maybe<IDienst>;
  eind: Scalars['String'];
  geschiktVoorUrenregistratie: Scalars['Boolean'];
  id: Scalars['ID'];
  onderwerp?: Maybe<Scalars['String']>;
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  opdracht?: Maybe<IOpdracht>;
  relatie?: Maybe<IRelatie>;
  start: Scalars['String'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  taak?: Maybe<Scalars['ID']>;
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  ticket?: Maybe<ITicket>;
  urenRegistratieId?: Maybe<Scalars['Int']>;
};

export type IIngelogdeGebruiker = {
  beschikbareActies: IBeschikbareActies;
};

export type IMedewerker = {
  actieveRooster?: Maybe<IRooster>;
  id: Scalars['ID'];
  volledigeNaam: Scalars['String'];
};

export type IMutation = {
  ActiveerEvenementMutation?: Maybe<IVoid>;
  MaakEvenementOpenbaarMutation?: Maybe<IVoid>;
  MaakEvenementPriveMutation?: Maybe<IVoid>;
  MaakEvenementVoorOpdracht?: Maybe<IVoid>;
  MaakEvenementVoorTicket?: Maybe<IVoid>;
  VeranderActieveAgendasMutation?: Maybe<IVoid>;
  VerwijderEvenementMutation?: Maybe<IVoid>;
  VerwijderUrenRegistratieVanIngelogdeGebruikerMutation?: Maybe<IVoid>;
  VoltooiEvenementMutation?: Maybe<IVoid>;
  ZetActieveAgendaMutation?: Maybe<IVoid>;
  ZetEvenementKleurMutation?: Maybe<IVoid>;
  ZetPlanningGroupByMutation?: Maybe<IVoid>;
  ZetPlanningTijdMutation?: Maybe<IVoid>;
  ZetPlanningWeergaveMutation?: Maybe<IVoid>;
  ZetPlanningWeergaveTypeMutation?: Maybe<IVoid>;
  ZetUrenRegistratieDatumsMutation?: Maybe<IVoid>;
};


export type IMutationActiveerEvenementMutationArgs = {
  evenementId: Scalars['ID'];
};


export type IMutationMaakEvenementOpenbaarMutationArgs = {
  evenementId?: InputMaybe<Scalars['ID']>;
};


export type IMutationMaakEvenementPriveMutationArgs = {
  evenementId?: InputMaybe<Scalars['ID']>;
};


export type IMutationMaakEvenementVoorOpdrachtArgs = {
  agendaId: Scalars['ID'];
  datums: Array<IStartEnEindDatumInput>;
  dienstId?: InputMaybe<Scalars['ID']>;
  opdrachtId: Scalars['ID'];
  toegewezenBudgetId?: InputMaybe<Scalars['String']>;
};


export type IMutationMaakEvenementVoorTicketArgs = {
  agendaId: Scalars['ID'];
  datums: Array<IStartEnEindDatumInput>;
  dienstId?: InputMaybe<Scalars['ID']>;
  ticketId: Scalars['ID'];
};


export type IMutationVeranderActieveAgendasMutationArgs = {
  agendaIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  socketId?: InputMaybe<Scalars['String']>;
};


export type IMutationVerwijderEvenementMutationArgs = {
  evenementId?: InputMaybe<Scalars['ID']>;
};


export type IMutationVerwijderUrenRegistratieVanIngelogdeGebruikerMutationArgs = {
  urenRegistratieLogId?: InputMaybe<Scalars['ID']>;
};


export type IMutationVoltooiEvenementMutationArgs = {
  evenementId: Scalars['ID'];
};


export type IMutationZetActieveAgendaMutationArgs = {
  agendaId: Scalars['ID'];
  socketId?: InputMaybe<Scalars['String']>;
};


export type IMutationZetEvenementKleurMutationArgs = {
  evenementId?: InputMaybe<Scalars['ID']>;
  kleur?: InputMaybe<Scalars['String']>;
};


export type IMutationZetPlanningGroupByMutationArgs = {
  group_by: Scalars['String'];
  socketId?: InputMaybe<Scalars['String']>;
};


export type IMutationZetPlanningTijdMutationArgs = {
  dagBeginTijd: Scalars['String'];
  dagEindTijd: Scalars['String'];
  eersteDag: Scalars['String'];
  laatsteDag: Scalars['String'];
  socketId?: InputMaybe<Scalars['String']>;
};


export type IMutationZetPlanningWeergaveMutationArgs = {
  socketId?: InputMaybe<Scalars['String']>;
  weergave: Scalars['String'];
};


export type IMutationZetPlanningWeergaveTypeMutationArgs = {
  socketId?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  weergave: Scalars['String'];
};


export type IMutationZetUrenRegistratieDatumsMutationArgs = {
  datums: IDateTimeRangeInput;
  urenRegistratieLogId?: InputMaybe<Scalars['ID']>;
};

export type INietAfwijkendRooster = IRooster & {
  id: Scalars['ID'];
  werkweek: IWerkweek;
};

export type IOpdracht = {
  /** @deprecated Gebruik Opdracht.nummer */
  code?: Maybe<Scalars['String']>;
  diensten?: Maybe<Array<Maybe<IDienst>>>;
  id: Scalars['ID'];
  /** @deprecated Gebruik Opdracht.relatienaam */
  name?: Maybe<Scalars['String']>;
  nummer: Scalars['String'];
  onderwerp: Scalars['String'];
  relatie?: Maybe<IRelatie>;
  /** De (historische) naam van de relatie op het punt dat de opdracht is aangemaakt. */
  relatienaam?: Maybe<Scalars['String']>;
  /** @deprecated Gebruik Opdracht.onderwerp */
  title?: Maybe<Scalars['String']>;
};


export type IOpdrachtDienstenArgs = {
  type?: InputMaybe<IOpdracht_Dienst_Type>;
};

export enum IOpdrachtCalculatieType {
  Budget = 'budget',
  Nacalculatie = 'nacalculatie'
}

export type IOpdrachtConnection = {
  edges?: Maybe<Array<Maybe<IOpdrachtEdge>>>;
  pageInfo: IOpdrachtPageInfo;
  /** De totale hoeveelheid resultaten, ongeacht paginering; i.e. hoeveel items zijn er die voldoen aan de filters. */
  totalCount: Scalars['Int'];
};

export type IOpdrachtEdge = {
  cursor: Scalars['String'];
  node?: Maybe<IOpdracht>;
};

export type IOpdrachtPageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  startCursor?: Maybe<Scalars['String']>;
};

export type IOpdrachtPlanStatistieken = {
  budget: Scalars['Int'];
  geboekt: Scalars['Int'];
  gepland: Scalars['Int'];
  resterend: Scalars['Int'];
};

export enum IOpdrachtstatus {
  Afgerond = 'Afgerond',
  InBehandeling = 'InBehandeling',
  Nieuw = 'Nieuw'
}

export type IPlanning = {
  id: Scalars['ID'];
  tijdblokken?: Maybe<Array<Maybe<ITijdblok>>>;
};


export type IPlanningTijdblokkenArgs = {
  periode: IDateTimeRangeInput;
};

export type IPlanningConnection = {
  edges?: Maybe<Array<Maybe<IPlanningEdge>>>;
  /** @deprecated Nog niet geïmplementeerd. */
  pageInfo: IPlanningInfo;
  /**
   * De totale hoeveelheid resultaten, ongeacht paginering; i.e. hoeveel items zijn er die voldoen aan de filters.
   * @deprecated Nog niet geïmplementeerd.
   */
  totalCount: Scalars['Int'];
};

export type IPlanningDay = {
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  dayType: IDayType;
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  end: Scalars['String'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  resource: Scalars['String'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  start: Scalars['String'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  timeAvailableInSeconds: Scalars['Int'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  timeAvailablePercentage: Scalars['Int'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  title: Scalars['String'];
};

export type IPlanningEdge = {
  /** @deprecated Nog niet geïmplementeerd. */
  cursor: Scalars['String'];
  node?: Maybe<IPlanning>;
};

export type IPlanningInfo = {
  /** @deprecated Nog niet geïmplementeerd. */
  endCursor?: Maybe<Scalars['String']>;
  /** @deprecated Nog niet geïmplementeerd. */
  hasNextPage?: Maybe<Scalars['Boolean']>;
  /** @deprecated Nog niet geïmplementeerd. */
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  /** @deprecated Nog niet geïmplementeerd. */
  startCursor?: Maybe<Scalars['String']>;
};

export type IPlanningItem = {
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  canEdit: Scalars['Boolean'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  canMoveToOtherCalendar: Scalars['Boolean'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  canResize: Scalars['Boolean'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  color?: Maybe<Scalars['String']>;
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  endDate: Scalars['String'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  hasUrenregistratie: Scalars['Boolean'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  id: Scalars['String'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  isCreatingUrenregistratie: Scalars['Boolean'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  isPrivate?: Maybe<Scalars['Boolean']>;
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  isUseInCapacityCalculation: Scalars['Boolean'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  startDate: Scalars['String'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  subject?: Maybe<Scalars['String']>;
};

export type IPlanningItem_Evenement = IPlanningItem & {
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  canEdit: Scalars['Boolean'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  canMoveToOtherCalendar: Scalars['Boolean'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  canResize: Scalars['Boolean'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  color?: Maybe<Scalars['String']>;
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  csrfToken?: Maybe<Scalars['String']>;
  dienst?: Maybe<IDienst>;
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  endDate: Scalars['String'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  hasUrenregistratie: Scalars['Boolean'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  id: Scalars['String'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  isCreatingUrenregistratie: Scalars['Boolean'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  isPrivate?: Maybe<Scalars['Boolean']>;
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  isUseInCapacityCalculation: Scalars['Boolean'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  opdracht?: Maybe<IOpdracht>;
  relatie?: Maybe<IRelatie>;
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  startDate: Scalars['String'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  subject?: Maybe<Scalars['String']>;
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  taak?: Maybe<Scalars['ID']>;
  ticket?: Maybe<ITicket>;
};

export type IPlanningItem_Feestdag = IPlanningItem & {
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  canEdit: Scalars['Boolean'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  canMoveToOtherCalendar: Scalars['Boolean'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  canResize: Scalars['Boolean'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  color?: Maybe<Scalars['String']>;
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  endDate: Scalars['String'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  hasUrenregistratie: Scalars['Boolean'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  id: Scalars['String'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  isCreatingUrenregistratie: Scalars['Boolean'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  isPrivate?: Maybe<Scalars['Boolean']>;
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  isUseInCapacityCalculation: Scalars['Boolean'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  startDate: Scalars['String'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  subject?: Maybe<Scalars['String']>;
};

export type IPlanningItem_Verlof = IPlanningItem & {
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  canEdit: Scalars['Boolean'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  canMoveToOtherCalendar: Scalars['Boolean'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  canResize: Scalars['Boolean'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  color?: Maybe<Scalars['String']>;
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  endDate: Scalars['String'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  hasUrenregistratie: Scalars['Boolean'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  id: Scalars['String'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  isCreatingUrenregistratie: Scalars['Boolean'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  isPrivate?: Maybe<Scalars['Boolean']>;
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  isUseInCapacityCalculation: Scalars['Boolean'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  startDate: Scalars['String'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  subject?: Maybe<Scalars['String']>;
};

export type IPlanningMedewerker = {
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  agenda: IAgenda;
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  fullName: Scalars['String'];
  id: Scalars['ID'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  planning: IPlanningPlanning;
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  team?: Maybe<ITeam>;
};


export type IPlanningMedewerkerPlanningArgs = {
  endDate: IDateTimeInput;
  startDate: IDateTimeInput;
};

export type IPlanningPlanning = {
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  items: Array<Maybe<IPlanningItem>>;
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  resourceTime: IPlanningResourceTime;
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  rooster: Array<IPlanningRooster>;
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  timePlannedInDays: Array<IPlanningDay>;
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  totalSecondsPlanned: Scalars['Int'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  weekNumberForStartDate: Scalars['Int'];
};

export type IPlanningResourceTime = {
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  capacityInSeconds: Scalars['Int'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  isEmpty: Scalars['Boolean'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  resourceTimeAvailablePercentage: Scalars['Int'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  timeLeftOverInSeconds: Scalars['Int'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  timePlannedInSeconds: Scalars['Int'];
};

export type IPlanningRooster = {
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  date: Scalars['String'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  day: Scalars['Int'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  end: Scalars['String'];
  id: Scalars['ID'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  isWorkDay: Scalars['Boolean'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  start: Scalars['String'];
  /** @deprecated Beoordelen of dit nog gerefactord moet worden. */
  workSeconds: Scalars['Int'];
};

export type IPlanningWeergaveVoorkeuren = {
  actieveAgendaId?: Maybe<Scalars['Int']>;
  dagBeginTijd?: Maybe<Scalars['String']>;
  dagEindTijd?: Maybe<Scalars['String']>;
  eersteDag?: Maybe<Scalars['String']>;
  groepeerOp?: Maybe<Scalars['String']>;
  laatsteDag?: Maybe<Scalars['String']>;
  weergave?: Maybe<Scalars['String']>;
  weergaveInstellingen?: Maybe<Array<IPlanningWeergaveVoorkeurenWeergave>>;
};

export type IPlanningWeergaveVoorkeurenWeergave = {
  weergave: Scalars['String'];
  weergaveType: Scalars['String'];
};

export type IProfiel = {
  actief: Scalars['Boolean'];
  id: Scalars['ID'];
  naam: Scalars['String'];
};

export type IPusher = {
  appCluster: Scalars['String'];
  appKey: Scalars['String'];
  calendarEventUpdatedChannel: Scalars['String'];
};

export type IQuery = {
  AfstandenGereden?: Maybe<Scalars['Int']>;
  Dienst?: Maybe<IDienst>;
  Diensten?: Maybe<IDienstConnection>;
  Evenement?: Maybe<IEvenement>;
  IngelogdeGebruiker?: Maybe<IIngelogdeGebruiker>;
  Opdracht?: Maybe<IOpdracht>;
  OpdrachtPlanStatistieken: IOpdrachtPlanStatistieken;
  Opdrachten?: Maybe<IOpdrachtConnection>;
  Planning?: Maybe<IPlanning>;
  Planning_Agenda?: Maybe<IAgenda>;
  Planning_Medewerkers: Array<IPlanningMedewerker>;
  Planning_PlanningVoorMedewerker?: Maybe<IPlanningPlanning>;
  Planning_Team?: Maybe<ITeam>;
  Planning_Weergave_Voorkeuren?: Maybe<IPlanningWeergaveVoorkeuren>;
  Planningen?: Maybe<IPlanningConnection>;
  Profielen: Array<IProfiel>;
  Pusher?: Maybe<IPusher>;
  ReisInfo?: Maybe<IReisInfo>;
  RoutesInfo?: Maybe<Array<Maybe<IRoutesInfo>>>;
  Ticket?: Maybe<ITicket>;
  ToegewezenBudgetten?: Maybe<IToegewezenBudgetConnection>;
  Voertuigen: Array<IVoertuig>;
  actieveTijdRegistratie?: Maybe<IActieveTijdRegistratie>;
  urenRegistratiesVanIngelogdeGebruiker?: Maybe<Array<IUrenRegistratie>>;
};


export type IQueryDienstArgs = {
  id: Scalars['ID'];
};


export type IQueryDienstenArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type IQueryEvenementArgs = {
  id: Scalars['ID'];
};


export type IQueryOpdrachtArgs = {
  id: Scalars['ID'];
};


export type IQueryOpdrachtPlanStatistiekenArgs = {
  opdrachtId: Scalars['ID'];
};


export type IQueryOpdrachtenArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  fulltextSearch?: InputMaybe<Scalars['String']>;
  gearchiveerd?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  opdrachtstatus?: InputMaybe<Array<InputMaybe<IOpdrachtstatus>>>;
  opdrachttype?: InputMaybe<IOpdrachtCalculatieType>;
  tePlannen?: InputMaybe<Scalars['Boolean']>;
};


export type IQueryPlanningArgs = {
  id: Scalars['ID'];
};


export type IQueryPlanning_AgendaArgs = {
  id: Scalars['ID'];
};


export type IQueryPlanning_MedewerkersArgs = {
  agendaIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type IQueryPlanning_PlanningVoorMedewerkerArgs = {
  employeeId: Scalars['ID'];
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type IQueryPlanning_TeamArgs = {
  id: Scalars['ID'];
};


export type IQueryPlanningenArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  last?: InputMaybe<Scalars['Int']>;
};


export type IQueryTicketArgs = {
  id: Scalars['ID'];
};


export type IQueryToegewezenBudgettenArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  medewerkerId?: InputMaybe<Scalars['ID']>;
  searchQuery?: InputMaybe<Scalars['String']>;
};


export type IQueryUrenRegistratiesVanIngelogdeGebruikerArgs = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  periode: IDateTimeRangeInput;
};

export type IRelatie = {
  id: Scalars['ID'];
  naam: Scalars['String'];
};

export type IResterendBudgetVoorOpdracht = {
  /**
   * De totale beschikbare tijd uit het budget van de opdracht min de gewerkte tijd
   * (tot vandaag) en min de geplande tijd (vanaf vandaag, inclusief vandaag).
   */
  resterendeTijdInSeconde: Scalars['Int'];
};

export type IRooster = {
  id: Scalars['ID'];
};

export type IStartEnEindDatumInput = {
  /** Eind datum */
  einddatum?: InputMaybe<Scalars['String']>;
  /** Start datum */
  startdatum?: InputMaybe<Scalars['String']>;
};

export type ITeam = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ITicket = {
  code: Scalars['String'];
  diensten?: Maybe<Array<Maybe<IDienst>>>;
  id: Scalars['ID'];
  onderwerp: Scalars['String'];
  relatie?: Maybe<IRelatie>;
};

export type ITijd = {
  /** De minuut van 0 t/m 59. */
  minuut: Scalars['Int'];
  /** Het uur van 0 t/m 23. */
  uur: Scalars['Int'];
  /** Tekstuele representatie van de tijd in 24-uursnotatie e.g. "09:15". */
  uur24: Scalars['String'];
};

export type ITijdblok = {
  tot: IDateTime;
  van: IDateTime;
};

export type ITijdblokPauze = ITijdblok & {
  tot: IDateTime;
  van: IDateTime;
};

export type IToegewezenBudget = {
  budgetRegel: IBudgetRegel;
  budgetRegelHouder: IBudgetRegelHouder;
  geplandeSeconden: Scalars['Int'];
  id: Scalars['ID'];
  medewerker?: Maybe<IMedewerker>;
  toegewezenSeconden: Scalars['Int'];
};

export type IToegewezenBudgetConnection = {
  edges?: Maybe<Array<Maybe<IToegewezenBudgetEdge>>>;
  pageInfo: IToegewezenBudgetPageInfo;
  /** De totale hoeveelheid resultaten, ongeacht paginering; i.e. hoeveel items zijn er die voldoen aan de filters. */
  totalCount: Scalars['Int'];
};

export type IToegewezenBudgetEdge = {
  cursor: Scalars['String'];
  node?: Maybe<IToegewezenBudget>;
};

export type IToegewezenBudgetPageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  startCursor?: Maybe<Scalars['String']>;
};

export type IUrenRegistratie = {
  dienstCode: Scalars['String'];
  dienstId: Scalars['Int'];
  dienstLabel: Scalars['String'];
  id: Scalars['Int'];
  opdrachtCode?: Maybe<Scalars['String']>;
  opdrachtId?: Maybe<Scalars['Int']>;
  opdrachtLabel?: Maybe<Scalars['String']>;
  parentId: Scalars['Int'];
  readOnly: Scalars['Boolean'];
  relatieId: Scalars['Int'];
  relatieLabel: Scalars['String'];
  tot: Scalars['String'];
  van: Scalars['String'];
};

export type IVoertuig = {
  id: Scalars['ID'];
  imei: Scalars['String'];
  naam: Scalars['String'];
  onntrackAccountId: Scalars['ID'];
  reisInfo?: Maybe<IReisInfo>;
};


export type IVoertuigReisInfoArgs = {
  eindDatum: Scalars['String'];
  startDatum: Scalars['String'];
};

export type IVoid = {
  void: Scalars['Boolean'];
};

export enum IWeekdag {
  Dinsdag = 'dinsdag',
  Donderdag = 'donderdag',
  Maandag = 'maandag',
  Vrijdag = 'vrijdag',
  Woensdag = 'woensdag',
  Zaterdag = 'zaterdag',
  Zonder = 'zonder'
}

export type IWerkweek = {
  dinsdag?: Maybe<IDag>;
  donderdag?: Maybe<IDag>;
  maandag?: Maybe<IDag>;
  vrijdag?: Maybe<IDag>;
  woensdag?: Maybe<IDag>;
  zaterdag?: Maybe<IDag>;
  zonder?: Maybe<IDag>;
};

export enum IDayType {
  Absence = 'ABSENCE',
  Dayoff = 'DAYOFF',
  Holiday = 'HOLIDAY',
  Workday = 'WORKDAY'
}

export enum IOpdracht_Dienst_Type {
  /** Haal alleen budget diensten op */
  Budget = 'BUDGET',
  /** Haal alleen budget diensten op */
  NonBudget = 'NON_BUDGET'
}

export type IReisInfo = {
  afstandGeredenInMeters?: Maybe<Scalars['Int']>;
  eindTijd?: Maybe<Scalars['String']>;
  routesInfo: IRoutesInfo;
  startTijd?: Maybe<Scalars['String']>;
};

export type IRoute = {
  eindLatitude: Scalars['String'];
  eindLongitude: Scalars['String'];
  eindTijd: Scalars['String'];
  reisTijdInSeconden: Scalars['Int'];
  startLatitude: Scalars['String'];
  startLongitude: Scalars['String'];
  startTijd: Scalars['String'];
};

export type IRoutesInfo = {
  routes?: Maybe<Array<Maybe<IRoute>>>;
  totaleReisTijdInSeconden: Scalars['Int'];
};

export type IActieveTijdRegistratieQueryVariables = Exact<{ [key: string]: never; }>;


export type IActieveTijdRegistratieQuery = { actieveTijdRegistratie?: { id: string, taakId?: string | null, secondsPassed: number, van: { iso8601: string } } | null };

export type IProfielMenuConnectedQueryVariables = Exact<{ [key: string]: never; }>;


export type IProfielMenuConnectedQuery = { Profielen: Array<{ id: string, naam: string, actief: boolean }> };

export type IPlanningPopupOpdrachtQueryVariables = Exact<{
  type?: InputMaybe<IOpdrachtCalculatieType>;
}>;


export type IPlanningPopupOpdrachtQuery = { Opdrachten?: { edges?: Array<{ node?: { id: string, onderwerp: string, relatienaam?: string | null, nummer: string } | null } | null> | null } | null };

export type IStatFooterDataQueryVariables = Exact<{
  opdrachtId: Scalars['ID'];
}>;


export type IStatFooterDataQuery = { OpdrachtPlanStatistieken: { budget: number, geboekt: number, gepland: number, resterend: number } };

export type IVeranderEvenementKleurMutationVariables = Exact<{
  evenementId: Scalars['ID'];
  kleur: Scalars['String'];
}>;


export type IVeranderEvenementKleurMutation = { ZetEvenementKleurMutation?: { void: boolean } | null };

export type IVerwijderEvenementMutationVariables = Exact<{
  evenementId: Scalars['ID'];
}>;


export type IVerwijderEvenementMutation = { VerwijderEvenementMutation?: { void: boolean } | null };

export type IMaakEvenementOpenbaarMutationVariables = Exact<{
  evenementId: Scalars['ID'];
}>;


export type IMaakEvenementOpenbaarMutation = { MaakEvenementOpenbaarMutation?: { void: boolean } | null };

export type IMaakEvenementPriveMutationVariables = Exact<{
  evenementId: Scalars['ID'];
}>;


export type IMaakEvenementPriveMutation = { MaakEvenementPriveMutation?: { void: boolean } | null };

export type IVerwijderUrenRegistratieLogMutationVariables = Exact<{
  urenRegistratieLogId: Scalars['ID'];
}>;


export type IVerwijderUrenRegistratieLogMutation = { VerwijderUrenRegistratieVanIngelogdeGebruikerMutation?: { void: boolean } | null };

export type IEventPopupQueryVariables = Exact<{
  eventId: Scalars['ID'];
}>;


export type IEventPopupQuery = { Evenement?: { id: string, onderwerp?: string | null, start: string, eind: string, csrfToken: string, taak?: string | null, geschiktVoorUrenregistratie: boolean, urenRegistratieId?: number | null, agenda: { id: string, name: string }, relatie?: { id: string, naam: string } | null, opdracht?: { id: string, onderwerp: string, nummer: string } | null, dienst?: { id: string, code: string, titel: string } | null, ticket?: { id: string, code: string, onderwerp: string } | null } | null };

export type IEventPopupEvenementFragment = { id: string, onderwerp?: string | null, start: string, eind: string, csrfToken: string, taak?: string | null, geschiktVoorUrenregistratie: boolean, urenRegistratieId?: number | null, agenda: { id: string, name: string }, relatie?: { id: string, naam: string } | null, opdracht?: { id: string, onderwerp: string, nummer: string } | null, dienst?: { id: string, code: string, titel: string } | null, ticket?: { id: string, code: string, onderwerp: string } | null };

export type IVoltooiButtonConnectedFragment = { id: string, onderwerp?: string | null, geschiktVoorUrenregistratie: boolean, urenRegistratieId?: number | null };

export type IVoltooiEvenementMutationVariables = Exact<{
  evenementId: Scalars['ID'];
}>;


export type IVoltooiEvenementMutation = { VoltooiEvenementMutation?: { void: boolean } | null };

export type IActiveerEvenementMutationVariables = Exact<{
  evenementId: Scalars['ID'];
}>;


export type IActiveerEvenementMutation = { ActiveerEvenementMutation?: { void: boolean } | null };

export type IPlanningPageConnectedQueryVariables = Exact<{ [key: string]: never; }>;


export type IPlanningPageConnectedQuery = { IngelogdeGebruiker?: { beschikbareActies: { planningOpdrachtenSidebarIsBeschikbaar: boolean } } | null };

export type IZetActieveAgendasMutationVariables = Exact<{
  agendaIds: Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>;
  socketId?: InputMaybe<Scalars['String']>;
}>;


export type IZetActieveAgendasMutation = { VeranderActieveAgendasMutation?: { void: boolean } | null };

export type IZetPlanningWeergaveMutationVariables = Exact<{
  socketId?: InputMaybe<Scalars['String']>;
  weergave: Scalars['String'];
}>;


export type IZetPlanningWeergaveMutation = { ZetPlanningWeergaveMutation?: { void: boolean } | null };

export type IZetPlanningTijdMutationVariables = Exact<{
  dagBeginTijd: Scalars['String'];
  dagEindTijd: Scalars['String'];
  eersteDag: Scalars['String'];
  laatsteDag: Scalars['String'];
  socketId?: InputMaybe<Scalars['String']>;
}>;


export type IZetPlanningTijdMutation = { ZetPlanningTijdMutation?: { void: boolean } | null };

export type IZetPlanningWeergaveTypeMutationVariables = Exact<{
  weergave: Scalars['String'];
  type: Scalars['String'];
  socketId?: InputMaybe<Scalars['String']>;
}>;


export type IZetPlanningWeergaveTypeMutation = { ZetPlanningWeergaveTypeMutation?: { void: boolean } | null };

export type IZetActieveAgendaMutationVariables = Exact<{
  agendaId: Scalars['ID'];
  socketId?: InputMaybe<Scalars['String']>;
}>;


export type IZetActieveAgendaMutation = { ZetActieveAgendaMutation?: { void: boolean } | null };

export type IZetPlanningGroepeerOpMutationVariables = Exact<{
  groepeerOp: Scalars['String'];
  socketId?: InputMaybe<Scalars['String']>;
}>;


export type IZetPlanningGroepeerOpMutation = { ZetPlanningGroupByMutation?: { void: boolean } | null };

export type IGebruikerBeschikbareActiesFragment = { beschikbareActies: { planningOpdrachtenSidebarIsBeschikbaar: boolean, planningUrenRegistratiesIsBeschikbaar: boolean, planningUrenRegistratiesBetaIsBeschikbaar: boolean } };

export type IGetPlanningDataQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetPlanningDataQuery = { IngelogdeGebruiker?: { beschikbareActies: { planningOpdrachtenSidebarIsBeschikbaar: boolean, planningUrenRegistratiesIsBeschikbaar: boolean, planningUrenRegistratiesBetaIsBeschikbaar: boolean } } | null, Planning_Weergave_Voorkeuren?: { dagBeginTijd?: string | null, dagEindTijd?: string | null, eersteDag?: string | null, laatsteDag?: string | null, weergave?: string | null, groepeerOp?: string | null, actieveAgendaId?: number | null, weergaveInstellingen?: Array<{ weergaveType: string, weergave: string }> | null } | null, Planning_Medewerkers: Array<{ id: string, team?: { id: string, name: string } | null, agenda: { id: string, color: string, name: string, activeInPlanning: boolean, agendaVanIngelogdeGebruiker: boolean } }> };

export type IOpdrachtPlanningSidebarConnectedQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IOpdrachtPlanningSidebarConnectedQuery = { Opdracht?: { id: string, onderwerp: string, nummer: string, relatienaam?: string | null, budgetDiensten?: Array<{ id: string, titel: string, resterendBudgetVoorOpdracht?: { resterendeTijdInSeconde: number } | null } | null> | null, overigeDiensten?: Array<{ id: string, titel: string, resterendBudgetVoorOpdracht?: { resterendeTijdInSeconde: number } | null } | null> | null } | null };

export type IPlanningSidebarConnectedQueryVariables = Exact<{
  fulltextSearch?: InputMaybe<Scalars['String']>;
  opdrachtstatus?: InputMaybe<Array<InputMaybe<IOpdrachtstatus>> | InputMaybe<IOpdrachtstatus>>;
}>;


export type IPlanningSidebarConnectedQuery = { Opdrachten?: { edges?: Array<{ node?: { id: string, onderwerp: string, nummer: string, relatienaam?: string | null, diensten?: Array<{ id: string, titel: string } | null> | null } | null } | null> | null } | null };

export type IPlanningSideBarConnectedBudgetQueryVariables = Exact<{
  opdrachtId: Scalars['ID'];
}>;


export type IPlanningSideBarConnectedBudgetQuery = { Opdracht?: { id: string, diensten?: Array<{ id: string, titel: string, resterendBudgetVoorOpdracht?: { resterendeTijdInSeconde: number } | null } | null> | null } | null };

export type IPlanningSideBarConnectedDienstFragment = { id: string, titel: string };

export type IPlanningSideBarConnectedMaakEventVoorOpdrachtMutationVariables = Exact<{
  agendaId: Scalars['ID'];
  opdrachtId: Scalars['ID'];
  dienstId?: InputMaybe<Scalars['ID']>;
  datums: Array<IStartEnEindDatumInput> | IStartEnEindDatumInput;
  toegewezenBudgetId?: InputMaybe<Scalars['String']>;
}>;


export type IPlanningSideBarConnectedMaakEventVoorOpdrachtMutation = { MaakEvenementVoorOpdracht?: { void: boolean } | null };

export type ITicketPlanningSidebarConnectedQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ITicketPlanningSidebarConnectedQuery = { Ticket?: { id: string, onderwerp: string, code: string, relatie?: { id: string, naam: string } | null, diensten?: Array<{ id: string, code: string, titel: string } | null> | null } | null };

export type ITicketSideBarConnectedMaakEventVoorTicketMutationVariables = Exact<{
  agendaId: Scalars['ID'];
  ticketId: Scalars['ID'];
  dienstId?: InputMaybe<Scalars['ID']>;
  datums: Array<IStartEnEindDatumInput> | IStartEnEindDatumInput;
}>;


export type ITicketSideBarConnectedMaakEventVoorTicketMutation = { MaakEvenementVoorTicket?: { void: boolean } | null };

export type IToegewezenBudgetFragment = { id: string, toegewezenSeconden: number, geplandeSeconden: number, budgetRegel: { id: string, onderwerp: string, dienst: { id: string, code: string, titel: string } }, budgetRegelHouder: { code: string, onderwerp: string, id: string, relatienaam: string } };

export type IBudgettenQueryVariables = Exact<{
  medewerkerId: Scalars['ID'];
  searchQuery?: InputMaybe<Scalars['String']>;
}>;


export type IBudgettenQuery = { ToegewezenBudgetten?: { edges?: Array<{ node?: { id: string, toegewezenSeconden: number, geplandeSeconden: number, budgetRegel: { id: string, onderwerp: string, dienst: { id: string, code: string, titel: string } }, budgetRegelHouder: { code: string, onderwerp: string, id: string, relatienaam: string } } | null } | null> | null } | null };

export type ICapaciteitViewConnectedQueryVariables = Exact<{
  startDate: IDateTimeInput;
  endDate: IDateTimeInput;
  agendaIds: Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>;
}>;


export type ICapaciteitViewConnectedQuery = { medewerkers: Array<{ id: string, fullName: string, team?: { id: string, name: string } | null, agenda: { id: string, name: string, color: string }, planning: { weekNumberForStartDate: number, timePlannedInDays: Array<{ start: string, end: string, timeAvailableInSeconds: number, timeAvailablePercentage: number, title: string, resource: string, dayType: IDayType }>, resourceTime: { isEmpty: boolean, capacityInSeconds: number, timePlannedInSeconds: number, timeLeftOverInSeconds: number, resourceTimeAvailablePercentage: number } } }> };

export type IPlanningViewConnectedQueryVariables = Exact<{
  startDate: IDateTimeInput;
  endDate: IDateTimeInput;
  agendaIds: Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>;
  medewerkerIds: Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>;
  urenRegistratiesEnabled?: InputMaybe<Scalars['Boolean']>;
}>;


export type IPlanningViewConnectedQuery = { medewerkers: Array<{ id: string, fullName: string, agenda: { name: string, color: string, id: string }, team?: { id: string, name: string } | null, planning: { rooster: Array<{ start: string, end: string, isWorkDay: boolean }>, events: Array<{ hasUrenregistratie: boolean, isCreatingUrenregistratie: boolean, id: string, endDate: string, startDate: string, subject?: string | null, color?: string | null, isPrivate?: boolean | null, canEdit: boolean, canResize: boolean, canMoveToOtherCalendar: boolean, csrfToken?: string | null, taak?: string | null, ticket?: { id: string, onderwerp: string, code: string } | null, opdracht?: { id: string, onderwerp: string, nummer: string } | null, relatie?: { id: string, naam: string } | null, dienst?: { id: string, titel: string, code: string } | null } | { hasUrenregistratie: boolean, isCreatingUrenregistratie: boolean, id: string, endDate: string, startDate: string, subject?: string | null, color?: string | null, isPrivate?: boolean | null, canEdit: boolean, canResize: boolean, canMoveToOtherCalendar: boolean } | { hasUrenregistratie: boolean, isCreatingUrenregistratie: boolean, id: string, endDate: string, startDate: string, subject?: string | null, color?: string | null, isPrivate?: boolean | null, canEdit: boolean, canResize: boolean, canMoveToOtherCalendar: boolean } | null> } }>, urenRegistratiesVanIngelogdeGebruiker?: Array<{ id: number, parentId: number, readOnly: boolean, tot: string, van: string, dienstId: number, dienstLabel: string, dienstCode: string, opdrachtId?: number | null, opdrachtLabel?: string | null, opdrachtCode?: string | null, relatieId: number, relatieLabel: string }> | null, Planningen?: { edges?: Array<{ node?: { id: string, tijdblokken?: Array<{ van: { iso8601: string }, tot: { iso8601: string } } | null> | null } | null } | null> | null } | null };

export type IPlanningItemEvenementFragment = { csrfToken?: string | null, taak?: string | null, ticket?: { id: string, onderwerp: string, code: string } | null, opdracht?: { id: string, onderwerp: string, nummer: string } | null, relatie?: { id: string, naam: string } | null, dienst?: { id: string, titel: string, code: string } | null };

export type IMedewerkerFragment = { id: string, fullName: string, agenda: { name: string, color: string, id: string }, team?: { id: string, name: string } | null, planning: { rooster: Array<{ start: string, end: string, isWorkDay: boolean }>, events: Array<{ hasUrenregistratie: boolean, isCreatingUrenregistratie: boolean, id: string, endDate: string, startDate: string, subject?: string | null, color?: string | null, isPrivate?: boolean | null, canEdit: boolean, canResize: boolean, canMoveToOtherCalendar: boolean, csrfToken?: string | null, taak?: string | null, ticket?: { id: string, onderwerp: string, code: string } | null, opdracht?: { id: string, onderwerp: string, nummer: string } | null, relatie?: { id: string, naam: string } | null, dienst?: { id: string, titel: string, code: string } | null } | { hasUrenregistratie: boolean, isCreatingUrenregistratie: boolean, id: string, endDate: string, startDate: string, subject?: string | null, color?: string | null, isPrivate?: boolean | null, canEdit: boolean, canResize: boolean, canMoveToOtherCalendar: boolean } | { hasUrenregistratie: boolean, isCreatingUrenregistratie: boolean, id: string, endDate: string, startDate: string, subject?: string | null, color?: string | null, isPrivate?: boolean | null, canEdit: boolean, canResize: boolean, canMoveToOtherCalendar: boolean } | null> } };

type IPlanningEvent_PlanningItem_Evenement_Fragment = { id: string, endDate: string, startDate: string, subject?: string | null, color?: string | null, isPrivate?: boolean | null, canEdit: boolean, canResize: boolean, canMoveToOtherCalendar: boolean, hasUrenregistratie: boolean, isCreatingUrenregistratie: boolean, csrfToken?: string | null, taak?: string | null, ticket?: { id: string, onderwerp: string, code: string } | null, opdracht?: { id: string, onderwerp: string, nummer: string } | null, relatie?: { id: string, naam: string } | null, dienst?: { id: string, titel: string, code: string } | null };

type IPlanningEvent_PlanningItem_Feestdag_Fragment = { id: string, endDate: string, startDate: string, subject?: string | null, color?: string | null, isPrivate?: boolean | null, canEdit: boolean, canResize: boolean, canMoveToOtherCalendar: boolean, hasUrenregistratie: boolean, isCreatingUrenregistratie: boolean };

type IPlanningEvent_PlanningItem_Verlof_Fragment = { id: string, endDate: string, startDate: string, subject?: string | null, color?: string | null, isPrivate?: boolean | null, canEdit: boolean, canResize: boolean, canMoveToOtherCalendar: boolean, hasUrenregistratie: boolean, isCreatingUrenregistratie: boolean };

export type IPlanningEventFragment = IPlanningEvent_PlanningItem_Evenement_Fragment | IPlanningEvent_PlanningItem_Feestdag_Fragment | IPlanningEvent_PlanningItem_Verlof_Fragment;

export type IRoosterFragment = { start: string, end: string, isWorkDay: boolean };

export type IZetDatumsVanUrenRegistratieLogMutationVariables = Exact<{
  urenRegistratieLogId: Scalars['ID'];
  datums: IDateTimeRangeInput;
}>;


export type IZetDatumsVanUrenRegistratieLogMutation = { ZetUrenRegistratieDatumsMutation?: { void: boolean } | null };

export type ISocketChangesQueryVariables = Exact<{ [key: string]: never; }>;


export type ISocketChangesQuery = { Pusher?: { appKey: string, appCluster: string, calendarEventUpdatedChannel: string } | null };

export const VoltooiButtonConnectedFragmentDoc = gql`
    fragment VoltooiButtonConnected on Evenement {
  id
  onderwerp
  geschiktVoorUrenregistratie
  urenRegistratieId
}
    `;
export const EventPopupEvenementFragmentDoc = gql`
    fragment EventPopupEvenement on Evenement {
  id
  agenda {
    id
    name
  }
  onderwerp
  start
  eind
  csrfToken
  relatie {
    id
    naam
  }
  opdracht {
    id
    onderwerp
    nummer
  }
  dienst {
    id
    code
    titel
  }
  ticket {
    id
    code
    onderwerp
  }
  taak
  ...VoltooiButtonConnected
}
    ${VoltooiButtonConnectedFragmentDoc}`;
export const GebruikerBeschikbareActiesFragmentDoc = gql`
    fragment GebruikerBeschikbareActies on IngelogdeGebruiker {
  beschikbareActies {
    planningOpdrachtenSidebarIsBeschikbaar
    planningUrenRegistratiesIsBeschikbaar
    planningUrenRegistratiesBetaIsBeschikbaar
  }
}
    `;
export const PlanningSideBarConnectedDienstFragmentDoc = gql`
    fragment PlanningSideBarConnectedDienst on Dienst {
  id
  titel
}
    `;
export const ToegewezenBudgetFragmentDoc = gql`
    fragment ToegewezenBudget on ToegewezenBudget {
  id
  toegewezenSeconden
  geplandeSeconden
  budgetRegel {
    id
    onderwerp
    dienst {
      id
      code
      titel
    }
  }
  budgetRegelHouder {
    code
    onderwerp
    id
    relatienaam
  }
}
    `;
export const RoosterFragmentDoc = gql`
    fragment Rooster on PlanningRooster {
  start
  end
  isWorkDay
}
    `;
export const PlanningItemEvenementFragmentDoc = gql`
    fragment PlanningItemEvenement on PlanningItem_Evenement {
  csrfToken
  ticket {
    id
    onderwerp
    code
  }
  opdracht {
    id
    onderwerp
    nummer
  }
  relatie {
    id
    naam
  }
  dienst {
    id
    titel
    code
  }
  taak
}
    `;
export const MedewerkerFragmentDoc = gql`
    fragment Medewerker on PlanningMedewerker {
  id
  fullName
  agenda {
    name
    color
    id
  }
  team {
    id
    name
  }
  planning(startDate: $startDate, endDate: $endDate) {
    rooster {
      ...Rooster
    }
    events: items {
      hasUrenregistratie
      isCreatingUrenregistratie
      id
      endDate
      startDate
      subject
      color
      isPrivate
      canEdit
      canResize
      canMoveToOtherCalendar
      ...PlanningItemEvenement
    }
  }
}
    ${RoosterFragmentDoc}
${PlanningItemEvenementFragmentDoc}`;
export const PlanningEventFragmentDoc = gql`
    fragment PlanningEvent on PlanningItem {
  id
  endDate
  startDate
  subject
  color
  isPrivate
  canEdit
  canResize
  canMoveToOtherCalendar
  hasUrenregistratie
  isCreatingUrenregistratie
  ...PlanningItemEvenement
}
    ${PlanningItemEvenementFragmentDoc}`;
export const ActieveTijdRegistratieDocument = gql`
    query ActieveTijdRegistratie {
  actieveTijdRegistratie {
    id
    van {
      iso8601
    }
    taakId
    secondsPassed
  }
}
    `;

/**
 * __useActieveTijdRegistratieQuery__
 *
 * To run a query within a React component, call `useActieveTijdRegistratieQuery` and pass it any options that fit your needs.
 * When your component renders, `useActieveTijdRegistratieQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActieveTijdRegistratieQuery({
 *   variables: {
 *   },
 * });
 */
export function useActieveTijdRegistratieQuery(baseOptions?: Apollo.QueryHookOptions<IActieveTijdRegistratieQuery, IActieveTijdRegistratieQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IActieveTijdRegistratieQuery, IActieveTijdRegistratieQueryVariables>(ActieveTijdRegistratieDocument, options);
      }
export function useActieveTijdRegistratieLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IActieveTijdRegistratieQuery, IActieveTijdRegistratieQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IActieveTijdRegistratieQuery, IActieveTijdRegistratieQueryVariables>(ActieveTijdRegistratieDocument, options);
        }
export type ActieveTijdRegistratieQueryHookResult = ReturnType<typeof useActieveTijdRegistratieQuery>;
export type ActieveTijdRegistratieLazyQueryHookResult = ReturnType<typeof useActieveTijdRegistratieLazyQuery>;
export type ActieveTijdRegistratieQueryResult = Apollo.QueryResult<IActieveTijdRegistratieQuery, IActieveTijdRegistratieQueryVariables>;
export function refetchActieveTijdRegistratieQuery(variables?: IActieveTijdRegistratieQueryVariables) {
      return { query: ActieveTijdRegistratieDocument, variables: variables }
    }
export const ProfielMenuConnectedDocument = gql`
    query ProfielMenuConnected {
  Profielen {
    id
    naam
    actief
  }
}
    `;

/**
 * __useProfielMenuConnectedQuery__
 *
 * To run a query within a React component, call `useProfielMenuConnectedQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfielMenuConnectedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfielMenuConnectedQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfielMenuConnectedQuery(baseOptions?: Apollo.QueryHookOptions<IProfielMenuConnectedQuery, IProfielMenuConnectedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IProfielMenuConnectedQuery, IProfielMenuConnectedQueryVariables>(ProfielMenuConnectedDocument, options);
      }
export function useProfielMenuConnectedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IProfielMenuConnectedQuery, IProfielMenuConnectedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IProfielMenuConnectedQuery, IProfielMenuConnectedQueryVariables>(ProfielMenuConnectedDocument, options);
        }
export type ProfielMenuConnectedQueryHookResult = ReturnType<typeof useProfielMenuConnectedQuery>;
export type ProfielMenuConnectedLazyQueryHookResult = ReturnType<typeof useProfielMenuConnectedLazyQuery>;
export type ProfielMenuConnectedQueryResult = Apollo.QueryResult<IProfielMenuConnectedQuery, IProfielMenuConnectedQueryVariables>;
export function refetchProfielMenuConnectedQuery(variables?: IProfielMenuConnectedQueryVariables) {
      return { query: ProfielMenuConnectedDocument, variables: variables }
    }
export const PlanningPopupOpdrachtDocument = gql`
    query PlanningPopupOpdracht($type: OpdrachtCalculatieType) {
  Opdrachten(tePlannen: true, opdrachttype: $type) {
    edges {
      node {
        id
        onderwerp
        relatienaam
        nummer
      }
    }
  }
}
    `;

/**
 * __usePlanningPopupOpdrachtQuery__
 *
 * To run a query within a React component, call `usePlanningPopupOpdrachtQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanningPopupOpdrachtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanningPopupOpdrachtQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function usePlanningPopupOpdrachtQuery(baseOptions?: Apollo.QueryHookOptions<IPlanningPopupOpdrachtQuery, IPlanningPopupOpdrachtQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IPlanningPopupOpdrachtQuery, IPlanningPopupOpdrachtQueryVariables>(PlanningPopupOpdrachtDocument, options);
      }
export function usePlanningPopupOpdrachtLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IPlanningPopupOpdrachtQuery, IPlanningPopupOpdrachtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IPlanningPopupOpdrachtQuery, IPlanningPopupOpdrachtQueryVariables>(PlanningPopupOpdrachtDocument, options);
        }
export type PlanningPopupOpdrachtQueryHookResult = ReturnType<typeof usePlanningPopupOpdrachtQuery>;
export type PlanningPopupOpdrachtLazyQueryHookResult = ReturnType<typeof usePlanningPopupOpdrachtLazyQuery>;
export type PlanningPopupOpdrachtQueryResult = Apollo.QueryResult<IPlanningPopupOpdrachtQuery, IPlanningPopupOpdrachtQueryVariables>;
export function refetchPlanningPopupOpdrachtQuery(variables?: IPlanningPopupOpdrachtQueryVariables) {
      return { query: PlanningPopupOpdrachtDocument, variables: variables }
    }
export const StatFooterDataDocument = gql`
    query StatFooterData($opdrachtId: ID!) {
  OpdrachtPlanStatistieken(opdrachtId: $opdrachtId) {
    budget
    geboekt
    gepland
    resterend
  }
}
    `;

/**
 * __useStatFooterDataQuery__
 *
 * To run a query within a React component, call `useStatFooterDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatFooterDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatFooterDataQuery({
 *   variables: {
 *      opdrachtId: // value for 'opdrachtId'
 *   },
 * });
 */
export function useStatFooterDataQuery(baseOptions: Apollo.QueryHookOptions<IStatFooterDataQuery, IStatFooterDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IStatFooterDataQuery, IStatFooterDataQueryVariables>(StatFooterDataDocument, options);
      }
export function useStatFooterDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IStatFooterDataQuery, IStatFooterDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IStatFooterDataQuery, IStatFooterDataQueryVariables>(StatFooterDataDocument, options);
        }
export type StatFooterDataQueryHookResult = ReturnType<typeof useStatFooterDataQuery>;
export type StatFooterDataLazyQueryHookResult = ReturnType<typeof useStatFooterDataLazyQuery>;
export type StatFooterDataQueryResult = Apollo.QueryResult<IStatFooterDataQuery, IStatFooterDataQueryVariables>;
export function refetchStatFooterDataQuery(variables: IStatFooterDataQueryVariables) {
      return { query: StatFooterDataDocument, variables: variables }
    }
export const VeranderEvenementKleurDocument = gql`
    mutation VeranderEvenementKleur($evenementId: ID!, $kleur: String!) {
  ZetEvenementKleurMutation(evenementId: $evenementId, kleur: $kleur) {
    void
  }
}
    `;
export type IVeranderEvenementKleurMutationFn = Apollo.MutationFunction<IVeranderEvenementKleurMutation, IVeranderEvenementKleurMutationVariables>;

/**
 * __useVeranderEvenementKleurMutation__
 *
 * To run a mutation, you first call `useVeranderEvenementKleurMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVeranderEvenementKleurMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [veranderEvenementKleurMutation, { data, loading, error }] = useVeranderEvenementKleurMutation({
 *   variables: {
 *      evenementId: // value for 'evenementId'
 *      kleur: // value for 'kleur'
 *   },
 * });
 */
export function useVeranderEvenementKleurMutation(baseOptions?: Apollo.MutationHookOptions<IVeranderEvenementKleurMutation, IVeranderEvenementKleurMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IVeranderEvenementKleurMutation, IVeranderEvenementKleurMutationVariables>(VeranderEvenementKleurDocument, options);
      }
export type VeranderEvenementKleurMutationHookResult = ReturnType<typeof useVeranderEvenementKleurMutation>;
export type VeranderEvenementKleurMutationResult = Apollo.MutationResult<IVeranderEvenementKleurMutation>;
export type VeranderEvenementKleurMutationOptions = Apollo.BaseMutationOptions<IVeranderEvenementKleurMutation, IVeranderEvenementKleurMutationVariables>;
export const VerwijderEvenementDocument = gql`
    mutation VerwijderEvenement($evenementId: ID!) {
  VerwijderEvenementMutation(evenementId: $evenementId) {
    void
  }
}
    `;
export type IVerwijderEvenementMutationFn = Apollo.MutationFunction<IVerwijderEvenementMutation, IVerwijderEvenementMutationVariables>;

/**
 * __useVerwijderEvenementMutation__
 *
 * To run a mutation, you first call `useVerwijderEvenementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerwijderEvenementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verwijderEvenementMutation, { data, loading, error }] = useVerwijderEvenementMutation({
 *   variables: {
 *      evenementId: // value for 'evenementId'
 *   },
 * });
 */
export function useVerwijderEvenementMutation(baseOptions?: Apollo.MutationHookOptions<IVerwijderEvenementMutation, IVerwijderEvenementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IVerwijderEvenementMutation, IVerwijderEvenementMutationVariables>(VerwijderEvenementDocument, options);
      }
export type VerwijderEvenementMutationHookResult = ReturnType<typeof useVerwijderEvenementMutation>;
export type VerwijderEvenementMutationResult = Apollo.MutationResult<IVerwijderEvenementMutation>;
export type VerwijderEvenementMutationOptions = Apollo.BaseMutationOptions<IVerwijderEvenementMutation, IVerwijderEvenementMutationVariables>;
export const MaakEvenementOpenbaarDocument = gql`
    mutation MaakEvenementOpenbaar($evenementId: ID!) {
  MaakEvenementOpenbaarMutation(evenementId: $evenementId) {
    void
  }
}
    `;
export type IMaakEvenementOpenbaarMutationFn = Apollo.MutationFunction<IMaakEvenementOpenbaarMutation, IMaakEvenementOpenbaarMutationVariables>;

/**
 * __useMaakEvenementOpenbaarMutation__
 *
 * To run a mutation, you first call `useMaakEvenementOpenbaarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMaakEvenementOpenbaarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [maakEvenementOpenbaarMutation, { data, loading, error }] = useMaakEvenementOpenbaarMutation({
 *   variables: {
 *      evenementId: // value for 'evenementId'
 *   },
 * });
 */
export function useMaakEvenementOpenbaarMutation(baseOptions?: Apollo.MutationHookOptions<IMaakEvenementOpenbaarMutation, IMaakEvenementOpenbaarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IMaakEvenementOpenbaarMutation, IMaakEvenementOpenbaarMutationVariables>(MaakEvenementOpenbaarDocument, options);
      }
export type MaakEvenementOpenbaarMutationHookResult = ReturnType<typeof useMaakEvenementOpenbaarMutation>;
export type MaakEvenementOpenbaarMutationResult = Apollo.MutationResult<IMaakEvenementOpenbaarMutation>;
export type MaakEvenementOpenbaarMutationOptions = Apollo.BaseMutationOptions<IMaakEvenementOpenbaarMutation, IMaakEvenementOpenbaarMutationVariables>;
export const MaakEvenementPriveDocument = gql`
    mutation MaakEvenementPrive($evenementId: ID!) {
  MaakEvenementPriveMutation(evenementId: $evenementId) {
    void
  }
}
    `;
export type IMaakEvenementPriveMutationFn = Apollo.MutationFunction<IMaakEvenementPriveMutation, IMaakEvenementPriveMutationVariables>;

/**
 * __useMaakEvenementPriveMutation__
 *
 * To run a mutation, you first call `useMaakEvenementPriveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMaakEvenementPriveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [maakEvenementPriveMutation, { data, loading, error }] = useMaakEvenementPriveMutation({
 *   variables: {
 *      evenementId: // value for 'evenementId'
 *   },
 * });
 */
export function useMaakEvenementPriveMutation(baseOptions?: Apollo.MutationHookOptions<IMaakEvenementPriveMutation, IMaakEvenementPriveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IMaakEvenementPriveMutation, IMaakEvenementPriveMutationVariables>(MaakEvenementPriveDocument, options);
      }
export type MaakEvenementPriveMutationHookResult = ReturnType<typeof useMaakEvenementPriveMutation>;
export type MaakEvenementPriveMutationResult = Apollo.MutationResult<IMaakEvenementPriveMutation>;
export type MaakEvenementPriveMutationOptions = Apollo.BaseMutationOptions<IMaakEvenementPriveMutation, IMaakEvenementPriveMutationVariables>;
export const VerwijderUrenRegistratieLogDocument = gql`
    mutation VerwijderUrenRegistratieLog($urenRegistratieLogId: ID!) {
  VerwijderUrenRegistratieVanIngelogdeGebruikerMutation(
    urenRegistratieLogId: $urenRegistratieLogId
  ) {
    void
  }
}
    `;
export type IVerwijderUrenRegistratieLogMutationFn = Apollo.MutationFunction<IVerwijderUrenRegistratieLogMutation, IVerwijderUrenRegistratieLogMutationVariables>;

/**
 * __useVerwijderUrenRegistratieLogMutation__
 *
 * To run a mutation, you first call `useVerwijderUrenRegistratieLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerwijderUrenRegistratieLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verwijderUrenRegistratieLogMutation, { data, loading, error }] = useVerwijderUrenRegistratieLogMutation({
 *   variables: {
 *      urenRegistratieLogId: // value for 'urenRegistratieLogId'
 *   },
 * });
 */
export function useVerwijderUrenRegistratieLogMutation(baseOptions?: Apollo.MutationHookOptions<IVerwijderUrenRegistratieLogMutation, IVerwijderUrenRegistratieLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IVerwijderUrenRegistratieLogMutation, IVerwijderUrenRegistratieLogMutationVariables>(VerwijderUrenRegistratieLogDocument, options);
      }
export type VerwijderUrenRegistratieLogMutationHookResult = ReturnType<typeof useVerwijderUrenRegistratieLogMutation>;
export type VerwijderUrenRegistratieLogMutationResult = Apollo.MutationResult<IVerwijderUrenRegistratieLogMutation>;
export type VerwijderUrenRegistratieLogMutationOptions = Apollo.BaseMutationOptions<IVerwijderUrenRegistratieLogMutation, IVerwijderUrenRegistratieLogMutationVariables>;
export const EventPopupDocument = gql`
    query EventPopup($eventId: ID!) {
  Evenement(id: $eventId) {
    ...EventPopupEvenement
  }
}
    ${EventPopupEvenementFragmentDoc}`;

/**
 * __useEventPopupQuery__
 *
 * To run a query within a React component, call `useEventPopupQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventPopupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventPopupQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useEventPopupQuery(baseOptions: Apollo.QueryHookOptions<IEventPopupQuery, IEventPopupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IEventPopupQuery, IEventPopupQueryVariables>(EventPopupDocument, options);
      }
export function useEventPopupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IEventPopupQuery, IEventPopupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IEventPopupQuery, IEventPopupQueryVariables>(EventPopupDocument, options);
        }
export type EventPopupQueryHookResult = ReturnType<typeof useEventPopupQuery>;
export type EventPopupLazyQueryHookResult = ReturnType<typeof useEventPopupLazyQuery>;
export type EventPopupQueryResult = Apollo.QueryResult<IEventPopupQuery, IEventPopupQueryVariables>;
export function refetchEventPopupQuery(variables: IEventPopupQueryVariables) {
      return { query: EventPopupDocument, variables: variables }
    }
export const VoltooiEvenementDocument = gql`
    mutation VoltooiEvenement($evenementId: ID!) {
  VoltooiEvenementMutation(evenementId: $evenementId) {
    void
  }
}
    `;
export type IVoltooiEvenementMutationFn = Apollo.MutationFunction<IVoltooiEvenementMutation, IVoltooiEvenementMutationVariables>;

/**
 * __useVoltooiEvenementMutation__
 *
 * To run a mutation, you first call `useVoltooiEvenementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoltooiEvenementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voltooiEvenementMutation, { data, loading, error }] = useVoltooiEvenementMutation({
 *   variables: {
 *      evenementId: // value for 'evenementId'
 *   },
 * });
 */
export function useVoltooiEvenementMutation(baseOptions?: Apollo.MutationHookOptions<IVoltooiEvenementMutation, IVoltooiEvenementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IVoltooiEvenementMutation, IVoltooiEvenementMutationVariables>(VoltooiEvenementDocument, options);
      }
export type VoltooiEvenementMutationHookResult = ReturnType<typeof useVoltooiEvenementMutation>;
export type VoltooiEvenementMutationResult = Apollo.MutationResult<IVoltooiEvenementMutation>;
export type VoltooiEvenementMutationOptions = Apollo.BaseMutationOptions<IVoltooiEvenementMutation, IVoltooiEvenementMutationVariables>;
export const ActiveerEvenementDocument = gql`
    mutation ActiveerEvenement($evenementId: ID!) {
  ActiveerEvenementMutation(evenementId: $evenementId) {
    void
  }
}
    `;
export type IActiveerEvenementMutationFn = Apollo.MutationFunction<IActiveerEvenementMutation, IActiveerEvenementMutationVariables>;

/**
 * __useActiveerEvenementMutation__
 *
 * To run a mutation, you first call `useActiveerEvenementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActiveerEvenementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activeerEvenementMutation, { data, loading, error }] = useActiveerEvenementMutation({
 *   variables: {
 *      evenementId: // value for 'evenementId'
 *   },
 * });
 */
export function useActiveerEvenementMutation(baseOptions?: Apollo.MutationHookOptions<IActiveerEvenementMutation, IActiveerEvenementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IActiveerEvenementMutation, IActiveerEvenementMutationVariables>(ActiveerEvenementDocument, options);
      }
export type ActiveerEvenementMutationHookResult = ReturnType<typeof useActiveerEvenementMutation>;
export type ActiveerEvenementMutationResult = Apollo.MutationResult<IActiveerEvenementMutation>;
export type ActiveerEvenementMutationOptions = Apollo.BaseMutationOptions<IActiveerEvenementMutation, IActiveerEvenementMutationVariables>;
export const PlanningPageConnectedDocument = gql`
    query PlanningPageConnected {
  IngelogdeGebruiker {
    beschikbareActies {
      planningOpdrachtenSidebarIsBeschikbaar
    }
  }
}
    `;

/**
 * __usePlanningPageConnectedQuery__
 *
 * To run a query within a React component, call `usePlanningPageConnectedQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanningPageConnectedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanningPageConnectedQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlanningPageConnectedQuery(baseOptions?: Apollo.QueryHookOptions<IPlanningPageConnectedQuery, IPlanningPageConnectedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IPlanningPageConnectedQuery, IPlanningPageConnectedQueryVariables>(PlanningPageConnectedDocument, options);
      }
export function usePlanningPageConnectedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IPlanningPageConnectedQuery, IPlanningPageConnectedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IPlanningPageConnectedQuery, IPlanningPageConnectedQueryVariables>(PlanningPageConnectedDocument, options);
        }
export type PlanningPageConnectedQueryHookResult = ReturnType<typeof usePlanningPageConnectedQuery>;
export type PlanningPageConnectedLazyQueryHookResult = ReturnType<typeof usePlanningPageConnectedLazyQuery>;
export type PlanningPageConnectedQueryResult = Apollo.QueryResult<IPlanningPageConnectedQuery, IPlanningPageConnectedQueryVariables>;
export function refetchPlanningPageConnectedQuery(variables?: IPlanningPageConnectedQueryVariables) {
      return { query: PlanningPageConnectedDocument, variables: variables }
    }
export const ZetActieveAgendasDocument = gql`
    mutation ZetActieveAgendas($agendaIds: [ID]!, $socketId: String) {
  VeranderActieveAgendasMutation(agendaIds: $agendaIds, socketId: $socketId) {
    void
  }
}
    `;
export type IZetActieveAgendasMutationFn = Apollo.MutationFunction<IZetActieveAgendasMutation, IZetActieveAgendasMutationVariables>;

/**
 * __useZetActieveAgendasMutation__
 *
 * To run a mutation, you first call `useZetActieveAgendasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useZetActieveAgendasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [zetActieveAgendasMutation, { data, loading, error }] = useZetActieveAgendasMutation({
 *   variables: {
 *      agendaIds: // value for 'agendaIds'
 *      socketId: // value for 'socketId'
 *   },
 * });
 */
export function useZetActieveAgendasMutation(baseOptions?: Apollo.MutationHookOptions<IZetActieveAgendasMutation, IZetActieveAgendasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IZetActieveAgendasMutation, IZetActieveAgendasMutationVariables>(ZetActieveAgendasDocument, options);
      }
export type ZetActieveAgendasMutationHookResult = ReturnType<typeof useZetActieveAgendasMutation>;
export type ZetActieveAgendasMutationResult = Apollo.MutationResult<IZetActieveAgendasMutation>;
export type ZetActieveAgendasMutationOptions = Apollo.BaseMutationOptions<IZetActieveAgendasMutation, IZetActieveAgendasMutationVariables>;
export const ZetPlanningWeergaveDocument = gql`
    mutation ZetPlanningWeergave($socketId: String, $weergave: String!) {
  ZetPlanningWeergaveMutation(socketId: $socketId, weergave: $weergave) {
    void
  }
}
    `;
export type IZetPlanningWeergaveMutationFn = Apollo.MutationFunction<IZetPlanningWeergaveMutation, IZetPlanningWeergaveMutationVariables>;

/**
 * __useZetPlanningWeergaveMutation__
 *
 * To run a mutation, you first call `useZetPlanningWeergaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useZetPlanningWeergaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [zetPlanningWeergaveMutation, { data, loading, error }] = useZetPlanningWeergaveMutation({
 *   variables: {
 *      socketId: // value for 'socketId'
 *      weergave: // value for 'weergave'
 *   },
 * });
 */
export function useZetPlanningWeergaveMutation(baseOptions?: Apollo.MutationHookOptions<IZetPlanningWeergaveMutation, IZetPlanningWeergaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IZetPlanningWeergaveMutation, IZetPlanningWeergaveMutationVariables>(ZetPlanningWeergaveDocument, options);
      }
export type ZetPlanningWeergaveMutationHookResult = ReturnType<typeof useZetPlanningWeergaveMutation>;
export type ZetPlanningWeergaveMutationResult = Apollo.MutationResult<IZetPlanningWeergaveMutation>;
export type ZetPlanningWeergaveMutationOptions = Apollo.BaseMutationOptions<IZetPlanningWeergaveMutation, IZetPlanningWeergaveMutationVariables>;
export const ZetPlanningTijdDocument = gql`
    mutation ZetPlanningTijd($dagBeginTijd: String!, $dagEindTijd: String!, $eersteDag: String!, $laatsteDag: String!, $socketId: String) {
  ZetPlanningTijdMutation(
    dagBeginTijd: $dagBeginTijd
    dagEindTijd: $dagEindTijd
    eersteDag: $eersteDag
    laatsteDag: $laatsteDag
    socketId: $socketId
  ) {
    void
  }
}
    `;
export type IZetPlanningTijdMutationFn = Apollo.MutationFunction<IZetPlanningTijdMutation, IZetPlanningTijdMutationVariables>;

/**
 * __useZetPlanningTijdMutation__
 *
 * To run a mutation, you first call `useZetPlanningTijdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useZetPlanningTijdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [zetPlanningTijdMutation, { data, loading, error }] = useZetPlanningTijdMutation({
 *   variables: {
 *      dagBeginTijd: // value for 'dagBeginTijd'
 *      dagEindTijd: // value for 'dagEindTijd'
 *      eersteDag: // value for 'eersteDag'
 *      laatsteDag: // value for 'laatsteDag'
 *      socketId: // value for 'socketId'
 *   },
 * });
 */
export function useZetPlanningTijdMutation(baseOptions?: Apollo.MutationHookOptions<IZetPlanningTijdMutation, IZetPlanningTijdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IZetPlanningTijdMutation, IZetPlanningTijdMutationVariables>(ZetPlanningTijdDocument, options);
      }
export type ZetPlanningTijdMutationHookResult = ReturnType<typeof useZetPlanningTijdMutation>;
export type ZetPlanningTijdMutationResult = Apollo.MutationResult<IZetPlanningTijdMutation>;
export type ZetPlanningTijdMutationOptions = Apollo.BaseMutationOptions<IZetPlanningTijdMutation, IZetPlanningTijdMutationVariables>;
export const ZetPlanningWeergaveTypeDocument = gql`
    mutation ZetPlanningWeergaveType($weergave: String!, $type: String!, $socketId: String) {
  ZetPlanningWeergaveTypeMutation(
    weergave: $weergave
    type: $type
    socketId: $socketId
  ) {
    void
  }
}
    `;
export type IZetPlanningWeergaveTypeMutationFn = Apollo.MutationFunction<IZetPlanningWeergaveTypeMutation, IZetPlanningWeergaveTypeMutationVariables>;

/**
 * __useZetPlanningWeergaveTypeMutation__
 *
 * To run a mutation, you first call `useZetPlanningWeergaveTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useZetPlanningWeergaveTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [zetPlanningWeergaveTypeMutation, { data, loading, error }] = useZetPlanningWeergaveTypeMutation({
 *   variables: {
 *      weergave: // value for 'weergave'
 *      type: // value for 'type'
 *      socketId: // value for 'socketId'
 *   },
 * });
 */
export function useZetPlanningWeergaveTypeMutation(baseOptions?: Apollo.MutationHookOptions<IZetPlanningWeergaveTypeMutation, IZetPlanningWeergaveTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IZetPlanningWeergaveTypeMutation, IZetPlanningWeergaveTypeMutationVariables>(ZetPlanningWeergaveTypeDocument, options);
      }
export type ZetPlanningWeergaveTypeMutationHookResult = ReturnType<typeof useZetPlanningWeergaveTypeMutation>;
export type ZetPlanningWeergaveTypeMutationResult = Apollo.MutationResult<IZetPlanningWeergaveTypeMutation>;
export type ZetPlanningWeergaveTypeMutationOptions = Apollo.BaseMutationOptions<IZetPlanningWeergaveTypeMutation, IZetPlanningWeergaveTypeMutationVariables>;
export const ZetActieveAgendaDocument = gql`
    mutation ZetActieveAgenda($agendaId: ID!, $socketId: String) {
  ZetActieveAgendaMutation(agendaId: $agendaId, socketId: $socketId) {
    void
  }
}
    `;
export type IZetActieveAgendaMutationFn = Apollo.MutationFunction<IZetActieveAgendaMutation, IZetActieveAgendaMutationVariables>;

/**
 * __useZetActieveAgendaMutation__
 *
 * To run a mutation, you first call `useZetActieveAgendaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useZetActieveAgendaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [zetActieveAgendaMutation, { data, loading, error }] = useZetActieveAgendaMutation({
 *   variables: {
 *      agendaId: // value for 'agendaId'
 *      socketId: // value for 'socketId'
 *   },
 * });
 */
export function useZetActieveAgendaMutation(baseOptions?: Apollo.MutationHookOptions<IZetActieveAgendaMutation, IZetActieveAgendaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IZetActieveAgendaMutation, IZetActieveAgendaMutationVariables>(ZetActieveAgendaDocument, options);
      }
export type ZetActieveAgendaMutationHookResult = ReturnType<typeof useZetActieveAgendaMutation>;
export type ZetActieveAgendaMutationResult = Apollo.MutationResult<IZetActieveAgendaMutation>;
export type ZetActieveAgendaMutationOptions = Apollo.BaseMutationOptions<IZetActieveAgendaMutation, IZetActieveAgendaMutationVariables>;
export const ZetPlanningGroepeerOpDocument = gql`
    mutation ZetPlanningGroepeerOp($groepeerOp: String!, $socketId: String) {
  ZetPlanningGroupByMutation(group_by: $groepeerOp, socketId: $socketId) {
    void
  }
}
    `;
export type IZetPlanningGroepeerOpMutationFn = Apollo.MutationFunction<IZetPlanningGroepeerOpMutation, IZetPlanningGroepeerOpMutationVariables>;

/**
 * __useZetPlanningGroepeerOpMutation__
 *
 * To run a mutation, you first call `useZetPlanningGroepeerOpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useZetPlanningGroepeerOpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [zetPlanningGroepeerOpMutation, { data, loading, error }] = useZetPlanningGroepeerOpMutation({
 *   variables: {
 *      groepeerOp: // value for 'groepeerOp'
 *      socketId: // value for 'socketId'
 *   },
 * });
 */
export function useZetPlanningGroepeerOpMutation(baseOptions?: Apollo.MutationHookOptions<IZetPlanningGroepeerOpMutation, IZetPlanningGroepeerOpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IZetPlanningGroepeerOpMutation, IZetPlanningGroepeerOpMutationVariables>(ZetPlanningGroepeerOpDocument, options);
      }
export type ZetPlanningGroepeerOpMutationHookResult = ReturnType<typeof useZetPlanningGroepeerOpMutation>;
export type ZetPlanningGroepeerOpMutationResult = Apollo.MutationResult<IZetPlanningGroepeerOpMutation>;
export type ZetPlanningGroepeerOpMutationOptions = Apollo.BaseMutationOptions<IZetPlanningGroepeerOpMutation, IZetPlanningGroepeerOpMutationVariables>;
export const GetPlanningDataDocument = gql`
    query GetPlanningData {
  IngelogdeGebruiker {
    ...GebruikerBeschikbareActies
  }
  Planning_Weergave_Voorkeuren {
    dagBeginTijd
    dagEindTijd
    eersteDag
    laatsteDag
    weergave
    groepeerOp
    actieveAgendaId
    weergaveInstellingen {
      weergaveType
      weergave
    }
  }
  Planning_Medewerkers {
    id
    team {
      id
      name
    }
    agenda {
      id
      color
      name
      activeInPlanning
      agendaVanIngelogdeGebruiker
    }
  }
}
    ${GebruikerBeschikbareActiesFragmentDoc}`;

/**
 * __useGetPlanningDataQuery__
 *
 * To run a query within a React component, call `useGetPlanningDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanningDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanningDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlanningDataQuery(baseOptions?: Apollo.QueryHookOptions<IGetPlanningDataQuery, IGetPlanningDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetPlanningDataQuery, IGetPlanningDataQueryVariables>(GetPlanningDataDocument, options);
      }
export function useGetPlanningDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetPlanningDataQuery, IGetPlanningDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetPlanningDataQuery, IGetPlanningDataQueryVariables>(GetPlanningDataDocument, options);
        }
export type GetPlanningDataQueryHookResult = ReturnType<typeof useGetPlanningDataQuery>;
export type GetPlanningDataLazyQueryHookResult = ReturnType<typeof useGetPlanningDataLazyQuery>;
export type GetPlanningDataQueryResult = Apollo.QueryResult<IGetPlanningDataQuery, IGetPlanningDataQueryVariables>;
export function refetchGetPlanningDataQuery(variables?: IGetPlanningDataQueryVariables) {
      return { query: GetPlanningDataDocument, variables: variables }
    }
export const OpdrachtPlanningSidebarConnectedDocument = gql`
    query OpdrachtPlanningSidebarConnected($id: ID!) {
  Opdracht(id: $id) {
    id
    onderwerp
    nummer
    relatienaam
    budgetDiensten: diensten(type: BUDGET) {
      id
      titel
      resterendBudgetVoorOpdracht(opdrachtId: $id) {
        resterendeTijdInSeconde
      }
    }
    overigeDiensten: diensten(type: NON_BUDGET) {
      id
      titel
      resterendBudgetVoorOpdracht(opdrachtId: $id) {
        resterendeTijdInSeconde
      }
    }
  }
}
    `;

/**
 * __useOpdrachtPlanningSidebarConnectedQuery__
 *
 * To run a query within a React component, call `useOpdrachtPlanningSidebarConnectedQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpdrachtPlanningSidebarConnectedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpdrachtPlanningSidebarConnectedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOpdrachtPlanningSidebarConnectedQuery(baseOptions: Apollo.QueryHookOptions<IOpdrachtPlanningSidebarConnectedQuery, IOpdrachtPlanningSidebarConnectedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IOpdrachtPlanningSidebarConnectedQuery, IOpdrachtPlanningSidebarConnectedQueryVariables>(OpdrachtPlanningSidebarConnectedDocument, options);
      }
export function useOpdrachtPlanningSidebarConnectedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IOpdrachtPlanningSidebarConnectedQuery, IOpdrachtPlanningSidebarConnectedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IOpdrachtPlanningSidebarConnectedQuery, IOpdrachtPlanningSidebarConnectedQueryVariables>(OpdrachtPlanningSidebarConnectedDocument, options);
        }
export type OpdrachtPlanningSidebarConnectedQueryHookResult = ReturnType<typeof useOpdrachtPlanningSidebarConnectedQuery>;
export type OpdrachtPlanningSidebarConnectedLazyQueryHookResult = ReturnType<typeof useOpdrachtPlanningSidebarConnectedLazyQuery>;
export type OpdrachtPlanningSidebarConnectedQueryResult = Apollo.QueryResult<IOpdrachtPlanningSidebarConnectedQuery, IOpdrachtPlanningSidebarConnectedQueryVariables>;
export function refetchOpdrachtPlanningSidebarConnectedQuery(variables: IOpdrachtPlanningSidebarConnectedQueryVariables) {
      return { query: OpdrachtPlanningSidebarConnectedDocument, variables: variables }
    }
export const PlanningSidebarConnectedDocument = gql`
    query PlanningSidebarConnected($fulltextSearch: String, $opdrachtstatus: [Opdrachtstatus]) {
  Opdrachten(
    fulltextSearch: $fulltextSearch
    opdrachtstatus: $opdrachtstatus
    gearchiveerd: false
  ) {
    edges {
      node {
        id
        onderwerp
        nummer
        relatienaam
        diensten {
          ...PlanningSideBarConnectedDienst
        }
      }
    }
  }
}
    ${PlanningSideBarConnectedDienstFragmentDoc}`;

/**
 * __usePlanningSidebarConnectedQuery__
 *
 * To run a query within a React component, call `usePlanningSidebarConnectedQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanningSidebarConnectedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanningSidebarConnectedQuery({
 *   variables: {
 *      fulltextSearch: // value for 'fulltextSearch'
 *      opdrachtstatus: // value for 'opdrachtstatus'
 *   },
 * });
 */
export function usePlanningSidebarConnectedQuery(baseOptions?: Apollo.QueryHookOptions<IPlanningSidebarConnectedQuery, IPlanningSidebarConnectedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IPlanningSidebarConnectedQuery, IPlanningSidebarConnectedQueryVariables>(PlanningSidebarConnectedDocument, options);
      }
export function usePlanningSidebarConnectedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IPlanningSidebarConnectedQuery, IPlanningSidebarConnectedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IPlanningSidebarConnectedQuery, IPlanningSidebarConnectedQueryVariables>(PlanningSidebarConnectedDocument, options);
        }
export type PlanningSidebarConnectedQueryHookResult = ReturnType<typeof usePlanningSidebarConnectedQuery>;
export type PlanningSidebarConnectedLazyQueryHookResult = ReturnType<typeof usePlanningSidebarConnectedLazyQuery>;
export type PlanningSidebarConnectedQueryResult = Apollo.QueryResult<IPlanningSidebarConnectedQuery, IPlanningSidebarConnectedQueryVariables>;
export function refetchPlanningSidebarConnectedQuery(variables?: IPlanningSidebarConnectedQueryVariables) {
      return { query: PlanningSidebarConnectedDocument, variables: variables }
    }
export const PlanningSideBarConnectedBudgetDocument = gql`
    query PlanningSideBarConnectedBudget($opdrachtId: ID!) {
  Opdracht(id: $opdrachtId) {
    id
    diensten {
      ...PlanningSideBarConnectedDienst
      resterendBudgetVoorOpdracht(opdrachtId: $opdrachtId) {
        resterendeTijdInSeconde
      }
    }
  }
}
    ${PlanningSideBarConnectedDienstFragmentDoc}`;

/**
 * __usePlanningSideBarConnectedBudgetQuery__
 *
 * To run a query within a React component, call `usePlanningSideBarConnectedBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanningSideBarConnectedBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanningSideBarConnectedBudgetQuery({
 *   variables: {
 *      opdrachtId: // value for 'opdrachtId'
 *   },
 * });
 */
export function usePlanningSideBarConnectedBudgetQuery(baseOptions: Apollo.QueryHookOptions<IPlanningSideBarConnectedBudgetQuery, IPlanningSideBarConnectedBudgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IPlanningSideBarConnectedBudgetQuery, IPlanningSideBarConnectedBudgetQueryVariables>(PlanningSideBarConnectedBudgetDocument, options);
      }
export function usePlanningSideBarConnectedBudgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IPlanningSideBarConnectedBudgetQuery, IPlanningSideBarConnectedBudgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IPlanningSideBarConnectedBudgetQuery, IPlanningSideBarConnectedBudgetQueryVariables>(PlanningSideBarConnectedBudgetDocument, options);
        }
export type PlanningSideBarConnectedBudgetQueryHookResult = ReturnType<typeof usePlanningSideBarConnectedBudgetQuery>;
export type PlanningSideBarConnectedBudgetLazyQueryHookResult = ReturnType<typeof usePlanningSideBarConnectedBudgetLazyQuery>;
export type PlanningSideBarConnectedBudgetQueryResult = Apollo.QueryResult<IPlanningSideBarConnectedBudgetQuery, IPlanningSideBarConnectedBudgetQueryVariables>;
export function refetchPlanningSideBarConnectedBudgetQuery(variables: IPlanningSideBarConnectedBudgetQueryVariables) {
      return { query: PlanningSideBarConnectedBudgetDocument, variables: variables }
    }
export const PlanningSideBarConnectedMaakEventVoorOpdrachtDocument = gql`
    mutation PlanningSideBarConnectedMaakEventVoorOpdracht($agendaId: ID!, $opdrachtId: ID!, $dienstId: ID, $datums: [StartEnEindDatumInput!]!, $toegewezenBudgetId: String) {
  MaakEvenementVoorOpdracht(
    agendaId: $agendaId
    opdrachtId: $opdrachtId
    dienstId: $dienstId
    datums: $datums
    toegewezenBudgetId: $toegewezenBudgetId
  ) {
    void
  }
}
    `;
export type IPlanningSideBarConnectedMaakEventVoorOpdrachtMutationFn = Apollo.MutationFunction<IPlanningSideBarConnectedMaakEventVoorOpdrachtMutation, IPlanningSideBarConnectedMaakEventVoorOpdrachtMutationVariables>;

/**
 * __usePlanningSideBarConnectedMaakEventVoorOpdrachtMutation__
 *
 * To run a mutation, you first call `usePlanningSideBarConnectedMaakEventVoorOpdrachtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlanningSideBarConnectedMaakEventVoorOpdrachtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [planningSideBarConnectedMaakEventVoorOpdrachtMutation, { data, loading, error }] = usePlanningSideBarConnectedMaakEventVoorOpdrachtMutation({
 *   variables: {
 *      agendaId: // value for 'agendaId'
 *      opdrachtId: // value for 'opdrachtId'
 *      dienstId: // value for 'dienstId'
 *      datums: // value for 'datums'
 *      toegewezenBudgetId: // value for 'toegewezenBudgetId'
 *   },
 * });
 */
export function usePlanningSideBarConnectedMaakEventVoorOpdrachtMutation(baseOptions?: Apollo.MutationHookOptions<IPlanningSideBarConnectedMaakEventVoorOpdrachtMutation, IPlanningSideBarConnectedMaakEventVoorOpdrachtMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IPlanningSideBarConnectedMaakEventVoorOpdrachtMutation, IPlanningSideBarConnectedMaakEventVoorOpdrachtMutationVariables>(PlanningSideBarConnectedMaakEventVoorOpdrachtDocument, options);
      }
export type PlanningSideBarConnectedMaakEventVoorOpdrachtMutationHookResult = ReturnType<typeof usePlanningSideBarConnectedMaakEventVoorOpdrachtMutation>;
export type PlanningSideBarConnectedMaakEventVoorOpdrachtMutationResult = Apollo.MutationResult<IPlanningSideBarConnectedMaakEventVoorOpdrachtMutation>;
export type PlanningSideBarConnectedMaakEventVoorOpdrachtMutationOptions = Apollo.BaseMutationOptions<IPlanningSideBarConnectedMaakEventVoorOpdrachtMutation, IPlanningSideBarConnectedMaakEventVoorOpdrachtMutationVariables>;
export const TicketPlanningSidebarConnectedDocument = gql`
    query TicketPlanningSidebarConnected($id: ID!) {
  Ticket(id: $id) {
    id
    onderwerp
    code
    relatie {
      id
      naam
    }
    diensten {
      id
      code
      titel
    }
  }
}
    `;

/**
 * __useTicketPlanningSidebarConnectedQuery__
 *
 * To run a query within a React component, call `useTicketPlanningSidebarConnectedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketPlanningSidebarConnectedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketPlanningSidebarConnectedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTicketPlanningSidebarConnectedQuery(baseOptions: Apollo.QueryHookOptions<ITicketPlanningSidebarConnectedQuery, ITicketPlanningSidebarConnectedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ITicketPlanningSidebarConnectedQuery, ITicketPlanningSidebarConnectedQueryVariables>(TicketPlanningSidebarConnectedDocument, options);
      }
export function useTicketPlanningSidebarConnectedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ITicketPlanningSidebarConnectedQuery, ITicketPlanningSidebarConnectedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ITicketPlanningSidebarConnectedQuery, ITicketPlanningSidebarConnectedQueryVariables>(TicketPlanningSidebarConnectedDocument, options);
        }
export type TicketPlanningSidebarConnectedQueryHookResult = ReturnType<typeof useTicketPlanningSidebarConnectedQuery>;
export type TicketPlanningSidebarConnectedLazyQueryHookResult = ReturnType<typeof useTicketPlanningSidebarConnectedLazyQuery>;
export type TicketPlanningSidebarConnectedQueryResult = Apollo.QueryResult<ITicketPlanningSidebarConnectedQuery, ITicketPlanningSidebarConnectedQueryVariables>;
export function refetchTicketPlanningSidebarConnectedQuery(variables: ITicketPlanningSidebarConnectedQueryVariables) {
      return { query: TicketPlanningSidebarConnectedDocument, variables: variables }
    }
export const TicketSideBarConnectedMaakEventVoorTicketDocument = gql`
    mutation TicketSideBarConnectedMaakEventVoorTicket($agendaId: ID!, $ticketId: ID!, $dienstId: ID, $datums: [StartEnEindDatumInput!]!) {
  MaakEvenementVoorTicket(
    agendaId: $agendaId
    ticketId: $ticketId
    dienstId: $dienstId
    datums: $datums
  ) {
    void
  }
}
    `;
export type ITicketSideBarConnectedMaakEventVoorTicketMutationFn = Apollo.MutationFunction<ITicketSideBarConnectedMaakEventVoorTicketMutation, ITicketSideBarConnectedMaakEventVoorTicketMutationVariables>;

/**
 * __useTicketSideBarConnectedMaakEventVoorTicketMutation__
 *
 * To run a mutation, you first call `useTicketSideBarConnectedMaakEventVoorTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTicketSideBarConnectedMaakEventVoorTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ticketSideBarConnectedMaakEventVoorTicketMutation, { data, loading, error }] = useTicketSideBarConnectedMaakEventVoorTicketMutation({
 *   variables: {
 *      agendaId: // value for 'agendaId'
 *      ticketId: // value for 'ticketId'
 *      dienstId: // value for 'dienstId'
 *      datums: // value for 'datums'
 *   },
 * });
 */
export function useTicketSideBarConnectedMaakEventVoorTicketMutation(baseOptions?: Apollo.MutationHookOptions<ITicketSideBarConnectedMaakEventVoorTicketMutation, ITicketSideBarConnectedMaakEventVoorTicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ITicketSideBarConnectedMaakEventVoorTicketMutation, ITicketSideBarConnectedMaakEventVoorTicketMutationVariables>(TicketSideBarConnectedMaakEventVoorTicketDocument, options);
      }
export type TicketSideBarConnectedMaakEventVoorTicketMutationHookResult = ReturnType<typeof useTicketSideBarConnectedMaakEventVoorTicketMutation>;
export type TicketSideBarConnectedMaakEventVoorTicketMutationResult = Apollo.MutationResult<ITicketSideBarConnectedMaakEventVoorTicketMutation>;
export type TicketSideBarConnectedMaakEventVoorTicketMutationOptions = Apollo.BaseMutationOptions<ITicketSideBarConnectedMaakEventVoorTicketMutation, ITicketSideBarConnectedMaakEventVoorTicketMutationVariables>;
export const BudgettenDocument = gql`
    query Budgetten($medewerkerId: ID!, $searchQuery: String) {
  ToegewezenBudgetten(medewerkerId: $medewerkerId, searchQuery: $searchQuery) {
    edges {
      node {
        ...ToegewezenBudget
      }
    }
  }
}
    ${ToegewezenBudgetFragmentDoc}`;

/**
 * __useBudgettenQuery__
 *
 * To run a query within a React component, call `useBudgettenQuery` and pass it any options that fit your needs.
 * When your component renders, `useBudgettenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBudgettenQuery({
 *   variables: {
 *      medewerkerId: // value for 'medewerkerId'
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useBudgettenQuery(baseOptions: Apollo.QueryHookOptions<IBudgettenQuery, IBudgettenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IBudgettenQuery, IBudgettenQueryVariables>(BudgettenDocument, options);
      }
export function useBudgettenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IBudgettenQuery, IBudgettenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IBudgettenQuery, IBudgettenQueryVariables>(BudgettenDocument, options);
        }
export type BudgettenQueryHookResult = ReturnType<typeof useBudgettenQuery>;
export type BudgettenLazyQueryHookResult = ReturnType<typeof useBudgettenLazyQuery>;
export type BudgettenQueryResult = Apollo.QueryResult<IBudgettenQuery, IBudgettenQueryVariables>;
export function refetchBudgettenQuery(variables: IBudgettenQueryVariables) {
      return { query: BudgettenDocument, variables: variables }
    }
export const CapaciteitViewConnectedDocument = gql`
    query CapaciteitViewConnected($startDate: DateTimeInput!, $endDate: DateTimeInput!, $agendaIds: [ID]!) {
  medewerkers: Planning_Medewerkers(agendaIds: $agendaIds) {
    id
    fullName
    team {
      id
      name
    }
    agenda {
      id
      name
      color
    }
    planning(startDate: $startDate, endDate: $endDate) {
      weekNumberForStartDate
      timePlannedInDays {
        start
        end
        timeAvailableInSeconds
        timeAvailablePercentage
        title
        resource
        dayType
      }
      resourceTime {
        isEmpty
        capacityInSeconds
        timePlannedInSeconds
        timeLeftOverInSeconds
        resourceTimeAvailablePercentage
      }
    }
  }
}
    `;

/**
 * __useCapaciteitViewConnectedQuery__
 *
 * To run a query within a React component, call `useCapaciteitViewConnectedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCapaciteitViewConnectedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCapaciteitViewConnectedQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      agendaIds: // value for 'agendaIds'
 *   },
 * });
 */
export function useCapaciteitViewConnectedQuery(baseOptions: Apollo.QueryHookOptions<ICapaciteitViewConnectedQuery, ICapaciteitViewConnectedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ICapaciteitViewConnectedQuery, ICapaciteitViewConnectedQueryVariables>(CapaciteitViewConnectedDocument, options);
      }
export function useCapaciteitViewConnectedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ICapaciteitViewConnectedQuery, ICapaciteitViewConnectedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ICapaciteitViewConnectedQuery, ICapaciteitViewConnectedQueryVariables>(CapaciteitViewConnectedDocument, options);
        }
export type CapaciteitViewConnectedQueryHookResult = ReturnType<typeof useCapaciteitViewConnectedQuery>;
export type CapaciteitViewConnectedLazyQueryHookResult = ReturnType<typeof useCapaciteitViewConnectedLazyQuery>;
export type CapaciteitViewConnectedQueryResult = Apollo.QueryResult<ICapaciteitViewConnectedQuery, ICapaciteitViewConnectedQueryVariables>;
export function refetchCapaciteitViewConnectedQuery(variables: ICapaciteitViewConnectedQueryVariables) {
      return { query: CapaciteitViewConnectedDocument, variables: variables }
    }
export const PlanningViewConnectedDocument = gql`
    query PlanningViewConnected($startDate: DateTimeInput!, $endDate: DateTimeInput!, $agendaIds: [ID]!, $medewerkerIds: [ID]!, $urenRegistratiesEnabled: Boolean) {
  medewerkers: Planning_Medewerkers(agendaIds: $agendaIds) {
    ...Medewerker
  }
  urenRegistratiesVanIngelogdeGebruiker(
    periode: {van: $startDate, tot: $endDate}
    enabled: $urenRegistratiesEnabled
  ) {
    id
    parentId
    readOnly
    tot
    van
    dienstId
    dienstLabel
    dienstCode
    opdrachtId
    opdrachtLabel
    opdrachtCode
    relatieId
    relatieLabel
  }
  Planningen(ids: $medewerkerIds) {
    edges {
      node {
        id
        tijdblokken(periode: {van: $startDate, tot: $endDate}) {
          van {
            iso8601
          }
          tot {
            iso8601
          }
        }
      }
    }
  }
}
    ${MedewerkerFragmentDoc}`;

/**
 * __usePlanningViewConnectedQuery__
 *
 * To run a query within a React component, call `usePlanningViewConnectedQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanningViewConnectedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanningViewConnectedQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      agendaIds: // value for 'agendaIds'
 *      medewerkerIds: // value for 'medewerkerIds'
 *      urenRegistratiesEnabled: // value for 'urenRegistratiesEnabled'
 *   },
 * });
 */
export function usePlanningViewConnectedQuery(baseOptions: Apollo.QueryHookOptions<IPlanningViewConnectedQuery, IPlanningViewConnectedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IPlanningViewConnectedQuery, IPlanningViewConnectedQueryVariables>(PlanningViewConnectedDocument, options);
      }
export function usePlanningViewConnectedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IPlanningViewConnectedQuery, IPlanningViewConnectedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IPlanningViewConnectedQuery, IPlanningViewConnectedQueryVariables>(PlanningViewConnectedDocument, options);
        }
export type PlanningViewConnectedQueryHookResult = ReturnType<typeof usePlanningViewConnectedQuery>;
export type PlanningViewConnectedLazyQueryHookResult = ReturnType<typeof usePlanningViewConnectedLazyQuery>;
export type PlanningViewConnectedQueryResult = Apollo.QueryResult<IPlanningViewConnectedQuery, IPlanningViewConnectedQueryVariables>;
export function refetchPlanningViewConnectedQuery(variables: IPlanningViewConnectedQueryVariables) {
      return { query: PlanningViewConnectedDocument, variables: variables }
    }
export const ZetDatumsVanUrenRegistratieLogDocument = gql`
    mutation ZetDatumsVanUrenRegistratieLog($urenRegistratieLogId: ID!, $datums: DateTimeRangeInput!) {
  ZetUrenRegistratieDatumsMutation(
    urenRegistratieLogId: $urenRegistratieLogId
    datums: $datums
  ) {
    void
  }
}
    `;
export type IZetDatumsVanUrenRegistratieLogMutationFn = Apollo.MutationFunction<IZetDatumsVanUrenRegistratieLogMutation, IZetDatumsVanUrenRegistratieLogMutationVariables>;

/**
 * __useZetDatumsVanUrenRegistratieLogMutation__
 *
 * To run a mutation, you first call `useZetDatumsVanUrenRegistratieLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useZetDatumsVanUrenRegistratieLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [zetDatumsVanUrenRegistratieLogMutation, { data, loading, error }] = useZetDatumsVanUrenRegistratieLogMutation({
 *   variables: {
 *      urenRegistratieLogId: // value for 'urenRegistratieLogId'
 *      datums: // value for 'datums'
 *   },
 * });
 */
export function useZetDatumsVanUrenRegistratieLogMutation(baseOptions?: Apollo.MutationHookOptions<IZetDatumsVanUrenRegistratieLogMutation, IZetDatumsVanUrenRegistratieLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IZetDatumsVanUrenRegistratieLogMutation, IZetDatumsVanUrenRegistratieLogMutationVariables>(ZetDatumsVanUrenRegistratieLogDocument, options);
      }
export type ZetDatumsVanUrenRegistratieLogMutationHookResult = ReturnType<typeof useZetDatumsVanUrenRegistratieLogMutation>;
export type ZetDatumsVanUrenRegistratieLogMutationResult = Apollo.MutationResult<IZetDatumsVanUrenRegistratieLogMutation>;
export type ZetDatumsVanUrenRegistratieLogMutationOptions = Apollo.BaseMutationOptions<IZetDatumsVanUrenRegistratieLogMutation, IZetDatumsVanUrenRegistratieLogMutationVariables>;
export const SocketChangesDocument = gql`
    query SocketChanges {
  Pusher {
    appKey
    appCluster
    calendarEventUpdatedChannel
  }
}
    `;

/**
 * __useSocketChangesQuery__
 *
 * To run a query within a React component, call `useSocketChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSocketChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSocketChangesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSocketChangesQuery(baseOptions?: Apollo.QueryHookOptions<ISocketChangesQuery, ISocketChangesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ISocketChangesQuery, ISocketChangesQueryVariables>(SocketChangesDocument, options);
      }
export function useSocketChangesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ISocketChangesQuery, ISocketChangesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ISocketChangesQuery, ISocketChangesQueryVariables>(SocketChangesDocument, options);
        }
export type SocketChangesQueryHookResult = ReturnType<typeof useSocketChangesQuery>;
export type SocketChangesLazyQueryHookResult = ReturnType<typeof useSocketChangesLazyQuery>;
export type SocketChangesQueryResult = Apollo.QueryResult<ISocketChangesQuery, ISocketChangesQueryVariables>;
export function refetchSocketChangesQuery(variables?: ISocketChangesQueryVariables) {
      return { query: SocketChangesDocument, variables: variables }
    }