import {useField} from 'formik';
import {StyledSelectOption} from '../StyledSelect';
import {FormikFieldProps} from '../FieldWrapper';
import {BoxSelect, BoxSelectSearchConfig} from './BoxSelect';

export type BoxSelectInputProps<T> = FormikFieldProps<T> &
    BoxSelectSearchConfig & {
        options: StyledSelectOption<T>[];
        label: string;
        isClearable?: boolean;
    };

export function BoxSelectInput<T>({
    meta,
    hasError,
    hasControls,
    form,
    field,
    isClearable,
    options,
    label,
    ...props
}: BoxSelectInputProps<T>) {
    const [, , {setValue}] = useField(field.name);
    return (
        <BoxSelect<T>
            readonly={false}
            disabled={false}
            options={options}
            clearable={isClearable}
            id={field.name}
            label={label}
            {...field}
            {...props}
            onChange={setValue}
        />
    );
}
