import {ReactNode, FC} from 'react';
import {Diff} from 'utility-types';
import {SchemaOf} from 'yup';
import {StrictTypeFieldProps, styledFieldComponentName, useCreateStrictForm} from '../../Hook';
import {FormikFieldProps} from '../FieldWrapper';

export interface SingleFieldFormProps<TSchema extends object, TName extends keyof TSchema, TComponent extends object> {
    /**
     * Note: ensure this is always the same function by e.g. wrapping it in a useCallback().
     */
    createInitialValues: () => TSchema;
    onSubmit: (value: TSchema) => Promise<void>;
    schema: SchemaOf<TSchema>;
    name: TName;
    testfieldName?: string;
    label?: ReactNode;
    placeholder?: string | null;
    autoFocus?: boolean;
    autoSubmit?: boolean;
    debug?: boolean;
    /**
     * Disable when there is no data.
     */
    disabled: boolean;
    readonly?: boolean;
    doNoWaitForSubmit?: boolean;
    disableInlineEditable?: boolean;
    createInitialValuesAfterSubmit?: boolean;
    component?: FC<TComponent>;
    controlsPosition?: 'right' | 'bottom';
    extraFieldProps?: Partial<Diff<TComponent, FormikFieldProps<TSchema[TName]>>>;
}

export function SingleFieldForm<TSchema extends object, TName extends keyof TSchema, TComponent extends object>({
    onSubmit,
    createInitialValues,
    schema,
    name,
    testfieldName,
    readonly,
    disableInlineEditable = false,
    autoSubmit = true,
    component,
    controlsPosition,
    label,
    disabled,
    autoFocus,
    placeholder,
    doNoWaitForSubmit,
    createInitialValuesAfterSubmit,
    debug,
    extraFieldProps = {},
}: SingleFieldFormProps<TSchema, TName, TComponent>) {
    const {Form, Fields, formProps} = useCreateStrictForm<TSchema>({
        onSubmit,
        autoSubmit,
        schema,
        createInitialValues,
        doNoWaitForSubmit,
        createInitialValuesAfterSubmit,
    });

    return (
        <Form {...formProps}>
            {Object.entries(Fields).map(([fieldName, StyledField]) => {
                const Typed = StyledField as FC<StrictTypeFieldProps<TSchema, TName>>;

                if (styledFieldComponentName(name as string) !== fieldName) {
                    return <Typed type="hidden" label={null} key={fieldName} />;
                }

                return (
                    <Typed
                        key={fieldName}
                        // @ts-expect-error TODO: fix this
                        component={component}
                        inlineEditable={!disableInlineEditable}
                        controls
                        controlsPosition={controlsPosition}
                        label={label}
                        placeholder={placeholder}
                        disabled={disabled}
                        readonly={readonly}
                        autoFocus={autoFocus}
                        debug={debug}
                        testfieldName={testfieldName}
                        {...extraFieldProps}
                    />
                );
            })}
        </Form>
    );
}
