import {StyledSelectOption} from '@growthbase/design-components';
import {useMemo} from 'react';
import {Lijst} from './useVerplaatsSchema';
import {Opties} from './useVerplaatsForm';

export interface LijstOptie extends StyledSelectOption<string> {
    id: string;
}

export interface LijstOptiesArgs {
    lijsten: Lijst[];
}

export const useLijstOpties = (opties?: Opties | null) =>
    useMemo(
        (): LijstOptie[] =>
            (opties?.lijsten ?? []).map((lijst) => ({
                id: lijst.id,
                label: lijst.titel,
                value: lijst.id,
            })),
        [opties?.lijsten]
    );
