import {ITaakTabelLijstConnectedFragment} from '@growthbase/graphql';
import {PaginationHelpers} from '@growthbase/spa';
import React, {useCallback} from 'react';
import {onDropItemHandler, onRemoveItemHandler} from '@growthbase/keys';
import {ContainerData, TaakFilters} from '../../types';
import {
    RenderSortableTakenTabelLijstProps,
    RenderSortableTakenTabelLijstType,
    SortableTakenTabelLijstConnected,
} from './Components';
import {Tabel} from './Tabel';

export interface TabelConnectedProps<
    TLijst extends ITaakTabelLijstConnectedFragment = ITaakTabelLijstConnectedFragment,
    TContainerData extends ContainerData = ContainerData
> {
    pagination: PaginationHelpers<TLijst>;
    userData: TContainerData;
    onDrop: onDropItemHandler<TLijst, TContainerData>;
    onRemove?: onRemoveItemHandler<TLijst>;
    filters: TaakFilters;
}

export function TabelConnected<
    TLijst extends ITaakTabelLijstConnectedFragment = ITaakTabelLijstConnectedFragment,
    TContainerData extends ContainerData = ContainerData
>({filters, pagination, onRemove, userData, onDrop}: TabelConnectedProps<TLijst, TContainerData>) {
    const renderTabelLijst: RenderSortableTakenTabelLijstType<TLijst> = useCallback(
        ({item}: RenderSortableTakenTabelLijstProps<TLijst>) => (
            <SortableTakenTabelLijstConnected item={item} filters={filters} />
        ),
        [filters]
    );
    return (
        <Tabel<TLijst, TContainerData> pagination={pagination} userData={userData} onDrop={onDrop} onRemove={onRemove}>
            {renderTabelLijst}
        </Tabel>
    );
}
