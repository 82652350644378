import moment from 'moment';
import {asMomentValues, ISOStringDateRange, isSame} from '../Hook/useDateRangeSchema';

/**
 * When one value is after or before the other. We change the other value
 * base on difference.
 *
 * @param changedValue
 * @param previousValue
 */
export const normalizeDateRangeValue = (
    changedValue: ISOStringDateRange,
    previousValue: ISOStringDateRange
): ISOStringDateRange => {
    const toChanged = changedValue?.to;
    const fromChanged = changedValue?.from;
    if (!toChanged || !fromChanged) {
        return changedValue;
    }
    const {to, from} = asMomentValues({to: toChanged, from: fromChanged});

    if (!to || !from) {
        return changedValue;
    }

    if (to.isSameOrAfter(from)) {
        // Correct
        return changedValue;
    }

    const toPrevious = previousValue?.to;
    const fromPrevious = previousValue?.from;

    const {to: previousTo, from: previousFrom} = asMomentValues({to: toPrevious, from: fromPrevious});

    if (!previousTo || !previousFrom) {
        return {
            from: {
                ...fromChanged,
            },
            to: {
                ...toChanged,
                date: from.add(1, 'day').toISOString(),
            },
        };
    }

    const duration = moment.duration(previousTo.diff(previousFrom));

    if (previousTo && isSame(to, previousTo)) {
        return {
            from: {
                ...fromChanged,
            },
            to: {
                ...toChanged,
                date: from.add(duration).toISOString(),
            },
        };
    }
    return {
        from: {
            ...fromChanged,
            date: to.subtract(duration).toISOString(),
        },
        to: {
            ...toChanged,
        },
    };
};
