import {useContext, useEffect, useState} from 'react';
import {GeenToegangContext} from '../GeenToegangPaginaContext';

export interface GebruikerHeeftToegangOptions {
    /**
     * Default enabled when toegang is defined.
     */
    enabled?: boolean;
    toegang: boolean | undefined;
}

/**
 * Return the state of all global useGebruikerHeeftToegang components.
 */
export const useGebruikerHeeftToegang = (
    {toegang, enabled = toegang !== undefined}: GebruikerHeeftToegangOptions = {toegang: true}
) => {
    const context = useContext(GeenToegangContext);
    const [noAccess, setNoAccess] = useState<boolean>(context.denied);
    useEffect(() => {
        const subscription = context.listener.subscribe({
            next: setNoAccess,
        });
        if (!enabled) {
            return () => subscription.unsubscribe();
        }
        if (!toegang) {
            subscription.add(context.geenToegang());
        }
        setNoAccess(context.denied);
        return () => subscription.unsubscribe();
    }, [context, toegang, setNoAccess, enabled]);

    if (noAccess) {
        return false;
    }
    return !enabled || toegang;
};
