import {BaseButtonProps, Buttons, PopupControlsContext, useMemoryPopupControls} from '@growthbase/design-components';
import {IKopieerTaakButtonFragment} from '@growthbase/graphql';
import {FC, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {TaakKopierenForm, TaakKopierenFormValues} from '../TaakKopierenForm';

export interface KopieerTaakButtonProps {
    fragment?: IKopieerTaakButtonFragment;
    onSubmit: (values: TaakKopierenFormValues) => Promise<void>;
    Button: FC<BaseButtonProps>;
}

export const KopieerTaakButton: FC<KopieerTaakButtonProps> = ({Button = Buttons.Copy, onSubmit, fragment}) => {
    const controls = useMemoryPopupControls(false);
    const {t} = useTranslation('spa_form.nl');
    const submitAndClosePopup = useCallback(
        (values: TaakKopierenFormValues) => onSubmit(values).finally(controls.close),
        [onSubmit, controls.close]
    );
    if (!fragment || !fragment.availableActions.kanKopieren) {
        return null;
    }
    return (
        <>
            <Button onClick={controls.open}>{t('copy')}</Button>
            <PopupControlsContext.Provider value={controls}>
                <TaakKopierenForm onSubmit={submitAndClosePopup} fragment={fragment} />
            </PopupControlsContext.Provider>
        </>
    );
};
