import {LoaderModal, useGebruikerHeeftToegang} from '@growthbase/design-components';
import {useBestandViewQuery} from '@growthbase/graphql';
import {FC} from 'react';
import {BestandView} from './BestandView';

export const BestandViewConnected: FC<{
    bestandId: string;
}> = ({bestandId}) => {
    const {data, loading} = useBestandViewQuery({
        variables: {
            bestand: bestandId,
        },
    });

    const bestand = data?.bestand;
    const toegang = useGebruikerHeeftToegang({
        enabled: !loading,
        toegang: !!bestand,
    });
    if (!toegang || !bestand) {
        return <LoaderModal />;
    }

    const {path, type, uploaded, url} = bestand;

    return <BestandView type={type} path={path} url={url} uploaded={uploaded} />;
};
