import {useCallback, useEffect} from 'react';
import {usePlanningSetEventContext} from '@growthbase/planning';
import {Opdracht} from './Opdracht';

export const useSidebarState = (opdrachten: Array<Opdracht>) => {
    const setEventContext = usePlanningSetEventContext();

    // Set initial state
    useEffect(() => {
        setEventContext({
            context: {},
        });
    }, [setEventContext]);

    const handleKeuzeChange = useCallback(
        (keuze?: {opdrachtId: string; dienstId?: string}) => {
            if (!keuze) {
                // Reset event creation context
                setEventContext({
                    context: {},
                });
                return;
            }
            setEventContext({
                context: {
                    dienst: keuze.dienstId ? {id: keuze.dienstId} : undefined,
                    opdracht: opdrachten.find((opdracht) => opdracht.id === keuze.opdrachtId),
                },
            });
        },
        [opdrachten, setEventContext]
    );

    return {
        handleKeuzeChange,
    };
};
