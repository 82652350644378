import {ReactNode} from 'react';
import classNames from 'classnames/bind';
import styles from './FieldWarning.module.scss';

const cx = classNames.bind(styles);

export interface FieldWarningProps {
    children: ReactNode;
    negativeMargin?: boolean;
    triangle?: boolean;
}

export const FieldWarning = ({children, negativeMargin, triangle}: FieldWarningProps) => {
    const className = cx('fieldWarning', {
        'fieldWarning--negativeMargin': negativeMargin,
    });

    return (
        <div className={className}>
            {triangle && <div className={cx('fieldWarning__triangle')} />}
            <div className={cx('fieldWarning__content')}>{children}</div>
        </div>
    );
};
