import {useEffect, useState} from 'react';

export const useDebouncedCallback = <T>(
    value: T,
    setValue: (change: T) => void,
    timeout: number
): [T, (change: T) => void] => {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(() => {
        const handle = setTimeout(() => {
            if (value !== debouncedValue) {
                setValue(debouncedValue);
            }
        }, timeout);
        return () => clearTimeout(handle);
    }, [value, setValue, timeout, debouncedValue]);

    return [debouncedValue, setDebouncedValue];
};
