import {ReactNode} from 'react';
import {Heading4} from '@growthbase/design-components';

export interface CardHeaderTitleProps {
    children: ReactNode;
}

/**
 * @deprecated use Card.HeaderTitle instead
 */
export const CardHeaderTitle = ({children}: CardHeaderTitleProps) => <Heading4>{children}</Heading4>;
