import {useMemo} from 'react';
import {createURL, relativeURL} from '@growthbase/routing';

export type TabUrl<T> = {
    [TKey in keyof T]?: T[TKey] extends object ? TabUrl<T[TKey]> | true : true;
};

/**
 * Tab url is always based on the path.
 */
export const useInstellingTabUrl = <T extends object>(inputData: TabUrl<T>, url: string) => {
    // @ts-expect-error This is a recursive type
    type SimpleThree = Record<string, true | SimpleThree>;

    const createNestedPath = (input: SimpleThree, parent = ''): string => {
        const entries = Object.entries(input);
        for (const [key, value] of entries) {
            if (value === true) {
                return parent + key;
            }
            if (typeof input[key] === 'object') {
                return createNestedPath(input[key] as TabUrl<T>, `${parent}.${key}`);
            }
        }
        return '';
    };
    const result = createNestedPath(inputData);

    return useMemo(() => {
        const split = url.split('.');
        const parent = split.slice(0, split.length - 1).join('.');
        const object = createURL(url);
        object.searchParams.set(`${parent}_tab`, result);
        return relativeURL(object);
    }, [result, url]);
};
