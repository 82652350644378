import {
    createComboboxPlugin,
    createMentionPlugin,
    createPlateUI,
    createPlugins,
    createSingleLinePlugin,
    ELEMENT_MENTION_INPUT,
} from '@udecode/plate';
import {MentionInput} from '../MentionInput/MentionInput';

export const singleLinePlugins = createPlugins(
    [
        createMentionPlugin(),
        createSingleLinePlugin(),
        createComboboxPlugin(),
    ],
    {
        components: {
            ...createPlateUI(),
            [ELEMENT_MENTION_INPUT]: MentionInput,
        },
    }
);
