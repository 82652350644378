import {ISnoozeButtonFragment} from '@growthbase/graphql';
import {useTranslation} from 'react-i18next';

export interface SnoozeLabelProps {
    snoozed: ISnoozeButtonFragment;
}

export const SnoozeLabel = ({snoozed}: SnoozeLabelProps) => {
    const {t} = useTranslation('Taken.nl');

    if (!snoozed.snooze?.tot || !snoozed.snooze?.tot) {
        return null;
    }

    const date = Date.parse(snoozed.snooze.tot);

    // Snooze tot 23-02-2045 om 12:00 uur
    const formattedDate = new Intl.DateTimeFormat('nl-NL', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
    }).format(date);

    const formattedTime = new Intl.DateTimeFormat('nl-NL', {
        hour: 'numeric',
        minute: 'numeric',
    }).format(date);

    return (
        <>
            {t('GesnoozedTot', {
                date: formattedDate,
                time: formattedTime,
            })}
        </>
    );
};
