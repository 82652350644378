import {useCallbackRef} from '@growthbase/spa';

type CheckDateRange = {
    start: Date;
    end: Date;
};

export const useCheckDateOverlap = () =>
    useCallbackRef(
        (date1: CheckDateRange, date2: CheckDateRange): boolean => date1.start < date2.end && date1.end > date2.start
    );
