import {createMentionPlugin} from '@udecode/plate';
import {TComboboxItem} from '@udecode/plate-combobox/dist/types';
import {FC} from 'react';
import {RelatieMention} from './RelatieMention';
import {
    RelatiesComboboxItem,
    RelatiesComboboxConnected,
    RelatiesComboboxStub,
    RelatiesMentionKey,
    RelatiesMentionTrigger,
} from './RelatiesCombobox';

const createRelatiesMentionPlugin = (searchBar: FC) =>
    createMentionPlugin({
        key: RelatiesMentionKey,
        component: RelatieMention,
        renderAfterEditable: searchBar,
        options: {
            createMentionNode: (mention) => ({
                relatie: (mention as TComboboxItem<RelatiesComboboxItem>).data,
                children: [{text: ''}],
                type: RelatiesMentionKey,
                relatieId: mention.key,
            }),
            id: RelatiesMentionKey,
            trigger: RelatiesMentionTrigger,
        },
    });

export const createRelatiesMentionPluginStub = () => createRelatiesMentionPlugin(RelatiesComboboxStub);
export const createRelatiesMentionPluginConnected = () => createRelatiesMentionPlugin(RelatiesComboboxConnected);
