import {ITaakReactieCounterFragment} from '@growthbase/graphql';
import {memo, FC} from 'react';
import {TaakReactieCounter} from './TaakReactieCounter';

export interface TaakReactieCounterConnectedProps {
    fragment?: ITaakReactieCounterFragment;
}

export const TaakReactieCounterConnected: FC<TaakReactieCounterConnectedProps> = memo(({fragment}) => {
    if (fragment?.reacties?.totalCount) {
        return <TaakReactieCounter count={fragment.reacties.totalCount} />;
    }

    return null;
});

TaakReactieCounterConnected.displayName = 'TaakReactieCounterConnected';
