import {FieldInstance, ValueType} from '../contract';

export const flattenConditions = (field: FieldInstance): FieldInstance => {
    if (field.type === ValueType.conditions) {
        const conditions = field.value as FieldInstance[];
        const flattened = [];
        for (let i = 0; i < conditions.length; i += 1) {
            if (conditions[i].name === field.name) {
                flattened.push(...(conditions[i].value as FieldInstance[]));
                // eslint-disable-next-line no-continue
                continue;
            }
            flattened.push(conditions[i]);
        }
        return {
            ...field,
            value: flattened,
        };
    }
    return field;
};
