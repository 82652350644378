import {Editor, Transforms} from 'slate';
import {useGetActiveEditor} from '../Components';

export const useRemoveNodesByType = (editor?: Editor): ((type: string) => void) => {
    const getEditor = useGetActiveEditor();

    return (type: string) => {
        editor = getEditor() ?? editor;
        if (!editor) {
            throw new Error('No editor found');
        }
        Transforms.removeNodes(editor, {
            at: [],
            match: (node) => 'type' in node && node.type === type,
        });
    };
};
