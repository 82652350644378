import {useConvertFetchEntity, useExternalEntityAdded, useExternalEntityRemoved} from '@growthbase/cache';
import {
    ITaakBijlageFragment,
    refetchRefetchBijlagesToolTipQuery,
    refetchRefetchBijlagesOpenQuery,
} from '@growthbase/graphql';
import {useHandleBijlagesChanges} from '../../Bijlages';

export interface EntityChangesParams {
    taakId?: string | null;
    isOpen: boolean;
}

export const useEntityChanges = ({taakId, isOpen}: EntityChangesParams) => {
    const updateCache = useConvertFetchEntity(({id}) =>
        refetchRefetchBijlagesToolTipQuery({
            taakId: id,
        })
    );

    const updateThumbnails = useConvertFetchEntity(({id}) =>
        refetchRefetchBijlagesOpenQuery({
            taakId: id,
        })
    );

    const handle = useHandleBijlagesChanges(taakId, () => {
        updateCache({id: taakId ?? ''});
        if (isOpen) {
            updateThumbnails({id: taakId ?? ''});
        }
    });
    useExternalEntityAdded<ITaakBijlageFragment>(handle);
    useExternalEntityRemoved<ITaakBijlageFragment>(handle);
};
