import {ReactNode} from 'react';
import styles from './RadioSwitchWrapper.module.scss';

export interface RadioSwitchWrapperProps {
    children: ReactNode;
    testId?: string;
}

export const RadioSwitchWrapper = ({children, testId}: RadioSwitchWrapperProps) => (
    <div className={styles.radioSwitchWrapper} data-testid={testId}>
        {children}
    </div>
);
