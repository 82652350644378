import {BaseButtonProps, IconSize, NullableISOStringDateRange} from '@growthbase/design-components';
import {ITaakPlanningButtonFragment, useTaakPlanningStelPlanningInMutation} from '@growthbase/graphql';
import {useCallback, FC} from 'react';
import {useMapFragmentToValue} from './hook/useMapFragmentToValue';
import {TaakPlanningButton} from './TaakPlanningButton';

export interface TaakPlanningButtonConnectedProps {
    fragment?: ITaakPlanningButtonFragment;
    disabled?: boolean;
    asText?: boolean;
    textType?: 'deadline' | 'begindatum';
    AddButton?: FC<BaseButtonProps>;
    ModifyButton?: FC<BaseButtonProps>;
    iconSize?: IconSize;
}

export const TaakPlanningButtonConnected: FC<TaakPlanningButtonConnectedProps> = ({
    fragment,
    asText,
    disabled,
    AddButton,
    ModifyButton,
    iconSize,
    textType,
}) => {
    const [stelPlanningIn, {loading: stelPlanningInLoading}] = useTaakPlanningStelPlanningInMutation();

    const onSubmit = useCallback(
        async (range: NullableISOStringDateRange) => {
            const {id} = fragment ?? {};
            if (!id) {
                return;
            }
            await stelPlanningIn({
                variables: {
                    input: {
                        id,
                        begindatum: range.from
                            ? {
                                  dateTime: range.from.date,
                                  gebruikTijd: range.from.timeUsed,
                              }
                            : null,
                        deadline: range.to
                            ? {
                                  dateTime: range.to.date,
                                  gebruikTijd: range.to.timeUsed,
                              }
                            : null,
                    },
                },
            });
        },
        [fragment, stelPlanningIn]
    );

    const isDisabled = disabled || stelPlanningInLoading;
    return (
        <TaakPlanningButton
            AddButton={AddButton}
            ModifyButton={ModifyButton}
            asText={asText}
            textType={textType}
            onSubmit={onSubmit}
            value={useMapFragmentToValue(fragment)}
            readonly={!fragment?.availableActions?.kanPlanningBewerken}
            disabled={isDisabled}
        />
    );
};
