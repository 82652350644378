import {EventResource, ICapaciteitViewConnectedQuery} from '@growthbase/planning';
import {CapacityEvent} from '../CapacityEvent';

const getEvents = (medewerkers: ICapaciteitViewConnectedQuery['medewerkers']) => {
    const events: CapacityEvent[] = [];
    medewerkers.forEach((medewerker) => {
        medewerker.planning.timePlannedInDays.forEach((info) => {
            const event: CapacityEvent = {
                resource: medewerker.agenda.id,
                start: info.start,
                end: info.end,
                availableSeconds: info.timeAvailableInSeconds,
                plannedSeconds: 0,
                type: info.dayType,
            };
            events.push(event);
        });
    });
    return events;
};

const getResources = (medewerkers: ICapaciteitViewConnectedQuery['medewerkers']) => {
    const teams: EventResource[] = [];

    medewerkers.forEach((medewerker) => {
        const teamResource: EventResource = medewerker.team?.id
            ? medewerker.team
            : {
                  id: 'noteam',
                  name: 'Geen team',
                  children: [],
                  isParent: true,
                  eventCreation: false,
              };
        const teamId = `team-${teamResource.id}`;

        // Check if team id exists in teams array
        let team: EventResource | undefined = teams.find((findTeam) => findTeam.id === teamId);

        // Create team if not exists
        if (!team) {
            team = {
                isParent: true,
                eventCreation: false,
                id: teamId,
                name: teamResource.name,
                children: [],
            };
            teams.push(team);
        }

        if (!team.children) {
            team.children = [];
        }

        team.children.push({
            id: medewerker.agenda.id,
            name: medewerker.fullName,
            color: medewerker.agenda.color,
            resourceTime: medewerker.planning.resourceTime,
            weekNumberForStartDate: medewerker.planning.weekNumberForStartDate,
        });
    });

    return teams;
};

export const normalizeData = ({medewerkers}: ICapaciteitViewConnectedQuery) => ({
    resources: getResources(medewerkers),
    events: getEvents(medewerkers),
});
