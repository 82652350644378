import {NullableISOStringDate} from '@growthbase/design-components';
import {ISnoozeButtonFragment} from '@growthbase/graphql';
import {useMemo} from 'react';
import moment from 'moment';

export const useMapFragmentToValue = (fragment?: ISnoozeButtonFragment): NullableISOStringDate => {
    const tot = fragment?.snooze?.tot;

    return useMemo((): NullableISOStringDate => {
        if (!tot) {
            return {
                date: null,
            };
        }
        return {
            date: {
                date: tot,
                timeUsed: moment(tot).format('HH:mm:ss') !== '00:00:00',
            },
        };
    }, [tot]);
};
