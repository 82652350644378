import {ReactNode} from 'react';
import {FloatingPortal} from '@floating-ui/react-dom-interactions';

export interface CalendarPortalProps {
    children: ReactNode;
}

export const PageBodyPortal = ({children}: CalendarPortalProps) => {
    const portalElement = document.querySelector<HTMLElement>('.pageBody') || document.body;
    return (
        <FloatingPortal id="calendar-portal" root={portalElement}>
            {children}
        </FloatingPortal>
    );
};
