import React, {FC, useMemo} from 'react';
import {useEmailFormulierQuery, useVerzendEmailMutation} from '@growthbase/graphql';
import {Loader} from '@growthbase/design-components';
import {useCallbackRef} from '@growthbase/spa';
import {EmailFormulier} from './EmailFormulier';
import {DTO} from './Hook/useFields';

export const EmailFormulierConnected: FC = () => {
    const {data} = useEmailFormulierQuery();

    const [mutation] = useVerzendEmailMutation();

    const melding = useMemo((): DTO | null => {
        const m = data?.ingelogdeGebruiker?.emailMeldingen;
        if (!m) {
            return null;
        }
        return {
            van: m.van ?? new Date().toISOString(),
            tot: m.tot ?? new Date().toISOString(),
        };
    }, [data?.ingelogdeGebruiker?.emailMeldingen]);

    const verzend = useCallbackRef(
        (dto: DTO): Promise<void> =>
            mutation({
                variables: {
                    input: {
                        van: dto.van,
                        tot: dto.tot,
                    },
                },
            }).then()
    );

    if (!melding) {
        return <Loader center />;
    }

    return <EmailFormulier onSubmit={verzend} dto={melding} view={false} />;
};
