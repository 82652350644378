import {
    IKoppelAanTaakButtonFragment,
    useKoppelAanTaakButtonKoppelAbonnementMutation,
    useKoppelAanTaakButtonKoppelOpdrachtMutation,
    useKoppelAanTaakButtonKoppelTicketMutation,
    useKoppelAanTaakButtonVerwijderAbonnementMutation,
    useKoppelAanTaakButtonVerwijderOpdrachtMutation,
    useKoppelAanTaakButtonVerwijderTicketMutation,
} from '@growthbase/graphql';
import {useCallbackRef, useWrapMutationWithErrorHandler} from '@growthbase/spa';
import {KoppelbaarItem, KoppelbaarItemType} from '../../KoppelPopup';
import {useNormalizeTaakOnderdeelVanFragmentFactory} from '../../KoppelButton/KoppelButton/Hook/useNormalizeTaakOnderdeelVanFragment';

export const useWijzigGekoppeldeItem = (fragment: IKoppelAanTaakButtonFragment) => {
    const koppelOpdracht = useWrapMutationWithErrorHandler(useKoppelAanTaakButtonKoppelOpdrachtMutation());
    const verwijderOpdracht = useWrapMutationWithErrorHandler(useKoppelAanTaakButtonVerwijderOpdrachtMutation());
    const koppelAbonnement = useWrapMutationWithErrorHandler(useKoppelAanTaakButtonKoppelAbonnementMutation());
    const verwijderAbonnement = useWrapMutationWithErrorHandler(useKoppelAanTaakButtonVerwijderAbonnementMutation());
    const koppelTicket = useWrapMutationWithErrorHandler(useKoppelAanTaakButtonKoppelTicketMutation());
    const verwijderTicket = useWrapMutationWithErrorHandler(useKoppelAanTaakButtonVerwijderTicketMutation());
    const taakId = fragment.id;

    const handleOnRemove = useCallbackRef((item: KoppelbaarItem) => {
        switch (item.type) {
            case KoppelbaarItemType.Opdracht:
                return verwijderOpdracht({
                    variables: {
                        input: {
                            id: taakId,
                        },
                    },
                });
            case KoppelbaarItemType.Abonnement:
                return verwijderAbonnement({
                    variables: {
                        input: {
                            id: taakId,
                        },
                    },
                });
            case KoppelbaarItemType.Ticket:
                return verwijderTicket({
                    variables: {
                        input: {
                            id: taakId,
                        },
                    },
                });
            default:
                throw new Error(`Unsupported KoppelPopupType "${item.type}".`);
        }
    });

    const handleOnChange = useCallbackRef((item: KoppelbaarItem) => {
        switch (item.type) {
            case KoppelbaarItemType.Abonnement:
                return koppelAbonnement({
                    variables: {
                        input: {
                            abonnementId: item.id,
                            id: taakId,
                        },
                    },
                });
            case KoppelbaarItemType.Opdracht:
                return koppelOpdracht({
                    variables: {
                        input: {
                            opdrachtId: item.id,
                            id: taakId,
                        },
                    },
                });
            case KoppelbaarItemType.Ticket:
                return koppelTicket({
                    variables: {
                        input: {
                            ticketId: item.id,
                            id: taakId,
                        },
                    },
                });
            default:
                throw new Error(`Unsupported KoppelPopupType "${item.type}".`);
        }
    });
    const normalizeIKoppelButtonConnectedFragment = useNormalizeTaakOnderdeelVanFragmentFactory();

    const onChange = useCallbackRef((item: KoppelbaarItem) => {
        const algekoppeld = normalizeIKoppelButtonConnectedFragment(fragment);
        if (algekoppeld !== null) {
            if (algekoppeld.type === item.type && algekoppeld.id === item.id) {
                return Promise.resolve();
            }
            // TODO: Zou mooier zijn als hier een saga voor was.
            return handleOnRemove(algekoppeld).then(() => handleOnChange(item));
        }
        return handleOnChange(item);
    });

    const onRemove = useCallbackRef(() => {
        const item = normalizeIKoppelButtonConnectedFragment(fragment);
        if (item === null) {
            throw new Error();
        }
        return handleOnRemove(item);
    });

    return {onChange, onRemove};
};
