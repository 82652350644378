import {CellProps, Column} from 'react-table';
import {Table} from '@growthbase/design-components';
import {PaginationHelpers} from '@growthbase/spa';
import {FC, ReactNode} from 'react';
import {DeveloperRoutes} from '@growthbase/health';
import {Link} from 'react-router-dom';

export interface ApplicationLogDTO {
    id: string;
    message: ReactNode;
    info: ReactNode;
    profiel: ReactNode;
    errorIds: ReactNode;
    source: ReactNode;
}
const OpenLogs: FC<CellProps<ApplicationLogDTO, ReactNode>> = ({
    value,
    row: {
        original: {id},
    },
}) => {
    const href = DeveloperRoutes.log.useUrl({
        id,
    });
    return <Link to={href}>{value}</Link>;
};

const columns: ReadonlyArray<Column<ApplicationLogDTO>> = [
    {
        Header: 'Info',
        accessor: 'info',
        width: 50,
        Cell: OpenLogs,
    },
    {
        Header: 'Profiel',
        accessor: 'profiel',
        width: 100,
    },
    {
        Header: 'Source',
        accessor: 'source',
        width: 50,
    },
    {
        Header: 'Error',
        accessor: 'errorIds',
        width: 50,
    },
    {
        Header: 'Bericht',
        accessor: 'message',
        width: 200,
    },
];

export interface ApplicationLogsTableProps {
    pagination: PaginationHelpers<ApplicationLogDTO>;
}

export const ApplicationLogsTable: FC<ApplicationLogsTableProps> = ({pagination}) => (
    <Table<ApplicationLogDTO> pagination={pagination} columns={columns} />
);
