import {ViewConfig} from '@growthbase/design-components';
import {ITaaktemplateKaartTitelFragment} from '@growthbase/graphql';
import {FC} from 'react';
import {Titel} from './Titel';

export interface TitelConnectedProps extends ViewConfig {
    fragment: ITaaktemplateKaartTitelFragment;
}

export const TitelConnected: FC<TitelConnectedProps> = ({fragment, ...props}) => (
    <Titel
        titel={fragment.titel}
        id={fragment.id}
        {...props}
        readonly={!fragment.availableActions.KanTitelWijzigen || props.readonly}
    />
);
