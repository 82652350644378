import {Observable, Subscriber} from 'rxjs';

/**
 * Global ajax, don't use require for this.
 * @constructor
 */
export const JQueryAjaxCompleted: () => Observable<void> = () =>
    new Observable((observer: Subscriber<void>) => {
        $.ajaxSetup({
            complete: () => {
                observer.next();
            },
        });
    });
