/* eslint-disable */
import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {
    useResolveConnections,
    useTaakSearchFieldQuery,
    useTaakSearchFieldTaakQuery,
    ITaakSearchFieldFragment,
} from '@growthbase/graphql';
import {compareIds, idFromIri} from '@growthbase/routing';
import {TaakSearchDTO} from '../Hook/useExtractValueFromElement';
import {AutoCompleteControlled} from '@growthbase/design-elements';
import {throttle} from 'lodash';
import {useTranslation} from 'react-i18next';

export interface TaakSearchFieldConnectedProps {
    value: TaakSearchDTO;
    onChangeTaakId?: (value: string | null) => void;
    setShow: (show: boolean) => void;
}

export const TaakSearchFieldConnected: FC<TaakSearchFieldConnectedProps> = ({value, onChangeTaakId, setShow}) => {
    const {taakId: id, opdrachtId, ticketId, persoonId, bedrijfId, disabled, readonly} = value;
    const [query, setQuery] = useState<string | null>(null);
    const {t} = useTranslation('ClientboxUren.nl');
    const onChange = useCallback(
        (value: string | null) => {
            if (onChangeTaakId) {
                onChangeTaakId(value);
            }
        },
        [onChangeTaakId]
    );

    const {data: taakData} = useTaakSearchFieldTaakQuery({
        skip: !id,
        variables: {
            id: id ?? undefined,
        },
    });
    const heeftOnderdeel = !!(opdrachtId || ticketId);
    const {data, loading} = useTaakSearchFieldQuery({
        variables: {
            query: query ? query : undefined,
            onderdeelVan: opdrachtId || ticketId || undefined,
            relatieverwijzing: persoonId || bedrijfId || undefined,
        },
        pollInterval: 5000,
    });
    const taken = useResolveConnections(data?.items);
    const edges = taakData?.Taken?.edges;

    const updatedTaak = useMemo((): ITaakSearchFieldFragment | null => {
        const all = edges || [];
        const first = all[0];
        const node = first?.node;
        if (node && id && compareIds(node.id, id)) {
            return {
                id: idFromIri(node.id),
                titel: node.titel,
            };
        }
        return null;
    }, [id, edges]);

    const options = useMemo(() => {
        const search = taken.map((taak) => ({
            label: taak.titel,
            value: idFromIri(taak.id),
        }));
        if (updatedTaak && search.every((taak) => taak.value !== updatedTaak.id)) {
            return search.concat([{label: updatedTaak.titel, value: updatedTaak.id}]);
        }
        return search;
    }, [taken, updatedTaak]);

    const foundInOptions = options.find((option) => option.value === id);

    const changeQueryThrottled = useMemo(() => throttle(setQuery, 500), []);

    const cantSelect = !query && !options.length && !query;

    const cantOnlyBoolOnRelation =
        !taakData?.huidigProfiel?.profielUrenInstellingen?.directBoekenOpRelatie && !heeftOnderdeel;

    const show = !(cantSelect || disabled || cantOnlyBoolOnRelation);

    /**
     * If there are no tasks found, but the query is not empty, we should show the search field
     */
    useEffect(() => {
        setShow(show);
    }, [show, setShow]);

    if (!show) {
        return null;
    }

    return (
        <AutoCompleteControlled
            disabled={!show}
            readonly={readonly}
            label={t('Taak')}
            placeholder={(cantSelect ? t('Geen taken beschikbaar') : t('Kiest taak')) ?? ''}
            options={options}
            value={foundInOptions ? id : ''}
            loading={loading}
            search={query}
            onChangeValue={onChange}
            onSearchChange={async (change) => changeQueryThrottled(change)}
        />
    );
};
