import {ButtonProps, Buttons} from '@growthbase/design-components';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';

export interface ViewButtonProps {
    Button?: FC<ButtonProps>;
    to?: string;
}

export const ViewButton: FC<ViewButtonProps> = ({to, Button = Buttons.Search}) => {
    const {t} = useTranslation('TakenlijstBord.nl');
    const title = t('bekijken');
    return (
        <Button key="add" to={to} tooltip={title} ariaLabel={title}>
            {title}
        </Button>
    );
};
