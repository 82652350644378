import {SidenavContext} from '@growthbase/design-components';
import {useLogger} from '@growthbase/spa';
import {FC, useContext, useEffect} from 'react';

export const PositionGlobalHTMLContent: FC = () => {
    const logger = useLogger('PositionGlobalHTMLContent');
    const context = useContext(SidenavContext);

    useEffect(() => {
        if (!context) {
            return () => false;
        }

        const {isExpanded, toggleExpanded} = context;

        const bodyElement = document.body;
        if (bodyElement) {
            bodyElement.classList.add('new-menu');
            bodyElement.classList.toggle('highlight-main-sidebar', isExpanded);
        }

        const collapseSidebar = () => toggleExpanded(false);

        document.addEventListener('collapse-sidebar', collapseSidebar);

        return () => {
            document.removeEventListener('collapse-sidebar', collapseSidebar);
        };
    }, [context, logger]);

    return null;
};
