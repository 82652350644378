import {usePopup} from '@growthbase/design-components';
import {ITaakEditPopupHeaderFragment, ITaakEditPopupHeaderProfielFragment} from '@growthbase/graphql';
import {ReactNode, useCallback, FC} from 'react';
import {noop} from 'rxjs';
import {PriveFieldConnected, KopieerTaakButtonConnected, TaakEditFragment} from '@growthbase/taken';
import {TakenRoutes} from '../../TaakRoutes';
import {VerwijderTaakButtonConnected} from '../../TaakVerwijderen';
import {VoltooiOfActiveerTaakButtonConnected} from '../../VoltooiOfActiveerTaakButton';
import {TaakEditPopupHeader} from './TaakEditPopupHeader';
import {ArchiveerTaakButtonConnected} from '../../TaakArchiveren/ArchiveerTaakButton/ArchiveerTaakButtonConnected';
import {VolgersConnected} from '../../Volgers';
import {TaakMijnDagButtonConnected} from '../../TaakMijnDagButton';

export interface TaakEditPopupHeaderConnectedProps {
    fragment?: ITaakEditPopupHeaderFragment | TaakEditFragment;
    profielFragment?: ITaakEditPopupHeaderProfielFragment;
    children: ReactNode;
}

export const TaakEditPopupHeaderConnected: FC<TaakEditPopupHeaderConnectedProps> = ({
    children,
    fragment,
    profielFragment,
}) => {
    const {close} = usePopup();
    const edit = TakenRoutes.edit.useNavigateFactory();

    return (
        <TaakEditPopupHeader
            VoltooiOfActiveerTaakButton={VoltooiOfActiveerTaakButtonConnected}
            VerwijderTaakButton={VerwijderTaakButtonConnected}
            KopieerTaakButton={KopieerTaakButtonConnected}
            ArchiveerTaakButton={ArchiveerTaakButtonConnected}
            TaakMijnDagButton={TaakMijnDagButtonConnected}
            VolgersButton={VolgersConnected}
            PriveTaakButton={PriveFieldConnected}
            fragment={fragment}
            onDeleted={close}
            onCreated={useCallback((id) => edit({id}, {replace: true}), [edit])}
            onArchived={noop}
            profielFragment={profielFragment}
        >
            {children}
        </TaakEditPopupHeader>
    );
};
