import {useCallback} from 'react';
import {InstellingValues} from '../types';
import {InstellingFieldCollection} from '../InstellingFieldCollection';

export const createInitialValues = (fields: InstellingFieldCollection) => {
    const values: InstellingValues = {};
    for (const field of fields.getFields()) {
        if ('name' in field) {
            values[field.basePath] =
                'fields' in field ? createInitialValues(fields.filter(field.fields)) : field.defaultValue;
        }
    }
    return values;
};

export const useCreateInitialValues = (fields: InstellingFieldCollection, valuesInput: unknown) =>
    useCallback(() => {
        const defaults = createInitialValues(fields);
        if (typeof valuesInput === 'object' && valuesInput !== null) {
            return {
                ...defaults,
                ...valuesInput,
            };
        }
        return defaults;
    }, [fields, valuesInput]);
