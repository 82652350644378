import {
    BordenPaginaConnected,
    BordenPaginaConnectedProps,
    BordPaginaConnected,
    BordPaginaConnectedProps,
    TaakAanmakenModalConnected,
    TaakAanmakenPaginaConnectedProps,
} from '@growthbase/tickets-taken';
import {
    assertIdFromIri,
    createLegacyRoutes,
    CreateRouteFunction,
    createRoutes,
    LEGACY_ROUTE,
} from '@growthbase/routing';
import {generatePath} from 'react-router-dom';
import {assertClientboxIdentifier} from '@growthbase/clientbox';
import React from 'react';

const createRoute: CreateRouteFunction<{
    ticketId: string;
    ticketnummer: string;
    ticketUuid: string;
}> = (route: string, params) => {
    if (!params) {
        throw new Error('Logic exception.');
    }
    const {ticketId, ticketUuid, ticketnummer} = params;
    return generatePath(route, {
        ticketnummer,
        ticketId: assertClientboxIdentifier(ticketId),
        ticketUuid: assertIdFromIri(ticketUuid),
    });
};

const generateBordRoute: CreateRouteFunction<{
    ticketId: string;
    ticketnummer: string;
    ticketUuid: string;
    bordUuid: string;
}> = (route: string, params) => {
    if (!params) {
        throw new Error('Logic exception.');
    }
    const {ticketId, ticketUuid, ticketnummer, bordUuid} = params;
    return generatePath(route, {
        ticketnummer,
        ticketId: assertClientboxIdentifier(ticketId),
        ticketUuid: assertIdFromIri(ticketUuid),
        bordUuid: assertIdFromIri(bordUuid),
    });
};

export const TicketRoutes = createRoutes('ticket', {
    borden: {
        Element: (props: BordenPaginaConnectedProps) => <BordenPaginaConnected {...props} />,
        createRoute,
    },
    bord: {
        Element: (props: BordPaginaConnectedProps) => <BordPaginaConnected {...props} />,
        createRoute: generateBordRoute,
    },
    taak_aanmaken: {
        Element: (props: TaakAanmakenPaginaConnectedProps) => <TaakAanmakenModalConnected {...props} />,
        createRoute,
        defaultBackgroundRoute: 'borden',
    },
    index: {
        Element: LEGACY_ROUTE,
    },
    view: {
        Element: LEGACY_ROUTE,
    },
    read: {
        Element: LEGACY_ROUTE,
    },
    dashboard_read: {
        Element: LEGACY_ROUTE,
    },
});

export const TicketLegacyRoutes = createLegacyRoutes('ticket', {
    borden: {
        createRoute,
    },
    bord: {
        createRoute,
    },
    dashboard_read: {createRoute},
    taak_aanmaken: {createRoute},
    index: {createRoute},
    view: {createRoute},
    read: {createRoute},
});
