import {useCallback, useRef, useState} from 'react';
import {noop, Subject} from 'rxjs';
import {v4} from 'uuid';
import {GeenToegangContextApi} from '../GeenToegangPaginaContext';

export const useGebruikerHeeftToegangContextApi = () => {
    const denies = useRef<string[]>([]);
    const listeners = useRef<Subject<boolean>>(new Subject());
    const [denied, setDenied] = useState(false);

    const ref = useRef<GeenToegangContextApi>({
        geenToegang: () => noop,
        denied: false,
        listener: listeners.current,
    });

    const geenToegang = useCallback(() => {
        const id = v4();
        denies.current.push(id);
        ref.current.denied = true;
        setDenied(true);
        listeners.current.next(true);
        return () => {
            denies.current = denies.current.filter((deny) => deny !== id);
            if (denies.current.length === 0) {
                setDenied(false);
                listeners.current.next(false);
                ref.current.denied = false;
            }
        };
    }, [denies, listeners]);

    ref.current.geenToegang = geenToegang;

    return {
        api: ref.current,
        denied,
    };
};
