import {useContext} from 'react';
import {PopupControls, PopupControlsContext} from '../PopupControls';

export const usePopup = (): PopupControls => {
    const context = useContext(PopupControlsContext);
    if (context) {
        return context;
    }
    throw new Error('Wrap popup in popup provider');
};
