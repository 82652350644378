import {debounceTime, distinctUntilChanged, EMPTY, fromEvent, interval, map, mergeMap, of, merge} from 'rxjs';
import moment, {Moment} from 'moment';
import {Logger} from '../Utils';
import {ConfiguredElement} from '../rxjs';
import {DateOffsetConfigDTO} from './DTO/DateOffsetConfigDTO';

const DATE_FORMAT = 'DD-MM-YYYY';

export const apply =
    (logger: Logger) =>
    ({element, dto}: ConfiguredElement<DateOffsetConfigDTO>) => {
        const fromDate = document.getElementsByName(dto.fromDate)[0] as HTMLInputElement;
        const toDate = document.getElementsByName(dto.toDate)[0] as HTMLInputElement;
        const offset = document.getElementsByName(dto.offset)[0] as HTMLInputElement;
        const type = document.getElementsByName(dto.type)[0] as HTMLInputElement;

        if (!fromDate || !toDate || !offset || !type) {
            logger.error('DateOffsetTypeExtension: Missing input fields!', dto);
            return EMPTY;
        }

        return of(fromDate, toDate, offset, type).pipe(
            mergeMap((nextElement) =>
                merge(
                    fromEvent(nextElement, 'change'),
                    fromEvent(nextElement, 'blur'),
                    fromEvent(nextElement, 'input'),
                    interval(300) // Select2 does not trigger changes... grr
                ).pipe(
                    map(() => nextElement.value),
                    distinctUntilChanged()
                )
            ),
            debounceTime(1),
            map(() => {
                const fromDateValue: Moment = moment(fromDate.value, DATE_FORMAT);
                const toDateValue: Moment = moment(toDate.value, DATE_FORMAT);
                const offsetValue = parseInt(offset.value, 10);
                const typeValue = type.value;
                let date!: Moment;
                switch (true) {
                    case typeValue.includes('BEFORE'):
                        date = fromDateValue.clone().subtract(offsetValue, 'days');
                        break;
                    case typeValue.includes('AFTER'):
                        date = toDateValue.clone().add(offsetValue, 'days');
                        break;
                    default:
                        logger.error('typeValue not supported', typeValue);
                        return null;
                }

                if (date.isValid()) {
                    element.textContent = date.format(DATE_FORMAT);
                } else {
                    element.textContent = '-';
                }

                return {
                    date: element.textContent,
                    dto,
                    fromDateValue,
                    toDateValue,
                    offsetValue,
                    typeValue,
                };
            })
        );
    };
