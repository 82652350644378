import {FC, useEffect} from 'react';
import {useActieveTijdRegistratieQuery} from '@growthbase/planning';
import {useService} from '@growthbase/spa';
import {noop} from 'lodash';
import {useActiveTimeRegistration, useUpdateActiveTimeregistration} from './Hook/useLegacyTimeRegistration';

declare global {
    interface LegacyRegistrationTimeData {
        id: string;
        fromTimestamp: number;
        taak?: string;
    }
    interface Window {
        legacyTimeRegistration?: {
            addListener: (listener: (data: LegacyRegistrationTimeData | null) => void) => void;
            removeListener: (listener: (data: LegacyRegistrationTimeData | null) => void) => void;
            updateAppData: () => Promise<void>;
        };
    }
}

export const LegacyTimeRegistrationProvider: FC = () => {
    const {
        config: {takenEnabled},
    } = useService();
    useActieveTijdRegistratieQuery({
        skip: !takenEnabled,
        pollInterval: 10000,
    });
    const update = useUpdateActiveTimeregistration();
    const active = useActiveTimeRegistration();
    useEffect(() => {
        if (!window.legacyTimeRegistration) {
            return noop;
        }
        let previous: LegacyRegistrationTimeData | null = null;
        const listener = (data: LegacyRegistrationTimeData | null) => {
            if (previous === data || previous?.id === data?.id) {
                return;
            }
            previous = data;
            if (active?.id === data?.id) {
                return;
            }
            update();
        };
        window.legacyTimeRegistration.addListener(listener);
        return () => {
            window.legacyTimeRegistration?.removeListener(listener);
        };
    }, [update, active]);
    return null;
};
