import {IMedewerkerFragment} from '@growthbase/planning';
import {MbscResource} from '@mobiscroll/react';
import {useMemo} from 'react';

export const useNormalizeMedewerkerAgenda = () =>
    useMemo(
        () =>
            (medewerker: IMedewerkerFragment): MbscResource => ({
                id: medewerker.agenda.id,
                name: medewerker.agenda.name,
                color: medewerker.agenda.color,
            }),
        []
    );
