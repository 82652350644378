import {IPlanningSidebarConnectedQuery} from '@growthbase/planning';
import {useCallback} from 'react';
import {useDienstenNormalizer} from './useDienstenNormalizer';

export const useOpdrachtenNormalizer = () => {
    const normalizeDiensten = useDienstenNormalizer();

    return useCallback(
        (data?: IPlanningSidebarConnectedQuery) => {
            if (!data?.Opdrachten?.edges) {
                return [];
            }

            return data.Opdrachten.edges.map((edge) => ({
                id: edge?.node?.id ?? '',
                relatie: edge?.node?.relatienaam ?? '',
                onderwerp: edge?.node?.onderwerp ?? '',
                nummer: edge?.node?.nummer ?? '',
                diensten: normalizeDiensten(edge?.node?.diensten),
            }));
        },
        [normalizeDiensten]
    );
};
