import {AnchorHTMLAttributes, DetailedHTMLProps, ReactNode} from 'react';
import classNames from 'classnames/bind';
import styles from './SwitchButton.module.scss';
import {Icons} from '../../icons';

const cx = classNames.bind(styles);

export interface SwitchButtonProps
    extends DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
    children: ReactNode;
    active?: boolean;
}

export const SwitchButton = ({children, active, className, ...props}: SwitchButtonProps) => {
    const classes = cx('switchButton', {
        'switchButton--active': active,
    });

    return (
        <a {...props} className={`${classes} ${className}`}>
            <div className={styles.switchButton__text}>{children}</div>
            {active && <Icons.CheckmarkCircle className={styles.switchButton__icon} />}
        </a>
    );
};
