import {useCallback, useEffect} from 'react';
import {usePlanningSetEventContext, usePlanningSetDienst} from '@growthbase/planning';
import {Ticket} from '../PlanningSidebar/Ticket';

export const useTicketEventState = (ticket: Ticket) => {
    const setEventContext = usePlanningSetEventContext();
    const setDienst = usePlanningSetDienst();

    // Set initial state
    useEffect(() => {
        setEventContext({
            context: {
                ticket: {
                    nummer: ticket.code,
                    ...ticket,
                },
            },
        });
    }, [setEventContext, ticket]);

    const handleKeuzeChange = useCallback(
        (dienstId?: string) => {
            setDienst({
                dienst: dienstId ? {id: dienstId} : undefined,
            });
        },
        [setDienst]
    );

    return {
        handleKeuzeChange,
    };
};
