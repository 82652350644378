import React, {FC, PropsWithChildren, useMemo} from 'react';
import {GeenToegangPagina} from './GeenToegangPagina';
import {GeenToegangContext} from './GeenToegangPaginaContext';
import {useGebruikerHeeftToegangContextApi} from './Hook/useGebruikerHeeftToegangContextApi';

export type GeenToegangPaginaConnectedProps = PropsWithChildren;

export const GoHome = () => {
    window.location.href = '';
};

/**
 * We cannot dismount the component because that would re-enable the page.
 */
export const GeenToegangPaginaConnected: FC<GeenToegangPaginaConnectedProps> = ({children}) => {
    const {denied, api} = useGebruikerHeeftToegangContextApi();
    return (
        <GeenToegangContext.Provider value={api}>
            {denied && <GeenToegangPagina onClientClick={GoHome} />}
            <div style={useMemo(() => ({display: denied ? 'none' : 'block', height: '100%'}), [denied])}>
                {children}
            </div>
        </GeenToegangContext.Provider>
    );
};
