import React from 'react';
import classNames from 'classnames';
import {TableInterface} from '../TableInterface';

import styles from '../Table.module.scss';

export interface TableColumnHeaderProps<TRow extends object = object> {
    table: TableInterface<TRow>;
}

/* eslint-disable react/jsx-key */

export function TableColumnHeader<TRow extends object = object>({table: {headerGroups}}: TableColumnHeaderProps<TRow>) {
    return (
        <div>
            {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className={classNames([styles.table__tr])}>
                    {headerGroup.headers.map((column) => (
                        <div data-test="header" {...column.getHeaderProps()} className={classNames([styles.table__th])}>
                            {column.render('Header')}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}
