import {IEntityReferenceLinksFragment} from '@growthbase/graphql';
import {FC} from 'react';
import {EntityReferenceLink} from './EntityReferenceLink';

export interface EntityReferenceLinksProps {
    fragments: (IEntityReferenceLinksFragment | null)[];
}

export const EntityReferenceLinks: FC<EntityReferenceLinksProps> = ({fragments}) => (
    <div>{fragments.map((fragment) => fragment && <EntityReferenceLink key={fragment.id} fragment={fragment} />)}</div>
);
