import {ReactNode} from 'react';
import {Wrapper} from './components/Wrapper/Wrapper';
import {Title} from './components/Title/Title';
import {Info} from './components/Info/Info';
import {useAutoContrastColor} from '../../../Typography/AutoContrastColor/useAutoContrastColor';

export interface CalendarEventProps {
    children: ReactNode;
    info?: ReactNode;
    disabled?: boolean;
    backgroundColor?: string;
}

// eslint-disable-next-line no-shadow
export enum TextColorMode {
    LIGHT = 'light',
    DARK = 'dark',
}

export const CalendarEvent = ({children, info, backgroundColor = '#FFE09A', disabled}: CalendarEventProps) => {
    const cc = useAutoContrastColor(backgroundColor);
    const textColorMode: TextColorMode = cc === 'black' ? TextColorMode.DARK : TextColorMode.LIGHT;

    return (
        <Wrapper disabled={disabled} backgroundColor={backgroundColor}>
            <Title colorMode={textColorMode}>{children}</Title>
            {info && <Info colorMode={textColorMode}>{info}</Info>}
        </Wrapper>
    );
};
