import React, {FC, PropsWithChildren} from 'react';
import {Loader, Padding, Popup, PopupContent, PopupHeader, Stack} from '@growthbase/design-components';
import {useApplicationLogQuery} from '@growthbase/graphql';
import {ObjectInspector} from 'react-inspector';
import {Stacktrace} from './Component/Stacktrace';
import {LogFilter, useFilterBarStateUrlFactory} from '../ApplicationLogsTable/Components';

export interface ApplicationLogConnectedProps {
    id: string;
}

const Section: FC<{name: string; filter: LogFilter | boolean | undefined | null | ''} & PropsWithChildren> = ({
    name,
    filter,
    children,
}) => {
    const [, navigate] = useFilterBarStateUrlFactory();
    if (children === null) {
        return null;
    }
    if (typeof filter !== 'boolean' && filter) {
        children = (
            // eslint-disable-next-line jsx-a11y/interactive-supports-focus
            <div
                role="button"
                onClick={() => navigate([filter])}
                style={{
                    cursor: 'pointer',
                }}
            >
                {children}
            </div>
        );
    }

    return (
        <Stack.Item>
            <Stack horizontal gap="tiny">
                <Stack.Item>{name}:</Stack.Item> {children}
            </Stack>
        </Stack.Item>
    );
};

export const ApplicationLogConnected: FC<ApplicationLogConnectedProps> = ({id}) => {
    const {data} = useApplicationLogQuery({
        variables: {
            id,
        },
    });

    const log = data?.log;
    if (!log) {
        return (
            <Popup>
                <PopupContent>
                    <PopupHeader title={id} />
                    <Loader center />
                </PopupContent>
            </Popup>
        );
    }
    const {
        profielId,
        profielNaam,
        errorId,
        errorCode,
        message,
        timestamp,
        level,
        application,
        source,
        processId,
        previousProcessId,
        transactionDepth,
        transactionId,
        gebruikersNaam,
        exception,
        facility,
        gebruikerId,
    } = log;
    return (
        <Popup>
            <PopupContent
                width="full"
                style={{
                    maxWidth: window.innerWidth - 100,
                }}
            >
                <PopupHeader title={message} />
                <div style={{backgroundColor: 'white'}}>
                    <Padding>
                        <Stack gap="tiny">
                            <Section name="id" filter={false}>
                                {id}
                            </Section>
                            <Section name="timestamp" filter={false}>
                                {timestamp}
                            </Section>
                            <Section name="level" filter={false}>
                                {level}
                            </Section>
                            <Section
                                name="application"
                                filter={
                                    application && {
                                        clientbox: application === 'clientbox' ? true : undefined,
                                        growthbase: application === 'growthbase' ? true : undefined,
                                    }
                                }
                            >
                                {application}
                            </Section>
                            <Section
                                name="facility"
                                filter={
                                    facility && {
                                        facilities: [facility],
                                    }
                                }
                            >
                                {facility}
                            </Section>
                            <Section
                                name="profiel"
                                filter={
                                    profielId && {
                                        profielId,
                                    }
                                }
                            >
                                {profielNaam} ({profielId})
                            </Section>
                            <Section
                                name="gebruiker"
                                filter={
                                    gebruikerId && {
                                        gebruikerId,
                                    }
                                }
                            >
                                {gebruikersNaam} ({gebruikerId})
                            </Section>
                            <Section
                                name="processId"
                                filter={
                                    processId && {
                                        processIds: [processId],
                                    }
                                }
                            >
                                {processId}
                            </Section>
                            <Section
                                name="previousProcessId"
                                filter={
                                    previousProcessId && {
                                        previousProcessIds: [previousProcessId],
                                    }
                                }
                            >
                                {previousProcessId}
                            </Section>
                            <Section
                                name="transactionDepth"
                                filter={
                                    typeof transactionDepth === 'number' && {
                                        transactionDepth: [transactionDepth],
                                    }
                                }
                            >
                                {transactionDepth}
                            </Section>
                            <Section
                                name="transactionId"
                                filter={
                                    transactionId && {
                                        transactionIds: [transactionId],
                                    }
                                }
                            >
                                {transactionId}
                            </Section>
                            <Section
                                name="errorId"
                                filter={
                                    errorId && {
                                        errorIds: [errorId],
                                    }
                                }
                            >
                                {errorId}
                            </Section>
                            <Section
                                name="errorCode"
                                filter={
                                    errorCode && {
                                        errorCodes: [errorCode],
                                    }
                                }
                            >
                                {errorCode}
                            </Section>
                            {exception && (
                                <Stack.Item>
                                    <Stacktrace stacktrace={exception} />
                                </Stack.Item>
                            )}
                            <Stack.Item>
                                message:
                                <ObjectInspector expandLevel={10} data={source} />
                            </Stack.Item>
                        </Stack>
                    </Padding>
                </div>
            </PopupContent>
        </Popup>
    );
};
