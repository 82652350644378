import {FC, useMemo} from 'react';
import {StyledSelect} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';
import {useCallbackRef} from '@growthbase/spa';
import {BordtemplateRoutes} from '@growthbase/takentemplates-bordtemplate';
import {useNavigate} from 'react-router';
import {idFromIri} from '@growthbase/routing';
import {AbonnementTemplateRoutes} from '@growthbase/abonnementen-takentemplates';

export interface Bord {
    id: string;
    value: string;
    label: string;
}

export interface BordSelectProps {
    abonnementId: string;
    borden: Bord[];
    bordId: string | null;
}

export const BordSelect: FC<BordSelectProps> = ({borden, bordId, abonnementId: id}) => {
    const {t} = useTranslation('AbonnementTakentemplates.nl');

    const navigate = useNavigate();

    const createBordUrl = BordtemplateRoutes.create.useUrl();

    const options = useMemo(
        () => [
            ...borden.map((bord) => ({
                ...bord,
                value: idFromIri(bord.id),
                id: idFromIri(bord.id),
            })),
            {
                value: createBordUrl,
                label: t('Bord toevoegen'),
                link: true,
            },
        ],
        [borden, createBordUrl, t]
    );
    const toTo = AbonnementTemplateRoutes.view.useNavigateFactory();
    return (
        <StyledSelect<string | null>
            readonly={false}
            disabled={false}
            value={bordId ? idFromIri(bordId) : null}
            options={options}
            onChange={useCallbackRef((change): void => {
                if (change && [createBordUrl].includes(change)) {
                    navigate(change);
                    return;
                }
                if (!change) {
                    return;
                }
                toTo({
                    bordtemplateId: change,
                    abonnementUuid: id,
                });
            })}
        />
    );
};
