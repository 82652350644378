import React, {FC} from 'react';
import {BaseButtonProps, ViewConfig} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';

export interface MarkeerAlsInboxButtonProps extends ViewConfig {
    isInbox: boolean;
    isGekoppeldAanBord: boolean;
    onClick: () => void;
    Button: FC<BaseButtonProps>;
    isSubmitting: boolean;
}

export const MarkeerAlsInboxButton: FC<MarkeerAlsInboxButtonProps> = ({
    isInbox,
    isGekoppeldAanBord,
    onClick,
    Button,
    isSubmitting,
    disabled,
    readonly,
}) => {
    const {t} = useTranslation('TakenlijstLijst.nl');
    const titel = isInbox ? t('isInbox') : t('markeerAlsInbox');
    if (!isGekoppeldAanBord) {
        return null;
    }
    return (
        <Button onClick={onClick} disabled={isSubmitting || disabled} readonly={isInbox || readonly}>
            {titel}
        </Button>
    );
};
