import {FC, useCallback, useState} from 'react';
import {CardSelectOption} from '@growthbase/design-components';
import {Opdracht} from '../../../../opdrachten/planning/src/Opdracht';
import {useSubtitle} from './Hook/useSubtitle';

export interface OpdrachtSelectieItemProps {
    opdracht: Opdracht;
    onKeuzeAangepast: (keuze?: {opdrachtId: string; dienstId?: string}) => void;
    active?: boolean;
}

export const OpdrachtSelectieItem: FC<OpdrachtSelectieItemProps> = ({opdracht, onKeuzeAangepast, active}) => {
    const [dienst, setDienst] = useState<string | undefined>(
        opdracht && opdracht.diensten.length > 0 ? opdracht.diensten[0].id : undefined
    );
    const subtitle = useSubtitle(opdracht.diensten, dienst);

    const titel = `${opdracht.nummer} - ${opdracht.onderwerp}`;

    const onChangeValue = useCallback(
        (value?: string, subValue?: string) => {
            setDienst(subValue);
            onKeuzeAangepast(
                value
                    ? {
                          opdrachtId: value,
                          dienstId: subValue,
                      }
                    : undefined
            );
        },
        [onKeuzeAangepast, setDienst]
    );

    return (
        <CardSelectOption
            active={active}
            title={titel}
            onChangeValue={onChangeValue}
            value={opdracht.id}
            subValue={dienst}
            subValues={opdracht.diensten.map((item) => ({
                value: item.id,
                label: item.titel,
            }))}
            subtitle={opdracht.relatie}
            subValuesSubtitle={subtitle}
        />
    );
};
