import {Icons} from '@growthbase/design-components';
import {useLogger} from '@growthbase/spa';
import {useMemo} from 'react';

export const useResolveIcon = ({label, name}: {label?: string | null; name?: string | null}) => {
    const logger = useLogger('useResolveIcon');
    return useMemo(() => {
        switch (name) {
            case 'dashboard': // Dashboard
                return Icons.Dashboard;
            case 'calendar_index': // Agenda
                return Icons.ModuleCalendar;
            case 'relation_view': // CRM
                return Icons.ModuleRelations;
            case 'employee_index': // HRM
                return Icons.ModuleEmployees;
            case 'taken_mijn_taken': // Taken
                return Icons.ModuleTask;
            case 'order_index': // Opdrachten
                return Icons.ModuleOrder;
            case 'product_index': // Artikelen
                return Icons.ModuleArticle;
            case 'registration_time': // Urenregistratie
                return Icons.ModuleTimeregistration;
            case 'ticket_index': // Tickets
                return Icons.ModuleTicket;
            case 'offer_index': // Offertes
                return Icons.ModuleOffer;
            case 'invoice_index': // Facturen
                return Icons.ModuleInvoice;
            case 'purchase_index': // Inkoopfacturen
                return Icons.ModulePurchase;
            case 'subscription_index': // Abonnementen
                return Icons.ModuleSubscription;
                break;
            case 'stat_index': // Statistieken
                return Icons.ModuleReports;
                break;
            case 'user_dashboard': // Gegevens
                return Icons.ModuleUser;
                break;
            case 'setting_index': // Instellingen
                return Icons.ModuleSettings;
                break;
            default:
                break;
        }
        if (label === 'Support') {
            return Icons.ModuleSupport;
        }
        if (label?.indexOf('menu gebruikern')) {
            return Icons.ModuleMenu;
        }
        if (label === 'Export') {
            return Icons.ModuleExport;
        }
        logger.warn('missing icon for: ', label, name);
        return Icons.BitCoin;
    }, [label, name, logger]);
};
