interface JQuery {
    throwWhenEmpty(...values: string[]): JQuery;
}

jQuery.fn.throwWhenEmpty = function (message) {
    if (this.length === 0) {
        throw new Error(message);
    }
    return this;
};
