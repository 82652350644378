import {useIsBackgroundRoute} from '@growthbase/routing';
import {useCallbackRef} from '@growthbase/spa';
import {KeyHandler} from 'hotkeys-js';
import {useHotkeys} from 'react-hotkeys-hook';
import {useInteractionAllowed} from './useInteractionAllowed';

export interface UseKeysOptions {
    enabled?: boolean;
    onKeyUp?: boolean;
    onKeyDown?: boolean;
    enableOnInput?: boolean;

    filter?: () => boolean;
}

const TAGS: ('INPUT' | 'TEXTAREA' | 'SELECT')[] = ['INPUT', 'TEXTAREA', 'SELECT'];

/**
 * Is aware of popup stacks and application routing (background/foreground).
 */
export function useKeys<TRef extends Element = Element>(
    debugName: string,
    keys: string,
    callback: KeyHandler,
    {onKeyDown: keydown, onKeyUp: keyup, enableOnInput, enabled = true, filter}: UseKeysOptions = {}
) {
    const allowed = useInteractionAllowed();
    const isBackgroundRoute = useIsBackgroundRoute();

    return useHotkeys<TRef>(
        keys,
        useCallbackRef(callback),
        {
            // @ts-expect-error Add this for debugging purposes.
            debugName,
            filter: useCallbackRef(() => allowed() && (!filter || filter())),
            keyup,
            keydown,
            filterPreventDefault: false,
            enableOnContentEditable: enableOnInput,
            enableOnTags: enableOnInput ? TAGS : undefined,
            enabled: enabled && !isBackgroundRoute,
        },
        [callback, allowed]
    );
}
