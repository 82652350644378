import React, {FC, ReactNode} from 'react';
import {PopupControls, PopupControlsContext} from './PopupControls';
import {useMemoryPopupControls} from './Hook';

export interface MemoryPopupProviderProps {
    children: ((helpers: PopupControls) => ReactNode) | ReactNode;
    open?: boolean;
    expanded?: boolean;
    preventClose?: boolean;
    popupId?: string;
}

export const MemoryPopupProvider: FC<MemoryPopupProviderProps> = ({
    children,
    open: openInitial = false,
    expanded: expandedInitial = false,
    preventClose = false,
    popupId,
}) => {
    const helpers = useMemoryPopupControls(openInitial, expandedInitial, preventClose, popupId);
    return (
        <PopupControlsContext.Provider value={helpers}>
            {typeof children === 'function' ? children(helpers) : children}
        </PopupControlsContext.Provider>
    );
};
