import {FC, useMemo} from 'react';
import {Button, PopupControlsContext, Stack, useMemoryPopupControls} from '@growthbase/design-components';
import {IFixEventstreamType, useFixEventButtonConnectedMutation} from '@growthbase/graphql';
import {useCallbackRef} from '@growthbase/spa';
import {toast} from 'react-toastify';
import {FixEventForm, FixEventInput} from './Components/FixEventForm';

export interface DeprecateButtonConnectedProps {
    values?: FixEventInput | null;
    type: IFixEventstreamType;
}

export const FixEventButtonConnected: FC<DeprecateButtonConnectedProps> = ({values, type}) => {
    const popup = useMemoryPopupControls();

    const [mutation, {loading}] = useFixEventButtonConnectedMutation();

    const label = useMemo(() => {
        switch (type) {
            case IFixEventstreamType.Deprecate:
                return 'Deprecate';
            case IFixEventstreamType.ReverseDeprecate:
                return 'Reverse deprecate';
            case IFixEventstreamType.Update:
                return 'Update';
            case IFixEventstreamType.ReverseUpdate:
                return 'Reverse update';
            default:
                return 'Unknown fix';
        }
    }, [type]);

    const handle = useCallbackRef(async (input: FixEventInput) => {
        popup.close();
        const promise = mutation({
            variables: {
                input: {
                    ...input,
                    update: JSON.parse(input.update),
                    type,
                },
            },
        });
        const simple = promise.then(({data}) => data?.FixEventstream?.message ?? 'unknown result');
        await toast.promise<string>(simple, {
            success: {
                render: (started) => `${started.data}`,
                autoClose: 3000,
            },
            error: `Failed to ${label}`,
        });
    });

    if (!values) {
        return null;
    }

    return (
        <PopupControlsContext.Provider value={popup}>
            <Button onClick={popup.toggle} loading={loading} disabled={loading}>
                {label}
            </Button>
            <FixEventForm type={type} onSubmit={handle} values={values} />
        </PopupControlsContext.Provider>
    );
};
export interface FixEventButtonsConnectedProps {
    values?: FixEventInput | null;
}
export const FixEventButtonsConnected: FC<FixEventButtonsConnectedProps> = ({values}) => (
    <Stack horizontal>
        <FixEventButtonConnected type={IFixEventstreamType.Deprecate} values={values} />
        <FixEventButtonConnected type={IFixEventstreamType.ReverseDeprecate} values={values} />
        <FixEventButtonConnected type={IFixEventstreamType.Update} values={values} />
        <FixEventButtonConnected type={IFixEventstreamType.ReverseUpdate} values={values} />
    </Stack>
);
