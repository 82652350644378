import {Loader} from '@growthbase/design-components';
import {PropsWithChildren, FC} from 'react';
import {useTranslation} from 'react-i18next';
import {BordenPagina, BordenPaginaProps} from '../BordenPagina';

export interface BordPaginaProps extends PropsWithChildren, BordenPaginaProps {
    bordUuid: string;
    bordNaam?: string;
}

export const BordPagina: FC<BordPaginaProps> = ({bordUuid, bordNaam, children, ...rest}) => {
    const {t} = useTranslation('OpdrachtenTaken.nl');
    return (
        <BordenPagina
            {...rest}
            titel={
                t('BordPagina.titel', {
                    opdrachtNaam: rest.opdrachtNaam,
                    bordNaam: bordNaam ?? '',
                }) ?? ''
            }
        >
            {children ?? <Loader center />}
        </BordenPagina>
    );
};
