import {gql, useApolloClient} from '@apollo/client';
import {Reference, StoreObject} from '@apollo/client/utilities';
import {useLogger} from '@growthbase/spa';
import {isPromise} from 'graphql/jsutils/isPromise';
import {useCallback} from 'react';

/**
 * With this you can lock or unlock an apollo cache item.
 *
 * For interface 'Node' isLocked.
 *
 * You can query this item with:
 *
 * ... on Node {
 *   isLocked @client
 * }
 */
export const useLockItemHandler = () => {
    const appolo = useApolloClient();
    const logger = useLogger('useLockItemHandler');
    return useCallback(
        (object: StoreObject | Reference, lock = true) => {
            const id = appolo.cache.identify(object);
            if (
                !appolo.cache.writeFragment({
                    id,
                    fragment: gql`
                        fragment useLockItem on Node {
                            isLocked
                        }
                    `,
                    data: {
                        isLocked: lock,
                    },
                })
            ) {
                logger.warn(`Item lock updated ${id} failed`);
            }
        },
        [appolo, logger]
    );
};

/**
 * @see useLockItemHandler
 */
export const useLockItem = (object?: StoreObject | Reference) => {
    const doLock = useLockItemHandler();
    return useCallback(
        (lock = true) => {
            if (!object) {
                return;
            }
            doLock(object, lock);
        },
        [object, doLock]
    );
};

/**
 * Lock item before invoking the given function.
 */
export const useWrapLockItem = <TArgs extends unknown[], TReturn>(
    object: undefined | StoreObject | Reference,
    callback: (...args: TArgs) => TReturn
): ((...args: TArgs) => TReturn) => {
    const lock = useLockItem(object);
    return useCallback(
        (...args) => {
            lock(true);
            const response = callback(...args);
            if (isPromise(response)) {
                return response.finally(() => lock(false)) as unknown as TReturn;
            }
            lock(false);
            return response;
        },
        [lock, callback]
    );
};

export const useCreateWrapLockItem = () => {
    const lock = useLockItemHandler();
    return useCallback(
        (object: StoreObject | Reference, callback: () => Promise<unknown>) => {
            lock(object, true);
            const response = callback();
            return response.finally(() => lock(object, false)).then(() => undefined);
        },
        [lock]
    );
};
