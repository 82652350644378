import {useOptionalCallback, useSwitch} from '@growthbase/spa';
import classNames from 'classnames/bind';
import {ForwardedRef, forwardRef, ReactNode} from 'react';
import {camelCase, capitalize, upperFirst} from 'lodash';
import {BaseButtonProps, ButtonHandler, useHandleClickState} from '../../Button';
import {useShortName} from './Hook';
import styles from './Avatar.module.scss';
import {Tooltip} from '../../Tooltip';
import {ActionButtonProps} from '../../Buttons';

const cx = classNames.bind(styles);

export type AvatarSize = 'default' | 'small' | 'bigger';

export interface AvatarProps extends BaseButtonProps {
    naam: string;
    imageUrl?: string | null;
    selected?: boolean;
    tooltip?: ReactNode;
    size?: AvatarSize;

    // Action Button
    renderActionButton?: (props: Partial<ActionButtonProps>) => ReactNode;
    actionButtonVisible?: boolean;
    actionButtonVisibleOnHover?: boolean;
    actionButtonPosition?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
}

export const Avatar = forwardRef(
    (
        {
            readonly,
            imageUrl,
            naam,
            selected,
            actionButtonPosition = 'bottom-right',

            renderActionButton,
            actionButtonVisibleOnHover = !!renderActionButton,
            actionButtonVisible = false,
            size = 'default',
            tooltip = naam,
            ...rest
        }: AvatarProps,
        ref
    ) => {
        const {disabled, onClick} = useHandleClickState<ButtonHandler | undefined>(rest);

        const shortName = useShortName(naam);
        const classes = cx('avatar', {
            'avatar--clickAble': onClick,
            'avatar--noImage': !imageUrl,
            'avatar--disabled': disabled,
            'avatar--readonly': readonly,
            [`avatar--actionButtonPosition${upperFirst(camelCase(actionButtonPosition))}`]: true,
            [`avatar--size${capitalize(size)}`]: true,
        });

        const enabled = !disabled && !readonly;

        const [showActionButton, handleOnMouseEnter, handleOnMouseLeave] = useSwitch(false);
        const actionButtonEnabled =
            enabled && (actionButtonVisible || (actionButtonVisibleOnHover && showActionButton)) && renderActionButton;

        const handleClick = useOptionalCallback(onClick, enabled);
        /* eslint-disable jsx-a11y/no-noninteractive-tabindex, jsx-a11y/no-static-element-interactions */

        return (
            <div
                data-testid={`Avatar-${naam}`}
                className={classes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
                onClick={handleClick}
                role={onClick ? 'button' : undefined}
                ref={ref as ForwardedRef<HTMLDivElement>}
                tabIndex={enabled && onClick ? 0 : -1}
            >
                <Tooltip content={tooltip} disabled={!tooltip}>
                    <div data-testid="Persoon" className={cx('avatar__inner')}>
                        {selected && <div className={cx('avatar__outline')} />}
                        {imageUrl && <img alt={naam} src={imageUrl} />}
                        {!imageUrl && shortName}
                    </div>
                </Tooltip>
                {actionButtonEnabled && (
                    <div className={cx('avatar__actionButton')}>{renderActionButton({variant: 'danger'})}</div>
                )}
            </div>
        );
    }
);

Avatar.displayName = 'Avatar';
