import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {InstellingFieldsInput} from '@growthbase/instellingen';
import {BordtemplateKopierenFormValues} from '../KopieerForm';

export const useKopierenOpties = (naam: string) => {
    const {t} = useTranslation('Lijsttemplate.nl');
    return useMemo(
        (): InstellingFieldsInput<BordtemplateKopierenFormValues> => ({
            naam: {
                label: t('Kopieren.naam'),
                defaultValue: t('Kopieren.copyNaam', {naam}) ?? '',
            },
            taken: {
                label: t('Kopieren.taken'),
                defaultValue: true,
            },
            activiteiten: {
                label: t('Kopieren.activiteiten'),
                defaultValue: false,
            },
        }),
        [naam, t]
    );
};
