import * as yup from 'yup';
import {InferType} from 'yup';

export const ActiveAgendasUpdatedSchema = yup
    .object({
        agendaIds: yup.array(yup.string().required()).required(),
    })
    .label('ActiveAgendasUpdatedSchema');

export type ActiveAgendasUpdated = InferType<typeof ActiveAgendasUpdatedSchema>;
