import {
    createBasicMarksPlugin,
    createBlockquotePlugin,
    createCodeBlockPlugin,
    createComboboxPlugin,
    createHeadingPlugin,
    createLinkPlugin,
    createListPlugin,
    createMentionPlugin,
    createParagraphPlugin,
    createPlateUI,
    createPlugins,
    createResetNodePlugin,
    createSoftBreakPlugin,
    ELEMENT_MENTION_INPUT,
    PlateFloatingLink,
    PlatePlugin,
} from '@udecode/plate';
import {resetBlockTypePlugin} from '../../Plugins/resetBlockTypePlugin';
import {softBreakPlugin} from '../../Plugins/softBreakPlugin';
import {MentionInput} from '../MentionInput/MentionInput';

export const multipleLinePlugins = createPlugins(
    [
        createBlockquotePlugin(),
        createCodeBlockPlugin(),
        createHeadingPlugin({
            options: {
                levels: 3,
            },
        }),
        createMentionPlugin(),
        createLinkPlugin({
            renderAfterEditable: PlateFloatingLink,
        }),
        createParagraphPlugin(),
        createBasicMarksPlugin(),
        createListPlugin(),
        createResetNodePlugin(resetBlockTypePlugin) as PlatePlugin,
        createSoftBreakPlugin(softBreakPlugin) as PlatePlugin,
        createComboboxPlugin(),
    ],
    {
        components: {
            ...createPlateUI(),
            [ELEMENT_MENTION_INPUT]: MentionInput,
        },
    }
);
