import {NullableISOStringDate} from '@growthbase/design-components';
import {useMemo} from 'react';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

export const useDateText = (asActions?: boolean, value?: NullableISOStringDate): string | null => {
    const {t} = useTranslation('Taken.nl');

    return useMemo(() => {
        if (asActions) {
            if (value?.date?.date) {
                return t('SnoozeButton.editSnooze');
            }
            return t('SnoozeButton.snooze');
        }

        if (!value?.date) {
            return null;
        }
        const instance = moment(value.date.date);
        return instance.format(value.date.timeUsed ? 'L - LT' : 'L');
    }, [value, asActions, t]);
};
