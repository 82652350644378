import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from '../Button';
import {Page} from '../Page';
import {MemoryPopupProvider, PopupHeader, ScrollablePopupContent} from '../Popups';
import {AddBreadcrumb} from '../Breadcrumb';
import {Information} from '../Typography';

export interface OnbekendeFoutPaginaProps {
    onClientClick?: () => void;
    error: string | null;
}

export const OnbekendeFoutPagina: FC<OnbekendeFoutPaginaProps> = ({onClientClick, error}) => {
    const {t} = useTranslation('spa.nl');
    return (
        <MemoryPopupProvider open>
            <Page sidebar={null} header={null}>
                <AddBreadcrumb>{t('OnbekendeFoutPagina.Titel')}</AddBreadcrumb>
                <ScrollablePopupContent
                    fullscreen
                    header={<PopupHeader unclosable title={t('OnbekendeFoutPagina.Titel')} />}
                >
                    <p>
                        {t('OnbekendeFoutPagina.Omschrijving')} {error && <Information>{`(${error})`}</Information>}
                    </p>
                    <Button variant="primary" active onClick={onClientClick}>
                        {t('OnbekendeFoutPagina.OpnieuwProberen')}
                    </Button>
                </ScrollablePopupContent>
            </Page>
        </MemoryPopupProvider>
    );
};
