import {useEffect, useRef, useState} from 'react';
import {noop, Observable, Subscription} from 'rxjs';
import {useCallbackRef} from '../../Hook';
import {PaginationHelpers} from '../Value';

export type PaginationRequestResponse<TNode> = {
    items: TNode[];
    totalCount: number;
    page: number;
};

export interface RequestPaginationOptions<TQueryParams extends object> {
    interval?: number;
    queryParams?: TQueryParams;
}

export const useRequestPagination = <TNode, TQueryParams extends object>(
    requestData: (pagination: {limit: number; page: number}) => Observable<PaginationRequestResponse<TNode>>,
    {interval, queryParams}: RequestPaginationOptions<TQueryParams> = {}
): PaginationHelpers<TNode> => {
    const [nodes, setNodes] = useState<TNode[]>([]);

    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const subscription = useRef<Subscription>();

    const loadPage = useCallbackRef(async (nextPage: number, reset = false) => {
        if (subscription.current) {
            subscription.current.unsubscribe();
        }
        return new Promise<void>((resolve, error) => {
            subscription.current = requestData({
                limit: 50,
                page: nextPage,
                ...queryParams,
            }).subscribe({
                next: (response) => {
                    setTotalCount(response.totalCount);
                    if (reset) {
                        setNodes(response.items);
                    } else {
                        setNodes([...nodes, ...response.items]);
                    }
                    setPage(response.page);
                    resolve();
                },
                error,
            });
        });
    });

    const reset = useCallbackRef(async () => {
        setNodes([]);
        setPage(1);
        setTotalCount(0);
        setTimeout(() => loadPage(1), 0);
    });

    const md4 = JSON.stringify(queryParams);
    useEffect(() => {
        reset();
    }, [reset, md4]);

    const refresh = useCallbackRef(async () => {
        if (page === 1) {
            loadPage(1, true);
        }
    });

    const loadMore = useCallbackRef(async () => loadPage(page + 1));

    useEffect(() => {
        if (!interval) {
            return noop;
        }
        const intervalId = setInterval(refresh, interval);
        return () => clearInterval(intervalId);
    }, [loadMore, interval, refresh]);

    return {
        totalCount,
        connections: nodes,
        loadMore,
        hasMore: nodes.length < totalCount,
        isLoading: false,
    };
};
