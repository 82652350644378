import {useCallbackRef} from '@growthbase/spa';
import {MbscEventClickEvent} from '@mobiscroll/react';
import {EventType} from '@growthbase/planning';

export const useSimpleMobiEventAction = (eventName: string) =>
    useCallbackRef((e: MbscEventClickEvent) => {
        const event = e.event as EventType;
        if (!event.id || !event.resource || !event.csrfToken || !event.editable) {
            return;
        }

        document.dispatchEvent(
            new CustomEvent(eventName, {
                detail: {
                    csrfToken: event.csrfToken,
                    resourceId: event.resource.toString(),
                    id: event.id.toString(),
                    timeRegistrationParentId: event.timeRegistrationParent ? event.timeRegistrationParent.id : null,
                },
            })
        );
    });
