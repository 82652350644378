import {EventType} from '@growthbase/planning';

export const getEventTitle = (event: EventType): string => {
    if (event.title !== '') {
        return event.title;
    }

    if (event.ticket) {
        return `${event.ticket.nummer} - ${event.ticket.label}`;
    }

    if (event.opdracht) {
        return `${event.opdracht.nummer} - ${event.opdracht.label}`;
    }
    if (event.relatie) {
        return event.relatie.label;
    }

    return '';
};
