import {CreateRouteFunction, createRoutes, idFromIri, PageFromMenu, RouteElementProps} from '@growthbase/routing';
import {generatePath} from 'react-router-dom';
import {IEditFromFragment} from '@growthbase/graphql';
import {LogbookConnected} from '@growthbase/activiteiten';
import React from 'react';
import {EditFormConnected} from '../EditForm';
import {TabelConnected} from '../Tabel';
import {ViewConnected} from '../View';
import {CreateFormConnected} from '../CreateForm';

const generateLijsttemplateRoute: CreateRouteFunction<{
    lijsttemplateId: string;
}> = (route: string, params) =>
    generatePath(route, {
        lijsttemplateId: idFromIri(params?.lijsttemplateId ?? ''),
    });

export const LijsttemplateRoutes = createRoutes('lijsttemplate', {
    bewerken: {
        defaultBackgroundRoute: 'overview',
        Element: (props: RouteElementProps<{lijsttemplateId: string}, {lijsttemplate?: IEditFromFragment}>) => (
            <EditFormConnected {...props} />
        ),
        createRoute: generateLijsttemplateRoute,
    },
    bekijken: {
        Element: ({params}: RouteElementProps<{lijsttemplateId: string}>) => (
            <PageFromMenu sidebar={<LogbookConnected id={params?.lijsttemplateId ?? ''} />}>
                <ViewConnected lijsttemplateId={params?.lijsttemplateId ?? ''} />
            </PageFromMenu>
        ),
        createRoute: generateLijsttemplateRoute,
    },
    overview: {
        Element: () => (
            <PageFromMenu sidebar={<LogbookConnected relatedTypes={['Lijsttemplate']} />}>
                <TabelConnected />
            </PageFromMenu>
        ),
        createRoute: generateLijsttemplateRoute,
    },
    aanmaken: {
        Element: () => <CreateFormConnected />,
        defaultBackgroundRoute: 'overview',
    },
});
