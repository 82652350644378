import {
    ITaakVerplaatsFormFragment,
    resolveConnections,
    useTaakVerplaatsMutation,
    useTaakVerwijderUitBordMutation,
    useVerplaatsFormBordenLazyQuery,
    useVerplaatsFormBordLazyQuery,
    useVerplaatsFormLijstLazyQuery,
} from '@growthbase/graphql';
import React, {ReactNode, useCallback, useMemo} from 'react';
import {useCallbackRef} from '@growthbase/spa';
import {defer} from 'rxjs';
import {idFromIri} from '@growthbase/routing';
import {PopupAnchorProps, ViewConfig} from '@growthbase/design-components';
import {useNormalizeRelatieValues} from '@growthbase/relaties';
import {find} from 'lodash';
import {VerplaatsenForm} from './VerplaatsenForm';
import {Opties} from './Hook';
import {useNormalizeTaakOnderdeelVanFragment} from '../../../Koppelen';

export interface VerplaatsenFormConnectedProps<RT extends Element = Element> extends ViewConfig {
    fragment: ITaakVerplaatsFormFragment;
    renderAnchor: (props: PopupAnchorProps<RT>) => ReactNode;
}

export const VerplaatsenFormConnected = ({
    fragment,
    disabled,
    readonly,
    renderAnchor,
}: VerplaatsenFormConnectedProps) => {
    const taak = useMemo(
        () => ({
            id: fragment.id,
            titel: fragment.titel,
        }),
        [fragment]
    );
    const [verplaatsTaak] = useTaakVerplaatsMutation();

    const [fetchBord] = useVerplaatsFormBordLazyQuery();
    const [fetchBorden] = useVerplaatsFormBordenLazyQuery();
    const [fetchLijst] = useVerplaatsFormLijstLazyQuery();
    const [verwijderUitLijst] = useTaakVerwijderUitBordMutation();
    const onderdeelVan = useNormalizeTaakOnderdeelVanFragment(fragment);

    const currentLijst = fragment.lijstInBord?.lijst ?? null;
    const currentBord = currentLijst?.bord ?? null;
    const currentRelatie = useNormalizeRelatieValues(fragment.relatie?.relatieverwijzing);

    return (
        <VerplaatsenForm
            onOntkoppel={useCallbackRef(() => {
                if (!currentLijst) {
                    return Promise.reject(new Error('Zat niet in een lijst'));
                }
                return verwijderUitLijst({
                    variables: {
                        input: {
                            id: taak.id,
                            lijstId: idFromIri(currentLijst?.id),
                        },
                    },
                }).then();
            })}
            readonly={readonly}
            disabled={disabled}
            renderAnchor={renderAnchor}
            taak={taak}
            onVerplaats={useCallbackRef((dto) =>
                verplaatsTaak({
                    variables: {
                        input: {
                            id: taak.id,
                            nieuweLijstId: dto.lijst,
                            vorigeLijstId: currentLijst?.id ?? undefined,
                            positieInNieuweLijst: {
                                bovenTaakId: dto.bovenTaak,
                                onderTaakId: dto.onderTaak,
                            },
                        },
                    },
                }).then()
            )}
            createInitialValues={useCallback(
                () => ({
                    bord: currentBord,
                    lijst: currentLijst,
                    relatie: currentRelatie,
                    onderdeelVan,
                }),
                [currentBord, currentLijst, onderdeelVan, currentRelatie]
            )}
            fetchOptions={useCallbackRef(
                ({bord, lijst, onderdeelChanged, positie, bordQuery, onderdeelVan: onderdeelVanFilter, relatie}) =>
                    defer(async (): Promise<Opties> => {
                        const [bordenResult, selectedBordResult] = await Promise.all([
                            fetchBorden({
                                variables: {
                                    onderdeelVanId: onderdeelVanFilter ? onderdeelVanFilter.id : null,
                                    onderdeelVanType: onderdeelVanFilter ? onderdeelVanFilter.type : null,
                                    query: bordQuery || undefined,
                                },
                            }),
                            bord
                                ? fetchBord({
                                      variables: {
                                          bordId: bord,
                                          id: bord,
                                      },
                                      fetchPolicy: 'cache-first',
                                  })
                                : Promise.resolve(null),
                        ]);
                        const borden = resolveConnections(bordenResult.data?.TakenBorden);

                        let selectedBord = selectedBordResult?.data?.takenBord;
                        if (
                            selectedBord &&
                            onderdeelVanFilter &&
                            (selectedBord?.onderdeelVan?.itemId !== onderdeelVanFilter.id ||
                                selectedBord?.onderdeelVan?.itemType !== onderdeelVanFilter.type)
                        ) {
                            selectedBord = null;
                        }

                        if (selectedBord && !find(borden, {id: selectedBord.id})) {
                            borden.push({
                                id: selectedBord.id,
                                onderdeelVan: selectedBord.onderdeelVan,
                                inbox: selectedBord.inbox,
                                naam: selectedBord.naam,
                            });
                        }

                        let foundBord = borden.find((b) => b.id === bord) ?? null;
                        const emptyOpties: Opties = {
                            bord: null,
                            borden,
                            lijsten: [],
                            lijst: null,
                            taken: [],
                            onderdeelVan: onderdeelVanFilter,
                            relatie,
                        };
                        // If the user selects a different onderdeel we find the first inbox of the new onderdeel
                        if (onderdeelChanged && !foundBord) {
                            foundBord = borden.find((b) => b.inbox?.isInbox) ?? null;
                        }
                        if (foundBord === null) {
                            return emptyOpties;
                        }
                        const bordId = idFromIri(foundBord.id);
                        const bordResult = await fetchBord({
                            variables: {
                                bordId,
                                id: bordId,
                            },
                            fetchPolicy: 'cache-first',
                        });
                        const lijsten = resolveConnections(bordResult.data?.TakenLijsten);
                        let foundLijst = lijsten.find((l) => l.id === lijst) ?? null;

                        // We need to select a new lijst
                        if (foundLijst === null) {
                            foundLijst = bordResult.data?.takenBord?.inbox?.lijst ?? lijsten[0] ?? null;
                        }
                        if (foundLijst === null) {
                            return {
                                ...emptyOpties,
                                onderdeelVan: onderdeelVanFilter,
                                bord: foundBord,
                            };
                        }
                        const lijstId = idFromIri(foundLijst.id);
                        const lijstResult = await fetchLijst({
                            variables: {
                                lijstId,
                                id: lijstId,
                            },
                        });
                        const taken = resolveConnections(lijstResult.data?.Taken);
                        return {
                            bord: foundBord,
                            borden,
                            lijsten,
                            lijst: foundLijst,
                            taken,
                            onderdeelVan: onderdeelVanFilter,
                            relatie,
                        };
                    })
            )}
        />
    );
};
