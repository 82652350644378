import {FC} from 'react';
import {TaakReactieCounter} from './TaakReactieCounter';

export interface TaakReactieCounterStubProps {
    count?: number;
}

export const TaakReactieCounterStub: FC<TaakReactieCounterStubProps> = ({count}) => (
    <TaakReactieCounter count={count || 3} />
);
