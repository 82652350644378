import React, {FC, ReactNode} from 'react';

import styles from './TableHeader.module.scss';

export interface TableHeaderProps {
    children: ReactNode;
    className?: string;
}

export const TableHeader: FC<TableHeaderProps> = ({children, className = ''}) => (
    <div className={`${styles.tableHeader} ${className}`}>{children}</div>
);
