import {BlockMessage, LinkButton} from '@growthbase/design-components';
import {Trans, useTranslation} from 'react-i18next';
import React, {FC} from 'react';
import {BordRoutes} from '@growthbase/takenlijst/bord';

export interface GeenItemsAanwezigProps {
    bordId: string;
}

export const GeenItemsAanwezig: FC<GeenItemsAanwezigProps> = ({bordId}) => {
    const {t} = useTranslation('TakenlijstBord.nl');
    return (
        <BlockMessage title={t('geenLijstAanwezigTekst')}>
            <Trans
                t={t}
                components={{
                    maakLijst: (
                        <LinkButton
                            onClick={BordRoutes.lijst_aanmaken.useNavigate({
                                bordId,
                            })}
                            color="primary"
                        />
                    ),
                    voegToe: (
                        <LinkButton
                            onClick={BordRoutes.lijsttemplate_als_lijst_toevoegen.useNavigate({
                                bordId,
                            })}
                            color="primary"
                        />
                    ),
                }}
            >
                maakEenNieuwLijst
            </Trans>
        </BlockMessage>
    );
};
