import moment from 'moment';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import styles from './CalendarMonthDate.module.scss';
import {formatTimeDigital} from '../formatTime';

export interface CalendarMonthDateProps {
    date: Date;
    totalSeconds: number;
}

export const CalendarMonthDate = ({date, totalSeconds}: CalendarMonthDateProps) => {
    const {t} = useTranslation('spa.nl');
    const momentDate = moment(date);

    return (
        <div className={styles.calendarMonthDate}>
            {momentDate.day() === 1 && (
                <span className={styles.calendarMonthDate__week}>
                    {t('planning.week')} {momentDate.format('W')}
                </span>
            )}
            <span className={styles.calendarMonthDate__total}>{formatTimeDigital(totalSeconds)}&nbsp;-&nbsp;</span>
            <span className={styles.calendarMonthDate__day}>{capitalize(momentDate.format('D'))}</span>
        </div>
    );
};
