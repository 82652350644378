import {
    Buttons,
    Stack,
    SwimlaneCard,
    SwimlaneCardBody,
    SwimlaneFooter,
    TextFieldComponent,
} from '@growthbase/design-components';
import {useCollapsible} from '@growthbase/spa';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {CreateFormDTO, useCreateForm} from '../../../../../CreateForm';

export interface SwimlaneCreateFromProps {
    onSubmit: (values: CreateFormDTO) => Promise<void>;
}

export const SwimlaneCreateFrom: FC<SwimlaneCreateFromProps> = ({...rest}) => {
    const {Form, Fields, formik, formProps} = useCreateForm({
        ...rest,
        doNoWaitForSubmit: true,
    });

    const {TitelField} = Fields;

    const Titel = TitelField.useComponent(TextFieldComponent);

    const {t} = useTranslation('TakentemplateTaak.nl');
    const {isOpen, open, ref} = useCollapsible();
    if (!isOpen) {
        return (
            <SwimlaneFooter ref={ref} padding>
                <Buttons.PlusCircle onClick={open} variant="link">
                    {t('add')}
                </Buttons.PlusCircle>
            </SwimlaneFooter>
        );
    }
    return (
        <SwimlaneFooter ref={ref}>
            <Form {...formProps}>
                <SwimlaneCard>
                    <SwimlaneCardBody>
                        <Stack gap="small" horizontal>
                            <Stack.Item grow>
                                <Titel autoFocus controls={false} borderVisible variant="default" />
                            </Stack.Item>
                            <Stack.Item>
                                <Buttons.Save formik={formik} enabledWhenSubmitting />
                            </Stack.Item>
                        </Stack>
                    </SwimlaneCardBody>
                </SwimlaneCard>
            </Form>
        </SwimlaneFooter>
    );
};
