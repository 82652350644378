import {ReactNode} from 'react';
import {Stack} from '../Layout';

export interface TableFiltersHeaderProps {
    rightActions?: ReactNode[];
    leftActions?: ReactNode[];
}

/**
 * TODO: This isn't actually (part of) a table. Instead it is used above a table. The naming could therefore be improved.
 * TODO: left/right maybe doesn't belong here?
 */
export const TableFiltersHeader = ({rightActions = [], leftActions = []}: TableFiltersHeaderProps) => (
    <Stack gap="tiny" horizontal>
        {leftActions.map((node, index) => node && <Stack.Item key={index}>{node}</Stack.Item>)}
        {rightActions.map(
            (node, index) =>
                node && (
                    <Stack.Item pushItems={index === 0 ? 'before' : undefined} key={index}>
                        {node}
                    </Stack.Item>
                )
        )}
    </Stack>
);
