import {opgemaakteTekstFindElementsByType, useRemoveNodesByType} from '@growthbase/design-components';
import {FormikContextType} from 'formik';
import {useEffect} from 'react';
import {ComboboxOnderdeelVanItem, KoppelbaarItem, OnderdeelVanMentionKey} from '@growthbase/taken';
import {MaakTaakAanTaakFormDTO} from '../TaakCreateFormSchema';
import {KoppelbaarItemType} from '../../Koppelen/KoppelPopup';

export interface StringOnderdeelVanVanTitelProps {
    formik: FormikContextType<MaakTaakAanTaakFormDTO>;
}

export const useStripOnderdeelVanVanTitel = ({formik}: StringOnderdeelVanVanTitelProps) => {
    const removeNodesByType = useRemoveNodesByType();
    const {
        setFieldValue,
        values: {titel},
    } = formik;

    useEffect(() => {
        const all = opgemaakteTekstFindElementsByType(OnderdeelVanMentionKey, titel);

        if (all.length === 0) {
            return;
        }
        for (const item of all) {
            if (!item) {
                // eslint-disable-next-line no-continue
                continue;
            }
            const {item: koppelItem} = item as unknown as {item: ComboboxOnderdeelVanItem};

            const type: KoppelbaarItemType = KoppelbaarItemType[koppelItem.type];
            const kopelbaar: KoppelbaarItem = {
                id: koppelItem.id,
                type,
                label: koppelItem.label,
                href: koppelItem.href,
            };
            setFieldValue('relatie', koppelItem.relatie);
            setFieldValue('onderdeelVan', kopelbaar);
        }
        removeNodesByType(OnderdeelVanMentionKey);
    }, [removeNodesByType, setFieldValue, titel]);
};
