import {FC} from 'react';
import {Opdracht} from '@growthbase/opdrachten-planning';
import {OpdrachtSelectieItem} from '../OpdrachtSelectieItem';

export interface OpdrachtSelectieProps {
    activeOpdrachtId?: string;
    opdrachten: Array<Opdracht>;
    keuzeAangepast: (keuze?: {opdrachtId: string; dienstId?: string}) => void;
}

export const OpdrachtSelectie: FC<OpdrachtSelectieProps> = ({opdrachten, activeOpdrachtId, keuzeAangepast}) => (
    <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
        {opdrachten.map((order) => (
            <OpdrachtSelectieItem
                opdracht={order}
                onKeuzeAangepast={keuzeAangepast}
                key={order.id}
                active={order.id === activeOpdrachtId}
            />
        ))}
    </div>
);
