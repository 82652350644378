import {
    Flex,
    Line,
    Padding,
    PaddingHorizontal,
    PaddingVertical,
    Scrollable,
    SwitchButton,
} from '@growthbase/design-components';
import React, {ReactNode} from 'react';
import {useTranslation} from 'react-i18next';

export interface ProfileSwitchProfile {
    id: string;
    name: string;
    active: boolean;
}

export interface ProfielMenuProps {
    profiles: ProfileSwitchProfile[];
    loading: boolean;
    children?: ReactNode;
    onGotoProfile: (url: string) => void;
}

export const ProfielMenu = ({profiles, loading, children, onGotoProfile}: ProfielMenuProps) => {
    const {t} = useTranslation('spa.nl');

    return (
        <div style={{minWidth: 266}}>
            <Flex.Column noGap>
                <Flex.Item>
                    <PaddingVertical paddingSize="medium">
                        <Flex.Column>
                            <Flex.Item>
                                <Scrollable>
                                    <PaddingHorizontal paddingSize="medium">
                                        <Flex.Column smallGap>
                                            {profiles.map((profile, index) => (
                                                <Flex.Item key={index}>
                                                    <SwitchButton
                                                        key={profile.id}
                                                        active={profile.active}
                                                        className="switch-profile"
                                                        data-modal-size="modal-mid"
                                                        data-text={t('Navigatie.ProfielMenu.ModalText', {
                                                            profielNaam: profile.name,
                                                        })}
                                                        href={`/dashboard?profile=${profile.id}`}
                                                    >
                                                        {profile.name}
                                                    </SwitchButton>
                                                </Flex.Item>
                                            ))}
                                        </Flex.Column>
                                    </PaddingHorizontal>
                                </Scrollable>
                            </Flex.Item>
                        </Flex.Column>
                    </PaddingVertical>
                </Flex.Item>
                <Flex.Item>
                    <Line />
                </Flex.Item>
                {children && (
                    <Flex.Item>
                        <Padding paddingSize="medium">{children}</Padding>
                    </Flex.Item>
                )}
            </Flex.Column>
        </div>
    );
};
