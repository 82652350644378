import {useExternalEntityFieldsInvalidated} from '@growthbase/cache';
import {ExternalEntityWithLabel, ExternalLinkType} from '@growthbase/clientbox';
import {
    IKoppelRelatieGegevens,
    IRelatieButtonFragment,
    IRelatieButtonGroupsFragment,
    IRelatieGroupsQuery,
    useRelatieButtonVerwijzingenLazyQuery,
} from '@growthbase/graphql';
import {compareIds} from '@growthbase/routing';
import React, {FC, useCallback, useMemo} from 'react';
import {v4} from 'uuid';
import {RelatieButton, RelatieValue} from './RelatieButton';
import {RelatiePopupConnected} from '../RelatiePopup/RelatiePopupConnected';
import {RelatieButtonContent, RelatieButtonContentProps} from '../RelatieButtonContent';

export interface RelatieButtonConnectedProps {
    readonly?: boolean;
    disabled?: boolean;
    fragment?: IRelatieButtonFragment | null;
    requestData: (query: IRelatieGroupsQuery) => Promise<IRelatieButtonGroupsFragment>;
    onChange: (gegevens: IKoppelRelatieGegevens) => Promise<unknown>;
    singleValue?: boolean;
    ContentElement?: FC<RelatieButtonContentProps>;
}

export const RelatieButtonConnected: FC<RelatieButtonConnectedProps> = ({
    fragment,
    disabled,
    readonly,
    onChange,
    requestData,
    singleValue,
    ContentElement = RelatieButtonContent,
}) => {
    const value = useMemo((): RelatieValue => {
        if (!fragment) {
            return {};
        }
        let bedrijf: ExternalEntityWithLabel | undefined;
        if ('bedrijf' in fragment) {
            bedrijf = {
                ...fragment.bedrijf,
                linkType: ExternalLinkType.Bedrijf,
            };
        }
        let persoon: ExternalEntityWithLabel | undefined;
        if ('persoon' in fragment) {
            persoon = {
                ...fragment.persoon,
                linkType: ExternalLinkType.Persoon,
            };
        }
        return {
            persoon,
            bedrijf,
        };
    }, [fragment]);
    const id = fragment?.id;
    const [refetch] = useRelatieButtonVerwijzingenLazyQuery({
        variables: id ? {id} : undefined,
    });

    useExternalEntityFieldsInvalidated<IRelatieButtonFragment>(
        useCallback(
            (args) => {
                if (compareIds(id, args.id) && args.typename === 'Relatieverwijzing') {
                    refetch({
                        variables: id ? {id} : undefined,
                    });
                }
            },
            [refetch, id]
        )
    );
    return (
        <RelatieButton
            RelatiePopup={RelatiePopupConnected}
            disabled={disabled}
            readonly={readonly}
            value={value}
            requestData={requestData}
            singleValue={singleValue}
            ContentElement={ContentElement}
            onChange={useCallback(
                (change) =>
                    onChange({
                        id: id ?? v4(),
                        bedrijfId: change.bedrijf?.id,
                        persoonId: change.persoon?.id,
                    }).then(() => undefined),
                [id, onChange]
            )}
        />
    );
};
