import {createLegacyRoutes, CreateRouteFunction, idFromIri} from '@growthbase/routing';
import {generatePath} from 'react-router-dom';
import {useCallbackRef} from '@growthbase/spa';
import {useMemo} from 'react';

/**
 * @deprecated Should only use uuid to references entities, redirect clientside.
 */
export type ExternalId = string | null | undefined;

export const ClientboxUuidRoutes = createLegacyRoutes('growthbase', {
    abonnement: {
        createRoute: ((route: string, {uuid}: {uuid: string}): string =>
            generatePath(route, {
                abonnementUuid: idFromIri(uuid),
            })) as CreateRouteFunction<{uuid: string}>,
    },
    order: {
        createRoute: ((route: string, {uuid}: {uuid: string}): string =>
            generatePath(route, {
                orderUuid: idFromIri(uuid),
            })) as CreateRouteFunction<{uuid: string}>,
    },
    ticket: {
        createRoute: ((route: string, {uuid}: {uuid: string}): string =>
            generatePath(route, {
                ticketUuid: idFromIri(uuid),
            })) as CreateRouteFunction<{uuid: string}>,
    },
    bedrijf: {
        createRoute: ((route: string, {uuid}: {uuid: string}): string =>
            generatePath(route, {
                relation: idFromIri(uuid),
            })) as CreateRouteFunction<{uuid: string}>,
    },
    persoon: {
        createRoute: ((route: string, {uuid}: {uuid: string}): string =>
            generatePath(route, {
                relation: idFromIri(uuid),
            })) as CreateRouteFunction<{uuid: string}>,
    },
});

// eslint-disable-next-line no-shadow
export enum ExternalLinkType {
    Bedrijf = 'Bedrijf',
    Persoon = 'Persoon',
    Opdracht = 'Opdracht',
    Abonnement = 'Abonnement',
    Gebruiker = 'Gebruiker',
    Ticket = 'Ticket',
}

export interface ExternalLinkEntity {
    id: string;
    linkType: ExternalLinkType;
}

export interface ExternalEntityWithLabel extends ExternalLinkEntity {
    id: string;
    label: string;
    linkType: ExternalLinkType;
}

export const useExternalLinkFactory = () => {
    const abonnement = ClientboxUuidRoutes.abonnement.useUrlFactory();
    const order = ClientboxUuidRoutes.order.useUrlFactory();
    const ticket = ClientboxUuidRoutes.ticket.useUrlFactory();
    const bedrijf = ClientboxUuidRoutes.bedrijf.useUrlFactory();
    const persoon = ClientboxUuidRoutes.persoon.useUrlFactory();

    return useCallbackRef((externalEntity?: ExternalLinkEntity) => {
        if (!externalEntity) {
            return undefined;
        }
        switch (externalEntity.linkType) {
            case ExternalLinkType.Abonnement:
                return abonnement({uuid: externalEntity.id});
            case ExternalLinkType.Opdracht:
                return order({uuid: externalEntity.id});
            case ExternalLinkType.Ticket:
                return ticket({uuid: externalEntity.id});
            case ExternalLinkType.Bedrijf:
                return bedrijf({uuid: externalEntity.id});
            case ExternalLinkType.Persoon:
                return persoon({uuid: externalEntity.id});
            default:
                return undefined;
        }
    });
};

export const useExternalLink = (externalEntity?: ExternalLinkEntity) => {
    const externalLinkFactory = useExternalLinkFactory();
    return useMemo(() => externalLinkFactory(externalEntity), [externalEntity, externalLinkFactory]);
};

/**
 * @deprecated Should only use uuid to references entities, redirect clientside.
 */
export function assertClientboxIdentifier(externalId: ExternalId): string {
    if (!externalId) {
        throw new Error('ExternalId is required');
    }
    const [type, id] = externalId.split(':');
    if (id && type && type === 'clientbox') {
        return id;
    }
    // check if externalId is a number
    if (!Number.isNaN(Number(externalId))) {
        return externalId;
    }
    throw new Error(`Invalid externalId:${externalId}`);
}
