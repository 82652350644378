import {
    ActionsMenu,
    ActionsMenuButton,
    Button,
    IconButton,
    LegacyActionsMenuRefButton,
    PopupHeader,
    useIsMobile,
} from '@growthbase/design-components';
import {ITaakEditPopupHeaderFragment, ITaakEditPopupHeaderProfielFragment} from '@growthbase/graphql';
import {ReactNode, FC} from 'react';
import {useOr} from '@growthbase/spa';
import {
    PriveFieldConnected,
    KopieerTaakButtonConnected,
    SnoozeButtonConnected,
    TaakEditFragment,
} from '@growthbase/taken';
import {useTranslation} from 'react-i18next';
import {TimeRegistrationButtonConnected} from '@growthbase/clientbox-uren';
import {VerwijderTaakButtonConnected} from '../../TaakVerwijderen';
import {VoltooiOfActiveerTaakButtonConnected} from '../../VoltooiOfActiveerTaakButton';
import {ArchiveerTaakButtonConnected} from '../../TaakArchiveren/ArchiveerTaakButton/ArchiveerTaakButtonConnected';
import {VolgersConnected} from '../../Volgers';
import {TaakMijnDagButtonConnected} from '../../TaakMijnDagButton';
import {VerplaatsButton} from '../../TaakVerplaatsen';

export interface TaakEditPopupHeaderProps {
    fragment?: ITaakEditPopupHeaderFragment | TaakEditFragment;
    profielFragment?: ITaakEditPopupHeaderProfielFragment;
    children: ReactNode;
    VoltooiOfActiveerTaakButton: typeof VoltooiOfActiveerTaakButtonConnected;
    VerwijderTaakButton: typeof VerwijderTaakButtonConnected;
    KopieerTaakButton: typeof KopieerTaakButtonConnected;
    ArchiveerTaakButton: typeof ArchiveerTaakButtonConnected;
    VolgersButton: typeof VolgersConnected;
    TaakMijnDagButton: typeof TaakMijnDagButtonConnected;
    PriveTaakButton: typeof PriveFieldConnected;
    onDeleted: () => void;
    onCreated: (id: string) => void;
    onArchived: () => void;

    TimeRegistrationButton?: typeof TimeRegistrationButtonConnected;
}
function isITaakEditPopupHeaderFragment(
    fragment: ITaakEditPopupHeaderFragment | TaakEditFragment
): fragment is ITaakEditPopupHeaderFragment {
    return (fragment as ITaakEditPopupHeaderFragment).TaakEditPopupHeader !== undefined;
}

export const TaakEditPopupHeader: FC<TaakEditPopupHeaderProps> = ({
    VoltooiOfActiveerTaakButton,
    VerwijderTaakButton,
    KopieerTaakButton,
    ArchiveerTaakButton,
    TaakMijnDagButton,
    PriveTaakButton,
    children,
    fragment,
    profielFragment,
    onDeleted,
    onCreated,
    onArchived,
    VolgersButton,
    TimeRegistrationButton = TimeRegistrationButtonConnected,
}) => {
    const {t} = useTranslation('Taken.nl');
    const visible = useOr(fragment?.availableActions, ['kanKopieren', 'kanArchiveren', 'kanVerwijderen']);
    const isMobile = useIsMobile();

    const ActiveerButton = isMobile ? (IconButton as typeof Button) : Button;

    return (
        <PopupHeader
            sticky={isMobile}
            buttons={
                fragment &&
                profielFragment && [
                    <PriveTaakButton key="prive" fragment={fragment} />,
                    isITaakEditPopupHeaderFragment(fragment) && !fragment?.gearchiveerd && (
                        <TaakMijnDagButton key="mijndag" fragment={fragment} />
                    ),
                    isITaakEditPopupHeaderFragment(fragment) && (
                        <VolgersButton key="volgers" fragment={fragment} />
                    ),
                    <TimeRegistrationButton
                        profielFragment={profielFragment}
                        taakFragment={fragment}
                        key="timeRegistration"
                    />,
                    <VoltooiOfActiveerTaakButton key="active" fragment={fragment} SubmitButton={ActiveerButton} />,
                    <ActionsMenu key="menu" visible={visible}>
                        <VerplaatsButton
                            Button={ActionsMenuButton}
                            fragment={fragment}
                            readonly={false}
                            disabled={false}
                        >
                            {t('TaakVerplaatsen.menu')}
                        </VerplaatsButton>
                        <KopieerTaakButton onCreated={onCreated} fragment={fragment} Button={ActionsMenuButton} />
                        <ArchiveerTaakButton
                            onChanged={onArchived}
                            fragment={fragment}
                            SubmitButton={ActionsMenuButton}
                        />
                        <VerwijderTaakButton onDeleted={onDeleted} fragment={fragment} Button={ActionsMenuButton} />
                        <SnoozeButtonConnected asActions Button={LegacyActionsMenuRefButton} fragment={fragment} />
                    </ActionsMenu>,
                ]
            }
        >
            {children}
        </PopupHeader>
    );
};
