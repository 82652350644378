import {ITaakEditModalFragment, ITaakTabelFragment, ITakenswimlaneConnectedFragment} from '@growthbase/graphql';

export type TaakEditFragment = ITaakEditModalFragment | ITaakTabelFragment | ITakenswimlaneConnectedFragment;

export function isTaakEditModalFragment(fragment?: TaakEditFragment): fragment is ITaakEditModalFragment {
    if (!fragment) {
        return false;
    }
    return (fragment as ITaakEditModalFragment).TaakEditModal === 'Taak';
}

export function isTaakTabelFragment(fragment?: TaakEditFragment | object): fragment is TaakEditFragment {
    if (!fragment) {
        return false;
    }
    return (
        (fragment as TaakEditFragment).priveVoorMij !== undefined &&
        (fragment as TaakEditFragment).titel !== undefined &&
        (fragment as TaakEditFragment).id !== undefined
    );
}
