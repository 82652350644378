import {IBestandType} from '@growthbase/graphql';
import {KeysProvider} from '@growthbase/keys';
import {useCallbackRef, useLogger} from '@growthbase/spa';
import {createContext, ReactNode, useEffect, useRef, useState} from 'react';
import {useErrorHandler} from 'react-error-boundary';
import {Slideshow} from '../../../Slideshow';
import {useCurrentSlideShowState} from './useCurrentSlideshowState';

export interface Bestand {
    id: string;
    type: IBestandType;
    path: string;
    url: string;
}

export type BestandLoadFunction = () => Promise<Bestand[]>;

export interface SlideshowViewControls {
    id: string;
    open: () => Promise<void>;
    view: (bestandId: string) => Promise<void>;
    close: () => void;
    isOpen: boolean;
}

export const SlideshowContext = createContext<SlideshowViewControls | null>(null);

export interface BestandSlideshowApi extends SlideshowViewControls {
    slideshow: ReactNode;
}

/**
 * You can render the interactionLock inside the component to prevent interaction with the rest of the application
 *
 * TODO: sapperate the provider and the api.
 * TODO: the slides should be given to this hook instead of using the result inside the promise
 */
export const useBestandSlideshow = ({
    load,
    id,
    render,
}: {
    load: BestandLoadFunction;
    id: string;
    render: ReactNode | ((api: SlideshowViewControls) => ReactNode);
}): BestandSlideshowApi => {
    const handle = useErrorHandler();
    const logger = useLogger('slideshow');
    const slideshow = useRef<Slideshow | null>(null);
    const [preview, setPreview] = useState<ReactNode | null>();
    const [{id: currentSlideshowId, bid: currentBestandId}, onPageChange] = useCurrentSlideShowState();
    const api = useCallbackRef(() => {
        if (!slideshow.current) {
            throw new Error('Slideshow not initialized');
        }
        return slideshow.current;
    });
    if (!slideshow.current) {
        slideshow.current = new Slideshow(
            logger,
            id,
            load,
            (slideshowElement) => {
                setPreview(slideshowElement);
            },
            (bestandId) => {
                onPageChange({
                    bid: bestandId,
                    id,
                });
            },
            () => {
                setPreview(null);
                onPageChange({
                    bid: undefined,
                    id: undefined,
                });
            }
        );
    }
    useEffect(() => {
        if (currentSlideshowId === id && currentBestandId) {
            api().view(currentBestandId);
        }
    }, [currentSlideshowId, api, currentBestandId, id]);

    return {
        id,
        isOpen: !!preview,
        slideshow: (
            <SlideshowContext.Provider value={api()}>
                {typeof render === 'function' ? render(api()) : render}
                {/* the KeysProvider locks the interaction, like closing the model etc. */}
                {preview && (
                    <KeysProvider key="useBestandSlideshow" name="useBestandSlideshow">
                        {preview}
                    </KeysProvider>
                )}
            </SlideshowContext.Provider>
        ),
        view: useCallbackRef((bestandId: string) => api().view(bestandId).catch(handle)),
        open: useCallbackRef(() => api().open().catch(handle)),
        close: useCallbackRef(() => api().close()),
    };
};
