import {useCallback, useState} from 'react';
import {IOpdrachtstatus, IPlanningSidebarConnectedQueryVariables} from '@growthbase/planning';
import {Opdrachtstatus} from '../../Tabs/OpdrachtenTab/OpdrachtenTab';

export const useOpdrachtStatus = (): {
    setSearchArgs: (
        value:
            | ((prevState: IPlanningSidebarConnectedQueryVariables) => IPlanningSidebarConnectedQueryVariables)
            | IPlanningSidebarConnectedQueryVariables
    ) => void;
    searchArgs: IPlanningSidebarConnectedQueryVariables;
    activeOrderStatus: Opdrachtstatus | undefined;
    handleChangeOpdrachtStatus: (status?: Opdrachtstatus) => void;
} => {
    const [searchArgs, setSearchArgs] = useState<IPlanningSidebarConnectedQueryVariables>({
        opdrachtstatus: [IOpdrachtstatus.Nieuw, IOpdrachtstatus.InBehandeling],
    });

    const [activeOrderStatus, setActiveOrderStatus] = useState<Opdrachtstatus | undefined>(Opdrachtstatus.Alle);

    const handleChangeOpdrachtStatus = useCallback(
        (status?: Opdrachtstatus) => {
            if (!status) {
                return;
            }

            setActiveOrderStatus(status);
            if (status === Opdrachtstatus.Alle) {
                setSearchArgs({
                    ...searchArgs,
                    opdrachtstatus: [IOpdrachtstatus.Nieuw, IOpdrachtstatus.InBehandeling],
                });
            }
            if (status === Opdrachtstatus.Nieuw) {
                setSearchArgs({
                    ...searchArgs,
                    opdrachtstatus: [IOpdrachtstatus.Nieuw],
                });
            }
            if (status === Opdrachtstatus.InBehandeling) {
                setSearchArgs({
                    ...searchArgs,
                    opdrachtstatus: [IOpdrachtstatus.InBehandeling],
                });
            }
            if (status === Opdrachtstatus.Afgerond) {
                setSearchArgs({
                    ...searchArgs,
                    opdrachtstatus: [IOpdrachtstatus.Afgerond],
                });
            }
        },
        [searchArgs, setActiveOrderStatus, setSearchArgs]
    );

    return {
        activeOrderStatus,
        handleChangeOpdrachtStatus,
        searchArgs,
        setSearchArgs,
    };
};
