import {Instellingen, InstellingFieldsInput} from '@growthbase/instellingen';
import {FC} from 'react';

export type ReplayOptionsInput = {
    async: boolean;
    failOnFirstError: boolean;
    ignoreCriteria: boolean;
    replayWhenPreviousWasUnsuccessful: boolean;
    inMemory?: boolean;
    tag: string;
    verbose: boolean;
    verboseHandlerTiming: boolean;
    allowSharedRepositoryRecreate: boolean;
    retryWhenExecutionFailed: boolean;
    breakpointOnMessageId?: number;
    profileId: string;
    projectionId: string;
    breakOnModelChange: string;
    localBuffer: boolean;
};

export const replayOptionsFields: InstellingFieldsInput<ReplayOptionsInput> = {
    tag: {
        label: 'Tag',
        defaultValue: '',
    },
    async: {
        label: 'Asynchroon (Via RMQ uitvoeren)',
        defaultValue: true,
    },
    failOnFirstError: {
        label: 'Stop bij eerste error',
        defaultValue: false,
    },
    ignoreCriteria: {
        label: 'Ignore criteria (Criteria bepaald normaal of projector moet worden uitgevoerd)',
        defaultValue: false,
    },
    inMemory: {
        label: 'In memory',
        defaultValue: true,
    },
    localBuffer: {
        label: 'Local buffer',
        defaultValue: true,
    },
    replayWhenPreviousWasUnsuccessful: {
        label: 'Replay als het niet succesvol was',
        defaultValue: false,
    },
    verbose: {
        label: 'Verbose (Log item per event)',
        defaultValue: false,
    },
    verboseHandlerTiming: {
        label: 'Timing statistieken per projector handler',
        defaultValue: false,
    },
    allowSharedRepositoryRecreate: {
        label: 'Gedeelte repository opnieuw maken toestaan schema gewijzigd is. (Dit verwijdert alle data voor alle profielen!, voor deze projector)',
        defaultValue: false,
    },
    retryWhenExecutionFailed: {
        label: 'Opnieuw proberen als de uitvoering is mislukt (Proces gestopt vanwege memoryleak of andere problemen)',
        defaultValue: false,
    },
    breakpointOnMessageId: {
        label: 'Breakpoint op message id',
        defaultValue: 0,
    },
    profileId: {
        label: 'Profiel ID',
        defaultValue: 'Be careful with this one!, this will replay all projectors for all profiles if not set!',
    },
    projectionId: {
        label: 'Projectie ID',
        defaultValue: '',
    },
    breakOnModelChange: {
        label: 'Break on model change',
        defaultValue: '',
    },
};

export interface ReplayOptionsFormProps {
    initialValues?: Partial<ReplayOptionsInput>;
    onSubmit: (values: ReplayOptionsInput) => Promise<void>;
}

export const ReplayOptionsForm: FC<ReplayOptionsFormProps> = ({onSubmit, initialValues}) => (
    <Instellingen<ReplayOptionsInput>
        titel="Replay opties"
        view={false}
        fields={replayOptionsFields}
        onSubmit={onSubmit}
        values={initialValues}
    />
);
