import {DragStartEvent} from '@dnd-kit/core';
import {useHandleError, useLogger} from '@growthbase/spa';
import {useCallback} from 'react';
import {useOnDragStart} from '../DNDReducer';
import {DNDData} from '../Value';

export const useOnDragStartEvent = () => {
    const dragStart = useOnDragStart();
    const errorHandler = useHandleError();
    const logger = useLogger('useOnDragStartEvent');
    return useCallback(
        (event: DragStartEvent): void => {
            const {active} = event;
            const data = (active?.data?.current ?? null) as DNDData | undefined;
            if (!data) {
                logger.error('Drag start Error: Item not found', 'Event:', event);
                return;
            }
            if (data.type !== 'item') {
                logger.error('Drag start Error: Unknown type', 'Event:', event);
                return;
            }
            try {
                dragStart({
                    containerId: data.containerId,
                    itemId: data.id,
                });
            } catch (e) {
                errorHandler(e);
            }
        },
        [dragStart, errorHandler, logger]
    );
};
