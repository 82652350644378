import React, {ReactNode, useCallback} from 'react';
import Select, {StylesConfig, Theme, components, SingleValue} from 'react-select';

import {useTranslation} from 'react-i18next';
import {ChevronDownIcon} from '@udecode/plate';
import {ViewConfig} from '../../../BaseFieldProps';

export interface StyledSelectOption<T> {
    label: ReactNode;
    value: T;
    link?: boolean;
    isDisabled?: boolean;
}

export interface StyledSelectProps<T> extends ViewConfig {
    options: StyledSelectOption<T>[];
    value: T;
    menuIsOpen?: boolean;
    onChange?: (value: T) => void;
    testId?: string;
}

const CrossIcon: typeof components.CrossIcon = (props) => <ChevronDownIcon />;

const customStyles: StylesConfig = {
    control: (provided, state) => ({
        ...provided,
        outline: 0,
        boxShadow: 'none',
        borderWidth: 1,
        background: state.isDisabled ? 'var(--formInputDisabledBackground)' : 'var(--formInputBackground)',
        borderColor: 'var(--formBorderColor)',
        borderRadius: state.menuIsOpen ? 0 : provided.borderRadius,
        minHeight: 40,
        cursor: state.isDisabled ? 'default' : 'pointer',
        '&:hover': {},
    }),
    menuPortal: (provided, state) => ({
        ...provided,
    }),
    menu: (provided, state) => ({
        ...provided,
        border: '1px solid var(--formBorderColor)',
        boxShadow: 'none',
        marginTop: state.placement === 'bottom' ? '-1px' : 0,
        marginBottom: state.placement === 'top' ? '-1px' : 0,
        borderTopLeftRadius: state.placement === 'top' ? undefined : 0,
        borderTopRightRadius: state.placement === 'top' ? undefined : 0,
        borderBottomLeftRadius: state.placement === 'bottom' ? undefined : 0,
        borderBottomRightRadius: state.placement === 'bottom' ? undefined : 0,
        zIndex: 100,
    }),
    menuList: (provided, state) => ({
        ...provided,
        padding: 0,
    }),
    option: (provided, state) => {
        const data = state.data as StyledSelectOption<unknown>;
        return {
            ...provided,
            color: data.link ? 'var(--colorPrimaryLight20)' : provided.color,
            borderBottom: data.link ? 'var(--border)' : provided.borderBottom,
            cursor: state.isDisabled ? 'default' : 'pointer',
        };
    },
    valueContainer: (provided, state) => ({
        ...provided,
        paddingRight: 0,
    }),
    indicatorSeparator: (provided, state) => ({}),
    dropdownIndicator: (provided, state) => ({
        ...provided,
    }),
};

const NoOptionsMessage: typeof components.NoOptionsMessage = (props) => {
    const {t} = useTranslation('DesignComponents.nl');
    return (
        <components.NoOptionsMessage {...props}>
            <span className="custom-css-class">{t('StyledSelect.emptyOption')}</span>
        </components.NoOptionsMessage>
    );
};
NoOptionsMessage.defaultProps = components.NoOptionsMessage.defaultProps;

const customTheme = (theme: Theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: 'var(--colorPrimaryLighter)',
        primary75: 'var(--colorPrimaryLight20)',
        primary50: 'var(--colorPrimaryLight20)',
        primary25: 'var(--colorBlueGrayUltraLight)',
    },
});

export function StyledSelect<T>({
    options,
    readonly,
    onChange,
    menuIsOpen,
    disabled,
    testId,
    value: valueGiven,
}: StyledSelectProps<T>) {
    const {t} = useTranslation('DesignComponents.nl');

    const value = valueGiven ?? null;

    const onChangeCallback = useCallback(
        (newValue: SingleValue<StyledSelectOption<T>>) => {
            if (onChange && newValue) {
                onChange(newValue.value);
            }
        },
        [onChange]
    );

    if (readonly) {
        const option = options.find((o) => o.value === value);
        return <div>{option?.label}</div>;
    }

    return (
        <Select<StyledSelectOption<T>>
            onChange={onChangeCallback}
            components={{CrossIcon}}
            isOptionSelected={(option) => option.value === value}
            value={options?.find((o) => o.value === value)}
            menuIsOpen={menuIsOpen}
            options={options}
            styles={customStyles as StylesConfig<StyledSelectOption<T>>}
            theme={customTheme}
            menuPlacement="auto"
            menuPosition="absolute"
            placeholder={t('StyledSelect.placeholder')}
            isSearchable
            isDisabled={disabled}
            id={testId}
        />
    );
}
