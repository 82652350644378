import {useDroppable} from '@dnd-kit/core';
import {SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable';
import React, {LegacyRef, ReactNode} from 'react';
import {useDNDContainer} from '../Hook';
import {
    DNDActiveState,
    DNDContainerUserData,
    DNDItemData,
    DNDItemUserData,
    DraggedItemOptions,
    onDropItemHandler,
    onRemoveItemHandler,
    DNDUserDataType,
} from '../Value';

export interface RenderSortableContainerProps<
    TUserDataType extends DNDUserDataType,
    TItemUserData extends DNDItemUserData
> {
    items: DNDItemData<TItemUserData, TUserDataType>[];
    element: {ref?: LegacyRef<never> | undefined};
}

export type RenderSortableContainer<TUserDataType extends DNDUserDataType, TItemUserData extends DNDItemUserData> = (
    props: RenderSortableContainerProps<TUserDataType, TItemUserData>
) => ReactNode;

export type AcceptDroppable<
    TUserDataType extends DNDUserDataType,
    TItemUserData extends DNDItemUserData,
    TContainerUserData extends DNDContainerUserData
> = (accept: DNDActiveState) => accept is DNDActiveState<TUserDataType, TItemUserData, TContainerUserData>;

export interface DNDSortableContainerProps<
    TUserDataType extends DNDUserDataType,
    TItemUserData extends DNDItemUserData,
    TContainerUserData extends DNDContainerUserData
> extends DraggedItemOptions<TUserDataType, TItemUserData> {
    userDataType: TUserDataType;
    userData: TContainerUserData;
    items: TItemUserData[];
    onDrop: onDropItemHandler<TItemUserData, TContainerUserData>;
    accept?: AcceptDroppable<TUserDataType, TItemUserData, TContainerUserData>;
    onRemove?: onRemoveItemHandler<TItemUserData>;
    children: RenderSortableContainer<TUserDataType, TItemUserData>;
}

export function DNDSortableContainer<
    TUserDataType extends DNDUserDataType,
    TItemUserData extends DNDItemUserData,
    TContainerUserData extends DNDContainerUserData
>({
    children,
    items: givenItems,
    onDrop,
    onRemove,
    wrapperElement,
    renderDraggedItem,
    accept,
    userData,
    userDataType,
}: DNDSortableContainerProps<TUserDataType, TItemUserData, TContainerUserData>) {
    const {items, containerData} = useDNDContainer({
        userDataType,
        userData,
        wrapperElement,
        renderDraggedItem,
        onRemove,
        onDrop,
        items: givenItems,
        accept,
    });
    const {setNodeRef} = useDroppable({
        id: containerData.id,
        data: containerData,
    });
    return (
        <SortableContext id={containerData.id} items={items} strategy={verticalListSortingStrategy}>
            {children({items, element: {ref: setNodeRef}})}
        </SortableContext>
    );
}
