import {BordenOnderdeelVan} from '@growthbase/takenlijst/bord';
import {useMemo} from 'react';
import {normalizeRelatieValues} from '@growthbase/relaties';
import {KoppelbaarItemType} from '@growthbase/taken';
import {ITicketVanBordenPaginaFragment} from '@growthbase/graphql';
import {TicketLegacyRoutes, TicketRoutes} from '@growthbase/tickets-tickets';

export const useResolveOnderdeelVan = (
    ticketId: string,
    ticketUuid: string,
    ticket?: ITicketVanBordenPaginaFragment | null
): BordenOnderdeelVan | null => {
    const factory = TicketRoutes.bord.useUrlFactory();
    const viewLegacyOrder = TicketLegacyRoutes.view.useUrlFactory();

    return useMemo((): BordenOnderdeelVan | null => {
        const relatie = normalizeRelatieValues(ticket?.relatieverwijzing?.relatieverwijzing ?? null);
        if (!ticket || !relatie) {
            return null;
        }
        return {
            id: ticketUuid,
            label: ticket.titel ?? '-',
            type: KoppelbaarItemType.Ticket,
            relatie,
            href: viewLegacyOrder({
                ticketId: ticket.externalId ?? ticketId,
                ticketnummer: ticket.nummer,
                ticketUuid: ticket.id,
            }),
            route: ({onderdeelVan, id, naam}) => {
                const node = onderdeelVan?.node;
                if (!node) {
                    throw new Error('Logic exception.');
                }
                return factory({
                    ticketId: node.externalId ?? ticketId,
                    ticketnummer: node.nummer,
                    ticketUuid: node.id,
                    bordUuid: id,
                });
            },
        };
    }, [factory, ticket, ticketId, ticketUuid, viewLegacyOrder]);
};
