import React from 'react';
import {FieldWrapperControlled, StringDatepickerField} from '@growthbase/design-components';
import {InstellingInputProps} from './InstellingInputWrapper';

export const DateInstellingInput = ({label, name, override}: InstellingInputProps) => {
    type Type = Record<typeof name, string>;
    const extraProps: Record<string, unknown> = {};
    return (
        <FieldWrapperControlled<Type, keyof Type>
            label={label}
            component={StringDatepickerField}
            name={name}
            disabled={!!override}
            {...extraProps}
        />
    );
};
