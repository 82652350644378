import React, {FC} from 'react';
import {IBordtemplateMetLijstenBordFragment} from '@growthbase/graphql';
import {VerwijderButtonConnected} from '@growthbase/takentemplates-bordtemplate';
import {ActionsBar} from './ActionsBar';
import {KopieerButtonConnected} from '../../../Kopieren';

export interface LijstTemplateBordActionMenuItemsConnectedProps {
    fragment: IBordtemplateMetLijstenBordFragment;
}

export const ActionsBarConnected: FC<LijstTemplateBordActionMenuItemsConnectedProps> = ({fragment}) => (
    <ActionsBar
        bordtemplateId={fragment.id}
        renderVerwijderButton={
            fragment.availableActions.kanVerwijderen
                ? (props) => (
                      <VerwijderButtonConnected disabled={false} readonly={false} {...props} fragment={fragment} />
                  )
                : undefined
        }
        renderKopierenButton={
            fragment.availableActions.kanKopieren
                ? (props) => <KopieerButtonConnected {...props} fragment={fragment} />
                : undefined
        }
    />
);
