import {produce} from 'immer';
import {GroupedCalendarSelectionItem} from './CalendarSelectionGrouped';

export type CalendarSelectionGroupedState = GroupedCalendarSelectionItem[];

// eslint-disable-next-line no-shadow
export enum CalendarSelectionGroupedActionType {
    SET = 'SET',
    SELECT = 'SELECT',
    TOGGLE_ITEM = 'TOGGLE_ITEM',
    TOGGLE_GROUP = 'TOGGLE_GROUP',
}

type GroupedCalendarSelectionSetAction = {
    type: CalendarSelectionGroupedActionType.SET;
    items: GroupedCalendarSelectionItem[];
};

type GroupedCalendarSelectionDefaultAction = {
    type:
        | CalendarSelectionGroupedActionType.TOGGLE_GROUP
        | CalendarSelectionGroupedActionType.TOGGLE_ITEM
        | CalendarSelectionGroupedActionType.SELECT;
    id: string;
};

export type GroupedCalendarSelectionAction = GroupedCalendarSelectionDefaultAction | GroupedCalendarSelectionSetAction;

export const groupedCalendarSelectionReducer = (
    state: CalendarSelectionGroupedState,
    action: GroupedCalendarSelectionAction
) => {
    switch (action.type) {
        case CalendarSelectionGroupedActionType.SET:
            return action.items;
        case CalendarSelectionGroupedActionType.SELECT:
            return produce(state, (draftState) => {
                draftState.forEach((group) => {
                    group.items.forEach((item) => {
                        if (item.id === action.id && item.selectable) {
                            item.selected = true;
                            item.enabled = true;
                        } else if (item.id === action.id) {
                            item.enabled = !item.enabled;
                        } else {
                            item.selected = false;
                        }
                    });
                });
            });
        case CalendarSelectionGroupedActionType.TOGGLE_ITEM:
            return produce(state, (draftState) => {
                const items = draftState.flatMap((group) => group.items);
                const otherEnabledItem = items.find((i) => i.enabled && !i.selected);
                draftState.forEach((group) => {
                    group.items.forEach((item) => {
                        if (item.id === action.id) {
                            if (item.selected) {
                                if (otherEnabledItem) {
                                    otherEnabledItem.selected = true;
                                    otherEnabledItem.enabled = true;
                                    item.selected = false;
                                    item.enabled = false;
                                    return;
                                }
                                return;
                            }
                            item.enabled = !item.enabled;
                        }
                        group.enabled = !group.items.some((filterItem) => !filterItem.enabled);
                    });
                });
            });
        case CalendarSelectionGroupedActionType.TOGGLE_GROUP:
            return produce(state, (draftState) => {
                draftState.forEach((group) => {
                    if (group.id === action.id) {
                        group.enabled = !group.enabled;
                        group.items.forEach((item) => {
                            item.enabled = group.enabled;
                        });
                    }
                });
            });
        default:
            return state;
    }
};
