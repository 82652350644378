/* eslint-disable */
import {
    IBordenTemplatesConnectedFragment,
    IKoppelbaarType,
    useBordenTemplatesConnectedQuery,
} from '@growthbase/graphql';
import React, {useMemo, useState} from 'react';
import {usePaginationSearchParams} from '@growthbase/routing';
import {DefaultKoppelbaarItem, useCallbackRef, useHandleError} from '@growthbase/spa';
import {RenderViewType, RenderViewTypeProps} from './Components/RenderViewType';
import {CardsViewConnected} from './Components';
import {TabelConnected} from './Components/Tabel/TabelConnected';
import {Overview} from './Overview';
import {OverviewFilters, ViewType} from './types';
import {useUpdateFromExternalEntityChanges} from './Hook';

export interface OverviewConnectedProps {
    readonly?: boolean;
}

export function OverviewConnected({readonly}: OverviewConnectedProps) {
    const defaultItem = DefaultKoppelbaarItem.useValue();

    const abonnementId = defaultItem?.type === IKoppelbaarType.Abonnement ? defaultItem.id : null;

    const disabled = false;
    const [filters, setFilter] = useState<OverviewFilters>({
        searchQuery: null,
    });

    const {pagination, usePagination} = usePaginationSearchParams<IBordenTemplatesConnectedFragment>('bord');
    const result = useBordenTemplatesConnectedQuery({
        variables: {
            abonnement: abonnementId,
            herbruikbaar: !abonnementId,
            fulltextSearch: filters.searchQuery,
            ...pagination,
        },
        onError: useHandleError(),
    });

    const renderType = useMemo(
        (): Record<ViewType, RenderViewType<IBordenTemplatesConnectedFragment>> => ({
            [ViewType.Cards]: (args: RenderViewTypeProps<IBordenTemplatesConnectedFragment>) => (
                <CardsViewConnected {...args} />
            ),
            [ViewType.Tabel]: (args: RenderViewTypeProps<IBordenTemplatesConnectedFragment>) => (
                <TabelConnected {...args} />
            ),
        }),
        []
    );
    const helpers = usePagination(result);

    useUpdateFromExternalEntityChanges({
        result,
        filters,
    });

    return (
        <Overview<IBordenTemplatesConnectedFragment>
            setFilter={useCallbackRef((updatedFilters) => {
                setFilter(updatedFilters);
            })}
            filters={filters}
            pagination={helpers}
            renderType={renderType}
            disabled={disabled}
            readonly={readonly ?? false}
        />
    );
}
