import {useConvertFetchEntity, useExternalEntityAdded, useExternalEntityRemoved} from '@growthbase/cache';
import {ITaakBijlageFragment, refetchTaakBijlagesQuery} from '@growthbase/graphql';
import {compareIds} from '@growthbase/routing';
import {useCallbackRef} from '@growthbase/spa';

export interface EntityChangesParams {
    taakId?: string | null;
}

export const useHandleBijlagesChanges = (taakId: string | null | undefined, onChange: () => void) =>
    useCallbackRef((options: {id: string; typename: string; node?: {taakId?: string}}) => {
        const {typename, node} = options;
        if (typename !== 'TaakBijlage') {
            return;
        }
        if (!taakId || !node?.taakId || !compareIds(taakId, node?.taakId)) {
            return;
        }
        onChange();
    });

export const useEntityChanges = ({taakId}: EntityChangesParams) => {
    const updateCache = useConvertFetchEntity(({id}) =>
        refetchTaakBijlagesQuery({
            taakId: id,
        })
    );

    const handle = useHandleBijlagesChanges(taakId, () => updateCache({id: taakId ?? ''}));

    useExternalEntityAdded<ITaakBijlageFragment>(handle);
    useExternalEntityRemoved<ITaakBijlageFragment>(handle);
};
