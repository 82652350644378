import {PaginationHelpers} from '@growthbase/spa';
import {Fragment, ReactNode} from 'react';
import classNames from 'classnames/bind';
import {PaginationInfiniteScroll} from '../../PaginationInfiniteScroll';
import styles from './ActivityCardList.module.scss';

export interface ActivityCardListProps<TNode> {
    children: (node: TNode) => ReactNode;
    pagination: PaginationHelpers<TNode>;
}

const cx = classNames.bind(styles);

export function ActivityCardList<TNode>({children, pagination}: ActivityCardListProps<TNode>) {
    const nodes = pagination.connections.map((node, key) => <Fragment key={key}>{children(node)}</Fragment>);
    return (
        <PaginationInfiniteScroll<TNode> helpers={pagination}>
            <div className={cx('activityCardList')}>
                <div className={cx('activityCardList__items')}>
                    <div className={cx('activityCardList__line')} />
                    {nodes.slice(0, -1)}
                </div>
                {nodes.slice(-1)}
            </div>
        </PaginationInfiniteScroll>
    );
}
