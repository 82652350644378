import {InstellingenId, useInstellingenValues} from '@growthbase/instellingen';
import {ReactNode} from 'react';

export interface InstellingenValuesProps<T> {
    id: InstellingenId;

    children: (values: T) => ReactNode;
}

export function InstellingenValues<T>({id, children}: InstellingenValuesProps<T>) {
    const values = useInstellingenValues(id);
    return <>{children(values as T)}</>;
}
