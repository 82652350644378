const users = [
    {naam: 'Steffen Albronda'},
    {naam: 'Sieuwerd Abrahams'},
    {naam: 'Anneke Heeren'},
    {naam: 'Lambert Baak'},
    {naam: 'Helga Van Veen'},
    {naam: 'Jacintha Jansing'},
    {naam: 'Helga Van Veen'},
    {naam: 'Matthias Aldenkamp'},
    {naam: 'Cornelis Schneiders'},
    {naam: 'Constant Snaaijer'},
];

export const createAvatarRowData = (id = 0) => {
    const total = users.length;
    let user = users[Math.floor(Math.random() * total)];

    if (id && id <= total) {
        user = users[id - 1];
    }

    const imageUrl = id % 3 === 0 ? null : `https://i.pravatar.cc/150?u=${id}`;

    return {
        ...user,
        id: id.toString(),
        active: id % 2 !== 0,
        imageUrl,
    };
};
