import {ApolloClient, NormalizedCacheObject} from '@apollo/client';
import {IPusherFragment, IPusherQuery, IPusherQueryVariables, PusherDocument} from '@growthbase/graphql';
import Pusher, {Channel} from 'pusher-js/with-encryption';
import {PusherAuthorizer} from '../PusherAuthorizer';

export type PusherWithChannel = [Pusher, Channel];

export class SocketsClient {
    constructor(private client: ApolloClient<NormalizedCacheObject>) {}

    public async fetch(): Promise<PusherWithChannel[]> {
        const result = await this.client.query<IPusherQuery, IPusherQueryVariables>({
            query: PusherDocument,
            variables: {},
        });

        const channels = [];
        const {socketQueryProfielSocket, socketQueryGebruikerSocket} = result.data;
        if (socketQueryProfielSocket?.channel) {
            channels.push(this.map(socketQueryProfielSocket.channel));
        }
        if (socketQueryGebruikerSocket?.channel) {
            channels.push(this.map(socketQueryGebruikerSocket.channel));
        }
        return channels;
    }

    private map(channelData: IPusherFragment): PusherWithChannel {
        const pusher = new Pusher(channelData.appKey, {
            authEndpoint: channelData.authenticationUrl,
            authorizer: (channel) => new PusherAuthorizer(channelData.authenticationUrl, channel),
            cluster: channelData.cluster,
        });

        const channel = pusher.subscribe(channelData.pusherChannel);
        return [pusher, channel];
    }
}
