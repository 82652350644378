import {QueryResult} from '@apollo/client/react/types/types';
import {PositieType, useAddToQueryCache} from '@growthbase/cache';
import {
    IAvailableTaaktemplateActions,
    IMaakTaaktemplateTaaktemplateInput,
    ITaaktemplate,
    ITakentemplateVanLijstSwimlaneQuery,
} from '@growthbase/graphql';
import {useCreateWrapLockItem} from '@growthbase/locking';
import {useCallback} from 'react';
import {CreateFormDTO} from '@growthbase/takentemplates-taaktemplate';
import {v4} from 'uuid';

/**
 * Common hook for adding a new locked taak to the cache --> sending it to the api --> adding it to the cache.
 */
export function useAddTaaktemplateLockHandler<TVariables, TRow extends IMaakTaaktemplateTaaktemplateInput>(
    result: QueryResult<ITakentemplateVanLijstSwimlaneQuery, TVariables>,
    add: (item: TRow) => Promise<unknown>
) {
    const appendBeforeSendingToTheApi = useAddToQueryCache(result.updateQuery, {
        appendItemFromNextPage: true,
        edgeTypeName: 'TaaktemplateEdge',
        nodeTypeName: 'Taaktemplate',
        placeholderPosition: true,
    });
    const lock = useCreateWrapLockItem();
    return useCallback(
        (data: CreateFormDTO) => {
            const availableActions: IAvailableTaaktemplateActions = {
                KanTitelWijzigen: false,
                kanVerwijderen: false,
                kanOmschrijvingWijzigen: false,
                kanVerwijderenUitLijst: false,
            };
            const row: ITaaktemplate = {
                titel: data.titel,
                isLocked: true,
                availableActions,
                id: v4(),
                events: {
                    nodes: [],
                },
                created: new Date().toISOString(),
            };

            appendBeforeSendingToTheApi(row, PositieType.after);
            return lock(
                {
                    id: row.id,
                    __typename: 'Taaktemplate',
                },
                () =>
                    add({
                        id: row.id,
                        titel: data.titel,
                        omschrijving: data.omschrijving,
                    } as TRow).then(() => undefined)
            );
        },
        [lock, appendBeforeSendingToTheApi, add]
    );
}
