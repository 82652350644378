import {useSelector} from 'react-redux';
import {PlanningStoreState, ViewFormat} from '@growthbase/planning';

export type AgendaModuleType = 'agenda' | 'planning' | 'opdracht-plan-popup';

export const useCurrentView = (type?: AgendaModuleType): ViewFormat => {
    const {activeAgendaView, views} = useSelector((state: PlanningStoreState) => state.planning);

    if (type === 'agenda') {
        return views.find((view) => view.id === activeAgendaView) || views[0];
    }

    return views.find((view) => view.active) || views[0];
};
