import {DNDItemData, DNDItemId, ItemPlacement} from '../Value';

/**
 * Calculates the placement of the item that is being replaced.
 *
 * We cannot simply swap the items, because we need to keep the order of the items.
 * and we don't know if the item is direct neighbour of the item that is being dragged.
 */
export const calculateReplacementPlacement = (
    items: DNDItemData[],
    targetId: DNDItemId | null,
    itemId: DNDItemId | null,
    /**
     * @internal - Used for recursion.
     *
     * The placement of the item that is being dragged should it be before or after the target.
     */
    placement?: 'before' | 'after'
): ItemPlacement => {
    const targetIndex = items.findIndex((i) => i.id === targetId);
    const itemIndex = itemId ? items.findIndex((i) => i.id === itemId) : -1;
    const beforeTarget = items[targetIndex - 1];
    const target = items[targetIndex];
    const afterTarget = items[targetIndex + 1];
    const itemInList = itemIndex !== -1;

    if (targetIndex === -1) {
        return {};
    }

    // Already in place.
    if (itemIndex === targetIndex) {
        return {};
    }

    if (!placement) {
        placement = targetIndex === 0 ? 'before' : 'after';
    }

    if (itemInList) {
        return calculateReplacementPlacement(
            items.filter((i) => i.id !== itemId),
            targetId,
            null,
            itemIndex > targetIndex ? 'before' : 'after'
        );
    }
    if (afterTarget && beforeTarget) {
        if (placement === 'before') {
            return {
                itemThatIsBefore: beforeTarget.userData,
                itemThatIsAfter: target.userData,
            };
        }
        return {
            itemThatIsBefore: target.userData,
            itemThatIsAfter: afterTarget.userData,
        };
    }

    if (beforeTarget) {
        if (placement === 'before') {
            return {
                itemThatIsBefore: beforeTarget.userData,
                itemThatIsAfter: target.userData,
            };
        }
        return {
            itemThatIsBefore: target.userData,
        };
    }
    if (afterTarget) {
        if (placement === 'after') {
            return {
                itemThatIsBefore: target.userData,
                itemThatIsAfter: afterTarget.userData,
            };
        }
        return {
            itemThatIsAfter: target.userData,
        };
    }
    if (target) {
        if (placement === 'after') {
            return {
                itemThatIsBefore: target.userData,
            };
        }
        return {
            itemThatIsAfter: target.userData,
        };
    }
    return {};
};
