import {useMemo} from 'react';
import {InstellingFieldsInput, ScalarValueInput} from '@growthbase/instellingen';
import {useTranslation} from 'react-i18next';
import {useLijstKopierenOpties} from '@growthbase/takenlijst-lijst';
import {BordKopierenFormValues} from '../KopieerForm';

export const useBordKopierenOpties = (naam: string) => {
    const {t} = useTranslation('TakenlijstBord.nl');
    const takenFields = useLijstKopierenOpties('');
    return useMemo(
        (): InstellingFieldsInput<BordKopierenFormValues> => ({
            naam: {
                label: t('Kopieren.naam'),
                defaultValue: t('Kopieren.copyNaam', {naam}) ?? '',
            },
            alles: {
                control: {
                    activiteiten: {when: true, value: true},
                    omschrijving: {when: true, value: true},
                    deelnemers: {when: true, value: true},
                    gekoppeldeItem: {when: true, value: true},
                    zichtbaarVoor: {when: true, value: true},
                    lijsten: {when: true, value: true},
                    taken: {when: true, value: true},
                    taak: {when: true, value: true},
                },
                label: t('Kopieren.everything'),
                defaultValue: false,
                exclude: false,
            },
            omschrijving: {
                label: t('Kopieren.omschrijving'),
                defaultValue: true,
            },
            gekoppeldeItem: {
                label: t('Kopieren.gekoppeldeItem'),
                defaultValue: true,
            },
            lijsten: {
                label: t('Kopieren.lijsten'),
                defaultValue: true,
                control: {
                    taken: {when: false, value: false, hide: true},
                    taak: {when: false, value: false, hide: true},
                    taakFilter: {when: false, value: false, hide: true},
                },
            },
            taken: takenFields.taken as ScalarValueInput<BordKopierenFormValues, false, BordKopierenFormValues>,
            deelnemers: {
                label: t('Kopieren.deelnemers'),
                defaultValue: false,
            },
            zichtbaarVoor: {
                label: t('Kopieren.zichtbaarVoor'),
                defaultValue: false,
                control: {
                    deelnemers: {when: true, value: true},
                },
            },
            activiteiten: {
                label: t('Kopieren.activiteiten'),
                defaultValue: false,
            },
            taakFilter: takenFields.taakFilter,
            taak: takenFields.taak,
        }),
        [naam, t, takenFields.taakFilter, takenFields.taak, takenFields.taken]
    );
};
