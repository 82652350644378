import {PayloadAction} from '@reduxjs/toolkit';
import type {WritableDraft} from 'immer/dist/types/types-external';
import {orderDraggingItemsFromPlacement, resetContainers} from '../utils';
import {DNDContainerId, DNDItemId, DNDState} from '../Value';

export function onDragOverAction(
    state: WritableDraft<DNDState>,
    {
        payload: {overContainerId, overId, position},
    }: PayloadAction<{
        overContainerId: DNDContainerId | null;
        overId: DNDItemId | null;
        /**
         * Null when dragging over void space.
         */
        position: 'before' | 'after' | null;
    }>
) {
    if (!state.active) {
        throw new Error('No active item');
    }
    const {item, overContainerId: previousOverContainerId} = state.active;
    const {sourceContainerId} = state.active;

    const sourceContainer = state.containers[sourceContainerId];
    if (!sourceContainer) {
        throw new Error('Source container not found');
    }
    const overContainer = overContainerId ? state.containers[overContainerId] : null;
    const previousOverContainer = previousOverContainerId ? state.containers[previousOverContainerId] : null;

    /**
     * We are not dragging over a container, we should set the correct items in order for DND.
     */
    if (!overContainer) {
        sourceContainer.draggingItemsDND = sourceContainer.draggingItems;
        if (previousOverContainer) {
            previousOverContainer.draggingItemsDND = previousOverContainer.draggingItems;
        }
        return;
    }

    /**
     * Switching containers, we should reset the previous DND items.
     */
    if (previousOverContainerId !== overContainerId) {
        sourceContainer.draggingItemsDND = null;
    }

    resetContainers(state, {excludeIds: [overContainerId, sourceContainerId]});

    /**
     * We are dragging over a container, but not over an item inside the container.
     */
    if (overId === null) {
        const found = !!overContainer?.draggingItemsDND?.find((i) => i.id === item.id);

        if (found) {
            sourceContainer.draggingItemsDND = sourceContainer.draggingItems;
            return;
        }

        const filteredItems = overContainer.items.filter((i) => i.id !== item.id);
        if (position === 'before') {
            overContainer.draggingItems = [item, ...filteredItems];
        }
        if (position === 'after') {
            overContainer.draggingItems = [...filteredItems, item];
        }
        if (sourceContainerId !== overContainerId) {
            sourceContainer.draggingItemsDND = null;
            sourceContainer.draggingItems = sourceContainer.items.filter((i) => i.id !== item.id);
        }
        overContainer.draggingItemsDND = overContainer.draggingItems;

        state.active.overContainerId = overContainer.container.id;
        return;
    }

    if (overId === item.id) {
        return;
    }

    state.active.itemOverId = overId;

    if (!overContainer) {
        orderDraggingItemsFromPlacement(sourceContainer, item, overId);
        if (sourceContainer.draggingItemsDND === null) {
            sourceContainer.draggingItemsDND = sourceContainer.items;
        }
        return;
    }

    state.active.overContainerId = overContainer?.container.id ?? null;

    if (overContainerId !== sourceContainerId) {
        sourceContainer.draggingItems = sourceContainer.items.filter((i) => i.id !== item.id);
        sourceContainer.draggingItemsDND = null;
    } else if (sourceContainer.draggingItemsDND === null) {
        sourceContainer.draggingItemsDND = sourceContainer.items;
    }

    orderDraggingItemsFromPlacement(overContainer, item, overId);

    // The first time we are over a container, we need to save the original items.
    if (overContainerId !== sourceContainerId && overContainer.draggingItemsDND === null) {
        overContainer.draggingItemsDND = overContainer.draggingItems;
    }
}
