import {useCallback} from 'react';
import {useCreateStrictForm} from '../../Form/Hook';
import {SearchInput, useSearchFormSchema} from '../SearchFormSchema';

export interface SearchFormProps {
    value: SearchInput;
    onChange: (value: SearchInput) => Promise<void> | void;
}

export const useSearchForm = ({onChange, value}: SearchFormProps) =>
    useCreateStrictForm<SearchInput>({
        onSubmit: useCallback(
            (submitted) => {
                const query = submitted.query ?? null;
                return Promise.resolve(
                    onChange({
                        query: query?.trim() === '' ? null : query,
                    })
                );
            },
            [onChange]
        ),
        enableLockPopupAndRoutingWhenDirty: false,
        schema: useSearchFormSchema(),
        continuesAutoSubmit: true,
        createInitialValues: useCallback(() => ({query: value?.query ?? ''}), [value]),
    });
