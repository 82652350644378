import {RelatieValue} from '@growthbase/relaties';
import {IKoppelbaarType} from '@growthbase/graphql';
import {DefaultValueFactory} from './DefaultValueFactory';

/**
 * Wordt gebruikt zodat binnen een component standaard waarde gebruikt worden.
 * Woordoor het niet nodig is om deze waardes steeds door te geven.
 */

export const DefaultGebruiker = DefaultValueFactory<{
    id: string;
    naam: string;
}>('Gebruiker');

export interface KoppelbaarItem {
    id: string;
    label: string;
    type: IKoppelbaarType;
    href?: string;
}

export const DefaultKoppelbaarItem = DefaultValueFactory<KoppelbaarItem>('KoppelbaarItem');
export const DefaultRelatieValue = DefaultValueFactory<RelatieValue>('RelatieValue');
export const DefaultLijsttemplate = DefaultValueFactory<string>('Lijsttemplate');
export const DefaultBordtemplate = DefaultValueFactory<string>('Bordtemplate');
