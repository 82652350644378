import {ReactNode} from 'react';

export interface TabRouteInterface {
    title: string;
    to: string;
    subRoutes?: string[];
    replace?: boolean;
}

export interface TabQueryInterface {
    title: string;
    value: string;
}

export interface TabDisabledInterface {
    title: string;
}

export type TabInterface = (TabRouteInterface | TabDisabledInterface | TabQueryInterface) & {
    title: string;
    children?: ReactNode;
};

export type TabEnabledInterface = TabRouteInterface | TabQueryInterface;

export const isTabRoute = (tab: unknown): tab is TabRouteInterface => {
    const record = tab as Record<string, unknown>;
    return typeof record.to === 'string';
};

export const isTabQuery = (tab: unknown): tab is TabQueryInterface => {
    const record = tab as Record<string, unknown>;
    return typeof record.value === 'string';
};

export const isDisabledTab = (tab: unknown): tab is TabDisabledInterface => !isTabRoute(tab) && !isTabQuery(tab);
