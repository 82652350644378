import * as yup from 'yup';
import {Channel} from 'pusher-js/with-encryption';
import {SocketEnvelope} from './value';
import {PusherWithChannel} from './hook/SocketsClient';

export type MessageListener = (message: unknown) => void;

export class ListenerRegistry {
    private listeners: Map<string, MessageListener[]> = new Map();

    private typesConnected: Map<string, boolean> = new Map();

    private channels: PusherWithChannel[] = [];

    public add(event: string, listener: MessageListener): void {
        const listeners = this.listeners.get(event) || [];
        listeners.push(listener);
        this.listeners.set(event, listeners);
        this.listenKeys([event]);
    }

    public listen(channels: PusherWithChannel[]): void {
        this.channels = channels;
        for (const [, channel] of this.channels) {
            const collectionId = this.createId(channel, 'MessageCollection');
            if (!this.typesConnected.has(collectionId)) {
                this.typesConnected.set(collectionId, true);
                channel.bind('MessageCollection', (data: SocketEnvelope<unknown>) => {
                    const normalized = yup.mixed<SocketEnvelope<unknown>[]>().required().validateSync(data.data);
                    for (const it of normalized) {
                        this.dispatch(it.type, it.data);
                    }
                });
            }
        }
        this.listenKeys([...this.listeners.keys()]);
    }

    private listenKeys(keys: string[]): void {
        for (const [, channel] of this.channels) {
            for (const key of keys) {
                const id = this.createId(channel, key);
                if (this.typesConnected.has(id)) {
                    // eslint-disable-next-line no-continue
                    continue;
                }
                this.typesConnected.set(id, true);
                channel.bind(key, (message: {data: SocketEnvelope<unknown>}) => this.dispatch(key, message.data.data));
            }
        }
    }

    public remove(event: string, listener: MessageListener): void {
        const listeners = this.listeners.get(event);
        if (listeners) {
            const index = listeners.indexOf(listener);
            if (index !== -1) {
                listeners.splice(index, 1);
            }
        }
    }

    public dispatch(event: string, message: unknown): void {
        const listeners = this.listeners.get(event);
        if (!listeners) {
            return;
        }
        for (const listener of listeners) {
            listener(message);
        }
    }

    private createId(channel: Channel, event: string): string {
        return `${channel.name}-${event}`;
    }
}
