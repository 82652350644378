import axios from 'axios';
import {ReactiesTwigConnectorCommentsDtoSchema} from '../ReactiesTwigInputSchema';
import {ReactieAction} from '../reducer/ReactiesReducer';

/**
 * Load data from fetchUrl and validate with yup
 */
export const loadComments = (fetchUrl: string, dispatch: (action: ReactieAction) => void) => {
    dispatch({type: 'loading'});
    axios.get(fetchUrl).then((response) => {
        const parsedResponse = ReactiesTwigConnectorCommentsDtoSchema.validateSync(response.data);
        dispatch({type: 'setData', data: parsedResponse});
    });
};
