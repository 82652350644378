import {useContext} from 'react';
import {noop} from 'rxjs';
import {SidenavContext, SidenavContextType} from './SidenavContext';

const none: SidenavContextType = {
    isExpanded: false,
    toggleExpanded: noop,
};

export const useSidenavContext = (): SidenavContextType => {
    const context = useContext(SidenavContext);

    if (context) {
        return context;
    }

    return none;
};

export const useInSideNav = (): boolean => {
    const context = useContext(SidenavContext);
    return !!context;
};
