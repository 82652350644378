import React, {ReactNode} from 'react';
import {Link, To} from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from './SidenavSubLink.module.scss';
import {useSidenavContext} from '../Sidenav/useSidenavContext';
import {BaseButtonProps} from '../../Button';

const cx = classNames.bind(styles);

export interface SidenavSubLinkProps extends BaseButtonProps {
    children: ReactNode;
    active?: boolean;
    className?: string;
    to?: To | null;
    href?: string | null;
    withLines?: boolean;
    attributes?: {[key: string]: string};
}

export const SidenavSubLink = ({
    children,
    to,
    href,
    withLines,
    active,
    attributes,
    className = '',
}: SidenavSubLinkProps) => {
    const {isExpanded} = useSidenavContext();
    const hasLines = withLines && isExpanded;

    const linkContent = (
        <>
            {hasLines && <div className={styles.sidenavSubLink__lineHorizontal} />}
            {hasLines && <div className={styles.sidenavSubLink__lineVertical} />}
            <div className={styles.sidenavSubLink__inner}>{children}</div>
        </>
    );

    const classes = cx(className, 'sidenavSubLink', {
        'sidenavSubLink--withLines': hasLines,
        'sidenavSubLink--active': active,
    });

    if (href) {
        return (
            <a href={href} {...attributes} className={classes}>
                {linkContent}
            </a>
        );
    }

    if (!to) {
        return <div className={cx('sidenavSubLink', {'sidenavSubLink--withLines': hasLines})}>{linkContent}</div>;
    }

    return (
        <Link to={to} className={cx('sidenavSubLink', {'sidenavSubLink--withLines': hasLines})}>
            {linkContent}
        </Link>
    );
};
