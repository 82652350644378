import {RenderSwimlaneCardProps, Swimlane} from '@growthbase/design-components';
import {DNDContainerUserData, onDropItemHandler, onRemoveItemHandler} from '@growthbase/keys';
import {PaginationHelpers} from '@growthbase/spa';
import {ReactNode} from 'react';
import {Footer} from './Components/Footer';
import {Header} from './Components/Header';
import {Kaart} from './Components/Kaart/Kaart';

export interface TaakKaart {
    id: string;
}

export interface TakenswimlaneProps<
    TKaart extends TaakKaart = TaakKaart,
    TContainerData extends DNDContainerUserData = DNDContainerUserData
> {
    onDrop: onDropItemHandler<TKaart, TContainerData>;
    onRemove?: onRemoveItemHandler<TKaart>;
    userData: TContainerData;
    pagination: PaginationHelpers<TKaart>;
    header?: ReactNode;
    footer?: ReactNode;
    children: (props: RenderSwimlaneCardProps<TKaart>) => ReactNode;
    dragDisabled?: boolean;
    placeholder?: boolean;
}

export type TaaktemplateDragType = 'Taaktemplate';

export function Takenswimlane<
    TKaart extends TaakKaart = TaakKaart,
    TContainerData extends DNDContainerUserData = DNDContainerUserData
>({children, dragDisabled, ...rest}: TakenswimlaneProps<TKaart, TContainerData>) {
    const renderReadOnly = dragDisabled && ((item: TKaart) => children({item, dragging: false, hoover: false}));
    return (
        <Swimlane<TaaktemplateDragType, TKaart, TContainerData>
            type="Taaktemplate"
            {...rest}
            renderReadOnly={renderReadOnly}
        >
            {children}
        </Swimlane>
    );
}

Takenswimlane.Header = Header;
Takenswimlane.Footer = Footer;
Takenswimlane.Kaart = Kaart;
