import {FC} from 'react';
import {ActionsMenuButton} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';
import {LijstRoutes} from '@growthbase/takenlijst/bord';

export interface BewerkLijstActionsMenuButtonProps {
    lijstId: string;
    bordId: string | null;
}

export const BewerkLijstActionsMenuButton: FC<BewerkLijstActionsMenuButtonProps> = ({lijstId, bordId}) => {
    const {t} = useTranslation('spa.nl');

    const buttonText = t('takenlijst.lijst.LijstMenu.WijzigLijstActionsMenuButton.ButtonText');

    if (!lijstId) {
        return <ActionsMenuButton disabled>{buttonText}</ActionsMenuButton>;
    }

    const lijstToevoegenClickHandler = LijstRoutes.bewerken.useNavigate({
        lijstId,
        bordId,
    });

    return <ActionsMenuButton onClick={lijstToevoegenClickHandler}>{buttonText}</ActionsMenuButton>;
};
