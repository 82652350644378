/* eslint-disable */
import {useMemo} from 'react';
import {InstellingFieldsInput} from '../types';
import {InstellingFieldCollection} from '../InstellingFieldCollection';
import {ControlFieldNormalizer, FieldsNormalizer} from '../normalizers';

const normalizer = new FieldsNormalizer(new ControlFieldNormalizer());

export const normalizeFields = (fieldsInput: InstellingFieldsInput<object>): InstellingFieldCollection => {
    return normalizer.normalize(fieldsInput);
};

export const useNormalizeFields = (fieldsInput: InstellingFieldsInput<object>): InstellingFieldCollection =>
    useMemo(() => normalizeFields(fieldsInput), [fieldsInput]);
