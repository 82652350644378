import React, {FC, useMemo} from 'react';
import {Loader, useOptionalPopup} from '@growthbase/design-components';
import {useVeranderTaakVoorkeurenVanGebruikerMutation, useVoorkeurenFormulierQuery} from '@growthbase/graphql';
import {useCallbackRef, useHandleError} from '@growthbase/spa';
import {TakenInstellingenRoutes} from '@growthbase/taken-instellingen';
import {useInstellingTabUrl} from '@growthbase/instellingen';
import {useTranslation} from 'react-i18next';
import {InstellingenFormulier, TaakInstellingen} from '../InstellingenFormulier';

export interface VoorkeurenFormulierConnectedProps {
    view: boolean;
}

export const VoorkeurenFormulierConnected: FC<VoorkeurenFormulierConnectedProps> = ({view}) => {
    const {data} = useVoorkeurenFormulierQuery();

    const {gebruikersEmailInstellingen, id, gebruikerWeergaveInstellingen} = data?.ingelogdeGebruiker ?? {};

    const voorkeuren = useMemo((): (TaakInstellingen & {id: string}) | null => {
        if (!id || !gebruikersEmailInstellingen || !gebruikerWeergaveInstellingen) {
            return null;
        }
        return {
            id,
            weergave: gebruikerWeergaveInstellingen,
            email: gebruikersEmailInstellingen,
            uren: {
                directBoekenOpRelatie: false,
            },
        };
    }, [id, gebruikerWeergaveInstellingen, gebruikersEmailInstellingen]);

    const [mutate] = useVeranderTaakVoorkeurenVanGebruikerMutation({
        onError: useHandleError(),
    });
    const controls = useOptionalPopup();

    const onSubmit = useCallbackRef(async (values: TaakInstellingen) => {
        await mutate({
            variables: {
                weergave: {
                    id: voorkeuren?.id ?? '',
                    ...values.weergave,
                },
                email: {
                    id: voorkeuren?.id ?? '',
                    ...values.email,
                },
            },
        }).then(controls?.close);
    });

    const editUrl = TakenInstellingenRoutes.mijnvoorkeuren_bewerken.useUrl();
    const weergave = useInstellingTabUrl<TaakInstellingen>({weergave: true}, editUrl);
    const email = useInstellingTabUrl<TaakInstellingen>({email: true}, editUrl);
    const {t} = useTranslation('TakenInstellingen.nl');
    if (!voorkeuren) {
        return <Loader center />;
    }

    return (
        <InstellingenFormulier
            title={t('MijnVoorkeuren')}
            editEmail={email}
            editWeergave={weergave}
            view={view}
            onSubmit={onSubmit}
            voorkeuren={voorkeuren}
        />
    );
};
