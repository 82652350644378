import {FC, useEffect} from 'react';
import {Buttons, Stack, useIsMobile} from '@growthbase/design-components';
import {createPortal} from 'react-dom';
import {createUseService, useLogger, usePortalDestination} from '@growthbase/spa';
import {useLocalStorage} from 'react-use';
import {AggregationLogs} from './Logs/AggregationLogs/AggregationLogs';
import {useChangeOrientation} from './Hook/useChangeOrientation';
import {useDashboardEnabled} from './Hook/useDashboardEnabled';
import {useTotalErrorCount} from './Redux/ErrorCounterReducer';

const useIsEnabled = createUseService(
    ({config: {additionalProperties}}) => additionalProperties.developerDashboardEnabled ?? false
);

export const Dashboard: FC = () => {
    const destination = usePortalDestination('developers-bar');
    const logger = useLogger('Dashboard');
    const isMobile = useIsMobile();
    const enabled = useIsEnabled();
    const [userEnabled] = useDashboardEnabled();

    useEffect(() => {
        if (!destination) {
            logger.warn('Could not find portal destination for developers-bar');
        }
    }, [logger, destination]);
    const {isBottom, isRight, changeVertical, changeHorizontal} = useChangeOrientation();

    const [open, setOpen] = useLocalStorage('dashboard-open', true);
    const toggle = () => setOpen(!open);
    const errorCount = useTotalErrorCount();
    if (!destination || isMobile || !enabled || !userEnabled) {
        return null;
    }
    return createPortal(
        <div
            style={{
                position: 'absolute',
                bottom: isBottom ? 20 : undefined,
                top: isBottom ? undefined : 20,
                right: isRight ? 20 : undefined,
                left: isRight ? undefined : 20,
                padding: open ? 20 : 0,
                border: '1px solid #ccc',
                zIndex: 10000,
                backgroundColor: 'white',
            }}
        >
            <Stack gap="tiny">
                <AggregationLogs isOpen={open ?? false} />
                <Stack gap="tiny" horizontal>
                    <Buttons.Eye
                        variant={errorCount && !open ? 'danger' : undefined}
                        onClick={toggle}
                        badgeCount={open ? undefined : errorCount}
                    />
                    {open && (
                        <>
                            {isBottom ? (
                                <Buttons.ArrowUp onClick={changeVertical} />
                            ) : (
                                <Buttons.ArrowDown onClick={changeVertical} />
                            )}
                            {isRight ? (
                                <Buttons.ChevronLeft onClick={changeHorizontal} />
                            ) : (
                                <Buttons.ChevronRight onClick={changeHorizontal} />
                            )}
                        </>
                    )}
                </Stack>
            </Stack>
        </div>,
        destination
    );
};
