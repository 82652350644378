import * as React from 'react';
import {ReactNode} from 'react';
import classNames from 'classnames/bind';
import {upperFirst} from 'lodash';
import styles from './Icon.module.scss';

/* eslint-disable no-shadow */

export enum IconSize {
    SIZE_1 = '1',
    SIZE_2 = '2',
    SIZE_3 = '3',
    SIZE_4 = '4',
    SIZE_5 = '5',
}

export type IconAnimation = 'spin' | 'pulse' | false;

export interface IconProps {
    animation?: IconAnimation;
    children?: ReactNode;
    size?: IconSize;
    className?: string;
    visible?: boolean;
}

const cx = classNames.bind(styles);

export const Icon = ({size, animation, visible = true, className, children}: IconProps) => {
    const iconClass = cx(
        {
            icon: true,
            'icon--hidden': !visible,
            [`icon--size${size}`]: !!size,
            [`icon--animation${!!animation && upperFirst(animation)}`]: !!animation,
        },
        className
    );

    return <span className={iconClass}>{children}</span>;
};

export default Icon;
