export function setValueToPath(values: Record<string, unknown>, path: string, value: unknown): void {
    const split = path.split('.');
    const name = split[0];
    const rest = split.slice(1).join('.');
    if (rest.length === 0) {
        values[name] = value;
        return;
    }
    if (!values[name]) {
        values[name] = {};
    }
    setValueToPath(values[name] as Record<string, unknown>, rest, value);
}
