import {Dispatch, SetStateAction, useState} from 'react';
import {useDebouncedValue} from './useDebouncedValue';

export const useDebouncedState = <T>(
    initialValue: T,
    timeout: number
): {
    value: T;
    setValue: Dispatch<SetStateAction<T>>;
    debouncedValue: T;
} => {
    const [value, setValue] = useState<T>(initialValue);
    const debouncedValue = useDebouncedValue<T>(value, timeout);
    return {
        value,
        setValue,
        debouncedValue,
    };
};
