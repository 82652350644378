import {ActionsMenu} from '@growthbase/design-components';
import {FC, ReactNode} from 'react';

export interface LijstMenuProps {
    bewerkMenuButton: ReactNode;
    verwijderMenuButton: ReactNode;
    markeerAlsInboxButton: ReactNode;
    lijstKopieren: ReactNode;
    verplaatstButton?: ReactNode;
}

export const LijstMenu: FC<LijstMenuProps> = ({
    bewerkMenuButton,
    verwijderMenuButton,
    markeerAlsInboxButton,
    lijstKopieren,
    verplaatstButton,
}) => (
    <ActionsMenu>
        {bewerkMenuButton}
        {lijstKopieren}
        {verplaatstButton}
        {verwijderMenuButton}
        {markeerAlsInboxButton}
    </ActionsMenu>
);
