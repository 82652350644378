import React, {ReactNode, FC} from 'react';
import styles from './ButtonBadge.module.scss';

export interface ButtonBadgeProps {
    children: ReactNode;
    className?: string;
}

export const ButtonBadge: FC<ButtonBadgeProps> = ({children, className}) => (
    <div className={`${styles.buttonBadge} ${className ?? ''}`}>{children}</div>
);
