import {TextField} from '@mui/material';
import {FC} from 'react';
import {FieldComponentProps} from '../../contract';

export const FieldNumber: FC<FieldComponentProps<number>> = ({onChange, value}) => (
    <TextField
        type="number"
        variant="outlined"
        value={`${value}`}
        onChange={(e) => onChange(parseInt(e.target.value, 10))}
    />
);
