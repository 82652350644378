import {FC} from 'react';
import {AvatarRowField, AvatarRowFieldItem, AvatarSize, SearchInput} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';

export type IDeelnemer = AvatarRowFieldItem;

export interface DeelnemersProps {
    readonly?: boolean;
    disabled?: boolean;
    items: IDeelnemer[];
    popupItems: IDeelnemer[];
    stackSize?: number;
    size?: AvatarSize;
    onAdd: (item: IDeelnemer) => void;
    onRemove: (item: IDeelnemer) => void;

    onChangeQuery: (value: SearchInput) => void;
    searchQuery: string | null;
    loading: boolean;
}

export const Deelnemers: FC<DeelnemersProps> = ({...props}) => {
    const {t} = useTranslation('spa.nl');
    return (
        <>
            <AvatarRowField<IDeelnemer>
                testId="DeelnemersAvatarPopup"
                addButtonAriaLabel={t('Deelnemers.DeelnemerToevoegen')}
                popupTitle={t('Deelnemers.Deelnemers')}
                deleteToolTip={({naam}) => t('Deelnemers.VerwijderDeelnemer', {naam})}
                addToolTip={({naam}) => t('Deelnemers.DeelnemerToevoegen')}
                {...props}
            />
        </>
    );
};
