import {AgendaModuleType, EventType, PlanningStoreState, useSelectedAgenda} from '@growthbase/planning';
import {useSelector} from 'react-redux';
import {useMemo} from 'react';
import {Gekoppeld, NietGekoppeld} from '../_const/EventClass';

export const useApplyEventColors = (events: EventType[] | undefined, module?: AgendaModuleType) => {
    const {activeTab, ticket, opdracht} = useSelector((state: PlanningStoreState) => state.planning);

    const selectedAgenda = useSelectedAgenda();
    const selectedAgendaId = selectedAgenda?.id;

    return useMemo(() => {
        if (!events) {
            return events;
        }

        return events.map((event) => {
            const cssClass = Gekoppeld;

            if (ticket) {
                return {
                    ...event,
                    cssClass: event.ticket?.id === ticket.id ? Gekoppeld : NietGekoppeld,
                };
            }

            if (opdracht && activeTab !== 'opdrachten') {
                return {
                    ...event,
                    cssClass: event.opdracht?.id === opdracht.id ? Gekoppeld : NietGekoppeld,
                };
            }

            if ((activeTab === 'inbox' || module === 'agenda') && event.resource && selectedAgendaId) {
                return {
                    ...event,
                    cssClass: event.resource === selectedAgendaId ? Gekoppeld : NietGekoppeld,
                };
            }

            return {
                ...event,
                cssClass,
            };
        });
    }, [
        activeTab,
        events,
        module,
        opdracht,
        selectedAgendaId,
        ticket,
    ]);
};
