import {TFunction} from 'i18next';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {InferType, SchemaOf} from 'yup';
import {TitelSchema} from '@growthbase/design-components';
import {useMemo} from 'react';

export const CreateFormSchema = (t: TFunction) =>
    yup
        .object({
            naam: TitelSchema(t).label(t('lijsttemplate.tabel.naam')),
        })
        .label(t('lijsttemplate.tabel.lijsttemplate', {ns: 'spa_form.nl'}))
        .required();

export type CreateFormSchemaDto = InferType<ReturnType<typeof CreateFormSchema>>;

export const createFormValue = (): CreateFormSchemaDto => ({
    naam: '',
});

export const useLijsttemplateCreateSchema = (): SchemaOf<CreateFormSchemaDto> => {
    const {t} = useTranslation();
    return useMemo(() => CreateFormSchema(t), [t]);
};
