import {memo, ReactElement, ReactNode} from 'react';
import {Menu, MenuButton, MenuButtonModifiers, MenuChangeEvent} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/zoom.css';
import classNames from 'classnames/bind';
import {useCallbackRef} from '@growthbase/spa';
import styles from './ActionsMenu.module.scss';
import {Icons} from '../icons';
import {IconSize} from '../Icon';
import {usePopupsContext} from '../Popups/PopupsContext/usePopupsContext';

const cx = classNames.bind(styles);

export interface ActionsMenuProps {
    children?: ReactNode;
    visible?: boolean;
    renderButton?: (props: MenuButtonModifiers) => ReactElement;
    gap?: number;
    arrow?: boolean;
}

const defaultButton = () => (
    <MenuButton data-testid="ActionsMenuTrigger" className={cx('actionsMenu__trigger')}>
        <Icons.Dots size={IconSize.SIZE_4} />
    </MenuButton>
);

export const ActionsMenu = memo(
    ({children, visible = true, renderButton = defaultButton, gap = 10, arrow}: ActionsMenuProps) => {
        const {setPopupOpened} = usePopupsContext();

        const onMenuChange = useCallbackRef((event: MenuChangeEvent) => {
            setPopupOpened('actions-menu', event.open);
        });

        if (!visible) {
            return null;
        }

        return (
            <Menu
                data-testid="ActionsMenu"
                align="end"
                portal
                menuClassName={cx('actionsMenu__content')}
                onMenuChange={onMenuChange}
                gap={gap}
                arrow={arrow}
                menuButton={renderButton}
            >
                {children}
            </Menu>
        );
    }
);

ActionsMenu.displayName = 'ActionsMenu';
