import {
    BoxButton,
    BoxSelectInput,
    ButtonGroup,
    Buttons,
    Field,
    Font,
    FormikMuiAutocomplete,
    ModalContent,
    ModalFooter,
    Popup,
    PopupAnchorProps,
    PopupCancelButton,
    PopupContent,
    PopupHeader,
    Stack,
    ViewConfig,
} from '@growthbase/design-components';
import React, {ReactNode, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {RelatieInputConnected} from '@growthbase/relaties';
import {KoppelButtonInputConnected, RenderKoppelButtonProps} from '@growthbase/taken';
import {useVerplaatsForm, UseVerplaatsFormOptions} from './Hook';
import {useBordOpties} from './Hook/useBordOpties';
import {usePositieOpties} from './Hook/usePositieOpties';

export interface VerplaatsenFormProps<RT extends Element = Element> extends UseVerplaatsFormOptions, ViewConfig {
    OnderdeelVanComponent?: typeof KoppelButtonInputConnected;
    RelatieInput?: typeof RelatieInputConnected;
    renderAnchor: (props: PopupAnchorProps<RT>) => ReactNode;
    enableRelatie?: boolean;
}

export const VerplaatsenForm = ({
    disabled,
    readonly,
    RelatieInput = RelatieInputConnected,
    OnderdeelVanComponent = KoppelButtonInputConnected,
    renderAnchor,
    // Temporary fix for the fact that the relatie input is not yet implemented for searching the backend
    enableRelatie,
    ...rest
}: VerplaatsenFormProps) => {
    const {t} = useTranslation('TakenlijstLijst.nl');
    const {
        form: {formik, Form, Fields, formProps},
        isValid,
        loading,
        opties,
        changeBordQuery,
        bordQuery,
    } = useVerplaatsForm(rest);
    const {PositieField, RelatieField, OnderdeelVanField, BordField} = Fields;
    const Bord = BordField.useMuiComponent(FormikMuiAutocomplete);
    const Positie = PositieField.useComponent(BoxSelectInput);
    const OnderdeelVan = OnderdeelVanField.useComponent(OnderdeelVanComponent);
    const Relatie = RelatieField.useComponent(RelatieInput);
    const renderKoppelButton = useCallback(
        ({item, ...props}: RenderKoppelButtonProps) => (
            <BoxButton {...props} value={item?.label}>
                {t('Verplaatsen.field.onderdeelVan')}
            </BoxButton>
        ),
        [t]
    );
    return (
        <Popup renderAnchor={renderAnchor} testId="VerplaatsFormModel">
            <Form {...formProps}>
                <PopupContent width="large">
                    <PopupHeader title={t('Verplaatsen.popup.titel')} />
                    <ModalContent theme="gray">
                        <Stack gap="small">
                            <Stack.Item>
                                <Font.Bold>{t('Verplaatsen.popup.tekst')}</Font.Bold>
                            </Stack.Item>
                            <Stack.Item>
                                <Bord
                                    labelHidden
                                    readonly={readonly}
                                    disabled={disabled || loading}
                                    options={useBordOpties(opties)}
                                    label={t('Verplaatsen.field.bord')}
                                    search={bordQuery ?? ''}
                                    onSearchChange={(change: string) => {
                                        change = change.trim();
                                        changeBordQuery(change.length ? change : null);
                                    }}
                                />
                            </Stack.Item>
                            <Stack.Item grow size={1}>
                                <Positie
                                    labelHidden
                                    readonly={readonly}
                                    disabled={disabled || loading}
                                    options={usePositieOpties(opties)}
                                    label={t('Verplaatsen.field.positie')}
                                />
                            </Stack.Item>
                            {enableRelatie && (
                                <Stack.Item grow size={1}>
                                    <Field label={t('Verplaatsen.field.relatie')} compact disableMargin>
                                        <Relatie readonly={readonly} disabled={disabled || loading} />
                                    </Field>
                                </Stack.Item>
                            )}
                            <OnderdeelVan
                                readonly={readonly}
                                disabled={disabled || loading}
                                renderKoppelButton={renderKoppelButton}
                            />
                        </Stack>
                    </ModalContent>
                    <ModalFooter
                        buttons={
                            <ButtonGroup justify>
                                <PopupCancelButton disabled={formik.isSubmitting} />
                                {!readonly && (
                                    <Buttons.Save formik={formik} loading={loading} disabled={disabled || !isValid}>
                                        {t('Verplaatsen.popup.verplaats')}
                                    </Buttons.Save>
                                )}
                            </ButtonGroup>
                        }
                    />
                </PopupContent>
            </Form>
        </Popup>
    );
};
