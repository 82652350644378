import {createPortal} from 'react-dom';
import {usePortalDestination} from '@growthbase/spa';
import {ButtonLinkProps, NavbarActionButton} from '../../../Buttons';

export interface ClientboxNavbarActionButtonProps {
    target: string;
    onClick?: ButtonLinkProps['onClick'];
    buttonText: string;
}

export const ClientboxNavbarActionButton = ({target, onClick, buttonText}: ClientboxNavbarActionButtonProps) => {
    const destination = usePortalDestination(target);

    if (!destination) {
        return null;
    }

    const button = <NavbarActionButton onClick={onClick}>{buttonText}</NavbarActionButton>;

    return createPortal(button, destination);
};
