import React, {FC, PropsWithChildren} from 'react';

import classNames from 'classnames/bind';
import styles from './PopupResultsGroup.module.scss';

const cx = classNames.bind(styles);

interface PopupItem {
    id: string;
}

export const generateGroupKeyById = (item: PopupItem) => item.id;

export interface PopupResultsGroupProps<T extends PopupItem = never> {
    items: T[];
    generateKey: (item: T) => string;
    children: (props: {disabled?: boolean; tabIndex: number; data: T}, item: T) => React.ReactNode;
    title?: string;
    disabled?: boolean;
}

export const PopupResultsGroupContainer: FC<PropsWithChildren> = ({children}) => (
    <div className={cx('popupResults')}>{children}</div>
);

export const PopupResultsGroupSubHeader: FC<PropsWithChildren> = ({children}) => (
    <div className={cx('popupResults__subHeader')}>{children}</div>
);

export function PopupResultsGroup<T extends PopupItem = PopupItem>({
    items,
    disabled,
    title,
    children,
    generateKey,
}: PopupResultsGroupProps<T>) {
    return (
        <PopupResultsGroupContainer>
            {title && <PopupResultsGroupSubHeader>{title}</PopupResultsGroupSubHeader>}
            <div className={cx('popupResults__results')}>
                {items.map((item, tabIndex) => (
                    <div className={cx('popupResults__result')} key={generateKey(item)}>
                        {children({disabled, tabIndex, data: item}, item)}
                    </div>
                ))}
            </div>
        </PopupResultsGroupContainer>
    );
}

export default PopupResultsGroup;
