import React, {FC, PropsWithChildren, ReactNode} from 'react';
import {Card, Stack} from '@growthbase/design-components';
import {InstellingViewProps} from './InstellingViewWrapper';
import {NestedValue} from '../../../types';
import {NestedInstellingView} from './NestedInstellingView';

/**
 * TODO: Bas <^-^> :)
 *
 * Dit lijkt me niet echt de oplossing toch?
 *
 * @param label
 * @param children
 * @constructor
 */
export const KeyValuePair: FC<PropsWithChildren<{label: string}>> = ({label, children}) => (
    <Stack horizontal>
        <Stack.Item size="50%">{label}</Stack.Item>
        <Stack.Item size="50%">{children}</Stack.Item>
    </Stack>
);

export interface NestedAsCardInstellingViewProps {
    actions?: ReactNode;
}

export const NestedAsCardInstellingView = (props: InstellingViewProps & NestedAsCardInstellingViewProps) => {
    const {actions} = props;
    const {label} = props as InstellingViewProps<NestedValue>;
    return (
        <Card limitWidth="large">
            <Card.Header>
                <Stack horizontal justify align="center">
                    <Card.HeaderTitle>{label}</Card.HeaderTitle>
                    {actions}
                </Stack>
            </Card.Header>
            <Card.Body>
                <NestedInstellingView {...props} />
            </Card.Body>
        </Card>
    );
};
