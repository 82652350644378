import {StoreObject, useApolloClient} from '@apollo/client';
import {idFromIri} from '@growthbase/routing';
import {useSocketEvent} from '@growthbase/websockets';
import * as yup from 'yup';
import {BaseNode} from '../baseNode';

export const UpdateFieldSchema = yup
    .object({
        name: yup.string().required(),
        value: yup.mixed().defined().nullable(),
        args: yup.object(),
    })
    .label('UpdateFieldSchema')
    .required();

export const UpdateFieldsSchema = yup
    .object({
        id: yup.string().required(),
        typename: yup.string().required(),
        fields: yup.array(UpdateFieldSchema).required(),
    })
    .label('UpdateFieldsSchema')
    .required();

export interface ExternalEntityChangedField<TNode extends BaseNode, Tkey extends keyof TNode = keyof TNode> {
    name: Tkey;
    value: TNode[Tkey];
    args: Record<string, unknown>;
}

export interface ExternalEntityChangedArguments<TNode extends BaseNode> {
    id: string;
    typename: string;
    changes: ExternalEntityChangedField<TNode>[];
    /**
     * Node that reflects the changes, for convenience purpose only.
     */
    node: Partial<TNode>;
    /**
     * List of all fields that changed.
     */
    fields: (keyof TNode)[];
}

export const useExternalEntityChanged = <TNode extends BaseNode>(
    callback: (args: ExternalEntityChangedArguments<TNode>) => void
) => {
    const apollo = useApolloClient();
    useSocketEvent('UpdateFields', (data) => {
        const normalized = UpdateFieldsSchema.validateSync(data);
        const node = {
            __typename: normalized.typename,
        } as unknown as TNode;

        normalized.fields.forEach(({name, value}) => {
            // @ts-expect-error Ignore types.
            node[name] = value;
        });

        node.id = apollo.cache.identify({
            ...node,
            id: idFromIri(normalized.id),
            __typename: normalized.typename,
        } as unknown as StoreObject) as string;

        callback({
            ...normalized,
            changes: normalized.fields as unknown as Array<ExternalEntityChangedField<TNode>>,
            fields: Object.keys(node) as unknown as Array<keyof TNode>,
            node,
        });
    });
};
