import {RenderApolloError, useGebruikerHeeftToegang} from '@growthbase/design-components';
import {
    ChangeType,
    PositieType,
    QueryCacheUpdateType,
    useConvertFetchEntity,
    useUpdateQueryCacheFromExternalEntityChanges,
} from '@growthbase/cache';
import {ILogbookFragment, PossibleTypes, refetchLogbookAddQuery, useLogbookQuery} from '@growthbase/graphql';
import {compareIds, usePaginationSearchParams} from '@growthbase/routing';
import {usePollInterval} from '@growthbase/websockets';
import {FC, useMemo} from 'react';
import {Logbook} from './Logbook';
import {useLogbookItems} from './Hook/useAddLogbookItems';

export interface LogbookConnectedProps {
    id?: string;
    relatedTypes?: string[];
}

export const LogbookConnected: FC<LogbookConnectedProps> = ({relatedTypes, id}) => {
    const {pagination, usePagination} = usePaginationSearchParams<ILogbookFragment>('logbook');

    const ids = useLogbookItems();

    const idsCombined = useMemo(() => {
        const combined = [...ids];
        if (id) {
            combined.push(id);
        }
        if (combined.length === 0) {
            return undefined;
        }
        return combined;
    }, [ids, id]);

    const result = useLogbookQuery({
        pollInterval: usePollInterval(),
        variables: {
            ids: idsCombined,
            relatedTypes,
            ...pagination,
        },
    });

    useUpdateQueryCacheFromExternalEntityChanges<ILogbookFragment & {targetId?: string}>({
        result,
        // @ts-expect-error We know the type of the node
        determineAction: ({type, node, id: forId, typename}) => {
            if (type === ChangeType.add && PossibleTypes.Activiteit.includes(typename)) {
                if (idsCombined) {
                    const {targetId} = node;
                    const found = idsCombined.find((n) => compareIds(n, targetId));
                    if (!found) {
                        return QueryCacheUpdateType.ignore;
                    }
                }
                if (relatedTypes) {
                    return QueryCacheUpdateType.refetch;
                }
                return QueryCacheUpdateType.add;
            }
            return QueryCacheUpdateType.ignore;
        },
        positie: PositieType.before,
        fetchEntity: useConvertFetchEntity(refetchLogbookAddQuery),
    });

    const toegang = useGebruikerHeeftToegang({
        toegang: result?.data?.ingelogdeGebruiker?.availableActions?.kanTakenBekijken,
    });

    const helpers = usePagination(result);

    if (!toegang) {
        return null;
    }

    // TODO: add to list, errors can also be warnings for missing fields.
    if (result.error) {
        return <RenderApolloError error={result.error} />;
    }

    return <Logbook helpers={helpers} />;
};
