import {ReactNode} from 'react';
import styled from 'styled-components';
import {CardHeader} from './Components/CardHeader';
import {CardHeaderTitle} from './Components/CardHeaderTitle';
import {CardBody} from './Components/CardBody';

export interface CardProps {
    limitWidth?: 'large';
    children: ReactNode;
}

const CardRoot = styled.div<CardProps>`
  border: 1px solid var(--cardColorBorder);
  border-radius: var(--borderRadius);
  margin: 0 auto;
  width: 100%;
  background: var(--colorWhite);
  ${(props) => props.limitWidth === 'large' && `max-width: 900px;`}
}`;

export const Card = Object.assign(CardRoot, {
    Header: CardHeader,
    HeaderTitle: CardHeaderTitle,
    Body: CardBody,
});
