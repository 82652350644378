import React, {ReactNode, FC} from 'react';

import classNames from 'classnames/bind';
import styles from './CommentFormWrapper.module.scss';
import {Avatar} from '../../Avatar';

const cx = classNames.bind(styles);

export interface CommentFormWrapperProps {
    name: string;
    avatarUrl?: string | null;
    children: ReactNode;
}

export const CommentFormWrapper: FC<CommentFormWrapperProps> = ({name, avatarUrl, children}) => {
    const classes = cx('commentFormWrapper');
    return (
        <div className={classes}>
            <div className={cx('commentFormWrapper__avatar')}>
                <Avatar naam={name} imageUrl={avatarUrl} size="bigger" />
            </div>
            <div className={cx('commentFormWrapper__body')}>{children}</div>
        </div>
    );
};
