import {useSelector} from 'react-redux';
import {
    AgendaViewData,
    BaseEventType,
    PlanningStoreState,
    usePlanningRefresh,
    usePlanningSideBarConnectedMaakEventVoorOpdrachtMutation,
    useSelectedAgenda,
    useTicketSideBarConnectedMaakEventVoorTicketMutation,
} from '@growthbase/planning';

import {useAppendMessage, useCallbackRef} from '@growthbase/spa';
import {useBoolean} from 'react-use';
import {MbscEventCreateEvent} from '@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar.types.public';
import {useGlobalModifierKeys} from '@growthbase/keys';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {useEventFillGaps} from './planning/useEventFillGaps';
import {useEventPlanOnDay} from './planning/useEventPlanOnDay';

export const useOnEventCreate = (data: AgendaViewData) => {
    const {t} = useTranslation('Planning.nl');
    const {creationDateRestrictions, opdracht, dienst, ticket} = useSelector(
        (state: PlanningStoreState) => state.planning
    );

    const selectedAgenda = useSelectedAgenda();
    const refresh = usePlanningRefresh();
    const [maakEvenementVoorOpdracht] = usePlanningSideBarConnectedMaakEventVoorOpdrachtMutation();
    const [maakEvenementVoorTicket] = useTicketSideBarConnectedMaakEventVoorTicketMutation();
    const addMessage = useAppendMessage();
    const [loading, setIsLoading] = useBoolean(false);

    const fillGaps = useEventFillGaps();
    const planOnDay = useEventPlanOnDay();

    const {alt} = useGlobalModifierKeys();

    const onEventCreate = useCallbackRef((e: MbscEventCreateEvent): boolean => {
        if ((!e.event.resource && !selectedAgenda) || !e.event.start || !e.event.end) {
            return false;
        }

        const agendaId = e.event?.resource?.toString() || selectedAgenda?.id?.toString();
        let start = moment(e.event.start.toString()).toDate();
        let end = moment(e.event.end.toString()).toDate();

        if (!agendaId) {
            return false;
        }

        const backgroundEvents = data.backgroundEvents
            ? data.backgroundEvents.filter((event) => event.resource === agendaId)
            : [];

        const events = data.events ? data.events.filter((event) => event.resource === agendaId) : [];

        const range = planOnDay(e, [...events, ...backgroundEvents]);
        if (range) {
            start = range.start;
            end = range.end;
        }

        if (e.action === 'externalDrop' && e.event.originalResource !== agendaId) {
            return false;
        }

        // Don't allow event creation on disabled days
        const compareStart = moment(start).add(1, 'second');
        const compareEnd = moment(end).subtract(1, 'second');
        const creationStartDate = compareStart.toDate();
        const creationEndDate = compareEnd.toDate();

        const overlapping = creationDateRestrictions.some((overlapRange) => {
            const overlapStartDate = overlapRange.start;
            const overlapEndDate = overlapRange.end;
            return (
                (creationStartDate <= overlapEndDate && creationStartDate >= overlapStartDate) ||
                (creationEndDate <= overlapEndDate && creationEndDate >= overlapStartDate) ||
                (creationStartDate <= overlapStartDate && creationEndDate >= overlapEndDate)
            );
        });

        if (overlapping) {
            addMessage([
                {
                    id: 'cant-create-event-in-past',
                    type: 'warning',
                    message: t('JeKanNietPlannenInHetVerleden'),
                    displayType: 'user',
                },
            ]);
            return false;
        }

        let datesToCreate: BaseEventType[] = [
            {
                resource: agendaId,
                start,
                end,
            },
        ];

        if (!alt) {
            datesToCreate = fillGaps(datesToCreate[0], [...events, ...backgroundEvents]);

            if (datesToCreate.length === 0) {
                addMessage([
                    {
                        id: 'no-plan-capacity',
                        type: 'warning',
                        message: t('ErIsGeenCapaciteitBeschikbarOpHetGekozenTijdstipAlt'),
                        displayType: 'user',
                    },
                ]);
                return false;
            }
        }

        const dataDatums = datesToCreate.map((dateToCreate) => ({
            startdatum: moment(dateToCreate.start).toISOString(),
            einddatum: moment(dateToCreate.end).toISOString(),
        }));

        if (dataDatums.length === 0) {
            return false;
        }

        let opdrachtId = opdracht?.id;
        let dienstId = dienst?.id;
        let ticketId = ticket?.id;

        if (e.action === 'externalDrop') {
            opdrachtId = e.event?.opdrachtId;
            dienstId = e.event?.dienstId;
            ticketId = e.event?.ticketId;
        }

        if (opdrachtId && dienstId) {
            setIsLoading(true);
            maakEvenementVoorOpdracht({
                variables: {
                    agendaId,
                    opdrachtId,
                    dienstId,
                    datums: dataDatums,
                    toegewezenBudgetId: e.event?.toegewezenBudgetId,
                },
            }).finally(() => {
                setIsLoading(false);
                refresh();
            });
            return true;
        }

        if (ticketId && dienstId) {
            setIsLoading(true);
            maakEvenementVoorTicket({
                variables: {
                    agendaId,
                    ticketId,
                    dienstId,
                    datums: dataDatums,
                },
            }).finally(() => {
                setIsLoading(false);
                refresh();
            });
            return true;
        }

        if (e.event.resource === 'time-registrations') {
            document.dispatchEvent(
                new CustomEvent('openUrenRegistratieAanmakenModal', {
                    detail: {
                        start: moment(start).format('YYYY-MM-DD HH:mm:ss'),
                        end: moment(end).format('YYYY-MM-DD HH:mm:ss'),
                        opdracht: opdrachtId,
                        dienst: dienstId,
                        ticket: ticketId,
                    },
                })
            );
            return true;
        }

        document.dispatchEvent(
            new CustomEvent('planningEventCreate', {
                detail: {
                    start: moment(start).format('YYYY-MM-DD HH:mm:ss'),
                    end: moment(end).format('YYYY-MM-DD HH:mm:ss'),
                    resource: agendaId,
                    opdracht: opdrachtId,
                    dienst: dienstId,
                    ticket: ticketId,
                },
            })
        );
        return true;
    });

    return {
        onEventCreate,
        isCreatingEvent: loading,
    };
};
