import {ReactNode, useMemo, FC} from 'react';
import {
    Buttons,
    Table,
    TableFiltersHeader,
    TableHeader,
    TableHeaderSection,
    TableTitleWithCount,
} from '@growthbase/design-components';
import {ILijsttemplateTabelFragment} from '@growthbase/graphql';
import {PaginationHelpers} from '@growthbase/spa';
import {ApolloError} from '@apollo/client';
import {useTranslation} from 'react-i18next';
import {useTabelColumns} from './Hook/useTabelColumns';

export interface TabelProps {
    pagination: PaginationHelpers<ILijsttemplateTabelFragment>;
    zoekveld: ReactNode;
    error?: ApolloError;
    onClickCreate: () => void;
}

export const Tabel: FC<TabelProps> = ({pagination, zoekveld, error, onClickCreate}) => {
    const {t} = useTranslation('Lijsttemplate.nl');

    const columns = useTabelColumns();

    const leftActions = useMemo(() => [zoekveld], [zoekveld]);

    const rightActions = useMemo(
        () => [
            <Buttons.Add key="add" onClick={onClickCreate} tooltip={t('Aanmaken')} ariaLabel={t('Aanmaken')} />,
        ],
        [onClickCreate, t]
    );

    return (
        <Table
            error={error}
            header={
                <TableHeader>
                    <TableHeaderSection>
                        <TableTitleWithCount
                            titel={t('Lijsten')}
                            count={
                                !pagination.isLoading || pagination.totalCount !== 0 ? pagination.totalCount : undefined
                            }
                        />
                    </TableHeaderSection>
                </TableHeader>
            }
            filters={<TableFiltersHeader leftActions={leftActions} rightActions={rightActions} />}
            columns={columns}
            pagination={pagination}
        />
    );
};
