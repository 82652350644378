import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {
    DefaultFormModalFooter,
    Modal,
    ModalContent,
    PopupHeader,
    TextFieldComponent,
    useCreateStrictForm,
    usePopup,
} from '@growthbase/design-components';
import {useCallbackRef} from '@growthbase/spa';
import {CreateFormSchemaDto, createFormValue, useLijsttemplateCreateSchema} from './CreateFormSchema';

export interface CreateFormProps {
    onSubmit: (values: CreateFormSchemaDto) => Promise<void>;
}

export const CreateForm: FC<CreateFormProps> = ({onSubmit}) => {
    const {t} = useTranslation('spa_form.nl');
    const popup = usePopup();
    const {
        formik,
        Form,
        Fields: {NaamField},
        formProps,
    } = useCreateStrictForm<CreateFormSchemaDto>({
        onSubmit: useCallbackRef((values) => onSubmit(values).then(popup.close)),
        enableLockPopupAndRoutingWhenDirty: true,
        createInitialValuesAfterSubmit: true,
        createInitialValues: createFormValue,
        schema: useLijsttemplateCreateSchema(),
    });

    return (
        <Modal>
            <PopupHeader title={t('lijsttemplate.tabel.aanmaken')} />
            <ModalContent theme="gray">
                <Form {...formProps}>
                    <NaamField component={TextFieldComponent} label={t('lijsttemplate.tabel.naam')} autoFocus />
                </Form>
            </ModalContent>
            <DefaultFormModalFooter formik={formik} />
        </Modal>
    );
};
