import * as yup from 'yup';
import {InferType} from 'yup';

export const SettingsUpdatedEventDataSchema = yup
    .object({
        dagBeginTijd: yup.string().nullable(),
        dagEindTijd: yup.string().nullable(),
        eersteDag: yup.string().nullable(),
        laatsteDag: yup.string().nullable(),
    })
    .label('SettingsUpdatedEventSchema');

export type SettingsUpdatedEventData = InferType<typeof SettingsUpdatedEventDataSchema>;
