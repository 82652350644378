import {FC, PropsWithChildren, ReactNode} from 'react';
import {Paper} from '@mui/material';
import {Padding} from '@growthbase/design-components';

export interface WrapperProps extends PropsWithChildren {
    label: ReactNode;
    nestingLevel: number;
}

export const Wrapper: FC<WrapperProps> = ({label, children, nestingLevel}) => (
    <Paper elevation={nestingLevel}>
        <Padding>{children}</Padding>
    </Paper>
);
