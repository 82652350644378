import React, {ReactNode} from 'react';
import {BoardCard} from '@growthbase/design-components';

export interface CardProps {
    header: ReactNode;
}

export function Card({header}: CardProps) {
    return <BoardCard>{header}</BoardCard>;
}
