import {
    ELEMENT_BLOCKQUOTE,
    ELEMENT_CODE_BLOCK,
    ELEMENT_CODE_LINE,
    ELEMENT_DEFAULT,
    ELEMENT_H1,
    ELEMENT_H2,
    ELEMENT_H3,
    ELEMENT_H4,
    ELEMENT_H5,
    ELEMENT_H6,
    ELEMENT_LINK,
    ELEMENT_PARAGRAPH,
    MARK_BOLD,
    MARK_CODE,
    MARK_ITALIC,
    MARK_STRIKETHROUGH,
    MARK_UNDERLINE,
} from '@udecode/plate';
import {IElement, IOpgemaakteTekst, IParentElement, ISlateJsType} from '@growthbase/graphql';
import {OpgemaakteTekst, OpgemaakteTekstElement} from '../Hook/useOpgemaakteTekstSchema';

const createElement = (text: string, {type = 'p', mark}: {type?: string; mark?: string} = {}) => {
    const leaf: Record<string, unknown> = {text};
    if (mark) {
        leaf[mark] = true;
    }
    return {
        type,
        children: [leaf],
    } as unknown as OpgemaakteTekstElement;
};

export const OpgemaakteTekstExampleValue: OpgemaakteTekst = {
    elements: [
        createElement('🧱 Elements', {type: ELEMENT_H1}),
        createElement('🔥 Basic Elements', {type: ELEMENT_H2}),
        createElement('These are the most common elements, known as blocks:'),
        createElement('Heading 1', {type: ELEMENT_H1}),
        createElement('Heading 2', {type: ELEMENT_H2}),
        createElement('Heading 3', {type: ELEMENT_H3}),
        createElement('Heading 4', {type: ELEMENT_H4}),
        createElement('Heading 5', {type: ELEMENT_H5}),
        createElement('Heading 6', {type: ELEMENT_H6}),
        createElement('Blockquote', {type: ELEMENT_BLOCKQUOTE}),
        {
            type: ELEMENT_LINK,
            url: 'https://www.google.com',
            children: [
                {
                    type: ELEMENT_DEFAULT,
                    children: [
                        {
                            text: 'https://www.google.com',
                        },
                    ],
                },
            ],
        },
        {
            type: ELEMENT_CODE_BLOCK,
            children: [
                {
                    type: ELEMENT_CODE_LINE,
                    children: [
                        {
                            text: "const a = 'Hello';",
                        },
                    ],
                },
                {
                    type: ELEMENT_CODE_LINE,
                    children: [
                        {
                            text: "const b = 'World';",
                        },
                    ],
                },
            ],
        },
        createElement('💅 Marks', {type: ELEMENT_H1}),
        createElement('💧 Basic Marks', {type: ELEMENT_H2}),
        createElement(
            'The basic marks consist of text formatting such as bold, italic, underline, strikethrough, subscript, superscript, and code.'
        ),
        createElement('You can customize the type, the component and the hotkey for each of these.'),
        createElement('This text is bold.', {mark: MARK_BOLD}),
        createElement('This text is italic.', {mark: MARK_ITALIC}),
        createElement('This text is underlined.', {
            mark: MARK_UNDERLINE,
        }),
        {
            type: ELEMENT_PARAGRAPH,
            children: [
                {
                    text: 'This text is bold, italic and underlined.',
                    [MARK_BOLD]: true,
                    [MARK_ITALIC]: true,
                    [MARK_UNDERLINE]: true,
                },
            ],
        },
        createElement('This is a strikethrough text.', {
            mark: MARK_STRIKETHROUGH,
        }),
        createElement('This is an inline code.', {mark: MARK_CODE}),
    ],
};

export const normalizeOpgemaakteTekstToGraphQLInput = (tekst: OpgemaakteTekst): IOpgemaakteTekst => {
    const elements: IElement[] = [];

    // eslint-disable-next-line no-shadow
    function createElement(element: OpgemaakteTekstElement, parentIndex: number | null = null): number {
        const index = elements.length;
        // @ts-expect-error This is correct
        if (!('type' in element) || element.type === 'text') {
            elements.push({
                ...element,
                index,
                type: ISlateJsType.Text,
                parentIndex,
            });
            return index;
        }
        const newElement = {
            ...element,
            index,
            parentIndex,
        } as unknown as IElement & IParentElement;

        elements.push(newElement);

        newElement.children = element.children.map((child) => createElement(child, index));

        return index;
    }

    tekst.elements.forEach((element, index) => {
        createElement(element);
    });

    return {
        elements,
    };
};
