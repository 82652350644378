import {ApolloCache, QueryResult} from '@apollo/client';
import {MutationUpdaterFunction} from '@apollo/client/core/types';
import {ResultData} from '@growthbase/graphql';
import {useCallbackRef, useLogger} from '@growthbase/spa';
import {flattenDeep, hasIn} from 'lodash';
import {PositieType, useAddToQueryCache} from './useAddToQueryCache';
import {BaseNode} from '../baseNode';

/**
 * Add the added entity from the mutation to the query cache.
 *
 * This is an extra safety feature to make sure the user sees the entity.
 *
 * ! The entity is always added to the list, even if the list is filtered.
 */
export const useAddMutationCacheUpdate = <TNode extends BaseNode, TVariables>(
    result: QueryResult<ResultData<TNode>, TVariables>,
    positie: PositieType,
    targetCacheId?: string
): MutationUpdaterFunction<object, TVariables, unknown, ApolloCache<unknown>> => {
    const append = useAddToQueryCache<TNode, TVariables>(result.updateQuery, {
        appendItemFromNextPage: true,
    });
    const logger = useLogger('useAddMutationCacheUpdate');
    return useCallbackRef((cache: ApolloCache<unknown>, mutateResult) => {
        if (!mutateResult.data) {
            logger.warn('Missing data in mutation result', mutateResult);
            return;
        }
        const values = Object.values(mutateResult.data);
        const valuesOfValues = values.map((value) => (value instanceof Object ? Object.values(value) : []));
        const allValues = flattenDeep(valuesOfValues).filter((value) => hasIn(value, 'id'));
        if (allValues.length !== 1) {
            logger.warn('Could not update query cache for mutation result:', mutateResult);
            return;
        }
        const node = allValues[0] as TNode;
        if (!node) {
            logger.warn('Missing node in mutation result', mutateResult);
            return;
        }
        append(node, positie, targetCacheId);
    });
};
