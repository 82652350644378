import {DienstenTab, PlanningStoreState, useOpdrachtPlanningSidebarConnectedQuery} from '@growthbase/planning';
import {useEffect, useMemo} from 'react';
import {Opdracht} from '@growthbase/opdrachten-planning';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Tab, Tabs, TabsRendererMarginLeft} from '@growthbase/design-components';
import {Dienst} from '../PlanningSidebar/Dienst';
import {AgendaTabConnected} from '../../Tabs';
import {useOpdrachtSidebarState} from './useOpdrachtSidebarState';
import {DienstSelectieGroep} from '../../Components/DienstSelectie/DienstSelectieGrouped';

export interface OpdrachtSidebarConnectedProps {
    opdrachtId: string;
}

export const OpdrachtSidebarConnected = ({opdrachtId}: OpdrachtSidebarConnectedProps) => {
    const {t} = useTranslation('spa.nl');

    const {data, refetch} = useOpdrachtPlanningSidebarConnectedQuery({
        variables: {
            id: opdrachtId,
        },
    });

    useEffect(() => {
        const listener = () => {
            refetch();
        };

        document.addEventListener('refetchPlanning', listener);
        return () => {
            document.removeEventListener('refetchPlanning', listener);
        };
    }, [refetch]);

    const opdracht: Opdracht = useMemo(
        () => ({
            id: data?.Opdracht?.id || '',
            relatie: data?.Opdracht?.relatienaam || '',
            diensten: [],
            nummer: data?.Opdracht?.nummer || '',
            onderwerp: data?.Opdracht?.onderwerp || '',
        }),
        [data?.Opdracht?.id, data?.Opdracht?.nummer, data?.Opdracht?.onderwerp, data?.Opdracht?.relatienaam]
    );

    const {handleKeuzeChange} = useOpdrachtSidebarState(opdracht);

    useEffect(() => {
        handleKeuzeChange();
    }, [handleKeuzeChange, opdrachtId]);

    const budgetDiensten =
        data?.Opdracht?.budgetDiensten?.map(
            (dienst): Dienst => ({
                id: dienst?.id ?? '',
                titel: dienst?.titel ?? '',
                resterendBudgetVoorOpdracht: dienst?.resterendBudgetVoorOpdracht?.resterendeTijdInSeconde
                    ? {
                          resterendeTijdInSeconde: dienst.resterendBudgetVoorOpdracht.resterendeTijdInSeconde,
                      }
                    : undefined,
            })
        ) ?? [];

    const overigeDiensten =
        data?.Opdracht?.overigeDiensten?.map(
            (dienst): Dienst => ({
                id: dienst?.id ?? '',
                titel: dienst?.titel ?? '',
                resterendBudgetVoorOpdracht: dienst?.resterendBudgetVoorOpdracht?.resterendeTijdInSeconde
                    ? {
                          resterendeTijdInSeconde: dienst.resterendBudgetVoorOpdracht.resterendeTijdInSeconde,
                      }
                    : undefined,
            })
        ) ?? [];

    const hasGroups = budgetDiensten.length && overigeDiensten.length;

    const groepen: DienstSelectieGroep[] | undefined = hasGroups
        ? [
              {label: 'Budget', diensten: budgetDiensten},
              {label: 'Overig', diensten: overigeDiensten},
          ]
        : undefined;

    const diensten: Dienst[] = budgetDiensten.length ? budgetDiensten : overigeDiensten;

    const {dienst} = useSelector((state: PlanningStoreState) => state.planning);

    return (
        <Tabs render={TabsRendererMarginLeft} query={{defaultValue: 'agendas', name: 'sbd'}}>
            <Tab title={t('planning.agendasTab')} value="agendas">
                <AgendaTabConnected />
            </Tab>
            <Tab title={t('planning.dienstenTab')} value="diensten">
                {groepen ? (
                    <DienstenTab
                        key={opdrachtId}
                        groepen={groepen}
                        activeDienstId={dienst?.id}
                        keuzeAangepast={(dienstId) => {
                            handleKeuzeChange(dienstId);
                        }}
                    />
                ) : (
                    <DienstenTab
                        key={opdrachtId}
                        diensten={diensten}
                        activeDienstId={dienst?.id}
                        keuzeAangepast={(dienstId) => {
                            handleKeuzeChange(dienstId);
                        }}
                    />
                )}
            </Tab>
        </Tabs>
    );
};
