import {QueryResult} from '@apollo/client/react/types/types';
import {useConvertFetchEntity, useUpdateQueryCacheFromExternalEntityChanges} from '@growthbase/cache';
import {
    IFilterableTakenTableConnectedQuery,
    IFilterableTakenTableConnectedQueryVariables,
    ITaakTabelFragment,
    refetchFilterableTakenTableConnectedAddQuery,
} from '@growthbase/graphql';
import {useCallback} from 'react';
import {determineTaakUpdateActionOnEntityChanges} from '../../TaakTabel';
import {FilterableTakenTableFilters} from '../FilterableTakenTableConnected';

export interface TaakTabelEntityChangesParams {
    searchQuery: string | null;
    result: QueryResult<IFilterableTakenTableConnectedQuery, IFilterableTakenTableConnectedQueryVariables>;
    filters: FilterableTakenTableFilters;
}

export const useTaakTabelEntityChanges = ({result, searchQuery, filters}: TaakTabelEntityChangesParams) => {
    const gebruikerId = result?.data?.ingelogdeGebruiker?.id;
    useUpdateQueryCacheFromExternalEntityChanges<ITaakTabelFragment>({
        result,
        determineAction: useCallback(
            (params) =>
                determineTaakUpdateActionOnEntityChanges(params, {
                    searchQuery,
                    filterVoltooid: filters.voltooid,
                    filterGearchiveerd: filters.gearchiveerd,
                    filterByGebruikerIds: filters.takenDieIkVolg && gebruikerId ? [gebruikerId] : null,
                    reorderForList: null,
                    filterByLijstIds: filters.takenDieIkVolg && gebruikerId ? [gebruikerId] : null,
                    filterByRelatieId: filters.relatie ? filters.relatie : undefined,
                }),
            [gebruikerId, filters, searchQuery]
        ),
        fetchEntity: useConvertFetchEntity(refetchFilterableTakenTableConnectedAddQuery),
    });
};
