import {useMemo} from 'react';
import {PlanningStoreState} from '@growthbase/planning';
import {useSelector} from 'react-redux';

export const useAgendaIsActive = (agendaId: string): boolean => {
    const {agendas: agendaGroups} = useSelector((state: PlanningStoreState) => state.planning);
    const agendas = agendaGroups.flatMap((group) => group.items);

    return useMemo(() => {
        const foundAgenda = agendas.find((agenda) => agenda.id === agendaId);
        return foundAgenda?.enabled ? foundAgenda.enabled : false;
    }, [agendas, agendaId]);
};
