import {FC, forwardRef} from 'react';
import {useTranslation} from 'react-i18next';
import {mapKeys, mapValues, upperFirst} from 'lodash';
import {Optional} from 'utility-types';
import {FormikContextType, useFormikContext} from 'formik';
import {IconButton, ButtonLink, ButtonLinkProps} from '../Buttons';
import {IconSize} from '../Icon';
import {Button, ButtonProps} from './Button';
import * as svgs from '../icons/svg';
import {IconComponents, Icons, SVGTypes} from '../icons';

export * from './Button';
export * from './Components';
export * from './Hook/useHandleClickState';

export type IconButtonComponents = {
    [TName in keyof SVGTypes as `${Capitalize<TName & string>}`]: FC<ButtonProps>;
};

export type IconButtonIconOnlyComponents = {
    [TName in keyof SVGTypes as `${Capitalize<TName & string>}IconOnly`]: FC<ButtonProps>;
};

export type IconButtonIconDottedComponents = {
    [TName in keyof SVGTypes as `${Capitalize<TName & string>}IconDotted`]: FC<ButtonProps>;
};

export type IconButtonIconDottedSmallComponents = {
    [TName in keyof SVGTypes as `${Capitalize<TName & string>}IconDottedSmall`]: FC<ButtonProps>;
};

export type IconButtonIconRightComponents = {
    [TName in keyof SVGTypes as `${Capitalize<TName & string>}IconRight`]: FC<ButtonProps>;
};

const createButton = (defaults: Optional<ButtonProps>, name: string) => {
    const Forwarded = forwardRef((props, ref) => <Button {...defaults} {...props} ref={ref} />);
    Forwarded.displayName = name;
    return Forwarded;
};

const createLink = (defaults: Optional<ButtonLinkProps>, name: string) => {
    const Forwarded = forwardRef((props, ref) => <ButtonLink {...defaults} {...props} ref={ref} />);
    Forwarded.displayName = name;
    return Forwarded;
};

function filterUndefined(obj: {[name: string]: unknown}): {[name: string]: unknown} {
    Object.keys(obj).forEach((key) => {
        if (obj[key] === undefined) {
            delete obj[key];
        }
    });
    return obj;
}

const createIconButton = (name: string, defaults: Optional<ButtonProps> = {}) => {
    const SVGIcon = Icons[name as keyof IconComponents];
    const filteredProps = filterUndefined(defaults);
    return createButton(
        {
            Icon: SVGIcon,
            ...filteredProps,
        },
        `${name}IconButton`
    );
};

export const LinkButton = createLink({}, 'LinkButton') as FC<ButtonLinkProps>;

const ButtonSmallNoBorder: FC<ButtonProps> = (props) => (
    <Button variant="default" size="small" disableBorder {...props} />
);
const ButtonPrimary: FC<ButtonProps> = (props) => <Button variant="primary" {...props} />;
const ButtonDanger: FC<ButtonProps> = (props) => <Button variant="danger" {...props} />;

/**
 * Formik forms can be nested, so we need to use useFormikContext to get the formik context and set a custom handler
 * for the submit button.
 *
 */
function SaveButton<T>(props: ButtonProps & {enabledWhenSubmitting?: boolean; formik?: FormikContextType<T>}) {
    const {enabledWhenSubmitting, formik: formikProps, ...rest} = props;
    const {disabled, onClick, loading} = props;
    const formikContext = useFormikContext();
    /**
     * Save button can be outside a formik context, so the user can pass a formik context as a prop.
     */
    const formik = formikProps ?? formikContext;
    const {t} = useTranslation('spa_form.nl');
    if (!formik) {
        return (
            <Button buttonType="submit" variant="primary" {...rest}>
                {t('save')}
            </Button>
        );
    }
    const {handleSubmit, isSubmitting, isValid, dirty} = formik;
    const dis =
        typeof disabled === 'boolean' ? disabled : (!enabledWhenSubmitting && isSubmitting) || (!isValid && !dirty);
    const click = typeof onClick === 'function' ? onClick : handleSubmit;
    const lod = typeof loading === 'boolean' ? loading : isSubmitting;
    return (
        <Button buttonType="submit" variant="primary" {...rest} disabled={dis} loading={lod} onClick={click}>
            {rest.children ?? t('save')}
        </Button>
    );
}

const DeleteButton: FC<ButtonLinkProps> = (props) => {
    const {t} = useTranslation('spa_form.nl');
    return <ButtonLink {...props}>{t('delete')}</ButtonLink>;
};

const AddButton: FC<ButtonProps> = (props) => <Button variant="primary" Icon={Icons.Plus} {...props} />;
const ButtonNoBorder: FC<ButtonProps> = (props) => <Button disableBorder {...props} />;
const OnlyIconButton: FC<ButtonProps> = ({children, ...props}) => <Button variant="iconOnly" {...props} />;
const OnlyIconPrimaryButton: FC<ButtonProps> = ({children, ...props}) => (
    <Button variant="iconOnlyPrimary" {...props} />
);
const IconRightButton: FC<ButtonProps> = (props) => <Button iconRight {...props} />;

const TabelAddButton: FC<ButtonProps> = forwardRef((props, ref) => (
    <IconButton Icon={Icons.PlusCircleOutline} iconSize={IconSize.SIZE_5} {...props} ref={ref} />
));
TabelAddButton.displayName = 'TabelAddButton';

const BaseButtons = {
    Link: LinkButton,
    Delete: DeleteButton,
    SmallNoBorder: ButtonSmallNoBorder,
    Primary: ButtonPrimary,
    Danger: ButtonDanger,
    Save: SaveButton,
    Add: AddButton,
    NoBorder: ButtonNoBorder,
    OnlyIcon: OnlyIconButton,
    OnlyIconPrimary: OnlyIconPrimaryButton,
    IconRight: IconRightButton,
    TabelAdd: TabelAddButton,
};

export const Buttons = Object.assign(
    BaseButtons,
    mapKeys(
        mapValues(svgs, (_, k) => createIconButton(k)),
        (v, k) => `${upperFirst(k)}`
    ),
    mapKeys(
        mapValues(svgs, (_, k) => createIconButton(k, {variant: 'iconOnly'})),
        (v, k) => `${upperFirst(k)}IconOnly`
    ),
    mapKeys(
        mapValues(svgs, (_, k) => createIconButton(k, {variant: 'iconOnlyPrimary'})),
        (v, k) => `${upperFirst(k)}IconOnlyPrimary`
    ),
    mapKeys(
        mapValues(svgs, (_, k) => createIconButton(k, {variant: 'iconOnlyDotted', size: 'medium'})),
        (v, k) => `${upperFirst(k)}IconDotted`
    ),
    mapKeys(
        mapValues(svgs, (_, k) => createIconButton(k, {variant: 'iconOnlyDotted', size: 'small'})),
        (v, k) => `${upperFirst(k)}IconDottedSmall`
    ),
    mapKeys(
        mapValues(svgs, (_, k) => createIconButton(k, {iconRight: true})),
        (v, k) => `${upperFirst(k)}IconRight`
    )
) as unknown as IconButtonComponents &
    IconButtonIconOnlyComponents &
    IconButtonIconDottedComponents &
    IconButtonIconDottedSmallComponents &
    typeof BaseButtons;
