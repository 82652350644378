import {useSelector} from 'react-redux';
import {PlanningStoreState, useCurrentView, ViewFormat, useOnChangeDate, AgendaModuleType} from '@growthbase/planning';
import {
    CalendarDateTitle,
    DateSelect,
    MemoryPopupProvider,
    PlanningButtons,
    Stack,
} from '@growthbase/design-components';
import {PlanningViewSwitch} from '../PlanningViewSwitch/PlanningViewSwitch';
import {PlanningViewTypeSwitch, PlanningGroupBySwitch, ViewSettingsPopup, ViewSettingsForm} from '../index';

export interface PlanningHeaderProps {
    availableFormats: ViewFormat[];
    viewFormats: ViewFormat[];
    module: AgendaModuleType;
}

export function PlanningHeader({viewFormats, availableFormats, module}: PlanningHeaderProps) {
    const {viewedDateRange: dateRange} = useSelector((state: PlanningStoreState) => state.planning);
    const currentViewFormat = useCurrentView(module);
    const onChangeDate = useOnChangeDate();
    const {groupBy} = currentViewFormat;

    return (
        <div className="planningHeader" style={{width: '100%'}}>
            <Stack gap="small" horizontal>
                <Stack.Item pushItems="after">
                    <PlanningViewSwitch views={availableFormats} />
                </Stack.Item>
                {currentViewFormat.settings && (
                    <Stack.Item pushItems="before">
                        <ViewSettingsPopup>
                            <ViewSettingsForm module={module} />
                        </ViewSettingsPopup>
                    </Stack.Item>
                )}
                {groupBy && (
                    <Stack.Item pushItems={currentViewFormat.view?.timeline ? 'before' : undefined}>
                        <PlanningGroupBySwitch />
                    </Stack.Item>
                )}
                <Stack.Item>
                    <PlanningViewTypeSwitch formats={viewFormats} module={module} />
                </Stack.Item>
                <Stack.Item>
                    <PlanningButtons.Today />
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal>
                        <PlanningButtons.Prev />
                        <MemoryPopupProvider>
                            <DateSelect
                                date={dateRange && dateRange.start}
                                onDateChange={onChangeDate}
                                renderButton={({onClick, reference}) => (
                                    <PlanningButtons.Date onClick={onClick} ref={reference}>
                                        {dateRange && <CalendarDateTitle from={dateRange.start} to={dateRange.end} />}
                                    </PlanningButtons.Date>
                                )}
                            />
                        </MemoryPopupProvider>
                        <PlanningButtons.Next />
                    </Stack>
                </Stack.Item>
            </Stack>
        </div>
    );
}
