import {DynamicContentServices, DynamicPosition} from '@growthbase/dynamiccontent';
import {TakenCounterSideNavBadgeConnected} from './TakenCounterSideNavBadge';
import {SnoozedBadgeCount} from './SnoozedBadges';

export const createTakenServices = ({configs = []}: DynamicContentServices): DynamicContentServices => ({
    configs: [
        ...configs,
        {
            Component: TakenCounterSideNavBadgeConnected,
            route: 'taken_mijn_taken',
            position: DynamicPosition.sidenavBadge,
        },
        {
            Component: () => <SnoozedBadgeCount />,
            route: 'taken_snooze',
            position: DynamicPosition.menuBadge,
        },
        {
            Component: () => <SnoozedBadgeCount />,
            route: 'taken_snooze',
            position: DynamicPosition.sidenavBadge,
        },
    ],
});
