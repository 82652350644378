import {Reducer} from 'react';
import {ReactiesTwigConnectorCommentsDto} from '../ReactiesTwigInputSchema';

export interface ReactiesState {
    count: number;
    needsUpdate: boolean;
    loading: boolean;
    showing: boolean;
    data: ReactiesTwigConnectorCommentsDto;
}

export const reactiesInitialState: ReactiesState = {
    count: 0,
    needsUpdate: false,
    loading: false,
    showing: false,
    data: {
        reactions: [],
    },
};

type SetCountAction = {
    type: 'setCount';
    count: number;
};

type ShowAction = {
    type: 'show';
};

type ToggleAction = {
    type: 'toggle';
};

type HideAction = {
    type: 'hide';
};

type SetLoadingAction = {
    type: 'loading';
};

type SetDataAction = {
    type: 'setData';
    data: ReactiesTwigConnectorCommentsDto;
};

export type ReactieAction = SetCountAction | ShowAction | HideAction | ToggleAction | SetDataAction | SetLoadingAction;

export type ReactiesRedcucerType = Reducer<ReactiesState, ReactieAction>;

export const reactiesReducer: ReactiesRedcucerType = (state, action) => {
    switch (action.type) {
        case 'setData':
            return {
                ...state,
                data: action.data,
                loading: false,
                needsUpdate: false,
                loadedCount: action.data.reactions?.length || 0,
            };
        case 'setCount':
            return {
                ...state,
                needsUpdate: true,
                count: action.count,
            };
        case 'show':
            return {
                ...state,
                showing: true,
            };
        case 'toggle':
            return {
                ...state,
                showing: !state.showing,
            };
        case 'hide':
            return {
                ...state,
                showing: false,
            };
        case 'loading':
            return {
                ...state,
                loading: true,
            };
        default:
            throw new Error();
    }
};
