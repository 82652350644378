import React, {memo, ReactNode} from 'react';
import {AnchorAttributes, useSortableItemProps} from '../Hook';
import {DNDItemData, DNDItemUserData, DNDUserDataType} from '../Value';

export interface RenderDraggableItemProps<
    TUserDataType extends DNDUserDataType,
    TItemUserData extends DNDItemUserData
> {
    item: DNDItemData<TItemUserData, TUserDataType>;

    dragging?: boolean;

    anchor?: AnchorAttributes;

    element: React.HTMLAttributes<HTMLElement> & {ref: ((node: HTMLElement | null) => void) | undefined};
}

export type RenderDraggableItem<TUserDataType extends DNDUserDataType, TItemUserData extends DNDItemUserData> = (
    props: RenderDraggableItemProps<TUserDataType, TItemUserData>
) => ReactNode;

export interface DNDSortableItemProps<TUserDataType extends DNDUserDataType, TItemUserData extends DNDItemUserData> {
    item: DNDItemData<TItemUserData, TUserDataType>;
    children: RenderDraggableItem<TUserDataType, TItemUserData>;
    disabled?: boolean;
}

function PlainDNDSortableItem<TUserDataType extends DNDUserDataType, TItemUserData extends DNDItemUserData>({
    children,
    item,
    disabled,
}: DNDSortableItemProps<TUserDataType, TItemUserData>) {
    const {attributes, anchorAttributes, isDragging} = useSortableItemProps({item, disabled});
    return <>{children({element: attributes, anchor: anchorAttributes, dragging: isDragging, item})}</>;
}

const MemoDNDSortableItem = memo(PlainDNDSortableItem);
MemoDNDSortableItem.displayName = 'DNDSortableItem';

export const DNDSortableItem = MemoDNDSortableItem as unknown as typeof PlainDNDSortableItem;
