import {ButtonGroup, TableHeader, TableHeaderSection} from '@growthbase/design-components';
import {FC, ReactNode} from 'react';
import {RenderTabelType} from '@growthbase/takentemplates-taaktemplate';

export interface TabelProps {
    naam: string;
    render: RenderTabelType;
    menu: ReactNode;
    header?: React.ReactNode;
}

export const Tabel: FC<TabelProps> = ({render, header, naam, menu}) => (
    <>
        {render({
            header: header ?? (
                <TableHeader>
                    <TableHeaderSection testId={naam}>{naam}</TableHeaderSection>
                    <TableHeaderSection>
                        <ButtonGroup disableRightMargin>{menu}</ButtonGroup>
                    </TableHeaderSection>
                </TableHeader>
            ),
            filters: false,
        })}
    </>
);
