import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {DateOptions, ISOStringDate} from './useDateSchema';

export const useDefaultLabels = (
    {dateLabel}: Partial<DateOptions>,
    previous: ISOStringDate
): Required<Omit<DateOptions, 'minimumDate'>> => {
    const {t} = useTranslation('DesignComponents.nl');
    return useMemo(
        () => ({
            dateLabel: dateLabel ?? t('DatePicker.date') ?? '',
            previous,
        }),
        [t, dateLabel, previous]
    );
};
