import {ReactNode} from 'react';
import classNames from 'classnames/bind';
import styles from './SidenavCounterLink.module.scss';
import {BaseButtonProps} from '../../Button';

const cx = classNames.bind(styles);

export interface SidenavCounterLinkProps extends BaseButtonProps {
    children?: ReactNode;
    count: string | number;
    active?: boolean;
}

export const SidenavCounterLink = ({children, count, onClick, active}: SidenavCounterLinkProps) => {
    const classes = cx('sidenavCounterLink', {
        'sidenavCounterLink--active': active,
    });

    return (
        <button type="button" onClick={onClick} className={classes}>
            <div className={cx('sidenavCounterLink__text')}>{children}</div>
            <div className={cx('sidenavCounterLink__count')}>{count}</div>
        </button>
    );
};
