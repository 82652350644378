import produce from 'immer';

export interface NavigateOptions<TState = Record<string, string>> {
    withBackground?: boolean;
    replace?: boolean;
    state?: TState;
}

export function mergeNavigateOptions<TState>(
    options: Array<NavigateOptions<TState>>,
    prev?: NavigateOptions<TState>
): NavigateOptions<TState> {
    const previous = prev ?? ({state: {}} as NavigateOptions<TState>);
    return produce<NavigateOptions<TState>>(previous, (draft) => {
        options.forEach(({state, ...rest}) => {
            Object.assign(draft, rest);
            // @ts-expect-error just ignore.
            Object.assign(draft.state, state);
        });
    });
}
