import {Button, BlockMessage} from '@growthbase/design-components';
import {useTakenlijstenConnectedMaakAanMutation} from '@growthbase/graphql';
import {BordtemplateRoutes} from '@growthbase/takentemplates-bordtemplate';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {v4} from 'uuid';

export interface GeenItemsAanwezigProps {
    abonnementId: string;
    bordtemplateId?: string;
}

export const GeenItemsAanwezig: FC<GeenItemsAanwezigProps> = ({abonnementId, bordtemplateId}) => {
    const [mutation] = useTakenlijstenConnectedMaakAanMutation();

    const {t: tLegacy} = useTranslation('AbonnementTakentemplates.nl');
    const addTemplate = BordtemplateRoutes.lijsttoevoegen.useNavigateFactory();

    return (
        <BlockMessage title={tLegacy('abonnementen.takentemplates.Takenlijsten.useTemplates')}>
            <p>{tLegacy('abonnementen.takentemplates.Takenlijsten.text')}</p>
            <Button
                onClick={() => {
                    const id = v4();
                    return mutation({
                        variables: {
                            input: {
                                abonnementId,
                                id,
                                naam: tLegacy('abonnementen.takentemplates.Takenlijsten.newBoardName'),
                            },
                        },
                    }).then(() =>
                        addTemplate({
                            bordtemplateId: id,
                        })
                    );
                }}
            >
                {tLegacy('abonnementen.takentemplates.Takenlijsten.useTemplates')}
            </Button>
        </BlockMessage>
    );
};
