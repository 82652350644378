import {Filters, SortableTabelConnected} from '@growthbase/takentemplates-taaktemplate';
import React, {FC, useMemo} from 'react';
import {useLijstemplateViewQuery} from '@growthbase/graphql';
import {
    AddBreadcrumb,
    CardHeaderTitle,
    Loader,
    SearchForm,
    TableFiltersHeader,
    TableHeader,
    useGebruikerHeeftToegang,
    useSearchField,
} from '@growthbase/design-components';
import {idFromIri} from '@growthbase/routing';
import {ActionsBarConnected} from './Components/ActionsBar';

export interface ViewConnectedProps {
    lijsttemplateId: string;
}

export const ViewConnected: FC<ViewConnectedProps> = ({lijsttemplateId}) => {
    const {data, loading} = useLijstemplateViewQuery({
        variables: {
            id: idFromIri(lijsttemplateId),
        },
    });

    const toegang = useGebruikerHeeftToegang({
        enabled: !loading,
        toegang: !!data,
    });

    const [searchQuery, setSearchQuery] = useSearchField('taak');

    const filters = useMemo(
        (): Filters => ({
            searchQuery: searchQuery.query,
        }),
        [searchQuery.query]
    );

    if (!toegang) {
        return null;
    }

    const fragment = data?.lijsttemplate;

    if (!fragment) {
        return <Loader center />;
    }

    return (
        <>
            <AddBreadcrumb>{fragment.naam}</AddBreadcrumb>
            <TableFiltersHeader
                leftActions={[
                    <SearchForm
                        key="search"
                        disableAutofocus
                        loading={loading}
                        onChange={setSearchQuery}
                        value={searchQuery}
                    />,
                ]}
                rightActions={[<ActionsBarConnected key="actions" fragment={fragment} />]}
            />
            <SortableTabelConnected
                header={
                    <TableHeader>
                        <CardHeaderTitle>{fragment.naam}</CardHeaderTitle>
                    </TableHeader>
                }
                filters={filters}
                fragment={fragment}
            />
        </>
    );
};
