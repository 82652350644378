import {FC} from 'react';
import {Buttons} from '../../Button';
import styles from './FooterActions.module.scss';

interface FooterActionsProps {
    disabled: boolean;
    submit: () => void;
    close: () => void;
}

export const FooterActions: FC<FooterActionsProps> = ({disabled, submit, close}) => (
    <div className={styles.footerActions}>
        <div className={styles.absoluteContainer}>
            <div className={styles.footerActions__item}>
                <Buttons.Save disabled={disabled} onClick={submit} />
            </div>
            <div className={styles.footerActions__item}>
                <Buttons.Cross variant="iconOnly" onClick={close} />
            </div>
        </div>
    </div>
);
