import {PaginationHelpers} from '@growthbase/spa';
import React, {ReactNode} from 'react';
import {Table, TableHeader, TableRowInterface} from '@growthbase/design-components';
import {Column} from 'react-table';
import {useTranslation} from 'react-i18next';

export interface CardData extends TableRowInterface {
    id: string;
    naam: ReactNode;
    locatie: ReactNode;
    actions: ReactNode;
    deelnemers: ReactNode;
}

export interface TabelProps {
    pagination: PaginationHelpers<CardData>;
}

const columns: ReadonlyArray<Column<CardData>> = [
    {
        Header: () => {
            const {t} = useTranslation('TakenlijstBord.nl');
            return <>{t('Tabel.Naam')}</>;
        },
        accessor: 'naam',
    },
    {
        Header: () => {
            const {t} = useTranslation('TakenlijstBord.nl');
            return <>{t('Tabel.Locatie')}</>;
        },
        accessor: 'locatie',
    },
    {
        Header: () => {
            const {t} = useTranslation('TakenlijstBord.nl');
            return <>{t('Tabel.Deelnemers')}</>;
        },
        accessor: 'deelnemers',
    },
    {
        Header: <div />,
        accessor: 'actions',
        id: 'actions',
        width: 'auto',
        minWidth: 90,
    },
];

export function Tabel({pagination}: TabelProps) {
    const {t} = useTranslation('TakenlijstBord.nl');
    return (
        <Table<CardData>
            header={
                <TableHeader>
                    {t('headerWithCount', {
                        count: pagination.totalCount,
                    })}
                </TableHeader>
            }
            columns={columns}
            pagination={pagination}
        />
    );
}
