import {AgendaViewData, PlanningStoreState, usePlanningViewConnectedLazyQuery} from '@growthbase/planning';
import {useCallback, useState} from 'react';
import moment from 'moment';
import {resolveConnections} from '@growthbase/graphql';
import {useSelector} from 'react-redux';
import {useNormalizeEvents} from './normalizer/useNormalizeEvents';
import {useNormalizeTeams} from './normalizer/useNormalizeTeams';
import {useNormalizeSchedules} from './normalizer/useNormalizeSchedules';
import {useGetResourceIdByEmployeeId} from './useGetResourceIdByEmployeeId';
import {useNormalizeEvent} from './normalizer/useNormalizeEvent';
import {useNormalizeMedewerkerAgenda} from './normalizer/useNormalizeMedewerkerAgenda';
import {useNormalizeSchedule} from './normalizer/useNormalizeSchedule';
import {useNormalizeUrenRegistraties} from './normalizer/useNormalizeUrenRegistraties';

export const usePlanningData = (teamsEnabled?: boolean) => {
    const eventNormalizer = useNormalizeEvent();
    const agendaNormalizer = useNormalizeMedewerkerAgenda();
    const scheduleNormalizer = useNormalizeSchedule();

    const normalizeUrenRegistraties = useNormalizeUrenRegistraties();
    const normalizeEvents = useNormalizeEvents(eventNormalizer);
    const normalizeTeams = useNormalizeTeams(agendaNormalizer, teamsEnabled);
    const normalizeSchedule = useNormalizeSchedules(scheduleNormalizer);

    const [normalizedData, setNormalizedData] = useState<AgendaViewData>({
        events: [],
        resources: [],
    });

    const [request, {loading}] = usePlanningViewConnectedLazyQuery({
        nextFetchPolicy: 'network-only',
    });

    const urenRegistratiesEnabled = useSelector((state: PlanningStoreState) => state.planning.urenRegistratiesEnabled);
    const activeAgendaIds = useSelector((state: PlanningStoreState) => state.planning.activeAgendaIds);
    const activeEmployeeIds = useSelector((state: PlanningStoreState) => state.planning.activeEmployeeIds);
    const getAgendaIdByEmployee = useGetResourceIdByEmployeeId();

    // uwc-debug
    const onRequestData = useCallback(
        (event: {startDate: Date; endDate: Date}): void => {
            request({
                onCompleted: (fetchedData) => {
                    const planningen = resolveConnections(fetchedData.Planningen);

                    const data = {
                        events: normalizeEvents(fetchedData.medewerkers),
                        resources: normalizeTeams(fetchedData.medewerkers),
                        backgroundEvents: normalizeSchedule(fetchedData.medewerkers) ?? [],
                    };

                    if (fetchedData.urenRegistratiesVanIngelogdeGebruiker) {
                        const urenRegistratieEvents = normalizeUrenRegistraties(
                            fetchedData.urenRegistratiesVanIngelogdeGebruiker
                        );
                        data.events = [...data.events, ...urenRegistratieEvents];
                    }

                    planningen.forEach((planning) =>
                        planning.tijdblokken?.forEach((tijdblok) => {
                            const agendaId = getAgendaIdByEmployee(fetchedData.medewerkers, planning.id);
                            data.backgroundEvents.push({
                                resource: agendaId,
                                start: new Date(tijdblok?.van.iso8601 ?? ''),
                                end: new Date(tijdblok?.tot.iso8601 ?? ''),
                                background: 'var(--calendarBreakColor)',
                            });
                        })
                    );

                    setNormalizedData(data);
                },
                variables: {
                    startDate: {
                        iso8601: moment(event.startDate).format(),
                        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    },
                    endDate: {
                        iso8601: moment(event.endDate).format(),
                        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    },
                    agendaIds: activeAgendaIds,
                    medewerkerIds: activeEmployeeIds,
                    urenRegistratiesEnabled,
                },
            });
        },
        [
            activeAgendaIds,
            activeEmployeeIds,
            getAgendaIdByEmployee,
            normalizeEvents,
            normalizeSchedule,
            normalizeTeams,
            normalizeUrenRegistraties,
            request,
            urenRegistratiesEnabled,
        ]
    );

    return {
        loading,
        onRequestData,
        normalizedData,
    };
};
