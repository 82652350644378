import {
    ButtonGroup,
    IconButton,
    Icons,
    IconSize,
    TableHeaderDraggable,
    TableHeaderSection,
} from '@growthbase/design-components';
import {DND, DNDItemData, useOnDragMoveButtonHandlers} from '@growthbase/keys';
import {RenderTaakTabelType} from '@growthbase/taken';
import {ReactNode} from 'react';
import {LijstDragType, ContainerData, ItemData} from '../../../../types';

export interface SortableTakenTabelLijstProps<T extends ContainerData> {
    item: DNDItemData<T, LijstDragType>;
    renderTabel: RenderTaakTabelType;
    menu: ReactNode;
}

export function SortableTakenTabelLijst<T extends ContainerData>({
    renderTabel,
    item,
    menu,
}: SortableTakenTabelLijstProps<T>) {
    const {onUp, onDown} = useOnDragMoveButtonHandlers<ItemData>({
        itemId: item.userData.id,
        type: item.userDataType,
    });
    return (
        <DND.Item disabled={!(onUp || onDown)} item={item}>
            {({element, anchor, dragging}) => (
                <div {...element} style={{opacity: dragging ? 0.5 : 1}}>
                    {renderTabel({
                        header: (
                            <TableHeaderDraggable title={item.userData.titel} anchor={anchor}>
                                <TableHeaderSection>
                                    <ButtonGroup disableRightMargin>
                                        {onUp && (
                                            <IconButton
                                                Icon={Icons.ArrowUp}
                                                iconSize={IconSize.SIZE_3}
                                                color="gray-light"
                                                onClick={onUp}
                                            />
                                        )}
                                        {onDown && (
                                            <IconButton
                                                Icon={Icons.ArrowDown}
                                                iconSize={IconSize.SIZE_3}
                                                color="gray-light"
                                                onClick={onDown}
                                            />
                                        )}
                                        {menu}
                                    </ButtonGroup>
                                </TableHeaderSection>
                            </TableHeaderDraggable>
                        ),
                        filters: false,
                    })}
                </div>
            )}
        </DND.Item>
    );
}
