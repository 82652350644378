import React, {FC} from 'react';
import {ActionsMenuButton, ConfirmModalButton, ModalContent, PopupHeader} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';
import {useVerwijderLegeBordenMutation} from '@growthbase/graphql';

export const VerwijderLegeBorden: FC = () => {
    const [executeMutation, {loading}] = useVerwijderLegeBordenMutation({});
    const {t} = useTranslation('TakenlijstBord.nl');
    return (
        <ConfirmModalButton
            header={<PopupHeader title={t('confirmDeleteEmpty.Titel')} />}
            onSubmit={() => executeMutation()}
            popupButtonContent={t('confirmDeleteEmpty.Bevestigknop')}
            renderOpenButton={(props) => (
                <ActionsMenuButton {...props} disabled={loading}>
                    {t('confirmDeleteEmpty.Dropdownknop')}
                </ActionsMenuButton>
            )}
        >
            <ModalContent>
                <div>
                    <p>{t('confirmDeleteEmpty.Tekst')}</p>
                </div>
            </ModalContent>
        </ConfirmModalButton>
    );
};
