import {useAddReferenceLinkResolver} from '@growthbase/design-components';
import {CreateRouteFunction, createRoutes, idFromIri, PageFromMenu, RouteElementProps} from '@growthbase/routing';
import React, {useCallback} from 'react';
import {generatePath, Link} from 'react-router-dom';
import {LogbookConnected} from '@growthbase/activiteiten';
import {CreateFormConnected} from '../CreateForm';
import {EditFormConnected} from '../EditForm';

const generateTaaktemplateRoute: CreateRouteFunction<{
    taaktemplateId: string;
}> = (route: string, params) =>
    generatePath(route, {
        taaktemplateId: idFromIri(params?.taaktemplateId ?? ''),
    });

export const TaaktemplateRoutes = createRoutes('taaktemplate', {
    aanmaken: {
        defaultBackgroundRoute: 'overview',
        createRoute: generateTaaktemplateRoute,
        Element: () => <CreateFormConnected />,
    },
    overview: {
        defaultBackgroundRoute: 'overview',
        Element: () => <PageFromMenu sidebar={<LogbookConnected />}>TODO:</PageFromMenu>,
    },
    bewerken: {
        defaultBackgroundRoute: 'overview',
        createRoute: generateTaaktemplateRoute,
        Element: ({params}: RouteElementProps<{taaktemplateId: string}>) => (
            <EditFormConnected taaktemplateId={params?.taaktemplateId ?? ''} />
        ),
    },
});

TaaktemplateRoutes.decorate((Decorated) => {
    const editLink = TaaktemplateRoutes.bewerken.useUrlFactory();
    useAddReferenceLinkResolver(
        useCallback(
            (data) => {
                if (data.type === 'Taaktemplate') {
                    return (
                        <Link
                            to={editLink({
                                taaktemplateId: data.id,
                            })}
                        >
                            {data.text}
                        </Link>
                    );
                }
                return null;
            },
            [editLink]
        )
    );
    return <Decorated />;
}, 'TaakTemplateRoutesLinkResolver');
