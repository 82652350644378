import {QueryResult} from '@apollo/client/react/types/types';
import {
    determineActionFilterForTypes,
    determineActionForList,
    determineActionTypeWhenSearching,
    useConvertFetchEntity,
    useUpdateQueryCacheFromExternalEntityChanges,
} from '@growthbase/cache';
import {
    ITakentemplateVanLijstSwimlaneQuery,
    ITakentemplateVanLijstSwimlaneQueryVariables,
    ITakentemplateVanLijsttemplatwSwimlaneKaartFragment,
    refetchTakentemplateVanLijsttemplateSwimlaneSingleQuery,
} from '@growthbase/graphql';
import {idFromIri} from '@growthbase/routing';
import {Filters} from '../../../../../types';

export interface EntityChangesParams {
    filters: Filters;
    lijstId: string;
    result: QueryResult<ITakentemplateVanLijstSwimlaneQuery, ITakentemplateVanLijstSwimlaneQueryVariables>;
}

export const useEntityChanges = ({result, filters, lijstId}: EntityChangesParams) => {
    const id = idFromIri(lijstId);
    useUpdateQueryCacheFromExternalEntityChanges<ITakentemplateVanLijsttemplatwSwimlaneKaartFragment>({
        // @ts-expect-error TODO: fix variables, we don't need to match that.
        result,
        determineAction: (params, defaultAction) => {
            let action = determineActionFilterForTypes(defaultAction, params, 'Taaktemplate');
            action = determineActionForList(action, params, id);
            return determineActionTypeWhenSearching(action, params, !!filters.searchQuery);
        },
        fetchEntity: useConvertFetchEntity(refetchTakentemplateVanLijsttemplateSwimlaneSingleQuery),
    });
};
