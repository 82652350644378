import {useEffect} from 'react';
import {useCallbackRef, useLegacySocket} from '@growthbase/spa';
import {
    usePlanningRefresh,
    usePlanningSetActiveAgendas,
    usePlanningSetSocketId,
    usePlanningSetTimePreference,
    useSelectedAgenda,
} from '@growthbase/planning';
import {SettingsUpdatedEventDataSchema} from './SettingsUpdatedEventSchema';
import {ActiveAgendasUpdatedSchema} from './ActiveAgendasUpdatedEventSchema';
import {useAgendaIsActive} from '../_hooks/useAgendaIsActive';

export const useSyncedPlanningState = () => {
    const setSocketId = usePlanningSetSocketId();

    const onConnectedCallback = useCallbackRef((socketId: string) => {
        setSocketId({
            socketId,
        });
    });

    const {subscribeUserEvent} = useLegacySocket('planning', onConnectedCallback);
    const setTimePreference = usePlanningSetTimePreference();
    const setActiveAgendas = usePlanningSetActiveAgendas();
    const refresh = usePlanningRefresh();
    const selectedAgenda = useSelectedAgenda();
    const urenRegistratieAgendaActive = useAgendaIsActive('time-registrations');

    useEffect(() => {
        const listener = () => {
            refresh();
        };

        document.addEventListener('refetchPlanning', listener);
        if (urenRegistratieAgendaActive) {
            document.addEventListener('urenRegistratiesBijgewerkt', listener);
        }

        return () => {
            document.removeEventListener('refetchPlanning', listener);
            if (urenRegistratieAgendaActive) {
                document.removeEventListener('urenRegistratiesBijgewerkt', listener);
            }
        };
    }, [refresh, urenRegistratieAgendaActive]);

    useEffect(() => {
        const subscriptionForTime = subscribeUserEvent('settings-updated', (data: unknown) => {
            const validatedData = SettingsUpdatedEventDataSchema.validateSync(data);
            setTimePreference({
                timeStart: validatedData.dagBeginTijd || '0:00',
                timeEnd: validatedData.dagEindTijd || '0:00',
                dayStart: validatedData.eersteDag || '1',
                dayEnd: validatedData.laatsteDag || '5',
                localOnly: true,
            });
        });

        const subscriptionForAgendas = subscribeUserEvent('active-agendas-updated', (data: unknown) => {
            const validatedData = ActiveAgendasUpdatedSchema.validateSync(data);

            if (selectedAgenda && !validatedData.agendaIds.includes(selectedAgenda.id)) {
                validatedData.agendaIds.push(selectedAgenda.id);
            }

            setActiveAgendas({
                activeAgendas: validatedData.agendaIds,
                localOnly: true,
            });
        });

        return () => {
            subscriptionForTime.unsubscribe();
            subscriptionForAgendas.unsubscribe();
        };
    }, [selectedAgenda, setActiveAgendas, setTimePreference, subscribeUserEvent]);
};
