import React, {useCallback} from 'react';
import {VerticalStack} from '@growthbase/design-components';
import {DND, onDropItemHandler, onRemoveItemHandler} from '@growthbase/keys';
import {ItemData, ContainerData, LijstDragType, LijstDragTypeValue} from '../../../../types';
import {RenderSortableTakenTabelLijstType} from '../SortableTakenTabelLijst';

export interface SortableTakenTabelLijstenProps<
    TLijst extends ItemData = ItemData,
    TContainerData extends ContainerData = ContainerData
> {
    lijsten: TLijst[];
    userData: TContainerData;
    renderLijst: RenderSortableTakenTabelLijstType<TLijst>;
    onDrop: onDropItemHandler<TLijst, TContainerData>;
    onRemove?: onRemoveItemHandler<TLijst>;
}

export function SortableTakenTabelLijsten<
    T extends ItemData = ItemData,
    TContainerData extends ContainerData = ContainerData
>({lijsten, renderLijst, ...props}: SortableTakenTabelLijstenProps<T, TContainerData>) {
    return (
        <>
            <DND.Container<LijstDragType, T, TContainerData>
                {...props}
                userDataType={LijstDragTypeValue}
                items={lijsten}
                renderDraggedItem={useCallback(
                    ({item, element}) => (
                        <div ref={element.ref}>{renderLijst({item})}</div>
                    ),
                    [renderLijst]
                )}
            >
                {({items}) => (
                    <VerticalStack>
                        {items.map((list) => (
                            <div key={list.id}>{renderLijst({item: list})}</div>
                        ))}
                    </VerticalStack>
                )}
            </DND.Container>
        </>
    );
}
