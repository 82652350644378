import {
    CSSVariables,
    SidenavSection,
    SidenavFooter,
    SidenavLogo,
    SidenavSeperator,
    Sidenav,
    SidenavContextProvider,
    LinkButton,
    SidenavSubLinkProps,
} from '@growthbase/design-components';
import React, {FC} from 'react';

import {NavigationData} from '../Schema/NavigationData';
import {PositionGlobalHTMLContent} from '../Component/PositionGlobalHTMLContent';
import {SidenavLinkConnected} from './SidenavLinkConnected';
import {SidenavSubLinkConnected} from './SidenavSubLinkConnected';
import {ProfielMenuConnected} from './ProfielMenu/ProfielMenuConnected';

export interface SidenavConnectedProps {
    navigationData: NavigationData;
    isExpandedOverride?: boolean;
}

const ProfielSubLink = (props: SidenavSubLinkProps) => (
    <LinkButton block {...props} color="primary" padding="vertical" />
);

export const SidenavConnected: FC<SidenavConnectedProps> = ({navigationData, isExpandedOverride}) => (
    <CSSVariables variables={navigationData.theme.variables}>
        <SidenavContextProvider isExpandedOverride={isExpandedOverride}>
            <PositionGlobalHTMLContent />
            <Sidenav>
                <SidenavLogo src={navigationData.theme.logoUrl} />

                {!!navigationData.top.length && (
                    <SidenavSection>
                        {navigationData.top.map((link, index) => (
                            <SidenavLinkConnected key={index} item={link} />
                        ))}
                    </SidenavSection>
                )}

                {!!(navigationData.center.length && navigationData.top.length) && <SidenavSeperator />}

                {!!navigationData.center.length && (
                    <SidenavSection scrollable scrollableTheme={navigationData.theme.scrollableTheme}>
                        {navigationData.center.map((link, index) => (
                            <SidenavLinkConnected key={index} item={link} />
                        ))}
                    </SidenavSection>
                )}

                <SidenavFooter
                    profileName={navigationData.profile.name}
                    userName={navigationData.user.name}
                    avatarUrl={navigationData.user.avatarUrl}
                >
                    <ProfielMenuConnected>
                        {navigationData.bottom.map((item, index) => (
                            <SidenavSubLinkConnected key={index} item={item} Element={ProfielSubLink} />
                        ))}
                    </ProfielMenuConnected>
                </SidenavFooter>
            </Sidenav>
        </SidenavContextProvider>
    </CSSVariables>
);
