import {FC, useEffect, useRef} from 'react';
import {useField} from 'formik';
import {FormikFieldProps} from '../FieldWrapper';
import {TextareaField} from './TextareaField';

export type TextareaFieldComponentProps = FormikFieldProps<string> & {
    autoFocus?: boolean;
    borderVisible?: boolean;
    variant?: 'default' | 'big';
};

export type TextareaFieldComponentNullableProps = FormikFieldProps<string | null>;

export const TextareaFieldComponent: FC<TextareaFieldComponentProps> = ({
    meta,
    field: {onChange, ...field},
    form,
    ...props
}) => {
    const [{value}, , {setValue}] = useField(field.name);
    const ref = useRef<HTMLTextAreaElement | null>(null);
    const {isSubmitting} = form;
    const {autoFocus} = props;

    useEffect(() => {
        if (ref.current && autoFocus) {
            setTimeout(() => {
                ref.current?.focus();
            }, 1);
        }
    }, [isSubmitting, autoFocus]);

    return (
        <TextareaField
            {...field}
            value={value ?? ''}
            {...props}
            onBlur={props.onBlur}
            onChange={setValue}
            innerRef={ref}
        />
    );
};

export const TextareaFieldComponentNullable: FC<TextareaFieldComponentNullableProps> = ({
    meta,
    field: {onChange, ...field},
    form,
    ...props
}) => {
    const [{value}, , {setValue}] = useField(field.name);
    return <TextareaField {...field} value={value ?? ''} {...props} onBlur={props.onBlur} onChange={setValue} />;
};
