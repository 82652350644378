import {useEffect} from 'react';
import {noop} from 'rxjs';

declare global {
    interface Window {
        setTestValue(input: HTMLInputElement, value: string): boolean;
    }
}

const handlersMap: Record<string, (value: string) => void> = {};

window.setTestValue = (input, value: string) => {
    if (!input.id) {
        return false;
    }
    const handler = handlersMap[input.id];
    if (!handler) {
        return false;
    }
    handler(value);
    return true;
};

/**
 * Hook to set the value of an input element without using normal html events.
 */
export const useTestChangeValue = (id: string | undefined, onChange: (value: string) => void) => {
    useEffect(() => {
        if (!id) {
            return noop;
        }
        handlersMap[id] = onChange;
        return () => {
            delete handlersMap[id];
        };
    }, [id, onChange]);
    return null;
};
