import {ActiveItemSource, ActiveItemType, useOnMouseHoverWithRef} from '@growthbase/keys';
import classNames from 'classnames/bind';
import React, {memo, FC} from 'react';
import {Row} from 'react-table';
import styles from '../Table.module.scss';
import {TableRowInterface} from '../TableRowInterface';

const cx = classNames.bind(styles);

export interface TableRowProps<TRow extends TableRowInterface = TableRowInterface> {
    row: Row<TRow>;
    draggedRow?: boolean;
}

/* eslint-disable react/jsx-key */
export const TableRow: FC<TableRowProps> = memo(({row, draggedRow}) => {
    const {ref} = useOnMouseHoverWithRef<typeof row.original, HTMLDivElement>({
        data: row.original,
        source: ActiveItemSource.TableRow,
        type: ActiveItemType.Unknown,
    });

    return (
        <div
            ref={ref}
            {...row.getRowProps()}
            data-test="row"
            data-testid={row.id}
            className={cx('table__tr', {
                'table__tr--draggedRow': draggedRow,
                'table__tr--locked': row.original.isLocked,
            })}
        >
            {row.cells.map((cell) => (
                <div {...cell.getCellProps()} data-test="cell" className={cx('table__td')}>
                    {cell.render('Cell')}
                </div>
            ))}
        </div>
    );
});
TableRow.displayName = 'TableRow';
