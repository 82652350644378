import React, {ReactNode, FC} from 'react';
import {PopupControls, PopupControlsContext, useMemoryPopupControls} from '../../../Popups';
import {Button, ButtonHandler} from '../../../Button';
import {Modal, ModalFooter} from '../../Modal';

export interface ConfirmModalProps {
    header: ReactNode;
    children: ReactNode;
    buttonContent: ReactNode;
    helpers?: PopupControls;
    onSubmit: ButtonHandler;
    disabled?: boolean;
    loading?: boolean;
}

export const ConfirmModal: FC<ConfirmModalProps> = ({
    children,
    header,
    buttonContent,
    helpers: helpersGiven,
    onSubmit,
    disabled,
    loading,
}) => {
    const helpers = useMemoryPopupControls(false);
    return (
        <PopupControlsContext.Provider value={helpersGiven || helpers}>
            <Modal testId="ConfirmModal">
                {header}
                {children}
                <ModalFooter
                    alignRight
                    buttons={
                        <Button
                            loading={loading}
                            buttonType="submit"
                            variant="danger"
                            onClick={onSubmit}
                            disabled={disabled}
                        >
                            {buttonContent}
                        </Button>
                    }
                />
            </Modal>
        </PopupControlsContext.Provider>
    );
};
