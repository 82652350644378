import {useCallback, useEffect} from 'react';

import {usePlanningSetEventContext, usePlanningSetDienst} from '@growthbase/planning';
import {Opdracht} from '@growthbase/opdrachten-planning';

export const useOpdrachtSidebarState = (opdracht: Opdracht) => {
    const setEventContext = usePlanningSetEventContext();
    const setDienst = usePlanningSetDienst();

    // Set initial state
    useEffect(() => {
        setEventContext({
            context: {
                opdracht,
            },
        });
    }, [opdracht, setEventContext]);

    const handleKeuzeChange = useCallback(
        (dienstId?: string) => {
            setDienst({
                dienst: dienstId ? {id: dienstId} : undefined,
            });
        },
        [setDienst]
    );

    return {
        handleKeuzeChange,
    };
};
