import {BaseButtonProps, Buttons} from '@growthbase/design-components';
import {IBordKopieerButtonFragment, useBordtemplateKopieerButtonConnectedMutation} from '@growthbase/graphql';
import {FC, useCallback} from 'react';
import {v4} from 'uuid';
import {BordtemplateRoutes} from '@growthbase/takentemplates-bordtemplate';
import {KopieerButton} from './KopieerButton';
import {BordtemplateKopierenFormValues, KopieerForm} from '../KopieerForm/KopieerForm';

export interface KopieerButtonConnectedProps {
    fragment: IBordKopieerButtonFragment;
    onCreated?: (id: string) => void;
    Button: FC<BaseButtonProps>;
}

export const KopieerButtonConnected: FC<KopieerButtonConnectedProps> = ({
    Button = Buttons.Copy,
    fragment,
    onCreated,
}) => {
    const [mutation] = useBordtemplateKopieerButtonConnectedMutation();
    const factory = BordtemplateRoutes.bekijken.useNavigateFactory();
    return (
        <KopieerButton<BordtemplateKopierenFormValues>
            Button={Button}
            renderForm={({onSubmit}) => <KopieerForm onSubmit={onSubmit} titel={fragment.naam} />}
            onSubmit={useCallback(
                async (values): Promise<void> => {
                    if (!fragment) {
                        return;
                    }
                    const id = v4();

                    await mutation({
                        variables: {
                            input: {
                                id,
                                naam: values.naam,
                                opties: {
                                    copyId: id,
                                    originalId: fragment.id,
                                    metActiviteiten: values.activiteiten,
                                },
                                bordOpties: values.taken
                                    ? {
                                          taakOpties: {
                                              omschrijving: true,
                                          },
                                      }
                                    : {},
                            },
                        },
                    });
                    if (onCreated) {
                        onCreated(id);
                    } else {
                        factory({
                            bordtemplateId: id,
                        });
                    }
                },
                [fragment, mutation, onCreated, factory]
            )}
        />
    );
};
