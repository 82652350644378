import React, {FC} from 'react';
import {ITaakCreateFormMutation} from '@growthbase/graphql';
import {
    Modal,
    ModalContent,
    ModalSize,
    PopupHeader,
    TabContentWrapper,
    useIsMobile,
} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';
import {TaakCreateFormConnectedProps, TaakOverrideValues} from '../TaakCreateForm';

export interface TaakCreateModalProps {
    CreateForm: FC<TaakCreateFormConnectedProps>;
    onCreated?: (taak: ITaakCreateFormMutation) => void;
    overrideValues?: TaakOverrideValues;
}

export const TaakCreateModal: FC<TaakCreateModalProps> = ({CreateForm, onCreated, overrideValues}) => {
    const {t} = useTranslation('spa_form.nl');
    const isMobile = useIsMobile();
    const themeColor = isMobile ? undefined : 'gray';

    return (
        <Modal testId="TaakCreateModal" size={ModalSize.SIZE_L}>
            <PopupHeader title={t('taak.add')} />
            <ModalContent theme={themeColor}>
                <TabContentWrapper title="x">
                    <CreateForm onCreated={onCreated} overrideValues={overrideValues} />
                </TabContentWrapper>
            </ModalContent>
        </Modal>
    );
};
