import {
    ILijsttemplateTaakTemplateTabelFragment,
    ILijsttemplateTemplatetabelFragment,
    useLijsttemplateTemplatetabelQuery,
} from '@growthbase/graphql';
import {idFromIri, usePaginationSearchParams} from '@growthbase/routing';
import {FC, ReactNode} from 'react';
import {useEntityChanges} from './Hook';
import {Tabel} from './Tabel';
import {Filters} from '../RenderTableType';

export interface TabelConnectedProps {
    fragment: ILijsttemplateTaakTemplateTabelFragment;
    filters: Filters;
    header: ReactNode;
}

export const TabelConnected: FC<TabelConnectedProps> = ({fragment, filters, header}) => {
    const {id: lijsttemplateId} = fragment;
    const {pagination, usePagination} =
        usePaginationSearchParams<ILijsttemplateTemplatetabelFragment>('lijsttemplates');
    const result = useLijsttemplateTemplatetabelQuery({
        variables: {
            ...pagination,
            lijstId: idFromIri(lijsttemplateId),
            query: filters.searchQuery,
        },
    });

    useEntityChanges({
        lijsttemplateId,
        result,
        searchQuery: filters.searchQuery,
    });

    return <Tabel header={header} pagination={usePagination(result)} />;
};
