import {useTranslation} from 'react-i18next';
import {Dienst} from '../../../Sidebars/PlanningSidebar/Dienst';
import {useRenderTime} from '../../DienstSelectie/Hook/useRenderTime';

export const useSubtitle = (diensten: Array<Dienst>, gekozenDienstId: string | undefined) => {
    const {t} = useTranslation('spa.nl');
    const renderTime = useRenderTime();

    if (!gekozenDienstId) {
        return undefined;
    }

    const match = diensten.find((d) => d.id === gekozenDienstId);

    if (!match || !match.resterendBudgetVoorOpdracht) {
        return undefined;
    }

    return t('Planning.Sidebar.DienstSelectie.Resterend', {
        tijd: renderTime(match.resterendBudgetVoorOpdracht.resterendeTijdInSeconde),
    }).toString();
};
