import React, {ReactNode} from 'react';
import {IconSize} from '../../Icon';
import {Icons} from '../../icons';
import styles from './CommentCount.module.scss';

export interface CommentCountProps {
    children: ReactNode;
}

export const CommentCount = ({children}: CommentCountProps) => (
    <div className={styles.commentCount}>
        <Icons.Comments size={IconSize.SIZE_3} className={styles.commentCount__icon} />
        {children}
    </div>
);
