// TODO: fix src imports!
import React, {ReactNode, useCallback, useEffect} from 'react';
import {
    Eventcalendar,
    localeNl,
    MbscEventClickEvent,
    MbscEventCreateFailedEvent,
    MbscEventDragEvent,
    MbscPageLoadingEvent,
    momentTimezone,
} from '@mobiscroll/react';
import {useCallbackRef} from '@growthbase/spa';
import {CalendarWrapper, EventPopupTarget, Icons, Loader} from '@growthbase/design-components';
import {useBoolean} from 'react-use';
import {EventcalendarBase} from '@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar';
import {
    MbscEventCreateEvent,
    MbscNewEventData,
} from '@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar.types.public';
import {MbscCalendarEvent} from '@mobiscroll/react/dist/src/core/shared/calendar-view/calendar-view.types';
import {
    EventBackground,
    EventResource,
    EventType,
    PlanningContextMenu,
    PlanningStoreState,
    useCurrentView,
    useOnChangeDate,
    usePlanningContextMenu,
    usePlanningRefresh,
    usePlanningSetViewedDateRange,
    usePlanningStartDraggingBudgetForResource,
    usePlanningStopDraggingBudgetForResource,
    useRenderHeader,
} from '@growthbase/planning';
import {useSelector} from 'react-redux';
import {DateType} from '@mobiscroll/react/dist/src/core/util/datetime';
import {useUpdateEventAction} from '../../../planning/src/_hooks/useUpdateEventAction';
import {useSimpleMobiEventAction} from '../../../planning/src/_hooks/useSimpleEventAction';
import {useRenderLabel} from '../../../planning/src/Views/AgendaView/Hook/useRenderLabel';
import {useRenderDay} from '../../../planning/src/Views/AgendaView/Hook/useRenderDay';
import {useRenderEventContent} from '../../../planning/src/Views/AgendaView/Hook/useRenderEventContent';
import {useApplyEventColors} from '../../../planning/src/_hooks/useApplyEventColors';
import {AgendaAgendaViewFormats} from './AgendaViewsFormats';

export interface AgendaViewData {
    events: EventType[];
    resources: EventResource[];
    backgroundEvents?: EventBackground[];
}

export interface AgendaProps {
    onRequestData?: (event: {startDate: Date; endDate: Date}) => void;
    data?: AgendaViewData;
    loading?: boolean;
    onEventCreate?: (args: MbscEventCreateEvent, inst: EventcalendarBase) => void;
    extendDefaultEvent?: (args: MbscNewEventData) => MbscCalendarEvent;
    onShowPopupForEvent?: (args: EventPopupTarget) => void;
    onClosePopupForEvent?: () => void;
    contextMenu?: ReactNode;
}

export const Agenda = ({
    onRequestData,
    data,
    loading,
    extendDefaultEvent,
    onShowPopupForEvent,
    onClosePopupForEvent,
    onEventCreate,
    contextMenu = <PlanningContextMenu />,
}: AgendaProps) => {
    const [localLoading, setLocalLoading] = useBoolean(true);
    const onEventEditCallback = useUpdateEventAction(setLocalLoading);
    const onOpenEvent = useSimpleMobiEventAction('planningEventOpenModal');

    const refresh = usePlanningRefresh();
    const setViewedDateRange = usePlanningSetViewedDateRange();
    const startDraggingBudget = usePlanningStartDraggingBudgetForResource();
    const stopDraggingBudget = usePlanningStopDraggingBudgetForResource();

    const {openMenu} = usePlanningContextMenu();
    const currentViewFormat = useCurrentView('agenda');
    const {smallDates, id: viewId} = currentViewFormat;

    const renderLabel = useRenderLabel();
    const renderDay = useRenderDay(viewId, smallDates ?? false);
    const renderEventContent = useRenderEventContent();
    const renderHeader = useRenderHeader(AgendaAgendaViewFormats, 'agenda');
    const onDateChange = useOnChangeDate();

    const events = useApplyEventColors(data?.events, 'agenda');

    const {
        hash,
        date: selectedDate,
        creationDateRestrictions,
        viewedDateRange,
    } = useSelector((state: PlanningStoreState) => state.planning);

    const invalidDates: DateType[] = creationDateRestrictions.map(({start, end}) => ({
        start: new Date(start),
        end: new Date(end),
        allDay: true,
    }));

    useEffect(() => {
        setLocalLoading(false);
    }, [data, setLocalLoading]);

    const onPageLoading = useCallback(
        (event: MbscPageLoadingEvent) => {
            setTimeout(() => {
                setViewedDateRange({
                    start: event.firstDay,
                    end: event.lastDay,
                });
            }, 1);
        },
        [setViewedDateRange]
    );

    useEffect(() => {
        if (onRequestData && viewedDateRange) {
            setTimeout(() => {
                onRequestData({
                    startDate: viewedDateRange.start,
                    endDate: viewedDateRange.end,
                });
            }, 1);
        }
    }, [viewedDateRange, onRequestData, hash]);

    const onEventClick = useCallbackRef((event: MbscEventClickEvent) => {
        const domEvent = event.domEvent as MouseEvent;
        if (onShowPopupForEvent && event.event.editable) {
            onShowPopupForEvent({
                eventDomElement: domEvent.currentTarget as HTMLElement,
                externalEventId: event.event.id as string,
            });
        }
    });

    const onEventDoubleClick = useCallbackRef((event: MbscEventClickEvent) => {
        onOpenEvent(event);
        if (onClosePopupForEvent) {
            onClosePopupForEvent();
        }
    });

    const onEventRightClick = useCallbackRef((event: MbscEventClickEvent) => {
        openMenu(event.domEvent as MouseEvent, event.event as EventType);
    });

    const onEventCreateFailedCallback = useCallbackRef((event: MbscEventCreateFailedEvent) => {
        refresh();
    });

    const onEventDragStart = useCallbackRef((event: MbscEventDragEvent) => {
        if (event.event.toegewezenBudgetId && event.event.originalResource) {
            startDraggingBudget({
                resource: event.event.originalResource,
            });
        }
    });

    const onEventDragEnd = useCallbackRef((event: MbscEventDragEvent) => {
        stopDraggingBudget();
    });

    return (
        <>
            {contextMenu}
            {(localLoading || loading) && <Loader fixed testId="planningLoading" />}
            <CalendarWrapper>
                <Eventcalendar
                    data={events}
                    theme="ios"
                    themeVariant="light"
                    height="100%"
                    timezonePlugin={momentTimezone}
                    timeFormat="HH:mm"
                    displayTimezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                    locale={localeNl}
                    view={currentViewFormat.view}
                    returnFormat="jsdate"
                    selectedDate={selectedDate}
                    onSelectedDateChange={(event) => onDateChange(event.date as Date)}
                    extendDefaultEvent={extendDefaultEvent}
                    onEventUpdate={onEventEditCallback}
                    onEventRightClick={onEventRightClick}
                    onEventClick={onEventClick}
                    onEventCreateFailed={onEventCreateFailedCallback}
                    onEventDoubleClick={onEventDoubleClick}
                    onPageLoading={onPageLoading}
                    actionableEvents
                    dragTimeStep={15}
                    dragToCreate={!loading}
                    dragToMove={!loading}
                    dragToResize={!loading}
                    clickToCreate={!loading}
                    renderHeader={renderHeader}
                    renderDay={renderDay}
                    renderLabel={renderLabel}
                    renderScheduleEventContent={renderEventContent}
                    onEventCreate={onEventCreate}
                    // @ts-expect-error string expected, react element given
                    todayText={<Icons.Calendar />}
                    externalDrop
                    invalid={invalidDates}
                    onEventDragStart={onEventDragStart}
                    onEventDragEnd={onEventDragEnd}
                />
            </CalendarWrapper>
        </>
    );
};
