import {AddBreadcrumb, LoaderCenter, Page} from '@growthbase/design-components';
import {OpdrachtLegacyRoutes} from '@growthbase/opdrachten-opdrachten';
import {useTranslation} from 'react-i18next';
import {FC, PropsWithChildren} from 'react';

export interface PaginaProps extends PropsWithChildren {
    opdrachtNaam: string;
    opdrachtId: string;
    opdrachtnummer: string;
    opdrachtUuid: string;
    titel: string;
}

export const Pagina: FC<PaginaProps> = ({titel, opdrachtId, opdrachtUuid, opdrachtnummer, opdrachtNaam, children}) => {
    const params = {
        opdrachtId,
        opdrachtUuid,
        opdrachtnummer,
    };
    const {t: tGeneral} = useTranslation('Opdrachten.nl');
    return (
        <Page header={null}>
            <AddBreadcrumb order={-11} to={OpdrachtLegacyRoutes.index.useUrl(params)}>
                {tGeneral('ViewBreadcrumb')}
            </AddBreadcrumb>
            <AddBreadcrumb order={-10} to={OpdrachtLegacyRoutes.read.useUrl(params)}>
                {opdrachtNaam}
            </AddBreadcrumb>
            {children ?? <LoaderCenter />}
        </Page>
    );
};
