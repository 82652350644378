import {FC, HTMLAttributes, memo, PropsWithChildren, useRef} from 'react';
import classNames from 'classnames/bind';
import {useHover} from 'usehooks-ts';
import styles from './SwimlaneCard.module.scss';
import {Footer, Body} from './Components';
import {PopupsContextProvider} from '../../../Popups/PopupsContext/PopupsContextProvider';
import {usePopupsContext} from '../../../Popups/PopupsContext/usePopupsContext';

const cx = classNames.bind(styles);

export interface SwimlaneCardProps extends PropsWithChildren {
    anchor?: HTMLAttributes<HTMLElement> | undefined;
    showPlaceholder?: boolean;
}

export const SwimlaneCardInner: FC<SwimlaneCardProps> = memo(({children, anchor, showPlaceholder}) => {
    const elementRef = useRef<HTMLDivElement>(null);
    const {hasOpen} = usePopupsContext();
    const isHovered = useHover(elementRef) || hasOpen;

    const classes = cx('swimlaneCard', {
        'swimlaneCard--placeholder': showPlaceholder,
    });

    return (
        <div
            className={classes}
            ref={elementRef}
            {...anchor}
            data-scope="card"
            role="row"
            data-hover={isHovered ? 'true' : 'false'}
        >
            <div className={cx('swimlaneCard__inner')}>
                {children}
                {showPlaceholder && <div className={cx('swimlaneCard__placeholderOverlay')} />}
            </div>
        </div>
    );
});
SwimlaneCardInner.displayName = 'SwimlaneCardInner';

export const SwimlaneCard: FC<SwimlaneCardProps> = memo((props) => (
    <PopupsContextProvider>
        <SwimlaneCardInner {...props} />
    </PopupsContextProvider>
));

SwimlaneCard.displayName = 'SwimlaneCard';

export const SwimlaneCardFooter = Footer;
export const SwimlaneCardBody = Body;
