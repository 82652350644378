import {PlanningViewProps, PlanningView, StrokenPlanningData} from '@growthbase/planning';
import {Optional} from 'utility-types';
import moment from 'moment/moment';

export type StrokenPlanningStubProps = Optional<PlanningViewProps>;

export const PlanningViewStub = (props: StrokenPlanningStubProps) => {
    const date = moment('2023-01-01');

    const baseEvent: {editable: boolean; resize: boolean; dragBetweenResources: boolean; taak: string | null} = {
        editable: true,
        resize: true,
        dragBetweenResources: true,
        taak: null,
    };

    const data: StrokenPlanningData = {
        resources: [
            {
                id: 'team-1',
                name: 'Team 1',
                isParent: true,
                eventCreation: false,
                children: [
                    {
                        id: '1',
                        name: 'Harrie Hendrik',
                        color: '#FFE09A',
                    },
                    {
                        id: '2',
                        name: 'Henk Jan',
                        color: '#970002',
                    },
                    {
                        id: '3',
                        name: 'Marieke van der Velden',
                        color: '#29254b',
                    },
                ],
            },
            {
                id: 'team-2',
                name: 'Team 2',
                eventCreation: false,
                children: [
                    {
                        id: '4',
                        name: 'Harrie Hendrik',
                        color: '#006c6b',
                    },
                    {
                        id: '5',
                        name: 'Henk Jan',
                        color: '#36191b',
                    },
                    {
                        id: '6',
                        name: 'Marieke van der Velden',
                        color: '#ff6767',
                    },
                ],
            },
        ],
        events: [
            {
                id: 'e-1',
                start: date.hours(8).minutes(30).toDate(),
                end: date.hours(9).minutes(0).toDate(),
                title: 'Overleg',
                resource: '1',
                ...baseEvent,
            },
            {
                id: 'e-12',
                start: date.hours(9).minutes(0).toDate(),
                end: date.hours(12).minutes(0).toDate(),
                title: 'Project X',
                resource: '1',
                ...baseEvent,
            },
            {
                id: 'e-13',
                start: date.hours(12).minutes(30).toDate(),
                end: date.hours(17).minutes(0).toDate(),
                title: 'Project X',
                resource: '1',
                ...baseEvent,
            },
            {
                id: 'e-14',
                start: date.hours(16).minutes(0).toDate(),
                end: date.hours(17).minutes(0).toDate(),
                title: 'Release',
                resource: '1',
                ...baseEvent,
            },
            {
                id: 'e-21',
                start: date.hours(8).minutes(30).toDate(),
                end: date.hours(9).minutes(30).toDate(),
                title: 'Overleg',
                resource: '2',
                ...baseEvent,
            },
            {
                id: 'e-22',
                start: date.hours(9).minutes(30).toDate(),
                end: date.hours(12).minutes(0).toDate(),
                title: 'Project X',
                resource: '2',
                ...baseEvent,
            },
            {
                id: 'e-23',
                start: date.hours(12).minutes(30).toDate(),
                end: date.hours(17).minutes(0).toDate(),
                title: 'Project X',
                resource: '2',
                ...baseEvent,
            },
            {
                id: 'e-31',
                start: date.hours(8).minutes(30).toDate(),
                end: date.hours(9).minutes(30).toDate(),
                title: 'Overleg',
                resource: '3',
                ...baseEvent,
            },
            {
                id: 'e-32',
                start: date.hours(9).minutes(30).toDate(),
                end: date.hours(12).minutes(0).toDate(),
                title: 'Project X',
                resource: '3',
                ...baseEvent,
            },
            {
                id: 'e-33',
                start: date.hours(12).minutes(30).toDate(),
                end: date.hours(17).minutes(0).toDate(),
                title: 'Project X',
                resource: '3',
                ...baseEvent,
            },
            {
                id: 'e-1',
                start: date.hours(8).minutes(30).toDate(),
                end: date.hours(9).minutes(0).toDate(),
                title: 'Overleg',
                resource: '4',
                ...baseEvent,
            },
            {
                id: 'e-12',
                start: date.hours(9).minutes(0).toDate(),
                end: date.hours(12).minutes(0).toDate(),
                title: 'Project X',
                resource: '4',
                ...baseEvent,
            },
            {
                id: 'e-13',
                start: date.hours(12).minutes(30).toDate(),
                end: date.hours(17).minutes(0).toDate(),
                title: 'Project X',
                resource: '4',
                ...baseEvent,
            },
            {
                id: 'e-14',
                start: date.hours(16).minutes(0).toDate(),
                end: date.hours(17).minutes(0).toDate(),
                title: 'Release',
                resource: '4',
                ...baseEvent,
            },
            {
                id: 'e-21',
                start: date.hours(8).minutes(30).toDate(),
                end: date.hours(9).minutes(30).toDate(),
                title: 'Overleg',
                resource: '5',
                ...baseEvent,
            },
            {
                id: 'e-22',
                start: date.hours(9).minutes(30).toDate(),
                end: date.hours(12).minutes(0).toDate(),
                title: 'Project X',
                resource: '5',
                ...baseEvent,
            },
            {
                id: 'e-23',
                start: date.hours(12).minutes(30).toDate(),
                end: date.hours(17).minutes(0).toDate(),
                title: 'Project X',
                resource: '5',
                ...baseEvent,
            },
            {
                id: 'e-31',
                start: date.hours(8).minutes(30).toDate(),
                end: date.hours(9).minutes(30).toDate(),
                title: 'Overleg',
                resource: '6',
                ...baseEvent,
            },
            {
                id: 'e-32',
                start: date.hours(9).minutes(30).toDate(),
                end: date.hours(12).minutes(0).toDate(),
                title: 'Project X',
                resource: '6',
                ...baseEvent,
            },
        ],
    };

    return <PlanningView data={data} {...props} onEventCreate={undefined} />;
};
