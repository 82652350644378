import {useMemo} from 'react';
import {InstellingFieldsInput} from '@growthbase/instellingen';
import {useTaakKopierenOpties} from '@growthbase/taken';
import {useTranslation} from 'react-i18next';
import {LijstKopierenFormValues} from '../KopieerForm';

export const useLijstKopierenOpties = (titel: string) => {
    const {t} = useTranslation('TakenlijstLijst.nl');
    const takenFields = useTaakKopierenOpties('');
    return useMemo(
        (): InstellingFieldsInput<LijstKopierenFormValues> => ({
            title: {
                label: t('Kopieren.titel'),
                defaultValue: t('Kopieren.copyTitle', {titel}) ?? '',
            },
            activiteiten: {
                label: t('Kopieren.activiteiten'),
                defaultValue: false,
            },
            taken: {
                label: t('Kopieren.taken'),
                defaultValue: true,
                control: {
                    taak: {when: false, value: false, hide: true},
                    taakFilter: {when: false, value: false, hide: true},
                },
            },
            taakFilter: {
                label: t('Kopieren.taakFilter'),
                fields: {
                    alles: {
                        label: t('Kopieren.alleTaken'),
                        control: {
                            actieveTaken: {when: true, value: true},
                            afgerondeTaken: {when: true, value: true},
                            gearchiveerdeTaken: {when: true, value: true},
                        },
                        defaultValue: false,
                        exclude: true,
                    },
                    actieveTaken: {
                        label: t('Kopieren.actieveTaken'),
                        defaultValue: true,
                    },
                    afgerondeTaken: {
                        label: t('Kopieren.afgerondeTaken'),
                        defaultValue: true,
                    },
                    gearchiveerdeTaken: {
                        label: t('Kopieren.gearchiveerdeTaken'),
                        defaultValue: false,
                    },
                },
            },
            taak: {
                ...takenFields.properties,
                label: t('Kopieren.whatDoYouWantToCopyFromTaken'),
            },
        }),
        [t, takenFields.properties, titel]
    );
};
