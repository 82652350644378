import {
    CellPropGetter,
    FooterGroupPropGetter,
    FooterPropGetter,
    HeaderGroupPropGetter,
    HeaderPropGetter,
    PluginHook,
    RowPropGetter,
    TablePropGetter,
} from 'react-table';

type TableProps = object;

const getTableProps: TablePropGetter<TableProps> = (props, {instance}) => [
    props,
    {
        style: {
            // @ts-expect-error This was already wrong in the original code
            minWidth: `${instance.totalColumnsMinWidth}px`,
        },
    },
];

const getRowStyles: RowPropGetter<TableProps> = (props, {instance}) => [
    props,
    {
        style: {
            display: 'flex',
            flex: '1 0 auto',
            // @ts-expect-error This was already wrong in the original code
            minWidth: `${instance.totalColumnsMinWidth}px`,
        },
    },
];

const getHeaderGroup: HeaderGroupPropGetter<TableProps> = (props, {instance}) => [
    props,
    {
        style: {
            display: 'flex',
            flex: '1 0 auto',
            // @ts-expect-error This was already wrong in the original code
            minWidth: `${instance.totalColumnsMinWidth}px`,
        },
    },
];

const getFooterGroup: FooterGroupPropGetter<TableProps> = (props, {instance}) => [
    props,
    {
        style: {
            display: 'flex',
            flex: '1 0 auto',
            // @ts-expect-error This was already wrong in the original code
            minWidth: `${instance.totalColumnsMinWidth}px`,
        },
    },
];

const getHeaderProps: HeaderPropGetter<TableProps> = (props, {column}) => [
    props,
    {
        style: {
            boxSizing: 'border-box',
            // @ts-expect-error This was already wrong in the original code
            flex: column.totalFlexWidth ? `${column.totalFlexWidth} 0 auto` : undefined,
            // @ts-expect-error This was already wrong in the original code
            minWidth: `${column.totalMinWidth}px`,
            width: `${column.totalWidth}px`,
        },
    },
];

const getCellProps: CellPropGetter<TableProps> = (props, {cell}) => [
    props,
    {
        style: {
            boxSizing: 'border-box',
            // @ts-expect-error This was already wrong in the original code
            flex: `${cell.column.totalFlexWidth} 0 auto`,
            // @ts-expect-error This was already wrong in the original code
            minWidth: `${cell.column.totalMinWidth}px`,
            width: `${cell.column.totalWidth}px`,
        },
    },
];

const getFooterProps: FooterPropGetter<TableProps> = (props, {column}) => [
    props,
    {
        style: {
            boxSizing: 'border-box',
            // @ts-expect-error This was already wrong in the original code
            flex: column.totalFlexWidth ? `${column.totalFlexWidth} 0 auto` : undefined,
            // @ts-expect-error This was already wrong in the original code
            minWidth: `${column.totalMinWidth}px`,
            width: `${column.totalWidth}px`,
        },
    },
];

export const useCustomLayout: PluginHook<TableProps> = (hooks) => {
    hooks.getTableProps.push(getTableProps);
    hooks.getRowProps.push(getRowStyles);
    hooks.getHeaderGroupProps.push(getHeaderGroup);
    hooks.getFooterGroupProps.push(getFooterGroup);
    hooks.getHeaderProps.push(getHeaderProps);
    hooks.getCellProps.push(getCellProps);
    hooks.getFooterProps.push(getFooterProps);
};

useCustomLayout.pluginName = 'useCustomLayout';
