import {useTranslation} from 'react-i18next';
import {FC, useMemo} from 'react';
import {ButtonGroup, Columns, TableEditLink} from '@growthbase/design-components';
import {ILijsttemplateTabelFragment} from '@growthbase/graphql';
import {CellProps} from 'react-table';
import {LijsttemplateRoutes} from '../../LijsttemplateRoutes';
import {LijsttemplateTakenCountCounterConnected} from '../../LijsttemplateTakenCountCounter';
import {MenuConnected} from '../../Menu';

const ActionsCell: FC<CellProps<ILijsttemplateTabelFragment>> = ({row}) => (
    <ButtonGroup disableRightMargin>
        <MenuConnected fragment={row.original} />
    </ButtonGroup>
);

const TakenCountButtonCell: FC<CellProps<ILijsttemplateTabelFragment>> = ({row}) => (
    <LijsttemplateTakenCountCounterConnected fragment={row.original} />
);

export const useTabelColumns = () => {
    const {t} = useTranslation('spa_form.nl');
    const urlFactory = LijsttemplateRoutes.bekijken.useUrlFactory();
    return useMemo(
        (): Columns<ILijsttemplateTabelFragment> => [
            {
                Header: <>{t('lijsttemplate.tabel.naam')}</>,
                accessor: 'naam',
                Cell: ({
                    value,
                    row: {
                        original: {id: lijsttemplateId},
                    },
                }) => <TableEditLink to={urlFactory({lijsttemplateId})}>{value}</TableEditLink>,
            },
            {
                Header: <>{t('lijsttemplate.tabel.aantal_taken')}</>,
                id: 'aantal-taken',
                Cell: TakenCountButtonCell,
            },
            {
                Header: <div />,
                Cell: ActionsCell,
                id: 'actions',
                width: 'auto',
                minWidth: 140,
            },
        ],
        [t, urlFactory]
    );
};
