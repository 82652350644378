import {useMemo, useRef} from 'react';
import {AnyFunction} from '@udecode/plate';

/*
 * Refs all functions on the object and returns a new object with the same keys and values.
 */
export const useHashedMemorize = <T extends Record<string | number, unknown>>(props: T): T => {
    const functionNames = Object.keys(props).filter((key) => typeof props[key] === 'function');

    const propsWithoutFunctions = Object.keys(props).reduce((acc: T, key: keyof T) => {
        if (typeof props[key] !== 'function') {
            acc[key] = props[key];
        }
        return acc;
    }, {} as T);

    const hash = JSON.stringify({
        propsWithoutFunctions,
        functionNames,
    });

    const functionRef = useRef<{
        props: T;
        functions: Record<string, AnyFunction>;
    }>({
        props,
        functions: {},
    });

    functionRef.current.props = props;

    return useMemo(
        (): T => ({
            ...props,
            ...Object.fromEntries(
                functionNames.map((key) => {
                    if (!functionRef.current.functions[key]) {
                        functionRef.current.functions[key] = (...args) =>
                            // @ts-expect-error We know this is correct :).
                            functionRef.current.props[key](...args);
                    }
                    return [key, functionRef.current.functions[key]];
                })
            ),
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [hash]
    );
};
