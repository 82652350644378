import {RoutePopupProvider} from '@growthbase/design-components';
import {FC} from 'react';
import {TaakCreateFormConnected, TaakOverrideValues} from '../TaakCreateForm';
import {TaakCreateModal} from './TaakCreateModal';
import {TaakDefaultPrivateProvider} from '../PriveField';

export interface TaakCreateModalConnectedProps {
    overrideValues?: TaakOverrideValues;
}

export const TaakCreateModalConnected: FC<TaakCreateModalConnectedProps> = ({overrideValues}) => (
    <RoutePopupProvider>
        <TaakDefaultPrivateProvider defaultPrivate={overrideValues?.isPrive ?? false}>
            <TaakCreateModal overrideValues={overrideValues} CreateForm={TaakCreateFormConnected} />
        </TaakDefaultPrivateProvider>
    </RoutePopupProvider>
);
