import {SortableTabelStub as SortableTaakTabelStub} from '@growthbase/takentemplates-taaktemplate';
import {RenderSortableTabelType} from './SortableTabelType';
import {SortableTabel} from './SortableTabel';
import {MenuStub} from '../../../../../Menu';

export const renderSortableTabellenStub: RenderSortableTabelType = ({item}) => (
    <SortableTabel
        renderTabel={(config) => <SortableTaakTabelStub />}
        menu={<MenuStub lijstTitel={`Lijst ${item.userData.id}`} />}
        item={item}
    />
);
