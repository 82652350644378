import {IRelatieGroupsQuery, IRelatiePopupGroupsFragment, IRelatieType} from '@growthbase/graphql';
import {useReducer} from 'react';
import produce from 'immer';

const compareRelatieGroupsQuery = (first: IRelatieGroupsQuery, second: IRelatieGroupsQuery) =>
    first.query === second.query && first.changeType === second.changeType;

export interface State {
    query: IRelatieGroupsQuery;
    loading: boolean;
    groups: IRelatiePopupGroupsFragment;
}

const initialState: State = {
    query: {},
    loading: false,
    groups: {
        groups: [],
    },
};

interface LoadingAction {
    type: 'loading';
    query: IRelatieGroupsQuery;
    changeType?: IRelatieType | null;
}

interface ResultAction {
    type: 'results';
    fragment: IRelatiePopupGroupsFragment;
    query: IRelatieGroupsQuery;
}

interface ErrorAction {
    type: 'errored';
    error: unknown;
    query: IRelatieGroupsQuery;
}

type Action = LoadingAction | ResultAction | ErrorAction;

const reducer = (state: State, action: Action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case 'loading':
                draft.loading = true;
                draft.query = action.query;
                draft.groups = {
                    groups: [],
                };
                return;
            case 'results':
                if (!compareRelatieGroupsQuery(action.query, draft.query)) {
                    return;
                }
                draft.groups = action.fragment ?? {
                    groups: [],
                };
                draft.loading = false;
                break;
            case 'errored':
                if (!compareRelatieGroupsQuery(action.query, draft.query)) {
                    return;
                }
                draft.loading = false;
                draft.groups = {
                    groups: [],
                };
                break;
            default:
                throw new Error();
        }
    });

export const useRelatiePopupReducer = () => useReducer(reducer, initialState);
