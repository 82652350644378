import {useGebruikerHeeftToegang, Warning, Loader} from '@growthbase/design-components';
import {useResolveConnections, useTakenlijstenConnectedQuery} from '@growthbase/graphql';
import {ViewConnected} from '@growthbase/takentemplates-bordtemplate';
import React, {FC} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {DefaultKoppelbaarItem} from '@growthbase/spa';
import {BordSelect, GeenItemsAanwezig} from './Components';
import {useEntityChanges} from './Hook/useEntityChanges';
import {useResolveOnderdeelVan} from '../../../taken/src/Overview/Hook/useResolveOnderdeelVan';

export interface OverviewProps {
    abonnementId: string;
    bordtemplateId?: string;
}

export const Overview: FC<OverviewProps> = ({abonnementId, bordtemplateId}) => {
    const result = useTakenlijstenConnectedQuery({
        variables: {
            abonnementId,
            id: abonnementId,
        },
    });
    const {data, loading} = result;
    const {t: tLegacy} = useTranslation('AbonnementTakentemplates.nl');
    const connections = useResolveConnections(data?.Bordtemplates);

    const toegang = useGebruikerHeeftToegang({
        toegang: data?.ingelogdeGebruiker?.availableActions.kanTakenBekijken,
    });

    const aboITem = useResolveOnderdeelVan(abonnementId, data?.abonnement);

    useEntityChanges({result});

    bordtemplateId = bordtemplateId ?? connections[0]?.id ?? null;

    if (!toegang) {
        return null;
    }

    if (loading) {
        return <Loader center />;
    }

    return (
        <DefaultKoppelbaarItem.Provider id="Overview" value={aboITem}>
            <Warning>
                <Trans
                    t={tLegacy}
                    components={{
                        bold: <strong />,
                    }}
                >
                    takentemplates.bordtemplate.BordMetLijsten.warning
                </Trans>
            </Warning>

            {(connections.length === 0 || !bordtemplateId) && (
                <GeenItemsAanwezig abonnementId={abonnementId} bordtemplateId={bordtemplateId} />
            )}
            {bordtemplateId && (
                <ViewConnected
                    bordtemplateId={bordtemplateId}
                    bordSelect={<BordSelect abonnementId={abonnementId} bordId={bordtemplateId} borden={connections} />}
                />
            )}
        </DefaultKoppelbaarItem.Provider>
    );
};
