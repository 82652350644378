import {FC} from 'react';
import {
    BaseButtonProps,
    ButtonHandler,
    ConfirmModalButton,
    ModalContent,
    PopupHeader,
    Button as DefaultButton,
} from '@growthbase/design-components';
import {Trans, useTranslation} from 'react-i18next';

export interface VerwijderenButtonProps {
    lijstTitel: string;
    onSubmit: ButtonHandler;
    Button?: FC<BaseButtonProps>;
}

export const VerwijderenButton: FC<VerwijderenButtonProps> = ({lijstTitel, Button = DefaultButton, onSubmit}) => {
    const {t} = useTranslation('spa.nl');
    return (
        <>
            <ConfirmModalButton
                header={<PopupHeader title={t('lijsttemplate.LijstVerwijderenMenuButton.Bevestigmodal.Titel')} />}
                onSubmit={onSubmit}
                popupButtonContent={t('lijsttemplate.LijstVerwijderenMenuButton.Bevestigmodal.Bevestigknop')}
                renderOpenButton={(props) => (
                    <Button {...props}>{t('lijsttemplate.LijstVerwijderenMenuButton.Dropdownknop')}</Button>
                )}
            >
                <ModalContent>
                    <div>
                        <p>
                            <Trans
                                t={t}
                                components={{strong: <strong />}}
                                values={{titel: `<strong>${lijstTitel}</strong>`}}
                            >
                                lijsttemplate.LijstVerwijderenMenuButton.Bevestigmodal.Tekst
                            </Trans>
                        </p>
                    </div>
                </ModalContent>
            </ConfirmModalButton>
        </>
    );
};
