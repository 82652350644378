import {FC} from 'react';
import {StyledSelect} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';
import {Opdrachtstatus} from '@growthbase/planning';

export interface StatusfilterProps {
    onChange: (opdrachtstatus: Opdrachtstatus | undefined) => void;
    value?: Opdrachtstatus;
}

export const Statusfilter: FC<StatusfilterProps> = ({onChange, value}) => {
    const {t} = useTranslation('spa.nl');
    const options: Array<{
        value: Opdrachtstatus;
        label: string;
    }> = [
        {label: t('planning.opdracht.status.alle'), value: Opdrachtstatus.Alle},
        {label: t('planning.opdracht.status.nieuw'), value: Opdrachtstatus.Nieuw},
        {label: t('planning.opdracht.status.inBehandeling'), value: Opdrachtstatus.InBehandeling},
        {label: t('planning.opdracht.status.afgerond'), value: Opdrachtstatus.Afgerond},
    ];

    return (
        <StyledSelect<Opdrachtstatus>
            readonly={false}
            disabled={false}
            value={value ?? Opdrachtstatus.Alle}
            options={options}
            onChange={onChange}
        />
    );
};
