import {QueryResult} from '@apollo/client/react/types/types';
import {
    ITakenTabelVanLijstQuery,
    ITakenTabelVanLijstQueryVariables,
    ITakenTabelVanLijstRowFragment,
    useMaakTaakInLijstMutation,
} from '@growthbase/graphql';
import {TaakExtraProps, useAddTaakLockHandler} from '@growthbase/taken';
import {useCallback} from 'react';

export const useMaakTaakInLijstHandler = (
    lijstId: string,
    result: QueryResult<ITakenTabelVanLijstQuery, ITakenTabelVanLijstQueryVariables>
) => {
    const [mutateAsync] = useMaakTaakInLijstMutation();

    return useAddTaakLockHandler(
        result,
        (data) =>
            mutateAsync({
                variables: {
                    input: {
                        ...data,
                        lijsten: [lijstId],
                    },
                },
            }),
        useCallback(
            (): TaakExtraProps<ITakenTabelVanLijstRowFragment> => ({
                type: 'Taak',
            }),
            []
        )
    );
};
