import {
    CreateFieldFormOptions,
    SingleLineOpgemaakteTekstInput,
    useCreateStrictForm,
} from '@growthbase/design-components';
import {IRelatieverwijzingInput, IZichtbaarVoor} from '@growthbase/graphql';
import {RelatieComboboxQuery, relatieIsEmpty} from '@growthbase/relaties';
import {DefaultGebruiker, DefaultKoppelbaarItem, DefaultRelatieValue, useCallbackRef} from '@growthbase/spa';
import {useFormikContext} from 'formik';
import React, {ReactNode, useCallback, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {CreateFormDTO, createInitialFormValues, useCreateSchema} from './useCreateSchema';
import {useStripOnderdeelVanNaam} from './useStripOnderdeelVanNaam';
import {useStripRelatieFromNaam} from './useStripRelatieFromNaam';
import {useStripDeelnemersFromNaam} from './useStripDeelnemersFromNaam';

export interface UseCreateFormOptions
    extends Pick<
        CreateFieldFormOptions<CreateFormDTO>,
        'autoSubmit' | 'doNoWaitForSubmit' | 'createInitialValuesAfterSubmit'
    > {
    onSubmit: (values: CreateFormDTO) => Promise<void>;
}

const StripTitleField = () => {
    const formik = useFormikContext<CreateFormDTO>();
    useStripOnderdeelVanNaam({formik});
    useStripRelatieFromNaam({formik});
    useStripDeelnemersFromNaam({formik});
    return null;
};

export const useCreateForm = ({onSubmit, autoSubmit = false, ...rest}: UseCreateFormOptions) => {
    const {t} = useTranslation('TakenlijstBord.nl');
    const defaultItem = DefaultKoppelbaarItem.useValue(null);
    const defaultRelatie = DefaultRelatieValue.useValue(null);
    const defaultDeelnemer = DefaultGebruiker.useValue();

    const results = useCreateStrictForm<CreateFormDTO>({
        onSubmit: useCallback((values) => onSubmit(values), [onSubmit]),
        autoSubmit,
        schema: useCreateSchema(),
        enableLockPopupAndRoutingWhenDirty: false,
        createInitialValuesAfterSubmit: true,
        createInitialValues: useCallbackRef(() => {
            const values = {
                ...createInitialFormValues(),
            };
            if (defaultItem) {
                values.onderdeelVan = defaultItem;
            }
            if (defaultRelatie) {
                values.relatie = defaultRelatie;
            }
            if (defaultDeelnemer) {
                values.deelnemers.push(defaultDeelnemer);
            }
            values.lijsten.push(`${t('taken')}`);
            return values;
        }),
        ...rest,
    });
    const Naam = results.Fields.NaamField.useComponent(SingleLineOpgemaakteTekstInput);

    const relatieValue = results.formik.values.relatie;
    const relatie = useMemo(
        (): IRelatieverwijzingInput | null =>
            relatieIsEmpty(relatieValue)
                ? null
                : {
                      persoonId: relatieValue.persoon?.id,
                      bedrijfId: relatieValue.bedrijf?.id,
                  },
        [relatieValue]
    );

    const renderNaam: typeof Naam = (props) => <Naam {...props} nested={<StripTitleField />} />;

    const {onderdeelVan, deelnemers, zichtbaarVoor} = results.formik.values;

    const clearOnderdeelVan = useCallbackRef(() => results.formik.setFieldValue('onderdeelVan', null));
    const toMijzelf = useCallbackRef(() => results.formik.setFieldValue('zichtbaarVoor', IZichtbaarVoor.Mijzelf));

    useEffect(() => {
        if (onderdeelVan && relatieIsEmpty(relatieValue)) {
            clearOnderdeelVan();
        }
    }, [
        relatieValue,
        onderdeelVan,
        clearOnderdeelVan,
        deelnemers.length,
        zichtbaarVoor,
        toMijzelf,
    ]);

    const render = (children: ReactNode) => (
        <RelatieComboboxQuery
            value={relatie}
            onChange={(newRelatie) => {
                results.formik.setFieldValue('relatie', newRelatie);
            }}
        >
            {children}
        </RelatieComboboxQuery>
    );

    return {...results, render, renderNaam};
};
