import {IGetPlanningDataQuery} from '@growthbase/planning';
import {GroupedCalendarSelectionItem} from '@growthbase/design-components';
import {TFunction} from 'i18next';

export const denormalizeAgendas: (
    t: TFunction<'spa.nl'>,
    agendas?: IGetPlanningDataQuery
) => GroupedCalendarSelectionItem[] = (t, agendas) => {
    if (!agendas) {
        return [];
    }

    const agendaGroups: GroupedCalendarSelectionItem[] = [];

    agendas.Planning_Medewerkers.forEach((medewerker) => {
        let employeeTeam = medewerker.team || {id: 'no-team', name: t('planning.geenTeam')};

        if (medewerker.agenda.agendaVanIngelogdeGebruiker) {
            employeeTeam = {id: 'my-calendars', name: t('planning.mijnAgenda')};
        }

        // Create team if not exists
        if (!agendaGroups.find((group) => group.id === employeeTeam.id)) {
            agendaGroups.push({
                id: employeeTeam.id,
                name: employeeTeam.name,
                items: [],
            });
        }

        // Add medewerker to team
        const team = agendaGroups.find((group) => group.id === employeeTeam.id);
        if (team) {
            team.items.push({
                id: medewerker.agenda.id,
                title: medewerker.agenda.name,
                enabled: medewerker.agenda.activeInPlanning,
                color: medewerker.agenda.color,
                employeeId: medewerker.id,
                selectable: true,
            });
            team.enabled = !team.items.some((item) => !item.enabled);
        }
    });

    // Sort teams alphabetically
    agendaGroups.sort((teamA, teamB) => teamA.name.localeCompare(teamB.name));

    // Sort medewerkers alphabetically
    agendaGroups.forEach((team) => {
        team.items.sort((itemA, itemB) => itemA.title.localeCompare(itemB.title));
    });

    // Move my-agenda to the top
    agendaGroups.sort((teamA) => (teamA.id === 'my-calendars' ? -1 : 0));

    // Move no-team to the bottom
    agendaGroups.sort((teamA) => (teamA.id === 'no-team' ? 1 : 0));

    return agendaGroups;
};
