import {QueryTabs, Tab, TabsRendererMarginLeft} from '@growthbase/design-components';
import {FC, memo, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';

export interface TakenSideLinksProps {
    children: ReactNode;
}

export const TakenSideLinks: FC<TakenSideLinksProps> = memo(({children}) => {
    const {t} = useTranslation('spa.nl');
    return (
        <QueryTabs render={TabsRendererMarginLeft} name="sidelinks" defaultValue="links">
            <Tab title={t('Mijn taken')} value="links">
                {children}
            </Tab>
        </QueryTabs>
    );
});

TakenSideLinks.displayName = 'TakenSideLinks';
