import './Utils';
import {bootstrap} from '@growthbase/kernel';
import {TakenRoutes} from '@growthbase/taken';
import {DeveloperRoutes} from '@growthbase/health';
import {BordRoutes} from '@growthbase/takenlijst/bord';
import {BordtemplateRoutes} from '@growthbase/takentemplates-bordtemplate';
import {TaaktemplateRoutes} from '@growthbase/takentemplates-taaktemplate';
import {OpdrachtRoutes} from '@growthbase/opdrachten-opdrachten';
import React, {FC} from 'react';
import {NavigationPortal} from '@growthbase/clientbox';
import {AbonnementRoutes} from '@growthbase/abonnementen-abonnementen';
import {RelatieRoutes} from '@growthbase/relaties';
import {PlanningRoutes} from '@growthbase/planning';
import {AgendaRoutes} from '@growthbase/agenda';
import {BestandsbeheerRoutes} from '@growthbase/bestandsbeheer';
import {ClientApplicationConfigDTO} from '@growthbase/spa';
import {TicketRoutes} from '@growthbase/tickets-tickets';
import {PlanningPopupTwigConnector} from '@growthbase/opdrachten-planning';
import {TakenInstellingenRoutes} from '@growthbase/taken-instellingen';
import {LijsttemplateRoutes} from '@growthbase/takentemplates-lijsttemplate';
import {AbonnementTemplateRoutes} from '@growthbase/abonnementen-takentemplates';
import {laodConditionizeExtension} from './ConditionizeExtension';
import {loadSelect2AutocompleteType} from './Select2AutocompleteType';
import {loadTicketMessages} from './TwigComponents';
import {loadCopyInputValueExtension} from './CopyInputValueExtension';
import {loadDateOffsetTypeExtension} from './DateOffsetTypeExtension';

const AllRoutes: FC<{config: ClientApplicationConfigDTO}> = ({config}) => (
    <>
        <PlanningPopupTwigConnector />
        <NavigationPortal />
        <DeveloperRoutes.Routes />

        {config.takenEnabled && (
            <>
                <TakenInstellingenRoutes.Routes />
                <TakenRoutes.Routes />
                <OpdrachtRoutes.Routes />
                <BordRoutes.Routes />
                <AbonnementRoutes.Routes />
                <RelatieRoutes.Routes />
                <TicketRoutes.Routes />
                <BordtemplateRoutes.Routes />
                <LijsttemplateRoutes.Routes />
                <TaaktemplateRoutes.Routes />
                <AbonnementTemplateRoutes.Routes />
                <BestandsbeheerRoutes.Routes />
            </>
        )}

        {config.planningEnabled && <PlanningRoutes.Routes />}
        {config.agendaEnabled && <AgendaRoutes.Routes />}
    </>
);

bootstrap(
    {
        Components: AllRoutes,
    },
    () => ({
        reducers: {},
    })
);

loadSelect2AutocompleteType();
laodConditionizeExtension();
loadDateOffsetTypeExtension();
loadCopyInputValueExtension();
loadTicketMessages();
