import React, {ImgHTMLAttributes} from 'react';
import classNames from 'classnames/bind';
import styles from './SidenavLogo.module.scss';
import {useSidenavContext} from '../Sidenav/useSidenavContext';

const cx = classNames.bind(styles);

export type SidenavLogoProps = ImgHTMLAttributes<HTMLImageElement>;

// eslint-disable-next-line jsx-a11y/alt-text
export const SidenavLogo = (props: SidenavLogoProps) => {
    const {isExpanded} = useSidenavContext();
    const classes = cx('sidenavLogo', {
        'sidenavLogo--expanded': isExpanded,
    });
    return <img {...props} className={classes} alt="Logo" />;
};
