import {ConversationComment} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';

export interface AangemaaktDoorReactieProps {
    naam: string;
    image?: string | null | undefined;
}

export const AangemaaktDoorReactie = ({naam, image}: AangemaaktDoorReactieProps) => {
    const {t} = useTranslation('Taken.nl');
    return (
        <ConversationComment avatarUrl={image} name={naam}>
            {t('Aangemaakt door', {naam})}
        </ConversationComment>
    );
};
