import {useTypedSearchParams} from '@growthbase/routing';
import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {object, string} from 'yup';
import {IStandaardWeergaveMijnDag} from '@growthbase/graphql';

const schema = object({
    view: string().required(),
});

// eslint-disable-next-line no-shadow
export const enum MijnTakenFilter {
    mijnTaken,
    mijnDag,
    prive,
    snoozed,
}

/**
 * Stores the current view in the URL
 */
export const useTakenSidelinksFilters = (
    actieveMijnTakenTeller?: IStandaardWeergaveMijnDag
): [MijnTakenFilter, (change: MijnTakenFilter) => void] => {
    const {t} = useTranslation('Taken.nl');

    const options: Record<string, MijnTakenFilter> = useMemo(
        () => ({
            [t('taak.mijnTaken')]: MijnTakenFilter.mijnTaken,
            [t('taak.mijnDag')]: MijnTakenFilter.mijnDag,
            [t('taak.mijnPriveTaken')]: MijnTakenFilter.prive,
            [t('taak.snoozed')]: MijnTakenFilter.snoozed,
        }),
        [t]
    );

    const fallbackValue = Object.keys(options)[0];
    const storedValue =
        actieveMijnTakenTeller === IStandaardWeergaveMijnDag.AlMijnTaken
            ? Object.keys(options)[MijnTakenFilter.mijnTaken]
            : Object.keys(options)[MijnTakenFilter.mijnDag];

    const view = storedValue ?? fallbackValue;

    const [currentRoute, setCurrentRoute] = useTypedSearchParams<{view: string}>('mt', schema, {view});

    return [
        options[currentRoute.view] ?? MijnTakenFilter.mijnTaken,
        useCallback(
            (change: MijnTakenFilter) => {
                setCurrentRoute({view: Object.keys(options)[change]});
            },
            [options, setCurrentRoute]
        ),
    ];
};
