import {createContext, ReactNode, FC} from 'react';
import {TabContentWrapperProps} from './Components';

export interface QueryTabsProps {
    name: string;
    defaultValue: string;
}

export interface TabsContextState {
    query?: QueryTabsProps;
    sizeSmall?: boolean;
    ContentWrapper?: FC<TabContentWrapperProps>;
    setContent: (node: ReactNode) => void;
    onQueryChange?: (value: string) => void;
}

export const TabsContext = createContext<TabsContextState>(null as unknown as TabsContextState);
