import {useContext, useEffect, useState} from 'react';
import {interval, noop} from 'rxjs';
import {ScrollContext} from '../ScrollContext';

export const useScrollSelector = () => {
    const className = useContext(ScrollContext);
    return className ? `.${className}` : 'window';
};

const findElement = (className: string): Element | null => {
    const found = document.querySelector(className);
    return found ?? null;
};

/**
 * Find the html element on the page.
 */
export const useScrollElement = (): Element | null => {
    const className = useScrollSelector();
    const [element, setElement] = useState<Element | null>(findElement(className));

    useEffect(() => {
        const found = findElement(className);
        if (found && found === element) {
            return noop;
        }
        if (found) {
            setElement(found);
            return noop;
        }

        if (element && element) {
            return noop;
        }
        const subscription = interval(1).subscribe(() => {
            const f = findElement(className);
            if (!f) {
                return;
            }
            setElement(f);
            subscription.unsubscribe();
        });
        return () => subscription.unsubscribe();
    }, [className, element, setElement]);

    return element;
};
