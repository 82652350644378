import {FC, HTMLAttributes, PropsWithChildren, ReactNode} from 'react';
import classNames from 'classnames/bind';
import styles from './SwimlaneHeader.module.scss';

const cx = classNames.bind(styles);
export interface SwimlaneHeaderProps extends PropsWithChildren {
    titel: string;
    anchor?: HTMLAttributes<HTMLElement> | undefined;
    menu?: ReactNode;
}

export const SwimlaneHeader: FC<SwimlaneHeaderProps> = ({titel, children, anchor, menu}) => {
    const classes = cx('swimlaneHeader');

    return (
        <div className={classes}>
            <div className={cx('swimlaneHeader__bar')} />
            <div className={cx('swimlaneHeader__content')} {...anchor}>
                <div className={cx('swimlaneHeader__title')}>{titel}</div>
                <div className={cx('swimlaneHeader__actions')}>{menu}</div>
            </div>
        </div>
    );
};
