import React, {ReactNode, FC} from 'react';
import classNames from 'classnames/bind';
import styles from './SidenavLinkTitle.module.scss';

const cx = classNames.bind(styles);

export interface SidenavLinkTitleProps {
    children: ReactNode;
    alignWithSubnav?: boolean;
    isSubtitle?: boolean;
}

export const SidenavLinkTitle: FC<SidenavLinkTitleProps> = ({children, alignWithSubnav, isSubtitle}) => {
    const classes = cx('sidenavLinkTitle', {
        'sidenavLinkTitle--alignWithSubnav': alignWithSubnav,
        'sidenavLinkTitle--subtitle': isSubtitle,
    });
    return <div className={classes}>{children}</div>;
};
