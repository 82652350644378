import {ActiveItemRegionContainer, KeysProvider} from '@growthbase/keys';
import React, {FC, PropsWithChildren} from 'react';
import classNames from 'classnames/bind';
import styles from './Page.module.scss';
import {ScrollContext, useUniqueClassname} from '../PaginationInfiniteScroll';

const cx = classNames.bind(styles);

export const TestPage: FC<PropsWithChildren> = ({children}) => {
    const uniqueClassName = useUniqueClassname();
    return (
        <ActiveItemRegionContainer name="TestPage">
            <KeysProvider name="page">
                <div className={cx('page')}>
                    <div className={cx('page__main')}>
                        <div className={cx('page__content', uniqueClassName)}>
                            <ScrollContext.Provider value={uniqueClassName}>{children}</ScrollContext.Provider>
                        </div>
                    </div>
                </div>
            </KeysProvider>
        </ActiveItemRegionContainer>
    );
};
