import {FC} from 'react';
import {normalizeOpgemaakteTekstGraphQLResult, useGebruikerHeeftToegang, usePopup} from '@growthbase/design-components';
import {useBordEditFormQuery, useEditBordFormMutation} from '@growthbase/graphql';
import {useNormalizeBordOnderdeelVanFragmentFactory} from '@growthbase/takenlijst/bord';
import {useCallbackRef} from '@growthbase/spa';
import {EditForm} from './EditForm';
import {mapDTOToGraphQL} from './mapDTOToGraphQL';
import {createInitialFormValues, EditFormDTO} from './Hook/useEditSchema';
import {ModelHeaderConnected} from './Components/ModelHeader/ModelHeaderConnected';

export interface EditFormConnectedProps {
    bordId: string;
}

export const EditFormConnected: FC<EditFormConnectedProps> = ({bordId}) => {
    const {data} = useBordEditFormQuery({
        variables: {
            id: bordId,
        },
    });

    const fragment = data?.takenBord ?? null;

    const [mutateAsync] = useEditBordFormMutation();
    const popup = usePopup();
    const onSubmit = useCallbackRef((value: EditFormDTO) =>
        mutateAsync({
            variables: {
                input: mapDTOToGraphQL(bordId, value),
            },
        })
            .then(popup.close)
            .then()
    );

    const normalizeBordOnderdeelVanFragment = useNormalizeBordOnderdeelVanFragmentFactory();

    const toegang = useGebruikerHeeftToegang({
        toegang: fragment?.availableActions?.kanBekijken,
    });

    const initialValues = useCallbackRef((): EditFormDTO => {
        if (!fragment) {
            throw new Error('Fragment is null');
        }
        const empty = createInitialFormValues();
        const normalized = normalizeOpgemaakteTekstGraphQLResult(fragment.omschrijving?.opgemaakteTekst);
        const deelnemers = fragment.deelnemers ?? [];
        return {
            onderdeelVan: normalizeBordOnderdeelVanFragment(fragment),
            naam: {
                elements: [
                    {
                        type: 'p',
                        children: [
                            {
                                text: fragment.naam,
                            },
                        ],
                    },
                ],
            },
            deelnemers: deelnemers.map((deelnemer) => {
                if (!deelnemer) {
                    throw new Error('Deelnemer is null');
                }
                return {
                    ...deelnemer.gebruiker,
                    selected: true,
                };
            }),
            zichtbaarVoor: fragment.zichtbaarVoor?.zichtbaarVoor ?? empty.zichtbaarVoor,
            omschrijving: normalized,
            relatie: {},
        };
    });
    if (!toegang || !fragment) {
        return null;
    }
    return (
        <EditForm
            renderHeader={() => <ModelHeaderConnected fragment={fragment} />}
            {...fragment.availableActions}
            createInitialValues={initialValues}
            onEdit={onSubmit}
        />
    );
};
