import {idFromIri} from '@growthbase/routing';
import {useSocketEvent} from '@growthbase/websockets';
import * as yup from 'yup';
import {BaseNode} from '../baseNode';

export const InvalidateFieldSchema = yup
    .object({
        name: yup.string().required(),
        args: yup.object(),
    })
    .label('InvalidateFieldSchema')
    .required();

const InvalidateFieldsSchema = yup
    .object({
        id: yup.string().required(),
        typename: yup.string().required(),
        fields: yup.array(InvalidateFieldSchema).required(),
    })
    .label('InvalidateFieldsSchema')
    .required();

export interface ExternalEntityInvalidatedField<TNode extends BaseNode, Tkey extends keyof TNode = keyof TNode> {
    name: Tkey;
    args: Record<string, unknown>;
}

export interface ExternalEntityFieldsInvalidatedArguments<TNode extends BaseNode> {
    id: string;
    typename: string;
    /**
     * Empty node.
     */
    node: Partial<TNode>;

    invalidation: ExternalEntityInvalidatedField<TNode>[];

    fields: (keyof TNode)[];
}

export const useExternalEntityFieldsInvalidated = <TNode extends BaseNode>(
    callback: (args: ExternalEntityFieldsInvalidatedArguments<TNode>) => void
) => {
    useSocketEvent('InvalidateFields', (data) => {
        const normalized = InvalidateFieldsSchema.validateSync(data);

        callback({
            ...normalized,
            fields: normalized.fields.map(({name}) => name),
            invalidation: normalized.fields as unknown as ExternalEntityInvalidatedField<TNode>[],
            node: {
                id: idFromIri(normalized.id),
            },
        } as ExternalEntityFieldsInvalidatedArguments<TNode>);
    });
};
