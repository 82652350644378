import {ViewConfig} from '@growthbase/design-components';
import {onDropItemHandler, onRemoveItemHandler} from '@growthbase/keys';
import {PaginationHelpers} from '@growthbase/spa';
import {RenderViewType} from './Components';
import {ContainerData, ItemData, TaakFilters, ViewType} from './types';

export interface OverviewProps<TLijst extends ItemData = ItemData, TContainerData extends ContainerData = ContainerData>
    extends ViewConfig {
    pagination: PaginationHelpers<TLijst>;
    userData: TContainerData;
    onDrop: onDropItemHandler<TLijst, TContainerData>;
    onRemove?: onRemoveItemHandler<TLijst>;
    filters: TaakFilters;
    view: ViewType;
    renderLijstType: Record<ViewType, RenderViewType<TLijst, TContainerData>>;
}

export function Overview<TLijst extends ItemData = ItemData, TContainerData extends ContainerData = ContainerData>({
    view,
    renderLijstType,
    ...props
}: OverviewProps<TLijst, TContainerData>) {
    return <>{renderLijstType[view](props)}</>;
}
