import {createTheme, ThemeProvider} from '@mui/material';
import {ReactNode} from 'react';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {CBTheme} from '@growthbase/design-elements';
import {enUS, nlNL} from '@mui/material/locale';
import {AutoComplete, TextField, InputLabel} from './components';

const lang = navigator.language === 'nl' ? nlNL : enUS;

let legacyTheme = createTheme({
    options: {
        separateLabel: true,
    },
    components: {
        ...AutoComplete,
        ...TextField,
        ...InputLabel,
    },
    typography: {
        body1: {
            fontSize: 13.2,
        },
    },
    palette: {
        primary: {
            main: '#014544',
        },
        secondary: {
            main: '#ff9518',
        },
        border: {
            default: '#dfe3e9',
        },
        background: {
            default: '#f3f3f3',
        },
    },
    shape: {
        borderRadius: 3,
    },
    zIndex: {
        modal: 10000300,
        snackbar: 10000400,
        tooltip: 10000500,
    },
});

legacyTheme = createTheme(
    legacyTheme as unknown as CBTheme,
    {
        palette: {
            primaryLight: legacyTheme.palette.augmentColor({
                color: {
                    main: '#029C99',
                },
                name: 'primaryLight',
            }),
        },
    },
    lang
);

export const LegacyTheme = ({children}: {children: ReactNode}) => (
    <ThemeProvider theme={legacyTheme}>
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="nl">
            {children}
        </LocalizationProvider>
    </ThemeProvider>
);
