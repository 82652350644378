import {Font, formatDurationDigital, Padding, Stack} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';
import React from 'react';

export interface StatFooterProps {
    planned: number;
    booked: number;
    budget: number;
    remaining: number;
}

export const StatFooter = ({booked, planned, budget, remaining}: StatFooterProps) => {
    const {t} = useTranslation('OpdrachtenPlanning.nl');

    return (
        <div style={{background: remaining <= 0 && planned > 0 ? 'var(--colorSuccessLight)' : 'transparent'}}>
            <Padding paddingSize="large">
                <Stack gap="large" horizontal testId="PlanningStatFooter">
                    <Stack.Item>
                        <Font.Gray>
                            {t('Gepland')}: {formatDurationDigital(planned)}
                        </Font.Gray>
                    </Stack.Item>
                    <Stack.Item>
                        <Font.Gray>
                            {t('Geboekt')}: {formatDurationDigital(booked)}
                        </Font.Gray>
                    </Stack.Item>
                    <Stack.Item>
                        <Font.Gray>
                            {t('Budget')}: {budget > 0 ? formatDurationDigital(budget) : t('Niet van toepassing')}
                        </Font.Gray>
                    </Stack.Item>
                    <Stack.Item>
                        {t('Resterend')}: {formatDurationDigital(remaining)}
                    </Stack.Item>
                </Stack>
            </Padding>
        </div>
    );
};
