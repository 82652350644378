import {PaginationHelpers, usePaginationMapper} from '@growthbase/spa';
import React, {useCallback} from 'react';
import {IBordtemplateTabelFragment} from '@growthbase/graphql';

import {ViewConfig} from '@growthbase/design-components';
import {Link} from 'react-router-dom';
import {BordtemplateRoutes} from '@growthbase/takentemplates-bordtemplate';
import {CardData, Tabel} from './Tabel';
import {ActionMenuConnected} from '../../../ActionMenu';

export interface TabelConnectedProps extends ViewConfig {
    pagination: PaginationHelpers<IBordtemplateTabelFragment>;
}

export function TabelConnected({pagination, readonly, disabled}: TabelConnectedProps) {
    const routeFactory = BordtemplateRoutes.bekijken.useUrlFactory();
    const mapper = useCallback(
        (fragment: IBordtemplateTabelFragment): CardData => ({
            id: fragment.id,
            actions: (
                <ActionMenuConnected key={fragment.id} disabled={disabled} readonly={readonly} fragment={fragment} />
            ),
            naam: (
                <Link
                    to={routeFactory({
                        bordtemplateId: fragment.id,
                    })}
                >
                    {fragment.naam}
                </Link>
            ),
        }),
        [disabled, readonly, routeFactory]
    );

    const convertedPagination = usePaginationMapper(pagination, mapper);

    return <Tabel pagination={convertedPagination} />;
}
