import {ListPositie, useExternalEntityAdded} from './MessageListener/useExternalEntityAdded';
import {useExternalEntityChanged} from './MessageListener/useExternalEntityChanged';
import {useExternalEntityRemoved} from './MessageListener/useExternalEntityRemoved';
import {useExternalEntityFieldsInvalidated} from './MessageListener/useExternalEntityFieldsInvalidated';
import {
    EntityOrderChangedSchemaDto,
    useExternalEntityChangeOrder,
} from './MessageListener/useExternalEntityChangeOrder';
import {BaseNode} from './baseNode';

/* eslint-disable no-shadow */
export enum ChangeType {
    add,
    remove,
    changed,
    invalidate,
    changeOrder,
}

type EntityOrderDetermineActionParams = {
    type: ChangeType.changeOrder;
} & EntityOrderChangedSchemaDto;

type CommonDetermineActionParams<TNode extends BaseNode> = {
    type: ChangeType.add | ChangeType.remove | ChangeType.changed | ChangeType.invalidate;
    id: string;
    typename: string;
    node: Partial<TNode>;
    fields: (keyof TNode)[];
    positie?: ListPositie | null;
    data?: unknown;
};

export type DetermineActionParams<TNode extends BaseNode> =
    | CommonDetermineActionParams<TNode>
    | EntityOrderDetermineActionParams;

const useWrapWithType =
    <TNode extends BaseNode>(type: ChangeType, doQueryUpdate: (options: DetermineActionParams<TNode>) => void) =>
    (args: object) =>
        doQueryUpdate({
            type,
            ...args,
        } as DetermineActionParams<TNode>);

export const useHandleExternalEntityChanges = <TNode extends BaseNode>(
    doQueryUpdate: (options: DetermineActionParams<TNode>) => void
) => {
    useExternalEntityAdded<TNode>(useWrapWithType(ChangeType.add, doQueryUpdate));
    useExternalEntityRemoved<TNode>(useWrapWithType(ChangeType.remove, doQueryUpdate));
    useExternalEntityChanged<TNode>(useWrapWithType(ChangeType.changed, doQueryUpdate));
    useExternalEntityFieldsInvalidated(useWrapWithType(ChangeType.invalidate, doQueryUpdate));
    useExternalEntityChangeOrder(useWrapWithType(ChangeType.changeOrder, doQueryUpdate));
};
