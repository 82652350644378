import {SearchForm, useGebruikerHeeftToegang, useSearchField} from '@growthbase/design-components';
import {ILijsttemplateTabelFragment, useLijsttemplateTabelQuery} from '@growthbase/graphql';
import {usePaginationSearchParams} from '@growthbase/routing';
import {FC} from 'react';
import {useEntityChanges} from './Hook/useEntityChanges';
import {Tabel} from './Tabel';
import {LijsttemplateRoutes} from '../LijsttemplateRoutes';

export const TabelConnected: FC = () => {
    const {pagination, usePagination} = usePaginationSearchParams<ILijsttemplateTabelFragment>('lijsttemplates');
    const [searchQuery, setSearchQuery] = useSearchField('lijsttemplate');
    const result = useLijsttemplateTabelQuery({
        variables: {
            ...pagination,
            query: searchQuery.query,
        },
    });
    useEntityChanges({result, searchQuery: searchQuery.query});
    const toegang = useGebruikerHeeftToegang({
        toegang: result?.data?.ingelogdeGebruiker?.availableActions?.kanTakenBekijken,
    });
    const pager = usePagination(result);
    const onClickCreate = LijsttemplateRoutes.aanmaken.useNavigate();
    if (!toegang) {
        return null;
    }
    return (
        <Tabel
            onClickCreate={onClickCreate}
            pagination={pager}
            zoekveld={<SearchForm loading={result.loading} onChange={setSearchQuery} value={searchQuery} />}
        />
    );
};
