/* eslint-disable no-use-before-define */

import {
    IChildElementFragment,
    IOpgemaakteTekstEditorFragment,
    IParentElementFragment,
    ISlateJsType,
} from '@growthbase/graphql';
import {useMemo} from 'react';
import {OpgemaakteTekst, OpgemaakteTekstElement} from '../Hook/useOpgemaakteTekstSchema';
import {normalizeValues} from './useNormalizeValues';

export const normalizeOpgemaakteTekstGraphQLResult = (
    value?: IOpgemaakteTekstEditorFragment | null
): OpgemaakteTekst => {
    const elements: Record<number, IChildElementFragment | IParentElementFragment> = (value?.elements ?? []).map(
        (element) => ({
            ...element,
        })
    );

    const deNormalized: OpgemaakteTekstElement[] = [];
    for (const element of Object.values(elements)) {
        if ('children' in element) {
            // @ts-expect-error This is correct
            element.children = element.children.map((child) => elements[child]);
        }
        const copy: object = element;

        if (!('parentIndex' in element) || element.parentIndex === null || element.parentIndex === undefined) {
            // @ts-expect-error This is correct
            deNormalized.push(copy);
        }
        if ('parentIndex' in copy) {
            delete copy.parentIndex;
        }
        if ('__typename' in copy) {
            // eslint-disable-next-line no-underscore-dangle
            delete copy.__typename;
        }
        if ('type' in copy && copy.type === ISlateJsType.Text) {
            delete copy.type;
        }
    }

    return {
        elements: normalizeValues(deNormalized),
    };
};

export const useNormalizeOpgemaakteTekstGraphQLResult = (
    value?: IOpgemaakteTekstEditorFragment | null
): OpgemaakteTekst => useMemo(() => normalizeOpgemaakteTekstGraphQLResult(value), [value]);
