import {useMemo} from 'react';
import {IToegewezenBudgetFragment} from '@growthbase/planning';

export const useTotalBudget = (budgetten: IToegewezenBudgetFragment[]) =>
    useMemo(
        () =>
            budgetten.reduce(
                (previousValue, currentValue) =>
                    previousValue + currentValue.toegewezenSeconden - currentValue.geplandeSeconden,
                0
            ),
        [budgetten]
    );
