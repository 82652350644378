/* eslint-disable */
import {Button, Buttons, Stack} from '@growthbase/design-components';
import {FC, PropsWithChildren} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, FieldInstance} from '../contract';
import {createFieldInstance, flattenConditions} from '../Utils';
import {Condition} from './Condition';

export interface ConditionsProps<T = unknown> extends PropsWithChildren {
    fields: Field<T>[];
    values: FieldInstance[];
    onChange: (value: FieldInstance[]) => void;
    nestingLevel?: number;
}

export const Conditions: FC<ConditionsProps> = ({values, onChange, fields, nestingLevel = 0, children}) => {
    const {t} = useTranslation('querybuilder.nl');
    return (
        <Stack gap="small">
            {values.map((value) => (
                <Stack.Item key={value.id}>
                    <Condition
                        nestingLevel={nestingLevel}
                        key={value.id}
                        remove={(value) => {
                            onChange(values.filter((v) => v.id !== value.id));
                        }}
                        fields={fields as unknown as Field[]}
                        value={value}
                        onChange={(value) => {
                            const index = values.findIndex((v) => v.id === value.id);
                            if (index >= 0) {
                                const newValues = [...values];
                                newValues[index] = flattenConditions(value);
                                onChange(newValues);
                            }
                        }}
                    />
                </Stack.Item>
            ))}
            <Stack.Item grow>
                <Stack horizontal gap="small" stretch>
                    <Stack.Item>
                        <Buttons.Add
                            onClick={() => {
                                onChange([...values, createFieldInstance({fields}, fields[0])]);
                            }}
                        >
                            {t('add')}
                        </Buttons.Add>
                    </Stack.Item>
                    {values.length !== 0 && (
                        <Stack.Item>
                            <Button
                                onClick={() => {
                                    onChange([]);
                                }}
                            >
                                {t('removeFilters')}
                            </Button>
                        </Stack.Item>
                    )}
                    {children}
                </Stack>
            </Stack.Item>
        </Stack>
    );
};
