import {useMemo} from 'react';

export const useAutoContrastColor = (color: string, threshold: number | undefined = 0.4) =>
    useMemo(() => {
        const r = parseInt(color.substring(1, 3), 16);
        const g = parseInt(color.substring(3, 5), 16);
        const b = parseInt(color.substring(5, 7), 16);
        const yiq = (r * 299 + g * 587 + b * 114) / 1000;
        return yiq >= 128 ? 'black' : 'white';
    }, [color]);
