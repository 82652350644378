import {Menu, Item, Submenu, ItemParams, BooleanPredicate} from 'react-contexify';
import 'react-contexify/ReactContexify.css';
import {BodyPortal} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';
import {useCallbackRef} from '@growthbase/spa';
import styles from './PlanningContextMenu.module.scss';

import {PLANNING_CONTEXT_MENU_ID} from './usePlanningContextMenu';

export const PlanningColors = [
    // Taken from design.
    '#05a28a',
    '#cc3f0a',
    '#0b254a',
    '#9a1a63',
    '#ffb45d',
    '#1abc9c',
    '#16a085',
    '#2ecc71',
    '#27ae60',
    '#3498db',
    '#2980b9',
    '#9b59b6',
    '#8e44ad',
    '#34495e',
    '#2c3e50',
    '#f1c40f',
    '#f39c12',
    '#e67e22',
    '#d35400',
    '#e74c3c',
    '#c0392b',
];

export interface PlanningContextMenuProps {
    handleColorClick?: ({props, data}: ItemParams) => void;
    handleClick?: ({props, data}: ItemParams) => void;
}

export const PlanningContextMenu = ({handleClick, handleColorClick}: PlanningContextMenuProps) => {
    const {t} = useTranslation('spa.nl');

    const hidePublic: BooleanPredicate = useCallbackRef(
        ({props}) => !props.event.isPrivate || !props.event.editable || props.event.timeRegistrationParent
    );
    const hidePrivate: BooleanPredicate = useCallbackRef(
        ({props}) => props.event.isPrivate || !props.event.editable || props.event.timeRegistrationParent
    );
    const hideColors: BooleanPredicate = useCallbackRef(({props}) => props.event.timeRegistrationParent);
    const disabled: BooleanPredicate = useCallbackRef(({props}) => !props.event.editable);

    return (
        <BodyPortal>
            <Menu id={PLANNING_CONTEXT_MENU_ID} className={styles.PlanningContextMenu} animation={false}>
                <Item id="edit" onClick={handleClick} disabled={disabled}>
                    {t('planning.context.bewerken')}
                </Item>
                <Item id="delete" onClick={handleClick} disabled={disabled}>
                    {t('planning.context.verwijderen')}
                </Item>
                <Item hidden={hidePrivate} id="private" onClick={handleClick}>
                    {t('planning.context.prive')}
                </Item>
                <Item hidden={hidePublic} id="public" onClick={handleClick}>
                    {t('planning.context.openbaar')}
                </Item>
                <Submenu
                    label={t('planning.context.kleur_kiezen')}
                    className={styles.PlanningContextMenu__ColorMenu}
                    hidden={hideColors}
                    disabled={disabled}
                >
                    {PlanningColors.map((color) => (
                        <Item key={color} onClick={handleColorClick} data={{color}}>
                            <div className={styles.PlanningContextMenu__ColorBlock} style={{backgroundColor: color}} />
                        </Item>
                    ))}
                </Submenu>
            </Menu>
        </BodyPortal>
    );
};
