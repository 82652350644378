import {ITextFragment} from '@growthbase/graphql';
import React, {memo, ReactNode} from 'react';
import styles from './ReadonlyOpgemaakteTekst.module.scss';
import {OpgemaakteTekst, OpgemaakteTekstElement} from '../../Hook/useOpgemaakteTekstSchema';
import {ReadOnlyRenderer, useReadOnlyComponents} from '../../Plugins/useOpgemaakteTextPlugins';

export interface ReadonlyOpgemaakteTekstProps {
    value: OpgemaakteTekst;
}

/* eslint-disable no-use-before-define */
function render(element: OpgemaakteTekstElement, renderer: ReadOnlyRenderer): ReactNode {
    if (!element) {
        return null;
    }
    const parentElement = element as OpgemaakteTekstElement;

    if (!('type' in parentElement)) {
        const text = element as ITextFragment;
        const style: React.CSSProperties = {
            whiteSpace: 'pre-wrap',
            fontWeight: text.bold ? 'bold' : undefined,
            fontStyle: text.italic ? 'italic' : undefined,
            textDecoration: text.underline ? 'underline' : undefined,
        };
        return <span style={style}>{text.text}</span>;
    }

    const children = () => renderElements(parentElement.children, renderer);

    switch (parentElement.type) {
        case 'a':
            return (
                <a href={parentElement.url} target="_blank" rel="noreferrer">
                    {children()}
                </a>
            );
        case 'code_block':
            return <code>{children()}</code>;
        case 'code_line':
            return <code>{children()}</code>;
        case 'h1':
            return <h1>{children()}</h1>;
        case 'h2':
            return <h2>{children()}</h2>;
        case 'h3':
            return <h3>{children()}</h3>;
        case 'ul':
            return <ul>{children()}</ul>;
        case 'li':
            return <li>{children()}</li>;
        case 'lic':
            return <>{children()}</>;
        case 'ol':
            return <ol>{children()}</ol>;
        case 'p':
            return <p>{children()}</p>;
        case 'blockquote':
        default: {
            const result = renderer(parentElement, children);
            if (result) {
                return result;
            }
            return <span>[Unsupported readonly element {parentElement.type}]</span>;
        }
    }

    return null;
}

function renderElements(elements: OpgemaakteTekstElement[], renderer: ReadOnlyRenderer): ReactNode {
    return (
        <>
            {elements.map((el, index) => (
                <React.Fragment key={index}>{render(el, renderer)}</React.Fragment>
            ))}
        </>
    );
}

export const ReadonlyOpgemaakteTekst = memo(({value}: ReadonlyOpgemaakteTekstProps) => {
    const renderer = useReadOnlyComponents();
    return <div className={styles.rendered}>{renderElements(value.elements ?? [], renderer)}</div>;
});

ReadonlyOpgemaakteTekst.displayName = 'ReadonlyOpgemaakteTekst';
