import {FC, ReactNode} from 'react';
import classNames from 'classnames/bind';
import {FieldArray, useField} from 'formik';
import styles from './ListField.module.scss';
import {FieldWrapperControlled, FormikFieldProps} from '../FieldWrapper';
import {Buttons} from '../../../Button';

const cx = classNames.bind(styles);

export interface ArrayHelpers<T> {
    /** Imperatively add a value to the end of an array */
    push: (obj: T) => void;
    /** Curried fn to add a value to the end of an array */
    handlePush: (obj: T) => () => void;
    /** Imperatively swap two values in an array */
    swap: (indexA: number, indexB: number) => void;
    /** Curried fn to swap two values in an array */
    handleSwap: (indexA: number, indexB: number) => () => void;
    /** Imperatively move an element in an array to another index */
    move: (from: number, to: number) => void;
    /** Imperatively move an element in an array to another index */
    handleMove: (from: number, to: number) => () => void;
    /** Imperatively insert an element at a given index into the array */
    insert: (index: number, value: T) => void;
    /** Curried fn to insert an element at a given index into the array */
    handleInsert: (index: number, value: T) => () => void;
    /** Imperatively replace a value at an index of an array  */
    replace: (index: number, value: T) => void;
    /** Curried fn to replace an element at a given index into the array */
    handleReplace: (index: number, value: T) => () => void;
    /** Imperatively add an element to the beginning of an array and return its length */
    unshift: (value: T) => number;
    /** Curried fn to add an element to the beginning of an array */
    handleUnshift: (value: T) => () => void;
    /** Curried fn to remove an element at an index of an array */
    handleRemove: (index: number) => () => void;
    /** Curried fn to remove a value from the end of the array */
    handlePop: () => () => void;
    /** Imperatively remove and element at an index of an array */
    remove(index: number): T | undefined;
    /** Imperatively remove and return value from the end of the array */
    pop(): T | undefined;
}

export type ListFieldAddProps<T> = {current: T[]; helpers: ArrayHelpers<T>; disabled: boolean};

/**
 * Value in the format of hh:mm
 */
export interface ListFieldProps<TSchema, T> {
    name: keyof TSchema;
    Component: FC<FormikFieldProps<T>>;

    /**
     * Component is not an input field, just layout only
     */
    readonly?: boolean;
    /**
     * Component is disabled, no interaction is possible, but it can still be the input of a form
     */
    disabled?: boolean;

    renderAddField: (props: ListFieldAddProps<T>) => ReactNode;
}

export function ListField<TSchema, T>({
    name,
    Component,
    readonly,
    disabled,
    renderAddField,
}: ListFieldProps<TSchema, T>) {
    const [
        {value},
        {error, touched},
    ] = useField<T[]>(name as string);

    const classes = cx('ListField', {
        'textField--hasError': touched && error,
        'textField--isReadonly': readonly,
        'textField--isDisabled': disabled,
    });

    return (
        <FieldArray
            name={name as string}
            render={(helpers) => (
                <div className={classes}>
                    {value &&
                        value.length > 0 &&
                        value.map((val, index) => {
                            // @ts-expect-error Cannot use the name type.
                            const field = <FieldWrapperControlled component={Component} name={`${name}.${index}`} />;
                            return (
                                <div key={index} className={cx('ListField__item')}>
                                    {field}
                                    {!readonly && (
                                        <Buttons.Cross
                                            buttonType="button"
                                            disabled={disabled}
                                            onClick={() => helpers.remove(index)}
                                        />
                                    )}
                                </div>
                            );
                        })}
                    {!readonly &&
                        renderAddField({
                            current: value,
                            helpers,
                            disabled: !!disabled,
                        })}
                </div>
            )}
        />
    );
}
