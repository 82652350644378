import {useIsIntegration} from '@growthbase/spa';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useBeforeUnload} from 'react-use';
import {useOnCloseEvents} from '../../Popups';
import {StrictFormOptions} from './useCreateStrictForm';

/**
 * Wait when user follows an urls or tries to close a popup.
 */
export const useLockPopupAndRouting = <T extends object>({formik}: StrictFormOptions<T>, enabled: boolean): void => {
    const {t} = useTranslation('spa_form.nl');
    const {dirty, isSubmitting} = formik;

    useOnCloseEvents(
        useCallback(
            (event) => {
                if (!enabled) {
                    return;
                }
                if (dirty || isSubmitting) {
                    event.cancel = true;
                }
            },
            [enabled, dirty, isSubmitting]
        )
    );

    const message = t('YouHaveUnsavedChanges');
    /**
     * During behat browser testing this would fail the browser to close the window.
     */
    const isIntegrationEnv = useIsIntegration();
    useBeforeUnload(!isIntegrationEnv && enabled && dirty, message);
};
