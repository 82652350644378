import classNames from 'classnames/bind';
import {capitalize} from 'lodash';
import React, {FC, memo, ReactNode, useMemo} from 'react';
import {Plate, PlatePlugin, Value} from '@udecode/plate';

import {useNormalizeValues} from '../Hook/useNormalizeValues';
import {useOpgemaakteTextPlugins, useOpgemaakteTextSingleLinePlugins} from '../Plugins/useOpgemaakteTextPlugins';
import {OpgemaaktetekstToolbar} from './OpgemaaktetekstToolbar/OpgemaaktetekstToolbar';
import styles from './OpgemaakteTekstEditor.module.scss';
import {ComboBoxContextProvider} from './AsyncCombobox';
import {multipleLinePlugins} from './Plugin/multipleLinePlugins';
import {singleLinePlugins} from './Plugin/singleLinePlugins';
import {OpgemaakteTekstElement} from '../Hook/useOpgemaakteTekstSchema';

const cx = classNames.bind(styles);

export interface OpgemaakteTekstEditorProps {
    children?: ReactNode;
    variant?: 'default' | 'big' | 'text';
    borderVisible?: boolean;
    plateId: string;
    value: OpgemaakteTekstElement[];
    disabled?: boolean;
    readonly?: boolean;
    focused?: boolean;
    floatingToolbar?: boolean;
    disableToolbar?: boolean;
    plugins?: PlatePlugin[];
    isSubmitting?: boolean;
    placeholder?: string;
    singleLine?: boolean;
    setValue?: (values: OpgemaakteTekstElement[]) => void;
}

export const OpgemaakteTekstEditor: FC<OpgemaakteTekstEditorProps> = memo(
    ({
        children,
        setValue,
        plateId,
        readonly,
        value: inputValue,
        plugins,
        floatingToolbar = true,
        disabled,
        focused,
        placeholder,
        isSubmitting,
        variant,
        singleLine = false,
        disableToolbar = false,
        borderVisible = false,
    }) => {
        const editableProps = useMemo(
            () => ({
                placeholder: readonly ? undefined : placeholder,
                readOnly: disabled || readonly,
                className: cx('editor', {
                    'editor--disabled': disabled,
                    'editor--staticToolbar': !floatingToolbar,
                    'editor--active': focused || borderVisible,
                    'editor--submitting': isSubmitting,
                    'editor--singleLine': singleLine,
                    [`editor--variant${capitalize(variant)}`]: true,
                }),
            }),
            [
                disabled,
                focused,
                placeholder,
                readonly,
                singleLine,
                variant,
                isSubmitting,
                borderVisible,
                floatingToolbar,
            ]
        );
        const globalPlugins = useOpgemaakteTextPlugins();

        const globalSingleLinePlugins = useOpgemaakteTextSingleLinePlugins();

        return (
            <div className={cx('editor__outer')}>
                <ComboBoxContextProvider>
                    <Plate
                        id={plateId}
                        plugins={useMemo(() => {
                            const base = singleLine
                                ? [...singleLinePlugins, ...globalSingleLinePlugins]
                                : multipleLinePlugins;
                            return [...base, ...(plugins ?? []), ...globalPlugins];
                        }, [singleLine, plugins, globalPlugins, globalSingleLinePlugins])}
                        value={useNormalizeValues(inputValue) as Value}
                        editableProps={editableProps}
                        onChange={setValue ? (setValue as unknown as () => void) : undefined}
                        firstChildren={!floatingToolbar && <OpgemaaktetekstToolbar active isStatic />}
                    >
                        {children}
                        {floatingToolbar && (
                            <OpgemaaktetekstToolbar active={(focused || isSubmitting) && !disableToolbar} />
                        )}
                    </Plate>
                </ComboBoxContextProvider>
            </div>
        );
    }
);

OpgemaakteTekstEditor.displayName = 'OpgemaakteTekstEditor';
