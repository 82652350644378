import {FC} from 'react';
import {Optional} from 'utility-types';
import {VoegTaakToeFormStub} from './Components/VoegTaakToeForm';
import {Footer, FooterProps} from './Footer';

export interface FooterStubProps extends Optional<FooterProps> {
    titel?: string;
}

export const FooterStub: FC<FooterStubProps> = (props) => (
    <Footer form={<VoegTaakToeFormStub />} titel="Test" {...props} />
);
