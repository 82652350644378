import {FC} from 'react';
import {LoaderModal, RoutePopupProvider} from '@growthbase/design-components';
import {RouteElementProps, useHistoryBack} from '@growthbase/routing';
import {useEditModalPageConnectedQuery} from '@growthbase/graphql';
import {usePollInterval} from '@growthbase/websockets';
import {EditFormConnected} from '../EditForm/EditFormConnected';

export type EditModalPageConnectedProps = RouteElementProps<{
    lijstId?: string;
}>;

export const EditModalPageConnected: FC<EditModalPageConnectedProps> = ({params}: EditModalPageConnectedProps) => {
    const lijstId = params?.lijstId;
    if (!lijstId) {
        throw new Error('Missing lijstId');
    }

    const {data} = useEditModalPageConnectedQuery({
        pollInterval: usePollInterval(),
        variables: {
            lijstId,
        },
    });
    const lijst = data?.takenLijst;
    const goBack = useHistoryBack();
    if (!lijst) {
        return <LoaderModal />;
    }
    return (
        <RoutePopupProvider>
            <EditFormConnected onSubmit={() => Promise.resolve().then(goBack)} fragment={lijst} />
        </RoutePopupProvider>
    );
};
