import {PaginationHelpers} from '@growthbase/spa';
import React, {ReactNode} from 'react';
import {
    Table,
    TableHeader,
    TableHeaderSection,
    TableRowInterface,
    TableTitleWithCount,
} from '@growthbase/design-components';
import {Column} from 'react-table';
import {useTranslation} from 'react-i18next';

export interface CardData extends TableRowInterface {
    id: string;
    naam: ReactNode;
    actions: ReactNode;
}

export interface TabelProps {
    pagination: PaginationHelpers<CardData>;
}

const columns: ReadonlyArray<Column<CardData>> = [
    {
        Header: () => {
            const {t} = useTranslation('TakenlijstBord.nl');
            return <>{t('Tabel.Naam')}</>;
        },
        accessor: 'naam',
    },
    {
        Header: <div />,
        accessor: 'actions',
        id: 'actions',
        width: 'auto',
        minWidth: 90,
    },
];

export function Tabel({pagination}: TabelProps) {
    const {t} = useTranslation('Bordtemplate.nl');
    return (
        <Table<CardData>
            header={
                <TableHeader>
                    <TableHeaderSection>
                        <TableTitleWithCount
                            titel={t('Borden')}
                            count={
                                !pagination.isLoading || pagination.totalCount !== 0 ? pagination.totalCount : undefined
                            }
                        />
                    </TableHeaderSection>
                </TableHeader>
            }
            columns={columns}
            pagination={pagination}
        />
    );
}
