import {ForegroundDefaultTreeProvider, RoutesDTO} from '@growthbase/spa';
import * as React from 'react';
import {memo, FC} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import {GeenToegangModalConnected} from '@growthbase/design-components';
import {useMount} from 'react-use';
import {BackgroundRouteContext} from '../BackgroundRouteContext';
import {useBackgroundRouteLocation} from '../Hook';
import {RoutesConfig} from '../Value';
import {useRoutesRegistry} from '../Hook/useRoutesRegistry';

export const Routing: FC = () => {
    const {main, secondary} = useRoutesRegistry();
    const backgroundRouteLocation = useBackgroundRouteLocation();
    const location = useLocation();
    return (
        <>
            <BackgroundRouteContext.Provider value={backgroundRouteLocation}>
                <Routes location={backgroundRouteLocation ?? location}>
                    {main}
                    <Route path="*" element={null} />
                </Routes>
            </BackgroundRouteContext.Provider>
            <GeenToegangModalConnected>
                <ForegroundDefaultTreeProvider>
                    <Routes>
                        {secondary}
                        <Route path="*" element={null} />
                    </Routes>
                </ForegroundDefaultTreeProvider>
            </GeenToegangModalConnected>
        </>
    );
};

export function createReactRoutes<
    TRoutesKey extends keyof RoutesDTO,
    TRoutes extends RoutesDTO[TRoutesKey],
    TConfigs extends RoutesConfig<TRoutes>
>(routesKey: TRoutesKey, configs: TConfigs): FC {
    const NamedRoutes = memo(() => {
        const {addRoutes} = useRoutesRegistry();
        useMount(() => {
            // @ts-expect-error Can use this as component.
            addRoutes(routesKey, configs);
        });
        return <></>;
    });
    NamedRoutes.displayName = `${routesKey}Routes`;
    return NamedRoutes;
}
