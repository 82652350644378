import React, {FC, memo} from 'react';
import {AddBreadcrumb, AddBreadcrumbProps} from '@growthbase/design-components';
import {Tab} from './Tab';
import {TabInterface} from './TabInterface';

export const BreadcrumbTab: FC<TabInterface & AddBreadcrumbProps> = memo(
    ({order, asPageTitle = true, excludeForChildren, ...props}) => {
        const {title, children} = props;
        const to = 'to' in props ? props.to : null;
        return (
            <>
                <Tab to={to} {...props}>
                    {children}
                    {to && (
                        <AddBreadcrumb
                            excludeForChildren={excludeForChildren}
                            order={order}
                            to={to}
                            asPageTitle={asPageTitle}
                        >
                            {title}
                        </AddBreadcrumb>
                    )}
                </Tab>
            </>
        );
    }
);

BreadcrumbTab.displayName = 'Tab';
