import {FC, memo} from 'react';
import {CounterButton} from '@growthbase/design-components';
import {useSnoozedBadgeCountQuery} from '@growthbase/graphql';
import {useExternalEntityChanged} from '@growthbase/cache';

export const SnoozedBadgeCount: FC = memo(() => {
    const {data, refetch} = useSnoozedBadgeCountQuery();
    const totalCount = data?.items?.totalCount;
    useExternalEntityChanged(({typename}) => {
        if (typename === 'IsGesnoozedEdge') {
            refetch();
        }
    });
    if (totalCount === undefined) {
        return null;
    }
    return <CounterButton count={totalCount} />;
});

SnoozedBadgeCount.displayName = 'SnoozedBadgeCount';
