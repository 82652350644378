import {FC} from 'react';
import {Diff} from 'utility-types';
import * as React from 'react';
import {ComponentProps} from '../../../Hook';
import {FieldWrapperControlled, FormikFieldProps} from '../FieldWrapperControlled';

const index: FC[] = [];
const components: FC[] = [];

export const useCustomFieldComponent = <TValue, TExtra extends object>(
    Component: FC<TExtra>
): FC<{name: string} & ComponentProps & Diff<TExtra, FormikFieldProps<TValue>>> => {
    const i = index.indexOf(Component as FC);
    if (i !== -1) {
        return components[i] as FC;
    }
    const WithComponent = (props: object) => (
        // @ts-expect-error We are creating a new component, only for type safety.
        <FieldWrapperControlled {...props} component={Component} />
    );
    WithComponent.displayName = `WrappedWithComponent${index.length}`;

    index.push(Component as FC);
    components.push(WithComponent as FC);

    return WithComponent;
};
