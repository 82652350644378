import {ReactNode} from 'react';
import {Center, Tooltip} from '@growthbase/design-components';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {Stack} from '@mui/material';

export interface BijlagesTooltipProps<TBijlage extends {id: string}> {
    open: () => Promise<void>;
    bijlages: TBijlage[];
    render: (bijlage: TBijlage) => ReactNode;
    onShow?: () => void;
}

export function BijlagesTooltip<TBijlage extends {id: string}>({
    render,
    bijlages,
    onShow,
    open,
}: BijlagesTooltipProps<TBijlage>) {
    return (
        <Tooltip
            content={
                <Stack>
                    {bijlages.map((bijlage) => (
                        <Stack key={bijlage.id}>{render(bijlage)}</Stack>
                    ))}
                </Stack>
            }
            onShow={onShow}
        >
            <Center vertical>
                <AttachFileIcon onClick={open} style={{width: 18}} />
            </Center>
        </Tooltip>
    );
}
