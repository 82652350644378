import {BaseButtonProps, PopupAnchorProps, usePopup} from '@growthbase/design-components';
import {useSuccessiveCallbacks} from '@growthbase/spa';
import React, {ReactNode, useCallback} from 'react';
import {RelatieValue} from '@growthbase/relaties';
import {KoppelPopup, KoppelbaarItem, KoppelPopupProps} from '../../KoppelPopup';
import {KoppelButtonContent} from '../KoppelButtonContent';
import styles from './KoppelButton.module.scss';

export interface RenderKoppelButtonProps extends BaseButtonProps {
    item: KoppelbaarItem | null;
    onClear?: () => void;
}

export interface KoppelButtonProps extends Omit<KoppelPopupProps, 'renderAnchor' | 'onClickItem'> {
    readonly?: boolean;
    item: KoppelbaarItem | null;
    onChange: (item: KoppelbaarItem) => Promise<void>;
    onRemove: () => Promise<void>;
    onChangeRelation?: (value: RelatieValue | null) => void;
    renderKoppelButton?: (props: RenderKoppelButtonProps) => ReactNode;
}

export const defaultRenderKoppelButton = ({onClick, onClear, ...props}: RenderKoppelButtonProps) => (
    <KoppelButtonContent onRemove={onClear} onEdit={onClick} {...props} />
);

export const KoppelButton = ({
    onChange,
    disabled: disabledInput,
    readonly,
    item,
    onRemove,
    renderKoppelButton = defaultRenderKoppelButton,
    ...props
}: KoppelButtonProps) => {
    const {close} = usePopup();

    const handleChange = useSuccessiveCallbacks(onChange, close);

    const disabled = disabledInput;

    const render = useCallback(
        ({reference, controls: {open}}: PopupAnchorProps) =>
            renderKoppelButton({
                item,
                reference,
                disabled,
                readonly,
                onClear: onRemove,
                onClick: open,
            }),
        [item, disabled, readonly, onRemove, renderKoppelButton]
    );

    if (readonly && !item) {
        return null;
    }

    if (readonly || disabled) {
        return (
            <>
                {renderKoppelButton({
                    item,
                    disabled,
                    readonly,
                })}
            </>
        );
    }

    return (
        <div className={styles.koppelButton}>
            <KoppelPopup {...props} disabled={disabled} onClickItem={handleChange} item={item} renderAnchor={render} />
        </div>
    );
};
