import {ButtonWithCounter, TabListItem} from '@growthbase/design-components';
import {MijnTakenFilter, TakenSideLinks} from '@growthbase/taken';
import {useTranslation} from 'react-i18next';
import {useEntityChanges} from './hook/useEntityChanges';
import {useMijnTakenOverzichten} from './hook/useMijnTakenOverzichten';

export const TakenSideLinksConnected = () => {
    const {result, view, setView} = useMijnTakenOverzichten();

    const {data} = result;
    useEntityChanges({result});

    const {t} = useTranslation('Taken.nl');
    return (
        <TakenSideLinks>
            <TabListItem>
                <ButtonWithCounter
                    active={view === MijnTakenFilter.mijnDag}
                    counterContent={data?.mijnDag?.totalCount ?? null}
                    onClick={() => setView(MijnTakenFilter.mijnDag)}
                >
                    {t('mijnTaken.mijnDag')}
                </ButtonWithCounter>
            </TabListItem>
            <TabListItem>
                <ButtonWithCounter
                    active={view === MijnTakenFilter.mijnTaken}
                    counterContent={data?.mijnTaken?.totalCount ?? null}
                    onClick={() => setView(MijnTakenFilter.mijnTaken)}
                >
                    {t('mijnTaken.mijnTaken')}
                </ButtonWithCounter>
            </TabListItem>
            <TabListItem>
                <ButtonWithCounter
                    active={view === MijnTakenFilter.prive}
                    counterContent={data?.prive?.totalCount ?? null}
                    onClick={() => setView(MijnTakenFilter.prive)}
                >
                    {t('mijnTaken.mijnPriveTaken')}
                </ButtonWithCounter>
            </TabListItem>
            <TabListItem>
                <ButtonWithCounter
                    active={view === MijnTakenFilter.snoozed}
                    counterContent={data?.snoozed?.totalCount ?? null}
                    onClick={() => setView(MijnTakenFilter.snoozed)}
                >
                    {t('Gesnoozed')}
                </ButtonWithCounter>
            </TabListItem>
        </TakenSideLinks>
    );
};
