import {CalendarSelectionItem} from '../CalendarSelectionItem';

export type CalendarSelectionState = CalendarSelectionItem[];

// eslint-disable-next-line no-shadow
export enum CalendarSelectionActionType {
    SELECT = 'SELECT',
    TOGGLE_ENABLED = 'TOGGLE_ENABLED',
    UPDATE = 'UPDATE',
}

interface CalendarSelectionAction {
    type: CalendarSelectionActionType;
    id: string;
}

export const calendarSelectionReducer = (state: CalendarSelectionState, action: CalendarSelectionAction) => {
    switch (action.type) {
        case CalendarSelectionActionType.SELECT:
            return state.map((item) => {
                if (item.id === action.id) {
                    return {...item, selected: true, enabled: true};
                }
                return {...item, selected: false};
            });
        case CalendarSelectionActionType.TOGGLE_ENABLED:
            return state.map((item) => {
                if (item.id === action.id) {
                    if (item.selected) {
                        return item;
                    }
                    return {...item, enabled: !item.enabled};
                }
                return item;
            });
        default:
            return state;
    }
};
