import {faker} from '@faker-js/faker/locale/nl';
import {CalendarSelectionItem, GroupedCalendarSelectionItem} from '@growthbase/design-components';
import {random} from 'lodash';
import moment from 'moment';
import {defaultPlanningState, PlanningContextState} from './PlanningSlice';

const generateItems = (generateItemCount: number): CalendarSelectionItem[] => {
    faker.seed(1);

    const items: CalendarSelectionItem[] = [];

    for (let i = 0; i < generateItemCount; i += 1) {
        const item: CalendarSelectionItem = {
            id: random(0, 100000).toString(),
            enabled: faker.datatype.boolean(),
            selected: false,
            title: faker.person.fullName(),
            color: faker.internet.color({
                redBase: 100,
                blueBase: 100,
                greenBase: 100,
            }),
            employeeId: random(0, 100000).toString(),
        };
        items.push(item);
    }
    return items;
};

const generateCalendarGroups = (generateGroups: number, generateItemCount: number): GroupedCalendarSelectionItem[] => {
    faker.seed(1);

    const groups: GroupedCalendarSelectionItem[] = [];

    for (let i = 0; i < generateGroups; i += 1) {
        const group: GroupedCalendarSelectionItem = {
            id: i.toString(),
            name: faker.commerce.department(),
            items: generateItems(generateItemCount),
        };
        groups.push(group);
    }

    return groups;
};

export const DemoPlanningState: {planning: PlanningContextState} = {
    planning: {
        ...defaultPlanningState,
        date: moment('2023-01-01').toDate(),
        agendas: generateCalendarGroups(3, 5),
        viewSettingsLoaded: true,
    },
};
