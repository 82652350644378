import {MenuItemDTO, useCurrentHref, useMenuItems} from '@growthbase/routing';
import {useMemo} from 'react';
import {matchRoutes} from 'react-router-dom';

export interface ActiveMenuItemBreadcrumbProps {
    forceId?: string;
}

function isActive(currentRoute: string, menuItem: MenuItemDTO, {forceId}: ActiveMenuItemBreadcrumbProps) {
    if (forceId) {
        return menuItem.id === forceId;
    }

    return matchRoutes([{path: menuItem.route, exact: true}], currentRoute);
}

function findActiveBreadcrumb(
    currentRoute: string,
    menuItem: MenuItemDTO[],
    options: ActiveMenuItemBreadcrumbProps
): MenuItemDTO[] {
    for (const item of menuItem) {
        const found = findActiveBreadcrumb(currentRoute, item.items, options);
        if (found.length) {
            return [item, ...found];
        }
        if (isActive(currentRoute, item, options)) {
            return [item];
        }
    }
    return [];
}

export const useActiveMenuItemBreadcrumb = ({forceId}: ActiveMenuItemBreadcrumbProps = {}): {
    breadcrumbs: MenuItemDTO[];
    activeRoute: string;
} => {
    const active = useCurrentHref();
    const main = useMenuItems();
    return useMemo(
        () => ({
            breadcrumbs: findActiveBreadcrumb(active, main, {forceId}),
            activeRoute: active,
        }),
        [active, main, forceId]
    );
};
