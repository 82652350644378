import {DNDContainerState, DNDItemData} from '../Value';
import {placeItemFromPlacement} from './placeItemFromPlacement';

export function preservePlacement(container: DNDContainerState, item: DNDItemData) {
    if (!container.draggingItems) {
        throw new Error('Dragging items should not be null when preserving placement');
    }
    const previousPosition = container.draggingItems.findIndex((i) => i.id === item.id);

    const before = container.draggingItems[previousPosition - 1];
    const itemThatIsBefore: DNDItemData | undefined = container.items.find((i) => i.id === before?.id);
    const after = container.draggingItems[previousPosition + 1];
    const itemThatIsAfter: DNDItemData | undefined = container.items.find((i) => i.id === after?.id);
    container.draggingItems = placeItemFromPlacement<DNDItemData>({
        item,
        items: container.items,
        placement: {
            itemThatIsBefore,
            itemThatIsAfter,
        },
    });
    container.draggingItemsDND = container.draggingItems;
}
