import {ILijsttemplateTemplateMenuFragment} from '@growthbase/graphql';
import {FC} from 'react';
import {TemplateVerwijderenMenuButtonConnected} from './Components/TemplateVerwijderenMenuButton';
import {TemplateMenu} from './TemplateMenu';
import {TemplateOntkoppelenMenuButtonConnected} from './Components/TemplateOntkoppelenMenuButton';

export interface TemplateMenuConnectedProps {
    bordtemplateId: string;
    fragment: ILijsttemplateTemplateMenuFragment;
}

export const TemplateMenuConnected: FC<TemplateMenuConnectedProps> = ({fragment, bordtemplateId}) => (
    <TemplateMenu
        lijsttemplateId={fragment.id}
        verwijderTemplateMenuButton={
            <TemplateVerwijderenMenuButtonConnected fragment={fragment} bordtemplateId={bordtemplateId} />
        }
        ontkoppelTemplateMenuButton={
            <TemplateOntkoppelenMenuButtonConnected fragment={fragment} bordtemplateId={bordtemplateId} />
        }
    />
);
