import {ResultData} from '@growthbase/graphql';
import {idFromIri} from '@growthbase/routing';
import {findIndex} from 'lodash';
import {useCallbackRef, useLogger} from '@growthbase/spa';
import {UpdateQuery} from './useAddToQueryCache';
import {BaseNode} from '../baseNode';

export interface ChangeOrderQueryOptions {
    /**
     * If there is a next page, we won't append the item.
     */
    appendItemFromNextPage?: boolean;
}

export const useChangeOrderInQueryCache = <TNode extends BaseNode, TVariables>(
    updateQuery: UpdateQuery<TNode, TVariables>,
    {appendItemFromNextPage = false}: ChangeOrderQueryOptions = {}
) => {
    const logger = useLogger();
    return useCallbackRef((id: string, placedBeforeId?: string | null, placedAfterId?: string | null) => {
        updateQuery((cached): ResultData<TNode> => {
            const typed: ResultData<TNode> | undefined = cached ?? undefined;
            const edges = (typed?.items?.edges ?? []) as Array<{
                node: {id: string};
            }>;
            const pageInfo = typed?.items?.pageInfo;
            if (!edges || !pageInfo || edges.length === 0) {
                // @ts-expect-error Type is not correct see @https://www.apollographql.com/docs/react/caching/cache-interaction/
                return typed;
            }

            id = idFromIri(id);
            placedBeforeId = placedBeforeId ? idFromIri(placedBeforeId) : undefined;
            placedAfterId = placedAfterId ? idFromIri(placedAfterId) : undefined;

            const found = findIndex(edges, (edge) => idFromIri(edge.node.id) === id);
            const placedBefore = findIndex(edges, (edge) => idFromIri(edge.node.id) === placedBeforeId);
            const placedAfter = findIndex(edges, (edge) => idFromIri(edge.node.id) === placedAfterId);

            if (found === -1) {
                logger.info(`Node "${id}" not found in list of document.`);
                return typed;
            }

            if (placedBefore === -1 && placedAfter === -1) {
                logger.info(`placeBefore and placedAfter not found in list of document, removing the item`, {
                    placedBeforeId,
                    placedAfterId,
                    typed,
                });
                const changedList = [...edges];
                changedList.splice(found, 1);
                return {
                    ...typed,
                    items: {
                        ...typed?.items,
                        edges: changedList,
                    },
                } as ResultData<TNode>;
            }

            const changedList = [...edges];
            changedList.splice(found, 1);

            let index;
            if (placedAfter !== -1) {
                index = placedAfter > found ? placedAfter : placedAfter + 1;
            } else {
                index = placedBefore > found ? placedBefore - 1 : placedBefore;
            }
            changedList.splice(index, 0, edges[found]);

            return {
                ...typed,
                items: {
                    ...typed?.items,
                    edges: changedList,
                },
            } as ResultData<TNode>;
        });
    });
};
