import {PlanningViewType, usePlanningSetActiveViewGroup, useCurrentView, ViewFormat} from '@growthbase/planning';
import {StyledSelect} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';
import {useEffect, useMemo} from 'react';

export interface PlanningViewSwitchItem {
    label: string;
    value: PlanningViewType;
}

export const PlanningViewSwitch = ({views}: {views: ViewFormat[]}) => {
    const {t} = useTranslation('spa.nl');

    const currentViewFormat = useCurrentView();
    const setActiveViewGroup = usePlanningSetActiveViewGroup();
    const {group} = currentViewFormat;

    const options: PlanningViewSwitchItem[] = useMemo(() => {
        const viewGroups: PlanningViewSwitchItem[] = [];
        views.forEach((view) => {
            if (viewGroups.find((viewGroup) => viewGroup.value === view.group)) {
                return;
            }

            viewGroups.push({
                value: view.group,
                label: t(`planning.${view.group}`),
            });
        });
        return viewGroups;
    }, [t, views]);

    useEffect(() => {
        if (!views.find((view) => view.id === currentViewFormat.id)) {
            setActiveViewGroup({
                groupId: views[0].group,
                localOnly: true,
            });
        }
    }, [currentViewFormat.id, setActiveViewGroup, views]);

    if (options.length < 2) {
        return null;
    }

    return (
        <div style={{width: 170}}>
            <StyledSelect<string>
                testId="planning-view-switch"
                disabled={false}
                readonly={false}
                options={options}
                value={group}
                onChange={(option) => {
                    if (option) {
                        setActiveViewGroup({
                            groupId: option,
                        });
                    }
                }}
            />
        </div>
    );
};
