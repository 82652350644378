import React, {FC} from 'react';
import {Reason} from '../../../FieldControlledByService';
import {FullNestedName, InstellingField, InstellingFieldType} from '../../../types';
import {InstellingenId, useFieldIsOverridden, useInstellingenField, useInstellingenValue} from '../../../redux';

// eslint-disable-next-line no-use-before-define
export type ViewComponentResolver = (type: InstellingFieldType, name: string) => FC<InstellingViewProps>;

export type InstellingViewProps<T extends InstellingField = InstellingField> = T & {
    override: Reason | null;
    id: InstellingenId;
    value: unknown;
    resolveComponent: ViewComponentResolver;
};
export type InstellingViewWrapperProps = {
    id: InstellingenId;
    name: FullNestedName;
    resolveComponent: ViewComponentResolver;
};

export const InstellingViewWrapper = ({id, name, resolveComponent}: InstellingViewWrapperProps) => {
    const field = useInstellingenField(id, name);
    const value = useInstellingenValue(id, name);
    const override = useFieldIsOverridden(id, name);
    const props: InstellingViewProps = {
        id,
        resolveComponent,
        value,
        ...field,
        name,
        override,
    };
    if (override?.hide || field.hide) {
        return null;
    }

    const Component = field.ViewComponent ?? resolveComponent(field.type, name);
    return field.renderViewComponent ? <>{field.renderViewComponent(props)}</> : <Component {...props} />;
};
