import classNames from 'classnames/bind';
import {ReactNode} from 'react';
import styles from './CardHeader.module.scss';

const cx = classNames.bind(styles);

/**
 * @deprecated use alias for this component: Card.Header instead
 */
export const CardHeader = ({children, disableMinHeight}: {children?: ReactNode; disableMinHeight?: boolean}) => {
    const className = cx('CardHeader', {
        'CardHeader--noMinHeight': disableMinHeight,
    });
    return <div className={className}>{children}</div>;
};
