import {useDefaultValueTreeId, useLogger} from '@growthbase/spa';
import {useCallback, useMemo} from 'react';
import {generatePath, Location, useParams} from 'react-router-dom';
import {backgroundRouteSchema} from '../Schema/backgroundRouteSchema';
import {createURL} from '../Util';
import {useTypedSearchParams} from './useTypedSearchParams';

export const useBackgroundRoute = (): [string | undefined, (route: undefined | string) => void] => {
    const [background, setBackground] = useTypedSearchParams('bg', backgroundRouteSchema);
    const params = useParams();
    const treeId = useDefaultValueTreeId();

    return [
        background.route,
        useCallback(
            (route) => {
                if (!route) {
                    setBackground({
                        route: undefined,
                        treeid: undefined,
                    });
                    return;
                }
                setBackground({
                    route: generatePath(route, params),
                    treeid: treeId,
                });
            },
            [setBackground, params, treeId]
        ),
    ];
};

export const useBackgroundRouteTreeId = (): string | undefined => {
    const [background] = useTypedSearchParams('bg', backgroundRouteSchema);
    return background.treeid;
};

export const useBackgroundRouteLocation = (): Location | undefined => {
    const [backgroundRoute] = useBackgroundRoute();
    const logger = useLogger('useBackgroundRouteLocation');
    return useMemo((): Location | undefined => {
        if (!backgroundRoute) {
            return undefined;
        }
        try {
            const withParams = createURL(backgroundRoute);
            return {
                pathname: withParams.pathname,
                search: withParams.search,
                hash: withParams.hash,
                state: {},
                key: backgroundRoute,
            };
        } catch (e) {
            logger.warn('Invalid background route: ', e);
        }
        return undefined;
    }, [backgroundRoute, logger]);
};
