import {TFunction} from 'i18next';
import {useTranslation} from 'react-i18next';
import {SchemaOf} from 'yup';
import * as yup from 'yup';
import {useMemo} from 'react';

export type SearchInput = {
    query: string | null;
};

export const SearchFormSchema: (t: TFunction<'spa_form.nl'>) => SchemaOf<SearchInput> = (t) =>
    yup
        .object({
            query: yup
                .string()
                .nullable()
                .test(
                    'query',
                    t('SearchFormSchema.validationWarning') ?? '',
                    (val) => !val || val.length >= 2 || val.length === 0
                ),
        })
        .required() as SchemaOf<SearchInput>;

export const useSearchFormSchema = () => {
    const {t} = useTranslation('spa_form.nl');
    return useMemo(() => SearchFormSchema(t), [t]);
};
