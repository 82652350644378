import {FC, useCallback} from 'react';
import {useGebruikerHeeftToegang} from '@growthbase/design-components';
import {
    ICreateBordFormMutation,
    IMaakBordAanBordInput,
    useCreateBordFormMutation,
    useCreateBordFormPermissionsQuery,
} from '@growthbase/graphql';
import {useNavigate} from 'react-router';
import {CreateForm} from './CreateForm';
import {mapDTOToGraphQL} from './mapDTOToGraphQL';
import {CreateFormDTO} from './Hook/useCreateSchema';
import {useBordRouteViewFactory} from '../Hook';

export type BordOverrideValues = Omit<IMaakBordAanBordInput, 'id' | 'titel'>;

export interface CreateFormConnectedProps {
    onCreated?: (value: ICreateBordFormMutation) => void;
    overrideValues?: BordOverrideValues;
}

export const CreateFormConnected: FC<CreateFormConnectedProps> = ({onCreated, overrideValues}) => {
    const [mutateAsync] = useCreateBordFormMutation();
    const {data} = useCreateBordFormPermissionsQuery();

    const viewBord = useBordRouteViewFactory();
    const navigator = useNavigate();
    const navigateToEditModal = useCallback(
        async (value: ICreateBordFormMutation) => {
            const bord = value.MaakBordAan?.takenBord;
            if (!bord) {
                return;
            }
            const href = viewBord(bord, {
                replace: true,
            });
            navigator(href);
        },
        [navigator, viewBord]
    );

    const onSubmit = useCallback(
        (value: CreateFormDTO) =>
            mutateAsync({
                variables: {
                    input: {...mapDTOToGraphQL(value), ...overrideValues},
                },
            })
                .then((mutation) => {
                    const createdBord = mutation?.data;
                    if (!createdBord) {
                        return Promise.resolve();
                    }
                    if (onCreated) {
                        onCreated(createdBord);
                        return Promise.resolve();
                    }
                    return navigateToEditModal(createdBord);
                })
                .then(() => undefined),
        [mutateAsync, navigateToEditModal, onCreated, overrideValues]
    );

    const toegang = useGebruikerHeeftToegang({
        toegang: data?.ingelogdeGebruiker?.availableActions?.kanBordAanmaken,
    });

    if (!data || !toegang) {
        return null;
    }

    return <CreateForm onSubmit={onSubmit} />;
};
