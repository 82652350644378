import {findIndex} from 'lodash';
import {ItemPlacement} from '../Value';

export function placementFromDragOver<TItem extends {id: string} = {id: string}>({
    activeId,
    items,
    overId,
}: {
    items: TItem[];
    activeId: string;
    overId: string | null;
}): ItemPlacement<TItem> {
    const activeIndex = findIndex(items, (item) => item.id === activeId);
    const overIndex = findIndex(items, (item) => item.id === overId);
    const isDraggedDown: boolean = activeIndex < overIndex;

    if (!items[activeIndex]) {
        // This happens when the item is hoovered over the list for the first time.
        return {
            itemThatIsBefore: items[overIndex],
            itemThatIsAfter: items[overIndex + 1],
        };
    }

    if (!items[overIndex]) {
        return {};
    }

    const afterIndex = isDraggedDown ? overIndex : overIndex - 1;
    const beforeIndex = isDraggedDown ? overIndex + 1 : overIndex;
    const itemThatIsAfter = items[beforeIndex];
    const itemThatIsBefore = items[afterIndex];

    return {
        itemThatIsBefore,
        itemThatIsAfter,
    };
}
