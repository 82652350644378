import {AddBreadcrumb, LoaderCenter} from '@growthbase/design-components';
import {TicketRoutes, Pagina, PaginaProps} from '@growthbase/tickets-tickets';
import {useTranslation} from 'react-i18next';
import {FC, PropsWithChildren} from 'react';

export interface BordenPaginaProps extends PropsWithChildren, Omit<PaginaProps, 'titel'> {
    titel?: string;
}

export const BordenPagina: FC<BordenPaginaProps> = ({titel, children, ...rest}) => {
    const {t} = useTranslation('TicketsTaken.nl');
    return (
        <Pagina
            {...rest}
            titel={
                titel ??
                t('BordenPagina.titel', {
                    ticketNaam: rest.ticketNaam,
                })
            }
        >
            <AddBreadcrumb to={TicketRoutes.borden.useUrl(rest)}>{t('BordenPagina.breadcrumb')}</AddBreadcrumb>
            {children ?? <LoaderCenter />}
        </Pagina>
    );
};
