import {useCallback, useEffect, useReducer} from 'react';
import {useCallbackRef} from '@growthbase/spa';
import styles from './CalendarSelection.module.scss';
import {
    CalendarSelectionGroupedActionType,
    GroupedCalendarSelectionAction,
    groupedCalendarSelectionReducer,
} from './CalenderSelectionGroupedState';
import {CalendarSelectionItem} from '../CalendarSelectionItem';

export interface GroupedCalendarSelectionItem {
    id: string;
    name: string;
    items: CalendarSelectionItem[];
    enabled?: boolean;
}

export interface CalendarSelectionGroupedProps {
    calendars: GroupedCalendarSelectionItem[];
    selectedStateEnabled?: boolean;
    onChangeEnabledState?: (items: CalendarSelectionItem[]) => void;
}

export const CalendarSelectionGrouped = ({
    calendars,
    selectedStateEnabled,
    onChangeEnabledState,
}: CalendarSelectionGroupedProps) => {
    const [groups, dispatch] = useReducer(groupedCalendarSelectionReducer, calendars);

    // Update reducer on prop change
    useEffect(() => {
        dispatch({
            type: CalendarSelectionGroupedActionType.SET,
            items: calendars,
        });
    }, [calendars]);

    // Callback on state change
    const changeEnabledState = useCallback(
        (action: GroupedCalendarSelectionAction) => {
            if (onChangeEnabledState) {
                const nextGroups = groupedCalendarSelectionReducer(groups, action);
                onChangeEnabledState(nextGroups.flatMap((group) => group.items));
            }
        },
        [groups, onChangeEnabledState]
    );

    const dispatchToggleGroup = useCallbackRef((id: string) => {
        const action: GroupedCalendarSelectionAction = {type: CalendarSelectionGroupedActionType.TOGGLE_GROUP, id};
        dispatch(action);
        changeEnabledState(action);
    });

    const dispatchToggleItem = useCallbackRef((id: string) => {
        const action: GroupedCalendarSelectionAction = {type: CalendarSelectionGroupedActionType.TOGGLE_ITEM, id};
        dispatch(action);
        changeEnabledState(action);
    });

    const dispatchSelectOrToggleItem = useCallbackRef((id: string) => {
        const action: GroupedCalendarSelectionAction = {
            type: selectedStateEnabled
                ? CalendarSelectionGroupedActionType.SELECT
                : CalendarSelectionGroupedActionType.TOGGLE_ITEM,
            id,
        };
        dispatch(action);
        changeEnabledState(action);
    });

    return (
        <div className={styles.calendarSelectionGrouped}>
            {groups.map((group) => (
                <div key={group.id}>
                    <CalendarSelectionItem
                        employeeId="group"
                        isGroup
                        key={group.id}
                        enabled={group.enabled}
                        color="#000"
                        id={group.id}
                        title={group.name}
                        onClickBullet={dispatchToggleGroup}
                        onClickLabel={dispatchToggleGroup}
                    />
                    <div className={styles.calendarSelectionGrouped__items}>
                        {group.items.map((calendar) => (
                            <CalendarSelectionItem
                                key={calendar.id}
                                {...calendar}
                                onClickBullet={dispatchToggleItem}
                                onClickLabel={dispatchSelectOrToggleItem}
                            />
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};
