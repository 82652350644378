import {BaseButtonProps, NullableISOStringDate} from '@growthbase/design-components';
import {
    ISnoozeButtonFragment,
    useSnoozeButtonSnoozeMutation,
    useSnoozeButtonVerwijderSnoozeMutation,
} from '@growthbase/graphql';
import {useCallback, FC} from 'react';
import moment from 'moment';
import {useMapFragmentToValue} from './hook/useMapFragmentToValue';
import {SnoozeButton} from './SnoozeButton';

export interface SnoozeButtonConnectedProps {
    fragment?: ISnoozeButtonFragment;
    disabled?: boolean;
    asText?: boolean;
    asActions?: boolean;
    Button?: FC<BaseButtonProps>;
    ModifyButton?: FC<BaseButtonProps>;
}

export const SnoozeButtonConnected: FC<SnoozeButtonConnectedProps> = ({
    fragment,
    asText,
    disabled,
    asActions,
    Button,
    ModifyButton = Button,
}) => {
    const [snooze] = useSnoozeButtonSnoozeMutation();
    const [unSnooze] = useSnoozeButtonVerwijderSnoozeMutation();

    const onSubmit = useCallback(
        async (range: NullableISOStringDate) => {
            const {id} = fragment ?? {};
            if (!id) {
                return;
            }
            const {date} = range;
            if (date) {
                let time = moment(date.date);
                if (!date.timeUsed) {
                    time = time.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
                }
                await snooze({
                    variables: {
                        input: {
                            id,
                            tot: time.toISOString(),
                            taakId: id,
                        },
                    },
                });
                return;
            }
            await unSnooze({
                variables: {
                    input: {
                        id,
                        taakId: id,
                    },
                },
            });
        },
        [fragment, snooze, unSnooze]
    );

    const value = useMapFragmentToValue(fragment);

    if (fragment?.gearchiveerd) {
        return null;
    }

    return (
        <SnoozeButton
            Button={Button}
            ModifyButton={ModifyButton}
            asText={asText}
            onSubmit={onSubmit}
            asActions={asActions}
            value={value}
            disabled={disabled ?? false}
        />
    );
};
