import {useTypedSearchParams} from '@growthbase/routing';
import {useMemo} from 'react';
import * as yup from 'yup';
import {SchemaOf} from 'yup';
import {useLocalStorage} from 'react-use';
import {useCallbackRef} from '@growthbase/spa';
import {ViewType} from '../../../types';

interface Filters {
    overzicht: ViewType;
}

const Schema = (): SchemaOf<Filters> =>
    yup
        .object({
            overzicht: yup.mixed().oneOf(Object.keys(ViewType)).required(),
        })
        .required();

const useFilterSchema = (): SchemaOf<Filters> => useMemo(() => Schema(), []);

export interface SelectOptions {
    defaultView?: ViewType;
}

export const useViewSelectState = ({defaultView}: SelectOptions): [ViewType, (overzicht: ViewType) => void] => {
    const [overzichtFromLocalStorage, setOverzichtFromLocalStorage] = useLocalStorage<ViewType>(
        `borden-view`,
        defaultView
    );

    const [{overzicht}, setFilters] = useTypedSearchParams<Filters>(
        'view',
        useFilterSchema(),
        useMemo(
            (): Filters => ({
                overzicht: overzichtFromLocalStorage ?? ViewType.Tabel,
            }),
            [overzichtFromLocalStorage]
        )
    );
    return [
        overzicht,
        useCallbackRef((newOverzicht: ViewType) => {
            setFilters({overzicht: newOverzicht});
            setOverzichtFromLocalStorage(newOverzicht);
        }),
    ];
};
