import {IKoppelAanTaakButtonFragment, useKoppelAanTaakButtonLazyQuery} from '@growthbase/graphql';
import {useExternalEntityFieldsInvalidated} from '@growthbase/cache';
import {useCallback} from 'react';
import {idFromIri} from '@growthbase/routing';

export const useKoppelAanTaakButtonChanges = (taakId?: string): void => {
    const [fetchTaak] = useKoppelAanTaakButtonLazyQuery();

    useExternalEntityFieldsInvalidated<IKoppelAanTaakButtonFragment>(
        useCallback(
            ({typename, id, fields}) => {
                if (!taakId) {
                    return;
                }
                if (typename === 'Taak' && idFromIri(id) === idFromIri(taakId)) {
                    if (fields.includes('opdracht') || fields.includes('abonnement') || fields.includes('ticket')) {
                        fetchTaak({variables: {id: taakId}});
                    }
                }
            },
            [taakId, fetchTaak]
        )
    );
};
