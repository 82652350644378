import {
    ActiveItemRegionContainer,
    ActiveItemRegionContainerProps,
    ActiveItemSource,
    ActiveItemType,
} from '@growthbase/keys';
import {useRef} from 'react';

import {ActiveItem} from './Redux/activeReducer';

export interface ConvertActiveItemSourceContainerProps extends Omit<ActiveItemRegionContainerProps, 'convert'> {
    convert: {
        from: ActiveItemSource;
        to: ActiveItemSource;
        type?: ActiveItemType;
    }[];
}

export function ConvertActiveItemSourceContainer({
    convert: convertMap,
    children,
    ...rest
}: ConvertActiveItemSourceContainerProps) {
    const convertRef = useRef((item: ActiveItem): Partial<ActiveItem> => {
        const convert = convertMap.find(({from}) => from === item.source);
        if (convert) {
            return {
                source: convert.to,
                type: convert.type ?? item.type,
            };
        }
        return {};
    });
    return (
        <ActiveItemRegionContainer {...rest} convert={convertRef.current}>
            <ActiveItemRegionContainer name={`${rest.name}.decorated`}>{children}</ActiveItemRegionContainer>
        </ActiveItemRegionContainer>
    );
}
