import {IRelatieButtonGroupsFragment, IRelatieGroupType, IRelatieType} from '@growthbase/graphql';

export const dummyIRelatieButtonGroupsFragmentData: IRelatieButtonGroupsFragment = {
    groups: [
        {
            label: 'Personen',
            type: IRelatieGroupType.Personen,
            edges: [
                {
                    node: {
                        id: '1',
                        type: IRelatieType.Persoon,
                        label: 'John Doe',
                        externalId: 'clientbox:1',
                    },
                    verbonden: true,
                },
                {
                    node: {
                        id: '2',
                        type: IRelatieType.Persoon,
                        label: 'Jane Doe',
                        externalId: 'clientbox:2',
                    },
                    verbonden: false,
                },
            ],
        },
        {
            label: 'Bedrijven',
            type: IRelatieGroupType.Bedrijven,
            edges: [
                {
                    node: {
                        id: '3',
                        type: IRelatieType.Bedrijf,
                        label: 'Vollan',
                        externalId: 'clientbox:1',
                    },
                    verbonden: true,
                },
                {
                    node: {
                        id: '4',
                        type: IRelatieType.Bedrijf,
                        label: 'Facebook',
                        externalId: 'clientbox:2',
                    },
                    verbonden: false,
                },
            ],
        },
    ],
};
