import {useCallback} from 'react';
import {ErrorContext} from '../Value';
import {useErrorClient} from './useErrorClient';

export const useSendHandleError = (context?: ErrorContext) => {
    const client = useErrorClient();
    return useCallback(
        (error: Error, info: {componentStack: string}): void => {
            const extra = context ?? {};
            if (!client) {
                return;
            }
            client.sendError(error, {componentStack: info.componentStack, ...extra});
        },
        [client, context]
    );
};
