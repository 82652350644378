import {TFunction} from 'i18next';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {SchemaOf} from 'yup';
import {validate} from 'uuid';

export const UuidFieldSchema = (t: TFunction) =>
    yup
        .string()
        .label(t('id', {ns: 'spa_form.nl'}))
        .strict(true)
        .required()
        // eslint-disable-next-line
        .test('uuid-v4', '${path} is not a valid v4 uuid', (value) => {
            if (!value) {
                return false;
            }

            // The uuid is in the API Platform format e.g. "/taken/eb66eee0-269f-4407-bf54-611a02d3204a".
            const uuid = value.split('/').pop();

            return validate(uuid ?? '');
        });

export const useUuidFieldSchema = (): SchemaOf<string> => {
    const {t} = useTranslation();
    return useMemo(() => UuidFieldSchema(t), [t]);
};
