import {
    DefaultFormModalFooter,
    Field,
    Modal,
    ModalContent,
    PopupHeader,
    TabContentWrapper,
    Stack,
    SingleLineOpgemaakteTekstInput,
} from '@growthbase/design-components';
import {RelatieInputConnected} from '@growthbase/relaties';
import React, {FC, PropsWithChildren} from 'react';
import {useTranslation} from 'react-i18next';
import {KoppelButtonInputConnected} from '@growthbase/taken';
import {DeelnemersInputConnected} from '@growthbase/gebruikers';
import {useCreateForm, UseCreateFormOptions} from './Hook/useCreateForm';

export interface CreateFormProps extends UseCreateFormOptions, PropsWithChildren {
    RelatieInput?: typeof RelatieInputConnected;
    OnderdeelVanComponent?: typeof KoppelButtonInputConnected;
    DeelnemersInput?: typeof DeelnemersInputConnected;
}

export const CreateForm: FC<CreateFormProps> = ({
    children,
    RelatieInput = RelatieInputConnected,
    OnderdeelVanComponent = KoppelButtonInputConnected,
    DeelnemersInput = DeelnemersInputConnected,
    ...rest
}) => {
    const {t} = useTranslation('Bordtemplate.nl');
    const {Form, Fields, formik, formProps} = useCreateForm(rest);
    const {NaamField} = Fields;

    const Naam = NaamField.useComponent(SingleLineOpgemaakteTekstInput);

    return (
        <Modal testId="BordCreateModal">
            <Form {...formProps}>
                <PopupHeader title={t('Bord template toevoegen')} />
                <ModalContent theme="gray">
                    <TabContentWrapper title="x">
                        <Stack gap="small">
                            <Stack.Item>
                                <Field label={t('naam')} compact disableMargin>
                                    <Naam autoFocus controls={false} borderVisible variant="default" />
                                </Field>
                            </Stack.Item>
                        </Stack>
                        {children}
                    </TabContentWrapper>
                </ModalContent>
                <DefaultFormModalFooter formik={formik} />
            </Form>
        </Modal>
    );
};
