import {useSelector} from 'react-redux';
import {EventType, PlanningStoreState, useSelectedAgenda} from '@growthbase/planning';
import {useCallbackRef} from '@growthbase/spa';
import {MbscNewEventData} from '@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar.types.public';
import {getEventTitle} from './getEventTitle';

export const useExtendDefaultEvent = () => {
    const selectedAgenda = useSelectedAgenda();
    const {opdracht, ticket} = useSelector((state: PlanningStoreState) => state.planning);

    const callback = useCallbackRef((originalEvent: MbscNewEventData): Partial<EventType> => {
        const eventTicket: EventType['ticket'] = ticket
            ? {
                  id: ticket.id,
                  label: ticket.onderwerp,
                  nummer: ticket.nummer,
              }
            : undefined;

        const eventOpdracht: EventType['opdracht'] = opdracht
            ? {
                  id: opdracht.id,
                  label: opdracht.onderwerp,
                  nummer: opdracht.nummer,
              }
            : undefined;

        let relatie: EventType['relatie'] | undefined;

        if (opdracht) {
            relatie = {
                id: '',
                label: opdracht.relatie,
            };
        } else if (ticket) {
            relatie = {
                id: '',
                label: ticket.relatie,
            };
        }

        const event = {
            ...originalEvent,
            ticket: eventTicket,
            opdracht: eventOpdracht,
            relatie,
        } as EventType;

        if (originalEvent.resource === 'mbsc-def' && selectedAgenda) {
            event.color = selectedAgenda.color;
            event.resource = selectedAgenda.id;
        }

        event.title = getEventTitle(event);
        return event;
    });

    const selectedAgendaCallback = useCallbackRef((originalEvent: MbscNewEventData): Partial<EventType> => {
        if (originalEvent.resource === 'mbsc-def' && selectedAgenda) {
            return {
                ...originalEvent,
                title: 'Nieuw',
                color: selectedAgenda.color,
                resource: selectedAgenda.id,
            } as EventType;
        }

        return {
            ...originalEvent,
        } as EventType;
    });

    if (opdracht || ticket) {
        return callback;
    }

    if (selectedAgenda) {
        return selectedAgendaCallback;
    }

    return undefined;
};
