import {FC, ReactNode} from 'react';

export interface FooterProps {
    form: ReactNode;
}

export const Footer: FC<FooterProps> = ({form}) => (
    <div
        style={{
            padding: '20px',
            border: '1px solid blue',
        }}
    >
        {form}
    </div>
);
