import {PaginationHelpers} from '@growthbase/spa';
import React, {PropsWithChildren} from 'react';
import {Loader} from '@growthbase/design-components';
import {DNDContainerUserData} from '@growthbase/keys';
import {ItemData} from '@growthbase/takentemplates-lijsttemplate';
import {GeenItemsAanwezig} from './Components';

export interface BordMetLijstenData extends DNDContainerUserData {
    titel: string;
}

export interface ViewProps<T extends ItemData = ItemData> extends PropsWithChildren {
    data: BordMetLijstenData;

    pagination: PaginationHelpers<T>;

    header: React.ReactNode;
}

export function View<T extends ItemData = ItemData>({data, children, pagination, header}: ViewProps<T>) {
    if (pagination.isLoading) {
        return <Loader center />;
    }
    return (
        <>
            {header}
            {pagination.totalCount === 0 ? <GeenItemsAanwezig bordtemplateId={data.id} /> : null}
            {children}
        </>
    );
}
