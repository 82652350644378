export interface PaginationHelpers<TNode> {
    loadMore: (amount?: number) => Promise<void>;
    connections: TNode[];
    isLoading: boolean;
    totalCount: number;
    hasMore: boolean;
}

const empty: never[] = [];

export const fixedPaginationHelpers = <TNode>(connections: TNode[] | null): PaginationHelpers<TNode> => ({
    isLoading: false,
    hasMore: false,
    loadMore: () => Promise.resolve(),
    connections: connections ?? empty,
    totalCount: connections?.length ?? 0,
});
