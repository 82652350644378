import {useEffect} from 'react';
import {createHTMLString} from '@growthbase/kernel';
import {useCallbackRef, useHTTPClient, useLogger} from '@growthbase/spa';
import {useRoutes} from '@growthbase/routing';
import {noop} from 'rxjs';
import {SidenavConnected} from '../../Navigatie/SidenavConnected';
import {NavigationData} from '../../Schema/NavigationData';

export const useSSRMenu = (navigationData: NavigationData | null) => {
    const routes = useRoutes();
    const logger = useLogger('useSSRMenu');
    const postMenuHTMLRoute = routes.spa.menu_save;
    const client = useHTTPClient();
    const tryPostMenuHTML = useCallbackRef((data: NavigationData): boolean => {
        if (!data) {
            return false;
        }
        const portal = document.getElementsByClassName('navigation-portal');
        if (portal.length === 0) {
            // logger.debug('No menu portal found');
            return false;
        }
        const firstElement = portal[0];
        if (!(firstElement instanceof HTMLElement)) {
            // logger.debug('No menu portal found');
            return false;
        }
        const preloadedTimeStamp = firstElement.getAttribute('data-htmlpreloaded');
        if (!preloadedTimeStamp) {
            logger.info('Missing preloaded timestamp');
            return false;
        }
        const seconds = parseInt(preloadedTimeStamp, 10);
        const now = new Date().getTime() / 1000;
        const diff = now - seconds;
        if (diff < 1800) {
            logger.info('Menu preloaded too recently');
            return true;
        }
        try {
            const expandedHTML = createHTMLString(
                {
                    Components: () => <SidenavConnected isExpandedOverride navigationData={data} />,
                },
                () => ({})
            );

            const collapsedHTML = createHTMLString(
                {
                    Components: () => <SidenavConnected isExpandedOverride={false} navigationData={data} />,
                },
                () => ({})
            );
            const dto = {
                expandedHTML,
                collapsedHTML,
            };
            logger.debug('Rendered menu:', dto);
            client.post(postMenuHTMLRoute, dto).subscribe({
                complete: () => {
                    logger.info('Menu rendered and saved');
                },
                error: (e) => {
                    logger.warn('Could not saved rendered menu', e);
                },
            });
        } catch (e) {
            logger.warn('Could not render menu', e);
        }
        return true;
    });
    useEffect(() => {
        if (!navigationData) {
            return noop;
        }
        let count = 0;
        const interval = setInterval(() => {
            if (count > 20) {
                logger.info('New menu post interval exceeded, stopping');
                clearInterval(interval);
            }
            if (tryPostMenuHTML(navigationData)) {
                clearInterval(interval);
            }
            count += 1;
        }, 100);
        return () => {
            clearInterval(interval);
        };
    }, [logger, navigationData, tryPostMenuHTML]);
};
