import {Icons, RadioSwitch} from '@growthbase/design-components';
import React, {FC, useMemo} from 'react';
import {ViewType} from '../../types';

export interface ViewSelectProps {
    value: ViewType;
    onChange: (value: ViewType) => void;
}

interface ViewSelectItem {
    value: ViewType;
    label: React.ReactNode;
    testId?: string;
}

export const ViewSelect: FC<ViewSelectProps> = ({value, onChange}) => {
    const options = useMemo(
        (): ViewSelectItem[] => [
            {
                value: ViewType.Lijsten,
                label: <Icons.Rows />,
                testId: 'view-select-lijsten',
            },
            {
                value: ViewType.Kanban,
                label: <Icons.Columns />,
                testId: 'view-select-kanban',
            },
        ],
        []
    );

    return <RadioSwitch<ViewType> square onChange={onChange} value={value} items={options} />;
};
