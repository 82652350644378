import {createContext, PropsWithChildren, useContext} from 'react';

const DefaultPrivateContext = createContext<boolean>(false);

export const useTaakDefaultPrivate = () => useContext(DefaultPrivateContext);

export const TaakDefaultPrivateProvider = ({
    defaultPrivate = true,
    children,
}: {defaultPrivate?: boolean} & PropsWithChildren) => (
    <DefaultPrivateContext.Provider value={defaultPrivate}>{children}</DefaultPrivateContext.Provider>
);
