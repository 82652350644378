import {ActiveItemRegionContainer, KeysProvider} from '@growthbase/keys';
import React, {ReactNode} from 'react';
import {Helmet} from 'react-helmet-async';
import classNames from 'classnames/bind';
import {PageSidebarPortal} from './components/PageSidebar/PageSidebarPortal';
import styles from './Page.module.scss';
import {ScrollContext, useUniqueClassname} from '../PaginationInfiniteScroll';
import {PageBody} from './components/PageBody';

export interface PageProps {
    /**
     * @deprecated Use breadcrumb instead
     */
    title?: string | null;
    children: ReactNode;
    header?: ReactNode;
    sidebar?: ReactNode | 'preserve' | 'remove';
}

const cx = classNames.bind(styles);

export const Page = ({children, sidebar, header, title}: PageProps) => {
    const uniqueClassName = useUniqueClassname();
    return (
        <ActiveItemRegionContainer name="page">
            <KeysProvider name="page">
                {title && (
                    <Helmet>
                        <title>{title}</title>
                    </Helmet>
                )}
                <div className={cx('page')}>
                    <div className={cx('page__main')}>
                        {header && <div className={cx('page__header')}>{header}</div>}
                        <PageBody className={uniqueClassName}>
                            <ScrollContext.Provider value={uniqueClassName}>{children}</ScrollContext.Provider>
                        </PageBody>
                    </div>
                    {sidebar !== 'preserve' && sidebar && (
                        <PageSidebarPortal>{sidebar === 'remove' ? '' : sidebar}</PageSidebarPortal>
                    )}
                </div>
            </KeysProvider>
        </ActiveItemRegionContainer>
    );
};
