import {useCallbackRef} from '@growthbase/spa';
import {MbscCalendarEventData} from '@mobiscroll/react';
import React from 'react';
import {EventType} from '@growthbase/planning';
import {Icons} from '@growthbase/design-components';
import {Tooltip} from '@growthbase/design-elements';
import {useTranslation} from 'react-i18next';
import {TaakLinkConnected} from '@growthbase/clientbox-uren';

export const useRenderEventContent = () => {
    const {t} = useTranslation('Planning.nl');

    return useCallbackRef((event: MbscCalendarEventData) => {
        const original = event.original as EventType;

        const rows: string[] = [];

        if (event.title && event.title !== 'New event') {
            rows.push(event.title);
        }

        if (original.ticket) {
            const ticketText = `${original.ticket.nummer} - ${original.ticket.label}`;
            if (event.title !== ticketText) {
                rows.push(ticketText);
            }
        }

        if (original.opdracht && !original.ticket) {
            const opdrachtText = `${original.opdracht.nummer} - ${original.opdracht.label}`;
            if (event.title !== opdrachtText) {
                rows.push(opdrachtText);
            }
        }

        if (original.relatie && original.relatie.label) {
            rows.push(original.relatie.label);
        }

        if (original.dienst) {
            const dienstText = `${original.dienst.nummer} - ${original.dienst.label}`;
            rows.push(dienstText);
        }

        const showTimeIcon = original.isCreatingTimeRegistration || original.hasTimeRegistration;
        const timeIconLabel = original.hasTimeRegistration
            ? t('ErIsEenUrenRegistratieAangemaakt')
            : t('ErWordtEenUrenRegistratieAangemaakt');

        return (
            <div className={showTimeIcon ? 'event-title with-event-icon' : ''}>
                {showTimeIcon && (
                    <Tooltip title={timeIconLabel}>
                        <div className="event-icon">
                            <Icons.ModuleTimeregistration />
                        </div>
                    </Tooltip>
                )}
                {rows.map((row, index) =>
                    index !== 0 ? <small key={index}>{row}</small> : <div key={index}>{row}</div>
                )}
                {original.taak && <TaakLinkConnected id={original.taak} />}
            </div>
        );
    });
};
