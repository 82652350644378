import React, {forwardRef, ReactNode, Ref} from 'react';
import {capitalize} from 'lodash';
import classNames from 'classnames/bind';
import {Center} from '../../Center/Center';
import styles from './AvatarOverflowText.module.scss';
import {BaseButtonProps} from '../../Button';
import {AvatarSize} from '../Avatar';

export interface AvatarOverflowTextProps extends BaseButtonProps {
    children?: ReactNode;
    size?: AvatarSize;
}

const cx = classNames.bind(styles);

export const AvatarOverflowText = forwardRef(({children, size = 'default', onClick}: AvatarOverflowTextProps, ref) => {
    const classes = cx('avatarOverflowText', `avatarOverflowText--size${capitalize(size)}`);
    return (
        <div role="button" tabIndex={0} className={classes} onClick={onClick} ref={ref as Ref<HTMLDivElement>}>
            <Center>{children}</Center>
        </div>
    );
});

AvatarOverflowText.displayName = 'AvatarOverflowText';
