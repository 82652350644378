import {ActivityCardList} from '@growthbase/design-components';
import {IActiviteitenListFragment} from '@growthbase/graphql';
import {PaginationHelpers} from '@growthbase/spa';
import {FC, memo} from 'react';
import {ActiviteitenListItem} from './ActiviteitenListItem';

export interface ActiviteitenListProps {
    helpers: PaginationHelpers<IActiviteitenListFragment>;
}

export const ActiviteitenList: FC<ActiviteitenListProps> = memo(({helpers}) => (
    <ActivityCardList pagination={helpers}>
        {(fragment) => <ActiviteitenListItem key={fragment.id} fragment={fragment} />}
    </ActivityCardList>
));

ActiviteitenList.displayName = 'ActiviteitenList';
