import {createContext} from 'react';
import {EMPTY, noop, Observable} from 'rxjs';

/**
 * Call this when the component is dismounted.
 */
export type GeenToegangCleanup = () => void;

export interface GeenToegangContextApi {
    geenToegang: () => GeenToegangCleanup;
    denied: boolean;
    listener: Observable<boolean>;
}

export const GeenToegangContext = createContext<GeenToegangContextApi>({
    geenToegang: () => noop,
    denied: false,
    listener: EMPTY,
});
