import {
    OpgemaakteTekstInput,
    SingleFieldForm,
    useNormalizeOpgemaakteTekstGraphQLResult,
} from '@growthbase/design-components';
import {ITaakomschrijvingFieldFragment, IWijzigTaakomschrijvingTaakomschrijvingInput} from '@growthbase/graphql';
import {ActiveItemSource, ActiveItemType, useSetItemActiveWhenMounted} from '@growthbase/keys';
import {useCallback, FC} from 'react';
import {useTranslation} from 'react-i18next';
import {v4} from 'uuid';
import {useTaakomschrijvingFieldSchema} from './TaakomschrijvingFieldSchema';

export interface TaakomschrijvingFieldProps {
    fragment?: ITaakomschrijvingFieldFragment;
    onSubmit: (value: IWijzigTaakomschrijvingTaakomschrijvingInput) => Promise<void>;
}

export const TaakomschrijvingField: FC<TaakomschrijvingFieldProps> = ({onSubmit, fragment}) => {
    const {t} = useTranslation('spa_form.nl');

    useSetItemActiveWhenMounted({
        type: ActiveItemType.Taak,
        source: ActiveItemSource.Unknown,
        data: fragment,
    });

    const opgemaakteTekst = useNormalizeOpgemaakteTekstGraphQLResult(fragment?.omschrijving?.opgemaakteTekst);

    return (
        <SingleFieldForm
            disabled={!fragment}
            autoSubmit
            readonly={!fragment?.availableActions?.kanOmschrijvingBewerken}
            schema={useTaakomschrijvingFieldSchema()}
            name="opgemaakteTekst"
            testfieldName="TaakomschrijvingOpgemaakteTekst"
            controlsPosition="bottom"
            onSubmit={onSubmit}
            createInitialValues={useCallback(
                () => ({
                    id: fragment?.id ?? v4(),
                    opgemaakteTekst,
                    clientMutationId: undefined,
                }),
                [fragment?.id, opgemaakteTekst]
            )}
            label={t('taak.omschrijving')}
            placeholder={t('taak.omschrijvingPlaceholder')}
            component={OpgemaakteTekstInput}
        />
    );
};
