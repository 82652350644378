import {StyledSelectOption} from '@growthbase/design-components';
import {useMemo} from 'react';
import {Opties} from './useVerplaatsForm';
import {PositieLabel} from '../../PositieLabel';

export interface PositieOptie extends StyledSelectOption<number> {
    id: string;
}

export const usePositieOpties = (opties?: Opties | null) =>
    useMemo(
        (): PositieOptie[] =>
            (opties?.lijsten ?? []).map((value, index) => ({
                id: value.id,
                label: <PositieLabel onderaan={value.onderaan} key={value.id} index={index} title={value.titel} />,
                value: index,
            })),
        [opties?.lijsten]
    );
