import {MouseEventHandler, useMemo, useRef, useState} from 'react';
import {Scrollbars} from 'react-custom-scrollbars-2';
import {noop} from 'rxjs';

export const useDraggableScroll = (scrollbars: Scrollbars | null, enabled = false) => {
    const mouseCoords = useRef({
        startX: 0,
        scrollLeft: 0,
    });

    const [isMouseDown, setIsMouseDown] = useState(false);

    return useMemo(() => {
        if (!enabled) {
            return {
                handleMouseMove: () => noop(),
                handleMouseDown: () => noop(),
                handleMouseUp: () => noop(),
            };
        }

        const handleMouseUp: MouseEventHandler<Scrollbars> = (e) => {
            const container = scrollbars?.container;

            if (!container) {
                return;
            }

            setIsMouseDown(false);
            container.style.cursor = 'initial';
            container.style.userSelect = 'initial';
        };

        const handleMouseDown: MouseEventHandler<Scrollbars> = (e) => {
            const container = scrollbars?.container;

            if (!container) {
                return;
            }

            mouseCoords.current = {
                startX: e.pageX - container.offsetLeft,
                scrollLeft: scrollbars.getValues().scrollLeft,
            };

            setIsMouseDown(true);
            container.style.cursor = 'grabbing';
            container.style.userSelect = 'none';
        };

        const handleMouseMove: MouseEventHandler<Scrollbars> = (e) => {
            if (!isMouseDown) {
                return;
            }

            const container = scrollbars?.container;

            if (!container) {
                return;
            }

            const x = e.pageX - container.offsetLeft;
            const scroll = x - mouseCoords.current.startX;

            scrollbars.scrollLeft(-scroll + mouseCoords.current.scrollLeft);
        };

        return {
            handleMouseDown,
            handleMouseUp,
            handleMouseMove,
        };
    }, [enabled, isMouseDown, scrollbars]);
};
