import {ViewFormat} from '../../ViewFormat';

export const CapacityMonthView: ViewFormat = {
    group: 'capaciteit',
    id: 'capaciteit-maand',
    label: 'planning.maand',
    view: {
        timeline: {
            type: 'month',
            startDay: 1,
            endDay: 5,
            size: 1,
            weekNumbers: true,
            eventList: true,
            resolutionHorizontal: 'hour',
        },
    },
};

export const CapacityQuarterView: ViewFormat = {
    group: 'capaciteit',
    id: 'capaciteit-kwartaal',
    label: 'planning.kwartaal',
    view: {
        timeline: {
            type: 'month',
            startDay: 1,
            endDay: 5,
            size: 3,
            weekNumbers: true,
            eventList: true,
        },
    },
};

export const CapacityWeekView: ViewFormat = {
    group: 'capaciteit',
    id: 'capaciteit-week',
    label: 'planning.week',
    preferred: true,
    view: {
        timeline: {
            type: 'week',
            startDay: 1,
            endDay: 5,
            weekNumbers: false,
            eventList: true,
        },
    },
};

export const CapacityViewFormats: ViewFormat[] = [
    CapacityWeekView,
    CapacityMonthView,
    CapacityQuarterView,
];
