import {useCallbackRef, useHandleError} from '@growthbase/spa';
import {ErrorMessage, FormikHelpers, useFormik, FormikContextType} from 'formik';
import {useMemo, FC} from 'react';
import {SchemaOf} from 'yup';
import {FormikForm, FormikFormProp} from '../Components';
import {StrictErrorMessageProps} from '../Type';
import {useAutoSubmit} from './useAutoSubmit';
import {useContinuesAutoSubmit} from './useContinuesAutoSubmit';
import {StrictFormState, useCreateStrictFormState} from './useCreateStrictFormState';
import {MappedStyledField, useNamedStyledFields} from './useNamedStyledFields';
import {useLockPopupAndRouting} from './useLockPopupAndRouting';

export interface CreateFieldFormOptions<T> {
    onSubmit: (values: T) => Promise<void>;
    createInitialValues: () => T;
    schema: SchemaOf<T>;
    /**
     * Save when focused is lost or attempts to close the popup (This will cancel the close action).
     *
     * This is default the value of continuesAutoSubmit.
     */
    autoSubmit?: boolean;
    /**
     * Continuously auto-submit the field during typing.
     */
    continuesAutoSubmit?: boolean;
    /**
     * Reset the typed values on submission.
     */
    disableResetAfterSubmit?: boolean;

    /**
     * Uses createInitialValuesAfterSubmit to create the next values state.
     */
    createInitialValuesAfterSubmit?: boolean;

    /**
     * Should Formik reset the form when createInitialValues changes.
     */
    disableReinitialize?: boolean;
    /**
     * After submit directly reset form for new input.
     *
     * Handy if you only have 1 field and want to preserve focus to the field.
     */
    doNoWaitForSubmit?: boolean;
    validateOnChange?: boolean;
    /**
     * Don't close models etc. This default the value of autoSubmit.
     */
    enableLockPopupAndRoutingWhenDirty?: boolean;
}

export interface StrictFormOptions<T extends object> {
    Fields: MappedStyledField<T>;
    ErrorMessage: FC<StrictErrorMessageProps<T>>;
    formik: FormikContextType<T>;
    formProps: {
        formik: FormikContextType<T>;
        state: StrictFormState;
    };
    Form: FC<FormikFormProp<T>>;
}

export function useCreateStrictForm<T extends object>({
    createInitialValues,
    onSubmit,
    schema,
    continuesAutoSubmit = false,
    autoSubmit = continuesAutoSubmit,
    disableResetAfterSubmit = false,
    doNoWaitForSubmit = false,
    disableReinitialize = false,
    validateOnChange = false,
    createInitialValuesAfterSubmit = false,
    enableLockPopupAndRoutingWhenDirty = autoSubmit,
}: CreateFieldFormOptions<T>): StrictFormOptions<T> {
    const handleError = useHandleError();

    const state = useCreateStrictFormState();
    const {onReset} = state;

    const onSubmitCallback = useCallbackRef((values: T, helpers: FormikHelpers<T>) => {
        let promise = Promise.resolve(onSubmit(schema.validateSync(values, {stripUnknown: true}) as T)).catch(
            handleError
        );
        if (doNoWaitForSubmit) {
            promise = Promise.resolve();
        }
        return promise.then(() => {
            if (disableResetAfterSubmit) {
                return;
            }
            if (createInitialValuesAfterSubmit) {
                const newValues = createInitialValues();
                helpers.resetForm({
                    values: newValues,
                });
                onReset.next(newValues);
            } else {
                helpers.resetForm();
                onReset.next(values);
            }
        });
    });

    const formik = useFormik<T>({
        enableReinitialize: !disableReinitialize,
        validationSchema: schema,
        validateOnChange,
        initialValues: useMemo(createInitialValues, [createInitialValues]),
        onSubmit: onSubmitCallback,
    });

    const options: StrictFormOptions<T> = {
        formik,
        formProps: {
            formik,
            state,
        },
        Form: FormikForm,
        ErrorMessage: ErrorMessage as FC<StrictErrorMessageProps<T>>,
        Fields: useNamedStyledFields<T>(schema),
    };

    useAutoSubmit(options, autoSubmit, state);
    useContinuesAutoSubmit(options, continuesAutoSubmit);
    useLockPopupAndRouting(options, enableLockPopupAndRoutingWhenDirty);

    return options;
}
