import React, {ReactNode} from 'react';
import {FloatingPortal} from '@floating-ui/react-dom-interactions';
import {FloatingNode, FloatingTree, useFloatingNodeId, useFloatingParentNodeId} from '@floating-ui/react';

export interface PopupPortalProps {
    children: ReactNode;
}

const PopupPortalComponent = ({children}: PopupPortalProps) => {
    const nodeId = useFloatingNodeId();

    return (
        <FloatingNode id={nodeId}>
            <FloatingPortal id="PopupPortal">{children}</FloatingPortal>
        </FloatingNode>
    );
};

export const PopupPortal = (props: PopupPortalProps) => {
    const parentId = useFloatingParentNodeId();

    if (parentId === null) {
        return (
            <FloatingTree>
                <PopupPortalComponent {...props} />
            </FloatingTree>
        );
    }

    return <PopupPortalComponent {...props} />;
};
