import {ReactNode, FC, PropsWithChildren} from 'react';
import {Link} from 'react-router-dom';
import {Heading4, Stack} from '@growthbase/design-components';
import {BordtemplateRoutes} from '@growthbase/takentemplates-bordtemplate';

export interface CardHeaderProps extends PropsWithChildren {
    id: string;
    actionMenu?: ReactNode;
}

export const CardHeader: FC<CardHeaderProps> = ({id, children, actionMenu}) => (
    <Stack justify align="start" horizontal>
        <Stack.Item shrink>
            <Link to={BordtemplateRoutes.bekijken.useUrl({bordtemplateId: id})} style={{textDecoration: 0}}>
                <Heading4>{children}</Heading4>
            </Link>
        </Stack.Item>
        <Stack.Item>{actionMenu}</Stack.Item>
    </Stack>
);
