import {ILijsttemplateSortableTabellenConnectedFragment} from '@growthbase/graphql';
import {PaginationHelpers} from '@growthbase/spa';
import React, {useCallback} from 'react';
import {onDropItemHandler, onRemoveItemHandler} from '@growthbase/keys';
import {ContainerData, Filters} from '../../../../types';
import {RenderSortableTabelType, RenderSortableTabelTypeProps, SortableTabelConnected} from '../SortableTabel';
import {SortableTabellen} from './SortableTabellen';

export interface TabelConnectedProps<
    TLijst extends ILijsttemplateSortableTabellenConnectedFragment = ILijsttemplateSortableTabellenConnectedFragment,
    TContainerData extends ContainerData = ContainerData
> {
    pagination: PaginationHelpers<TLijst>;
    userData: TContainerData;
    onDrop: onDropItemHandler<TLijst, TContainerData>;
    onRemove?: onRemoveItemHandler<TLijst>;
    filters: Filters;
}

export function SortableTabellenConnected<
    TLijst extends ILijsttemplateSortableTabellenConnectedFragment = ILijsttemplateSortableTabellenConnectedFragment,
    TContainerData extends ContainerData = ContainerData
>({filters, pagination, onRemove, userData, onDrop}: TabelConnectedProps<TLijst, TContainerData>) {
    const renderTabelLijst: RenderSortableTabelType<TLijst> = useCallback(
        ({item}: RenderSortableTabelTypeProps<TLijst>) => (
            <SortableTabelConnected item={item} filters={filters} bordtemplateId={userData.id} />
        ),
        [filters, userData.id]
    );
    return (
        <SortableTabellen<TLijst, TContainerData>
            lijsten={pagination.connections}
            userData={userData}
            onDrop={onDrop}
            onRemove={onRemove}
            renderLijst={renderTabelLijst}
        />
    );
}
