import {idFromIri} from '@growthbase/routing';
import {useSocketEvent} from '@growthbase/websockets';
import * as yup from 'yup';
import {InferType} from 'yup';
import {BaseNode} from '../baseNode';
import {PositieSchema} from './useExternalEntityAdded';

const EntityOrderChangedSchema = yup
    .object({
        id: yup.string().required(),
        typename: yup.string().required(),
        positie: PositieSchema.required(),
    })
    .label('EntityOrderChangedSchema')
    .required();

export type EntityOrderChangedSchemaDto = InferType<typeof EntityOrderChangedSchema>;

export const useExternalEntityChangeOrder = <TNode extends BaseNode>(
    callback: (args: {id: string; typename: string; node: Partial<TNode>}) => void
) => {
    useSocketEvent('OrderChanged', (data) => {
        const normalized = EntityOrderChangedSchema.validateSync(data);

        const node = {
            id: `${normalized.typename}_${idFromIri(normalized.id)}`,
            __typename: normalized.typename,
        } as unknown as TNode;

        callback({
            ...normalized,
            node,
        });
    });
};
