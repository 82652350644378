import {IEntityReferenceLinksFragment} from '@growthbase/graphql';
import {ReactNode, useEffect, useMemo} from 'react';
import {useToggle} from 'react-use';
import {EntityReferenceLinksRegistry} from '../EntityReferenceLinksRegistry';

export const useResolveReferenceLink = (reference: IEntityReferenceLinksFragment): ReactNode => {
    const [value, toggle] = useToggle(false);

    /**
     * Listen to resolver changes, so the urls (or logic will update.)
     */
    useEffect(() => {
        EntityReferenceLinksRegistry.changeListeners.push(toggle);
        return (): void => {
            EntityReferenceLinksRegistry.changeListeners.splice(
                EntityReferenceLinksRegistry.changeListeners.indexOf(toggle),
                1
            );
        };
    }, [toggle]);

    return useMemo(() => {
        for (const resolver of EntityReferenceLinksRegistry.resolvers) {
            const result = resolver(reference);
            if (result !== null) {
                return result;
            }
        }
        return `No resolver for reference ${reference.type} ${value}`;
    }, [reference, value]);
};
