import {RoutePopupProvider} from '@growthbase/design-components';
import {useLijstCreateFormConnectedToevoegenMutation} from '@growthbase/graphql';
import {RouteElementProps, useHistoryBack} from '@growthbase/routing';
import {CreateForm} from '@growthbase/takenlijst-lijst';
import {v4} from 'uuid';

export type LijstCreateFormConnectedProps = RouteElementProps<{
    bordId?: string;
}>;

export const LijstCreateFormConnected = ({params}: LijstCreateFormConnectedProps) => {
    const bordId = params?.bordId;
    if (!bordId) {
        throw new Error('Missing bordId');
    }
    const goBack = useHistoryBack();
    const [submit] = useLijstCreateFormConnectedToevoegenMutation();
    return (
        <RoutePopupProvider>
            <CreateForm
                onSubmit={(lijst) =>
                    submit({
                        variables: {
                            input: {
                                id: v4(),
                                bordId,
                                lijstTitel: lijst.titel,
                            },
                        },
                    }).then(goBack)
                }
            />
        </RoutePopupProvider>
    );
};
