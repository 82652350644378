import React from 'react';
import {StatusIconButton} from '../../../../Buttons';
import {Center} from '../../../../Center/Center';
import styles from './MoreLoaderFooter.module.scss';

export interface MoreLoaderFooterProps {
    loading?: boolean;
}

export const MoreLoaderFooter = ({loading = true}: MoreLoaderFooterProps) => {
    if (!loading) {
        return null;
    }
    return (
        <div className={styles.MoreLoaderFooter}>
            <Center horizontal>
                <StatusIconButton loading readonly />
            </Center>
        </div>
    );
};
