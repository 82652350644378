import React, {ReactNode, useCallback, useMemo} from 'react';
import {useCookie} from 'react-use';

export type SidenavContextType = {
    isExpanded: boolean;
    toggleExpanded: (expanded?: boolean) => void;
};

export const SidenavContext = React.createContext<SidenavContextType | null>(null);

export interface SidenavContextProviderProps {
    children: ReactNode;
    isExpandedDefault?: boolean;
    isExpandedOverride?: boolean;
}

export const SidenavContextProvider = ({
    children,
    isExpandedDefault = false,
    isExpandedOverride,
}: SidenavContextProviderProps) => {
    const [isExpandedS, setToggleExpanded] = useCookie('sidenav-expanded');
    const isExpanded = isExpandedOverride ?? isExpandedS === 'true';

    const toggleExpanded = useCallback(
        (expanded?: boolean): void => {
            if (expanded === undefined) {
                setToggleExpanded(isExpanded ? 'false' : 'true', {
                    path: '/',
                });
            } else {
                setToggleExpanded(isExpanded ? 'true' : 'false', {
                    path: '/',
                });
            }

            document.dispatchEvent(new Event('sidebar-toggled'));
        },
        [setToggleExpanded, isExpanded]
    );

    const contextProviderValues: SidenavContextType = useMemo(
        () => ({
            isExpanded,
            toggleExpanded,
        }),
        [isExpanded, toggleExpanded]
    );

    return <SidenavContext.Provider value={contextProviderValues}>{children}</SidenavContext.Provider>;
};
