import {
    CreateFieldFormOptions,
    SingleLineOpgemaakteTekstInput,
    useCreateStrictForm,
} from '@growthbase/design-components';
import {GebruikersComboboxIgnoreIds} from '@growthbase/gebruikers';
import {IRelatieverwijzingInput} from '@growthbase/graphql';
import {relatieIsEmpty, RelatieComboboxQuery} from '@growthbase/relaties';
import {DefaultKoppelbaarItem, DefaultRelatieValue, useCallbackRef, useMemoProp} from '@growthbase/spa';
import {useFormikContext} from 'formik';
import React, {ReactNode, useCallback, useEffect, useMemo} from 'react';
import {useTaakDefaultPrivate} from '@growthbase/taken';
import {createTaakInitialFormValues, MaakTaakAanTaakFormDTO, useTaakCreateSchema} from '../TaakCreateFormSchema';
import {useStripGebruikersFromTitel} from './useStripGebruikersFromTitel';
import {useStripRelatieFromTitel} from './useStripRelatieFromTitel';
import {useStripOnderdeelVanVanTitel} from './useStripOnderdeelVanVanTitel';

export interface CreateTaakFormProps
    extends Pick<
        CreateFieldFormOptions<MaakTaakAanTaakFormDTO>,
        'autoSubmit' | 'doNoWaitForSubmit' | 'createInitialValuesAfterSubmit'
    > {
    onSubmit: (values: MaakTaakAanTaakFormDTO) => Promise<void>;
}

const StripTitleField = () => {
    const formik = useFormikContext<MaakTaakAanTaakFormDTO>();
    useStripGebruikersFromTitel({formik});
    useStripRelatieFromTitel({formik});
    useStripOnderdeelVanVanTitel({formik});
    return null;
};

export const useCreateTaakForm = ({onSubmit, autoSubmit = false, ...rest}: CreateTaakFormProps) => {
    const defaultItem = DefaultKoppelbaarItem.useValue();
    const defaultRelatie = DefaultRelatieValue.useValue();
    const isPrive = useTaakDefaultPrivate();
    const results = useCreateStrictForm<MaakTaakAanTaakFormDTO>({
        onSubmit: useCallback((values) => onSubmit(values), [onSubmit]),
        autoSubmit,
        schema: useTaakCreateSchema(),
        enableLockPopupAndRoutingWhenDirty: false,
        createInitialValuesAfterSubmit: true,
        createInitialValues: useCallback(
            (): MaakTaakAanTaakFormDTO => ({
                ...createTaakInitialFormValues(),
                relatie: defaultRelatie || {},
                onderdeelVan: defaultItem,
                ...(isPrive ? {isPrive: true} : {}),
            }),
            [defaultRelatie, defaultItem, isPrive]
        ),
        ...rest,
    });

    const Titel = results.Fields.TitelField.useComponent(SingleLineOpgemaakteTekstInput);

    const deelnemers = useMemoProp(results.formik.values.deelnemers, 'id');

    const relatieValue = results.formik.values.relatie;
    const relatie = useMemo(
        (): IRelatieverwijzingInput | null =>
            relatieIsEmpty(relatieValue)
                ? null
                : {
                      persoonId: relatieValue.persoon?.id,
                      bedrijfId: relatieValue.bedrijf?.id,
                  },
        [relatieValue]
    );

    const renderTitel: typeof Titel = (props) => <Titel {...props} nested={<StripTitleField />} />;

    const {onderdeelVan} = results.formik.values;

    const clear = useCallbackRef(() => results.formik.setFieldValue('onderdeelVan', null));
    useEffect(() => {
        if (onderdeelVan && relatieIsEmpty(relatieValue)) {
            clear();
        }
    }, [relatieValue, onderdeelVan, clear]);

    const render = (children: ReactNode) => (
        <GebruikersComboboxIgnoreIds ids={deelnemers}>
            <RelatieComboboxQuery
                value={relatie}
                onChange={(newRelatie) => {
                    results.formik.setFieldValue('relatie', newRelatie);
                }}
            >
                {children}
            </RelatieComboboxQuery>
        </GebruikersComboboxIgnoreIds>
    );

    return {...results, render, renderTitel};
};
