import moment from 'moment';
import {useCallback, useMemo} from 'react';
import {DatePickerValue} from '../types/DatePickerValue';

export const useOnTimeChange = (
    value: DatePickerValue | null,
    onChange: (changedValue: DatePickerValue) => void
): [string, (date: string) => void] => [
    useMemo(() => {
        if (!value?.date || !value.timeUsed) {
            return '';
        }
        return moment(value.date).format('HH:mm');
    }, [value]),
    useCallback(
        (changeValue) => {
            const [hours, minutes] = changeValue.split(':') as [string, string];

            const momentValue = moment(value?.date);
            const intMinutes = parseInt(minutes, 10);
            const intHours = parseInt(hours, 10);
            const updated = momentValue.clone().startOf('day').add(intMinutes, 'minutes').add(intHours, 'hours');

            onChange({date: updated.toISOString(), timeUsed: true});
        },
        [onChange, value]
    ),
];
