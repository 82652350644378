import {FC} from 'react';

export interface HeaderProps {
    titel: string;
}

export const Header: FC<HeaderProps> = ({titel}) => (
    <div
        style={{
            border: '1px solid red',
        }}
    >
        {titel}
    </div>
);
