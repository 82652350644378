import {CreateFieldFormOptions, useCreateStrictForm} from '@growthbase/design-components';
import {useCallback} from 'react';
import {CreateFormDTO, createInitialFormValues, useCreateSchema} from './useCreateSchema';

export interface UseCreateFormOptions
    extends Pick<
        CreateFieldFormOptions<CreateFormDTO>,
        'autoSubmit' | 'doNoWaitForSubmit' | 'createInitialValuesAfterSubmit'
    > {
    onSubmit: (values: CreateFormDTO) => Promise<void>;
}

export const useCreateForm = ({onSubmit, autoSubmit = false, ...rest}: UseCreateFormOptions) => {
    const results = useCreateStrictForm<CreateFormDTO>({
        onSubmit: useCallback((values) => onSubmit(values), [onSubmit]),
        autoSubmit,
        schema: useCreateSchema(),
        enableLockPopupAndRoutingWhenDirty: false,
        createInitialValuesAfterSubmit: true,
        createInitialValues: createInitialFormValues,
        ...rest,
    });

    return {...results};
};
