import {ViewConfig} from '@growthbase/design-components';
import {DNDContainerUserData, onDropItemHandler, onRemoveItemHandler} from '@growthbase/keys';
import {PaginationHelpers} from '@growthbase/spa';
import {ReactNode} from 'react';
import {ITakentemplateswimlaneConnectedFragment} from '@growthbase/graphql';
import {HeaderConnected} from './Components/Header';
import {KaartConnected} from './Components/Kaart';
import {Takenswimlane} from './Takenswimlane';

export interface TakenswimlaneConnectedProps<
    TKaart extends ITakentemplateswimlaneConnectedFragment = ITakentemplateswimlaneConnectedFragment,
    TContainerData extends DNDContainerUserData = DNDContainerUserData
> extends ViewConfig {
    onDrop: onDropItemHandler<TKaart, TContainerData>;
    onRemove?: onRemoveItemHandler<TKaart>;
    userData: TContainerData;
    pagination: PaginationHelpers<TKaart>;
    header?: ReactNode;
    footer?: ReactNode;
    dragDisabled?: boolean;
    placeholder?: boolean;
}

export function TakenswimlaneConnected<
    TKaart extends ITakentemplateswimlaneConnectedFragment = ITakentemplateswimlaneConnectedFragment,
    TContainerData extends DNDContainerUserData = DNDContainerUserData
>({readonly, disabled, ...rest}: TakenswimlaneConnectedProps<TKaart, TContainerData>) {
    return (
        <Takenswimlane<TKaart, TContainerData> {...rest}>
            {({anchor, item, dragging, hoover}) => (
                <KaartConnected
                    disabled={disabled}
                    readonly={readonly}
                    dragging={dragging}
                    hoover={hoover}
                    fragment={item}
                    anchor={anchor}
                />
            )}
        </Takenswimlane>
    );
}

TakenswimlaneConnected.Header = HeaderConnected;
TakenswimlaneConnected.Kaart = KaartConnected;
