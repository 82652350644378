import React, {FC} from 'react';
import {BaseButtonProps, useMemoryPopupControls} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';
import {
    IVerwijderTaakButtonFragment,
    IVerwijderTaakButtonQueryQuery,
    IVerwijderTaakButtonQueryQueryVariables,
} from '@growthbase/graphql';
import {LazyQueryExecFunction} from '@apollo/client/react/types/types';
import {useCallbackRef} from '@growthbase/spa';
import {VerwijderTaakConfirmModal} from '../VerwijderTaakConfirmModal/VerwijderTaakConfirmModal';

export interface VerwijderTaakButtonProps {
    fragment?: IVerwijderTaakButtonFragment;
    qeury: LazyQueryExecFunction<IVerwijderTaakButtonQueryQuery, IVerwijderTaakButtonQueryQueryVariables>;
    onSubmit: () => void;
    SubmitButton: FC<BaseButtonProps>;
    isSubmitting: boolean;
}

export const VerwijderTaakButton: FC<VerwijderTaakButtonProps> = ({
    fragment,
    onSubmit,
    SubmitButton,
    qeury,
    isSubmitting,
}) => {
    const {t} = useTranslation('spa.nl');
    const helpers = useMemoryPopupControls(false);
    const titel = t('form.delete');

    const onSubmitAndCloseModel = useCallbackRef(() => {
        helpers.close();
        onSubmit();
    });

    const requestInfo = useCallbackRef(() => {
        qeury().then((result) => {
            const verplicht = !!result.data?.taak?.verwijderdBevestigingVerplicht;
            if (verplicht) {
                helpers.open();
            } else {
                onSubmit();
            }
        });
    });

    if (!fragment || !fragment.availableActions.kanVerwijderen) {
        return null;
    }

    return (
        <>
            <SubmitButton onClick={requestInfo} disabled={isSubmitting}>
                {titel}
            </SubmitButton>
            <VerwijderTaakConfirmModal
                onSubmit={onSubmitAndCloseModel}
                isSubmitting={isSubmitting}
                helpers={helpers}
                taaktitel={fragment.titel}
            />
        </>
    );
};
