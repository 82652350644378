import {IToggleDeelnameAanTaakFragment} from '@growthbase/graphql';
import {ActiveItemType, useActiveItem, useKeys} from '@growthbase/keys';
import {useToggleDeelnameAanTaak} from './useToggleDeelnameAanTaak';

export interface ToggleDeelnameAanTaakByKeyboardOptions {
    gebruikerId: string | null;
}

export const useToggleDeelnameAanTaakByKeyboard = ({gebruikerId}: ToggleDeelnameAanTaakByKeyboardOptions) => {
    const active = useActiveItem<IToggleDeelnameAanTaakFragment>({
        type: ActiveItemType.Taak,
    });
    const {loading, toggle} = useToggleDeelnameAanTaak({
        gebruikerId,
    });
    useKeys(
        `ToggleDeelnameAanTaak: toggle deelname aan taak ${active?.data?.id}}`,
        'space',
        (event) => {
            const item = active?.data;
            if (!item) {
                return;
            }
            if (toggle(item)) {
                event.preventDefault();
            }
        },
        {
            enabled: !!active && !loading,
            onKeyDown: true,
            enableOnInput: false,
        }
    );
    return {loading};
};
