import {InstellingFieldCollection} from '../InstellingFieldCollection';
import {ControlFieldNormalizerInterface} from './ControlFieldNormalizer';
import {
    ArrayValue,
    ArrayValueInput,
    BaseField,
    InstellingField,
    InstellingFieldInput,
    InstellingFieldsInput,
    InstellingFieldType,
    NestedValue,
    NestedValueInput,
    ScalarValue,
    ScalarValueInput,
} from '../types';
import {ControlFieldCollection} from '../ControlFieldCollection';

export class FieldsNormalizer {
    constructor(private controlFieldNormalizer: ControlFieldNormalizerInterface) {}

    public normalize(fieldsInput: InstellingFieldsInput<object>): InstellingFieldCollection {
        const fields = new InstellingFieldCollection([]);
        this.flattenFields(fields, fieldsInput);
        this.controlFieldNormalizer.normalize(fields);
        this.cleanup(fields);
        return fields;
    }

    private flattenField(
        fields: InstellingFieldCollection,
        typed: InstellingFieldInput<object, object, object>,
        name: string,
        parent?: InstellingField
    ): InstellingField {
        const fullName = parent ? `${parent.name}.${name}` : name;
        const basePath = fullName.split('.').pop() ?? '';

        const base: BaseField = {
            label: typed.label,
            input: typed,
            name: fullName,
            exclude: typed.exclude ?? false,
            FormComponent: typed.InputComponent,
            renderInputComponent: typed.renderInputComponent,
            ViewComponent: typed.ViewComponent,
            renderViewComponent: typed.renderViewComponent,
            parent: parent?.name,
            basePath,
        };

        if ('fields' in typed) {
            const nested = typed as NestedValueInput<object, object>;
            const converted: NestedValue = {
                type: InstellingFieldType.nested,
                fields: [],
                ...base,
                input: nested,
            };
            fields.add(converted);
            converted.fields = this.flattenFields(fields, nested.fields, converted).names();
            return converted;
        }

        if ('options' in typed) {
            const nested = typed as ArrayValueInput<object, string, object>;
            const converted: ArrayValue = {
                ...base,
                type: InstellingFieldType.array,
                controlFields: new ControlFieldCollection(),
                defaultValue: nested.defaultValue,
                options: nested.options,
                input: nested,
            };
            fields.add(converted);
            return converted;
        }

        if ('defaultValue' in typed) {
            const value = typed as ScalarValueInput<object, unknown, object>;
            const type = this.resolveType(value, name);
            const scalarValue: ScalarValue = {
                ...base,
                type,
                defaultValue: value.defaultValue,
                controlFields: new ControlFieldCollection(),
                input: typed,
            };
            fields.add(scalarValue);
            return scalarValue;
        }

        throw new Error(`Invalid field ${name}`);
    }

    private resolveType(value: ScalarValueInput<object, unknown, object>, name: string) {
        const type = typeof value.defaultValue;
        if (type === 'boolean') {
            return InstellingFieldType.boolean;
        }
        if (type === 'number') {
            return InstellingFieldType.number;
        }
        if (type === 'string') {
            return InstellingFieldType.string;
        }
        throw new Error(`Invalid type ${type} for field ${name}`);
    }

    private flattenFields(
        fields: InstellingFieldCollection,
        fieldsInput: InstellingFieldsInput<object>,
        parent?: InstellingField
    ): InstellingFieldCollection {
        const currentField: InstellingFieldCollection = new InstellingFieldCollection([]);
        for (const [name, value] of Object.entries(fieldsInput)) {
            const typed = value as InstellingFieldInput<object, object, object>;
            const normalized = this.flattenField(fields, typed, name, parent);
            currentField.add(normalized);
        }
        return currentField;
    }

    private cleanup(fields: InstellingFieldCollection) {
        for (const field of fields.getFields()) {
            delete field.input;
        }
    }
}
