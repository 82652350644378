import {ReactNode, FC} from 'react';
import {
    ActionsMenuButton,
    ButtonHandler,
    ConfirmModalButton,
    ModalContent,
    PopupHeader,
} from '@growthbase/design-components';
import {Trans, useTranslation} from 'react-i18next';

export interface VerwijderLijstActionsMenuButtonProps {
    lijstTitel: string;
    onSubmit: ButtonHandler;
    renderBevatTaken: (render: (bevatTaken: boolean) => ReactNode) => ReactNode;
}

export const VerwijderLijstActionsMenuButton: FC<VerwijderLijstActionsMenuButtonProps> = ({
    lijstTitel,
    onSubmit,
    renderBevatTaken,
}) => {
    const {t} = useTranslation('spa.nl');
    return (
        <>
            <ConfirmModalButton
                header={
                    <PopupHeader title={t('takenlijst.lijst.VerwijderLijstActionsMenuButton.Bevestigmodal.Titel')} />
                }
                onSubmit={onSubmit}
                popupButtonContent={t('takenlijst.lijst.VerwijderLijstActionsMenuButton.Bevestigmodal.Bevestigknop')}
                renderOpenButton={(props) => (
                    <ActionsMenuButton onClick={props.onClick}>
                        {t('takenlijst.lijst.VerwijderLijstActionsMenuButton.Dropdownknop')}
                    </ActionsMenuButton>
                )}
            >
                <ModalContent>
                    <div>
                        <p>
                            <Trans
                                t={t}
                                components={{strong: <strong />}}
                                values={{titel: `<strong>${lijstTitel}</strong>`}}
                            >
                                takenlijst.lijst.VerwijderLijstActionsMenuButton.Bevestigmodal.Tekst
                            </Trans>
                        </p>
                        {renderBevatTaken(
                            (bevatTaken) =>
                                bevatTaken &&
                                t(
                                    'takenlijst.lijst.VerwijderLijstActionsMenuButton.Bevestigmodal.AlleTakenWordenOokVerwijderd'
                                )
                        )}
                    </div>
                </ModalContent>
            </ConfirmModalButton>
        </>
    );
};
