import {ILijsttemplateTabelConnectedFragment} from '@growthbase/graphql';

import React, {FC, memo} from 'react';
import {TabelConnected as TaakTabelConnected} from '@growthbase/takentemplates-taaktemplate';
import {Filters} from '../../../../types';
import {Tabel} from './Tabel';
import {MenuConnected} from '../../../../../Menu';

interface TabelConnectedProps {
    fragment: ILijsttemplateTabelConnectedFragment;
    filters: Filters;
    header?: React.ReactNode;
}

export const TabelConnected: FC<TabelConnectedProps> = memo(({fragment, filters, header}) => {
    const {naam} = fragment;

    return (
        <Tabel
            header={header}
            render={(props) => <TaakTabelConnected header={props.header} fragment={fragment} filters={filters} />}
            naam={naam}
            menu={<MenuConnected fragment={fragment} />}
        />
    );
});
TabelConnected.displayName = 'TabelConnected';
