import {opgemaakteTekstAsText} from '@growthbase/design-components';
import {IMaakTaakAanTaakInput} from '@growthbase/graphql';
import {relatieIsEmpty} from '@growthbase/relaties';
import {v4} from 'uuid';
import {MaakTaakAanTaakFormDTO} from '../TaakCreateFormSchema';
import {KoppelbaarItemType} from '../../Koppelen/KoppelPopup';

export const normalizeMaakTaakAanTaakDTO = (dto: MaakTaakAanTaakFormDTO): IMaakTaakAanTaakInput => ({
    titel: opgemaakteTekstAsText(dto.titel),
    id: v4(),
    omschrijving: dto.omschrijving,
    isPrive: dto.isPrive,
    abonnement: dto.onderdeelVan?.type === KoppelbaarItemType.Abonnement ? dto.onderdeelVan.id : undefined,
    opdracht: dto.onderdeelVan?.type === KoppelbaarItemType.Opdracht ? dto.onderdeelVan.id : undefined,
    ticket: dto.onderdeelVan?.type === KoppelbaarItemType.Ticket ? dto.onderdeelVan.id : undefined,
    deelnemers: dto.deelnemers.map(({id}) => id),
    relatieverwijzing: relatieIsEmpty(dto.relatie)
        ? undefined
        : {
              id: v4(),
              persoonId: dto.relatie?.persoon?.id,
              bedrijfId: dto.relatie?.bedrijf?.id,
          },
    deadline: dto.planning.to
        ? {
              dateTime: dto.planning.to.date,
              gebruikTijd: dto.planning.to.timeUsed,
          }
        : undefined,

    begindatum: dto.planning.from
        ? {
              dateTime: dto.planning.from.date,
              gebruikTijd: dto.planning.from.timeUsed,
          }
        : undefined,
});
