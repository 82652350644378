import {useCallback, FC, ReactNode} from 'react';
import {BaseButtonProps} from '../../Button';
import {PopupAnchorProps} from '../Popup';

export const popupAnchorPropsAsButtonProps =
    (button: (props: BaseButtonProps) => ReactNode) =>
    ({reference, controls: {toggle}}: PopupAnchorProps) =>
        button({ref: reference, onClick: toggle});

export const usePopupAnchorPropsAsButtonProps = (button: (props: BaseButtonProps) => ReactNode) =>
    useCallback(() => popupAnchorPropsAsButtonProps(button), [button]);

export const renderPopupAnchorAsButton =
    (Button: FC<BaseButtonProps>) =>
    // eslint-disable-next-line react/display-name
    ({reference, controls: {toggle}}: PopupAnchorProps) =>
        <Button ref={reference} onClick={toggle} />;

export const useRenderPopupAnchorAsButton = (Button: FC<BaseButtonProps>) =>
    useCallback(() => renderPopupAnchorAsButton(Button), [Button]);
