import {FC, PropsWithChildren} from 'react';
import {BordRoutes} from '@growthbase/takenlijst/bord';
import {Link} from 'react-router-dom';

export const BestemmingBordLink: FC<{bordId: string} & PropsWithChildren> = ({bordId, children}) => {
    // TODO: link to onderdeel.
    const url = BordRoutes.view.useUrl({bordId});
    return (
        <Link to={url} target="_blank">
            {children}
        </Link>
    );
};
