/* eslint-disable */
import React, {FC, useMemo} from 'react';
import {useResolveConnections, useTaakLinkQuery} from '@growthbase/graphql';
import {Taak} from './TaakLink';
import {TakenRoutes} from '@growthbase/taken';
import {Link} from 'react-router-dom';

export interface TaakSearchFieldConnectedProps {
    id: string;
}

export const TaakLinkConnected: FC<TaakSearchFieldConnectedProps> = ({id}) => {
    const {data} = useTaakLinkQuery({
        skip: !id,
        variables: {
            id,
        },
    });

    const taken = useResolveConnections(data?.Taken);
    const taak = useMemo((): Taak | null => {
        if (taken.length == 1) {
            return taken[0];
        }
        return null;
    }, [id, taken]);

    const href = TakenRoutes.edit.useUrl({
        id,
    });

    if (!taak) {
        return null;
    }
    return <Link to={href}>{taak?.titel}</Link>;
};
