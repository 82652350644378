/* eslint-disable no-continue */
import {createDispatchHook} from '@growthbase/spa';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';

export type ErrorSourceID = string;

export interface ErrorCountState {
    errorCount: Record<ErrorSourceID, number>;
    totalErrorCount: number;
}

export interface ErrorCountStoreState {
    errorCount: ErrorCountState;
}

const initialMessageState: ErrorCountState = {
    errorCount: {},
    totalErrorCount: 0,
};

export const ErrorCountSlice = createSlice({
    name: 'errorCount',
    initialState: initialMessageState,
    reducers: {
        count: (
            state,
            action: PayloadAction<{
                id: ErrorSourceID;
                count: number;
            }>
        ) => {
            state.errorCount[action.payload.id] = action.payload.count;
            state.totalErrorCount = Object.values(state.errorCount).reduce((acc, val) => acc + val, 0);
        },
        unmount: (state, action: PayloadAction<ErrorSourceID>) => {
            delete state.errorCount[action.payload];
            state.totalErrorCount = Object.values(state.errorCount).reduce((acc, val) => acc + val, 0);
        },
    },
});

export const {count, unmount} = ErrorCountSlice.actions;

const useCount = createDispatchHook(count);
const useDestroy = createDispatchHook(unmount);

export const useSetErrorCount = (id: ErrorSourceID, value: number) => {
    const dispatch = useCount();
    const destroy = useDestroy();

    useEffect(() => {
        dispatch({id, count: value});
        return () => {
            destroy(id);
        };
    }, [id, value, dispatch, destroy]);
};
export const useTotalErrorCount = () =>
    useSelector(({errorCount: {totalErrorCount}}: ErrorCountStoreState) => totalErrorCount);
