import {FC} from 'react';
import {Icons, Stack} from '@growthbase/design-components';
import {IStacktraceFragment} from '@growthbase/graphql';

export interface StacktraceProps {
    stacktrace: IStacktraceFragment;
}

export const Stacktrace: FC<StacktraceProps> = ({stacktrace}) => (
    <Stack>
        <span>{stacktrace.message}</span>
        <span>Trace:</span>
        {stacktrace.lines.map((line, index) => (
            <Stack horizontal gap="tiny" align="center" key={index}>
                <a
                    href={line.ideUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{color: line.isVendorCode ? 'gray' : 'black'}}
                >
                    {line.file}:<span style={{color: 'blue'}}>{line.line}</span> {line.function}
                </a>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <div
                    style={{
                        cursor: 'copy',
                    }}
                    title={`Copy ${line.file}:${line.line} to clipboard`}
                    onClick={() => {
                        navigator.clipboard.writeText(`${line.file}:${line.line}`);
                    }}
                >
                    <Icons.Copy />
                </div>
            </Stack>
        ))}
        {stacktrace.previous && (
            <span>
                Previous:
                <Stacktrace stacktrace={stacktrace.previous} />
            </span>
        )}
    </Stack>
);
