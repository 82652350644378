import {
    AnchorAttributes,
    ActiveItemSource,
    ActiveItemType,
    RenderDraggableItemProps,
    useOnMouseHoverWithRef,
    DNDItemUserData,
    DNDUserDataType,
} from '@growthbase/keys';
import React, {forwardRef, memo, useCallback, useMemo} from 'react';
import classNames from 'classnames';
import {Cell, Row} from 'react-table';
import tableStyles from '../../Table.module.scss';
import styles from './DraggableTableRow.module.scss';
import {TableRowInterface} from '../../TableRowInterface';
import {Drag} from '../../../../icons/svg';

/* eslint-disable react/jsx-key */

export interface RenderDraggableTableRowProps<
    Type extends DNDUserDataType,
    TRow extends TableRowInterface & DNDItemUserData
> extends RenderDraggableItemProps<Type, TRow> {
    row: Row<TRow>;
    showAnchor?: boolean;
}

const Cells = memo(
    ({
        cells,
        anchor,
        dragging,
        showAnchor,
    }: {
        cells: Array<Cell<TableRowInterface & DNDItemUserData>>;
        dragging?: boolean;
        showAnchor: boolean;
        anchor?: AnchorAttributes;
    }) => (
        <>
            {cells.map((cell, i) => {
                if (i === 0) {
                    return (
                        <div
                            {...cell.getCellProps()}
                            data-test="cell"
                            className={classNames([tableStyles.table__td])}
                            style={{position: 'relative', ...cell.getCellProps().style}}
                        >
                            <div
                                {...anchor}
                                className={classNames(showAnchor && [styles.draggableTableRow__handler], {
                                    [styles['draggableTableRow__handler--dragging']]: dragging,
                                })}
                            >
                                {showAnchor && <Drag />}
                            </div>
                            {cell.render('Cell')}
                        </div>
                    );
                }
                return (
                    <div {...cell.getCellProps()} data-test="cell" className={classNames([tableStyles.table__td])}>
                        {cell.render('Cell')}
                    </div>
                );
            })}
        </>
    )
);
Cells.displayName = 'Cells';

export const DraggableTableRow = forwardRef<
    never,
    RenderDraggableTableRowProps<DNDUserDataType, TableRowInterface & DNDItemUserData>
>(({row, showAnchor = true, anchor, dragging, element}, forwardedRef) => {
    const {ref} = useOnMouseHoverWithRef<typeof row.original, HTMLDivElement>({
        data: row.original,
        source: ActiveItemSource.TableRow,
        type: ActiveItemType.Unknown,
    });
    const rowProps = row.getRowProps();
    return (
        <div
            ref={useCallback(
                (node: null) => {
                    if (forwardedRef) {
                        if (typeof forwardedRef === 'function') {
                            forwardedRef(node);
                        } else {
                            forwardedRef.current = node;
                        }
                    }
                    ref(node);
                    if (element.ref) {
                        element.ref(node);
                    }
                },
                [ref, element, forwardedRef]
            )}
            {...rowProps}
            style={useMemo(
                () => ({
                    ...rowProps.style,
                    ...element.style,
                }),
                [element.style, rowProps.style]
            )}
            data-test="row"
            data-testid={row.id}
            className={classNames([tableStyles.table__tr, styles.draggableTableRow], {
                [tableStyles['table__tr--locked']]: row.original.isLocked,
                [tableStyles['table__tr--dragged']]: dragging,
            })}
        >
            <Cells showAnchor={showAnchor} dragging={dragging} cells={row.cells} anchor={anchor} />
        </div>
    );
});
DraggableTableRow.displayName = 'DraggableTableRow';

export type DraggableTableRow<Type extends DNDUserDataType, TRow extends TableRowInterface & DNDItemUserData> = (
    props: RenderDraggableTableRowProps<Type, TRow>
) => React.ReactElement;

export function defaultDraggableTableRow<
    Type extends DNDUserDataType,
    TRow extends TableRowInterface & DNDItemUserData
>(props: RenderDraggableTableRowProps<Type, TRow>) {
    // @ts-expect-error - TS doesn't like the fact that we're using a generic type here
    return <DraggableTableRow {...props} />;
}

export function noAnchorDraggableTableRow<
    Type extends DNDUserDataType,
    TRow extends TableRowInterface & DNDItemUserData
>(props: RenderDraggableTableRowProps<Type, TRow>) {
    // @ts-expect-error - TS doesn't like the fact that we're using a generic type here
    return <DraggableTableRow showAnchor={false} {...props} />;
}
