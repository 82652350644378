import {TakenRoutes} from '@growthbase/taken';
import {useCallbackRef} from '@growthbase/spa';
import {NavigateOptions, createRouteHook} from '@growthbase/routing';
import {HoverIcon, useAddReferenceLinkResolver} from '@growthbase/design-components';
import React, {useCallback} from 'react';
import {Link} from 'react-router-dom';
import {BordRoutes} from './BordRoutes';

export type BordLijstParams = {lijstId: string; bordId: string | null};

const useLijstBewerkRouteFactory = (): ((params?: BordLijstParams, options?: NavigateOptions<never>) => string) => {
    const borden = BordRoutes.lijst_bewerken.useUrlFactory();
    const taken = TakenRoutes.lijst_bewerken.useUrlFactory();

    return useCallbackRef((params, options) => {
        const {lijstId, bordId} = params ?? {};
        if (!lijstId) {
            throw new Error('lijstId is required');
        }
        if (bordId) {
            return borden(
                {
                    bordId,
                    lijstId,
                },
                options
            );
        }

        return taken(
            {
                lijstId,
            },
            options
        );
    });
};

export const LijstRoutes = {
    bewerken: createRouteHook(useLijstBewerkRouteFactory, true),
};

BordRoutes.decorate((Decorated) => {
    const bewerk = LijstRoutes.bewerken.useUrlFactory();
    useAddReferenceLinkResolver(
        useCallback(
            (data) => {
                if (data.type === 'TakenLijst') {
                    const link = (
                        <Link
                            to={bewerk({
                                lijstId: data.id,
                                bordId: data.bord?.id ?? null,
                            })}
                        >
                            {data.text}
                        </Link>
                    );
                    return <HoverIcon>{link}</HoverIcon>;
                }
                return null;
            },
            [bewerk]
        )
    );
    return <Decorated />;
}, 'LijstRoutesLinkResolver');
