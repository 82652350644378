import {IEntityReferenceLinksFragment} from '@growthbase/graphql';
import {ReactNode} from 'react';

/**
 * We cannot directly use the routes components,
 * this will create a circular dependency.
 *
 * We don't we want to put any logic here, the entities can be deleted of the user does not have permissions,
 * maybe only show the text.
 */
export type ReferenceLinkResolver = (reference: IEntityReferenceLinksFragment) => null | ReactNode;

export const EntityReferenceLinksRegistry: {
    resolvers: ReferenceLinkResolver[];
    /**
     * When resolvers have changed.
     */
    changeListeners: Array<() => void>;
} = {
    resolvers: [],
    changeListeners: [],
};
