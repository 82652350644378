import {AvatarSize, PopupControlsContext, useMemoryPopupControls} from '@growthbase/design-components';
import {
    IVolgersFragment,
    useResolveConnections,
    useVerwijderVolgerMutation,
    useVoegVolgerToeMutation,
    useVolgersZoekenQuery,
} from '@growthbase/graphql';
import {useFilterEmptyValues, useWrapFunctionWithErrorHandler} from '@growthbase/spa';
import {useCallback, useMemo, useState, FC} from 'react';
import {useDebounce} from 'react-use';
import {Volgers} from './Volgers';

export interface VolgersConnectedProps {
    readonly?: boolean;
    disabled?: boolean;
    stackSize?: number;
    size?: AvatarSize;
    fragment?: IVolgersFragment;
}

export const VolgersConnected: FC<VolgersConnectedProps> = ({fragment, disabled, readonly, ...rest}) => {
    const taakId = fragment?.id ?? '';

    const [searchQuery, setSearchQuery] = useState<string | null>(null);
    const [searchQueryDebounced, setSearchQueryDebounced] = useState<string | null>(null);
    useDebounce(
        () => {
            setSearchQueryDebounced(searchQuery);
        },
        400,
        [searchQuery]
    );

    readonly = !fragment?.availableActions?.kanVolgersBewerken || readonly;
    const controls = useMemoryPopupControls(false);
    const {data, loading} = useVolgersZoekenQuery({
        skip: !controls.isOpen || readonly,
        variables: {
            taakId,
            fulltextSearch: searchQueryDebounced,
        },
    });
    const voegToe = useWrapFunctionWithErrorHandler(useVoegVolgerToeMutation()[0], undefined);
    const verwijder = useWrapFunctionWithErrorHandler(useVerwijderVolgerMutation()[0], undefined);
    const volgers = useFilterEmptyValues(fragment?.volgers);
    return (
        <PopupControlsContext.Provider value={controls}>
            <Volgers
                disabled={!fragment || disabled}
                readonly={readonly}
                {...rest}
                items={useMemo(() => volgers.map(({gebruiker}) => ({...gebruiker, selected: true})), [volgers])}
                popupItems={useResolveConnections(data?.Gebruikers)}
                onRemove={useCallback(
                    ({gebruikerId}) =>
                        verwijder({
                            variables: {
                                input: {
                                    gebruikerId,
                                    id: taakId,
                                },
                            },
                        }),
                    [taakId, verwijder]
                )}
                onAdd={useCallback(
                    ({gebruikerId}) =>
                        voegToe({
                            variables: {
                                input: {
                                    gebruikerId,
                                    id: taakId,
                                },
                            },
                        }),
                    [taakId, voegToe]
                )}
                onChangeQuery={(input) => {
                    setSearchQuery(input.query);
                }}
                searchQuery={searchQuery}
                loading={loading}
            />
        </PopupControlsContext.Provider>
    );
};
