import {IBestandThumbnailBestandFragment, IBestandThumbnailThumbnailEdgeFragment} from '@growthbase/graphql';
import {FC} from 'react';
import {BestandThumbnail} from './BestandThumbnail';

export interface BestandThumbnailConnectedProps {
    fragment: IBestandThumbnailBestandFragment & {
        thumbnail: IBestandThumbnailThumbnailEdgeFragment;
    };
}

export const BestandThumbnailConnected: FC<BestandThumbnailConnectedProps> = ({fragment}) => {
    const thumbnailBestand = fragment.thumbnail?.node;
    const {uploaded} = fragment;
    const {path, type, id} = fragment;
    const bestand = thumbnailBestand?.bestand;
    return <BestandThumbnail id={id} path={path} type={type} url={bestand?.url} uploaded={uploaded} />;
};
