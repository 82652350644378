import i18n, {TFunction} from 'i18next';
import {initReactI18next} from 'react-i18next';
import {setLocale} from 'yup';
import * as locales from 'yup-locales';
import {LocaleObject} from 'yup/lib/locale';
import {ClientApplicationConfigDTO} from '../DTO';

export interface TranslationDependencies {
    config: ClientApplicationConfigDTO;
}

export interface TranslationServices {
    i18n: typeof i18n;
    t: TFunction;
}

export const createTranslationServices = ({config}: TranslationDependencies): TranslationServices => {
    const object = (locales as Record<string, LocaleObject>)[config.locale];
    if (object) {
        setLocale(object);
    }
    i18n.use(initReactI18next) // passes i18n down to react-i18next
        .init({
            resources: config.translations,
            lng: config.locale, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
            // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
            // if you're using a language detector, do not define the lng option
            interpolation: {
                escapeValue: false, // react already safes from xss
            },
        });
    return {
        i18n,
        t: i18n.t.bind(i18n),
    };
};
