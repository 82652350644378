import classNames from 'classnames/bind';
import {ReactNode, FC} from 'react';
import {useDateObject} from '@growthbase/spa';
import classes from './Activity.module.scss';
import {RelativeTime} from '../../RelativeTime/RelativeTime';

const cx = classNames.bind(classes);

export interface ActivityProps {
    description: ReactNode;
    time: string;
    referenceImage: ReactNode;
    entityReferences: ReactNode;
}

export const Activity: FC<ActivityProps> = ({referenceImage, description, time, entityReferences}) => (
    <div data-testid="Activity" className={cx('activity')}>
        {referenceImage}
        <div className={classes.activity__content}>
            <div data-testid="Omschrijving" className={cx('activity__text')}>
                {description}
            </div>
            <div data-testid="Tijd" className={cx('activity__time')}>
                <RelativeTime time={useDateObject(time)} />
            </div>
            {entityReferences && (
                <div data-testid="Referenties" className={cx('activity__references')}>
                    {entityReferences}
                </div>
            )}
        </div>
    </div>
);
