import {QueryResult} from '@apollo/client/react/types/types';
import {
    IMijnTakenOverzichtQuery,
    IMijnTakenOverzichtQueryVariables,
    IMijnTakenOverzichtRowFragment,
    useMijnTakenOverzichtMaakEenTaakAanMutation,
} from '@growthbase/graphql';
import {TaakExtraProps, useAddTaakLockHandler} from '@growthbase/taken';
import {useCallback} from 'react';

/**
 * - Add the taak to the table before submitting the form to the api.
 * - Locks the taak during the api call.
 */
export const useAddTaakHandler = (
    result: QueryResult<IMijnTakenOverzichtQuery, IMijnTakenOverzichtQueryVariables>,
    voorVandaag = false
) => {
    const [mutateAsync] = useMijnTakenOverzichtMaakEenTaakAanMutation();
    return useAddTaakLockHandler(
        result,
        (data) =>
            mutateAsync({
                variables: {
                    input: {
                        ...data,
                        ikNeemDeel: true,
                        voorVandaag,
                    },
                },
            }),
        useCallback(
            (): TaakExtraProps<IMijnTakenOverzichtRowFragment> => ({
                type: 'Taak',
            }),
            []
        )
    );
};
