import {
    AcceptDroppable,
    DND,
    DNDContainerUserData,
    DNDItemUserData,
    DNDUserDataType,
    onDropItemHandler,
    onRemoveItemHandler,
    type RenderDraggableItem,
    RenderDraggableItemProps,
} from '@growthbase/keys';
import {PaginationHelpers} from '@growthbase/spa';
import {HTMLAttributes, ReactNode, useCallback} from 'react';
import styles from './Swimlane.module.scss';
import {SwimlaneBody, SwimlanePlaceholder} from './Components';
import {Icons} from '../icons';

export type RenderSwimlaneCardProps<TCard extends DNDItemUserData> = {
    item: TCard;
    dragging: boolean;
    hoover: boolean;
    anchor?: HTMLAttributes<HTMLElement>;
};

export interface SwimlaneProps<
    TType extends DNDUserDataType,
    TCard extends DNDItemUserData,
    TSwimlane extends DNDContainerUserData = DNDContainerUserData
> {
    pagination: PaginationHelpers<TCard>;

    userData: TSwimlane;

    type: TType;
    onDrop: onDropItemHandler<TCard, TSwimlane>;

    onRemove?: onRemoveItemHandler<TCard>;

    header?: ReactNode;
    footer?: ReactNode;

    children: (props: RenderSwimlaneCardProps<TCard>) => ReactNode;

    accept?: AcceptDroppable<TType, TCard, TSwimlane>;

    renderReadOnly?: ((item: TCard) => ReactNode) | false;
    placeholder?: boolean;
}

export function Swimlane<
    TType extends DNDUserDataType,
    TCard extends DNDItemUserData,
    TSwimlane extends DNDContainerUserData = DNDContainerUserData
>({
    onRemove,
    userData,
    type,
    onDrop,
    pagination,
    header,
    footer,
    accept,
    children: renderItem,
    renderReadOnly,
    placeholder,
}: SwimlaneProps<TType, TCard, TSwimlane>) {
    const renderDraggedItem: RenderDraggableItem<TType, TCard> = useCallback(
        (props: RenderDraggableItemProps<TType, TCard>) =>
            renderItem({
                item: {
                    ...props.item.userData,
                    id: `dragged-item-${props.item.userData.id}`,
                },
                dragging: true,
                hoover: true,
                anchor: props.anchor,
            }),
        [
            renderItem,
        ]
    );

    if (placeholder) {
        return <SwimlanePlaceholder />;
    }

    if (renderReadOnly) {
        return (
            <div className={styles.swimlane}>
                {header}
                <SwimlaneBody>
                    {pagination.isLoading && <Icons.LoadingAnimated />}
                    {pagination.connections.map((item) => (
                        <div key={item.id}>{renderReadOnly(item)}</div>
                    ))}
                </SwimlaneBody>
                {footer}
            </div>
        );
    }
    return (
        <div className={`${styles.swimlane} swimlane`}>
            {header}
            <DND.Container<TType, TCard, TSwimlane>
                userDataType={type}
                userData={userData}
                onDrop={onDrop}
                onRemove={onRemove}
                accept={accept}
                renderDraggedItem={renderDraggedItem}
                items={pagination.connections}
            >
                {({items, element}) => (
                    <SwimlaneBody innerRef={element.ref}>
                        {pagination.isLoading && <Icons.LoadingAnimated />}
                        {items.map((item) => (
                            <DND.Item item={item} key={item.id}>
                                {({element: itemElement, dragging, anchor}) => (
                                    <div {...itemElement}>
                                        {renderItem({
                                            item: item.userData,
                                            anchor,
                                            hoover: false,
                                            dragging: dragging ?? false,
                                        })}
                                    </div>
                                )}
                            </DND.Item>
                        ))}
                    </SwimlaneBody>
                )}
            </DND.Container>
            {footer}
        </div>
    );
}
