import React, {useCallback} from 'react';
import {Eventcalendar, localeNl, MbscCalendarEventData, MbscPageLoadingEvent, momentTimezone} from '@mobiscroll/react';
import {
    CalendarDay,
    CalendarWrapper,
    CalenderItemWithoutMargin,
    Icons,
    Loader,
    Medewerker,
} from '@growthbase/design-components';
import {MbscCalendarDayData} from '@mobiscroll/react/dist/src/core/shared/calendar-view/calendar-day';
import {useCallbackRef} from '@growthbase/spa';
import moment from 'moment';
import {useSelector} from 'react-redux';
import {CapacityEvent} from './CapacityEvent';
import {CapacityItem} from '../../Components/CapacityItem/CapacityItem';
import {CapacityViewFormats} from './CapaciteitViewFormats';
import {EventResource} from '../types';
import {PlanningStoreState, usePlanningSetViewedDateRange} from '../../Redux';
import {useCurrentView, useRenderHeader, useOnChangeDate, AgendaModuleType} from '../../_hooks';

export type DateTime = string;

/**
 * Links Event to Resource;
 */
export type ResourceEventReference = number | string;

export interface Event {
    start: DateTime;
    end: DateTime;
    title: string;
    resource: ResourceEventReference;
}

export interface Resource {
    resource: ResourceEventReference;
}

export interface MedewerkerResource extends Resource, Medewerker {
    type: 'Medewerker';
}

export interface Color {
    cellCssClass?: string;
    start: string;
    end: string;
    title: string;
    background: string;
    resource: string;
}

export interface CapaciteitViewProps {
    resources: EventResource[];
    events: CapacityEvent[];
    loading?: boolean;
    module?: AgendaModuleType;
}

export const CapaciteitView = ({resources, events, loading, module}: CapaciteitViewProps) => {
    const viewFormat = useCurrentView();
    const setViewedDateRange = usePlanningSetViewedDateRange();
    const {date: selectedDate} = useSelector((state: PlanningStoreState) => state.planning);
    const renderHeader = useRenderHeader(CapacityViewFormats, module);
    const onDateChange = useOnChangeDate();

    const onPageLoading = useCallback(
        (event: MbscPageLoadingEvent) => {
            setTimeout(() => {
                setViewedDateRange({
                    start: event.firstDay,
                    end: event.lastDay,
                });
            }, 1);
        },
        [setViewedDateRange]
    );

    const renderDay = useCallbackRef(({date}: MbscCalendarDayData) => {
        const isToday = moment(date).format('DDDD-GGGG') === moment().format('DDDD-GGGG');
        return <CalendarDay date={date} active={isToday} />;
    });

    const renderEvent = useCallbackRef((itemToRender: MbscCalendarEventData) => {
        const originalEvent = itemToRender.original as CapacityEvent;
        return (
            <CalenderItemWithoutMargin>
                <CapacityItem {...originalEvent} />
            </CalenderItemWithoutMargin>
        );
    });

    return (
        <>
            {loading && <Loader fixed />}
            <CalendarWrapper>
                <Eventcalendar
                    theme="ios"
                    height="auto"
                    themeVariant="light"
                    locale={localeNl}
                    timezonePlugin={momentTimezone}
                    displayTimezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                    dragTimeStep={60}
                    view={viewFormat.view}
                    resources={resources}
                    data={events}
                    onPageLoading={onPageLoading}
                    // renderResource={renderResource}
                    renderHeader={renderHeader}
                    renderScheduleEvent={renderEvent}
                    renderDay={renderDay}
                    returnFormat="jsdate"
                    selectedDate={selectedDate}
                    onSelectedDateChange={(event) => onDateChange(event.date as Date)}
                    // @ts-expect-error type is not correct
                    todayText={<Icons.Calendar />}
                />
            </CalendarWrapper>
        </>
    );
};
