import {FieldConditions} from '@growthbase/querybuilder';
import {Field, FieldInstance} from '../contract';
import {ConditionsValueType} from '../Types';

export const OR: Field<FieldInstance[]> = {
    Component: FieldConditions,
    label: (t) => t('Or', {ns: 'querybuilder.nl'}),
    valueType: ConditionsValueType,
    name: 'OR',
};

export const AND: Field<FieldInstance[]> = {
    Component: FieldConditions,
    label: (t) => t('And', {ns: 'querybuilder.nl'}),
    valueType: ConditionsValueType,
    name: 'AND',
};
