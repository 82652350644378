import {asMomentValues, ISOStringDate} from '../Hook/useDateSchema';

/**
 * When one value is after or before the other. We change the other value
 * base on difference.
 *
 * @param changedValue
 * @param previousValue
 */
export const normalizeDateValue = (changedValue: ISOStringDate, previousValue: ISOStringDate): ISOStringDate => {
    const dateChanged = changedValue?.date;
    if (!dateChanged) {
        return changedValue;
    }
    const {date} = asMomentValues({date: dateChanged});

    if (!date) {
        return previousValue;
    }

    return {
        date: {
            timeUsed: dateChanged.timeUsed,
            date: date.toISOString(),
        },
    };
};
