import {useOverlaybestandsViewFallbackLazyQuery} from '@growthbase/graphql';
import {useCallbackRef} from '@growthbase/spa';
import {ReactNode, useContext, useCallback} from 'react';
import {BestandSlideshowApi, SlideshowContext, Bestand, useBestandSlideshow} from './useBestandSlideshow';

export const useBestandView = (
    bestandId: string
): {
    open: () => Promise<void>;
} => {
    const parentSlideShow = useContext(SlideshowContext);
    return {
        open: useCallbackRef(() => parentSlideShow?.view(bestandId) ?? Promise.reject(new Error('No slideshow found'))),
    };
};

/**
 * This will open parent slideshow if there is any, otherwise it will open it's own slideshow with a single slide.
 */
export const useBestandViewWithFallback = (
    bestandId: string,
    children?: ReactNode
): Omit<BestandSlideshowApi, 'isOpen'> => {
    const parentSlideShow = useContext(SlideshowContext);
    const [fetch] = useOverlaybestandsViewFallbackLazyQuery();
    const loadBestanden = useCallback(
        () =>
            fetch({
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'network-only',
                variables: {
                    id: bestandId,
                },
            }).then((result): Bestand[] => {
                if (!result.data?.bestand) {
                    throw new Error('Bestand not found');
                }
                return [result.data.bestand as Bestand];
            }),
        [bestandId, fetch]
    );
    const slideshowId = `bestand-${bestandId}`;
    const fallbackSlideShow = useBestandSlideshow({
        render: children ?? null,
        id: slideshowId,
        load: loadBestanden,
    });
    return {
        id: slideshowId,
        slideshow: fallbackSlideShow.slideshow,
        view: useCallbackRef((toOpen: string) => {
            if (parentSlideShow) {
                return parentSlideShow.view(toOpen).catch(() => fallbackSlideShow.view(toOpen));
            }
            return fallbackSlideShow.view(toOpen);
        }),
        open: useCallbackRef(() => {
            if (parentSlideShow) {
                return parentSlideShow.view(bestandId).catch(() => fallbackSlideShow.view(bestandId));
            }
            return fallbackSlideShow.view(bestandId).catch(() => fallbackSlideShow.view(bestandId));
        }),
        close: useCallbackRef(() => {
            parentSlideShow?.close();
            fallbackSlideShow.close();
        }),
    };
};
