import {DynamicContentServices, DynamicPosition} from '@growthbase/dynamiccontent';
import {ReduxUserDependencies} from '@growthbase/spa';
import {Dashboard} from './Dashboard';
import {ErrorCountSlice} from './Redux/ErrorCounterReducer';

export const createDeveloperService = ({
    configs = [],
    reducers = {},
}: DynamicContentServices & ReduxUserDependencies): DynamicContentServices & ReduxUserDependencies => ({
    configs: [
        ...configs,
        {
            Component: () => <Dashboard />,
            position: DynamicPosition.root,
        },
    ],
    reducers: {
        ...reducers,
        errorCount: ErrorCountSlice.reducer,
    },
});
