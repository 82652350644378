import {ActionsMenuTrigger, StatusIconButton, ViewConfig} from '@growthbase/design-components';
import {IKaartConnectedFragment} from '@growthbase/graphql';
import {TaakPlanningButtonConnected, TaakOmschrijvingTooltipConnected} from '@growthbase/taken';
import {FC, HTMLAttributes, memo} from 'react';
import {MenuButtonModifiers} from '@szhsin/react-menu';
import {RelatieButtonContentInline, RelatieButtonContentProps} from '@growthbase/relaties';
import {BijlagesTooltipConnected} from '@growthbase/taak-bijlage';
import {TimeRegistrationButtonConnected, useLegacyTimeRegistrationIsActive} from '@growthbase/clientbox-uren';
import {TaakActionMenuConnected} from '../../../TaakActionMenu';
import {TaakReactieCounterConnected} from '../../../TaakReactieCounter';
import {VoltooiOfActiveerTaakButtonConnected} from '../../../VoltooiOfActiveerTaakButton';
import {TitelConnected} from './Components/Titel';
import {Kaart} from './Kaart';
import {TaakRelatieButtonConnected} from '../../../Relaties/TaakRelatieButtonConnected';
import {DeelnemersConnected} from '../../../Deelnemers/DeelnemersConnected';

// @todo: simplify anchor prop to memoize this component
export interface KaartConnectedProps extends ViewConfig {
    anchor: HTMLAttributes<HTMLElement> | undefined;
    fragment: IKaartConnectedFragment;
    dragging?: boolean;
    hoover?: boolean;
}

const RelatieButtonContentElement = (relatieContentProps: RelatieButtonContentProps) => (
    <RelatieButtonContentInline {...relatieContentProps} textSize="small" iconsSize="small" />
);

export const KaartConnectedInner: FC<KaartConnectedProps> = ({anchor, dragging, hoover, ...rest}) => {
    const {fragment} = rest;
    const hasPlanning = fragment.planning?.begindatum || fragment.planning?.deadline;
    const hasRelatie = !!fragment.relatie?.relatieverwijzing?.id;
    const {actief, profiel} = useLegacyTimeRegistrationIsActive(fragment.id);
    return (
        <Kaart
            hasBijlages={fragment.heeftBijlages.heeftBijlages}
            hasOmchrijving={fragment.heeftOmschrijving}
            bijlages={<BijlagesTooltipConnected fragment={fragment} />}
            omschrijving={<TaakOmschrijvingTooltipConnected fragment={fragment} />}
            titel={<TitelConnected {...rest} />}
            status={<VoltooiOfActiveerTaakButtonConnected {...rest} SubmitButton={StatusIconButton} />}
            deelnemers={<DeelnemersConnected {...rest} stackSize={3} size="small" />}
            menu={
                <TaakActionMenuConnected
                    {...rest}
                    renderButton={({open}: MenuButtonModifiers) => <ActionsMenuTrigger active={open} />}
                />
            }
            reacties={<TaakReactieCounterConnected {...rest} />}
            relatie={
                <TaakRelatieButtonConnected
                    {...rest}
                    readonly={hasRelatie}
                    ContentElement={RelatieButtonContentElement}
                />
            }
            hasDeelnemers={!!fragment.deelnemers?.length}
            hasReacties={!!fragment.reacties?.totalCount}
            hasPlanning={!!hasPlanning}
            hasRelatie={hasRelatie}
            renderPlanning={(renderTaakPlanningProps) => (
                <TaakPlanningButtonConnected {...rest} {...renderTaakPlanningProps} />
            )}
            uren={
                profiel ? <TimeRegistrationButtonConnected profielFragment={profiel} taakFragment={fragment} /> : null
            }
            anchor={anchor}
            dragging={dragging}
            hoover={hoover}
            hasActiveUrenBoeking={actief}
        />
    );
};

export const KaartConnected = memo(KaartConnectedInner);
