import {isEqual} from 'lodash';
import {useDebounce} from 'react-use';
import type {StrictFormOptions} from './useCreateStrictForm';

export const useContinuesAutoSubmit = <T extends object>({formik}: StrictFormOptions<T>, enabled: boolean) => {
    const {handleSubmit, resetForm, values, initialValues, isSubmitting} = formik;
    useDebounce(
        () => {
            if (!enabled) {
                return;
            }
            if (isSubmitting) {
                return;
            }
            if (isEqual(values, initialValues)) {
                return;
            }
            handleSubmit();
            resetForm({values});
        },
        250,
        [enabled, handleSubmit, isSubmitting, resetForm, values]
    );
    return null;
};
