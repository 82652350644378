import {ReactNode} from 'react';
import classNames from 'classnames/bind';
import styles from './BoardCard.module.scss';

const cx = classNames.bind(styles);

export interface BoardCardProps {
    children: ReactNode;
}

export const BoardCard = ({children}: BoardCardProps) => <div className={cx('boardCard')}>{children}</div>;
