import {FC} from 'react';
import {useTranslation} from 'react-i18next';

export type PositieLabelProps = {
    index: number;
    title: string;
    onderaan?: boolean;
};

export const PositieLabel: FC<PositieLabelProps> = ({index, title, onderaan}) => {
    const {t} = useTranslation('TakenlijstLijst.nl');
    const positie = index + 1;
    if (!onderaan) {
        return (
            <>
                {positie}- {title}
            </>
        );
    }
    if (positie === 1) {
        return <>{positie}</>;
    }
    return (
        <>
            {positie} - {t('Verplaatsen.onderaanLijst')}
        </>
    );
};
