import {PaginationHelpers, useCallbackRef, usePaginationMapper} from '@growthbase/spa';
import {IApplicationLogFragment} from '@growthbase/graphql';
import {FC} from 'react';
import {Stack, TextColorByHash} from '@growthbase/design-components';
import moment from 'moment/moment';
import {noop} from 'lodash';
import {ApplicationLogDTO} from '../ApplicationLogsTable';
import {dutchFormat} from '../../../Projections/LogsTable';
import {useFilterBarStateUrlFactory} from '../Components';

const Profiel: FC<{log: IApplicationLogFragment}> = ({
    log: {profielId, gebruikerId, clientboxProfielId, clientboxGebruikerId, profielNaam, gebruikersNaam},
}) => {
    const [, navigate] = useFilterBarStateUrlFactory();

    let profile = profielId ? (
        <TextColorByHash
            title="application"
            onClick={() => {
                navigate([
                    {
                        profielId,
                    },
                ]);
            }}
        >
            {profielId}
        </TextColorByHash>
    ) : null;

    if (clientboxProfielId) {
        profile = (
            <>
                {profile} - {clientboxProfielId}
            </>
        );
    }

    if (profielNaam) {
        profile = (
            <>
                {profielNaam} ({profile})
            </>
        );
    }

    let gebruiker = gebruikerId ? (
        <TextColorByHash
            title="application"
            onClick={() => {
                navigate([
                    {
                        gebruikerId,
                    },
                ]);
            }}
        >
            {gebruikerId}
        </TextColorByHash>
    ) : null;

    if (clientboxGebruikerId) {
        gebruiker = (
            <>
                {gebruiker} - {clientboxGebruikerId}
            </>
        );
    }

    if (gebruikersNaam) {
        gebruiker = (
            <>
                {gebruikersNaam} ({gebruiker})
            </>
        );
    }

    return (
        <Stack>
            <Stack horizontal gap="tiny">
                {profile}
            </Stack>
            <Stack horizontal gap="tiny">
                {gebruiker}
            </Stack>
        </Stack>
    );
};

const Source: FC<{log: IApplicationLogFragment}> = ({
    log: {processId, previousProcessId, transactionDepth, transactionId, facility, application},
}) => {
    const [, navigate] = useFilterBarStateUrlFactory();
    return (
        <Stack>
            <TextColorByHash
                title="application"
                onClick={
                    application
                        ? () => {
                              navigate([
                                  {
                                      clientbox: application === 'clientbox' ? true : undefined,
                                      growthbase: application === 'growthbase' ? true : undefined,
                                  },
                              ]);
                          }
                        : noop
                }
            >
                {application}
            </TextColorByHash>
            <Stack horizontal gap="tiny">
                <TextColorByHash
                    title="processId"
                    onClick={
                        processId
                            ? () => {
                                  navigate([
                                      {
                                          processIds: [processId],
                                      },
                                  ]);
                              }
                            : noop
                    }
                >
                    {processId}
                </TextColorByHash>
                {previousProcessId && (
                    <TextColorByHash
                        title="previousProcessId"
                        onClick={() => {
                            navigate([
                                {
                                    previousProcessIds: [previousProcessId],
                                },
                            ]);
                        }}
                    >
                        {previousProcessId}
                    </TextColorByHash>
                )}
            </Stack>
            <Stack horizontal gap="tiny">
                <TextColorByHash
                    title="transactionId"
                    onClick={
                        transactionId
                            ? () => {
                                  navigate([
                                      {
                                          transactionIds: [transactionId],
                                      },
                                  ]);
                              }
                            : noop
                    }
                >
                    {transactionId}
                </TextColorByHash>
                <TextColorByHash
                    title="transactionDepth"
                    noColor
                    onClick={
                        typeof transactionDepth === 'number'
                            ? () => {
                                  navigate([
                                      {
                                          transactionDepth: [transactionDepth],
                                      },
                                  ]);
                              }
                            : noop
                    }
                >
                    ({transactionDepth})
                </TextColorByHash>
            </Stack>
            <TextColorByHash
                title="facility"
                onClick={
                    facility
                        ? () => {
                              navigate([
                                  {
                                      facilities: [facility],
                                  },
                              ]);
                          }
                        : noop
                }
            >
                {facility}
            </TextColorByHash>
        </Stack>
    );
};

const Info: FC<{log: IApplicationLogFragment}> = ({log: {timestamp, level}}) => (
    <Stack>
        <Stack.Item>{moment(timestamp).format(dutchFormat)}</Stack.Item>
        <Stack.Item>{level}</Stack.Item>
    </Stack>
);

const Bericht: FC<{log: IApplicationLogFragment}> = ({
    log: {message, cliCommand, requestIp, requestRoute, requestUri},
}) => {
    const [, navigate] = useFilterBarStateUrlFactory();
    return (
        <Stack>
            {cliCommand && (
                <Stack.Item>
                    <TextColorByHash maxTextSize={1000} onClick={() => navigate([{cliCommand}])}>
                        {cliCommand}
                    </TextColorByHash>
                </Stack.Item>
            )}
            {(requestIp || requestRoute || requestUri) && (
                <Stack.Item>
                    <Stack horizontal gap={10}>
                        {requestRoute && (
                            <Stack.Item>
                                <TextColorByHash onClick={() => navigate([{requestRoute}])}>
                                    {requestRoute}
                                </TextColorByHash>
                            </Stack.Item>
                        )}
                        {requestIp && (
                            <Stack.Item>
                                <TextColorByHash onClick={() => navigate([{requestIp}])}>{requestIp}</TextColorByHash>
                            </Stack.Item>
                        )}
                        {requestUri && (
                            <Stack.Item>
                                <TextColorByHash maxTextSize={400} onClick={() => navigate([{requestUri}])}>
                                    {requestUri}
                                </TextColorByHash>
                            </Stack.Item>
                        )}
                    </Stack>
                </Stack.Item>
            )}
            <Stack.Item>{message}</Stack.Item>
        </Stack>
    );
};

const ErrorIds: FC<{log: IApplicationLogFragment}> = ({log: {errorId, errorCode}}) => {
    const [, navigate] = useFilterBarStateUrlFactory();
    return (
        <Stack>
            <Stack.Item>
                {errorId && (
                    <>
                        Id: <TextColorByHash>{errorId}</TextColorByHash>
                    </>
                )}
            </Stack.Item>
            <Stack.Item>
                {errorCode && (
                    <>
                        Code:{' '}
                        <TextColorByHash
                            onClick={() => {
                                navigate([
                                    {
                                        errorCodes: [errorCode],
                                    },
                                ]);
                            }}
                        >
                            {errorCode}
                        </TextColorByHash>
                    </>
                )}
            </Stack.Item>
        </Stack>
    );
};

export const useApplicationLogsTableNormalizer = (pagination: PaginationHelpers<IApplicationLogFragment>) =>
    usePaginationMapper(
        pagination,
        useCallbackRef(
            ({...log}: IApplicationLogFragment): ApplicationLogDTO => ({
                message: <Bericht log={log} />,
                id: log.id,
                info: <Info log={log} />,
                profiel: <Profiel log={log} />,
                source: <Source log={log} />,
                errorIds: <ErrorIds log={log} />,
            })
        )
    );
