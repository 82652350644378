import {ReactNode} from 'react';
import styles from './ModalSidebar.module.scss';

export interface ModalSidebarProps {
    children: ReactNode;
}

export const ModalSidebar = ({children}: ModalSidebarProps) => (
    <div className={`${styles.modalSidebar} modalSidebar`}>{children}</div>
);
