import {useContext} from 'react';
import {GlobalModifierKeysContext} from './GlobalModifierKeysContext';

export const useGlobalModifierKeys = () => {
    const context = useContext(GlobalModifierKeysContext);
    if (!context) {
        throw new Error('useGlobalModifierKeys must be used within a GlobalModifierKeysContextProvider');
    }
    return context;
};
