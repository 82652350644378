import './jquery.conditionize2';
import {ConfiguredElement, logInfo} from '../rxjs';

export const applyCondition = ({element}: ConfiguredElement<string>) => {
    return (jQuery(element) as any)
        .conditionize2({
            onload: true,
            ifTrue: function () {
                jQuery(element).show();
                if (element.getAttribute('data-apply-hidden-no-validate')) {
                    jQuery(element)
                        .find(':input')
                        .each(function () {
                            jQuery(this).removeClass('has-error hidden-no-validate');
                            if (jQuery(this).closest('.input-group').find('label.error').length > 0) {
                                jQuery(this).closest('.input-group').find('label.error').remove();
                            }
                        });
                }
            },
            ifFalse: function () {
                jQuery(element).hide();
                if (element.getAttribute('data-apply-hidden-no-validate')) {
                    jQuery(element).find(':input').addClass('hidden-no-validate');
                }
            },
        })
        .toArray()
        .map(function () {
            return element.getAttribute('data-condition');
        });
};
