import {ViewConfig} from '@growthbase/design-components';
import {
    ITakentemplateVanLijsttemplatwSwimlaneFragment,
    ITakentemplateVanLijsttemplatwSwimlaneKaartFragment,
    useTakentemplateVanLijstSwimlaneQuery,
} from '@growthbase/graphql';
import {idFromIri, usePaginationSearchParams} from '@growthbase/routing';
import {Takenswimlane, TakenswimlaneConnected, SwimlaneCreateFrom} from '@growthbase/takentemplates-taaktemplate';
import {usePollInterval} from '@growthbase/websockets';
import React from 'react';
import {useChangeOrder} from './Hook/useChangeOrder';
import {useEntityChanges} from './Hook/useEntityChanges';
import {useMaakTaakInLijstHandler} from './Hook/useMaakTaakInLijstHandler';
import {Filters} from '../../../../types';

export interface SwimlaneConnectedProps extends ViewConfig {
    fragment: ITakentemplateVanLijsttemplatwSwimlaneFragment;
    filters: Filters;
    header?: React.ReactNode;
    footer?: React.ReactNode;
    readonly: boolean;
    dragging?: boolean;
    hoover?: boolean;
}

export function TakenVanLijstSwimlaneConnected({
    fragment,
    filters,
    readonly,
    disabled,
    ...rest
}: SwimlaneConnectedProps) {
    const viewConfig = {disabled, readonly};
    const {
        id,
        availableActions: {kanTakenToevoegen},
        herbruikbaarheid,
    } = fragment;
    const {pagination, usePagination} =
        usePaginationSearchParams<ITakentemplateVanLijsttemplatwSwimlaneKaartFragment>('taak');
    const result = useTakentemplateVanLijstSwimlaneQuery({
        pollInterval: usePollInterval(),
        variables: {
            ...pagination,
            fulltextSearch: filters.searchQuery,
            lijstId: idFromIri(id),
        },
    });

    const helpers = usePagination(result);

    useEntityChanges({
        lijstId: id,
        filters,
        result,
    });

    const handler = useMaakTaakInLijstHandler(id, result);

    const readOnly = !kanTakenToevoegen || herbruikbaarheid?.herbruikbaar || readonly;

    return (
        <TakenswimlaneConnected<ITakentemplateVanLijsttemplatwSwimlaneKaartFragment>
            pagination={helpers}
            userData={fragment}
            footer={<Takenswimlane.Footer form={!readOnly && <SwimlaneCreateFrom onSubmit={handler} />} />}
            dragDisabled={readOnly}
            {...viewConfig}
            {...rest}
            {...useChangeOrder(id, result)}
            readonly={readOnly}
        />
    );
}
