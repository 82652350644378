import React from 'react';
import {FieldWrapperControlled, TextFieldComponent} from '@growthbase/design-components';
import {InstellingInputProps} from './InstellingInputWrapper';

export const TextInstellingInput = ({label, name, override}: InstellingInputProps) => {
    type Type = Record<typeof name, string>;
    const extraProps: Record<string, unknown> = {};
    if (override) {
        extraProps.disabled = true;
        if (override.useValue) {
            extraProps.value = override.value;
        }
    }
    return (
        <FieldWrapperControlled<Type, keyof Type>
            label={label}
            component={TextFieldComponent}
            name={name}
            disabled={!!override}
            {...extraProps}
        />
    );
};
