import {useSwitch} from '@growthbase/spa';
import {useCallback} from 'react';

export type PromiseHandler = Promise<unknown>;

export const useIsPromiseActive = <T = PromiseHandler>(handler: T): [boolean, T | null] => {
    const [isActive, enable, disable] = useSwitch(false);
    const withState = useCallback(
        (...args: unknown[]): unknown => {
            if (isActive) {
                // Should not happen.
                return Promise.resolve();
            }
            enable();
            try {
                // @ts-expect-error This is correct :).
                return Promise.resolve(handler(...args)).finally(disable);
            } catch (error) {
                disable();
                throw error;
            }
        },
        [isActive, disable, enable, handler]
    );
    return [isActive, !isActive ? (withState as T) : null];
};
