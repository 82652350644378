import {Buttons} from '@growthbase/design-components';
import {useCallbackRef, useHandleError, useHTTPClient} from '@growthbase/spa';
import React, {memo} from 'react';
import {ObjectInspector} from 'react-inspector';
import {toast} from 'react-toastify';
import type {DTO} from '../SyncPage';
import {ApiRoutes} from '../SyncRoutes';

export const SyncButton = memo(({row}: {row: DTO}) => {
    const client = useHTTPClient();
    const url = ApiRoutes.sync.useUrl();
    const handleError = useHandleError();
    return (
        <Buttons.Sync
            onClick={useCallbackRef(() => {
                toast
                    .promise(
                        client
                            .post(url, {
                                id: row.id,
                                type: row.type,
                            })
                            .toPromise(),
                        {
                            success: {
                                render: ({data}) => <ObjectInspector expandLevel={10} data={data} />,
                                autoClose: false,
                                type: 'info',
                            },
                            pending: 'Synchroniseren...',
                        }
                    )
                    .catch(handleError);
            })}
        />
    );
});
SyncButton.displayName = 'SyncButton';
