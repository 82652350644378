import {ViewConfig, Information, StyledLink} from '@growthbase/design-components';
import {TakenRoutes} from '@growthbase/taken';
import {FC} from 'react';
import {Box} from '@mui/material';
import {TaakEditFragment} from '../../../../../TaakEditModal';

export interface TitelProps extends ViewConfig {
    id: string;
    titel: string;
    fragment?: TaakEditFragment | null;
}

export const Titel: FC<TitelProps> = ({id, titel, readonly, fragment}) => {
    const to = TakenRoutes.edit.useUrl(
        {id},
        {
            state: {
                taak: fragment ?? undefined,
            },
        }
    );
    if (readonly) {
        return <Information>{titel}</Information>;
    }
    return (
        <StyledLink to={to}>
            <Box sx={{pr: 3.5}}>{titel}</Box>
        </StyledLink>
    );
};
