import {QueryResult} from '@apollo/client/react/types/types';
import {useDropQueryCacheHandlers} from '@growthbase/design-components';
import {
    IMijnTakenOverzichtQuery,
    IMijnTakenOverzichtQueryVariables,
    IMijnTakenOverzichtRowFragment,
    useMijnTakenOverzichtVeranderVolgordeMutation,
} from '@growthbase/graphql';
import {DropItemHandlerEvent} from '@growthbase/keys';
import {idFromIri} from '@growthbase/routing';
import {useCallback} from 'react';

/**
 * - Change the order of the taak in the table before submitting the form to the api.
 */
export const useChangeOrder = (result: QueryResult<IMijnTakenOverzichtQuery, IMijnTakenOverzichtQueryVariables>) => {
    const [changeOrder] = useMijnTakenOverzichtVeranderVolgordeMutation();

    return useDropQueryCacheHandlers({
        edgeTypeName: 'TaakEdge',
        nodeTypeName: 'Taak',
        updateQuery: result.updateQuery,
        onDrop: useCallback(
            ({
                placement: {itemThatIsBefore, itemThatIsAfter},
                item,
            }: DropItemHandlerEvent<IMijnTakenOverzichtRowFragment>): Promise<void> =>
                changeOrder({
                    variables: {
                        input: {
                            id: idFromIri(item.id),
                            taakpositie: {
                                bovenTaakId: itemThatIsAfter ? idFromIri(itemThatIsAfter.id) : undefined,
                                onderTaakId: itemThatIsBefore ? idFromIri(itemThatIsBefore.id) : undefined,
                            },
                        },
                    },
                }).then(() => undefined),
            [changeOrder]
        ),
    });
};
