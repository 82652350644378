import {useTranslation} from 'react-i18next';
import {Buttons} from '../../../Button';
import {usePopup} from '../../Hook';

export const PopupCancelButton = ({disabled}: {disabled: boolean}) => {
    const {close} = usePopup();
    const {t} = useTranslation('spa_form.nl');
    return (
        <Buttons.NoBorder disabled={disabled} onClick={close}>
            {t('cancel')}
        </Buttons.NoBorder>
    );
};
