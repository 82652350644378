import type {OpgemaakteTextPluginsServices} from '@growthbase/design-components';
import {OpgemaakteTekstElement, PlateReadonlyRenderer} from '@growthbase/design-components';
import React, {ReactNode} from 'react';
import {createRelatiesMentionPluginConnected} from './OpgemaakteTekstEditor/createRelatiesMentionPlugin';
import {RelatieMentionReadonly} from './OpgemaakteTekstEditor';

export const relationReadOnlyRenderer: PlateReadonlyRenderer = {
    render: (element: OpgemaakteTekstElement): ReactNode => {
        if ('type' in element && element.type === 'bedrijf_mention') {
            return <RelatieMentionReadonly relatie={element.bedrijf} />;
        }
        if ('type' in element && element.type === 'persoon_mention') {
            return <RelatieMentionReadonly relatie={element.persoon} />;
        }
        return null;
    },
};

export const createRelatiesServices = ({
    plateSingleLinePlugins = [],
    readOnlyComponents = [],
}: OpgemaakteTextPluginsServices): unknown => ({
    plateSingleLinePlugins: [...plateSingleLinePlugins, createRelatiesMentionPluginConnected()],
    readOnlyComponents: [
        ...readOnlyComponents,
        {
            relationReadOnlyRenderer,
        },
    ],
});
