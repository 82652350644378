import {
    ChangeType,
    DetermineActionParams,
    determineActionTypeWhenGearchiveerd,
    determineActionForList,
    QueryCacheUpdateType,
} from '@growthbase/cache';
import {ITaakTabelFragment} from '@growthbase/graphql';

interface TaakTabelFilters {
    searchQuery: string | null;
    /**
     * If set, remove or add the taak based on the changed value.
     */
    filterVoltooid?: boolean | null;
    /**
     * If set, remove or add the taak based on the changed value.
     */
    filterGearchiveerd?: boolean | null;
    /**
     * If set, the query will be fetched when deelnemers are changed of any taak.
     *
     * Maybe split this for deelnemers/volgers?
     */
    filterByGebruikerIds?: string[] | null;
    filterByOpdrachtIds?: string[] | null;
    filterByTicketIds?: string[] | null;
    filterByLijstIds?: string[] | null;
    filterByAbonnementIds?: string[] | null;
    filterByRelatieId?: string;
    reorderForList: null | string;
}

/**
 * Hook to update the query cache when a taak is added, changed or removed.
 */
export function determineTaakUpdateActionOnEntityChanges<TRow extends ITaakTabelFragment>(
    params: DetermineActionParams<TRow>,
    {
        searchQuery,
        filterVoltooid = null,
        filterGearchiveerd = null,
        filterByGebruikerIds = null,
        reorderForList = null,
        filterByOpdrachtIds = null,
        filterByLijstIds = null,
        filterByAbonnementIds = null,
        filterByTicketIds = null,
        filterByRelatieId,
    }: TaakTabelFilters
): QueryCacheUpdateType {
    const {type, typename} = params;
    if (searchQuery || typename === 'IsGesnoozedEdge') {
        return QueryCacheUpdateType.refetch;
    }
    if (typename !== 'Taak') {
        return QueryCacheUpdateType.ignore;
    }
    const filterGebruikers = filterByGebruikerIds && filterByGebruikerIds.length > 0;
    const filterOpdrachten = filterByOpdrachtIds && filterByOpdrachtIds.length > 0;
    const filterAbonnementen = filterByAbonnementIds && filterByAbonnementIds.length > 0;
    const filterTickets = filterByTicketIds && filterByTicketIds.length > 0;
    const filterLijsten = filterByLijstIds && filterByLijstIds.length > 0;
    const filterRelatie = filterByRelatieId !== undefined;
    switch (type) {
        case ChangeType.add:
            if (
                filterGebruikers ||
                filterLijsten ||
                filterOpdrachten ||
                filterTickets ||
                filterAbonnementen ||
                filterRelatie
            ) {
                return QueryCacheUpdateType.ignore;
            }
            return QueryCacheUpdateType.default;
        case ChangeType.changed: {
            const {
                node: {voltooid},
                fields,
            } = params;
            if (fields.includes('voltooid') && filterVoltooid !== null) {
                return voltooid === filterVoltooid ? QueryCacheUpdateType.add : QueryCacheUpdateType.remove;
            }
            return determineActionTypeWhenGearchiveerd(QueryCacheUpdateType.default, params, filterGearchiveerd);
        }
        case ChangeType.changeOrder:
            return determineActionForList(QueryCacheUpdateType.default, params, reorderForList);
        case ChangeType.invalidate: {
            const {fields} = params;
            if (fields.includes('priveVoorMij')) {
                return QueryCacheUpdateType.refetch;
            }
            if (fields.includes('opdracht') && filterOpdrachten) {
                /**
                 * We cannot look at the id, because we don't know the opdracht id of the message.
                 */
                return QueryCacheUpdateType.refetch;
            }
            if (fields.includes('lijsten') && filterLijsten) {
                /**
                 * We cannot look at the id, because we don't know the lijst id of the message.
                 */
                return QueryCacheUpdateType.refetch;
            }
            if (fields.includes('deelnemers') || !fields.includes('volgers')) {
                if (filterGebruikers) {
                    /**
                     * We cannot look at the id, because we don't know the gebruikers value
                     * TODO: Add the previous value to the message send and look in Apollo cache?.
                     */
                    return QueryCacheUpdateType.refetch;
                }
                return QueryCacheUpdateType.refetchEntity;
            }
            if (fields.includes('relatie') && filterRelatie) {
                /**
                 * We cannot look at the id, because we don't know the lijst id of the message.
                 */
                return QueryCacheUpdateType.refetch;
            }
            return QueryCacheUpdateType.default;
        }
        default:
            return QueryCacheUpdateType.default;
    }
}
