/* eslint-disable */
import {FC, useMemo} from 'react';

import {Field, FieldInstance, ValueType} from '../contract';
import {Buttons, Padding, Stack} from '@growthbase/design-components';
import {TypeSelection} from './TypeSelection/TypeSelection';
import {useTranslation} from 'react-i18next';

export interface ConditionProps {
    fields: Field[];
    value: FieldInstance;
    onChange: (value: FieldInstance) => void;
    remove?: (value: FieldInstance) => void;
    nestingLevel?: number;
}

export const Condition: FC<ConditionProps> = ({value, onChange, remove, fields, nestingLevel = 0}) => {
    const {t} = useTranslation('querybuilder.nl');
    const field = useMemo(() => fields.find((field) => field.name === value.name), [fields, value]);
    if (!field) {
        return null;
    }
    const {id} = value;
    const {Component, label, name, valueType} = field;
    const item = (
        <Stack.Item grow>
            <Component
                nestingLevel={nestingLevel + 1}
                id={id}
                fields={fields}
                label={label(t)}
                onChangeField={onChange}
                onChange={(change) => {
                    onChange({
                        ...value,
                        value: change,
                    });
                }}
                value={value.value}
                name={name}
            />
        </Stack.Item>
    );
    return (
        <Stack stretch>
            <Stack horizontal stretch gap="small">
                <Stack.Item>
                    <TypeSelection
                        fields={fields}
                        field={field}
                        onChange={(newField) => {
                            const converted: FieldInstance = {
                                value: newField.valueType.convertToNewValueType({
                                    previousField: field,
                                    previousValue: value,
                                    fields,
                                    newField: newField,
                                }),
                                name: newField.name,
                                type: newField.valueType.type,
                                id,
                            };
                            onChange(converted);
                        }}
                    />
                </Stack.Item>
                {valueType.type !== ValueType.conditions && item}
                {remove && (
                    <Stack.Item>
                        <Buttons.CrossCircle onClick={() => remove(value)}>{t('delete')}</Buttons.CrossCircle>
                    </Stack.Item>
                )}
            </Stack>
            <Padding>{valueType.type === ValueType.conditions && item}</Padding>
        </Stack>
    );
};
