import {ReactNode} from 'react';
import {Buttons} from '@growthbase/design-components';
import {ActionsMenu} from '../ActionsMenu';

export interface PageActionsMenuProps {
    children: ReactNode;
}

const renderPageActionsMenuButton = () => <Buttons.Dots testId="page-actions-menu-button" />;

export const PageActionsMenu = ({children}: PageActionsMenuProps) => (
    <ActionsMenu renderButton={renderPageActionsMenuButton}>{children}</ActionsMenu>
);
