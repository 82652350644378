/* eslint-disable no-continue */
import {isPlainObject} from 'lodash';
import {InstellingFieldCollection} from '../InstellingFieldCollection';
import {BaseControlField, ControlField, ControlsFields, ControlsOptions} from '../types';
import {ControlFieldCollection} from '../ControlFieldCollection';
import {setValueToPath} from '../Helpers';

export interface ControlFieldNormalizerInterface {
    normalize(fields: InstellingFieldCollection): void;
}

export class ControlFieldNormalizer implements ControlFieldNormalizerInterface {
    private normalizeControlField(
        fields: InstellingFieldCollection,
        name: string,
        input: ControlsOptions<unknown, unknown>
    ): ControlField {
        let {value} = input;

        if (isPlainObject(value)) {
            const object = {};
            setValueToPath(object, name, value);
            value = object;
        }

        const field: ControlField = {
            name,
            options: {
                when: input.when,
                value,
                hide: input.hide ?? false,
            },
        };
        if (field.options.value === undefined) {
            delete field.options.value;
        }
        return field;
    }

    private normalizeControlFields(
        fields: InstellingFieldCollection,
        controls: ControlsFields<unknown, object>,
        parent?: string
    ): ControlField[] {
        const normalizedFields: ControlField[] = [];
        for (const [name, value] of Object.entries(controls)) {
            if (!('when' in value)) {
                const typed = value as ControlsFields<unknown, object>;
                const normalized = this.normalizeControlFields(fields, typed, parent ? `${parent}.${name}` : name);
                normalizedFields.push(...normalized);
                continue;
            }
            const typed = value as ControlsOptions<unknown, unknown>;
            const normalized = this.normalizeControlField(fields, parent ? `${parent}.${name}` : name, typed);
            normalizedFields.push(normalized);
        }
        return normalizedFields;
    }

    public normalize(fields: InstellingFieldCollection): void {
        for (const field of fields.getFields()) {
            const inputField = field.input;
            const normalized: ControlField[] = [];
            if (!inputField) {
                continue;
            }
            if ('control' in inputField) {
                normalized.push(...this.normalizeControlFields(fields, inputField.control ?? {}, field?.parent));
            }
            if ('controlRoot' in inputField) {
                normalized.push(...this.normalizeControlFields(fields, inputField.controlRoot ?? {}));
            }
            if (normalized.length !== 0) {
                (field as BaseControlField).controlFields = new ControlFieldCollection(...normalized);
            }
        }
    }
}
