import {FC, ReactNode} from 'react';

export interface WarningProps {
    children: ReactNode;
}

/**
 * TODO: Don't rely on Clientbox styling.
 */
export const Warning: FC<WarningProps> = ({children}) => (
    <div className="alert alert-warning" style={{marginBottom: 0}}>
        {children}
    </div>
);
