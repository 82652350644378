import {useCallbackRef} from '@growthbase/spa';
import {MbscCalendarEventData} from '@mobiscroll/react';
import {CalendarEventSmall, formatTimeDigital} from '@growthbase/design-components';
import React from 'react';
import moment from 'moment';

export const useRenderLabel = () =>
    useCallbackRef((event: MbscCalendarEventData) => {
        const start = event.original?.start ?? event.start;
        const end = event.original?.end ?? event.end;
        const seconds = event.original?.seconden ?? moment(end).diff(moment(start), 'seconds');
        const time = formatTimeDigital(seconds);
        return (
            <CalendarEventSmall color={event.original?.color ?? '#D8DFEA'} extraLabel={time}>
                {event.title}
            </CalendarEventSmall>
        );
    });
