import React, {memo, ReactNode, useMemo, useState, FC} from 'react';
import {TabsRenderer, TabContentWrapper, TabContentWrapperProps, TabsHeader, TabsHeaderProps} from './Components';
import {QueryTabsProps, TabsContext, TabsContextState} from './TabsContext';

export interface TabsProps {
    render?: typeof TabsRenderer;
    children: ReactNode;
    Header?: FC<TabsHeaderProps>;
    ContentWrapper?: FC<TabContentWrapperProps>;
    sizeSmall?: boolean;
    query?: QueryTabsProps;
    onQueryChange?: (value: string) => void;
}

export const Tabs: FC<TabsProps> = memo(
    ({
        render = TabsRenderer,
        sizeSmall,
        children: tabs,
        query,
        Header = TabsHeader,
        ContentWrapper = TabContentWrapper,
        onQueryChange,
    }) => {
        const [content, setContent] = useState<ReactNode>(null);

        const state = useMemo(
            (): TabsContextState => ({
                sizeSmall,
                ContentWrapper,
                setContent,
                query,
                onQueryChange,
            }),
            [sizeSmall, query, ContentWrapper, onQueryChange]
        );

        const renderProps = useMemo(
            () => ({
                header: <Header>{tabs ?? null}</Header>,
                content,
            }),
            [tabs, Header, content]
        );

        return <TabsContext.Provider value={state}>{render(renderProps)}</TabsContext.Provider>;
    }
);
Tabs.displayName = 'Tabs';

export const QueryTabs: FC<Omit<TabsProps, 'query'> & QueryTabsProps> = ({name, defaultValue, children, ...props}) => (
    <Tabs {...props} query={useMemo(() => ({defaultValue, name}), [defaultValue, name])}>
        {children}
    </Tabs>
);
