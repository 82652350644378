export interface VerticalStackProps {
    gap?: number;
    children?: React.ReactNode;
}

/**
 * @deprecated use Stack instead
 */
export const VerticalStack = ({children, gap = 20}: VerticalStackProps) => {
    const styles = {
        grid: {
            display: 'grid',
            gridTemplateColumns: '1fr',
            rowGap: gap,
        },
    };
    return <div style={styles.grid}>{children}</div>;
};
