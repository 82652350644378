import {IStandaardWeergaveMijnDag, useTakenSideLinksQuery} from '@growthbase/graphql';
import {useTakenSidelinksFilters} from '@growthbase/taken';
import {useState} from 'react';

export const useMijnTakenOverzichten = () => {
    const result = useTakenSideLinksQuery();

    const actieveMijnTakenTeller =
        result?.data?.ingelogdeGebruiker?.gebruikerWeergaveInstellingen?.standaardWeergaveMijnDag;

    const [initialView, setInitialView] = useState<IStandaardWeergaveMijnDag | undefined>(undefined);
    if (actieveMijnTakenTeller !== undefined && initialView === undefined) {
        setInitialView(actieveMijnTakenTeller);
    }

    // eslint-disable-next-line prefer-const
    let [view, setView] = useTakenSidelinksFilters(initialView);

    return {result, view, setView};
};
