import {isEqual, noop} from 'lodash';
import {useCallback, useEffect, useMemo} from 'react';
import {filter} from 'rxjs/operators';
import {useOnCloseEvents} from '../../Popups';
import type {StrictFormOptions} from './useCreateStrictForm';
import {StrictFormState} from './useCreateStrictFormState';

/**
 * - Save on popup close, and cancel popup close.
 * - Save on focus lost.
 */
export const useAutoSubmit = <T extends object>(
    {formik}: StrictFormOptions<T>,
    enabled: boolean,
    state: StrictFormState
) => {
    const {handleSubmit, dirty, values, initialValues, isSubmitting} = formik;
    const submitCallback = useCallback(() => {
        if (!enabled) {
            return;
        }
        if (!dirty && isEqual(values, initialValues)) {
            return;
        }
        // Wait until the previous is completed.
        if (isSubmitting) {
            return;
        }
        handleSubmit();
    }, [
        values,
        initialValues,
        isSubmitting,
        enabled,
        dirty,
        handleSubmit,
    ]);

    useOnCloseEvents(
        useCallback(
            (event) => {
                if (!enabled) {
                    return;
                }
                if (!dirty && isEqual(values, initialValues)) {
                    return;
                }
                submitCallback();
                event.cancel = true;
            },
            [values, initialValues, enabled, dirty, submitCallback]
        )
    );

    const pending: Record<string, boolean> = useMemo(() => ({}), []);
    useEffect(() => {
        if (!enabled) {
            return noop;
        }
        const subscription = state.onActiveChange
            .pipe(
                filter((value) => {
                    if (value.active) {
                        pending[value.field] = true;
                        return false;
                    }
                    if (pending[value.field]) {
                        pending[value.field] = false;
                        return true;
                    }
                    return false;
                })
            )
            .subscribe(submitCallback);
        return () => subscription.unsubscribe();
    }, [pending, enabled, state, submitCallback]);

    return null;
};
