import {InstellingenKopieerForm} from '@growthbase/instellingen';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {TaakKopierenPropertiesValues} from '@growthbase/taken';
import {TaakKopieerFilter} from '@growthbase/takenlijst-lijst';
import {useBordKopierenOpties} from './Hook/useBordKopierenOpties';

export interface BordKopierenFormValues {
    naam: string;
    activiteiten: boolean;
    alles?: boolean;
    omschrijving: boolean;
    deelnemers: boolean;
    zichtbaarVoor: boolean;
    gekoppeldeItem: boolean;
    lijsten: boolean;
    taken: boolean;
    taakFilter: TaakKopieerFilter;
    taak: TaakKopierenPropertiesValues;
}

export interface BordKopierenFormProps {
    titel: string;
    onSubmit: (values: BordKopierenFormValues) => Promise<void>;
}

export const KopieerForm: FC<BordKopierenFormProps> = ({onSubmit, titel}) => {
    const {t} = useTranslation('TakenlijstBord.nl');
    return (
        <InstellingenKopieerForm<BordKopierenFormValues>
            titel={t('Kopieren.copyBord')}
            fields={useBordKopierenOpties(titel)}
            onSubmit={onSubmit}
        />
    );
};
