import {useBestandView} from '@growthbase/bestandsbeheer';
import {LinkButton} from '@growthbase/design-components';
import {FC, ReactNode} from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import styles from './BijlageTeaser.module.scss';

export interface BijlageTeaserProps {
    naam: string;
    uploaded: boolean;
    bestandId: string;
    thumbnail: ReactNode;
    download: () => Promise<void>;
}

export const BijlageTeaser: FC<BijlageTeaserProps> = ({naam, uploaded, thumbnail, bestandId, download}) => {
    const {open: onClick} = useBestandView(bestandId);

    return (
        <div className={styles.BijlageTeaser}>
            <div className={styles.BijlageTeaserPreview}>{thumbnail}</div>
            <div className={styles.BijlageTeaserBody}>
                <span className={styles.BijlageTeaserTitle}>
                    <LinkButton disabled={!uploaded} onClick={onClick}>
                        {naam}
                    </LinkButton>
                </span>
                <LinkButton disabled={!uploaded} onClick={download}>
                    <DownloadIcon style={{display: 'block'}} fontSize="small" />
                </LinkButton>
            </div>
        </div>
    );
};
