import React, {FC} from 'react';
import {BlockMessage, LinkButton, Loader} from '@growthbase/design-components';
import {BordRoutes} from '@growthbase/takenlijst/bord';
import {Trans, useTranslation} from 'react-i18next';
import {BordtemplateRoutes} from '@growthbase/takentemplates-bordtemplate';

export interface LandingsPaginaBordProps {
    loading?: boolean;
}

export const LandingsPaginaBord: FC<LandingsPaginaBordProps> = ({loading}) => {
    const {t} = useTranslation('TakenlijstBord.nl');
    const createBord = BordRoutes.aanmaken.useNavigate();
    const createBordVanTemplate = BordtemplateRoutes.bordtoevoegen.useNavigate();
    if (loading) {
        return <Loader center />;
    }
    return (
        <BlockMessage title={t('geenBordAanwezigTekst')}>
            <Trans
                t={t}
                components={{
                    maakBord: (
                        <LinkButton onClick={createBord} color="primary">
                            {t('maakEenNieuwBord')}
                        </LinkButton>
                    ),
                    voegToe: (
                        <LinkButton onClick={createBordVanTemplate} color="primary">
                            {t('maakBordVanTemplate')}
                        </LinkButton>
                    ),
                }}
            >
                maakEenNieuwBord
            </Trans>
        </BlockMessage>
    );
};
