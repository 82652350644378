import classNames from 'classnames/bind';
import {forwardRef, FC, ForwardedRef} from 'react';
import styles from './StatusIconButton.module.scss';
import {BaseButtonProps, IconProps, Icons, Tooltip, useHandleClickState} from '../../../src';

export interface StatusIconButtonProps extends BaseButtonProps {
    active?: boolean;
    Icon?: FC<IconProps>;
}

const cx = classNames.bind(styles);

export const StatusIconButton = forwardRef(
    (
        {
            testId,
            ariaLabel,
            Icon = (props: IconProps) => <Icons.Check {...props} />,
            className,
            children,
            active,
            onMouseDown,
            tooltip,
            tabIndex,
            ...rest
        }: StatusIconButtonProps,
        ref
    ) => {
        const {loading, disabled, onClick} = useHandleClickState(rest);

        const classes = cx('statusIconButton', {
            'statusIconButton--active': active,
            'statusIconButton--loading': loading,
            'statusIconButton--disabled': disabled,
        });

        const button = (
            <button
                tabIndex={tabIndex}
                data-testid={testId}
                ref={tooltip ? undefined : (ref as ForwardedRef<HTMLButtonElement>)}
                type="button"
                aria-label={ariaLabel ?? undefined}
                disabled={disabled}
                className={classes}
                onMouseDown={disabled ? undefined : onMouseDown}
                onClick={disabled ? undefined : onClick}
            >
                {loading && <Icons.LoadingAnimated className={styles.statusIconButton__icon} />}
                {!loading && <Icon className={styles.statusIconButton__icon} />}
            </button>
        );

        if (tooltip) {
            return (
                <Tooltip content={tooltip}>
                    <span ref={ref as ForwardedRef<HTMLSpanElement>}>{button}</span>
                </Tooltip>
            );
        }

        return button;
    }
);

StatusIconButton.displayName = 'StatusIconButton';
