import React, {useCallback} from 'react';
import {useSet} from 'react-use';
import {Buttons} from '../../Button';
import {AvatarSize} from '../Avatar';
import {AvatarPopup, AvatarPopupItem} from '../AvatarPopup';
import {IconSize} from '../../Icon';
import {SearchInput} from '../../SearchForm';

export interface AvatarPopupFieldItem {
    id: string;
    selected?: boolean;
    naam: string;
    imageUrl?: string | null;
}

export interface AvatarPopupFieldProps<T extends AvatarPopupFieldItem = AvatarPopupFieldItem> {
    testId?: string;
    addButtonAriaLabel?: string | null;
    popupTitle: string;
    popupItems: T[];
    items: T[];
    size?: AvatarSize;
    readonly?: boolean;
    disabled?: boolean;
    onAdd: (item: T) => unknown | Promise<unknown>;
    onRemove: (item: T) => unknown | Promise<unknown>;
    deleteToolTip: (item: T) => string;
    addToolTip: (item: T) => string;

    onChangeQuery: (value: SearchInput) => void;
    searchQuery: string | null;
    loading: boolean;
}

export const AvatarPopupField = <T extends AvatarPopupItem = AvatarPopupItem>({
    popupTitle,
    onAdd,
    onRemove,
    popupItems,
    items,
    deleteToolTip,
    addToolTip,
    size,
    disabled,
    readonly,
    testId,
    addButtonAriaLabel,
    onChangeQuery,
    searchQuery,
    loading,
}: AvatarPopupFieldProps<T>) => {
    const enabled = !disabled && !readonly;

    // Holt set for handlers currently active.
    const [, {add, has, remove}] = useSet();

    const onClick = useCallback(
        (item: T) => {
            if (!enabled) {
                return;
            }
            add(item.id);
            Promise.resolve(item.selected ? onRemove(item) : onAdd(item)).finally(() => remove(item.id));
        },
        [onAdd, add, remove, onRemove, enabled]
    );

    return (
        <AvatarPopup<T>
            testId={testId}
            items={readonly ? items : popupItems}
            title={popupTitle}
            onClickRow={onClick}
            disabled={disabled}
            readonly={readonly}
            onChangeQuery={onChangeQuery}
            searchQuery={searchQuery}
            loading={loading}
            avatarProps={useCallback(
                (item: T) => {
                    const props = {
                        disabled: has(item.id) || disabled,
                        loading: has(item.id),
                    };
                    if (!enabled) {
                        return {...props, tooltip: item.naam};
                    }
                    return {...props, tooltip: item.selected ? deleteToolTip(item) : addToolTip(item)};
                },
                [has, disabled, enabled, deleteToolTip, addToolTip]
            )}
            actionButtonProps={(item) => ({onClick: () => onClick(item), tooltip: deleteToolTip(item)})}
            renderAnchor={({controls: {toggle}, reference}) => (
                <Buttons.EyeIconOnly
                    iconSize={IconSize.SIZE_5}
                    showOnRowHover
                    ariaLabel={addButtonAriaLabel}
                    size={size === 'small' ? 'small' : 'medium'}
                    disabled={disabled}
                    ref={reference}
                    onClick={toggle}
                    badgeCount={items.length}
                />
            )}
        />
    );
};
