import {FC} from 'react';
import {Trans} from 'react-i18next';
import {BestemmingBordLink} from './Components/BestemmingBordLink';

export interface BestemmingTextLabelProps {
    bord: string;
    bordId: string;
    lijst: string;
    renderBestemmingLijstLink: () => JSX.Element;
}

export const BestemmingTextLabel: FC<BestemmingTextLabelProps> = ({bord, lijst, bordId, renderBestemmingLijstLink}) => (
    <Trans
        ns="Taken.nl"
        i18nKey="TaakVerplaatsen.bestemmingText"
        values={{bord, lijst}}
        components={{linkBord: <BestemmingBordLink bordId={bordId} />, linkLijst: renderBestemmingLijstLink()}}
    />
);
