import {createGebruikerServices} from '@growthbase/gebruikers';
import {pipe} from 'lodash/fp';
import {createGraphQLServices} from '@growthbase/graphql';
import {
    ApplicationServiceDependencies,
    ApplicationServices,
    BaseApplicationServices,
    BaseDependencies,
    createWarningServices,
    createDTOsFromGlobals,
    createErrorHandlingServices,
    createHttpServices,
    createLoggingServices,
    createReduxService,
    createTranslationServices,
    SpaVariablesDTO,
} from '@growthbase/spa';
import {createRelatiesServices} from '@growthbase/relaties';
import {createWebsocketsReduxServices, createWebsocketsServices} from '@growthbase/websockets';
import {ServiceFactory} from '../createServices';

function combine<TDeps, TReturn>(serviceFactory: ServiceFactory<TDeps, TReturn>): (deps: TDeps) => TDeps & TReturn {
    return (deps) => ({...deps, ...serviceFactory(deps)});
}

export const createSSRServices = <TVariables extends SpaVariablesDTO>(
    deps: BaseDependencies<TVariables>,
    createApplicationServices: ServiceFactory<BaseApplicationServices<TVariables>, ApplicationServiceDependencies>
): ApplicationServices<TVariables> =>
    pipe(
        combine(createDTOsFromGlobals),
        combine(createLoggingServices),
        combine(createTranslationServices),
        combine(createWebsocketsReduxServices),

        combine(createHttpServices),

        combine(createErrorHandlingServices),
        combine(createApplicationServices),
        combine(createReduxService),
        combine(createWarningServices),

        combine(createGraphQLServices),

        combine(createWebsocketsServices),
        combine(createGebruikerServices),
        combine(createRelatiesServices)
    )(deps);
