import {useMemo} from 'react';
import {createURL} from '../Util';
import {useCurrentHref} from './useCurrentHref';

export const useCurrentSearchParams = () => {
    const href = useCurrentHref();

    return useMemo(() => {
        const withParams = createURL(href);
        return withParams.searchParams;
    }, [href]);
};
