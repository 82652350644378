import React, {FC, memo, useContext, useEffect, useMemo} from 'react';
import {TabButton, TabContent, TabContentWrapper} from './Components';
import {useIsTabActive} from './Hook';
import {TabInterface} from './TabInterface';
import {TabsContext} from './TabsContext';

export const Tab: FC<TabInterface> = memo((props) => {
    const {sizeSmall, ContentWrapper = TabContentWrapper, setContent} = useContext(TabsContext);
    const {title, children} = props as TabInterface;

    const isActive = useIsTabActive(props);

    const content = useMemo(
        () => (
            <>
                <TabContent ContentWrapper={ContentWrapper} title={`${title}`}>
                    {children}
                </TabContent>
            </>
        ),
        [ContentWrapper, title, children]
    );

    useEffect(() => {
        if (!isActive) {
            return;
        }
        setContent(content);
    }, [content, setContent, isActive]);

    return <TabButton isActive={isActive} tab={props} sizeSmall={sizeSmall} />;
});

Tab.displayName = 'Tab';
