import {createLegacyRoutes, CreateRouteFunction, createRoutes, idFromIri} from '@growthbase/routing';
import {generatePath} from 'react-router-dom';
import {TakenVanRelatiePaginaConnected, TakenVanRelatiePaginaConnectedProps} from '../Taken';

const generateRelatieRouteWithLegacyId: CreateRouteFunction<{
    relatieId: string;
    naam: string;
}> = (route: string, params) =>
    generatePath(route, {
        naam: idFromIri(params?.naam ?? ''),
        relatieId: idFromIri(params?.relatieId ?? ''),
    });

export const RelatieRoutes = createRoutes('relatie', {
    taken_van_relatie: {
        Element: (props: TakenVanRelatiePaginaConnectedProps) => <TakenVanRelatiePaginaConnected {...props} />,
        createRoute: generateRelatieRouteWithLegacyId,
    },
});

const generateReadParams: CreateRouteFunction<{
    relatieId: string;
    relatienaam: string;
}> = (route: string, params) =>
    generatePath(route, {
        id: params?.relatieId ?? '',
        name: params?.relatienaam ?? '',
    });

export const RelationRoutes = createLegacyRoutes('relation', {
    index: {},
    read: {createRoute: generateReadParams},
});
