import * as yup from 'yup';

export const paginationSearchParamsSchema = yup
    .object({
        start: yup.string(),
        last: yup.string(),
        items: yup.number().default(50).required(),
    })
    .label('PaginationSearchParams')
    .required();
