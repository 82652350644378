import {ReactNode} from 'react';
import {Stack} from '../Layout';
import {Card, CardBody, CardHeader, CardHeaderTitle} from '../Card';

export interface BlockMessageProps {
    title: string;
    actions?: ReactNode;
    children: ReactNode;
}

export const BlockMessage = ({title, children, actions}: BlockMessageProps) => (
    <Card>
        <CardHeader>
            <Stack>
                <Stack.Item>
                    <CardHeaderTitle>{title}</CardHeaderTitle>
                </Stack.Item>
                <Stack.Item pushItems="before">{actions}</Stack.Item>
            </Stack>
        </CardHeader>
        <CardBody>{children}</CardBody>
    </Card>
);
