import {FC, ReactNode} from 'react';
import classNames from 'classnames/bind';
import styles from './FlexLayout.module.scss';
import {FlexProps} from './FlexProps';

export interface RowProps extends FlexProps {
    children?: ReactNode;
    position?: 'start' | 'end';
    itemsWidthAuto?: boolean;
    id?: string;
}

const cx = classNames.bind(styles);

/**
 * @deprecated use Stack instead
 */
export const Row: FC<RowProps> = ({children, smallGap, position, itemsWidthAuto, id, noGap}) => (
    <div
        id={id}
        className={cx('flex', {
            'flex--small': smallGap,
            'flex--noGap': noGap,
            'flex__row--start': position === 'start',
            'flex__row--end': position === 'end',
            'flex__row--itemsWidthAuto': itemsWidthAuto,
        })}
    >
        {children}
    </div>
);
