import React, {memo, ReactNode, FC} from 'react';
import classNames from 'classnames/bind';
import styles from './PopupContent.module.scss';

const cx = classNames.bind(styles);

export interface PopupContentProps {
    children: ReactNode;

    /** @deprecated - place header in children */
    header?: ReactNode;
    width?: 'small' | 'medium' | 'large' | 'full';
    style?: React.CSSProperties;
}

export const PopupContent: FC<PopupContentProps> = memo(({children, header, style, width}) => {
    const classes = cx('popupContent', {
        'popupContent--widthSmall': width === 'small',
        'popupContent--widthMedium': width === 'medium',
        'popupContent--widthLarge': width === 'large',
        'popupContent--widthFull': width === 'full',
    });

    return (
        <div className={classes} style={style}>
            {header}
            <div className={cx('popupContent__content')}>{children}</div>
        </div>
    );
});

PopupContent.displayName = 'PopupContent';
