import {assertIdFromIri, CreateRouteFunction, createRoutes, PageFromMenu, RouteElementProps} from '@growthbase/routing';
import {generatePath} from 'react-router-dom';
import {RoutePopupProvider} from '@growthbase/design-components';
import {CreateFormConnected as LijsttemplateCreateform} from '@growthbase/takentemplates-lijsttemplate';
import React from 'react';
import {LogbookConnected} from '@growthbase/activiteiten';
import {
    LijsttemplateAlsLijstToevoegenFormConnected,
    LijsttemplateAlsLijstToevoegenFormConnectedProps,
} from './LijsttemplateAlsLijstToevoegenForm';
import {EditFormConnected} from './EditForm';
import {OverviewConnected} from './Overview';
import {CreateFormConnected} from './CreateForm';
import {ViewConnected} from './View';
import {BordtemplateAlsBordToevoegenFormConnected} from './BordtemplateAlsBordToevoegenForm';

const generateBordRoute: CreateRouteFunction<{
    bordtemplateId: string;
}> = (route: string, params) =>
    generatePath(route, {
        bordtemplateId: assertIdFromIri(params?.bordtemplateId),
    });

export const BordtemplateRoutes = createRoutes('bordtemplate', {
    bekijken: {
        Element: ({params}: RouteElementProps<{bordtemplateId: string}>) => (
            <PageFromMenu sidebar={<LogbookConnected id={params?.bordtemplateId ?? ''} />}>
                <ViewConnected bordtemplateId={params?.bordtemplateId ?? '-'} bordSelect={null} />
            </PageFromMenu>
        ),
        createRoute: generateBordRoute,
    },
    lijsttoevoegen: {
        defaultBackgroundRoute: 'bekijken',
        Element: (props: LijsttemplateAlsLijstToevoegenFormConnectedProps) => (
            <LijsttemplateAlsLijstToevoegenFormConnected {...props} />
        ),
        createRoute: generateBordRoute,
    },
    lijstaanmaken: {
        defaultBackgroundRoute: 'bekijken',
        Element: ({params}: RouteElementProps<{bordtemplateId: string}>) => (
            <LijsttemplateCreateform closeOnSubmit bordtemplateId={params?.bordtemplateId} />
        ),
        createRoute: generateBordRoute,
    },
    create: {
        defaultBackgroundRoute: 'overview',
        Element: () => (
            <RoutePopupProvider>
                <CreateFormConnected />
            </RoutePopupProvider>
        ),
    },
    bewerken: {
        defaultBackgroundRoute: 'overview',
        Element: ({params}: RouteElementProps<{bordtemplateId: string}>) => (
            <RoutePopupProvider>
                <EditFormConnected bordId={params?.bordtemplateId ?? ''} />
            </RoutePopupProvider>
        ),
        createRoute: generateBordRoute,
    },
    overview: {
        Element: () => (
            <PageFromMenu sidebar={<LogbookConnected relatedTypes={['Bordtemplate']} />}>
                <OverviewConnected />
            </PageFromMenu>
        ),
        createRoute: generateBordRoute,
    },
    bordtoevoegen: {
        defaultBackgroundRoute: 'overview',
        Element: () => (
            <RoutePopupProvider>
                <BordtemplateAlsBordToevoegenFormConnected />
            </RoutePopupProvider>
        ),
    },
});
