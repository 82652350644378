import {
    getPluginType,
    usePlateEditorRef,
    MarkToolbarButton,
    MARK_BOLD,
    MARK_ITALIC,
    MARK_UNDERLINE,
    MARK_CODE,
} from '@udecode/plate';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Icons} from '../../../icons';

export const BasicMarksToolbarButtons = () => {
    const {t} = useTranslation('spa.nl');
    const editor = usePlateEditorRef();
    if (editor === null) {
        return null;
    }
    return (
        <>
            <MarkToolbarButton
                tooltip={{
                    content: t('BasicMarksToolbarButtons.bold'),
                }}
                type={getPluginType(editor, MARK_BOLD)}
                icon={<Icons.FormatBold />}
            />
            <MarkToolbarButton
                tooltip={{
                    content: t('BasicMarksToolbarButtons.italic'),
                }}
                type={getPluginType(editor, MARK_ITALIC)}
                icon={<Icons.FormatItalic />}
            />
            <MarkToolbarButton
                tooltip={{
                    content: t('BasicMarksToolbarButtons.underline'),
                }}
                type={getPluginType(editor, MARK_UNDERLINE)}
                icon={<Icons.FormatUnderlined />}
            />
            <MarkToolbarButton
                tooltip={{
                    content: t('BasicMarksToolbarButtons.code'),
                }}
                type={getPluginType(editor, MARK_CODE)}
                icon={<Icons.FormatCode />}
            />
        </>
    );
};
