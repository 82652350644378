import React, {ReactNode} from 'react';
import {createGlobalStyle} from 'styled-components';
import * as yup from 'yup';

export interface CSSVariable {
    variable: string;
    value: string;
}

export const CSSVariableSchema = yup
    .object({
        variable: yup.string().required(),
        value: yup.string().required(),
    })
    .label('CSSVariable')
    .required() as yup.SchemaOf<CSSVariable>;

export interface CSSVariablesProps {
    variables: CSSVariable[];
    children?: ReactNode;
}

export const CSSVariables = ({variables, children}: CSSVariablesProps) => {
    const GlobalStyles = createGlobalStyle`
      body { ${variables.map(({variable, value}) => `--${variable}: ${value};`).join('')} 
    `;
    return (
        <>
            <GlobalStyles />
            {children}
        </>
    );
};
