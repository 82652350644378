import {useCallbackRef} from '@growthbase/spa';
import {PlatePlugin, useEventEditorSelectors} from '@udecode/plate';
import React, {memo, ReactNode, useCallback, useEffect, useMemo, useRef, useState, FC} from 'react';
import {FormikContextType, useField, useFormikContext} from 'formik';
import {IOpgemaakteTekstEditorFragment} from '@growthbase/graphql';
import {useDebounce, useMount} from 'react-use';
import {Descendant, Editor, Node, Transforms} from 'slate';
import {ReactEditor, useSlate} from 'slate-react';
import {capitalize, isEqual, noop} from 'lodash';
import {timer} from 'rxjs';
import {useKeys} from '@growthbase/keys';
import {useStyledFieldState, FormikFieldProps} from '../../Form';
import {OpgemaakteTekstEditor} from './OpgemaakteTekstEditor';
import {resetNodes} from './resetNodes';
import {usePlateEditorId} from './hooks/useResetPlateEditor';
import {FallbackScrollProvider} from '../../PaginationInfiniteScroll';

export interface OpgemaakteTekstInputProps extends FormikFieldProps<IOpgemaakteTekstEditorFragment> {
    disableToolbar?: boolean;
    isFocussed?: boolean;
    floatingToolbar?: boolean;
    isSubmitting?: boolean;
    singleLine?: boolean;
    autoFocus?: boolean;
    plugins?: PlatePlugin[];
    nested?: ReactNode;
    variant?: 'default' | 'big' | 'text';
    borderVisible?: boolean;
    testId?: string;
}

interface BoundEventsProps {
    value: unknown;
    plateId: string;
    onFocus: () => void;
    onBlur: () => void;
    autoFocus?: boolean;
    singleLine: boolean;
    formik: FormikContextType<unknown>;
    testId?: string;
}

export const useGetActiveEditor = () => {
    const editor = useSlate();
    const ref = useRef(editor);
    return useCallback(() => ref.current, [ref]);
};

const BoundEvents: FC<BoundEventsProps> = ({
    plateId,
    onFocus,
    onBlur,
    value,
    autoFocus,
    formik,
    singleLine,
    testId,
}) => {
    const editor = useSlate();
    const focused = useEventEditorSelectors.focus() === plateId;

    const [
        isFocused,
        setFocused,
    ] = useState(false);
    const {resetObservable: onReset} = useStyledFieldState<{
        elements?: Node[];
    }>();

    const focus = () => {
        ReactEditor.focus(editor);
        onFocus();
        setFocused(true);
        Transforms.select(editor, Editor.end(editor, []));
    };
    const focusFunctionName = `focusEditor${testId ? capitalize(testId) : ''}`;
    (window as unknown as {[key: string]: () => void})[focusFunctionName] = focus;

    useMount(() => {
        if (autoFocus) {
            focus();
        }
    });

    const unFocusFunctionName = `unFocusEditor${testId ? capitalize(testId) : ''}`;
    (window as unknown as {[key: string]: () => void})[unFocusFunctionName] = () => {
        ReactEditor.blur(editor);
        onBlur();
        setFocused(false);
    };

    useDebounce(
        () => {
            /**
             * No clue why we cannot direct handle this event...
             * Fixes focus lost of the first click.
             */
            if (focused) {
                onFocus();
                setFocused(true);
            } else {
                onBlur();
                setFocused(false);
            }
        },
        50,
        [focused]
    );

    const setNodes = useCallbackRef((nodes: Node[]) => {
        resetNodes(editor, {
            nodes,
        });
    });

    useEffect(() => {
        const subscription = onReset.subscribe((previousElements) => setNodes(previousElements?.elements ?? []));
        return () => {
            subscription.unsubscribe();
        };
    }, [
        onReset,
        setNodes,
    ]);

    useEffect(() => {
        const subscription = timer(500).subscribe(() => {
            if (isFocused) {
                return;
            }
            setNodes(value as Node[]);
        });

        return () => subscription.unsubscribe();
    }, [
        value,
        setNodes,
        isFocused,
    ]);

    const {handleSubmit, dirty, values, initialValues, isSubmitting} = formik;

    const submitCallback = useCallbackRef(() => {
        if (!dirty && isEqual(values, initialValues)) {
            return;
        }
        // Wait until the previous is completed.
        if (isSubmitting) {
            return;
        }

        handleSubmit();

        setNodes(value as Node[]);
    });

    useKeys('OpgemaakteTekstEditor: On submit handler', singleLine ? 'enter' : 'cmd+enter,ctrl+enter', submitCallback, {
        enableOnInput: focused,
        enabled: focused,
    });

    return null;
};

export const OpgemaakteTekstInput: FC<OpgemaakteTekstInputProps> = memo(
    ({
        plugins,
        field: {name},
        onBlur,
        onFocus,
        isSubmitting,
        floatingToolbar = true,
        form,
        meta,
        readonly,
        disabled,
        disableToolbar,
        isFocussed,
        autoFocus,
        nested,
        testId,
        borderVisible,
        ...props
    }) => {
        const [
            plateId,
        ] = usePlateEditorId();
        const [
            {value},
            ,
            {setValue},
        ] = useField(name);
        const focused = useEventEditorSelectors.focus() === plateId || isFocussed;
        const definedValue = useMemo(() => value?.elements ?? [], [value]);
        const formik = useFormikContext();
        const submitting = formik.isSubmitting || isSubmitting;

        return (
            <FallbackScrollProvider>
                <OpgemaakteTekstEditor
                    {...props}
                    {...(readonly ? {placeholder: ''} : {})}
                    plugins={plugins}
                    floatingToolbar={floatingToolbar}
                    disabled={disabled}
                    readonly={readonly}
                    plateId={plateId}
                    value={definedValue}
                    focused={focused}
                    isSubmitting={submitting}
                    disableToolbar={disableToolbar}
                    borderVisible={borderVisible}
                    setValue={useCallback(
                        (elements: Descendant[]) => {
                            if (disabled || readonly) {
                                return;
                            }
                            setValue({elements});
                        },
                        [
                            setValue,
                            disabled,
                            readonly,
                        ]
                    )}
                >
                    <BoundEvents
                        formik={formik}
                        singleLine={props.singleLine ?? false}
                        autoFocus={autoFocus}
                        value={definedValue}
                        plateId={plateId}
                        onFocus={onFocus ?? noop}
                        onBlur={onBlur ?? noop}
                        testId={testId}
                    />
                    {nested}
                </OpgemaakteTekstEditor>
            </FallbackScrollProvider>
        );
    }
);

OpgemaakteTekstInput.displayName = 'OpgemaakteTekstInput';

export const SingleLineOpgemaakteTekstInput: FC<OpgemaakteTekstInputProps> = (props) => (
    <OpgemaakteTekstInput singleLine disableToolbar {...props} />
);
