/* eslint-disable */
import {FC} from 'react';
import {
    ITimeRegistrationButtonConnectedFragment,
    ITimeRegistrationButtonConnectedProfielFragment,
} from '@growthbase/graphql';
import {TimeRegistrationButton} from '@growthbase/clientbox-uren';
import {BaseButtonProps} from '@growthbase/design-components';

export interface TimeRegistrationButtonConnectedProps {
    taakFragment: ITimeRegistrationButtonConnectedFragment;
    profielFragment: ITimeRegistrationButtonConnectedProfielFragment;
    Button?: FC<BaseButtonProps>;
}

export const TimeRegistrationButtonConnected: FC<TimeRegistrationButtonConnectedProps> = ({
    Button,
    profielFragment: {profielUrenInstellingen},
    taakFragment: {
        id,
        titel,
        relatie,
        onderdeelVan,
        availableActions: {uren},
    },
}) => {
    return (
        <TimeRegistrationButton
            Button={Button}
            taakId={id}
            enabled={uren?.bewerken ?? false}
            directBoekenOpRelatie={profielUrenInstellingen?.directBoekenOpRelatie ?? true}
            heeftOnderdeelVan={!!onderdeelVan?.onderdeel}
            heeftRelatie={!!relatie?.relatieverwijzing}
            onderdeelLabel={
                onderdeelVan?.onderdeel && titel in onderdeelVan.onderdeel
                    ? onderdeelVan.onderdeel?.titel
                    : relatie?.relatieverwijzing?.label ?? null
            }
            taakTitel={titel}
        />
    );
};
