import {IBijlagesVanTaakFragment, useBijlagesVanTaakLazyQuery, useTaakBijlagesLazyQuery} from '@growthbase/graphql';
import {
    BestandThumbnailConnected,
    useDownloadBestandFactory,
    useBestandSlideshow,
    Bestand,
} from '@growthbase/bestandsbeheer';
import {idFromIri} from '@growthbase/routing';
import {FC, Fragment, useCallback} from 'react';
import {BijlageTeaser} from './BijlageTeaser';
import {BijlagesTooltip} from './BijlageTooltip';
import {useTaakBijlagesEnabled} from '../Hook/useTaakBijlagesEnabled';
import {useEntityChanges} from './Hook/useEntityChanges';

export interface BijlagesTooltipConnectedProps {
    fragment?: IBijlagesVanTaakFragment;
}

export const BijlagesTooltipConnected: FC<BijlagesTooltipConnectedProps> = ({fragment}) => {
    const taakId = fragment?.id ? idFromIri(fragment.id) : '';

    const [fetch, result] = useBijlagesVanTaakLazyQuery({
        variables: {
            id: taakId,
        },
    });
    const enabled = useTaakBijlagesEnabled();
    const download = useDownloadBestandFactory();

    const data = fragment?.heeftBijlages;

    const [query, {called}] = useTaakBijlagesLazyQuery({
        variables: {
            taakId,
        },
    });

    useEntityChanges({
        taakId,
        isOpen: called,
    });

    const {slideshow} = useBestandSlideshow({
        id: `BijlagesTooltip-${taakId}`,
        load: useCallback(
            async () =>
                query({
                    fetchPolicy: 'network-only',
                    nextFetchPolicy: 'network-only',
                }).then((r) => {
                    const fetched = r.data?.taak?.bijlages;
                    if (!fetched) {
                        return [];
                    }
                    return fetched.map(({bestand}) => bestand).filter((bestand) => !!bestand.url) as Bestand[];
                }),
            [query]
        ),
        render: (api) =>
            !data || data.aantal === 0 ? null : (
                <BijlagesTooltip
                    open={() => api.open()}
                    bijlages={result?.data?.taak?.bijlages ?? []}
                    render={(bijlage) => {
                        const {bestand, id, naam} = bijlage;
                        return (
                            <BijlageTeaser
                                key={id}
                                bestandId={bestand.id}
                                download={() => download(bestand.id, naam)}
                                naam={naam}
                                uploaded={bestand.uploaded}
                                thumbnail={<BestandThumbnailConnected fragment={bestand} />}
                            />
                        );
                    }}
                    onShow={fetch}
                />
            ),
    });

    if (!enabled) {
        return null;
    }

    // eslint-disable-next-line
    return <Fragment>{slideshow}</Fragment>;
};
