import styled from 'styled-components';
import {ReactNode} from 'react';
import {capitalize} from 'lodash';
import {Spacing} from '../../../../Layout';

export interface CardBodyProps {
    children?: ReactNode;
    padding?: Spacing;
}

const CardBodyRoot = styled.div<CardBodyProps>`
    ${(props) => props.padding !== 'none' && `padding: var(--spacing${capitalize(props.padding)});`}

    & + & {
        border-top: 1px solid var(--cardColorBorder);
    }
`;

/**
 * @deprecated use Card.Body instead
 */
export const CardBody = ({children, padding = 'medium'}: CardBodyProps) => (
    <CardBodyRoot padding={padding}>{children}</CardBodyRoot>
);
