import {FC} from 'react';
import {
    ArrayInstellingInput,
    BooleanInstellingInput,
    InstellingInputProps,
    NestedContainerInstellingInput,
    NestedInstellingInput,
    NumberInstellingInput,
    TextInstellingInput,
} from './Components';
import {InstellingFieldType} from '../../types';

export function defaultFormComponent(type: InstellingFieldType, name: string, depth: number): FC<InstellingInputProps> {
    switch (type) {
        case InstellingFieldType.string:
            return TextInstellingInput;
        case InstellingFieldType.boolean:
            return BooleanInstellingInput;
        case InstellingFieldType.number:
            return NumberInstellingInput;
        case InstellingFieldType.array:
            return ArrayInstellingInput;
        case InstellingFieldType.nested:
            return depth === 0 ? NestedInstellingInput : NestedContainerInstellingInput;
        default:
            throw new Error(`Unknown type ${type} for field ${name}`);
    }
}
