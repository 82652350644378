import {DNDUserDataType, RenderDraggableItemProps} from '@growthbase/keys';
import {usePaginationFixedResult} from '@growthbase/spa';
import React, {ReactNode} from 'react';
import classNames from 'classnames';
import {Table, TableProps} from '../../Table';
import {DraggableTableRowInterface} from '../DraggableTableBody';
import {TableRowInterface} from '../../TableRowInterface';
import {RenderRowProps, TableBody, TableBodyProps} from '../TableBody';
import tableStyles from '../../Table.module.scss';
import {Drag} from '../../../../icons/svg';
import styles from './DraggableTableRow.module.scss';

interface DraggedRowProps<TUserDataType extends DNDUserDataType, TRow extends DraggableTableRowInterface>
    extends Omit<TableProps<TRow>, 'pagination'>,
        RenderDraggableItemProps<TUserDataType, TRow> {}

/* eslint-disable react/jsx-key */

export function renderDraggedRow<TRow extends TableRowInterface>({row}: RenderRowProps<TRow>) {
    return (
        <div
            {...row.getRowProps()}
            data-test="row"
            data-testid={row.id}
            className={classNames([tableStyles.table__tr], {
                [tableStyles['table__tr--locked']]: row.original.isLocked,
            })}
        >
            {row.cells.map((cell, i) => (
                <div
                    {...cell.getCellProps()}
                    data-test="cell"
                    className={classNames([tableStyles.table__td])}
                    style={{position: 'relative', ...cell.getCellProps().style}}
                >
                    <>
                        {i === 0 && (
                            <div className={styles.draggableTableRow__handler}>
                                <Drag />
                            </div>
                        )}
                        {cell.render('Cell')}
                    </>
                </div>
            ))}
        </div>
    );
}

function renderDraggedBody<TRow extends TableRowInterface>(props: TableBodyProps<TRow>): ReactNode {
    return (
        <>
            <TableBody {...props} renderRow={renderDraggedRow} dragged />
        </>
    );
}

export function DraggedRow<TUserDataType extends DNDUserDataType, TRow extends DraggableTableRowInterface>({
    item,
    ...props
}: DraggedRowProps<TUserDataType, TRow>) {
    const pagination = usePaginationFixedResult<TRow>([item.userData]);
    return (
        <div style={{height: '50px'}} aria-label="dragged-row">
            <Table<TRow> ColumnHeader={() => null} pagination={pagination} {...props} renderBody={renderDraggedBody} />
        </div>
    );
}
