import {InstellingField} from './types';

export class InstellingFieldCollection {
    private fields: InstellingField[];

    constructor(fields: InstellingField[]) {
        this.fields = fields;
    }

    public add(field: InstellingField): void {
        if (!field.parent) {
            delete field.parent;
        }
        this.fields.push(field);
    }

    public get(name: string): InstellingField {
        const field = this.fields.find((f) => f.name === name);
        if (!field) {
            throw new Error(`Field '${name}' not found`);
        }
        return field;
    }

    public getFields(names?: string[]): InstellingField[] {
        if (!names) {
            return this.fields;
        }
        return this.fields.filter((f) => names.includes(f.name));
    }

    public filter(names?: string[]): InstellingFieldCollection {
        return new InstellingFieldCollection(this.getFields(names));
    }

    public names(): string[] {
        return this.fields.map((f) => f.name);
    }

    rootFields(): InstellingFieldCollection {
        return new InstellingFieldCollection(this.fields.filter((f) => !f.parent));
    }

    map<T>(mapper: (field: InstellingField) => T) {
        return this.fields.map(mapper);
    }

    toArray() {
        return this.fields;
    }
}
