/* eslint-disable */
import React, {FC, useMemo, useRef} from 'react';
import {
    BaseButtonProps,
    Button as DefaultButton,
    IconButton,
    Icons,
    IconSize,
    useOptionalPopup,
    usePopup,
} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';
import {RegistrationLegacyRoutes} from '@growthbase/clientbox-uren';
import {idFromIri} from '@growthbase/routing';
import {useLegacyTimeRegistration} from '../Hook/useLegacyTimeRegistration';
import moment from 'moment';
import {useStop} from './Hook/useStop';

export interface TimeRegistrationButtonProps {
    enabled?: boolean;
    taakId: string;
    taakTitel: string;
    heeftRelatie: boolean;
    heeftOnderdeelVan: boolean;
    directBoekenOpRelatie: boolean;
    onderdeelLabel: string | null;
    Button?: FC<BaseButtonProps>;
}

export const TimeRegistrationButton: FC<TimeRegistrationButtonProps> = ({
    Button = DefaultButton,
    taakId,
    taakTitel,
    onderdeelLabel,
    heeftOnderdeelVan,
    heeftRelatie,
    directBoekenOpRelatie,
    enabled,
}) => {
    const ref = useRef<HTMLAnchorElement | null>(null);
    const api = useOptionalPopup();
    const {t} = useTranslation('ClientboxUren.nl');
    const id = idFromIri(taakId);
    const create = RegistrationLegacyRoutes.time_create.useUrl({
        taakId: id,
    });

    const data = useLegacyTimeRegistration({taak: id});

    const [loading, stop] = useStop(data?.id ?? null);

    let cantBookText: string | null = useMemo(() => {
        if (loading) {
            return t('stopLoading');
        }
        if (!heeftRelatie) {
            return t('kanGeenTijdBoekenAlsErNiksGekoppeldIs');
        }
        if (!heeftOnderdeelVan && !directBoekenOpRelatie) {
            return t('kanGeenTijdBoekenOpAlleenEenRelatie', {
                label: onderdeelLabel,
            });
        }
        return null;
    }, [loading, heeftOnderdeelVan, heeftRelatie, directBoekenOpRelatie, t]);

    const bookTekst = useMemo(() => {
        if (cantBookText) {
            return cantBookText;
        }

        const titel = `${taakTitel} - ${onderdeelLabel}`;

        if (data) {
            return t('stoppen', {titel});
        }
        return t('startUrenBoeken', {titel});
    }, [taakTitel, onderdeelLabel, data, cantBookText]);

    if (!enabled && !data) {
        return null;
    }

    return (
        <>
            {data && (
                <Button
                    testId="TimeRegistrationButton"
                    Icon={Icons.Timer}
                    size="medium"
                    iconSize={IconSize.SIZE_5}
                    tooltip={bookTekst}
                    disabled={loading || cantBookText !== null}
                    onClick={() => {
                        if (data) {
                            stop();
                            return;
                        }
                        // TODO: base or we need to fix z-index from the model.
                        api?.close();
                        ref.current?.click();
                    }}
                >
                    {moment.utc(data.seconds * 1000).format('HH:mm:ss')}
                </Button>
            )}
            {!data && (
                <IconButton
                    testId="TimeRegistrationButton"
                    Icon={Icons.Timer}
                    iconSize={IconSize.SIZE_5}
                    tooltip={bookTekst}
                    disabled={loading || cantBookText !== null}
                    onClick={() => {
                        if (data) {
                            stop();
                            return;
                        }
                        // TODO: base or we need to fix z-index from the model.
                        api?.close();
                        ref.current?.click();
                    }}
                />
            )}
            {/*TODO: weet iemand hier betere manier voor? browser vind dit niet heel leuk */}
            <a
                href="javascript://"
                data-action={create}
                data-modal-title={t('startUrenBoeken', {
                    titel: taakTitel,
                })}
                data-modal-size="modal-md"
                ref={ref}
                className="button button-primary icon-add app-create-timeregistration-button"
                style={{
                    display: 'none',
                }}
            ></a>
        </>
    );
};
