import type {
    OpgemaakteTekstElement,
    OpgemaakteTextPluginsServices,
    PlateReadonlyRenderer,
} from '@growthbase/design-components';
import React, {ReactNode} from 'react';
import {createGebruikersMentionPluginConnected} from './OpgemaakteTekstEditor/createGebruikersMentionPlugin';
import {GebruikerMentionReadonly} from './OpgemaakteTekstEditor/GebruikerMention';

export const gebruikerReadOnlyRenderer: PlateReadonlyRenderer = {
    render: (element: OpgemaakteTekstElement): ReactNode => {
        if ('type' in element && element.type === 'gebruiker_mention') {
            return <GebruikerMentionReadonly gebruiker={element.gebruiker} />;
        }
        return null;
    },
};

export const createGebruikerServices = ({
    platePlugins = [],
    readOnlyComponents = [],
}: OpgemaakteTextPluginsServices): unknown => ({
    platePlugins: [...platePlugins, createGebruikersMentionPluginConnected()],
    readOnlyComponents: [...readOnlyComponents, gebruikerReadOnlyRenderer],
});
