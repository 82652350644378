import {useCallbackRef} from '@growthbase/spa';
import * as React from 'react';
import {ReactNode, useContext, useEffect, useMemo, FC} from 'react';
import {Field, FieldAttributes, FieldProps, useField, useFormikContext} from 'formik';
import {Observable, Subject} from 'rxjs';
import {ObjectInspector} from 'react-inspector';
import {v4} from 'uuid';
import {StrictFormStateContext} from '../../Hook';
import {StrictFieldProps} from '../../Type';
import {useFieldIsDirty} from './Hook/useFieldIsDirty';
import {StyledFieldState, StyledFieldStateContext} from './Hook';
import {FieldWrapperControlsProps} from './Components/FieldWrapperControls';
import {useFieldControls} from './Hook/useFieldControls';
import {FieldErrors} from './FieldErrors';
import {InputIsActiveField, useInputIsActive} from './Hook/useInputIsActive';
import {FieldLabel} from '../FieldLabel';
import {FieldWrapper} from './Components/FieldWrapper';

export interface FormikFieldProps<TValue> extends FieldProps<TValue> {
    disabled?: boolean;
    hasError?: boolean;
    hasControls?: boolean;
    readonly?: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
}

export interface FieldWrapperControlledProps<TSchema extends object, TName extends keyof TSchema>
/**
 * Only use component properly.
 *
 * With 'as' or 'children' the disabled property won't be passed down.
 */
    extends Omit<StrictFieldProps<TSchema, TName>, 'as' | 'component' | 'children' | 'placeholder'> {
    placeholder?: string | null;
    label?: ReactNode;
    labelHidden?: boolean;
    controls?: FC<FieldWrapperControlsProps> | boolean;
    controlsPosition?: 'right' | 'bottom';
    debug?: boolean;
    readonly?: boolean;
    compact?: boolean;
    enabledDuringSubmit?: boolean;
    hideWarningOnFocusLost?: boolean;
    inlineEditable?: boolean;
    component?: FC<FormikFieldProps<TSchema[TName]>>;
    testfieldName?: string;
    mui?: boolean;
}

export function FieldWrapperControlled<TSchema extends object, TName extends keyof TSchema>({
    debug = false,
    enabledDuringSubmit = false,
    labelHidden,
    controls,
    controlsPosition,
    compact,
    hideWarningOnFocusLost = false,
    inlineEditable = false,
    readonly = false,
    testfieldName = undefined,
    mui = false,
    ...props
}: FieldWrapperControlledProps<TSchema, TName>) {
    const name = props.name as string;
    const {id, label} = props;
    const formik = useFormikContext();
    const {field, isActive} = useInputIsActive<TSchema>(props as unknown as InputIsActiveField<TSchema>);
    const state = useMemo(
        (): StyledFieldState<TSchema> => ({
            resetObservable: new Subject<TSchema>(),
        }),
        []
    );
    const Controls = useFieldControls(controls);
    const isDirty = useFieldIsDirty(name);
    const [fieldProps, metaProps] = useField(name);
    const {error} = metaProps;
    const showError = !!error && (formik.submitCount > 0 || !hideWarningOnFocusLost);
    const htlmFor = useMemo(() => id || v4() + name, [id, name]);
    const {onReset} = useContext(StrictFormStateContext) ?? {};
    const update = useCallbackRef<[Record<TName, TSchema>], void>((values) => {
        (state.resetObservable as Subject<TSchema>).next(values[props.name]);
    });

    useEffect(() => {
        const subscription = (onReset as Observable<Record<TName, TSchema>>).subscribe(update);
        return () => subscription.unsubscribe();
    }, [onReset, update]);

    if (mui) {
        return (
            <>
                <Field {...props} />
                <FieldErrors noFocusLostWarning={hideWarningOnFocusLost} name={name} />
            </>
        );
    }

    if (props.type === 'hidden') {
        return (
            <>
                <Field {...props} />
                <FieldErrors noFocusLostWarning={hideWarningOnFocusLost} name={name} />
            </>
        );
    }

    return (
        <FieldWrapper
            buttonPosition={controlsPosition}
            negativeMargin={inlineEditable}
            errorContent={<FieldErrors noFocusLostWarning={hideWarningOnFocusLost} name={name} />}
            labelContent={
                label && (
                    <FieldLabel hidden={labelHidden} compact={compact} htmlFor={htlmFor}>
                        {label}
                    </FieldLabel>
                )
            }
            buttonContent={
                Controls && (
                    <Controls
                        onReset={state.resetObservable as Subject<unknown>}
                        isActive={isActive}
                        loading={formik.isSubmitting}
                        name={name}
                        isDirty={isDirty}
                    />
                )
            }
            data-testfield={testfieldName ?? name}
            data-testidle={!isDirty && !formik.isSubmitting}
        >
            <StyledFieldStateContext.Provider value={state}>
                <Field
                    {...field}
                    id={htlmFor}
                    isInlineEditable={inlineEditable}
                    isActive={isActive}
                    readonly={readonly}
                    hasError={showError}
                    hasControls={controls}
                    disabled={
                        !enabledDuringSubmit && formik.isSubmitting
                            ? true
                            : (field as unknown as FieldAttributes<unknown>).disabled
                    }
                />
            </StyledFieldStateContext.Provider>
            {debug && (
                <div>
                    <pre>
                        <ObjectInspector data={{isActive, isSubmitting: formik.isSubmitting, isDirty}} />
                    </pre>
                    <pre>
                        <ObjectInspector data={fieldProps} />
                    </pre>
                    <pre>
                        <ObjectInspector data={metaProps} />
                    </pre>
                    <pre>
                        <ObjectInspector data={formik} />
                    </pre>
                </div>
            )}
        </FieldWrapper>
    );
}
