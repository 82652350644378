import {ReactNode, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {StyledSelectOption} from '@growthbase/design-components';
import {IZichtbaarVoor} from '@growthbase/graphql';

export interface ZichtbaarVoorOptie extends StyledSelectOption<IZichtbaarVoor> {
    id: string;
}

export const useZichtbaarVoorOpties = (heeftDeelnemers: boolean) => {
    const {t} = useTranslation('TakenlijstBord.nl');
    return useMemo(
        (): ZichtbaarVoorOptie[] => [
            {
                id: IZichtbaarVoor.Iedereen,
                value: IZichtbaarVoor.Iedereen,
                label: t('zichtbaarVoor.iedereen'),
            },
            {
                id: IZichtbaarVoor.Mijzelf,
                value: IZichtbaarVoor.Mijzelf,
                label: t('zichtbaarVoor.mijzelf'),
            },
            {
                id: IZichtbaarVoor.Deelnemers,
                value: IZichtbaarVoor.Deelnemers,
                label: t('zichtbaarVoor.deelnemers'),
            },
        ],
        [t]
    );
};

export const useZichtbaarVoorLabel = (zichtbaarVoor: IZichtbaarVoor): ReactNode => {
    const {t} = useTranslation('TakenlijstBord.nl');
    const opties = useZichtbaarVoorOpties(false);
    const optie = opties.find((o) => o.value === zichtbaarVoor);
    return optie?.label ?? t('zichtbaarVoor.iedereen');
};
