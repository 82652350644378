import {ITaakOmschrijvingVanTaakFragment, useTaakOmschrijvingVanTaakLazyQuery} from '@growthbase/graphql';
import {normalizeOpgemaakteTekstGraphQLResult, opgemaakteTekstAsText} from '@growthbase/design-components';
import {truncate} from 'lodash';
import {FC, useMemo} from 'react';
import {TaakOmschrijvingTooltip} from './TaakOmschrijvingTooltip';

export interface TaakOmschrijvingTooltipConnectedProps {
    fragment?: ITaakOmschrijvingVanTaakFragment;
}

export const TaakOmschrijvingTooltipConnected: FC<TaakOmschrijvingTooltipConnectedProps> = ({fragment}) => {
    const [fetch, result] = useTaakOmschrijvingVanTaakLazyQuery({
        variables: {
            id: fragment?.id ?? '',
        },
    });

    const opgemaakteTekst = result?.data?.taak?.omschrijving?.opgemaakteTekst;

    const tekst = useMemo(() => {
        const normalizedData = normalizeOpgemaakteTekstGraphQLResult(opgemaakteTekst);
        return opgemaakteTekstAsText(normalizedData);
    }, [opgemaakteTekst]);

    if (!fragment?.heeftOmschrijving) {
        return null;
    }

    return (
        <TaakOmschrijvingTooltip onShow={fetch}>
            {truncate(tekst, {
                length: 200,
                omission: '...',
            })}
        </TaakOmschrijvingTooltip>
    );
};
