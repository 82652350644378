import {useHistoryBack} from '@growthbase/routing';
import {useBordtemplateAlsBordToevoegenMutation, useBordtemplatesQuery} from '@growthbase/graphql';
import {v4} from 'uuid';
import {useNavigate} from 'react-router';
import {BordtemplateAlsBordToevoegenForm} from './BordtemplateAlsBordToevoegenForm';
import {useBordRouteViewFactory} from '../../../../takenlijst/bord/src/Hook';

export const BordtemplateAlsBordToevoegenFormConnected = () => {
    const goBack = useHistoryBack();
    const [submit] = useBordtemplateAlsBordToevoegenMutation();
    const {data, loading} = useBordtemplatesQuery();

    const viewBord = useBordRouteViewFactory();
    const navigator = useNavigate();

    return (
        <BordtemplateAlsBordToevoegenForm
            onSubmit={(values) => {
                const id = v4();
                return submit({
                    variables: {
                        input: {
                            id,
                            bordtemplateId: values.bordtemplate,
                            bordId: id,
                            deelnemers: values.deelnemers.map((deelnemer) => deelnemer.id),
                            item: values.onderdeelVan
                                ? {
                                      id: values.onderdeelVan.id,
                                      type: values.onderdeelVan.type,
                                  }
                                : undefined,
                        },
                    },
                }).then(async ({data: rd}) => {
                    const takenBord = rd?.MaakBordOpBasisVanBordtemplate?.takenBord;
                    if (!takenBord) {
                        return goBack();
                    }
                    const href = viewBord(takenBord, {
                        replace: true,
                    });
                    return navigator(href);
                });
            }}
            optionsLoading={loading}
            options={
                data?.Bordtemplates?.edges?.map((bordtemplate) => ({
                    value: bordtemplate?.node?.id ?? '',
                    label: bordtemplate?.node?.naam ?? '',
                })) ?? []
            }
        />
    );
};
