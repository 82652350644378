import React, {FC, InputHTMLAttributes, ReactNode} from 'react';
import classNames from 'classnames/bind';
import styles from './Checkbox.module.scss';
import {Icons} from '../../../icons';

const cx = classNames.bind(styles);

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: ReactNode;
    box?: boolean;
    className?: string;
}

export const Checkbox: FC<CheckboxProps> = ({checked, label, box, disabled, className, ...props}) => {
    const classes = cx(
        'checkbox',
        {
            'checkbox--checked': checked ?? false,
            'checkbox--label': label,
            'checkbox--box': box,
            'checkbox--disabledBox': disabled && box,
            'checkbox--disabled': disabled,
        },
        className
    );

    return (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label className={classes}>
            <div className={styles.checkbox__checkbox}>
                <input
                    type="checkbox"
                    checked={checked}
                    disabled={disabled}
                    {...props}
                    className={styles.checkbox__input}
                />
                <Icons.Check className={styles.checkbox__icon} />
            </div>
            <span className={styles.checkbox__label}>{label}</span>
        </label>
    );
};
