import React, {useEffect, useMemo, useReducer, FC} from 'react';
import {ButtonLink, Comment, CommentFormWrapper} from '@growthbase/design-components';
import {TwigComponentRoot} from '@growthbase/kernel';
import {MemoryRouter} from 'react-router';
import {ReactiesTwigConnectorInputDtoSchema} from './ReactiesTwigInputSchema';
import {ReactieMenu} from '../ReactieMenu';
import {loadComments} from './hooks/loadComments';
import {reactiesInitialState, ReactiesRedcucerType, reactiesReducer} from './reducer/ReactiesReducer';
import {ReactieTextArea} from './components/ReactieTextArea';
import {useSubscribeToSocketEvents} from './hooks/useSubscribeToSocketEvents';
import {buttonText} from './hooks/buttonText';

export interface ReactiesTwigConnectedProps {
    data: unknown;
}

export const ReactiesTwigConnected: FC<ReactiesTwigConnectedProps> = ({data}) => {
    // Parsed data from html source
    const syncData = useMemo(() => ReactiesTwigConnectorInputDtoSchema.validateSync(data), [data]);
    const {fetchUrl, translations, reactionCount} = syncData;

    const [state, dispatch] = useReducer<ReactiesRedcucerType>(reactiesReducer, reactiesInitialState);

    // Subscribe to websocket event (when new reaction is placed)
    useSubscribeToSocketEvents(state.showing, syncData, dispatch);

    // Set count from async data to state
    useEffect(() => {
        dispatch({type: 'setCount', count: reactionCount});
    }, [dispatch, reactionCount]);

    // Get messages from json endpoint when reactions are first showed
    useEffect(() => {
        if (state.needsUpdate && fetchUrl) {
            loadComments(fetchUrl, dispatch);
        }
    }, [fetchUrl, state.showing, state.needsUpdate]);

    return (
        <TwigComponentRoot>
            <MemoryRouter>
                <div style={{paddingBottom: 20, paddingLeft: 20}}>
                    <ButtonLink color="primary" onClick={() => dispatch({type: 'toggle'})}>
                        {buttonText(state.loading, state.showing, translations)}
                    </ButtonLink>
                </div>
                {state.showing && (
                    <CommentFormWrapper name={syncData.currentUser.name} avatarUrl={syncData.currentUser.avatarUrl}>
                        <ReactieTextArea formHTML={syncData.commentForm} />
                    </CommentFormWrapper>
                )}
                {state.data.reactions &&
                    state.data.reactions.map((item) => (
                        <Comment
                            key={item.id}
                            {...item}
                            menuContent={
                                <ReactieMenu
                                    deleteUrl={item.deleteUrl}
                                    editUrl={item.editUrl}
                                    translations={syncData.translations}
                                />
                            }
                        >
                            {/* eslint-disable-next-line react/no-danger */}
                            <div dangerouslySetInnerHTML={{__html: item.text}} />
                        </Comment>
                    ))}
            </MemoryRouter>
        </TwigComponentRoot>
    );
};
