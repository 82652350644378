import {QueryResult} from '@apollo/client/react/types/types';
import {useConvertFetchEntity, useUpdateQueryCacheFromExternalEntityChanges} from '@growthbase/cache';
import {
    ITakenVanLijstSwimlaneKaartFragment,
    ITakenVanLijstSwimlaneQuery,
    ITakenVanLijstSwimlaneQueryVariables,
    refetchTakenVanLijstSwimlaneSingleQuery,
} from '@growthbase/graphql';
import {idFromIri} from '@growthbase/routing';
import {determineTaakUpdateActionOnEntityChanges} from '@growthbase/taken';
import {useCallback} from 'react';
import {OverviewType, TaakFilters} from '../../../../../types';

export interface EntityChangesParams {
    filters: TaakFilters;
    lijstId: string;
    result: QueryResult<ITakenVanLijstSwimlaneQuery, ITakenVanLijstSwimlaneQueryVariables>;
}

export const useEntityChanges = ({result, filters, lijstId}: EntityChangesParams) => {
    const id = idFromIri(lijstId);
    useUpdateQueryCacheFromExternalEntityChanges<ITakenVanLijstSwimlaneKaartFragment>({
        // @ts-expect-error TODO: fix variables, we don't need to match that.
        result,
        determineAction: useCallback(
            (params) => {
                let filterVoltooid;
                if (filters.overviewType === OverviewType.Voltooid) {
                    filterVoltooid = true;
                }
                if (filters.overviewType === OverviewType.Overzicht) {
                    filterVoltooid = false;
                }
                // @ts-expect-error TODO: make common interface for filters.
                return determineTaakUpdateActionOnEntityChanges(params, {
                    searchQuery: filters.searchQuery,
                    filterGearchiveerd: false,
                    reorderForList: id,
                    filterVoltooid,
                    filterByGebruikerIds: filters.deelnemers,
                    filterByLijstIds: [id],
                });
            },
            [
                filters,
                id,
            ]
        ),
        fetchEntity: useConvertFetchEntity(refetchTakenVanLijstSwimlaneSingleQuery),
    });
};
