import {useField} from 'formik';
import {FC} from 'react';
import {FormikFieldProps} from '../FieldWrapper';
import {Datepicker} from './Datepicker';
import {DatePickerValue} from './types/DatePickerValue';

export interface DatepickerFieldProps extends FormikFieldProps<DatePickerValue> {
    initialValue?: DatePickerValue;
}

export const DatepickerField: FC<DatepickerFieldProps> = ({field, form, meta, initialValue, ...props}) => {
    const [, , {setValue}] = useField(field.name);

    return <Datepicker initialValue={initialValue} {...field} {...props} onChange={setValue} />;
};
