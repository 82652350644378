import {SchemaOf} from 'yup';
import * as yup from 'yup';

export interface Select2ConfigDTO {
    url: string;
    symfonyFieldType: string;
    multiple: boolean;
    excludeIds: string[];
}

export const Select2ConfigDTOSchema: SchemaOf<Select2ConfigDTO> = yup
    .object({
        url: yup.string().required(),
        symfonyFieldType: yup.string().required(),
        multiple: yup.boolean().required(),
        excludeIds: yup.array().of(yup.string().required()).required(),
    })
    .label('Select2ConfigDTO')
    .required();
