import {Buttons} from '@growthbase/design-components';
import {TaakOverrideValues, TakenRoutes} from '@growthbase/taken';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

export interface TaakAddActionProps {
    overrideValues?: TaakOverrideValues;
}

export const TaakAddAction: FC<TaakAddActionProps> = ({overrideValues}) => {
    const {t} = useTranslation('spa_form.nl');
    const create = TakenRoutes.create.useNavigate(undefined, {state: {overrideValues}});
    return <Buttons.Add tooltip={t('taak.add')} key="add" onClick={create} />;
};
