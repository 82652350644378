import {useBordSelectBordConnectedQuery, useResolveConnections} from '@growthbase/graphql';
import React, {useMemo} from 'react';
import {DefaultKoppelbaarItem, DefaultRelatieValue, useHandleError} from '@growthbase/spa';
import {BordenOnderdeelVan, BordRoutes} from '@growthbase/takenlijst/bord';
import {BordtemplateRoutes} from '@growthbase/takentemplates-bordtemplate';
import {Bord, BordSelect} from './BordSelect';
import {useBordRouteViewFactory} from '../Hook';

/* eslint-disable react/no-unstable-nested-components */

export interface BordSelectConnectedProps {
    onderdeelVan?: BordenOnderdeelVan;
}

export function BordSelectConnected({onderdeelVan: onderdeelVanMutable}: BordSelectConnectedProps) {
    const onderdeelVan = onderdeelVanMutable;
    const result = useBordSelectBordConnectedQuery({
        variables: {
            type: onderdeelVan?.type,
            id: onderdeelVan?.id,
        },
        onError: useHandleError(),
    });

    const createUrl = useBordRouteViewFactory();
    const items = useResolveConnections(result.data?.items);
    const borden = useMemo(
        (): Bord[] =>
            items.map((item) => ({
                id: item.id,
                value: createUrl(item),
                label: item.naam,
            })),
        [items, createUrl]
    );

    return (
        <DefaultKoppelbaarItem.Provider id="BordSelectConnected" value={onderdeelVan}>
            <DefaultRelatieValue.Provider id="BordSelectConnected" value={onderdeelVan?.relatie}>
                <BordSelect
                    createUrl={BordRoutes.aanmaken.useUrl()}
                    borden={borden}
                    templateUrl={BordtemplateRoutes.bordtoevoegen.useUrl()}
                />
            </DefaultRelatieValue.Provider>
        </DefaultKoppelbaarItem.Provider>
    );
}
