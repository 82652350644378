import React, {FC, useMemo, useState} from 'react';

import {highlight, languages} from 'prismjs';

import 'prismjs/themes/prism.css';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-markup-templating';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-sass';

import 'prismjs/components/prism-json';
import 'prismjs/components/prism-yaml';
import 'prismjs/components/prism-markdown';
import 'prismjs/components/prism-graphql';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-csv';

import axios from 'axios';
import {useMount} from 'react-use';
import {Loader} from '@growthbase/design-components';
import {useLogger} from '@growthbase/spa';

/* eslint-disable */

export const CodeViewer: FC<{ext: string; code: string}> = ({ext, code}) => {
    const logger = useLogger('CodeViewer');
    const converted = useMemo(() => {
        if (code === '') {
            return '';
        }

        try {
            if (['js', 'tsx', 'ts'].includes(ext)) {
                return highlight(code, languages.javascript, 'javascript');
            }
            if (['html', 'xml', 'svg'].includes(ext)) {
                return highlight(code, languages.markup, 'markup');
            }
            if (['css'].includes(ext)) {
                return highlight(code, languages.css, 'css');
            }
            if (['scss'].includes(ext)) {
                return highlight(code, languages.sass, 'sass');
            }
            if (['json'].includes(ext)) {
                return highlight(code, languages.json, 'json');
            }
            if (['md'].includes(ext)) {
                return highlight(code, languages.markdown, 'markdown');
            }
            if (['graphql'].includes(ext)) {
                return highlight(code, languages.graphql, 'graphql');
            }
            if (['sql'].includes(ext)) {
                return highlight(code, languages.sql, 'sql');
            }
            if (['csv'].includes(ext)) {
                return highlight(code, languages.csv, 'csv');
            }
            if (['yaml', 'yml'].includes(ext)) {
                return highlight(code, languages.yaml, 'yaml');
            }
            if (['php'].includes(ext)) {
                return highlight(code, languages.php, 'php');
            }
            return highlight(code, languages.markup, 'markup');
        } catch (e) {
            logger.error(e);
        }

        // Escape html
        const div = document.createElement('div');
        div.innerText = code;
        return div.innerHTML;
    }, [ext, code, logger]);

    return (
        <div
            style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 12,
                padding: 10,
                overflow: 'auto',
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
            }}
            dangerouslySetInnerHTML={{
                __html: converted,
            }}
        />
    );
};

export const LoadCodeViewer: FC<{path: string; url: string}> = ({path, url}) => {
    const [code, setCode] = useState<string | null>(null);
    useMount(async () => {
        const response = await axios.get(url, {
            responseType: 'text',
        });
        setCode(response.data);
    });

    if (code === null) {
        return <Loader center />;
    }
    return <CodeViewer ext={path.split('.').pop() || ''} code={code} />;
};
