import React, {ReactNode, useEffect} from 'react';
import classNames from 'classnames/bind';
import styles from './Sidenav.module.scss';
import {useSidenavContext} from './useSidenavContext';
import {SidenavToggle} from '../SidenavToggle';

const cx = classNames.bind(styles);

export interface SidenavProps {
    children: ReactNode;
    isOpenDefault?: boolean;
}

export const Sidenav = ({children, isOpenDefault}: SidenavProps) => {
    const {isExpanded, toggleExpanded} = useSidenavContext();

    useEffect(() => {
        if (isOpenDefault) {
            toggleExpanded(isOpenDefault);
        }
    }, [isOpenDefault, toggleExpanded]);

    const classes = cx('sidenav', {
        'sidenav--expanded': isExpanded,
    });

    return (
        <div className={classes}>
            <SidenavToggle />
            {children}
        </div>
    );
};
