import {FieldCondition, FieldConditions} from '../Component';
import {Field, FieldInstance} from '../contract';
import {ConditionsValueType, ConditionValueType} from '../Types';

export const NOT: Field<FieldInstance> = {
    Component: FieldCondition,
    label: (t) => t('Not', {ns: 'querybuilder.nl'}),
    valueType: ConditionValueType,
    name: 'NOT',
};

export const NOTMultiple: Field<FieldInstance[]> = {
    Component: FieldConditions,
    label: (t) => t('Not', {ns: 'querybuilder.nl'}),
    valueType: ConditionsValueType,
    name: 'NOTS',
};
