import {useCallback, FC} from 'react';
import {SingleValueFieldForm, TextFieldBigComponent, useTitelSchema} from '@growthbase/design-components';

export interface TaaktitelFieldProps {
    titel: string;
    readonly?: boolean;
    onSubmit: (value: string) => Promise<void>;
}

export const TitelField: FC<TaaktitelFieldProps> = ({titel, readonly, onSubmit}) => (
    <SingleValueFieldForm
        disabled={false}
        readonly={readonly}
        component={TextFieldBigComponent}
        schema={useTitelSchema()}
        createInitialValues={useCallback(() => titel, [titel])}
        onSubmit={onSubmit}
    />
);
