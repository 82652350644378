import {GraphQLServices} from '@growthbase/graphql';
import {ApplicationContext, ApplicationServices, TranslationProvider} from '@growthbase/spa';
import React, {FC} from 'react';
import {Provider} from 'react-redux';
import {HelmetProvider} from 'react-helmet-async';
import '@growthbase/design-tokens';
import {RoutingProvider} from '@growthbase/routing';
import {BreadcrumbProvider} from '@growthbase/design-components';

type CombinedApplicationServices = ApplicationServices & GraphQLServices;

export interface SSRApplicationRootProps {
    services: ApplicationServices;
}

export const SSRApplicationRoot: FC<SSRApplicationRootProps> = ({services}) => {
    const GraphqlProvider = (services as CombinedApplicationServices).graphqlProvider;
    return (
        <>
            <HelmetProvider>
                <ApplicationContext.Provider value={services}>
                    <TranslationProvider>
                        <Provider store={services.store}>
                            <GraphqlProvider>
                                <RoutingProvider>
                                    <BreadcrumbProvider>
                                        <services.Components config={services.config} />
                                    </BreadcrumbProvider>
                                </RoutingProvider>
                            </GraphqlProvider>
                        </Provider>
                    </TranslationProvider>
                </ApplicationContext.Provider>
            </HelmetProvider>
        </>
    );
};
