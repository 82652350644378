import {useCallback} from 'react';
import {useOnDragCancel} from '../DNDReducer';

export const useOnDragCancelEvent = () => {
    const cancel = useOnDragCancel();

    return useCallback(() => {
        cancel();
    }, [cancel]);
};
