import React, {PropsWithChildren} from 'react';
import {Loader, Stack} from '@growthbase/design-components';
import {DNDContainerUserData} from '@growthbase/keys';

export interface ViewData extends DNDContainerUserData {
    titel: string;
}

export interface ViewProps extends PropsWithChildren {
    header: React.ReactNode;
}

export function View({children, header}: ViewProps) {
    if (!children) {
        return <Loader center />;
    }
    return (
        <Stack gap="medium">
            {header}
            {children}
        </Stack>
    );
}
