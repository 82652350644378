import {useAsyncComboBox, useComboBoxContext} from '@growthbase/design-components';
import React, {useEffect, useMemo} from 'react';
import {IKoppelbaarType} from '@growthbase/graphql';
import {ExternalEntityWithLabel, ExternalLinkType} from '@growthbase/clientbox';
import {ComboboxOnderdeelVanItem, OnderdeelVanCombobox, OnderdeelVanMentionKey} from './OnderdeelVanCombobox';

const adPatat: ExternalEntityWithLabel = {
    label: 'Ad patat',
    id: '1',
    linkType: ExternalLinkType.Persoon,
};

const klassPatat: ExternalEntityWithLabel = {
    label: 'Klaas patat',
    id: '2',
    linkType: ExternalLinkType.Persoon,
};

const items: ComboboxOnderdeelVanItem[] = [
    {
        id: '0',
        label: 'O-21-01391 - Frikandelbroodjes',
        groupHeader: 'Opdrachten',
        relatieHeader: 'Ad patat',
        externalId: 'clientbox:123',
        type: IKoppelbaarType.Opdracht,
        relatie: {
            persoon: adPatat,
        },
    },
    {
        id: '1',
        label: 'Schilder BV',
        externalId: 'clientbox:456',
        type: IKoppelbaarType.Opdracht,
        relatie: {
            persoon: adPatat,
        },
    },
    {
        id: '2',
        groupHeader: 'Tickets',
        label: 'T-21-01392 - Eten',
        externalId: 'clientbox:456',
        type: IKoppelbaarType.Ticket,
        relatie: {
            persoon: adPatat,
        },
    },
    {
        id: '3',
        label: 'Frituurvet verwijderen',
        groupHeader: 'Abonnementen',
        relatieHeader: 'Klaas patat',
        externalId: 'clientbox:456',
        type: IKoppelbaarType.Abonnement,
        relatie: {
            persoon: klassPatat,
        },
    },
    {
        id: '4',
        label: 'Frietjes bakken',
        externalId: 'clientbox:456',
        type: IKoppelbaarType.Abonnement,
        relatie: {
            persoon: klassPatat,
        },
    },
];

export const OnderdeelVanComboboxStub = () => {
    const {setLoading} = useComboBoxContext();
    const {text} = useAsyncComboBox(OnderdeelVanMentionKey);

    useEffect(() => {
        setLoading(true);
    }, [setLoading, text]);

    const result = useMemo(
        () => items.filter((item) => !text || item.label.toLowerCase().includes(text.toLowerCase())),
        [text]
    );

    return <OnderdeelVanCombobox items={result} />;
};
