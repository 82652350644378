import {ITaakKopierenFormFragment} from '@growthbase/graphql';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {InstellingenKopieerForm} from '@growthbase/instellingen';
import {useTaakKopierenOpties} from './Hook/useTaakKopierenOpties';

export interface TaakKopierenPropertiesValues {
    alles?: boolean;
    omschrijving: boolean;
    planning: boolean;
    deelnemers: boolean;
    relatie: boolean;
    volgers: boolean;
    gekoppeldeItem: boolean;
    reacties: boolean;
    mijnDag: boolean;
    bijlages: boolean;
}

export interface TaakKopierenFormValues {
    title: string;
    activiteiten: boolean;
    properties: TaakKopierenPropertiesValues;
}

export interface TaakKopierenFormProps {
    fragment: ITaakKopierenFormFragment;
    onSubmit: (values: TaakKopierenFormValues) => Promise<void>;
}

export const TaakKopierenForm: FC<TaakKopierenFormProps> = ({fragment: {titel}, onSubmit}) => {
    const {t} = useTranslation('spa_form.nl');
    return (
        <InstellingenKopieerForm<TaakKopierenFormValues>
            fields={useTaakKopierenOpties(titel)}
            titel={t('taak.copyTaskModaltitel')}
            onSubmit={onSubmit}
        />
    );
};
