import {ViewFormat} from '../../ViewFormat';

export const PlanningDayViewFormat: ViewFormat = {
    id: 'planning-dag',
    group: 'planning',
    label: 'Dag',
    settings: {
        time: true,
    },
    view: {
        timeline: {
            type: 'day',
            timeLabelStep: 30,
            timeCellStep: 30,
            startTime: '05:00',
            endTime: '22:00',
            allDay: true,
        },
    },
};

export const PlanningWeekViewFormat: ViewFormat = {
    label: 'planning.week',
    group: 'planning',
    id: 'planning-week',
    settings: {
        time: true,
        days: true,
    },
    view: {
        timeline: {
            type: 'week',
            startDay: 1,
            endDay: 5,
            timeLabelStep: 30,
            timeCellStep: 30,
            startTime: '05:00',
            endTime: '22:00',
            allDay: true,
        },
    },
};

export const PlanningMonthViewFormat: ViewFormat = {
    label: 'planning.maand',
    group: 'planning',
    id: 'planning-maand',
    smallDates: true,
    preferred: true,
    autoPlan: true,
    settings: {
        days: true,
    },
    view: {
        timeline: {
            type: 'month',
            startDay: 1,
            endDay: 5,
            size: 1,
            weekNumbers: true,
            eventList: true,
        },
    },
};

export const PlanningViews: ViewFormat[] = [
    PlanningDayViewFormat,
    PlanningWeekViewFormat,
    PlanningMonthViewFormat,
];
