import {IEventPopupEvenementFragment} from '@growthbase/planning';
import {useMemo} from 'react';

export const useEventTitle = (event: IEventPopupEvenementFragment | null): string =>
    useMemo(() => {
        if (!event) {
            return '';
        }

        if (event.onderwerp && event.onderwerp !== '') {
            return event.onderwerp;
        }

        if (event.ticket) {
            return `${event.ticket.code} - ${event.ticket.onderwerp}`;
        }

        if (event.opdracht) {
            return `${event.opdracht.nummer} - ${event.opdracht.onderwerp}`;
        }
        if (event.relatie) {
            return event.relatie.naam;
        }

        return '';
    }, [event]);
