import {ReactNode} from 'react';
import {idFromIri} from '@growthbase/routing';
import {LoaderModal} from '@growthbase/design-components';
import {Logger} from 'ts-log';
import {Fancybox} from './Fancybox';
import {Bestand, BestandLoadFunction} from './Component';

export class Slideshow {
    private currentBestandId: string | null = null;

    private loaded: Promise<Bestand[]> | null = null;

    private fancybox: Fancybox | null = null;

    constructor(
        public readonly logger: Logger,
        public readonly id: string,
        public readonly loadBestanden: BestandLoadFunction,
        public readonly setNodeToDocument: (preview: ReactNode) => void,
        public readonly onPageChange: (bestandId: string) => void,
        public readonly onClose: () => void
    ) {}

    public close() {
        this.currentBestandId = null;
        this.loaded = null;
        if (!this.fancybox) {
            return;
        }
        this.fancybox = null;
        this.onClose();
    }

    public async hasBestand(bestandId: string): Promise<boolean> {
        if (this.loaded) {
            return this.loaded.then((bestanden) => bestanden.some((bestand) => bestand.id === bestandId));
        }
        return Promise.resolve(false);
    }

    public open(): Promise<void> {
        if (this.fancybox || this.loaded) {
            return Promise.resolve();
        }

        return this.load().then((bestanden) => {
            if (bestanden.length === 0) {
                return;
            }
            this.fancybox?.view(bestanden[0].id);
        });
    }

    public view(bestandId: string): Promise<void> {
        if (this.currentBestandId === bestandId && this.loaded) {
            return this.loaded.then(() => undefined);
        }
        this.currentBestandId = bestandId;
        if (this.fancybox) {
            this.fancybox.view(this.currentBestandId);
            return Promise.resolve();
        }
        if (this.loaded) {
            return this.loaded.then(() => {
                if (this.currentBestandId !== bestandId) {
                    return;
                }
                this.fancybox?.view(bestandId);
            });
        }

        return this.load().then(() => {
            this.fancybox?.view(bestandId);
        });
    }

    private load(): Promise<Bestand[]> {
        this.setNodeToDocument(<LoaderModal />);
        this.loaded = this.loadBestanden()
            .then((result: Bestand[]) => {
                result = result.map((bestand) => ({
                    ...bestand,
                    id: idFromIri(bestand.id),
                }));
                this.fancybox = new Fancybox(this.logger, this.id, result, this.onPageChange, this.close.bind(this));
                this.setNodeToDocument(this.fancybox.create());
                return result;
            })
            .catch((error) => {
                this.logger.error(error);
                this.close();
                return [];
            });

        return this.loaded;
    }

    public get isOpen(): boolean {
        return !!this.fancybox;
    }
}
