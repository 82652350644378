import {QueryResult} from '@apollo/client/react/types/types';
import {
    determineActionFilterForTypes,
    determineActionTypeWhenSearching,
    useConvertFetchEntity,
    useUpdateQueryCacheFromExternalEntityChanges,
} from '@growthbase/cache';
import {
    ILijsttemplateTabelFragment,
    ILijsttemplateTabelQuery,
    ILijsttemplateTabelQueryVariables,
    refetchLijsttemplateTabelSingleQuery,
} from '@growthbase/graphql';
import {useCallback} from 'react';

export interface EntityChangesParams {
    searchQuery?: string | null;
    result: QueryResult<ILijsttemplateTabelQuery, ILijsttemplateTabelQueryVariables>;
}

export const useEntityChanges = ({result, searchQuery}: EntityChangesParams) => {
    useUpdateQueryCacheFromExternalEntityChanges<ILijsttemplateTabelFragment>({
        result,
        determineAction: useCallback(
            (params, defaultAction) => {
                const action = determineActionFilterForTypes(defaultAction, params, 'Lijsttemplate');
                return determineActionTypeWhenSearching(action, params, !!searchQuery);
            },
            [searchQuery]
        ),
        fetchEntity: useConvertFetchEntity(refetchLijsttemplateTabelSingleQuery),
    });
};
