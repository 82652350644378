import {AvatarSize, PopupControlsContext, useMemoryPopupControls} from '@growthbase/design-components';
import {
    IBordDeelnemersFieldFragment,
    IZichtbaarVoor,
    useBordDeelnemersZoekenQuery,
    useResolveConnections,
    useVerwijderBordDeelnemerMutation,
    useVoegBordDeelnemerToeMutation,
} from '@growthbase/graphql';
import {useDebouncedState, useFilterEmptyValues, useWrapFunctionWithErrorHandler} from '@growthbase/spa';
import {useCallback, useMemo, FC} from 'react';
import {Deelnemers} from '@growthbase/gebruikers';
import {useUpdateFromExternalEntityChanges} from './Hook/useUpdateFromExternalEntityChanges';

export interface DeelnemersFieldConnectedProps {
    readonly?: boolean;
    disabled?: boolean;
    stackSize?: number;
    size?: AvatarSize;
    fragment?: IBordDeelnemersFieldFragment;
    kanDeelnemersBewerken?: boolean;
}

export const DeelnemersFieldConnected: FC<DeelnemersFieldConnectedProps> = ({
    fragment,
    disabled,
    readonly,
    ...rest
}) => {
    const zichtbaarVoor = fragment?.zichtbaarVoor?.zichtbaarVoor;
    const voorMij = zichtbaarVoor === IZichtbaarVoor.Mijzelf;
    readonly = !zichtbaarVoor || voorMij || readonly || !fragment?.availableActions?.kanDeelnemersBewerken;
    const bordId = fragment?.id ?? '';
    const {
        value: searchQuery,
        setValue: setSearchQuery,
        debouncedValue: searchQueryDebounced,
    } = useDebouncedState<string | null>(null, 400);

    const controls = useMemoryPopupControls(false);
    const {data, loading} = useBordDeelnemersZoekenQuery({
        skip: !controls.isOpen || readonly,
        variables: {
            bordId,
            fulltextSearch: searchQueryDebounced,
        },
    });

    const voegToe = useWrapFunctionWithErrorHandler(useVoegBordDeelnemerToeMutation()[0], undefined);
    const verwijder = useWrapFunctionWithErrorHandler(useVerwijderBordDeelnemerMutation()[0], undefined);
    const deelnemers = useFilterEmptyValues(fragment?.deelnemers);
    useUpdateFromExternalEntityChanges(bordId);
    return (
        <PopupControlsContext.Provider value={controls}>
            <Deelnemers
                disabled={!fragment || disabled}
                readonly={readonly}
                {...rest}
                items={useMemo(
                    () =>
                        deelnemers.map(({gebruiker}) => ({
                            ...gebruiker,
                            selected: true,
                        })),
                    [deelnemers]
                )}
                popupItems={useResolveConnections(data?.Gebruikers)}
                onRemove={useCallback(
                    ({id: gebruikerId}) =>
                        verwijder({
                            variables: {
                                input: {
                                    gebruikerId,
                                    id: bordId,
                                },
                            },
                        }),
                    [
                        bordId,
                        verwijder,
                    ]
                )}
                onAdd={useCallback(
                    ({id: gebruikerId}) =>
                        voegToe({
                            variables: {
                                input: {
                                    gebruikerId,
                                    id: bordId,
                                },
                            },
                        }),
                    [
                        bordId,
                        voegToe,
                    ]
                )}
                onChangeQuery={(input) => {
                    setSearchQuery(input.query);
                }}
                searchQuery={searchQuery}
                loading={loading}
            />
        </PopupControlsContext.Provider>
    );
};
