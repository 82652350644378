import {useNow} from '@growthbase/spa';
import moment from 'moment';
import {useCallback, useEffect, useMemo, FC} from 'react';
import {isEqual} from 'lodash';
import {usePrevious} from 'react-use';
import {DatepickerField, MinimumDateOptions, useCreateStrictForm} from '../Form';
import {DateOptions, ISOStringDate, useDateSchema, useDefaultLabels} from './Hook';
import {ButtonGroup} from '../Buttons';
import styles from './DatePicker.module.scss';
import {Buttons} from '../Button';
import {normalizeDateValue} from './Normalize/normalizeDateValue';

export interface DatePickerContentProps {
    disabled?: boolean;
    labels?: Partial<DateOptions>;
    value: ISOStringDate;
    onSubmit: (value: ISOStringDate) => Promise<void>;
    minimumDate?: MinimumDateOptions;
}

export const DatePickerContent: FC<DatePickerContentProps> = ({labels, onSubmit, value, disabled, minimumDate}) => {
    const defaultLabels = useDefaultLabels(labels ?? {}, value);
    const now = useNow();
    const {
        Form,
        formProps,
        Fields: {DateField},
        formik,
    } = useCreateStrictForm<ISOStringDate>({
        onSubmit,
        disableReinitialize: true,
        schema: useDateSchema({...defaultLabels, minimumDate}),
        createInitialValues: useCallback(() => value, [value]),
    });

    const previous = usePrevious(formik.values);

    useEffect(() => {
        const updated = normalizeDateValue(
            formik.values,
            previous ?? {
                date: null,
            }
        );
        if (isEqual(updated, formik.values)) {
            return;
        }
        formik.setValues(updated);
    }, [formik, previous]);

    const initialValue: ISOStringDate = useMemo(
        () => ({
            date: {
                date: moment(value?.date?.date ?? now()).toISOString(),
                timeUsed: value?.date?.timeUsed ?? false,
            },
        }),
        [now, value]
    );

    return (
        <Form {...formProps}>
            <div className={styles.datePicker__content}>
                <DateField
                    disabled={disabled}
                    label={<>{defaultLabels.dateLabel}</>}
                    {...{
                        initialValue: initialValue?.date,
                    }}
                    compact
                    component={DatepickerField}
                />
            </div>
            <div className={styles.datePicker__footer}>
                <ButtonGroup justify>
                    <Buttons.Delete
                        disabled={formik.isSubmitting || disabled}
                        fat
                        onClick={useCallback(() => {
                            formik.setValues({
                                date: null,
                            });
                            formik.handleSubmit();
                        }, [formik])}
                    />
                    <Buttons.Save formik={formik} />
                </ButtonGroup>
            </div>
        </Form>
    );
};
