import React, {FC, PropsWithChildren} from 'react';
import {Buttons} from '@growthbase/design-components';
import {PopupControlsContext} from './PopupControls';
import {useMemoryPopupControls} from './Hook';

export type TestPopupProviderProps = PropsWithChildren;

export const TestPopupProvider: FC<TestPopupProviderProps> = ({children}) => {
    const helpers = useMemoryPopupControls(true);

    return (
        <PopupControlsContext.Provider value={helpers}>
            <Buttons.ArrowUp onClick={helpers.toggle}>Toggle</Buttons.ArrowUp>
            {children}
        </PopupControlsContext.Provider>
    );
};
