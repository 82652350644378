import {ValueType, ValueTypeInterface, defaultToNewValueType} from '../contract';

export const NUMBER: ValueTypeInterface<number> = {
    defaultValue: (): number => 0,
    type: ValueType.number,
    isEmpty: (): boolean => false,
    convertToNewValueType: defaultToNewValueType,
};

export const BOOLEAN: ValueTypeInterface<boolean> = {
    defaultValue: (): boolean => true,
    type: ValueType.boolean,
    isEmpty: (): boolean => false,
    convertToNewValueType: defaultToNewValueType,
};

export const TEXT: ValueTypeInterface<string> = {
    defaultValue: () => '',
    type: ValueType.string,
    isEmpty: (value): boolean => value.trim() === '',
    convertToNewValueType: defaultToNewValueType,
};

export const TEXTS: ValueTypeInterface<string[]> = {
    defaultValue: () => [''],
    type: ValueType.strings,
    isEmpty: (value): boolean => {
        for (const v of value) {
            if (v.trim() !== '') {
                return false;
            }
        }
        return true;
    },
    convertToNewValueType: defaultToNewValueType,
};
