import React, {FC, ReactNode, useEffect, useId} from 'react';
import {useUnmount} from 'react-use';
import {useHandleError} from '@growthbase/spa';
import {defaultViewComponent} from './defaultViewComponent';
import {InstellingFieldsInput, InstellingFieldType, InstellingValues, NestedValue} from '../../types';
import {InstellingViewProps, NestedInstellingView, ViewComponentResolver} from './Components';
import {useCreateSchema, useNormalizeFields} from '../../Hook';
import {useDestroy, useOptionalInstellingenForm, useUpdateExternals} from '../../redux';

export interface InstellingenViewProps<T extends object> {
    titel: string;
    values?: Partial<T> | null;
    fields: InstellingFieldsInput<T>;
    resolveViewComponent?: ViewComponentResolver | null;
    renderRootViewComponent?: (props: InstellingViewProps) => ReactNode;
    RootViewComponent?: FC<InstellingViewProps>;
}

export function InstellingenView<T extends object>({
    fields: fieldsInput,
    titel,
    values,
    resolveViewComponent: resolveComponentInput,
    renderRootViewComponent,
    RootViewComponent = NestedInstellingView,
}: InstellingenViewProps<T>) {
    const id = useId();
    const fields = useNormalizeFields(fieldsInput);
    const schema = useCreateSchema(fields);
    const state = useOptionalInstellingenForm(id);
    const handle = useHandleError();
    const update = useUpdateExternals();
    const resolveComponent = resolveComponentInput ?? defaultViewComponent;
    useEffect(() => {
        schema.validate(values).catch(handle);
        update({id, fields: fields.getFields(), values: values as InstellingValues});
    }, [
        id,
        fields,
        update,
        values,
        schema,
        handle,
    ]);

    const destroy = useDestroy();
    useUnmount(() => destroy(id));

    const rootValues: InstellingViewProps<NestedValue> = {
        value: values,
        fields: fields.rootFields().names(),
        resolveComponent,
        basePath: '',
        type: InstellingFieldType.nested,
        name: '',
        override: null,
        label: titel,
        id,
        input: undefined,
        parent: undefined,
    };

    if (!state) {
        return null;
    }
    return <>{renderRootViewComponent ? renderRootViewComponent(rootValues) : <RootViewComponent {...rootValues} />}</>;
}
