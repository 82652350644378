import {useBoolean} from 'react-use';
import {useCallback, useEffect, useMemo} from 'react';
import {PopupControls} from '../PopupControls';
import {useOnCloseSubject} from './useOnCloseSubject';
import {usePopupsContext} from '../PopupsContext/usePopupsContext';

export const useMemoryPopupControls = (
    openInitial = false,
    expandedInitial = false,
    preventClose = false,
    popupId: null | string = null
): PopupControls => {
    const {setPopupOpened} = usePopupsContext();

    const [isOpen, setIsOpen] = useBoolean(openInitial);
    const [isExpanded, setIsExpanded] = useBoolean(expandedInitial);
    const [onClose, isCanceled] = useOnCloseSubject();

    const open = useCallback(() => setIsOpen(true), [setIsOpen]);
    const forceClose = useCallback(() => setIsOpen(false), [setIsOpen]);

    const expand = useCallback(() => setIsExpanded(true), [setIsExpanded]);
    const collapse = useCallback(() => setIsExpanded(false), [setIsExpanded]);

    useEffect(() => {
        if (popupId) {
            setPopupOpened(popupId, isOpen);
        }
    }, [popupId, isOpen, setPopupOpened]);

    const close = useCallback(() => {
        if (preventClose) {
            return;
        }

        if (isCanceled()) {
            return;
        }
        setIsOpen(false);
    }, [isCanceled, preventClose, setIsOpen]);

    const set = useCallback(
        (value: boolean) => {
            if (value) {
                open();
                return;
            }
            close();
        },
        [close, open]
    );

    const toggle = useCallback(() => {
        set(!isOpen);
    }, [isOpen, set]);

    return useMemo(
        () => ({
            open,
            close,
            isOpen,
            onClose,
            forceClose,
            set,
            toggle,
            expand,
            collapse,
            isExpanded,
        }),
        [
            open,
            close,
            isOpen,
            onClose,
            forceClose,
            set,
            toggle,
            expand,
            collapse,
            isExpanded,
        ]
    );
};
