import {DNDState, DNDUserContainerId} from '../Value';

export const resetContainers = (
    state: DNDState,
    {excludeIds}: {excludeIds: (DNDUserContainerId | undefined | null)[]} = {excludeIds: []}
) => {
    Object.values(state.containers)
        .filter((container) => !excludeIds.includes(container.container.id))
        .forEach((container) => {
            container.draggingItems = null;
            container.draggingItemsDND = null;
        });
};
