import {FC, useMemo} from 'react';
import {StyledSelect} from '@growthbase/design-components';
import {useNavigate, useParams} from 'react-router';
import {useTranslation} from 'react-i18next';
import {useCallbackRef} from '@growthbase/spa';
import {idFromIri} from '@growthbase/routing';

export interface Bord {
    id: string;
    value: string;
    label: string;
}

export interface BordSelectProps {
    borden: Bord[];
    createUrl: string;
    templateUrl: string;
}

export const BordSelect: FC<BordSelectProps> = ({borden, createUrl, templateUrl}) => {
    const {t} = useTranslation('TakenlijstBord.nl');
    const navigate = useNavigate();
    const params = useParams();

    const value = useMemo(() => {
        if ('bordUuid' in params) {
            return borden.find((bord) => idFromIri(bord.id) === params.bordUuid)?.value ?? null;
        }
        return null;
    }, [borden, params]);

    const options = useMemo(
        () => [
            {
                value: createUrl,
                label: t('add'),
                link: true,
            },
            {
                value: templateUrl,
                label: t('addFromTemplate'),
                link: true,
            },
            ...borden,
        ],
        [borden, createUrl, t, templateUrl]
    );

    return (
        <StyledSelect<string | null>
            readonly={false}
            disabled={false}
            value={value}
            options={options}
            onChange={useCallbackRef((url): void => {
                if (!url) {
                    return;
                }
                navigate(url);
            })}
        />
    );
};
