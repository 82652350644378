import {useMemo} from 'react';
import {PlanningStoreState} from '@growthbase/planning';
import {useSelector} from 'react-redux';
import {CalendarSelectionItem} from '@growthbase/design-components';

export const useSelectedAgenda = (): CalendarSelectionItem | undefined => {
    const {agendas: agendaGroups} = useSelector((state: PlanningStoreState) => state.planning);
    const agendas = agendaGroups.flatMap((group) => group.items);
    return useMemo(() => agendas.find((agenda) => agenda.selected) || agendas[0], [agendas]);
};
