import {ViewConfig, Information, StyledLink, HoverIcon} from '@growthbase/design-components';
import {FC} from 'react';
import {TaaktemplateRoutes} from '@growthbase/takentemplates-taaktemplate';

export interface TitelProps extends ViewConfig {
    id: string;
    titel: string;
}

export const Titel: FC<TitelProps> = ({id, titel, readonly}) => {
    const to = TaaktemplateRoutes.bewerken.useUrl({
        taaktemplateId: id,
    });
    if (readonly) {
        return <Information>{titel}</Information>;
    }
    return (
        <StyledLink to={to}>
            <HoverIcon overflowEllipsis>{titel}</HoverIcon>
        </StyledLink>
    );
};
