import {compareKoppelbaarItem} from '@growthbase/taken';
import {compareIds} from '@growthbase/routing';
import {Taak, VerplaatsFormDTO} from './useVerplaatsSchema';
import {CurrentTaakValues, FetchOptions, Opties} from './useVerplaatsForm';

export interface VerplaatstFormState {
    instanceId: string;
    bordQuery: string | null;
    taak: Taak;
    loading: boolean;
    isValid: boolean;
    changed: boolean;
    error: unknown | false;
    // positie: number | null;
    opties: Opties | null;
    currentValues: CurrentTaakValues | null;
    formValues: VerplaatsFormDTO;
    formValuesOfOptionRequest: FetchOptions | null;
}

const valuesChanged = (state: VerplaatstFormState): boolean => {
    const {currentValues, formValues, opties} = state;
    if (currentValues === null) {
        return false;
    }
    const {lijst, bord} = currentValues;
    const previousBordId = bord?.id ?? null;
    const previousLijstId = lijst?.id ?? null;
    if (previousBordId !== formValues.bord || previousLijstId !== formValues.lijst) {
        return true;
    }
    if (previousLijstId === null || !opties) {
        return false;
    }
    const {taken} = opties;
    const previousTaakPositie = taken.findIndex((t) => t.id === state.taak.id);
    return previousTaakPositie !== formValues.positie;
};

export interface CurrentTaakValuesAction {
    type: 'currentTaakValues';
    values: CurrentTaakValues;
    taak: Taak;
}

export interface LoadOptiesAction {
    type: 'load';
    formValuesOfOptionRequest: VerplaatsFormDTO;
}

export interface OptiesResultsAction {
    type: 'results';
    opties: Opties;
    formValuesOfOptionRequest: VerplaatsFormDTO;
}

export interface LoadErrorAction {
    type: 'errored';
    error: unknown;
    formValuesOfOptionRequest: VerplaatsFormDTO;
}

export interface RetryErrorAction {
    type: 'retry';
}

export interface BordQuery {
    type: 'bordQuery';
    query: string | null;
}

export interface UpdateFormValuesAction {
    type: 'formValues';
    values: VerplaatsFormDTO;
}

export type VerplaatstFormAction =
    | CurrentTaakValuesAction
    | RetryErrorAction
    | LoadOptiesAction
    | OptiesResultsAction
    | LoadErrorAction
    | UpdateFormValuesAction
    | BordQuery;

export const verplaatsenFormReducer = (draft: VerplaatstFormState, action: VerplaatstFormAction): void => {
    switch (action.type) {
        case 'currentTaakValues': {
            draft.taak = action.taak;
            draft.loading = true;
            draft.currentValues = action.values;
            draft.formValuesOfOptionRequest = {
                ...draft.formValues,
                bordQuery: draft.bordQuery,
                onderdeelChanged: false,
            };
            if (draft.opties) {
                return;
            }
            const {lijst, bord} = action.values;
            draft.opties = {
                bord,
                lijst,
                taken: [],
                lijsten: lijst ? [lijst] : [],
                borden: bord ? [bord] : [],
                onderdeelVan: action.values.onderdeelVan,
                relatie: action.values.relatie,
            };
            break;
        }
        case 'bordQuery':
            draft.bordQuery = action.query;
            draft.formValuesOfOptionRequest = {
                ...draft.formValues,
                bordQuery: action.query,
                onderdeelChanged: false,
            };
            break;
        case 'load':
            draft.error = false;
            break;
        case 'results': {
            draft.formValuesOfOptionRequest = null;
            draft.loading = false;
            const taken = [
                ...action.opties.taken,
            ];

            if (!taken.some((t) => compareIds(t.id, draft.taak.id))) {
                taken.push({
                    ...draft.taak,
                    onderaan: true,
                });
            }

            draft.opties = {
                ...action.opties,
                taken,
            };
            if (!draft.formValues) {
                break;
            }
            const bordChanged = draft.formValues.bord !== action.opties.bord?.id;
            const lijstChanged = draft.formValues.lijst !== action.opties.lijst?.id;
            draft.formValues.bord = action.opties.bord?.id ?? null;
            draft.formValues.lijst = action.opties.lijst?.id ?? null;
            draft.formValues.onderdeelVan = action.opties.onderdeelVan;
            if (
                bordChanged ||
                lijstChanged ||
                draft.formValues.positie === null ||
                draft.formValues.positie > taken.length
            ) {
                draft.formValues.positie = taken.findIndex((t) => t.id === draft.taak.id);
            }
            if (draft.formValues.lijst == null) {
                draft.formValues.positie = null;
            }
            break;
        }
        case 'errored':
            draft.loading = false;
            draft.error = action.error;
            break;
        case 'retry':
            draft.loading = true;
            draft.error = false;
            break;
        case 'formValues': {
            const bordChanged = draft.formValues?.bord !== action.values.bord;
            const lijstChanged = draft.formValues?.lijst !== action.values.lijst;
            const onderdeelVanChanged = !compareKoppelbaarItem(
                draft.formValues?.onderdeelVan,
                action.values.onderdeelVan
            );
            if (bordChanged || lijstChanged || onderdeelVanChanged) {
                draft.loading = true;
                draft.formValuesOfOptionRequest = {
                    ...action.values,
                    bordQuery: draft.bordQuery,
                    onderdeelChanged: onderdeelVanChanged,
                };
            }
            draft.formValues = {
                ...action.values,
            };
            if (!draft.formValues.bord) {
                draft.formValues.lijst = null;
                draft.formValues.positie = null;
            }
            break;
        }
        default:
            break;
    }

    draft.isValid =
        draft.opties !== null &&
        ((draft.opties.bord !== null && draft.opties.lijst !== null) ||
            (draft.opties.bord === null && draft.opties.lijst === null));

    draft.changed = valuesChanged(draft);
};
