import {PropsWithChildren, useContext, useEffect, useMemo} from 'react';
import {InteractionContext, InteractionStackContext} from './InteractionStackContext';

export interface KeysProviderProps extends PropsWithChildren {
    name: string;
}

export const KeysProvider = ({children, name}: KeysProviderProps) => {
    const parent = useContext(InteractionStackContext);
    const stack = useMemo(
        (): InteractionContext => ({
            name,
            parent,
            children: [],
        }),
        [name, parent]
    );

    /**
     * Add and remove child to the parent contexts.
     */
    useEffect(() => {
        parent?.children.push(stack);
        return () => {
            parent?.children.splice(parent?.children.indexOf(stack), 1);
        };
    }, [parent, stack]);

    return <InteractionStackContext.Provider value={stack}>{children}</InteractionStackContext.Provider>;
};
