import {FC} from 'react';
import {Optional} from 'utility-types';
import {SwimlaneCreateFromStub} from './Components';
import {Footer, FooterProps} from './Footer';

export interface FooterStubProps extends Optional<FooterProps> {
    titel?: string;
}

export const FooterStub: FC<FooterStubProps> = (props) => (
    <Footer form={<SwimlaneCreateFromStub />} titel="Test" {...props} />
);
