import {Stack, Table} from '@growthbase/design-components';
import React, {FC} from 'react';
import {CellProps, Column} from 'react-table';
import {DeveloperRoutes} from '@growthbase/health';
import {
    IOrder,
    IProfielenPageFragment,
    IProfielenPageQuery,
    IProfielenPageQueryVariables,
    useProfielenPageQuery,
} from '@growthbase/graphql';
import {useQueryPagination} from '@growthbase/spa';
import {Link} from 'react-router-dom';

export type DTO = IProfielenPageFragment;

const IdCell: FC<CellProps<DTO>> = ({row}) => (
    <Stack horizontal>
        <Link to={DeveloperRoutes.replay_profile_detail.useUrl({id: row.original.id})}>{row.original.id}</Link>
    </Stack>
);

const NumberWhenNotZero = ({value}: {value?: number | null}) => <span>{value || ''}</span>;

const columns: ReadonlyArray<Column<DTO>> = [
    {
        Header: 'Id',
        accessor: 'id',
        minWidth: 150,
        Cell: IdCell,
    },
    {
        Header: 'Name',
        accessor: 'name',
        minWidth: 100,
    },
    {
        Header: 'Emergency',
        accessor: 'emergency',
        minWidth: 5,
        Cell: NumberWhenNotZero,
    },
    {
        Header: 'Errors',
        accessor: 'errors',
        minWidth: 5,
        Cell: NumberWhenNotZero,
    },
    {
        Header: 'Warnings',
        accessor: 'warnings',
        minWidth: 5,
        Cell: NumberWhenNotZero,
    },
];

export interface ProfielenTableProps {
    projectionId?: string;
}

export const ProfielenTable: FC<ProfielenTableProps> = ({projectionId}) => {
    const query = useProfielenPageQuery({
        pollInterval: 1000,
        variables: {
            projectionId,
            filters: {
                pagination: {
                    order: IOrder.Desc,
                    first: 50,
                },
            },
        },
    });
    const pagination = useQueryPagination<IProfielenPageQuery, IProfielenPageQueryVariables, IProfielenPageFragment>({
        query,
        connectionsMapper: ({projectionProfielen}) => projectionProfielen,
        fetchBefore: (variables) => ({
            filters: {
                pagination: {
                    after: variables.first,
                },
            },
        }),
        mergeBefore: (previousResult, fetchMoreResult) => ({
            projectionProfielen: [...fetchMoreResult.projectionProfielen, ...previousResult.projectionProfielen],
        }),
        fetchAfter: (variables) => ({
            filters: {
                pagination: {
                    before: variables.last,
                },
            },
        }),
        mergeAfter: (previousResult, fetchMoreResult) => ({
            projectionProfielen: [...previousResult.projectionProfielen, ...fetchMoreResult.projectionProfielen],
        }),
    });
    return <Table<DTO> columns={columns} pagination={pagination} />;
};
