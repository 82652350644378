import {createUseService} from '@growthbase/spa';
import {AnyObject} from 'yup/lib/object';
import {SchemaOf} from 'yup';
import * as yup from 'yup';
import {useMemo} from 'react';

export interface MenuItemDTO {
    id: string;
    items: MenuItemDTO[];
    routeName: string;
    route: string;
    label: string;
    options: AnyObject;
    pageTitel: string;
    representation: {
        rootMenuItem: boolean;
        subMenu: boolean;
        tab: boolean;
        ignoreInBreadcrumb: boolean;
    };
}

// @ts-expect-error TODO: fix this, it should be possible to use yup with recursive types
export const MenuItemDTOSchema: SchemaOf<MenuItemDTO> = yup
    .object({
        id: yup.string().defined().required(),
        // eslint-disable-next-line no-use-before-define
        items: yup.lazy(() => MenuItemsDTOSchema.defined()) as unknown as SchemaOf<MenuItemDTO[]>,
        routeName: yup.string().defined().required(),
        route: yup.string().defined().required(),
        label: yup.string().defined().required(),
        options: yup.object(),
        pageTitel: yup.string().defined().required(),
        representation: yup
            .object({
                rootMenuItem: yup.boolean().defined().required(),
                subMenu: yup.boolean().defined().required(),
                tab: yup.boolean().defined().required(),
                ignoreInBreadcrumb: yup.boolean().defined().required(),
            })
            .defined()
            .required(),
    })
    .label('MenuItemDTO')
    .required();

export const MenuItemsDTOSchema: SchemaOf<MenuItemDTO[]> = yup
    .array()
    .of(MenuItemDTOSchema)
    .label('MenuItemsDTO')
    .required();

export const useMenuItems = () =>
    createUseService(({config: {additionalProperties}}) =>
        MenuItemsDTOSchema.validateSync(additionalProperties.menu)
    )() as MenuItemDTO[];

export const useMenuItem = (id: string): MenuItemDTO => {
    const menuItems = useMenuItems();
    return useMemo(() => {
        const found = menuItems.find((menuItem) => menuItem.id === id);
        if (!found) {
            throw new Error(`Could not find menu item with id ${id}`);
        }
        return found;
    }, [menuItems, id]);
};
