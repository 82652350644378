import {Information, TableEditLink} from '@growthbase/design-components';
import {ITaakTabelFragment} from '@growthbase/graphql';
import {TakenRoutes} from '@growthbase/taken';
import React, {FC} from 'react';
import {CellProps} from 'react-table';

export const TitleCell: FC<CellProps<ITaakTabelFragment, string>> = ({value, row}) => {
    const to = TakenRoutes.edit.useUrl({id: row.original.id});
    const {
        original: {
            availableActions: {kanTitelBewerken},
        },
    } = row;
    if (row.original.isLocked) {
        return <Information>{value}</Information>;
    }
    return (
        // TODO: Don't check editable based on gearchiveerd.
        <TableEditLink completed={row.original.voltooid} editable={kanTitelBewerken} to={to}>
            {value}
        </TableEditLink>
    );
};
