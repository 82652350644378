import {createContext, ForwardedRef, forwardRef, RefObject, useContext, useRef} from 'react';
import {ActionsMenuButton} from './ActionsMenuButton';
import {ButtonProps} from '../../../Button';

const RefContext = createContext<RefObject<HTMLDivElement> | null>(null);

export const useLegacyActionsMenuRefButton = () => {
    const ref = useRef(null);
    return {
        ref,
        Context: RefContext.Provider,
    };
};
const PassWithRef = ({forwardRef: ref}: {forwardRef?: ForwardedRef<unknown> | null}) => {
    const refContext = useContext(RefContext);
    if (ref instanceof Function) {
        ref(refContext?.current ?? null);
    }
    if (ref && 'current' in ref) {
        ref.current = refContext?.current ?? null;
    }
    return null;
};

/**
 * @deprecated Currently menu items have no ref, so this is not needed.
 *
 * This hacks triggers errors in the console, because the ref is not forwarded to the MenuItem.
 *
 * We should remove this when we have a ref on the menu items.
 */
export const LegacyActionsMenuRefButton = forwardRef((props: ButtonProps, ref) => (
    <>
        <PassWithRef forwardRef={ref} />
        <ActionsMenuButton {...props} />
    </>
));
LegacyActionsMenuRefButton.displayName = 'LegacyActionsMenuRefButton';
