import {useEffect} from 'react';
import {ReactiesTwigConnectorInputDto} from '../ReactiesTwigInputSchema';
import {ReactieAction} from '../reducer/ReactiesReducer';

declare const SocketSubscription: {
    subscribe: (
        channel: string,
        event: string,
        callback: (data: {comment_id: number; reaction_count: number}) => void
    ) => {unsubscribe: () => void};
};

export const useSubscribeToSocketEvents = (
    enabled: boolean,
    data: ReactiesTwigConnectorInputDto,
    dispatch: (action: ReactieAction) => void
) => {
    const {ticketId, fetchUrl, commentId} = data;
    return useEffect(() => {
        const subscription = SocketSubscription.subscribe(
            `ticket-${ticketId}-comment-${commentId}`,
            'placed-reaction',
            (socketEventData: {comment_id: number; reaction_count: number}) => {
                if (commentId === socketEventData.comment_id) {
                    dispatch({type: 'setCount', count: socketEventData.reaction_count});
                }
            }
        );
        return () => {
            subscription.unsubscribe();
        };
    }, [ticketId, fetchUrl, commentId, enabled, dispatch]);
};
