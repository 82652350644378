import {useApolloClient} from '@apollo/client';
import {useSocketEvent} from '@growthbase/websockets';
import * as yup from 'yup';
import {InferType} from 'yup';
import {BaseNode} from '../baseNode';

export const PositieSchema = yup
    .object({
        listId: yup.string().nullable(),
        placedBeforeId: yup.string().nullable(),
        placedBeforeTypename: yup.string().nullable(),
        placedAfterId: yup.string().nullable(),
        placedAfterTypename: yup.string().nullable(),
    })
    .label('Positie')
    .required();

const EntityAddedSchema = yup
    .object({
        id: yup.string().required(),
        typename: yup.string().required(),
        data: yup.mixed().defined(),
        positie: PositieSchema.optional().nullable(),
    })
    .label('EntityAddedSchema')
    .required();

export type ListPositie = InferType<typeof PositieSchema>;

export const useExternalEntityAdded = <TNode extends BaseNode>(
    callback: (args: {id: string; typename: string; node: Partial<TNode>; fields: Array<keyof TNode>}) => void
) => {
    const apollo = useApolloClient();
    useSocketEvent('EntityAdded', (data) => {
        const normalized = EntityAddedSchema.validateSync(data);
        const node = {
            id: apollo.cache.identify({
                id: normalized.id,
                ...normalized.data,
                __typename: normalized.typename,
            }),
            __typename: normalized.typename,
            ...normalized.data,
        } as unknown as TNode;
        callback({
            ...normalized,
            node,
            fields: Object.keys(node) as unknown as (keyof TNode)[],
        });
    });
};
