import {ReactNode} from 'react';
import classNames from 'classnames/bind';
import {capitalize} from 'lodash';
import styles from './CapacitySquare.module.scss';

const cx = classNames.bind(styles);

export interface CapacitySquareProps {
    children: ReactNode;
    color: 'high' | 'medium' | 'low' | 'none';
    filled?: boolean;
    position?: 'top' | 'bottom' | 'both';
}

export const CapacitySquare = ({children, color = 'none', position = 'both', filled}: CapacitySquareProps) => {
    const classes = cx('capacitySquare', `capacitySquare--color${capitalize(color)}`, {
        'capacitySquare--filled': filled,
        [`capacitySquare--position${capitalize(position)}`]: position,
    });

    return (
        <div className={classes}>
            <span className={cx('capacitySquare__text')}>{children}</span>
        </div>
    );
};
