import {ApolloError} from '@apollo/client';
import {useIsDevelopment} from '@growthbase/spa';
import * as React from 'react';
import {FC} from 'react';
import {ObjectInspector} from 'react-inspector';
import * as yup from 'yup';

interface RenderApolloErrorProps {
    error?: ApolloError;
}

const SymfonyTraceSchema = yup
    .object({
        call: yup.string().required(),
        file: yup.string().required(),
        line: yup.number().required(),
    })
    .label('SymfonyTraceSchema')
    .required();

const SymfonyErrorSchema = yup
    .object({
        debugMessage: yup.string().required(),
        trace: yup.array().of(SymfonyTraceSchema).required(),
    })
    .label('SymfonyErrorSchema')
    .required();

export const RenderApolloError: FC<RenderApolloErrorProps> = ({error}) => {
    const isDebug = useIsDevelopment();
    if (error) {
        if (!isDebug) {
            return null;
        }
        const nodes = error.graphQLErrors.map((e, ei) => {
            try {
                const {trace} = SymfonyErrorSchema.validateSync(e);
                return trace.map(({call, line}) => `${call} (${line})`);
            } catch (normalizeError) {
                return [];
            }
        });
        return (
            <>
                <ObjectInspector data={error} />
                {nodes.map((node, index) => (
                    <ObjectInspector key={index} data={node} />
                ))}
            </>
        );
    }
    return null;
};
