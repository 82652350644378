import {ReactNode, FC} from 'react';
import {IBordtemplateActionMenuFragment} from '@growthbase/graphql';
import {ActionsMenuButton, ViewConfig} from '@growthbase/design-components';
import {ActionMenu} from './ActionMenu';
import {ViewButton, VerwijderButtonConnected} from './Components';
import {KopieerButtonConnected} from '../Kopieren';

export interface ActionMenuConnectedProps extends ViewConfig {
    hideBewerkButton?: boolean;
    hideViewButton?: boolean;
    fragment: IBordtemplateActionMenuFragment;
    children?: ReactNode;
}

export const ActionMenuConnected: FC<ActionMenuConnectedProps> = ({
    hideViewButton,
    hideBewerkButton,
    fragment,
    children,
    ...viewConfig
}) => (
    <ActionMenu
        hideBewerkButton={hideBewerkButton}
        bordId={fragment.id}
        renderVerwijderButton={
            fragment.availableActions.kanVerwijderen
                ? (props) => <VerwijderButtonConnected {...viewConfig} {...props} fragment={fragment} />
                : undefined
        }
        renderKopierenButton={
            fragment.availableActions.kanKopieren
                ? (props) => <KopieerButtonConnected {...viewConfig} {...props} fragment={fragment} />
                : undefined
        }
        {...viewConfig}
    >
        {children}
        {hideViewButton ?? <ViewButton bordtemplateId={fragment.id} Button={ActionsMenuButton} />}
    </ActionMenu>
);
