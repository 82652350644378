import {Font, Stack} from '@growthbase/design-components';
import {ITaakTabelFragment} from '@growthbase/graphql';
import React, {FC} from 'react';
import {CellProps} from 'react-table';
import {RelatieButtonContentInline} from '@growthbase/relaties';
import {TaakRelatieButtonConnected} from '../../Relaties/TaakRelatieButtonConnected';
import {TitleCell} from './TitleCell';
import {KoppelAanTaakButtonConnected} from '../../Koppelen/KoppelAanTaakButton/KoppelAanTaakButtonConnected';
import {SnoozeLabel} from '../../SnoozeLabel/SnoozeLabel';

export const DetailedTitleCell: FC<CellProps<ITaakTabelFragment, string>> = (props) => {
    const {
        row: {original},
    } = props;

    const {
        availableActions: {
            gekoppeldeRelatieBekijken,
            gekoppeldeAbonnementBekijken,
            gekoppeldeOpdrachtBekijken,
            gekoppeldeTicketBekijken,
        },
    } = original;

    const subContent = [];

    if (gekoppeldeRelatieBekijken && original.relatie?.relatieverwijzing) {
        subContent.push(
            <TaakRelatieButtonConnected
                key="relatie"
                fragment={original}
                ContentElement={RelatieButtonContentInline}
                singleValue
                readonly
            />
        );
    }

    const opdrachtBeschikbaar = gekoppeldeOpdrachtBekijken && original.opdracht;
    const ticketBeschikbaar = gekoppeldeTicketBekijken && original.ticket;
    const abonnementBeschikbaar = gekoppeldeAbonnementBekijken && original.abonnement;
    if (opdrachtBeschikbaar || ticketBeschikbaar || abonnementBeschikbaar) {
        subContent.push(
            <KoppelAanTaakButtonConnected key="gekoppelde-item" fragment={original} readonly textColor="gray" />
        );
    }

    if (subContent.length === 2) {
        subContent.splice(1, 0, <span key="spacer">,&nbsp;</span>);
    }

    return (
        <Stack inline>
            <Stack.Item>
                <TitleCell {...props} />
            </Stack.Item>
            {!!subContent.length && (
                <Stack.Item>
                    <Font.Small>{subContent}</Font.Small>
                </Stack.Item>
            )}
            {original.snooze?.tot && (
                <Stack.Item>
                    <Font.Info>
                        <SnoozeLabel snoozed={original} />
                    </Font.Info>
                </Stack.Item>
            )}
        </Stack>
    );
};
