import {
    BoxButton,
    BoxSelectInput,
    ButtonGroup,
    Buttons,
    Field,
    Font,
    ModalContent,
    ModalFooter,
    Padding,
    Popup,
    PopupAnchorProps,
    PopupCancelButton,
    PopupContent,
    PopupHeader,
    Stack,
    useIsMobile,
    ViewConfig,
    FormikMuiAutocomplete,
} from '@growthbase/design-components';
import React, {ReactNode, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {RelatieInputConnected} from '@growthbase/relaties';
import {useVerplaatsForm, UseVerplaatsFormOptions} from './Hook';
import {useBordOpties} from './Hook/useBordOpties';
import {useLijstOpties} from './Hook/useLijstOpties';
import {usePositieOpties} from './Hook/usePositieOpties';
import {KoppelButtonInputConnected, RenderKoppelButtonProps} from '../../../Koppelen';

export interface VerplaatsenFormProps<RT extends Element = Element> extends UseVerplaatsFormOptions, ViewConfig {
    OnderdeelVanComponent?: typeof KoppelButtonInputConnected;
    RelatieInput?: typeof RelatieInputConnected;
    renderAnchor: (props: PopupAnchorProps<RT>) => ReactNode;
    enableRelatie?: boolean;
}

export const VerplaatsenForm = ({
    disabled,
    readonly,
    RelatieInput = RelatieInputConnected,
    OnderdeelVanComponent = KoppelButtonInputConnected,
    renderAnchor,
    // Temporary fix for the fact that the relatie input is not yet implemented for searching the backend
    enableRelatie,
    ...rest
}: VerplaatsenFormProps) => {
    const {t} = useTranslation('Taken.nl');
    const {
        form: {formik, Form, Fields, formProps},
        isValid,
        loading,
        opties,
        bordQuery,
        changeBordQuery,
    } = useVerplaatsForm(rest);
    const {LijstField, PositieField, RelatieField, OnderdeelVanField, BordField} = Fields;

    const {
        values: {bord, lijst},
    } = formik;
    const Bord = BordField.useMuiComponent(FormikMuiAutocomplete);
    const Lijst = LijstField.useComponent(BoxSelectInput);
    const Positie = PositieField.useComponent(BoxSelectInput);
    const OnderdeelVan = OnderdeelVanField.useComponent(OnderdeelVanComponent);
    const Relatie = RelatieField.useComponent(RelatieInput);
    const isMobile = useIsMobile();

    const renderKoppelButton = useCallback(
        ({item, ...props}: RenderKoppelButtonProps) => (
            <BoxButton {...props} value={item?.label}>
                {t('TaakVerplaatsen.field.onderdeelVan')}
            </BoxButton>
        ),
        [t]
    );

    return (
        <Popup renderAnchor={renderAnchor} testId="VerplaatsFormModel" arrow>
            <Form {...formProps}>
                <PopupContent width={isMobile ? 'full' : 'large'}>
                    <PopupHeader title={t('TaakVerplaatsen.popup.titel')} />
                    <ModalContent theme="gray">
                        <Padding paddingSize={isMobile ? 'large' : 'none'}>
                            <Stack gap="small">
                                <Stack.Item>
                                    <Font.Bold>{t('TaakVerplaatsen.popup.tekst')}</Font.Bold>
                                </Stack.Item>
                                <Bord
                                    label={t('TaakVerplaatsen.field.bord')}
                                    labelHidden
                                    readonly={readonly}
                                    disabled={disabled || loading}
                                    options={useBordOpties(opties)}
                                    search={bordQuery ?? ''}
                                    onSearchChange={(change: string) => {
                                        change = change.trim();
                                        changeBordQuery(change.length ? change : null);
                                    }}
                                />
                                <Stack gap="small" horizontal>
                                    <Stack.Item grow size={1}>
                                        <Lijst
                                            labelHidden
                                            label={t('TaakVerplaatsen.field.lijst')}
                                            readonly={readonly}
                                            disabled={disabled || loading || bord === null}
                                            options={useLijstOpties(opties)}
                                        />
                                    </Stack.Item>
                                    <Stack.Item grow size={1}>
                                        <Positie
                                            label={t('TaakVerplaatsen.field.positie')}
                                            readonly={readonly}
                                            labelHidden
                                            disabled={disabled || loading || lijst === null}
                                            options={usePositieOpties(opties)}
                                        />
                                    </Stack.Item>
                                </Stack>
                                {enableRelatie && (
                                    <Field label={t('TaakVerplaatsen.field.relatie')} compact disableMargin>
                                        <Relatie readonly={readonly} disabled={disabled || loading} />
                                    </Field>
                                )}
                                <OnderdeelVan
                                    label={t('TaakVerplaatsen.field.onderdeelVan')}
                                    labelHidden
                                    readonly={readonly}
                                    disabled={disabled || loading}
                                    renderKoppelButton={renderKoppelButton}
                                />
                            </Stack>
                        </Padding>
                    </ModalContent>
                    <ModalFooter
                        buttons={
                            <ButtonGroup justify>
                                <PopupCancelButton disabled={formik.isSubmitting} />
                                {!readonly && (
                                    <Buttons.Save formik={formik} loading={loading} disabled={disabled || !isValid}>
                                        {!isValid || bord
                                            ? t('TaakVerplaatsen.popup.verplaats')
                                            : t('TaakVerplaatsen.popup.ontkoppel')}
                                    </Buttons.Save>
                                )}
                            </ButtonGroup>
                        }
                    />
                </PopupContent>
            </Form>
        </Popup>
    );
};
