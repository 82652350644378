import {MockedResponse} from '@apollo/client/testing/core/mocking/mockLink';
import {ILogbookItemFragment, ILogbookQuery, LogbookDocument} from '@growthbase/graphql';
import {faker} from '@faker-js/faker';

const createItem = (id: string): ILogbookItemFragment & {__typename: string} => ({
    __typename: 'Activiteit',
    id,
    description: {
        interpolated: `Logbook item ${id}`,
    },
    at: '2021-01-01T00:00:00.000Z',
    entities: [],
    gebruiker: {
        id: '1',
        naam: 'Test Gebruiker',
        imageUrl: faker.image.avatar(),
    },
});

const data: ILogbookQuery = {
    items: {
        pageInfo: {
            hasNextPage: false,
            endCursor: 'end',
            startCursor: 'start',
            hasPreviousPage: false,
        },
        totalCount: 3,
        edges: [
            {
                node: createItem('1'),
            },
            {
                node: createItem('2'),
            },
            {
                node: createItem('3'),
            },
        ],
    },
};

const LogbookConnectedMock: MockedResponse<ILogbookQuery> = {
    request: {
        query: LogbookDocument,
        variables: {
            first: 50,
        },
    },
    result: {
        data,
    },
};

export const LogbookConnectedMocks = [LogbookConnectedMock];
