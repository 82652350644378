import 'react-contexify/ReactContexify.css';
import {ItemParams} from 'react-contexify';
import {
    useMaakEvenementOpenbaarMutation,
    useMaakEvenementPriveMutation,
    usePlanningRefresh,
    useVeranderEvenementKleurMutation,
    useVerwijderEvenementMutation,
    useVerwijderUrenRegistratieLogMutation,
} from '@growthbase/planning';
import {Loader} from '@growthbase/design-components';
import {useBoolean} from 'react-use';
import {useAppendMessage} from '@growthbase/spa';
import {useTranslation} from 'react-i18next';
import {PlanningContextMenu} from './PlanningContextMenu';
import {getEventTitle} from '../../_hooks/getEventTitle';

export const PlanningContextMenuConnected = () => {
    const [veranderKleur] = useVeranderEvenementKleurMutation();
    const [verwijderEvenement] = useVerwijderEvenementMutation();
    const [verwijderUrenRegistratie] = useVerwijderUrenRegistratieLogMutation();
    const [maakPrive] = useMaakEvenementPriveMutation();
    const [maakOpenbaar] = useMaakEvenementOpenbaarMutation();
    const addMessage = useAppendMessage();
    const {t} = useTranslation('Planning.nl');
    const refetchPlanning = usePlanningRefresh();

    const handleColorClick = ({props, data}: ItemParams) => {
        if (props.event.id) {
            veranderKleur({
                variables: {
                    evenementId: props.event.id.replace('e-', ''),
                    kleur: data.color,
                },
            });
        }
    };

    const [loading, setLoading] = useBoolean(false);

    const handleClick = ({props, id}: ItemParams) => {
        const eventId = props.event.id.replace('e-', '');
        switch (id) {
            case 'edit':
                document.dispatchEvent(
                    new CustomEvent('planningEventOpenModal', {
                        detail: {
                            csrfToken: props.event.csrfToken,
                            resourceId: props.event.agendaId,
                            id: props.event.id,
                            timeRegistrationParentId: props.event.timeRegistrationParent
                                ? props.event.timeRegistrationParent.id
                                : null,
                        },
                    })
                );
                break;
            case 'delete':
                setLoading(true);

                if (props.event.timeRegistrationParent) {
                    addMessage([
                        {
                            id: 'delete-timeregistration',
                            displayType: 'user',
                            type: 'success',
                            message: 'planning.context.verwijderen.success',
                            promise: {
                                promise: verwijderUrenRegistratie({
                                    variables: {
                                        urenRegistratieLogId: eventId,
                                    },
                                    onCompleted: () => {
                                        setLoading(false);
                                        refetchPlanning();
                                    },
                                }),
                                pending:
                                    t('UrenRegistratieXWordtVerwijderd', {
                                        naam: getEventTitle(props.event),
                                    }) ?? '',
                                success:
                                    t('UrenRegistratieXIsVerwijderd', {
                                        naam: getEventTitle(props.event),
                                    }) ?? '',
                            },
                        },
                    ]);
                    return;
                }
                addMessage([
                    {
                        id: 'delete-event',
                        displayType: 'user',
                        type: 'success',
                        message: 'planning.context.verwijderen.success',
                        promise: {
                            promise: verwijderEvenement({
                                variables: {
                                    evenementId: eventId,
                                },
                                onCompleted: () => {
                                    setLoading(false);
                                },
                            }),
                            pending:
                                t('EvenementXWordtVerwijderd', {
                                    naam: getEventTitle(props.event),
                                }) ?? '',
                            success:
                                t('EvenementXIsVerwijderd', {
                                    naam: getEventTitle(props.event),
                                }) ?? '',
                        },
                    },
                ]);
                break;
            case 'private':
                setLoading(true);
                maakPrive({
                    variables: {
                        evenementId: eventId,
                    },
                    onCompleted: () => {
                        setLoading(false);
                    },
                });
                break;
            case 'public':
                setLoading(true);
                maakOpenbaar({
                    variables: {
                        evenementId: eventId,
                    },
                    onCompleted: () => {
                        setLoading(false);
                    },
                });
                break;
            default:
                break;
        }
    };

    return (
        <>
            {loading && <Loader fixed />}
            <PlanningContextMenu handleColorClick={handleColorClick} handleClick={handleClick} />
        </>
    );
};
