import React from 'react';
import {Stack} from '@growthbase/design-components';
import {InstellingViewProps, InstellingViewWrapper} from './InstellingViewWrapper';
import {NestedValue} from '../../../types';

/**
 * Please don't do any layout here in this component, just render the children.
 *
 * @param props
 * @constructor
 */
export const NestedInstellingView = (props: InstellingViewProps) => {
    const {fields, resolveComponent, id} = props as InstellingViewProps<NestedValue>;
    return (
        <Stack gap="medium">
            {fields.map((name) => (
                <InstellingViewWrapper resolveComponent={resolveComponent} id={id} key={name} name={name} />
            ))}
        </Stack>
    );
};
