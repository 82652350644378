import {FC} from 'react';
import {createPortal} from 'react-dom';
import {useTranslation} from 'react-i18next';
import {useLogger, usePortalDestination} from '@growthbase/spa';
import {DropdownMenuButton} from '@growthbase/design-components';
import {TicketRoutes} from '@growthbase/tickets-tickets';

export const TicketCommentFormAddTaakButtonConnected: FC = () => {
    const logger = useLogger('TicketCommentFormAddTaakButtonConnected');
    const {t} = useTranslation('spa_form.nl');
    const destination = usePortalDestination('taken-ticket-voeg-taak-toe-knop-container');

    const createTaak = TicketRoutes.taak_aanmaken.useNavigateFactory();

    if (!destination) {
        return null;
    }

    const {ticketId, ticketNummer: ticketnummer, ticketUuid} = destination?.dataset ?? {};
    if (ticketId === undefined || ticketnummer === undefined || ticketUuid === undefined) {
        logger.error('Een of meerdere van de vereiste data-attributen ontbreken.', {
            data: destination.dataset,
        });
        return null;
    }

    const button = (
        <DropdownMenuButton
            onClick={() =>
                createTaak({
                    ticketId,
                    ticketnummer,
                    ticketUuid,
                })
            }
        >
            {t('ticket.TicketCommentFormAddTaakButton.add')}
        </DropdownMenuButton>
    );

    return createPortal(button, destination);
};
