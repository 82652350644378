import {ReactNode} from 'react';
import {
    Buttons,
    IconSize,
    MemoryPopupProvider,
    Padding,
    Popup,
    PopupAnchorProps,
    PopupContent,
} from '@growthbase/design-components';

export interface ViewSettingsPopupProps {
    children: ReactNode;
}

export const ViewSettingsPopup = ({children}: ViewSettingsPopupProps) => {
    const anchor = ({reference, controls: {open}}: PopupAnchorProps) => (
        <Buttons.Gear iconSize={IconSize.SIZE_3} onClick={open} ref={reference} />
    );
    return (
        <MemoryPopupProvider>
            <Popup renderAnchor={anchor} arrow>
                <PopupContent>
                    <Padding>{children}</Padding>
                </PopupContent>
            </Popup>
        </MemoryPopupProvider>
    );
};
