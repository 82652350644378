import {useMemo} from 'react';
import {SocketStatus, useSocketStatus} from '../Redux';

/**
 * Use polling if 1 pusher channel has not connected properly.
 *
 * - Default polling time is 5 seconds.
 * - Always defaults to 5 minute fetching.
 */
export const usePollInterval = (pollingTime: number = 5 * 1000): number | undefined => {
    const status = useSocketStatus();
    return useMemo(() => {
        switch (status) {
            case SocketStatus.disconnected:
                return pollingTime;
            case SocketStatus.connected:
            case SocketStatus.connecting:
            default:
                break;
        }
        return 5 * 60 * 1000;
    }, [status, pollingTime]);
};
