import {opgemaakteTekstFindElementsByType, useRemoveNodesByType} from '@growthbase/design-components';
import {ComboboxOnderdeelVanItem, KoppelbaarItem, KoppelbaarItemType, OnderdeelVanMentionKey} from '@growthbase/taken';
import {FormikContextType} from 'formik';
import {useEffect} from 'react';

import {EditFormDTO} from './useEditSchema';

export interface StringOnderdeelVanNaamProps {
    formik: FormikContextType<EditFormDTO>;
}

export const useStripOnderdeelVanNaam = ({formik}: StringOnderdeelVanNaamProps) => {
    const removeNodesByType = useRemoveNodesByType();
    const {
        setFieldValue,
        values: {naam},
    } = formik;

    useEffect(() => {
        const all = opgemaakteTekstFindElementsByType(OnderdeelVanMentionKey, naam);

        if (all.length === 0) {
            return;
        }
        for (const item of all) {
            if (!item) {
                // eslint-disable-next-line no-continue
                continue;
            }
            const {item: koppelItem} = item as unknown as {item: ComboboxOnderdeelVanItem};

            const type: KoppelbaarItemType = KoppelbaarItemType[koppelItem.type];
            const kopelbaar: KoppelbaarItem = {
                id: koppelItem.id,
                type,
                label: koppelItem.label,
                href: koppelItem.href,
            };
            setFieldValue('relatie', koppelItem.relatie);
            setFieldValue('onderdeelVan', kopelbaar);
        }
        removeNodesByType(OnderdeelVanMentionKey);
    }, [removeNodesByType, setFieldValue, naam]);
};
