import React, {LegacyRef} from 'react';
import {BaseButtonProps, Buttons, Tooltip, usePopup} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';
import {KoppelbaarItem} from '@growthbase/taken';
import {GekoppeldLink} from '../GekoppeldLink/GekoppeldLink';

export interface KoppelButtonContentProps extends BaseButtonProps {
    disabled?: boolean;
    readonly?: boolean;
    onEdit?: () => void;
    onRemove?: () => void;
    item: KoppelbaarItem | null;
    textSize?: 'small' | 'default';
    textColor?: 'default' | 'gray';
    size?: 'small' | 'default';
}

export const KoppelButtonContent = ({
    ref,
    disabled,
    readonly,
    onEdit = () => null,
    onRemove = () => null,
    item,
    textSize,
    textColor,
    reference,
    size,
}: KoppelButtonContentProps) => {
    const {t} = useTranslation('spa_form.nl');
    const {isOpen} = usePopup();

    if (item) {
        return (
            <span ref={reference as LegacyRef<HTMLSpanElement>}>
                <GekoppeldLink
                    testId="KoppelButton"
                    item={item}
                    onEdit={onEdit}
                    disabled={disabled}
                    readonly={readonly}
                    onRemove={onRemove}
                    textSize={textSize}
                    textColor={textColor}
                    ref={ref}
                />
            </span>
        );
    }

    return (
        <Tooltip content={t('item.koppel')}>
            <span ref={reference as LegacyRef<HTMLSpanElement>} style={{display: 'inline-block'}}>
                <Buttons.BrokenLinkUnlinkedAttachmentIconDotted
                    size={size === 'default' || !size ? 'medium' : size}
                    onClick={onEdit}
                    disabled={disabled}
                    ariaLabel={t('item.koppel')}
                    ref={ref}
                    active={isOpen}
                />
            </span>
        </Tooltip>
    );
};
