import {GraphQLServices} from '@growthbase/graphql';
import {ReduxServices, ReduxUserDependencies} from '@growthbase/spa';
import Pusher from 'pusher-js/with-encryption';
import {ListenerRegistry} from './ListenerRegistry';
import {SocketsClient} from './hook/SocketsClient';
import {SocketService} from './SocketService';
import {SocketSlice} from './Redux';

export interface WebSocketsDependencies extends GraphQLServices, ReduxServices {
    config: {
        environment: string;
    };
}

export interface WebsocketsServices {
    listenerRegistry: ListenerRegistry;
    socketsClient: SocketsClient;
    socketService: SocketService;
}

export const createWebsocketsServices = ({
    graphqlClient,
    store,
    config: {environment},
}: WebSocketsDependencies): WebsocketsServices => {
    Pusher.logToConsole = !environment.includes('prod');
    const listenerRegistry = new ListenerRegistry();
    const socketsClient = new SocketsClient(graphqlClient);
    const socketService = new SocketService(socketsClient, listenerRegistry, store);
    return {
        listenerRegistry,
        socketsClient,
        socketService,
    };
};

export const createWebsocketsReduxServices = ({reducers = {}}: ReduxUserDependencies): ReduxUserDependencies => ({
    reducers: {
        sockets: SocketSlice.reducer,
        ...reducers,
    },
});
