import {ITaaktitelFieldFragment, useTaaktitelFieldMutation} from '@growthbase/graphql';
import {FC} from 'react';
import {useCreateMutationHandler} from '@growthbase/design-components';
import {TaaktitelField} from './index';

export interface TaaktitelFieldConnectedProps {
    fragment?: ITaaktitelFieldFragment;
}

export const TaaktitelFieldConnected: FC<TaaktitelFieldConnectedProps> = ({fragment}) => (
    <TaaktitelField onSubmit={useCreateMutationHandler(useTaaktitelFieldMutation()[0])} fragment={fragment} />
);
