import classNames from 'classnames/bind';
import {forwardRef, ReactElement, Ref} from 'react';
import styles from './ActionsMenuTrigger.module.scss';
import {BaseButtonProps, IconProps, IconSize, Tooltip, Icons} from '../../..';

export interface ActionsMenuTriggerProps extends BaseButtonProps {
    className?: string;
    icon?: ReactElement<IconProps>;
    onClick?: () => void;
    active?: boolean;
}

const cx = classNames.bind(styles);

const defaultButton = () => <Icons.Dots size={IconSize.SIZE_3} />;

export const ActionsMenuTrigger = forwardRef(
    (
        {
            testId,
            ariaLabel,
            className,
            children,
            active,
            Icon = defaultButton,
            onMouseDown,
            onClick,
            showOnRowHover,
            tooltip,
            tabIndex,
            ...rest
        }: ActionsMenuTriggerProps,
        ref
    ) => {
        const classes = cx(className, 'actionsMenuTrigger', {'actionsMenuTrigger--active': active});

        const Button = (
            <button
                tabIndex={tabIndex}
                data-testid={testId}
                ref={tooltip ? undefined : (ref as Ref<HTMLButtonElement>)}
                type="button"
                aria-label={ariaLabel ?? undefined}
                onClick={onClick}
                className={`${classes} actionMenuTrigger`}
                onMouseDown={onMouseDown}
            >
                <Icon />
            </button>
        );

        if (tooltip) {
            return (
                <Tooltip content={tooltip}>
                    <span ref={ref as Ref<HTMLElement>}>{Button}</span>
                </Tooltip>
            );
        }

        return Button;
    }
);

ActionsMenuTrigger.displayName = 'ActionMenuTrigger';
