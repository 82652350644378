import {RefObject, useEffect} from 'react';
import {useCallbackRef, useLogger} from '@growthbase/spa';
import {noop} from 'lodash';
import {useInteractionAllowed} from './useInteractionAllowed';

/**
 * Is aware of popup stacks and application routing (background/foreground).
 */
export const useClickOutside = (enabled: boolean, refs: RefObject<Element>[], callback: () => void) => {
    const allowed = useInteractionAllowed();
    const callbackRef = useCallbackRef(callback);
    const logger = useLogger('useClickOutside');

    useEffect(() => {
        if (!enabled) {
            return noop;
        }
        function handleClickOutside(event: MouseEvent) {
            if (!allowed()) {
                return;
            }

            const targetElement = event.target;

            if (targetElement instanceof HTMLElement && targetElement.closest('.MuiAutocomplete-listbox')) {
                return;
            }

            if (targetElement instanceof Node && !document.body.contains(targetElement)) {
                logger.info('Prevented click outside because element is not mounted', targetElement);
                return;
            }

            let fireCallback = true;
            refs.forEach((ref) => {
                if (ref && ref.current && targetElement instanceof Node && ref.current.contains(targetElement)) {
                    fireCallback = false;
                }
            });

            if (fireCallback) {
                callbackRef();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [enabled, refs, callbackRef, allowed, logger]);
};
