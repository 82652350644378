import * as yup from 'yup';
import {SchemaOf} from 'yup';
import {nullableString} from '@growthbase/spa';
import {MenuChild, MenuChildSchema} from './MenuChild';
import {Route, RouteSchema} from './Route';

export interface MenuItem {
    label: string;
    path: string | null;
    active: boolean;
    route: Route | null;
    children: MenuChild[];
    id?: string;
}

export const MenuItemSchema = yup
    .object({
        label: yup.string().required(),
        active: yup.boolean().required(),
        path: nullableString(),
        route: RouteSchema.nullable(),
        children: yup.array(MenuChildSchema.required()).required(),
        id: yup.string().nullable(),
    })
    .label('menuItem')
    .required() as SchemaOf<MenuItem>;
