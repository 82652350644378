import React, {useCallback} from 'react';
import {Icons} from '../../icons';
import {useSidenavContext} from '../Sidenav/useSidenavContext';
import styles from './SidenavToggle.module.scss';

export const SidenavToggle = () => {
    const {toggleExpanded, isExpanded} = useSidenavContext();

    return (
        <button
            className={styles.sidenavToggle}
            onClick={useCallback(() => toggleExpanded(), [toggleExpanded])}
            type="button"
        >
            {isExpanded ? <Icons.ChevronLeftCentered /> : <Icons.ChevronRightCentered />}
        </button>
    );
};
