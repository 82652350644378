import {ReactNode} from 'react';
import styles from './FieldWrapperButtons.module.scss';

export interface FieldWrapperButtonsProps {
    children: ReactNode;
}

export const FieldWrapperButtons = ({children}: FieldWrapperButtonsProps) => (
    <div className={styles.fieldWrapperButtons}>{children}</div>
);
