import {ApplicationServices, useService} from '@growthbase/spa';
import {PlatePlugin} from '@udecode/plate';
import {ReactNode, useCallback} from 'react';
import {OpgemaakteTekstElement} from '../Hook/useOpgemaakteTekstSchema';

export type ChildrenRenderer = () => ReactNode;
export type ReadOnlyRenderer = (element: OpgemaakteTekstElement, children: ChildrenRenderer) => ReactNode;

export interface PlateReadonlyRenderer {
    render: ReadOnlyRenderer;
}

export interface OpgemaakteTextPluginsServices {
    platePlugins?: PlatePlugin[];
    plateSingleLinePlugins?: PlatePlugin[];
    readOnlyComponents?: PlateReadonlyRenderer[];
}

export const useOpgemaakteTextPlugins = (): PlatePlugin[] => {
    const {platePlugins = []} = useService<ApplicationServices & OpgemaakteTextPluginsServices>();
    return platePlugins;
};

export const useReadOnlyComponents = (): ReadOnlyRenderer => {
    const {readOnlyComponents = []} = useService<ApplicationServices & OpgemaakteTextPluginsServices>();
    return useCallback(
        (element, children): ReactNode => {
            if (!readOnlyComponents) {
                return null;
            }
            for (const component of readOnlyComponents) {
                const result = component.render(element, children);
                if (result) {
                    return result;
                }
            }
            return null;
        },
        [readOnlyComponents]
    );
};

export const useOpgemaakteTextSingleLinePlugins = (): PlatePlugin[] => {
    const {plateSingleLinePlugins = []} = useService<ApplicationServices & OpgemaakteTextPluginsServices>();
    return plateSingleLinePlugins;
};
