import {useEffect} from 'react';
import Pusher from 'pusher-js/with-encryption';
import {useSocketChangesQuery} from '@growthbase/planning';
import {noop} from 'rxjs';

export const useSocketChanges = () => {
    const {data} = useSocketChangesQuery();

    useEffect(() => {
        const appCluster = data?.Pusher?.appCluster;
        if (!appCluster) {
            return noop();
        }

        const appKey = data?.Pusher?.appKey;
        if (!appKey) {
            return noop();
        }

        const channelName = data?.Pusher?.calendarEventUpdatedChannel;
        if (!channelName) {
            return noop();
        }

        const pusher = new Pusher(appKey, {
            cluster: appCluster,
            forceTLS: true,
        });

        const channel = pusher.subscribe(channelName);

        channel.bind('event-updated', () => {
            document.dispatchEvent(new Event('refetchPlanning'));
        });

        return () => {
            channel.unbind('event-updated');
            channel.unsubscribe();
        };
    }, [data]);
};
