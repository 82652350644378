import {useCallback, useEffect, useState} from 'react';

export const useNow = () => useCallback(() => new Date(), []);
export const useNowInterval = () => {
    const now = useNow();
    const [state, setState] = useState<Date | null>(null);
    useEffect(() => {
        const interval = setInterval(() => setState(now()), 1000);
        return () => clearInterval(interval);
    }, [now, setState]);

    return state ?? now();
};
