import {
    AgendaTabConnected,
    DienstenTab,
    PlanningStoreState,
    useTicketPlanningSidebarConnectedQuery,
} from '@growthbase/planning';
import {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {TicketSidebar} from './TicketSidebar';
import {useTicketEventState} from './useTicketEventState';
import {Ticket} from '../PlanningSidebar/Ticket';
import {Dienst} from '../PlanningSidebar/Dienst';
import {useCurrentView} from '../../_hooks/useCurrentView';

export interface TicketSidebarConnectedProps {
    ticketId: string;
}

export const TicketSidebarConnected = ({ticketId}: TicketSidebarConnectedProps) => {
    const {data, refetch} = useTicketPlanningSidebarConnectedQuery({
        variables: {
            id: ticketId,
        },
    });

    useEffect(() => {
        const listener = () => {
            refetch();
        };

        document.addEventListener('refetchPlanning', listener);
        return () => {
            document.removeEventListener('refetchPlanning', listener);
        };
    }, [refetch]);

    const ticket: Ticket = useMemo(
        () => ({
            id: data?.Ticket?.id || '',
            relatie: data?.Ticket?.relatie?.naam || '',
            diensten: [],
            code: data?.Ticket?.code || '',
            onderwerp: data?.Ticket?.onderwerp || '',
        }),
        [data?.Ticket?.id, data?.Ticket?.code, data?.Ticket?.onderwerp, data?.Ticket?.relatie?.naam]
    );

    const {handleKeuzeChange} = useTicketEventState(ticket);

    const diensten =
        data?.Ticket?.diensten?.map(
            (dienst): Dienst => ({
                id: dienst?.id ?? '',
                titel: dienst?.titel ?? '',
            })
        ) ?? [];

    const currentView = useCurrentView();
    const {opdrachtenSidebarEnabled, dienst} = useSelector((state: PlanningStoreState) => state.planning);
    const toonOpdrachtenSidebar = currentView.id !== 'capaciteit' && opdrachtenSidebarEnabled;

    return (
        <TicketSidebar
            dienstenTab={
                toonOpdrachtenSidebar && (
                    <DienstenTab diensten={diensten} keuzeAangepast={handleKeuzeChange} activeDienstId={dienst?.id} />
                )
            }
            agendaTab={<AgendaTabConnected />}
        />
    );
};
