import {isEqual} from 'lodash';
import {Editor, Node, Transforms} from 'slate';
import {ReactEditor} from 'slate-react';
import {normalizeValues} from '../Hook/useNormalizeValues';
import {OpgemaakteTekstElement} from '../Hook/useOpgemaakteTekstSchema';

export const resetNodes = (
    editor: Editor,
    options: {
        nodes?: Node[];
        at?: Location;
    } = {}
) => {
    const newNodes = normalizeValues(options.nodes as OpgemaakteTekstElement[]) as Node[];

    if (isEqual(editor.children, newNodes)) {
        return;
    }

    const focus = ReactEditor.isFocused(editor);

    Transforms.deselect(editor);
    ReactEditor.blur(editor);

    const children = [...editor.children];
    children.forEach((node) => editor.apply({type: 'remove_node', path: [0], node}));

    options.nodes = newNodes;

    options.nodes.forEach((node, i) => editor.apply({type: 'insert_node', path: [i], node}));

    // Focus and select the first node,
    if (focus) {
        ReactEditor.focus(editor);
        Transforms.select(editor, [0]);
    }
};
