import {
    NodeWrapper,
    SearchForm,
    TableFiltersHeader,
    useGebruikerHeeftToegang,
    useSearchField,
} from '@growthbase/design-components';
import {IMijnTakenOverzichtRowFragment, useMijnTakenOverzichtQuery} from '@growthbase/graphql';
import {usePaginationSearchParams} from '@growthbase/routing';
import {MijnTakenFilter, TaakDefaultPrivateProvider} from '@growthbase/taken';
import {usePollInterval} from '@growthbase/websockets';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {capitalize} from 'lodash';
import {TaakAddAction, TaakTabel} from '../TaakTabel';
import {useChangeOrder} from './Hook/useChangeOrder';
import {useAddTaakHandler} from './Hook/useAddTaakHandler';
import {useMijnTaakTabelEntityChanges} from './Hook/useMijnTaakTabelEntityChanges';
import {useMijnTakenOverzichten} from '../TakenSideLinks/hook/useMijnTakenOverzichten';

const userData = {
    id: 'mijn-taken',
};

export interface MijnTakenOverzichtConnectedProps {
    bodyWrapper?: NodeWrapper;
    disableFilters?: boolean;
}

export const MijnTakenOverzichtConnected: FC<MijnTakenOverzichtConnectedProps> = ({bodyWrapper, disableFilters}) => {
    const {t} = useTranslation('Taken.nl');
    const {pagination, usePagination} = usePaginationSearchParams<IMijnTakenOverzichtRowFragment>('taak');
    const [searchQuery, setSearchQuery] = useSearchField('taak');

    const {result: takenSideLinksResult, view: value} = useMijnTakenOverzichten();

    const actieveMijnTakenTellerLoaded =
        takenSideLinksResult.data?.ingelogdeGebruiker?.gebruikerWeergaveInstellingen !== undefined;

    const mijnDag = value === MijnTakenFilter.mijnDag;
    const mijnPriveTaken = value === MijnTakenFilter.prive;
    const snoozed = value === MijnTakenFilter.snoozed;
    const result = useMijnTakenOverzichtQuery({
        pollInterval: usePollInterval(),
        variables: {
            ...pagination,
            fulltextSearch: searchQuery?.query,
            mijnDag: mijnDag ? true : undefined,
            mijnPriveTaken: mijnPriveTaken ? true : undefined,
            beperkTotMijnTaken: mijnPriveTaken ? undefined : true,
            snoozed,
        },
        skip: !actieveMijnTakenTellerLoaded,
    });

    useMijnTaakTabelEntityChanges({
        mijnDag,
        result,
        searchQuery: searchQuery.query,
    });
    const toegang = useGebruikerHeeftToegang({
        toegang: result?.data?.ingelogdeGebruiker?.availableActions?.kanTakenBekijken,
    });

    const enableAddTaken = !!result.data?.ingelogdeGebruiker?.availableActions?.kanTaakAanmaken && !snoozed;
    const onSubmit = useAddTaakHandler(result, mijnDag);
    const changeOrderProps = useChangeOrder(result);
    const pager = usePagination(result);

    const today = capitalize(moment().format('dddd D MMMM'));

    const label = () => {
        if (mijnPriveTaken) {
            return t('prive.mijnPriveTaken');
        }
        if (mijnDag) {
            return t('mijnTaken.mijnDag');
        }
        return t('mijnTaken.mijnTaken');
    };

    if (!toegang || !actieveMijnTakenTellerLoaded) {
        return null;
    }
    return (
        <TaakDefaultPrivateProvider defaultPrivate={mijnPriveTaken}>
            <TaakTabel
                enableAdd={enableAddTaken}
                title={label()}
                subtitle={mijnDag ? today : undefined}
                bodyWrapper={bodyWrapper}
                filters={
                    !disableFilters && (
                        <TableFiltersHeader
                            leftActions={[
                                <SearchForm
                                    key="search"
                                    disableAutofocus
                                    loading={result.loading}
                                    onChange={setSearchQuery}
                                    value={searchQuery}
                                />,
                            ]}
                            rightActions={[
                                enableAddTaken && (
                                    <TaakAddAction
                                        key="add"
                                        overrideValues={{
                                            ikNeemDeel: true,
                                            ...(mijnDag ? {voorVandaag: true} : {}),
                                            ...(mijnPriveTaken ? {isPrive: true} : {}),
                                        }}
                                    />
                                ),
                            ]}
                        />
                    )
                }
                showTaskDetails
                error={result.error}
                onSubmit={onSubmit}
                pagination={pager}
                dragProps={{
                    userData,
                    ...changeOrderProps,
                }}
            />
        </TaakDefaultPrivateProvider>
    );
};
