import {createURL, useCurrentHref} from '@growthbase/routing';
import {useMemo} from 'react';
import {matchRoutes} from 'react-router-dom';
import {isTabQuery, isTabRoute, TabInterface} from '../TabInterface';
import {useTabQueryValue} from './useTabQueryValue';

/* eslint-disable  */

export const useIsTabActive = (tab: Omit<TabInterface, 'title' | 'children'>) => {
    if (isTabRoute(tab)) {
        const location = useCurrentHref();
        return useMemo(() => {
            const all = tab.subRoutes ? [tab.to, ...tab.subRoutes] : [tab.to];
            for (const path of all) {
                const paths = [
                    {
                        path: createURL(path).pathname,
                    },
                ];
                const url = createURL(location);
                const matches = matchRoutes(paths, url.pathname);
                if (matches && matches.length > 0) {
                    return true;
                }
            }
            return false;
        }, [location, tab]);
    }
    if (isTabQuery(tab)) {
        const [result] = useTabQueryValue();
        return result === tab.value;
    }
    return false;
};
