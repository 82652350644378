import {HoverIcon, useAddReferenceLinkResolver} from '@growthbase/design-components';
import {
    assertIdFromIri,
    CreateRouteFunction,
    createRoutes,
    createRouteWithId,
    iriFromId,
    PageFromMenu,
    RouteElementProps,
} from '@growthbase/routing';
import React, {useCallback} from 'react';
import {generatePath, Link} from 'react-router-dom';
import {TicketCommentFormAddTaakButtonConnected} from '@growthbase/tickets-taken';
import {EditModalPageConnected} from '@growthbase/takenlijst-lijst';
import {LogbookConnected} from '@growthbase/activiteiten';
import {useTranslation} from 'react-i18next';
import {TaakEditRoute} from './TaakEditRoute';
import {TakenCounterSideNavBadgeConnected} from '../TakenCounterSideNavBadge';
import {NavbarMaakTaakActionButtonConnected} from '../NavbarMaakTaakActionButton';
import {TaakCreateRoute} from '../TaakCreateModal/TaakCreateRoute';
import {MijnTakenOpDashboard} from '../MijnTakenOpDashboard/MijnTakenOpDashboard';
import {MijnTakenOverzichtConnected} from '../MijnTakenOverzicht';
import {TakenSideLinksConnected} from '../TakenSideLinks';
import {FilterableTakenTableConnected} from '../FilterableTakenTable';
import {MijnTakenArchiefPage} from '../MijnTakenArchiefPage';

const generateLijstRoute: CreateRouteFunction<{
    lijstId: string;
}> = (route: string, params) =>
    generatePath(route, {
        lijstId: assertIdFromIri(params?.lijstId),
    });

export const TakenRoutes = createRoutes('taken', {
    mijn_taken: {
        Element: () => (
            <PageFromMenu sidebar={<TakenSideLinksConnected />}>
                <MijnTakenOverzichtConnected />
            </PageFromMenu>
        ),
    },
    mijn_taken_archief: {
        Element: () => <MijnTakenArchiefPage />,
    },
    mijn_taken_voltooid: {
        Element: () => {
            const {t} = useTranslation('Taken.nl');
            return (
                <PageFromMenu sidebar={<LogbookConnected />}>
                    <FilterableTakenTableConnected
                        voltooid
                        takenWaaraanIkDeelneem
                        title={t('MijnTakenPage.voltooidTableTitle')}
                    />
                </PageFromMenu>
            );
        },
    },
    prive: {
        Element: () => (
            <PageFromMenu sidebar={<TakenSideLinksConnected />}>
                <MijnTakenOverzichtConnected />
            </PageFromMenu>
        ),
    },
    mijn_taken_volgend: {
        Element: () => {
            const {t} = useTranslation('Taken.nl');
            return (
                <PageFromMenu sidebar={<LogbookConnected />}>
                    <FilterableTakenTableConnected
                        voltooid={false}
                        takenDieIkVolg
                        title={t('MijnTakenPage.volgendTableTitle')}
                    />
                </PageFromMenu>
            );
        },
    },
    overzicht: {
        Element: () => {
            const {t} = useTranslation('Taken.nl');
            return (
                <PageFromMenu sidebar={<LogbookConnected />}>
                    <FilterableTakenTableConnected voltooid={false} title={t('TaakOverviewPage.actiefTableTitle')} />
                </PageFromMenu>
            );
        },
    },

    voltooid: {
        Element: () => {
            const {t} = useTranslation('Taken.nl');
            return (
                <PageFromMenu sidebar={<LogbookConnected />}>
                    <FilterableTakenTableConnected voltooid title={t('TaakOverviewPage.voltooidTableTitle')} />
                </PageFromMenu>
            );
        },
    },
    archief: {
        Element: () => {
            const {t} = useTranslation('Taken.nl');
            return (
                <PageFromMenu sidebar={<LogbookConnected />}>
                    <FilterableTakenTableConnected
                        voltooid={null}
                        gearchiveerd
                        snoozed={null}
                        title={t('TaakOverviewPage.archiefTableTitle')}
                    />
                </PageFromMenu>
            );
        },
    },
    snooze: {
        Element: () => {
            const {t} = useTranslation('Taken.nl');
            return (
                <PageFromMenu sidebar={<LogbookConnected />}>
                    <FilterableTakenTableConnected enableAdd={false} snoozed title={t('Gesnoozed')} />
                </PageFromMenu>
            );
        },
    },
    create: {
        Element: TaakCreateRoute,
        defaultBackgroundRoute: 'mijn_taken',
    },
    edit: {
        createRoute: createRouteWithId,
        defaultBackgroundRoute: 'mijn_taken',
        Element: TaakEditRoute,
    },
    editActivities: {
        createRoute: createRouteWithId,
        defaultBackgroundRoute: 'mijn_taken',
        Element: TaakEditRoute,
    },
    lijst_bewerken: {
        defaultBackgroundRoute: 'overzicht',
        Element: (props: RouteElementProps<{lijstId: string}>) => <EditModalPageConnected {...props} />,
        createRoute: generateLijstRoute,
    },
});

TakenRoutes.decorate((Decorated) => {
    const edit = TakenRoutes.edit.useUrlFactory();
    useAddReferenceLinkResolver(
        useCallback(
            (data) => {
                if (data.type === 'Taak') {
                    const link = <Link to={edit(data)}>{data.text}</Link>;
                    if (data.gearchiveerd) {
                        return link;
                    }
                    return <HoverIcon>{link}</HoverIcon>;
                }
                // TODO: This does not belong here.
                if (data.type === 'Gebruiker') {
                    return data.text;
                }
                if (data.type === 'VerwijderdeTaak') {
                    return data.text;
                }
                return null;
            },
            [edit]
        )
    );
    return <Decorated />;
}, 'RoutesLinkResolver');

TakenRoutes.decorate(
    (Decorated) => (
        <>
            <Decorated />
            <TakenCounterSideNavBadgeConnected legacy />
            <MijnTakenOpDashboard />
            <NavbarMaakTaakActionButtonConnected />
            <TicketCommentFormAddTaakButtonConnected />
        </>
    ),
    'RoutesLinkResolver'
);

export const IriOfTaak = iriFromId('Taken');
