import {Activity, LogbookListItem, EntityReferenceLinks, Avatar} from '@growthbase/design-components';
import {ILogbookItemFragment} from '@growthbase/graphql';
import {FC, memo} from 'react';

export interface LogbookItemProps {
    node: ILogbookItemFragment;
}

export const LogbookItem: FC<LogbookItemProps> = memo(({node: {entities, gebruiker, description, at}}) => (
    <LogbookListItem>
        <Activity
            description={
                <>
                    {gebruiker?.naam} {description.interpolated}
                </>
            }
            time={at}
            referenceImage={gebruiker && <Avatar {...gebruiker} />}
            entityReferences={entities && <EntityReferenceLinks fragments={entities} />}
        />
    </LogbookListItem>
));

LogbookItem.displayName = 'LogbookItem';
