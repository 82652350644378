import {ReactNode} from 'react';
import {createPortal} from 'react-dom';
import classNames from 'classnames';
import {UseFloatingReturn} from '@floating-ui/react-dom-interactions/src/types';
import {usePortalDestination} from '@growthbase/spa';
import styles from './SideNavBadge.module.scss';
import {useUniqueClassname} from '../../../PaginationInfiniteScroll';

export interface SideNavBadgeProps {
    target: string;
    children: ReactNode;
    reference?: UseFloatingReturn['reference'];
    onClick?: () => void;
}

export const SideNavBadge = ({target, children, reference, onClick}: SideNavBadgeProps) => {
    const destination = usePortalDestination(target);

    const uniqueClassName = useUniqueClassname();

    if (!destination) {
        return null;
    }

    // The "badge" class is styled by Clientbox and not React.
    const badge = onClick ? (
        <div
            className={classNames(uniqueClassName, 'badge', styles.SideNavBadge__clickable)}
            onClick={onClick}
            role="button"
            ref={reference}
            tabIndex={0}
        >
            {children}
        </div>
    ) : (
        <div className={classNames(uniqueClassName, 'badge')} ref={reference}>
            {children}
        </div>
    );

    return createPortal(badge, destination);
};
