import {ReactNode} from 'react';

import classNames from 'classnames/bind';
import {capitalize} from 'lodash';
import styles from './Message.module.scss';

const cx = classNames.bind(styles);

export interface MessageProps {
    children: ReactNode;
    type?: 'info' | 'warning' | 'danger' | 'success';
}

export const Message = ({children, type = 'info'}: MessageProps) => {
    const classes = cx('message', `message--type${capitalize(type)}`);
    return <div className={classes}>{children}</div>;
};

export const MessageInfo = (props: MessageProps) => <Message {...props} />;
export const MessageSuccess = (props: MessageProps) => <Message {...props} type="success" />;
export const MessageWarning = (props: MessageProps) => <Message {...props} type="warning" />;
export const MessageDanger = (props: MessageProps) => <Message {...props} type="danger" />;
