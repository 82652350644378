import {DienstSelectieGroep, DienstSelectieGrouped} from '../../Components/DienstSelectie/DienstSelectieGrouped';
import {Dienst} from '../../Sidebars/PlanningSidebar/Dienst';
import {DienstSelectie} from '../../Components/DienstSelectie';

interface GroupedTypeProps {
    activeDienstId?: string;
    keuzeAangepast: (value?: string) => void;
    groepen: DienstSelectieGroep[];
}

interface ListTypeProps {
    activeDienstId?: string;
    keuzeAangepast: (value?: string) => void;
    diensten: Dienst[];
}

export type DienstenTabProps = GroupedTypeProps | ListTypeProps;

export const DienstenTab = ({keuzeAangepast, activeDienstId, ...props}: DienstenTabProps) => {
    if ('diensten' in props) {
        return (
            <DienstSelectie diensten={props.diensten} keuzeAangepast={keuzeAangepast} activeDienstId={activeDienstId} />
        );
    }

    return (
        <DienstSelectieGrouped
            groepen={props.groepen}
            keuzeAangepast={keuzeAangepast}
            activeDienstId={activeDienstId}
        />
    );
};
