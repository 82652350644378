import {ILogbookFragment} from '@growthbase/graphql';
import {usePaginationFixedResult} from '@growthbase/spa';
import {faker} from '@faker-js/faker';
import {Logbook} from './Logbook';

faker.seed(1);

export function LogbookStub() {
    return (
        <Logbook
            helpers={usePaginationFixedResult<ILogbookFragment>([
                {
                    id: 'id1',
                    description: {
                        interpolated: 'hi!!',
                    },
                    at: new Date().toISOString(),
                    gebruiker: {
                        id: '123123',
                        naam: 'John doe',
                        imageUrl: faker.image.avatar(),
                    },
                    entities: [],
                },
                {
                    id: 'id2',
                    description: {
                        interpolated: 'Bye!!',
                    },
                    at: new Date().toISOString(),
                    gebruiker: {
                        id: '123121233',
                        naam: 'Jane doe',
                        imageUrl: faker.image.avatar(),
                    },
                    entities: [],
                },
            ])}
        />
    );
}
