import {
    PositieType,
    UpdateQuery,
    useAddToQueryCache,
    useChangeOrderInQueryCache,
    useRemoveFromQueryCache,
} from '@growthbase/cache';
import {DNDContainerUserData, DropItemHandlerEvent, onDropItemHandler, onRemoveItemHandler} from '@growthbase/keys';
import {useCallback} from 'react';

export interface PlacementToQueryCacheParams<
    TNode extends DNDContainerUserData,
    TContainer extends DNDContainerUserData,
    TVariables
> {
    updateQuery: UpdateQuery<TNode, TVariables>;

    onDrop: onDropItemHandler<TNode, TContainer>;

    onRemove?: onRemoveItemHandler<TNode>;

    edgeTypeName: string;
    nodeTypeName: string;
}

export interface DropQueryCacheHandlers<TNode extends DNDContainerUserData, TContainer extends DNDContainerUserData> {
    onDrop: onDropItemHandler<TNode, TContainer>;

    onRemove: onRemoveItemHandler<TNode>;
}

/**
 * Add or remove from query cache when dropping an item.
 */
export const useDropQueryCacheHandlers = <
    TNode extends DNDContainerUserData,
    TContainer extends DNDContainerUserData,
    TVariables
>({
    updateQuery,
    onDrop,
    onRemove,
    ...rest
}: PlacementToQueryCacheParams<TNode, TContainer, TVariables>): DropQueryCacheHandlers<TNode, TContainer> => {
    const addTaakToQueryCache = useAddToQueryCache(updateQuery, {
        placeholderPosition: true,
        ...rest,
    });
    const changeOrderInCache = useChangeOrderInQueryCache(updateQuery);
    const removeInCache = useRemoveFromQueryCache(updateQuery);

    return {
        onDrop: useCallback(
            (event: DropItemHandlerEvent<TNode, TContainer>) => {
                const {
                    placement: {itemThatIsBefore, itemThatIsAfter},
                    item,
                    previousContainer: {id: previousContainerId},
                    overContainer: {id: overContainerId},
                } = event;
                if (previousContainerId === overContainerId) {
                    changeOrderInCache(item.id, itemThatIsAfter?.id, itemThatIsBefore?.id);
                } else if (itemThatIsBefore) {
                    addTaakToQueryCache(item, PositieType.after, itemThatIsBefore?.id);
                } else {
                    addTaakToQueryCache(item, PositieType.before, itemThatIsAfter?.id);
                }
                return onDrop(event);
            },
            [addTaakToQueryCache, changeOrderInCache, onDrop]
        ),
        onRemove: useCallback(
            (item: TNode) => {
                removeInCache(item.id);
                return onRemove?.(item) ?? Promise.resolve();
            },
            [onRemove, removeInCache]
        ),
    };
};
