import React from 'react';
import {stubTrue, constant} from 'lodash/fp';
import {getMentionOnSelectItem} from '@udecode/plate';

import {TComboboxItem} from '@udecode/plate-combobox/dist/types';
import styles from './AsyncComboBox.module.scss';
import {Combobox} from './Vendor/Combobox';
import {AsyncComboboxProps} from './AsyncCombobox';

/* eslint-disable react/no-unstable-nested-components */
const noFilter = constant(stubTrue);

export const AsyncComboboxForked = <T extends TComboboxItem = TComboboxItem>({
    pluginKey,
    items,
    trigger,
    children,
}: AsyncComboboxProps<T>) => (
    <Combobox
        classNames={{
            root: styles.asyncComboBox__root,
            item: styles.asyncComboBox__item,
            highlightedItem: styles.asyncComboBox__highlight,
        }}
        onRenderItem={({item}, index) => <>{children(item as T)}</>}
        id={pluginKey}
        trigger={trigger}
        filter={noFilter}
        onSelectItem={getMentionOnSelectItem({key: pluginKey})}
        items={items}
        controlled
    />
);
