import {FC} from 'react';
import {useLijsttemplateTaakCreateFormConnectedMutation} from '@growthbase/graphql';
import {DefaultLijsttemplate, useCallbackRef} from '@growthbase/spa';
import {v4} from 'uuid';
import {CreateForm} from './CreateForm';

export const CreateFormConnected: FC = () => {
    const lijsttemplate = DefaultLijsttemplate.useValue();
    const [mutate] = useLijsttemplateTaakCreateFormConnectedMutation();
    return (
        <CreateForm
            onSubmit={useCallbackRef((values) =>
                mutate({
                    variables: {
                        input: {
                            lijsttemplateId: lijsttemplate,
                            omschrijving: values.omschrijving,
                            id: v4(),
                            titel: values.titel,
                        },
                    },
                }).then()
            )}
        />
    );
};
