import React, {ReactNode, useMemo} from 'react';
import {Subject} from 'rxjs';
import {findIndex} from 'lodash';
import {v4} from 'uuid';
import {Breadcrumb, BreadcrumbContext, BreadcrumbContextType, BreadcrumbEvent} from './BreadcrumbContext';

export interface BreadcrumbProviderProps {
    children?: ReactNode;
}

export const BreadcrumbProvider = ({children}: BreadcrumbProviderProps) => {
    const contextValue = useMemo((): BreadcrumbContextType => {
        const breadcrumbs: (Breadcrumb & {id: string})[] = [];
        const subject = new Subject<BreadcrumbEvent>();
        return {
            breadcrumbs,
            context: subject,
            addBreadcrumb: (breadcrumb: Breadcrumb) => {
                const id = v4();
                breadcrumbs.push({
                    ...breadcrumb,
                    id,
                });

                breadcrumbs.sort((a, b) => {
                    const orderA = a.order || 0;
                    const orderB = b.order || 0;
                    if (orderB === orderA) {
                        return 0;
                    }
                    if (orderA > orderB) {
                        return 1;
                    }
                    return -1;
                });

                subject.next({
                    breadcrumbs: [...breadcrumbs],
                });

                return (): void => {
                    const removeIndex = findIndex(breadcrumbs, {id});
                    breadcrumbs.splice(removeIndex, 1);
                    subject.next({
                        breadcrumbs: [...breadcrumbs],
                    });
                };
            },
        };
    }, []);

    return <BreadcrumbContext.Provider value={contextValue}>{children}</BreadcrumbContext.Provider>;
};
