import {idFromIri} from '@growthbase/routing';
import {useSocketEvent} from '@growthbase/websockets';
import * as yup from 'yup';
import {BaseNode} from '../baseNode';
import {PositieSchema} from './useExternalEntityAdded';

const EntityRemovedSchema = yup
    .object({
        id: yup.string().required(),
        typename: yup.string().required(),
        data: yup.object().defined().default({}).nullable(),
        positie: PositieSchema.optional().nullable(),
    })
    .label('EntityRemovedSchema')
    .required();

export const useExternalEntityRemoved = <TNode extends BaseNode>(
    callback: (args: {id: string; typename: string; node: Partial<TNode>; fields: (keyof TNode)[]}) => void
) => {
    useSocketEvent('EntityRemoved', (data): void => {
        const args = EntityRemovedSchema.validateSync(data);
        const object = (args.data ?? {}) as Partial<TNode>;
        if (object.id) {
            object.id = idFromIri(object.id);
        }
        callback({
            ...args,
            node: object,
            fields: args.data ? (Object.keys(args.data) as unknown as (keyof TNode)[]) : [],
        });
    });
};
