import {useAsyncComboBox, useComboBoxContext} from '@growthbase/design-components';
import {IRelatieType} from '@growthbase/graphql';
import React, {useEffect, useMemo} from 'react';
import {ComboboxRelatieGroup, RelatiesCombobox, RelatiesMentionKey} from './RelatiesCombobox';

const items: ComboboxRelatieGroup[] = [
    {
        label: 'Bedrijven',
        relaties: [
            {
                id: '1',
                label: 'Bedrijf 1',
                type: IRelatieType.Bedrijf,
            },
            {
                id: '2',
                label: 'Bedrijf 2',
                type: IRelatieType.Bedrijf,
            },
        ],
    },
    {
        label: 'Personen',
        relaties: [
            {
                id: '3',
                label: 'Persoon 1',
                type: IRelatieType.Persoon,
            },
            {
                id: '4',
                label: 'Persoon 2',
                type: IRelatieType.Persoon,
            },
        ],
    },
];

export const RelatiesComboboxStub = () => {
    const {setLoading} = useComboBoxContext();
    const {text} = useAsyncComboBox(RelatiesMentionKey);

    useEffect(() => {
        setLoading(true);
    }, [setLoading, text]);

    const result = useMemo(
        () =>
            items
                .map(
                    (item): ComboboxRelatieGroup => ({
                        ...item,
                        relaties: item.relaties.filter(
                            (relatie) => !text || relatie.label.toLowerCase().includes(text.toLowerCase())
                        ),
                    })
                )
                .filter(
                    (item) =>
                        !text || (item.label.toLowerCase().includes(text.toLowerCase()) && item.relaties.length > 0)
                ),
        [text]
    );
    return <RelatiesCombobox groups={result} />;
};
