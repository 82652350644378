import {PaginationHelpers, useLogger} from '@growthbase/spa';
import InfiniteScroll from 'react-infinite-scroll-component';
import {ReactNode} from 'react';
import {useScrollElement} from './hooks/useScrollSelector';
import './PaginationInfiniteScroll.scss';

export interface PaginationInfiniteScrollProps<TNode> {
    helpers: PaginationHelpers<TNode>;
    children: ReactNode;
    inverse?: boolean;
    enabled?: boolean;
}

export function PaginationInfiniteScroll<TNode>({
    helpers,
    children,
    inverse,
    enabled = true,
}: PaginationInfiniteScrollProps<TNode>) {
    const logger = useLogger('PaginationInfiniteScroll');
    const target = useScrollElement();
    const {connections, hasMore, loadMore} = helpers;

    if (!enabled) {
        return <>{children}</>;
    }

    if (!target) {
        logger.warn('No target found for PaginationInfiniteScroll');
        return null;
    }

    return (
        <InfiniteScroll
            // @ts-expect-error I hope this still works...
            scrollableTarget={target}
            dataLength={connections.length}
            next={loadMore}
            style={{display: 'flex', flexDirection: inverse ? 'column-reverse' : 'column', overflow: 'visible'}}
            hasMore={hasMore}
            inverse={inverse}
            loader={null}
        >
            {children}
        </InfiniteScroll>
    );
}
