import {DraggableNodes} from '@dnd-kit/core/dist/store/types';
import {DNDData, DNDItemData} from '../Value';

export type NodeEntry = {
    node: DNDItemData;
    element: HTMLElement | null;
};
export const convertDraggableNodes = (nodes: DraggableNodes) => {
    const result: NodeEntry[] = [];
    for (const [, node] of nodes.entries()) {
        const data = node?.data?.current as unknown as DNDData;
        if (data?.type !== 'item') {
            // eslint-disable-next-line no-continue
            continue;
        }
        result.push({
            node: data,
            element: node?.node?.current ?? null,
        });
    }
    return result;
};
