import * as yup from 'yup';
import {InferType} from 'yup';

export const ReactiesTwigConnectorCommentDtoSchema = yup
    .object({
        id: yup.number().required(),
        name: yup.string().required(),
        avatarUrl: yup.string().nullable(),
        date: yup.date().required(),
        text: yup.string().required(),
        deleteUrl: yup.string().required(),
        editUrl: yup.string().required(),
    })
    .label('ReactiesTwigConnectorInputItemDtoSchema');

export const ReactiesTwigConnectorCommentsDtoSchema = yup
    .object({
        reactions: yup.array(ReactiesTwigConnectorCommentDtoSchema).min(0),
    })
    .label('ReactiesTwigConnectorCommentsDtoSchema');

export const ReactiesTwigConnectorTranslationsDtoSchema = yup
    .object({
        react: yup.string().required(),
        edit: yup.string().required(),
        edit_title: yup.string().required(),
        delete: yup.string().required(),
        delete_title: yup.string().required(),
        delete_text: yup.string().required(),
        loading: yup.string().required(),
        reactions: yup.string().required(),
        reaction: yup.string().required(),
    })
    .label('ReactiesTwigConnectorTranslationsDtoSchema')
    .required();

export const ReactiesTwigConnectorInputDtoSchema = yup
    .object({
        commentId: yup.number().required(),
        ticketId: yup.number().required(),
        fetchUrl: yup.string(),
        reactions: yup.array(ReactiesTwigConnectorCommentDtoSchema).min(0),
        reactionCount: yup.number().required(),
        currentUser: yup
            .object({
                avatarUrl: yup.string(),
                name: yup.string().required(),
            })
            .required(),
        commentForm: yup.string().required(),
        translations: ReactiesTwigConnectorTranslationsDtoSchema,
    })
    .label('ReactiesTwigConnectorInputDtoSchema')
    .required();

export type ReactiesTwigConnectorCommentsDto = InferType<typeof ReactiesTwigConnectorCommentsDtoSchema>;
export type ReactiesTwigConnectorTranslationsDto = InferType<typeof ReactiesTwigConnectorTranslationsDtoSchema>;
export type ReactiesTwigConnectorInputDto = InferType<typeof ReactiesTwigConnectorInputDtoSchema>;
