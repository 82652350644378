import {useMemo} from 'react';
import {Opties} from './useVerplaatsForm';

export interface BordOptie {
    id: string;
    label: string;
    value: string;
}

export const useBordOpties = (opties?: Opties | null) =>
    useMemo(
        (): BordOptie[] =>
            (opties?.borden ?? []).map((bord) => ({
                id: bord.id,
                value: bord.id,
                label: bord.naam,
            })),
        [opties?.borden]
    );
