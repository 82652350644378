import {ReactNode} from 'react';
import classNames from 'classnames/bind';
import styles from './FieldWrapper.module.scss';

const cx = classNames.bind(styles);

export interface FieldWrapperProps {
    errorContent?: ReactNode;
    labelContent?: ReactNode;
    buttonContent?: ReactNode;
    buttonPosition?: 'right' | 'bottom';
    children: ReactNode;
    negativeMargin?: boolean;
}

export const FieldWrapper = ({
    children,
    negativeMargin,
    labelContent,
    errorContent,
    buttonContent,
    buttonPosition,
    ...props
}: FieldWrapperProps) => {
    const className = cx('fieldWrapper', {
        'fieldWrapper--negativeMargin': negativeMargin,
        'fieldWrapper--buttonPositionBottom': buttonPosition === 'bottom',
    });
    return (
        <div className={className} {...props}>
            {labelContent}
            <div className={cx('fieldWrapper__content')}>
                <div className={cx('fieldWrapper__field')}>{children}</div>
                {buttonContent && <div className={cx('fieldWrapper__buttons')}>{buttonContent}</div>}
            </div>
            {errorContent}
        </div>
    );
};
