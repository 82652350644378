import {from, Observable} from 'rxjs';
import {SchemaOf} from 'yup';

export interface ConfiguredElement<TConfig, TName extends string = string> {
    element: Element;
    dto: TConfig;
    name: TName;
}

export function findElementByAttributeDTOOnce<TConfig, TName extends string = string>(
    name: TName,
    schema: SchemaOf<TConfig>
): Observable<ConfiguredElement<TConfig, TName>> {
    const elements = [...Array.from(document.querySelectorAll(`[${CSS.escape(name)}]`))];
    const configuredElements = elements.map((element) => {
        const parse = JSON.parse(element.getAttribute(name) ?? '');
        element.removeAttribute(name);
        return {
            element,
            dto: schema.validateSync(parse) as TConfig,
            name,
        };
    });
    return from(configuredElements);
}
