import {DraggableAttributes} from '@dnd-kit/core';
import {SyntheticListenerMap} from '@dnd-kit/core/dist/hooks/utilities';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {useMemo} from 'react';
import {useHashedMemorize} from '@growthbase/spa';
import {DNDItemData, DNDItemUserData} from '../Value';

export interface SortableAttributes {
    ref: (node: HTMLElement | null) => void;

    /**
     * Style for the container that is being dragged.
     */
    style: {
        transform: string | undefined;
        transition: string | undefined;
    };
}

export type AnchorAttributes = SyntheticListenerMap & DraggableAttributes;

export interface SortableItemResult {
    attributes: SortableAttributes;

    anchorAttributes: AnchorAttributes;

    isDragging: boolean;
}

export interface SortableItemProps<TItemUserData extends DNDItemUserData> {
    item: DNDItemData<TItemUserData>;
    disabled?: boolean;
}

export function useSortableItemProps<TItemUserData extends DNDItemUserData>({
    item,
    disabled,
}: SortableItemProps<TItemUserData>): SortableItemResult {
    const {isDragging, attributes, listeners, setNodeRef, transform, transition} = useSortable({
        id: item.id,
        data: item,
        disabled,
    });
    const anchorAttributes = useHashedMemorize({
        ...listeners,
        ...attributes,
    } as AnchorAttributes);

    const style = useHashedMemorize({
        transform: CSS.Transform.toString(transform),
        transition,
    });

    const combinedAttributes = useHashedMemorize({
        ref: setNodeRef,
        style,
    });

    return useMemo(
        (): SortableItemResult => ({
            attributes: combinedAttributes,
            anchorAttributes,
            isDragging,
        }),
        [combinedAttributes, anchorAttributes, isDragging]
    );
}
