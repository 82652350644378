import {useEffect, useMemo} from 'react';
import {useMount, useUnmount} from 'react-use';
import {useDNDContainerState, useRemoveContainer, useSetItems} from '../DNDReducer';
import {asDNDContainerId, asDNDItemId} from '../utils';
import {
    DNDContainerContext,
    DNDContainerData,
    DNDContainerOptions,
    DNDContainerState,
    DNDContainerUserData,
    DNDItemData,
    DNDItemUserData,
    DNDUserDataType,
} from '../Value';
import {useDNDContext} from './useDNDContext';

export interface DNDContainerResult<
    TUserDataType extends DNDUserDataType,
    TItemUserData extends DNDItemUserData,
    TContainerUserData extends DNDContainerUserData
> {
    items: DNDItemData<TItemUserData, TUserDataType>[];

    containerData: DNDContainerData<TContainerUserData, TUserDataType>;
}

const empty: [] = [];

export function useDNDContainer<
    TUserDataType extends DNDUserDataType,
    TItemUserData extends DNDItemUserData,
    TContainerUserData extends DNDContainerUserData
>({
    userDataType,
    userData,
    onDrop,
    items,
    renderDraggedItem,
    wrapperElement,
    onRemove,
    accept,
}: DNDContainerOptions<TUserDataType, TItemUserData, TContainerUserData>): DNDContainerResult<
    TUserDataType,
    TItemUserData,
    TContainerUserData
> {
    const containerData = useMemo(
        (): DNDContainerData<TContainerUserData, TUserDataType> => ({
            type: 'container',
            id: asDNDContainerId(userData.id),
            userDataType,
            userData,
        }),
        [userData, userDataType]
    );

    const itemsData = useMemo(
        (): DNDItemData<TItemUserData, TUserDataType>[] =>
            items.map((item) => ({
                id: asDNDItemId(item.id),
                type: 'item',
                userDataType,
                userData: item,
                containerId: containerData.id,
            })),
        [items, userDataType, containerData.id]
    );

    const {containers} = useDNDContext();
    const setItems = useSetItems();
    const removeContainer = useRemoveContainer();
    const containerState = useDNDContainerState(containerData.id) as DNDContainerState<
        TUserDataType,
        TItemUserData,
        TContainerUserData
    > | null;

    useEffect(() => {
        setItems({container: containerData, items: itemsData});
    }, [containerData, setItems, itemsData]);

    useUnmount(() => {
        if (containers[containerData.id]?.instances === 0) {
            removeContainer(containerData.id);
            delete containers[containerData.id];
        }
    });

    const found = containers[containerData.id] as unknown as DNDContainerContext<
        TUserDataType,
        TItemUserData,
        TContainerUserData
    > | null;

    const container: DNDContainerContext<TUserDataType, TItemUserData, TContainerUserData> = {
        instances: containers[containerData.id]?.instances ?? 0,
        userDataType,
        userData,
        accept,
        handleDrop: onDrop,
        handleRemove: onRemove,
        draggedItemOptions: {
            renderDraggedItem,
            wrapperElement,
        },
    };
    if (found) {
        Object.assign(found, container);
    } else {
        Object.assign(containers, {[containerData.id]: container});
    }

    useMount(() => {
        if (!containers[containerData.id]) {
            return;
        }
        containers[containerData.id].instances += 1;
    });

    return {
        items: containerState?.draggingItemsDND ?? containerState?.draggingItems ?? containerState?.items ?? empty,
        containerData,
    };
}
