import {useTranslation} from 'react-i18next';
import {AddBreadcrumb, Page} from '@growthbase/design-components';
import {
    TicketRouteParams,
    PlanningRoutes,
    TicketSidebarConnected,
    useLoadPersistentPlanningState,
    useSyncedPlanningState,
    useCurrentView,
    PlanningViews,
} from '@growthbase/planning';
import {RouteElementProps, useRoutes} from '@growthbase/routing';

import {generatePath} from 'react-router';
import {toUpper} from 'lodash';

export type TicketPlanningPageConnectedProps = RouteElementProps<TicketRouteParams>;

export const TicketPlanningPageConnected = (props: TicketPlanningPageConnectedProps) => {
    const {params} = props;

    if (!params) {
        throw new Error('Logic exception.');
    }

    const {t} = useTranslation('spa.nl');

    useLoadPersistentPlanningState();
    useSyncedPlanningState();

    const currentView = useCurrentView();

    const routes = useRoutes();

    return (
        <Page sidebar={<TicketSidebarConnected key={currentView.id} ticketId={params.ticket} />}>
            <AddBreadcrumb to={generatePath(routes.ticket.index)}>{t('ticket.breadcrumb.tickets')}</AddBreadcrumb>
            <AddBreadcrumb
                to={generatePath(routes.ticket.read, {
                    ticket: params.ticket,
                    name: params.name,
                })}
            >
                {t('ticket.breadcrumb.ticketnummer', {
                    ticketnummer: toUpper(params.name),
                })}
            </AddBreadcrumb>
            <AddBreadcrumb to={PlanningRoutes.ticket.useUrl(params)}>{t('opdracht.breadcrumb.planning')}</AddBreadcrumb>
            <PlanningViews />
        </Page>
    );
};
