import {useLogger} from '@growthbase/spa';
import {useEffect, useState} from 'react';
import {useStore} from 'react-redux';
import {ClientRect, useDndContext} from '@dnd-kit/core';
import {of} from 'rxjs';
import {retry, tap} from 'rxjs/operators';
import {useDNDContainersItemsGroupedByType} from '../DNDReducer';
import {
    asDNDItemId,
    calculateReplacementPlacement,
    ContainerEntry,
    convertDraggableNodes,
    convertDroppableContainers,
    NodeEntry,
} from '../utils';
import {DNDItemUserData, DNDStoreState, DNDData, DNDContainerUserData, DNDUserItemId, DNDUserDataType} from '../Value';
import {useDNDContext} from './useDNDContext';

export interface OnDragMoveButtonHandlers {
    itemId: DNDUserItemId;
    type: DNDUserDataType;
    skipContainers?: boolean;
}

export interface OnDragMoveButtonHandlersResult<
    TUserData extends DNDItemUserData,
    TContainer extends DNDContainerUserData
> {
    onUp: (() => Promise<void>) | null;
    upItem: TUserData | null;
    upContainer: TContainer | null;

    onDown: (() => Promise<void>) | null;
    downItem: TUserData | null;
    downContainer: TContainer | null;
}

type Entry = NodeEntry | ContainerEntry;

export function useOnDragMoveButtonHandlers<
    TItem extends DNDItemUserData,
    TContainer extends DNDContainerUserData = DNDContainerUserData
>({
    itemId: userItemId,
    type,
    skipContainers,
}: OnDragMoveButtonHandlers): OnDragMoveButtonHandlersResult<TItem, TContainer> {
    const {containers} = useDNDContext();
    const dndContext = useDndContext();
    const containersState = useDNDContainersItemsGroupedByType();
    const typeItems = containersState[type];
    const store = useStore<DNDStoreState>();
    const [handlers, setHandlers] = useState<OnDragMoveButtonHandlersResult<TItem, TContainer>>({
        onDown: null,
        onUp: null,
        downContainer: null,
        upItem: null,
        upContainer: null,
        downItem: null,
    });

    const logger = useLogger('useOnDragMoveButtonHandlers');

    useEffect(() => {
        const doCalculate = (): OnDragMoveButtonHandlersResult<DNDItemUserData, DNDContainerUserData> => {
            const result: OnDragMoveButtonHandlersResult<DNDItemUserData, DNDContainerUserData> = {
                onDown: null,
                onUp: null,
                downContainer: null,
                upItem: null,
                upContainer: null,
                downItem: null,
            };
            const itemId = asDNDItemId(userItemId);
            if (!typeItems) {
                logger.warn(`Type items not found`);
                return result;
            }
            const allItems = Object.values(typeItems);
            const item = allItems.find((i) => i.id === itemId);
            if (!item) {
                logger.warn(`Item not found ${itemId}`);
                return result;
            }

            const itemNode = dndContext.draggableNodes.get(asDNDItemId(userItemId));
            if (!itemNode) {
                logger.warn(`Item node not found ${itemId}`);
                return result;
            }

            const itemElement = itemNode.node.current;
            if (!itemElement) {
                logger.warn(`Item element not found ${itemId}`);
                return result;
            }

            const droppableRects = new Map<string, ClientRect>();

            const measure = (node: HTMLElement) => dndContext.measuringConfiguration.draggable.measure(node);

            // Add items to drag to.
            const entries = [
                ...convertDraggableNodes(dndContext.draggableNodes),
                ...(skipContainers ? convertDroppableContainers(dndContext.droppableContainers) : []),
            ];

            const filtered = entries
                .filter(({node}) => node.id !== itemId && node.userDataType === type)
                .map((entry) => {
                    const {
                        element,
                        node: {id},
                    } = entry;
                    if (element && !droppableRects.has(id)) {
                        droppableRects.set(id, measure(element));
                    }
                    return entry;
                })
                .sort(({node: {id: a}}, {node: {id: b}}) => {
                    const aRect = droppableRects.get(a);
                    const bRect = droppableRects.get(b);
                    if (!aRect || !bRect) {
                        return 0;
                    }
                    const aCenter = aRect.top + aRect.height / 2;
                    const bCenter = bRect.top + bRect.height / 2;
                    return aCenter - bCenter;
                });

            if (filtered.length === 0) {
                return result;
            }
            let itemMeasurement = droppableRects.get(itemId);
            if (!itemMeasurement) {
                itemMeasurement = measure(itemElement);
                droppableRects.set(userItemId, itemMeasurement);
            }

            if (!itemMeasurement) {
                logger.warn(`Item measurement not found ${itemId}`);
                return result;
            }
            const itemRect = itemMeasurement;

            const up = filtered.filter(({node: {id}}) => {
                const rect = droppableRects.get(id);
                if (!rect) {
                    return false;
                }
                return rect.top <= itemRect.top;
            });

            const down = filtered.filter(({node: {id}}) => {
                const rect = droppableRects.get(id);
                if (!rect) {
                    return false;
                }
                return rect.bottom >= itemRect.bottom;
            });

            const get = (entry: Entry | undefined) => {
                if (!entry) {
                    return {
                        item: null,
                        container: null,
                    };
                }
                const {node} = entry;
                if (node.type === 'item') {
                    return {
                        item: node,
                        container: containers[node.containerId] ?? null,
                    };
                }
                if (node.type === 'container') {
                    return {
                        item: null,
                        container: node,
                    };
                }
                return {
                    item: null,
                    container: null,
                };
            };

            const dropOn = (target: DNDData): Promise<void> => {
                const promises = [];
                const sourceContextContainer = containers[item.containerId];
                if (!sourceContextContainer) {
                    throw new Error(`Container not found`);
                }
                const containerOfItem = containers[item.containerId];
                if (!containerOfItem) {
                    throw new Error(`Container not found`);
                }
                let overContainerId;

                if (target.type === 'item') {
                    const overContainer = containers[target.containerId];
                    if (!overContainer) {
                        throw new Error(`Container not found`);
                    }
                    const containerState = store.getState().DND.containers[target.containerId];
                    if (!containerState) {
                        throw new Error(`Container state not found`);
                    }
                    overContainerId = target.containerId;
                    const placement = calculateReplacementPlacement(containerState.items, target.id, itemId);
                    promises.push(
                        overContainer.handleDrop({
                            item: item.userData,
                            overContainer: overContainer.userData,
                            placement,
                            previousContainer: containerOfItem.userData,
                        })
                    );
                }
                if (target.type === 'container') {
                    const overContainer = containers[target.id];
                    if (!overContainer) {
                        throw new Error(`Container not found`);
                    }
                    overContainerId = target.id;
                    promises.push(
                        overContainer.handleDrop({
                            item: item.userData,
                            overContainer: target.userData,
                            placement: {},
                            previousContainer: containerOfItem.userData,
                        })
                    );
                }

                if (sourceContextContainer.handleRemove && item.containerId !== overContainerId) {
                    promises.push(sourceContextContainer.handleRemove(item));
                }
                return Promise.all(promises).then(() => undefined);
            };

            const {item: upItem, container: upContainer} = get(up[up.length - 1]);
            if (upItem || upContainer) {
                result.upItem = upItem?.userData ?? null;
                result.upContainer = upContainer?.userData ?? null;
                result.onUp = (): Promise<void> => dropOn(upItem ?? upContainer);
            }
            const {item: downItem, container: downContainer} = get(down[0]);
            if (downItem || downContainer) {
                result.downItem = downItem?.userData ?? null;
                result.downContainer = downContainer?.userData ?? null;
                result.onDown = (): Promise<void> => dropOn(downItem ?? downContainer);
            }
            return result;
        };

        const subscription = of(1)
            .pipe(
                tap(() => setHandlers(doCalculate() as OnDragMoveButtonHandlersResult<TItem, TContainer>)),
                // Could be that the dom/state is not ready yet.
                retry({
                    count: 3,
                    delay: 10,
                })
            )
            .subscribe();

        return () => subscription.unsubscribe();
    }, [
        containers,
        dndContext.draggableNodes,
        dndContext.droppableContainers,
        dndContext.measuringConfiguration.draggable,
        userItemId,
        typeItems,
        type,
        store,
        skipContainers,
        logger,
    ]);

    return handlers;
}
