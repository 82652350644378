import {CSSProperties} from 'styled-components';
import classNames from 'classnames/bind';
import {Spacing} from '@growthbase/design-components';
import {capitalize} from 'lodash';
import styles from './StackDivider.module.scss';

const cx = classNames.bind(styles);

export interface StackDividerProps {
    pushItems?: 'before' | 'after';
    gap?: Spacing | number;
    label?: string;
    hide?: boolean;
}

interface StackDividerCssProps extends CSSProperties {
    '--margin': string;
}

export const StackDivider = ({label, pushItems, hide, gap = 0}: StackDividerProps) => {
    if (hide) {
        return null;
    }

    const style: StackDividerCssProps = {
        '--margin': typeof gap === 'number' ? `${gap}px` : `var(--spacing${capitalize(gap)})`,
    };

    const className = cx('stack-divider', {
        'stackDivider--pushItemsBefore': pushItems === 'before',
        'stackDivider--pushItemsAfter': pushItems === 'after',
    });
    return <div className={className} style={style} aria-label={label} />;
};
