import {FC, ReactNode} from 'react';
import classNames from 'classnames/bind';
import styles from './FieldLabel.module.scss';

const cx = classNames.bind(styles);

export interface FieldLabelProps {
    compact?: boolean;
    asPlaceholder?: boolean;
    hidden?: boolean;
    noMargin?: boolean;
    htmlFor?: string;
    children: ReactNode;
}

export const FieldLabel: FC<FieldLabelProps> = ({compact, asPlaceholder, hidden, htmlFor, children, noMargin}) => {
    const classes = cx('fieldLabel', {
        'fieldLabel--compact': compact,
        'fieldLabel--hidden': hidden,
        'fieldLabel--noMargin': noMargin,
        'fieldLabel--asPlaceholder': asPlaceholder,
    });
    return (
        <label className={classes} htmlFor={htmlFor && htmlFor}>
            {children}
        </label>
    );
};
