import {IEditFromFragment, useEditFromMutation, useEditFromLijsttemplateQuery} from '@growthbase/graphql';
import {RouteElementProps, useHistoryBack} from '@growthbase/routing';
import React, {useCallback, FC} from 'react';
import {RoutePopupProvider, useGebruikerHeeftToegang} from '@growthbase/design-components';
import {EditForm} from './EditForm';
import {EditFormSchemaDto} from './EditFormSchema';

export const EditFormConnected: FC<
    RouteElementProps<{lijsttemplateId: string}, {lijsttemplate?: IEditFromFragment}>
> = ({params, state}) => {
    if (!params?.lijsttemplateId) {
        throw new Error('Missing id');
    }
    const {data} = useEditFromLijsttemplateQuery({
        variables: {
            id: params?.lijsttemplateId,
        },
    });

    const lijsttemplate = data?.lijsttemplate ?? state?.usr?.lijsttemplate;

    const [mutate] = useEditFromMutation();
    const goBack = useHistoryBack();

    const onSubmit = useCallback(
        ({naam}: EditFormSchemaDto) => {
            const id = lijsttemplate?.id;
            if (!id) {
                return Promise.reject(new Error('No id'));
            }
            return mutate({
                variables: {
                    input: {
                        id,
                        nieuweNaam: naam,
                        clientMutationId: 'EditFormConnected',
                    },
                },
            }).then(goBack);
        },
        [mutate, lijsttemplate, goBack]
    );

    const toegang = useGebruikerHeeftToegang({
        toegang: data?.ingelogdeGebruiker?.availableActions?.kanTakenBekijken,
    });
    if (!toegang || !lijsttemplate) {
        return null;
    }

    return (
        <RoutePopupProvider>
            <EditForm value={lijsttemplate} onSubmit={onSubmit} />
        </RoutePopupProvider>
    );
};
