import {
    PopupResultsGroupSubHeader,
    PopupResultsHeader,
    PopupResultsItem,
    AsyncComboboxForked,
} from '@growthbase/design-components';
import React, {useMemo} from 'react';
import {TComboboxItem} from '@udecode/plate-combobox/dist/types';
import {IKoppelbaarType} from '@growthbase/graphql';
import {RelatieValue} from '@growthbase/relaties';

export const OnderdeelVanMentionKey = 'onderdeelVan_mention';
export const OnderdeelVanMentionTrigger = '/';

export interface ComboboxOnderdeelVanItem {
    id: string;
    label: string;
    externalId?: string | null;
    relatieHeader?: string;
    groupHeader?: string;
    disabled?: boolean;
    href?: string;
    type: IKoppelbaarType;
    relatie: RelatieValue;
    first?: boolean;
    last?: boolean;
}

export interface OnderdeelVanComboboxProps {
    items: ComboboxOnderdeelVanItem[];
}

const mapOnderdeelVanToComboItem = (item: ComboboxOnderdeelVanItem): TComboboxItem<ComboboxOnderdeelVanItem> => ({
    key: item.id,
    text: item.label,
    disabled: item.disabled,
    data: item,
});

export const OnderdeelVanCombobox = ({items}: OnderdeelVanComboboxProps) => (
    <AsyncComboboxForked<TComboboxItem<ComboboxOnderdeelVanItem>>
        pluginKey={OnderdeelVanMentionKey}
        trigger={OnderdeelVanMentionTrigger}
        items={useMemo(() => items.map(mapOnderdeelVanToComboItem), [items])}
    >
        {({data}) => {
            const {relatieHeader, groupHeader, label} = data;
            const relatie = relatieHeader && (
                <PopupResultsHeader first={!!data.first}>{relatieHeader}</PopupResultsHeader>
            );
            const group = groupHeader && <PopupResultsGroupSubHeader>{groupHeader}</PopupResultsGroupSubHeader>;
            return (
                <div style={{width: '100%'}}>
                    {relatie}
                    {group}
                    <PopupResultsItem data={data} marginBottom={!!data.last}>
                        {label}
                    </PopupResultsItem>
                </div>
            );
        }}
    </AsyncComboboxForked>
);
