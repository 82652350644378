import {ReactNode, useCallback, useMemo, useState, FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useMount} from 'react-use';
import {timer} from 'rxjs';
import classNames from 'classnames/bind';
import {blurElement, TextFieldComponentNullable} from '../Form';
import {useSearchForm} from './hooks/useSearchForm';
import {SearchInput} from './SearchFormSchema';
import {useHandleResetOnEscapeKeyPress} from './hooks/useHandleResetOnEscapeKeyPress';
import styles from './SearchForm.module.scss';
import {Buttons} from '../Button';

const cx = classNames.bind(styles);

export interface SearchFormProps {
    innerRef?: (node: HTMLInputElement | null) => void;
    loading: boolean;
    disableAutofocus?: boolean;

    value: SearchInput;
    onChange: (value: SearchInput) => Promise<void> | void;

    placeholder?: string | undefined;
    label?: ReactNode;

    noBorders?: boolean;
    big?: boolean;
}

export const SearchForm: FC<SearchFormProps> = ({
    onChange,
    disableAutofocus = false,
    innerRef,
    loading,
    value,
    placeholder,
    label,
    noBorders,
    big,
}) => {
    const {t} = useTranslation('spa_form.nl');

    const [element, setRef] = useState<HTMLInputElement | null>(null);

    const {
        Form,
        Fields: {QueryField},
        formik,
        formProps,
    } = useSearchForm({onChange, value});

    const onClear = useCallback(() => {
        onChange({query: null});
        formik.setValues({
            query: null,
        });
        if (!formik.values.query || formik.values.query.trim() === '') {
            blurElement(element);
        }
    }, [element, formik, onChange]);

    const activeQuery: boolean = !!formik.values.query && formik.values.query.length > 0;

    const Button = useMemo(() => {
        if (loading) {
            return Buttons.LoadingIconOnly;
        }
        return activeQuery ? Buttons.CrossIconOnly : Buttons.SearchIconOnly;
    }, [loading, activeQuery]);

    const ref = useHandleResetOnEscapeKeyPress<HTMLInputElement>({
        onClear,
        activeQuery,
    });

    /**
     * We focus after dom is done rendering, otherwise the page will be
     * snapped to a wrong place.
     */
    useMount(() => {
        timer(50)
            .toPromise()
            .then(() => {
                if (!disableAutofocus) {
                    ref.current?.focus();
                }
            });
    });

    const className = cx('SearchForm', {
        'searchForm--noBorders': noBorders,
        'searchForm--big': big,
    });

    const searchText = t('design.SearchForm.search');
    return (
        <Form {...formProps}>
            <div className={className}>
                <QueryField
                    enabledDuringSubmit
                    role="searchbox"
                    component={TextFieldComponentNullable}
                    placeholder={placeholder ?? searchText}
                    label={label ?? searchText}
                    labelHidden
                    innerRef={useCallback(
                        (node: HTMLInputElement | null) => {
                            if (innerRef) {
                                innerRef(node);
                            }
                            setRef(node);
                            ref.current = node;
                        },
                        [innerRef, setRef, ref]
                    )}
                />
                <Button
                    loading={loading}
                    size="medium"
                    className={styles.searchForm__button}
                    ariaLabel={t('reset')}
                    onClick={onClear}
                    tabIndex={-1}
                />
                <button type="submit" className="visually-hidden">
                    {searchText}
                </button>
            </div>
        </Form>
    );
};
