import {useProfielMenuConnectedQuery} from '@growthbase/planning';
import {ReactNode} from 'react';
import {ProfielMenu, ProfileSwitchProfile} from './ProfielMenu';

export interface ProfielMenuConnectedProps {
    children?: ReactNode;
}

export const ProfielMenuConnected = (props: ProfielMenuConnectedProps) => {
    const {data, loading} = useProfielMenuConnectedQuery();

    const profiles: ProfileSwitchProfile[] =
        data?.Profielen?.map((profile) => ({
            id: profile.id,
            name: profile.naam,
            active: profile.actief,
        })) || [];

    return (
        <ProfielMenu
            profiles={profiles}
            loading={loading}
            {...props}
            onGotoProfile={(id) => {
                window.location.href = `?dashboard?profile=${id}`;
            }}
        />
    );
};
