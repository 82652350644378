import {
    ITaakPriveFieldFragment,
    usePriveFieldMaakPriveMutation,
    usePriveFieldMaakPubliekMutation,
} from '@growthbase/graphql';
import {FC} from 'react';
import {useCallbackRef} from '@growthbase/spa';
import {PriveField} from './index';

export interface PriveFieldConnectedProps {
    fragment?: ITaakPriveFieldFragment;
}

export const PriveFieldConnected: FC<PriveFieldConnectedProps> = ({fragment}) => {
    const priveVoorMij = fragment?.priveVoorMij ?? false;

    const [prive] = usePriveFieldMaakPriveMutation();
    const [publiek] = usePriveFieldMaakPubliekMutation();

    const taakId = fragment?.id ?? '';

    return (
        <PriveField
            onSubmit={useCallbackRef(async (newValue) => {
                if (newValue) {
                    await prive({
                        variables: {
                            input: {
                                id: taakId,
                                taakId,
                            },
                        },
                    });
                    return;
                }
                await publiek({
                    variables: {
                        input: {
                            id: taakId,
                            taakId,
                        },
                    },
                });
            })}
            isPrivate={priveVoorMij}
        />
    );
};
