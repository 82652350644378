import {createContext, FC, PropsWithChildren, ReactNode, useMemo, useRef, useState} from 'react';
import {RoutesDTO} from '@growthbase/spa';
import * as React from 'react';
import {Route} from 'react-router-dom';
import {DynamicPosition, useDynamicContent} from '@growthbase/dynamiccontent';
import {useRoutes} from '../Hook';
import {RouteConfig, RoutesConfig} from '../Value';
import {SetBackgroundRoute} from '../Component/SetBackgroundRoute';
import {RouteElementRenderer} from '../Component/RouteElementRenderer';
import {Routing} from '../Component/createReactRoutes';

interface RoutesRegistry {
    configuredRoutes: Partial<Record<keyof RoutesDTO, RoutesConfig<unknown>>>;
    addRoutes<
        TRoutesKey extends keyof RoutesDTO,
        TRoutes extends RoutesDTO[TRoutesKey],
        TConfigs extends RoutesConfig<TRoutes>
    >(
        routesKey: TRoutesKey,
        configs: TConfigs
    ): void;
}

const RoutesRegistryContent = createContext<RoutesRegistry | null>(null);

export const RoutesRegistryProvider: FC<PropsWithChildren> = ({children}) => {
    const ref = useRef<RoutesRegistry>();
    const [value, setValue] = useState<RoutesRegistry | null>(ref.current ?? null);

    if (!ref.current) {
        ref.current = {
            configuredRoutes: {},
            addRoutes(routesKey, configs) {
                const state = ref.current;
                if (!state) {
                    throw new Error('Should be initialized');
                }
                state.configuredRoutes[routesKey] = configs;
                // We need to update the value to trigger rerender,
                // so we spread the current value to trigger a new reference.
                setValue({
                    ...state,
                    configuredRoutes: {
                        ...state.configuredRoutes,
                    },
                });
            },
        };
    }
    const content = useDynamicContent({
        position: DynamicPosition.root,
    });

    return (
        <RoutesRegistryContent.Provider value={value ?? ref.current}>
            {children}
            <Routing />
            {content}
        </RoutesRegistryContent.Provider>
    );
};

/**
 * Create two Routes objects.
 *
 * 1. Main routes like overview pages
 * 2. Popups routes
 *
 * All routes will be added to both Routes objects.
 *
 * - The popup routes add SetBackgroundRoute component to the main routes
 *   This will replace the current url with the query parameter background route.
 */
export const useRoutesRegistry = () => {
    const context = React.useContext(RoutesRegistryContent);
    if (!context) {
        throw new Error('Should be used inside RoutesRegistryProvider');
    }
    const {configuredRoutes, addRoutes} = context;
    const routes = useRoutes();
    return useMemo(() => {
        const main: ReactNode[] = [];
        const secondary: ReactNode[] = [];

        for (const [routesKey, configs] of Object.entries(configuredRoutes)) {
            const entries = Object.entries(configs) as Array<[string, RouteConfig<never, never, never>]>;

            for (const [routeKey, {Element, defaultBackgroundRoute}] of entries) {
                // @ts-expect-error should fix type hint.
                const route = routes[routesKey][routeKey];
                const key = `${routesKey}.${routeKey.toString()}`;
                if (typeof route !== 'string') {
                    throw new Error(`Missing ${key} from routes config`);
                }
                if (defaultBackgroundRoute) {
                    // @ts-expect-error should fix type hint.
                    const backupRoute = routes[routesKey][defaultBackgroundRoute];
                    if (typeof backupRoute !== 'string') {
                        throw new Error(`Should be existing background route '${defaultBackgroundRoute}'`);
                    }
                    main.push(
                        <Route
                            key={`${key}main`}
                            path={route}
                            element={<SetBackgroundRoute defaultBackgroundRoute={backupRoute} />}
                        />
                    );
                    secondary.push(
                        <Route
                            key={`${key}secondary`}
                            path={route}
                            element={<RouteElementRenderer Element={Element} />}
                        />
                    );
                } else {
                    main.push(
                        <Route key={`${key}main`} path={route} element={<RouteElementRenderer Element={Element} />} />
                    );
                    secondary.push(<Route key={`${key}secondary`} path={route} element={null} />);
                }
            }
        }
        return {main, secondary, addRoutes};
    }, [configuredRoutes, routes, addRoutes]);
};
