import {assertIdFromIri, CreateRouteFunction, createRoutes, PageFromMenu, RouteElementProps} from '@growthbase/routing';
import {generatePath} from 'react-router-dom';
import {assertClientboxIdentifier} from '@growthbase/clientbox';
import {ReactNode} from 'react';
import {Overview} from './Overview/Overview';

const generateParams: CreateRouteFunction<{
    abonnementId: string;
    abonnementnummer: string;
    abonnementUuid: string;
}> = (route: string, params): string => {
    if (!params) {
        throw new Error('Missing params');
    }
    const {abonnementId, abonnementUuid, abonnementnummer} = params;
    const clientboxId = assertClientboxIdentifier(abonnementId);
    return generatePath(route, {
        abonnementnummer,
        id: clientboxId,
        abonnementId,
        abonnementUuid: assertIdFromIri(abonnementUuid),
        name: abonnementnummer,
    });
};

const generateBordtemplateParams: CreateRouteFunction<{
    abonnementUuid: string;
    bordtemplateId: string;
}> = (route: string, params) => {
    if (!params) {
        throw new Error('Missing params');
    }
    const {abonnementUuid, bordtemplateId} = params;
    return generatePath(route, {
        abonnementUuid: assertIdFromIri(abonnementUuid),
        bordtemplateId: assertIdFromIri(bordtemplateId),
    });
};

export const AbonnementTemplateRoutes = createRoutes('abonnementtemplates', {
    overview: {
        Element: ({params}: RouteElementProps<{bordtemplateId: string; abonnementUuid: string}>): ReactNode => (
            <PageFromMenu sidebar="preserve">
                <Overview abonnementId={params?.abonnementUuid ?? ''} bordtemplateId={params?.bordtemplateId} />
            </PageFromMenu>
        ),
        createRoute: generateParams,
    },
    view: {
        Element: ({params}: RouteElementProps<{bordtemplateId: string; abonnementUuid: string}>): ReactNode => (
            <PageFromMenu sidebar="preserve" forceId="abonnementtemplates_overview">
                <Overview abonnementId={params?.abonnementUuid ?? ''} bordtemplateId={params?.bordtemplateId} />
            </PageFromMenu>
        ),
        createRoute: generateBordtemplateParams,
    },
});
