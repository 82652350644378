import {useExternalLink} from '@growthbase/clientbox';
import {MentionElementProps, Value} from '@udecode/plate';
import {useMemo} from 'react';
import {useSelected} from 'slate-react';
import classNames from 'classnames/bind';
import {IKoppelbaarType} from '@growthbase/graphql';
import styles from './OnderdeelVanMention.module.scss';
import {resolveIKoppelbaarLinkType} from '../../resolveIKoppelbaarLinkType';

export interface OnderdeelVan {
    item: {
        id: string;
        label: string;
        externalId?: string;
        type: IKoppelbaarType;
    };
}

const cx = classNames.bind(styles);

export const OnderdeelVanMention = (props: MentionElementProps<Value> & {element: OnderdeelVan}) => {
    const {
        attributes,
        children,
        element: {item: onderdeelVan, value},
        prefix,
    } = props;
    const {label} = onderdeelVan;
    const link = useExternalLink(
        useMemo(
            () => ({
                linkType: resolveIKoppelbaarLinkType(onderdeelVan.type),
                externalId: onderdeelVan.externalId,
                label: onderdeelVan.label,
                id: onderdeelVan.id,
            }),
            [onderdeelVan]
        )
    );

    const selected = useSelected();

    const classes = cx('onderdeelVanMention', {
        'onderdeelVanMention--selected': selected,
    });

    if (link) {
        return (
            <a
                {...attributes}
                rel="noopener noreferrer"
                target="_blank"
                data-slate-value={value}
                className={classes}
                contentEditable={false}
                href={link}
            >
                {prefix}
                {label}
                {children}
            </a>
        );
    }
    return (
        <span {...attributes} data-slate-value={value} className={styles.OnderdeelVanMention} contentEditable={false}>
            {prefix}
            {label}
            {children}
        </span>
    );
};
