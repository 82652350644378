import axios, {AxiosRequestConfig} from 'axios';
import {AxiosHttpClient} from './AxiosHttpClient';
import {HttpClientInterface} from './HttpClientInterface';

export interface HttpServices {
    httpClient: HttpClientInterface;
}

export const createHttpServices = (baseUrl?: string): HttpServices => {
    const config: AxiosRequestConfig = {
        withCredentials: true,
    };
    if (typeof baseUrl === 'string') {
        config.baseURL = baseUrl;
    }
    return {
        httpClient: new AxiosHttpClient(axios.create(config)),
    };
};
