import {
    ILijstVerplaatsFormFragment,
    resolveConnections,
    useLijstVerplaatsFormBordenLazyQuery,
    useLijstVerplaatsFormBordLazyQuery,
    useLijstVerplaatsMutation,
} from '@growthbase/graphql';
import {ReactNode, useCallback, useMemo} from 'react';
import {useCallbackRef} from '@growthbase/spa';
import {defer} from 'rxjs';
import {idFromIri} from '@growthbase/routing';
import {PopupAnchorProps, ViewConfig} from '@growthbase/design-components';
import {useNormalizeBordOnderdeelVanFragment} from '@growthbase/takenlijst/bord';
import {find} from 'lodash';
import {VerplaatsenForm} from './VerplaatsenForm';
import {Lijst, Opties} from './Hook';

export interface VerplaatsenFormConnectedProps<RT extends Element = Element> extends ViewConfig {
    fragment: ILijstVerplaatsFormFragment;
    renderAnchor: (props: PopupAnchorProps<RT>) => ReactNode;
}

export const VerplaatsenFormConnected = ({
    fragment,
    disabled,
    readonly,
    renderAnchor,
}: VerplaatsenFormConnectedProps) => {
    const lijst = useMemo((): Lijst => ({id: fragment.id, titel: fragment.titel}), [fragment]);
    const [fetchBord] = useLijstVerplaatsFormBordLazyQuery();
    const [fetchBorden] = useLijstVerplaatsFormBordenLazyQuery();

    const [verplaats] = useLijstVerplaatsMutation();
    const onderdeelVan = useNormalizeBordOnderdeelVanFragment(fragment.bord);
    const currentBord = fragment.bord;
    if (!currentBord) {
        throw new Error('Fragment has no bord');
    }
    const currentRelatie = null; // useNormalizeRelatieValues(fragment.relatie?.relatieverwijzing);

    return (
        <VerplaatsenForm
            readonly={readonly}
            disabled={disabled}
            renderAnchor={renderAnchor}
            lijst={lijst}
            onVerplaats={useCallbackRef((dto) =>
                verplaats({
                    variables: {
                        input: {
                            lijstId: idFromIri(lijst.id),
                            id: idFromIri(dto.bord),
                            vorigeBordId: idFromIri(currentBord.id),
                            lijstpositie: {
                                bovenLijstId: dto.bovenLijst,
                                onderLijstId: dto.onderLijst,
                            },
                        },
                    },
                }).then()
            )}
            createInitialValues={useCallback(
                () => ({
                    bord: currentBord,
                    relatie: currentRelatie,
                    onderdeelVan,
                }),
                [currentBord, onderdeelVan, currentRelatie]
            )}
            fetchOptions={useCallbackRef(
                ({bord, onderdeelChanged, positie, bordQuery, onderdeelVan: onderdeelVanFilter, relatie}) =>
                    defer(async (): Promise<Opties> => {
                        const [bordenResult, selectedBordResult] = await Promise.all([
                            fetchBorden({
                                variables: {
                                    onderdeelVanId: onderdeelVanFilter ? onderdeelVanFilter.id : null,
                                    onderdeelVanType: onderdeelVanFilter ? onderdeelVanFilter.type : null,
                                    query: bordQuery || undefined,
                                },
                            }),
                            bord
                                ? fetchBord({
                                      variables: {
                                          bordId: bord,
                                          id: bord,
                                      },
                                      fetchPolicy: 'cache-first',
                                  })
                                : Promise.resolve(null),
                        ]);
                        const borden = resolveConnections(bordenResult.data?.TakenBorden);

                        let selectedBord = selectedBordResult?.data?.takenBord;
                        if (
                            selectedBord &&
                            onderdeelVanFilter &&
                            (selectedBord?.onderdeelVan?.id !== onderdeelVanFilter.id ||
                                selectedBord?.onderdeelVan?.itemType !== onderdeelVanFilter.type)
                        ) {
                            selectedBord = null;
                        }

                        if (selectedBord && !find(borden, {id: selectedBord.id})) {
                            borden.push(selectedBord);
                        }

                        let foundBord = borden.find((b) => b.id === bord) ?? null;
                        const emptyOpties: Opties = {
                            bord: null,
                            borden,
                            lijsten: [],
                            onderdeelVan: onderdeelVanFilter,
                            relatie,
                        };
                        // If the user selects a different onderdeel we find the first inbox of the new onderdeel
                        if (onderdeelChanged && !foundBord) {
                            foundBord = borden.find((b) => b.inbox?.isInbox) ?? null;
                        }
                        if (foundBord === null) {
                            return emptyOpties;
                        }
                        const bordId = idFromIri(foundBord.id);
                        const bordResult = await fetchBord({
                            variables: {
                                bordId,
                                id: bordId,
                            },
                            fetchPolicy: 'cache-first',
                        });
                        const lijsten = resolveConnections(bordResult.data?.TakenLijsten);
                        return {
                            bord: foundBord,
                            borden,
                            lijsten,
                            onderdeelVan: onderdeelVanFilter,
                            relatie,
                        };
                    })
            )}
        />
    );
};
