import {ApolloError} from '@apollo/client';
import {
    DraggableTable,
    DraggedRow,
    noAnchorDraggableTableRow,
    NodeWrapper,
    RenderDraggableTableProps,
    Table,
    TableHeader,
    TableHeaderSection,
    TableTitleWithCount,
} from '@growthbase/design-components';
import {ITaakTabelFragment} from '@growthbase/graphql';
import {
    ActiveItemSource,
    ActiveItemType,
    ConvertActiveItemSourceContainer,
    DNDContainerUserData,
    onDropItemHandler,
    onRemoveItemHandler,
    RenderDraggableItemProps,
} from '@growthbase/keys';
import {PaginationHelpers} from '@growthbase/spa';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {MaakTaakAanTaakFormDTO} from '../TaakCreateForm';
import {useAddTaakFooterForm, useTaakColumns} from './Hook';
import {RenderTaakTabelProps} from './RenderTaakTabelType';
import {TableHeaderSubtitle} from '../../../design/components/src/Table/TableHeader/components/TableHeaderSubtitle';

/**
 * Both the TaakTabel and TaakKaarten use the same drag container data.
 *
 * TODO: move this type to generic place?
 */
export type TaakDragType = 'Taak';
export type TaakDragContainerData = DNDContainerUserData;

export interface TaakTabelDragProps<TRow extends ITaakTabelFragment, TContainer extends TaakDragContainerData> {
    onDrop?: onDropItemHandler<TRow, TContainer>;
    onRemove?: onRemoveItemHandler<TRow>;
    userData: TContainer;
}

const userData = {
    id: 'AlleTakenOverzicht',
};

export interface TaakTabelProps<
    TRow extends ITaakTabelFragment = ITaakTabelFragment,
    TContainer extends TaakDragContainerData = TaakDragContainerData
> extends RenderTaakTabelProps {
    error?: ApolloError;
    pagination: PaginationHelpers<TRow>;
    onSubmit: (values: MaakTaakAanTaakFormDTO) => Promise<void>;
    /**
     * Always needed.
     */
    dragProps?: TaakTabelDragProps<TRow, TContainer>;
    bodyWrapper?: NodeWrapper;
}

const Convert = [
    {
        from: ActiveItemSource.TableRow,
        to: ActiveItemSource.TaakTableRow,
        type: ActiveItemType.Taak,
    },
];

export function TaakTabel<
    TRow extends ITaakTabelFragment = ITaakTabelFragment,
    TContainer extends TaakDragContainerData = TaakDragContainerData
>({
    pagination,
    onSubmit,
    error,
    header,
    title,
    subtitle,
    filters,
    showTaskDetails,
    dragProps,
    enableAdd,
    bodyWrapper,
}: TaakTabelProps<TRow, TContainer>) {
    const {t} = useTranslation('spa_form.nl');

    const {columns, render, Footer, formProps, Form} = useAddTaakFooterForm({
        columns: useTaakColumns({showTaskDetails}),
        onSubmit,
    });
    const renderTable = (tableProps: RenderDraggableTableProps<TRow>) => (
        <Table<TRow>
            testId={tableProps.testId ?? t('taaktabel.titel')}
            error={error}
            filters={filters}
            emptyMessage={t('taaktabel.leeg')}
            header={
                header ?? (
                    <TableHeader>
                        <TableHeaderSection>
                            <div>
                                <TableTitleWithCount
                                    titel={title ?? t('taaktabel.titel')}
                                    count={
                                        !pagination.isLoading || pagination.totalCount !== 0
                                            ? pagination.totalCount
                                            : undefined
                                    }
                                />
                                {subtitle && <TableHeaderSubtitle>{subtitle}</TableHeaderSubtitle>}
                            </div>
                        </TableHeaderSection>
                    </TableHeader>
                )
            }
            bodyWrapper={bodyWrapper}
            // @ts-expect-error Columns Does not respect the Trow type
            columns={columns}
            // @ts-expect-error Footer Does not respect the Trow type
            Footer={enableAdd ? Footer : undefined}
            {...tableProps}
        />
    );
    const renderDraggedItem = useCallback(
        (props: RenderDraggableItemProps<TaakDragType, TRow>) => <DraggedRow {...props} columns={columns} />,
        [columns]
    );

    return render(
        <ConvertActiveItemSourceContainer name="TaakTabel" convert={Convert}>
            <Form {...formProps}>
                <DraggableTable<TaakDragType, TRow, TContainer>
                    type="Taak"
                    renderRow={!dragProps ? noAnchorDraggableTableRow : undefined}
                    renderDraggedItem={renderDraggedItem}
                    pagination={pagination}
                    userData={userData as TContainer}
                    {...dragProps}
                >
                    {renderTable}
                </DraggableTable>
            </Form>
        </ConvertActiveItemSourceContainer>
    );
}
