import {useCallback} from 'react';
import {useActiveItem, ActiveItemData, SetItemActiveApi} from '../../Active';
import {useKeys} from '../../Keys';

export interface ListKeyTraverserOptions<T extends ActiveItemData = ActiveItemData> extends SetItemActiveApi<T> {
    items: T[];
    direction?: 'horizontal' | 'vertical';
}

export function useTraversableList<T extends ActiveItemData = ActiveItemData>({
    type,
    activate,
    source,
    items,
    direction = 'vertical',
}: ListKeyTraverserOptions<T>) {
    const activeItem = useActiveItem({
        source,
        type,
    });
    useKeys<HTMLElement>(
        'TraversableList: traverse items',
        direction === 'vertical' ? 'up,down' : 'left,right',
        (keyboardEvent, hotsEvent) => {
            const index = items.findIndex((item) => item.id === activeItem?.data.id);
            if (index === -1) {
                return;
            }
            const goingToEnd = ['down', 'right'].includes(hotsEvent.key);
            const goingToStart = ['up', 'left'].includes(hotsEvent.key);
            if (goingToEnd) {
                if (index + 1 < items.length) {
                    activate(items[index + 1]);
                }
            }
            if (goingToStart) {
                if (index - 1 >= 0) {
                    activate(items[index - 1]);
                }
            }
        },
        {
            onKeyDown: true,
            onKeyUp: false,
            enabled: items.length > 0,
            enableOnInput: true,
            filter: useCallback(() => !!activeItem?.data.id, [activeItem]),
        }
    );
    return activeItem;
}
