import React, {FC, ReactNode} from 'react';
import {ActionsMenuButton, PageActionsMenu} from '@growthbase/design-components';
import {BewerkButton} from '../../../Menu/Components';

export interface ActionsBarProps {
    lijsttemplateID: string;
    children?: ReactNode;
}

export const ActionsBar: FC<ActionsBarProps> = ({lijsttemplateID, children}) => (
    <PageActionsMenu>
        <BewerkButton id={lijsttemplateID} Button={ActionsMenuButton} />
        {children}
    </PageActionsMenu>
);
