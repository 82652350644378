import {faker} from '@faker-js/faker';
import {EventType} from '@growthbase/planning';
import moment from 'moment/moment';

export const generateEventsForView = (date: undefined | Date | string = new Date()) => {
    if (!date) {
        return {
            resources: [],
            events: [],
        };
    }

    faker.seed(moment(date).dayOfYear());

    const resources = [
        {
            id: 'agenda',
            name: 'agenda',
        },
    ];

    // function to create number between 0 and 8 based on de day
    const createNumber = (day: number) => Math.floor(day % 3);

    const events: EventType[] = [];
    const firstDay = moment(date);
    for (let day = 0; day < 31; day += 1) {
        let lastEnd = null;
        for (let i = 0; i < createNumber(day); i += 1) {
            const startHour: number = lastEnd ?? faker.number.float({min: 8.5, max: 10, precision: 0.25});
            const endHour: number = startHour + faker.number.float({min: 1, max: 3, precision: 0.25});
            lastEnd = endHour;

            events.push({
                id: `${day}${i}`,
                title: faker.lorem.sentence(),
                start: firstDay
                    .clone()
                    .add(day, 'days')
                    .add(startHour * 60, 'minutes')
                    .toDate(),
                end: firstDay
                    .clone()
                    .add(day, 'days')
                    .add(endHour * 60, 'minutes')
                    .toDate(),
                color: faker.color.rgb(),
                resource: 'agenda',
                editable: true,
                resize: true,
                isCreatingTimeRegistration: i % 3 === 0,
                hasTimeRegistration: (i + 1) % 3 === 0,
                dragBetweenResources: true,
                opdracht: {
                    id: `opdracht${day}${i}`,
                    label: 'Support 2024-Q1',
                    nummer: 'O2022050',
                },
                ticket: {
                    id: `ticket${day}${i}`,
                    label: 'Bug bij opslaan opdracht',
                    nummer: 'T2022050',
                },
                relatie: {
                    id: `relatie${day}${i}`,
                    label: faker.company.name(),
                },
                taak: null,
            });
        }
    }

    events.push({
        id: `multiday`,
        title: faker.lorem.sentence(),
        start: firstDay.clone().add(0, 'days').hours(8).minutes(0).toDate(),
        end: firstDay.clone().add(2, 'days').hours(9).minutes(0).toDate(),
        color: faker.color.rgb(),
        resource: 'agenda',
        editable: true,
        resize: true,
        dragBetweenResources: true,
        taak: null,
    });

    return {
        resources,
        events,
    };
};
