import './Select2';
import './MatchesText';
import './ThrowWhenEmpty';

export * from './Logger';

const globalWindow = window as unknown as {
    ms?: () => number;
};

console.log(`${globalWindow.ms?.()}:`, 'Loading utils..');
