import {TFunction} from 'i18next';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {SchemaOf} from 'yup';

export const ClientMutationIdSchema = (t: TFunction) => yup.string().notRequired() as SchemaOf<string | undefined>;

export const useClientMutationIdSchema = (): SchemaOf<string | undefined> => {
    const {t} = useTranslation();
    return useMemo(() => ClientMutationIdSchema(t), [t]);
};
