import React, {ReactNode, useContext, useMemo, useState} from 'react';
import {useCallbackRef} from '@growthbase/spa';
import {PopupsContext} from './PopupsContext';

export const PopupsContextProvider = ({children}: {children: ReactNode}) => {
    // Pass to parent
    const parentProvider = useContext(PopupsContext);

    const [openPopups, setOpenPopups] = useState<string[]>([]);

    const setPopupOpened = useCallbackRef((popupId: string, isOpenen: boolean) => {
        if (parentProvider) {
            const {setPopupOpened: setPopupOpenedParent} = parentProvider;
            setPopupOpenedParent(popupId, isOpenen);
        }

        setOpenPopups((prevState) => {
            if (!prevState.includes(popupId) && isOpenen) {
                return [...prevState, popupId];
            }
            if (prevState.includes(popupId) && !isOpenen) {
                const index = prevState.indexOf(popupId);
                prevState.splice(index, 1);
                return [...prevState];
            }

            return prevState;
        });
    });

    const context: PopupsContext = useMemo(
        () => ({
            openPopups,
            setPopupOpened,
            hasOpen: openPopups.length !== 0,
        }),
        [openPopups, setPopupOpened]
    );

    return <PopupsContext.Provider value={context}>{children}</PopupsContext.Provider>;
};
