import {useTranslation} from 'react-i18next';

export const NotFound = () => {
    const {t} = useTranslation('spa.nl');
    return (
        <div className="messages">
            <div className="message message--noClose message--error message--persistent">
                <div>{t('not_found')}</div>
            </div>
        </div>
    );
};
