import React from 'react';
import {Information, Spacer, Stack} from '@growthbase/design-components';
import {InstellingInputProps, InstellingInputWrapper} from './InstellingInputWrapper';
import {NestedValue} from '../../../types';

export const NestedContainerInstellingInput = (props: InstellingInputProps) => {
    const {fields, label, resolveComponent, id, depth} = props as InstellingInputProps<NestedValue>;
    return (
        <Stack>
            <Spacer />
            <Information>{label}</Information>
            <Spacer />
            {fields.map((name) => (
                <InstellingInputWrapper
                    depth={depth}
                    key={name}
                    resolveComponent={resolveComponent}
                    id={id}
                    name={name}
                />
            ))}
        </Stack>
    );
};
