import {Column} from 'react-table';
import {Table} from '@growthbase/design-components';
import {PaginationHelpers} from '@growthbase/spa';
import {FC, ReactNode} from 'react';

export interface LogsTableDTO {
    id: string;
    info: ReactNode;
    context: ReactNode;
    message: ReactNode;
}

const columns: ReadonlyArray<Column<LogsTableDTO>> = [
    {
        Header: '#',
        accessor: 'info',
        width: 18,
    },
    {
        Header: 'Van',
        accessor: 'context',
        width: 30,
    },
    {
        Header: 'Bericht',
        accessor: 'message',
    },
];

export interface LogsTableProps {
    pagination: PaginationHelpers<LogsTableDTO>;
}

export const LogsTable: FC<LogsTableProps> = ({pagination}) => (
    <Table<LogsTableDTO> pagination={pagination} columns={columns} />
);
