import {ReactNode} from 'react';
import styles from './TableHeaderSubtitle.module.scss';

export interface TableHeaderSubtitleProps {
    children: ReactNode;
}

export const TableHeaderSubtitle = ({children}: TableHeaderSubtitleProps) => (
    <div className={styles.tableHeaderSubtitle}>{children}</div>
);
