import {useContext} from 'react';
import {PopupsContext} from './PopupsContext';

export const usePopupsContext = (): PopupsContext => {
    const context = useContext(PopupsContext);

    if (!context) {
        return {
            setPopupOpened: (popupId: string) => undefined,
            openPopups: [],
            hasOpen: false,
        };
    }

    return context;
};
