import {useState} from 'react';
import {Line, Padding, Scrollable, SearchForm, Stack} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';
import {Opdracht} from '@growthbase/opdrachten-planning';
import {Statusfilter} from '../../Components/StatusFilter/StatusFilter';
import {OpdrachtSelectie} from '../../Components/OpdrachtSelectie';

// eslint-disable-next-line no-shadow
export enum Opdrachtstatus {
    Alle = 'alle',
    Nieuw = 'nieuw',
    InBehandeling = 'in_behandeling',
    Afgerond = 'afgerond',
}

export interface OpdrachtenTabProps {
    loading?: boolean;

    searchQuery?: string;
    onSearchQueryChange?: (query?: string) => void;

    opdrachten: Opdracht[];
    onKeuzeAangepast: (keuze?: {opdrachtId: string; dienstId?: string}) => void;

    activeOpdrachtStatus?: Opdrachtstatus;
    onChangeOpdrachtStatus: (status?: Opdrachtstatus) => void;
}

export const OpdrachtenTab = ({
    opdrachten,
    onKeuzeAangepast,
    onChangeOpdrachtStatus,
    activeOpdrachtStatus,
    onSearchQueryChange,
    searchQuery,
    loading,
}: OpdrachtenTabProps) => {
    const [activeKeuze, setActiveKeuze] = useState<{
        opdrachtId: string;
        dienstId?: string;
    }>();
    const {t} = useTranslation('spa.nl');

    return (
        <Stack data-test="planning-opdrachten-tab">
            <Stack.Item>
                <SearchForm
                    big
                    noBorders
                    loading={!!loading}
                    value={{query: searchQuery ?? ''}}
                    onChange={(searchInput) => {
                        if (onSearchQueryChange) {
                            onSearchQueryChange(searchInput.query ?? '');
                        }
                    }}
                />
            </Stack.Item>
            <Stack.Item grow>
                <Line />
                {opdrachten.length > 0 && (
                    <Scrollable fillParent>
                        <Padding paddingSize="medium">
                            <OpdrachtSelectie
                                opdrachten={opdrachten}
                                keuzeAangepast={(keuze) => {
                                    setActiveKeuze(keuze);
                                    onKeuzeAangepast(keuze);
                                }}
                                activeOpdrachtId={activeKeuze?.opdrachtId}
                            />
                        </Padding>
                    </Scrollable>
                )}
                {opdrachten.length === 0 && !loading && (
                    <Padding paddingSize="medium">{t('planning.opdracht.geenOpdrachtenGevonden')}</Padding>
                )}
            </Stack.Item>
            <Stack.Item>
                <Line />
                <Padding paddingSize="medium">
                    <Stack gap="small">
                        <Statusfilter onChange={onChangeOpdrachtStatus} value={activeOpdrachtStatus} />
                    </Stack>
                </Padding>
            </Stack.Item>
        </Stack>
    );
};
