const render = (seconds: number): string => {
    const hours = Math.floor(seconds / 60 / 60);
    const minutes = Math.round((seconds - hours * 60 * 60) / 60);

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
};

export const useRenderTime =
    () =>
    (seconds: number): string =>
        seconds < 0 ? `-${render(Math.abs(seconds))}` : render(seconds);
