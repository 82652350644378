import {
    BaseButtonProps,
    Buttons,
    Flex,
    Stack,
    PositionType,
    SwimlaneCard,
    SwimlaneCardBody,
    SwimlaneCardFooter,
} from '@growthbase/design-components';
import {FC, forwardRef, HTMLAttributes, memo, ReactNode} from 'react';

interface renderPlanningProps {
    AddButton?: FC<BaseButtonProps>;
    ModifyButton?: FC<BaseButtonProps>;
}

export interface KaartProps {
    titel: ReactNode;
    status: ReactNode;
    reacties: ReactNode;
    deelnemers: ReactNode;
    relatie: ReactNode;
    bijlages: ReactNode;
    omschrijving: ReactNode;
    uren: ReactNode;

    menu: ReactNode;

    renderPlanning: ({AddButton, ModifyButton}: renderPlanningProps) => ReactNode;

    anchor: HTMLAttributes<HTMLElement> | undefined;

    dragging?: boolean;
    hoover?: boolean;

    hasDeelnemers: boolean;
    hasOmchrijving: boolean;
    hasBijlages: boolean;
    hasPlanning: boolean;
    hasReacties: boolean;
    hasRelatie: boolean;
    hasActiveUrenBoeking: boolean;
}

const PlanningModifyButton = forwardRef((props, ref) => (
    <Buttons.CalendarPlusIconDottedSmall {...props} active ref={ref} />
));
PlanningModifyButton.displayName = 'PlanningModifyButton';

export const Kaart: FC<KaartProps> = memo(
    ({
        hasDeelnemers,
        hasPlanning,
        hasReacties,
        hasRelatie,
        hasBijlages,
        hasOmchrijving,
        bijlages,
        omschrijving,
        anchor,
        titel,
        deelnemers,
        menu,
        renderPlanning,
        relatie,
        reacties,
        hoover,
        dragging,
        uren,
        hasActiveUrenBoeking,
    }) => {
        const showPlaceholder = !hoover && dragging;

        return (
            <SwimlaneCard anchor={anchor} showPlaceholder={showPlaceholder}>
                <SwimlaneCardBody menu={!showPlaceholder && menu}>
                    <div>{titel}</div>
                    <div>{hasRelatie && relatie}</div>
                </SwimlaneCardBody>
                <SwimlaneCardFooter
                    showOnHoverCard={
                        !hasDeelnemers && !hasPlanning && !hasBijlages && !hasOmchrijving && !hasActiveUrenBoeking
                    }
                >
                    <Stack horizontal gap="small" align="center">
                        {Flex.createOrderedItems({start: {cellMinWidth: 22}}, ({add}) => {
                            add(
                                renderPlanning({
                                    AddButton: Buttons.CalendarPlusIconDottedSmall,
                                    ModifyButton: PlanningModifyButton,
                                }),
                                hasPlanning
                            );
                            add(relatie, hasRelatie ? PositionType.none : PositionType.showOnHooverEnd);
                            add(deelnemers, hasDeelnemers);
                            add(reacties, hasReacties ? PositionType.start : PositionType.none);
                            add(bijlages, hasBijlages ? PositionType.start : PositionType.none);
                            add(omschrijving, hasOmchrijving ? PositionType.start : PositionType.none);
                            add(uren, hasActiveUrenBoeking);
                        })}
                    </Stack>
                </SwimlaneCardFooter>
                <div style={{display: 'none'}}>
                    {!hasBijlages && bijlages}
                    {!hasOmchrijving && omschrijving}
                </div>
            </SwimlaneCard>
        );
    }
);

Kaart.displayName = 'Kaart';
