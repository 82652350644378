import {ExternalEntityWithLabel, ExternalLinkType} from '@growthbase/clientbox';
import {
    PopupControls,
    PopupControlsContext,
    useHandleClickState,
    useMemoryPopupControls,
} from '@growthbase/design-components';
import {IRelatieButtonGroupsFragment, IRelatieGroupsQuery, IRelatieType} from '@growthbase/graphql';
import {useCallbackRef} from '@growthbase/spa';
import React, {FC, memo, useCallback} from 'react';
import {RelatieButtonContent, RelatieButtonContentProps} from '../RelatieButtonContent';
import {RelatiePopupConnected} from '../RelatiePopup/RelatiePopupConnected';

export interface RelatieValue {
    persoon?: ExternalEntityWithLabel;
    bedrijf?: ExternalEntityWithLabel;
}

export const relatieIsEmpty = (value: RelatieValue | null | undefined): boolean => {
    if (!value) {
        return true;
    }
    const {persoon, bedrijf} = value;
    return !persoon && !bedrijf;
};

export type RelatieButtonItem = ExternalEntityWithLabel;

export interface RelatieButtonProps {
    testId?: string;
    requestData: (query: IRelatieGroupsQuery) => Promise<IRelatieButtonGroupsFragment>;
    open?: boolean;
    readonly?: boolean;
    disabled?: boolean;
    value?: RelatieValue | null;
    onChange: (change: RelatieValue) => Promise<void>;
    RelatiePopup: typeof RelatiePopupConnected;
    singleValue?: boolean;
    controls?: PopupControls;
    ContentElement?: FC<RelatieButtonContentProps>;
}

function RelatieButtonInner({
    RelatiePopup,
    value = {},
    open,
    requestData,
    readonly,
    testId,
    singleValue,
    controls: inputControls,
    ContentElement = RelatieButtonContent,
    ...props
}: RelatieButtonProps) {
    const {persoon, bedrijf} = value ?? {};

    const {onClick: onChange, disabled} = useHandleClickState<(change: RelatieValue) => Promise<void>>({
        ...props,
        onClick: props.onChange,
    });

    const fallbackControls = useMemoryPopupControls(open, false, false, 'relatie');
    const controls = inputControls ?? fallbackControls;

    const onPersoonRemove = useCallbackRef(() => onChange?.({bedrijf}));

    const onBedrijfRemove = useCallbackRef(() => onChange?.({persoon}));

    const onClickItem = useCallbackRef((item: RelatieButtonItem): Promise<void> => {
        if (!onChange) {
            return Promise.resolve().then(controls.close);
        }
        switch (item.linkType) {
            case ExternalLinkType.Persoon:
                return onChange({
                    bedrijf,
                    persoon: item,
                }).then(controls.close);
            case ExternalLinkType.Bedrijf:
                return onChange({
                    bedrijf: item,
                    persoon,
                }).then(controls.close);
            default:
                throw new Error('Unknown relation type');
        }
    });

    return (
        <PopupControlsContext.Provider value={controls}>
            <RelatiePopup
                testId={testId}
                bedrijf={bedrijf}
                persoon={persoon}
                disabled={disabled}
                requestData={requestData}
                onClickItem={useCallbackRef((item) =>
                    onClickItem({
                        ...item,
                        linkType:
                            item.type === IRelatieType.Persoon ? ExternalLinkType.Persoon : ExternalLinkType.Bedrijf,
                    })
                )}
                renderAnchor={useCallback(
                    ({open: doOpen, reference}) => (
                        <ContentElement
                            persoon={persoon}
                            bedrijf={bedrijf}
                            onPersoonRemove={onPersoonRemove}
                            onBedrijfRemove={onBedrijfRemove}
                            onPersoonEdit={() => doOpen(IRelatieType.Persoon)}
                            onBedrijfEdit={() => doOpen(IRelatieType.Bedrijf)}
                            onPersoonBedrijfEdit={() => doOpen()}
                            disabled={disabled}
                            readonly={readonly}
                            singleValue={singleValue}
                            reference={reference}
                        />
                    ),
                    [
                        ContentElement,
                        persoon,
                        bedrijf,
                        onPersoonRemove,
                        onBedrijfRemove,
                        disabled,
                        readonly,
                        singleValue,
                    ]
                )}
            />
        </PopupControlsContext.Provider>
    );
}

export const RelatieButton = memo(RelatieButtonInner);
