import {useCallbackRef, useOptionalCallback} from '@growthbase/spa';
import {forwardRef, FC} from 'react';
import {MenuItem} from '@szhsin/react-menu';
import {To, useNavigate, createPath} from 'react-router-dom';
import styles from './ActionsMenuButton.module.scss';
import {BaseButtonProps} from '../../../Button';

export type ActionsMenuButtonProps = BaseButtonProps & {to?: To};

export const ActionsMenuButton: FC<ActionsMenuButtonProps> = forwardRef(
    ({children, onClick, disabled, to, readonly, ariaLabel, active, ...props}, ref) => {
        const router = useNavigate();
        const route = useCallbackRef(() => {
            if (!to) {
                return;
            }
            if (typeof to === 'string') {
                router(to);
            } else {
                router(createPath(to));
            }
        });

        return (
            <MenuItem
                ref={ref}
                data-testid="actionsMenuButton"
                disabled={disabled}
                onClick={useOptionalCallback(onClick ?? route, true)}
                {...props}
                className={`${styles.actionsMenuButton} ${props.className}`}
            >
                {children}
            </MenuItem>
        );
    }
);

ActionsMenuButton.displayName = 'ActionsMenuButton';
