import {CSSProperties} from 'styled-components';
import {capitalize} from 'lodash';
import {Spacing} from '../Spacing';

export const Spacer = ({
    size = 'default',
    type = 'horizontal',
}: {
    size?: Spacing | number;
    type?: 'horizontal' | 'vertical' | 'square';
}) => {
    const sizeValue = typeof size === 'number' ? `${size}px` : `var(--spacing${capitalize(size)})`;
    const style: CSSProperties = {
        display: 'block',
        width: type === 'vertical' ? sizeValue : '100%',
        height: type === 'horizontal' ? sizeValue : '100%',
    };
    return <div style={style}>&nbsp;</div>;
};
