import {Autocomplete, TextField, useTheme} from '@mui/material';
import {useCallback, useMemo} from 'react';
import {AutocompleteRenderInputParams} from '@mui/material/Autocomplete/Autocomplete';
import * as React from 'react';
import {useCallbackRef} from '@growthbase/spa';
import {CBTheme} from '../../Utils';
import {MuiInputWrapper} from '../MuiInputWrapper/MuiInputWrapper';

// extend dev tool to global window object

declare global {
    interface Window {
        AutoCompleteControlled: Record<string, (valueLabel: string) => void>;
    }
}

export type AutoCompleteProps<ValueType extends string, OptionsType extends {label: string; value: ValueType}> = {
    label: React.ReactNode;
    options: OptionsType[];
    value: ValueType | null;
    placeholder?: string;
    required?: boolean;
    onSearchChange?: (value: string) => void;
    onChangeValue?: (value: ValueType | null) => void;
    search: string | null;
    loading?: boolean;
    disabled?: boolean;
    readonly?: boolean;
};

export function AutoCompleteControlled<
    ValueType extends string,
    OptionsType extends {
        label: string;
        value: ValueType;
    }
>({
    onSearchChange,
    options,
    search,
    label,
    value,
    onChangeValue,
    placeholder,
    required = false,
    loading = false,
    disabled = false,
    readonly = false,
    ...props
}: AutoCompleteProps<ValueType, OptionsType>) {
    const {options: themeOptions} = useTheme<CBTheme>();
    const {separateLabel} = themeOptions;

    const onSearchChangeCallback = useCallback(
        (e: unknown, changedValue: string) => {
            if (onSearchChange) {
                onSearchChange(changedValue);
            }
        },
        [onSearchChange]
    );

    const selectedOption = useMemo(
        () => options.find((option: OptionsType) => option.value === value),
        [options, value]
    );

    const onChangeCallback = useCallbackRef((event: unknown, changedValue: OptionsType | null) => {
        if (onChangeValue) {
            onChangeValue(changedValue?.value || null);
        }
    });

    const renderInput = useCallback(
        (params: AutocompleteRenderInputParams) => (
            <TextField
                label={!separateLabel ? label : null}
                {...params}
                placeholder={placeholder}
                inputProps={{
                    ...params.inputProps,
                    style: {
                        paddingTop: 0,
                        paddingBottom: 0,
                    },
                }}
            />
        ),
        [label, placeholder, separateLabel]
    );

    window.AutoCompleteControlled = window.AutoCompleteControlled || {};
    if (typeof label === 'string') {
        window.AutoCompleteControlled[label] = (valueOrLabel: string) => {
            const foundInOptions = options.find(
                (option) => option.value === valueOrLabel || option.label === valueOrLabel
            );
            if (!foundInOptions) {
                throw new Error(`Value or Label ${valueOrLabel} not found in options ${JSON.stringify(options)}`);
            }

            if (!onChangeValue) {
                throw new Error('onChangeValue is not defined');
            }

            onChangeValue(foundInOptions.value);
        };
    }

    if (required) {
        return (
            <MuiInputWrapper label={label} seperateLabel={separateLabel}>
                <Autocomplete<OptionsType, false, true>
                    {...props}
                    options={options}
                    onInputChange={onSearchChangeCallback}
                    disableClearable
                    value={selectedOption}
                    onChange={onChangeCallback}
                    renderInput={renderInput}
                    loading={loading}
                    disabled={disabled}
                    readOnly={readonly}
                />
            </MuiInputWrapper>
        );
    }

    return (
        <MuiInputWrapper seperateLabel={separateLabel} label={label}>
            <Autocomplete<OptionsType, false>
                {...props}
                options={options}
                onInputChange={onSearchChangeCallback}
                value={selectedOption || null}
                onChange={onChangeCallback}
                renderInput={renderInput}
                loading={loading}
                disabled={disabled}
                readOnly={readonly}
            />
        </MuiInputWrapper>
    );
}
