import {ReferenceType} from '@floating-ui/react-dom-interactions/src/types';
import {ExternalEntityWithLabel} from '@growthbase/clientbox';
import {usePopup} from '@growthbase/design-components';
import {
    IRelatieGroupsQuery,
    IRelatiePopupGroupRelatieFragment,
    IRelatiePopupGroupsFragment,
    IRelatieType,
} from '@growthbase/graphql';
import {useCallbackRef} from '@growthbase/spa';
import React, {ReactNode, useCallback, useMemo} from 'react';
import {useDebounce} from 'react-use';
import {useRelatiePopupReducer} from './Hook/useRelatiePopupReducer';
import {RelatiePopup} from './RelatiePopup';

export interface RelatiePopupAnchorProps<RT extends ReferenceType = ReferenceType> {
    reference: (node: RT | null) => void;
    open: (type?: IRelatieType) => void;
}

export interface RelatiePopupConnectedProps<T extends IRelatiePopupGroupsFragment = IRelatiePopupGroupsFragment> {
    testId?: string;
    persoon: ExternalEntityWithLabel | undefined;
    bedrijf: ExternalEntityWithLabel | undefined;
    disabled?: boolean;
    requestData: (query: IRelatieGroupsQuery) => Promise<T>;
    onClickItem: (item: IRelatiePopupGroupRelatieFragment) => Promise<void>;
    renderAnchor: (props: RelatiePopupAnchorProps) => ReactNode;
}

export function RelatiePopupConnected<T extends IRelatiePopupGroupsFragment = IRelatiePopupGroupsFragment>({
    disabled,
    testId,
    requestData,
    onClickItem,
    renderAnchor,
    persoon,
    bedrijf,
}: RelatiePopupConnectedProps<T>) {
    const {isOpen} = usePopup();
    const [{loading, groups, query}, dispatch] = useRelatiePopupReducer();

    const relatieverwijzing = useMemo(() => {
        if (!bedrijf?.id && !persoon?.id) {
            return null;
        }
        return {
            bedrijfId: bedrijf?.id,
            persoonId: persoon?.id,
        };
    }, [bedrijf?.id, persoon?.id]);

    useDebounce(
        () => {
            if (!isOpen) {
                return;
            }
            requestData(query)
                .then((data) => {
                    dispatch({
                        query: {
                            ...query,
                            relatieverwijzing,
                        },
                        type: 'results',
                        fragment: data,
                    });
                })
                .catch((error) => {
                    dispatch({
                        query: {
                            ...query,
                            relatieverwijzing,
                        },
                        type: 'errored',
                        error,
                    });
                });
        },
        400,
        [query.query, relatieverwijzing, isOpen]
    );

    return (
        <RelatiePopup
            testId={testId}
            persoon={persoon ?? null}
            bedrijf={bedrijf ?? null}
            changeType={query.changeType}
            disabled={disabled}
            onClickItem={onClickItem}
            anchor={useCallback(
                ({controls, reference}) =>
                    renderAnchor({
                        reference,
                        open: (changeType) => {
                            dispatch({
                                type: 'loading',
                                query: {
                                    relatieverwijzing,
                                    changeType,
                                },
                            });
                            controls.open();
                        },
                    }),
                [dispatch, relatieverwijzing, renderAnchor]
            )}
            loading={loading}
            query={query.query}
            groups={groups}
            onChangeQuery={useCallbackRef(({query: newQuery}) => {
                dispatch({
                    type: 'loading',
                    query: {
                        ...query,
                        query: newQuery,
                    },
                });
                return Promise.resolve();
            })}
        />
    );
}
