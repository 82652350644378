import {useFormikContext} from 'formik';
import {FocusEvent, FocusEventHandler, useCallback, useEffect} from 'react';
import {useBoolean} from 'react-use';
import {useActiveChangeDispatcher} from '../../../Hook';

export interface InputIsActiveField<T> {
    onFocus?: FocusEventHandler<T>;
    onBlur?: FocusEventHandler<T>;
    name: string;
}

export function useInputIsActive<V>(field: InputIsActiveField<V>): {
    field: InputIsActiveField<V>;
    isActive: boolean;
} {
    const {setFieldTouched} = useFormikContext();
    const onFocusPrevious = field.onFocus;
    const onBlurPrevious = field.onBlur;

    const [isActive, setIsActive] = useBoolean(false);
    const onActiveChange = useActiveChangeDispatcher(field.name);
    const onFocus: FocusEventHandler<V> = useCallback(
        (event) => {
            setIsActive(true);
            onActiveChange(true);
            if (onFocusPrevious) {
                onFocusPrevious(event);
            }
        },
        [onFocusPrevious, setIsActive, onActiveChange]
    );

    const onBlur = useCallback(
        (event: FocusEvent<V>) => {
            if (!isActive) {
                return;
            }
            onActiveChange(false);
            setIsActive(false);
            if (event && onBlurPrevious) {
                onBlurPrevious(event);
            } else {
                setFieldTouched(field.name, true);
            }
        },
        [
            isActive,
            field,
            setFieldTouched,
            onBlurPrevious,
            onActiveChange,
            setIsActive,
        ]
    );

    /**
     * Continuously say the field is active.
     */
    useEffect(() => {
        if (field && (field as {autoFocus?: boolean}).autoFocus) {
            setIsActive(true);
            onActiveChange(true);
        }
    }, [field, setIsActive, onActiveChange]);

    return {
        field: {
            ...field,
            onBlur,
            onFocus,
        },
        isActive,
    };
}
