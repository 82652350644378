import {DateRangeSchema, useDateRangeSchema} from '@growthbase/design-components';
import {TFunction} from 'i18next';
import {useTranslation} from 'react-i18next';

export const TaakPlanningSchema = (t: TFunction<'spa_form.nl'>) =>
    DateRangeSchema({
        fromLabel: t('taken.TaakPlanningButton.from') ?? '',
        toLabel: t('taken.TaakPlanningButton.to') ?? '',
        fromNotGreaterThanTo: t('taken.TaakPlanningButton.fromNotGreaterThanTo') ?? '',
    });

export const useTaakPlanningSchema = () => {
    const {t} = useTranslation('spa_form.nl');
    return useDateRangeSchema({
        fromLabel: t('taken.TaakPlanningButton.from') ?? '',
        toLabel: t('taken.TaakPlanningButton.to') ?? '',
        fromNotGreaterThanTo: t('taken.TaakPlanningButton.fromNotGreaterThanTo') ?? '',
    });
};
