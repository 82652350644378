import {RouteElementProps} from '@growthbase/routing';
import {AddBreadcrumb, Page} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';
import {RelationRoutes} from '@growthbase/relaties';
import {useTakenVanRelatiePaginaConnectedQuery} from '@growthbase/graphql';
import {FC} from 'react';
import {OverzichtConnected} from '../Overzicht';

interface BreadcrumbsProps {
    relatienaam: string;
    relatieId: string;
    slug: string;
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({relatienaam, relatieId, slug}) => {
    const {t} = useTranslation('spa.nl');
    const urlFactory = RelationRoutes.read.useUrlFactory();

    return (
        <>
            <AddBreadcrumb to={RelationRoutes.index.useUrl()}>{t('relaties.breadcrumb.index')}</AddBreadcrumb>
            <AddBreadcrumb to={urlFactory({relatieId, relatienaam: slug})}>{relatienaam}</AddBreadcrumb>
            <AddBreadcrumb to={false}>{t('relaties.breadcrumb.taken_van_relatie')}</AddBreadcrumb>
        </>
    );
};

export type TakenVanRelatiePaginaConnectedProps = RouteElementProps<{
    relatieId: string;
    relatieUuid: string;
    naam: string;
}>;

export const TakenVanRelatiePaginaConnected = ({params}: TakenVanRelatiePaginaConnectedProps) => {
    if (params === undefined) {
        throw new Error('Logic exception.');
    }
    const {t} = useTranslation('spa.nl');

    const result = useTakenVanRelatiePaginaConnectedQuery({
        variables: {
            id: params.relatieUuid,
        },
    });
    const naam = result.data?.bedrijf?.bedrijfsnaam || result.data?.persoon?.naam || '';

    if (result.loading) {
        return null;
    }

    return (
        <Page
            title={t('relaties.taken.taken_van_relatie_pagina_title', {
                relatienaam: naam,
            })}
            header={null}
        >
            <Breadcrumbs relatienaam={naam} relatieId={params.relatieId} slug={params.naam} />
            <OverzichtConnected relatie={params.relatieUuid} />
        </Page>
    );
};
