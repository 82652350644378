import {BlockMessage, LinkButton} from '@growthbase/design-components';
import {Trans, useTranslation} from 'react-i18next';
import React, {FC} from 'react';
import {BordtemplateRoutes} from '@growthbase/takentemplates-bordtemplate';

export interface GeenItemsAanwezigProps {
    bordtemplateId: string;
}

export const GeenItemsAanwezig: FC<GeenItemsAanwezigProps> = ({bordtemplateId}) => {
    const {t} = useTranslation('Bordtemplate.nl');
    return (
        <BlockMessage title={t('geenLijstAanwezigTekst')}>
            <Trans
                t={t}
                components={{
                    maakLijst: (
                        <LinkButton
                            onClick={BordtemplateRoutes.lijstaanmaken.useNavigate({
                                bordtemplateId,
                            })}
                            color="primary"
                        />
                    ),
                    voegToe: (
                        <LinkButton
                            onClick={BordtemplateRoutes.lijsttoevoegen.useNavigate({
                                bordtemplateId,
                            })}
                            color="primary"
                        />
                    ),
                }}
            >
                maakEenNieuwLijst
            </Trans>
        </BlockMessage>
    );
};
