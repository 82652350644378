import classNames from 'classnames/bind';
import {memo, FC, PropsWithChildren} from 'react';
import {isDisabledTab, isTabQuery, isTabRoute, TabInterface} from '../TabInterface';
import {QueryTabLink} from './QueryTabLink';
import {RouteTabLink} from './RouteTabLink';

import styles from '../Tabs.module.scss';

const cx = classNames.bind(styles);

interface TabButtonProps extends PropsWithChildren {
    tab: TabInterface;
    isActive: boolean;
    sizeSmall?: boolean;
}

export const TabButton: FC<TabButtonProps> = memo(({tab, isActive, sizeSmall, children}) => {
    const tabClasses = cx({
        tabs__tab: true,
        'tabs__tab--disabled': isDisabledTab(tab),
        'tabs__tab--active': isActive,
        'tabs__tab--secondaryActive': isTabQuery(tab),
        'tabs__tab--sizeSmall': sizeSmall,
    });

    const tabLinkClasses = cx({
        tabs__link: true,
        'tabs__link--active': isActive,
    });

    if (isTabRoute(tab)) {
        return (
            <RouteTabLink tab={tab}>
                <div className={tabClasses}>
                    <div className={tabLinkClasses}>
                        {tab.title}
                        {children}
                    </div>
                </div>
            </RouteTabLink>
        );
    }

    if (isTabQuery(tab)) {
        return (
            <QueryTabLink tab={tab}>
                <div className={tabClasses}>
                    <div className={tabLinkClasses}>
                        {tab.title}
                        {children}
                    </div>
                </div>
            </QueryTabLink>
        );
    }

    return (
        <div className={tabClasses}>
            <div className={tabLinkClasses}>
                {tab.title}
                {children}
            </div>
        </div>
    );
});

TabButton.displayName = 'Tab';
