import {
    IVerwijderLijstActionsMenuButtonFragment,
    useVerwijderLijstMutation,
    useVerwijderLijstQueryHasTakenQuery,
} from '@growthbase/graphql';
import {FC, ReactNode} from 'react';
import {idFromIri} from '@growthbase/routing';
import {VerwijderLijstActionsMenuButton} from './VerwijderLijstActionsMenuButton';

export interface VerwijderLijstActionsMenuButtonConnectedProps {
    fragment: IVerwijderLijstActionsMenuButtonFragment;
}

const BevatTaken: FC<{id: string; children: (bevatTaken: boolean) => ReactNode}> = ({children, id}) => {
    const {data} = useVerwijderLijstQueryHasTakenQuery({
        variables: {
            id: idFromIri(id),
        },
    });
    return <>{children((data?.Taken?.totalCount ?? 0) > 0)}</>;
};

export const VerwijderLijstActionsMenuButtonConnected: FC<VerwijderLijstActionsMenuButtonConnectedProps> = ({
    fragment: {
        titel,
        id,
        availableActions: {verwijderLijst},
    },
}) => {
    const [mutate] = useVerwijderLijstMutation({
        variables: {
            input: {
                id: idFromIri(id),
            },
        },
    });
    if (!verwijderLijst) {
        return null;
    }
    return (
        <VerwijderLijstActionsMenuButton
            lijstTitel={titel}
            onSubmit={mutate}
            renderBevatTaken={(render) => <BevatTaken id={id}>{render}</BevatTaken>}
        />
    );
};
