import {useTypedSearchParams} from '@growthbase/routing';
import {useMemo} from 'react';
import * as yup from 'yup';
import {SchemaOf} from 'yup';
import {useLocalStorage} from 'react-use';
import {useCallbackRef} from '@growthbase/spa';
import {IStandaardWeergaveBorden} from '@growthbase/graphql';
import {ViewType} from '../../../types';

interface Filters {
    overzicht: ViewType;
}

const Schema = (): SchemaOf<Filters> =>
    yup
        .object({
            overzicht: yup.mixed().oneOf(Object.keys(ViewType)).required(),
        })
        .required();

const useFilterSchema = (): SchemaOf<Filters> => useMemo(() => Schema(), []);

export interface SelectOptions {
    standaardWeergaveBorden: IStandaardWeergaveBorden;
    laatstGebruikteWeergaveBorden: boolean;
}

export const useViewSelectState = ({
    standaardWeergaveBorden,
    laatstGebruikteWeergaveBorden,
}: SelectOptions): [ViewType, (overzicht: ViewType) => void] => {
    let initialView = standaardWeergaveBorden === IStandaardWeergaveBorden.Tabel ? ViewType.Tabel : ViewType.Cards;
    const [lastVast, setLastView] = useLocalStorage<ViewType>(`last-view`, initialView);

    if (standaardWeergaveBorden === IStandaardWeergaveBorden.LaatstGebruiktWeergeven) {
        initialView = lastVast ?? initialView;
    }

    const [overzichtFromLocalStorage, setOverzichtFromLocalStorage] = useLocalStorage<ViewType>(
        `borden-view`,
        initialView
    );

    const [{overzicht}, setFilters] = useTypedSearchParams<Filters>(
        'view',
        useFilterSchema(),
        useMemo(
            (): Filters => ({
                overzicht: laatstGebruikteWeergaveBorden ? overzichtFromLocalStorage ?? initialView : initialView,
            }),
            [initialView, laatstGebruikteWeergaveBorden, overzichtFromLocalStorage]
        )
    );
    return [
        overzicht,
        useCallbackRef((newOverzicht: ViewType) => {
            setLastView(newOverzicht);
            setFilters({overzicht: newOverzicht});
            setOverzichtFromLocalStorage(newOverzicht);
        }),
    ];
};
