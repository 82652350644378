import {SchemaOf} from 'yup';
import * as yup from 'yup';

export interface DateOffsetConfigDTO {
    fromDate: string;
    toDate: string;
    offset: string;
    type: string;
}

export const DateOffsetConfigDTOSchema: SchemaOf<DateOffsetConfigDTO> = yup
    .object({
        fromDate: yup.string().required(),
        toDate: yup.string().required(),
        offset: yup.string().required(),
        type: yup.string().required(),
    })
    .label('DateOffsetConfigDTO')
    .required();
