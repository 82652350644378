import {ReactNode, useCallback, useRef} from 'react';
import {Datepicker} from '@mobiscroll/react';
import {useCallbackRef} from '@growthbase/spa';
import {Popup, PopupAnchorProps, PopupContent, usePopup} from '../../../Popups';

export interface DateSelectButtonProps {
    onClick: () => void;
    reference: PopupAnchorProps['reference'];
    open?: boolean;
}

export interface DateSelectProps {
    renderButton: (props: DateSelectButtonProps) => ReactNode;
    onDateChange?: (date: Date) => void;
    date?: Date;
}

export const DateSelect = ({renderButton, onDateChange, date}: DateSelectProps) => {
    const ref = useRef<HTMLDivElement>();
    const controls = usePopup();

    const onChangeCallback = useCallbackRef((args: {value: Date}) => onDateChange?.(args.value));

    const renderAnchor = useCallback(
        ({controls: anchorControls, reference}: PopupAnchorProps) =>
            renderButton({
                onClick: anchorControls.open,
                reference,
            }),
        [renderButton]
    );

    return (
        <div>
            <Popup renderAnchor={renderAnchor}>
                <PopupContent>
                    <Datepicker
                        defaultValue={date}
                        context={ref.current}
                        theme="ios"
                        themeVariant="light"
                        controls={['calendar']}
                        showWeekNumbers={false}
                        showOuterDays={false}
                        focusOnClose
                        onClose={controls.close}
                        onCellClick={controls.close}
                        closeOnEsc={false}
                        display="inline"
                        returnFormat="jsdate"
                        onChange={onChangeCallback}
                    />
                </PopupContent>
            </Popup>
        </div>
    );
};
