import {QueryResult} from '@apollo/client/react/types/types';
import {
    ChangeType,
    PositieType,
    QueryCacheUpdateType,
    useConvertFetchEntity,
    useUpdateQueryCacheFromExternalEntityChanges,
} from '@growthbase/cache';
import {
    ITaakReactiesQuery,
    ITaakReactiesQueryVariables,
    ITaakReactiesReactieFragment,
    refetchTaakReactiesReactieQuery,
} from '@growthbase/graphql';

import {useCallback} from 'react';

export interface TaakTabelEntityChangesParams {
    taakId: string;
    result: QueryResult<ITaakReactiesQuery, ITaakReactiesQueryVariables>;
}

export const useTaakReactiesEntityChanges = ({result, taakId}: TaakTabelEntityChangesParams) => {
    useUpdateQueryCacheFromExternalEntityChanges<ITaakReactiesReactieFragment>({
        // @ts-expect-error TODO: fix this
        result,
        iri: 'Taakreacties',
        positie: PositieType.before,
        determineAction: useCallback(
            (params) => {
                const {type, typename} = params;
                if (typename !== 'Taakreactie') {
                    return QueryCacheUpdateType.ignore;
                }
                switch (type) {
                    case ChangeType.add:
                        if (taakId !== params.node.taakId) {
                            return QueryCacheUpdateType.ignore;
                        }
                        return QueryCacheUpdateType.add;
                    case ChangeType.remove:
                        return QueryCacheUpdateType.remove;
                    case ChangeType.invalidate:
                        return QueryCacheUpdateType.refetchEntity;
                    default:
                        return QueryCacheUpdateType.ignore;
                }
            },
            [taakId]
        ),
        fetchEntity: useConvertFetchEntity(refetchTaakReactiesReactieQuery),
    });
};
