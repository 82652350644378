import {useTakenInstellingenPaginaQuery} from '@growthbase/graphql';
import {TakenInstellingenRoutes} from '@growthbase/taken-instellingen';
import {useEffect} from 'react';

// eslint-disable-next-line no-shadow
export enum InstellingenPages {
    index,
    algemeen,
    mijnvoorkeuren,
}

export const useRedirectIndex = () => {
    const {data, loading} = useTakenInstellingenPaginaQuery();

    const algemeen = TakenInstellingenRoutes.algemeen.useNavigate();
    const mijnVoorkeuren = TakenInstellingenRoutes.mijnvoorkeuren.useNavigate();

    useEffect(() => {
        if (loading) {
            return;
        }
        if (data?.ingelogdeGebruiker?.availableActions?.kanTakenAlgemeenInstellen) {
            algemeen();
            return;
        }
        mijnVoorkeuren();
    }, [algemeen, data, loading, mijnVoorkeuren]);
};
