import {BaseButtonProps, Button, Icons} from '@growthbase/design-components';
import {FC} from 'react';
import {IVoltooiOfActiveerTaakButtonFragment} from '@growthbase/graphql';
import {useTranslation} from 'react-i18next';

export interface VoltooiOfActiveerTaakButtonProps {
    SubmitButton?: FC<BaseButtonProps>;
    disabled?: boolean;
    loading?: boolean;
    activeer: () => void;
    voltooi: () => void;
    fragment?: IVoltooiOfActiveerTaakButtonFragment;
}

export const VoltooiOfActiveerTaakButton: FC<VoltooiOfActiveerTaakButtonProps> = ({
    SubmitButton = Button,
    activeer,
    voltooi,
    disabled,
    loading,
    fragment,
}) => {
    const {t} = useTranslation('spa_form.nl');
    if (!fragment) {
        return null;
    }

    const {
        voltooid,
        availableActions: {kanVoltooien, kanActiveren},
    } = fragment;

    if (voltooid) {
        return (
            <SubmitButton
                active
                size="medium"
                ariaLabel={t('taak.activeer')}
                Icon={Icons.Check}
                disabled={!kanActiveren || disabled}
                iconRight
                loading={loading}
                onClick={activeer}
            >
                {t('taak.activeer')}
            </SubmitButton>
        );
    }

    return (
        <SubmitButton
            size="medium"
            ariaLabel={t('taak.voltooi')}
            Icon={Icons.Check}
            disabled={!kanVoltooien || disabled}
            iconRight
            loading={loading}
            onClick={voltooi}
        >
            {t('taak.voltooi')}
        </SubmitButton>
    );
};
