import {useCreateFromMutation} from '@growthbase/graphql';
import React, {FC} from 'react';
import {RoutePopupProvider} from '@growthbase/design-components';
import {v4} from 'uuid';
import {useCallbackRef} from '@growthbase/spa';
import {useHistoryBack} from '@growthbase/routing';
import {CreateForm} from './CreateForm';
import {LijsttemplateRoutes} from '../LijsttemplateRoutes';

export interface CreateFormConnectedProps {
    bordtemplateId?: string;
    closeOnSubmit?: boolean;
}

export const CreateFormConnected: FC<CreateFormConnectedProps> = ({bordtemplateId, closeOnSubmit}) => {
    const [mutate] = useCreateFromMutation();
    const view = LijsttemplateRoutes.bekijken.useNavigateFactory();
    const goBack = useHistoryBack();
    return (
        <RoutePopupProvider>
            <CreateForm
                onSubmit={useCallbackRef(({naam}) => {
                    const id = v4();
                    return mutate({
                        variables: {
                            input: {
                                id,
                                naam,
                                bordtemplateId,
                            },
                        },
                    }).then(() =>
                        closeOnSubmit
                            ? goBack()
                            : view({
                                  lijsttemplateId: id,
                              })
                    );
                })}
            />
        </RoutePopupProvider>
    );
};
