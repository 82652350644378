import {PaginationHelpers, usePaginationMapper} from '@growthbase/spa';
import React, {useCallback} from 'react';
import {IBordTabelFragment} from '@growthbase/graphql';

import {ViewConfig} from '@growthbase/design-components';
import {Link} from 'react-router-dom';
import {CardData, Tabel} from './Tabel';
import {ActionMenuConnected} from '../../../ActionMenu';
import {OnderdeelVanFieldConnected, DeelnemersFieldConnected} from '../../../EditForm/Components';
import {useBordRouteViewFactory} from '../../../Hook';

export interface TabelConnectedProps extends ViewConfig {
    pagination: PaginationHelpers<IBordTabelFragment>;
}

export function TabelConnected({pagination, readonly, disabled}: TabelConnectedProps) {
    const routeFactory = useBordRouteViewFactory();
    const mapper = useCallback(
        (fragment: IBordTabelFragment): CardData => ({
            id: fragment.id,
            actions: (
                <ActionMenuConnected key={fragment.id} disabled={disabled} readonly={readonly} fragment={fragment} />
            ),
            locatie: (
                <OnderdeelVanFieldConnected
                    key={fragment.id}
                    fragment={fragment}
                    readonly={readonly}
                    disabled={disabled}
                />
            ),
            naam: <Link to={routeFactory(fragment)}>{fragment.naam}</Link>,
            deelnemers: <DeelnemersFieldConnected size="small" stackSize={4} fragment={fragment} />,
        }),
        [disabled, readonly, routeFactory]
    );

    const convertedPagination = usePaginationMapper(pagination, mapper);

    return <Tabel pagination={convertedPagination} />;
}
