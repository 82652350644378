import * as yup from 'yup';
import {SchemaOf} from 'yup';
import {useMemo} from 'react';
import {requiredString} from '@growthbase/spa';
import {TFunction} from 'i18next';
import {KoppelbaarItem, KoppelbaarItemType} from '../../../KoppelPopup';

export const KoppelbaarItemSchema = (t: TFunction): SchemaOf<KoppelbaarItem> =>
    yup
        .object({
            id: requiredString(),
            label: requiredString(),
            type: yup
                .string()
                .oneOf(Object.values(KoppelbaarItemType) as string[])
                .defined() as unknown as SchemaOf<KoppelbaarItemType>,
            href: yup.string(),
        })
        .defined();

export const NullableKoppelbaarItemSchema = (t: TFunction) => KoppelbaarItemSchema(t).nullable();

export const useNullableKoppelbaarItemSchema = (t: TFunction) => useMemo(() => NullableKoppelbaarItemSchema(t), [t]);
