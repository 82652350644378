import {ViewConfig} from '@growthbase/design-components';
import {onDropItemHandler, onRemoveItemHandler} from '@growthbase/keys';
import {PaginationHelpers} from '@growthbase/spa';
import React, {useMemo} from 'react';
import {ILijstentemplateOverviewConnectedFragment} from '@growthbase/graphql';
import {RenderViewType, RenderViewTypeProps, SwimlaneViewConnected, SortableTabellenConnected} from './Components';
import {Overview} from './Overview';
import {ContainerData, Filters, ViewType} from './types';

/* eslint-disable react/no-unstable-nested-components */

export interface OverviewConnectedProps<
    TLijst extends ILijstentemplateOverviewConnectedFragment = ILijstentemplateOverviewConnectedFragment,
    TContainerData extends ContainerData = ContainerData
> extends ViewConfig {
    pagination: PaginationHelpers<TLijst>;
    userData: TContainerData;
    onDrop: onDropItemHandler<TLijst, TContainerData>;
    onRemove?: onRemoveItemHandler<TLijst>;
    filters: Filters;
    view: ViewType;
}

export function OverviewConnected<
    TLijst extends ILijstentemplateOverviewConnectedFragment,
    TContainerData extends ContainerData = ContainerData
>({...props}: OverviewConnectedProps<TLijst, TContainerData>) {
    const renderType = useMemo(
        (): Record<ViewType, RenderViewType<TLijst, TContainerData>> => ({
            [ViewType.Swimlane]: (args: RenderViewTypeProps<TLijst, TContainerData>) => (
                <SwimlaneViewConnected {...args} />
            ),
            [ViewType.Tabel]: (args: RenderViewTypeProps<TLijst, TContainerData>) => (
                <SortableTabellenConnected {...args} />
            ),
        }),
        []
    );
    return <Overview {...props} renderLijstType={renderType} />;
}
