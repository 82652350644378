import {BaseEventType, DateRange, useCurrentView} from '@growthbase/planning';
import {MbscEventCreateEvent} from '@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar.types.public';
import moment from 'moment';
import {useCallbackRef} from '@growthbase/spa';
import {useFindGap} from './useEventFindGap';

export const useEventPlanOnDay = () => {
    const findGap = useFindGap();
    const currentView = useCurrentView();

    return useCallbackRef(({action, event}: MbscEventCreateEvent, events: BaseEventType[]) => {
        const {start, end} = event;

        const startDate = moment(start).toDate();
        const endDate = moment(end).toDate();

        const startOfDay = new Date(startDate);
        startOfDay.setHours(0, 0, 0, 0);

        const endOfDay = new Date(startDate);
        endOfDay.setHours(23, 59, 0, 0);

        const range: DateRange = {
            start: startDate,
            end: endDate,
        };

        const midnight = startDate.getSeconds() === 0 && startDate.getMinutes() === 0 && startDate.getHours() === 0;
        if (action === 'externalDrop' && currentView.autoPlan && midnight) {
            const eventDuration = endDate.getTime() - startDate.getTime();
            const creationRange = {start: startOfDay, end: endOfDay};

            const gap = findGap(creationRange, events, 60);
            const fullGap = findGap(creationRange, events, eventDuration);

            if (fullGap) {
                range.start = fullGap;
                range.end = new Date(fullGap.getTime() + eventDuration);
            } else if (gap) {
                range.start = gap;
                range.end = new Date(gap.getTime() + eventDuration);
            }

            // Limit end range to end of day of start
            if (range.end > endOfDay) {
                range.end = endOfDay;
            }
        }
        return range;
    });
};
