import {ReactNode} from 'react';
import classNames from 'classnames/bind';
import styles from './Footer.module.scss';

const cx = classNames.bind(styles);

export interface FooterProps {
    children: ReactNode;
    showOnHoverCard?: boolean;
}

export const Footer = ({children, showOnHoverCard}: FooterProps) => {
    const classes = cx('footer', {
        'footer--showOnHover': showOnHoverCard,
    });
    return <div className={classes}>{children}</div>;
};
