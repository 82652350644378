import {IAvailableTaakActions} from '@growthbase/graphql';

export const AllActionsAvailableStub: IAvailableTaakActions = {
    kanActiveren: true,
    kanVoltooien: true,
    kanVerwijderen: true,
    kanKopieren: true,
    kanArchiveren: true,
    kanTitelBewerken: true,
    kanOmschrijvingBewerken: true,
    kanPlanningBewerken: true,
    kanDeelnemersBewerken: true,
    kanVolgersBewerken: true,
    kanRelatieBewerken: true,
    gekoppeldeOpdrachtBekijken: true,
    gekoppeldeOpdrachtBewerken: true,
    gekoppeldeRelatieBekijken: true,
    isArchived: false,
    gekoppeldeRelatieBewerken: true,
    kanReactiesBekijken: true,
    kanReactiePlaatsen: true,
    gekoppeldeAbonnementBewerken: true,
    gekoppeldeAbonnementBekijken: true,
    gekoppeldeTicketBekijken: true,
    gekoppeldeTicketBewerken: true,
    KanDearchiveren: true,
    kanBijlageAanmaken: true,
};

export const NoActionsAvailableStub: Required<IAvailableTaakActions> = {
    kanActiveren: false,
    kanVoltooien: false,
    kanVerwijderen: false,
    kanKopieren: false,
    kanArchiveren: false,
    kanTitelBewerken: false,
    kanOmschrijvingBewerken: false,
    kanPlanningBewerken: false,
    kanDeelnemersBewerken: false,
    kanVolgersBewerken: false,
    kanRelatieBewerken: false,
    gekoppeldeOpdrachtBekijken: false,
    gekoppeldeOpdrachtBewerken: false,
    gekoppeldeRelatieBekijken: false,
    gekoppeldeRelatieBewerken: false,
    kanReactiePlaatsen: false,
    kanReactiesBekijken: false,
    isArchived: false,
    gekoppeldeAbonnementBekijken: false,
    gekoppeldeAbonnementBewerken: false,
    gekoppeldeTicketBekijken: false,
    gekoppeldeTicketBewerken: false,
    KanDearchiveren: false,
    uren: {
        aanmaken: false,
        bewerken: false,
        lezen: false,
    },
    kanBijlageAanmaken: false,
};
