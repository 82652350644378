import React, {FC, useMemo} from 'react';
import {Loader, useGebruikerHeeftToegang, useOptionalPopup} from '@growthbase/design-components';
import {useAlgemeenFormulierQuery, useVeranderTaakVoorkeurenVanProfielMutation} from '@growthbase/graphql';
import {useCallbackRef, useHandleError} from '@growthbase/spa';
import {TakenInstellingenRoutes} from '@growthbase/taken-instellingen';
import {useInstellingTabUrl} from '@growthbase/instellingen';
import {useTranslation} from 'react-i18next';
import {InstellingenFormulier, TaakInstellingen} from '../InstellingenFormulier';

export interface VoorkeurenFormulierConnectedProps {
    view: boolean;
}

export const AlgemeenFormulierConnected: FC<VoorkeurenFormulierConnectedProps> = ({view}) => {
    const {data, loading} = useAlgemeenFormulierQuery();

    const {profielEmailInstellingen, id, profielWeergaveInstellingen, profielUrenInstellingen} =
        data?.huidigProfiel ?? {};

    const voorkeuren = useMemo((): (TaakInstellingen & {id: string}) | null => {
        if (!id || !profielEmailInstellingen || !profielWeergaveInstellingen || !profielUrenInstellingen) {
            return null;
        }
        return {
            id,
            weergave: profielWeergaveInstellingen,
            email: profielEmailInstellingen,
            uren: profielUrenInstellingen,
        };
    }, [id, profielWeergaveInstellingen, profielEmailInstellingen, profielUrenInstellingen]);

    const [mutate] = useVeranderTaakVoorkeurenVanProfielMutation({
        onError: useHandleError(),
    });

    const controls = useOptionalPopup();

    const onSubmit = useCallbackRef(async (values: TaakInstellingen) => {
        await mutate({
            variables: {
                weergave: {
                    id: voorkeuren?.id ?? '',
                    ...values.weergave,
                },
                email: {
                    id: voorkeuren?.id ?? '',
                    ...values.email,
                },
                uren: {
                    id: voorkeuren?.id ?? '',
                    ...values.uren,
                },
            },
        }).then(controls?.close);
    });

    const editUrl = TakenInstellingenRoutes.algemeen_bewerken.useUrl();

    const weergave = useInstellingTabUrl<TaakInstellingen>({weergave: true}, editUrl);
    const email = useInstellingTabUrl<TaakInstellingen>({email: true}, editUrl);
    const uren = useInstellingTabUrl<TaakInstellingen>({uren: true}, editUrl);

    const toegang = useGebruikerHeeftToegang({
        enabled: !loading,
        toegang: !!voorkeuren,
    });
    const {t} = useTranslation('TakenInstellingen.nl');

    if (loading) {
        return <Loader center />;
    }
    if (!voorkeuren || !toegang) {
        return null;
    }

    return (
        <InstellingenFormulier
            title={t('Algemeen wijzigen')}
            editWeergave={weergave}
            editEmail={email}
            editUren={uren}
            view={view}
            onSubmit={onSubmit}
            voorkeuren={voorkeuren}
        />
    );
};
