import {TFunction} from 'i18next';
import * as yup from 'yup';
import {SchemaOf} from 'yup';
import {TitelSchema, UuidFieldSchema} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';
import {useMemo} from 'react';

export interface LijsttitelFieldValues {
    id: string;
    titel: string;
}

export const LijsttitelFieldSchema = (t: TFunction): SchemaOf<LijsttitelFieldValues> =>
    yup
        .object({
            id: UuidFieldSchema(t),
            titel: TitelSchema(t).label(t('titel', {ns: 'spa_form.nl'})),
        })
        .required();

export const useLijsttitelFieldSchema = () => {
    const {t} = useTranslation();
    return useMemo(() => LijsttitelFieldSchema(t), [t]);
};
