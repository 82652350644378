import {IRelatieverwijzingInput} from '@growthbase/graphql';
import {createContext, FC, PropsWithChildren, useContext, useMemo} from 'react';
import {noop} from 'rxjs';
import {RelatieValue} from '../../RelatieButton';

interface IRelatieComboboxQueryContext {
    value: IRelatieverwijzingInput | null;
    onChange: (value: RelatieValue | null) => void;
}

const RelatieComboboxQueryContext = createContext<IRelatieComboboxQueryContext>({
    value: null,
    onChange: noop,
});

export const useRelatieComboboxQuery = () => useContext(RelatieComboboxQueryContext);

export const RelatieComboboxQuery: FC<
    PropsWithChildren & {
        value: IRelatieverwijzingInput | null;
        onChange: (value: RelatieValue | null) => void;
    }
> = ({value, onChange, children}) => (
    <RelatieComboboxQueryContext.Provider value={useMemo(() => ({onChange, value}), [value, onChange])}>
        {children}
    </RelatieComboboxQueryContext.Provider>
);
