import React, {ReactNode} from 'react';
import classNames from 'classnames/bind';
import styles from './TableHeaderSection.module.scss';

export interface TableHeaderSectionProps {
    children: ReactNode;
    grow?: boolean;
    testId?: string | null;
}

const cx = classNames.bind(styles);

export const TableHeaderSection = ({children, grow, testId}: TableHeaderSectionProps) => {
    const classes = cx({
        tableHeaderSection: true,
        'tableHeaderSection--grow': grow,
    });
    return (
        <div
            className={classes}
            {...{
                ...(testId && {'data-testid': `TableHeaderSection-${testId}`}),
            }}
        >
            {children}
        </div>
    );
};
