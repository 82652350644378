import {
    DefaultFormModalFooter,
    Field,
    Modal,
    ModalContent,
    TabContentWrapper,
    Stack,
    SingleLineOpgemaakteTekstInput,
} from '@growthbase/design-components';
import React, {FC, PropsWithChildren, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {useEditForm, UseEditFormOptions} from './Hook/useEditForm';

export interface EditFormProps extends UseEditFormOptions, PropsWithChildren {
    renderHeader: () => ReactNode;

    KanNaamWijzigen: boolean;
}

export const EditForm: FC<EditFormProps> = ({children, renderHeader, KanNaamWijzigen, ...rest}) => {
    const {t} = useTranslation('TakenlijstBord.nl');
    const {Form, Fields, formik, formProps} = useEditForm(rest);
    const {NaamField} = Fields;

    const Naam = NaamField.useComponent(SingleLineOpgemaakteTekstInput);

    return (
        <Modal testId="BordCreateModal">
            <Form {...formProps}>
                {renderHeader()}
                <ModalContent theme="gray">
                    <TabContentWrapper title="x">
                        <Stack gap="small">
                            <Stack.Item>
                                <Field label={t('naam')} compact disableMargin>
                                    <Naam
                                        autoFocus
                                        controls={false}
                                        borderVisible
                                        variant="default"
                                        readonly={!KanNaamWijzigen}
                                    />
                                </Field>
                            </Stack.Item>
                        </Stack>
                        {children}
                    </TabContentWrapper>
                </ModalContent>
                <DefaultFormModalFooter formik={formik} />
            </Form>
        </Modal>
    );
};
