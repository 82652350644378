import {RegistrationLegacyRoutes} from '@growthbase/clientbox-uren';
import {useCallbackRef, useHTTPClient} from '@growthbase/spa';
import {useState} from 'react';
import {useUpdateActiveTimeregistration} from '../../Hook/useLegacyTimeRegistration';

/**
 * Post stop request to client, after this is done, it will request the newly updated time registration data.
 *
 * Return the loadings state + stop function.
 */
export const useStop = (registrationId: string | null): [boolean, () => void] => {
    const stopUrl = RegistrationLegacyRoutes.time_stop.useOptionalUrl({
        registrationId: registrationId ?? '-',
    });
    const client = useHTTPClient();
    const update = useUpdateActiveTimeregistration();
    const [loading, setLoading] = useState(false);
    const stop = useCallbackRef(() => {
        if (!stopUrl) {
            return;
        }
        setLoading(true);
        client
            .post(
                stopUrl,
                {},
                {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                }
            )
            .subscribe({
                complete: () => {
                    window.legacyTimeRegistration?.updateAppData();

                    update().finally(() => setLoading(false));
                },
            });
    });

    return [loading, stop];
};
