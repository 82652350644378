/* eslint-disable no-use-before-define,no-shadow */

import {FC, ReactNode} from 'react';
import {ControlFieldCollection} from './ControlFieldCollection';
import {InstellingInputProps, InstellingViewProps} from './Components';

export interface ControlsOptions<TField, TParent> {
    /**
     * Create special type for this so when can have conditions like includes etc.
     */
    when: TField extends boolean ? boolean : TField extends string ? string : TField extends number ? number : TField;
    value?: TParent extends object ? TParent | boolean : TParent;

    hide?: boolean;
}

export type ControlsFields<TField, TParent extends object> = {
    [TKey in keyof TParent]?:
        | (TParent[TKey] extends object ? ControlsFields<TField, TParent[TKey]> : never)
        | ControlsOptions<TField, TParent[TKey]>;
};

export interface BaseFieldInput {
    label: string;
    renderInputComponent?: (props: InstellingInputProps) => ReactNode;
    InputComponent?: FC<InstellingInputProps>;
    renderViewComponent?: (props: InstellingViewProps) => ReactNode;
    ViewComponent?: FC<InstellingViewProps>;
    /**
     * If true, the field will be excluded from submission DTO.
     * This is handy for fields that are only used for UI purposes. GraphQL will complain about extra fields.
     */
    exclude?: boolean;
}

export interface BaseControllableFieldInput<TRoot extends object, TField, TParent extends object>
    extends BaseFieldInput {
    /**
     * From the nested fields, this is the field that is used for the control.
     */
    control?: ControlsFields<TField, TParent>;
    tpl?: TParent;
    /**
     * The root of the control fields. This is used for fields that are not nested.
     */
    controlRoot?: ControlsFields<TField, TRoot>;
}

export interface ScalarValueInput<TRoot extends object, TField, TParent extends object>
    extends BaseControllableFieldInput<TRoot, TField, TParent> {
    defaultValue: TField;
    options?: TField extends string ? Record<string, string> & Record<TField, string> : never;
}

/**
 * TODO: type is not correct, union type is not working, so we use any for now.
 *
 * 'appel'|'peer' forces the type to be one of the options.
 */
export interface ArrayValueInput<TRoot extends object, TField extends string, TParent extends object>
    extends BaseControllableFieldInput<TRoot, TField, TParent> {
    defaultValue: TField;
    options: Record<string, string> & Record<TField, string>;
}

export interface NestedValueInput<TRoot extends object, TField extends object> extends BaseFieldInput {
    fields: InstellingFieldsInput<TRoot, TField, TField>;
}

export type InstellingFieldInput<TRoot extends object, TField, TParent extends object> = TField extends object
    ? NestedValueInput<TRoot, TField>
    : ScalarValueInput<TRoot, TField, TParent>;

export type InstellingFieldsInput<
    TRoot extends object,
    TField extends object = TRoot,
    TParent extends object = TRoot
> = {
    [TName in keyof TField]: InstellingFieldInput<TRoot, TField[TName], TParent>;
};

/// //////////////
// Above is the input, below is the output used by the components internally.
// This looses the strict type safety, but that's way easier to work with. !!! a string is still a string. etc.
//
// The input is a nested structure, the output is a flat structure.
//
/// //////////////

export type FullNestedName = string;

export enum InstellingFieldType {
    boolean = 'boolean',
    string = 'string',
    number = 'number',
    array = 'array',
    nested = 'nested',
}

export interface BaseField {
    /**
     * foo.bar.baz
     */
    name: FullNestedName;
    hide?: boolean;
    /**
     * parent.<basePath>
     */
    basePath: string;
    label: string;
    exclude?: boolean;
    FormComponent?: FC<InstellingInputProps>;
    renderInputComponent?: (props: InstellingInputProps) => ReactNode;
    ViewComponent?: FC<InstellingViewProps>;
    renderViewComponent?: (props: InstellingViewProps) => ReactNode;
    parent?: FullNestedName;
    /**
     * Only used during normalization, so we can easily add extra properties to the field.
     */
    input?: BaseFieldInput;
}

export interface BaseControlField extends BaseField {
    controlFields: ControlFieldCollection;
    input?: BaseControllableFieldInput<object, unknown, object>;
}

export interface ScalarValue<T = unknown> extends BaseControlField {
    defaultValue: T;
    type: InstellingFieldType.boolean | InstellingFieldType.string | InstellingFieldType.number;
    input?: ScalarValueInput<object, unknown, object>;
}

export interface ArrayValue extends BaseControlField {
    defaultValue: string;
    options: Record<string, string>;
    type: InstellingFieldType.array;
    input?: ArrayValueInput<object, string, object>;
}

export interface ControlFieldOptions {
    value: unknown;
    when: unknown;
    hide?: boolean;
}

export interface ControlField {
    name: FullNestedName;
    options: ControlFieldOptions;
    hide?: boolean;
}

export interface NestedValue extends BaseField {
    fields: FullNestedName[];
    type: InstellingFieldType.nested;
    input?: NestedValueInput<object, object>;
}

export type InstellingValues = Record<string, unknown>;

export type InstellingField = ScalarValue | NestedValue | ArrayValue;
