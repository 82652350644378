import {ReactNode} from 'react';
import {CardHeaderTitle} from '../../../Card';

export interface TableHeaderTitleProps {
    children: ReactNode;
}

/**
 * @deprecated Use CardHeaderTitle instead
 */
export const TableHeaderTitle = ({children}: TableHeaderTitleProps) => <CardHeaderTitle>{children}</CardHeaderTitle>;
