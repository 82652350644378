import moment from 'moment';

export const formatDateRange = (start: Date, end?: Date) => {
    const startMoment = moment(start);
    let endMoment = moment(end);

    if (endMoment.format('m') === '0' && endMoment.format('H') === '0') {
        endMoment = endMoment.subtract(1, 'day');
    }

    if (startMoment.isSame(endMoment, 'day')) {
        return startMoment.format('D MMMM YYYY');
    }

    if (startMoment.year() !== endMoment.year()) {
        return startMoment.format('D MMMM YYYY') + (end ? ` - ${endMoment.format('D MMMM YYYY')}` : '');
    }

    if (startMoment.month() !== endMoment.month()) {
        return startMoment.format('D MMMM') + (end ? ` - ${endMoment.format('D MMMM YYYY')}` : '');
    }

    return startMoment.format('D') + (end ? ` - ${endMoment.format('D MMMM YYYY')}` : '');
};
