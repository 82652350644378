import {useCreateStrictForm, usePopup} from '@growthbase/design-components';
import {useCallback, useEffect, useId, useMemo} from 'react';
import {noop, Observable} from 'rxjs';
import {calculatePlacement, useCallbackRef, useHashedMemorize} from '@growthbase/spa';
import {RelatieValue} from '@growthbase/relaties';
import {KoppelbaarItem} from '@growthbase/taken';
import {useImmerReducer} from 'use-immer';
import {idFromIri} from '@growthbase/routing';
import {Bord, Lijst, useVerplaatsSchema, VerplaatsFormDTO} from './useVerplaatsSchema';
import {verplaatsenFormReducer, VerplaatstFormState} from './useVerplaatsFormReducer';

export interface Opties {
    borden: Bord[];
    /**
     * The selected or first bord
     */
    bord: Bord | null;
    lijsten: Lijst[];
    relatie: RelatieValue | null;
    onderdeelVan: KoppelbaarItem | null;
}

export interface CurrentLijstValues {
    bord: Bord;
    relatie: RelatieValue | null;
    onderdeelVan: KoppelbaarItem | null;
}

export type SubmitValues = {
    bord: string;
    lijst: string;
    bovenLijst: string | null;
    onderLijst: string | null;
};

export interface FetchOptions extends VerplaatsFormDTO {
    onderdeelChanged: boolean;
    bordQuery: string | null;
}

export interface UseVerplaatsFormOptions {
    lijst: Lijst;
    onVerplaats: (values: SubmitValues) => Promise<void>;
    createInitialValues: () => CurrentLijstValues;
    fetchOptions: (values: FetchOptions) => Observable<Opties>;
}

export const useVerplaatsForm = ({onVerplaats, fetchOptions, lijst, createInitialValues}: UseVerplaatsFormOptions) => {
    const id = useId();
    const currentFormValues = useCallbackRef(() => {
        const currentLijstValues = createInitialValues();
        return {
            bord: currentLijstValues.bord?.id ?? null,
            relatie: currentLijstValues.relatie,
            onderdeelVan: currentLijstValues.onderdeelVan,
            positie: null,
        };
    });
    const [state, dispatch] = useImmerReducer(
        verplaatsenFormReducer,
        useMemo(
            (): VerplaatstFormState => ({
                instanceId: id,
                error: false,
                lijst,
                loading: false,
                formValues: currentFormValues(),
                formValuesOfOptionRequest: null,
                opties: null,
                currentValues: null,
                isValid: false,
                changed: false,
                bordQuery: null,
            }),
            [currentFormValues, id, lijst]
        )
    );
    const controls = usePopup();
    const {formValuesOfOptionRequest, isValid, opties, changed, loading, bordQuery} = state;
    const form = useCreateStrictForm<VerplaatsFormDTO>({
        onSubmit: useCallbackRef((values) => {
            const moveToBord = values.bord;
            const {positie} = values;
            if (moveToBord === null || opties === null || positie === null) {
                throw new Error('Invalid state');
            }
            const {lijsten} = opties;
            const heeftNepLijst = lijsten.some((l) => l.onderaan);
            const ids = lijsten.map((l) => idFromIri(l.id));
            const {after, before} = calculatePlacement(ids, heeftNepLijst, idFromIri(lijst.id), positie);
            return onVerplaats({
                bord: idFromIri(moveToBord),
                bovenLijst: before ?? null,
                onderLijst: after ?? null,
                lijst: lijst.id,
            }).then(controls.close);
        }),
        autoSubmit: false,
        schema: useVerplaatsSchema(),
        enableLockPopupAndRoutingWhenDirty: false,
        // This makes the reducer in control of the form values.
        createInitialValues: useCallback(() => state.formValues, [state.formValues]),
        disableResetAfterSubmit: true,
    });
    const {values} = form.formik;
    const valuesMemorized = useHashedMemorize(values);

    useEffect(() => {
        dispatch({
            type: 'formValues',
            values: valuesMemorized,
        });
    }, [dispatch, valuesMemorized]);

    useEffect(() => {
        dispatch({
            type: 'currentValues',
            lijst,
            values: createInitialValues(),
        });
    }, [dispatch, lijst, createInitialValues]);

    useEffect(() => {
        if (formValuesOfOptionRequest === null) {
            return noop;
        }
        dispatch({
            type: 'load',
            formValuesOfOptionRequest,
        });
        const subscription = fetchOptions(formValuesOfOptionRequest).subscribe({
            next: (newOpties) => {
                dispatch({
                    type: 'results',
                    opties: newOpties,
                    formValuesOfOptionRequest,
                });
            },
            error: (error) => {
                dispatch({
                    type: 'errored',
                    error,
                    formValuesOfOptionRequest,
                });
            },
        });
        return () => subscription.unsubscribe();
    }, [dispatch, fetchOptions, formValuesOfOptionRequest]);
    return {
        form,
        opties,
        loading,
        isValid: !loading && isValid && changed,
        changed,
        bordQuery,
        changeBordQuery: useCallbackRef((query: string | null): void =>
            dispatch({
                type: 'bordQuery',
                query,
            })
        ),
    };
};
