import React, {FC} from 'react';
import {ILijstTemplateActionBarFragment} from '@growthbase/graphql';
import {ActionsMenuButton} from '@growthbase/design-components';
import {ActionsBar} from './ActionsBar';
import {KopieerButtonConnected} from '../../../Kopieren';
import {VerwijderenButtonConnected} from '../../../Menu/Components';

export interface LijstTemplateBordActionMenuItemsConnectedProps {
    fragment: ILijstTemplateActionBarFragment;
}

export const ActionsBarConnected: FC<LijstTemplateBordActionMenuItemsConnectedProps> = ({fragment}) => (
    <ActionsBar lijsttemplateID={fragment.id}>
        <VerwijderenButtonConnected Button={ActionsMenuButton} fragment={fragment} />
        <KopieerButtonConnected Button={ActionsMenuButton} fragment={fragment} />
    </ActionsBar>
);
