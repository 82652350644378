import {PaginationHelpers} from '@growthbase/spa';
import React from 'react';
import {BoardCardGrid, PaginationInfiniteScroll} from '@growthbase/design-components';

export interface CardsViewProps<TCard extends {id: string}> {
    pagination: PaginationHelpers<TCard>;
    children: (props: {card: TCard}) => React.ReactNode;
}

export function CardsView<TCard extends {id: string}>({children, pagination}: CardsViewProps<TCard>) {
    return (
        <div style={{height: '100%', width: '100%'}}>
            <PaginationInfiniteScroll helpers={pagination}>
                <BoardCardGrid>
                    {pagination.connections.map((card) => (
                        <div key={card.id}>{children({card})}</div>
                    ))}
                </BoardCardGrid>
            </PaginationInfiniteScroll>
        </div>
    );
}
