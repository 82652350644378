import React, {FC} from 'react';
import {BaseButtonProps, ViewConfig} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';

export interface ArchiveerButtonProps extends ViewConfig {
    isArchived?: boolean;
    kanArchiveren: boolean;
    onClick: () => void;
    Button: FC<BaseButtonProps>;
    isSubmitting: boolean;
}

export const ArchiveerButton: FC<ArchiveerButtonProps> = ({
    isArchived,
    kanArchiveren,
    onClick,
    Button,
    isSubmitting,
    disabled,
    readonly,
}) => {
    const {t} = useTranslation('TakenlijstBord.nl');
    const titel = isArchived ? t('ArchiveerButton.unarchive') : t('ArchiveerButton.archive');
    if (!kanArchiveren) {
        return null;
    }
    return (
        <Button onClick={onClick} disabled={isSubmitting || disabled} readonly={readonly}>
            {titel}
        </Button>
    );
};
