import {ErrorMessage, useField, useFormikContext} from 'formik';
import React, {FC} from 'react';
import {FieldWarning} from '../FieldWarning';

export interface FieldErrorsProps {
    name: string;
    noFocusLostWarning: boolean;
}

export const FieldErrors: FC<FieldErrorsProps> = ({name, noFocusLostWarning}) => {
    const [, {error, touched}] = useField(name);
    const {submitCount} = useFormikContext();
    if (!error || !touched) {
        return null;
    }
    if (submitCount === 0 && noFocusLostWarning) {
        return null;
    }
    if (typeof error === 'object') {
        return (
            <>
                {Object.values(error).map((message, index) => (
                    <FieldWarning key={index} triangle>
                        {`${message}`}
                    </FieldWarning>
                ))}
            </>
        );
    }
    return (
        <FieldWarning triangle>
            <ErrorMessage name={name} />
        </FieldWarning>
    );
};
