import {FC} from 'react';
import {FormikFieldProps} from '../FieldWrapper/FieldWrapperControlled';
import {Checkbox} from './Checkbox';

export type CheckboxComponentProps = FormikFieldProps<boolean> & {checked?: boolean};

export const CheckboxComponent: FC<CheckboxComponentProps> = ({
    field: {value, ...field},
    disabled,
    onFocus,
    readonly,
    onBlur,
    // @ts-expect-error No clue where this label is comming from.
    label,
    checked,
}) => (
    <Checkbox
        box
        {...field}
        checked={value || checked || false}
        readOnly={readonly}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        label={label}
    />
);
