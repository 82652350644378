import {GroupBy, ViewFormat} from '../../ViewFormat';

export const PlanningAgendaDayView: ViewFormat = {
    group: 'agenda',
    id: 'agenda-dag',
    label: 'planning.dag',
    smallDates: true,
    view: {
        schedule: {
            type: 'day',
            size: 1,
            days: false,
        },
    },
};
export const PlanningAgendaWeekView: ViewFormat = {
    group: 'agenda',
    id: 'agenda-week',
    label: 'planning.week',
    smallDates: true,
    groupBy: 'resource',
    settings: {
        days: true,
    },
    view: {
        schedule: {
            type: 'week',
            startDay: 1,
            endDay: 5,
            days: true,
        },
    },
};
export const PlanningAgendaMonthView: ViewFormat = {
    group: 'agenda',
    id: 'agenda-maand',
    label: 'planning.4-weken',
    smallDates: true,
    active: true,
    preferred: true,
    autoPlan: true,
    view: {
        calendar: {
            weekNumbers: false,
            outerDays: false,
            type: 'week',
            size: 4,
        },
    },
};

export const PlanningAgendaViewFormats: ViewFormat[] = [
    PlanningAgendaDayView,
    PlanningAgendaWeekView,
    PlanningAgendaMonthView,
];

export const AgendaGroupByResource: GroupBy = {
    label: 'planning.medewerker',
    groupBy: 'resource',
};
export const AgendaGroupByDate: GroupBy = {
    label: 'planning.datum',
    groupBy: 'date',
};

export const AgendaGroupBy: GroupBy[] = [
    AgendaGroupByResource,
    AgendaGroupByDate,
];
