import {PaginationHelpers} from '@growthbase/spa';
import {FC} from 'react';
import {BaseNode} from '@growthbase/cache';
import {PaginationInfiniteScroll} from '../../PaginationInfiniteScroll';

export interface ActivityListProps<TNode extends BaseNode> {
    children: FC<{node: TNode}>;
    pagination: PaginationHelpers<TNode>;
}

export function LogbookActivityList<TNode extends BaseNode>({
    children: RenderChild,
    pagination,
}: ActivityListProps<TNode>) {
    const nodes = pagination.connections.map((node) => <RenderChild key={node.id} node={node} />);
    return <PaginationInfiniteScroll<TNode> helpers={pagination}>{nodes}</PaginationInfiniteScroll>;
}
