import {useLogger} from '@growthbase/spa';
import {useMemo} from 'react';
import {useCookie} from 'react-use';
import Cookies from 'js-cookie';
import {NavigationData, NavigationDataSchema} from '../Schema/NavigationData';

/**
 * @see sidebar.html.twig
 */
if (document.location.href.includes('legacy-menu')) {
    Cookies.set('legacy-menu', 'true');
    // eslint-disable-next-line no-restricted-globals,prefer-destructuring
    location.href = window.location.href.split('?')[0];
}

if (document.location.href.includes('new-menu')) {
    Cookies.set('legacy-menu', 'false');
    // eslint-disable-next-line no-restricted-globals,prefer-destructuring
    window.location.href = window.location.href.split('?')[0];
}

declare global {
    interface Window {
        MENU_DATA: NavigationData;
    }
}

export const useNavigationData = (): NavigationData | null => {
    const [legacyMenu] = useCookie('legacy-menu');
    const logger = useLogger('useNavigationData');
    return useMemo(() => {
        const showLegacyMenu = () => {
            const menu = document.getElementsByClassName('legacy-menu');
            if (menu.length > 0 && menu[0] instanceof HTMLElement) {
                menu[0].style.display = 'block';
            } else {
                logger.error('Legacy menu not found');
            }

            const menuButton = document.getElementsByClassName('legacy-trigger');
            if (menuButton.length > 0 && menuButton[0] instanceof HTMLElement) {
                menuButton[0].style.display = 'block';
            } else {
                logger.error('Legacy menu trigger not found');
            }
        };

        if (legacyMenu === 'true') {
            logger.info('Legacy menu enabled');
            showLegacyMenu();
            return null;
        }
        if (!window.MENU_DATA) {
            logger.info('No navigation data found, using legacy menu');
            showLegacyMenu();
            return null;
        }
        logger.info('Validation navigation data...', window.MENU_DATA);
        try {
            const data = NavigationDataSchema().validateSync(window.MENU_DATA) as NavigationData;
            data.center.push({
                label: 'Vorige menu gebruiken',
                route: null,
                path: '/dashboard?legacy-menu',
                active: false,
                children: [],
            });
            return data;
        } catch (error) {
            logger.error('Invalid navigation data, using legacy menu', error);
            showLegacyMenu();
            return null;
        }
    }, [logger, legacyMenu]);
};
