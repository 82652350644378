import {Icons, RadioSwitch, RadioSwitchOption} from '@growthbase/design-components';
import React, {FC, useMemo} from 'react';
import {ViewType} from '../../types';

export interface ViewSelectProps {
    value: ViewType;
    onChange: (value: ViewType) => void;
}

type ViewSelectItem = RadioSwitchOption<ViewType>;

export const ViewSelect: FC<ViewSelectProps> = ({value, onChange}) => {
    const options = useMemo(
        (): ViewSelectItem[] => [
            {
                value: ViewType.Tabel,
                label: <Icons.Rows />,
                testId: 'lijst-view-select-lijsten',
            },
            {
                value: ViewType.Swimlane,
                label: <Icons.Columns />,
                testId: 'lijst-view-select-kanban',
            },
        ],
        []
    );

    return <RadioSwitch<ViewType> square onChange={onChange} value={value} items={options} />;
};
