import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {useSelector} from 'react-redux';
import {createDispatchHook} from '../Redux';

export type DefaultValueName = string;
export type ProviderId = string;

export interface ValueProviderContainer {
    parent: ProviderId | null;
    values: Record<DefaultValueName, unknown>;
}

export interface DefaultValueState {
    containers: Record<ProviderId, ValueProviderContainer>;
}

const initialMessageState: DefaultValueState = {
    containers: {},
};

export interface DefaultValuesStoreState {
    defaultValues: DefaultValueState;
}

export const DefaultValuesSlice = createSlice({
    name: 'defaultValues',
    initialState: initialMessageState,
    reducers: {
        UpdateDefaultValue: (
            state,
            action: PayloadAction<{
                name: string;
                id: string;
                value: unknown;
                parent: string;
            }>
        ) => {
            let self = state.containers[action.payload.id];
            if (!self) {
                state.containers[action.payload.id] = {
                    values: {},
                    parent: null,
                };
                self = state.containers[action.payload.id];
            }
            if (self) {
                self.values[action.payload.name] = action.payload.value;
            }
        },

        RemoveDefaultValue: (state, action: PayloadAction<{name: string; id: string}>) => {
            const self = state.containers[action.payload.id];
            if (self) {
                delete self.values[action.payload.name];
            }
        },
    },
});

export const useDefaultValues = () =>
    useSelector(({defaultValues}: DefaultValuesStoreState) => defaultValues.containers);

export const {UpdateDefaultValue, RemoveDefaultValue} = DefaultValuesSlice.actions;

export const useUpdateDefaultValue = createDispatchHook(UpdateDefaultValue);
export const useRemoveDefaultValue = createDispatchHook(RemoveDefaultValue);

export const DefaultValuesReducer = DefaultValuesSlice.reducer;
