import type {DataFormat} from 'select2';
import {SchemaOf} from 'yup';
import * as yup from 'yup';

export interface Select2ItemDTO extends DataFormat {
    img?: string;
    name: string;
}

export const Select2ItemDTOSchema: SchemaOf<Select2ItemDTO> = yup
    .object({
        img: yup.string().optional(),
        name: yup.string().required(),
        text: yup.string().required(),
        id: yup.string().required(),
        selected: yup.boolean().optional(),
        disabled: yup.boolean().optional(),
    })
    .label('Select2ItemDTO')
    .required();

export const Select2ItemsDTOSchema: SchemaOf<Select2ItemDTO[]> = yup
    .array(Select2ItemDTOSchema)
    .label('Select2ItemsDTO')
    .required();
