import {IZichtbaarVoor} from '@growthbase/graphql';
import {TFunction} from 'i18next';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {SchemaOf} from 'yup';
import {
    AvatarRowFieldItem,
    createParagraph,
    createText,
    OpgemaakteTekst,
    opgemaakteTekstAsText,
    OpgemaakteTekstSchema,
} from '@growthbase/design-components';
import {RelatieSchema, RelatieValue} from '@growthbase/relaties';
import {KoppelbaarItem, NullableKoppelbaarItemSchema} from '@growthbase/taken';

export type CreateFormDTO = {
    naam: OpgemaakteTekst;
    relatie: RelatieValue;
    omschrijving: OpgemaakteTekst;
    onderdeelVan: KoppelbaarItem | null;
    deelnemers: AvatarRowFieldItem[];
    lijsten: string[];
    zichtbaarVoor: IZichtbaarVoor;
};

export const CreateFormSchema = (t: TFunction): SchemaOf<CreateFormDTO> =>
    yup
        .object({
            relatie: RelatieSchema(t),
            naam: OpgemaakteTekstSchema(t)
                .test(function TestMinLength() {
                    const {path, createError, parent: value} = this;
                    const naam = opgemaakteTekstAsText(value.naam).trim();
                    if (naam.length > 0) {
                        return true;
                    }
                    return createError({path, message: t('naamLength', {ns: 'TakenlijstBord.nl'}) ?? ''});
                })
                .label(t('naam', {ns: 'TakenlijstBord.nl'})),
            onderdeelVan: NullableKoppelbaarItemSchema(t).label(t('onderdeelVan', {ns: 'TakenlijstBord.nl'})),
            omschrijving: OpgemaakteTekstSchema(t).label(t('omschrijving', {ns: 'TakenlijstBord.nl'})),
            deelnemers: (
                yup.array().label(t('deelnemers', {ns: 'TakenlijstBord.nl'})) as SchemaOf<AvatarRowFieldItem[]>
            ).test(function TestRequire() {
                const {path, createError, parent: value} = this;
                const parent = value as CreateFormDTO;
                if (parent.deelnemers.length > 0 || parent.zichtbaarVoor !== IZichtbaarVoor.Deelnemers) {
                    return true;
                }
                return createError({path, message: t('deelnemersLength', {ns: 'TakenlijstBord.nl'}) ?? ''});
            }),

            lijsten: yup
                .array()
                .of(yup.string())
                .label(t('lijsten', {ns: 'TakenlijstBord.nl'})) as SchemaOf<string[]>,
            zichtbaarVoor: yup
                .string()
                .oneOf(Object.values(IZichtbaarVoor))
                .default(IZichtbaarVoor.Iedereen)
                .label(t('zichtbaarVoor', {ns: 'TakenlijstBord.nl'})) as SchemaOf<IZichtbaarVoor>,
        })
        .label(t('formulier', {ns: 'TakenlijstBord.nl'}))
        .required();

export const createInitialFormValues = (): CreateFormDTO => ({
    onderdeelVan: null,
    relatie: {},
    omschrijving: {
        elements: [createParagraph([createText('')])],
    },
    naam: {
        elements: [
            {
                type: 'p',
                children: [
                    {
                        text: '',
                    },
                ],
            },
        ],
    },
    deelnemers: [],
    lijsten: [],
    zichtbaarVoor: IZichtbaarVoor.Iedereen,
});

export const useCreateSchema = (): SchemaOf<CreateFormDTO> => {
    const {t} = useTranslation();
    return useMemo(() => CreateFormSchema(t), [t]);
};
