import {ReactNode} from 'react';
import classNames from 'classnames/bind';
import {Link, LinkProps} from 'react-router-dom';
import * as React from 'react';
import styles from './StyledLink.module.scss';

const cx = classNames.bind(styles);

export interface StyledLinkProps extends LinkProps {
    children: ReactNode;
    color?: 'black' | 'primary';
    hoverColor?: 'black' | 'primary';
}

export const StyledLink = ({children, color, hoverColor, ...rest}: StyledLinkProps) => {
    const classes = cx('styledLink', {
        'styledLink--colorBlack': color === 'black',
        'styledLink--colorPrimary': color === 'primary',
        'styledLink--hoverColorBlack': hoverColor === 'black',
        'styledLink--hoverColorPrimary': hoverColor === 'primary',
    });

    return (
        <Link {...rest} className={classes}>
            {children}
        </Link>
    );
};
