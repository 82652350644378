import {CapacitySquare, formatShortTime} from '@growthbase/design-components';
import {IDayType} from '@growthbase/planning';
import {useTranslation} from 'react-i18next';
import {CapacityEvent} from '../../Views/CapaciteitView/CapacityEvent';

export const CapacityItem = ({availableSeconds, plannedSeconds, type}: CapacityEvent) => {
    const topPercentage = (availableSeconds / 28800) * 100;
    const bottomPercentage = (plannedSeconds / 28800) * 100;
    const {t} = useTranslation('spa.nl');

    if (type === IDayType.Dayoff) {
        return <CapacitySquare color="none">{t('planning.vrij')}</CapacitySquare>;
    }

    if (type === IDayType.Absence) {
        return <CapacitySquare color="none">{t('planning.nietAanwezig')}</CapacitySquare>;
    }

    if (type === IDayType.Holiday) {
        return <CapacitySquare color="none">{t('planning.feestdag')}</CapacitySquare>;
    }

    const topPosition = plannedSeconds === 0 ? 'both' : 'top';

    let bottomSquare = <></>;

    if (plannedSeconds) {
        if (bottomPercentage) {
            bottomSquare = (
                <CapacitySquare color="low" filled position="bottom">
                    {formatShortTime(plannedSeconds)}
                </CapacitySquare>
            );
        } else if (bottomPercentage > 66) {
            bottomSquare = (
                <CapacitySquare color="high" filled position="bottom">
                    {formatShortTime(plannedSeconds)}
                </CapacitySquare>
            );
        } else if (bottomPercentage > 33) {
            bottomSquare = (
                <CapacitySquare color="medium" filled position="bottom">
                    {formatShortTime(plannedSeconds)}
                </CapacitySquare>
            );
        } else if (bottomPercentage === 0) {
            bottomSquare = (
                <CapacitySquare color="low" position={topPosition}>
                    {formatShortTime(plannedSeconds)}
                </CapacitySquare>
            );
        }
    }

    if (topPercentage > 66) {
        return (
            <>
                <CapacitySquare color="high" filled position={topPosition}>
                    {formatShortTime(availableSeconds)}
                </CapacitySquare>
                {bottomSquare}
            </>
        );
    }

    if (topPercentage > 33) {
        return (
            <>
                <CapacitySquare color="medium" filled position={topPosition}>
                    {formatShortTime(availableSeconds)}
                </CapacitySquare>
                {bottomSquare}
            </>
        );
    }

    if (topPercentage === 0) {
        return (
            <>
                <CapacitySquare color="low" position={topPosition}>
                    {formatShortTime(availableSeconds)}
                </CapacitySquare>
                {bottomSquare}
            </>
        );
    }

    return (
        <>
            <CapacitySquare color="low" filled position={topPosition}>
                {formatShortTime(availableSeconds)}
            </CapacitySquare>
            {bottomSquare}
        </>
    );
};
