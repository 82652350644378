import {FC, useContext} from 'react';
import {ScrollContext} from './ScrollContext';
import {ScrollProvider, ScrollProviderProps} from './ScrollProvider';

export const FallbackScrollProvider: FC<ScrollProviderProps> = ({children, ...props}) => {
    const parent = useContext(ScrollContext);
    if (parent) {
        return <>{children}</>;
    }
    return <ScrollProvider {...props}>{children}</ScrollProvider>;
};
