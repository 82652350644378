import {createContext, FC, PropsWithChildren, useContext, useEffect, useId, useRef, useState} from 'react';
import {Subject} from 'rxjs';
import {idFromIri} from '@growthbase/routing';

export interface LogbookItems {
    ids: string[];
    addedSets: Record<string, string[]>;
    subject: Subject<string[]>;
}

const loogbookItemsContext = createContext<LogbookItems | null>(null);

export const LogbookItemsProvider: FC<PropsWithChildren> = ({children}) => {
    const state = useRef<LogbookItems>();
    if (!state.current) {
        state.current = {
            ids: [],
            addedSets: {},
            subject: new Subject<string[]>(),
        };
    }
    return <loogbookItemsContext.Provider value={state.current}>{children}</loogbookItemsContext.Provider>;
};

export const useAddLogbookItems = (ids: string[]) => {
    const context = useContext(loogbookItemsContext);
    const id = useId();
    if (!context) {
        throw new Error('useAddLogbookItems must be used within a LogbookItemsProvider');
    }
    useEffect(() => {
        context.addedSets[id] = ids;

        const update = (): void => {
            const values = Object.values(context.addedSets);
            const flat = values.reduce((acc, val) => [...acc, ...val], []);
            const distinct = [...new Set(flat)];
            context.ids = distinct.map(idFromIri);
            context.subject.next(context.ids);
        };

        update();

        return () => {
            delete context.addedSets[id];
            update();
        };
    }, [context, id, ids]);
};

export const useLogbookItems = () => {
    const context = useContext(loogbookItemsContext);
    if (!context) {
        throw new Error('useLogbookItems must be used within a LogbookItemsProvider');
    }
    const [ids, setIDs] = useState<string[]>(context.ids);
    useEffect(() => {
        const subscription = context.subject.subscribe(setIDs);
        return () => {
            subscription.unsubscribe();
        };
    }, [context]);
    return ids;
};
