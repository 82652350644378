import {useWrapLockItem} from '@growthbase/locking';
import {FC} from 'react';
import {
    IVerwijderTaakButtonFragment,
    useVerwijderTaakButtonMutation,
    useVerwijderTaakButtonQueryLazyQuery,
} from '@growthbase/graphql';
import {BaseButtonProps, Button as DefaultButton} from '@growthbase/design-components';
import {VerwijderTaakButton} from './VerwijderTaakButton';

export interface VerwijderTaakButtonConnectedProps {
    fragment?: IVerwijderTaakButtonFragment;
    Button?: FC<BaseButtonProps>;
    onDeleted?: () => void;
}

export const VerwijderTaakButtonConnected: FC<VerwijderTaakButtonConnectedProps> = ({
    fragment,
    Button = DefaultButton,
    onDeleted,
}) => {
    const [executeMutation, {loading}] = useVerwijderTaakButtonMutation({
        variables: {
            input: {
                id: fragment?.id ?? '',
            },
        },
        onCompleted: onDeleted,
    });
    const [request] = useVerwijderTaakButtonQueryLazyQuery({
        variables: {
            id: fragment?.id ?? '',
        },
    });
    return (
        <VerwijderTaakButton
            qeury={request}
            fragment={fragment}
            onSubmit={useWrapLockItem(fragment, executeMutation)}
            isSubmitting={loading}
            SubmitButton={Button}
        />
    );
};
