import {ThemeOptions} from '@mui/material/styles/createTheme';

export const Select: ThemeOptions['components'] = {
    MuiSelect: {
        defaultProps: {
            variant: 'filled',
        },
        styleOverrides: {
            root: {
                background: 'var(--formInputBackground)',
            },
        },
    },
};
