import {FC, PropsWithChildren, useMemo} from 'react';

// only non red colors. Red is reserved for errors
const colors = [
    '#00FF00',
    '#0000FF',
    '#FFFF00',
    '#FF00FF',
    '#00FFFF',
    '#FFA500',
    '#FFC0CB',
    '#800080',
    '#008000',
    '#000080',
    '#008080',
    '#808000',
    '#808080',
    '#C0C0C0',
    '#FFD700',
    '#FF4500',
    '#FF6347',
    '#FF8C00',
    '#FFA07A',
    '#FFA500',
    '#FFD700',
    '#FFDAB9',
    '#FFDEAD',
    '#FFE4B5',
    '#FFE4C4',
    '#DC143C',
    '#7FFF00',
    '#D2691E',
    '#6495ED',
    '#00FA9A',
    '#B8860B',
    '#FF1493',
    '#1E90FF',
    '#FFD700',
    '#32CD32',
    '#800000',
    '#BA55D3',
    '#9400D3',
    '#00CED1',
    '#FF4500',
    '#DDA0DD',
    '#B0E0E6',
    '#BC8F8F',
    '#4169E1',
    '#8B4513',
    '#FA8072',
    '#F4A460',
    '#2E8B57',
    '#A0522D',
    '#6A5ACD',
    '#708090',
    '#4682B4',
    '#D2B48C',
    '#EE82EE',
    '#9ACD32',
];

export const TextColorByHash: FC<
    PropsWithChildren<{
        /**
         * Max number of characters, rest is ...
         */
        maxTextSize?: number;
        hash?: string;
        title?: string;
        noColor?: boolean;
        onClick?: () => void;
    }>
> = ({children, hash, title, onClick, noColor, maxTextSize}) => {
    const color = useMemo(() => {
        const from = hash || `${children}`;
        const numberOfHash = from.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
        return colors[numberOfHash % colors.length];
    }, [hash, children]);
    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            onClick={onClick}
            title={title}
            style={{
                color: noColor ? undefined : color,
                cursor: onClick ? 'cursor' : undefined,
                // Limit visual length of text
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: maxTextSize,
            }}
        >
            {children}
        </div>
    );
};
