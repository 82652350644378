import {useCallback, FC} from 'react';
import {IEditFormConnectedFragment, useLijsttitelFieldWijzigMutation} from '@growthbase/graphql';
import {EditForm} from './EditForm';

export interface EditFormConnectedProps {
    fragment: IEditFormConnectedFragment;
    onSubmit: () => Promise<void>;
}

export const EditFormConnected: FC<EditFormConnectedProps> = ({fragment, onSubmit}) => {
    const [mutation] = useLijsttitelFieldWijzigMutation();
    return (
        <EditForm
            value={fragment}
            availableActions={fragment.availableActions}
            onSubmit={useCallback(
                ({titel}) =>
                    mutation({
                        variables: {
                            input: {
                                id: fragment.id,
                                nieuweTitel: titel,
                            },
                        },
                    }).then(onSubmit),
                [fragment.id, mutation, onSubmit]
            )}
        />
    );
};
