import {BaseEventType, DateRange} from '@growthbase/planning';
import {useCallback} from 'react';

export function findGap(range: DateRange, events: BaseEventType[], gapDuration: number): Date | null {
    let eventDates = events.map((event) => ({
        start: new Date(event.start),
        end: new Date(event.end),
    }));

    // Filter events by range
    eventDates = eventDates.filter(
        (event) => event.start.getTime() >= range.start.getTime() && event.end.getTime() <= range.end.getTime()
    );

    // Sort events by start date
    eventDates.sort((a, b) => a.start.getTime() - b.start.getTime());

    // Add the date range to the beginning and end of the eventDates array
    eventDates.unshift({start: range.start, end: range.start});
    eventDates.push({start: range.end, end: range.end});

    // Find gap of at least specified duration
    for (let i = 0; i < eventDates.length - 1; i += 1) {
        const currentEventEnd = eventDates[i].end.getTime();
        const nextEventStart = eventDates[i + 1].start.getTime();
        const timeGap = nextEventStart - currentEventEnd;
        if (timeGap >= gapDuration) {
            // Found a gap of at least specified duration
            return new Date(currentEventEnd);
        }
    }

    // No gap found
    return null;
}

export function useFindGap() {
    return useCallback(
        (range: DateRange, events: BaseEventType[], gapDuration: number): Date | null =>
            findGap(range, events, gapDuration),
        []
    );
}
