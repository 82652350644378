import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {AddBreadcrumb} from '../Breadcrumb';
import {Button} from '../Button';
import {Page} from '../Page';
import {MemoryPopupProvider, PopupHeader, ScrollablePopupContent} from '../Popups';
import styles from './GeenToegangPagina.module.scss';

export interface GeenToegangPaginaProps {
    onClientClick?: () => void;
}

export const GeenToegangPagina: FC<GeenToegangPaginaProps> = ({onClientClick}) => {
    const {t} = useTranslation('spa.nl');
    return (
        <MemoryPopupProvider open>
            <Page sidebar={null} header={null}>
                <AddBreadcrumb>{t('GeenToegangPagina.Titel')}</AddBreadcrumb>

                <div className={styles.geenToegangPagina}>
                    <ScrollablePopupContent
                        fullscreen
                        header={<PopupHeader unclosable title={t('GeenToegangPagina.Titel')} />}
                    >
                        <div className={styles.geenToegangPagina__text}>{t('GeenToegangPagina.Omschrijving')}</div>
                        <Button variant="primary" active onClick={onClientClick}>
                            {t('GeenToegangPagina.TerugNaarHetDashboard')}
                        </Button>
                    </ScrollablePopupContent>
                </div>
            </Page>
        </MemoryPopupProvider>
    );
};
