import {ActionsMenu, ActionsMenuButton, BaseButtonProps, ViewConfig} from '@growthbase/design-components';
import {FC, PropsWithChildren, ReactNode} from 'react';
import {BewerkButton} from './Components';

export interface ActionMenuProps extends ViewConfig, PropsWithChildren {
    bordId: string;
    hideBewerkButton?: boolean;
    children?: ReactNode;
    renderVerwijderButton?: (props: {Button: FC<BaseButtonProps>}) => ReactNode;
    renderKopierenButton?: (props: {Button: FC<BaseButtonProps>}) => ReactNode;
}

export const ActionMenu: FC<ActionMenuProps> = ({
    bordId,
    hideBewerkButton,
    children,
    renderVerwijderButton,
    renderKopierenButton,
}) => {
    const visible = renderVerwijderButton || !hideBewerkButton;
    return (
        <ActionsMenu key="menu" visible={!!visible}>
            {renderVerwijderButton && renderVerwijderButton({Button: ActionsMenuButton})}
            {!hideBewerkButton && <BewerkButton id={bordId} Button={ActionsMenuButton} />}
            {renderKopierenButton && renderKopierenButton({Button: ActionsMenuButton})}
            {children}
        </ActionsMenu>
    );
};
