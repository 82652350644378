import {QueryResult} from '@apollo/client/react/types/types';
import {
    IMaakTaaktemplateTaaktemplateInput,
    ITakentemplateVanLijstSwimlaneQuery,
    ITakentemplateVanLijstSwimlaneQueryVariables,
    useMaakTaaktemplateInSwimlaneLijsttemplateMutation,
} from '@growthbase/graphql';
import {useCallbackRef} from '@growthbase/spa';
import {useAddTaaktemplateLockHandler} from './useAddTaaktemplateLockHandler';

export const useMaakTaakInLijstHandler = (
    lijstId: string,
    result: QueryResult<ITakentemplateVanLijstSwimlaneQuery, ITakentemplateVanLijstSwimlaneQueryVariables>
) => {
    const [mutateAsync] = useMaakTaaktemplateInSwimlaneLijsttemplateMutation();
    return useAddTaaktemplateLockHandler<
        ITakentemplateVanLijstSwimlaneQueryVariables,
        IMaakTaaktemplateTaaktemplateInput
    >(
        result,
        useCallbackRef((data) =>
            mutateAsync({
                variables: {
                    input: {
                        ...data,
                        lijsttemplateId: lijstId,
                    },
                },
            })
        )
    );
};
