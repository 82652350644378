import {IOpdrachtCalculatieType, usePlanningPopupOpdrachtQuery} from '@growthbase/planning';
import {useResolveConnections} from '@growthbase/graphql';
import {useEffect, useState} from 'react';
import {opdrachtFilterTypes, OpdrachtSelectie} from './OpdrachtSelectie';

export interface OpdrachtSelectieConnectedProps {
    onChangeOpdrachtId?: (opdrachtId: string) => void;
    opdrachtId: string;
}

export const OpdrachtSelectieConnected = ({opdrachtId, onChangeOpdrachtId}: OpdrachtSelectieConnectedProps) => {
    const [activeType, setActiveType] = useState<opdrachtFilterTypes>('all');

    let graphQlOpdrachtType: IOpdrachtCalculatieType | undefined;
    if (activeType === 'budget') {
        graphQlOpdrachtType = IOpdrachtCalculatieType.Budget;
    } else if (activeType === 'nacalculatie') {
        graphQlOpdrachtType = IOpdrachtCalculatieType.Nacalculatie;
    }

    const {data, loading, previousData} = usePlanningPopupOpdrachtQuery({
        variables: {
            type: graphQlOpdrachtType,
        },
    });

    const result = loading ? previousData : data;

    const tePlannenOpdrachten = useResolveConnections(result?.Opdrachten);

    const opdrachten = tePlannenOpdrachten.map((opdracht) => ({
        label: `${opdracht.nummer} - ${opdracht.relatienaam} - ${opdracht.onderwerp}`,
        value: opdracht.id,
    }));

    useEffect(() => {
        const opdracht = opdrachten.find((item) => item.value === opdrachtId);
        if (!opdracht && onChangeOpdrachtId && graphQlOpdrachtType && !loading && opdrachten.length > 0) {
            onChangeOpdrachtId(opdrachten[0].value);
        }
    }, [opdrachten, opdrachtId, onChangeOpdrachtId, graphQlOpdrachtType, loading]);

    return (
        <OpdrachtSelectie
            opdrachten={opdrachten}
            value={opdrachtId}
            onChange={onChangeOpdrachtId}
            onChangeType={setActiveType}
            activeType={activeType}
            loading={loading}
        />
    );
};
