import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {groupBy} from 'lodash';
import {useSelector} from 'react-redux';
import {createDispatchHook} from '../Active/Redux/activeReducer';
import {preservePlacement, resetContainers} from './utils';
import {onDragOverAction} from './Action';
import {
    DNDContainerData,
    DNDContainerId,
    DNDItemData,
    DNDItemId,
    initialDraggableStoreState,
    DNDContainerState,
    DNDStoreState,
    DNDUserDataType,
} from './Value';

export const DNDSlice = createSlice({
    name: 'DND',
    initialState: initialDraggableStoreState,
    reducers: {
        setItems(
            state,
            {
                payload: {container: from, items},
            }: PayloadAction<{
                items: DNDItemData[];
                container: DNDContainerData;
            }>
        ) {
            const {id} = from;
            if (!state.containers[id]) {
                state.containers[id] = {
                    draggingItems: null,
                    draggingItemsDND: null,
                    items: [],
                    container: from,
                };
            }
            const {active} = state;
            const container = state.containers[id];
            container.items = items;
            container.container = {...container.container, ...from};
            if (!active) {
                return;
            }

            /**
             * If this is the source container, and it's dragged to different container, remove the item from the source container.
             */
            if (active.sourceContainerId === id && active.sourceContainerId !== active.overContainerId) {
                container.draggingItems = container.items.filter((i) => i.id !== active.item.id);
                container.draggingItemsDND = null;
                return;
            }

            if (container.draggingItems) {
                preservePlacement(container, active.item);
            }
        },
        removeContainer(state, {payload: id}: PayloadAction<DNDContainerId>) {
            delete state.containers[id];
        },
        onDragOver: onDragOverAction,
        onDragStart(
            state,
            {
                payload: {containerId, itemId},
            }: PayloadAction<{
                containerId: DNDContainerId;
                itemId: DNDItemId;
            }>
        ) {
            resetContainers(state);
            const activeContainer = state.containers[containerId];
            if (!activeContainer) {
                throw new Error(`Container ${containerId} not found`);
            }
            const item = activeContainer.items.find((i) => i.id === itemId);
            if (!item) {
                throw new Error(`Item ${itemId} not found`);
            }
            state.active = {
                sourceContainerId: activeContainer.container.id,
                item,
                overContainerId: activeContainer.container.id ?? null,
                saving: false,
                itemOverId: null,
            };
        },
        onDragSave(state) {
            const {active} = state;
            if (!active) {
                throw new Error('No item active');
            }
            active.saving = true;
            /**
             * DND reset the position after drop, preventing the item position until backend is done.
             */
            const {sourceContainerId, overContainerId} = active;
            const sourceContainer = state.containers[sourceContainerId];
            if (sourceContainer) {
                sourceContainer.draggingItemsDND = sourceContainer.draggingItems;
            }
            const overContainer = state.containers[overContainerId];
            if (overContainer) {
                overContainer.draggingItemsDND = overContainer.draggingItems;
            }
        },
        onDragEnd(state) {
            state.active = null;
            resetContainers(state);
        },
        onDragCancel(state) {
            state.active = null;
            resetContainers(state);
        },
    },
});

const {onDragStart, setItems, removeContainer, onDragOver, onDragEnd, onDragCancel, onDragSave} = DNDSlice.actions;

export const useSetItems = createDispatchHook(setItems);
export const useRemoveContainer = createDispatchHook(removeContainer);

export const useOnDragOver = createDispatchHook(onDragOver);

export const useOnDragEnd = createDispatchHook(onDragEnd);

export const useOnDragStart = createDispatchHook(onDragStart);

export const useOnSave = createDispatchHook(onDragSave);

export const useOnDragCancel = createDispatchHook(onDragCancel);

export const DNDReducer = DNDSlice.reducer;

export const DNDContainersSelector = createSelector(
    (state: DNDStoreState) => state.DND.containers,
    (state) => state
);

export const useDNDContainersState = () => useSelector(DNDContainersSelector);

export const DNDContainersItemsGroupedByTypeSelector = createSelector(
    (state: DNDStoreState): Record<DNDUserDataType, DNDItemData[]> => {
        const grouped = groupBy(state.DND.containers, (c) => c.container.userDataType);
        const pairs = Object.entries(grouped).map(([group, c]) => [
            group,
            Object.values(c)
                .map(({items}) => items)
                .flat(),
        ]);
        return Object.fromEntries(pairs);
    },
    (state) => state as Record<DNDUserDataType, DNDItemData[]>
);

export const useDNDContainersItemsGroupedByType = () => useSelector(DNDContainersItemsGroupedByTypeSelector);

export function useDNDContainerState(id: DNDContainerId): DNDContainerState | null {
    const containers = useDNDContainersState();
    return (containers[id] ?? null) as DNDContainerState | null;
}
