import {Logger} from 'ts-log';
import {HttpServices} from '../HTTP';
import {ClientApplicationConfigDTO} from '../DTO';
import {ReduxServices} from '../Redux';
import {TranslationServices} from '../Translation';
import {ErrorClient, ErrorClientInterface} from './ErrorClient';
import {ExtractWarningFromResponseService} from './ExtractWarningFromResponseService';

export interface ErrorHandlingDependencies extends HttpServices {
    logger: Logger;
    config: ClientApplicationConfigDTO;
}

export interface ErrorHandlingServices {
    errorClient: ErrorClientInterface;
}

export const createErrorHandlingServices = ({
    logger,
    httpClient,
    config: {defaultErrorContext, errorRoute},
}: ErrorHandlingDependencies): ErrorHandlingServices => ({
    errorClient: new ErrorClient(httpClient, defaultErrorContext, logger, errorRoute),
});

export const createWarningServices = ({
    httpClient,
    t,
}: {logger: Logger} & ReduxServices & HttpServices & TranslationServices): unknown => {
    const extractWarningFromResponseService = new ExtractWarningFromResponseService(t);
    httpClient.setWarningService(extractWarningFromResponseService);
    return {
        extractWarningFromResponseService,
    };
};

export const createTestWarningServices = ({
    t,
}: {logger: Logger} & ReduxServices & HttpServices & TranslationServices): unknown => {
    const extractWarningFromResponseService = new ExtractWarningFromResponseService(t);
    return {
        extractWarningFromResponseService,
    };
};
