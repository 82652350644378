import {
    ITemplateVerwijderenMenuButtonConnectedFragment,
    useTemplateVerwijderenMenuButtonMutation,
} from '@growthbase/graphql';
import {idFromIri} from '@growthbase/routing';
import {FC} from 'react';
import {TemplateVerwijderenMenuButton} from './TemplateVerwijderenMenuButton';

export interface TemplateVerwijderenMenuButtonConnectedProps {
    bordtemplateId: string;
    fragment: ITemplateVerwijderenMenuButtonConnectedFragment;
}

export const TemplateVerwijderenMenuButtonConnected: FC<TemplateVerwijderenMenuButtonConnectedProps> = ({
    bordtemplateId,
    fragment: {
        naam,
        id,
        availableActions: {kanVerwijderen},
    },
}) => {
    const [mutate] = useTemplateVerwijderenMenuButtonMutation({
        variables: {
            input: {
                id: idFromIri(bordtemplateId),
                lijsttemplateId: idFromIri(id),
            },
        },
    });
    if (!kanVerwijderen) {
        return null;
    }
    return <TemplateVerwijderenMenuButton lijstTitel={naam} onSubmit={mutate} />;
};
