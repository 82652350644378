import * as yup from 'yup';

export interface Route {
    name: string;
    /**
     * Should the page be reloaded when the route is changed or is it an SPA route?
     */
    withPageReload: boolean;

    /**
     * Should it have an ?background=<current route> params
     */
    renderInForeground: boolean;
}

export const RouteSchema = yup
    .object({
        name: yup.string().required(),
        withPageReload: yup.boolean().required(),
        renderInForeground: yup.boolean().required(),
    })
    .label('Route') as yup.SchemaOf<Route>;
