import {FC} from 'react';
import {IVolgerFragment, IVolgersZoekenResultFragment} from '@growthbase/graphql';
import {AvatarPopupField, AvatarSize, SearchInput} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';

export type IVolger = IVolgerFragment['gebruiker'] | IVolgersZoekenResultFragment;

export interface VolgersProps {
    readonly?: boolean;
    disabled?: boolean;
    items: IVolger[];
    popupItems: IVolger[];
    stackSize?: number;
    size?: AvatarSize;
    onAdd: (item: IVolger) => void;
    onRemove: (item: IVolger) => void;

    onChangeQuery: (value: SearchInput) => void;
    searchQuery: string | null;
    loading: boolean;
}

export const Volgers: FC<VolgersProps> = ({...props}) => {
    const {t} = useTranslation('spa.nl');
    return (
        <AvatarPopupField<IVolger>
            testId="VolgersAvatarPopup"
            addButtonAriaLabel={t('Volgers.VolgerToevoegen')}
            popupTitle={t('Volgers.Volgers')}
            deleteToolTip={({naam}) => t('Volgers.VerwijderVolger', {naam})}
            addToolTip={({naam}) => t('Volgers.VolgerToevoegen', {naam})}
            {...props}
        />
    );
};
