import {
    PaginationRequestResponse,
    useCallbackRef,
    useGrowthbaseUrl,
    useHTTPClient,
    useRequestPagination,
} from '@growthbase/spa';
import {map} from 'rxjs';
import {DTO, Query} from '../ModelsTable';

export const useModelData = (query?: string) => {
    const client = useHTTPClient();
    const route = useGrowthbaseUrl('viewmodels');
    return useRequestPagination<DTO, Query>(
        useCallbackRef((q) =>
            client.get<DTO[]>(route, q).pipe(
                map(
                    (data): PaginationRequestResponse<DTO> => ({
                        page: 1,
                        items: data.map((item) => ({...item, id: item.name})),
                        totalCount: data.length,
                    })
                )
            )
        ),
        {
            queryParams: {
                query,
            },
            interval: 3000,
        }
    );
};
