import {BordenOnderdeelVan} from '@growthbase/takenlijst/bord';
import {useMemo} from 'react';
import {normalizeRelatieValues} from '@growthbase/relaties';
import {KoppelbaarItemType} from '@growthbase/taken';
import {IOpdrachtVanBordenPaginaFragment} from '@growthbase/graphql';
import {OpdrachtLegacyRoutes, OpdrachtRoutes} from '@growthbase/opdrachten-opdrachten';

export const useResolveOnderdeelVan = (
    opdrachtUuid: string,
    opdracht?: IOpdrachtVanBordenPaginaFragment | null
): BordenOnderdeelVan | null => {
    const factory = OpdrachtRoutes.bord.useUrlFactory();
    const viewLegacyOrder = OpdrachtLegacyRoutes.read.useUrlFactory();

    return useMemo((): BordenOnderdeelVan | null => {
        const relatie = normalizeRelatieValues(opdracht?.relatieverwijzing?.relatieverwijzing ?? null);
        if (!opdracht || !relatie) {
            return null;
        }
        return {
            id: opdrachtUuid,
            label: opdracht.titel ?? '-',
            type: KoppelbaarItemType.Opdracht,
            relatie,
            href: viewLegacyOrder({
                opdrachtId: opdracht.externalId ?? '-',
                opdrachtnummer: opdracht.nummer,
                opdrachtUuid: opdracht.id,
            }),
            route: ({onderdeelVan: opdrachtFromBord, id, naam}) => {
                const node = opdrachtFromBord?.node;
                if (!node) {
                    throw new Error('Logic exception.');
                }
                return factory({
                    opdrachtId: node.externalId ?? '-',
                    opdrachtnummer: node.nummer,
                    opdrachtUuid: node.id,
                    bordUuid: id,
                });
            },
        };
    }, [factory, opdracht, opdrachtUuid, viewLegacyOrder]);
};
