import React, {FC} from 'react';
import {TabsRendererProps} from './TabsRenderer';

import styles from './TabsRendererMarginLeft.module.scss';

export const TabsRendererMarginLeft: FC<TabsRendererProps> = ({header, content}) => (
    <div className={styles.tabsRendererMarginLeft}>
        <div className={styles.tabsRendererMarginLeft__header}>{header}</div>
        <div className={styles.tabsRendererMarginLeft__content}>{content}</div>
    </div>
);
