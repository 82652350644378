/* eslint-disable no-continue */
import {createContext, FC, PropsWithChildren, useContext, useEffect, useMemo} from 'react';
import {useBackgroundRouteTreeId} from '@growthbase/routing';
import {
    DefaultValueName,
    ProviderId,
    ValueProviderContainer,
    useDefaultValues,
    useRemoveDefaultValue,
    useUpdateDefaultValue,
} from './DefaultValueReducer';

/**
 * Wordt gebruikt zodat binnen een component standaard waarde gebruikt worden.
 * Woordoor het niet nodig is om deze waardes steeds door te geven.
 *
 * Provider id, moet altijd uniek zijn, anders kan het zijn dat de waarde van een andere component wordt gebruikt.
 */

export interface DefaultValue<T> {
    useValue: (fallback?: T | null, debugKey?: string) => T | null;
    Provider: React.FC<{id: ProviderId; value?: T | null} & PropsWithChildren>;
}

const TreeContext = createContext<ProviderId>('root');

export const useDefaultValueTreeId = () => useContext(TreeContext);

export const ForegroundDefaultTreeProvider: FC<PropsWithChildren> = ({children}) => {
    const treeId = useBackgroundRouteTreeId();
    return <TreeContext.Provider value={treeId ?? 'root'}>{children}</TreeContext.Provider>;
};

const useRootValues = () => {
    const treeId = useBackgroundRouteTreeId();
    const values = useDefaultValues();

    return useMemo((): Record<string, unknown> => {
        const c: Record<string, unknown> = {};
        let it: string | undefined | null = treeId;
        do {
            if (!it) {
                break;
            }
            const found: ValueProviderContainer = values[it];
            if (!found) {
                break;
            }
            for (const [name, value] of Object.entries(found.values)) {
                if (value === null || c[name]) {
                    continue;
                }
                c[name] = value;
            }
            it = found.parent;
        } while (it);
        return c;
    }, [treeId, values]);
};

export function DefaultValueFactory<T>(name: DefaultValueName): DefaultValue<T> {
    const Context = createContext<T | null>(null);
    const useValue = (fallback: T | null = null): T | null => {
        const value = useContext(Context);
        const rootValues = useRootValues();
        return value ?? (rootValues[name] as T | null) ?? fallback;
    };

    const Provider: React.FC<{id: ProviderId; value?: T | null} & PropsWithChildren> = ({
        value = null,
        children,
        id,
    }) => {
        const update = useUpdateDefaultValue();
        const remove = useRemoveDefaultValue();
        const parent = useContext(TreeContext);

        useEffect(() => {
            update({name, id, value, parent});
            return (): void => {
                remove({name, id});
            };
        }, [id, parent, remove, update, value]);

        if (value === null) {
            return <TreeContext.Provider value={id}>{children}</TreeContext.Provider>;
        }
        return (
            <TreeContext.Provider value={id}>
                <Context.Provider value={value}>{children}</Context.Provider>
            </TreeContext.Provider>
        );
    };

    Provider.displayName = `${name}DefaultValueProvider`;

    return {
        useValue,
        Provider,
    };
}
