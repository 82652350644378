import {QueryResult} from '@apollo/client/react/types/types';
import {useConvertFetchEntity, useUpdateQueryCacheFromExternalEntityChanges} from '@growthbase/cache';
import {
    ITakenTabelVanLijstQuery,
    ITakenTabelVanLijstQueryVariables,
    ITakenTabelVanLijstRowFragment,
    refetchTakenTabelVanLijstSingleQuery,
} from '@growthbase/graphql';
import {idFromIri} from '@growthbase/routing';
import {determineTaakUpdateActionOnEntityChanges} from '@growthbase/taken';
import {useCallback} from 'react';
import {OverviewType, TaakFilters} from '../../../../../types';

export interface TaakTabelEntityChangesParams {
    filters: TaakFilters;
    lijstId: string;
    result: QueryResult<ITakenTabelVanLijstQuery, ITakenTabelVanLijstQueryVariables>;
}

export const useTaakTabelEntityChanges = ({result, filters, lijstId}: TaakTabelEntityChangesParams) => {
    const id = idFromIri(lijstId);
    useUpdateQueryCacheFromExternalEntityChanges<ITakenTabelVanLijstRowFragment>({
        // @ts-expect-error TODO: fix variables, we don't need to match that.
        result,
        determineAction: useCallback(
            (params) => {
                let filterVoltooid;
                if (filters.overviewType === OverviewType.Voltooid) {
                    filterVoltooid = true;
                }
                if (filters.overviewType === OverviewType.Overzicht) {
                    filterVoltooid = false;
                }
                return determineTaakUpdateActionOnEntityChanges(params, {
                    searchQuery: filters.searchQuery,
                    filterGearchiveerd: false,
                    reorderForList: id,
                    filterVoltooid,
                    filterByGebruikerIds: filters.deelnemers,
                    filterByLijstIds: [id],
                });
            },
            [filters, id]
        ),
        fetchEntity: useConvertFetchEntity(refetchTakenTabelVanLijstSingleQuery),
    });
};
