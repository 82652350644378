import {FC, useCallback} from 'react';
import {CardSelectOption, PaddingHorizontal, PaddingVertical, Scrollable} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';
import {Dienst} from '../../Sidebars/PlanningSidebar/Dienst';
import {useRenderTime} from './Hook/useRenderTime';

export interface DienstSelectieProps {
    activeDienstId?: string;
    diensten: Dienst[];
    keuzeAangepast: (value?: string) => void;
}

export const DienstSelectie: FC<DienstSelectieProps> = ({diensten, activeDienstId, keuzeAangepast}) => {
    const {t} = useTranslation('spa.nl');
    const renderTime = useRenderTime();

    const onChangeValue = useCallback(
        (value?: string) => {
            keuzeAangepast(value);
        },
        [keuzeAangepast]
    );

    return (
        <div style={{height: '100%'}}>
            <PaddingVertical height="100%">
                <Scrollable fillParent>
                    <PaddingHorizontal>
                        <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                            {diensten.map((dienst) => (
                                <CardSelectOption
                                    key={dienst.id}
                                    value={dienst.id}
                                    onChangeValue={onChangeValue}
                                    title={dienst.titel}
                                    active={dienst.id === activeDienstId}
                                    subtitle={
                                        dienst.resterendBudgetVoorOpdracht
                                            ? t('Planning.Sidebar.DienstSelectie.Resterend', {
                                                  tijd: renderTime(
                                                      dienst.resterendBudgetVoorOpdracht.resterendeTijdInSeconde
                                                  ),
                                              }).toString()
                                            : undefined
                                    }
                                />
                            ))}
                        </div>
                    </PaddingHorizontal>
                </Scrollable>
            </PaddingVertical>
        </div>
    );
};
