import {replace} from 'lodash';

/**
 * Hacker the hack code to select2 values.
 *
 * https://select2.org/programmatic-control/add-select-clear-items
 */
jQuery.fn.select2Value = function (value: string, byText = true): JQuery {
    // Find label
    const that = jQuery(this);
    that.select2('open');
    let name = that.attr('id');
    if (!name) {
        name = replace(this.attr('name') + '', /[\[\]]/gm, '');
    }
    if (!byText) {
        // @ts-ignore
        const option = that.find('option').filter(function (): boolean {
            // @ts-ignore
            return jQuery(this).val() === value;
        });
        if (option.length === 0) {
            console.warn('Option not found: ' + value);
            return that;
        }
        value = option.text();
    }
    return (
        jQuery('ul.select2-results__options')
            // @ts-ignore
            .filter(function () {
                // @ts-ignore
                return jQuery(this).attr('id') + ''.includes(name + '');
            })
            .throwWhenEmpty('Option with list with name: ' + name)
            .find('li')
            .matchesText(value)
            .throwWhenEmpty('Option with text: ' + value)
            .mouseup()
    );
};
