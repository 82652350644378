import {EventType, IMedewerkerFragment, IPlanningEventFragment} from '@growthbase/planning';
import moment from 'moment/moment';
import {useMemo} from 'react';
import {getEventTitle} from '../getEventTitle';

export const useNormalizeEvent = () =>
    useMemo(
        () =>
            (event: IPlanningEventFragment, medewerker: IMedewerkerFragment): EventType => {
                const csrfToken = 'csrfToken' in event && event.csrfToken ? event.csrfToken : undefined;

                const mobiEvent: EventType = {
                    id: event.id,
                    start: new Date(event.startDate),
                    end: new Date(event.endDate),
                    title: event.subject ?? '',
                    taak: null,
                    editable: event.canEdit,
                    resource: medewerker.agenda.id,
                    color: event.color || medewerker.agenda.color || undefined,
                    csrfToken,
                    isPrivate: event.isPrivate || false,
                    resize: event.canResize,
                    dragBetweenResources: event.canMoveToOtherCalendar,
                    isCreatingTimeRegistration: event.isCreatingUrenregistratie,
                    hasTimeRegistration: event.hasUrenregistratie,
                    allDay:
                        moment(event.startDate).format('HH:mm') === '00:00' &&
                        moment(event.endDate).format('HH:mm') === '00:00',
                };
                if ('taak' in event && typeof event.taak === 'string') {
                    mobiEvent.taak = event.taak;
                }
                if ('opdracht' in event && event.opdracht) {
                    mobiEvent.opdracht = {
                        id: event.opdracht.id,
                        label: event.opdracht.onderwerp,
                        nummer: event.opdracht.nummer,
                    };
                }
                if ('dienst' in event && event.dienst) {
                    mobiEvent.dienst = {
                        id: event.dienst.id,
                        label: event.dienst.titel,
                        nummer: event.dienst.code,
                    };
                }
                if ('ticket' in event && event.ticket) {
                    mobiEvent.ticket = {
                        id: event.ticket.id,
                        label: event.ticket.onderwerp,
                        nummer: event.ticket.code,
                    };
                }
                if ('relatie' in event && event.relatie) {
                    mobiEvent.relatie = {
                        id: event.relatie.id,
                        label: event.relatie.naam,
                    };
                }

                mobiEvent.title = getEventTitle(mobiEvent);
                return mobiEvent;
            },
        []
    );
