import {useTranslation} from 'react-i18next';
import {AddBreadcrumb, Loader, Page} from '@growthbase/design-components';
import {useSelector} from 'react-redux';
import React from 'react';
import {
    PlanningSidebarConnected,
    PlanningStoreState,
    useLoadPersistentPlanningState,
    useSyncedPlanningState,
    CapaciteitViewConnected,
    PlanningViewConnected,
    AgendaViewConnected,
} from './index';
import {PlanningRoutes} from './PlanningRoutes';
import {useCurrentView} from './_hooks';

export const PlanningConnected = () => {
    const {viewSettingsLoaded} = useSelector((state: PlanningStoreState) => state.planning);
    const currentView = useCurrentView();
    const {group} = currentView;

    const {t} = useTranslation('spa.nl');

    useLoadPersistentPlanningState();
    useSyncedPlanningState();

    return (
        <Page title={t('planning.titel')} sidebar={<PlanningSidebarConnected key={currentView.group} />}>
            <AddBreadcrumb to={PlanningRoutes.index.useUrl()}>{t('planning.planning')}</AddBreadcrumb>
            {viewSettingsLoaded && group === 'lijst' && <Loader fixed />}
            {viewSettingsLoaded && group === 'planning' && <PlanningViewConnected />}
            {viewSettingsLoaded && group === 'agenda' && <AgendaViewConnected />}
            {viewSettingsLoaded && group === 'capaciteit' && <CapaciteitViewConnected />}
        </Page>
    );
};
