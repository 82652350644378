import {ReactNode} from 'react';
import styles from './PopupTitle.module.scss';

export interface PopupTitleProps {
    children: ReactNode;
    className?: string;
}

export const PopupTitle = ({children, className}: PopupTitleProps) => (
    <div className={`${styles.popupTitle} ${className}`}>{children}</div>
);
