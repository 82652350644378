import {TFunction} from 'i18next';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {SchemaOf} from 'yup';
import {
    createParagraph,
    createText,
    OpgemaakteTekst,
    OpgemaakteTekstSchema,
    TitelSchema,
} from '@growthbase/design-components';

export interface CreateFormDTO {
    titel: string;
    omschrijving: OpgemaakteTekst;
}

export const CreateSchema = (t: TFunction): SchemaOf<CreateFormDTO> =>
    yup
        .object({
            titel: TitelSchema(t),
            omschrijving: OpgemaakteTekstSchema(t).label(t('omschrijving', {ns: 'TakenlijstBord.nl'})),
        })
        .label(t('taakformulier', {ns: 'spa_form.nl'}))
        .required();

export const createInitialFormValues = (): CreateFormDTO => ({
    titel: '',
    omschrijving: {
        elements: [createParagraph([createText('')])],
    },
});

export const useCreateSchema = (): SchemaOf<CreateFormDTO> => {
    const {t} = useTranslation();
    return useMemo(() => CreateSchema(t), [t]);
};
