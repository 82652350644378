import {QueryResult} from '@apollo/client/react/types/types';
import {
    useExternalEntityAdded,
    useExternalEntityChanged,
    useExternalEntityFieldsInvalidated,
    useExternalEntityRemoved,
} from '@growthbase/cache';
import {ITaak, ITakenSideLinksQuery, ITakenSideLinksQueryVariables} from '@growthbase/graphql';

export interface EntityChangesParams {
    result: QueryResult<ITakenSideLinksQuery, ITakenSideLinksQueryVariables>;
}

export const useEntityChanges = ({result: {refetch}}: EntityChangesParams) => {
    const callback = (args: {typename: string}): void => {
        if (args.typename === 'LijstVanTaakEdge') {
            refetch();
        }
    };
    useExternalEntityChanged<ITaak>(({fields}) => {
        if (fields.includes('voltooid')) {
            refetch();
        }
    });
    useExternalEntityRemoved<{id: string}>(callback);
    useExternalEntityAdded(callback);
    useExternalEntityFieldsInvalidated<{
        id: string;
        priveVoorMij: boolean;
    }>((args) => {
        const found = args.invalidation.find((invalidation) => invalidation.name === 'priveVoorMij');
        if (!found) {
            return;
        }
        refetch();
    });
};
