import {useMemo} from 'react';
import {trimEnd} from 'lodash';
import {ApplicationServices} from '../ApplicationServices';
import {useApplicationServices} from './useApplicationServices';

/**
 * Select partial services from the global service container.
 */
export const createUseService =
    <R, T = ApplicationServices>(mapper: (services: T) => R): (() => R) =>
    () => {
        const dependencies = useApplicationServices();
        return useMemo(() => mapper(dependencies as unknown as T), [dependencies]);
    };

export function createUseAdditionalProperties<TReturn>(name: string): () => TReturn {
    return createUseService(({config: {additionalProperties}}) => additionalProperties[name]) as () => TReturn;
}

export const useService = <T = ApplicationServices>(): T => createUseService((s) => s as unknown as T)();

export const useIsDevelopment = createUseService<boolean, ApplicationServices>(
    (services) => services?.config && !!services.config.environment && !services.config.environment.includes('prod')
);

export const useIsIntegration = createUseService<boolean, ApplicationServices>(
    (services) => services?.config && services.config.environment === 'integration'
);

export const useBaseRoute = () => createUseService((s): string => trimEnd(s.config.baseRoute ?? '', '/'))();
