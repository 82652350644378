import {QueryResult} from '@apollo/client/react/types/types';
import {PositieType, useAddToQueryCache} from '@growthbase/cache';
import {IMaakTaakAanTaakInput, ITakenVanLijstSwimlaneKaartFragment, ResultData} from '@growthbase/graphql';
import {useCreateWrapLockItem} from '@growthbase/locking';
import {useCallback} from 'react';
import {MaakTaakAanTaakFormDTO, NoActionsAvailableStub, normalizeMaakTaakAanTaakDTO} from '@growthbase/taken';

/**
 * Common hook for adding a new locked taak to the cache --> sending it to the api --> adding it to the cache.
 */
export function useAddTaakLockHandler<TVariables, TRow extends ITakenVanLijstSwimlaneKaartFragment>(
    result: QueryResult<ResultData<TRow>, TVariables>,
    add: (item: IMaakTaakAanTaakInput) => Promise<unknown>
) {
    const appendBeforeSendingToTheApi = useAddToQueryCache(result.updateQuery, {
        appendItemFromNextPage: true,
        edgeTypeName: 'TaakEdge',
        nodeTypeName: 'Taak',
        placeholderPosition: true,
    });
    const lock = useCreateWrapLockItem();
    return useCallback(
        (data: MaakTaakAanTaakFormDTO) => {
            const input: IMaakTaakAanTaakInput = normalizeMaakTaakAanTaakDTO(data);
            const base: Required<ITakenVanLijstSwimlaneKaartFragment> = {
                id: input.id,
                heeftOmschrijving: false,
                heeftBijlages: {
                    aantal: 0,
                    heeftBijlages: false,
                },
                titel: input.titel,
                isLocked: true,
                availableActions: NoActionsAvailableStub,
                gearchiveerd: false,
                abonnement: null,
                ticket: null,
                opdracht: null,
                lijstInBord: {
                    id: input.id,
                    lijst: null,
                },
                snooze: {
                    id: input.id,
                    tot: null,
                },
                priveVoorMij: data.isPrive ?? false,
                deelnemers: data.deelnemers?.map((deelnemer) => ({
                    id: input.id,
                    __typename: 'Deelnemer',
                    gebruiker: {
                        ...deelnemer,
                        __typename: 'Gebruiker',
                    },
                })),
                relatie: null,
                planning: {
                    __typename: 'Taakplanning',
                    id: input.id,
                    begindatum: input.begindatum?.dateTime ?? null,
                    gebruikBegindatumTijd: input.begindatum?.gebruikTijd ?? false,
                    deadline: input.deadline?.dateTime ?? null,
                    gebruikDeadlineTijd: input.deadline?.gebruikTijd ?? false,
                    begindatumIsBereikt: false,
                    deadlineIsBereikt: false,
                },
                onderdeelVan: {
                    id: input.id,
                    onderdeel: null,
                },
                reacties: null,
                voltooid: false,
                bijlages: [],
            };

            appendBeforeSendingToTheApi(
                {
                    ...base,
                } as TRow,
                PositieType.after
            );

            return lock(
                {
                    id: input.id,
                    __typename: 'Taak',
                },
                () => add(input).then(() => undefined)
            );
        },
        [lock, appendBeforeSendingToTheApi, add]
    );
}
