import {Buttons, Table} from '@growthbase/design-components';
import {usePaginationFixedResult} from '@growthbase/spa';
import * as React from 'react';
import {FC, useCallback, useMemo, useState} from 'react';
import {ObjectInspector} from 'react-inspector';
import {HealthInterface} from '../Health';

export interface CronHealthContentProps {
    value: HealthInterface;
}

export interface Stats {
    id: string;
    started: string;
    ended: string;
    errored: boolean;
    key: string;
    error: string | null;
    durationSeconds: number;
    metadata: unknown;
}

export interface CronMetadata {
    name: string;
    locked: boolean;
    started: string;
    key: string;
    durationSeconds: number;
    timeLeftSeconds: number;
    history: Stats[];
}

const ObjectInspectorCell: FC<{value: unknown}> = ({value}) => <ObjectInspector data={value} />;
const BoolCell: FC<{value: boolean}> = ({value}) => <>{value ? 'Ja' : 'Nee'}</>;

export const CronData: FC<{stat: CronMetadata}> = ({stat: {history, name, ...rest}}) => (
    <Table<Stats>
        header={
            <div>
                <br />
                {name}
            </div>
        }
        pagination={usePaginationFixedResult(history)}
        columns={[
            {
                Header: 'Id',
                accessor: 'id',
            },
            {
                Header: 'Started',
                accessor: 'started',
            },
            {
                Header: 'Ended',
                accessor: 'ended',
            },
            {
                Header: 'Errored',
                accessor: 'errored',
                Cell: BoolCell,
            },
            {
                Header: 'Error',
                accessor: 'error',
            },
            {
                Header: 'Duration',
                accessor: 'durationSeconds',
            },
            {
                Header: 'metadata',
                accessor: 'metadata',
                Cell: ObjectInspectorCell,
            },
        ]}
    />
);

export const CronHealthContent: FC<CronHealthContentProps> = ({value}) => {
    const metadata = value.metadata as CronMetadata[];
    const connections = useMemo(() => metadata.map((val) => ({...val, id: val.name})), [metadata]);
    const overalData = usePaginationFixedResult(connections);
    const [page, setPage] = useState<string | null>(null);

    const found = metadata.find((val) => val.name === page);

    return (
        <div>
            <Table
                pagination={overalData}
                columns={[
                    {
                        Header: 'Name',
                        accessor: 'name',
                        Cell: useCallback(
                            // eslint-disable-next-line react/no-unstable-nested-components
                            ({value: newValue}: {value: string}) => (
                                <Buttons.NoBorder onClick={() => setPage(newValue)}>{newValue}</Buttons.NoBorder>
                            ),
                            [setPage]
                        ),
                    },
                    {
                        Header: 'Started',
                        accessor: 'started',
                    },
                    {
                        Header: 'Duration',
                        accessor: 'durationSeconds',
                    },
                    {
                        Header: 'Time left',
                        accessor: 'timeLeftSeconds',
                    },
                ]}
            />
            {found && <CronData stat={found} />}
        </div>
    );
};
