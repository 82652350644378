import {FC} from 'react';
import {TableHeaderTitle} from '../Table/TableHeader';

export interface TableTitleWithCountProps {
    titel: string;
    count?: number | null;
}

export const TableTitleWithCount: FC<TableTitleWithCountProps> = ({titel, count}) => (
    <TableHeaderTitle>
        {titel}
        {count ? ` (${count})` : ''}
    </TableHeaderTitle>
);
