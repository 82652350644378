import React from 'react';
import {IBordtemplateCardFragment} from '@growthbase/graphql';
import {ViewConfig} from '@growthbase/design-components';
import {Card} from './Card';
import {CardHeaderConnected} from './Components/CardHeader/CardHeaderConnected';

export interface CardProps extends ViewConfig {
    fragment: IBordtemplateCardFragment;
}

export function CardConnected({fragment, disabled, readonly}: CardProps) {
    return <Card header={<CardHeaderConnected disabled={disabled} readonly={readonly} fragment={fragment} />} />;
}
