import React from 'react';
import {Padding, Stack, useIsMobile} from '@growthbase/design-components';
import {InstellingInputProps, InstellingInputWrapper} from './InstellingInputWrapper';
import {NestedValue} from '../../../types';

export const NestedInstellingInput = (props: InstellingInputProps) => {
    const {fields, resolveComponent, depth, id} = props as InstellingInputProps<NestedValue>;
    const ismobile = useIsMobile();

    return (
        <Stack>
            <Padding paddingSize={ismobile ? 'large' : 'none'}>
                {fields.map((name) => (
                    <InstellingInputWrapper
                        depth={depth}
                        key={name}
                        resolveComponent={resolveComponent}
                        id={id}
                        name={name}
                    />
                ))}
            </Padding>
        </Stack>
    );
};
