import {useKeys} from '@growthbase/keys';
import {useCallbackRef} from '@growthbase/spa';
import * as React from 'react';
import {memo} from 'react';
import {useField, useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import {Subject} from 'rxjs';
import {FieldWrapperButtons} from '../FieldWrapperButtons';
import {Icons} from '../../../../../icons';
import {Buttons} from '../../../../../Button';

export interface FieldWrapperControlsProps {
    name: string;
    loading: boolean;
    isActive: boolean;
    isDirty: boolean;
    onReset: Subject<unknown>;
}

/**
 * @deprecated Don't use this, now what you are blurring.
 */
export const blur = () => {
    setTimeout(() => {
        const activeElement = document.activeElement as unknown as HTMLOrSVGElement;
        if (activeElement?.blur) {
            activeElement?.blur();
        }
    }, 1);
};

export const blurElement = (element: HTMLElement | null) => {
    const activeElement = document.activeElement as unknown as HTMLOrSVGElement;
    if (activeElement === element && activeElement?.blur) {
        activeElement?.blur();
    }
};

export const FieldWrapperControls = memo(({name, onReset, isDirty, isActive, loading}: FieldWrapperControlsProps) => {
    const [, {initialValue}] = useField(name);
    const {isSubmitting, resetForm} = useFormikContext();
    const {t} = useTranslation('spa_form.nl');

    const resetValue = useCallbackRef(() => {
        resetForm();
        onReset.next(initialValue);
    });

    useKeys('FieldWrapperControls: reset on escape', 'escape', resetValue, {
        enableOnInput: true,
        enabled: isDirty && isActive,
    });

    useKeys('FieldWrapperControls: blur on escape', 'escape', blur, {
        enableOnInput: true,
        enabled: isActive,
    });

    if (loading) {
        return (
            <FieldWrapperButtons>
                <Icons.LoadingAnimated />
            </FieldWrapperButtons>
        );
    }

    if (!isDirty) {
        return null;
    }

    return (
        <FieldWrapperButtons>
            <Buttons.Cross
                variant="dangerIcon"
                buttonType="reset"
                ariaLabel={t('cancel')}
                onMouseDown={resetValue}
                disabled={isSubmitting}
            />
            <Buttons.Check variant="primary" ariaLabel={t('save')} buttonType="submit" disabled={isSubmitting} />
        </FieldWrapperButtons>
    );
});

FieldWrapperControls.displayName = 'StyledFieldControls';
