import {
    ChangeType,
    PositieType,
    QueryCacheUpdateType,
    useConvertFetchEntity,
    useUpdateQueryCacheFromExternalEntityChanges,
} from '@growthbase/cache';
import {RenderApolloError} from '@growthbase/design-components';
import {
    IActiviteitenListFragment,
    PossibleTypes,
    refetchActiviteitenListAddQuery,
    useActiviteitenListQuery,
} from '@growthbase/graphql';
import {usePaginationSearchParams} from '@growthbase/routing';
import {usePollInterval} from '@growthbase/websockets';
import {useCallback, FC} from 'react';
import {ActiviteitenList} from './ActiviteitenList';

export interface ActiviteitenListConnectedProps {
    id?: string;
}

export const ActiviteitenListConnected: FC<ActiviteitenListConnectedProps> = ({id}) => {
    const {pagination, usePagination} = usePaginationSearchParams<IActiviteitenListFragment>('activiteiten');
    const result = useActiviteitenListQuery({
        pollInterval: usePollInterval(),
        variables: {
            id,
            ...pagination,
        },
    });

    useUpdateQueryCacheFromExternalEntityChanges<IActiviteitenListFragment>({
        result,
        determineAction: useCallback(
            (args) => {
                if (!PossibleTypes.Activiteit.includes(args.typename)) {
                    return QueryCacheUpdateType.ignore;
                }

                if (args.type !== ChangeType.changeOrder && args.node.targetId !== id) {
                    return QueryCacheUpdateType.ignore;
                }

                if (args.type === ChangeType.add) {
                    return QueryCacheUpdateType.refetch;
                }

                return QueryCacheUpdateType.default;
            },
            [id]
        ),
        positie: PositieType.before,
        fetchEntity: useConvertFetchEntity(refetchActiviteitenListAddQuery),
    });

    const helpers = usePagination(result);

    // TODO: add to list, errors can also be warnings for missing fields.
    if (result.error) {
        return <RenderApolloError error={result.error} />;
    }

    return <ActiviteitenList helpers={helpers} />;
};
