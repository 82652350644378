import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {IKoppelbaarType, IKoppelButtonGroepenFragment, IRelatieType} from '@growthbase/graphql';
import {ExternalLinkType} from '@growthbase/clientbox';
import {
    KoppelbaarItem,
    KoppelPopupItemGroupType,
    KoppelPopupRelatieGroupType,
    KoppelbaarItemType,
} from '../../../KoppelPopup';

interface NormalizeOptions {
    relatieverwijzingId?: string;
    groepen?: IKoppelButtonGroepenFragment[];
}

export const useNormalize = ({relatieverwijzingId, groepen}: NormalizeOptions) => {
    const {t} = useTranslation('spa_form.nl');
    return useMemo((): KoppelPopupRelatieGroupType[] => {
        if (!groepen) {
            return [];
        }
        const groupTitle = (type: IKoppelbaarType) => {
            const mapped = KoppelbaarItemType[type];
            switch (mapped) {
                case KoppelbaarItemType.Abonnement:
                    return t('itemgroep.abonnement');
                case KoppelbaarItemType.Opdracht:
                    return t('itemgroep.opdracht');
                case KoppelbaarItemType.Ticket:
                    return t('itemgroep.ticket');
                default:
                    return type;
            }
        };

        return groepen.map(
            (groep): KoppelPopupRelatieGroupType => ({
                id: groep.relatie.id,
                title: groep.relatie.label,
                displayTitle: !relatieverwijzingId || groepen.length > 1,
                relatie: {
                    persoon:
                        groep.relatie.type === IRelatieType.Persoon
                            ? {
                                  id: groep.relatie.id,
                                  label: groep.relatie.label,
                                  linkType: ExternalLinkType.Persoon,
                              }
                            : undefined,
                    bedrijf:
                        groep.relatie.type === IRelatieType.Bedrijf
                            ? {
                                  id: groep.relatie.id,
                                  label: groep.relatie.label,
                                  linkType: ExternalLinkType.Bedrijf,
                              }
                            : undefined,
                },
                itemgroepen: groep.items.map(
                    (itemgroep): KoppelPopupItemGroupType => ({
                        id: itemgroep.type,
                        title: groupTitle(itemgroep.type),
                        items: itemgroep.items.map(
                            (item): KoppelbaarItem => ({
                                id: item.id,
                                label: item.titel,
                                type: KoppelbaarItemType[itemgroep.type],
                            })
                        ),
                    })
                ),
            })
        );
    }, [groepen, relatieverwijzingId, t]);
};
