import {ReactNode} from 'react';
import {SwimlaneCreateFrom} from './Components';

export interface FooterProps {
    form: ReactNode;
}

export function Footer({form}: FooterProps) {
    return <>{form}</>;
}

Footer.SwimlaneCreateFrom = SwimlaneCreateFrom;
