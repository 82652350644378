import classNames from 'classnames/bind';
import {forwardRef, MouseEvent, Ref, useCallback} from 'react';
import {upperFirst} from 'lodash';
import {useHandleClickState, BaseButtonProps} from '../../Button';
import styles from './ActionButton.module.scss';
import {Tooltip} from '../../Tooltip';
import {Icons} from '../../icons';
import {AvatarSize} from '../../Avatar';

export interface ActionButtonProps extends BaseButtonProps {
    testId?: string;
    variant?: 'default' | 'danger';
    className?: string;
    buttonType?: 'button' | 'submit' | 'reset';
    size?: AvatarSize;
}

const cx = classNames.bind(styles);

export const ActionButton = forwardRef(
    (
        {
            testId,
            ariaLabel,
            Icon = Icons.Cross,
            className,
            variant = 'default',
            size = 'default',
            buttonType,
            onMouseDown,
            active,
            tooltip,
            ...rest
        }: ActionButtonProps,
        ref
    ) => {
        const {loading, disabled, onClick} = useHandleClickState(rest);
        // HTML/CSS class list
        const classes = cx(className, 'actionButton', {
            'actionButton--variantDanger': variant === 'danger',
            'actionButton--variantDangerActive': active && variant === 'danger',
            'actionButton--disabled': disabled,
            'actionButton--loading': loading,
            [`actionButton--size${upperFirst(size)}`]: true,
        });

        const handleOnClick = useCallback(
            (event: MouseEvent) => {
                event.stopPropagation();
                event.preventDefault();
                if (onClick) {
                    onClick();
                }
            },
            [onClick]
        );

        const Button = (
            <button
                data-testid={testId}
                ref={ref as Ref<HTMLButtonElement>}
                // eslint-disable-next-line react/button-has-type
                type={buttonType === undefined ? 'button' : buttonType}
                aria-label={ariaLabel ?? undefined}
                className={classes}
                onMouseDown={disabled ? undefined : onMouseDown}
                onClick={disabled ? undefined : handleOnClick}
            >
                {Icon && <Icon />}
            </button>
        );

        if (tooltip) {
            return <Tooltip content={tooltip}>{Button}</Tooltip>;
        }

        return Button;
    }
);

ActionButton.displayName = 'ActionButton';
