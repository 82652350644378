import {ReactNode, useRef} from 'react';
import classNames from 'classnames/bind';
import styles from './Title.module.scss';
import {TextColorMode} from '../../CalendarEvent';
import {Tooltip} from '../../../../../Tooltip';

const cx = classNames.bind(styles);

export interface TitleProps {
    children: ReactNode;
    colorMode: TextColorMode;
}

export const Title = ({children, colorMode}: TitleProps) => {
    const ref = useRef<HTMLDivElement>(null);

    const className = cx('title', {
        'title--light': colorMode === TextColorMode.LIGHT,
        'title--dark': colorMode === TextColorMode.DARK,
    });

    return (
        <div className={className} ref={ref} data-testid="CalendarEventTitle">
            <Tooltip content={children}>
                <span ref={ref}>{children}</span>
            </Tooltip>
        </div>
    );
};
