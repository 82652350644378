import {useTypedSearchParams} from '@growthbase/routing';
import {requiredString} from '@growthbase/spa';
import {TFunction} from 'i18next';
import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {SchemaOf} from 'yup';

interface Filters {
    deelnemers: string[] | null;
}

const Schema = (t: TFunction): SchemaOf<Filters> =>
    yup
        .object({
            deelnemers: yup.array(requiredString()).required().nullable(),
        })
        .label(t('takenFilters', {ns: 'spa_form.nl'}))
        .required();

const createEmptyFilterSchema = (): Filters => ({
    deelnemers: null,
});

const useFilterSchema = (): SchemaOf<Filters> => {
    const {t} = useTranslation();
    return useMemo(() => Schema(t), [t]);
};

export const useDeelnemersFilterState = (): [string[] | null, (deelnemers: string[] | null) => void] => {
    const [{deelnemers}, setFilters] = useTypedSearchParams<Filters>(
        'tfd',
        useFilterSchema(),
        useMemo(createEmptyFilterSchema, [])
    );
    return [
        deelnemers,
        useCallback((newDeelnemers: string[] | null) => setFilters({deelnemers: newDeelnemers}), [setFilters]),
    ];
};
