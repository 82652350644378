import {useMemo} from 'react';
import {DateInstellingInput, InstellingFieldsInput} from '@growthbase/instellingen';

export interface DTO {
    van: string;
    tot: string;
}

export const useFields = () =>
    useMemo(
        (): InstellingFieldsInput<DTO> => ({
            van: {
                label: 'Van',
                defaultValue: '00:00',
                InputComponent: DateInstellingInput,
            },
            tot: {
                label: 'Tot',
                defaultValue: '00:00',
                InputComponent: DateInstellingInput,
            },
        }),
        []
    );
