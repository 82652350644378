import {forwardRef, Ref} from 'react';
import classNames from 'classnames/bind';
import styles from './NavbarActionButton.module.scss';
import {BaseButtonProps} from '../../Button';

const cx = classNames.bind(styles);

export const NavbarActionButton = forwardRef(
    ({ariaLabel, disabled, readonly, onClick, onMouseDown, children}: BaseButtonProps, ref) => (
        <button
            className={cx('navbarActionButton', {
                'navbarActionButton--disabled': disabled,
                'navbarActionButton--readonly': readonly,
            })}
            ref={ref as Ref<HTMLButtonElement>}
            type="button"
            aria-label={ariaLabel ?? undefined}
            onClick={disabled ? undefined : onClick}
            onMouseDown={disabled ? undefined : onMouseDown}
        >
            {children}
        </button>
    )
);

NavbarActionButton.displayName = 'NavbarActionButton';
