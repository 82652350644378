import {Buttons, SearchForm, Stack, PageActionsMenu} from '@growthbase/design-components';
import React, {FC, ReactNode, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {BordRoutes} from '@growthbase/takenlijst/bord';
import {DeelnemersFilterConnected} from '@growthbase/taken';
import {OverviewFilters} from '../../types';

export interface HeaderProps {
    filter: OverviewFilters;
    loading: boolean;
    setFilter: (filter: OverviewFilters) => void;
    selectBord: ReactNode;
    DeelnemerFilter?: typeof DeelnemersFilterConnected;
    actions?: ReactNode;
}

export const Header: FC<HeaderProps> = ({
    setFilter,
    filter,
    loading,
    selectBord,
    DeelnemerFilter = DeelnemersFilterConnected,
    actions,
}) => {
    const {t} = useTranslation('spa-form.nl');
    return (
        <Stack gap="tiny" horizontal>
            <Stack.Item pushItems="after">
                <SearchForm
                    loading={loading}
                    value={useMemo(
                        () => ({
                            query: filter.searchQuery,
                        }),
                        [filter.searchQuery]
                    )}
                    onChange={useCallback(
                        (value) =>
                            setFilter({
                                ...filter,
                                searchQuery: value.query,
                            }),
                        [filter, setFilter]
                    )}
                />
            </Stack.Item>
            <Stack.Item>{selectBord}</Stack.Item>
            <Stack.Item>
                <DeelnemerFilter
                    deelnemers={filter.deelnemers}
                    setDeelnemers={(deelnemers) =>
                        setFilter({
                            ...filter,
                            deelnemers,
                        })
                    }
                />
            </Stack.Item>
            <Stack.Item>
                <Buttons.Add
                    key="add"
                    onClick={BordRoutes.aanmaken.useNavigate()}
                    tooltip={t('aanmaken')}
                    ariaLabel={t('aanmaken')}
                />
            </Stack.Item>
            {actions && (
                <Stack.Item>
                    <PageActionsMenu>{actions}</PageActionsMenu>
                </Stack.Item>
            )}
        </Stack>
    );
};
