import {FC} from 'react';
import {IBestandType} from '@growthbase/graphql';
import {Loader} from '@growthbase/design-components';
import {getIcon} from 'material-file-icons';
import {useBestandViewWithFallback} from '../OverlayBestandsView';
import styles from './BestandThumnail.module.scss';

export interface BestandThumbnailProps {
    id: string;
    type: IBestandType;
    path: string;
    url?: string | null;
    uploaded?: boolean;
}

/* eslint-disable */

export const BestandThumbnail: FC<BestandThumbnailProps> = ({path, uploaded, url, type, id}) => {
    const {open, slideshow} = useBestandViewWithFallback(id);

    const render = () => {
        if (!uploaded) {
            return <Loader center small />;
        }
        if (type === IBestandType.Afbeelding && url) {
            return <img src={url} onClick={open} alt={path} className={styles.BestandThumbnailImage} />;
        }
        return (
            <button
                type="button"
                onClick={open}
                className={styles.BestandThumbnailIconButton}
                dangerouslySetInnerHTML={{__html: getIcon(path).svg}}
            />
        );
    };

    return (
        <div className={styles.BestandThumbnail}>
            {slideshow}
            {render()}
        </div>
    );
};
