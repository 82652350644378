import {createTheme, ThemeProvider} from '@mui/material';
import {ReactNode} from 'react';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {nlNL, enUS} from '@mui/material/locale';
import {TextField, Switch, Select, Alert, AutoComplete, Chip, ButtonThemeOptions} from './components';

const lang = navigator.language === 'nl' ? nlNL : enUS;

type ExtraThemeOptions = {
    separateLabel?: boolean;
};

export type CBTheme = {
    options: ExtraThemeOptions;
};

declare module '@mui/material/styles' {
    interface Palette {
        border: {
            default: string;
        };
        primaryLight?: Palette['primary'];
    }

    interface PaletteOptions {
        primaryLight?: PaletteOptions['primary'];
        border?: {
            default?: string;
        };
    }
}

declare module '@mui/material' {
    interface ThemeOptions {
        options: ExtraThemeOptions;
    }
}

declare module '@mui/material/Switch' {
    interface SwitchPropsColorOverrides {
        primaryLight: true;
    }
}

let globalTheme = createTheme({
    options: {
        separateLabel: false,
    },
    typography: {
        fontFamily: 'Inter, Roboto, sans-serif',
    },
    components: {
        ...Alert,
        ...AutoComplete,
        ...ButtonThemeOptions,
        ...Chip,
        ...Select,
        ...Switch,
        ...TextField,
    },
    palette: {
        primary: {
            main: '#014544',
        },
        secondary: {
            main: '#ff9518',
        },
        border: {
            default: '#dfe3e9',
        },
        background: {
            default: '#f3f3f3',
        },
    },
    shape: {
        borderRadius: 3,
    },
    zIndex: {
        modal: 10000300,
        snackbar: 10000400,
        tooltip: 10000500,
    },
});

globalTheme = createTheme(
    globalTheme as unknown as CBTheme,
    {
        palette: {
            primaryLight: globalTheme.palette.augmentColor({
                color: {
                    main: '#029C99',
                },
                name: 'primaryLight',
            }),
        },
    },
    lang
);

export interface GlobalThemeProps {
    children: ReactNode;
}

export const GlobalTheme = ({children}: GlobalThemeProps) => (
    <ThemeProvider theme={globalTheme}>
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="nl">
            {children}
        </LocalizationProvider>
    </ThemeProvider>
);
