/* eslint-disable no-use-before-define */
import {TFunction} from 'i18next';
import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {SchemaOf} from 'yup';
import {
    IBedrijfMentionNodeFragment,
    IGebruikerMentionNodeFragment,
    IPersoonMentionNodeFragment,
} from '@growthbase/graphql';
import {removeNullAndFalsePropertiesArray} from '../Normalize/RemoveNullAndFalseProperties';

export type OpgemaakteTekstTekst = {
    text: string;
    bold?: boolean;
    code?: boolean;
    italic?: boolean;
    strikethrough?: boolean;
    subscript?: boolean;
    superscript?: boolean;
    underline?: boolean;
};

export type OpgemaakteTekstParagraph = {children: OpgemaakteTekstElement[]; type: 'p'};
export type OpgemaakteTekstAnchor = {children: OpgemaakteTekstElement[]; type: 'a'; url: string};
export type OpgemaakteTekstCode = {children: OpgemaakteTekstElement[]; type: 'code'};
export type OpgemaakteTekstCodeBlock = {children: OpgemaakteTekstElement[]; type: 'code_block'};
export type OpgemaakteTekstCodeLine = {children: OpgemaakteTekstElement[]; type: 'code_line'};
export type OpgemaakteTekstH1 = {children: OpgemaakteTekstElement[]; type: 'h1'};
export type OpgemaakteTekstH2 = {children: OpgemaakteTekstElement[]; type: 'h2'};
export type OpgemaakteTekstH3 = {children: OpgemaakteTekstElement[]; type: 'h3'};
export type OpgemaakteTekstUl = {children: OpgemaakteTekstElement[]; type: 'ul'};
export type OpgemaakteTekstLi = {children: OpgemaakteTekstElement[]; type: 'li'};
export type OpgemaakteTekstLic = {children: OpgemaakteTekstElement[]; type: 'lic'};
export type OpgemaakteTekstOl = {children: OpgemaakteTekstElement[]; type: 'ol'};
export type OpgemaakteTekstGebruikerMention = {
    children: OpgemaakteTekstElement[];
    type: 'gebruiker_mention';
    gebruiker: IGebruikerMentionNodeFragment;
};
export type OpgemaakteTekstBedrijfMention = {
    children: OpgemaakteTekstElement[];
    type: 'bedrijf_mention';
    bedrijf: IBedrijfMentionNodeFragment;
};
export type OpgemaakteTekstPersoonMention = {
    children: OpgemaakteTekstElement[];
    type: 'persoon_mention';
    persoon: IPersoonMentionNodeFragment;
};
export type OpgemaakteTekstBlockquote = {children: OpgemaakteTekstElement[]; type: 'blockquote'};

export type OpgemaakteTekstElement =
    | OpgemaakteTekstTekst
    | OpgemaakteTekstParagraph
    | OpgemaakteTekstAnchor
    | OpgemaakteTekstCode
    | OpgemaakteTekstCodeBlock
    | OpgemaakteTekstCodeLine
    | OpgemaakteTekstH1
    | OpgemaakteTekstH2
    | OpgemaakteTekstH3
    | OpgemaakteTekstUl
    | OpgemaakteTekstLi
    | OpgemaakteTekstLic
    | OpgemaakteTekstOl
    | OpgemaakteTekstGebruikerMention
    | OpgemaakteTekstBedrijfMention
    | OpgemaakteTekstPersoonMention
    | OpgemaakteTekstBlockquote;

export type OpgemaakteTekst = {
    elements: OpgemaakteTekstElement[];
};

export const OpgemaakteTekstSchema = (t: TFunction) =>
    yup.mixed().transform((value: OpgemaakteTekst) => {
        const elements = removeNullAndFalsePropertiesArray(value.elements);
        return {elements};
    }) as SchemaOf<OpgemaakteTekst>;

export const useOpgemaakteTekstSchema = (): SchemaOf<OpgemaakteTekst> => {
    const {t} = useTranslation();
    return useMemo(() => OpgemaakteTekstSchema(t), [t]);
};

export const useInitialValuesOpgemaakteTekst = (value?: OpgemaakteTekst | null) =>
    useCallback(
        () => ({
            elements: removeNullAndFalsePropertiesArray(value?.elements),
        }),
        [value]
    );
