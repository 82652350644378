import React, {useCallback} from 'react';
import Select, {components, SingleValue, StylesConfig, Theme} from 'react-select';

import classNames from 'classnames/bind';
import {TextField} from '@mui/material';
import {useDebouncedCallback} from '@growthbase/spa';
import {noop} from 'lodash';
import {StyledSelectOption} from '../StyledSelect';
import styles from './BoxSelect.module.scss';
import {Icons} from '../../../icons';
import {IconSize} from '../../../Icon';
import {ViewConfig} from '../../../BaseFieldProps';

const cx = classNames.bind(styles);

export interface BoxSelectSearchConfig {
    search?: string;
    changeSearch?: (search: string) => void;
    debounce?: number;
}

export interface BoxSelectProps<T> extends ViewConfig, BoxSelectSearchConfig {
    id: string;
    label: string;
    options: StyledSelectOption<T>[];
    value?: T;
    menuIsOpen?: boolean;
    clearable?: boolean;
    onChange?: (value?: T) => void;
}

const customStyles: StylesConfig = {
    control: (provided, state) => ({
        ...provided,
        outline: 0,
        boxShadow: 'none',
        borderWidth: 1,
        borderColor: 'var(--formBorderColor)',
        borderRadius: state.menuIsOpen ? 0 : 'var(--formBorderRadius)',
        background: state.isDisabled ? 'var(--formInputDisabledBackground)' : 'var(--formInputBackground)',
        cursor: state.isDisabled ? undefined : 'pointer',
        minHeight: 56,
        '&:hover': {},
    }),
    menuPortal: (provided, state) => ({
        ...provided,
    }),
    menu: (provided, state) => ({
        ...provided,
        border: '1px solid var(--formBorderColor)',
        boxShadow: 'none',
        marginTop: state.placement === 'bottom' ? '-1px' : 0,
        marginBottom: state.placement === 'top' ? '-1px' : 0,
        borderTopLeftRadius: state.placement === 'top' ? 'var(--formBorderRadius)' : 0,
        borderTopRightRadius: state.placement === 'top' ? 'var(--formBorderRadius)' : 0,
        borderBottomLeftRadius: state.placement === 'bottom' ? 'var(--formBorderRadius)' : 0,
        borderBottomRightRadius: state.placement === 'bottom' ? 'var(--formBorderRadius)' : 0,
        zIndex: 100,
    }),
    menuList: (provided, state) => ({
        ...provided,
        padding: 0,
    }),
    option: (provided, state) => ({
        ...provided,
    }),
    indicatorSeparator: (provided, state) => ({}),
    dropdownIndicator: (provided, state) => ({
        ...provided,
    }),
};

const customTheme = (theme: Theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: 'var(--colorPrimaryLighter)',
        primary75: 'var(--colorPrimaryLight20)',
        primary50: 'var(--colorPrimaryLight20)',
        primary25: 'var(--colorBlueGrayUltraLight)',
    },
});

const DropdownIndicator: typeof components.DropdownIndicator = (props) => <></>;

const ClearIndicator: typeof components.ClearIndicator = ({innerProps, getStyles}) => (
    <div {...innerProps} className={cx('boxSelect__clear')}>
        <Icons.Cross size={IconSize.SIZE_2} />
    </div>
);

const ValueContainer: typeof components.ValueContainer = ({innerProps, children}) => (
    <div {...innerProps} className={cx('boxSelect__valueContainer')}>
        {children}
    </div>
);

export function BoxSelect<T>({
    options,
    readonly,
    onChange,
    menuIsOpen,
    disabled,
    value,
    clearable,
    label,
    id,
    search,
    changeSearch,
    debounce = 300,
}: BoxSelectProps<T>) {
    const onChangeCallback = useCallback(
        (newValue: SingleValue<StyledSelectOption<T>>) => {
            if (onChange) {
                onChange(newValue?.value);
            }
        },
        [onChange]
    );

    const [debounceValue, debounceCallback] = useDebouncedCallback<string>(
        search ?? '',
        changeSearch ?? noop,
        debounce
    );

    if (readonly) {
        const option = options.find((o) => o.value === value);
        return <div>{option?.label}</div>;
    }

    const classes = cx('boxSelect', {
        'boxSelect--hasValue': value !== null && value !== undefined,
    });

    return (
        <div className={classes}>
            {changeSearch && (
                <>
                    TODO: mui search drop select gebruiken
                    <TextField
                        value={debounceValue}
                        onChange={(e) => {
                            debounceCallback(e.target.value);
                        }}
                        label={label}
                    />
                </>
            )}
            <label htmlFor={id} className={cx('boxSelect__label')}>
                {label}
            </label>
            <Select<StyledSelectOption<T>>
                theme={customTheme}
                styles={customStyles as StylesConfig<StyledSelectOption<T>>}
                id={id}
                placeholder=""
                onChange={onChangeCallback}
                value={options?.find((o) => o.value === value) ?? null}
                menuIsOpen={menuIsOpen}
                options={options}
                menuPlacement="auto"
                menuPosition="absolute"
                isSearchable={false}
                isClearable={clearable}
                isDisabled={disabled}
                components={{DropdownIndicator, ClearIndicator, ValueContainer}}
            />
        </div>
    );
}
