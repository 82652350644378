import {RouteElementProps} from '@growthbase/routing';
import {FC} from 'react';
import {TaakEditModalConnected, TaakEditFragment} from '../TaakEditModal';

export const TaakEditRoute: FC<RouteElementProps<{id: string}, {taak?: TaakEditFragment}>> = ({state, params}) => {
    if (!params || !params.id) {
        throw new Error('Missing id');
    }
    return <TaakEditModalConnected id={params.id} taak={state?.usr?.taak} />;
};
