import {QueryResult} from '@apollo/client/react/types/types';
import {useConvertFetchEntity, useUpdateQueryCacheFromExternalEntityChanges} from '@growthbase/cache';
import {
    IMijnTakenOverzichtQuery,
    IMijnTakenOverzichtQueryVariables,
    IMijnTakenOverzichtRowFragment,
    refetchMijnTakenOverzichtAddQuery,
} from '@growthbase/graphql';
import {determineTaakUpdateActionOnEntityChanges} from '@growthbase/taken';
import {idFromIri} from '@growthbase/routing';

export interface useMijnTaakTabelEntityChangesParams {
    searchQuery: string | null;
    result: QueryResult<IMijnTakenOverzichtQuery, IMijnTakenOverzichtQueryVariables>;
    mijnDag: boolean;
}

export const useMijnTaakTabelEntityChanges = ({mijnDag, result, searchQuery}: useMijnTaakTabelEntityChangesParams) => {
    const mijnDagLijstId = result?.data?.ingelogdeGebruiker?.mijnDagLijst.id
        ? idFromIri(result?.data?.ingelogdeGebruiker?.mijnDagLijst.id)
        : undefined;
    const mijnTakenLijstId = result?.data?.ingelogdeGebruiker?.mijnTakenLijst.id
        ? idFromIri(result?.data?.ingelogdeGebruiker?.mijnTakenLijst.id)
        : undefined;

    let lijstFilter: undefined | string;
    if (mijnDagLijstId !== undefined && mijnTakenLijstId !== undefined) {
        lijstFilter = mijnDag ? mijnDagLijstId : mijnTakenLijstId;
    }

    useUpdateQueryCacheFromExternalEntityChanges<IMijnTakenOverzichtRowFragment>({
        result,
        determineAction: (message) =>
            determineTaakUpdateActionOnEntityChanges(message, {
                searchQuery,
                filterByGebruikerIds: lijstFilter ? [lijstFilter] : undefined,
                filterGearchiveerd: false,
                filterVoltooid: false,
                reorderForList: lijstFilter ?? null,
                filterByLijstIds: lijstFilter ? [lijstFilter] : undefined,
            }),
        fetchEntity: useConvertFetchEntity(refetchMijnTakenOverzichtAddQuery),
    });
};
