import {BaseEventType} from '@growthbase/planning';
import {useCallback} from 'react';

export function eventFillGaps<T extends BaseEventType>(event: T, otherEvents: BaseEventType[]): T[] {
    const otherEventDates = otherEvents.map((otherEvent) => ({
        start: new Date(otherEvent.start),
        end: new Date(otherEvent.end),
    }));

    event = {
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
    };

    const newEvents: T[] = [{...event}];

    if (otherEvents.length === 0) {
        return newEvents;
    }

    for (const otherEventDate of otherEventDates) {
        const overlappingEvents: T[] = [];

        for (const newEvent of newEvents) {
            if (newEvent.start < otherEventDate.end && newEvent.end > otherEventDate.start) {
                // There is an overlap, so split the new event into non-overlapping parts
                if (newEvent.start < otherEventDate.start) {
                    overlappingEvents.push({
                        ...newEvent,
                        end: otherEventDate.start,
                    } as T);
                }

                if (newEvent.end > otherEventDate.end) {
                    overlappingEvents.push({
                        ...newEvent,
                        start: otherEventDate.end,
                    } as T);
                }
            } else {
                overlappingEvents.push(newEvent);
            }
        }

        newEvents.length = 0;
        newEvents.push(...overlappingEvents);
    }

    if (newEvents.length === 1 && newEvents[0].start === event.start && newEvents[0].end === event.end) {
        return newEvents;
    }

    // Filter out events that are too short (14min)
    return newEvents.filter((newEvent) => newEvent.start.getTime() - newEvent.end.getTime() < 14 * 60 * 1000) as T[];
}

export function useEventFillGaps<T extends BaseEventType>() {
    return useCallback((event: T, otherEvents: BaseEventType[]): T[] => eventFillGaps(event, otherEvents), []);
}
