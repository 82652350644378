import moment from 'moment';
import classNames from 'classnames/bind';
import styles from './CalendarDay.module.scss';

const cx = classNames.bind(styles);

export interface CalendarDayProps {
    date: Date;
    active?: boolean;
}

export const CalendarDay = ({date, active}: CalendarDayProps) => {
    const classes = cx('calendarDay', {
        'calendarDay--active': active,
    });
    return <div className={classes}>{moment(date).format('dd. D-M')}</div>;
};
