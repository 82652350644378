import {
    ButtonFormElement,
    Buttons,
    DefaultFormModalFooter,
    Field,
    Flex,
    OpgemaakteTekstInput,
    Padding,
    PaddingHorizontal,
    PositionType,
    Spacer,
    Stack,
    useIsMobile,
} from '@growthbase/design-components';
import {GebruikersComboboxIgnoreIds, DeelnemersInputConnected} from '@growthbase/gebruikers';
import {RelatieInputConnected, relatieIsEmpty} from '@growthbase/relaties';
import {useMemoProp} from '@growthbase/spa';
import React, {FC, PropsWithChildren} from 'react';
import {useTranslation} from 'react-i18next';
import {Box} from '@growthbase/design-elements';
import {TaakPlanningInput} from '../TaakPlanningInput';
import {CreateTaakFormProps, useCreateTaakForm} from './Hook/useCreateTaakForm';
import {KoppelButtonInputConnected} from '../Koppelen';

export interface TaakCreateFormProps extends CreateTaakFormProps, PropsWithChildren {
    availableActions: {
        kanOpdrachtAanTaakKoppelen: boolean;
        kanRelatieAanTaakKoppelen: boolean;
    };
    RelatieInput?: typeof RelatieInputConnected;
    OnderdeelVanComponent?: typeof KoppelButtonInputConnected;
}

export const TaakCreateForm: FC<TaakCreateFormProps> = ({
    children,
    RelatieInput = RelatieInputConnected,
    OnderdeelVanComponent = KoppelButtonInputConnected,
    availableActions,
    ...rest
}) => {
    const {t} = useTranslation('spa_form.nl');
    const {Form, Fields, formik, formProps, renderTitel, render} = useCreateTaakForm(rest);
    const {DeelnemersField, IsPriveField, PlanningField, OmschrijvingField, RelatieField, OnderdeelVanField} = Fields;

    const Planning = PlanningField.useComponent(TaakPlanningInput);
    const Omschrijving = OmschrijvingField.useComponent(OpgemaakteTekstInput);
    const OnderdeelVan = OnderdeelVanField.useComponent(OnderdeelVanComponent);
    const Relatie = RelatieField.useComponent(RelatieInput);
    const Prive = IsPriveField.useComponent(ButtonFormElement);

    const {
        values: {deelnemers, relatie, isPrive, onderdeelVan, planning},
    } = formik;
    const isMobile = useIsMobile();

    return render(
        <>
            <Form {...formProps}>
                <Stack gap="medium">
                    <Padding paddingSize={isMobile ? 'large' : 'none'}>
                        <Stack.Item>
                            <GebruikersComboboxIgnoreIds ids={useMemoProp(deelnemers, 'id')}>
                                {renderTitel({
                                    variant: 'big',
                                    autoFocus: true,
                                    controls: false,
                                })}
                            </GebruikersComboboxIgnoreIds>
                        </Stack.Item>
                    </Padding>

                    {isMobile && (
                        <Box
                            p={0.5}
                            bgcolor="background.default"
                            borderTop={1}
                            borderBottom={1}
                            borderColor="border.default"
                        />
                    )}

                    <Stack.Item>
                        <Stack horizontal={!isMobile} gap="default">
                            {Flex.createOrderedItems(
                                {
                                    start: {
                                        divider: true,
                                    },
                                },
                                ({add}) => {
                                    add(
                                        !isPrive && (
                                            <Field label={t('taak.table.deelnemers')} compact disableMargin>
                                                <DeelnemersField component={DeelnemersInputConnected} />
                                            </Field>
                                        ),
                                        PositionType.start
                                    );
                                    add(
                                        <Field
                                            label={t('taak.table.relation')}
                                            compact
                                            disableMargin
                                            hideLabel={relatieIsEmpty(relatie)}
                                        >
                                            <Relatie />
                                        </Field>,
                                        relatieIsEmpty(relatie) ? PositionType.end : PositionType.start
                                    );

                                    add(
                                        <Field
                                            label={t('taak.table.gekoppeld')}
                                            compact
                                            disableMargin
                                            hideLabel={!onderdeelVan}
                                        >
                                            <OnderdeelVan />
                                        </Field>,
                                        onderdeelVan ? PositionType.start : PositionType.end
                                    );

                                    add(
                                        planning?.from && (
                                            <Field label={t('taak.table.startDate')} compact disableMargin>
                                                <Planning asText textType="begindatum" />
                                            </Field>
                                        ),
                                        PositionType.start
                                    );
                                    add(
                                        planning?.to && (
                                            <Field label={t('taak.table.deadline')} compact disableMargin>
                                                <Planning asText />
                                            </Field>
                                        ),
                                        PositionType.start
                                    );
                                    add(
                                        !planning?.to && !planning?.from && (
                                            <Field label={t('taak.table.deadline')} compact disableMargin hideLabel>
                                                <Planning Button={Buttons.CalendarPlusIconDotted} />
                                            </Field>
                                        ),
                                        PositionType.end
                                    );

                                    add(
                                        <Field label={t('taak.table.prive')} compact disableMargin hideLabel>
                                            <Prive Button={Buttons.LockIconDotted} tooltip={t('taak.table.prive')} />
                                        </Field>,
                                        PositionType.end
                                    );
                                }
                            )}
                        </Stack>
                    </Stack.Item>

                    {isMobile && (
                        <Box
                            p={0.5}
                            bgcolor="background.default"
                            borderTop={1}
                            borderBottom={1}
                            borderColor="border.default"
                        />
                    )}

                    <PaddingHorizontal paddingSize={isMobile ? 'large' : 'none'}>
                        <Stack.Item>
                            <Omschrijving
                                floatingToolbar={false}
                                label={t('taak.omschrijving')}
                                placeholder={t('taak.omschrijvingPlaceholder')}
                            />
                            <Spacer size="medium" />
                        </Stack.Item>
                    </PaddingHorizontal>
                </Stack>
                {children}
            </Form>

            <PaddingHorizontal paddingSize={isMobile ? 'large' : 'none'}>
                <DefaultFormModalFooter formik={formik} negativeMargin />
            </PaddingHorizontal>
        </>
    );
};
