import {
    ApplicationServiceDependencies,
    BaseApplicationServices,
    BaseDependencies,
    IsReplayingServices,
    SpaVariablesDTO,
} from '@growthbase/spa';
import {createRoot} from 'react-dom/client';
import {WebsocketsServices} from '@growthbase/websockets';
import {GraphQLServices} from '@growthbase/graphql';
import {createServices, ServiceFactory} from './createServices';
import {ApplicationRoot} from './ApplicationRoot';

export async function bootstrap<TVariables extends SpaVariablesDTO, TAppServices = unknown>(
    deps: BaseDependencies<TVariables>,
    createApplicationServices: ServiceFactory<
        BaseApplicationServices<TVariables>,
        ApplicationServiceDependencies & TAppServices
    >
): Promise<void> {
    /* eslint-disable no-console */
    console.info('Initializing application...', deps);

    const services = createServices<TVariables>(deps, createApplicationServices);

    const {logger, config} = services;
    if (!services.config.boostrapEnabled) {
        logger.info('Skip bootstrapping', services);
        return;
    }
    const reactContainer = document.getElementById('app-container');
    if (reactContainer === null) {
        throw new Error('Could not bootstrap application: container element is missing.');
    }

    logger.info('Bootstrapping application with following services:', services);

    const graphqlServices = services as unknown as GraphQLServices;
    await graphqlServices.graphqlEnableLocalstorage(graphqlServices);

    const root = createRoot(reactContainer);
    root.render(<ApplicationRoot services={services} />);

    if (config.takenEnabled) {
        (services as unknown as IsReplayingServices).isReplayingService.startMonitoring();
        (services as unknown as WebsocketsServices).socketService
            .connect()
            .then(() => {
                logger.info('Started websocket service successfully');
            })
            .catch((error) => {
                logger.error('Failed to connect sockets', error);
            });
    }

    logger.info('Bootstrap successfully');

    (window as unknown as {services: unknown}).services = services;
}
