import {useMemo, FC} from 'react';
import {FieldWrapperControls, FieldWrapperControlsProps} from '../Components/FieldWrapperControls';

export const useFieldControls = (
    Controls?: FC<FieldWrapperControlsProps> | boolean
): FC<FieldWrapperControlsProps> | null =>
    useMemo(() => {
        if (!Controls) {
            return null;
        }
        if (Controls === true) {
            return FieldWrapperControls;
        }
        return Controls;
    }, [Controls]);
