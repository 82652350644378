import {KoppelbaarItem, KoppelbaarItemType} from '@growthbase/taken';
import {IBordOnderdeelVanFieldFragment} from '@growthbase/graphql';
import {ExternalLinkType, useExternalLinkFactory} from '@growthbase/clientbox';
import {useCallback, useMemo} from 'react';

export const useNormalizeBordOnderdeelVanFragmentFactory = (): ((
    fragment: IBordOnderdeelVanFieldFragment | null | undefined
) => KoppelbaarItem | null) => {
    const factory = useExternalLinkFactory();

    return useCallback(
        (fragment: IBordOnderdeelVanFieldFragment | null | undefined) => {
            const node = fragment?.onderdeelVan?.node;
            if (!node) {
                return null;
            }
            const type = fragment?.onderdeelVan?.itemType as KoppelbaarItemType | undefined;
            if (!type) {
                return null;
            }
            let href;
            switch (type) {
                case KoppelbaarItemType.Ticket:
                    href = factory({
                        id: node.id,
                        linkType: ExternalLinkType.Ticket,
                    });
                    break;
                case KoppelbaarItemType.Opdracht:
                    href = factory({
                        id: node.id,
                        linkType: ExternalLinkType.Opdracht,
                    });
                    break;
                case KoppelbaarItemType.Abonnement:
                    href = factory({
                        id: node.id,
                        linkType: ExternalLinkType.Abonnement,
                    });
                    break;
                default:
                    break;
            }

            return {
                id: node.id,
                type,
                label: node.titel,
                href: href || undefined,
            };
        },
        [factory]
    );
};

export const useNormalizeBordOnderdeelVanFragment = (
    fragment: IBordOnderdeelVanFieldFragment | null | undefined
): KoppelbaarItem | null => {
    const factory = useNormalizeBordOnderdeelVanFragmentFactory();
    return useMemo(() => factory(fragment), [factory, fragment]);
};
