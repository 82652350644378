import {createContext, ReactNode, useContext} from 'react';
import {useBoolean} from 'react-use';

export interface ComboBoxState {
    loading: boolean;

    setLoading: (loading: boolean) => void;
}

export const ComboboxContext = createContext<ComboBoxState | null>(null);

export const useComboBoxContext = (): ComboBoxState => {
    const context = useContext(ComboboxContext);

    if (context === null) {
        throw new Error('useComboBoxContext must be used within a ComboBoxContext');
    }

    return context;
};

export const useComboboxState: () => ComboBoxState = () => {
    const [isLoading, setIsLoading] = useBoolean(false);

    return {
        loading: isLoading,
        setLoading: setIsLoading,
    };
};

export const ComboBoxContextProvider = ({children}: {children: ReactNode}) => {
    const helpers = useComboboxState();
    return <ComboboxContext.Provider value={helpers}>{children}</ComboboxContext.Provider>;
};
