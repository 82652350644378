import ReactDOM from 'react-dom';
import {ReactiesTwigConnected} from '@growthbase/tickets-reacties';
import {interval, mergeWith} from 'rxjs';
import {pageRerender} from '../../rxjs';

export const loadTicketMessages = () =>
    pageRerender()
        .pipe(
            // We make sure we will find the elements in the DOM.
            mergeWith(interval(1000))
        )
        .subscribe({
            next: () => {
                document.querySelectorAll('.ticketSubComments:not(.react-rendered)').forEach((element) => {
                    element.classList.add('react-rendered');
                    const dataString = element.getAttribute('data-react-data');
                    const twigData = dataString && JSON.parse(dataString);
                    ReactDOM.render(<ReactiesTwigConnected data={twigData} />, element);
                });
            },
        });
