import {RoutesDTO} from '@growthbase/spa';
import {memo} from 'react';
import {createReactRoutes} from './Component/createReactRoutes';
import {createRouteHooksFromRoutes} from './Hook/createRouteHooksFromRoutes';
import {CreateRoutesResults, RouteHooks, RoutesConfig} from './Value';

export const LEGACY_ROUTE = () => null;

export function createRoutes<
    TRoutesKey extends keyof RoutesDTO,
    TRoutes extends RoutesDTO[TRoutesKey],
    TConfigs extends RoutesConfig<TRoutes>
>(routeKey: TRoutesKey, configs: TConfigs): CreateRoutesResults<TConfigs> {
    const results: Record<string, RouteHooks<never, never>> = {};

    Object.entries(configs).forEach(([name, config]) => {
        /**
         * The frontend application determines (In some cases) if a route is react-router based in the createRoutes config.
         */
        if (config.Element === LEGACY_ROUTE) {
            delete configs[name];
            return;
        }

        const key = name as keyof RoutesDTO[TRoutesKey];
        // @ts-expect-error should fix type hint.
        results[name] = createRouteHooksFromRoutes<TRoutesKey, typeof key, never, never>(routeKey, key, config);
    });
    const routeResults = {
        ...results,
        Routes: createReactRoutes<TRoutesKey, TRoutes, TConfigs>(routeKey, configs),
    } as unknown as CreateRoutesResults<TConfigs>;

    routeResults.decorate = (decorator, name) => {
        const Decorated = routeResults.Routes;
        const Created = memo(() => <>{decorator(Decorated)}</>);
        Created.displayName = name;
        routeResults.Routes = Created;
    };

    return routeResults;
}
