import React, {createContext, PropsWithChildren, useContext, useEffect, useRef} from 'react';
import {v4} from 'uuid';
import {
    ContainerNestedLevel,
    ContainerOptions,
    TreeId,
    useCloseContainer,
    useOpenContainer,
} from './Redux/activeReducer';

export interface ActiveItemRegionApi {
    treeId: TreeId;
    nestingLevel: ContainerNestedLevel;
}

export const ActiveItemRegionApiContext = createContext<ActiveItemRegionApi>(null as never);

export type ActiveItemRegionContainerProps = PropsWithChildren & ContainerOptions;

/**
 * The regions can be nested.
 */
export function ActiveItemRegionContainer({children, name, convert}: ActiveItemRegionContainerProps) {
    const parent = useContext(ActiveItemRegionApiContext);
    const api = useRef<ActiveItemRegionApi>({
        treeId: parent?.treeId ?? v4(),
        nestingLevel: (parent?.nestingLevel ?? -1) + 1,
    });
    const open = useOpenContainer();
    const close = useCloseContainer();
    useEffect(() => {
        const {current} = api;
        open({
            ...current,
            options: {
                name,
                convert,
            },
        });
        return () => {
            if (!current) {
                return;
            }
            close(current);
        };
    }, [open, close, name, convert, api]);
    return <ActiveItemRegionApiContext.Provider value={api.current}>{children}</ActiveItemRegionApiContext.Provider>;
}
