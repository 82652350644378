import React, {FC, useEffect, useState} from 'react';
import {useElements} from '@growthbase/clientbox';
import {PlaceholderPortal} from '@growthbase/design-components';
import {idFromIri} from '@growthbase/routing';
import {noop} from 'rxjs';
import {LegacyTheme} from '@growthbase/design-elements';
import {useCallbackRef} from '@growthbase/spa';
import {TaakSearchDTO, useExtractValueFromElement} from './Hook/useExtractValueFromElement';
import {TaakSearchFieldConnected} from './TaakSearchField/TaakSearchFieldConnected';

declare global {
    interface Window {
        /**
         * Currently we only support 1 field on the page
         *
         * @param data
         */
        updateTaakSearchField?: (data: TaakSearchDTO) => void;
    }
}

const Portal = ({element}: {element: Element}) => {
    const initialValue = useExtractValueFromElement(element);
    const [
        value,
        setValue,
    ] = useState<TaakSearchDTO>(initialValue);
    const {field} = initialValue;

    const [show, setShow] = useState(false);

    const parent = element.parentElement?.parentElement;
    if (parent) {
        parent.style.display = show ? 'block' : 'none';
    }
    useEffect(() => {
        const previous = window.updateTaakSearchField;
        window.updateTaakSearchField = (data: TaakSearchDTO) => {
            setValue({
                ...data,
                field: value.field,
            });
        };
        return () => {
            window.updateTaakSearchField = previous;
        };
    }, [
        setValue,
        value.field,
    ]);

    const {taakId} = value;
    const setTaakId = useCallbackRef((tid: string | null) => {
        setValue({
            ...value,
            taakId: tid,
        });
    });

    return (
        <PlaceholderPortal
            calculateSizeKey={show ? '1' : '2'}
            placeholderChildren={
                <input style={{display: 'none'}} value={idFromIri(taakId ?? '')} onChange={noop} name={field} />
            }
            placeholder={element}
            heightFrom="element"
            widthFrom="placeholder"
        >
            <LegacyTheme>
                <TaakSearchFieldConnected value={value} onChangeTaakId={setTaakId} setShow={setShow} />
            </LegacyTheme>
        </PlaceholderPortal>
    );
};

export const TaakSearchFieldConnector: FC = () => {
    const elements = useElements('[data-form-type="TaakSearchType"]');
    return (
        <>
            {elements.map(({id, element}) => (
                <Portal key={id} element={element} />
            ))}
        </>
    );
};
