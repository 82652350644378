import React, {ReactNode} from 'react';
import {useParams} from 'react-router-dom';
import {useLocation} from 'react-use';
import {RouteElementProps} from '../Value';

export interface RouteElementRendererProps<TParams, TState> {
    Element: (props: RouteElementProps<TParams, TState>) => ReactNode;
}

/**
 * When route is active but there is no background, it will set the background route.
 *
 * Passes the state and params to the Elements.
 */
export function RouteElementRenderer<TParams, TState>({Element}: RouteElementRendererProps<TParams, TState>) {
    const {state} = useLocation() as unknown as {state?: TState};
    const params = useParams() as unknown as TParams;

    document.dispatchEvent(new CustomEvent('route:element:rendered'));

    // @ts-expect-error Can use this as component.
    return <Element params={params} state={state} />;
}
