import {DNDContainerState, DNDItemData} from '../Value';
import {placeItemFromPlacement} from './placeItemFromPlacement';
import {placementFromDragOver} from './placementFromDragOver';

export function orderDraggingItemsFromPlacement(
    container: DNDContainerState,
    item: DNDItemData,
    itemOverId: string | null
) {
    const items = container.draggingItems ?? container.items;
    const placement = placementFromDragOver({
        activeId: item.id,
        overId: itemOverId,
        items,
    });
    container.draggingItems = placeItemFromPlacement<DNDItemData>({
        item,
        items,
        placement,
    });
}
