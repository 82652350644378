import {useMemo} from 'react';

export const useSuccessiveCallbacks = <TArgs extends unknown[], TReturn>(
    first: (...args: TArgs) => TReturn,
    second: () => void
) =>
    useMemo(
        () =>
            async (...args: TArgs) => {
                await first(...args);
                second();
            },
        [first, second]
    );
