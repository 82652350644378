import {ReactNode} from 'react';
import {CSSProperties} from 'styled-components';
import classNames from 'classnames/bind';
import styles from './StackItem.module.scss';

const cx = classNames.bind(styles);

export interface StackItemProps {
    children: ReactNode;
    hide?: boolean;
    grow?: boolean;
    shrink?: boolean;
    justify?: 'start' | 'end' | 'center';
    align?: 'start' | 'end' | 'center';
    label?: string;
    id?: string;
    pushItems?: 'before' | 'after';
    size?: number | string;
    overflow?: 'hidden' | 'visible' | 'scroll' | 'auto';
    style?: CSSProperties;
}

/** @deprecated use Stack.Item instead */
export const StackItem = ({
    children,
    grow,
    shrink,
    hide,
    justify,
    align,
    label,
    pushItems,
    size,
    overflow,
    id,
    style,
}: StackItemProps) => {
    if (hide) {
        return null;
    }
    style = {
        flexGrow: grow ? 1 : 0,
        flexShrink: shrink ? 1 : 0,
        flexBasis: size,
        alignSelf: align?.replace('start', 'flex-start').replace('end', 'flex-end'),
        justifySelf: justify?.replace('start', 'flex-start').replace('end', 'flex-end'),
        overflow,
        ...style,
    };
    const className = cx('stack-item', {
        'stackItem--pushItemsBefore': pushItems === 'before',
        'stackItem--pushItemsAfter': pushItems === 'after',
    });
    return (
        <div className={className} style={style} aria-label={label} id={id}>
            {children}
        </div>
    );
};
