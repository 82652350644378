import React, {memo, ReactNode} from 'react';
import {PaginationHelpers} from '@growthbase/spa';
import {useTranslation} from 'react-i18next';
import {Row} from 'react-table';
import {PaginationInfiniteScroll} from '../../../PaginationInfiniteScroll';
import {TableRowInterface} from '../TableRowInterface';
import {TableRow} from './TableRow';
import styles from '../Table.module.scss';
import {EmptyTableMessage} from './EmptyTableMessage/EmptyTableMessage';

export interface RenderRowProps<TRow extends TableRowInterface> {
    row: Row<TRow>;
}

export function defaultRenderRow<TRow extends TableRowInterface>({row}: RenderRowProps<TRow>) {
    return <TableRow key={row.id} row={row as unknown as Row<TableRowInterface>} />;
}

export type RenderRow<TRow extends TableRowInterface> = (props: RenderRowProps<TRow>) => React.ReactElement;

export interface TableBodyProps<TRow extends TableRowInterface> {
    rows: Row<TRow>[];
    prepareRow: (row: Row<TRow>) => void;
    renderRow?: RenderRow<TRow>;
    pagination: PaginationHelpers<TRow>;
    getRowId: (originalRow: TRow) => string;
    forwardedRef?: React.LegacyRef<never>;
    emptyMessage?: ReactNode;
    dragged?: boolean;
}

function TableBodyNormal<TRow extends TableRowInterface = TableRowInterface>({
    pagination,
    rows,
    prepareRow,
    renderRow = defaultRenderRow,
    getRowId,
    forwardedRef,
    emptyMessage,
    dragged,
    ...props
}: TableBodyProps<TRow>) {
    const {t} = useTranslation('spa.nl');
    return (
        <div className={styles.table__body} {...props} ref={forwardedRef}>
            {rows.length === 0 && (
                <EmptyTableMessage loading={pagination.isLoading}>
                    {emptyMessage ?? t('Tabel.GeenItems')}
                </EmptyTableMessage>
            )}
            <PaginationInfiniteScroll helpers={pagination} enabled={!dragged}>
                {rows.map((row) => {
                    prepareRow(row);
                    return renderRow({row});
                })}
            </PaginationInfiniteScroll>
        </div>
    );
}

const memoized = memo(TableBodyNormal);
memoized.displayName = 'TableBody';

export const TableBody = memoized as typeof TableBodyNormal;
