import {ReactNode, FC} from 'react';
import {IBordActionMenuFragment} from '@growthbase/graphql';
import {ActionsMenuButton, ViewConfig} from '@growthbase/design-components';
import {ActionMenu} from './ActionMenu';
import {ViewButton, VerwijderButtonConnected, ArchiveerButtonConnected} from './Components';
import {useBordRouteViewFactory} from '../Hook';
import {KopieerButtonConnected} from '../Kopieren';

export interface ActionMenuConnectedProps extends ViewConfig {
    hideBewerkButton?: boolean;
    hideViewButton?: boolean;
    fragment: IBordActionMenuFragment;
    children?: ReactNode;
}

export const ActionMenuConnected: FC<ActionMenuConnectedProps> = ({
    hideViewButton,
    hideBewerkButton,
    fragment,
    children,
    ...viewConfig
}) => {
    const factory = useBordRouteViewFactory();
    return (
        <ActionMenu
            hideBewerkButton={hideBewerkButton}
            bordId={fragment.id}
            renderVerwijderButton={
                fragment.availableActions.kanVerwijderen
                    ? (props) => <VerwijderButtonConnected {...viewConfig} {...props} fragment={fragment} />
                    : undefined
            }
            renderArchiveerButton={
                fragment.availableActions.kanArchiveren
                    ? (props) => <ArchiveerButtonConnected {...viewConfig} {...props} fragment={fragment} />
                    : undefined
            }
            renderKopierenButton={
                fragment.availableActions.KanKopieren
                    ? (props) => <KopieerButtonConnected {...viewConfig} {...props} fragment={fragment} />
                    : undefined
            }
            {...viewConfig}
        >
            {children}
            {hideViewButton ?? <ViewButton to={factory(fragment)} Button={ActionsMenuButton} />}
        </ActionMenu>
    );
};
