import {TFunction} from 'i18next';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {SchemaOf} from 'yup';
import {AvatarRowFieldItem} from '@growthbase/design-components';
import {KoppelbaarItem, NullableKoppelbaarItemSchema} from '@growthbase/taken';

export type CreateFormDTO = {
    bordtemplate: string;
    onderdeelVan: KoppelbaarItem | null;
    deelnemers: AvatarRowFieldItem[];
};

export const BordtemplateAlsBordToevoegenFormSchema = (t: TFunction): SchemaOf<CreateFormDTO> =>
    yup
        .object({
            bordtemplate: yup.string().required(),
            deelnemers: yup.array().label(t('deelnemers', {ns: 'Bordtemplate.nl'})) as SchemaOf<AvatarRowFieldItem[]>,
            onderdeelVan: NullableKoppelbaarItemSchema(t).label(t('onderdeelVan', {ns: 'TakenlijstBord.nl'})),
        })
        .required();

export const createFormValue = (): CreateFormDTO => ({
    bordtemplate: '',
    deelnemers: [],
    onderdeelVan: null,
});

export const useBordtemplateAlsBordToevoegenFormSchema = (): SchemaOf<CreateFormDTO> => {
    const {t} = useTranslation();
    return useMemo(() => BordtemplateAlsBordToevoegenFormSchema(t), [t]);
};
