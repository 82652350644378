import {FC, useEffect, useRef} from 'react';
import {useField} from 'formik';
import {FormikFieldProps} from '../FieldWrapper';
import {TextField} from './TextField';

export type TextFieldComponentProps = FormikFieldProps<string> & {
    autoFocus?: boolean;
    borderVisible?: boolean;
    variant?: 'default' | 'big';
};

export type TextFieldComponentNullableProps = FormikFieldProps<string | null>;

export const TextFieldComponent: FC<TextFieldComponentProps> = ({
    meta,
    field: {onChange, ...field},
    form,
    ...props
}) => {
    const [{value}, , {setValue}] = useField(field.name);
    const ref = useRef<HTMLInputElement | null>(null);
    const {isSubmitting} = form;
    const {autoFocus} = props;

    useEffect(() => {
        if (ref.current && autoFocus) {
            setTimeout(() => {
                ref.current?.focus();
            }, 1);
        }
    }, [isSubmitting, autoFocus]);

    return (
        <TextField {...field} value={value ?? ''} {...props} onBlur={props.onBlur} onChange={setValue} innerRef={ref} />
    );
};

export const TextFieldComponentNullable: FC<TextFieldComponentNullableProps> = ({
    meta,
    field: {onChange, ...field},
    form,
    ...props
}) => {
    const [{value}, , {setValue}] = useField(field.name);
    return <TextField {...field} value={value ?? ''} {...props} onBlur={props.onBlur} onChange={setValue} />;
};
