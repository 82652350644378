import {FC, ReactNode, useCallback, useMemo} from 'react';
import {Buttons} from '../Button';
import {
    Popup,
    PopupAnchorProps,
    PopupContent,
    PopupControlsContext,
    PopupHeader,
    useMemoryPopupControls,
} from '../Popups';
import {DateOptions, ISOStringDate, NullableISOStringDate} from './Hook';
import {DatePickerContent} from './DatePickerContent';
import {MinimumDateOptions} from '../Form';

export interface DatePickerProps {
    header?: ReactNode;
    labels?: Partial<DateOptions>;
    readonly?: boolean;
    disabled?: boolean;
    value?: NullableISOStringDate | null;
    onSubmit: (value: NullableISOStringDate) => Promise<void>;
    renderAnchor: (props: PopupAnchorProps) => ReactNode;
    minimumDate?: MinimumDateOptions;
}

export const DatePicker: FC<DatePickerProps> = ({
    onSubmit,
    header,
    renderAnchor,
    value,
    labels,
    readonly,
    disabled,
    minimumDate,
}) => {
    const controls = useMemoryPopupControls(false);
    const normalized = useMemo(
        (): ISOStringDate => ({
            date: value?.date ?? null,
        }),
        [value]
    );

    const defaultRenderAnchor = useCallback(
        ({reference, controls: {open}}: PopupAnchorProps) => (
            <Buttons.CalendarIconOnly ref={reference} onClick={open} readonly={readonly} disabled={disabled} />
        ),
        [disabled, readonly]
    );

    const normalizedSubmit = useCallback(
        (submitValues: NullableISOStringDate) =>
            onSubmit({
                date: submitValues.date?.date ? submitValues.date : null,
            }).finally(controls.close),
        [onSubmit, controls]
    );

    return (
        <PopupControlsContext.Provider value={controls}>
            <Popup renderAnchor={renderAnchor || defaultRenderAnchor} showOnRowHover arrow>
                <PopupContent>
                    <PopupHeader title={header} small />
                    <DatePickerContent
                        labels={labels}
                        value={normalized}
                        onSubmit={normalizedSubmit}
                        disabled={disabled}
                        minimumDate={minimumDate}
                    />
                </PopupContent>
            </Popup>
        </PopupControlsContext.Provider>
    );
};
