import {FC, ReactNode} from 'react';

import classNames from 'classnames';
import styles from './Center.module.scss';

export interface CenterProps {
    horizontal?: boolean;
    vertical?: boolean;
    children: ReactNode;
}

export const Center: FC<CenterProps> = ({horizontal = true, vertical = true, children}) => (
    <div
        className={classNames(styles.Center, {
            [styles.CenterHorizontal]: horizontal,
            [styles.CenterVertical]: vertical,
        })}
    >
        {children}
    </div>
);
