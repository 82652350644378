import {TFunction} from 'i18next';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {SchemaOf} from 'yup';
import {KoppelbaarItem, NullableKoppelbaarItemSchema} from '@growthbase/taken';
import {RelatieSchema, RelatieValue} from '@growthbase/relaties';
import {nullableNumber, nullableString} from '@growthbase/spa';

export interface Bord {
    id: string;
    naam: string;
}

export interface Lijst {
    id: string;
    titel: string;
    onderaan?: boolean;
}

export type VerplaatsFormDTO = {
    bord: string | null;
    positie: number | null;
    relatie: RelatieValue | null;
    onderdeelVan: KoppelbaarItem | null;
};

export const VerplaatsFormSchema = (t: TFunction): SchemaOf<VerplaatsFormDTO> =>
    yup
        .object({
            bord: nullableString().nullable(),
            positie: nullableNumber().nullable(),
            relatie: RelatieSchema(t).nullable(),
            onderdeelVan: NullableKoppelbaarItemSchema(t)
                .nullable()
                .label(t('onderdeelVan', {ns: 'TakenlijstBord.nl'})),
        })
        .label(t('formulier', {ns: 'TakenlijstLijst.nl'}))
        .required();

export const useVerplaatsSchema = (): SchemaOf<VerplaatsFormDTO> => {
    const {t} = useTranslation();
    return useMemo(() => VerplaatsFormSchema(t), [t]);
};
