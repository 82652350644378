import {RouteElementProps} from '@growthbase/routing';
// eslint-disable-next-line import/order
import {LandingsPaginaBordConnected} from '@growthbase/takenlijst/bord';
import {useTicketBordenConnectedQuery} from '@growthbase/graphql';
import {useGebruikerHeeftToegang} from '@growthbase/design-components';
import {DefaultKoppelbaarItem, DefaultRelatieValue} from '@growthbase/spa';
import {BordenPagina} from './BordenPagina';
import {useResolveOnderdeelVan} from './Hook/useResolveOnderdeelVan';

export type BordenPaginaConnectedProps = RouteElementProps<{
    ticketId: string;
    ticketnummer: string;
    ticketUuid: string;
}>;

export const BordenPaginaConnected = ({params}: BordenPaginaConnectedProps) => {
    if (params === undefined) {
        throw new Error('Logic exception.');
    }

    const {data, loading} = useTicketBordenConnectedQuery({
        variables: {
            id: params.ticketUuid,
        },
    });

    const toegang = useGebruikerHeeftToegang({
        toegang:
            data?.ingelogdeGebruiker?.availableActions.kanBordenBekijken &&
            data?.ticket?.availableActions?.volledigeGegevensBekijken,
        enabled: !loading,
    });
    const ticket = data?.ticket;
    const onderdeelVan = useResolveOnderdeelVan(params.ticketId, params.ticketUuid, ticket);
    if (!toegang) {
        return null;
    }
    return (
        <DefaultKoppelbaarItem.Provider id="BordenPaginaConnected" value={onderdeelVan}>
            <DefaultRelatieValue.Provider id="BordenPaginaConnected" value={onderdeelVan?.relatie}>
                <BordenPagina ticketNaam={ticket?.titel ?? params.ticketnummer} {...params}>
                    {ticket && onderdeelVan && <LandingsPaginaBordConnected />}
                </BordenPagina>
            </DefaultRelatieValue.Provider>
        </DefaultKoppelbaarItem.Provider>
    );
};
