import {ReactNode} from 'react';
import classNames from 'classnames/bind';
import styles from './RadioSwitchItem.module.scss';

const cx = classNames.bind(styles);

export interface RadioSwitchItemProps {
    children: ReactNode;
    last?: boolean;
    first?: boolean;
    active?: boolean;
    onClick?: () => void;
    square?: boolean;
    testId?: string;
}

export const RadioSwitchItem = ({children, first, last, active, onClick, square, testId}: RadioSwitchItemProps) => {
    const className = cx('radioSwitchItem', {
        'radioSwitchItem--first': first,
        'radioSwitchItem--last': last,
        'radioSwitchItem--active': active,
        'radioSwitchItem--square': square,
    });

    return (
        <button type="button" className={className} onClick={onClick} data-testid={testId}>
            {children}
        </button>
    );
};
