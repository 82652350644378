import {useEffect, useMemo, useState} from 'react';
import {interval, noop} from 'rxjs';
import {useCallbackRef} from '@growthbase/spa';
import moment from 'moment';
import {useActieveTijdRegistratieLazyQuery, useActieveTijdRegistratieQuery} from '@growthbase/planning';
import {
    useTimeRegistrationButtonConnectedQuery,
    ITimeRegistrationButtonConnectedProfielFragment,
} from '@growthbase/graphql';

import {compareIds} from '@growthbase/routing';

export interface LegacyTimeRegistrationOptions {
    taak: string;
}

export const useActiveTimeRegistration = () => {
    // See LegacyTimeRegistrationProvider, that does the actual fetching.
    const {data} = useActieveTijdRegistratieQuery({
        fetchPolicy: 'cache-only',
        nextFetchPolicy: 'cache-only',
    });
    const actieveTijdRegistratie = data?.actieveTijdRegistratie;
    return actieveTijdRegistratie;
};

export const useLegacyTimeRegistration = ({
    taak,
}: LegacyTimeRegistrationOptions): {id: string; taak: string; seconds: number} | null => {
    const actieveTijdRegistratie = useActiveTimeRegistration();
    const [seconds, setSeconds] = useState(0);
    const voorTaak =
        actieveTijdRegistratie && actieveTijdRegistratie.taakId && compareIds(actieveTijdRegistratie.taakId, taak);
    useEffect(() => {
        if (!voorTaak) {
            return noop;
        }
        const update = () => {
            const {
                van: {iso8601},
                secondsPassed,
            } = actieveTijdRegistratie;
            const from = moment(iso8601);
            const diff = moment().diff(from, 'seconds');
            setSeconds(diff + secondsPassed);
        };
        update();
        const subscription = interval(500).subscribe(() => update());
        return () => subscription.unsubscribe();
    }, [setSeconds, actieveTijdRegistratie, voorTaak]);

    return useMemo(
        (): {id: string; taak: string; seconds: number} | null =>
            actieveTijdRegistratie && voorTaak
                ? {
                      seconds,
                      id: actieveTijdRegistratie.id,
                      taak,
                  }
                : null,
        [actieveTijdRegistratie, taak, seconds, voorTaak]
    );
};

export const useLegacyTimeRegistrationIsActive = (
    taakId: string
): {
    actief: boolean;
    profiel: ITimeRegistrationButtonConnectedProfielFragment | null;
} => {
    const actieveTijdRegistratie = useActiveTimeRegistration();
    const {data} = useTimeRegistrationButtonConnectedQuery({
        nextFetchPolicy: 'cache-only',
    });
    return {
        actief: !!(actieveTijdRegistratie?.taakId ? compareIds(actieveTijdRegistratie.taakId, taakId) : false),
        profiel: data?.huidigProfiel || null,
    };
};

export const useUpdateActiveTimeregistration = () => {
    const [fetch] = useActieveTijdRegistratieLazyQuery({});
    return useCallbackRef(() =>
        fetch({
            nextFetchPolicy: 'network-only',
            fetchPolicy: 'network-only',
        })
    );
};
