import {NullableISOStringDateRange} from '@growthbase/design-components';
import {useMemo} from 'react';
import moment from 'moment';

moment.locale('nl');

export const usePlanningText = (
    textType?: 'deadline' | 'begindatum',
    value?: NullableISOStringDateRange
): string | null =>
    useMemo(() => {
        if (!value) {
            return null;
        }
        if (textType === 'deadline' && value.to) {
            const instance = moment(value.to.date);
            return instance.format(value.to.timeUsed ? 'L - LT' : 'L');
        }
        if (textType === 'begindatum' && value.from) {
            const instance = moment(value.from.date);
            return instance.format(value.from.timeUsed ? 'L - LT' : 'L');
        }
        return null;
    }, [textType, value]);
