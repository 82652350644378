import {ChangeEvent, FC, InputHTMLAttributes, Ref} from 'react';
import classNames from 'classnames/bind';
import {capitalize} from 'lodash';
import {useCallbackRef} from '@growthbase/spa';
import styles from './TextField.module.scss';

const cx = classNames.bind(styles);

/**
 * Value in the format of hh:mm
 */
export interface TextFieldProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    variant?: 'default' | 'big';
    onChange?: (value: string) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    value: string;
    disableRoundedCorners?: boolean;
    disabled?: boolean;
    hasError?: boolean;
    hasControls?: boolean;
    name?: string;
    readonly?: boolean;
    isInlineEditable?: boolean;
    isActive?: boolean;
    innerRef?: Ref<HTMLInputElement>;
}

export const TextField: FC<TextFieldProps> = ({
    disableRoundedCorners,
    value: valueProp,
    disabled,
    onChange,
    onFocus,
    onBlur,
    hasError,
    hasControls,
    name,
    isInlineEditable,
    isActive,
    variant = 'default',
    readonly,
    innerRef,
    ...rest
}) => {
    const classes = cx('textField', {
        // Boolean classes
        'textField--disableRoundedCorners': disableRoundedCorners,
        'textField--hasError': hasError,
        'textField--hasControls': hasControls,
        'textField--isInlineEditable': isInlineEditable,
        'textField--isActive': isActive,
        'textField--isReadonly': readonly,

        // Choice classes
        [`textField--variant${capitalize(variant)}`]: true,
    });

    const handleInputChangeField = useCallbackRef((changedValue: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            let {value} = changedValue.target as {value: string | number | null};

            if (rest.type === 'number') {
                value = typeof value === 'string' ? parseInt(value, 10) : value;
                if (Number.isNaN(value)) {
                    value = null;
                }
            }
            // @ts-expect-error TODO: create different component for number input? @BAS
            onChange(value);
        }
    });

    return (
        <div className={classes}>
            <input
                ref={innerRef}
                className={styles.textField__input}
                disabled={!!disabled || readonly}
                value={valueProp ?? ''}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={handleInputChangeField}
                name={name}
                type="text"
                {...rest}
            />
        </div>
    );
};
