import {FieldInstance, ValueType} from '../contract';

export const findBy = (
    isMatch: (condition: FieldInstance) => boolean,
    field: FieldInstance
): FieldInstance | undefined => {
    if (isMatch(field)) {
        return field;
    }
    if (field.type === ValueType.condition) {
        return findBy(isMatch, field.value as FieldInstance);
    }
    if (field.type === ValueType.conditions) {
        for (const f of field.value as FieldInstance[]) {
            const result = findBy(isMatch, f);
            if (result) {
                return result;
            }
        }
    }
    return undefined;
};

export const findByName = (name: string, field: FieldInstance): FieldInstance | undefined =>
    findBy((condition) => condition.name === name, field);
export const findByType = (type: ValueType, field: FieldInstance): FieldInstance | undefined =>
    findBy((condition) => condition.type === type, field);
