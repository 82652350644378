import {
    AvatarRowFieldItem,
    opgemaakteTekstFindElementsByType,
    useRemoveNodesByType,
} from '@growthbase/design-components';
import {GebruikersMentionKey, ComboboxGebruiker} from '@growthbase/gebruikers';
import {FormikContextType} from 'formik';
import {useEffect} from 'react';
import {MaakTaakAanTaakFormDTO} from '../TaakCreateFormSchema';

export interface StripGebruikersFromTitelProps {
    formik: FormikContextType<MaakTaakAanTaakFormDTO>;
}

export const useStripGebruikersFromTitel = ({formik}: StripGebruikersFromTitelProps) => {
    const removeNodesByType = useRemoveNodesByType();
    const {
        setFieldValue,
        values: {titel, deelnemers: currentDeelnemers},
    } = formik;

    useEffect(() => {
        const all = opgemaakteTekstFindElementsByType(
            GebruikersMentionKey,
            titel
            // @ts-expect-error We almost sure this is not null
        ).map((found): ComboboxGebruiker => found.gebruiker);
        if (all.length === 0) {
            return;
        }
        const deelnemers: AvatarRowFieldItem[] = all
            .map(
                (gebruiker): AvatarRowFieldItem => ({
                    id: gebruiker.id,
                    naam: gebruiker.label,
                    imageUrl: gebruiker.imgUrl,
                })
            )
            .filter((deelnemer) => !currentDeelnemers.find((d) => d.id === deelnemer.id));
        setFieldValue('deelnemers', [
            ...currentDeelnemers,
            ...deelnemers,
        ]);
        removeNodesByType(GebruikersMentionKey);
    }, [currentDeelnemers, removeNodesByType, setFieldValue, titel]);
};
