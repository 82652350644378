import {useCallbackRef} from '@growthbase/spa';
import {useEffect} from 'react';
import {useMessageListenerRegistry} from './useMessageListenerRegistry';

export const useSocketEvent = (type: string, messageCallback: (message: unknown) => void): void => {
    const messageCallbackRef = useCallbackRef(messageCallback);
    const registry = useMessageListenerRegistry();
    return useEffect(() => {
        registry.add(type, messageCallbackRef);

        return () => {
            registry.remove(type, messageCallbackRef);
        };
    }, [messageCallbackRef, registry, type]);
};
