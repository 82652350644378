import {useHandleError} from './useHandleError';
import {useCallbackRef} from '../../Hook';

export const useWrapMutationWithErrorHandler = <TArgs extends unknown[]>([
    target,
]: [
    (...args: TArgs) => Promise<unknown>,
    ...unknown[]
]): ((...args: TArgs) => Promise<void>) => {
    const handleError = useHandleError();
    return useCallbackRef(
        (...args: TArgs): Promise<void> =>
            target(...args)
                .catch((error: unknown) => {
                    handleError(error);
                })
                .then(() => Promise.resolve())
    );
};

export const useWrapFunctionWithErrorHandler = <TArgs extends unknown[], TReturn = undefined>(
    target: (...args: TArgs) => Promise<TReturn>,
    whenError: TReturn
): ((...args: TArgs) => Promise<TReturn>) => {
    const handleError = useHandleError();
    return useCallbackRef(
        (...args: TArgs): Promise<TReturn> =>
            target(...args).catch((error: unknown) => {
                handleError(error);
                return whenError;
            })
    );
};

export const useWrapFunctionWithVoidErrorHandler = <TArgs extends unknown[], TReturn>(
    target: (...args: TArgs) => Promise<TReturn>,
    callback?: (value: TReturn | null) => void
): ((...args: TArgs) => void) => {
    const handleError = useWrapFunctionWithErrorHandler(target, null);
    return useCallbackRef((...args: TArgs): void => {
        // ignore promise, errors are handled.
        handleError(...args).then((result) => {
            if (callback) {
                callback(result);
            }
        });
    });
};
