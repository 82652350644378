import {
    DND,
    DNDContainerUserData,
    DNDItemUserData,
    DNDUserDataType,
    onDropItemHandler,
    onRemoveItemHandler,
    RenderDraggableItem,
} from '@growthbase/keys';
import {PaginationHelpers} from '@growthbase/spa';
import {noop} from 'lodash';
import React, {memo} from 'react';
import {PaginationInfiniteScroll} from '../../../../PaginationInfiniteScroll';
import {defaultRenderBody, RenderBody} from '../../Table';
import {TableRowInterface} from '../../TableRowInterface';
import {defaultDraggableTableRow, DraggableTableRow} from '../DraggableTableRow';

export type DraggableTableRowInterface = TableRowInterface & DNDItemUserData;

export interface RenderDraggableTableProps<TRow extends DraggableTableRowInterface> {
    testId?: string | null;
    pagination: PaginationHelpers<TRow>;

    renderBody?: RenderBody<TRow>;
}

export type RenderDraggableTable<TRow extends DraggableTableRowInterface> = (
    props: RenderDraggableTableProps<TRow>
) => React.ReactElement;

const asyncNoop = async () => noop();

export interface DraggableTableProps<
    Type extends DNDUserDataType,
    TRow extends DraggableTableRowInterface,
    TContainerData extends DNDContainerUserData
> {
    pagination: PaginationHelpers<TRow>;

    userData: TContainerData;

    type: Type;
    onDrop?: onDropItemHandler<TRow, TContainerData>;

    onRemove?: onRemoveItemHandler<TRow>;

    renderBody?: RenderBody<TRow>;

    renderRow?: DraggableTableRow<Type, TRow>;

    children: RenderDraggableTable<TRow>;

    renderDraggedItem: RenderDraggableItem<Type, TRow>;
}

function DraggableTableNormal<
    Type extends DNDUserDataType,
    TRow extends DraggableTableRowInterface,
    TContainerData extends DNDContainerUserData
>({
    children,
    pagination,
    onDrop,
    onRemove,
    userData,
    type,
    renderDraggedItem,
    renderBody = defaultRenderBody,
    renderRow = defaultDraggableTableRow,
}: DraggableTableProps<Type, TRow, TContainerData>) {
    return (
        <DND.Container<Type, TRow, TContainerData>
            userDataType={type}
            userData={userData}
            renderDraggedItem={renderDraggedItem}
            items={pagination.connections}
            onDrop={onDrop ?? asyncNoop}
            onRemove={onRemove}
        >
            {({items, element: {ref, ...rest}}) => (
                <>
                    {children({
                        pagination: {
                            ...pagination,
                            totalCount: Math.max(items.length, pagination.totalCount),
                            connections: items.map((item) => item.userData),
                        },
                        renderBody: (props) => (
                            <PaginationInfiniteScroll helpers={pagination}>
                                {renderBody({
                                    forwardedRef: ref,
                                    ...props,
                                    ...rest,
                                    renderRow: ({row}) => {
                                        const found = items.find((item) => item.userData.id === row.id);
                                        if (!found) {
                                            return <React.Fragment key={row.id} />;
                                        }
                                        return (
                                            <DND.Item key={row.id} item={found}>
                                                {({...itemProps}) =>
                                                    renderRow({
                                                        row,
                                                        ...itemProps,
                                                    })
                                                }
                                            </DND.Item>
                                        );
                                    },
                                })}
                            </PaginationInfiniteScroll>
                        ),
                    })}
                </>
            )}
        </DND.Container>
    );
}

const memorized = memo(DraggableTableNormal);
memorized.displayName = 'DraggableTable';

export const DraggableTable = memorized as typeof DraggableTableNormal;
