import {ThemeOptions} from '@mui/material/styles/createTheme';

export const InputLabel: ThemeOptions['components'] = {
    MuiInputLabel: {
        styleOverrides: {
            root: {
                fontFamily: 'Inter',
                textAlign: 'left',
                color: 'gray',
                fontSize: '12px',
                lineHeight: '27px',
            },
        },
    },
};
