import {LogbookActivityList, QueryTabs, Tab, TabsRendererMarginLeft} from '@growthbase/design-components';
import {ILogbookFragment} from '@growthbase/graphql';
import {PaginationHelpers} from '@growthbase/spa';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {LogbookItem} from './LogbookItem';

export interface LogbookProps {
    helpers: PaginationHelpers<ILogbookFragment>;
}

export const Logbook: FC<LogbookProps> = ({helpers}) => {
    const {t} = useTranslation('spa.nl');

    return (
        <QueryTabs render={TabsRendererMarginLeft} name="sidelinks" defaultValue="links">
            <Tab title={t('Logboek')} value="links">
                <LogbookActivityList pagination={helpers}>{LogbookItem}</LogbookActivityList>
            </Tab>
        </QueryTabs>
    );
};
