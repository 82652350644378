/* eslint-disable no-use-before-define */
import {
    AND,
    OR,
    NOTMultiple,
    BOOLEAN,
    Conditions,
    Field,
    FieldBoolean,
    FieldComponentProps,
    FieldText,
    TEXT,
    TEXTS,
    FieldDate,
    FieldInstance,
    createFieldInstance,
    DATE,
    NUMBER,
    FieldNumber,
} from '@growthbase/querybuilder';
import {ILogLevel} from '@growthbase/graphql';
import {FC, PropsWithChildren, useMemo} from 'react';
import {find, isString} from 'lodash';
import {LogLevelSelect} from '../../../../Projections/LogLevelSelect';
import {LogFilter} from './Hooks/useFilterBarState';

export const QUERY: Field<string> = {
    name: 'query',
    label: () => 'Query',
    valueType: TEXT,
    Component: FieldText,
};

const LLC: FC<FieldComponentProps<string[]>> = ({onChange, value}) => (
    <LogLevelSelect value={(value as ILogLevel[]) ?? []} onChange={onChange} />
);

export const LEVELS: Field<string[]> = {
    name: 'levels',
    label: () => 'Levels',
    valueType: {
        ...TEXTS,
        defaultValue: () => [
            ILogLevel.Error,
            ILogLevel.Critical,
            ILogLevel.Emergency,
            ILogLevel.Warning,
            ILogLevel.Info,
            ILogLevel.Debug,
            ILogLevel.Notice,
            ILogLevel.Alert,
        ],
    },
    Component: LLC,
};

export const FACILITY: Field<string> = {
    name: 'Facility',
    label: () => 'Facility',
    valueType: TEXT,
    Component: FieldText,
};

export const PROFILE: Field<string> = {
    name: 'profielId',
    label: () => 'Profiel Id',
    valueType: TEXT,
    Component: FieldText,
};

export const GROWTHBASE: Field<boolean> = {
    name: 'growthbase',
    label: () => 'Growthbase',
    valueType: BOOLEAN,
    Component: FieldBoolean,
};

export const CLIENTBOX: Field<boolean> = {
    name: 'clientbox',
    label: () => 'Clientbox',
    valueType: BOOLEAN,
    Component: FieldBoolean,
};

export const GEBRUIKER: Field<string> = {
    name: 'gebruikerId',
    label: () => 'Gebruiker Id',
    valueType: TEXT,
    Component: FieldText,
};

export const PROCESS: Field<string> = {
    name: 'processId',
    label: () => 'Process Id',
    valueType: TEXT,
    Component: FieldText,
};

export const PREVIOUSPROCESS: Field<string> = {
    name: 'previousProcessId',
    label: () => 'Previous Process Id',
    valueType: TEXT,
    Component: FieldText,
};

export const ERROR: Field<string> = {
    name: 'errorId',
    label: () => 'Error Id',
    valueType: TEXT,
    Component: FieldText,
};

export const ERRORCODE: Field<string> = {
    name: 'errorCode',
    label: () => 'Error Code',
    valueType: TEXT,
    Component: FieldText,
};

export const DATEFROM: Field<Date> = {
    name: 'date from',
    label: () => 'Van',
    valueType: DATE,
    Component: FieldDate,
};

export const DATETO: Field<Date> = {
    name: 'date to',
    label: () => 'Tot',
    valueType: DATE,
    Component: FieldDate,
};

export const LASTMINUTES: Field<number> = {
    name: 'last minutes',
    label: () => 'Laatste minuten',
    valueType: {
        ...NUMBER,
        defaultValue: () => 5,
    },
    Component: FieldNumber,
};

export const TRANSACTION_ID: Field<string> = {
    name: 'transactionId',
    label: () => 'Transaction Id',
    valueType: TEXT,
    Component: FieldText,
};

export const TRANSACTION_DEPTH: Field<number> = {
    name: 'transactionDepth',
    label: () => 'Transaction Depth',
    valueType: NUMBER,
    Component: FieldNumber,
};

export const REQUEST_URI: Field<string> = {
    name: 'requestUri',
    label: () => 'Request Uri',
    valueType: TEXT,
    Component: FieldText,
};

export const REQUEST_ROUTE: Field<string> = {
    name: 'requestRoute',
    label: () => 'Request Route',
    valueType: TEXT,
    Component: FieldText,
};

export const REQUEST_METHOD: Field<string> = {
    name: 'requestMethod',
    label: () => 'Request Method',
    valueType: TEXT,
    Component: FieldText,
};

export const REQUEST_IP: Field<string> = {
    name: 'requestIp',
    label: () => 'Request Ip',
    valueType: TEXT,
    Component: FieldText,
};

export const CLI_COMMAND: Field<string> = {
    name: 'cliCommand',
    label: () => 'Cli Command',
    valueType: TEXT,
    Component: FieldText,
};

export const FIELDS = [
    QUERY,
    LEVELS,
    FACILITY,
    PROFILE,
    GROWTHBASE,
    CLIENTBOX,
    GEBRUIKER,
    PROCESS,
    PREVIOUSPROCESS,
    ERROR,
    ERRORCODE,
    DATEFROM,
    DATETO,
    LASTMINUTES,
    TRANSACTION_ID,
    TRANSACTION_DEPTH,
    CLI_COMMAND,
    REQUEST_URI,
    REQUEST_ROUTE,
    REQUEST_METHOD,
    REQUEST_IP,
    AND,
    OR,
    NOTMultiple,
] as Field[];

export interface FilterBarProps extends PropsWithChildren {
    onChange: (filter: LogFilter[]) => void;
    value: LogFilter[];
}

function normalizeMultiple(filters: LogFilter[]): FieldInstance[] {
    const normalizedMultiple = filters.map(normalize);
    return normalizedMultiple.flat(1);
}

function normalize(filters: LogFilter): FieldInstance[] {
    const fields: FieldInstance<unknown>[] = [];
    const {
        id,
        query,
        and,
        levels,
        errorCodes,
        facilities,
        or,
        errorIds,
        gebruikerId,
        not,
        growthbase,
        profielId,
        processIds,
        clientbox,
        from,
        to,
        lastMinutes,
        transactionDepth,
        transactionIds,
        previousProcessIds,
        requestIp,
        requestMethod,
        requestRoute,
        requestUri,
        cliCommand,
    } = filters;
    if (typeof query === 'string') {
        fields.push(createFieldInstance({fields: FIELDS}, QUERY, () => query, id));
    }
    if (levels) {
        fields.push(createFieldInstance({fields: FIELDS}, LEVELS, () => levels, id));
    }
    if (facilities) {
        fields.push(createFieldInstance({fields: FIELDS}, FACILITY, () => facilities[0], id));
    }
    if (typeof profielId === 'string') {
        fields.push(createFieldInstance({fields: FIELDS}, PROFILE, () => profielId, id));
    }
    if (typeof growthbase === 'boolean') {
        fields.push(createFieldInstance({fields: FIELDS}, GROWTHBASE, () => growthbase, id));
    }
    if (typeof clientbox === 'boolean') {
        fields.push(createFieldInstance({fields: FIELDS}, CLIENTBOX, () => clientbox, id));
    }
    if (typeof gebruikerId === 'string') {
        fields.push(createFieldInstance({fields: FIELDS}, GEBRUIKER, () => gebruikerId, id));
    }
    if (processIds) {
        fields.push(createFieldInstance({fields: FIELDS}, PROCESS, () => processIds[0], id));
    }
    if (previousProcessIds) {
        fields.push(createFieldInstance({fields: FIELDS}, PREVIOUSPROCESS, () => previousProcessIds[0], id));
    }
    if (errorIds) {
        fields.push(createFieldInstance({fields: FIELDS}, ERROR, () => errorIds[0], id));
    }
    if (errorCodes) {
        fields.push(createFieldInstance({fields: FIELDS}, ERRORCODE, () => errorCodes[0], id));
    }
    if (and) {
        fields.push(createFieldInstance({fields: FIELDS}, AND, () => normalizeMultiple(and), id));
    }
    if (or) {
        fields.push(createFieldInstance({fields: FIELDS}, OR, () => normalizeMultiple(or), id));
    }
    if (transactionIds) {
        fields.push(createFieldInstance({fields: FIELDS}, TRANSACTION_ID, () => transactionIds[0], id));
    }
    if (transactionDepth) {
        fields.push(createFieldInstance({fields: FIELDS}, TRANSACTION_DEPTH, () => transactionDepth[0], id));
    }
    if (from) {
        fields.push(createFieldInstance({fields: FIELDS}, DATEFROM, () => new Date(from), id));
    }
    if (to) {
        fields.push(createFieldInstance({fields: FIELDS}, DATETO, () => new Date(to), id));
    }
    if (typeof lastMinutes === 'number') {
        fields.push(createFieldInstance({fields: FIELDS}, LASTMINUTES, () => lastMinutes, id));
    }
    if (isString(cliCommand)) {
        fields.push(createFieldInstance({fields: FIELDS}, CLI_COMMAND, () => cliCommand, id));
    }
    if (isString(requestUri)) {
        fields.push(createFieldInstance({fields: FIELDS}, REQUEST_URI, () => requestUri, id));
    }
    if (isString(requestRoute)) {
        fields.push(createFieldInstance({fields: FIELDS}, REQUEST_ROUTE, () => requestRoute, id));
    }
    if (isString(requestMethod)) {
        fields.push(createFieldInstance({fields: FIELDS}, REQUEST_METHOD, () => requestMethod, id));
    }
    if (isString(requestIp)) {
        fields.push(createFieldInstance({fields: FIELDS}, REQUEST_IP, () => requestIp, id));
    }

    if (not) {
        return [
            // This is a AND, see denormalize.
            createFieldInstance({fields: FIELDS}, NOTMultiple, () => fields[0].value as FieldInstance[], id),
        ];
    }
    return fields;
}

const denormalize = (fields: FieldInstance[]): LogFilter[] => {
    const filters: LogFilter[] = [];
    for (const field of fields) {
        const filter: LogFilter = {
            id: field.id,
        };
        const fieldTYpe = find(FIELDS, {name: field.name});
        if (!fieldTYpe) {
            throw new Error(`Unknown field ${field.name}`);
        }
        switch (field.name) {
            case AND.name:
                filter.and = denormalize(field.value as FieldInstance[]);
                break;
            case OR.name:
                filter.or = denormalize(field.value as FieldInstance[]);
                break;
            case NOTMultiple.name:
                filter.not = true;
                filter.and = denormalize(field.value as FieldInstance[]);
                break;
            case QUERY.name:
                filter.query = field.value as string;
                break;
            case LEVELS.name:
                filter.levels = field.value as ILogLevel[];
                break;
            case FACILITY.name:
                filter.facilities = [field.value as string];
                break;
            case PROFILE.name:
                filter.profielId = field.value as string;
                break;
            case GROWTHBASE.name:
                filter.growthbase = field.value as boolean;
                break;
            case CLIENTBOX.name:
                filter.clientbox = field.value as boolean;
                break;
            case GEBRUIKER.name:
                filter.gebruikerId = field.value as string;
                break;
            case PROCESS.name:
                filter.processIds = [field.value as string];
                break;
            case ERROR.name:
                filter.errorIds = [field.value as string];
                break;
            case ERRORCODE.name:
                filter.errorCodes = [field.value as string];
                break;
            case DATEFROM.name:
                filter.from = (field.value as Date).toISOString();
                break;
            case DATETO.name:
                filter.to = (field.value as Date).toISOString();
                break;
            case LASTMINUTES.name:
                filter.lastMinutes = field.value as number;
                break;
            case TRANSACTION_ID.name:
                filter.transactionIds = [field.value as string];
                break;
            case TRANSACTION_DEPTH.name:
                filter.transactionDepth = [field.value as number];
                break;
            case PREVIOUSPROCESS.name:
                filter.previousProcessIds = [field.value as string];
                break;
            case CLI_COMMAND.name:
                filter.cliCommand = field.value as string;
                break;
            case REQUEST_URI.name:
                filter.requestUri = field.value as string;
                break;
            case REQUEST_ROUTE.name:
                filter.requestRoute = field.value as string;
                break;
            case REQUEST_METHOD.name:
                filter.requestMethod = field.value as string;
                break;
            case REQUEST_IP.name:
                filter.requestIp = field.value as string;
                break;
            default:
                throw new Error(`Unknown field ${field.name}`);
        }
        filters.push(filter);
    }
    return filters;
};

export const FilterBar: FC<FilterBarProps> = ({value, children, onChange}) => {
    const filters = useMemo<FieldInstance[]>(() => normalizeMultiple(value), [value]);
    return (
        <Conditions
            fields={FIELDS}
            values={filters}
            onChange={(updates) => {
                onChange(denormalize(updates));
            }}
        >
            {children}
        </Conditions>
    );
};
