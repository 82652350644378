import {ReduxUserDependencies} from '@growthbase/spa';
import {InstellingenReducer} from './Instellingen';

export const createInstellingenServices = ({reducers = {}, epics = []}: ReduxUserDependencies): unknown => ({
    reducers: {
        ...reducers,
        instellingenFrom: InstellingenReducer,
    },
    epics: [...epics],
});
