import React, {FC, KeyboardEvent, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {
    blurElement,
    DefaultFormModalFooter,
    Modal,
    ModalContent,
    PopupHeader,
    TextFieldComponent,
    useCreateStrictForm,
} from '@growthbase/design-components';
import {CreateFormSchemaDto, createFormValue, useLijstCreateSchema} from './CreateFormSchema';

export interface CreateFormProps {
    onSubmit: (values: CreateFormSchemaDto) => Promise<void>;
}

export const CreateForm: FC<CreateFormProps> = ({onSubmit}) => {
    const {t} = useTranslation('spa_form.nl');
    const {
        formik,
        Form,
        Fields: {TitelField},
        formProps,
    } = useCreateStrictForm<CreateFormSchemaDto>({
        onSubmit,
        enableLockPopupAndRoutingWhenDirty: false,
        createInitialValuesAfterSubmit: true,
        createInitialValues: createFormValue,
        schema: useLijstCreateSchema(),
    });

    return (
        <Modal>
            <PopupHeader title={t('lijst.tabel.toevoegen')} />
            <ModalContent theme="gray">
                <Form {...formProps}>
                    <TitelField
                        component={TextFieldComponent}
                        label={t('lijst.tabel.titel')}
                        hideWarningOnFocusLost
                        autoFocus
                        // TODO: hack to loose focus with escape, no clue why this is needed
                        onKeyDown={useCallback((event: KeyboardEvent<HTMLInputElement>) => {
                            // Ignore is not escape key
                            if (event.key !== 'Escape') {
                                return;
                            }
                            blurElement(event.target as HTMLElement);
                            event.preventDefault();
                            event.stopPropagation();
                        }, [])}
                    />
                </Form>
            </ModalContent>
            <DefaultFormModalFooter formik={formik} />
        </Modal>
    );
};
