import React, {ReactNode, useMemo} from 'react';
import {DraggableTable, DraggedRow, Table} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';
import {PaginationHelpers} from '@growthbase/spa';
import {DNDContainerUserData, onDropItemHandler} from '@growthbase/keys';
import {useColumns} from './Hook';
import {useFooterForm} from './Hook/useFooterForm';

export interface TaakTabelRow {
    id: string;
    titel: string;
}

export type TaakTabelUserData = DNDContainerUserData;

export interface SortableTabelProps<T extends TaakTabelRow = TaakTabelRow> {
    enableTakenToevoegen: boolean;
    lijsttemplateId: string | null;
    pagination: PaginationHelpers<T>;
    header: ReactNode;
    onSubmit: (values: string) => Promise<void>;
    renderMenu: (row: T) => ReactNode;
    onDrop: onDropItemHandler<T, TaakTabelUserData>;
}

export function SortableTabel<T extends TaakTabelRow = TaakTabelRow>({
    pagination,
    renderMenu,
    lijsttemplateId,
    onDrop,
    onSubmit,
    header,
    enableTakenToevoegen,
}: SortableTabelProps<T>) {
    const {t} = useTranslation('spa_form.nl');
    const {columns, Footer} = useFooterForm<T>({
        onSubmit,
        columns: useColumns<T>({renderMenu}),
    });

    return (
        <DraggableTable
            type="TemplateTaak"
            pagination={pagination}
            onDrop={onDrop}
            userData={useMemo(() => ({id: lijsttemplateId ?? 'alles'}), [lijsttemplateId])}
            renderDraggedItem={(props) => <DraggedRow {...props} columns={columns.slice(0, 1)} />}
        >
            {(tableProps) => (
                <Table<T>
                    columns={columns}
                    testId={t('taaktabel.titel')}
                    header={header}
                    Footer={enableTakenToevoegen ? Footer : undefined}
                    {...tableProps}
                />
            )}
        </DraggableTable>
    );
}
