import {useEffect, useState} from 'react';
import {v4} from 'uuid';
import {find} from 'lodash';
import {useCallbackRef, useLogger} from '@growthbase/spa';
import {useDebounceCallback} from 'usehooks-ts';

export interface GlobalElement {
    /**
     * The id of the element, this always the same of the same element, can be overruled
     * by setting the id attribute on the element.
     */
    id: string;
    element: Element;
}

/**
 * Find dom elements by search query every second, this also listen to global trigger events.
 */
export const useElements = (searchQuery: string): GlobalElement[] => {
    const [elements, setElements] = useState<GlobalElement[]>([]);
    const logger = useLogger('useElements');
    const search = useCallbackRef(() => {
        const found = document.querySelectorAll(searchQuery);
        const newElements = Array.from(found).map(
            (element): GlobalElement => ({
                id: element.id && element.id !== '' ? element.id : find(elements, {element})?.id ?? v4(),
                element,
            })
        );

        // Check if newElements is different from elements
        if (
            newElements.length !== elements.length ||
            newElements.some((element, index) => element.element !== elements[index].element)
        ) {
            setElements(newElements);
            logger.info(searchQuery, newElements);
        }
    });
    const debounce = useDebounceCallback(search, 10);
    useEffect(() => {
        const observer = new MutationObserver(debounce);
        observer.observe(document.body, {subtree: true, childList: true});
        const interval = setInterval(debounce, 2000);
        search();
        window.addEventListener('useElements', debounce);
        return () => {
            window.removeEventListener('useElements', debounce);
            observer.disconnect();
            clearInterval(interval);
        };
    }, [debounce, search]);
    return elements;
};
