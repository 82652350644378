import {QueryResult} from '@apollo/client/react/types/types';
import {useDropQueryCacheHandlers} from '@growthbase/design-components';
import {
    ITakentemplateVanLijstSwimlaneQuery,
    ITakentemplateVanLijstSwimlaneQueryVariables,
    ITakentemplateVanLijsttemplatwSwimlaneKaartFragment,
    useTakentemplateVanLijsttemplateSwimlaneChangeOrderMutation,
} from '@growthbase/graphql';
import {DropItemHandlerEvent} from '@growthbase/keys';
import {idFromIri} from '@growthbase/routing';
import {useCallback} from 'react';

/**
 * - Change the order of the taak in the table before submitting the form to the api.
 */
export const useChangeOrder = (
    lijstId: string,
    result: QueryResult<ITakentemplateVanLijstSwimlaneQuery, ITakentemplateVanLijstSwimlaneQueryVariables>
) => {
    const [changeOrder] = useTakentemplateVanLijsttemplateSwimlaneChangeOrderMutation();
    return useDropQueryCacheHandlers({
        edgeTypeName: 'TaaktemplateEdge',
        nodeTypeName: 'Taaktemplate',
        updateQuery: result.updateQuery,
        onDrop: useCallback(
            ({
                placement: {itemThatIsBefore, itemThatIsAfter},
                item,
                previousContainer: {id: previousContainerId},
                overContainer: {id: overContainerId},
            }: DropItemHandlerEvent<ITakentemplateVanLijsttemplatwSwimlaneKaartFragment>): Promise<void> =>
                changeOrder({
                    variables: {
                        input: {
                            id: idFromIri(item.id),
                            positieInNieuweLijst: {
                                bovenTaakId: itemThatIsAfter ? idFromIri(itemThatIsAfter.id) : undefined,
                                onderTaakId: itemThatIsBefore ? idFromIri(itemThatIsBefore.id) : undefined,
                            },
                            vorigeLijstId: idFromIri(previousContainerId),
                            nieuweLijstId: idFromIri(overContainerId),
                        },
                    },
                }).then(() => undefined),
            [changeOrder]
        ),
    });
};
