import {setLocale} from 'yup';
import * as locales from 'yup-locales';
import {LocaleObject} from 'yup/lib/locale';

const language =
    (window as unknown as {SPA_CONFIG?: {locale?: string}})?.SPA_CONFIG?.locale ?? window.navigator.language;

const object = (locales as Record<string, LocaleObject>)[language];
if (object) {
    setLocale(object);
} else {
    setLocale(locales.nl);
}
