import React, {FC, memo, ReactNode, useEffect, useRef} from 'react';
import {Keys, KeysProvider} from '@growthbase/keys';
import classNames from 'classnames/bind';
import {capitalize} from 'lodash';
import {
    autoUpdate,
    useFloating,
    offset,
    hide,
    shift,
    arrow as floatingArrow,
    Padding,
    useDismiss,
    useInteractions,
    autoPlacement,
} from '@floating-ui/react';
import {DebugRenderCounter} from '../../DebugRender/DebugRenderCounter';

import styles from './Popup.module.scss';
import './Popup.scss';
import {BaseButtonProps} from '../../Button';
import {PageBodyPortal} from '../../Page';

const cx = classNames.bind(styles);

export interface AnchoredPopup extends BaseButtonProps {
    testId?: string;
    onClose: () => void;
    children?: ReactNode;
    disablePortal?: boolean;
    arrow?: boolean;
    anchorElement: Element;
    shiftPadding?: Padding;
}

export const AnchoredPopup: FC<AnchoredPopup> = memo(
    ({testId, children, onClose, disablePortal, anchorElement, arrow, shiftPadding}) => {
        const arrowRef = useRef(null);

        const {floatingStyles, refs, middlewareData, placement, context} = useFloating({
            open: true,
            middleware: [
                autoPlacement(),
                hide(),
                shift({
                    padding: shiftPadding || {
                        left: 30,
                        right: 30,
                        top: 20,
                        bottom: 20,
                    },
                }),
                offset(arrow ? 20 : 10),
                arrow ? floatingArrow({element: arrowRef}) : undefined,
            ],
            whileElementsMounted: autoUpdate,
            elements: {
                reference: anchorElement,
            },
        });

        const {y: arrowY, x: arrowX} = middlewareData?.arrow || {x: 0, y: 0};
        const {referenceHidden} = middlewareData?.hide || {referenceHidden: false};

        const Portal = disablePortal ? 'div' : PageBodyPortal;

        const dismiss = useDismiss(context);

        const {getFloatingProps} = useInteractions([
            dismiss,
        ]);

        useEffect(() => {
            if (referenceHidden) {
                onClose();
            }
        }, [referenceHidden, onClose]);

        return (
            <>
                <DebugRenderCounter>Popup</DebugRenderCounter>
                <KeysProvider name="Popup">
                    <Keys debug="Popup: close" keys="escape" callback={onClose} />
                    <Portal>
                        <div
                            ref={refs.setFloating}
                            className={cx('popup', `popup--placement${capitalize(placement)}`)}
                            style={floatingStyles}
                            {...getFloatingProps()}
                            data-testid={testId}
                        >
                            {arrow && (
                                <div
                                    ref={arrowRef}
                                    className={cx('popup__arrow')}
                                    style={{
                                        left: arrowX != null ? `${arrowX}px` : '',
                                        top: arrowY != null ? `${arrowY}px` : '',
                                    }}
                                />
                            )}
                            {children}
                        </div>
                    </Portal>
                </KeysProvider>
            </>
        );
    }
);

AnchoredPopup.displayName = 'AnchoredPopup';
