import {useEffect} from 'react';
import {ActiveItemData, ActiveItemSource, ActiveItemType} from '../Redux/activeReducer';
import {useSetItemActive} from './useSetItemActive';

export interface SetItemActiveWhenMountedOptions<TData extends ActiveItemData = ActiveItemData> {
    type: ActiveItemType;
    source: ActiveItemSource;
    data: TData | undefined;
}

export function useSetItemActiveWhenMounted<TData extends ActiveItemData = ActiveItemData>({
    type,
    source,
    data,
}: SetItemActiveWhenMountedOptions<TData>): void {
    const {activate, deactivate} = useSetItemActive<TData>({type, source});
    useEffect(() => {
        if (!data) {
            deactivate();
            return;
        }
        activate(data);
    }, [activate, data, deactivate]);
}
