import {Logger} from 'ts-log';
import {toast} from 'react-toastify';
import {Action} from 'redux';
import {
    EMPTY,
    filter,
    ignoreElements,
    merge,
    mergeMap,
    Observable,
    Subject,
    tap,
    withLatestFrom,
    catchError,
} from 'rxjs';
import {ToastOptions} from 'react-toastify/dist/types';
import {ToastContent} from '../Component/ToastContent/ToastContent';
import {appendMessages, MessageStoreState, removeMessage, snoozeMessage} from '../Redux';

export const ToastMessageEpic = (logger: Logger) =>
    function ToastMessages(actions$: Observable<Action>, state$: Observable<MessageStoreState>): Observable<Action> {
        const subject = new Subject<Action>();

        const whenRemoved$ = merge(
            actions$.pipe(filter(removeMessage.match)),
            actions$.pipe(filter(snoozeMessage.match))
        );

        return merge(
            subject,
            whenRemoved$.pipe(
                tap((action) => {
                    toast.dismiss(action.payload.id);
                }),
                ignoreElements()
            ),
            actions$.pipe(
                filter(appendMessages.match),
                mergeMap((action) => action.payload),
                withLatestFrom(state$),
                filter(([message, state]) => !!state.messages.messages.find((m) => m.id === message.id)),
                filter(([message]) => !toast.isActive(message.id)),
                mergeMap(([message]) => {
                    const config: ToastOptions =
                        message.displayType === 'user'
                            ? {
                                  pauseOnFocusLoss: false,
                                  className: 'toast-user',
                              }
                            : {
                                  toastId: message.id,
                                  theme: 'colored',
                              };

                    if ('promise' in message) {
                        toast.promise(
                            message.promise.promise,
                            {
                                pending: message.promise.pending,
                                success: message.promise.success,
                                error: message.promise.error,
                            },
                            {
                                ...config,
                            }
                        );
                    } else {
                        const content =
                            message.displayType === 'user' ? message.message : <ToastContent message={message} />;
                        toast(content, {
                            ...config,
                            autoClose: message.type === 'error' ? 60 * 2 * 1000 : 5000,
                            closeOnClick: message.type !== 'error',
                            type: message.type,
                        });
                    }

                    const unSubscribe = toast.onChange((toastItem) => {
                        if (toastItem.id !== message.id) {
                            return;
                        }
                        if (toastItem.status === 'removed') {
                            subject.next(removeMessage(message));
                        }
                    });
                    whenRemoved$
                        .pipe(filter((action) => action.payload.id === message.id))
                        .subscribe({next: unSubscribe});
                    return EMPTY;
                }),
                catchError((err, source) => {
                    logger.error('ToastMessageEpic errored', err);
                    return source;
                })
            ),
            state$.pipe(
                // This will update duplicate count
                mergeMap((state) => {
                    state.messages.messages.forEach((message) => {
                        if (
                            toast.isActive(message.id) &&
                            'message' in message &&
                            message.displayType !== 'user' &&
                            message.duplicates
                        ) {
                            toast.update(message.id, {
                                render: <ToastContent message={message} />,
                            });
                        }
                    });
                    return EMPTY;
                })
            )
        );
    };
