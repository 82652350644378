import {useEffect, useRef} from 'react';

export interface SetInputActiveFromBool {
    isActive: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
}

export const useSetInputActiveFromBool = ({isActive, onBlur, onFocus}: SetInputActiveFromBool) => {
    const ref = useRef({
        onBlur,
        onFocus,
    });
    useEffect(() => {
        if (isActive) {
            ref.current.onFocus?.();
        }
        if (!isActive) {
            ref.current.onBlur?.();
        }
    }, [isActive, ref]);
};
