import {ButtonProps, Buttons} from '@growthbase/design-components';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {BordtemplateRoutes} from '../../../BordtemplateRoutes';

export interface BewerkButtonProps {
    id: string;
    Button?: FC<ButtonProps>;
    globalText?: boolean;
}

export const BewerkButton: FC<BewerkButtonProps> = ({id, globalText, Button = Buttons.EditPencil}) => {
    const {t} = useTranslation('Bordtemplate.nl');
    const title = globalText ? t('bordBewerken') : t('bewerken');
    return (
        <Button
            onClick={BordtemplateRoutes.bewerken.useNavigate({
                bordtemplateId: id,
            })}
            tooltip={title}
            ariaLabel={title}
        >
            {title}
        </Button>
    );
};
