import React from 'react';
import classNames from 'classnames/bind';
import {TableInterface} from '../TableInterface';
import styles from '../Table.module.scss';

const cx = classNames.bind(styles);

export interface TableFooterProps<TRow extends object = object> {
    table: TableInterface<TRow>;
}

/* eslint-disable react/jsx-key */

export function TableFooter<TRow extends object = object>({table: {footerGroups}}: TableFooterProps<TRow>) {
    return (
        <div>
            {footerGroups.map((group) => (
                <div {...group.getFooterGroupProps()} className={cx('table__footer', 'table__footer--open')}>
                    {group.headers.map((column) => (
                        <div {...column.getFooterProps()} className={cx('table__td')}>
                            {column.render('Footer')}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}
