import {createContext, useContext} from 'react';
import {EMPTY, Observable} from 'rxjs';

export interface StyledFieldState<T> {
    resetObservable: Observable<T>;
}

export const StyledFieldStateContext = createContext<StyledFieldState<unknown>>({
    resetObservable: EMPTY,
});

export const useStyledFieldState = <T>() => useContext(StyledFieldStateContext) as StyledFieldState<T>;
