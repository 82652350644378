import {SearchForm, TableFiltersHeader, useGebruikerHeeftToegang, useSearchField} from '@growthbase/design-components';
import {ITaakTabelFragment, useFilterableTakenTableConnectedQuery} from '@growthbase/graphql';
import {usePaginationSearchParams} from '@growthbase/routing';
import {usePollInterval} from '@growthbase/websockets';
import React, {FC, useMemo} from 'react';
import {TaakAddAction, TaakTabel} from '../TaakTabel';
import {DeelnemersFilterConnected, useDeelnemersFilterState} from '../TakenFilters';
import {useAddTaakHandler} from './Hook/useAddTaakHandler';
import {useTaakTabelEntityChanges} from './Hook/useTaakTabelEntityChanges';

export interface FilterableTakenTableFilters {
    voltooid: boolean | null;
    gearchiveerd: boolean | null;
    takenDieIkVolg: boolean | null;
    takenWaaraanIkDeelneem?: boolean | null;
    relatie: string | null;
}

export interface FilterableTakenTableConnectedProps {
    enableAdd?: boolean;
    voltooid?: boolean | null;
    mijnPriveTaken?: boolean;
    gearchiveerd?: boolean;
    takenDieIkVolg?: boolean | null;
    takenWaaraanIkDeelneem?: boolean | null;
    title?: string | null;
    relatie?: string;
    snoozed?: boolean | null;
}

export const FilterableTakenTableConnected: FC<FilterableTakenTableConnectedProps> = ({
    takenWaaraanIkDeelneem = null,
    relatie,
    takenDieIkVolg = null,
    voltooid = null,
    gearchiveerd = false,
    snoozed = false,
    title,
    mijnPriveTaken,
    enableAdd = !gearchiveerd && !voltooid,
}) => {
    const {pagination, usePagination} = usePaginationSearchParams<ITaakTabelFragment>('taak');

    const [searchQuery, setSearchQuery] = useSearchField('taak');

    const [deelnemers, setDeelnemers] = useDeelnemersFilterState();

    const result = useFilterableTakenTableConnectedQuery({
        pollInterval: usePollInterval(),
        variables: {
            ...pagination,
            voltooid,
            gearchiveerd,
            fulltextSearch: searchQuery?.query,
            takenDieIkVolg,
            deelnemers: deelnemers == null ? undefined : deelnemers,
            takenWaaraanIkDeelneem: takenWaaraanIkDeelneem == null ? undefined : takenWaaraanIkDeelneem,
            relatie: relatie == null ? undefined : relatie,
            mijnPriveTaken,
            snoozed: snoozed === null ? undefined : snoozed,
        },
    });

    const filters = useMemo(
        (): FilterableTakenTableFilters => ({
            voltooid: voltooid ?? null,
            gearchiveerd: gearchiveerd ?? null,
            takenDieIkVolg: takenDieIkVolg ?? null,
            takenWaaraanIkDeelneem: takenWaaraanIkDeelneem ?? null,
            relatie: relatie ?? null,
        }),
        [gearchiveerd, relatie, takenDieIkVolg, takenWaaraanIkDeelneem, voltooid]
    );

    useTaakTabelEntityChanges({
        result,
        searchQuery: searchQuery?.query ?? null,
        filters,
    });

    const enableAddTaken =
        enableAdd &&
        (result.data?.ingelogdeGebruiker?.availableActions?.kanTaakAanmaken ??
            result.previousData?.ingelogdeGebruiker?.availableActions?.kanTaakAanmaken);

    const toegang = useGebruikerHeeftToegang({
        toegang: result?.data?.ingelogdeGebruiker?.availableActions?.kanTakenBekijken,
    });
    const onSubmit = useAddTaakHandler(result);
    const pager = usePagination(result);
    if (!toegang) {
        return null;
    }
    return (
        <TaakTabel
            enableAdd={enableAddTaken}
            error={result.error}
            onSubmit={onSubmit}
            pagination={pager}
            showTaskDetails
            title={title}
            filters={
                <TableFiltersHeader
                    leftActions={[
                        <SearchForm
                            key="search"
                            disableAutofocus
                            loading={result.loading}
                            onChange={setSearchQuery}
                            value={searchQuery}
                        />,
                    ]}
                    rightActions={[
                        <DeelnemersFilterConnected
                            key="deelnemers"
                            deelnemers={deelnemers}
                            setDeelnemers={setDeelnemers}
                        />,
                        enableAddTaken && (
                            <TaakAddAction key="add" />
                        ),
                    ]}
                />
            }
        />
    );
};
