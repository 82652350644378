import {useMemo} from 'react';

/**
 * First letter of first name and last name.
 */
export const useShortName = (name: string) =>
    useMemo(() => {
        if (name.length === 0) {
            return '';
        }
        const split: string[] = name.split(' ');
        const first = split.shift();
        const last = split.pop();
        const short: string[] = [];
        if (first) {
            short.push(first[0]);
        }
        if (last) {
            short.push(last[0]);
        }
        return short.join('').toUpperCase();
    }, [name]);
