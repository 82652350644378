import React, {ReactNode} from 'react';
import {Subject} from 'rxjs';
import {To} from 'react-router-dom';

export interface Breadcrumb {
    title: ReactNode;
    to?: To;
    order?: number;
    asPageTitle?: boolean;
    excludeForChildren?: boolean;
}

export interface BreadcrumbEvent {
    breadcrumbs: Breadcrumb[];
}

export type BreadcrumbContextType = {
    addBreadcrumb: (breadcrumb: Breadcrumb) => () => void;
    breadcrumbs: Breadcrumb[];
    context: Subject<BreadcrumbEvent>;
};

export const BreadcrumbContext = React.createContext<BreadcrumbContextType | null>(null);
