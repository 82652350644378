import {ActionsMenu, ActionsMenuButton, OptionalButtonHandler} from '@growthbase/design-components';
import React from 'react';
import {useTranslation} from 'react-i18next';

export interface ReactieMenuProps {
    readonly?: boolean;
    disabled?: boolean;
    onEdit?: OptionalButtonHandler;
    onDelete?: OptionalButtonHandler;
}

export const ReactieMenu = ({onEdit, onDelete, readonly, disabled}: ReactieMenuProps) => {
    const {t} = useTranslation('spa_form.nl');
    if (readonly) {
        return null;
    }
    if (!onEdit && !onDelete) {
        return null;
    }

    return (
        <ActionsMenu visible>
            {onEdit && (
                <ActionsMenuButton onClick={onEdit} disabled={disabled}>
                    {t('edit')}
                </ActionsMenuButton>
            )}
            {onDelete && (
                <ActionsMenuButton onClick={onDelete} disabled={disabled}>
                    {t('delete')}
                </ActionsMenuButton>
            )}
        </ActionsMenu>
    );
};
