import React, {ReactNode} from 'react';

import classNames from 'classnames/bind';
import styles from './SidenavFooter.module.scss';
import {useSidenavContext} from '../Sidenav/useSidenavContext';
import {SidenavPopup, SidenavPopupButtonProps} from '../SidenavPopup';
import {Avatar} from '../../Avatar';
import {Icons} from '../../icons';
import {IconSize} from '../../Icon';

const cx = classNames.bind(styles);

export interface SidenavFooterProps {
    children?: ReactNode;
    profileName: string;
    avatarUrl: string | null;
    userName: string;
}

export const SidenavFooter = ({profileName, userName, avatarUrl, children}: SidenavFooterProps) => {
    const {isExpanded} = useSidenavContext();

    const renderAvatarButton = ({isOpen, ...props}: SidenavPopupButtonProps) => (
        <button {...props} type="button" className={styles.sidenavFooter__avatarButton}>
            <Avatar naam={userName} imageUrl={avatarUrl} />
        </button>
    );

    const renderDotsButton = ({isOpen, ...props}: SidenavPopupButtonProps) => (
        <button type="button" className={styles.sidenavFooter__dotsButton} {...props}>
            <Icons.Dots size={IconSize.SIZE_4} />
        </button>
    );

    const classes = cx('sidenavFooter', {
        'sidenavFooter--expanded': isExpanded,
    });

    if (!isExpanded) {
        return (
            <div className={classes}>
                {!isExpanded && (
                    <SidenavPopup renderButton={renderAvatarButton} theme="light">
                        {children}
                    </SidenavPopup>
                )}
            </div>
        );
    }

    return (
        <div className={classes}>
            <Avatar naam={userName} imageUrl={avatarUrl} tooltip={null} />
            <div>
                <div className={styles.sidenavFooter__profileName}>{profileName}</div>
                <div className={styles.sidenavFooter__userName}>{userName}</div>
            </div>
            <div className={styles.sidenavFooter__dotsButtonWrapper}>
                <SidenavPopup renderButton={renderDotsButton} theme="light">
                    {children}
                </SidenavPopup>
            </div>
        </div>
    );
};
