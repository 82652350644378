import {QueryResult} from '@apollo/client/react/types/types';
import {
    ChangeType,
    determineActionFilterForTypes,
    determineActionForList,
    PositieType,
    QueryCacheUpdateType,
    useConvertFetchEntity,
    useUpdateQueryCacheFromExternalEntityChanges,
} from '@growthbase/cache';
import {
    IBordViewLijstFragment,
    IBordViewQuery,
    IBordViewQueryVariables,
    refetchBordViewSingleLijstQuery,
} from '@growthbase/graphql';
import {idFromIri} from '@growthbase/routing';
import {useCallback} from 'react';

export interface EntityChangesParams {
    bordId: string;
    result: QueryResult<IBordViewQuery, IBordViewQueryVariables>;
}

export const useEntityChanges = ({result, bordId}: EntityChangesParams) => {
    useUpdateQueryCacheFromExternalEntityChanges<IBordViewLijstFragment>({
        // @ts-expect-error: result is not assignable to type 'QueryResult<IBordViewQuery, IBordViewQueryVariables>'
        result,
        determineAction: useCallback(
            (params, defaultAction) => {
                let action = determineActionFilterForTypes(defaultAction, params, 'TakenLijst', 'LijstVanBord');
                const {type, typename} = params;
                if (typename === 'TakenBord_ZichtbaarVoorEdge') {
                    return QueryCacheUpdateType.refetch;
                }
                if (typename === 'TakenLijst' && type === ChangeType.add) {
                    return QueryCacheUpdateType.ignore;
                }
                if ('node' in params && 'bordId' in params.node && params.node.bordId !== idFromIri(bordId)) {
                    return QueryCacheUpdateType.ignore;
                }
                action = determineActionForList(action, params);
                return action;
            },
            [bordId]
        ),
        positie: PositieType.after,
        addToQueryOptions: {
            edgeTypeName: 'TakenLijstEdge',
            nodeTypeName: 'TakenLijst',
        },
        fetchEntity: useConvertFetchEntity(({id}) => refetchBordViewSingleLijstQuery({id: id.split('--').pop() ?? ''})),
    });
};
