import {useExternalLink} from '@growthbase/clientbox';
import {useMemo} from 'react';
import {useSelected} from 'slate-react';
import classNames from 'classnames/bind';
import {IKoppelbaarType} from '@growthbase/graphql';
import styles from './OnderdeelVanMention.module.scss';
import {resolveIKoppelbaarLinkType} from '../../resolveIKoppelbaarLinkType';

type OnderdeelVanItem = {
    id: string;
    label: string;
    externalId?: string | null;
    type: IKoppelbaarType;
};

export interface OnderdeelVanMentionReadonlyProps {
    onderdeelVan: OnderdeelVanItem;
}

const cx = classNames.bind(styles);

export const OnderdeelVanMentionReadonly = ({onderdeelVan}: OnderdeelVanMentionReadonlyProps) => {
    const {label} = onderdeelVan;

    const link = useExternalLink(
        useMemo(
            () => ({
                linkType: resolveIKoppelbaarLinkType(onderdeelVan.type),
                externalId: onderdeelVan.externalId,
                label: onderdeelVan.label,
                id: onderdeelVan.id,
            }),
            [onderdeelVan]
        )
    );

    const selected = useSelected();

    const classes = cx('onderdeelVanMention', {
        'onderdeelVanMention--selected': selected,
    });

    if (link) {
        return (
            <a rel="noopener noreferrer" target="_blank" className={classes} href={link}>
                {label}
            </a>
        );
    }
    return (
        <span className={styles.OnderdeelVanMention} contentEditable={false}>
            {label}
        </span>
    );
};
