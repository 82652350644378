import {ReactNode} from 'react';
import {useAutoContrastColor} from '../../../Typography/AutoContrastColor/useAutoContrastColor';
import styles from './CalendarEvent.module.scss';

export interface CalendarEventSmallProps {
    children: ReactNode;
    extraLabel?: string;
    color: string;
}

export const CalendarEventSmall = ({children, extraLabel, color}: CalendarEventSmallProps) => {
    const contrastColor = useAutoContrastColor(color);
    return (
        <div style={{backgroundColor: color, color: contrastColor}} className={styles.calendarEventSmall}>
            <span className={styles.calendarEventSmall__label}>{children}</span>
            {!!extraLabel && <span className={styles.calendarEventSmall__time}>{extraLabel}</span>}
        </div>
    );
};
