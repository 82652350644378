import {FC} from 'react';
import {PagePortal} from '@growthbase/design-components';
import {useNavigationData} from '../hook/useNavigationData';
import {SidenavConnected} from './SidenavConnected';
import {useSSRMenu} from '../Component/Hook/useSSRMenu';

export const NavigationPortal: FC = () => {
    const navigationData = useNavigationData();

    useSSRMenu(navigationData);

    if (!navigationData) {
        return null;
    }
    return (
        <>
            <PagePortal classNameTarget=".navigation-portal">
                <SidenavConnected navigationData={navigationData} />
            </PagePortal>
        </>
    );
};
