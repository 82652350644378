import {useDefaultValueTreeId, useLogger, useService} from '@growthbase/spa';
import {FC, PropsWithChildren, useEffect} from 'react';
import {BrowserRouter, useNavigate} from 'react-router-dom';
import {useCurrentHref} from '../Hook';
import {useBackgroundRoute} from '../Hook/useBackgroundRoute';
import {BACKGROUND_ROUTE_KEY, BACKGROUND_TREE_ID} from '../Schema/backgroundRouteSchema';
import {createURL, relativeURL} from '../Util';
import {useRefreshBrowserForLegacyRoutes} from '../Hook/useRefreshBrowserForLegacyRoutes';

export interface SetBackgroundRouteProps {
    defaultBackgroundRoute: string;
}

declare global {
    interface Window {
        openSpaModel(event: MouseEvent): void;
    }
}

const ExternalNavigationProvider = () => {
    const logger = useLogger('ExternalNavigationProvider');
    const navigate = useNavigate();
    const currentHref = useCurrentHref();
    const treeId = useDefaultValueTreeId();
    const [backgroundRoute] = useBackgroundRoute();
    useRefreshBrowserForLegacyRoutes();
    useEffect(() => {
        window.openSpaModel = (event: MouseEvent) => {
            const {target} = event;
            if (!(target instanceof HTMLElement)) {
                logger.error('openSpaModel: target is not HTMLElement');
                return;
            }
            const href = target.getAttribute('data-href');
            if (!href) {
                logger.error('openSpaModel: No href found on element');
                return;
            }
            event.preventDefault();
            const withParams = createURL(href);
            withParams.searchParams.append(BACKGROUND_ROUTE_KEY, backgroundRoute ?? currentHref);
            withParams.searchParams.append(BACKGROUND_TREE_ID, treeId);
            const relative = relativeURL(withParams);
            navigate(relative);
        };
    }, [backgroundRoute, currentHref, navigate, logger, treeId]);

    return null;
};

export const RoutingProvider: FC<PropsWithChildren> = ({children}) => {
    const services = useService();
    return (
        <BrowserRouter basename={services.config.indexRoute ?? undefined}>
            <ExternalNavigationProvider />
            {children}
        </BrowserRouter>
    );
};
