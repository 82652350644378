import {ApolloClient} from '@apollo/client';
import {Modifiers} from '@apollo/client/cache/core/types/common';
import {getStoreKeyName} from '@apollo/client/utilities';
import {ExternalEntityChangedArguments, useExternalEntityChanged} from '../MessageListener/useExternalEntityChanged';
import {BaseNode} from '../baseNode';

export const useApplyModifyCacheFromEntityChanged = (apollo: ApolloClient<unknown>) => {
    useExternalEntityChanged((normalized: ExternalEntityChangedArguments<BaseNode>): void => {
        const fields = normalized.changes.reduce((modifier: Modifiers, field) => {
            modifier[getStoreKeyName(field.name, field.args)] = () => field.value;
            return modifier;
        }, {} as Modifiers);
        apollo.cache.modify({
            id: `${normalized.typename}:${normalized.id}`,
            fields,
        });
    });
};
