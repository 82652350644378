import {ITaakTabelLijstConnectedFragment} from '@growthbase/graphql';

import React, {FC, memo} from 'react';
import {LijstMenuConnected} from '../../../../../LijstMenu';
import {TaakFilters} from '../../../../types';
import {TakenTabelVanLijstConnected} from '../TakenTabelVanLijst';
import {TaakTabelLijst} from './TaakTabelLijst';

interface LijstConnectedProps {
    fragment: ITaakTabelLijstConnectedFragment;
    filters: TaakFilters;
}

export const TaakTabelLijstConnected: FC<LijstConnectedProps> = memo(({fragment, filters}) => {
    const {titel} = fragment;

    return (
        <TaakTabelLijst
            renderTabel={({header, showTaskDetails}) => (
                <TakenTabelVanLijstConnected
                    header={header}
                    showTaskDetails={showTaskDetails}
                    filters={filters}
                    fragment={fragment}
                />
            )}
            titel={titel}
            menu={<LijstMenuConnected fragment={fragment} />}
        />
    );
});
TaakTabelLijstConnected.displayName = 'LijstConnected';
