import {PageFromMenu} from '@growthbase/routing';
import {LogbookConnected} from '@growthbase/activiteiten';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {FilterableTakenTableConnected} from './FilterableTakenTable';

export const MijnTakenArchiefPage: FC = () => {
    const {t} = useTranslation('Taken.nl');
    return (
        <PageFromMenu sidebar={<LogbookConnected />}>
            <FilterableTakenTableConnected
                takenWaaraanIkDeelneem
                voltooid={null}
                gearchiveerd
                title={t('MijnTakenPage.archiefTableTitle')}
            />
        </PageFromMenu>
    );
};
