import {CalendarNext, CalendarPrev, CalendarToday} from '@mobiscroll/react';
import {forwardRef} from 'react';
import {BaseButtonProps, Button} from '@growthbase/design-components';
import styles from './PlanningButtons.module.scss';

const Today = () => <CalendarToday className={styles.planningButtons__today} />;
const Next = () => <CalendarNext className={styles.planningButtons__next} />;
const Prev = () => <CalendarPrev className={styles.planningButtons__prev} />;

const Date = forwardRef(({onClick, children}: BaseButtonProps, ref) => (
    <Button className={styles.planningButtons__date} ref={ref} onClick={onClick}>
        {children}
    </Button>
));
Date.displayName = 'Date';

export const PlanningButtons = {
    Today,
    Next,
    Prev,
    Date,
};
