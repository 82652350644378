import {ExternalEntityWithLabel} from '@growthbase/clientbox';
import {
    Information,
    Padding,
    Popup,
    PopupAnchorProps,
    PopupHeader,
    PopupResults,
    PopupResultsItem,
    ScrollablePopupContent,
    SearchForm,
    SearchInput,
} from '@growthbase/design-components';
import {
    IRelatiePopupEdgeFragment,
    IRelatiePopupGroupRelatieFragment,
    IRelatiePopupGroupsFragment,
    IRelatieType,
} from '@growthbase/graphql';
import React, {FC, ReactNode, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ActiveItemSource, ActiveItemType, useTraversableOverview} from '@growthbase/keys';

export interface RelatiePopupProps {
    testId?: string;

    persoon: ExternalEntityWithLabel | null;
    bedrijf: ExternalEntityWithLabel | null;

    disabled?: boolean;
    loading: boolean;
    groups: IRelatiePopupGroupsFragment;
    query?: string | null;

    changeType?: IRelatieType | null;

    onChangeQuery: (value: SearchInput) => Promise<void>;
    onClickItem: (item: IRelatiePopupGroupRelatieFragment) => Promise<void>;
    anchor: (props: PopupAnchorProps) => ReactNode;
}

export const RelatiePopupInner: FC<Omit<RelatiePopupProps, 'anchor'>> = ({
    persoon,
    bedrijf,
    disabled,
    loading,
    groups,
    query,
    onChangeQuery,
    onClickItem,
    changeType,
}) => {
    const {t} = useTranslation('spa_form.nl');

    const isEmpty = groups.groups.length === 0 && !loading;

    const label = () => {
        switch (changeType) {
            case IRelatieType.Bedrijf:
                if (bedrijf) {
                    return t('relatie.bedrijfWijzigen');
                }
                return t('relatie.bedrijfToevoegen');
            case IRelatieType.Persoon:
                if (persoon) {
                    return t('relatie.persoonWijzigen');
                }
                return t('relatie.persoonToevoegen');
            default:
                return t('relatie.relatieToevoegen');
        }
    };

    const items = useMemo(
        () =>
            groups.groups.reduce(
                (pre, group) => [...pre, ...group.edges.filter(({verbonden}) => !verbonden).map(({node}) => node)],
                [] as IRelatiePopupGroupRelatieFragment[]
            ),
        [groups]
    );

    const {setTopRef} = useTraversableOverview({
        items,
        type: ActiveItemType.Unknown,
        source: ActiveItemSource.PopupResultItem,
    });

    return (
        <ScrollablePopupContent
            noPadding
            header={<PopupHeader title={label()} />}
            contentHeader={
                <Padding>
                    <SearchForm
                        innerRef={setTopRef}
                        onChange={onChangeQuery}
                        value={{
                            query: query ?? null,
                        }}
                        loading={loading}
                    />
                </Padding>
            }
        >
            {isEmpty && <div>{t('relaties.RelatiePopup.GeenResultaten')}</div>}
            {groups.groups.map((group) => {
                if (group.edges.length === 0) {
                    return null;
                }
                return (
                    // @ts-expect-error We fix the active data during PopupResultsItem
                    <PopupResults<IRelatiePopupEdgeFragment>
                        generateKey={({node}) => node.id}
                        disabled={disabled}
                        key={group.label}
                        items={group.edges}
                        title={groups.groups.length === 1 ? undefined : group.label}
                    >
                        {(base, {node, verbonden}) => (
                            <PopupResultsItem
                                {...base}
                                data={node}
                                onClick={() => onClickItem(node)}
                                readonly={verbonden}
                                key={node.id}
                            >
                                {verbonden ? <Information>{node.label}</Information> : node.label}
                            </PopupResultsItem>
                        )}
                    </PopupResults>
                );
            })}
        </ScrollablePopupContent>
    );
};

export const RelatiePopup: FC<RelatiePopupProps> = ({anchor, ...rest}) => (
    <Popup renderAnchor={anchor} testId="RelatiePopup" arrow>
        <RelatiePopupInner {...rest} />
    </Popup>
);
