import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {last} from 'lodash';
import {Optional} from 'utility-types';

import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AnyAction} from 'redux';

/**
 * Create dispatch hook with single argument.
 */
export const createDispatchHook =
    <TArg>(action: (args: TArg) => AnyAction) =>
    () => {
        const dispatch = useDispatch();
        return useCallback((arg: TArg) => dispatch(action(arg)), [dispatch]);
    };

/* eslint-disable no-shadow, no-continue */

/**
 * Make this as specific as possible, so other components can use this to bind logic to
 * specific item selections.
 */
export enum ActiveItemSource {
    Unknown,
    TableRow,
    TaakTableRow,
    TaakKaart,

    TaakTable,

    SetTableRow,

    PopupResultItem,
    AvatarListItem,
}

export enum ActiveItemType {
    Unknown,
    Taak,
    Set,
}

export type ActiveItemData = {
    id: string;
};

export type ContainerNestedLevel = number;
export type TreeId = string;

export interface ActiveItem<TData extends ActiveItemData = ActiveItemData> {
    type: ActiveItemType;
    source: ActiveItemSource;
    data: TData;
}

export type ConvertFunction = (item: ActiveItem) => Optional<ActiveItem>;

export interface ContainerOptions {
    name: string;
    convert?: ConvertFunction;
}

export type Container<TData extends ActiveItemData = ActiveItemData> = {
    options: ContainerOptions;
    nestingLevel: ContainerNestedLevel;
    selections: ActiveItem<TData>[];
    fromChildren: ActiveItem<TData> | null;
};

type ContainerPayload = {
    treeId: TreeId;
    nestingLevel: ContainerNestedLevel;
};

export type ItemPayload = ActiveItem & ContainerPayload;

type TreesType = Record<
    TreeId,
    {
        containers: Container[];
    }
>;
/**
 * There can be more active regions,
 * The first match will be used.
 */
type ActiveItemState = {
    activeTrees: TreesType;
};

export type ActiveItemsStoreState = {
    activeItems: ActiveItemState;
};

function updateFromChildren(containers: Container[]) {
    for (let i = containers.length - 1; i >= 0; i -= 1) {
        const parent = containers[i];
        const child = containers[i + 1];
        if (!child) {
            parent.fromChildren = null;
            continue;
        }

        parent.fromChildren = last(child.selections) ?? child.fromChildren;

        if (!parent.fromChildren) {
            continue;
        }

        const {convert} = parent.options;
        if (convert) {
            parent.fromChildren = {...parent.fromChildren, ...convert(parent.fromChildren)};
        }
    }
}

const ActiveSlice = createSlice({
    name: 'ActiveItem',
    initialState: {
        activeTrees: {},
    } as ActiveItemState,
    reducers: {
        openContainer(
            state,
            {
                payload: {options, treeId, nestingLevel},
            }: PayloadAction<{
                treeId: TreeId;
                options: ContainerOptions;
                nestingLevel: ContainerNestedLevel;
            }>
        ) {
            if (!state.activeTrees[treeId]) {
                state.activeTrees[treeId] = {
                    containers: [],
                };
            }
            state.activeTrees[treeId].containers.push({
                options,
                nestingLevel,
                selections: [],
                fromChildren: null,
            });
            state.activeTrees[treeId].containers.sort((a, b) => {
                if (a.nestingLevel === b.nestingLevel) {
                    return 0;
                }
                return a.nestingLevel < b.nestingLevel ? -1 : 1;
            });
            updateFromChildren(state.activeTrees[treeId].containers);
        },
        closeContainer(
            state,
            {
                payload: {treeId, nestingLevel},
            }: PayloadAction<{
                treeId: TreeId;
                nestingLevel: ContainerNestedLevel;
            }>
        ) {
            if (!state.activeTrees[treeId]) {
                return;
            }
            const {containers} = state.activeTrees[treeId];
            if (!containers) {
                return;
            }
            containers.splice(
                containers.findIndex((container) => container.nestingLevel === nestingLevel),
                1
            );
            updateFromChildren(state.activeTrees[treeId].containers);

            if (containers.length === 0) {
                delete state.activeTrees[treeId];
            }
        },
        activate(state, {payload: {source, data, treeId, nestingLevel, type}}: PayloadAction<ItemPayload>) {
            if (!state.activeTrees[treeId]) {
                return;
            }
            const {containers} = state.activeTrees[treeId];
            if (!containers) {
                return;
            }
            const container = containers.find((container) => container.nestingLevel === nestingLevel);
            if (!container) {
                return;
            }
            container.selections.push({
                source,
                data,
                type,
            });
            updateFromChildren(state.activeTrees[treeId].containers);
        },
        deactivate(state, {payload: {source, data, treeId, nestingLevel, type}}: PayloadAction<ItemPayload>) {
            if (!state.activeTrees[treeId]) {
                return;
            }
            const {containers} = state.activeTrees[treeId];
            if (!containers) {
                return;
            }
            const container = containers.find((container) => container.nestingLevel === nestingLevel);
            if (!container) {
                return;
            }
            const {selections} = container;

            const selection = selections.findIndex(
                (item) => item.data.id === data.id && item.source === source && item.type === type
            );
            if (selection < 0) {
                return;
            }
            selections.splice(selection, 1);
            updateFromChildren(state.activeTrees[treeId].containers);
        },
    },
});

const {deactivate, activate, closeContainer, openContainer} = ActiveSlice.actions;

const activeSlice: (state: ActiveItemsStoreState) => TreesType = createSelector(
    ({activeItems: {activeTrees}}: ActiveItemsStoreState) => activeTrees,
    (t) => t
);

export const useActiveTrees = () => useSelector(activeSlice);

export const useDeactivate = createDispatchHook(deactivate);
export const useActivate = createDispatchHook(activate);

export const useOpenContainer = createDispatchHook(openContainer);
export const useCloseContainer = createDispatchHook(closeContainer);

export const ActiveReducer = ActiveSlice.reducer;
