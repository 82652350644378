import {
    DND,
    DNDContainerUserData,
    DNDItemUserData,
    DNDUserDataType,
    onDropItemHandler,
    onRemoveItemHandler,
} from '@growthbase/keys';
import {PaginationHelpers} from '@growthbase/spa';
import {HTMLAttributes, ReactNode, useCallback} from 'react';
import classNames from 'classnames/bind';
import {Footer, Header} from './Components';
import styles from './SwimlanePool.module.scss';
import {Scrollable} from '../Scrollable';
import {Swimlane} from '../Swimlane';
import {PageBodySpacing} from '../Page';

const cx = classNames.bind(styles);

export type RenderSwimlanePoolCardProps<TSwimlane extends DNDItemUserData> = {
    item: TSwimlane;
    dragging: boolean;
    hoover: boolean;
    anchor?: HTMLAttributes<HTMLElement>;
};

export interface SwimlanePoolProps<
    TType extends DNDUserDataType,
    TSwimlane extends DNDItemUserData,
    TPool extends DNDContainerUserData = DNDContainerUserData
> {
    pagination: PaginationHelpers<TSwimlane>;

    userData: TPool;

    type: TType;
    onDrop: onDropItemHandler<TSwimlane, TPool>;
    onRemove?: onRemoveItemHandler<TSwimlane>;

    header?: ReactNode;
    footer?: ReactNode;

    children: (props: RenderSwimlanePoolCardProps<TSwimlane>) => ReactNode;
}

export function SwimlanePool<
    TType extends DNDUserDataType,
    TSwimlane extends DNDItemUserData,
    TPool extends DNDContainerUserData = DNDContainerUserData
>({
    onRemove,
    userData,
    type,
    onDrop,
    pagination,
    header,
    footer,
    children: renderItem,
}: SwimlanePoolProps<TType, TSwimlane, TPool>) {
    return (
        <>
            {header}
            <DND.Container<TType, TSwimlane, TPool>
                userDataType={type}
                userData={userData}
                onDrop={onDrop}
                onRemove={onRemove}
                renderDraggedItem={useCallback(
                    (props) =>
                        renderItem({
                            item: {
                                ...props.item.userData,
                                id: `dragged-item-${props.item.userData.id}`,
                            },
                            dragging: true,
                            hoover: true,
                            anchor: props.anchor,
                        }),
                    [
                        renderItem,
                    ]
                )}
                items={pagination.connections}
            >
                {({items, element}) => (
                    <div className={styles.swimlanePool} {...element}>
                        <PageBodySpacing negative>
                            <Scrollable fillParent autoHide={false} grabToScroll pageMargin track>
                                <div className={cx('swimlanePool__content')}>
                                    {items.map((item) => (
                                        <DND.Item item={item} key={item.id}>
                                            {({element: itemElement, dragging, anchor}) => (
                                                <div {...itemElement} style={{height: '100%'}}>
                                                    {renderItem({
                                                        item: item.userData,
                                                        anchor,
                                                        hoover: false,
                                                        dragging: dragging ?? false,
                                                    })}
                                                </div>
                                            )}
                                        </DND.Item>
                                    ))}
                                </div>
                            </Scrollable>
                        </PageBodySpacing>
                    </div>
                )}
            </DND.Container>
            {footer}
        </>
    );
}

SwimlanePool.Lane = Swimlane;
SwimlanePool.Header = Header;
SwimlanePool.Footer = Footer;
