import {ActionsMenuButton} from '@growthbase/design-components';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {BordRoutes} from '@growthbase/takenlijst/bord';

export interface ActionsMenuSetAlsLijstToevoegenProps {
    bordId: string;
}

export const ActionsMenuLijsttemplateAlsLijstToevoegenButton: FC<ActionsMenuSetAlsLijstToevoegenProps> = ({bordId}) => {
    const {t} = useTranslation('TakenlijstLijst.nl');
    return (
        <ActionsMenuButton
            onClick={BordRoutes.lijsttemplate_als_lijst_toevoegen.useNavigate({
                bordId,
            })}
        >
            {t('Template toevoegen as lijst')}
        </ActionsMenuButton>
    );
};
