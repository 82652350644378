import {useMemo} from 'react';
import {asDNDContainerId} from '../utils';
import {DNDActiveState, DNDContainerContext, DNDContainerUserData, DNDItemUserData, DNDUserDataType} from '../Value';
import {useActiveState} from './useActiveState';
import {useDNDContext} from './useDNDContext';

export interface ActiveContainerAndItem<
    TUserDataType extends DNDUserDataType = DNDUserDataType,
    TItemUserData extends DNDItemUserData = DNDItemUserData,
    TContainerUserData extends DNDContainerUserData = DNDContainerUserData
> {
    // Source container.
    container: () => DNDContainerContext<TUserDataType, TItemUserData, TContainerUserData>;
    state: DNDActiveState<TUserDataType, TItemUserData, TContainerUserData>;
}

export const useActiveContainerAndItem = <
    TUserDataType extends DNDUserDataType,
    TItemUserData extends DNDItemUserData,
    TContainerUserData extends DNDContainerUserData
>(): ActiveContainerAndItem<TUserDataType, TItemUserData, TContainerUserData> | null => {
    const active = useActiveState() as DNDActiveState<TUserDataType, TItemUserData, TContainerUserData> | null;
    const context = useDNDContext();
    return useMemo((): ActiveContainerAndItem<TUserDataType, TItemUserData, TContainerUserData> | null => {
        if (!active) {
            return null;
        }
        return {
            container: () => {
                const {sourceContainer} = active ?? {};
                if (!sourceContainer) {
                    throw new Error(`Nothing is active`);
                }
                const container = context.containers[asDNDContainerId(sourceContainer.id)];
                if (!container) {
                    throw new Error(`Container ${sourceContainer.id} is not found in context`);
                }
                return container as unknown as DNDContainerContext<TUserDataType, TItemUserData, TContainerUserData>;
            },
            state: active,
        };
    }, [active, context.containers]);
};
