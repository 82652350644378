import React, {ReactNode, FC} from 'react';
import classNames from 'classnames/bind';
import styles from '../Tabs.module.scss';
import {TabContentWrapperProps} from './TabContentWrapper';

const cx = classNames.bind(styles);

export interface TabContentProps {
    title: string;
    subHeader?: ReactNode;
    ContentWrapper: FC<TabContentWrapperProps>;
    children: ReactNode;
}

export const TabContent: FC<TabContentProps> = ({ContentWrapper, title, subHeader, children}) => (
    <ContentWrapper title={title}>
        {subHeader && <div className={cx(styles.tabs__subHeader)}>{subHeader}</div>}
        {children}
    </ContentWrapper>
);
