import {useHistoryBack} from '@growthbase/routing';
import {useCallback, useMemo} from 'react';
import {useBoolean, useMountedState} from 'react-use';
import {noop} from 'rxjs';
import {PopupControls} from '../PopupControls';
import {useOnCloseSubject} from './useOnCloseSubject';

export const useRoutePopupControls = (): PopupControls => {
    const isMounted = useMountedState();
    const back = useHistoryBack();
    const [onClose, isCanceled] = useOnCloseSubject();
    const [isExpanded, setIsExpanded] = useBoolean(false);

    const close = useCallback(() => {
        if (!isMounted()) {
            return;
        }

        if (isCanceled()) {
            return;
        }

        back();
    }, [isMounted, isCanceled, back]);

    const set = useCallback(
        (open?: boolean) => {
            if (open) {
                return;
            }
            close();
        },
        [close]
    );

    const expand = useCallback(() => setIsExpanded(true), [setIsExpanded]);
    const collapse = useCallback(() => setIsExpanded(false), [setIsExpanded]);

    const toggle = useCallback(() => {
        if (!isMounted) {
            return;
        }
        close();
    }, [close, isMounted]);

    return useMemo(
        (): PopupControls => ({
            open: noop,
            close,
            isOpen: true,
            onClose,
            forceClose: back,
            set,
            toggle,
            isExpanded,
            expand,
            collapse,
        }),
        [
            close,
            onClose,
            back,
            set,
            toggle,
            isExpanded,
            expand,
            collapse,
        ]
    );
};
