import {useCallbackRef, useLocale} from '@growthbase/spa';
import {useCallback, useRef} from 'react';
import {
    Datepicker as MobiDatePicker,
    localeEn,
    localeNl,
    MbscDatepickerOptions,
    momentTimezone,
} from '@mobiscroll/react';
import './Datepicker.scss';
import moment from 'moment';
import timezone from 'moment-timezone';
import {useTestChangeValue} from '../../Hook';
import styles from './Datepicker.module.scss';
import {useOnDayChange, useOnTimeChange} from './hook';
import {Popup, PopupAnchorProps, PopupContent, PopupControlsContext, useMemoryPopupControls} from '../../../Popups';
import {InputWrapper} from '../../../InputWrapper';
import {Buttons} from '../../../Button';
import {DatePickerValue} from './types/DatePickerValue';
import {TimeField} from '../TimeField';

momentTimezone.moment = timezone;

/**
 * Iso datetime string format.
 */
export interface DatepickerProps {
    id?: string;
    value: DatePickerValue;
    disabled?: boolean;
    onChange: (value: DatePickerValue) => void;
    initialValue?: DatePickerValue | undefined;
    initialOpen?: boolean;
}

/**
 * TODO: focus events for FieldComponent.
 */
export const Datepicker = ({id, onChange, value, initialValue = null, disabled, initialOpen}: DatepickerProps) => {
    const locale = useLocale();
    const controls = useMemoryPopupControls(initialOpen);
    const ref = useRef<HTMLDivElement>();
    const [timeValue, onTimeChange] = useOnTimeChange(value, onChange);
    const onDayChange = useOnDayChange(value, onChange, initialValue);

    // Disable or enable datefield, will set timeUsed boolean in value object
    const setTimeEnabled = useCallback(
        (enabled: boolean) => {
            if (!value) {
                return;
            }
            onChange({date: value.date, timeUsed: enabled});
        },
        [onChange, value]
    );

    const options: MbscDatepickerOptions = {
        disabled,
        inputProps: {
            id,
        },
        value: value?.date ? moment(value?.date).toDate() : '',
        timezonePlugin: momentTimezone,
        displayTimezone: 'Europe/Amsterdam',
        locale: locale === 'nl' ? localeNl : localeEn,
        onChange: onDayChange,
    };

    useTestChangeValue(
        id,
        useCallback((newValue) => onDayChange({value: newValue}), [onDayChange])
    );

    const renderAnchor = useCallbackRef(({reference, controls: {open, isOpen}}: PopupAnchorProps) => (
        <Buttons.CalendarIconOnly ref={reference} onClick={open} active={isOpen} />
    ));

    return (
        <div className={`${styles.datePicker} datePicker`}>
            <div className={styles.datePicker__date}>
                <InputWrapper
                    showClearButton={!!value?.date && !disabled}
                    onClear={useCallback(() => onChange(null), [onChange])}
                    iconRight={
                        <PopupControlsContext.Provider value={controls}>
                            <Popup disabled={disabled} disablePortal renderAnchor={renderAnchor} arrow>
                                <PopupContent>
                                    <MobiDatePicker
                                        theme="ios"
                                        context={ref.current}
                                        themeVariant="light"
                                        controls={['calendar']}
                                        showWeekNumbers={false}
                                        showOuterDays={false}
                                        focusOnClose
                                        onClose={controls.close}
                                        closeOnEsc={false}
                                        display="inline"
                                        {...options}
                                        onChange={useCallback(
                                            (changedValue: {value: string | Date | null}) => {
                                                onDayChange(changedValue);
                                                if (value?.date) {
                                                    controls.close();
                                                }
                                            },
                                            [onDayChange, value?.date, controls]
                                        )}
                                    />
                                </PopupContent>
                            </Popup>
                        </PopupControlsContext.Provider>
                    }
                >
                    <MobiDatePicker
                        theme="ios"
                        context={ref.current}
                        themeVariant="light"
                        controls={['calendar']}
                        focusOnClose
                        onOpen={controls.open}
                        onCellClick={controls.open}
                        {...options}
                    />
                </InputWrapper>
            </div>
            <div className={styles.datePicker__time}>
                <InputWrapper onClear={() => setTimeEnabled(false)} showClearButton={!!timeValue && !disabled}>
                    <TimeField
                        value={timeValue}
                        disabled={disabled || !value?.date}
                        onChange={onTimeChange}
                        onFocus={useCallback(() => setTimeEnabled(true), [setTimeEnabled])}
                        disableRoundedCorners
                    />
                </InputWrapper>
            </div>
        </div>
    );
};
