import {SidenavSubLink, SidenavSubLinkProps} from '@growthbase/design-components';
import React, {FC, ReactElement} from 'react';
import {useLinkPropsForRoute} from '../hook/useLinkPropsForRoute';
import {useResolveIcon} from '../hook/useResolveIcon';
import {MenuChild, MenuChildLink, MenuCustom} from '../Schema/MenuChild';

export interface SidenavSubLinkConnectedProps {
    item: MenuChild;
    withLines?: boolean;
    Element?: (props: SidenavSubLinkProps) => ReactElement;
}

const LinkSubNav = ({
    Element,
    item,
    withLines,
}: {
    Element: (props: SidenavSubLinkProps) => ReactElement;
    item: MenuChildLink;
    withLines?: boolean;
}) => (
    <Element
        active={item.active}
        withLines={withLines}
        Icon={useResolveIcon({
            label: item.label,
            name: item.route?.name,
        })}
        {...useLinkPropsForRoute(item.path, item.route)}
    >
        {item.label}
    </Element>
);

const CustomLinkSubNav = ({
    item: {label, href, class: className, type, active, ...attributes},
    withLines,
    Element,
}: {
    item: MenuCustom;
    withLines?: boolean;
    Element: (props: SidenavSubLinkProps) => ReactElement;
}) => (
    <Element
        withLines={withLines}
        Icon={useResolveIcon({
            label,
            name: '',
        })}
        active={active}
        href={href}
        className={className}
        attributes={attributes}
    >
        {label}
    </Element>
);

export const SidenavSubLinkConnected: FC<SidenavSubLinkConnectedProps> = ({
    item,
    withLines,
    Element = SidenavSubLink,
}) => {
    if (item.type === 'custom') {
        return <CustomLinkSubNav item={item} withLines={withLines} Element={Element} />;
    }
    if (item.type === 'link') {
        return <LinkSubNav item={item} withLines={withLines} Element={Element} />;
    }
    return <SidenavSubLink withLines>not yet supported</SidenavSubLink>;
};
