import {Buttons, ModalFormButton, ModalFormButtonProps} from '@growthbase/design-components';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Optional} from 'utility-types';

export type KopieerButtonProps<T> = Optional<ModalFormButtonProps<T>, 'Button'>;

export function KopieerButton<T>({Button = Buttons.Copy, renderForm, children, onSubmit}: KopieerButtonProps<T>) {
    const {t} = useTranslation('TakenlijstLijst.nl');
    return (
        <ModalFormButton Button={Button} renderForm={renderForm} onSubmit={onSubmit}>
            {t('Kopieren.copy')}
            {children}
        </ModalFormButton>
    );
}
