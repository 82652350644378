import React, {useMemo} from 'react';
import {ActiveContainerAndItem} from '../Hook';
import {asDNDContainerId, asDNDItemId} from '../utils';
import {DNDItemData} from '../Value';
import {DNDSortableItem} from './DNDSortableItem';

export type DNDDraggedItemOverlayProps = ActiveContainerAndItem;

export const DNDDraggedItemOverlay = ({state: {draggedItem, type}, container}: DNDDraggedItemOverlayProps) => (
    <DNDSortableItem
        item={useMemo(
            (): DNDItemData => ({
                id: asDNDItemId('draggedItem'),
                userData: draggedItem,
                type: 'item',
                userDataType: type,
                containerId: asDNDContainerId('draggedContainer'),
            }),
            [draggedItem, type]
        )}
    >
        {container().draggedItemOptions.renderDraggedItem}
    </DNDSortableItem>
);
