import React from 'react';
import {ActionsMenu, ActionsMenuButton, Buttons} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';
import {BordtemplateRoutes} from '@growthbase/takentemplates-bordtemplate';

export const Actionbar = () => {
    const {t} = useTranslation('TakenlijstBord.nl');
    return (
        <ActionsMenu renderButton={() => <Buttons.Dots />}>
            <ActionsMenuButton to={BordtemplateRoutes.bordtoevoegen.useUrl()}>
                {t('bordVanuitBordtemplateMaken')}
            </ActionsMenuButton>
        </ActionsMenu>
    );
};
