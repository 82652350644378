import {Table, TableHeader, TableHeaderSection} from '@growthbase/design-components';
import {PaginationRequestResponse, useCallbackRef, useHTTPClient, useRequestPagination} from '@growthbase/spa';
import React, {FC} from 'react';
import {CellProps, Column} from 'react-table';
import {IgnoreButton, SyncButton, ThreeStateBool, TwoStateString} from './Components';
import {ApiRoutes} from './SyncRoutes';

export interface DTO {
    id: string;
    type: string;
    externalId: string;
    title: string;
    synced: boolean;
    errored: boolean;
    ignored: boolean;
    syncedDate: string;
    lastSyncDate: string;
}

interface Query {
    id?: string;
    type?: string;
    externalId?: string;
    synced?: number;
    errored?: number;
    ignored?: number;
}

const rowId = ({type, id}: DTO) => `${type}:${id}`;
const BoolCell: FC<{value: boolean}> = ({value}) => <>{value ? 'Ja' : 'Nee'}</>;

const IgnoreCell: FC<CellProps<DTO>> = ({row}) => <IgnoreButton row={row.original} />;

const ActionsCell: FC<CellProps<DTO>> = ({row}) => <SyncButton row={row.original} />;

const columns: ReadonlyArray<Column<DTO>> = [
    {
        Header: 'Clientbox ID',
        accessor: 'id',
    },
    {
        Header: 'Growthbase ID',
        accessor: 'externalId',
    },
    {
        Header: 'Type',
        accessor: 'type',
    },
    {
        Header: 'Title',
        accessor: 'title',
    },
    {
        Header: 'Synced',
        accessor: 'synced',
        Cell: BoolCell,
    },
    {
        Header: 'Errored',
        accessor: 'errored',
        Cell: BoolCell,
    },
    {
        Header: 'Ignored',
        accessor: 'ignored',
        Cell: IgnoreCell,
    },
    {
        Header: 'Synced Date',
        accessor: 'syncedDate',
    },
    {
        Header: 'Last Sync Date',
        accessor: 'lastSyncDate',
    },
    {
        Header: <div />,
        Cell: ActionsCell,
        id: 'actions',
        width: 'auto',
        minWidth: 90,
    },
];

export const SyncPage: FC = () => {
    const client = useHTTPClient();
    const route = ApiRoutes.search.useUrl();
    const [id, setId] = React.useState<string | undefined>();
    const [externalId, setExternalId] = React.useState<string | undefined>();
    const [type, setType] = React.useState<string | undefined>();
    const [synced, setSynced] = React.useState<number | undefined>(0);
    const [errored, setErrored] = React.useState<number | undefined>(undefined);
    const [ignored, setIgnored] = React.useState<number | undefined>(0);

    const mutatedPagination = useRequestPagination<DTO, Query>(
        useCallbackRef((query) => client.get<PaginationRequestResponse<DTO>>(route, query)),
        {
            queryParams: {
                id,
                externalId,
                type,
                synced,
                errored,
                ignored,
            },
            interval: 3000,
        }
    );

    return (
        <Table<DTO>
            header={
                <TableHeader>
                    <TableHeaderSection>
                        {mutatedPagination.connections.length}/{mutatedPagination.totalCount}
                    </TableHeaderSection>

                    <TableHeaderSection>
                        <TwoStateString value={id} setValue={setId}>
                            Clientbox ID
                        </TwoStateString>
                    </TableHeaderSection>
                    <TableHeaderSection>
                        <TwoStateString value={externalId} setValue={setExternalId}>
                            Growthbase ID
                        </TwoStateString>
                    </TableHeaderSection>
                    <TableHeaderSection>
                        <TwoStateString value={type} setValue={setType}>
                            Type
                        </TwoStateString>
                    </TableHeaderSection>
                    <TableHeaderSection>
                        <ThreeStateBool value={synced} setValue={setSynced}>
                            Synced
                        </ThreeStateBool>
                    </TableHeaderSection>
                    <TableHeaderSection>
                        <ThreeStateBool value={errored} setValue={setErrored}>
                            Errored
                        </ThreeStateBool>
                    </TableHeaderSection>
                    <TableHeaderSection>
                        <ThreeStateBool value={ignored} setValue={setIgnored}>
                            Ignored
                        </ThreeStateBool>
                    </TableHeaderSection>
                </TableHeader>
            }
            getRowId={rowId}
            columns={columns}
            pagination={mutatedPagination}
        />
    );
};
