import {FC} from 'react';
import {ToastContainer} from 'react-toastify';
import * as React from 'react';
import {createPortal} from 'react-dom';
import styles from './Toaster.module.scss';

export const Toaster: FC = () => {
    const ToasterElement = <ToastContainer className={styles.toaster} theme="dark" />;
    let portalDestination = document.body.querySelector('#portal-toaster');
    if (!portalDestination) {
        portalDestination = document.createElement('div');
        portalDestination.id = 'portal-toaster';
        document.body.prepend(portalDestination);
    }
    return createPortal(ToasterElement, portalDestination);
};
