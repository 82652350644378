import {useTranslation} from 'react-i18next';
import React, {useMemo} from 'react';
import {Columns, TableEditLink} from '@growthbase/design-components';
import {TaaktemplateRoutes} from '@growthbase/takentemplates-taaktemplate';
import {TemplatetabelRow} from '../Tabel';

export const useColumns = () => {
    const {t} = useTranslation('spa_form.nl');
    return useMemo(
        (): Columns<TemplatetabelRow> => [
            {
                Header: <>{t('taak.table.title')}</>,
                accessor: 'titel',
                Cell: ({
                    value,
                    row: {
                        original: {id: taakId},
                    },
                }) => (
                    <TableEditLink to={TaaktemplateRoutes.bewerken.useUrl({taaktemplateId: taakId})}>
                        {value}
                    </TableEditLink>
                ),
            },
        ],
        [t]
    );
};
