import {QueryResult} from '@apollo/client/react/types/types';
import {useExternalEntityAdded, useExternalEntityRemoved} from '@growthbase/cache';
import {ITakenlijstenConnectedQuery, ITakenlijstenConnectedQueryVariables} from '@growthbase/graphql';
import {useCallback} from 'react';

export interface EntityChangesParams {
    result: QueryResult<ITakenlijstenConnectedQuery, ITakenlijstenConnectedQueryVariables>;
}

export const useEntityChanges = ({result: {refetch}}: EntityChangesParams) => {
    const callback = useCallback(
        (args: {typename: string}): void => {
            if (args.typename === 'Bordtemplate') {
                refetch();
            }
        },
        [refetch]
    );
    useExternalEntityRemoved<{id: string}>(callback);
    useExternalEntityAdded(callback);
};
