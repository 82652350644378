import {useMemo} from 'react';
import * as yup from 'yup';
import {AnySchema, SchemaOf} from 'yup';
import {InstellingFieldType, InstellingValues} from '../types';
import {InstellingFieldCollection} from '../InstellingFieldCollection';

const createSchema = (fields: InstellingFieldCollection): Record<string, AnySchema> => {
    const records: Record<string, AnySchema> = {};
    for (const field of fields.getFields()) {
        switch (field.type) {
            case InstellingFieldType.string:
                records[field.basePath] = yup.string().label(field.label);
                break;
            case InstellingFieldType.boolean:
                records[field.basePath] = yup.boolean().label(field.label);
                break;
            case InstellingFieldType.number:
                records[field.basePath] = yup.number().label(field.label);
                break;
            case InstellingFieldType.array: {
                const values = Object.keys(field.options);
                records[field.basePath] = yup.mixed().oneOf(values).label(field.label);
                break;
            }
            case InstellingFieldType.nested:
                records[field.basePath] = yup.object(createSchema(fields.filter(field.fields))).label(field.label);
                break;
            default:
                throw new Error(`Unknown type`);
        }
    }
    return records;
};

export const useCreateSchema = (fields: InstellingFieldCollection) =>
    useMemo((): SchemaOf<InstellingValues> => {
        const records: Record<string, AnySchema> = createSchema(fields.rootFields());
        return yup.object(records) as SchemaOf<InstellingValues>;
    }, [fields]);
