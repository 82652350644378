import {ITaakTabelFragment} from '@growthbase/graphql';
import {useKeys, useOnDragMoveButtonHandlers} from '@growthbase/keys';
import {IriOfTaak, TakenRoutes} from '../../TaakRoutes';
import {TaakDragType} from '../../TaakTabel';

export const useTaakPageNavigation = (id: string): void => {
    const {upItem, downItem} = useOnDragMoveButtonHandlers<ITaakTabelFragment>({
        type: 'Taak' as TaakDragType,
        itemId: IriOfTaak(id),
        skipContainers: true,
    });

    const edit = TakenRoutes.edit.useNavigateFactory();

    useKeys(
        'TaakEditModal: Navigate to up item',
        'left',
        () => {
            if (!upItem) {
                return;
            }
            edit(
                {id: upItem.id},
                {
                    replace: true,
                    state: {
                        taak: upItem,
                    },
                }
            );
        },
        {
            enabled: !!upItem,
        }
    );

    useKeys(
        'TaakEditModal: Navigate to down item',
        'right',
        () => {
            if (!downItem) {
                return;
            }
            edit(
                {id: downItem?.id},
                {
                    replace: true,
                    state: {
                        taak: downItem,
                    },
                }
            );
        },
        {
            enabled: !!downItem,
        }
    );
};
