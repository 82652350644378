import {IKoppelButtonConnectedFragment} from '@growthbase/graphql';
import {ExternalLinkType, useExternalLinkFactory} from '@growthbase/clientbox';
import {useCallback, useMemo} from 'react';
import {KoppelbaarItem, KoppelbaarItemType} from '../../../KoppelPopup';

export const useNormalizeTaakOnderdeelVanFragmentFactory = (): ((
    fragment: IKoppelButtonConnectedFragment | null | undefined
) => KoppelbaarItem | null) => {
    const factory = useExternalLinkFactory();

    return useCallback(
        (fragment: IKoppelButtonConnectedFragment | null | undefined) => {
            const abonnement = fragment?.abonnement?.abonnement;
            if (abonnement) {
                return {
                    id: abonnement.id,
                    type: KoppelbaarItemType.Abonnement,
                    label: abonnement.label,
                    href:
                        factory({
                            ...abonnement,
                            linkType: ExternalLinkType.Abonnement,
                        }) ?? undefined,
                };
            }

            const opdracht = fragment?.opdracht?.opdracht;
            if (opdracht) {
                return {
                    id: opdracht.id,
                    type: KoppelbaarItemType.Opdracht,
                    label: opdracht.label,
                    href:
                        factory({
                            ...opdracht,
                            linkType: ExternalLinkType.Opdracht,
                        }) ?? undefined,
                };
            }

            const ticket = fragment?.ticket?.ticket;
            if (ticket) {
                return {
                    id: ticket.id,
                    type: KoppelbaarItemType.Ticket,
                    label: ticket.label,
                    href:
                        factory({
                            ...ticket,
                            linkType: ExternalLinkType.Ticket,
                        }) ?? undefined,
                };
            }

            return null;
        },
        [factory]
    );
};

export const useNormalizeTaakOnderdeelVanFragment = (
    fragment: IKoppelButtonConnectedFragment | null
): KoppelbaarItem | null => {
    const factory = useNormalizeTaakOnderdeelVanFragmentFactory();
    return useMemo(() => factory(fragment), [factory, fragment]);
};
