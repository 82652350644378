import {
    ActionsMenu,
    ActionsMenuButton,
    LegacyActionsMenuRefButton,
    useLegacyActionsMenuRefButton,
} from '@growthbase/design-components';
import {ITaakActionMenuConnectedFragment} from '@growthbase/graphql';
import {useOr} from '@growthbase/spa';
import {FC, ReactElement} from 'react';
import {MenuButtonModifiers} from '@szhsin/react-menu';
import {useTranslation} from 'react-i18next';
import {SnoozeButtonConnected} from '@growthbase/taken';
import {ArchiveerTaakButtonConnected} from '../TaakArchiveren/ArchiveerTaakButton/ArchiveerTaakButtonConnected';
import {KopieerTaakButtonConnected} from '../TaakKopieren';
import {VerwijderTaakButtonConnected} from '../TaakVerwijderen';
import {VerplaatsButton} from '../TaakVerplaatsen';

export interface TaakActionMenuConnectedProps {
    fragment: ITaakActionMenuConnectedFragment;
    renderButton?: (props: MenuButtonModifiers) => ReactElement;
}

export const TaakActionMenuConnected: FC<TaakActionMenuConnectedProps> = ({fragment, renderButton}) => {
    const {t} = useTranslation('Taken.nl');
    const {ref, Context} = useLegacyActionsMenuRefButton();

    return (
        <>
            <div ref={ref} />
            <ActionsMenu
                visible={useOr(fragment.availableActions, ['kanKopieren', 'kanArchiveren', 'kanVerwijderen'])}
                renderButton={renderButton}
            >
                <Context value={ref}>
                    <VerplaatsButton Button={ActionsMenuButton} fragment={fragment} readonly={false} disabled={false}>
                        {t('TaakVerplaatsen.menu')}
                    </VerplaatsButton>
                    <KopieerTaakButtonConnected Button={ActionsMenuButton} fragment={fragment} />
                    <ArchiveerTaakButtonConnected SubmitButton={ActionsMenuButton} fragment={fragment} />
                    <VerwijderTaakButtonConnected Button={ActionsMenuButton} fragment={fragment} />
                    <SnoozeButtonConnected asActions Button={LegacyActionsMenuRefButton} fragment={fragment} />
                </Context>
            </ActionsMenu>
        </>
    );
};
