import {EventType, IUrenRegistratie} from '@growthbase/planning';
import {useCallbackRef} from '@growthbase/spa';

export const useNormalizeUrenRegistraties = () =>
    useCallbackRef((urenRegistraties: IUrenRegistratie[]): EventType[] => {
        const events: EventType[] = [];
        urenRegistraties.forEach((urenRegistratie) => {
            const event: EventType = {
                id: urenRegistratie.id.toString(),
                start: new Date(urenRegistratie.van),
                end: new Date(urenRegistratie.tot),
                resource: 'time-registrations',
                title: '',
                editable: !urenRegistratie.readOnly,
                resize: !urenRegistratie.readOnly,
                dragBetweenResources: false,
                taak: null,
                timeRegistrationParent: {
                    id: urenRegistratie.parentId.toString(),
                },
                csrfToken: 'dummy',
            };

            if (urenRegistratie.dienstId) {
                event.dienst = {
                    id: urenRegistratie.dienstId.toString(),
                    nummer: urenRegistratie.dienstCode,
                    label: urenRegistratie.dienstLabel,
                };
            }

            if (urenRegistratie.relatieId) {
                event.relatie = {
                    id: urenRegistratie.relatieId.toString(),
                    label: urenRegistratie.relatieLabel,
                };
            }

            events.push(event);
        });

        return events;
    });
