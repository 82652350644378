import {
    ApplicationServiceDependencies,
    BaseApplicationServices,
    BaseDependencies,
    SpaVariablesDTO,
} from '@growthbase/spa';
import {createSSRServices, SSRApplicationRoot} from '@growthbase/kernel';
import {renderToStaticMarkup} from 'react-dom/server';
import {ServiceFactory} from '../createServices';

export function createHTMLString<TVariables extends SpaVariablesDTO, TAppServices = unknown>(
    deps: BaseDependencies<TVariables>,
    createApplicationServices: ServiceFactory<
        BaseApplicationServices<TVariables>,
        ApplicationServiceDependencies & TAppServices
    >
): string {
    deps.isSSR = true;

    const {error} = console;
    // eslint-disable-next-line no-console
    console.error = (msg: unknown, ...rest: unknown[]): void => {
        if (typeof msg === 'string' && msg.includes('useLayoutEffect does nothing on the server')) {
            return;
        }
        error(msg, ...rest);
    };

    const services = createSSRServices<TVariables>(deps, createApplicationServices);
    return renderToStaticMarkup(<SSRApplicationRoot services={services} />);
}
