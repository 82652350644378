import React, {useMemo} from 'react';
import {
    BooleanInstellingView,
    HiddenInstelling,
    InstellingenValues,
    InstellingFieldsInput,
    NestedAsCardInstellingView,
} from '@growthbase/instellingen';
import {useTranslation} from 'react-i18next';

import {IStandaardWeergaveBorden, IStandaardWeergaveLijsten, IStandaardWeergaveMijnDag} from '@growthbase/graphql';
import {Buttons} from '@growthbase/design-components';
import {TaakInstellingen} from '../InstellingenFormulier';

export interface InstellingenFields {
    editWeergave: string;
    editEmail: string;
    editUren?: string;
}

export const useInstellingenFields = ({editWeergave, editEmail, editUren}: InstellingenFields) => {
    const {t} = useTranslation('TakenInstellingen.nl');

    return useMemo(
        (): InstellingFieldsInput<TaakInstellingen> => ({
            uren: {
                label: t('instellingen.uren'),
                renderViewComponent: (props) =>
                    editUren ? (
                        <NestedAsCardInstellingView {...props} actions={<Buttons.EditPencil to={editUren} />} />
                    ) : (
                        <HiddenInstelling />
                    ),
                InputComponent: editUren ? undefined : HiddenInstelling,
                fields: {
                    directBoekenOpRelatie: {
                        label: t('instellingen.directBoekenOpRelatie'),
                        defaultValue: true,
                    },
                },
            },
            weergave: {
                label: t('instellingen.weergave'),
                renderViewComponent: (props) => (
                    <NestedAsCardInstellingView {...props} actions={<Buttons.EditPencil to={editWeergave} />} />
                ),
                fields: {
                    standaardWeergaveMijnDag: {
                        label: t('instellingen.standaardWeergaveMijnDag'),
                        defaultValue: IStandaardWeergaveMijnDag.Vandaag,
                        options: {
                            [IStandaardWeergaveMijnDag.Vandaag]: t('instellingen.vandaag'),
                            [IStandaardWeergaveMijnDag.AlMijnTaken]: t('instellingen.alMijnTaken'),
                        },
                    },
                    standaardWeergaveLijsten: {
                        label: t('instellingen.standaardWeergaveLijsten'),
                        defaultValue: IStandaardWeergaveLijsten.Kanban,
                        options: {
                            [IStandaardWeergaveLijsten.Kanban]: t('instellingen.kanban'),
                            [IStandaardWeergaveLijsten.Lijsten]: t('instellingen.lijsten'),
                            [IStandaardWeergaveLijsten.LaatstGebruiktWeergeven]: t(
                                'instellingen.laatstGebruikteWeergeven'
                            ),
                        },
                        control: {
                            laatstGebruikteWeergaveLijsten: {
                                when: IStandaardWeergaveLijsten.LaatstGebruiktWeergeven,
                                value: true,
                                hide: true,
                            },
                        },
                    },
                    laatstGebruikteWeergaveLijsten: {
                        label: t('instellingen.laatstGebruikteWeergeven'),
                        defaultValue: true,
                    },
                    standaardWeergaveBorden: {
                        label: t('instellingen.standaardWeergaveBorden'),
                        defaultValue: IStandaardWeergaveBorden.Kaarten,
                        options: {
                            [IStandaardWeergaveBorden.Tabel]: t('instellingen.tabel'),
                            [IStandaardWeergaveBorden.Kaarten]: t('instellingen.kaarten'),
                            [IStandaardWeergaveBorden.LaatstGebruiktWeergeven]: t(
                                'instellingen.laatstGebruikteWeergeven'
                            ),
                        },
                        control: {
                            laatstGebruikteWeergaveBorden: {
                                when: IStandaardWeergaveBorden.LaatstGebruiktWeergeven,
                                value: true,
                                hide: true,
                            },
                        },
                    },
                    laatstGebruikteWeergaveBorden: {
                        label: t('instellingen.laatstGebruikteWeergeven'),
                        defaultValue: true,
                    },
                },
            },
            email: {
                label: t('instellingen.emails'),
                renderViewComponent: (props) => (
                    <NestedAsCardInstellingView {...props} actions={<Buttons.EditPencil to={editEmail} />} />
                ),
                fields: {
                    meldingen: {
                        label: t('instellingen.meldingen'),
                        defaultValue: false,
                        control: {
                            meldingBijAfrondenTaak: {
                                when: false,
                                hide: true,
                            },
                            meldingBijInstellenDeadline: {
                                when: false,
                                hide: true,
                            },
                            meldingBijNaderendeDeadline: {
                                when: false,
                                hide: true,
                            },
                            meldingBijNaderendeDeadlineDagenVanTeVoren: {
                                when: false,
                                hide: true,
                            },
                            aparteDeadlineEmail: {
                                when: false,
                                hide: true,
                            },
                            meldingBijVerstrijkenVanDeadline: {
                                when: false,
                                hide: true,
                            },
                            meldingenGroeperen: {
                                when: false,
                                hide: true,
                            },
                            meldingIntervalMinuten: {
                                when: false,
                                hide: true,
                            },
                            meldingBijToewijzenTaak: {
                                when: false,
                                hide: true,
                            },
                            meldingBijReactie: {
                                when: false,
                                hide: true,
                            },
                            meldingBijActiverenTaak: {
                                when: false,
                                hide: true,
                            },
                            meldingBijVerwijderenVanTaak: {
                                when: false,
                                hide: true,
                            },
                            meldingBijDeelnemenVanTaak: {
                                when: false,
                                hide: true,
                            },
                            meldingBijGewijzigdeOmschrijving: {
                                when: false,
                                hide: true,
                            },
                            meldingBijInstellenBegindatum: {
                                when: false,
                                hide: true,
                            },
                            meldingBijNaderendeBegindatum: {
                                when: false,
                                hide: true,
                            },
                            meldingBijNaderendeBegindatumDagenVanTeVoren: {
                                when: false,
                                hide: true,
                            },
                            aparteBegindatumEmail: {
                                when: false,
                                hide: true,
                            },
                            meldingVanSnooze: {
                                when: false,
                                hide: true,
                            },
                        },
                    },
                    meldingBijToewijzenTaak: {
                        label: t('instellingen.meldingBijToewijzenTaak'),
                        defaultValue: false,
                    },
                    meldingBijInstellenDeadline: {
                        label: t('instellingen.meldingBijInstellenDeadline'),
                        defaultValue: false,
                    },
                    meldingBijNaderendeDeadline: {
                        label: t('instellingen.meldingBijNaderendeDeadline'),
                        defaultValue: false,
                        control: {
                            meldingBijNaderendeDeadlineDagenVanTeVoren: {
                                when: false,
                                hide: true,
                            },
                            aparteDeadlineEmail: {
                                when: false,
                                hide: true,
                            },
                        },
                    },
                    meldingBijNaderendeDeadlineDagenVanTeVoren: {
                        label: t('instellingen.meldingBijNaderendeDeadlineDagenVanTeVoren'),
                        defaultValue: 2,
                    },
                    meldingBijVerstrijkenVanDeadline: {
                        label: t('instellingen.meldingBijVerstrijkenVanDeadline'),
                        defaultValue: false,
                    },
                    aparteDeadlineEmail: {
                        label: t('instellingen.aparteDeadlineEmail'),
                        defaultValue: false,
                    },
                    meldingBijAfrondenTaak: {
                        label: t('instellingen.meldingBijAfrondenTaak'),
                        defaultValue: false,
                    },
                    meldingBijReactie: {
                        label: t('instellingen.meldingBijReactie'),
                        defaultValue: false,
                    },
                    meldingBijActiverenTaak: {
                        label: t('instellingen.meldingBijActiverenTaak'),
                        defaultValue: false,
                    },
                    meldingBijVerwijderenVanTaak: {
                        label: t('instellingen.meldingBijVerwijderenVanTaak'),
                        defaultValue: false,
                    },
                    meldingBijGewijzigdeOmschrijving: {
                        label: t('instellingen.meldingBijGewijzigdeOmschrijving'),
                        defaultValue: false,
                    },
                    meldingBijInstellenBegindatum: {
                        label: t('instellingen.meldingBijInstellenBegindatum'),
                        defaultValue: false,
                    },
                    meldingBijDeelnemenVanTaak: {
                        label: t('instellingen.meldingBijDeelnemenVanTaak'),
                        defaultValue: false,
                    },
                    meldingBijNaderendeBegindatum: {
                        label: t('instellingen.meldingBijNaderendeBegindatum'),
                        defaultValue: false,
                        control: {
                            meldingBijNaderendeBegindatumDagenVanTeVoren: {
                                when: false,
                                hide: true,
                            },
                        },
                    },
                    meldingBijNaderendeBegindatumDagenVanTeVoren: {
                        label: t('instellingen.meldingBijNaderendeBegindatumDagenVanTeVoren'),
                        defaultValue: 2,
                    },
                    aparteBegindatumEmail: {
                        label: t('instellingen.aparteBegindatumEmail'),
                        defaultValue: false,
                    },
                    meldingVanSnooze: {
                        label: t('instellingen.meldingVanSnooze'),
                        defaultValue: false,
                    },
                    meldingenGroeperen: {
                        label: t('instellingen.meldingenGroeperen'),
                        defaultValue: true,
                        control: {
                            meldingIntervalMinuten: {
                                when: false,
                                hide: true,
                            },
                        },
                        renderViewComponent: (props) => (
                            <BooleanInstellingView
                                {...props}
                                append={
                                    <InstellingenValues<TaakInstellingen> id={props.id}>
                                        {({email: {meldingenGroeperen, meldingIntervalMinuten}}) =>
                                            meldingenGroeperen &&
                                            t('instellingen.metEenIntervalVan', {meldingIntervalMinuten})
                                        }
                                    </InstellingenValues>
                                }
                            />
                        ),
                    },
                    meldingIntervalMinuten: {
                        label: t('instellingen.meldingIntervalMinuten'),
                        defaultValue: 15,
                        ViewComponent: HiddenInstelling,
                    },
                },
            },
        }),
        [editEmail, editWeergave, editUren, t]
    );
};
