import {useMemo} from 'react';
import {ITaakPageInfo} from '../generated/generated';

/**
 * TODO: Create interface for pagination info's. API platform does not yet support this.
 */
export type IPageInfo = ITaakPageInfo;

export type ConnectionInput<TNode> =
    | {
          edges?: Array<{node?: TNode | null} | null> | null;
      }
    | null
    | undefined;

/**
 * TODO: fix types server sides, to use interface for connections.
 */
export type Node = unknown;
export type ConnectionResult<TNode extends Node = Node> = ConnectionInput<TNode> & {
    pageInfo: IPageInfo;
    totalCount: number;
};
export type ResultData<TNode extends Node = Node> = {items?: ConnectionResult<TNode> | null};

export function resolveConnections<TNode>(value: ConnectionInput<TNode>): TNode[] {
    const edges: Array<{node?: TNode | null}> =
        (value?.edges?.filter((node) => !!node) as Array<{node?: TNode | null}>) ?? [];
    const nodes = edges.map(({node}) => node);
    return nodes.filter((node) => !!node) as TNode[];
}

export function useResolveConnections<TNode>(value: ConnectionInput<TNode>): TNode[] {
    return useMemo(() => resolveConnections(value), [value]);
}
