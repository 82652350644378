import {ActiveReducer, DNDReducer} from '@growthbase/keys';
import {configureStore, Middleware, Store} from '@reduxjs/toolkit';
import {CombinedState, PreloadedState, ReducersMapObject} from 'redux';
import {combineEpics, createEpicMiddleware, Epic} from 'redux-observable';
import {Logger} from 'ts-log';
import {NoInfer} from '@reduxjs/toolkit/src/tsHelpers';
import type {DTOsServices} from '../DTO';
import {MessageReducer, ToastMessageEpic} from '../ErrorHandling';
import {DefaultValuesReducer} from '../Hook/DefaultValueReducer';

export interface ReduxDependencies {
    logger: Logger;
}

export interface ReduxUserDependencies {
    reducers?: ReducersMapObject;
    epics?: Epic[];
}

export interface ReduxServices<T = unknown> {
    store: Store<T>;
}

export const createReduxService = ({
    logger,
    variables,
    config,
    reducers = {},
    epics = [],
}: ReduxDependencies & ReduxUserDependencies & DTOsServices): ReduxServices => {
    epics.push(ToastMessageEpic(logger) as unknown as Epic);
    const rootEpic = combineEpics(...epics);
    const epicMiddleware = createEpicMiddleware();
    const store = configureStore({
        middleware: [epicMiddleware as Middleware],
        reducer: {
            // Simple reducer with all services.
            dto: (state = {variables, config}) => state,
            // TODO: use DI to inject reducers!!, see createServices.ts for examples.
            messages: MessageReducer,
            activeItems: ActiveReducer,
            defaultValues: DefaultValuesReducer,
            DND: DNDReducer,
            ...reducers,
        },
        preloadedState: config.defaultReduxStateContext
            ? (config.defaultReduxStateContext as PreloadedState<
                  CombinedState<NoInfer<ReducersMapObject<never, never>>>
              >)
            : {},
    });
    epicMiddleware.run(rootEpic);
    return {store};
};
