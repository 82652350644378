import {FC} from 'react';
import {IBordVerwijderButtonFragment, useBordVerwijderButtonMutation} from '@growthbase/graphql';
import {BaseButtonProps, Button as DefaultButton, ViewConfig} from '@growthbase/design-components';
import {VerwijderButton} from './VerwijderButton';

export interface VerwijderButtonConnectedProps extends ViewConfig {
    fragment?: IBordVerwijderButtonFragment;
    Button?: FC<BaseButtonProps>;
    onDeleted?: () => void;
}

export const VerwijderButtonConnected: FC<VerwijderButtonConnectedProps> = ({
    fragment,
    Button = DefaultButton,
    onDeleted,
    disabled,
    readonly,
}) => {
    const [executeMutation, {loading}] = useBordVerwijderButtonMutation({
        variables: {
            id: fragment?.id ?? '',
        },
        onCompleted: onDeleted,
    });
    return (
        <VerwijderButton
            kanVerwijderen={fragment?.availableActions.kanVerwijderen ?? false}
            onClick={() => executeMutation().then()}
            isSubmitting={loading}
            Button={Button}
            disabled={disabled}
            readonly={readonly}
            naam={fragment?.naam ?? ''}
        />
    );
};
