import {
    DefaultFormModalFooter,
    Field,
    Modal,
    ModalContent,
    OpgemaakteTekstInput,
    TabContentWrapper,
    Stack,
    BoxButton,
    BoxSelectInput,
} from '@growthbase/design-components';
import {RelatieInputConnected} from '@growthbase/relaties';
import React, {FC, PropsWithChildren, ReactNode, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {KoppelButtonInputConnected, RenderKoppelButtonProps} from '@growthbase/taken';
import {DeelnemersInputConnected} from '@growthbase/gebruikers';
import {IZichtbaarVoor} from '@growthbase/graphql';
import {useEditForm, UseEditFormOptions} from './Hook/useEditForm';
import {useZichtbaarVoorOpties} from './Components';

export interface EditFormProps extends UseEditFormOptions, PropsWithChildren {
    RelatieInput?: typeof RelatieInputConnected;
    OnderdeelVanComponent?: typeof KoppelButtonInputConnected;
    DeelnemersInput?: typeof DeelnemersInputConnected;
    renderHeader: () => ReactNode;

    KanNaamWijzigen: boolean;
    kanOmschrijvingBewerken: boolean;
    kanDeelnemersBewerken: boolean;
    kanZichtbaarheidBewerken: boolean;
    kanBordKoppelen: boolean;
    kanBekijken: boolean;
}

export const EditForm: FC<EditFormProps> = ({
    children,
    RelatieInput = RelatieInputConnected,
    OnderdeelVanComponent = KoppelButtonInputConnected,
    DeelnemersInput = DeelnemersInputConnected,
    kanBordKoppelen,
    kanZichtbaarheidBewerken,
    kanOmschrijvingBewerken,
    kanBekijken,
    kanDeelnemersBewerken,
    renderHeader,
    ...rest
}) => {
    const {t} = useTranslation('TakenlijstBord.nl');
    const {Form, Fields, formik, formProps, renderNaam, render} = useEditForm(rest);
    const {OmschrijvingField, ZichtbaarVoorField, OnderdeelVanField, DeelnemersField} = Fields;

    const Omschrijving = OmschrijvingField.useComponent(OpgemaakteTekstInput);
    const OnderdeelVan = OnderdeelVanField.useComponent(OnderdeelVanComponent);
    const ZichtbaarVoor = ZichtbaarVoorField.useComponent(BoxSelectInput);
    const options = useZichtbaarVoorOpties(!!formik.values.deelnemers.length);
    const {
        values: {zichtbaarVoor},
    } = formik;

    const renderKoppelButton = useCallback(
        ({item, ...props}: RenderKoppelButtonProps) => (
            <BoxButton {...props} value={item?.label}>
                {t('gekoppeld')}
            </BoxButton>
        ),
        [t]
    );

    return render(
        <Modal testId="BordCreateModal">
            <Form {...formProps}>
                {renderHeader()}
                <ModalContent theme="gray">
                    <TabContentWrapper title="x">
                        <Stack gap="small">
                            <Stack.Item>
                                <Field label={t('naam')} compact disableMargin>
                                    {renderNaam({
                                        variant: 'default',
                                        autoFocus: true,
                                        controls: false,
                                        borderVisible: true,
                                        readonly: !kanBekijken,
                                    })}
                                </Field>
                            </Stack.Item>
                            <Stack.Item>
                                <ZichtbaarVoor
                                    readonly={!kanZichtbaarheidBewerken}
                                    {...{options}}
                                    labelHidden
                                    label={t('zichtbaarVoor.label')}
                                />
                            </Stack.Item>
                            <Stack.Item hide={zichtbaarVoor === IZichtbaarVoor.Mijzelf}>
                                <Field label={t('deelnemers')} compact disableMargin>
                                    <DeelnemersField readonly={!kanDeelnemersBewerken} component={DeelnemersInput} />
                                </Field>
                            </Stack.Item>
                            <Stack.Item>
                                <Omschrijving
                                    label={t('omschrijving')}
                                    placeholder={t('omschrijvingPlaceholder')}
                                    floatingToolbar={false}
                                    readonly={!kanOmschrijvingBewerken}
                                />
                            </Stack.Item>
                            <Stack.Item label="Koppelen">
                                <Stack.Item label="Onderdeel van">
                                    <OnderdeelVan readonly={!kanBordKoppelen} renderKoppelButton={renderKoppelButton} />
                                </Stack.Item>
                            </Stack.Item>
                        </Stack>
                        {children}
                    </TabContentWrapper>
                </ModalContent>
                <DefaultFormModalFooter formik={formik} />
            </Form>
        </Modal>
    );
};
