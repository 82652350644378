import React, {FC, useMemo} from 'react';
import {AddBreadcrumb, Loader, Stack} from '@growthbase/design-components';
import {IDisplayProgressFragment, IProcessDetailsReferenceFragment, useProcessDetailsQuery} from '@growthbase/graphql';
import moment from 'moment';
import {DeveloperRoutes} from '@growthbase/health';
import {Link} from 'react-router-dom';
import {useNowInterval} from '@growthbase/spa';
import {dutchFormat, LogsTableConnected} from '../LogsTable';
import {ReplayButtonConnected} from '../ReplayButton/ReplayButtonConnected';
import {DisplayProgress} from '../ProcessesPage/Components/DisplayProgress';

export interface ProjectionPageProps {
    id: number;
}

export const Reference: FC<{item?: IProcessDetailsReferenceFragment | null}> = ({item}) => {
    const urlFromProcess = DeveloperRoutes.process_detail.useUrlFactory();
    if (!item) {
        return null;
    }
    const {id, reason} = item;
    return (
        <Link
            to={urlFromProcess({
                id,
            })}
        >
            {id} ({reason})
        </Link>
    );
};

export const TimeLeftDuration = ({
    start,
    events,
}: {
    start?: string | null;
    events?: IDisplayProgressFragment | null;
}) => {
    const msLeft = useMemo((): number | null => {
        if (!events || !start) {
            return null;
        }
        const dateTime = new Date(start);
        const {count, total, running} = events;
        if (!running || !total || !count) {
            return null;
        }
        const msPerEvent = (Date.now() - dateTime.getTime()) / count;
        return msPerEvent * (total - count);
    }, [events, start]);

    if (!msLeft) {
        return null;
    }

    return <p>Time left: {moment.duration(msLeft, 'ms').humanize()}</p>;
};

export const TimePassed = ({start, end}: {start?: string | null; end?: string | null}) => {
    const now = useNowInterval();
    const msPassed = useMemo((): number | null => {
        if (!start) {
            return null;
        }
        const e = end ?? now;
        const dateTime = new Date(start);
        const endDateTime = new Date(e);
        return endDateTime.getTime() - dateTime.getTime();
    }, [end, now, start]);

    if (!msPassed) {
        return null;
    }

    return <p>Time passed: {moment.duration(msPassed, 'ms').humanize()}</p>;
};

export const ProcessDetails: React.FC<ProjectionPageProps> = ({id}) => {
    const urlFromProjection = DeveloperRoutes.projections_detail.useUrlFactory();
    const urlFromProfiel = DeveloperRoutes.replay_profile_detail.useUrlFactory();
    const query = useProcessDetailsQuery({
        variables: {
            id: id.toString(),
        },
        pollInterval: 2000,
    });
    const {data} = query;
    const dto = data?.process;

    if (!dto) {
        return <Loader center />;
    }

    const {
        profiel,
        hash,
        tag,
        reason,
        version,
        processId,
        serverName,
        finishedAt,
        createdAt,
        next,
        previous,
        countStatistic,
    } = dto;

    return (
        <Stack gap="medium">
            <AddBreadcrumb>{dto.id}</AddBreadcrumb>
            <h1>Replay process {dto.id}</h1>
            <LogsTableConnected
                hideActiefFilter
                hideRunningFilter
                infoContent={
                    <Stack.Item grow>
                        <Stack>
                            <p>
                                Previous: <Reference item={previous} />
                            </p>
                            <p>
                                Next: <Reference item={next} />
                            </p>
                            <div>
                                <ReplayButtonConnected
                                    profileId={profiel?.profielId}
                                    projectionId={profiel?.projection?.id}
                                />
                            </div>
                            <div>
                                Profiel: <Link to={urlFromProfiel({id: profiel.profielId})}>{profiel.name}</Link> (
                                {profiel.profielId})
                            </div>
                            <p>
                                Projection:{' '}
                                {profiel?.projection ? (
                                    <Link
                                        to={urlFromProjection({
                                            id: profiel.projection.id,
                                        })}
                                    >
                                        {profiel.projection.id}
                                    </Link>
                                ) : (
                                    '-'
                                )}
                            </p>
                            <p>Hash: {hash}</p>
                            <p>Tag: {tag}</p>
                            <p>Replay reason: {reason}</p>
                            <p>Server: {serverName}</p>
                            <p>Process id: {processId}</p>
                            <p>Version: {version}</p>
                            <p>
                                Created at: {createdAt ? moment(createdAt).format(dutchFormat) : '-'}
                                <TimePassed start={createdAt} end={finishedAt} />
                            </p>
                            <p>
                                Finished at:{' '}
                                {finishedAt ? (
                                    moment(finishedAt).format(dutchFormat)
                                ) : (
                                    <TimeLeftDuration start={createdAt} events={countStatistic} />
                                )}
                            </p>
                            <div>
                                Progress:
                                <DisplayProgress fragment={countStatistic} />
                            </div>
                        </Stack>
                    </Stack.Item>
                }
                processId={id}
                profielId={profiel.profielId}
                hideProfielFilter
                hideProjectionFilter
            />
        </Stack>
    );
};
