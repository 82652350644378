import React, {memo, useCallback} from 'react';
import {useSet} from 'react-use';
import {Buttons} from '../../Button';
import {ActionButton} from '../../Buttons';
import {AvatarSize} from '../Avatar';
import {AvatarButton} from '../AvatarButton';
import {AvatarPopup, AvatarPopupItem} from '../AvatarPopup';
import {AvatarRow} from '../AvatarRow';
import {SearchInput} from '../../SearchForm';

export interface AvatarRowFieldItem {
    id: string;
    selected?: boolean;
    naam: string;
    imageUrl?: string | null;
}

export interface AvatarRowFieldProps<T extends AvatarRowFieldItem = AvatarRowFieldItem> {
    testId?: string;
    addButtonAriaLabel?: string | null;
    popupTitle: string;
    items: T[];
    popupItems: T[];
    stackSize?: number;
    size?: AvatarSize;
    readonly?: boolean;
    disabled?: boolean;
    onAdd: (item: T) => unknown | Promise<unknown>;
    onRemove: (item: T) => unknown | Promise<unknown>;
    deleteToolTip: (item: T) => string;
    addToolTip: (item: T) => string;

    onChangeQuery: (value: SearchInput) => void;
    searchQuery: string | null;
    loading: boolean;
}

const AvatarRowFieldMemo = memo(
    <T extends AvatarPopupItem = AvatarPopupItem>({
        popupTitle,
        onAdd,
        onRemove,
        items,
        popupItems,
        deleteToolTip,
        addToolTip,
        size,
        disabled,
        readonly,
        stackSize,
        testId,
        addButtonAriaLabel,
        onChangeQuery,
        searchQuery,
        loading,
    }: AvatarRowFieldProps<T>) => {
        const enabled = !disabled && !readonly;

        // Holt set for handlers currently active.
        const [, {add, has, remove}] = useSet();

        const onClick = useCallback(
            (item: T) => {
                if (!enabled) {
                    return;
                }
                add(item.id);
                Promise.resolve(item.selected ? onRemove(item) : onAdd(item)).finally(() => remove(item.id));
            },
            [onAdd, add, remove, onRemove, enabled]
        );

        return (
            <AvatarPopup<T>
                testId={testId}
                items={enabled ? popupItems : items}
                title={popupTitle}
                onClickRow={onClick}
                disabled={disabled}
                readonly={readonly}
                onChangeQuery={onChangeQuery}
                searchQuery={searchQuery}
                loading={loading}
                avatarProps={useCallback(
                    (item: T) => {
                        const props = {
                            disabled: has(item.id) || disabled,
                            loading: has(item.id),
                        };
                        if (!enabled) {
                            return {...props, tooltip: item.naam};
                        }
                        return {...props, tooltip: item.selected ? deleteToolTip(item) : addToolTip(item)};
                    },
                    [has, disabled, enabled, deleteToolTip, addToolTip]
                )}
                actionButtonProps={(item) => ({onClick: () => onClick(item), tooltip: deleteToolTip(item)})}
                renderAnchor={({controls: {toggle, isOpen}, reference}) => (
                    <AvatarRow
                        stackSize={stackSize}
                        size={size}
                        overflowButtonProps={() => ({
                            size,
                            onClick: toggle,
                            tooltip: addButtonAriaLabel,
                        })}
                        button={
                            readonly ? (
                                <div ref={items.length === 0 ? reference : undefined} />
                            ) : (
                                <Buttons.PlusIconDotted
                                    showOnRowHover
                                    ariaLabel={addButtonAriaLabel}
                                    size={size === 'small' ? 'small' : 'medium'}
                                    disabled={disabled}
                                    ref={items.length === 0 ? reference : undefined}
                                    tooltip={addButtonAriaLabel}
                                    onClick={toggle}
                                    active={isOpen}
                                />
                            )
                        }
                    >
                        {items.map((item, index) => (
                            <AvatarButton
                                size={size}
                                key={`${item.id}`}
                                ref={index === 0 ? reference : undefined}
                                {...item}
                                disabled={disabled || has(item.id)}
                                loading={has(item.id)}
                                readonly={readonly}
                                selected={false}
                                onClick={toggle}
                                actionButtonVisibleOnHover
                                renderActionButton={(actionButtonProps) => (
                                    <ActionButton
                                        onClick={() => onClick(item)}
                                        {...actionButtonProps}
                                        size={size}
                                        ariaLabel={deleteToolTip(item)}
                                    />
                                )}
                            />
                        ))}
                    </AvatarRow>
                )}
            />
        );
    }
);

AvatarRowFieldMemo.displayName = 'AvatarRowField';

export const AvatarRowField = AvatarRowFieldMemo as unknown as <T extends AvatarPopupItem = AvatarPopupItem>(
    // eslint-disable-next-line no-use-before-define
    props: AvatarRowFieldProps<T>
) => JSX.Element;
