import {ViewConfig} from '@growthbase/design-components';
import {ILijstSwimlaneFragment} from '@growthbase/graphql';
import {PaginationHelpers} from '@growthbase/spa';
import {TakenswimlaneConnected} from '@growthbase/taken';
import React from 'react';
import {onDropItemHandler, onRemoveItemHandler} from '@growthbase/keys';
import {LijstMenuConnected} from '../../../LijstMenu';
import {TakenVanLijstSwimlaneConnected} from './Components/TakenVanLijstSwimlane/TakenVanLijstSwimlaneConnected';
import {ContainerData, TaakFilters} from '../../types';
import {SwimlaneView} from './SwimlaneView';

export interface SwimlaneViewConnectedProps<
    TLijst extends ILijstSwimlaneFragment = ILijstSwimlaneFragment,
    TContainerData extends ContainerData = ContainerData
> extends ViewConfig {
    pagination: PaginationHelpers<TLijst>;
    userData: TContainerData;
    onDrop: onDropItemHandler<TLijst, TContainerData>;
    onRemove?: onRemoveItemHandler<TLijst>;
    filters: TaakFilters;
}

export function SwimlaneViewConnected<
    TLijst extends ILijstSwimlaneFragment = ILijstSwimlaneFragment,
    TContainerData extends ContainerData = ContainerData
>({readonly, disabled, filters, ...props}: SwimlaneViewConnectedProps<TLijst, TContainerData>) {
    const viewConfig = {disabled, readonly};
    return (
        <SwimlaneView<TLijst, TContainerData> {...props}>
            {({anchor, item, dragging, hoover}) => (
                <TakenVanLijstSwimlaneConnected
                    fragment={item}
                    placeholder={!hoover && dragging}
                    header={
                        <TakenswimlaneConnected.Header
                            fragment={item}
                            anchor={anchor}
                            menu={<LijstMenuConnected fragment={item} />}
                        />
                    }
                    filters={filters}
                    {...viewConfig}
                />
            )}
        </SwimlaneView>
    );
}
