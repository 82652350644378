import {OpdrachtSelectie} from './OpdrachtSelectie';

export const OpdrachtSelectieStub = () => (
    <OpdrachtSelectie
        opdrachten={[
            {label: 'O-23-00175 - Website 2023 aanpassingen', value: '1'},
            {label: 'O-23-00173 - SEO 2023 - Maart', value: '2'},
            {label: 'O-23-00171 - Website 2023 aanpassingen', value: '3'},
        ]}
        value="1"
    />
);
