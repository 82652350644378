import {faker} from '@faker-js/faker';
import {createOpgemaakteTekstEditorFragment} from '@growthbase/design-components';
import {ITaakReactiesReactieFragment} from '@growthbase/graphql';
import {fixedPaginationHelpers} from '@growthbase/spa';
import {range} from 'lodash';
import {TaakReactieCreateFormConnected} from '../TaakReactieCreateForm/TaakReactieCreateFormConnected';
import {TaakReacties} from './TaakReacties';
import {TaakReactiesConnectedProps} from './TaakReactiesConnected';
import {TaakReactieStub} from '../TaakReactie/TaakReactieStub';
import {AangemaaktDoorReactie} from '../TaakReactie';

faker.seed(1);

export const TaakReactiesStub = ({fragment}: TaakReactiesConnectedProps) => {
    const pagination = fixedPaginationHelpers<ITaakReactiesReactieFragment>(
        range(0, 100).map((id) => ({
            id: id.toString(),
            availableActions: {
                kanVerwijderen: true,
                kanWijzigen: true,
            },
            aanmaakdatum: faker.date.past().toISOString(),
            taakId: '3',
            tekst: createOpgemaakteTekstEditorFragment(1),
            gebruiker: {
                id: '12',
                naam: faker.person.fullName(),
                image: faker.image.avatar(),
            },
        }))
    );

    return (
        <TaakReacties
            aangemaaktDoor={<AangemaaktDoorReactie naam="John Doe" image="https://i.pravatar.cc/150?u=1" />}
            CreateForm={TaakReactieCreateFormConnected}
            taakId={fragment.id}
            fragment={{
                ingelogdeGebruiker: {
                    id: '12',
                    naam: faker.person.fullName(),
                    image: faker.image.avatar(),
                },
            }}
            pagination={pagination}
            Reactie={TaakReactieStub}
        />
    );
};
