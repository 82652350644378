import {FileSize, useBestandView} from '@growthbase/bestandsbeheer';
import {ActionsMenu, ActionsMenuButton, LinkButton, ActionsMenuTrigger} from '@growthbase/design-components';
import {useDateObject, useLocale} from '@growthbase/spa';
import moment from 'moment';
import {FC, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './Bijlage.module.scss';

interface BijlageProps {
    bestandId: string;
    naam: string;
    bytes: number | null;
    date: string;
    preview: ReactNode;
    uploaded: boolean;
    kanVerwijderen: boolean;
    download: () => Promise<void>;
    onDelete: () => Promise<void>;
}

export const Bijlage: FC<BijlageProps> = ({
    naam,
    date,
    preview,
    bestandId,
    onDelete,
    download,
    uploaded,
    bytes,
    kanVerwijderen,
}) => {
    const {t} = useTranslation('TaakBijlage.nl');
    const dateObject = useDateObject(date);
    const {open: onClick} = useBestandView(bestandId);

    const locale = useLocale();
    const renderActionsMenuButton = () => <ActionsMenuTrigger />;

    return (
        <div className={styles.Bijlage}>
            <div className={styles.BijlagePreview}>{preview}</div>
            <div className={styles.BijlageBody}>
                <LinkButton disabled={!uploaded} onClick={onClick}>
                    {naam}
                </LinkButton>
                <div>
                    {t('toegevoegdOp', {
                        date: moment(dateObject).locale(locale).format('LL'),
                    })}{' '}
                    - <FileSize bytes={bytes} />
                </div>
                <div className={styles.BijlageMenu}>
                    <ActionsMenu key="menu" renderButton={renderActionsMenuButton}>
                        <ActionsMenuButton disabled={!uploaded} onClick={download}>
                            {t('download')}
                        </ActionsMenuButton>
                        <ActionsMenuButton disabled={!kanVerwijderen} onClick={onDelete}>
                            {t('verwijder')}
                        </ActionsMenuButton>
                    </ActionsMenu>
                </div>
            </div>
        </div>
    );
};
