import classNames from 'classnames/bind';
import {FC} from 'react';
import styles from './FlexLayout.module.scss';
import {FlexProps} from './FlexProps';

const cx = classNames.bind(styles);

/** @deprecated use Stack instead */
export const Column: FC<FlexProps> = ({children, smallGap, noGap}) => (
    <div
        className={cx('flex', {
            'flex--column': true,
            'flex--small': smallGap,
            'flex--noGap': noGap,
        })}
    >
        {children}
    </div>
);
