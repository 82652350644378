/* eslint-disable no-continue */
import React, {FC, useMemo} from 'react';
import {useAggregationQuery} from '@growthbase/graphql';
import {List, ListItem} from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import {find} from 'lodash';
import {Button} from '@growthbase/design-elements';
import {Aggregation, AggregationSection} from './Hook/useAggregationConfig';
import {LogFilter, useFilterBarStateUrlFactory} from '../ApplicationLogsTable/Components';
import {useSetErrorCount} from '../../Redux/ErrorCounterReducer';

export interface AggregationProps {
    section: AggregationSection;
    defaultFilter: LogFilter;
    profielId?: string | null;
    isOpen: boolean;
    refreshTimeSeconds: number;
}

const DisplayRed: FC<{
    error: boolean;
    children: number;
}> = ({error, children}) => <p style={{color: error && children > 0 ? 'red' : 'black'}}>{children}</p>;

export const AggregationList: FC<AggregationProps> = ({
    section,
    defaultFilter,
    refreshTimeSeconds,
    profielId,
    isOpen,
}) => {
    const [, navigate] = useFilterBarStateUrlFactory();

    const combinedAggregations = useMemo((): Array<Aggregation & {withProfileId?: boolean}> => {
        if (!profielId) {
            return section.aggregations;
        }

        const withProfileIds = [];
        for (const aggregation of section.aggregations) {
            withProfileIds.push({
                name: `${aggregation.name}_profileId`,
                withProfileId: true,
                filters: [
                    ...aggregation.filters,
                    {
                        profielId,
                    },
                ],
            });
        }
        return [
            ...section.aggregations,
            ...withProfileIds,
        ];
    }, [profielId, section.aggregations]);

    const {data: newData, previousData} = useAggregationQuery({
        variables: {
            filters: useMemo(() => [...section.filters, defaultFilter], [section.filters, defaultFilter]),
            aggregations: useMemo(
                () => combinedAggregations.map(({name, filters}) => ({name, filters})),
                [combinedAggregations]
            ),
        },
        pollInterval: refreshTimeSeconds * 1000,
    });

    const data = newData || previousData;

    const {aggregations: normalized, total} = useMemo(() => {
        const aggregations = data?.logsAggregation;
        if (!aggregations) {
            return {
                aggregations: [],
                total: 0,
            };
        }
        return aggregations;
    }, [data]);

    const sectionError = section.name.toLowerCase().includes('error');

    useSetErrorCount(section.name, sectionError ? total : 0);

    if (!isOpen) {
        return null;
    }

    const logFilters = [...section.filters, defaultFilter];

    return (
        <List sx={{minWidth: 150, bgcolor: 'background.paper'}}>
            <ListItem
                disableGutters
                secondaryAction={
                    <Button title="basis filter" onClick={() => navigate(logFilters)}>
                        <DisplayRed error={sectionError}>{total}</DisplayRed>
                    </Button>
                }
            >
                <ListItemText primary={section.name} />
            </ListItem>
            {normalized.map(({value, name}) => {
                const found = combinedAggregations.find((aggregation) => aggregation.name === name);
                if (!found || found.withProfileId) {
                    return null;
                }
                const profileName = `${name}_profileId`;
                const foundProfiel = find(combinedAggregations, {
                    name: profileName,
                });
                const foundProfielValue = find(normalized, {
                    name: profileName,
                });
                const isError = sectionError || name.toLowerCase().includes('error');
                return (
                    <ListItem
                        key={name}
                        disableGutters
                        secondaryAction={
                            <>
                                {foundProfiel && foundProfielValue && (
                                    <>
                                        <Button
                                            title="Van ingelogd profiel"
                                            onClick={() => {
                                                navigate([...logFilters, ...foundProfiel.filters]);
                                            }}
                                        >
                                            <DisplayRed error={isError}>{foundProfielValue.value}</DisplayRed>
                                        </Button>
                                        /
                                    </>
                                )}
                                <Button
                                    title="Alles"
                                    onClick={() => {
                                        navigate([...logFilters, ...found.filters]);
                                    }}
                                >
                                    <DisplayRed error={isError}>{value}</DisplayRed>
                                </Button>
                            </>
                        }
                    >
                        <ListItemText primary={`${name}`} />
                    </ListItem>
                );
            })}
        </List>
    );
};
