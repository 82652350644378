import React, {FC} from 'react';
import {IStandaardWeergaveBorden, IStandaardWeergaveLijsten, IStandaardWeergaveMijnDag} from '@growthbase/graphql';
import {Instellingen, ModelTabInstellingInput} from '@growthbase/instellingen';
import {InstellingenFields, useInstellingenFields} from './Hook/useInstellingenFields';

export interface Uren {
    directBoekenOpRelatie: boolean;
}

export interface Weergave {
    standaardWeergaveMijnDag: IStandaardWeergaveMijnDag;

    standaardWeergaveLijsten: IStandaardWeergaveLijsten;
    laatstGebruikteWeergaveLijsten: boolean;

    standaardWeergaveBorden: IStandaardWeergaveBorden;
    laatstGebruikteWeergaveBorden: boolean;
}

export interface Emails {
    meldingen: boolean;
    meldingBijToewijzenTaak: boolean;
    meldingBijInstellenDeadline: boolean;
    meldingBijNaderendeDeadline: boolean;
    meldingBijNaderendeDeadlineDagenVanTeVoren: number;
    aparteDeadlineEmail: boolean;
    meldingBijVerstrijkenVanDeadline: boolean;
    meldingBijAfrondenTaak: boolean;
    meldingenGroeperen: boolean;
    meldingBijDeelnemenVanTaak: boolean;
    meldingIntervalMinuten: number;
    meldingBijReactie: boolean;
    meldingBijActiverenTaak: boolean;
    meldingBijVerwijderenVanTaak: boolean;
    meldingBijGewijzigdeOmschrijving: boolean;
    meldingBijInstellenBegindatum: boolean;
    meldingBijNaderendeBegindatum: boolean;
    meldingBijNaderendeBegindatumDagenVanTeVoren: number;
    aparteBegindatumEmail: boolean;
    meldingVanSnooze: boolean;
}

export interface TaakInstellingen {
    uren: Uren;
    weergave: Weergave;
    email: Emails;
}

export interface InstellingenFormulierProps extends InstellingenFields {
    voorkeuren: TaakInstellingen;
    onSubmit: (values: TaakInstellingen) => Promise<void>;
    view?: boolean;
    title: string;
}

export const InstellingenFormulier: FC<InstellingenFormulierProps> = ({
    voorkeuren,
    view,
    title,
    onSubmit,
    ...props
}) => {
    const fields = useInstellingenFields(props);
    return (
        <Instellingen<TaakInstellingen>
            view={view}
            values={voorkeuren}
            fields={fields}
            titel={title}
            onSubmit={onSubmit}
            RootInputComponent={ModelTabInstellingInput}
        />
    );
};
