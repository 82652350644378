import {OpdrachtenTabConnected, PlanningSidebar, PlanningStoreState, useSelectedAgenda} from '@growthbase/planning';
import {useSelector} from 'react-redux';
import {BudgetTabConnected} from '../../Tabs/BudgetTab/BudgetTabConnected';

export const PlanningSidebarConnected = () => {
    const selectedAgenda = useSelectedAgenda();
    const employeeId = selectedAgenda?.employeeId;
    const {hash} = useSelector((state: PlanningStoreState) => state.planning);

    return (
        <PlanningSidebar
            opdrachtenTab={<OpdrachtenTabConnected />}
            budgetTab={employeeId && <BudgetTabConnected key={hash} employeeId={employeeId} />}
        />
    );
};
