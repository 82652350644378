import {ActionsMenu, ActionsMenuButton} from '@growthbase/design-components';
import {FC, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {LijsttemplateRoutes} from '@growthbase/takentemplates-lijsttemplate';

export interface TemplateMenuProps {
    verwijderTemplateMenuButton: ReactNode;
    ontkoppelTemplateMenuButton: ReactNode;
    lijsttemplateId: string;
}

export const TemplateMenu: FC<TemplateMenuProps> = ({
    verwijderTemplateMenuButton,
    ontkoppelTemplateMenuButton,
    lijsttemplateId,
}) => {
    const {t} = useTranslation('Lijsttemplate.nl');
    return (
        <ActionsMenu>
            {verwijderTemplateMenuButton}
            {ontkoppelTemplateMenuButton}

            <ActionsMenuButton
                to={LijsttemplateRoutes.bekijken.useUrl({
                    lijsttemplateId,
                })}
            >
                {t('bekijken')}
            </ActionsMenuButton>
        </ActionsMenu>
    );
};
