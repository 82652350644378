import {useEffect, useState} from 'react';
import {Opdracht} from '@growthbase/opdrachten-planning';
import {useSelector} from 'react-redux';
import {useCallbackRef} from '@growthbase/spa';
import {OpdrachtenTab} from './OpdrachtenTab';
import {useOpdrachtStatus} from '../../Sidebars/PlanningSidebar/useOpdrachtStatus';
import {useOpdrachtenNormalizer} from '../../Sidebars/PlanningSidebar/Hook/useOpdrachtenNormalizer';
import {useSidebarState} from '../../Sidebars';
import {usePlanningSideBarConnectedBudgetLazyQuery, usePlanningSidebarConnectedQuery} from '../../generated/generated';
import {PlanningStoreState} from '../../Redux';

export const OpdrachtenTabConnected = () => {
    const {handleChangeOpdrachtStatus, activeOrderStatus, searchArgs, setSearchArgs} = useOpdrachtStatus();
    const normalizeOpdrachten = useOpdrachtenNormalizer();
    const {opdracht} = useSelector((state: PlanningStoreState) => state.planning);

    const {data, loading} = usePlanningSidebarConnectedQuery({variables: searchArgs});
    const [requestBudget] = usePlanningSideBarConnectedBudgetLazyQuery();

    const [opdrachten, setOpdrachten] = useState<Opdracht[]>([]);

    const {handleKeuzeChange} = useSidebarState(opdrachten);

    const decoratedRequestBudget = useCallbackRef((gekozenOpdrachtId: string) => {
        requestBudget({
            variables: {
                opdrachtId: gekozenOpdrachtId,
            },
            onCompleted: (resultData) => {
                const diensten = resultData?.Opdracht?.diensten;
                if (!diensten) {
                    return;
                }

                const map = opdrachten.map((originalOpdracht) => {
                    if (originalOpdracht.id !== gekozenOpdrachtId) {
                        return originalOpdracht;
                    }

                    return {
                        ...originalOpdracht,
                        diensten: diensten.map((dienst) => ({
                            id: dienst?.id ?? '',
                            titel: dienst?.titel ?? '',
                            resterendBudgetVoorOpdracht: dienst?.resterendBudgetVoorOpdracht
                                ? {
                                      resterendeTijdInSeconde:
                                          dienst.resterendBudgetVoorOpdracht.resterendeTijdInSeconde,
                                  }
                                : undefined,
                        })),
                    };
                });
                setOpdrachten(map);
            },
        });
    });

    useEffect(() => {
        setOpdrachten(normalizeOpdrachten(data));
    }, [data, normalizeOpdrachten, setOpdrachten]);

    useEffect(() => {
        const listener = () => {
            if (opdracht) {
                decoratedRequestBudget(opdracht.id);
            }
        };

        document.addEventListener('refetchPlanning', listener);
        return () => {
            document.removeEventListener('refetchPlanning', listener);
        };
    }, [decoratedRequestBudget, opdracht]);

    useEffect(() => {
        if (opdracht) {
            decoratedRequestBudget(opdracht.id);
        }
    }, [decoratedRequestBudget, opdracht]);

    return (
        <OpdrachtenTab
            loading={loading}
            opdrachten={opdrachten}
            activeOpdrachtStatus={activeOrderStatus}
            searchQuery={searchArgs.fulltextSearch ?? ''}
            onSearchQueryChange={(query) => {
                setSearchArgs({
                    ...searchArgs,
                    fulltextSearch: query ?? '',
                });
            }}
            onChangeOpdrachtStatus={handleChangeOpdrachtStatus}
            onKeuzeAangepast={handleKeuzeChange}
        />
    );
};
