import {FormikFieldProps, useMemoryPopupControls, useSetInputActiveFromBool} from '@growthbase/design-components';
import {IRelatieButtonGroupsFragment, IRelatieGroupsQuery} from '@growthbase/graphql';
import {RelatieButton, RelatieValue} from '@growthbase/relaties';
import {useField} from 'formik';
import {FC, useCallback} from 'react';
import {RelatiePopupConnected} from '../RelatiePopup/RelatiePopupConnected';
import {RelatieButtonContentInline} from '../RelatieButtonContentInline';
import {RelatieButtonContent} from '../RelatieButtonContent';

export interface RelatieInputProps extends FormikFieldProps<RelatieValue> {
    requestData?: (query: IRelatieGroupsQuery) => Promise<IRelatieButtonGroupsFragment>;
    inline?: boolean;
}

export const RelatieInput: FC<RelatieInputProps> = ({
    disabled,
    readonly,
    onBlur,
    onFocus,
    field: {name},
    requestData,
    inline,
}) => {
    const [{value}, , {setValue}] = useField(name);
    const controls = useMemoryPopupControls();
    const {isOpen} = controls;
    useSetInputActiveFromBool({
        onFocus,
        onBlur,
        isActive: isOpen,
    });
    return (
        <RelatieButton
            disabled={disabled}
            readonly={readonly}
            controls={controls}
            requestData={useCallback(
                (query): Promise<IRelatieButtonGroupsFragment> => {
                    if (requestData) {
                        return requestData(query);
                    }
                    return Promise.resolve({
                        groups: [],
                    });
                },
                [requestData]
            )}
            onChange={useCallback(
                (val) => {
                    setValue(val);
                    return Promise.resolve();
                },
                [setValue]
            )}
            RelatiePopup={RelatiePopupConnected}
            value={value}
            ContentElement={inline ? RelatieButtonContentInline : RelatieButtonContent}
        />
    );
};
