import React, {useMemo, FC} from 'react';
import {IBordtemplateMetLijstenBordLijstFragment, useBordtemplateMetLijstenQuery} from '@growthbase/graphql';
import {usePollInterval} from '@growthbase/websockets';
import {idFromIri, usePaginationSearchParams} from '@growthbase/routing';
import {
    AddBreadcrumb,
    Loader,
    SearchForm,
    TableFiltersHeader,
    useGebruikerHeeftToegang,
    useSearchField,
} from '@growthbase/design-components';
import {Filters, OverviewConnected, useViewSelectState, ViewSelect} from '@growthbase/takentemplates-lijsttemplate';
import {BordMetLijstenData, View} from './View';
import {useEntityChanges} from './Hook/useEntityChanges';
import {useLijstDrop} from './Hook/useLijstDrop';
import {ActionsBarConnected} from './Components';

export interface ViewConnectedProps {
    bordtemplateId: string;
    bordSelect: React.ReactNode;
}

export const ViewConnected: FC<ViewConnectedProps> = ({bordtemplateId, bordSelect}) => {
    const {pagination, usePagination} = usePaginationSearchParams<IBordtemplateMetLijstenBordLijstFragment>('lijsten');
    const result = useBordtemplateMetLijstenQuery({
        pollInterval: usePollInterval(),
        variables: {
            ...pagination,
            bordId: idFromIri(bordtemplateId),
            id: idFromIri(bordtemplateId),
        },
    });
    const fragment = result.data?.bordtemplate;
    const toegang = useGebruikerHeeftToegang({
        enabled: !result.loading,
        toegang: !!result?.data,
    });
    const data = useMemo(
        (): BordMetLijstenData => ({id: bordtemplateId, titel: fragment?.naam ?? ''}),
        [bordtemplateId, fragment?.naam]
    );
    useEntityChanges({bordId: bordtemplateId, result});
    const [searchQuery, setSearchQuery] = useSearchField('taak');
    const helpers = usePagination(result);
    const onDrop = useLijstDrop(result);
    const filters = useMemo((): Filters => ({searchQuery: searchQuery.query}), [searchQuery]);

    const [view, setView] = useViewSelectState({
        bordId: bordtemplateId,
    });
    if (!toegang) {
        return null;
    }

    if (!fragment) {
        return <Loader center />;
    }

    return (
        <>
            <AddBreadcrumb>{fragment.naam}</AddBreadcrumb>
            <View<IBordtemplateMetLijstenBordLijstFragment>
                header={
                    <TableFiltersHeader
                        leftActions={[
                            <SearchForm
                                key="search"
                                disableAutofocus
                                loading={result.loading}
                                onChange={setSearchQuery}
                                value={searchQuery}
                            />,
                            bordSelect,
                        ]}
                        rightActions={[
                            <ViewSelect key="select" onChange={setView} value={view} />,
                            <ActionsBarConnected key="actions" fragment={fragment} />,
                        ]}
                    />
                }
                data={data}
                pagination={helpers}
            >
                <OverviewConnected
                    pagination={helpers}
                    userData={data}
                    onDrop={onDrop}
                    filters={filters}
                    view={view}
                    readonly={false}
                    disabled={false}
                />
            </View>
        </>
    );
};
