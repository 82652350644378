import {QueryResult} from '@apollo/client/react/types/types';
import {
    ITakenVanLijstSwimlaneQuery,
    ITakenVanLijstSwimlaneQueryVariables,
    useMaakTaakInSwimlaneLijstMutation,
} from '@growthbase/graphql';
import {useCallbackRef} from '@growthbase/spa';
import {useAddTaakLockHandler} from './useAddTaakLockHandler';

export const useMaakTaakInLijstHandler = (
    lijstId: string,
    result: QueryResult<ITakenVanLijstSwimlaneQuery, ITakenVanLijstSwimlaneQueryVariables>
) => {
    const [mutateAsync] = useMaakTaakInSwimlaneLijstMutation();
    return useAddTaakLockHandler(
        result,
        useCallbackRef((data) =>
            mutateAsync({
                variables: {
                    input: {
                        ...data,
                        lijsten: [lijstId],
                    },
                },
            })
        )
    );
};
