import {useEffect, useState} from 'react';

export const useDebouncedValue = <T>(value: T, timeout: number): T => {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(() => {
        const handle = setTimeout(() => {
            setDebouncedValue(value);
        }, timeout);
        return () => clearTimeout(handle);
    }, [timeout, value]);

    return debouncedValue;
};
