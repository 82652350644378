import {concat, merge, of, share, mapTo} from 'rxjs';
import {JQueryAjaxCompleted} from './jQueryAjaxCompleted';
import {pageReady} from './pageReady';

const hot = merge(JQueryAjaxCompleted().pipe(mapTo('Ajax completed')), pageReady().pipe(mapTo('Page ready'))).pipe(
    share()
);

/**
 * Event source for all things that adds new html to the page.
 */
export const pageRerender = () => concat(of('initial'), hot);
