import {useEffect, useMemo, useState} from 'react';
import {useBackgroundRoute, useCurrentHref, useRoutes} from '@growthbase/routing';
import {matchRoutes} from 'react-router-dom';
import {useLogger} from '@growthbase/spa';
import {removeLegacyContainers} from '@growthbase/design-components';
import {isSpaRoutes} from './createRouteHooksFromRoutes';

const useKnownSpaPaths = () => {
    const routes = useRoutes();
    const logger = useLogger('useKnownSpaPaths');

    return useMemo((): Array<{path: string}> => {
        const result: Array<{path: string; mainContext: string; subContext: string}> = [];

        const collectPaths = (target: object, spaRoutes: string[], mainContext: string) => {
            for (const [key, value] of Object.entries(target)) {
                if (typeof value === 'string' && spaRoutes.includes(key)) {
                    result.push({
                        path: value,
                        mainContext,
                        subContext: key,
                    });
                }
            }
        };

        const collectRoute = (target: object) => {
            for (const [key, value] of Object.entries(target)) {
                if (typeof value === 'object') {
                    collectPaths(value, isSpaRoutes.get(key) || [], key);
                }
            }
        };
        collectRoute(routes);

        logger.debug('Known spa paths', result);

        return result;
    }, [logger, routes]);
};

const useRouteIsSpaRoute = (path: string, paths: Array<{path: string}>) =>
    useMemo(() => {
        const previousRouteMatches = matchRoutes(paths, path);
        return previousRouteMatches && previousRouteMatches.length !== 0;
    }, [path, paths]);

/**
 * This handles the routing if the user navigates by using the browser back/forward buttons.
 */
export const useRefreshBrowserForLegacyRoutes = () => {
    const logger = useLogger();
    const paths = useKnownSpaPaths();
    const href = useCurrentHref();
    const [bg] = useBackgroundRoute();
    const location = bg ?? href;

    const [previousPath, setPreviousPath] = useState(location);

    const previousPathIsSpaRoute = useRouteIsSpaRoute(previousPath, paths);
    const currentPathIsSpaRoute = useRouteIsSpaRoute(location, paths);

    useEffect(() => {
        if (location !== previousPath) {
            setPreviousPath(location);
        }
        if (currentPathIsSpaRoute) {
            logger.debug('Current path is a spa route.');
        }
        if (previousPathIsSpaRoute) {
            logger.debug('Previous path was a spa route.');
        }
        if (!currentPathIsSpaRoute && previousPathIsSpaRoute) {
            logger.warn('Current path is not a spa route, but previous path was, reloading page.');
            window.location.reload();
        }
        if (!previousPathIsSpaRoute && currentPathIsSpaRoute) {
            logger.warn('Previous path was not a spa route, but current path is, removeLegacyContainers.');
            removeLegacyContainers();
        }
    }, [
        currentPathIsSpaRoute,
        location,
        logger,
        paths,
        previousPath,
        previousPathIsSpaRoute,
        setPreviousPath,
    ]);
};
