import {FC, PropsWithChildren, useMemo} from 'react';
import {Button, PopupControlsContext, useMemoryPopupControls} from '@growthbase/design-components';
import {useReplayMutation} from '@growthbase/graphql';
import {useCallbackRef} from '@growthbase/spa';
import {toast} from 'react-toastify';
import {ReplayOptionsForm, ReplayOptionsInput} from './Components/ReplayOptionsForm';

export interface ReplayButtonConnectedProps extends PropsWithChildren {
    profileId?: string;
    projectionId?: string;
}

export const ReplayButtonConnected: FC<ReplayButtonConnectedProps> = ({profileId, projectionId, children}) => {
    const popup = useMemoryPopupControls();

    const [mutation, {loading}] = useReplayMutation();

    const handle = useCallbackRef(async (options: ReplayOptionsInput) => {
        popup.close();
        const promise = mutation({
            variables: {
                options: {
                    ...options,
                    profileId: options.profileId === '' ? undefined : options.profileId,
                    projectionId: options.projectionId === '' ? undefined : options.projectionId,
                    breakpointOnMessageId:
                        options.breakpointOnMessageId === 0 ? undefined : options.breakpointOnMessageId,
                    breakOnModelChange: options.breakOnModelChange === '' ? undefined : options.breakOnModelChange,
                },
            },
        });
        const simple = promise.then(({data}) => data?.replay?.started ?? 0);
        await toast.promise<number>(simple, {
            success: {
                render: (started) => `${started.data} replays started`,
                autoClose: 3000,
            },
            error: 'Replay failed',
        });
    });

    const initialValues = useMemo(
        () => ({
            profileId,
            projectionId,
        }),
        [profileId, projectionId]
    );

    return (
        <PopupControlsContext.Provider value={popup}>
            <Button onClick={popup.toggle} loading={loading} disabled={loading}>
                {children ?? 'Replay'}
            </Button>
            <ReplayOptionsForm onSubmit={handle} initialValues={initialValues} />
        </PopupControlsContext.Provider>
    );
};
