import {CreateRouteFunction, createRoutes, idFromIri, PageFromMenu, RouteElementProps} from '@growthbase/routing';
import {generatePath, Link} from 'react-router-dom';
import {HoverIcon, RoutePopupProvider, useAddReferenceLinkResolver} from '@growthbase/design-components';
import {LijstCreateFormConnected, LijstCreateFormConnectedProps} from '@growthbase/taken';
import {
    EditModalPageConnected,
    EditModalPageConnectedProps,
    LijsttemplateAlsLijstToevoegenFormConnected,
    LijsttemplateAlsLijstToevoegenFormConnectedProps,
} from '@growthbase/takenlijst-lijst';
import React, {useCallback} from 'react';
import {LogbookConnected} from '@growthbase/activiteiten';
import {CreateFormConnected} from './CreateForm';
import {EditFormConnected} from './EditForm';
import {OverviewConnected} from './Overview';
import {ViewConnected} from './View';
import {VerwijderLegeBorden} from './ActionMenu';

const generateBordRoute: CreateRouteFunction<{
    bordId: string;
}> = (route: string, params) =>
    generatePath(route, {
        bordId: idFromIri(params?.bordId ?? ''),
    });

const generateLijstRoute: CreateRouteFunction<{
    bordId: string;
    lijstId: string;
}> = (route: string, params) =>
    generatePath(route, {
        bordId: idFromIri(params?.bordId ?? ''),
        lijstId: idFromIri(params?.lijstId ?? ''),
    });

export const BordRoutes = createRoutes('bord', {
    overview: {
        Element: () => (
            <PageFromMenu sidebar={<LogbookConnected relatedTypes={['TakenBord']} />}>
                <OverviewConnected showEmpty archief={false} actions={<VerwijderLegeBorden />} />
            </PageFromMenu>
        ),
    },
    mijnborden: {
        Element: () => (
            <PageFromMenu sidebar={<LogbookConnected relatedTypes={['TakenBord']} />}>
                <OverviewConnected mijnborden showEmpty={false} archief={false} />
            </PageFromMenu>
        ),
    },
    archief: {
        Element: () => (
            <PageFromMenu sidebar={<LogbookConnected relatedTypes={['TakenBord']} />}>
                <OverviewConnected archief />
            </PageFromMenu>
        ),
    },
    aanmaken: {
        Element: () => (
            <RoutePopupProvider>
                <CreateFormConnected />
            </RoutePopupProvider>
        ),
        defaultBackgroundRoute: 'overview',
    },
    bewerken: {
        defaultBackgroundRoute: 'overview',
        Element: ({
            params,
        }: RouteElementProps<{
            bordId: string;
        }>) => (
            <RoutePopupProvider>
                <EditFormConnected bordId={params?.bordId ?? ''} />
            </RoutePopupProvider>
        ),
        createRoute: generateBordRoute,
    },
    activiteiten: {
        defaultBackgroundRoute: 'overview',
        Element: ({
            params,
        }: RouteElementProps<{
            bordId: string;
        }>) => (
            <RoutePopupProvider>
                <EditFormConnected bordId={params?.bordId ?? ''} />
            </RoutePopupProvider>
        ),
        createRoute: generateBordRoute,
    },
    view: {
        Element: ({
            params,
        }: RouteElementProps<{
            bordId: string;
        }>) => (
            <PageFromMenu sidebar={<LogbookConnected id={params?.bordId ?? ''} />}>
                <ViewConnected bordId={params?.bordId ?? '-'} />
            </PageFromMenu>
        ),
        createRoute: generateBordRoute,
    },
    lijst_aanmaken: {
        defaultBackgroundRoute: 'view',
        Element: (props: LijstCreateFormConnectedProps) => <LijstCreateFormConnected {...props} />,
        createRoute: generateBordRoute,
    },
    lijsttemplate_als_lijst_toevoegen: {
        defaultBackgroundRoute: 'view',
        Element: (props: LijsttemplateAlsLijstToevoegenFormConnectedProps) => (
            <LijsttemplateAlsLijstToevoegenFormConnected {...props} />
        ),
        createRoute: generateBordRoute,
    },
    lijst_bewerken: {
        defaultBackgroundRoute: 'overview',
        Element: (props: EditModalPageConnectedProps) => <EditModalPageConnected {...props} />,
        createRoute: generateLijstRoute,
    },
});

BordRoutes.decorate((Decorated) => {
    const viewBord = BordRoutes.view.useUrlFactory();
    useAddReferenceLinkResolver(
        useCallback(
            (data) => {
                if (data.type === 'TakenBord') {
                    const link = (
                        <Link
                            to={viewBord({
                                bordId: data.id,
                            })}
                        >
                            {data.text}
                        </Link>
                    );
                    if (data.gearchiveerd) {
                        return link;
                    }
                    return <HoverIcon>{link}</HoverIcon>;
                }
                return null;
            },
            [
                viewBord,
            ]
        )
    );
    return <Decorated />;
}, 'RoutesLinkResolver');
