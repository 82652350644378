import {FC, ReactNode, useCallback, useMemo} from 'react';
import {Buttons} from '../Button';
import {
    Popup,
    PopupAnchorProps,
    PopupContent,
    PopupControlsContext,
    PopupHeader,
    useMemoryPopupControls,
} from '../Popups';
import {DateRangeOptions, ISOStringDateRange, NullableISOStringDateRange} from './Hook';
import {DateRangePickerContent} from './DateRangePickerContent';
import {MinimumDateOptions} from '../Form';

export interface DateRangePickerProps {
    header?: ReactNode;
    labels?: Partial<DateRangeOptions>;
    readonly?: boolean;
    disabled?: boolean;
    value?: NullableISOStringDateRange | null;
    onSubmit: (value: NullableISOStringDateRange) => Promise<void>;
    renderAnchor: (props: PopupAnchorProps) => ReactNode;
    minimumEndDate?: MinimumDateOptions;
}

export const DateRangePicker: FC<DateRangePickerProps> = ({
    onSubmit,
    header,
    renderAnchor,
    value,
    labels,
    readonly,
    disabled,
    minimumEndDate,
}) => {
    const controls = useMemoryPopupControls(false, false, false, 'dateRangePicker');
    const normalized = useMemo(
        (): ISOStringDateRange => ({
            from: value?.from ?? null,
            to: value?.to ?? null,
        }),
        [value]
    );

    const defaultRenderAnchor = useCallback(
        ({reference, controls: {open}}: PopupAnchorProps) => (
            <Buttons.CalendarIconOnly ref={reference} onClick={open} readonly={readonly} disabled={disabled} />
        ),
        [disabled, readonly]
    );

    const normalizedSubmit = useCallback(
        (submitValues: NullableISOStringDateRange) =>
            onSubmit({
                from: submitValues.from?.date ? submitValues.from : null,
                to: submitValues.to?.date ? submitValues.to : null,
            }).finally(controls.close),
        [onSubmit, controls]
    );

    return (
        <PopupControlsContext.Provider value={controls}>
            <Popup renderAnchor={renderAnchor || defaultRenderAnchor} showOnRowHover arrow>
                <PopupContent>
                    <PopupHeader title={header} small />
                    <DateRangePickerContent
                        labels={labels}
                        value={normalized}
                        onSubmit={normalizedSubmit}
                        disabled={disabled}
                        minimumEndDate={minimumEndDate}
                    />
                </PopupContent>
            </Popup>
        </PopupControlsContext.Provider>
    );
};
