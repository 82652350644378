import {
    Buttons,
    Flex,
    PositionType,
    SwimlaneCard,
    SwimlaneCardBody,
    SwimlaneCardFooter,
    SwimlaneFooter,
} from '@growthbase/design-components';
import {DeelnemersInputConnected, GebruikersComboboxIgnoreIds} from '@growthbase/gebruikers';
import {RelatieInputConnected} from '@growthbase/relaties';
import {useCollapsible, useMemoProp} from '@growthbase/spa';
import {MaakTaakAanTaakFormDTO, KoppelButtonInputConnected, RenderKoppelButtonProps} from '@growthbase/taken';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useCreateTaakForm} from '../../../../../TaakCreateForm/Hook/useCreateTaakForm';
import {TaakPlanningInput} from '../../../../../TaakPlanningInput';
import {KoppelButtonContent} from '../../../../../Koppelen/KoppelButton/KoppelButtonContent';

export interface VoegTaakToeFormProps {
    onSubmit: (values: MaakTaakAanTaakFormDTO) => Promise<void>;
    OnderdeelVanComponent?: typeof KoppelButtonInputConnected;
}

const renderKoppelButton = (props: RenderKoppelButtonProps) => <KoppelButtonContent {...props} size="small" />;

export const VoegTaakToeForm: FC<VoegTaakToeFormProps> = ({
    OnderdeelVanComponent = KoppelButtonInputConnected,
    ...rest
}) => {
    const {Form, Fields, render, formik, formProps, renderTitel} = useCreateTaakForm(rest);
    const {DeelnemersField, PlanningField, OnderdeelVanField, RelatieField} = Fields;

    const Planning = PlanningField.useComponent(TaakPlanningInput);
    const Deelnemers = DeelnemersField.useComponent(DeelnemersInputConnected);
    const Relatie = RelatieField.useComponent(RelatieInputConnected);
    const OnderdeelVan = OnderdeelVanField.useComponent(OnderdeelVanComponent);

    const {
        values: {deelnemers, onderdeelVan, planning},
    } = formik;
    const {t} = useTranslation('spa_form.nl');
    const {isOpen, open, ref} = useCollapsible();
    const deelnemerIDs = useMemoProp(deelnemers, 'id');
    if (!isOpen) {
        return (
            <SwimlaneFooter ref={ref} padding>
                <Buttons.PlusCircle onClick={open} variant="link">
                    {t('taak.add')}
                </Buttons.PlusCircle>
            </SwimlaneFooter>
        );
    }
    return (
        <SwimlaneFooter ref={ref}>
            {render(
                <Form {...formProps}>
                    <SwimlaneCard>
                        <SwimlaneCardBody>
                            <Flex.Column smallGap>
                                <Flex.Row>
                                    <GebruikersComboboxIgnoreIds ids={deelnemerIDs}>
                                        {renderTitel({
                                            variant: 'default',
                                            autoFocus: true,
                                        })}
                                    </GebruikersComboboxIgnoreIds>
                                </Flex.Row>
                                <Flex.Row>
                                    <Relatie readonly />
                                </Flex.Row>
                                <Flex.Row>
                                    <Buttons.Save formik={formik} enabledWhenSubmitting />
                                </Flex.Row>
                            </Flex.Column>
                        </SwimlaneCardBody>
                        <SwimlaneCardFooter>
                            <Flex.Row itemsWidthAuto smallGap>
                                {Flex.createOrderedItems(
                                    {
                                        start: {
                                            cellMinWidth: 22,
                                        },
                                    },
                                    ({add}) => {
                                        add(
                                            <Planning Button={Buttons.CalendarPlusIconDottedSmall} />,
                                            planning?.to || planning?.from ? PositionType.start : PositionType.end
                                        );
                                        add(
                                            <OnderdeelVan renderKoppelButton={renderKoppelButton} />,
                                            onderdeelVan ? PositionType.start : PositionType.end
                                        );
                                        add(<Deelnemers size="small" stackSize={3} />, PositionType.end);
                                    }
                                )}
                            </Flex.Row>
                        </SwimlaneCardFooter>
                    </SwimlaneCard>
                </Form>
            )}
        </SwimlaneFooter>
    );
};
