import {createRoutes, PageFromMenu} from '@growthbase/routing';
import {AgendaConnected} from './Agenda';
import {Sidebar} from './Sidebar/Sidebar';

export const AgendaRoutes = createRoutes('agenda', {
    index: {
        Element: () => (
            <PageFromMenu sidebar={<Sidebar />}>
                <AgendaConnected />
            </PageFromMenu>
        ),
    },
});
