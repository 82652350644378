import {Stack, Table, TableHeader, TableHeaderSection} from '@growthbase/design-components';
import React, {FC} from 'react';
import {CellProps, Column} from 'react-table';
import {DeveloperRoutes} from '@growthbase/health';
import {
    IOrder,
    IProcessesPageQuery,
    IProcessesPageQueryVariables,
    IProcessPageDataFragment,
    useProcessesPageQuery,
} from '@growthbase/graphql';
import {useQueryPagination} from '@growthbase/spa';
import {useStateBooleanFromSearchParam, useStateStringFromSearchParam} from '@growthbase/routing';
import Checkbox from '@mui/material/Checkbox';
import {Link} from 'react-router-dom';
import moment from 'moment/moment';
import {FormControlLabel, TextField} from '@mui/material';
import {dutchFormat} from '../LogsTable';
import {DisplayProgress} from './Components/DisplayProgress';

export type DTO = IProcessPageDataFragment;

const IdCell: FC<CellProps<DTO>> = ({row}) => (
    <Stack horizontal>
        <Link to={DeveloperRoutes.process_detail.useUrl({id: row.original.id})}>{row.original.id}</Link>
    </Stack>
);

const NumberWhenNotZero = ({value}: {value?: number | null}) => <span>{value || ''}</span>;
const ProfileCell: FC<CellProps<DTO>> = ({row}) => {
    const urlFromProfiel = DeveloperRoutes.replay_profile_detail.useUrlFactory();
    const {
        original: {profiel},
    } = row;
    const {profielId, name} = profiel ?? {};
    return (
        <Stack>
            {profiel && (
                <>
                    <Link to={urlFromProfiel({id: profielId})}>{name}</Link> ({profielId})
                </>
            )}
        </Stack>
    );
};

const ProgressCell: FC<CellProps<DTO>> = ({row}) => {
    const {
        original: {countStatistic},
    } = row;
    return <DisplayProgress fragment={countStatistic} />;
};

const ProjectionCell: FC<CellProps<DTO>> = ({row}) => {
    const {
        original: {profiel},
    } = row;
    const {projection} = profiel ?? {};
    return (
        <Stack>
            {projection && (
                <Link to={DeveloperRoutes.projections_detail.useUrl({id: projection.id})}>{projection.id}</Link>
            )}
        </Stack>
    );
};
const DateCell: FC<CellProps<DTO>> = ({row}) => {
    const {
        original: {createdAt, finishedAt},
    } = row;
    let duration: moment.Duration | null = null;
    if (createdAt && finishedAt) {
        duration = moment.duration(moment(finishedAt).diff(moment(createdAt)));
    }
    return (
        <Stack>
            <p>{createdAt ? moment(createdAt).format(dutchFormat) : '-'}</p>
            <p>{finishedAt ? moment(finishedAt).format(dutchFormat) : '-'}</p>
            <p>{duration ? duration.humanize() : null}</p>
        </Stack>
    );
};
const columns: ReadonlyArray<Column<DTO>> = [
    {
        Header: 'Id',
        accessor: 'id',
        minWidth: 5,
        Cell: IdCell,
    },
    {
        Header: 'Version',
        accessor: 'version',
        minWidth: 5,
    },
    {
        Header: 'Tag',
        accessor: 'tag',
        minWidth: 5,
    },
    {
        Header: 'Reason',
        accessor: 'reason',
        minWidth: 5,
    },
    {
        Header: 'Profile',
        Cell: ProfileCell,
        minWidth: 250,
    },
    {
        Header: 'Projection',
        Cell: ProjectionCell,
        minWidth: 250,
    },
    {
        Header: 'Date',
        Cell: DateCell,
    },
    {
        Header: 'Emergency',
        accessor: 'emergency',
        minWidth: 5,
        Cell: NumberWhenNotZero,
    },
    {
        Header: 'Errors',
        accessor: 'errors',
        minWidth: 5,
        Cell: NumberWhenNotZero,
    },
    {
        Header: 'Warnings',
        accessor: 'warnings',
        minWidth: 5,
        Cell: NumberWhenNotZero,
    },
    {
        Header: 'Progress',
        accessor: 'countStatistic',
        minWidth: 10,
        Cell: ProgressCell,
    },
];

export interface ProcessesPageInterface {
    actief?: boolean;
    running?: boolean;
    tag?: string;
    profileId?: string;
    projectionId?: string;
    hideFilters?: boolean;
    hideProjectionFilter?: boolean;
    hideProfielFilter?: boolean;
}

export const ProcessesTable: FC<ProcessesPageInterface> = ({
    profileId: initialProfielId,
    projectionId: initialProjectionId,
    hideProjectionFilter,
    hideProfielFilter,
    hideFilters,
    tag: initialTag,
    actief: initialActief,
    running: initialRunning,
}) => {
    const [stateActief, setActief] = useStateBooleanFromSearchParam('actief', true);
    const [stateRunning, setRunning] = useStateBooleanFromSearchParam('running');
    const [stateProfielId, setProfielId] = useStateStringFromSearchParam('profielId');
    const [stateProjectionId, setProjectionId] = useStateStringFromSearchParam('projectionId');
    const [stateTag, setTag] = useStateStringFromSearchParam('tag');

    const projectionId = initialProjectionId ?? stateProjectionId;
    const profielId = initialProfielId ?? stateProfielId;
    const tag = initialTag ?? stateTag;
    const actief = initialActief ?? stateActief;
    const running = initialRunning ?? stateRunning;

    const query = useProcessesPageQuery({
        pollInterval: 2000,
        variables: {
            filters: {
                projectionId,
                active: actief,
                profielId,
                tag,
                running,
                pagination: {
                    order: IOrder.Desc,
                    first: 50,
                },
            },
        },
    });
    const pagination = useQueryPagination<IProcessesPageQuery, IProcessesPageQueryVariables, IProcessPageDataFragment>({
        query,
        connectionsMapper: ({processes}) => processes,
        fetchBefore: (variables) => ({
            filters: {
                pagination: {
                    after: variables.first,
                },
            },
        }),
        mergeBefore: (previousResult, fetchMoreResult) => ({
            processes: [...fetchMoreResult.processes, ...previousResult.processes],
        }),
        fetchAfter: (variables) => ({
            filters: {
                pagination: {
                    before: variables.last,
                },
            },
        }),
        mergeAfter: (previousResult, fetchMoreResult) => ({
            processes: [...previousResult.processes, ...fetchMoreResult.processes],
        }),
    });
    return (
        <Table<DTO>
            header={
                !hideFilters && (
                    <TableHeader>
                        <TableHeaderSection>
                            <Stack.Item>
                                <Stack horizontal gap="tiny" align="center">
                                    <FormControlLabel
                                        label="Actief"
                                        labelPlacement="top"
                                        control={
                                            <Checkbox
                                                checked={actief}
                                                value={actief}
                                                onChange={(e) => setActief(e.target.checked)}
                                            />
                                        }
                                    />
                                    {!initialRunning && (
                                        <FormControlLabel
                                            label="Running"
                                            labelPlacement="top"
                                            control={
                                                <Checkbox
                                                    checked={running}
                                                    value={running}
                                                    onChange={(e) => setRunning(e.target.checked)}
                                                />
                                            }
                                        />
                                    )}
                                    {!initialProjectionId && !hideProjectionFilter && (
                                        <Stack.Item grow>
                                            <TextField
                                                label="ProjectionId"
                                                placeholder="ProjectionId"
                                                fullWidth
                                                value={projectionId}
                                                onChange={(e) => setProjectionId(e.target.value)}
                                            />
                                        </Stack.Item>
                                    )}
                                    {!initialProfielId && !hideProfielFilter && (
                                        <Stack.Item grow>
                                            <TextField
                                                label="ProfielId"
                                                placeholder="ProfielId"
                                                fullWidth
                                                value={profielId}
                                                onChange={(e) => setProfielId(e.target.value)}
                                            />
                                        </Stack.Item>
                                    )}
                                    <Stack.Item grow>
                                        <TextField
                                            label="Tag"
                                            placeholder="Tag"
                                            fullWidth
                                            value={tag}
                                            onChange={(e) => setTag(e.target.value)}
                                        />
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                        </TableHeaderSection>
                    </TableHeader>
                )
            }
            columns={columns}
            pagination={pagination}
        />
    );
};
