import React, {ReactNode, useCallback, useMemo, useRef} from 'react';
import {autoUpdate, flip, useFloating, shift} from '@floating-ui/react-dom-interactions';
import {arrow} from '@floating-ui/react';
import {useBoolean} from 'react-use';
import {UseFloatingReturn} from '@floating-ui/react-dom-interactions/src/types';
import {useClickOutside} from '@growthbase/keys';
import styles from './SidenavCounterPopup.module.scss';
import {SidenavPopupPortal} from '../SidenavPopupPortal';
import {Button} from '../../Button';

export interface SidenavCounterPopupButtonProps {
    onClick: () => void;
    reference: UseFloatingReturn['reference'];
}

export interface SidenavCounterPopupProps {
    children: ReactNode;
    isOpen?: boolean;
    renderButton: (props: SidenavCounterPopupButtonProps) => ReactNode;
}

const renderDefaultButton = ({reference, onClick}: SidenavCounterPopupButtonProps) => (
    <Button onClick={onClick} ref={reference}>
        x
    </Button>
);

export const SidenavCounterPopup = ({
    children,
    isOpen = false,
    renderButton = renderDefaultButton,
}: SidenavCounterPopupProps) => {
    const arrowRef = React.useRef<HTMLDivElement>(null);

    const [open, setOpen] = useBoolean(isOpen);

    const {x, y, strategy, floating, reference, middlewareData, refs} = useFloating({
        open,
        middleware: [flip(), shift({padding: 15}), arrow({element: arrowRef})],
        placement: 'right',
        whileElementsMounted: autoUpdate,
    });

    const {y: arrowY} = middlewareData?.arrow || {x: 0, y: 0};

    const toggle = useCallback(() => {
        setOpen((prevValue: boolean) => !prevValue);
    }, [setOpen]);

    const outerRef = useRef();

    useClickOutside(
        open,
        useMemo(() => [refs.floating, refs.domReference], [refs.floating, refs.domReference]),
        () => setOpen(false)
    );

    return (
        <>
            {useMemo(
                () =>
                    renderButton({
                        reference,
                        onClick: toggle,
                    }),
                [renderButton, reference, toggle]
            )}
            <SidenavPopupPortal>
                {open && (
                    <div ref={outerRef.current}>
                        <div
                            ref={floating}
                            className={styles.sidenavCounterPopup__popupWrapper}
                            style={{position: strategy, top: y ?? 0, left: x ?? 0}}
                        >
                            <div className={styles.sidenavCounterPopup__arrow} ref={arrowRef} style={{top: arrowY}} />
                            <div className={styles.sidenavCounterPopup__popup}>{children}</div>
                        </div>
                    </div>
                )}
            </SidenavPopupPortal>
        </>
    );
};
