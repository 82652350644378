import {createAvatarRowData, MemoryPopupProvider} from '@growthbase/design-components';
import {FC} from 'react';
import {noop} from 'rxjs';
import {Deelnemers, DeelnemersProps} from './Deelnemers';

const fixedItems = [
    {
        ...createAvatarRowData(1),
    },
    {
        ...createAvatarRowData(2),
    },
    {
        ...createAvatarRowData(3),
    },
];

export const DeelnemersStub: FC<Partial<DeelnemersProps>> = ({readonly, ...props}) => (
    <MemoryPopupProvider>
        <Deelnemers
            items={fixedItems}
            popupItems={fixedItems}
            readonly={readonly}
            onAdd={noop}
            onRemove={noop}
            onChangeQuery={noop}
            searchQuery={null}
            loading={false}
            {...props}
        />
    </MemoryPopupProvider>
);

export const renderDeelnemersFieldStub = (props: Partial<DeelnemersProps>) => <DeelnemersStub {...props} />;
