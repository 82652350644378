import {mergeMap} from 'rxjs';
import {logInfo, pageRerender} from '../rxjs';
import {logger} from '../Utils';
import {apply} from './apply';
import {findDateOffsetTypeExtensionsOnce} from './Observable';

export const loadDateOffsetTypeExtension = () =>
    pageRerender()
        .pipe(
            logInfo('DateOffsetTypeExtension:Page render..'),
            mergeMap(() => findDateOffsetTypeExtensionsOnce()),
            logInfo('DateOffsetTypeExtension:Apply conditions..'),
            mergeMap(apply(logger.withPrefix('DateOffsetTypeExtension'))),
            logInfo('DateOffsetTypeExtension:Conditions applied..')
        )
        .subscribe();
