import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {BaseButtonProps, ViewConfig} from '@growthbase/design-components';

export interface ArchiveerTaakButtonProps extends ViewConfig {
    gearchiveerd: boolean;
    onSubmit: () => void;
    SubmitButton: FC<BaseButtonProps>;
}

export const ArchiveerTaakButton: FC<ArchiveerTaakButtonProps> = ({gearchiveerd, onSubmit, SubmitButton, ...props}) => {
    const {t} = useTranslation('spa.nl');
    const titel = gearchiveerd ? t('form.unArchive') : t('form.archive');
    return (
        <SubmitButton onClick={onSubmit} {...props}>
            {titel}
        </SubmitButton>
    );
};
