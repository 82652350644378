import {InstellingenKopieerForm} from '@growthbase/instellingen';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {TaakKopierenPropertiesValues} from '@growthbase/taken';
import {useLijstKopierenOpties} from './Hook/useLijstKopierenOpties';

export interface TaakKopieerFilter {
    alles?: boolean;
    gearchiveerdeTaken: boolean;
    actieveTaken: boolean;
    afgerondeTaken: boolean;
}

export interface LijstKopierenFormValues {
    title: string;
    taakFilter: TaakKopieerFilter;
    taken: boolean;
    activiteiten: boolean;
    taak: TaakKopierenPropertiesValues;
}

export interface LijstKopierenFormProps {
    titel: string;
    onSubmit: (values: LijstKopierenFormValues) => Promise<void>;
}

export const KopieerForm: FC<LijstKopierenFormProps> = ({onSubmit, titel}) => {
    const {t} = useTranslation('TakenlijstLijst.nl');
    return (
        <InstellingenKopieerForm<LijstKopierenFormValues>
            titel={t('Kopieren.copyLijst')}
            fields={useLijstKopierenOpties(titel)}
            onSubmit={onSubmit}
        />
    );
};
