import React, {Ref, useMemo} from 'react';
import {useClickOutside, useKeys} from '@growthbase/keys';
import {useSwitch} from './useSwitch';

export interface CollapsibleOptions {
    open?: boolean;
}

export interface CollapsibleResult {
    isOpen: boolean;
    open: () => void;
    close: () => void;
    ref: Ref<HTMLElement>;
}

export const useCollapsible = ({open: defaultOpen}: CollapsibleOptions = {}): CollapsibleResult => {
    const [
        isOpen,
        open,
        close,
    ] = useSwitch(defaultOpen);
    useKeys('SwimlaneFooter: Close on escape', 'Escape', close, {
        enabled: isOpen,
    });
    const ref = React.useRef<HTMLElement>(null);
    useClickOutside(
        isOpen,
        useMemo(() => [ref], [ref]),
        close
    );
    return {
        open,
        close,
        isOpen,
        ref,
    };
};
