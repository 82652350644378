import React, {useCallback, FC} from 'react';
import {
    DefaultFormModalFooter,
    Modal,
    PopupHeader,
    ModalContent,
    TextFieldComponent,
    useCreateStrictForm,
} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';
import {EditFormSchemaDto, useLijstEditFormSchema} from './EditFormSchema';

export interface EditFormProps {
    value: EditFormSchemaDto;

    onSubmit: (values: EditFormSchemaDto) => Promise<void>;

    availableActions: {wijzigTitel: boolean};
}

export const EditForm: FC<EditFormProps> = ({value, onSubmit, availableActions}) => {
    const {t} = useTranslation('spa_form.nl');
    const {
        Form,
        Fields: {TitelField},
        formProps,
        formik,
    } = useCreateStrictForm<EditFormSchemaDto>({
        onSubmit,
        enableLockPopupAndRoutingWhenDirty: true,
        autoSubmit: false,
        createInitialValuesAfterSubmit: false,
        createInitialValues: useCallback(() => value, [value]),
        schema: useLijstEditFormSchema(),
    });
    return (
        <Modal>
            <PopupHeader title={t('takenlijst.lijst.EditForm.EditForm.PopupHeader')} />
            <ModalContent theme="gray">
                <Form {...formProps}>
                    <TitelField
                        readonly={!availableActions.wijzigTitel}
                        component={TextFieldComponent}
                        autoFocus
                        label={t('lijst.tabel.titel')}
                    />
                </Form>
            </ModalContent>
            <DefaultFormModalFooter readonly={!availableActions.wijzigTitel} formik={formik} />
        </Modal>
    );
};
