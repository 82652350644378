import {ReactNode} from 'react';
import classNames from 'classnames/bind';
import {Link, To} from 'react-router-dom';
import styles from './ButtonWithCounter.module.scss';

const cx = classNames.bind(styles);

export interface ButtonWithCounterProps {
    children: ReactNode;
    counterContent: ReactNode;
    onClick?: () => void;
    active?: boolean;
    to?: To;
}

export const ButtonWithCounter = ({children, counterContent, active, to, ...props}: ButtonWithCounterProps) => {
    const classes = cx('buttonWithCounter', {
        'buttonWithCounter--active': active,
    });

    if (to) {
        return (
            <Link {...props} className={classes} to={to}>
                <div className={cx('buttonWithCounter__text')}>{children}</div>
                <div className={cx('buttonWithCounter__counter')}>{counterContent}</div>
            </Link>
        );
    }

    return (
        <button type="button" {...props} className={classes}>
            <div className={cx('buttonWithCounter__text')}>{children}</div>
            <div className={cx('buttonWithCounter__counter')}>{counterContent}</div>
        </button>
    );
};
