import React, {FC} from 'react';
import {Stack} from '@growthbase/design-components';
import {IDisplayProgressFragment} from '@growthbase/graphql';
import {isDefined} from '@udecode/plate';
import {CircularProgressWithLabel} from '../../ProjectionsPage/Components/CircularProgressWithLabel';

export const DisplayProgress: FC<{fragment?: IDisplayProgressFragment | null}> = ({fragment}) => {
    if (!fragment) {
        return null;
    }
    const {count, total} = fragment;
    if (!isDefined(count)) {
        if (!isDefined(total)) {
            return null;
        }
        return <div>{total}</div>;
    }
    if (!isDefined(total) || total === 0 || total <= count) {
        return <div>{count}</div>;
    }
    return (
        <Stack gap="tiny" align="start" justify>
            {count}/{total} <CircularProgressWithLabel value={(count / total) * 100} />
        </Stack>
    );
};
