import {IKoppelButtonConnectedQueryVariables, useKoppelButtonConnectedQuery} from '@growthbase/graphql';
import {useState} from 'react';
import {SearchInput} from '@growthbase/design-components';
import {useNormalize} from './useNormalize';

export interface useQueryKoppelGroepenOptions {
    skip?: boolean;
    variables?: IKoppelButtonConnectedQueryVariables;
}

export const useQueryKoppelGroepen = ({skip, variables = {}}: useQueryKoppelGroepenOptions) => {
    const [searchQuery, setSearchQuery] = useState<SearchInput>({
        query: null,
    });

    const {
        loading,
        data: current,
        previousData,
    } = useKoppelButtonConnectedQuery({
        skip,
        variables: {
            query: searchQuery.query,
            ...variables,
        },
    });
    const data = current ?? previousData;

    const relatiegroepen = useNormalize({
        groepen: data?.Koppelen?.OpRelatie ?? [],
    });

    return {
        loading,
        searchQuery,
        setSearchQuery,
        relatiegroepen,
    };
};
