import {AsyncCombobox, Information, PopupResultsItem} from '@growthbase/design-components';
import {IRelatieType} from '@growthbase/graphql';
import React, {useMemo} from 'react';
import {TComboboxItem} from '@udecode/plate-combobox/dist/types';

export const RelatiesMentionKey = 'relaties_mention';
export const RelatiesMentionTrigger = '#';

export interface ComboboxRelaties {
    id: string;
    label: string;
    type: IRelatieType;
}

export interface ComboboxRelatieGroup {
    label: string;
    relaties: ComboboxRelaties[];
}

export interface RelatiesComboboxProps {
    groups: ComboboxRelatieGroup[];
}

interface ComboboxItemGroup {
    type: 'group';
    label: string;
    relaties: ComboboxRelaties[];
}

interface ComboboxItemRelatie {
    type: 'relatie';
    id: string;
    label: string;
    relatieType: IRelatieType;
}

export type RelatiesComboboxItem = ComboboxItemGroup | ComboboxItemRelatie;

function mapRelatieToComboItem(item: ComboboxRelaties): TComboboxItem<RelatiesComboboxItem> {
    return {
        key: item.id,
        text: item.label,
        disabled: false,
        data: {
            type: 'relatie',
            id: item.id,
            label: item.label,
            relatieType: item.type,
        },
    };
}

function mapRelatieGroupToComboItem(item: ComboboxRelatieGroup): Array<TComboboxItem<RelatiesComboboxItem>> {
    return [
        // {
        //     key: item.label,
        //     text: item.label,
        //     disabled: true,
        //     data: {
        //         type: 'group',
        //         label: item.label,
        //         relaties: item.relaties,
        //     },
        // },
        ...item.relaties.map(mapRelatieToComboItem),
    ];
}

// TODO: @bas should not use PopupResults components directly, support for custom components should be added to design-components.

export const RelatiesCombobox = ({groups}: RelatiesComboboxProps) => (
    <AsyncCombobox<TComboboxItem<RelatiesComboboxItem>>
        pluginKey={RelatiesMentionKey}
        trigger={RelatiesMentionTrigger}
        items={useMemo(() => groups.map(mapRelatieGroupToComboItem).flat(), [groups])}
    >
        {({data}) => {
            const {label, type} = data;
            if (type === 'group') {
                return <Information>{label}</Information>;
            }
            return (
                <PopupResultsItem key={data.id} data={data}>
                    {label}
                </PopupResultsItem>
            );
        }}
    </AsyncCombobox>
);
