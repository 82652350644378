import {useMemo} from 'react';
import * as yup from 'yup';
import {SchemaOf} from 'yup';
import moment, {Moment} from 'moment';
import {TFunction} from 'i18next';
import {DatePickerValue} from '../../Form/Components/Datepicker/types/DatePickerValue';
import {
    MinimumDateOptions,
    NullableDatePickerValueSchema,
} from '../../Form/Components/Datepicker/hook/useDatePickerSchema';

export interface ISOStringDate {
    date: DatePickerValue;
}

type NullableMoment = Moment | null;
export const asMomentValues = (value: ISOStringDate): {date: NullableMoment} => {
    let date = value.date ? moment(value.date.date) : null;
    if (date && !date.isValid()) {
        date = null;
    }
    return {date};
};

export interface NullableISOStringDate {
    date: DatePickerValue | null;
}

export interface DateOptions {
    dateLabel: string | TFunction;
    minimumDate?: MinimumDateOptions;
}

export const DateSchema = ({dateLabel, minimumDate}: DateOptions): SchemaOf<ISOStringDate> =>
    yup
        .object({
            date: NullableDatePickerValueSchema({minimumDate}),
        })
        .required() as SchemaOf<ISOStringDate>;

export const useDateSchema = ({dateLabel, minimumDate}: DateOptions) =>
    useMemo(
        () =>
            DateSchema({
                dateLabel,
                minimumDate,
            }),
        [dateLabel, minimumDate]
    );
