import {PropsWithChildren, FC} from 'react';
import {IBordtemplateModelHeaderFragment} from '@growthbase/graphql';
import {ModelHeader} from './ModelHeader';
import {ActionMenuConnected} from '../../../ActionMenu';

export interface ModelHeaderConnectedProps extends PropsWithChildren {
    fragment: IBordtemplateModelHeaderFragment;
}

export const ModelHeaderConnected: FC<ModelHeaderConnectedProps> = ({fragment, children, ...viewConfig}) => (
    <ModelHeader
        actionMenu={
            <ActionMenuConnected
                readonly={false}
                disabled={false}
                {...viewConfig}
                hideBewerkButton
                fragment={fragment}
            />
        }
    >
        {children}
    </ModelHeader>
);
