import React, {FC, ReactElement, ReactNode} from 'react';
import classNames from 'classnames/bind';
import {AvatarOverflowText, AvatarOverflowTextProps} from '../AvatarOverflowText';
import styles from './AvatarRow.module.scss';
import {AvatarProps, AvatarSize} from '../Avatar';

const cx = classNames.bind(styles);

export interface AvatarRowProps {
    testId?: string;
    children: ReactElement<AvatarProps>[] | ReactElement<AvatarProps>;
    button?: ReactNode;
    overflowButtonProps?: () => Partial<AvatarOverflowTextProps>;
    stackSize?: number;
    size?: AvatarSize;
}

export const AvatarRow: FC<AvatarRowProps> = ({
    testId,
    children,
    button,
    stackSize = 0,
    size = 'default',
    overflowButtonProps,
}) => {
    const childrenArray = React.Children.toArray(children) as ReactElement[];
    const totalStackSize = childrenArray.length;
    const extra = totalStackSize - stackSize < 0 ? 0 : totalStackSize - stackSize;
    const filteredChildren = stackSize ? childrenArray.slice(0, stackSize) : childrenArray;

    const classes = cx('avatarRow', {
        'avatarRow--isStacked': !!stackSize,
        'avatarRow--isSmallStacked': size === 'small' && !!stackSize,
    });

    return (
        <div className={classes} data-testid={testId}>
            {!!filteredChildren.length && (
                <div className={cx('avatarRow__items')}>
                    {filteredChildren.map((child, i) => (
                        <div key={i} className={cx('avatarRow__item')}>
                            {child}
                        </div>
                    ))}
                    {!!stackSize && extra > 0 && (
                        <AvatarOverflowText {...(overflowButtonProps ? overflowButtonProps() : {})}>
                            +{extra}
                        </AvatarOverflowText>
                    )}
                </div>
            )}
            {button && (!stackSize || totalStackSize === 0) && <div className={cx('avatarRow__button')}>{button}</div>}
        </div>
    );
};
