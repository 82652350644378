import {
    IMaakTaakAanTaakInput,
    ITaakCreateFormMutation,
    useTaakCreateFormMutation,
    useTaakCreateFormPermissionsQuery,
} from '@growthbase/graphql';
import {FC, useCallback} from 'react';
import {useGebruikerHeeftToegang} from '@growthbase/design-components';
import {TakenRoutes} from '../TaakRoutes';
import {TaakCreateForm} from './TaakCreateForm';
import {MaakTaakAanTaakFormDTO} from './TaakCreateFormSchema';
import {normalizeMaakTaakAanTaakDTO} from './Utils/convertMaakTaakAanTaakToGraphql';

export type TaakOverrideValues = Omit<IMaakTaakAanTaakInput, 'id' | 'titel'>;

export interface TaakCreateFormConnectedProps {
    onCreated?: (value: ITaakCreateFormMutation) => void;
    overrideValues?: TaakOverrideValues;
}

export const TaakCreateFormConnected: FC<TaakCreateFormConnectedProps> = ({onCreated, overrideValues}) => {
    const [mutateAsync] = useTaakCreateFormMutation();
    const {data} = useTaakCreateFormPermissionsQuery();

    const navigateToEditPage = TakenRoutes.edit.useNavigateFactory();

    const navigateToEditModal = useCallback(
        async (value: ITaakCreateFormMutation) => {
            const taak = value.MaakTaakAan?.taak;
            if (taak) {
                navigateToEditPage(
                    {
                        id: taak.id,
                    },
                    {
                        state: {taak},
                        replace: true,
                    }
                );
            }
        },
        [navigateToEditPage]
    );

    const onSubmit = useCallback(
        (value: MaakTaakAanTaakFormDTO) =>
            mutateAsync({
                variables: {
                    input: {...normalizeMaakTaakAanTaakDTO(value), ...overrideValues},
                },
            })
                .then((mutation) => {
                    const createdTaak = mutation?.data;
                    if (!createdTaak) {
                        return Promise.resolve();
                    }
                    if (onCreated) {
                        onCreated(createdTaak);
                        return Promise.resolve();
                    }
                    return navigateToEditModal(createdTaak);
                })
                .then(() => undefined),
        [mutateAsync, navigateToEditModal, onCreated, overrideValues]
    );

    const toegang = useGebruikerHeeftToegang({
        toegang: data?.ingelogdeGebruiker?.availableActions?.kanTaakAanmaken,
    });

    const availableActions = data?.ingelogdeGebruiker?.availableActions;

    if (!data || !toegang || !availableActions) {
        return null;
    }

    return <TaakCreateForm onSubmit={onSubmit} availableActions={availableActions} />;
};
