import {FC, ReactNode} from 'react';
import styles from './ShowOnRowHover.module.scss';

interface Created {
    children: ReactNode;
    forceVisible?: boolean;
}

export const ShowOnRowHover: FC<Created> = ({children, forceVisible}) => (
    <div className={forceVisible ? undefined : styles.showOnRowHover}>{children}</div>
);
