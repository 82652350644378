import {EventType, IMedewerkerFragment, IPlanningEventFragment} from '@growthbase/planning';
import {useMemo} from 'react';

export type EventNormalizer = (event: IPlanningEventFragment, medewerker: IMedewerkerFragment) => EventType;

export const useNormalizeEvents = (eventNormalizer: EventNormalizer) =>
    useMemo(
        () =>
            (medewerkers: IMedewerkerFragment[]): EventType[] => {
                const events: EventType[] = [];
                medewerkers.forEach((medewerker) => {
                    medewerker.planning.events.forEach((event) => {
                        if (event !== null) {
                            events.push(eventNormalizer(event, medewerker));
                        }
                    });
                });

                return events;
            },
        [eventNormalizer]
    );
