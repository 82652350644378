import {useExternalEntityFieldsInvalidated, useExternalEntityRemoved} from '@growthbase/cache';
import {FC, memo, useCallback} from 'react';
import {ITaakEditModalFragment, useTaakEditModalConnectedQuery} from '@growthbase/graphql';
import {PopupControlsContext, useGebruikerHeeftToegang, useRoutePopupControls} from '@growthbase/design-components';
import {idFromIri} from '@growthbase/routing';
import {TaakReactiesConnected} from '../Reactie';
import {TaakEditModal} from './TaakEditModal';
import {TaakActiviteitenConnected} from '../TaakActiviteiten';
import {IriOfTaak} from '../TaakRoutes';
import {TaakEditPopupHeaderConnected} from './Components/TaakEditPopupHeaderConnected';
import {TaakEditFragment} from './TaakEditFragment';

export interface TaakEditModalConnectedProps {
    id: string;
    taak?: TaakEditFragment;
}

export const TaakEditModalConnected: FC<TaakEditModalConnectedProps> = memo(({id, taak}) => {
    const helpers = useRoutePopupControls();
    const {data, refetch, loading, called} = useTaakEditModalConnectedQuery({
        variables: {
            id: IriOfTaak(id),
        },
    });
    useExternalEntityRemoved<TaakEditFragment>(
        useCallback(
            (args) => {
                if (args.typename === 'Taak' && args.id === IriOfTaak(id)) {
                    helpers.forceClose();
                }
            },
            [id, helpers]
        )
    );
    useExternalEntityFieldsInvalidated<ITaakEditModalFragment>(
        useCallback(
            (args) => {
                if (args.typename !== 'Taak') {
                    return;
                }
                if (idFromIri(args.id) !== idFromIri(id)) {
                    return;
                }
                if (
                    args.fields.includes('omschrijving') ||
                    args.fields.includes('deelnemers') ||
                    args.fields.includes('volgers') ||
                    args.fields.includes('relatie')
                ) {
                    refetch();
                }
            },
            [id, refetch]
        )
    );

    const taakData: TaakEditFragment | undefined = data?.taak ?? taak;
    const huidigProfiel = data?.huidigProfiel;
    const toegang = useGebruikerHeeftToegang({
        enabled: !loading && called,
        toegang: called && !!taakData,
    });

    if (!toegang) {
        return null;
    }

    return (
        <PopupControlsContext.Provider value={helpers}>
            <TaakEditModal
                key={id}
                id={id}
                Reacties={TaakReactiesConnected}
                ActiviteitenList={TaakActiviteitenConnected}
                fragment={taakData}
                profielFragment={huidigProfiel ?? undefined}
                TaakEditPopupHeader={TaakEditPopupHeaderConnected}
            />
        </PopupControlsContext.Provider>
    );
});

TaakEditModalConnected.displayName = 'TaakEditModalConnected';
