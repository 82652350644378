import {useAsyncComboBox, useComboBoxContext} from '@growthbase/design-components';
import {
    IOnderdeelVanComboboxItemFragment,
    IRelatieType,
    IRelatieverwijzingInput,
    useOnderdeelVanComboboxZoekenQuery,
} from '@growthbase/graphql';
import {useCallbackRef} from '@growthbase/spa';
import React, {memo, useEffect, useMemo} from 'react';
import {useRelatieComboboxQuery} from '@growthbase/relaties';
import {ExternalLinkType} from '@growthbase/clientbox';
import {ComboboxOnderdeelVanItem, OnderdeelVanCombobox, OnderdeelVanMentionKey} from './OnderdeelVanCombobox';

interface OnderdeelVanComboboxConnectedMemorizedProps {
    open: boolean;
    vanRelatie: IRelatieverwijzingInput | null;
    text: string | null;
    setLoading: (loading: boolean) => void;
}

const OnderdeelVanComboboxConnectedMemorized = memo(
    ({vanRelatie, text, open, setLoading}: OnderdeelVanComboboxConnectedMemorizedProps) => {
        const {data, loading} = useOnderdeelVanComboboxZoekenQuery({
            variables: {
                fulltextSearch: text === '' ? null : text,
                relatieVerwijzing: vanRelatie,
            },
            skip: !open,
        });

        useEffect(() => setLoading(loading), [loading, setLoading]);

        const relatieGroups: IOnderdeelVanComboboxItemFragment[] | null = data?.Koppelen?.OpRelatie ?? null;
        const mapped: ComboboxOnderdeelVanItem[] = useMemo((): ComboboxOnderdeelVanItem[] => {
            const items: ComboboxOnderdeelVanItem[] = [];

            if (!relatieGroups) {
                return items;
            }

            let relatie = null;
            let groep = null;
            let relatieCount = 0;

            for (const relatieGroup of relatieGroups) {
                relatieCount += 1;
                for (const typeGroup of relatieGroup.items) {
                    let koppelbaarCount = 0;
                    for (const koppelbaar of typeGroup.items) {
                        koppelbaarCount += 1;
                        const relatieHeader = relatieGroup.relatie.label;
                        const item: ComboboxOnderdeelVanItem = {
                            first: relatieCount === 1,
                            last: koppelbaarCount === typeGroup.items.length,
                            id: koppelbaar.id,
                            label: koppelbaar.titel,
                            relatieHeader: relatieHeader !== relatie ? relatieHeader : undefined,
                            groupHeader: groep !== typeGroup.type ? typeGroup.type : undefined,
                            type: typeGroup.type,
                            externalId: koppelbaar.externalId,
                            relatie: {
                                bedrijf:
                                    relatieGroup.relatie.type === IRelatieType.Bedrijf
                                        ? {
                                              label: relatieGroup.relatie.label,
                                              id: relatieGroup.relatie.id,
                                              linkType: ExternalLinkType.Bedrijf,
                                          }
                                        : undefined,
                                persoon:
                                    relatieGroup.relatie.type === IRelatieType.Persoon
                                        ? {
                                              label: relatieGroup.relatie.label,
                                              id: relatieGroup.relatie.id,
                                              linkType: ExternalLinkType.Persoon,
                                          }
                                        : undefined,
                            },
                        };
                        relatie = relatieHeader;
                        groep = typeGroup.type;
                        items.push(item);
                    }
                }
            }

            return items;
        }, [relatieGroups]);
        return <OnderdeelVanCombobox items={mapped} />;
    }
);

OnderdeelVanComboboxConnectedMemorized.displayName = 'OnderdeelVanComboboxConnectedMemorized';

export const OnderdeelVanComboboxConnected = () => {
    const vanRelatie = useRelatieComboboxQuery().value;
    const comboboxContext = useComboBoxContext();
    const {text, open} = useAsyncComboBox(OnderdeelVanMentionKey);
    return (
        <OnderdeelVanComboboxConnectedMemorized
            vanRelatie={vanRelatie}
            text={text}
            open={open}
            setLoading={useCallbackRef((loading) => comboboxContext.setLoading(loading))}
        />
    );
};
