import {ThemeOptions} from '@mui/material/styles/createTheme';

export const AutoComplete: ThemeOptions['components'] = {
    MuiAutocomplete: {
        styleOverrides: {
            inputRoot: {
                cursor: 'pointer',
                borderRadius: 'var(--formBorderRadius)',
                paddingTop: 11,
                paddingBottom: 9,
            },
            input: {
                paddingTop: 0,
                paddingBottom: 0,
                cursor: 'pointer',
            },
        },
    },
};
