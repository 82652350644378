import React, {ReactNode} from 'react';
import {StatusIconButton} from '../../../../Buttons';
import {Center} from '../../../../Center/Center';
import styles from './EmptyTableMessage.module.scss';

export interface EmptyTableMessageProps {
    children: ReactNode;
    loading?: boolean;
}

/**
 * TODO: fix to correct loader.
 */
export const EmptyTableMessage = ({children, loading}: EmptyTableMessageProps) => (
    <div className={styles.emptyTableMessage}>
        {loading ? (
            <Center horizontal>
                <StatusIconButton loading readonly />
            </Center>
        ) : (
            children
        )}
    </div>
);
