/* eslint-disable prefer-spread */

export class Logger {
    constructor(private prefix: string) {}

    /**
     * @param {string} prefix
     * @returns {Logger}
     */
    withPrefix(prefix: string) {
        if (this.prefix) {
            return new Logger(`${this.prefix}:${prefix}`);
        }
        return new Logger(prefix);
    }

    info(message: unknown, ...value: unknown[]) {
        console.log.apply(console, [this.buildMessage(message)].concat(value));
    }

    /**
     *
     * @public
     * @param {string} message
     * @param {any[]} values
     */
    table(message: unknown, ...values: unknown[]) {
        if (console.table) {
            console.log(this.buildMessage(message));
            console.table(values);
        } else {
            console.log.apply(console, [this.buildMessage(message)].concat(values));
        }
    }

    error(message: unknown, ...value: unknown[]) {
        console.error.apply(console, [this.buildMessage(message)].concat(value));
    }

    buildMessage(message: unknown): unknown {
        const prefix: unknown[] = [new Date().toISOString()];
        if (this.prefix) {
            prefix.push(this.prefix);
        }
        prefix.push(message);
        return prefix.join(':');
    }
}
export const logger = new Logger('frontend');
