import {ActionsMenu} from '@growthbase/design-components';
import {FC, ReactNode} from 'react';

export interface MenuProps {
    verwijderLijstMenuButton: ReactNode;
    bewerkLijstMenuButton: ReactNode;
    kopierButton: ReactNode;
}

export const Menu: FC<MenuProps> = ({verwijderLijstMenuButton, bewerkLijstMenuButton, kopierButton}) => (
    <ActionsMenu>
        {verwijderLijstMenuButton}
        {bewerkLijstMenuButton}
        {kopierButton}
    </ActionsMenu>
);
