import {PlanningStoreState, useStatFooterDataQuery} from '@growthbase/planning';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import {StatFooter} from './StatFooter';

export const StatFooterConnected = ({opdrachtId}: {opdrachtId: string}) => {
    const {hash} = useSelector((state: PlanningStoreState) => state.planning);

    const {data, previousData, refetch} = useStatFooterDataQuery({
        variables: {opdrachtId},
    });

    useEffect(() => {
        refetch();
    }, [hash, refetch]);

    const stats = data?.OpdrachtPlanStatistieken || previousData?.OpdrachtPlanStatistieken;

    if (!stats) {
        return <></>;
    }

    return (
        <StatFooter budget={stats.budget} booked={stats.geboekt} planned={stats.gepland} remaining={stats.resterend} />
    );
};
