import {useTranslation} from 'react-i18next';
import React, {ReactNode, useMemo} from 'react';
import {CellProps} from 'react-table';
import {Columns, TableEditLink} from '@growthbase/design-components';
import {TaakTabelRow} from '../SortableTabel';
import {TaaktemplateRoutes} from '../../TaaktemplateRoutes';

export interface ColumnsOptions<T extends TaakTabelRow = TaakTabelRow> {
    renderMenu: (row: T) => ReactNode;
}

export function useColumns<T extends TaakTabelRow = TaakTabelRow>({renderMenu}: ColumnsOptions<T>) {
    const {t} = useTranslation('spa_form.nl');
    return useMemo(
        (): Columns<TaakTabelRow> => [
            {
                Header: <>{t('taak.table.title')}</>,
                accessor: 'titel',
                Cell: ({
                    value,
                    row: {
                        original: {id: taakId},
                    },
                }) => (
                    <TableEditLink to={TaaktemplateRoutes.bewerken.useUrl({taaktemplateId: taakId})}>
                        {value}
                    </TableEditLink>
                ),
            },
            {
                Header: <div />,
                Cell: ({row: {original}}: CellProps<TaakTabelRow>) => <>{renderMenu(original as T)}</>,
                id: 'actions',
                width: 'auto',
                minWidth: 140,
            },
        ],
        [renderMenu, t]
    ) as Columns<T>;
}
