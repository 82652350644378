import {usePaginationMapper, useQueryPagination} from '@growthbase/spa';
import React, {FC, useMemo} from 'react';
import {Stack} from '@growthbase/design-components';
import {
    IApplicationLogEdgeFragment,
    IApplicationLogsQuery,
    IApplicationLogsQueryVariables,
    IOrder,
    useApplicationLogsQuery,
} from '@growthbase/graphql';
import {TextField} from '@mui/material';
import {FilterBar, LogFilter, useFilterBarState} from './Components';
import {useApplicationLogsTableNormalizer} from './Hook';
import {ApplicationLogsTable} from './ApplicationLogsTable';

export interface ApplicationLogsTableConnectedProps {
    projectionId?: string;
    profielId?: string;
    processId?: number;
}

export const ApplicationLogsTableConnected: FC<ApplicationLogsTableConnectedProps> = () => {
    const [value, onChange] = useFilterBarState({
        initialValue: [],
    });
    const [refreshTimeSeconds, setRefreshRate] = React.useState(5);
    const query = useApplicationLogsQuery({
        variables: {
            filters: useMemo(() => {
                function removeId(filter: LogFilter) {
                    const copy = {...filter};
                    const {and, or, query: q} = filter;
                    if (q === '') {
                        copy.query = '*';
                    }
                    if (and) {
                        copy.and = and.map(removeId);
                    }
                    if (or) {
                        copy.or = or.map(removeId);
                    }
                    return {
                        ...copy,
                        id: undefined,
                    };
                }
                return value.map(removeId);
            }, [value]),
            pagination: {
                order: IOrder.Desc,
                first: 50,
            },
        },
    });
    const pagination = useQueryPagination<
        IApplicationLogsQuery,
        IApplicationLogsQueryVariables,
        IApplicationLogEdgeFragment
    >({
        query,
        intervalMs: refreshTimeSeconds * 1000,
        fetchCursor: ({index}) => index,
        connectionsMapper: ({logs: {messages}}): IApplicationLogEdgeFragment[] => messages,
        fetchBefore: (variables) => ({
            pagination: {
                before: variables.first,
                last: 50,
                first: null,
            },
        }),
        mergeBefore: (previousResult, fetchMoreResult) => ({
            ...previousResult,
            logs: {
                ...previousResult.logs,
                totalResults: previousResult.logs.totalResults + fetchMoreResult.logs.totalResults,
                messages: [...fetchMoreResult.logs.messages, ...previousResult.logs.messages],
            },
        }),
        fetchAfter: (variables) => ({
            pagination: {
                after: variables.last,
                first: 50,
                last: null,
            },
        }),
        mergeAfter: (previousResult, fetchMoreResult) => ({
            ...previousResult,
            logs: {
                ...previousResult.logs,
                messages: [...previousResult.logs.messages, ...fetchMoreResult.logs.messages],
            },
        }),
    });

    const messages = usePaginationMapper(pagination, ({node}) => node);
    const converted = useApplicationLogsTableNormalizer(messages);

    return (
        <Stack gap="tiny">
            <Stack.Item>
                <FilterBar value={value} onChange={onChange}>
                    <TextField
                        type="number"
                        style={{width: 120}}
                        label="Refresh sec"
                        variant="outlined"
                        value={`${refreshTimeSeconds}`}
                        onChange={(e) => setRefreshRate(parseInt(e.target.value, 10))}
                    />
                    {query?.data?.logs?.totalResults} logs
                </FilterBar>
            </Stack.Item>
            <Stack.Item grow justify="start">
                <ApplicationLogsTable pagination={converted} />
            </Stack.Item>
        </Stack>
    );
};
