import classNames from 'classnames/bind';
import React, {FC} from 'react';
import {MemoryPopupProvider} from '../Popups';

import styles from './Loader.module.scss';
import LoaderSvg from './Loader.svg?react';
import {Center} from '../Center/Center';
import {Modal, ModalContent} from '../Modals';

const cx = classNames.bind(styles);

export interface LoaderProps {
    center?: boolean;
    small?: boolean;
    fixed?: boolean;
    testId?: string;
}

export const Loader: FC<LoaderProps> = ({fixed, small, center, testId}) => {
    const classes = cx('loader', {
        'loader--small': small,
        'loader--fixed': fixed,
    });

    if (center) {
        return (
            <Center>
                <div data-testid={testId} className={classes} data-loader="true">
                    <LoaderSvg />
                </div>
            </Center>
        );
    }

    return (
        <div data-testid={testId} className={classes}>
            <LoaderSvg />
        </div>
    );
};

/**
 * @deprecated Use: <Loader center>
 */
export const LoaderCenter = (props: LoaderProps) => <Loader {...props} center />;

export const LoaderModal: FC = () => (
    <MemoryPopupProvider open>
        <Modal noBackground>
            <ModalContent>
                <Loader center />
            </ModalContent>
        </Modal>
    </MemoryPopupProvider>
);
