import {FC, useState} from 'react';
import classNames from 'classnames/bind';
import styles from './CardSelectOption.module.scss';
import {Checkbox, StyledSelect} from '../../../Form';

const cx = classNames.bind(styles);

export interface CardSelectOptionProps {
    value: string;
    title: string;
    subtitle?: string;
    active?: boolean;
    subValue?: string;
    subValues?: Array<{value: string; label: string}>;
    subValuesSubtitle?: string;
    onChangeValue: (value?: string, subValue?: string) => void;
}

export const CardSelectOption: FC<CardSelectOptionProps> = ({
    active,
    value,
    subValue: defaultSubValue,
    title,
    subtitle,
    subValues,
    subValuesSubtitle,
    onChangeValue,
}) => {
    const [subValue, setSubValue] = useState<string | undefined>(defaultSubValue);

    const className = cx('cardSelectOption', {
        'cardSelectOption--active': active,
    });

    return (
        <div className={className} data-label={title} data-test="cardselect">
            <div>
                <Checkbox
                    id={`option-${value}`}
                    type="checkbox"
                    name="opdracht"
                    checked={active}
                    className={styles.cardSelectOption__input}
                    onChange={(event) => {
                        onChangeValue(event.target.checked ? value : undefined, subValue);
                    }}
                />
                <label htmlFor={`option-${value}`} className={cx('cardSelectOption__label')}>
                    <span>{title}</span>
                    {subtitle && <span className={cx('cardSelectOption__subtitle')}>{subtitle}</span>}
                </label>
            </div>
            {subValues && active && (
                <div className={cx('cardSelectOption__select')}>
                    <StyledSelect<string>
                        readonly={false}
                        disabled={false}
                        value={subValue ?? '-'}
                        options={subValues}
                        onChange={(newValue) => {
                            setSubValue(newValue);
                            onChangeValue(value, newValue);
                        }}
                    />
                    {subValuesSubtitle && <span className={cx('cardSelectOption__subtitle')}>{subValuesSubtitle}</span>}
                </div>
            )}
        </div>
    );
};
