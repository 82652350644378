import {useDebouncedState} from '@growthbase/spa';
import {FC, ReactNode} from 'react';
import {useKoppelButtonConnectedQuery} from '@growthbase/graphql';
import {FormikFieldProps, useMemoryPopupControls} from '@growthbase/design-components';
import {useRelatieComboboxQuery} from '@growthbase/relaties';
import {useNormalize} from '../KoppelButton/Hook/useNormalize';
import {KoppelButtonInput} from './KoppelButtonInput';
import {KoppelbaarItem} from '../../KoppelPopup';
import {RenderKoppelButtonProps} from '../KoppelButton';

export interface KoppelButtonInputConnectedProps extends FormikFieldProps<KoppelbaarItem | null> {
    _?: never;
    renderKoppelButton?: (props: RenderKoppelButtonProps) => ReactNode;
}

export const KoppelButtonInputConnected: FC<KoppelButtonInputConnectedProps> = ({...props}) => {
    const {value: vanRelatie, onChange} = useRelatieComboboxQuery();

    const controls = useMemoryPopupControls(false);
    const {
        value: searchQuery,
        setValue: setSearchQuery,
        debouncedValue: searchQueryDebounced,
    } = useDebouncedState<string | null>(null, 400);

    const {
        loading,
        data: current,
        previousData,
    } = useKoppelButtonConnectedQuery({
        skip: !controls.isOpen || props.disabled || props.readonly,
        variables: {
            query: searchQuery,
            relatieverwijzing: vanRelatie,
        },
    });
    const data = current ?? previousData;

    const relatiegroepen = useNormalize({
        groepen: data?.Koppelen?.OpRelatie ?? [],
    });

    return (
        <KoppelButtonInput
            {...props}
            onChangeRelation={onChange}
            loading={loading}
            groepen={relatiegroepen}
            searchQuery={searchQueryDebounced}
            setSearchQuery={setSearchQuery}
            popupControls={controls}
        />
    );
};
