import {RadioSwitch} from '@growthbase/design-components';
import {ViewFormat, useCurrentView, usePlanningSetPreferredViewForGroup, AgendaModuleType} from '@growthbase/planning';
import {useTranslation} from 'react-i18next';
import {useCallbackRef} from '@growthbase/spa';

export interface PlanningViewTypeSwitchProps {
    formats: ViewFormat[];
    module: AgendaModuleType;
}

export const PlanningViewTypeSwitch = ({formats, module}: PlanningViewTypeSwitchProps) => {
    const currentViewFormat = useCurrentView(module);

    const setActiveView = usePlanningSetPreferredViewForGroup();

    const {t} = useTranslation('spa.nl');

    const onChange = useCallbackRef((id: string) => {
        setActiveView({
            viewId: id,
            groupId: currentViewFormat.group,
            activate: true,
        });
    });

    const options = formats.map((format) => ({
        label: t(format.label),
        value: format.id,
    }));

    return (
        <RadioSwitch<string>
            onChange={onChange}
            value={currentViewFormat.id}
            items={options}
            testId="planning-view-type-switch"
        />
    );
};
