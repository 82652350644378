import {ReactNode} from 'react';

export interface LegacyComponentRootProps {
    children: ReactNode;
}

export const TwigComponentRoot = ({children}: LegacyComponentRootProps) => (
    <div>
        <>{children}</>
    </div>
);
