import {ButtonProps, Buttons} from '@growthbase/design-components';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {BordtemplateRoutes} from '@growthbase/takentemplates-bordtemplate';

export interface ViewButtonProps {
    Button?: FC<ButtonProps>;
    bordtemplateId: string;
}

export const ViewButton: FC<ViewButtonProps> = ({bordtemplateId, Button = Buttons.Search}) => {
    const {t} = useTranslation('Bordtemplate.nl');
    const title = t('bekijken');
    const to = BordtemplateRoutes.bekijken.useUrl({bordtemplateId});
    return (
        <Button key="add" to={to} tooltip={title} ariaLabel={title}>
            {title}
        </Button>
    );
};
