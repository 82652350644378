import {QueryResult} from '@apollo/client/react/types/types';
import {
    IFilterableTakenTableConnectedQuery,
    IFilterableTakenTableConnectedQueryVariables,
    IFilterableTakenTableConnectedRowFragment,
    ITaakTabelFragment,
    useFilterableTakenTableConnectedCreateMutation,
} from '@growthbase/graphql';
import {useAddTaakLockHandler} from '@growthbase/taken';
import {useCallback} from 'react';
import {Diff} from 'utility-types';

/**
 * - Add the taak to the table before submitting the form to the api.
 * - Locks the taak during the api call.
 */
export const useAddTaakHandler = (
    result: QueryResult<IFilterableTakenTableConnectedQuery, IFilterableTakenTableConnectedQueryVariables>
) => {
    const [mutateAsync] = useFilterableTakenTableConnectedCreateMutation();
    return useAddTaakLockHandler(
        result,
        (input) =>
            mutateAsync({
                variables: {
                    input,
                },
            }),
        useCallback((): Diff<IFilterableTakenTableConnectedRowFragment, ITaakTabelFragment> => ({}), [])
    );
};
