import {ILijstVerwijderenMenuButtonConnectedFragment, useLijstVerwijderenMenuButtonMutation} from '@growthbase/graphql';
import {idFromIri} from '@growthbase/routing';
import {FC} from 'react';
import {BaseButtonProps} from '@growthbase/design-components';
import {VerwijderenButton} from './VerwijderenButton';

export interface VerwijderenButtonConnectedProps {
    fragment: ILijstVerwijderenMenuButtonConnectedFragment;
    Button?: FC<BaseButtonProps>;
}

export const VerwijderenButtonConnected: FC<VerwijderenButtonConnectedProps> = ({
    fragment: {
        naam,
        id,
        availableActions: {kanVerwijderen},
    },
    Button,
}) => {
    const [mutate] = useLijstVerwijderenMenuButtonMutation({
        variables: {
            input: {
                id: idFromIri(id),
            },
        },
    });
    if (!kanVerwijderen) {
        return null;
    }
    return <VerwijderenButton Button={Button} lijstTitel={naam} onSubmit={mutate} />;
};
