import {SwimlaneCard, SwimlaneCardBody} from '@growthbase/design-components';
import {FC, HTMLAttributes, memo, ReactNode} from 'react';

export interface KaartProps {
    titel: ReactNode;
    menu: ReactNode;
    anchor: HTMLAttributes<HTMLElement> | undefined;

    dragging?: boolean;
    hoover?: boolean;
}

export const Kaart: FC<KaartProps> = memo(({anchor, titel, menu, hoover, dragging}) => {
    const showPlaceholder = !hoover && dragging;

    return (
        <SwimlaneCard anchor={anchor} showPlaceholder={showPlaceholder}>
            <SwimlaneCardBody menu={!showPlaceholder && menu}>
                <div>{titel}</div>
            </SwimlaneCardBody>
        </SwimlaneCard>
    );
});

Kaart.displayName = 'Kaart';
