import Pusher from 'pusher-js';
import {useMemo} from 'react';

declare const CONFIG: {
    get: (key: string) => string;
};

/**
 * This is config from head_scripts.html.twig
 */
const getChannelPrefix = () => CONFIG.get('PROFILE_ID') || (CONFIG.get('SUPERADMIN') ? 'superadmin' : '');

export const useLegacySocket = (channelName: string, connected: (socketId: string) => void) =>
    useMemo(() => {
        const APP_KEY = CONFIG.get('PUSHER_APP_KEY');
        const CLUSTER = CONFIG.get('PUSHER_APP_CLUSTER');
        Pusher.logToConsole = CONFIG.get('ENVIRONMENT') === 'dev';

        const connector: Pusher = new Pusher(APP_KEY, {
            cluster: CLUSTER,
            forceTLS: true,
        });

        connector.connection.bind('connected', () => {
            connected(connector.connection.socket_id);
        });

        const subscribe = (eventName: string, prefixedChannelName: string, callback: (data: never) => void) => {
            const channel = connector.subscribe(prefixedChannelName);
            const channelCallback = (payload: {data: never}) => {
                callback(payload.data);
            };
            channel.bind(eventName, channelCallback);

            return {
                unsubscribe() {
                    channel.unbind(eventName, channelCallback);
                    channel.unsubscribe();
                },
            };
        };

        const subscribeProfileEvent = (eventName: string, callback: (data: never) => void) =>
            subscribe(eventName, `${getChannelPrefix()}-${channelName}`, callback);

        const subscribeUserEvent = (eventName: string, callback: (data: never) => void) =>
            subscribe(eventName, `${getChannelPrefix()}-${CONFIG.get('USER_ID')}-${channelName}`, callback);

        return {
            socketId: connector.connection.socket_id,
            subscribeProfileEvent,
            subscribeUserEvent,
        };
    }, [channelName, connected]);
