import {FC, useCallback} from 'react';
import {IVerwijderLijsttemplateTaakButtonFragment, useVerwijderTaaktemplateMutation} from '@growthbase/graphql';
import {BaseButtonProps, Button as DefaultButton} from '@growthbase/design-components';
import {VerwijderButton} from './VerwijderButton';

export interface VerwijderButtonConnectedProps {
    fragment: IVerwijderLijsttemplateTaakButtonFragment;
    Button?: FC<BaseButtonProps>;
    onDeleted?: () => void;
}

export const VerwijderButtonConnected: FC<VerwijderButtonConnectedProps> = ({
    fragment,
    Button = DefaultButton,
    onDeleted,
}) => {
    const [executeMutation, {loading}] = useVerwijderTaaktemplateMutation({
        variables: {
            input: {
                id: fragment.id,
            },
        },
        onCompleted: onDeleted,
    });
    const {titel} = fragment;
    return (
        <VerwijderButton
            titel={titel}
            onSubmit={useCallback(() => executeMutation().then(() => undefined), [executeMutation])}
            isSubmitting={loading}
            SubmitButton={Button}
        />
    );
};
