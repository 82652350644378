import {forwardRef, Ref} from 'react';
import classNames from 'classnames/bind';
import {capitalize} from 'lodash';
import styles from './DropdownMenuButton.module.scss';
import {BaseButtonProps} from '../../Button';

const cx = classNames.bind(styles);

export interface DropdownMenuButtonProps extends BaseButtonProps {
    fat?: boolean;
    padding?: boolean;
    color?: 'default' | 'primary' | 'placeholder';

    inheritStyle?: boolean;
}

export const DropdownMenuButton = forwardRef(
    (
        {
            ariaLabel,
            disabled,
            readonly,
            color,
            onClick,
            onMouseDown,
            children,
            fat,
            padding,
            inheritStyle,
        }: DropdownMenuButtonProps,
        ref
    ) => (
        <button
            className={cx(
                'dropdownMenuButton',
                `dropdownMenuButton--color${capitalize(color)}`,
                !inheritStyle && {
                    'dropdownMenuButton--fat': fat,
                    'dropdownMenuButton--padding': padding,
                    'dropdownMenuButton--disabled': disabled,
                    'dropdownMenuButton--readonly': readonly,
                },
                {
                    'dropdownMenuButton--inheritStyle': inheritStyle,
                }
            )}
            ref={ref as Ref<HTMLButtonElement>}
            type="button"
            aria-label={ariaLabel ?? undefined}
            onClick={disabled ? undefined : onClick}
            onMouseDown={disabled ? undefined : onMouseDown}
        >
            {children}
        </button>
    )
);

DropdownMenuButton.displayName = 'DropdownMenuButton';
