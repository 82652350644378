import {createLegacyRoutes} from '@growthbase/routing';

export const ApiRoutes = createLegacyRoutes('syncApi', {
    sync: {
        createRoute: (route) => route,
    },
    search: {
        createRoute: (route) => route,
    },
    ignore: {
        createRoute: (route) => route,
    },
});
