import {MbscCalendarColor} from '@mobiscroll/react';
import moment from 'moment';
import {useSelector} from 'react-redux';
import {useCurrentView} from '@growthbase/planning';
import {PlanningStoreState} from '../Redux';
import {useCheckDateOverlap} from './useCheckDateOverlap';

export const useBackgroundEventForDisplay = (backgroundEvents: MbscCalendarColor[]): MbscCalendarColor[] => {
    const currentViewFormat = useCurrentView();
    const {creationDateRestrictions} = useSelector((state: PlanningStoreState) => state.planning);
    const checkDateOverlap = useCheckDateOverlap();
    const {id} = currentViewFormat;

    if (id.indexOf('maand') === -1) {
        return backgroundEvents;
    }

    backgroundEvents = backgroundEvents?.filter((event) => {
        const compareStart = moment(event.start).add(1, 'second');
        const compareEnd = moment(event.end).subtract(1, 'second');

        return !creationDateRestrictions.some((range) =>
            checkDateOverlap({start: compareStart.toDate(), end: compareEnd.toDate()}, range)
        );
    });

    backgroundEvents = backgroundEvents?.filter((event) => {
        const start = moment(event.start);
        const end = moment(event.end);
        return start.isSame(start.clone().startOf('day')) && end.isSame(end.clone().endOf('day'));
    });

    return backgroundEvents;
};
