import {createURL, mergeNavigateOptions, relativeURL, useRoutes} from '@growthbase/routing';
import {RoutesDTO} from '@growthbase/spa';
import {useCallback, useMemo} from 'react';
import {generatePath, To} from 'react-router-dom';
import {RouteHooks, NavigateOptions, RouteConfig} from '../Value';

export const stripReloadDocument = (url: string) => {
    const urlObject = createURL(url);
    urlObject.searchParams.delete('reloadDocument');
    return relativeURL(urlObject);
};

export const addReloadDocument = (url: string) => {
    const urlObject = createURL(url);
    urlObject.searchParams.set('reloadDocument', 'true');
    return relativeURL(urlObject);
};

export const isLegacyRoute = (to?: To): to is string => {
    if (typeof to !== 'string') {
        return false;
    }
    const urlObject = createURL(to);
    return urlObject.searchParams.has('reloadDocument');
};

const navigate = (to: string) => {
    window.location.href = stripReloadDocument(to);
};

export function createLegacyRouteHooks<
    TRoutesKey extends keyof RoutesDTO,
    TRouteKey extends keyof RoutesDTO[TRoutesKey],
    TParams,
    TState
>(
    routesKey: TRoutesKey,
    routeKey: TRouteKey,
    config: RouteConfig<TParams, TState, keyof RoutesDTO[TRoutesKey]>
): RouteHooks<TParams, TState> {
    const useUrlFactory: () => (value?: TParams, options?: NavigateOptions<TState>) => string = () => {
        const routes = useRoutes();
        return useCallback(
            (params, options) => {
                const route = routes[routesKey][routeKey];
                if (typeof route !== 'string') {
                    throw new Error(`Missing ${routesKey}.${routeKey.toString()} from routes config`);
                }
                const without = config.createRoute
                    ? config.createRoute(route, params)
                    : // @ts-expect-error - TS doesn't know that the route params are optional.
                      generatePath(route, params || {});
                return addReloadDocument(without);
            },
            [routes]
        );
    };
    const useNavigateFactory = (defaultOptions: NavigateOptions<TState> = {}) => {
        const createRoute = useUrlFactory();
        return useCallback(
            (params?: TParams, options: NavigateOptions<TState> = {}) => {
                const combined = mergeNavigateOptions([defaultOptions, options]);
                navigate(createRoute(params, combined));
            },
            [createRoute, defaultOptions]
        );
    };
    const useUrl = (params?: TParams, options?: NavigateOptions<TState>): string => {
        const url = useUrlFactory();
        return useMemo(() => url(params, options), [url, params, options]);
    };

    const useOptionalUrl = (params?: TParams | null, options?: NavigateOptions<TState>): string | null => {
        const url = useUrlFactory();
        return useMemo(() => (!params ? null : url(params, options)), [url, params, options]);
    };

    return {
        useUrlFactory,
        useUrl,
        useOptionalUrl,
        useNavigateFactory,
        useNavigate: (params?: TParams, options?: NavigateOptions<TState>) => {
            const callback = useNavigateFactory();
            return useCallback(() => callback(params, options), [callback, params, options]);
        },
        useTabRouting: (params?: TParams, options?: NavigateOptions<TState>) => {
            const to = useUrl(params, options);
            return useMemo(
                () => ({
                    to,
                    replace: !!config.defaultBackgroundRoute,
                }),
                [to]
            );
        },
        useIsActive: (params?: TParams) => {
            const url = useUrl(params);
            return useMemo(() => window.location.href === url, [url]);
        },
    };
}
