import {RenderSwimlaneCardProps, SwimlanePool} from '@growthbase/design-components';
import {PaginationHelpers} from '@growthbase/spa';
import React, {ReactNode} from 'react';
import {onDropItemHandler, onRemoveItemHandler} from '@growthbase/keys';
import {ContainerData, LijsttemplateDragType, LijstDragTypeValue, ItemData} from '../../types';

export interface SwimlaneViewProps<
    TLijst extends ItemData = ItemData,
    TContainerData extends ContainerData = ContainerData
> {
    pagination: PaginationHelpers<TLijst>;
    userData: TContainerData;
    children: (props: RenderSwimlaneCardProps<TLijst>) => ReactNode;
    onDrop: onDropItemHandler<TLijst, TContainerData>;
    onRemove?: onRemoveItemHandler<TLijst>;
}

export function SwimlaneView<TLijst extends ItemData = ItemData, TContainerData extends ContainerData = ContainerData>({
    children,
    ...props
}: SwimlaneViewProps<TLijst, TContainerData>) {
    return (
        <SwimlanePool<LijsttemplateDragType, TLijst, TContainerData> type={LijstDragTypeValue} {...props}>
            {children}
        </SwimlanePool>
    );
}
