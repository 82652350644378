import {forwardRef, ReactNode} from 'react';
import classNames from 'classnames/bind';
import styles from './SwimlaneFooter.module.scss';

const cx = classNames.bind(styles);

export interface SwimlaneFooterProps {
    children: ReactNode;
    padding?: boolean;
}

export const SwimlaneFooter = forwardRef<HTMLElement, SwimlaneFooterProps>(({children, padding}, ref) => {
    const classes = cx('swimlaneFooter', {
        'swimlaneFooter--padding': padding,
    });
    return (
        // @ts-expect-error We don't mind the type of ref
        <div ref={ref} className={classes}>
            {children}
        </div>
    );
});

SwimlaneFooter.displayName = 'SwimlaneFooter';
