import {FC} from 'react';
import {ILijsttemplateTakenCountCounterFragment} from '@growthbase/graphql';
import {LijsttemplateTakenCountCounter} from './LijsttemplateTakenCountCounter';

export interface LijsttemplateTakenCountCounterConnectedProps {
    fragment?: ILijsttemplateTakenCountCounterFragment;
}

export const LijsttemplateTakenCountCounterConnected: FC<LijsttemplateTakenCountCounterConnectedProps> = ({
    fragment,
}) => {
    if (fragment?.takenAantal) {
        return <LijsttemplateTakenCountCounter count={fragment.takenAantal} />;
    }
    return null;
};
