import {useTranslation} from 'react-i18next';
import {AddBreadcrumb, Loader, Page} from '@growthbase/design-components';
import {
    AgendaSidebarConnected,
    OpdrachtRouteParams,
    PlanningRoutes,
    PlanningStoreState,
    OpdrachtSidebarConnected,
    useLoadPersistentPlanningState,
    useSyncedPlanningState,
    useCurrentView,
    PlanningViews,
} from '@growthbase/planning';
import {RouteElementProps, useRoutes} from '@growthbase/routing';
import {generatePath} from 'react-router';
import {toUpper} from 'lodash';
import {useSelector} from 'react-redux';

export type OpdrachtPlanningPageConnectedProps = RouteElementProps<OpdrachtRouteParams>;

export const OpdrachtPlanningPageConnected = (props: OpdrachtPlanningPageConnectedProps) => {
    const {params} = props;

    if (!params) {
        throw new Error('Logic exception.');
    }

    const currentView = useCurrentView();
    const {opdrachtenSidebarEnabled, viewSettingsLoaded} = useSelector((state: PlanningStoreState) => state.planning);

    const {t} = useTranslation('spa.nl');

    const routes = useRoutes();

    useLoadPersistentPlanningState();
    useSyncedPlanningState();

    const planningUrl = PlanningRoutes.opdracht.useUrl(params);

    const sidebar =
        currentView.group !== 'capaciteit' && opdrachtenSidebarEnabled ? (
            <OpdrachtSidebarConnected key={currentView.group} opdrachtId={params.opdrachtId} />
        ) : (
            <AgendaSidebarConnected />
        );

    if (!viewSettingsLoaded) {
        return <Loader center />;
    }

    return (
        <Page title={t('planning.titel')} sidebar={sidebar}>
            <AddBreadcrumb to={generatePath(routes.order.index)}>{t('opdracht.breadcrumb.opdrachten')}</AddBreadcrumb>
            <AddBreadcrumb
                to={generatePath(routes.order.read, {
                    order: params.opdrachtId,
                    name: params.opdrachtnummer,
                })}
            >
                {t('opdracht.breadcrumb.opdrachtnummer', {
                    opdrachtnummer: toUpper(params.opdrachtnummer),
                })}
            </AddBreadcrumb>
            <AddBreadcrumb to={planningUrl}>{t('opdracht.breadcrumb.planning')}</AddBreadcrumb>
            <PlanningViews />
        </Page>
    );
};
