import {compareKoppelbaarItem} from '@growthbase/taken';
import {idFromIri} from '@growthbase/routing';
import {Lijst, VerplaatsFormDTO} from './useVerplaatsSchema';
import {CurrentLijstValues, FetchOptions, Opties} from './useVerplaatsForm';

export interface VerplaatstFormState {
    instanceId: string;
    lijst: Lijst;
    loading: boolean;
    isValid: boolean;
    changed: boolean;
    error: unknown | false;
    opties: Opties | null;
    currentValues: CurrentLijstValues | null;
    formValues: VerplaatsFormDTO;
    formValuesOfOptionRequest: FetchOptions | null;
    bordQuery: string | null;
}

const valuesChanged = (state: VerplaatstFormState): boolean => {
    const {currentValues, formValues, opties} = state;
    if (currentValues === null) {
        return false;
    }
    const {bord} = currentValues;
    const previousBordId = bord?.id ?? null;
    if (previousBordId !== formValues.bord) {
        return true;
    }
    if (!opties) {
        return false;
    }
    const {lijsten} = opties;
    const previousTaakPositie = lijsten.findIndex((t) => t.id === state.lijst.id);
    return previousTaakPositie !== formValues.positie;
};

export interface CurrentValuesAction {
    type: 'currentValues';
    values: CurrentLijstValues;
    lijst: Lijst;
}

export interface LoadOptiesAction {
    type: 'load';
    formValuesOfOptionRequest: VerplaatsFormDTO;
}

export interface OptiesResultsAction {
    type: 'results';
    opties: Opties;
    formValuesOfOptionRequest: VerplaatsFormDTO;
}

export interface LoadErrorAction {
    type: 'errored';
    error: unknown;
    formValuesOfOptionRequest: VerplaatsFormDTO;
}

export interface RetryErrorAction {
    type: 'retry';
}

export interface BordQuery {
    type: 'bordQuery';
    query: string | null;
}

export interface UpdateFormValuesAction {
    type: 'formValues';
    values: VerplaatsFormDTO;
}

export type VerplaatstFormAction =
    | CurrentValuesAction
    | RetryErrorAction
    | LoadOptiesAction
    | OptiesResultsAction
    | LoadErrorAction
    | UpdateFormValuesAction
    | BordQuery;

export const verplaatsenFormReducer = (draft: VerplaatstFormState, action: VerplaatstFormAction): void => {
    switch (action.type) {
        case 'currentValues': {
            draft.lijst = action.lijst;
            draft.loading = true;
            draft.currentValues = action.values;
            draft.formValuesOfOptionRequest = {
                ...draft.formValues,
                bordQuery: draft.bordQuery,
                onderdeelChanged: false,
            };
            if (draft.opties) {
                return;
            }
            const {bord} = action.values;
            draft.opties = {
                bord,
                lijsten: [action.lijst],
                borden: [bord],
                onderdeelVan: action.values.onderdeelVan,
                relatie: action.values.relatie,
            };
            break;
        }
        case 'load':
            draft.error = false;
            break;
        case 'results': {
            draft.formValuesOfOptionRequest = null;
            draft.loading = false;
            const lijsten: Lijst[] = [
                ...action.opties.lijsten,
            ];

            const lijstFound = lijsten.find((t) => idFromIri(t.id) === idFromIri(draft.lijst.id));

            // Add taak as last, when it's not already the last taak
            if (!lijstFound && lijsten[lijsten.length - 1]?.id !== draft.lijst.id) {
                lijsten.push({
                    ...draft.lijst,
                    onderaan: true,
                });
            }

            draft.opties = {
                ...action.opties,
                lijsten,
            };
            if (!draft.formValues) {
                break;
            }
            const bordChanged = draft.formValues.bord !== action.opties.bord?.id;
            draft.formValues.bord = action.opties.bord?.id ?? null;
            draft.formValues.onderdeelVan = action.opties.onderdeelVan;
            if (bordChanged || draft.formValues.positie === null || draft.formValues.positie > lijsten.length) {
                draft.formValues.positie = lijsten.findIndex((t) => idFromIri(t.id) === idFromIri(draft.lijst.id));
            }
            break;
        }
        case 'bordQuery':
            draft.bordQuery = action.query;
            draft.formValuesOfOptionRequest = {
                ...draft.formValues,
                bordQuery: action.query,
                onderdeelChanged: false,
            };
            break;
        case 'errored':
            draft.loading = false;
            draft.error = action.error;
            break;
        case 'retry':
            draft.loading = true;
            draft.error = false;
            break;
        case 'formValues': {
            const bordChanged = draft.formValues?.bord !== action.values.bord;
            const onderdeelVanChanged = !compareKoppelbaarItem(
                draft.formValues?.onderdeelVan,
                action.values.onderdeelVan
            );
            if (bordChanged || onderdeelVanChanged) {
                draft.loading = true;
                draft.formValuesOfOptionRequest = {
                    ...action.values,
                    bordQuery: draft.bordQuery,
                    onderdeelChanged: onderdeelVanChanged,
                };
            }
            draft.formValues = {
                ...action.values,
            };
            if (!draft.formValues.bord) {
                draft.formValues.positie = null;
            }
            break;
        }
        default:
            break;
    }

    draft.isValid = draft.opties !== null && draft.opties.bord !== null;

    draft.changed = valuesChanged(draft);
};
