import React, {ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {InstellingViewProps} from './InstellingViewWrapper';
import {KeyValuePair} from './NestedAsCardInstellingView';

export type BooleanInstellingViewProps = InstellingViewProps & {
    append?: ReactNode;
};

export const BooleanInstellingView = ({label, value, append = null}: BooleanInstellingViewProps) => {
    const {t} = useTranslation('DesignComponents.nl');
    return (
        <KeyValuePair label={label}>
            {value ? t('InstellingenView.yes') : t('InstellingenView.no')}
            {append}
        </KeyValuePair>
    );
};
