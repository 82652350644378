import React, {FC, PropsWithChildren, ReactNode} from 'react';
import {Buttons, Page} from '@growthbase/design-components';
import {InstellingInputProps} from './InstellingInputWrapper';
import {NestedInstellingInput} from './NestedInstellingInput';

export type PageInstellingInputProps = InstellingInputProps & {buttonText?: ReactNode} & PropsWithChildren;

export const PageInstellingInput: FC<PageInstellingInputProps> = ({children, buttonText, ...props}) => (
    <Page>
        <NestedInstellingInput {...props} />
        <Buttons.Save>{buttonText}</Buttons.Save>
        {children}
    </Page>
);
