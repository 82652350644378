import {
    Buttons,
    Field,
    FieldLabel,
    Flex,
    Line,
    ModalContentNegative,
    PaddingHorizontal,
    PositionType,
    Stack,
    useIsMobile,
} from '@growthbase/design-components';
import {ITaakEditFormFragment} from '@growthbase/graphql';
import React, {ReactNode, FC, MouseEventHandler} from 'react';
import {useTranslation} from 'react-i18next';
import {Box} from '@growthbase/design-elements';
import {TaakRelatieButtonConnected} from '../Relaties/TaakRelatieButtonConnected';
import {TaakomschrijvingFieldConnected} from '../TaakomschrijvingField';
import {TaakPlanningButtonConnected} from '../TaakPlanningButton';
import {TaaktitelFieldConnectedProps} from '../TaaktitelField';
import {RenderKoppelAanTaakButtonType} from '../Koppelen/KoppelAanTaakButton/RenderKoppelAanTaakButtonType';
import {useNormalizeTaakOnderdeelVanFragmentFactory} from '../Koppelen';
import {DeelnemersConnected} from '../Deelnemers/DeelnemersConnected';
import {BestemmingTextLabelConnected, VerplaatsButton} from '../TaakVerplaatsen';
import {SnoozeButtonConnected} from '../SnoozeButton';
import {TaakEditFragment} from '../TaakEditModal';

export interface TaakEditFormProps {
    fragment?: ITaakEditFormFragment | TaakEditFragment;
    renderTitelField: (props: TaaktitelFieldConnectedProps) => ReactNode;
    OmschrijvingsFields: typeof TaakomschrijvingFieldConnected;
    TaakPlanningButton: typeof TaakPlanningButtonConnected;
    Deelnemers: typeof DeelnemersConnected;
    RelatieButton: typeof TaakRelatieButtonConnected;
    SnoozeButton?: typeof SnoozeButtonConnected;
    bijlages: ReactNode;
    voegBijlageToe?: MouseEventHandler<HTMLElement>;
    renderKoppelButton: RenderKoppelAanTaakButtonType;
}

export const TaakEditForm: FC<TaakEditFormProps> = ({
    renderTitelField,
    OmschrijvingsFields,
    TaakPlanningButton,
    RelatieButton,
    renderKoppelButton,
    Deelnemers,
    fragment,
    SnoozeButton = SnoozeButtonConnected,
    bijlages,
    voegBijlageToe,
}) => {
    const {t} = useTranslation('spa_form.nl');
    const isMobile = useIsMobile();

    const {t: tNew} = useTranslation('Taken.nl');

    const normalizeIKoppelButtonConnectedFragment = useNormalizeTaakOnderdeelVanFragmentFactory();

    return (
        <Stack gap="large">
            <Stack.Divider hide={!isMobile} />

            <PaddingHorizontal paddingSize={isMobile ? 'large' : 'none'}>
                <Stack.Item>{renderTitelField({fragment})}</Stack.Item>
            </PaddingHorizontal>

            {isMobile && (
                <Box p={0.5} bgcolor="background.default" borderTop={1} borderBottom={1} borderColor="border.default" />
            )}

            {!isMobile && (
                <>
                    <Stack.Item>
                        <Stack horizontal justify>
                            <Stack.Item>
                                <BestemmingTextLabelConnected disabled={false} readonly={false} fragment={fragment} />
                            </Stack.Item>
                            <Stack.Item>
                                <VerplaatsButton disabled={false} readonly={false} fragment={fragment ?? null} />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </>
            )}

            <Stack.Item>
                <Stack horizontal={!isMobile} gap="default">
                    {Flex.createOrderedItems(
                        {
                            start: {
                                divider: true,
                            },
                        },
                        ({add}) => {
                            const {
                                gekoppeldeRelatieBekijken,
                                gekoppeldeOpdrachtBekijken,
                                gekoppeldeOpdrachtBewerken,
                                gekoppeldeRelatieBewerken,
                                gekoppeldeAbonnementBekijken,
                                gekoppeldeAbonnementBewerken,
                                gekoppeldeTicketBekijken,
                                gekoppeldeTicketBewerken,
                            } = {
                                gekoppeldeRelatieBekijken: false,
                                gekoppeldeOpdrachtBekijken: false,
                                gekoppeldeOpdrachtBewerken: false,
                                gekoppeldeRelatieBewerken: false,
                                gekoppeldeAbonnementBekijken: false,
                                gekoppeldeAbonnementBewerken: false,
                                gekoppeldeTicketBekijken: false,
                                gekoppeldeTicketBewerken: false,
                                ...fragment?.availableActions,
                            };

                            add(
                                <Field label={t('taak.table.deelnemers')} compact disableMargin>
                                    <Deelnemers fragment={fragment} />
                                </Field>,
                                PositionType.start
                            );
                            const hasRelatie = !!fragment?.relatie?.relatieverwijzing;
                            add(
                                gekoppeldeRelatieBekijken && fragment?.relatie && (
                                    <Field
                                        label={t('taak.table.relation')}
                                        compact
                                        disableMargin
                                        hideLabel={!hasRelatie}
                                    >
                                        <RelatieButton readonly={!gekoppeldeRelatieBewerken} fragment={fragment} />
                                    </Field>
                                ),
                                hasRelatie ? PositionType.start : PositionType.end
                            );
                            const item = normalizeIKoppelButtonConnectedFragment(fragment ?? null);
                            const magBewerken =
                                gekoppeldeAbonnementBewerken || gekoppeldeOpdrachtBewerken || gekoppeldeTicketBewerken;
                            const magBekijken =
                                gekoppeldeAbonnementBekijken || gekoppeldeOpdrachtBekijken || gekoppeldeTicketBekijken;

                            add(
                                magBekijken && (
                                    <Field label={t('taak.table.gekoppeld')} compact disableMargin hideLabel={!item}>
                                        {renderKoppelButton({
                                            readonly: !magBewerken,
                                        })}
                                    </Field>
                                ),
                                item ? PositionType.start : PositionType.end
                            );
                            const planning = fragment?.planning;
                            add(
                                planning?.begindatum && (
                                    <Field label={t('taak.table.startDate')} compact disableMargin>
                                        <TaakPlanningButton asText textType="begindatum" fragment={fragment} />
                                    </Field>
                                ),
                                PositionType.start
                            );
                            add(
                                planning?.deadline && (
                                    <Field label={t('taak.table.deadline')} compact disableMargin>
                                        <TaakPlanningButton asText fragment={fragment} />
                                    </Field>
                                ),
                                PositionType.start
                            );
                            add(
                                (!planning?.deadline || !planning?.begindatum) && (
                                    <Field label={t('taak.table.deadline')} compact disableMargin hideLabel>
                                        <TaakPlanningButton
                                            fragment={fragment}
                                            AddButton={Buttons.CalendarPlusIconDotted}
                                            ModifyButton={Buttons.CalendarIconDotted}
                                        />
                                    </Field>
                                ),
                                PositionType.end
                            );

                            const isSnoozed = !!fragment?.snooze?.tot;
                            const snooze = <SnoozeButton asText={isSnoozed} fragment={fragment} />;

                            add(
                                <Field
                                    label={tNew('SnoozeButton.snooze')}
                                    compact
                                    disableMargin
                                    hideLabel={!isSnoozed && !isMobile}
                                >
                                    {snooze}
                                </Field>,
                                isSnoozed ? PositionType.start : PositionType.end
                            );
                        }
                    )}
                </Stack>
            </Stack.Item>

            {isMobile && (
                <Box p={0.5} bgcolor="background.default" borderTop={1} borderBottom={1} borderColor="border.default" />
            )}

            <Stack.Item>
                {!isMobile && <Box paddingTop={2} />}
                <PaddingHorizontal paddingSize={isMobile ? 'large' : 'none'}>
                    <OmschrijvingsFields key={fragment?.id} fragment={fragment} />
                </PaddingHorizontal>

                {isMobile && <Box paddingBottom={8} />}
            </Stack.Item>

            {bijlages && (
                <ModalContentNegative>
                    <Box paddingTop={2} />
                    <div style={{position: 'relative'}}>
                        <Stack.Item pushItems="before">
                            <>
                                <Line />
                                <Box paddingTop={2} />
                                <PaddingHorizontal paddingSize="large">
                                    <Stack gap="default">
                                        <Stack.Item>
                                            <Stack horizontal={!isMobile} gap="default" justify align="center">
                                                <Stack.Item>
                                                    <FieldLabel>{tNew('Bijlage.title')}</FieldLabel>
                                                </Stack.Item>
                                                {voegBijlageToe && (
                                                    <Stack.Item>
                                                        <Buttons.Add
                                                            onNativeClick={voegBijlageToe}
                                                            size="medium"
                                                            variant="iconOnlySolid"
                                                        />
                                                    </Stack.Item>
                                                )}
                                            </Stack>
                                        </Stack.Item>
                                        <Stack.Item>{bijlages}</Stack.Item>
                                    </Stack>
                                </PaddingHorizontal>
                            </>
                        </Stack.Item>
                    </div>
                </ModalContentNegative>
            )}
        </Stack>
    );
};
