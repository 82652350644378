import {FC, ReactNode} from 'react';
import {useCallbackRef, usePortalDestination} from '@growthbase/spa';
import {createPortal} from 'react-dom';
import {ScrollProvider} from '@growthbase/design-components';
import {MijnTakenTabelConnected} from '../MijnTakenOverzicht/MijnTakenTabelConnected';

export const MijnTakenOpDashboard: FC = () => {
    const destination = usePortalDestination('taken-dashboard-mijn-taken');

    const bodyWrapper = useCallbackRef((children: ReactNode) => (
        <ScrollProvider maxHeight={400}>{children}</ScrollProvider>
    ));

    if (!destination) {
        return null;
    }

    return createPortal(<MijnTakenTabelConnected bodyWrapper={bodyWrapper} />, destination);
};
