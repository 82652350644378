import {PopupHeader} from '@growthbase/design-components';
import {ReactNode, FC} from 'react';
import {useTranslation} from 'react-i18next';

export interface ModelHeaderProps {
    children: ReactNode;
    actionMenu: ReactNode;
}

export const ModelHeader: FC<ModelHeaderProps> = ({children, actionMenu}) => {
    const {t} = useTranslation('Bordtemplate.nl');
    return (
        <PopupHeader title={t('bordBewerken')} buttons={[actionMenu]}>
            {children}
        </PopupHeader>
    );
};
