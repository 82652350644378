import {AvatarPopup, Buttons, PopupControlsContext, useMemoryPopupControls} from '@growthbase/design-components';
import {useDeelnemersFilterZoekenQuery, useResolveConnections} from '@growthbase/graphql';
import {idFromIri} from '@growthbase/routing';
import {useDebouncedState} from '@growthbase/spa';
import {useCallback, useMemo, FC} from 'react';
import {useTranslation} from 'react-i18next';
import {IDeelnemer} from './Deelnemers';

export interface DeelnemersFilterConnectedProps {
    deelnemers: string[] | null;
    setDeelnemers: (value: string[] | null) => void;
}

export const DeelnemersFilterConnected: FC<DeelnemersFilterConnectedProps> = ({deelnemers, setDeelnemers}) => {
    const {t} = useTranslation('spa.nl');

    const {
        value: searchQuery,
        setValue: setSearchQuery,
        debouncedValue: searchQueryDebounced,
    } = useDebouncedState<string | null>(null, 400);
    const controls = useMemoryPopupControls(false);

    const {data: searchData, loading: loadingSearch} = useDeelnemersFilterZoekenQuery({
        skip: !controls.isOpen,
        variables: {
            fulltextSearch: searchQueryDebounced,
        },
    });
    const searchDeelnemers = useResolveConnections(searchData?.Gebruikers);

    return (
        <PopupControlsContext.Provider value={controls}>
            <AvatarPopup<IDeelnemer>
                items={useMemo(
                    () => searchDeelnemers.map((d) => ({...d, selected: deelnemers?.includes(idFromIri(d.id))})),
                    [deelnemers, searchDeelnemers]
                )}
                title={t('TakenFilters.Deelnemers.Popup.Title')}
                onClickRow={useCallback(
                    ({id}) => {
                        const stripped = idFromIri(id);
                        if (deelnemers?.includes(stripped)) {
                            return;
                        }
                        setDeelnemers([...(deelnemers ?? []), stripped]);
                    },
                    [deelnemers, setDeelnemers]
                )}
                actionButtonProps={useCallback(
                    (item: IDeelnemer) => ({
                        onClick: () => {
                            const stripped = idFromIri(item.id);
                            if (!deelnemers?.includes(stripped)) {
                                return;
                            }
                            const without = deelnemers?.filter((deelnemer) => deelnemer !== stripped) ?? [];
                            setDeelnemers(without.length === 0 ? null : without);
                        },
                    }),
                    [deelnemers, setDeelnemers]
                )}
                onChangeQuery={useCallback(
                    (input) => {
                        setSearchQuery(input.query);
                    },
                    [setSearchQuery]
                )}
                searchQuery={searchQuery}
                loading={loadingSearch}
                renderAnchor={({reference: anchorReference, controls: anchorControls}) => (
                    <Buttons.Filter
                        active={anchorControls.isOpen || (!!deelnemers && deelnemers.length > 0)}
                        ref={anchorReference}
                        onClick={anchorControls.toggle}
                    />
                )}
            />
        </PopupControlsContext.Provider>
    );
};
