import {RouteElementProps} from '@growthbase/routing';
import {LoaderCenter, useGebruikerHeeftToegang} from '@growthbase/design-components';
import {ViewConnected as LijstViewConnected} from '@growthbase/takenlijst/bord';
import {useAbonnementBordenBordPaginaQuery} from '@growthbase/graphql';
import {DefaultKoppelbaarItem, DefaultRelatieValue} from '@growthbase/spa';
import {useResolveOnderdeelVan} from '../Overview/Hook/useResolveOnderdeelVan';

export type ViewConnectedProps = RouteElementProps<{
    abonnementId: string;
    abonnementnummer: string;
    abonnementUuid: string;
    bordUuid: string;
}>;

export const ViewConnected = ({params}: ViewConnectedProps) => {
    if (!params) {
        throw new Error('Logic exception.');
    }
    const bordId = params.bordUuid;
    const {data, loading} = useAbonnementBordenBordPaginaQuery({
        variables: {
            id: params.abonnementUuid,
            bordId,
        },
    });

    const bord = data?.takenBord;
    const toegang = useGebruikerHeeftToegang({
        enabled: !loading,
        toegang: bord?.availableActions?.kanBekijken,
    });
    const onderdeelVan = useResolveOnderdeelVan(params.abonnementUuid, data?.abonnement);

    if (!toegang) {
        return <LoaderCenter />;
    }

    return (
        <DefaultKoppelbaarItem.Provider id="OverviewConnected" value={onderdeelVan}>
            <DefaultRelatieValue.Provider id="OverviewConnected" value={onderdeelVan?.relatie}>
                {bord && <LijstViewConnected bordId={bordId} />}
            </DefaultRelatieValue.Provider>
        </DefaultKoppelbaarItem.Provider>
    );
};
