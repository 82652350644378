import React, {FC} from 'react';
import {FormControl, MenuItem, Select} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Field} from '../../contract';

export interface TypeSelectionProps {
    fields: Field[];
    field: Field;
    onChange: (field: Field) => void;
}

export const TypeSelection: FC<TypeSelectionProps> = ({field, fields, onChange}) => {
    const {t} = useTranslation('querybuilder.nl');
    return (
        <FormControl fullWidth>
            <Select
                style={{
                    minWidth: 150,
                }}
                value={field.name}
                onChange={(e) => {
                    const f = fields.find((n) => n.name === e.target.value);
                    if (f) {
                        onChange(f);
                    }
                }}
            >
                {fields.map((f) => (
                    <MenuItem key={f.name} value={f.name}>
                        {f.label(t)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
