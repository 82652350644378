import React, {FC, ReactElement, ReactNode} from 'react';
import classNames from 'classnames/bind';
import {Icons, IconSize} from '../index';
import styles from './HoverIcon.module.scss';

const cx = classNames.bind(styles);

export interface HoverIconProps {
    icon?: ReactElement;
    children?: ReactNode;
    overflowEllipsis?: boolean;
}

export const HoverIcon: FC<HoverIconProps> = ({
    icon = <Icons.Pencil size={IconSize.SIZE_1} />,
    children,
    overflowEllipsis,
}) => {
    const classes = cx('hoverIcon', {
        'hoverIcon--overflowEllipsis': overflowEllipsis,
    });

    return (
        <div className={classes}>
            {children}
            <div className={styles.hoverIcon__icon}>{icon}</div>
        </div>
    );
};
