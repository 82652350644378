import {FC} from 'react';
import {InstellingFieldType} from '../../types';
import {
    ArrayInstellingView,
    BooleanInstellingView,
    InstellingViewProps,
    NestedAsCardInstellingView,
    NumberInstellingView,
    TextInstellingView,
} from './Components';

export function defaultViewComponent(type: InstellingFieldType, name: string): FC<InstellingViewProps> {
    switch (type) {
        case InstellingFieldType.string:
            return TextInstellingView;
        case InstellingFieldType.boolean:
            return BooleanInstellingView;
        case InstellingFieldType.number:
            return NumberInstellingView;
        case InstellingFieldType.array:
            return ArrayInstellingView;
        case InstellingFieldType.nested:
            return NestedAsCardInstellingView;
        default:
            throw new Error(`Unknown type ${type} for field ${name}`);
    }
}
