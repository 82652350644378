import React, {FC, useMemo} from 'react';

import {BsLightning as SocketConnecting} from 'react-icons/bs';
import {AiOutlineDisconnect as SocketDisconnected} from 'react-icons/ai';
import {SocketStatus, useSocketStatus} from '@growthbase/websockets';

export const SocketStatusIconConnected: FC = () => {
    const status = useSocketStatus();
    const Icon = useMemo(() => {
        switch (status) {
            case SocketStatus.connecting:
                return SocketConnecting;
            case SocketStatus.connected:
                return React.Fragment;
            case SocketStatus.disconnected:
            default:
                return SocketDisconnected;
        }
    }, [status]);
    return (
        <div
            data-socket-status={SocketStatus[status]}
            style={{
                color: status === SocketStatus.connected ? 'green' : 'orange',
                position: 'absolute',
                bottom: 'var(--spacingSmall)',
                left: 'var(--spacingSmall)',
                opacity: 0.2,
            }}
        >
            <Icon />
        </div>
    );
};
