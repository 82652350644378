import React, {FC} from 'react';
import {Stack} from '@growthbase/design-components';
import Checkbox from '@mui/material/Checkbox';
import {FormControlLabel, FormGroup} from '@mui/material';
import {useDashboardEnabled} from './Hook/useDashboardEnabled';

export const DashboardConfig: FC = () => {
    const [enabled, setEnabled] = useDashboardEnabled();

    return (
        <Stack gap="tiny">
            <FormGroup>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={enabled}
                            onChange={(event, checked) => setEnabled(checked)}
                            title="dashboard"
                        />
                    }
                    label="dashboard (Refresh required)"
                />
            </FormGroup>
        </Stack>
    );
};
