import {CacheUpdate} from '@growthbase/cache';
import {GraphQLServices} from '@growthbase/graphql';
import {
    ApplicationContext,
    ApplicationServices,
    SpaErrorBoundary,
    Toaster,
    TranslationProvider,
    useService,
} from '@growthbase/spa';
import React, {FC} from 'react';
import {Provider} from 'react-redux';
import {HelmetProvider} from 'react-helmet-async';
import '@growthbase/design-tokens';
import {RoutesRegistryProvider, RoutingProvider} from '@growthbase/routing';
import {
    BreadcrumbProvider,
    PageBreadcrumbs,
    Breadcrumbs,
    GeenToegangPaginaConnected,
} from '@growthbase/design-components';
import {ActiveItemRegionContainer, DNDContext, GlobalModifierKeysContextProvider} from '@growthbase/keys';
import {PlanningDebug} from '@growthbase/planning';
import {GlobalTheme} from '@growthbase/design-elements';
import {LogbookItemsProvider} from '@growthbase/activiteiten';
import {SocketStatusIconConnected} from './SocketStatusIcon/SocketStatusIconConnected';

type CombinedApplicationServices = ApplicationServices & GraphQLServices;

export interface ApplicationRootProps {
    services: ApplicationServices;
}

const InErrorBoundary: FC = () => {
    const services = useService<CombinedApplicationServices & {Routes: FC}>();

    const {Components} = services;
    return (
        <ActiveItemRegionContainer name="InErrorBoundary">
            <GeenToegangPaginaConnected>
                <PlanningDebug />
                <DNDContext>
                    <RoutesRegistryProvider>
                        <PageBreadcrumbs>
                            <Breadcrumbs />
                        </PageBreadcrumbs>
                        {services.config.takenEnabled && (
                            <>
                                <CacheUpdate />
                                <SocketStatusIconConnected />
                            </>
                        )}

                        <Components config={services.config} />
                    </RoutesRegistryProvider>
                </DNDContext>
            </GeenToegangPaginaConnected>
        </ActiveItemRegionContainer>
    );
};

export const ApplicationRoot: FC<ApplicationRootProps> = ({services}) => {
    const GraphqlProvider = (services as CombinedApplicationServices).graphqlProvider;
    return (
        // Sticky note: StrictMode is not compatible react-sticky-el
        // const Mode = services.disableStrictMode ? React.Fragment : StrictMode;
        <>
            <GlobalTheme>
                <HelmetProvider>
                    <ApplicationContext.Provider value={services}>
                        <TranslationProvider>
                            <Provider store={services.store}>
                                <Toaster />
                                <GraphqlProvider>
                                    <RoutingProvider>
                                        <LogbookItemsProvider>
                                            <BreadcrumbProvider>
                                                <GlobalModifierKeysContextProvider>
                                                    <SpaErrorBoundary>{InErrorBoundary}</SpaErrorBoundary>
                                                </GlobalModifierKeysContextProvider>
                                            </BreadcrumbProvider>
                                        </LogbookItemsProvider>
                                    </RoutingProvider>
                                </GraphqlProvider>
                            </Provider>
                        </TranslationProvider>
                    </ApplicationContext.Provider>
                </HelmetProvider>
            </GlobalTheme>
        </>
    );
};
