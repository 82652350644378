import {
    DefaultFormModalFooter,
    Field,
    Modal,
    ModalContent,
    OpgemaakteTekstInput,
    PopupHeader,
    TabContentWrapper,
    Stack,
    BoxSelectInput,
    BoxButton,
} from '@growthbase/design-components';
import {RelatieInputConnected} from '@growthbase/relaties';
import React, {FC, PropsWithChildren, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {KoppelButtonInputConnected, RenderKoppelButtonProps} from '@growthbase/taken';
import {DeelnemersInputConnected} from '@growthbase/gebruikers';
import {IZichtbaarVoor} from '@growthbase/graphql';
import {useCreateForm, UseCreateFormOptions} from './Hook/useCreateForm';
import {useZichtbaarVoorOpties} from '../EditForm/Components';

export interface CreateFormProps extends UseCreateFormOptions, PropsWithChildren {
    RelatieInput?: typeof RelatieInputConnected;
    OnderdeelVanComponent?: typeof KoppelButtonInputConnected;
    DeelnemersInput?: typeof DeelnemersInputConnected;
}

export const CreateForm: FC<CreateFormProps> = ({
    children,
    RelatieInput = RelatieInputConnected,
    OnderdeelVanComponent = KoppelButtonInputConnected,
    DeelnemersInput = DeelnemersInputConnected,
    ...rest
}) => {
    const {t} = useTranslation('TakenlijstBord.nl');
    const {Form, Fields, formik, formProps, renderNaam, render} = useCreateForm(rest);
    const {OmschrijvingField, ZichtbaarVoorField, OnderdeelVanField, DeelnemersField} = Fields;
    const Omschrijving = OmschrijvingField.useComponent(OpgemaakteTekstInput);
    const OnderdeelVan = OnderdeelVanField.useComponent(OnderdeelVanComponent);
    const ZichtbaarVoor = ZichtbaarVoorField.useComponent(BoxSelectInput);
    const options = useZichtbaarVoorOpties(!!formik.values.deelnemers.length);
    const {
        values: {zichtbaarVoor},
    } = formik;

    const renderKoppelButton = useCallback(
        ({item, ...props}: RenderKoppelButtonProps) => (
            <BoxButton {...props} value={item?.label}>
                {t('gekoppeld')}
            </BoxButton>
        ),
        [t]
    );

    return render(
        <Modal testId="BordCreateModal">
            <Form {...formProps}>
                <PopupHeader title={t('add')} />
                <ModalContent theme="gray">
                    <TabContentWrapper title="x">
                        <Stack gap="small">
                            <Stack.Item>
                                <Field label={t('naam')} compact disableMargin>
                                    {renderNaam({
                                        variant: 'default',
                                        autoFocus: true,
                                        controls: false,
                                        borderVisible: true,
                                    })}
                                </Field>
                            </Stack.Item>
                            <Stack.Item>
                                <ZichtbaarVoor {...{options}} label={t('zichtbaarVoor.label')} labelHidden />
                            </Stack.Item>
                            <Stack.Item hide={zichtbaarVoor === IZichtbaarVoor.Mijzelf}>
                                <Field label={t('deelnemers')} compact disableMargin>
                                    <DeelnemersField component={DeelnemersInput} />
                                </Field>
                            </Stack.Item>
                            <Stack.Item>
                                <Omschrijving
                                    label={t('omschrijving')}
                                    placeholder={t('omschrijvingPlaceholder')}
                                    floatingToolbar={false}
                                />
                            </Stack.Item>
                            <Stack.Item label="Koppelen">
                                <OnderdeelVan renderKoppelButton={renderKoppelButton} />
                            </Stack.Item>
                        </Stack>
                        {children}
                    </TabContentWrapper>
                </ModalContent>
                <DefaultFormModalFooter formik={formik} />
            </Form>
        </Modal>
    );
};
