import {ThemeOptions} from '@mui/material/styles/createTheme';

export const Chip: ThemeOptions['components'] = {
    MuiChip: {
        styleOverrides: {
            root: {
                borderRadius: 0,
            },
        },
    },
};
