import {ITaaktitelFieldFragment, IWijzigTaaktitelTaakInput} from '@growthbase/graphql';
import {useCallback, FC} from 'react';
import {SingleFieldForm, TextFieldBigComponent} from '@growthbase/design-components';
import {v4} from 'uuid';
import {useTaaktitelFieldSchema} from './TaaktitelFieldSchema';

export interface TaaktitelFieldProps {
    fragment?: ITaaktitelFieldFragment;
    onSubmit: (value: IWijzigTaaktitelTaakInput) => Promise<void>;
}

export const TaaktitelField: FC<TaaktitelFieldProps> = ({onSubmit, fragment}) => (
    <SingleFieldForm
        disabled={!fragment}
        readonly={!fragment?.availableActions?.kanTitelBewerken}
        name="titel"
        component={TextFieldBigComponent}
        schema={useTaaktitelFieldSchema()}
        createInitialValues={useCallback(() => fragment ?? {titel: '', id: v4()}, [fragment])}
        onSubmit={onSubmit}
    />
);
