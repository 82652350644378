import {useAsyncComboBox, useComboBoxContext} from '@growthbase/design-components';
import {IRelatieverwijzingInput, useRelatiesComboboxVerwijzingenQuery} from '@growthbase/graphql';
import {useCallbackRef} from '@growthbase/spa';
import React, {memo, useEffect, useMemo} from 'react';
import {RelatiesCombobox, RelatiesMentionKey} from './RelatiesCombobox';
import {useRelatieComboboxQuery} from './RelatiesComboboxContext';

interface RelatiesComboboxConnectedInnerProps {
    open: boolean;
    text: string | null;
    setLoading: (loading: boolean) => void;
    relatieverwijzing: IRelatieverwijzingInput | null;
}

const RelatiesComboboxConnectedInner = memo(
    ({setLoading, text, relatieverwijzing, open}: RelatiesComboboxConnectedInnerProps) => {
        const {data, loading} = useRelatiesComboboxVerwijzingenQuery({
            variables: {
                query: {
                    query: text,
                    relatieverwijzing,
                },
            },
            skip: !open,
        });

        useEffect(() => setLoading(loading), [loading, setLoading]);

        const nodes = data?.ingelogdeGebruiker?.beschikbareRelaties?.groups;
        return (
            <RelatiesCombobox
                groups={useMemo(
                    () =>
                        (nodes || []).map((node) => ({
                            label: node.label,
                            relaties: node.edges.map(({node: n}) => n),
                        })),
                    [nodes]
                )}
            />
        );
    }
);
RelatiesComboboxConnectedInner.displayName = 'RelatiesComboboxConnectedInner';

export const RelatiesComboboxConnected = () => {
    const relatieverwijzing = useRelatieComboboxQuery().value;
    const comboboxContext = useComboBoxContext();
    const {text, open} = useAsyncComboBox(RelatiesMentionKey);
    return (
        <RelatiesComboboxConnectedInner
            open={open}
            text={text}
            setLoading={useCallbackRef(() => comboboxContext.setLoading)}
            relatieverwijzing={relatieverwijzing}
        />
    );
};
