import React, {FC, PropsWithChildren, ReactNode} from 'react';

import classNames from 'classnames/bind';
import styles from './PopupResults.module.scss';

const cx = classNames.bind(styles);

interface PopupItem {
    id: string;
}

export const generateKeyById = (item: PopupItem) => item.id;

export interface PopupResultsProps<T extends PopupItem = never> {
    items: T[];
    generateKey: (item: T) => string;
    children: (props: {disabled?: boolean; tabIndex: number; data: T}, item: T) => React.ReactNode;
    title?: string;
    disabled?: boolean;
}

export const PopupResultsContainer: FC<PropsWithChildren> = ({children}) => (
    <div className={cx('popupResults')}>{children}</div>
);

export const PopupResultsHeader = ({children, first}: {children: ReactNode; first?: boolean}) => (
    <div className={cx('popupResults__title', {'popupResults__title--first': first})}>{children}</div>
);

export function PopupResults<T extends PopupItem = PopupItem>({
    items,
    disabled,
    title,
    children,
    generateKey,
}: PopupResultsProps<T>) {
    return (
        <PopupResultsContainer>
            {title && <PopupResultsHeader>{title}</PopupResultsHeader>}
            <div className={cx('popupResults__results')}>
                {items.map((item, tabIndex) => (
                    <div className={cx('popupResults__result')} key={generateKey(item)}>
                        {children({disabled, tabIndex, data: item}, item)}
                    </div>
                ))}
            </div>
        </PopupResultsContainer>
    );
}

export default PopupResults;
