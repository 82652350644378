import {AnchorHTMLAttributes} from 'react';
import classNames from 'classnames/bind';
import {capitalize} from 'lodash';
import styles from './HtmlLink.module.scss';

const cx = classNames.bind(styles);

export interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    color?: 'gray' | 'default' | 'primary';
    size?: 'small' | 'default';
}

export const HtmlLink = ({children, color, size, ...props}: LinkProps) => {
    const classes = cx('htmlLink', `htmlLink--size${capitalize(size)}`, `htmlLink--color${capitalize(color)}`);
    return (
        <a className={classes} {...props}>
            {children}
        </a>
    );
};
