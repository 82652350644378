import {CounterButton, SidenavLink} from '@growthbase/design-components';
import React, {FC, useMemo} from 'react';
import {DynamicPosition, useDynamicContent} from '@growthbase/dynamiccontent';
import {useLinkPropsForRoute, useLinkPropsForRouteFactory} from '../hook/useLinkPropsForRoute';
import {useResolveIcon} from '../hook/useResolveIcon';
import {MenuItem} from '../Schema/MenuItem';
import {SidenavSubLinkConnected} from './SidenavSubLinkConnected';

export interface SidenavLinkConnectedProps {
    item: MenuItem;
}

const BasicSidenav: FC<SidenavLinkConnectedProps> = ({item: {children, path, route, label, active, id}}) => {
    const subNavContent = children.map((item, key) => <SidenavSubLinkConnected withLines item={item} key={key} />);
    const factory = useLinkPropsForRouteFactory();
    const badges = useDynamicContent({
        position: DynamicPosition.sidenavBadge,
        route: route?.name,
    });
    const nestedRoutes = useMemo(
        () =>
            children
                .filter((item) => item.type === 'link')
                .map((item) => {
                    if (item.type !== 'link') {
                        throw new Error('Only links are supported');
                    }
                    return factory(item.path, item.route);
                }),
        [children, factory]
    );
    return (
        <SidenavLink
            {...useLinkPropsForRoute(path, route)}
            Icon={useResolveIcon({
                label,
                name: route?.name,
            })}
            nestedRoutes={nestedRoutes}
            id={id}
            count={badges ?? <CounterButton count={0} />}
            subnavContent={children.length ? subNavContent : null}
            active={active}
        >
            {label}
        </SidenavLink>
    );
};

export const SidenavLinkConnected: FC<SidenavLinkConnectedProps> = ({item}) => <BasicSidenav item={item} />;
