import {Columns, TextFieldComponent, useCreateStrictForm, useTableFormFooter} from '@growthbase/design-components';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {TaakTabelRow} from '../SortableTabel';

export interface FooterOptions<T extends TaakTabelRow = TaakTabelRow> {
    onSubmit: (values: string) => Promise<void>;
    columns: Columns<T>;
}

const createCreateValue = (): {titel: string} => ({titel: ''});
const useCreateSchema = () => yup.object({titel: yup.string().required()});

export function useFooterForm<T extends TaakTabelRow = TaakTabelRow>({onSubmit, columns}: FooterOptions<T>) {
    const {t} = useTranslation('spa_form.nl');

    const {
        Form,
        Fields: {TitelField},
        formik,
        formProps,
    } = useCreateStrictForm<{titel: string}>({
        onSubmit: useCallback((values) => onSubmit(values.titel), [onSubmit]),
        createInitialValuesAfterSubmit: true,
        createInitialValues: createCreateValue,
        schema: useCreateSchema(),
        doNoWaitForSubmit: true,
    });

    return useTableFormFooter({
        columns,
        formik,
        fields: {
            titel: (
                <Form {...formProps}>
                    <TitelField
                        hideWarningOnFocusLost
                        enabledDuringSubmit
                        disabled={false}
                        autoFocus
                        component={TextFieldComponent}
                    />
                </Form>
            ),
        },
        addText: t('taak.add'),
    });
}
