import * as React from 'react';
import {FC} from 'react';
import {HealthInterface} from '../Health';

export interface VersionsMetadata {
    versions: Record<string, string>;
}

export interface VersionsContentProps {
    value: HealthInterface<VersionsMetadata>;
}

export const VersionsContent: FC<VersionsContentProps> = ({
    value: {
        metadata: {versions},
        name,
    },
}) => (
    <div>
        <div>{name} versions</div>
        <ol>
            {Object.entries(versions).map(([n, version]) => (
                <li key={n}>{`${n}: ${version}`}</li>
            ))}
        </ol>
    </div>
);
