import {ThemeOptions} from '@mui/material/styles/createTheme';

export const TextField: ThemeOptions['components'] = {
    MuiTextField: {
        defaultProps: {
            variant: 'filled',
            fullWidth: true,
        },
    },
    MuiFilledInput: {
        defaultProps: {
            disableUnderline: true,
        },
        styleOverrides: {
            root: {
                backgroundColor: 'var(--formInputBackground)',
                border: 'var(--formInputBorderWidth) solid var(--formBorderColor)',
                '&.Mui-focused:not(.Mui-disabled)': {
                    backgroundColor: 'var(--formInputBackground)',
                },
                '&.Mui-disabled': {
                    backgroundColor: 'var(--formInputDisabledBackground)',
                },
                '&.Mui-error': {
                    borderColor: 'var(--formBorderColorError)',
                },
                '&:hover:not(.Mui-disabled)': {
                    backgroundColor: 'var(--formInputBackground)',
                },
            },
        },
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                background: 'var(--formInputBackground)',
                '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--formBorderColor)',
                },
            },
        },
    },
};
