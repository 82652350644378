import {useEventPopupQuery} from '@growthbase/planning';
import {Buttons, EventPopup, EventPopupData, EventPopupTarget, HtmlLink, IconSize} from '@growthbase/design-components';
import {useCallbackRef} from '@growthbase/spa';
import {useRoutes} from '@growthbase/routing';
import {generatePath} from 'react-router';
import {TaakLinkConnected} from '@growthbase/clientbox-uren';
import {VoltooiButtonConnected} from './VoltooiButton/VoltooiButtonConnected';
import {useEventTitle} from './useEventTitle';

export interface EventPopupConnectedProps {
    target: EventPopupTarget;
    onClose: () => void;
    paddingLeft?: boolean;
}

export const EventPopupConnected = ({target, onClose, paddingLeft}: EventPopupConnectedProps) => {
    interface renderOrderLinkProps {
        id: string;
        onderwerp: string;
        nummer: string;
    }

    interface renderTicketLinkProps {
        id: string;
        code: string;
        onderwerp: string;
    }

    interface renderRelationLinkProps {
        id: string;
        naam: string;
    }

    const {data, loading, refetch} = useEventPopupQuery({
        variables: {
            eventId: target.externalEventId.replace('e-', ''),
        },
    });

    const title = useEventTitle(data?.Evenement ?? null);
    const routes = useRoutes();

    const handleOnChange = useCallbackRef(() => {
        refetch();
    });

    const renderRelationLink = useCallbackRef(({id, naam}: renderRelationLinkProps) => (
        <HtmlLink color="primary" href={generatePath(routes.relation.read, {id, name: naam})}>
            {naam}
        </HtmlLink>
    ));

    const renderOrderLink = useCallbackRef(({id, onderwerp, nummer}: renderOrderLinkProps) => (
        <HtmlLink color="primary" href={generatePath(routes.order.read, {order: id, name: nummer})}>
            {nummer} - {onderwerp}
        </HtmlLink>
    ));

    const renderTicketLink = useCallbackRef(({id, code, onderwerp}: renderTicketLinkProps) => (
        <HtmlLink color="primary" href={generatePath(routes.ticket.read, {ticket: id, name: code})}>
            {code} - {onderwerp}
        </HtmlLink>
    ));

    const renderButtons = useCallbackRef((id: string) => (
        <>
            <Buttons.EditPencil
                square
                size="medium"
                iconSize={IconSize.SIZE_3}
                onClick={() => {
                    if (!data?.Evenement) {
                        return;
                    }
                    document.dispatchEvent(
                        new CustomEvent('planningEventOpenModal', {
                            detail: {
                                csrfToken: data.Evenement.csrfToken,
                                resourceId: data.Evenement.agenda.id,
                                id: data.Evenement.id,
                            },
                        })
                    );
                    onClose();
                }}
            />
            <Buttons.Trash
                square
                size="medium"
                iconSize={IconSize.SIZE_3}
                onClick={() => {
                    if (!data?.Evenement) {
                        return;
                    }
                    document.dispatchEvent(
                        new CustomEvent('planningEventDelete', {
                            detail: {
                                csrfToken: data.Evenement.csrfToken,
                                resourceId: data.Evenement.agenda.id,
                                id: data.Evenement.id,
                            },
                        })
                    );
                    onClose();
                }}
            />
            {data?.Evenement && (
                <VoltooiButtonConnected fragment={data.Evenement} title={title} onChange={handleOnChange} />
            )}
        </>
    ));

    if (!data?.Evenement) {
        return (
            <EventPopup
                loading={loading}
                reference={target.eventDomElement}
                onClose={onClose}
                paddingLeft={paddingLeft}
            />
        );
    }

    const event = data.Evenement;

    const relation = event.relatie;
    const order = event.opdracht;
    const {ticket, dienst, taak} = event;
    const dienstLabel = dienst ? `${dienst.code} - ${dienst.titel}` : undefined;

    const parseData: EventPopupData = {
        title,
        start: event.start,
        eind: event.eind,
        timeCodeLabel: dienstLabel,
        agendaLabel: event.agenda.name,
    };

    return (
        <EventPopup
            loading={loading}
            data={parseData}
            reference={target.eventDomElement}
            onClose={onClose}
            relationLinkContent={relation ? renderRelationLink(relation) : undefined}
            orderLinkContent={order ? renderOrderLink(order) : undefined}
            ticketLinkContent={ticket ? renderTicketLink(ticket) : undefined}
            buttonContent={renderButtons(data?.Evenement?.id || '')}
            paddingLeft={paddingLeft}
            taakContent={taak && <TaakLinkConnected id={taak} />}
        />
    );
};
