import type {ExtractWarningFromResponseService} from '@growthbase/spa';
import {Logger} from 'ts-log';

import {ApolloLink} from '@apollo/client';
import {HttpLink} from '@apollo/client/link/http';
import {onError} from '@apollo/client/link/error';

export interface ApolloLinkDependencies {
    logger: Logger;
    graphqlRoute: string;
    locale: string;
    extractWarningFromResponseService: ExtractWarningFromResponseService;
}

export const createApolloLink = ({
    locale,
    logger,
    extractWarningFromResponseService,
    graphqlRoute,
}: ApolloLinkDependencies): ApolloLink => {
    const customFetch: WindowOrWorkerGlobalScope['fetch'] = (uri: RequestInfo, options) => {
        if (typeof uri === 'string') {
            const url = new URL(uri);
            const headers = (options?.headers ?? {}) as {
                operationname?: string;
            };
            url.searchParams.set('operationName', headers.operationname ?? '');
            delete headers.operationname;
            uri = url.toString();
        }
        return fetch(uri, {
            ...options,
        });
    };

    const httpLink = new HttpLink({
        uri: `${graphqlRoute}?${new URLSearchParams({
            AcceptLanguage: locale,
        })}`,
        fetch: customFetch,
        credentials: 'include',

        fetchOptions: {
            credentials: 'include',
            headers: {
                'Accept-Language': locale,
                'Content-Type': 'application/json',
            },
        },
    });

    // Log any GraphQL errors or network error that occurred
    const errorLink = onError(({graphQLErrors, networkError, operation}) => {
        if (graphQLErrors) {
            graphQLErrors.forEach(({message, locations, path}) => {
                logger.error(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path} OperationName: ${operation.operationName}`
                );
                extractWarningFromResponseService.error(message);
            });
        }

        if (networkError) {
            logger.warn(`[Network error]: ${networkError}`);
        }
    });

    const authLink = new ApolloLink((operation, forward) => {
        operation.setContext(() => ({
            headers: {
                operationname: operation.operationName,
            },
        }));
        return forward(operation);
    });

    return errorLink.concat(authLink).concat(httpLink);
};
