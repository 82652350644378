import {BordenOnderdeelVan} from '@growthbase/takenlijst/bord';
import {useMemo} from 'react';
import {normalizeRelatieValues} from '@growthbase/relaties';
import {KoppelbaarItemType} from '@growthbase/taken';
import {IAbonnementVanBordenPaginaFragment} from '@growthbase/graphql';
import {AbonnementLegacyRoutes, AbonnementRoutes} from '@growthbase/abonnementen-abonnementen';

export const useResolveOnderdeelVan = (
    abonnementUuid: string,
    abonnement?: IAbonnementVanBordenPaginaFragment | null
): BordenOnderdeelVan | null => {
    const factory = AbonnementRoutes.bord.useUrlFactory();
    const viewLegacyOrder = AbonnementLegacyRoutes.read.useUrlFactory();

    return useMemo((): BordenOnderdeelVan | null => {
        const relatie = normalizeRelatieValues(abonnement?.relatieverwijzing?.relatieverwijzing ?? null);
        if (!abonnement || !relatie) {
            return null;
        }
        return {
            id: abonnementUuid,
            label: abonnement.titel ?? '-',
            type: KoppelbaarItemType.Abonnement,
            relatie,
            href: viewLegacyOrder({
                id: abonnement.externalId ?? '-',
                name: abonnement.titel,
            }),
            route: ({onderdeelVan, id, naam}) => {
                const node = onderdeelVan?.node;
                if (!node) {
                    throw new Error('Logic exception.');
                }
                return factory({
                    abonnementId: node.externalId ?? '-',
                    abonnementnummer: node.titel,
                    abonnementUuid: node.id,
                    bordUuid: id,
                });
            },
        };
    }, [factory, abonnement, abonnementUuid, viewLegacyOrder]);
};
