import {ActiviteitenListConnected} from '@growthbase/activiteiten';
import {FC} from 'react';

export interface TaakActiviteitenConnectedProps {
    id: string;
}

export const TaakActiviteitenConnected: FC<TaakActiviteitenConnectedProps> = ({id}) => (
    <ActiviteitenListConnected id={id} />
);
