import {SwimlaneHeader} from '@growthbase/design-components';
import {FC, HTMLAttributes, ReactNode} from 'react';

export interface HeaderProps {
    titel: string;
    anchor?: HTMLAttributes<HTMLElement> | undefined;
    menu?: ReactNode;
}

export const Header: FC<HeaderProps> = ({titel, anchor, menu}) => (
    <SwimlaneHeader menu={menu} titel={titel} anchor={anchor} />
);
