import moment from 'moment';
import {useCallback} from 'react';
import {DatePickerValue} from '../types/DatePickerValue';

export const useOnDayChange = (
    value: DatePickerValue,
    onChange: (changedValue: DatePickerValue) => void,
    initialValue: DatePickerValue
) =>
    useCallback(
        ({value: newValue}: {value: string | Date | null | unknown}) => {
            if (newValue === null) {
                onChange(null);
            } else if (typeof newValue === 'string' || newValue instanceof Date) {
                const time = moment(value?.date ?? initialValue?.date);
                onChange({
                    date: moment(newValue)
                        .minutes(time.minutes())
                        .hours(time.hours())
                        .seconds(time.seconds())
                        .toDate()
                        .toISOString(),
                    timeUsed: value?.timeUsed ?? false,
                });
            }
        },
        [value, onChange, initialValue]
    );
