import React, {KeyboardEventHandler} from 'react';

import classNames from 'classnames/bind';
import {ActiveItemData, ActiveItemSource, ActiveItemType, useTraversableItemWithRef} from '@growthbase/keys';
import {useCallbackRef} from '@growthbase/spa';
import styles from './PopupResultsItem.module.scss';
import {BaseButtonProps} from '../../../Button';

const cx = classNames.bind(styles);

export interface PopupResultsItemProps extends BaseButtonProps {
    data: ActiveItemData;
    marginBottom?: boolean;
    marginTop?: boolean;
    asListItem?: boolean;
}

export function PopupResultsItem({
    disabled,
    children,
    onClick,
    readonly,
    testId,
    ariaLabel,
    loading,
    data,
    asListItem = false,
    marginBottom,
    marginTop,
}: PopupResultsItemProps) {
    const onKeyPressed: KeyboardEventHandler<HTMLElement> = useCallbackRef((e) => {
        if (e.key === 'Enter' && onClick && !disabled && !readonly) {
            onClick();
        }
    });
    const {isActive, ref} = useTraversableItemWithRef<HTMLDivElement>({
        data,
        disabled: disabled || readonly,
        type: ActiveItemType.Unknown,
        source: ActiveItemSource.PopupResultItem,
        onEnter: disabled || readonly ? undefined : onClick,
    });
    return (
        // eslint-disable-next-line jsx-a11y/interactive-supports-focus
        <div
            ref={ref}
            aria-label={ariaLabel ?? undefined}
            data-testid={testId}
            className={cx('popupResultsItem', {
                'popupResultsItem--disabled': disabled,
                'popupResultsItem--readonly': readonly,
                'popupResultsItem--focus': !!isActive,
                'popupResultsItem--loading': loading,
                'popupResultsItem--listItem': asListItem,
                'popupResultsItem--marginBottom': marginBottom,
                'popupResultsItem--marginTop': marginTop,
            })}
            role="button"
            onKeyDown={onKeyPressed}
            onClick={disabled || readonly ? undefined : onClick}
        >
            {children}
        </div>
    );
}

export default PopupResultsItem;
