import React from 'react';
import {ArrayValue} from '../../../types';
import {InstellingViewProps} from './InstellingViewWrapper';
import {KeyValuePair} from './NestedAsCardInstellingView';

export const ArrayInstellingView = (props: InstellingViewProps) => {
    const {label, value, name, options} = props as InstellingViewProps<ArrayValue>;

    if (typeof value !== 'number' && typeof value !== 'string') {
        throw new Error(`Invalid value ${value} for field ${name}`);
    }
    const selectedOptions = options[value];
    return <KeyValuePair label={label}>{selectedOptions}</KeyValuePair>;
};
