import React, {ReactNode} from 'react';
import styles from './PageBody.module.scss';

export interface PageBodyProps {
    children: ReactNode;
    className?: string;
    negative?: boolean;
}

export const PageBody = ({children, negative, className}: PageBodyProps) => (
    <>
        <div className={`${styles.PageBody} ${negative} ${className ?? ''} pageBody`}>{children}</div>
    </>
);
