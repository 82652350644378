import {ReferenceType} from '@floating-ui/react-dom-interactions/src/types';
import {memo, ReactNode, useRef, FC, useMemo} from 'react';
import {Keys, KeysProvider, useClickOutside, useInteractionAllowed} from '@growthbase/keys';
import classNames from 'classnames/bind';
import {
    useFloating,
    flip,
    offset,
    arrow as floatingArrow,
    shift,
    autoUpdate,
    Placement,
} from '@floating-ui/react-dom-interactions';
import {capitalize} from 'lodash';
import {autoPlacement, FloatingContext} from '@floating-ui/react';
import {useCallbackRef} from '@growthbase/spa';
import {useIsMobile} from '@growthbase/design-components';
import {DebugRenderCounter} from '../../DebugRender/DebugRenderCounter';
import {PopupControls} from '../PopupControls';

import styles from './Popup.module.scss';
import './Popup.scss';
import {BaseButtonProps} from '../../Button';
import {usePopup} from '../Hook';
import {PopupPortal} from '../PopupPortal';

const cx = classNames.bind(styles);

export interface PopupAnchorProps<RT extends ReferenceType = ReferenceType> {
    reference: (node: RT | null) => void;
    controls: PopupControls;
}

export interface PopupProps<RT extends ReferenceType = ReferenceType> extends BaseButtonProps {
    testId?: string;
    renderAnchor?: (props: PopupAnchorProps<RT>) => ReactNode;
    children?: ReactNode;
    disablePortal?: boolean;
    arrow?: boolean;
    allowedPlacements?: Placement[];
}

function InteractionWrapper({context, controls}: {controls: PopupControls; context: FloatingContext<ReferenceType>}) {
    const allReffs = useMemo(() => {
        const refs = [];
        if (context.refs.floating) {
            refs.push(context.refs.floating);
        }
        if (context.refs.reference) {
            refs.push(context.refs.reference);
        }
        if (context.refs.domReference) {
            refs.push(context.refs.domReference);
        }
        return refs;
    }, [context]);
    useClickOutside(
        controls.isOpen,
        // @ts-expect-error If this is not working, we need to fix the typings
        allReffs,
        useCallbackRef(() => controls.close())
    );
    return null;
}

export const Popup: FC<PopupProps> = memo(
    ({testId, children, renderAnchor, disablePortal, arrow, allowedPlacements}) => {
        const controls = usePopup();

        const arrowRef = useRef<HTMLDivElement>(null);

        const middleware = [
            shift({padding: 20}),
            offset(arrow ? 15 : 10),
            arrow ? floatingArrow({element: arrowRef}) : undefined,
        ];

        middleware.push(allowedPlacements ? autoPlacement({allowedPlacements}) : flip());
        const allowed = useInteractionAllowed();
        const {x, y, strategy, reference, floating, context, middlewareData, placement} = useFloating<ReferenceType>({
            open: controls.isOpen,
            middleware,
            whileElementsMounted: autoUpdate,
            onOpenChange: useCallbackRef((change) => {
                if (!change && !allowed()) {
                    return;
                }
                controls.set(change);
            }),
        });
        const {y: arrowY, x: arrowX} = middlewareData?.arrow || {x: 0, y: 0};

        const Portal = disablePortal ? 'div' : PopupPortal;

        const hasVisibleReference =
            context.refs.reference?.current && context.refs.reference?.current.getBoundingClientRect().width > 0;

        const isMobile = useIsMobile();

        return (
            <>
                {!reference && 'no reference provided for popup.'}
                <DebugRenderCounter>Popup</DebugRenderCounter>
                {renderAnchor && renderAnchor({reference, controls})}
                {controls.isOpen && (
                    <KeysProvider name="Popup">
                        <Keys debug="Popup: close" keys="escape" callback={controls.close} />
                        <InteractionWrapper
                            context={context as unknown as FloatingContext<ReferenceType>}
                            controls={controls}
                        />
                        <Portal>
                            <div
                                ref={floating}
                                className={cx('popup', `popup--placement${capitalize(placement)}`, {
                                    'popup--detached': !hasVisibleReference || isMobile,
                                })}
                                style={{
                                    position: strategy,
                                    top: y ?? 0,
                                    left: x ?? 0,
                                }}
                                data-testid={testId}
                            >
                                {arrow && hasVisibleReference && !isMobile && (
                                    <div
                                        ref={arrowRef}
                                        className={cx('popup__arrow')}
                                        style={{
                                            left: arrowX != null ? `${arrowX}px` : '',
                                            top: arrowY != null ? `${arrowY}px` : '',
                                        }}
                                    />
                                )}
                                {children}
                            </div>
                        </Portal>
                    </KeysProvider>
                )}
            </>
        );
    }
);

Popup.displayName = 'Popup';
