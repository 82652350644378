import {
    CreateFieldFormOptions,
    SingleLineOpgemaakteTekstInput,
    useCreateStrictForm,
} from '@growthbase/design-components';
import {IRelatieverwijzingInput} from '@growthbase/graphql';
import {RelatieComboboxQuery, relatieIsEmpty} from '@growthbase/relaties';
import {useFormikContext} from 'formik';
import React, {ReactNode, useCallback, useMemo} from 'react';
import {EditFormDTO, useEditSchema} from './useEditSchema';
import {useStripOnderdeelVanNaam} from './useStripOnderdeelVanNaam';
import {useStripRelatieFromNaam} from './useStripRelatieFromNaam';
import {useStripDeelnemersFromNaam} from './useStripDeelnemersFromNaam';

export interface UseEditFormOptions
    extends Pick<
        CreateFieldFormOptions<EditFormDTO>,
        'autoSubmit' | 'doNoWaitForSubmit' | 'createInitialValuesAfterSubmit'
    > {
    onEdit: (values: EditFormDTO) => Promise<void>;
    createInitialValues: () => EditFormDTO;
}

const StripTitleField = () => {
    const formik = useFormikContext<EditFormDTO>();
    useStripOnderdeelVanNaam({formik});
    useStripRelatieFromNaam({formik});
    useStripDeelnemersFromNaam({formik});
    return null;
};

export const useEditForm = ({onEdit, createInitialValues, autoSubmit = false, ...rest}: UseEditFormOptions) => {
    const results = useCreateStrictForm<EditFormDTO>({
        onSubmit: useCallback((values) => onEdit(values), [onEdit]),
        autoSubmit,
        schema: useEditSchema(),
        enableLockPopupAndRoutingWhenDirty: false,
        createInitialValuesAfterSubmit: true,
        createInitialValues,
        ...rest,
    });
    const Naam = results.Fields.NaamField.useComponent(SingleLineOpgemaakteTekstInput);

    const relatieValue = results.formik.values.relatie;
    const relatie = useMemo(
        (): IRelatieverwijzingInput | null =>
            relatieIsEmpty(relatieValue)
                ? null
                : {
                      persoonId: relatieValue.persoon?.id,
                      bedrijfId: relatieValue.bedrijf?.id,
                  },
        [relatieValue]
    );

    const renderNaam: typeof Naam = (props) => <Naam {...props} nested={<StripTitleField />} />;

    const render = (children: ReactNode) => (
        <RelatieComboboxQuery
            value={relatie}
            onChange={(newRelatie) => {
                results.formik.setFieldValue('relatie', newRelatie);
            }}
        >
            {children}
        </RelatieComboboxQuery>
    );

    return {...results, render, renderNaam};
};
