import {Diff} from 'utility-types';
import * as yup from 'yup';
import {ReactNode, useCallback, useMemo, FC} from 'react';
import {SchemaOf} from 'yup';
import {SingleFieldForm} from './SingleFieldForm';
import {FormikFieldProps} from '../FieldWrapper';

export interface SingleValueDTO<TValue> {
    value: TValue;
}

export interface SingleValueFieldFormProps<TSchema, TComponent extends object> {
    createInitialValues: () => TSchema;
    onSubmit: (value: TSchema) => Promise<void>;
    schema: SchemaOf<TSchema>;
    testfieldName?: string;
    label?: ReactNode;
    placeholder?: string | null;
    autoFocus?: boolean;
    debug?: boolean;
    /**
     * Disable when there is no data.
     */
    disabled: boolean;
    readonly?: boolean;
    disableAutoSubmit?: boolean;
    doNoWaitForSubmit?: boolean;
    createInitialValuesAfterSubmit?: boolean;
    disableInlineEditable?: boolean;
    component?: FC<TComponent>;
    extraFieldProps?: Partial<Diff<TComponent, FormikFieldProps<TSchema>>>;
}

export function SingleValueFieldForm<TSchema, TComponent extends object>({
    onSubmit,
    createInitialValues,
    schema,
    disableAutoSubmit = false,
    ...props
}: SingleValueFieldFormProps<TSchema, TComponent>) {
    type Schema = SingleValueDTO<TSchema>;
    return (
        <SingleFieldForm<Schema, 'value', TComponent>
            autoSubmit={!disableAutoSubmit}
            controlsPosition="bottom"
            schema={useMemo(
                () =>
                    yup
                        .object({
                            value: schema,
                        })
                        .required(),
                [schema]
            )}
            onSubmit={useCallback(({value}) => onSubmit(value), [onSubmit])}
            createInitialValues={useCallback(() => ({value: createInitialValues()}), [createInitialValues])}
            name="value"
            {...props}
        />
    );
}
