import {PopupHeader, PopupHeaderProps, Stack, Tab, TabsModal} from '@growthbase/design-components';
import React, {FC, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {RenderOmschrijvingField, RenderTitelField} from './Components';
import {TaaktemplateRoutes} from '../TaaktemplateRoutes';

export interface EditFormProps {
    menu: React.ReactNode;
    taaktemplateId: string;
    renderTitelField: RenderTitelField;
    readonly?: boolean;
    renderOmschrijvingField: RenderOmschrijvingField;
}

export const EditForm: FC<EditFormProps> = ({
    renderTitelField,
    menu,
    taaktemplateId,
    readonly,
    renderOmschrijvingField,
}) => {
    const {t} = useTranslation('spa_form.nl');

    return (
        <TabsModal
            renderPopupHeader={useCallback(
                (props: PopupHeaderProps) => (
                    <PopupHeader buttons={[menu]} {...props} />
                ),
                [menu]
            )}
            testId="TaakEditModal"
        >
            <Tab title={t('details')} to={TaaktemplateRoutes.bewerken.useUrl({taaktemplateId})}>
                <Stack gap="large">
                    {renderTitelField({readonly})}
                    {renderOmschrijvingField({readonly})}
                </Stack>
            </Tab>
        </TabsModal>
    );
};
