import {
    ITemplateOntkoppelenMenuButtonConnectedFragment,
    useTemplateOntkoppelenMenuButtonMutation,
} from '@growthbase/graphql';
import {idFromIri} from '@growthbase/routing';
import {FC} from 'react';
import {v4} from 'uuid';
import {TemplateOntkoppelenMenuButton} from './TemplateOntkoppelenMenuButton';

export interface TemplateOntkoppelenMenuButtonConnectedProps {
    bordtemplateId: string;
    fragment: ITemplateOntkoppelenMenuButtonConnectedFragment;
}

export const TemplateOntkoppelenMenuButtonConnected: FC<TemplateOntkoppelenMenuButtonConnectedProps> = ({
    bordtemplateId,
    fragment: {naam, id},
}) => {
    const [mutate] = useTemplateOntkoppelenMenuButtonMutation({
        variables: {
            input: {
                newId: v4(),
                bordtemplateId: idFromIri(bordtemplateId),
                id: idFromIri(id),
            },
        },
    });

    return <TemplateOntkoppelenMenuButton lijstTitel={naam} onSubmit={mutate} />;
};
