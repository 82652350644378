import {CommentList, PaginationInfiniteScroll, Scrollable, Stack, useIsMobile} from '@growthbase/design-components';
import {ITaakReactiesQuery, ITaakReactiesReactieFragment} from '@growthbase/graphql';
import {PaginationHelpers} from '@growthbase/spa';
import React, {ReactNode} from 'react';
import classNames from 'classnames/bind';
import {TaakReactieConnected} from '../TaakReactie/TaakReactieConnected';
import {TaakReactieCreateFormConnected} from '../TaakReactieCreateForm/TaakReactieCreateFormConnected';
import {TaakReactiesMobile} from './TaakReactiesMobile';

import styles from './TaakReacties.module.scss';

const cx = classNames.bind(styles);

export interface TaakReactiesProps {
    taakId: string;
    fragment?: ITaakReactiesQuery;
    readonly?: boolean;
    pagination: PaginationHelpers<ITaakReactiesReactieFragment>;
    Reactie: typeof TaakReactieConnected;
    CreateForm: typeof TaakReactieCreateFormConnected;
    aangemaaktDoor: ReactNode;
}

export const TaakReacties = ({
    taakId,
    readonly,
    pagination,
    Reactie,
    CreateForm,
    fragment,
    aangemaaktDoor,
}: TaakReactiesProps) => {
    const isMobile = useIsMobile();
    const className = cx('taakReacties');

    if (isMobile) {
        return (
            <TaakReactiesMobile
                taakId={taakId}
                readonly={readonly}
                aangemaaktDoor={aangemaaktDoor}
                Reactie={Reactie}
                CreateForm={CreateForm}
                fragment={fragment}
                pagination={pagination}
            />
        );
    }
    const nodes = pagination.connections.map((node) => <Reactie fragment={node} key={node.id} readonly={readonly} />);

    return (
        <div className={className} data-testid="TakenReacties" style={{height: '100%'}}>
            <Stack>
                <Stack.Item grow>
                    <Scrollable fillParent inverse>
                        <PaginationInfiniteScroll<ITaakReactiesReactieFragment> helpers={pagination} inverse>
                            <CommentList inverse>
                                {nodes}
                                {aangemaaktDoor}
                            </CommentList>
                        </PaginationInfiniteScroll>
                    </Scrollable>
                </Stack.Item>
                <Stack.Item id="TaakReactieFormBottom">
                    {!readonly && fragment?.ingelogdeGebruiker && (
                        <CreateForm fragment={fragment?.ingelogdeGebruiker} taakId={taakId} />
                    )}
                </Stack.Item>
            </Stack>
        </div>
    );
};
