import {useCallback, useEffect, useMemo} from 'react';
import {QueryResult} from '@apollo/client/react/types/types';
import {useCallbackRef} from '@growthbase/spa';
import {mergeDeep} from '@apollo/client/utilities';
import {interval, mergeMap, noop, filter, catchError} from 'rxjs';
import {PaginationHelpers} from '../Value';

// eslint-disable-next-line no-shadow
enum SCROLL_DIRECTION {
    After,
    Before,
}

export const DefaultCursor = ({id}: {id: string}) => id;

export interface PaginationQueryParams<TData, TNode extends {id: string}, TQueryParams extends object> {
    direction?: SCROLL_DIRECTION;
    query: QueryResult<TData, TQueryParams>;
    connectionsMapper: (input: TData) => TNode[];
    fetchAfter: (info: {first: string; last: string}) => Partial<TQueryParams>;
    mergeAfter: (previousResult: TData, fetchMoreResult: TData) => TData;
    fetchBefore: (info: {first: string; last: string}) => Partial<TQueryParams>;
    mergeBefore: (previousResult: TData, fetchMoreResult: TData) => TData;
    intervalMs?: number | boolean;
    fetchCursor?: (node: TNode) => string;
}

export const useQueryPagination = <TData, TQueryParams extends object, TNode extends {id: string}>({
    intervalMs = 2000,
    direction = SCROLL_DIRECTION.After,
    connectionsMapper,
    query: {data, loading, fetchMore, variables},
    mergeAfter,
    fetchAfter,
    fetchBefore,
    mergeBefore,
    fetchCursor = DefaultCursor,
}: PaginationQueryParams<TData, TNode, TQueryParams>): PaginationHelpers<TNode> => {
    connectionsMapper = useCallbackRef(connectionsMapper);
    const connections = useMemo(() => (data ? connectionsMapper(data) : []), [data, connectionsMapper]);
    const merge = direction === SCROLL_DIRECTION.After ? mergeAfter : mergeBefore;
    const mergeInterval = direction === SCROLL_DIRECTION.Before ? mergeAfter : mergeBefore;
    const fetch = direction === SCROLL_DIRECTION.After ? fetchAfter : fetchBefore;
    const fetchInterval = direction === SCROLL_DIRECTION.Before ? fetchAfter : fetchBefore;

    useEffect(() => {
        if (typeof intervalMs !== 'number') {
            return noop;
        }
        let fetching = false;
        const subscription = interval(intervalMs)
            .pipe(
                filter(() => !fetching),
                mergeMap(async () => {
                    const moreVariables = connections.length
                        ? fetchInterval({
                              last: fetchCursor(connections[connections.length - 1]),
                              first: fetchCursor(connections[0]),
                          })
                        : {};
                    const merged = mergeDeep(variables, moreVariables);
                    return fetchMore({
                        variables: merged,
                        updateQuery: (previousResult, {fetchMoreResult}) => {
                            if (!fetchMoreResult) {
                                return previousResult;
                            }
                            return mergeInterval(previousResult, fetchMoreResult);
                        },
                    }).finally(() => {
                        fetching = false;
                    });
                }),
                // Catch errors to prevent the interval from stopping.
                catchError(() => [])
            )
            .subscribe();

        return () => subscription.unsubscribe();
    }, [
        connections,
        fetchCursor,
        fetchInterval,
        fetchMore,
        intervalMs,
        mergeInterval,
        variables,
    ]);

    return {
        // Required for inf scroll, otherwise it will not work.
        totalCount: connections.length + 1000,
        connections,
        loadMore: useCallback(async () => {
            const moreVariables = fetch({
                last: fetchCursor(connections[connections.length - 1]),
                first: fetchCursor(connections[0]),
            });
            const merged = mergeDeep(variables, moreVariables);
            await fetchMore({
                variables: merged,
                updateQuery: (previousResult, {fetchMoreResult}) => {
                    if (!fetchMoreResult) {
                        return previousResult;
                    }
                    return merge(previousResult, fetchMoreResult);
                },
            });
        }, [
            connections,
            fetch,
            fetchCursor,
            fetchMore,
            merge,
            variables,
        ]),
        hasMore: true,
        isLoading: loading,
    };
};
