import {FC} from 'react';
import {useGebruikerHeeftToegang, usePopup} from '@growthbase/design-components';
import {useBordTemplateEditFormQuery, useEditTemplateBordFormMutation} from '@growthbase/graphql';
import {useCallbackRef} from '@growthbase/spa';
import {EditForm} from './EditForm';
import {mapDTOToGraphQL} from './mapDTOToGraphQL';
import {EditFormDTO} from './Hook/useEditSchema';
import {ModelHeaderConnected} from './Components';

export interface EditFormConnectedProps {
    bordId: string;
}

export const EditFormConnected: FC<EditFormConnectedProps> = ({bordId}) => {
    const {data} = useBordTemplateEditFormQuery({
        variables: {
            id: bordId,
        },
    });

    const fragment = data?.bordtemplate ?? null;

    const [mutateAsync] = useEditTemplateBordFormMutation();
    const popup = usePopup();
    const onSubmit = useCallbackRef((value: EditFormDTO) =>
        mutateAsync({
            variables: {
                input: mapDTOToGraphQL(bordId, value),
            },
        })
            .then(popup.close)
            .then()
    );

    const toegang = useGebruikerHeeftToegang({
        toegang: true,
    });

    const initialValues = useCallbackRef((): EditFormDTO => {
        if (!fragment) {
            throw new Error('Fragment is null');
        }
        return {
            naam: {
                elements: [
                    {
                        type: 'p',
                        children: [
                            {
                                text: fragment.naam,
                            },
                        ],
                    },
                ],
            },
        };
    });
    if (!toegang || !fragment) {
        return null;
    }
    return (
        <EditForm
            renderHeader={() => fragment && <ModelHeaderConnected fragment={fragment} />}
            createInitialValues={initialValues}
            onEdit={onSubmit}
            KanNaamWijzigen
        />
    );
};
