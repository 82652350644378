import * as React from 'react';
import {FC} from 'react';
import {HealthInterface} from '../Health';

export interface SymfonyKernelMetadata {
    host: string;
    environment: string;
    debugEnabled: boolean;
}

export interface SymfonyKernelContentProps {
    value: HealthInterface<SymfonyKernelMetadata>;
}

export const SymfonyKernelContent: FC<SymfonyKernelContentProps> = ({value}) => {
    const metadata = value.metadata as SymfonyKernelMetadata;
    return (
        <div>
            <div>Host: {metadata.host}</div>
            <div>Environment: {metadata.environment}</div>
            <div>Debug enabled: {metadata.debugEnabled ? 'Ja' : 'Nee'}</div>
        </div>
    );
};
