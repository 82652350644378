import {Buttons, CommentList, PaginationInfiniteScroll, Scrollable, Stack} from '@growthbase/design-components';
import {ITaakReactiesReactieFragment} from '@growthbase/graphql';
import React from 'react';
import classNames from 'classnames/bind';
import {useTranslation} from 'react-i18next';
import {TaakReactiesProps} from './TaakReacties';
import styles from './TaakReacties.module.scss';

const cx = classNames.bind(styles);

export const TaakReactiesMobile = ({
    taakId,
    readonly,
    pagination,
    CreateForm,
    fragment,
    Reactie,
    aangemaaktDoor,
}: TaakReactiesProps) => {
    const nodes = pagination.connections.map((node) => <Reactie fragment={node} key={node.id} readonly={readonly} />);
    const [visible, setVisible] = React.useState(false);
    const {t} = useTranslation('Taken.nl');

    const className = cx('taakReacties', {
        'taakReacties--mobile': true,
        'taakReacties--visible': visible,
    });

    return (
        <div className={className} data-testid="TakenReactiesMobile">
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div className={cx('taakReacties__header')} onClick={() => setVisible(!visible)}>
                <span>{t('TaakReactie.reacties')}</span>
                <Buttons.ChevronDown />
            </div>

            {visible && (
                <Stack>
                    <Stack.Item grow>
                        <Scrollable fillParent inverse>
                            <PaginationInfiniteScroll<ITaakReactiesReactieFragment> helpers={pagination} inverse>
                                <CommentList inverse>
                                    {nodes}
                                    {aangemaaktDoor}
                                </CommentList>
                            </PaginationInfiniteScroll>
                        </Scrollable>
                    </Stack.Item>
                    <Stack.Item id="TaakReactieFormBottom">
                        {!readonly && fragment?.ingelogdeGebruiker && (
                            <CreateForm fragment={fragment?.ingelogdeGebruiker} taakId={taakId} />
                        )}
                    </Stack.Item>
                </Stack>
            )}
        </div>
    );
};
