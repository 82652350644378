import {QueryResult} from '@apollo/client/react/types/types';
import {
    determineActionTypeWhenSearching,
    determineActionForList,
    useUpdateQueryCacheFromExternalEntityChanges,
    determineActionFilterForTypes,
    useConvertFetchEntity,
} from '@growthbase/cache';
import {
    ILijsttemplateTemplatetabelFragment,
    ILijsttemplateTemplatetabelQuery,
    ILijsttemplateTemplatetabelQueryVariables,
    refetchLijsttemplateTemplatetabelSingleQuery,
} from '@growthbase/graphql';

export interface EntityChangesParams {
    lijsttemplateId?: string | null;
    searchQuery?: string | null;
    result: QueryResult<ILijsttemplateTemplatetabelQuery, ILijsttemplateTemplatetabelQueryVariables>;
}

export const useEntityChanges = ({result, searchQuery, lijsttemplateId}: EntityChangesParams) => {
    useUpdateQueryCacheFromExternalEntityChanges<ILijsttemplateTemplatetabelFragment>({
        // @ts-expect-error Fix variables...
        result,
        determineAction: (params, defaultAction) => {
            let action = determineActionFilterForTypes(defaultAction, params, 'Taaktemplate');
            action = determineActionForList(action, params, lijsttemplateId);
            return determineActionTypeWhenSearching(action, params, !!searchQuery);
        },
        fetchEntity: useConvertFetchEntity(refetchLijsttemplateTemplatetabelSingleQuery),
    });
};
