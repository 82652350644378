import React, {ReactNode, useRef} from 'react';
import {Scrollbars} from 'react-custom-scrollbars-2';
import classNames from 'classnames/bind';
import {CSSProperties} from 'styled-components';
import styles from './Scrollable.module.scss';
import {ScrollContext, useUniqueClassname} from '../PaginationInfiniteScroll';
import {useDraggableScroll} from './useDraggableScroll';

const cx = classNames.bind(styles);

export type ScrollableTheme = 'light' | 'dark';

export interface ScrollableProps {
    children: ReactNode;
    maxHeight?: number;
    fillParent?: boolean;
    theme?: ScrollableTheme;
    inverse?: boolean;
    autoHide?: boolean;
    grabToScroll?: boolean;
    pageMargin?: boolean;
    track?: boolean;
}

export const Scrollable = ({
    children,
    maxHeight = 350,
    fillParent = false,
    theme = 'dark',
    inverse,
    autoHide = true,
    grabToScroll = false,
    pageMargin = false,
    track = false,
}: ScrollableProps) => {
    const classes = cx('scrollable', `scrollable--${theme}`, {
        'scrollable--inversed': inverse,
        'scrollable--pageMargin': pageMargin,
        'scrollable--track': track,
    });
    const ref = useRef<Scrollbars>(null);
    const uniqueClassName = useUniqueClassname();

    const style: CSSProperties = {
        display: 'flex',
        flexFlow: inverse ? 'column-reverse' : 'column',
    };

    const {handleMouseDown, handleMouseUp, handleMouseMove} = useDraggableScroll(ref.current, grabToScroll);

    return (
        <ScrollContext.Provider value={uniqueClassName}>
            <Scrollbars
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
                onMouseOut={handleMouseUp}
                ref={ref}
                className={classes}
                style={{height: fillParent ? '100%' : 'auto'}}
                autoHeight={!fillParent}
                autoHide={autoHide}
                renderThumbVertical={(props) => <div {...props} className={styles.scrollable__thumb} />}
                renderThumbHorizontal={(props) => <div {...props} className={styles.scrollable__thumb} />}
                renderTrackVertical={(props) => <div {...props} className={styles.scrollable__trackVertical} />}
                renderTrackHorizontal={(props) => <div {...props} className={styles.scrollable__trackHorizontal} />}
                autoHeightMax={maxHeight}
                renderView={(props) => (
                    <div {...props} key="scrollbar" className={uniqueClassName} style={{...props.style, ...style}} />
                )}
            >
                {pageMargin && (
                    <div className={cx('scrollable__content')}>
                        <div className={cx('scrollable__spacing')} />
                        {children}
                        <div className={cx('scrollable__spacing')} />
                    </div>
                )}
                {!pageMargin && children}
            </Scrollbars>
        </ScrollContext.Provider>
    );
};

export default Scrollable;
