import {ExternalEntityWithLabel, useExternalLink} from '@growthbase/clientbox';
import React from 'react';
import {ButtonHandler, Buttons, Font, HoverButtons, HtmlLink, useIsMobile} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';

export interface RelatieLinkProps {
    relatie: ExternalEntityWithLabel;
    onEdit: ButtonHandler;
    onRemove: ButtonHandler;

    disabled?: boolean;
    readonly?: boolean;
    inline?: boolean;
    size?: 'small' | 'default';
}

export const RelatieLink = ({relatie, onRemove, size, onEdit, disabled, readonly, inline}: RelatieLinkProps) => {
    const {t} = useTranslation('spa_form.nl');
    const href = useExternalLink(relatie);
    const isMobile = useIsMobile();

    const link = href ? (
        <HtmlLink target="_blank" href={href} size={size} color={readonly ? 'gray' : undefined}>
            {relatie.label}
        </HtmlLink>
    ) : (
        <Font.Gray>{relatie.label}</Font.Gray>
    );

    if (readonly) {
        return link;
    }

    return (
        <HoverButtons
            testId={relatie.label}
            floating={inline}
            buttons={
                <>
                    <Buttons.PencilIconOnly
                        disabled={disabled}
                        onClick={onEdit}
                        ariaLabel={t('edit')}
                        tooltip={t('edit')}
                        size={isMobile ? 'larger' : 'small'}
                        autoHeight
                    />
                    {isMobile && (
                        <Buttons.Cross
                            testId="Verwijderen"
                            disabled={disabled}
                            onClick={onRemove}
                            tooltip={t('item.ontkoppel')}
                            ariaLabel={t('item.ontkoppel')}
                            size="larger"
                            variant="iconOnlyDanger"
                            autoHeight
                        />
                    )}
                    {!isMobile && (
                        <Buttons.CrossCircleIconOnly
                            testId="Verwijderen"
                            disabled={disabled}
                            onClick={onRemove}
                            tooltip={t('item.ontkoppel')}
                            ariaLabel={t('item.ontkoppel')}
                            size="small"
                            variant="iconOnlyDanger"
                            autoHeight
                        />
                    )}
                </>
            }
        >
            {link}
        </HoverButtons>
    );
};
