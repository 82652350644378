import {DNDContainerId} from './DNDContainerData';
import {DNDContainerState} from './DNDContainerState';
import {DNDItemData, DNDItemId} from './DNDItemData';

export type DNDState = {
    active: {
        item: DNDItemData;
        /**
         * The source container of the item.
         */
        sourceContainerId: DNDContainerId;
        /**
         * The container that the item is over and is placed in.
         */
        overContainerId: DNDContainerId;

        saving: boolean;
        itemOverId: null | DNDItemId;
    } | null;
    containers: Record<DNDContainerId, DNDContainerState>;
};

export interface DNDStoreState {
    DND: DNDState;
}

export const initialDraggableStoreState: DNDState = {
    containers: {},
    active: null,
};
