import {PlanningStoreState, usePlanningSetActiveAgenda, useSelectedAgenda} from '@growthbase/planning';
import {CalendarSelectionItem, StyledSelect} from '@growthbase/design-components';
import {useMemo} from 'react';
import {useCallbackRef} from '@growthbase/spa';
import {useSelector} from 'react-redux';

export const MedewerkerSwitch = () => {
    const {agendas} = useSelector((state: PlanningStoreState) => state.planning);
    const setActiveAgenda = usePlanningSetActiveAgenda();
    const selectedAgenda = useSelectedAgenda();

    const calendars: CalendarSelectionItem[] = agendas.flatMap((agenda) => agenda.items).filter((item) => item.enabled);

    const options = useMemo(
        () =>
            calendars.map(({title, id}) => ({
                label: title,
                value: id,
            })),
        [calendars]
    );

    return (
        <StyledSelect<string | null>
            readonly={false}
            disabled={false}
            options={options}
            value={selectedAgenda?.id ?? null}
            onChange={useCallbackRef((option) => {
                const agenda = calendars.find((a) => a.id === option);
                if (agenda) {
                    setActiveAgenda({agenda});
                }
            })}
        />
    );
};
