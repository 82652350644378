import {ElementType, HTMLAttributes, ReactNode} from 'react';
import classNames from 'classnames/bind';
import styles from './Heading.module.scss';

const cx = classNames.bind(styles);

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
    children: ReactNode;
    tag?: ElementType;
    displaySize?: 1 | 2 | 3 | 4;
}

export const Heading = ({children, tag: Tag = 'h2', displaySize = 1}: HeadingProps) => {
    const classes = cx('heading', `heading--size${displaySize}`);
    return <Tag className={classes}>{children}</Tag>;
};

export const Heading1 = ({children, ...props}: HeadingProps) => (
    <Heading tag="h1" displaySize={1} {...props}>
        {children}
    </Heading>
);
export const Heading2 = ({children, ...props}: HeadingProps) => (
    <Heading tag="h2" displaySize={2} {...props}>
        {children}
    </Heading>
);
export const Heading3 = ({children, ...props}: HeadingProps) => (
    <Heading tag="h3" displaySize={3} {...props}>
        {children}
    </Heading>
);
export const Heading4 = ({children, ...props}: HeadingProps) => (
    <Heading tag="h4" displaySize={4} {...props}>
        {children}
    </Heading>
);
