import {ClientMutationIdSchema, OpgemaakteTekstSchema, UuidFieldSchema} from '@growthbase/design-components';
import {IWijzigTaakomschrijvingTaakomschrijvingInput} from '@growthbase/graphql';
import {TFunction} from 'i18next';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {SchemaOf} from 'yup';

export const TaakomschrijvingFieldSchema = (t: TFunction): SchemaOf<IWijzigTaakomschrijvingTaakomschrijvingInput> =>
    yup
        .object({
            clientMutationId: ClientMutationIdSchema(t),
            id: UuidFieldSchema(t),
            opgemaakteTekst: OpgemaakteTekstSchema(t),
        })
        .required();

export const useTaakomschrijvingFieldSchema = () => {
    const {t} = useTranslation();
    return useMemo(() => TaakomschrijvingFieldSchema(t), [t]);
};
