import {idFromIri, RouteElementProps, useHistoryBack} from '@growthbase/routing';
import {
    useReedsToegevoegdeLijsttemplatesQuery,
    useTemplateLijsttemplateAlsLijstToevoegenMutation,
    useTemplateLijsttemplatesQuery,
} from '@growthbase/graphql';
import {LoaderCenter, Modal, ModalContent, PopupHeader, RoutePopupProvider} from '@growthbase/design-components';
import {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useCallbackRef} from '@growthbase/spa';
import {LijsttemplateAlsLijstToevoegenForm} from './LijsttemplateAlsLijstToevoegenForm';

export type LijsttemplateAlsLijstToevoegenFormConnectedProps = RouteElementProps<{
    bordtemplateId: string;
}>;

interface FormModalProps {
    bordtemplateId: string;
    excludeIds?: string[];
}

const FormModal: FC<FormModalProps> = ({bordtemplateId, excludeIds}) => {
    const goBack = useHistoryBack();
    const [submit] = useTemplateLijsttemplateAlsLijstToevoegenMutation();
    const {data, loading} = useTemplateLijsttemplatesQuery({
        variables: {
            excludeIds,
        },
    });

    return (
        <RoutePopupProvider>
            <LijsttemplateAlsLijstToevoegenForm
                onSubmit={useCallbackRef((values) =>
                    submit({
                        variables: {
                            input: {
                                id: bordtemplateId,
                                lijsttemplateId: idFromIri(values.lijsttemplate),
                            },
                        },
                    }).then(goBack)
                )}
                optionsLoading={loading}
                options={useMemo(
                    () =>
                        data?.Lijsttemplates?.edges?.map((lijsttemplate) => ({
                            value: lijsttemplate?.node?.id ?? '',
                            label: lijsttemplate?.node?.naam ?? '',
                        })) ?? [],
                    [data?.Lijsttemplates?.edges]
                )}
            />
        </RoutePopupProvider>
    );
};

export const LijsttemplateAlsLijstToevoegenFormConnected = ({
    params,
}: LijsttemplateAlsLijstToevoegenFormConnectedProps) => {
    const {t} = useTranslation('spa_form.nl');
    const bordtemplateId = params?.bordtemplateId;
    if (!bordtemplateId) {
        throw new Error('Missing bordtemplateId.');
    }

    const {data, loading} = useReedsToegevoegdeLijsttemplatesQuery({
        variables: {
            bordtemplateId,
        },
    });

    if (loading && !data) {
        return (
            <RoutePopupProvider>
                <Modal>
                    <PopupHeader title={t('bordtemplate.LijsttemplateAlsLijstToevoegenForm.PopupHeader')} />
                    <ModalContent>
                        <LoaderCenter />
                    </ModalContent>
                </Modal>
            </RoutePopupProvider>
        );
    }

    const ids = (data?.items?.edges?.filter((edge) => edge?.node?.id !== undefined).map((edge) => edge?.node?.id) ??
        []) as string[];

    return <FormModal bordtemplateId={bordtemplateId} excludeIds={ids} />;
};
