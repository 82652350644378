import {idFromIri} from '@growthbase/routing';
import {useMemo} from 'react';

export interface TaakSearchDTO {
    taakId: string | null;
    disabled: boolean;
    readonly: boolean;
    field: string;
    titel: string | null;
    opdrachtId: string | null;
    ticketId: string | null;
    persoonId: string | null;
    bedrijfId: string | null;
}

/**
 * @see src/ACL/Taak/Uren/Presentation/Symfony/Template/TaakSearch.html.twig
 */
export const useExtractValueFromElement = (element: Element): TaakSearchDTO =>
    useMemo(() => {
        const name = element.getAttribute('data-form-name');
        const dto = element.getAttribute('data-form-dto');
        if (!name || !dto) {
            throw new Error('Missing form-name or form-dto attribute on element');
        }
        const defaultDTO: TaakSearchDTO = JSON.parse(dto) as TaakSearchDTO;
        defaultDTO.field = name;
        defaultDTO.taakId = defaultDTO.taakId ? idFromIri(defaultDTO.taakId) : null;
        return defaultDTO;
    }, [element]);
