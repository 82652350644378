import {LoaderCenter} from '@growthbase/design-components';
import {PropsWithChildren, FC} from 'react';
import {useTranslation} from 'react-i18next';
import {BordenPagina, BordenPaginaProps} from '../BordenPagina';

export interface BordPaginaProps extends PropsWithChildren, BordenPaginaProps {
    bordUuid: string;
    bordNaam?: string;
}

export const BordPagina: FC<BordPaginaProps> = ({bordUuid, bordNaam, children, ...rest}) => {
    const {t} = useTranslation('TicketsTaken.nl');
    return (
        <BordenPagina
            {...rest}
            titel={
                t('BordPagina.titel', {
                    ticketNaam: rest.ticketNaam,
                    bordNaam: bordNaam ?? '',
                }) ?? ''
            }
        >
            {children ?? <LoaderCenter />}
        </BordenPagina>
    );
};
