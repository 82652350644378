import {useMemo} from 'react';
import {ExternalLinkType} from '@growthbase/clientbox';
import {RelatieValue} from '@growthbase/relaties';

interface RawRelatieValue {
    id: string;
    label: string;
    externalId?: string | null;
}

interface RawRelatieValues {
    persoon?: RawRelatieValue | null;
    bedrijf?: RawRelatieValue | null;
}

export const normalizeRelatieValues = (fragment?: RawRelatieValues | null | {id: string}): RelatieValue => {
    if (!fragment) {
        return {};
    }
    const values: RelatieValue = {};
    if ('persoon' in fragment && fragment.persoon) {
        const {persoon} = fragment;
        values.persoon = {
            id: persoon.id,
            label: persoon.label,
            linkType: ExternalLinkType.Persoon,
        };
    }
    if ('bedrijf' in fragment && fragment.bedrijf) {
        const {bedrijf} = fragment;
        values.bedrijf = {
            id: bedrijf.id,
            label: bedrijf.label,
            linkType: ExternalLinkType.Bedrijf,
        };
    }
    return values;
};
export const useNormalizeRelatieValues = (fragment?: RawRelatieValues | null | {id: string}) =>
    useMemo(() => normalizeRelatieValues(fragment), [fragment]);
