import {Stack} from '@growthbase/design-components';
import {useTotalStatsQuery} from '@growthbase/graphql';
import {DisplayProgress} from '../ProcessesPage/Components/DisplayProgress';

export const TotalStats = () => {
    const {data} = useTotalStatsQuery({
        pollInterval: 5000,
    });

    if (!data) {
        return null;
    }

    if (data.processes.length === 0) {
        return null;
    }
    const {projectionCountStatistics} = data;
    if (projectionCountStatistics.length === 0) {
        return null;
    }
    return (
        <Stack horizontal justify align="center">
            Replaying&nbsp;
            <DisplayProgress fragment={projectionCountStatistics[0]} />
        </Stack>
    );
};
