import {ITaakEditModalProfielFragment} from '@growthbase/graphql';
import {FC, useCallback} from 'react';
import {Modal, ModalSize, Tabs, TabsRendererProps} from '@growthbase/design-components';
import {Helmet} from 'react-helmet-async';
import {BijlagesConnected} from '@growthbase/taak-bijlage';
import {TaakReactiesConnected} from '../Reactie';
import {TaakActiviteitenConnected} from '../TaakActiviteiten';
import {EditModalTabs} from './EditModalTabs';
import {useTaakPageNavigation} from './Hook/useTaakPageNavigation';
import {TaakEditPopupHeaderConnected} from './Components/TaakEditPopupHeaderConnected';
import {TaakEditFormConnected} from '../TaakEditForm';
import {TaakEditFragment, isTaakEditModalFragment} from './TaakEditFragment';

export interface TaakEditModalProps {
    id: string;
    fragment?: TaakEditFragment;
    profielFragment?: ITaakEditModalProfielFragment;
    TaakEditPopupHeader: typeof TaakEditPopupHeaderConnected;
    ActiviteitenList: typeof TaakActiviteitenConnected;
    Reacties: typeof TaakReactiesConnected;
}

export const Pager: FC<{id: string}> = ({id}) => {
    useTaakPageNavigation(id);

    return null;
};

export const TaakEditModal: FC<TaakEditModalProps> = ({
    id,
    fragment,
    profielFragment,
    ActiviteitenList,
    TaakEditPopupHeader,
    Reacties,
}) => {
    const PopupTabsRenderer: FC<TabsRendererProps> = useCallback(
        ({content, header}) => (
            <>
                <TaakEditPopupHeader profielFragment={profielFragment} fragment={fragment}>
                    {header}
                </TaakEditPopupHeader>
                {content}
            </>
        ),
        [TaakEditPopupHeader, profielFragment, fragment]
    );

    return (
        <Modal testId="TaakEditModal" size={ModalSize.SIZE_XL}>
            <Helmet>
                <title>{fragment?.titel}</title>
            </Helmet>
            <BijlagesConnected fragment={isTaakEditModalFragment(fragment) ? fragment : null}>
                {({bijlages, openInput, kanToevoegen}) => (
                    <Tabs render={PopupTabsRenderer} sizeSmall>
                        <Pager id={id} />
                        <EditModalTabs
                            id={id}
                            ActiviteitenList={ActiviteitenList}
                            fragment={fragment}
                            Reacties={Reacties}
                            editform={
                                fragment ? (
                                    <TaakEditFormConnected
                                        fragment={fragment}
                                        bijlages={bijlages}
                                        voegBijlageToe={kanToevoegen ? openInput : undefined}
                                    />
                                ) : null
                            }
                        />
                    </Tabs>
                )}
            </BijlagesConnected>
        </Modal>
    );
};
