import React, {FC, PropsWithChildren} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from '../Button';
import {Modal} from '../Modals';
import {PopupControlsContext, PopupHeader, ScrollablePopupContent, useRoutePopupControls} from '../Popups';
import styles from './GeenToegangPagina.module.scss';

export const GeenToegangModal: FC<PropsWithChildren> = () => {
    const {t} = useTranslation('spa.nl');
    const controls = useRoutePopupControls();
    return (
        <PopupControlsContext.Provider value={controls}>
            <Modal>
                <ScrollablePopupContent fullWidth header={<PopupHeader title={t('GeenToegangPagina.Titel')} />}>
                    <div className={styles.geenToegangPagina__text}>{t('GeenToegangPagina.Omschrijving')}</div>
                    <Button variant="primary" active onClick={controls.close}>
                        {t('GeenToegangPagina.Terug')}
                    </Button>
                </ScrollablePopupContent>
            </Modal>
        </PopupControlsContext.Provider>
    );
};
