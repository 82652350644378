import {FC, ReactNode} from 'react';

import classNames from 'classnames/bind';
import styles from './modalFooter.module.scss';

const cx = classNames.bind(styles);

export interface ModalFooterProps {
    children?: ReactNode;
    buttons?: ReactNode;
    alignRight?: boolean;
    negativeMargin?: boolean;
}

export const ModalFooter: FC<ModalFooterProps> = ({children, buttons, alignRight, negativeMargin}) => {
    const classes = cx('modalFooter', {
        'modalFooter--negativeMargin': negativeMargin,
    });

    const buttonClasses = cx('modalFooter__buttons', {
        'modalFooter__buttons--alignRight': alignRight,
    });

    return (
        <div className={classes}>
            {children}
            {buttons && <div className={buttonClasses}>{buttons}</div>}
        </div>
    );
};
