import {
    determineActionTypeWhenFiltered,
    determineActionTypeWhenGearchiveerd,
    determineActionTypeWhenSearching,
    PositieType,
    QueryCacheUpdateType,
    useConvertFetchEntity,
    useUpdateQueryCacheFromExternalEntityChanges,
} from '@growthbase/cache';
import {IBordenConnectedFragment, refetchBordenConnectedEntryQuery, ResultData} from '@growthbase/graphql';
import {QueryResult} from '@apollo/client';
import {BordenOnderdeelVan, OverviewFilters} from '@growthbase/takenlijst/bord';

export const useUpdateFromExternalEntityChanges = ({
    result,
    filters,
    onderdeelVan,
    archief,
    mijnborden,
}: {
    result: QueryResult<ResultData<IBordenConnectedFragment>>;
    filters: OverviewFilters;
    onderdeelVan?: BordenOnderdeelVan;
    archief: boolean;
    mijnborden?: boolean;
}) => {
    useUpdateQueryCacheFromExternalEntityChanges<IBordenConnectedFragment>({
        result,
        determineAction: (params, action) => {
            const {typename} = params;

            if (typename === 'TakenBord_ZichtbaarVoorEdge') {
                return QueryCacheUpdateType.refetch;
            }

            if (typename !== 'TakenBord') {
                return QueryCacheUpdateType.ignore;
            }

            action = determineActionTypeWhenSearching(action, params, !!filters.searchQuery);
            action = determineActionTypeWhenGearchiveerd(action, params, archief);
            action = determineActionTypeWhenFiltered(action, params, !!onderdeelVan || !!mijnborden);

            return action;
        },
        positie: PositieType.alphabetically,
        fetchEntity: useConvertFetchEntity(refetchBordenConnectedEntryQuery),
        addToQueryOptions: {
            edgeTypeName: 'TakenBordEdge',
            nodeTypeName: 'TakenBord',
            orderBy: 'naam',
        },
    });
};
