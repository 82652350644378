/* eslint-disable no-continue */
import {ControlField, FullNestedName, InstellingField, InstellingFieldType, InstellingValues} from './types';
import {valueFromPath} from './Helpers';
import {InstellingFieldCollection} from './InstellingFieldCollection';

export interface Reason {
    label: string;
    name: FullNestedName;
    parents: FullNestedName[];
    value: unknown;
    hide?: boolean;
    /**
     * We actually want to change to the value of the control field.
     */
    useValue: boolean;
}

export const createReason = (partial: {
    label: string;
    name: FullNestedName;
    parents?: FullNestedName[];
    value: unknown;
    hide?: boolean;
    useValue?: boolean;
}): Reason => ({
    parents: [],
    hide: false,
    useValue: true,
    ...partial,
});

export class FieldControlledByService {
    public isControlled(
        fields: InstellingField[],
        values: InstellingValues,
        field: string | InstellingField,
        recursiveCache: Record<string, Reason | null> = {}
    ): Reason | null {
        const name = typeof field === 'string' ? field : field.name;
        if (name in recursiveCache) {
            return recursiveCache[name];
        }
        const split = name.split('.');
        let cursor: string | null = null;
        let resolvedOverride: Reason | null = null;
        for (const part of split) {
            cursor = cursor ? `${cursor}.${part}` : part;
            const found = this.getField(fields, cursor);
            const override = this.findReason(fields, values, found, recursiveCache);
            if (!override) {
                continue;
            }
            resolvedOverride = this.mergeReason(resolvedOverride, override);
        }
        if (resolvedOverride) {
            resolvedOverride.value = this.normalizeValue(
                this.getField(fields, resolvedOverride.name),
                this.getField(fields, name),
                resolvedOverride.value
            );
            resolvedOverride.useValue = this.canUseValueForThisField(
                resolvedOverride.value,
                this.getField(fields, name)
            );
        }
        recursiveCache[name] = resolvedOverride;
        return recursiveCache[name];
    }

    private getField(fields: InstellingField[], name: string) {
        const found = fields.find((f) => f.name === name);
        if (!found) {
            throw new Error(`Field ${name} not found`);
        }
        return found;
    }

    /**
     * Checks if a field is overridden by a nested field. Null not if the field is not overridden.
     * Boolean if the field is overridden with that value.
     */
    private findReason(
        fields: InstellingField[],
        values: InstellingValues,
        target: InstellingField,
        recursiveCache: Record<string, Reason | null> = {}
    ): Reason | null {
        const controlFields = this.controlFieldsFor(fields, target.name);
        let result: null | Reason = null;
        for (const [source, controlField] of controlFields) {
            const reason = this.validateForField(fields, values, source, controlField, target, recursiveCache);
            if (reason !== null) {
                result = this.mergeReason(result, reason);
            }
        }
        return result;
    }

    private controlFieldsFor(fields: InstellingField[], name: string): Array<[InstellingField, ControlField]> {
        const result: Array<[InstellingField, ControlField]> = [];
        for (const field of fields) {
            if (!('controlFields' in field)) {
                continue;
            }
            for (const controlField of field.controlFields.getFields()) {
                if (controlField.name === name) {
                    result.push([field, controlField]);
                }
            }
        }
        return result;
    }

    private validateForField(
        fields: InstellingField[],
        values: InstellingValues,
        source: InstellingField,
        controlField: ControlField,
        target: InstellingField,
        recursiveCache: Record<string, Reason | null> = {}
    ): Reason | null {
        const reden = this.isControlled(fields, values, source, recursiveCache);
        const sourceValue = valueFromPath(values, source.name);
        const {value} = controlField.options;
        const canUseValue = this.canUseValueForThisField(value, target);
        if (this.criteriaMet(source, target, controlField, sourceValue)) {
            return {
                name: source.name,
                parents: reden ? [...reden.parents, reden.name] : [],
                value,
                useValue: canUseValue && reden ? reden.useValue : true,
                label: source.label,
                hide: controlField.options.hide,
            };
        }
        if (!reden) {
            return null;
        }
        return {
            name: source.name,
            parents: [...reden.parents, reden.name],
            value,
            useValue: canUseValue && reden.useValue,
            label: source.label,
            hide: controlField.options.hide,
        };
    }

    private criteriaMet(
        source: InstellingField,
        target: InstellingField,
        controlField: ControlField,
        value: unknown
    ): boolean {
        return value === controlField.options.when;
    }

    /**
     * Always return a copy of the previous or update, so we don't mutate the original.
     */
    private mergeReason(previous: null | Reason, update: Reason): Reason {
        if (previous === null) {
            return {
                ...update,
            };
        }
        if (update.useValue) {
            return {
                ...update,
            };
        }
        return {
            ...previous,
        };
    }

    /**
     * {
     *     foo: {
     *         bar: {
     *             a
     *         }
     *     }
     *
     *     'foo' group can be overridden, this determines if it knows the value of the nested field..
     * }
     */
    private canUseValueForThisField(value: unknown, field: InstellingField): boolean {
        switch (field.type) {
            case InstellingFieldType.boolean:
                if (typeof value !== 'boolean') {
                    return false;
                }
                break;
            case InstellingFieldType.string:
                if (typeof value !== 'string') {
                    return false;
                }
                break;
            case InstellingFieldType.number:
                if (typeof value !== 'number') {
                    return false;
                }
                break;
            case InstellingFieldType.nested:
                if (typeof value !== 'object' || value === null) {
                    return false;
                }
                break;
            default:
                throw new Error(`Unknown type ${field.type}`);
        }
        return true;
    }

    private normalizeValue(source: InstellingField, target: InstellingField, value: unknown): unknown {
        if (value === null || value === undefined) {
            return value;
        }
        if (this.canUseValueForThisField(value, target)) {
            return value;
        }
        if (typeof value === 'object') {
            return valueFromPath(value as Record<string, unknown>, target.name);
        }
        return value;
    }
}

const service = new FieldControlledByService();

/**
 * Checks if a field is overridden by a nested field. Null not if the field is not overridden.
 * Boolean if the field is overridden with that value.
 */
export const fieldIsControlledBy = (
    fields: InstellingFieldCollection | InstellingField[],
    values: InstellingValues,
    field: string | InstellingField,
    recursiveCache: Record<string, Reason | null> = {}
): Reason | null => {
    if (fields instanceof InstellingFieldCollection) {
        fields = fields.getFields();
    }
    return service.isControlled(fields, values, field, recursiveCache);
};
