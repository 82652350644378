import React, {useCallback} from 'react';
import type {MbscCalendarDayData} from '@mobiscroll/react/dist/src/core/shared/calendar-view/calendar-day';
import {CalendarDate, CalendarMonthDate} from '@growthbase/design-components';
import moment from 'moment';

export const useRenderDay = (viewId: string, smallDates: boolean) =>
    useCallback(
        ({date, events}: MbscCalendarDayData) => {
            const totalSeconds = events
                ? events.reduce((total, event) => {
                      const start = moment.max([moment(event.start), moment(date).startOf('day')]);
                      const end = moment.min([moment(event.end), moment(date).endOf('day').add(1, 'second')]);
                      return total + start.diff(end, 'seconds');
                  }, 0)
                : 0;

            return viewId.indexOf('maand') !== -1 ? (
                <CalendarMonthDate date={date} totalSeconds={totalSeconds} />
            ) : (
                <CalendarDate small={smallDates} date={date} />
            );
        },
        [viewId, smallDates]
    );
