import {useMemo} from 'react';
import {ILogLevel} from '@growthbase/graphql';
import {useStateStringFromSearchParam, useStateStringUrlFactory} from '@growthbase/routing';
import {DeveloperRoutes} from '@growthbase/health';
import {useCallbackRef} from '@growthbase/spa';

export type LogFilter = {
    id?: string;
    not?: boolean;
    or?: LogFilter[];
    and?: LogFilter[];
    query?: string | null;
    levels?: ILogLevel[];
    facilities?: string[];
    profielId?: string;
    growthbase?: boolean;
    clientbox?: boolean;
    gebruikerId?: string;
    processIds?: string[];
    previousProcessIds?: string[];
    transactionDepth?: number[];
    transactionIds?: string[];
    errorIds?: string[];
    errorCodes?: string[];
    lastMinutes?: number;
    from?: string;
    to?: string;
    cliCommand?: string;
    requestUri?: string;
    requestRoute?: string;
    requestMethod?: string;
    requestIp?: string;
};

export interface UseFilterBarStateOptions {
    initialValue: LogFilter[];
}

export const useFilterBarStateUrlFactory = () => {
    const [generate, navigate] = useStateStringUrlFactory('query', DeveloperRoutes.logs.useUrlFactory());
    return [
        useCallbackRef((filters: LogFilter[]): string => generate(JSON.stringify(filters))),
        useCallbackRef((filters: LogFilter[]): void => navigate(JSON.stringify(filters))),
    ];
};

export const useFilterBarState = ({
    initialValue,
}: UseFilterBarStateOptions): [LogFilter[], (update: LogFilter[]) => void] => {
    const [json, setJson] = useStateStringFromSearchParam('query', JSON.stringify(initialValue));

    const filters = useMemo(() => {
        if (!json) {
            return initialValue;
        }
        try {
            return JSON.parse(json);
        } catch (e) {
            return initialValue;
        }
    }, [json, initialValue]);
    return [
        filters,
        (update: LogFilter[]) => {
            setJson(JSON.stringify(update));
        },
    ];
};
