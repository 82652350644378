import React, {FC, ReactNode, useState} from 'react';
import axios from 'axios';
import {useMount} from 'react-use';
import {Loader, Stack} from '@growthbase/design-components';
import DOMPurify from 'dompurify';
import {CodeViewer} from './CodeViewer';

/* eslint-disable */

export const SVGViewer: FC<{content: string}> = ({content}) => (
    <div
        style={{
            maxWidth: '100%',
            maxHeight: '100%',
            minWidth: '50%',
            minHeight: '50%',
            flex: '0.5',
            overflow: 'auto',
            borderRadius: '5px',
            padding: '10px',
            margin: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            backgroundColor: 'white',
        }}
        dangerouslySetInnerHTML={{
            __html: content,
        }}
    />
);

export const LoadSVGViewer: FC<{path: string; url: string}> = ({path, url}) => {
    const [content, setContent] = useState<string>('');
    const [error, setError] = useState<ReactNode>(null);

    useMount(async () => {
        try {
            const response = await axios.get(url, {
                responseType: 'text',
            });
            // Sanitize the SVG content
            const sanitizedContent = DOMPurify.sanitize(response.data, {USE_PROFILES: {svg: true}});
            setContent(sanitizedContent);
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
                return;
            }
            setError(`${error}`);
        }
    });

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (content === '') {
        return <Loader center />;
    }

    return (
        <Stack horizontal>
            <SVGViewer content={content} />
            <CodeViewer ext={path.split('.').pop() || ''} code={content} />
        </Stack>
    );
};
