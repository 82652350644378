import {ReactElement, ReactNode} from 'react';
import styles from './EventPopup.module.scss';
import {DateTimeRangeDisplay} from '../DateTimeRangeDisplay';
import {AnchoredPopup, PopupContent, PopupTitle} from '../../../Popups';
import {Padding} from '../../../Layout';
import {Buttons} from '../../../Button';
import {Icons} from '../../../icons';

export interface EventPopupData {
    title: string;
    agendaLabel: string;
    start: string;
    eind: string;
    timeCodeLabel?: string;
}

export type renderEventPopupLink = () => React.ReactNode;

export interface EventPopupProps {
    data?: EventPopupData;
    loading?: boolean;
    reference: Element;
    onClose: () => void;
    relationLinkContent?: ReactElement;
    orderLinkContent?: ReactElement;
    ticketLinkContent?: ReactElement;
    buttonContent?: ReactElement;
    taakContent?: ReactNode;
    paddingLeft?: boolean;
}

export const EventPopup = ({
    data,
    loading,
    reference,
    onClose,
    relationLinkContent,
    orderLinkContent,
    ticketLinkContent,
    buttonContent,
    taakContent,
    paddingLeft,
}: EventPopupProps) => (
    <AnchoredPopup
        anchorElement={reference}
        onClose={onClose}
        arrow
        shiftPadding={
            paddingLeft
                ? {
                      left: 280,
                      right: 30,
                      top: 20,
                      bottom: 20,
                  }
                : undefined
        }
    >
        <PopupContent>
            <Padding paddingSize="large">
                <div className={styles.eventPopup}>
                    {loading && 'Loading...'}
                    {data && (
                        <>
                            <Buttons.CrossIconOnly onClick={onClose} className={styles.eventPopup__close} />
                            <PopupTitle className={styles.eventPopup__title}>{data.title}</PopupTitle>
                            <div className={styles.eventPopup__data}>
                                <Icons.ModuleCalendar />
                                <div>
                                    <DateTimeRangeDisplay from={data.start} to={data.eind} />
                                </div>
                                {relationLinkContent && (
                                    <>
                                        <Icons.ModuleRelations />
                                        {relationLinkContent}
                                    </>
                                )}
                                {orderLinkContent && (
                                    <>
                                        <Icons.ModuleOrder />
                                        {orderLinkContent}
                                    </>
                                )}
                                {ticketLinkContent && (
                                    <>
                                        <Icons.ModuleTicket />
                                        {ticketLinkContent}
                                    </>
                                )}
                                {data.timeCodeLabel && (
                                    <>
                                        <Icons.ModuleArticle />
                                        {data.timeCodeLabel}
                                    </>
                                )}
                                {data.agendaLabel && (
                                    <>
                                        <Icons.ModuleEmployees />
                                        {data.agendaLabel}
                                    </>
                                )}
                                {taakContent && (
                                    <>
                                        <Icons.ModuleTask />
                                        {taakContent}
                                    </>
                                )}
                            </div>
                            {buttonContent && <div className={styles.eventPopup__buttons}>{buttonContent}</div>}
                        </>
                    )}
                </div>
            </Padding>
        </PopupContent>
    </AnchoredPopup>
);
