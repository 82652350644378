import {
    CapaciteitViewConnected,
    OpdrachtRouteParams,
    useLoadPersistentPlanningState,
    useSyncedPlanningState,
    OpdrachtSidebarConnected,
    usePlanningInitPlanningPopup,
    PlanningStoreState,
    AgendaViewConnected,
    PlanningViewConnected,
} from '@growthbase/planning';
import {RouteElementProps} from '@growthbase/routing';
import {OpdrachtSelectieConnected, PlanningPopup} from '@growthbase/opdrachten-planning';
import React, {useEffect, useState} from 'react';
import {useCallbackRef} from '@growthbase/spa';
import {useSelector} from 'react-redux';
import {StatFooterConnected} from './Components/StatFooter/StatFooterConnected';

export type PlanningPopupConnectedProps = RouteElementProps<OpdrachtRouteParams>;

export const PlanningPopupConnected = ({params}: PlanningPopupConnectedProps) => {
    if (!params) {
        throw new Error('No params provided for PlanningPopupConnected.');
    }

    const [opdrachtId, setOpdrachtId] = useState<string>(params.opdrachtId);

    const initPopup = usePlanningInitPlanningPopup();
    const {viewSettingsLoaded} = useSelector((state: PlanningStoreState) => state.planning);

    useSyncedPlanningState();
    useLoadPersistentPlanningState(false);

    const onChangeOpdrachtId = useCallbackRef((changedOpdrachtId: string) => {
        setOpdrachtId(changedOpdrachtId);
    });

    useEffect(() => {
        initPopup();
    }, [initPopup]);

    if (!viewSettingsLoaded) {
        return <></>;
    }

    return (
        <PlanningPopup
            opdrachtSelector={
                <OpdrachtSelectieConnected opdrachtId={opdrachtId} onChangeOpdrachtId={onChangeOpdrachtId} />
            }
            sidebar={<OpdrachtSidebarConnected opdrachtId={opdrachtId} />}
            agendaView={<AgendaViewConnected module="opdracht-plan-popup" />}
            planningView={<PlanningViewConnected module="opdracht-plan-popup" />}
            capaciteitView={<CapaciteitViewConnected module="opdracht-plan-popup" />}
            footer={<StatFooterConnected opdrachtId={opdrachtId} />}
        />
    );
};
