interface JQuery {
    matchesText(value: string): JQuery;
    select2Value(value: string): JQuery;
}

jQuery.fn.matchesText = function MatchesText(value: string): JQuery {
    return jQuery(this).filter(function MatchesTextFilter() {
        return jQuery(this).text().trim() === value.trim();
    });
};
