import classNames from 'classnames/bind';
import {ReactNode, FC} from 'react';
import styles from './ButtonGroup.module.scss';

const cx = classNames.bind(styles);

export interface ButtonGroupProps {
    children: ReactNode;
    justify?: boolean;
    disableLeftMargin?: boolean;
    disableRightMargin?: boolean;
    align?: 'left' | 'right';
}

export const ButtonGroup: FC<ButtonGroupProps> = ({
    children,
    disableLeftMargin,
    disableRightMargin,
    justify,
    align,
}) => {
    const classes = cx('buttonGroup', {
        'buttonGroup--justify': justify,
        'buttonGroup--disableLeftMargin': disableLeftMargin,
        'buttonGroup--disableRightMargin': disableRightMargin,
        'buttonGroup--alignRight': align === 'right',
    });
    return <div className={classes}>{children}</div>;
};
