import {AgendaView, AgendaViewProps} from '@growthbase/planning';
import {useCallback, useState} from 'react';
import {generateEventsForView} from './Hook/useGenerateEventsForView';

export type AgendaViewStubProps = Partial<AgendaViewProps>;

export const AgendaViewStub = ({...props}: AgendaViewStubProps) => {
    const [data, setDate] = useState<AgendaViewProps['data']>(undefined);

    const onRequestData: AgendaViewProps['onRequestData'] = useCallback(({startDate}: {startDate: Date}) => {
        setDate(generateEventsForView(startDate));
    }, []);

    return <AgendaView {...props} onRequestData={onRequestData} data={data} />;
};
