import {ItemPlacement} from '../Value';

export function placeItemFromPlacement<TItem extends {id: string} = {id: string}>({
    item,
    items,
    placement: {itemThatIsBefore, itemThatIsAfter},
}: {
    item: TItem;
    items: TItem[];
    placement: ItemPlacement<TItem>;
}): TItem[] {
    const filtered = items.filter((i) => i.id !== item.id);
    const indexBefore = itemThatIsBefore ? filtered.findIndex((i) => i.id === itemThatIsBefore.id) : -1;

    if (indexBefore !== -1) {
        filtered.splice(indexBefore + 1, 0, item);
        return filtered;
    }

    const indexAfter = itemThatIsAfter ? filtered.findIndex((i) => i.id === itemThatIsAfter.id) : -1;
    if (indexAfter !== -1) {
        filtered.splice(indexAfter, 0, item);
        return filtered;
    }

    if (!items.find((i) => i.id === item.id)) {
        return [...items, item];
    }
    return items;
}
