import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {DateRangeOptions, ISOStringDateRange} from './useDateRangeSchema';

export const useDefaultLabels = (
    {fromLabel, toLabel, fromNotGreaterThanTo}: Partial<DateRangeOptions>,
    previous: ISOStringDateRange
): Required<Omit<DateRangeOptions, 'minimumEndDate'>> => {
    const {t} = useTranslation('spa.nl');
    return useMemo(
        () => ({
            fromLabel: fromLabel ?? t('design.DateRangePicker.from') ?? '',
            toLabel: toLabel ?? t('design.DateRangePicker.to') ?? '',
            fromNotGreaterThanTo: fromNotGreaterThanTo ?? t('design.DateRangePicker.fromNotGreaterThanTo') ?? '',
            previous,
        }),
        [t, fromLabel, toLabel, fromNotGreaterThanTo, previous]
    );
};
