import {ILijstMenuFragment} from '@growthbase/graphql';
import {FC} from 'react';
import {
    ActionsMenuButton,
    LegacyActionsMenuRefButton,
    useLegacyActionsMenuRefButton,
} from '@growthbase/design-components';
import {VerwijderLijstActionsMenuButtonConnected} from './Components/VerwijderLijstActionsMenuButton';
import {LijstMenu} from './LijstMenu';
import {BewerkLijstActionsMenuButtonConnected} from './Components/WijzigLijstActionsMenuButton/BewerkLijstActionsMenuButtonConnected';
import {MarkeerAlsInboxButtonConnected} from './Components/MarkeerAlsInboxButton';
import {KopieerButtonConnected} from '../Kopieren';
import {VerplaatsButton} from '../Verplaatsen';

export interface LijstMenuConnectedProps {
    fragment: ILijstMenuFragment;
}

export const LijstMenuConnected: FC<LijstMenuConnectedProps> = ({fragment}) => {
    const {ref, Context} = useLegacyActionsMenuRefButton();
    return (
        <>
            <div ref={ref} />
            <LijstMenu
                bewerkMenuButton={<BewerkLijstActionsMenuButtonConnected fragment={fragment} />}
                verwijderMenuButton={<VerwijderLijstActionsMenuButtonConnected fragment={fragment} />}
                markeerAlsInboxButton={
                    <MarkeerAlsInboxButtonConnected
                        readonly={false}
                        disabled={false}
                        Button={ActionsMenuButton}
                        fragment={fragment}
                    />
                }
                lijstKopieren={<KopieerButtonConnected Button={ActionsMenuButton} fragment={fragment} />}
                verplaatstButton={
                    <Context value={ref}>
                        <VerplaatsButton
                            Button={LegacyActionsMenuRefButton}
                            fragment={fragment}
                            readonly={false}
                            disabled={false}
                        >
                            Verplaatsen
                        </VerplaatsButton>
                    </Context>
                }
            />
        </>
    );
};
