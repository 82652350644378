import {
    ILijsttemplateTaakOmschrijvingFieldConnectedFragment,
    useLijsttemplateTaakOmschrijvingFieldConnectedMutation,
} from '@growthbase/graphql';
import {useCallback, FC} from 'react';
import {useNormalizeOpgemaakteTekstGraphQLResult} from '@growthbase/design-components';
import {OmschrijvingField} from './index';

export interface OmschrijvingFieldConnectedProps {
    fragment: ILijsttemplateTaakOmschrijvingFieldConnectedFragment | null;
    readonly?: boolean;
}

export const OmschrijvingFieldConnected: FC<OmschrijvingFieldConnectedProps> = ({fragment, readonly}) => {
    const [mutate] = useLijsttemplateTaakOmschrijvingFieldConnectedMutation();

    return (
        <OmschrijvingField
            readonly={readonly}
            onSubmit={useCallback(
                (value) => {
                    if (!fragment) {
                        throw new Error('Fragment is required');
                    }
                    return mutate({
                        variables: {
                            input: {
                                id: fragment.id,
                                opgemaakteTekst: value,
                            },
                        },
                    }).then(() => undefined);
                },
                [mutate, fragment]
            )}
            value={useNormalizeOpgemaakteTekstGraphQLResult(fragment?.omschrijving?.opgemaakteTekst)}
        />
    );
};
