import React, {FC, ReactNode} from 'react';
import {ActionsMenuButton, BaseButtonProps, PageActionsMenu} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';
import {BordtemplateRoutes} from '../../../BordtemplateRoutes';

export interface BordActionMenuItemsProps {
    bordtemplateId: string;
    renderVerwijderButton?: (props: {Button: FC<BaseButtonProps>}) => ReactNode;
    renderKopierenButton?: (props: {Button: FC<BaseButtonProps>}) => ReactNode;
}

export const ActionsBar = ({bordtemplateId, renderVerwijderButton, renderKopierenButton}: BordActionMenuItemsProps) => {
    const {t} = useTranslation('Bordtemplate.nl');
    return (
        <PageActionsMenu>
            <ActionsMenuButton to={BordtemplateRoutes.bewerken.useUrl({bordtemplateId})}>
                {t('bewerken')}
            </ActionsMenuButton>

            <ActionsMenuButton to={BordtemplateRoutes.lijsttoevoegen.useUrl({bordtemplateId})}>
                {t('LijsttemplateToevoegen')}
            </ActionsMenuButton>

            <ActionsMenuButton to={BordtemplateRoutes.lijstaanmaken.useUrl({bordtemplateId})}>
                {t('LijsttemplateAanmaken')}
            </ActionsMenuButton>

            {renderVerwijderButton && renderVerwijderButton({Button: ActionsMenuButton})}
            {renderKopierenButton && renderKopierenButton({Button: ActionsMenuButton})}
        </PageActionsMenu>
    );
};
