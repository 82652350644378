import {RelatieValue} from '@growthbase/relaties';
import {TFunction} from 'i18next';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {SchemaOf} from 'yup';

export const RelatieSchema = (t: TFunction): SchemaOf<RelatieValue> =>
    yup
        .object({
            bedrijf: yup.mixed(),
            persoon: yup.mixed(),
        })
        .label(t('Relatie', {ns: 'spa_form.nl'}));

export const useRelatieSchema = (): SchemaOf<RelatieValue> => {
    const {t} = useTranslation();
    return useMemo(() => RelatieSchema(t), [t]);
};
