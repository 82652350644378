import {QueryResult} from '@apollo/client/react/types/types';
import {
    determineActionFilterForTypes,
    determineActionForList,
    determineActionTypeWhenSearching,
    useConvertFetchEntity,
    useUpdateQueryCacheFromExternalEntityChanges,
} from '@growthbase/cache';
import {
    ITaaktemplateTabelFragment,
    ITaaktemplateTabelQuery,
    ITaaktemplateTabelQueryVariables,
    refetchTaaktemplateTabelSingleQuery,
} from '@growthbase/graphql';
import {idFromIri} from '@growthbase/routing';

export interface EntityChangesParams {
    lijsttemplateId?: string | null;
    searchQuery?: string | null;
    result: QueryResult<ITaaktemplateTabelQuery, ITaaktemplateTabelQueryVariables>;
}

export const useEntityChanges = ({result, lijsttemplateId, searchQuery}: EntityChangesParams) => {
    useUpdateQueryCacheFromExternalEntityChanges<ITaaktemplateTabelFragment>({
        result,
        determineAction: (params, defaultAction) => {
            let action = determineActionFilterForTypes(defaultAction, params, 'Taaktemplate');
            action = determineActionForList(action, params, lijsttemplateId ? idFromIri(lijsttemplateId) : null);
            return determineActionTypeWhenSearching(action, params, !!searchQuery);
        },
        fetchEntity: useConvertFetchEntity(refetchTaaktemplateTabelSingleQuery),
    });
};
