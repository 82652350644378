import {RouteElementProps, useHistoryBack} from '@growthbase/routing';
import {useLijsttemplateAlsLijstToevoegenMutation, useLijsttemplatesQuery} from '@growthbase/graphql';
import {RoutePopupProvider} from '@growthbase/design-components';
import {v4} from 'uuid';
import {LijsttemplateAlsLijstToevoegenForm} from './LijsttemplateAlsLijstToevoegenForm';

export type LijsttemplateAlsLijstToevoegenFormConnectedProps = RouteElementProps<{
    bordId?: string;
}>;

export const LijsttemplateAlsLijstToevoegenFormConnected = ({
    params,
}: LijsttemplateAlsLijstToevoegenFormConnectedProps) => {
    const bordId = params?.bordId;
    if (!bordId) {
        throw new Error('Missing bordId.');
    }

    const goBack = useHistoryBack();
    const [submit] = useLijsttemplateAlsLijstToevoegenMutation();
    const {data, loading} = useLijsttemplatesQuery();

    return (
        <RoutePopupProvider>
            <LijsttemplateAlsLijstToevoegenForm
                onSubmit={(values) =>
                    submit({
                        variables: {
                            input: {
                                id: bordId,
                                bordId,
                                lijsttemplateId: values.lijsttemplate,
                                newLijstId: v4(),
                            },
                        },
                    }).then(goBack)
                }
                optionsLoading={loading}
                options={
                    data?.Lijsttemplates?.edges?.map((lijsttemplate) => ({
                        value: lijsttemplate?.node?.id ?? '',
                        label: lijsttemplate?.node?.naam ?? '',
                    })) ?? []
                }
            />
        </RoutePopupProvider>
    );
};
