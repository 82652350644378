import {FC} from 'react';
import {
    CounterButton,
    SideNavBadge,
    SidenavCounterLink,
    SidenavCounterPopup,
    SidenavCounterPopupButtonProps,
} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';
import {IStandaardWeergaveMijnDag} from '@growthbase/graphql';
import {usePortalDestination} from '@growthbase/spa';
import {useMijnTakenCount} from './Hook/useMijnTakenCount';
import {useWijzigMijnActieveTeller} from './Hook/useWijzigMijnActieveTeller';

interface TakenCounterSideNavBadgeConnectedProps {
    legacy?: boolean;
}

const target = 'taken-nav-badge-container';

export const TakenCounterSideNavBadgeConnected: FC<TakenCounterSideNavBadgeConnectedProps> = ({legacy}) => {
    const {t} = useTranslation('spa.nl');

    const destination = usePortalDestination(target);
    const disabled = !destination && !!legacy;
    const counts = useMijnTakenCount({
        disabled,
    });
    const wijzigTeller = useWijzigMijnActieveTeller();

    const {actieveTeller} = counts;
    if (actieveTeller === undefined) {
        return null;
    }
    const popupContent = (
        <>
            <SidenavCounterLink
                count={counts.mijnDagCount}
                active={actieveTeller === IStandaardWeergaveMijnDag.Vandaag}
                onClick={() => wijzigTeller(IStandaardWeergaveMijnDag.Vandaag, actieveTeller)}
            >
                {t('TakenCounterSideNavBadge.MijnDag')}
            </SidenavCounterLink>
            <SidenavCounterLink
                count={counts.mijnTakenCount}
                active={actieveTeller === IStandaardWeergaveMijnDag.AlMijnTaken}
                onClick={() => wijzigTeller(IStandaardWeergaveMijnDag.AlMijnTaken, actieveTeller)}
            >
                {t('TakenCounterSideNavBadge.MijnTaken')}
            </SidenavCounterLink>
        </>
    );
    if (legacy) {
        return (
            <SidenavCounterPopup
                renderButton={({reference, onClick}: SidenavCounterPopupButtonProps) => (
                    <SideNavBadge target={target} reference={reference} onClick={onClick}>
                        {counts.count}
                    </SideNavBadge>
                )}
            >
                {popupContent}
            </SidenavCounterPopup>
        );
    }
    return <CounterButton count={counts.count} popupContent={popupContent} />;
};
