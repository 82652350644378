import {
    OverviewConnected,
    OverviewConnectedProps,
    ViewConnectedProps,
    ViewConnected,
} from '@growthbase/abonnementen-taken';
import {
    assertIdFromIri,
    createLegacyRoutes,
    CreateRouteFunction,
    createRoutes,
    PageFromMenu,
} from '@growthbase/routing';
import {generatePath} from 'react-router-dom';
import {assertClientboxIdentifier} from '@growthbase/clientbox';

const generateParams: CreateRouteFunction<{
    abonnementId: string;
    abonnementnummer: string;
    abonnementUuid: string;
    bordtemplateId?: string;
}> = (route: string, params): string => {
    if (!params) {
        throw new Error('Missing params');
    }
    const {abonnementId, abonnementUuid, abonnementnummer} = params;
    const clientboxId = assertClientboxIdentifier(abonnementId);
    return generatePath(route, {
        abonnementnummer,
        id: clientboxId,
        abonnementId,
        abonnementUuid: assertIdFromIri(abonnementUuid),
        name: abonnementnummer,
    });
};

const generateBordParams: CreateRouteFunction<{
    abonnementId: string;
    abonnementnummer: string;
    abonnementUuid: string;
    bordUuid: string;
}> = (route: string, params) => {
    if (!params) {
        throw new Error('Missing params');
    }
    const {abonnementId, abonnementUuid, abonnementnummer, bordUuid} = params;
    const clientboxId = assertClientboxIdentifier(abonnementId);
    return generatePath(route, {
        abonnementnummer,
        abonnementId: clientboxId,
        id: clientboxId,
        abonnementUuid: assertIdFromIri(abonnementUuid),
        bordUuid: assertIdFromIri(bordUuid),
        name: abonnementnummer,
    });
};

const generateLegacyParams: CreateRouteFunction<{
    id: string;
    name: string;
}> = (route: string, params): string => {
    if (!params) {
        throw new Error('Missing params');
    }
    const {id, name} = params;
    return generatePath(route, {
        id,
        name,
    });
};

export const AbonnementLegacyRoutes = createLegacyRoutes('subscription', {
    index: {},
    read: {
        createRoute: generateLegacyParams,
    },
});

export const AbonnementRoutes = createRoutes('abonnement', {
    borden: {
        Element: (props: OverviewConnectedProps) => (
            <PageFromMenu sidebar="preserve">
                <OverviewConnected {...props} />
            </PageFromMenu>
        ),
        createRoute: generateParams,
    },
    bord: {
        Element: (props: ViewConnectedProps) => (
            <PageFromMenu sidebar="preserve" forceId="abonnement_borden">
                <ViewConnected {...props} />
            </PageFromMenu>
        ),
        createRoute: generateBordParams,
    },
});
