import {useBoolean} from 'react-use';
import {ReactNode, useCallback, useMemo} from 'react';
import {ColumnWithLooseAccessor} from 'react-table';
import {FormikContextType} from 'formik';
import {FooterActions} from '../components/FooterActions';
import {Columns, TableFooter, TableFooterForm, TableFooterProps} from '../../Table/Table';
import {ButtonGroup} from '../../Buttons';
import {Buttons} from '../../Button';

export type TableCreateFields<T> = {
    [TName in keyof T as `${TName & string}`]?: ReactNode;
};

export interface FooterAddColumnsOptions<T extends object = object, F extends object = object> {
    open?: boolean;

    formik: FormikContextType<F>;

    /**
     * Needs to be memorized.
     */
    fields: TableCreateFields<T>;

    columns: Columns<T>;

    addText: ReactNode;
}

export function useTableFormFooter<T extends object = object, F extends object = object>({
    addText,
    formik,
    open: initialOpen = false,
    fields,
    columns,
}: FooterAddColumnsOptions<T, F>) {
    const [isOpen, setOpen] = useBoolean(initialOpen);

    const open = useCallback(() => setOpen(true), [setOpen]);
    const close = useCallback(() => setOpen(false), [setOpen]);

    const columnsWithFooter = useMemo(
        (): Columns<T> =>
            columns.map((column, index) => {
                const footerColumn: Partial<ColumnWithLooseAccessor<T>> = {};
                const accessor = (column.accessor || column.id) as keyof typeof fields;
                const footerNode: ReactNode | undefined = fields[accessor] as ReactNode | undefined;
                if (isOpen) {
                    if (columns.length === index + 1) {
                        footerColumn.Footer = (
                            <FooterActions
                                disabled={formik.isSubmitting || !formik.isValid}
                                submit={formik.handleSubmit}
                                close={close}
                            />
                        );
                    }
                    if (footerNode) {
                        // @ts-expect-error should fail
                        footerColumn.Footer = footerNode;
                    }
                }
                return Object.assign(footerColumn, column);
            }),
        [isOpen, close, fields, columns, formik]
    );

    return {
        columns: columnsWithFooter,
        open,
        close,
        isOpen,
        Footer: useCallback(
            ({table}: TableFooterProps<T>) => {
                if (isOpen) {
                    return <TableFooter table={table} />;
                }
                return (
                    <TableFooterForm>
                        <ButtonGroup disableLeftMargin>
                            <Buttons.PlusCircle variant="link" extraPadding onClick={open}>
                                {addText}
                            </Buttons.PlusCircle>
                        </ButtonGroup>
                    </TableFooterForm>
                );
            },
            [isOpen, open, addText]
        ),
    };
}
