import {ViewConfig} from '@growthbase/design-components';
import {IKaartTitelConnectedFragment} from '@growthbase/graphql';
import {FC} from 'react';
import {Titel} from './Titel';
import {TaakEditFragment, isTaakTabelFragment} from '../../../../../TaakEditModal';

export interface TitelConnectedProps extends ViewConfig {
    fragment: IKaartTitelConnectedFragment | TaakEditFragment;
}

export const TitelConnected: FC<TitelConnectedProps> = ({fragment, ...props}) => (
    <Titel
        titel={fragment.titel}
        id={fragment.id}
        {...props}
        readonly={!fragment.availableActions.kanTitelBewerken || props.readonly}
        fragment={isTaakTabelFragment(fragment) ? fragment : null}
    />
);
