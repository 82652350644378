import {FC} from 'react';
import {TakenCount} from '@growthbase/design-components';

export interface LijsttemplateTakenCountCounterProps {
    count: number;
}

export const LijsttemplateTakenCountCounter: FC<LijsttemplateTakenCountCounterProps> = ({count}) => (
    <TakenCount>{count}</TakenCount>
);
