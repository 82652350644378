import {useNow} from '@growthbase/spa';
import moment from 'moment';
import {useCallback, useEffect, useMemo, FC} from 'react';
import {isEqual} from 'lodash';
import {usePrevious} from 'react-use';
import {DatepickerField, MinimumDateOptions, useCreateStrictForm} from '../Form';
import {DateRangeOptions, ISOStringDateRange, useDateRangeSchema, useDefaultLabels} from './Hook';
import {ButtonGroup} from '../Buttons';
import styles from './DateRangePicker.module.scss';
import {Buttons} from '../Button';
import {normalizeDateRangeValue} from './Normalize/normalizeDateRangeValue';

export interface DateRangePickerContentProps {
    disabled?: boolean;
    labels?: Partial<DateRangeOptions>;
    value: ISOStringDateRange;
    onSubmit: (value: ISOStringDateRange) => Promise<void>;
    minimumEndDate?: MinimumDateOptions;
}

export const DateRangePickerContent: FC<DateRangePickerContentProps> = ({
    labels,
    onSubmit,
    value,
    disabled,
    minimumEndDate,
}) => {
    const defaultLabels = useDefaultLabels(labels ?? {}, value);
    const now = useNow();
    const {
        Form,
        formProps,
        Fields: {FromField, ToField},
        formik,
    } = useCreateStrictForm<ISOStringDateRange>({
        onSubmit,
        disableReinitialize: true,
        schema: useDateRangeSchema({...defaultLabels, minimumEndDate}),
        createInitialValues: useCallback(() => value, [value]),
    });

    const previous = usePrevious(formik.values);

    useEffect(() => {
        const updated = normalizeDateRangeValue(
            formik.values,
            previous ?? {
                from: null,
                to: null,
            }
        );
        if (isEqual(updated, formik.values)) {
            return;
        }
        formik.setValues(updated);
    }, [formik, previous]);

    const initialValue: ISOStringDateRange = useMemo(
        () => ({
            from: {
                date: moment(value?.from?.date ?? now()).toISOString(),
                timeUsed: value?.from?.timeUsed ?? false,
            },
            to: {
                date: moment(value?.from?.date ?? now())
                    .add(1, 'day')
                    .toISOString(),
                timeUsed: value?.to?.timeUsed ?? false,
            },
        }),
        [now, value]
    );

    return (
        <Form {...formProps}>
            <div className={styles.dateRangePicker__content}>
                <FromField
                    disabled={disabled}
                    label={<>{defaultLabels.fromLabel}</>}
                    {...{
                        initialValue: initialValue?.from,
                    }}
                    compact
                    component={DatepickerField}
                />
                <ToField
                    disabled={disabled}
                    label={<>{defaultLabels.toLabel}</>}
                    compact
                    {...{
                        initialValue: initialValue?.to,
                        minimumDate: minimumEndDate,
                    }}
                    component={DatepickerField}
                />
            </div>
            <div className={styles.dateRangePicker__footer}>
                <ButtonGroup justify>
                    <Buttons.Delete
                        disabled={formik.isSubmitting || disabled}
                        fat
                        onClick={useCallback(() => {
                            formik.setValues({
                                from: null,
                                to: null,
                            });
                            formik.handleSubmit();
                        }, [formik])}
                    />
                    <Buttons.Save formik={formik} />
                </ButtonGroup>
            </div>
        </Form>
    );
};
