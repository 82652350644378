import {
    Line,
    Modal,
    ModalContent,
    ModalSize,
    PaddingVertical,
    PopupContent,
    PopupHeader,
    PopupTitle,
    Stack,
    usePopup,
} from '@growthbase/design-components';
import {AgendaViewStub, PlanningViewStub, useCurrentView} from '@growthbase/planning';
import React, {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {OpdrachtSelectieStub} from '../OpdrachtSelectie/OpdrachtSelectieStub';

export interface PlanningPopupProps {
    planningView?: ReactElement;
    agendaView?: ReactElement;
    capaciteitView?: ReactElement;
    sidebar: ReactElement;
    opdrachtSelector: ReactElement;
    footer?: ReactElement;
}

export const PlanningPopup = ({
    planningView = <PlanningViewStub />,
    agendaView = <AgendaViewStub />,
    capaciteitView = <PlanningViewStub />,
    opdrachtSelector = <OpdrachtSelectieStub />,
    sidebar,
    footer,
}: PlanningPopupProps) => {
    const currentView = useCurrentView();
    const {isExpanded} = usePopup();
    const {t} = useTranslation('Planning.nl');
    return (
        <Modal size={ModalSize.SIZE_XXL} autoHeight={false} expanded={isExpanded}>
            <PopupContent>
                <Stack>
                    <Stack.Item>
                        <PopupHeader expandable>
                            <PaddingVertical paddingSize="default">
                                <Stack gap="large" align="center" horizontal>
                                    <Stack.Item>
                                        <PopupTitle>{t('plan')}</PopupTitle>
                                    </Stack.Item>
                                    <Stack.Item>{opdrachtSelector}</Stack.Item>
                                </Stack>
                            </PaddingVertical>
                        </PopupHeader>
                    </Stack.Item>
                    <Stack.Item grow>
                        <Stack stretch horizontal>
                            <Stack.Item size="320px">
                                <div style={{height: '100%', borderRight: 'var(--border)'}}>{sidebar}</div>
                            </Stack.Item>
                            <Stack.Item overflow="hidden" size="calc(100% - 320px)" style={{position: 'relative'}}>
                                <Stack style={{position: 'absolute', width: '100%', height: '100%'}}>
                                    <Stack.Item shrink grow style={{overflow: 'hidden'}}>
                                        <ModalContent theme="gray">
                                            {currentView.group === 'planning' && planningView}
                                            {currentView.group === 'agenda' && agendaView}
                                            {currentView.group === 'capaciteit' && capaciteitView}
                                        </ModalContent>
                                    </Stack.Item>
                                    {footer && (
                                        <Stack.Item>
                                            <Line />
                                            {footer}
                                        </Stack.Item>
                                    )}
                                </Stack>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </PopupContent>
        </Modal>
    );
};
