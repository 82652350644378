import React, {ReactNode} from 'react';
import {IconSize} from '../Icon';
import {Icons} from '../icons';
import styles from './TakenCount.module.scss';

export interface TakenCountProps {
    children: ReactNode;
}

export const TakenCount = ({children}: TakenCountProps) => (
    <div className={styles.takenCount}>
        <Icons.ModuleTask size={IconSize.SIZE_3} className={styles.takenCount__icon} />
        {children}
    </div>
);
