import {
    Buttons,
    generateGroupKeyById,
    Padding,
    Popup,
    PopupAnchorProps,
    PopupHeader,
    PopupResultsGroup,
    PopupResultsHeader,
    PopupResultsItem,
    ScrollablePopupContent,
    SearchForm,
    SearchInput,
} from '@growthbase/design-components';
import React, {ReactNode, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useIsPromiseActive} from '@growthbase/spa';
import {ActiveItemSource, ActiveItemType, useTraversableOverview} from '@growthbase/keys';
import {RelatieValue} from '@growthbase/relaties';
import {IKoppelbaarType} from '@growthbase/graphql';

/* eslint-disable no-shadow */
export {IKoppelbaarType as KoppelbaarItemType};

export interface KoppelbaarItem {
    id: string;
    label: string;
    type: IKoppelbaarType;
    href?: string;
}

export const compareKoppelbaarItem = (a?: KoppelbaarItem | null, b?: KoppelbaarItem | null): boolean => {
    if (!a && !b) {
        return true;
    }
    if (!a || !b) {
        return false;
    }
    return a.id === b.id && a.type === b.type;
};

export type KoppelPopupItemGroupType = {
    id: string;
    title: string;
    items: KoppelbaarItem[];
};

export type KoppelPopupRelatieGroupType = {
    id: string;
    title: string;
    relatie: RelatieValue;
    itemgroepen: KoppelPopupItemGroupType[];
    displayTitle: boolean;
};

export interface KoppelPopupProps {
    onChangeRelation?: (value: RelatieValue | null) => void;
    relatiegroepen: KoppelPopupRelatieGroupType[];
    loading?: boolean;
    disabled?: boolean;
    onChangeQuery: (value: SearchInput) => Promise<void> | void;
    onClickItem: (changedItem: KoppelbaarItem) => Promise<void>;
    query: string | null;
    renderAnchor: (props: PopupAnchorProps) => ReactNode;
    item: KoppelbaarItem | null;
}

const defaultAnchor = ({reference, controls: {open}}: PopupAnchorProps) => (
    <Buttons.BrokenLinkUnlinkedAttachmentIconDotted ref={reference} onClick={open} />
);

const KoppelPopupInner = ({
    relatiegroepen,
    loading,
    disabled: disabledInput,
    onChangeQuery,
    query,
    item,
    onChangeRelation,
    onClickItem: onClickItemInput,
}: Omit<KoppelPopupProps, 'renderAnchor'>) => {
    const isEmpty = (!relatiegroepen || relatiegroepen.length === 0) && !loading;

    const {t} = useTranslation('spa_form.nl');

    const [isActive, onClickItem] = useIsPromiseActive(onClickItemInput);

    const disabled = disabledInput || isActive;

    const itemgroepen = useMemo(
        () => relatiegroepen.reduce((pre, group) => [...pre, ...group.itemgroepen], [] as KoppelPopupItemGroupType[]),
        [relatiegroepen]
    );
    const items = useMemo(
        () => itemgroepen.reduce((pre, group) => [...pre, ...group.items], [] as KoppelbaarItem[]),
        [itemgroepen]
    );

    const {setTopRef} = useTraversableOverview({
        items,
        type: ActiveItemType.Unknown,
        source: ActiveItemSource.PopupResultItem,
    });

    const content = isEmpty ? (
        <Padding>{t('noResultsFound')}</Padding>
    ) : (
        <>
            {relatiegroepen.map((relatiegroup) => (
                <React.Fragment key={relatiegroup.id}>
                    {relatiegroup.displayTitle && <PopupResultsHeader>{relatiegroup.title}</PopupResultsHeader>}
                    {relatiegroup.itemgroepen.map((itemgroep) => (
                        <PopupResultsGroup<KoppelbaarItem>
                            key={itemgroep.id}
                            title={itemgroep.title}
                            generateKey={generateGroupKeyById}
                            disabled={disabled}
                            items={itemgroep.items}
                        >
                            {(itemProps, itemOption) => (
                                <PopupResultsItem
                                    {...itemProps}
                                    onClick={
                                        onClickItem
                                            ? () => {
                                                  if (onChangeRelation) {
                                                      onChangeRelation(relatiegroup.relatie);
                                                  }
                                                  return onClickItem(itemOption);
                                              }
                                            : undefined
                                    }
                                    key={itemOption.id}
                                    asListItem
                                >
                                    {itemOption.label}
                                </PopupResultsItem>
                            )}
                        </PopupResultsGroup>
                    ))}
                </React.Fragment>
            ))}
        </>
    );

    return (
        <ScrollablePopupContent
            noPadding
            header={<PopupHeader title={t(item ? 'item.wijzig' : 'item.koppel')} />}
            contentHeader={
                <Padding>
                    <SearchForm
                        innerRef={setTopRef}
                        onChange={onChangeQuery}
                        value={{query: query ?? null}}
                        loading={!!loading}
                    />
                </Padding>
            }
        >
            {content}
        </ScrollablePopupContent>
    );
};

export const KoppelPopup = ({renderAnchor = defaultAnchor, ...rest}: KoppelPopupProps) => (
    <Popup renderAnchor={renderAnchor} testId="ItemKoppelPopup" arrow>
        <KoppelPopupInner {...rest} />
    </Popup>
);
