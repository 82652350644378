import React, {FC, ReactNode} from 'react';
import classNames from 'classnames/bind';
import styles from './HoverButtons.module.scss';

const cx = classNames.bind(styles);

export interface HoverButtonsProps {
    children?: ReactNode;
    buttons?: ReactNode;
    testId?: string;
    floating?: boolean;
}

export const HoverButtons: FC<HoverButtonsProps> = ({children, testId, buttons, floating}) => {
    const classes = cx('hoverButtons', {
        'hoverButtons--floating': floating,
    });

    return (
        <div className={classes} data-testid={testId}>
            <div className={cx('hoverButtons__content')}>{children}</div>
            {floating && (
                <div className={cx('hoverButtons__buttons')}>
                    <div className={cx('hoverButtons__buttonsInner')}>{buttons}</div>
                </div>
            )}
            {!floating && <div className={cx('hoverButtons__buttons')}>{buttons}</div>}
        </div>
    );
};
