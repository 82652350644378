import {useMemo, useState} from 'react';
import {Draggable} from '@mobiscroll/react';
import {useCallbackRef} from '@growthbase/spa';
import {Card, Font, formatTimeDigital, Padding, Stack} from '@growthbase/design-components';
import {DropEvent, IToegewezenBudgetFragment, useCurrentView, useSelectedAgenda} from '@growthbase/planning';
import {min} from 'lodash';

export interface DroppableBudgetProps {
    budget: IToegewezenBudgetFragment;
}

const dayInSeconds = 86398;
const halfDayInSeconds = 43200;

export const DroppableBudget = ({budget}: DroppableBudgetProps) => {
    const [draggable, setDraggable] = useState<HTMLDivElement>();
    const setDraggableElement = useCallbackRef((element: HTMLDivElement) => setDraggable(element));
    const selectedAgenda = useSelectedAgenda();
    const currentView = useCurrentView();

    const agendaId = selectedAgenda?.id;
    const agendaColor = selectedAgenda?.color;

    const {
        toegewezenSeconden,
        geplandeSeconden,
        budgetRegel: {
            dienst: {id: dienstId},
        },
        budgetRegelHouder: {id: opdrachtId, code, onderwerp, relatienaam},
        id: toegewezenBudgetId,
    } = budget;

    const maxSecondsToPlan = currentView.autoPlan ? dayInSeconds - 2 : halfDayInSeconds;
    const secondsToPlan = min([toegewezenSeconden - geplandeSeconden, maxSecondsToPlan]) ?? 0;

    const dragData: DropEvent = useMemo((): DropEvent => {
        const start = new Date();
        start.setHours(0, 0, 0, 0);

        const end = new Date();
        end.setHours(0, secondsToPlan / 60, 0, 0);

        return {
            start,
            end,
            title: `${code} - ${onderwerp} - ${relatienaam}`,
            seconden: secondsToPlan,
            opdrachtId,
            dienstId,
            color: agendaColor,
            toegewezenBudgetId,
            originalResource: agendaId,
        };
    }, [
        secondsToPlan,
        code,
        onderwerp,
        relatienaam,
        opdrachtId,
        dienstId,
        agendaColor,
        toegewezenBudgetId,
        agendaId,
    ]);

    return (
        <div ref={setDraggableElement}>
            <Card>
                <Padding paddingSize="default">
                    <Stack horizontal gap="small" align="start">
                        <Stack.Item shrink>
                            <Font.LineHeightClose>
                                <Font.Bold>{`${budget.budgetRegelHouder.code} - ${budget.budgetRegelHouder.onderwerp}`}</Font.Bold>
                            </Font.LineHeightClose>
                        </Stack.Item>
                        <Stack.Item pushItems="before">
                            {formatTimeDigital(budget.toegewezenSeconden - budget.geplandeSeconden)}
                        </Stack.Item>
                    </Stack>
                    <Stack.Item>
                        <Font.LineHeightClose>{budget.budgetRegelHouder.relatienaam}</Font.LineHeightClose>
                    </Stack.Item>
                    <Stack.Item>
                        <Font.LineHeightClose>{`${budget.budgetRegel.dienst.code} - ${budget.budgetRegel.dienst.titel}`}</Font.LineHeightClose>
                    </Stack.Item>
                </Padding>
            </Card>
            <Draggable element={draggable} dragData={dragData} />
        </div>
    );
};
