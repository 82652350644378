import {FC, memo, NamedExoticComponent, ReactNode, useMemo} from 'react';
import {SchemaOf} from 'yup';
import {upperFirst} from 'lodash';
import {Diff} from 'utility-types';
import {
    FieldWrapperControlled,
    FieldWrapperControlsProps,
    FieldWrapperControlledProps,
    FormikFieldProps,
} from '../Components';

export type StrictTypeFieldProps<TSchema extends object, TName extends keyof TSchema> = Omit<
    FieldWrapperControlledProps<TSchema, TName>,
    'name'
>;

export interface ComponentProps {
    placeholder?: string | null;
    label?: ReactNode;
    labelHidden?: boolean;
    controls?: FC<FieldWrapperControlsProps> | boolean;
    controlsPosition?: 'right' | 'bottom';
    debug?: boolean;
    readonly?: boolean;
    disabled?: boolean;
    compact?: boolean;
    enabledDuringSubmit?: boolean;
    hideWarningOnFocusLost?: boolean;
    inlineEditable?: boolean;
    testfieldName?: string;
}

export type FCOrCustomComponent<TProps extends object, TValue> = FC<TProps> & {
    useComponent: <TExtra extends object>(
        Component: FC<TExtra>
    ) => FC<ComponentProps & Diff<TExtra, FormikFieldProps<TValue>>>;
    formikName: keyof TProps;
    useMuiComponent: <TExtra extends object>(
        Component: FC<TExtra>
    ) => FC<ComponentProps & Diff<TExtra, FormikFieldProps<TValue>>>;
};

export type MappedStyledField<TSchema extends object> = {
    [TName in keyof Required<TSchema> as `${Capitalize<TName & string>}Field`]: FCOrCustomComponent<
        StrictTypeFieldProps<TSchema, TName>,
        TSchema[TName]
    >;
};

/**
 * StyledFields can be re-used and memorized to prevent remounting.
 */
const schemaFieldMap: Map<SchemaOf<unknown>, Map<string | undefined, MappedStyledField<object>>> = new Map();

export const styledFieldComponentName = (name: string) => upperFirst(`${name}Field`);

export const useNamedStyledFields = <T extends object>(
    schema: SchemaOf<T>,
    namePrefix?: string
): MappedStyledField<T> =>
    useMemo(() => {
        let current = schemaFieldMap.get(schema);
        const result = current?.get(namePrefix);
        if (result) {
            return result;
        }
        if (!current) {
            current = new Map<string | undefined, MappedStyledField<object>>();
            schemaFieldMap.set(schema, current);
        }
        const description = schema.describe();
        const mapped = Object.entries(description.fields).map(([name]) => {
            const fieldName = styledFieldComponentName(name);
            let formikName = name;
            if (namePrefix) {
                formikName = `${namePrefix}.${name}`;
            }
            /* eslint-disable react/display-name */
            const NewFieldComponent = memo((props: StrictTypeFieldProps<T, keyof T>) => (
                <FieldWrapperControlled name={formikName as keyof T} {...props} />
            )) as NamedExoticComponent<unknown>;
            (NewFieldComponent as unknown as {formikName: string}).formikName = formikName;
            NewFieldComponent.displayName = fieldName;
            const FieldComponent = NewFieldComponent;
            // @ts-expect-error We know this is a component.
            FieldComponent.useComponent = (Component: FC) => {
                const target = Component as {
                    mapped?: Record<string, FC>;
                };
                target.mapped ??= {};
                const id = `${namePrefix}.${name}`;
                if (!target.mapped[id]) {
                    target.mapped[id] = (props: object) => (
                        // @ts-expect-error We are creating a new component, only for type safety.
                        <NewFieldComponent {...props} component={Component} />
                    );
                    target.mapped[id].displayName = `useComponent${fieldName}`;
                }
                return target.mapped[id];
            };
            // @ts-expect-error We know this is a component.
            FieldComponent.useMuiComponent = (Component: FC) => {
                const target = Component as {
                    mapped?: Record<string, FC>;
                };
                target.mapped ??= {};
                const cacheId = `mui-${fieldName}`;
                if (!target.mapped[cacheId]) {
                    target.mapped[cacheId] = (props: object) => (
                        // @ts-expect-error We are creating a new component, only for type safety.
                        <NewFieldComponent {...props} component={Component} mui />
                    );
                    target.mapped[cacheId].displayName = `useMuiComponent${fieldName}`;
                }
                return target.mapped[cacheId];
            };
            return [fieldName, FieldComponent];
        });
        const map = Object.fromEntries(mapped);
        current.set(namePrefix, map);
        return map;
    }, [namePrefix, schema]);
