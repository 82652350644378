import {
    Buttons,
    SearchForm,
    Table,
    TableHeader,
    TableHeaderSection,
    useSearchField,
} from '@growthbase/design-components';
import React, {FC} from 'react';
import {CellProps, Column} from 'react-table';
import {DeveloperRoutes} from '@growthbase/health';
import {useModelData} from './hook/useModelData';

export interface DTO {
    id: string;
    name: string;
    shortName: string;
    isValid: boolean;
    shared: boolean;
    distinct: boolean;
    abstraction: boolean;
}

export interface Query {
    query?: string;
}

const ShowYes: FC<{value: boolean}> = ({value}) => <>{value ? 'Ja' : ''}</>;
const ShowNee: FC<{value: boolean}> = ({value}) => <>{value ? '' : 'Nee'}</>;

const ActionsCell: FC<CellProps<DTO>> = ({row}) => (
    <Buttons.EditPencil to={DeveloperRoutes.models_detail.useUrl({name: row.original.name})} />
);

const columns: ReadonlyArray<Column<DTO>> = [
    {
        Header: 'Short Name',
        accessor: 'shortName',
        minWidth: 300,
    },
    {
        Header: 'Is valid',
        accessor: 'isValid',
        Cell: ShowNee,
    },
    {
        Header: 'Shared',
        accessor: 'shared',
        Cell: ShowNee,
    },
    {
        Header: 'Distinct',
        accessor: 'distinct',
        Cell: ShowYes,
    },
    {
        Header: 'Abstraction',
        accessor: 'abstraction',
        Cell: ShowYes,
    },
    {
        Header: <div />,
        Cell: ActionsCell,
        id: 'actions',
        width: 'auto',
        minWidth: 90,
    },
];

export const ModelsTable: FC = () => {
    const [state, setState] = useSearchField('query');
    const pagination = useModelData(state.query ?? undefined);
    return (
        <Table<DTO>
            header={
                <TableHeader>
                    <TableHeaderSection>
                        {pagination.connections.length}/{pagination.totalCount}
                    </TableHeaderSection>

                    <TableHeaderSection>
                        <SearchForm loading={pagination.isLoading} value={state} onChange={setState} />
                    </TableHeaderSection>
                </TableHeader>
            }
            columns={columns}
            pagination={pagination}
        />
    );
};
