import {ThemeOptions} from '@mui/material/styles/createTheme';

export const AutoComplete: ThemeOptions['components'] = {
    MuiAutocomplete: {
        styleOverrides: {
            inputRoot: {
                borderRadius: 'var(--formBorderRadius)',
                paddingTop: 25,
                paddingBottom: 6,
            },
            input: {
                paddingTop: 0,
                paddingBottom: 0,
            },
        },
    },
};
