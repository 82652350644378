import {useEffect, useMemo, useState} from 'react';

export const useModalScrolled = () => {
    const overlay = document.querySelector('.ReactModal__Overlay');
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const scrollListener = () => {
            const scroll = overlay?.scrollTop ?? 0;
            setIsScrolled(scroll > 15);
        };

        overlay?.addEventListener('scroll', scrollListener);

        return () => {
            overlay?.removeEventListener('scroll', scrollListener);
        };
    }, [overlay]);

    return useMemo(() => isScrolled, [isScrolled]);
};
