import {RouteElementProps} from '@growthbase/routing';
import {LandingsPaginaBordConnected} from '@growthbase/takenlijst/bord';
import {useAbonnementBordenConnectedQuery} from '@growthbase/graphql';
import {useGebruikerHeeftToegang} from '@growthbase/design-components';
import React from 'react';
import {DefaultKoppelbaarItem, DefaultRelatieValue} from '@growthbase/spa';
import {useResolveOnderdeelVan} from './Hook/useResolveOnderdeelVan';

export type OverviewConnectedProps = RouteElementProps<{
    abonnementId: string;
    abonnementnummer: string;
    abonnementUuid: string;
}>;

export const OverviewConnected = ({params}: OverviewConnectedProps) => {
    if (params === undefined) {
        throw new Error('Logic exception.');
    }

    const {data, loading} = useAbonnementBordenConnectedQuery({
        variables: {
            id: params.abonnementUuid,
        },
    });

    const abonnement = data?.abonnement;
    const onderdeelVan = useResolveOnderdeelVan(params.abonnementUuid, abonnement);
    const toegang = useGebruikerHeeftToegang({
        toegang:
            data?.ingelogdeGebruiker?.availableActions.kanBordenBekijken &&
            data?.abonnement?.availableActions?.volledigeGegevensBekijken &&
            onderdeelVan !== null,
        enabled: !loading,
    });
    if (!toegang || !onderdeelVan) {
        return null;
    }

    return (
        <DefaultKoppelbaarItem.Provider id="OverviewConnected" value={onderdeelVan}>
            <DefaultRelatieValue.Provider id="OverviewConnected" value={onderdeelVan?.relatie}>
                <LandingsPaginaBordConnected />
            </DefaultRelatieValue.Provider>
        </DefaultKoppelbaarItem.Provider>
    );
};
