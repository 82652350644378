import React, {FC} from 'react';
import {
    BaseButtonProps,
    ConfirmModalButton,
    ModalContent,
    PopupHeader,
    ViewConfig,
} from '@growthbase/design-components';
import {Trans, useTranslation} from 'react-i18next';

export interface VerwijderButtonProps extends ViewConfig {
    kanVerwijderen: boolean;
    onClick: () => void;
    Button: FC<BaseButtonProps>;
    isSubmitting: boolean;
    naam: string;
}

export const VerwijderButton: FC<VerwijderButtonProps> = ({
    kanVerwijderen,
    onClick,
    Button,
    isSubmitting,
    disabled,
    readonly,
    naam,
}) => {
    const {t} = useTranslation('TakenlijstBord.nl');
    if (!kanVerwijderen) {
        return null;
    }
    return (
        <ConfirmModalButton
            header={<PopupHeader title={t('confirmDelete.Titel')} />}
            onSubmit={onClick}
            popupButtonContent={t('confirmDelete.Bevestigknop')}
            renderOpenButton={(props) => (
                <Button {...props} disabled={isSubmitting || disabled} readonly={readonly}>
                    {t('confirmDelete.Dropdownknop')}
                </Button>
            )}
        >
            <ModalContent>
                <div>
                    <p>
                        <Trans t={t} components={{strong: <strong />}} values={{naam: `<strong>${naam}</strong>`}}>
                            confirmDelete.Tekst
                        </Trans>
                    </p>
                </div>
            </ModalContent>
        </ConfirmModalButton>
    );
};
