import {FC, PropsWithChildren} from 'react';
import {ApolloCache, ApolloClient, ApolloLink, ApolloProvider, NormalizedCacheObject, Operation} from '@apollo/client';
import {Logger} from 'ts-log';
import {ExtractWarningFromResponseService} from '@growthbase/spa';
import {persistCache, SessionStorageWrapper} from 'apollo3-cache-persist';
import {createGraphQLCache} from './createGraphQLCache';
import {createApolloLink} from './createApolloLink';

export interface GraphQLDependencies {
    logger: Logger;
    config: {
        graphqlRoutes: {[key: string]: string};
        environment: string;
        locale: string;
    };
    extractWarningFromResponseService: ExtractWarningFromResponseService;
}

export interface GraphQLServices {
    graphqlCache: ApolloCache<NormalizedCacheObject>;
    graphqlClient: ApolloClient<NormalizedCacheObject>;
    graphqlProvider: FC<PropsWithChildren>;
    graphqlEnableLocalstorage: (services: GraphQLServices) => Promise<void>;
}

export const createGraphQLServices = (dependencies: GraphQLDependencies): GraphQLServices => {
    const graphqlCache = createGraphQLCache();

    const defaultLink = createApolloLink({
        graphqlRoute: dependencies.config.graphqlRoutes.default,
        locale: dependencies.config.locale,
        logger: dependencies.logger,
        extractWarningFromResponseService: dependencies.extractWarningFromResponseService,
    });
    let apolloLink: ApolloLink = defaultLink;

    const isPlanningContext = (operation: Operation): boolean => operation.getContext().endpoint === 'planning';

    if (dependencies.config.graphqlRoutes.planning) {
        const planningLink = createApolloLink({
            graphqlRoute: dependencies.config.graphqlRoutes.planning,
            locale: dependencies.config.locale,
            logger: dependencies.logger,
            extractWarningFromResponseService: dependencies.extractWarningFromResponseService,
        });

        apolloLink = ApolloLink.split(isPlanningContext, planningLink, defaultLink);
    }

    const createClient = () =>
        new ApolloClient<NormalizedCacheObject>({
            connectToDevTools: true,
            cache: graphqlCache,
            assumeImmutableResults: true,
            link: apolloLink,
            defaultOptions: {
                watchQuery: {
                    nextFetchPolicy: 'cache-first',
                    fetchPolicy: 'cache-and-network',
                    errorPolicy: 'all',
                },
                query: {
                    notifyOnNetworkStatusChange: true,
                },
            },
        });

    let graphqlClient = createClient();

    return {
        graphqlClient,
        graphqlCache,
        graphqlProvider: ({children}) => <ApolloProvider client={graphqlClient}>{children}</ApolloProvider>,
        graphqlEnableLocalstorage: async (services) => {
            await persistCache({
                cache: graphqlCache,
                storage: new SessionStorageWrapper(window.sessionStorage),
            });
            services.graphqlClient = createClient();
            graphqlClient = services.graphqlClient;
        },
    };
};
