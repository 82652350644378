import React from 'react';
import {Observable} from 'rxjs';

export interface CloseEvent {
    cancel: boolean;
}

export interface PopupControls {
    close: () => void;
    toggle: () => void;
    open: () => void;
    set: (open: boolean) => void;
    forceClose: () => void;
    isOpen: boolean;
    onClose: Observable<CloseEvent>;
    expand: () => void;
    collapse: () => void;
    isExpanded: boolean;
}

export const PopupControlsContext = React.createContext<PopupControls | null>(null);
