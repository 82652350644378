import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {mergeNavigateOptions, NavigateOptions} from '../Value';

export function createUseNavigateFactory<TParams, TState>(
    useCreateRoute: () => (params?: TParams, options?: NavigateOptions<TState>) => string
) {
    return (defaultOptions: NavigateOptions<TState> = {}) => {
        const createRoute = useCreateRoute();
        const navigate = useNavigate();
        return useCallback(
            (params?: TParams, options: NavigateOptions<TState> = {}) => {
                const combined = mergeNavigateOptions([defaultOptions, options]);
                navigate(createRoute(params, combined), {
                    replace: combined.replace,
                    state: combined.state,
                });
            },
            [createRoute, navigate, defaultOptions]
        );
    };
}
