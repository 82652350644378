import {useContext, useEffect, useState} from 'react';
import {BreadcrumbContext, BreadcrumbContextType, Breadcrumb} from '../contexts/BreadcrumbContext';

export const useBreadcrumbContext = (): BreadcrumbContextType => {
    const context = useContext(BreadcrumbContext);
    if (context) {
        return context;
    }
    throw new Error('Wrap breadcrumb in breadcrumb provider');
};

export const useAddBreadCrumb = ({to, title, order, excludeForChildren, asPageTitle = true}: Breadcrumb) => {
    const {addBreadcrumb} = useBreadcrumbContext();

    useEffect(() => {
        const cleanup = addBreadcrumb({
            title,
            to,
            order,
            asPageTitle,
            excludeForChildren,
        });
        return () => cleanup();
    }, [
        asPageTitle,
        addBreadcrumb,
        to,
        title,
        order,
        excludeForChildren,
    ]);
};

export const useBreadcrumbs = (): Breadcrumb[] => {
    const {context, breadcrumbs} = useBreadcrumbContext();
    const [currentBreadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>(breadcrumbs);

    useEffect(() => {
        const subscription = context.subscribe((event) => {
            setBreadcrumbs(event.breadcrumbs);
        });
        setBreadcrumbs([...breadcrumbs]);
        return () => subscription.unsubscribe();
    }, [context, setBreadcrumbs, breadcrumbs]);

    return currentBreadcrumbs;
};
