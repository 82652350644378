import {RadioSwitch} from '@growthbase/design-components';
import {GroupBy, usePlanningSetGroupBy, useCurrentView} from '@growthbase/planning';
import {useTranslation} from 'react-i18next';
import {useCallbackRef} from '@growthbase/spa';
import {AgendaGroupBy} from '../../Views/AgendaView/AgendaViewsFormats';

export const PlanningGroupBySwitch = () => {
    const currentViewFormat = useCurrentView();
    const setGroupBy = usePlanningSetGroupBy();

    const {t} = useTranslation('spa.nl');

    const options = AgendaGroupBy.map((groupBy) => ({
        label: t(groupBy.label),
        value: groupBy,
    }));

    const value = AgendaGroupBy.find((groupBy) => groupBy.groupBy === currentViewFormat.groupBy) || null;

    const onChange = useCallbackRef((changedValue: GroupBy) => {
        setGroupBy({
            groupBy: changedValue.groupBy,
        });
    });

    if (!currentViewFormat.groupBy) {
        return <></>;
    }

    return <RadioSwitch<GroupBy> value={value} items={options} onChange={onChange} />;
};
