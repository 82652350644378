/* eslint-disable no-continue */
import {createDispatchHook} from '@growthbase/spa';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {useSelector} from 'react-redux';
import {WritableDraft} from 'immer/dist/types/types-external';
import {FullNestedName, InstellingField, InstellingValues} from './types';
import {setValueToPath, valueFromPath} from './Helpers';
import {fieldIsControlledBy, Reason} from './FieldControlledByService';

export type InstellingenId = string;

export interface InstellingenFormState {
    fields: Record<FullNestedName, InstellingField>;
    overrides: Record<FullNestedName, Reason | null>;
    values: InstellingValues;
    valuesWithOverrides: InstellingValues;
}

export interface InstellingenState {
    forms: Record<InstellingenId, InstellingenFormState>;
}

export interface InstellingenStoreState {
    instellingenFrom: InstellingenState;
}

const initialMessageState: InstellingenState = {
    forms: {},
};

function updateOverrides(state: WritableDraft<InstellingenState>): void {
    for (const form of Object.values(state.forms)) {
        const overrides: Record<FullNestedName, Reason | null> = {};
        const fields = Object.values(form.fields) as InstellingField[];
        for (const field of fields) {
            fieldIsControlledBy(fields, form.values, field, overrides);
        }
        form.overrides = overrides;
        for (const field of fields) {
            const {name} = field;
            if ('exclude' in field && field.exclude) {
                continue;
            }
            if ('fields' in field) {
                continue;
            }
            const overridden = overrides[name];
            if (overridden !== null && overridden.useValue) {
                setValueToPath(form.valuesWithOverrides, name, overridden.value);
                continue;
            }

            const value: unknown = valueFromPath(form.values, name);
            setValueToPath(form.valuesWithOverrides, name, value);
        }
    }
}

export const InstellingenSlice = createSlice({
    name: 'Message',
    initialState: initialMessageState,
    reducers: {
        values: (
            state,
            action: PayloadAction<{
                id: InstellingenId;
                fields: InstellingField[];
                values: InstellingValues;
            }>
        ) => {
            const {id, fields, values} = action.payload;
            state.forms[id] ??= {
                fields: {},
                overrides: {},
                values,
                valuesWithOverrides: {},
            };
            state.forms[id].fields = Object.fromEntries(fields.map((f) => [f.name, f]));
            state.forms[id].values = values;
            updateOverrides(state);
        },
        unmount: (state, action: PayloadAction<InstellingenId>) => {
            delete state.forms[action.payload];
        },
    },
});

export const {values} = InstellingenSlice.actions;

export const useUpdateExternals = createDispatchHook(values);
export const useDestroy = createDispatchHook(InstellingenSlice.actions.unmount);
export const useInstellingenForm = (id: InstellingenId) =>
    useSelector(({instellingenFrom: {forms}}: InstellingenStoreState): InstellingenFormState => forms[id]);

export const useInstellingenField = (id: InstellingenId, name: FullNestedName) =>
    useSelector(({instellingenFrom: {forms}}: InstellingenStoreState): InstellingField => {
        const found = forms[id]?.fields[name];
        if (!found) {
            throw new Error(`InstellingenField not found: ${name}`);
        }
        return found;
    });

export const useInstellingenValue = (id: InstellingenId, name: FullNestedName) =>
    useSelector(({instellingenFrom: {forms}}: InstellingenStoreState): unknown =>
        valueFromPath(forms[id]?.valuesWithOverrides, name)
    );

export const useInstellingenValues = (id: InstellingenId) =>
    useSelector(({instellingenFrom: {forms}}: InstellingenStoreState): unknown => forms[id]?.valuesWithOverrides ?? {});

export const useOptionalInstellingenForm = (id: InstellingenId) =>
    useSelector(({instellingenFrom: {forms}}: InstellingenStoreState) => forms[id] ?? null);

export const InstellingenReducer = InstellingenSlice.reducer;

export const useFieldIsOverridden = (id: InstellingenId, name: FullNestedName) =>
    useSelector(({instellingenFrom: {forms}}: InstellingenStoreState): null | Reason => {
        const form = forms[id];
        if (!form) {
            return null;
        }
        return form.overrides[name];
    });
