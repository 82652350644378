import classNames from 'classnames/bind';
import {FC, PropsWithChildren} from 'react';
import styles from './font.module.scss';

const cx = classNames.bind(styles);

export const Information: FC<PropsWithChildren> = ({children}) => (
    <span className={cx('font', 'font--information')}>{children}</span>
);

const Gray: FC<PropsWithChildren> = ({children}) => <span className={cx('font', 'font--gray')}>{children}</span>;
const LineTrough: FC<PropsWithChildren> = ({children}) => (
    <span className={cx('font', 'font--lineTrough')}>{children}</span>
);
const Small: FC<PropsWithChildren> = ({children}) => <span className={cx('font', 'font--small')}>{children}</span>;
const Heading: FC<PropsWithChildren> = ({children}) => <span className={cx('font', 'font--heading')}>{children}</span>;
const Bold: FC<PropsWithChildren> = ({children}) => <span className={cx('font', 'font--bold')}>{children}</span>;
const LineHeightClose: FC<PropsWithChildren> = ({children}) => (
    <span className={cx('font', 'font--lineHeightClose')}>{children}</span>
);

export const Font = {Info: Information, Heading, Gray, Bold, LineHeightClose, LineTrough, Small};
