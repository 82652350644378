import {ReduxUserDependencies} from '@growthbase/spa';
import {GraphQLServices} from '@growthbase/graphql';
import {PlanningReducer} from './Redux';
import {planningSaveStateEpic} from './Redux/PlanningSaveStateEpic';

export const createPlanningServices = ({
    reducers = {},
    epics = [],
    graphqlClient,
}: ReduxUserDependencies & GraphQLServices): unknown => ({
    reducers: {
        ...reducers,
        planning: PlanningReducer,
    },
    epics: [...epics, planningSaveStateEpic(graphqlClient)],
});
