import React, {ReactNode} from 'react';
import {BoardCard, Stack} from '@growthbase/design-components';

export interface CardProps {
    header: ReactNode;
    locatie: ReactNode;
    deelnemers: ReactNode;
    hasDeelnemers: boolean;
    hasLocatie: boolean;
}

export function Card({header, deelnemers, locatie, hasDeelnemers, hasLocatie}: CardProps) {
    return (
        <BoardCard>
            <Stack gap="default">
                <Stack.Item pushItems="after">{header}</Stack.Item>
                {hasLocatie && <Stack.Item>{locatie}</Stack.Item>}
                <Stack.Item>
                    <Stack gap="small" horizontal>
                        <Stack.Item>{deelnemers}</Stack.Item>
                        {!hasLocatie && <Stack.Item>{locatie}</Stack.Item>}
                    </Stack>
                </Stack.Item>
            </Stack>
        </BoardCard>
    );
}
