import {distinct, Subject} from 'rxjs';
import {serializeError} from 'serialize-error';
import {Dictionary} from 'ts-essentials';
import {Logger} from 'ts-log';
import {HttpClientInterface} from '../HTTP';
import {ErrorMessageDTO} from './DTO';
import {ErrorContext} from './Value';

export interface ErrorClientInterface {
    sendError(error: unknown, context: ErrorContext): void;
}

/**
 * Only send same errors once.
 */
export class ErrorClient implements ErrorClientInterface {
    private distinctStream: Subject<ErrorMessageDTO> = new Subject();

    constructor(
        private client: HttpClientInterface,
        private defaultContext: Dictionary<string | number>,
        private logger: Logger,
        private errorRoute: string
    ) {
        this.distinctStream.pipe(distinct(({message}) => message)).subscribe({
            next: (dto) => {
                this.client.post(this.errorRoute, dto).subscribe({
                    error: (e) => this.logger.error(e),
                });
            },
        });
    }

    public sendError(error: unknown, context: ErrorContext): void {
        const serialized = serializeError(error);
        const dto: ErrorMessageDTO = {
            name: `${serialized.name}`,
            message: `${serialized.message}`,
            stack: serialized.stack,
            context: {...this.defaultContext, ...context},
        };
        this.distinctStream.next(dto);
    }
}
