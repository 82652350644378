import React, {memo, useMemo, FC} from 'react';
import {idFromIri, usePaginationSearchParams} from '@growthbase/routing';
import {
    ITakenTabelVanLijstFragment,
    ITakenTabelVanLijstRowFragment,
    useTakenTabelVanLijstQuery,
} from '@growthbase/graphql';
import {usePollInterval} from '@growthbase/websockets';
import {RenderTaakTabelProps, TaakTabel} from '@growthbase/taken';
import {TaakFilters, OverviewType} from '../../../../types';
import {useChangeOrder} from './Hook/useChangeOrder';
import {useMaakTaakInLijstHandler} from './Hook/useMaakTaakInLijstHandler';
import {useTaakTabelEntityChanges} from './Hook/useTaakTabelEntityChanges';

export interface TakenTabelVanLijstConnectedProps extends Omit<RenderTaakTabelProps, 'filters'> {
    fragment: ITakenTabelVanLijstFragment;
    filters: TaakFilters;
}

export const TakenTabelVanLijstConnected: FC<TakenTabelVanLijstConnectedProps> = memo(
    ({
        fragment: {
            id,
            availableActions: {kanTakenToevoegen, kanVolgordeVeranderenVanTaken},
        },
        filters,
        ...rest
    }) => {
        const {pagination, usePagination} = usePaginationSearchParams<ITakenTabelVanLijstRowFragment>('taak');
        const result = useTakenTabelVanLijstQuery({
            pollInterval: usePollInterval(),
            variables: {
                ...pagination,
                fulltextSearch: filters.searchQuery,
                deelnemers: filters.deelnemers ? filters.deelnemers : undefined,
                gearchiveerd: filters.overviewType === OverviewType.Archief,
                voltooid:
                    filters.overviewType === OverviewType.ToonAlles || filters.overviewType === OverviewType.Archief
                        ? undefined
                        : filters.overviewType === OverviewType.Voltooid,
                takenDieIkVolg: filters.overviewType === OverviewType.Volgend ? true : undefined,
                lijstId: idFromIri(id),
            },
        });

        useTaakTabelEntityChanges({
            lijstId: id,
            filters,
            result,
        });

        const {onDrop, onRemove} = useChangeOrder(id, result);

        const userData = useMemo(() => ({id}), [id]);

        return (
            <TaakTabel<ITakenTabelVanLijstRowFragment>
                {...rest}
                enableAdd={kanTakenToevoegen && filters.overviewType !== OverviewType.Archief}
                pagination={usePagination(result)}
                onSubmit={useMaakTaakInLijstHandler(id, result)}
                error={result.error}
                dragProps={useMemo(() => {
                    if (!kanVolgordeVeranderenVanTaken) {
                        return {
                            userData,
                        };
                    }
                    return {
                        onDrop,
                        userData,
                        onRemove,
                    };
                }, [onDrop, onRemove, userData, kanVolgordeVeranderenVanTaken])}
            />
        );
    }
);

TakenTabelVanLijstConnected.displayName = 'TakenTabelVanLijstConnected';
