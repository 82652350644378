import React, {useCallback} from 'react';
import {
    Buttons,
    StyledSelectInput,
    TimeFieldFormikComponent,
    useCreateStrictForm,
    usePopup,
} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {AgendaModuleType, PlanningStoreState, useCurrentView, usePlanningSetTimePreference} from '@growthbase/planning';
import {Grid} from '@mui/material';
import {ViewSettingsFormSchema} from './ViewSettingsFormSchema';

export interface PlanningViewSettingsFormValues {
    from: string;
    to: string;
    dayFrom: string;
    dayTo: string;
}

export const ViewSettingsForm = ({module}: {module: AgendaModuleType}) => {
    const {close} = usePopup();
    const {t} = useTranslation('spa.nl');

    const {settings} = useSelector((state: PlanningStoreState) => state.planning);
    const currentViewFormat = useCurrentView(module);
    const {timeStart, timeEnd, dayEnd, dayStart} = settings;
    const setTimePreference = usePlanningSetTimePreference();

    const onSubmit = useCallback(
        (values: PlanningViewSettingsFormValues) => {
            setTimePreference({
                timeStart: values.from,
                timeEnd: values.to,
                dayStart: values.dayFrom,
                dayEnd: values.dayTo,
            });
            close();
            return Promise.resolve();
        },
        [setTimePreference, close]
    );

    const defaultValues = useCallback(
        () => ({
            from: timeStart,
            to: timeEnd,
            dayFrom: dayStart,
            dayTo: dayEnd,
        }),
        [dayEnd, dayStart, timeEnd, timeStart]
    );

    const {
        Form,
        formProps,
        Fields: {FromField, ToField, DayToField, DayFromField},
        formik,
    } = useCreateStrictForm<PlanningViewSettingsFormValues>({
        onSubmit,
        disableReinitialize: false,
        autoSubmit: false,
        schema: ViewSettingsFormSchema,
        createInitialValues: defaultValues,
    });

    const options = [
        {value: '1', label: 'Maandag'},
        {value: '2', label: 'Dinsdag'},
        {value: '3', label: 'Woensdag'},
        {value: '4', label: 'Donderdag'},
        {value: '5', label: 'Vrijdag'},
        {value: '6', label: 'Zaterdag'},
        {value: '7', label: 'Zondag'},
    ];

    const DayTo = DayToField.useComponent(StyledSelectInput);
    const DayFrom = DayFromField.useComponent(StyledSelectInput);

    return (
        <Form {...formProps} formik={formik}>
            <Grid spacing={2} container style={{maxWidth: 350}}>
                {currentViewFormat?.settings?.days && (
                    <Grid item xs={6}>
                        <DayFrom label={t('planning.instellingen.eersteDag')} options={options} />
                    </Grid>
                )}
                {currentViewFormat?.settings?.days && (
                    <Grid item xs={6}>
                        <DayTo label={t('planning.instellingen.laatsteDag')} options={options} />
                    </Grid>
                )}
                {currentViewFormat?.settings?.time && (
                    <Grid item xs={6}>
                        <FromField label={t('planning.instellingen.startTijd')} component={TimeFieldFormikComponent} />
                    </Grid>
                )}
                {currentViewFormat?.settings?.time && (
                    <Grid item xs={6}>
                        <ToField label={t('planning.instellingen.eindTijd')} component={TimeFieldFormikComponent} />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Buttons.Primary buttonType="submit">Opslaan</Buttons.Primary>
                </Grid>
            </Grid>
        </Form>
    );
};
