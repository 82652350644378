import {FC, useMemo} from 'react';
import {isNumber} from 'lodash';

export interface FileSizeProps {
    bytes?: number | null;
}

export const FileSize: FC<FileSizeProps> = ({bytes}) => {
    const text = useMemo(() => {
        if (!isNumber(bytes)) {
            return '-';
        }

        if (bytes < 1024) {
            return `${bytes} B`;
        }

        const kb = bytes / 1024;

        if (kb < 1024) {
            return `${kb.toFixed(2)} KB`;
        }

        const mb = kb / 1024;

        if (mb < 1024) {
            return `${mb.toFixed(2)} MB`;
        }

        const gb = mb / 1024;

        return `${gb.toFixed(2)} GB`;
    }, [bytes]);
    return <span>{text}</span>;
};
