import React, {useCallback} from 'react';
import {AgendaModuleType, ListViewFormat, ViewFormat} from '@growthbase/planning';
import {PlanningHeader} from '@growthbase/design-components';
import {AgendaAgendaViewFormats} from '../../../../agenda/src/Agenda/AgendaViewsFormats';
import {PlanningAgendaViewFormats} from '../../Views/AgendaView/AgendaViewsFormats';
import {CapacityViewFormats} from '../../Views/CapaciteitView/CapaciteitViewFormats';
import {PlanningViews} from '../../Views/PlanningView/PlanningViewFormats';

export const useRenderHeader = (viewFormats: ViewFormat[], module?: AgendaModuleType) =>
    useCallback(() => {
        let availableFormats: ViewFormat[] = [];
        switch (module) {
            case 'agenda':
                availableFormats = AgendaAgendaViewFormats;
                break;
            case 'opdracht-plan-popup':
                availableFormats = [...PlanningAgendaViewFormats, ...CapacityViewFormats, ...PlanningViews];
                break;
            default:
                availableFormats = [
                    ...PlanningAgendaViewFormats,
                    ...CapacityViewFormats,
                    ...PlanningViews,
                    ListViewFormat,
                ];
        }

        return (
            <PlanningHeader
                viewFormats={viewFormats}
                availableFormats={availableFormats}
                module={module || 'planning'}
            />
        );
    }, [viewFormats, module]);
