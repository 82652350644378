import {ITaakTabelFragment} from '@growthbase/graphql';
import {useTranslation} from 'react-i18next';
import {FC, useMemo} from 'react';
import {CellProps} from 'react-table';
import {ButtonGroup, Columns, ShowOnRowHover, StatusIconButton} from '@growthbase/design-components';
import {BijlagesTooltipConnected} from '@growthbase/taak-bijlage';
import {TimeRegistrationButtonConnected, useLegacyTimeRegistrationIsActive} from '@growthbase/clientbox-uren';
import {TaakActionMenuConnected} from '../../TaakActionMenu';
import {TaakPlanningButtonConnected} from '../../TaakPlanningButton';
import {VoltooiOfActiveerTaakButtonConnected} from '../../VoltooiOfActiveerTaakButton';
import {TaakReactieCounterConnected} from '../../TaakReactieCounter';
import {DetailedTitleCell} from '../Component/DetailedTitleCell';
import {TitleCell} from '../Component/TitleCell';
import {TaakMijnDagButtonConnected} from '../../TaakMijnDagButton';
import {DeelnemersConnected} from '../../Deelnemers/DeelnemersConnected';
import {PriveFieldConnected} from '../../PriveField';
import {TaakOmschrijvingTooltipConnected} from '../../TaakOmschrijvingTooltip/TaakOmschrijvingTooltipConnected';

const VoltooiTaakButtonCell: FC<CellProps<ITaakTabelFragment>> = ({row}) => (
    <ButtonGroup disableRightMargin>
        <VoltooiOfActiveerTaakButtonConnected fragment={row.original} SubmitButton={StatusIconButton} />
    </ButtonGroup>
);

const DeelnemersCell: FC<CellProps<ITaakTabelFragment>> = ({row}) => (
    <DeelnemersConnected size="small" stackSize={4} fragment={row.original} />
);

const OmschrijvingsCell: FC<CellProps<ITaakTabelFragment>> = ({row}) => null;

const BijlageCell: FC<CellProps<ITaakTabelFragment>> = ({row}) => {
    const {actief, profiel} = useLegacyTimeRegistrationIsActive(row.original.id);
    return (
        <ButtonGroup align="right">
            <ShowOnRowHover forceVisible={actief}>
                {profiel && <TimeRegistrationButtonConnected profielFragment={profiel} taakFragment={row.original} />}
            </ShowOnRowHover>
            <BijlagesTooltipConnected fragment={row.original} />
            <TaakOmschrijvingTooltipConnected fragment={row.original} />
        </ButtonGroup>
    );
};

const DeadLineButtonCell: FC<CellProps<ITaakTabelFragment>> = ({row}) => (
    <ShowOnRowHover forceVisible={!!(row.original.planning?.deadline || row.original.planning?.begindatum)}>
        <TaakPlanningButtonConnected asText fragment={row.original} />
    </ShowOnRowHover>
);

const ReactiesButtonCell: FC<CellProps<ITaakTabelFragment>> = ({row}) => (
    <TaakReactieCounterConnected fragment={row.original} />
);

const ActionsCell: FC<CellProps<ITaakTabelFragment>> = ({row}) => (
    <ButtonGroup align="right">
        <ShowOnRowHover forceVisible={row.original.priveVoorMij}>
            <PriveFieldConnected fragment={row.original} />
        </ShowOnRowHover>
        <ShowOnRowHover forceVisible={row.original.taakIsVoorVandaag}>
            <TaakMijnDagButtonConnected fragment={row.original} />
        </ShowOnRowHover>
        <TaakActionMenuConnected fragment={row.original} />
    </ButtonGroup>
);

export const useTaakColumns = ({showTaskDetails}: {showTaskDetails?: boolean}) => {
    const {t} = useTranslation('spa_form.nl');
    return useMemo(
        (): Columns<ITaakTabelFragment> => [
            {
                Header: <span aria-label="Status" />,
                id: 'select',
                Cell: VoltooiTaakButtonCell,
                resizable: false,
                minWidth: 47,
                maxWidth: 0,
            },
            {
                Header: <>{t('taak.table.title')}</>,
                accessor: 'titel',
                Cell: showTaskDetails ? DetailedTitleCell : TitleCell,
                width: 100,
            },
            {
                Header: <></>,
                id: 'omschrijving',
                width: 'auto',
                minWidth: 10,
                Cell: OmschrijvingsCell,
            },
            {
                Header: <></>,
                id: 'bijlage',
                width: 'auto',
                minWidth: 10,
                Cell: BijlageCell,
            },
            {
                Header: <>{t('taak.table.deelnemers')}</>,
                id: 'deelnemers',
                minWidth: 150,
                maxWidth: 0,
                Cell: DeelnemersCell,
            },
            {
                Header: <>{t('taak.table.deadline')}</>,
                id: 'deadline',
                Cell: DeadLineButtonCell,
                minWidth: 160,
                maxWidth: 0,
            },
            {
                Header: <>{t('taak.table.reacties')}</>,
                id: 'reacties',
                minWidth: 150,
                maxWidth: 0,
                Cell: ReactiesButtonCell,
            },
            {
                Header: <div />,
                Cell: ActionsCell,
                id: 'actions',
                width: 'auto',
                minWidth: 150,
            },
        ],
        [t, showTaskDetails]
    );
};
