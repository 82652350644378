import React, {useCallback} from 'react';
import {AddBreadcrumb, Loader, Stack} from '@growthbase/design-components';
import {useProfielDetailsQuery} from '@growthbase/graphql';
import {LogsTableConnected, RenderFilterProps} from '../LogsTable';
import {ReplayButtonConnected} from '../ReplayButton/ReplayButtonConnected';
import {ProcessesTable} from '../ProcessesPage';
import {DisplayProgress} from '../ProcessesPage/Components/DisplayProgress';

export interface ProjectionPageProps {
    id: string;
}

export const ProfielDetails: React.FC<ProjectionPageProps> = ({id}) => {
    const query = useProfielDetailsQuery({
        variables: {
            id,
            filters: {
                profielId: id,
                actief: true,
                name: 'Events',
            },
        },
        pollInterval: 2000,
    });
    const {data} = query;
    const dto = data?.projectionProfiel;

    const renderProcess = useCallback(
        (filters: RenderFilterProps) => <ProcessesTable profileId={id} {...filters} />,
        [id]
    );

    if (!dto) {
        return <Loader center />;
    }

    return (
        <Stack gap="medium">
            <AddBreadcrumb>{dto.id}</AddBreadcrumb>
            <h1>Profiel {dto.id} </h1>
            <LogsTableConnected
                renderProcess={renderProcess}
                infoContent={
                    <Stack.Item grow>
                        <Stack>
                            <p>
                                <ReplayButtonConnected profileId={id}>Replay van dit profiel</ReplayButtonConnected>
                            </p>
                            <p>Name: {dto.name}</p>
                            <p>Warning: {dto.warnings}</p>
                            <p>Errors: {dto.errors}</p>
                            <p>Emergency: {dto.emergency}</p>
                            <div>Events: {data?.active[0] && <DisplayProgress fragment={data?.active[0]} />}</div>
                        </Stack>
                    </Stack.Item>
                }
                profielId={id}
            />
        </Stack>
    );
};
