import {Store} from '@reduxjs/toolkit';
import {TFunction} from 'i18next';
import {isObject, has} from 'lodash';
import {Logger} from 'ts-log';
import {v4} from 'uuid';
import {GraphQLWarningsDTOSchema} from './DTO/GraphQLWarningDTO';
import {appendMessages} from './Redux';

export class ExtractWarningFromResponseService {
    // TODO: dependency injection is not working here, so we have to use a static property.
    public static store: Store;

    public static logger: Logger;

    public constructor(private t: TFunction) {}

    public extract(normalized: unknown): void {
        if (!isObject(normalized)) {
            return;
        }
        if (has(normalized, 'warnings')) {
            this.error(normalized);
        }
    }

    /**
     * Extracts warnings from error and dispatches them to the store
     *
     * Tries to extract warnings from Symfony response errors or GraphQL warnings.
     */
    public error(error: unknown): void {
        const data = ExtractWarningFromResponseService.dataFromError(error);
        const responseWarnings = data?.warnings;
        const responseErrors = data?.errors;
        let handled = false;
        if (responseWarnings && this.handleResponseWarning(responseWarnings)) {
            handled = true;
        }
        if (responseErrors && this.handleResponseWarning(responseErrors)) {
            handled = true;
        }
        if (handled) {
            return;
        }
        if (data && typeof data.error === 'string') {
            ExtractWarningFromResponseService.logger.error(data.error);
            ExtractWarningFromResponseService.store.dispatch(
                appendMessages([
                    {
                        id: v4(),
                        type: 'error',
                        message: data.error,
                    },
                ])
            );
        }
        const symfonyDebug = data?.detail;
        if (symfonyDebug && data?.trace && typeof symfonyDebug === 'string') {
            ExtractWarningFromResponseService.logger.error('Symfony error:', symfonyDebug);
            ExtractWarningFromResponseService.store.dispatch(
                appendMessages([
                    {
                        id: v4(),
                        type: 'error',
                        message: symfonyDebug,
                    },
                ])
            );
            return;
        }
        if (typeof error === 'string' && error.includes('Error id')) {
            ExtractWarningFromResponseService.logger.error('Error:', error);
            ExtractWarningFromResponseService.store.dispatch(
                appendMessages([
                    {
                        id: v4(),
                        type: 'error',
                        message: error,
                    },
                ])
            );
            return;
        }

        ExtractWarningFromResponseService.logger.error('Unknown error:', error);
        ExtractWarningFromResponseService.store.dispatch(
            appendMessages([
                {
                    id: v4(),
                    type: 'error',
                    message: `${this.t('an_unknown_error_occurred', {ns: 'spa.nl'})} (${error})`,
                },
            ])
        );
    }

    private handleResponseWarning(responseWarnings: number | string | boolean) {
        try {
            const warnings = GraphQLWarningsDTOSchema.validateSync(responseWarnings);
            ExtractWarningFromResponseService.store.dispatch(
                appendMessages(
                    warnings.map((warning) => {
                        const type = warning.type === 'warning' ? 'warning' : 'error';
                        ExtractWarningFromResponseService.logger[type]('GraphQL warnings', warnings);
                        return {
                            id: v4(),
                            message: warning.message,
                            type,
                        };
                    })
                )
            );

            return true;
        } catch (e) {
            // ignore error and continue with default error message.
            return false;
        }
    }

    private static dataFromError(error: unknown): Record<string, number | string | boolean> | null {
        if (typeof error !== 'object' || !error) {
            return null;
        }
        const {response} = error as {response?: {data?: Record<string, number | string | boolean>}};
        return response?.data ?? null;
    }
}
