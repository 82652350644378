import {OpgemaakteTekstElement, OpgemaakteTekstParagraph} from '../Hook/useOpgemaakteTekstSchema';

export const createParagraph = (
    children: OpgemaakteTekstElement[] | OpgemaakteTekstElement | string
): OpgemaakteTekstParagraph => {
    if (children instanceof Array) {
        return {
            type: 'p',
            children,
        };
    }

    return {
        type: 'p',
        children: typeof children === 'string' ? [{text: children}] : [children],
    };
};
