import React from 'react';
import {CheckboxComponent, FieldWrapperControlled} from '@growthbase/design-components';
import {InstellingInputProps} from './InstellingInputWrapper';

export const BooleanInstellingInput = ({label, name, override}: InstellingInputProps) => {
    type Type = Record<typeof name, boolean>;
    const extraProps: Record<string, unknown> = {};

    if (override) {
        extraProps.disabled = true;
        if (override.useValue) {
            extraProps.value = override.value;
            if (override.value) {
                extraProps.checked = override.value;
            }
        }
    }

    return (
        <FieldWrapperControlled<Type, keyof Type>
            type="checkbox"
            label={label}
            labelHidden
            component={CheckboxComponent}
            name={name}
            {...extraProps}
        />
    );
};
