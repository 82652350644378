import {AddBreadcrumb, LoaderCenter, Page} from '@growthbase/design-components';
import {TicketLegacyRoutes} from '@growthbase/tickets-tickets';
import {useTranslation} from 'react-i18next';
import {FC, PropsWithChildren} from 'react';

export interface PaginaProps extends PropsWithChildren {
    ticketNaam: string;
    ticketId: string;
    ticketnummer: string;
    ticketUuid: string;
    titel: string;
}

export const Pagina: FC<PaginaProps> = ({titel, ticketId, ticketUuid, ticketnummer, ticketNaam, children}) => {
    const params = {
        ticketId,
        ticketUuid,
        ticketnummer,
    };
    const {t: tGeneral} = useTranslation('Tickets.nl');
    return (
        <Page header={null} title={titel}>
            <AddBreadcrumb order={-11} to={TicketLegacyRoutes.index.useUrl(params)}>
                {tGeneral('ViewBreadcrumb')}
            </AddBreadcrumb>
            <AddBreadcrumb order={-10} to={TicketLegacyRoutes.view.useUrl(params)}>
                {ticketNaam}
            </AddBreadcrumb>
            {children ?? <LoaderCenter />}
        </Page>
    );
};
