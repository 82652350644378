import {
    IArchiveerTaakButtonFragment,
    useArchiveerTaakButtonMutation,
    useDearchiveerTaakButtonMutation,
} from '@growthbase/graphql';
import {BaseButtonProps, Button} from '@growthbase/design-components';
import {FC} from 'react';
import {useWrapLockItem} from '@growthbase/locking';
import {useCallbackRef} from '@growthbase/spa';
import {ArchiveerTaakButton} from './ArchiveerTaakButton';

export interface ArchiveerTaakButtonConnectedProps {
    fragment?: IArchiveerTaakButtonFragment;
    SubmitButton?: FC<BaseButtonProps>;
    onChanged?: () => void;
}

export const ArchiveerTaakButtonConnected: FC<ArchiveerTaakButtonConnectedProps> = ({
    fragment,
    SubmitButton = Button,
    onChanged,
}) => {
    const variables = {
        input: {
            id: fragment?.id ?? '',
        },
    };
    const [archiveerMutation, {loading: loadingArchiveer}] = useArchiveerTaakButtonMutation({
        variables,
        onCompleted: onChanged,
    });

    const [dearchiveerMutation, {loading: loadingDearchiveer}] = useDearchiveerTaakButtonMutation({
        variables,
        onCompleted: onChanged,
    });
    const gearchiveerd = fragment?.gearchiveerd ?? false;
    const onSubmit = useWrapLockItem(
        fragment,
        useCallbackRef(() => {
            if (gearchiveerd) {
                return dearchiveerMutation();
            }
            return archiveerMutation();
        })
    );

    const availableActions = fragment?.availableActions;

    if (!availableActions) {
        return null;
    }

    if ((!availableActions.kanArchiveren && !gearchiveerd) || (!availableActions.KanDearchiveren && gearchiveerd)) {
        return null;
    }

    return (
        <ArchiveerTaakButton
            gearchiveerd={gearchiveerd}
            onSubmit={onSubmit}
            readonly={false}
            disabled={loadingArchiveer || loadingDearchiveer}
            SubmitButton={SubmitButton}
        />
    );
};
