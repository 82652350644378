import {IEntityReferenceLinksFragment} from '@growthbase/graphql';
import {FC} from 'react';
import {useResolveReferenceLink} from '../Hook';

export interface EntityReferenceLinkProps {
    fragment: IEntityReferenceLinksFragment;
}

export const EntityReferenceLink: FC<EntityReferenceLinkProps> = ({fragment}) => (
    <div>{useResolveReferenceLink(fragment)}</div>
);
