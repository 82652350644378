import {ReactNode} from 'react';
import {InputLabel} from '@mui/material';

export interface MuiFieldWrapperProps {
    children: ReactNode;
    label?: ReactNode;
    seperateLabel?: boolean;
}

export const MuiInputWrapper = ({children, label, seperateLabel}: MuiFieldWrapperProps) => (
    <div>
        {seperateLabel && label && <InputLabel>{label}</InputLabel>}
        {children}
    </div>
);
