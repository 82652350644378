import {FC, PropsWithChildren} from 'react';
import {ScrollContext} from './ScrollContext';
import {useUniqueClassname} from './hooks/useUniqueClassname';

export interface ScrollProviderProps extends PropsWithChildren {
    maxHeight?: number;
}

export const ScrollProvider: FC<ScrollProviderProps> = ({maxHeight, children}) => {
    const id = useUniqueClassname();
    return (
        <ScrollContext.Provider value={id}>
            <div className={id} style={{maxHeight, overflowY: 'scroll'}}>
                {children}
            </div>
        </ScrollContext.Provider>
    );
};
