import {DynamicContentServices, DynamicPosition} from '@growthbase/dynamiccontent';
import {createLegacyRoutes, CreateRouteFunction, createURL} from '@growthbase/routing';
import {generatePath} from 'react-router-dom';
import {TaakSearchFieldConnector} from './Components/TaakSearchFieldConnector';
import {TaakLinkFieldConnector} from './Components/TaakLinkFieldConnector';
import {LegacyTimeRegistrationProvider} from './Components/LegacyTimeRegistrationProvider';

const generateLegacyParams: CreateRouteFunction<{
    taakId: string;
}> = (route: string, params): string => {
    if (!params) {
        throw new Error('Missing params');
    }
    const {taakId} = params;
    const url = generatePath(route);
    const urlObject = createURL(url);
    urlObject.searchParams.set('taakId', taakId);
    urlObject.searchParams.set('modal', '1');
    return urlObject.toString();
};

const generateStopParams: CreateRouteFunction<{
    registrationId: string;
}> = (route: string, params): string => {
    if (!params) {
        throw new Error('Missing params');
    }
    const {registrationId} = params;
    return generatePath(route, {id: registrationId});
};

export const RegistrationLegacyRoutes = createLegacyRoutes('registration', {
    time_create: {
        createRoute: generateLegacyParams,
    },
    time_stop: {
        createRoute: generateStopParams,
    },
});

export const createTaakUrenService = ({configs = []}: DynamicContentServices): DynamicContentServices => ({
    configs: [
        ...configs,
        {
            Component: TaakSearchFieldConnector,
            position: DynamicPosition.root,
        },
        {
            Component: TaakLinkFieldConnector,
            position: DynamicPosition.root,
        },
        {
            Component: LegacyTimeRegistrationProvider,
            position: DynamicPosition.root,
        },
    ],
});
