import {useSelector} from 'react-redux';
import React, {useCallback, useState} from 'react';
import {
    PlanningContextMenuConnected,
    PlanningStoreState,
    useLoadPersistentPlanningState,
    useSyncedPlanningState,
} from '@growthbase/planning';
import {EventPopupTarget} from '@growthbase/design-components';
import {useSocketChanges} from '../../../planning/src/_hooks/useSocketChanges';
import {usePlanningData} from '../../../planning/src/_hooks/usePlanningData';
import {useOnEventCreate} from '../../../planning/src/_hooks/useOnEventCreate';
import {useExtendDefaultEvent} from '../../../planning/src/_hooks/useExtendDefaultEvent';
import {EventPopupConnected} from '../../../planning/src/EventPopup/EventPopupConnected';
import {Agenda} from './Agenda';

export const AgendaConnected = () => {
    const {viewSettingsLoaded} = useSelector((state: PlanningStoreState) => state.planning);

    useLoadPersistentPlanningState();
    useSyncedPlanningState();
    useSocketChanges();

    const {loading, onRequestData, normalizedData} = usePlanningData(false);

    const [popupTarget, setPopupTarget] = useState<EventPopupTarget | null>(null);

    const onShowPopupForEvent = useCallback((args: EventPopupTarget) => {
        if (args.externalEventId.indexOf('e-') === 0) {
            setPopupTarget(args);
        }
    }, []);

    const {onEventCreate, isCreatingEvent} = useOnEventCreate(normalizedData);
    const extendDefaultEventCallback = useExtendDefaultEvent();

    if (!viewSettingsLoaded) {
        return <></>;
    }

    return (
        <>
            {popupTarget && <EventPopupConnected target={popupTarget} onClose={() => setPopupTarget(null)} />}

            <Agenda
                onRequestData={onRequestData}
                data={normalizedData}
                loading={loading || isCreatingEvent}
                onEventCreate={onEventCreate}
                extendDefaultEvent={extendDefaultEventCallback}
                onShowPopupForEvent={onShowPopupForEvent}
                onClosePopupForEvent={() => setPopupTarget(null)}
                contextMenu={<PlanningContextMenuConnected />}
            />
        </>
    );
};
