import React, {FC, ReactNode} from 'react';
import {Table} from '@growthbase/design-components';
import {PaginationHelpers} from '@growthbase/spa';
import {useColumns} from './Hook';

export interface TemplatetabelRow {
    id: string;
    titel: string;
}

export interface TemplatetabelProps {
    pagination: PaginationHelpers<TemplatetabelRow>;
    header?: ReactNode;
}

export const Tabel: FC<TemplatetabelProps> = ({pagination, header}) => {
    const columns = useColumns();
    return <Table columns={columns} header={header} pagination={pagination} />;
};

export interface RenderTabelProps {
    filters?: ReactNode;
    header?: ReactNode;
}

export type RenderTabelType = (props: RenderTabelProps) => ReactNode;
