import {createSlice, original, PayloadAction} from '@reduxjs/toolkit';
import {remove} from 'lodash';
import {useSelector} from 'react-redux';
import {createDispatchHook} from '../../Redux';

export interface BaseMessage {
    id: string;
    // eslint-disable-next-line no-use-before-define
    duplicates?: MessageTypes[];
}

export type SimpleMessage = {
    type: 'error' | 'warning' | 'info' | 'success';
    message: string;
    displayType?: 'system' | 'user';
} & BaseMessage;

export type PromiseMessage = {
    displayType?: 'system' | 'user';
    promise: {
        promise: Promise<unknown>;
        pending?: string;
        success?: string;
        error?: string;
    };
} & BaseMessage;

export type MessageTypes = SimpleMessage | PromiseMessage;

export interface MessageState {
    messages: MessageTypes[];

    /**
     * These won't be shown if the user does not refresh the browser.
     */
    snoozedWarnings: MessageTypes[];
}

export interface MessageStoreState {
    messages: MessageState;
}

let warning = window.sessionStorage.getItem('snoozedWarnings');
if (warning) {
    try {
        warning = JSON.parse(warning);
    } catch (e) {
        // ignore.
    }
}

const initialMessageState: MessageState = {
    messages: [],
    snoozedWarnings: (warning as unknown as MessageTypes[]) ?? [],
};

const foundInMessage = (message: MessageTypes, messages: MessageTypes[]): MessageTypes | undefined => {
    let messageContent = 'promise' in message ? message.promise.success : message.message;

    messageContent = messageContent?.replace('ApolloError: ', '') ?? '';
    const stripWithoutErrorId = (text: string) => text.replace(/\(Error id:.*\)/, '');
    const stripMessage = stripWithoutErrorId(messageContent);
    const found = messages.find((m) => {
        const o = original(m);
        if (!o || !('message' in o)) {
            return false;
        }
        return stripWithoutErrorId(o.message) === stripMessage;
    });
    if (found) {
        return found;
    }

    // Searching for error code, message is stripped inside the backend, the error is more clean
    const errorCode = messageContent.match(/Error code: (.*?),/);
    if (!errorCode) {
        return undefined;
    }
    return messages.find((m) => {
        const o = original(m);
        if (!o || !('message' in o)) {
            return false;
        }
        return o.message.includes(errorCode[1]);
    });
};

export const MessageSlice = createSlice({
    name: 'Message',
    initialState: initialMessageState,
    reducers: {
        appendMessages: (state, action: PayloadAction<MessageTypes[]>) => {
            action.payload.forEach((message) => {
                let found = foundInMessage(message, state.messages);
                if (!found) {
                    found = foundInMessage(message, state.snoozedWarnings);
                }
                if (found) {
                    found.duplicates = found.duplicates || [];
                    found.duplicates.push(message);
                    return;
                }
                state.messages.push(message);
            });
        },
        removeMessage: (state, action: PayloadAction<MessageTypes>) => {
            remove(state.messages, {
                id: action.payload.id,
            });
        },
        snoozeMessage: (state, action: PayloadAction<MessageTypes>) => {
            remove(state.messages, {
                id: action.payload.id,
            });
            state.snoozedWarnings.push(action.payload);
            window.sessionStorage.setItem('snoozedWarnings', JSON.stringify(state.snoozedWarnings));
        },
    },
});

export const {appendMessages, removeMessage, snoozeMessage} = MessageSlice.actions;

export const useRemoveMessage = createDispatchHook(removeMessage);

export const useAppendMessage = createDispatchHook(appendMessages);

export const useSnoozeMessage = createDispatchHook(snoozeMessage);

export const useMessages = () => useSelector(({messages: {messages}}: MessageStoreState) => messages);

export const MessageReducer = MessageSlice.reducer;
