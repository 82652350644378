import {ReactNode, FC} from 'react';
import classNames from 'classnames/bind';
import styles from './CardListItem.module.scss';

const cx = classNames.bind(styles);

export interface CardListItemProps {
    reference?: ReactNode;
    children: ReactNode;
    testId?: string;
}

export const CardListItem: FC<CardListItemProps> = ({reference, testId, children}) => (
    <div data-testid={testId} className={cx('cardListItem')}>
        {reference && <div className={cx('cardListItem__avatar')}>{reference}</div>}
        {children}
    </div>
);
