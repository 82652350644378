import {FormikFieldProps} from '@growthbase/design-components';
import {IRelatieGroupsQuery, useRelatieInputConnectedVerwijzingenLazyQuery} from '@growthbase/graphql';
import {RelatieValue} from '@growthbase/relaties';
import {useCallback} from 'react';
import {RelatieInput} from './RelatieInput';

export interface RelatieInputConnectedProps extends FormikFieldProps<RelatieValue> {
    inline?: boolean;
}

export const RelatieInputConnected = (props: RelatieInputConnectedProps) => {
    const [request] = useRelatieInputConnectedVerwijzingenLazyQuery();
    return (
        <RelatieInput
            {...props}
            requestData={useCallback(
                (query: IRelatieGroupsQuery) =>
                    request({
                        variables: {
                            query,
                        },
                    }).then(
                        ({data}) =>
                            data?.ingelogdeGebruiker?.beschikbareRelaties ?? {
                                groups: [],
                            }
                    ),
                [request]
            )}
        />
    );
};
