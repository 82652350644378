import {ReactNode} from 'react';
import classNames from 'classnames/bind';
import styles from './CommentList.module.scss';

const cx = classNames.bind(styles);

export interface CommentListProps {
    children: ReactNode;
    inverse?: boolean;
}

export const CommentList = ({children, inverse}: CommentListProps) => {
    const className = cx('commentListModule', {
        'commentListModule--inverse': inverse,
    });
    return <div className={className}>{children}</div>;
};
