import {FC} from 'react';
import {replace} from 'lodash';
import {Stack} from '@growthbase/design-components';
import {useSessionStorage} from 'react-use';
import {TextField} from '@mui/material';
import {useAggregationConfig} from './Hook/useAggregationConfig';
import {AggregationList} from './AggregationList';
import {LogFilter} from '../ApplicationLogsTable/Components';

export interface AggregationLogsProps {
    isOpen: boolean;
}

export const AggregationLogs: FC<AggregationLogsProps> = ({isOpen}) => {
    const config = useAggregationConfig();
    const [defaultFilter, setDefaultFilter] = useSessionStorage<LogFilter>('alf', {
        lastMinutes: 8 * 60,
    });
    const [refreshTimeSeconds, setRefreshTimeSeconds] = useSessionStorage<number>('alr', 30);
    if (!config) {
        return null;
    }
    const {profielId} = config;
    return (
        <Stack gap={isOpen ? 'tiny' : undefined}>
            {isOpen && (
                <Stack horizontal gap="tiny">
                    <TextField
                        style={{width: 120}}
                        type="number"
                        label="Afgelopen x min"
                        variant="outlined"
                        value={`${defaultFilter.lastMinutes}`}
                        onChange={(e) =>
                            setDefaultFilter({
                                lastMinutes: parseInt(e.target.value, 10),
                            })
                        }
                    />
                    <TextField
                        type="number"
                        style={{width: 120}}
                        label="Refresh sec"
                        variant="outlined"
                        value={`${refreshTimeSeconds}`}
                        onChange={(e) => setRefreshTimeSeconds(parseInt(e.target.value, 10))}
                    />
                </Stack>
            )}
            {isOpen && profielId && (
                <span title="Ingelogd profield">
                    {profielId} <p>0x{replace(profielId, /-/gm, '')}</p>
                </span>
            )}
            {config.aggregations.map((section) => (
                <Stack.Item key={section.name}>
                    <AggregationList
                        refreshTimeSeconds={refreshTimeSeconds}
                        isOpen={isOpen}
                        profielId={profielId}
                        defaultFilter={defaultFilter}
                        section={section}
                    />
                </Stack.Item>
            ))}
        </Stack>
    );
};
