import {ReactNode} from 'react';

import classNames from 'classnames/bind';
import styles from './PageBodySpacing.module.scss';

const cx = classNames.bind(styles);

export interface PageBodySpacingProps {
    children: ReactNode;
    negative?: boolean;
}

export const PageBodySpacing = ({children, negative}: PageBodySpacingProps) => (
    <div className={cx('PageBodySpacing', {'PageBodySpacing--negative': negative})}>{children}</div>
);
