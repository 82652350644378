import {ITaaktemplateKaartHeaderConnectedFragment} from '@growthbase/graphql';
import {FC} from 'react';
import {RenderHeader} from './RenderHeader';
import {Header, HeaderProps} from './Header';

export interface HeaderConnectedProps extends Omit<HeaderProps, 'titel'> {
    fragment: ITaaktemplateKaartHeaderConnectedFragment;
}

export const HeaderConnected: FC<HeaderConnectedProps> = ({fragment, ...props}) =>
    fragment && <Header titel={fragment.naam} {...props} />;

export const renderHeaderConnected =
    (fragment: ITaaktemplateKaartHeaderConnectedFragment): RenderHeader =>
    // eslint-disable-next-line react/display-name
    (props) =>
        <HeaderConnected fragment={fragment} {...props} />;
