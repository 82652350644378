import React, {FC} from 'react';
import {BaseButtonProps, ConfirmModalButton, ModalContent, PopupHeader} from '@growthbase/design-components';
import {Trans, useTranslation} from 'react-i18next';

export interface VerwijderButtonProps {
    titel: string;
    onSubmit: () => Promise<void>;
    SubmitButton: FC<BaseButtonProps>;
    isSubmitting: boolean;
}

export const VerwijderButton: FC<VerwijderButtonProps> = ({titel: taakTitel, onSubmit, SubmitButton, isSubmitting}) => {
    const {t} = useTranslation('spa.nl');
    const titel = t('form.delete');
    return (
        <ConfirmModalButton
            onSubmit={onSubmit}
            popupButtonContent={titel}
            header={<PopupHeader title={titel} />}
            disabled={isSubmitting}
            renderOpenButton={(props) => <SubmitButton {...props}>{titel}</SubmitButton>}
        >
            <ModalContent>
                <div>
                    <Trans t={t} components={{strong: <strong />}} values={{titel: `<strong>${taakTitel}</strong>`}}>
                        lijsttemplate.taak.form.confirmDelete
                    </Trans>
                </div>
            </ModalContent>
        </ConfirmModalButton>
    );
};
