/* eslint-disable */
import {FC, ReactNode} from 'react';
import {TFunction} from 'i18next';
import {findByName, findByType} from './Utils';

export interface FieldComponentProps<T> {
    id: string;
    name: string;
    label: ReactNode;
    value: T;
    fields: Field[];
    onChange: (value: T) => void;
    onChangeField: (value: FieldInstance) => void;
    nestingLevel: number;
}

export interface FactoryProps {
    fields: Field[];
}

export interface OverrideProps<TNew = unknown, TPrevious = unknown> extends FactoryProps {
    previousValue: FieldInstance<TPrevious>;
    newField: Field<TNew>;
    previousField: Field<TPrevious>;
}

export interface Field<T = unknown> {
    name: string;
    label: (t: TFunction) => ReactNode;
    valueType: ValueTypeInterface<T>;
    Component: FC<FieldComponentProps<T>>;
}

// eslint-disable-next-line no-shadow
export enum ValueType {
    string = 'string',
    strings = 'strings',
    number = 'number',
    boolean = 'boolean',
    date = 'date',
    condition = 'condition',
    conditions = 'conditions',
}

export interface ValueTypeInterface<T> {
    type: ValueType;
    defaultValue: (props: FactoryProps) => T;
    isEmpty: (value: T, props: FactoryProps) => boolean;
    convertToNewValueType: (props: OverrideProps<T>) => T;
}

export const defaultToNewValueType = <TNew, TPrevious>({
    previousValue,
    newField,
    fields,
}: OverrideProps<TNew, TPrevious>): TNew => {
    const foundByName = findByName(newField.name, previousValue);
    if (foundByName) {
        return foundByName.value as TNew;
    }
    const foundByType = findByType(newField.valueType.type, previousValue);
    if (foundByType) {
        return foundByType.value as TNew;
    }
    return newField.valueType.defaultValue({fields});
};

export interface FieldInstance<T = unknown> {
    id: string;
    name: string;
    type: ValueType;
    value: T;
}
