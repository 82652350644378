import {AnyAction} from 'redux';
import {useDispatch} from 'react-redux';
import {useCallback} from 'react';

export const createDispatchHook =
    <TArg>(action: (args: TArg) => AnyAction) =>
    () => {
        const dispatch = useDispatch();
        return useCallback((arg: TArg) => dispatch(action(arg)), [dispatch]);
    };
