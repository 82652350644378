import {DragEndEvent} from '@dnd-kit/core';
import {useCallback} from 'react';
import {useStore} from 'react-redux';
import {Logger} from 'ts-log';
import {useOnDragEnd, useOnSave} from '../DNDReducer';
import {asDNDContainerId} from '../utils';
import {DNDActiveState, DNDContainerContext, DNDContainerId, DNDStoreState, DropItemHandlerEvent} from '../Value';

export interface OnDragEndEventProps {
    activeState: DNDActiveState | null;
    containers: Record<DNDContainerId, DNDContainerContext>;
    logger: Logger;
}

export const useOnDragEndEvent = ({containers, logger, activeState}: OnDragEndEventProps) => {
    const dragEnd = useOnDragEnd();
    const store = useStore<DNDStoreState>();
    const save = useOnSave();
    return useCallback(
        (event: DragEndEvent): void => {
            if (!activeState) {
                logger.error(`Active item is not found in state`);
                return;
            }
            if (!activeState.draggedOverContainer) {
                logger.error(`Drop container is not found in state`);
                return;
            }

            const overContainerId = asDNDContainerId(activeState.draggedOverContainer.id);

            const overContainer = containers[overContainerId];

            if (!overContainer) {
                logger.error(`Over container is not found in context`, event);
                return;
            }
            const sourceContainerId = asDNDContainerId(activeState.sourceContainer.id);
            const sourceContainer = containers[sourceContainerId];
            if (!sourceContainer) {
                logger.error(`Active container ${activeState.sourceContainer} is not found in context`);
                return;
            }

            const {draggedItem: item} = activeState;
            const state = store.getState();
            const containerState = state.DND.containers[overContainerId];
            const draggingItems = containerState?.draggingItems ?? containerState?.items ?? [];
            const index = draggingItems.findIndex((i) => i.userData.id === item.id);

            const dragItemEvent: DropItemHandlerEvent = {
                item: activeState.draggedItem,
                placement: {
                    itemThatIsAfter: index !== -1 ? draggingItems[index + 1]?.userData : undefined,
                    itemThatIsBefore: index !== -1 ? draggingItems[index - 1]?.userData : undefined,
                },
                overContainer: activeState.draggedOverContainer,
                previousContainer: sourceContainer.userData,
            };
            // Ignore when the item is dropped in the same container without any change in the position.
            if (
                dragItemEvent.overContainer === dragItemEvent.previousContainer &&
                !dragItemEvent.placement.itemThatIsAfter &&
                !dragItemEvent.placement.itemThatIsBefore
            ) {
                dragEnd();
                return;
            }

            // This directly sets the position of the item in the container.
            save();

            const promises = [];
            promises.push(overContainer.handleDrop(dragItemEvent));
            if (sourceContainer.handleRemove && sourceContainer.userData.id !== overContainer.userData.id) {
                promises.push(sourceContainer.handleRemove(activeState.draggedItem));
            }
            Promise.all(promises).finally(dragEnd);
        },
        [
            activeState,
            containers,
            store,
            save,
            dragEnd,
            logger,
        ]
    );
};
