import {IBordenConnectedFragment, IUseBordRouteViewFactoryFragment, useBordenConnectedQuery} from '@growthbase/graphql';
import React, {ReactNode, useMemo, useState} from 'react';
import {NavigateOptions, usePaginationSearchParams} from '@growthbase/routing';
import {
    DefaultKoppelbaarItem,
    DefaultRelatieValue,
    KoppelbaarItem,
    useCallbackRef,
    useHandleError,
} from '@growthbase/spa';
import {RelatieValue} from '@growthbase/relaties';
import {Loader} from '@growthbase/design-components';
import {RenderViewType, RenderViewTypeProps} from './Components/RenderViewType';
import {CardsViewConnected} from './Components';
import {TabelConnected} from './Components/Tabel/TabelConnected';
import {Overview} from './Overview';
import {OverviewFilters, ViewType} from './types';
import {useUpdateFromExternalEntityChanges} from './Hook';
import {LandingsPaginaBordConnected} from '../LandingspaginaBord';

/* eslint-disable react/no-unstable-nested-components */

/**
 * Gebruikt om aan te geven waarin de huidige applicatie zich bevindt, bv tickets, opdrachten.
 *
 * @deprecated DefaultValueProvider zou voldoende moeten zijn om te weten waarin de huidige applicatie zich bevindt.
 */
export interface BordenOnderdeelVan extends KoppelbaarItem {
    /**
     * @deprecated
     * @param bord
     * @param options
     */
    route?: (bord: IUseBordRouteViewFactoryFragment, options?: NavigateOptions) => string;
    relatie: RelatieValue;
}

export interface OverviewConnectedProps {
    readonly?: boolean;
    onderdeelVan?: BordenOnderdeelVan;
    archief: boolean;
    mijnborden?: boolean;
    showEmpty?: boolean;
    actions?: ReactNode;
}

export function OverviewConnected({
    archief,
    readonly,
    mijnborden,
    actions,
    showEmpty = !archief && !readonly,
    onderdeelVan: onderdeelVanMutable,
}: OverviewConnectedProps) {
    const disabled = false;
    const [filters, setFilter] = useState<OverviewFilters>({
        searchQuery: null,
        deelnemers: null,
    });

    const onderdeelVan = onderdeelVanMutable;
    const {pagination, usePagination} = usePaginationSearchParams<IBordenConnectedFragment>('bord');
    const result = useBordenConnectedQuery({
        variables: {
            fulltextSearch: filters.searchQuery,
            ...pagination,
            type: onderdeelVan?.type,
            id: onderdeelVan?.id,
            deelnemers: filters.deelnemers,
            archief,
            mijnborden,
        },
        onError: useHandleError(),
    });

    const renderType = useMemo(
        (): Record<ViewType, RenderViewType<IBordenConnectedFragment>> => ({
            [ViewType.Cards]: (args: RenderViewTypeProps<IBordenConnectedFragment>) => <CardsViewConnected {...args} />,
            [ViewType.Tabel]: (args: RenderViewTypeProps<IBordenConnectedFragment>) => <TabelConnected {...args} />,
        }),
        []
    );
    const helpers = usePagination(result);

    useUpdateFromExternalEntityChanges({
        result,
        filters,
        onderdeelVan,
        archief,
        mijnborden,
    });

    const instellingen = result?.data?.ingelogdeGebruiker?.gebruikerWeergaveInstellingen;
    const setFilters = useCallbackRef((updatedFilters: OverviewFilters): void => {
        setFilter(updatedFilters);
    });
    if (!instellingen) {
        return <Loader center />;
    }
    const isEmpty = !result.loading && showEmpty && helpers.connections.length === 0;
    return (
        <DefaultKoppelbaarItem.Provider id="OverviewConnected" value={onderdeelVan}>
            <DefaultRelatieValue.Provider id="OverviewConnected" value={onderdeelVan?.relatie}>
                {isEmpty && <LandingsPaginaBordConnected />}
                {!isEmpty && (
                    <Overview<IBordenConnectedFragment>
                        standaardWeergaveBorden={instellingen.standaardWeergaveBorden}
                        laatstGebruikteWeergaveBorden={instellingen.laatstGebruikteWeergaveBorden}
                        setFilter={setFilters}
                        filters={filters}
                        pagination={helpers}
                        renderType={renderType}
                        disabled={disabled}
                        readonly={readonly ?? false}
                        actions={actions}
                    />
                )}
            </DefaultRelatieValue.Provider>
        </DefaultKoppelbaarItem.Provider>
    );
}
