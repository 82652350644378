import {NullableISOStringDateRange} from '@growthbase/design-components';
import {ITaakPlanningButtonFragment} from '@growthbase/graphql';
import {useMemo} from 'react';

export const useMapFragmentToValue = (fragment?: ITaakPlanningButtonFragment): NullableISOStringDateRange => {
    const planning = fragment?.planning;
    return useMemo(
        (): NullableISOStringDateRange => ({
            from: planning?.begindatum ? {date: planning.begindatum, timeUsed: planning.gebruikBegindatumTijd} : null,
            to: planning?.deadline ? {date: planning.deadline, timeUsed: planning.gebruikDeadlineTijd} : null,
        }),
        [planning]
    );
};
