import {Logger} from 'ts-log';
import {useMemo} from 'react';
import {createUseService} from './createUseService';

const useLoggerService = createUseService((s) => s.logger);

let currentGroup: string | null = '';

/* eslint-disable no-console */

const switchGroup = (name: string | null) => {
    if (currentGroup === name) {
        return;
    }

    if (currentGroup) {
        console.groupEnd();
    }

    if (name) {
        console.group(name);
    }

    currentGroup = name;
};

export const useLogger = (...p: string[]): Logger => {
    const logger = useLoggerService();
    let prefix = p.length ? p.join(':') : null;
    if (prefix) {
        prefix = prefix.trim();
    }
    if (prefix === '') {
        prefix = null;
    }
    return useMemo(
        () => ({
            trace(message?: unknown, ...optionalParams: unknown[]): void {
                switchGroup(prefix);
                logger.trace(message, ...optionalParams);
            },
            debug(message?: unknown, ...optionalParams: unknown[]): void {
                switchGroup(prefix);
                logger.debug(message, ...optionalParams);
            },
            info(message?: unknown, ...optionalParams: unknown[]): void {
                switchGroup(prefix);
                logger.info(message, ...optionalParams);
            },
            warn(message?: unknown, ...optionalParams: unknown[]): void {
                switchGroup(prefix);
                logger.warn(message, ...optionalParams);
            },
            error(message?: unknown, ...optionalParams: unknown[]): void {
                switchGroup(prefix);
                logger.error(message, ...optionalParams);
            },
        }),
        [logger, prefix]
    );
};
