import {renderSortableTaakTabelStub} from '@growthbase/taken';
import {LijstMenuStub} from '../../../../../LijstMenu';
import {RenderSortableTakenTabelLijstType} from './RenderSortableTakenTabelLijstType';
import {SortableTakenTabelLijst} from './SortableTakenTabelLijst';

export const renderSortableTakenTabelLijstStub: RenderSortableTakenTabelLijstType = ({item}) => (
    <SortableTakenTabelLijst
        renderTabel={(config) =>
            renderSortableTaakTabelStub({
                ...config,
                id: `lijst-${item.userData.id}`,
            })
        }
        menu={<LijstMenuStub lijstTitel={`Lijst ${item.userData.id}`} />}
        item={item}
    />
);
