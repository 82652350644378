import React from 'react';

import {TableHeader, TableHeaderProps, TableHeaderSection} from '@growthbase/design-components';
import classNames from 'classnames/bind';
import {AnchorAttributes} from '@growthbase/keys';
import styles from './TableHeader.module.scss';

const cx = classNames.bind(styles);

export interface DraggableTableHeaderProps extends TableHeaderProps {
    title: string;
    anchor?: AnchorAttributes;
}

export const TableHeaderDraggable = ({
    children,
    className = '',
    title,
    anchor,
    ...props
}: DraggableTableHeaderProps) => (
    <TableHeader className={`${cx('tableHeader--draggable')} ${className}`} {...props}>
        <div {...anchor} className={cx('tableHeader__draghandle')}>
            <TableHeaderSection grow testId={title}>
                {title}
            </TableHeaderSection>
        </div>
        {children}
    </TableHeader>
);
