import {createContext} from 'react';

export interface GlobalModifierKeysContext {
    alt?: boolean;
    ctrl?: boolean;
    meta?: boolean;
    shift?: boolean;
}

export const GlobalModifierKeysContext = createContext<GlobalModifierKeysContext | null>(null);
