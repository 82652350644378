import {PaginationHelpers} from '@growthbase/spa';
import React, {FC} from 'react';
import {IBordCardsViewFragment} from '@growthbase/graphql';
import {ViewConfig} from '@growthbase/design-components';
import {CardsView} from './CardsView';
import {CardConnected} from './Components/Card/CardConnected';

export interface CardsViewConnectedProps extends ViewConfig {
    pagination: PaginationHelpers<IBordCardsViewFragment>;
}

export const CardsViewConnected: FC<CardsViewConnectedProps> = ({pagination, disabled, readonly}) => (
    <CardsView<IBordCardsViewFragment> pagination={pagination}>
        {({card}) => <CardConnected fragment={card} readonly={readonly} disabled={disabled} />}
    </CardsView>
);
