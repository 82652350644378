import {PaginationHelpers, useCallbackRef, usePaginationMapper} from '@growthbase/spa';
import {IStatisticsTableCountFragment, IStatisticsTableTimingFragment} from '@growthbase/graphql';
import {Information} from '@growthbase/design-components';
import {round} from 'lodash';
import {StatisticsDTO} from '../StatisticsTable';
import {DisplayProgress} from '../../ProcessesPage/Components/DisplayProgress';

export const useStatisticsTableConvert = (
    pagination: PaginationHelpers<IStatisticsTableTimingFragment | IStatisticsTableCountFragment>
) =>
    usePaginationMapper(
        pagination,
        useCallbackRef((message): StatisticsDTO => {
            const {type, running, name} = message;

            const base = {
                name,
                id: `${name}_${type}`,
                running: <Information>{running ? 'Running' : 'Not running'}</Information>,
            };

            if (type === 'ReplayProcessTimingStatistic') {
                const {ms} = message;
                const s = ms / 1000;
                return {
                    ...base,
                    value: (
                        <div>
                            {round(ms, 4)}ms ({round(s, 2)}s)
                        </div>
                    ),
                };
            }
            return {
                ...base,
                value: <DisplayProgress fragment={message} />,
            };
        })
    );
