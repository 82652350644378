import {MutableRefObject} from 'react';
import {useActiveItem, ActiveItemData, ActiveItemType, SetItemActiveApi} from '../../Active';
import {useKeys} from '../../Keys';

export interface TraversableInputOptions<
    T extends ActiveItemData = ActiveItemData,
    TElement extends HTMLElement = HTMLElement
> extends SetItemActiveApi<T> {
    ref: MutableRefObject<TElement | null>;
    items: T[];
    position: 'start' | 'end';
    direction?: 'horizontal' | 'vertical';
}

export function useTraversableInput<
    T extends ActiveItemData = ActiveItemData,
    TElement extends HTMLElement = HTMLElement
>({
    type = ActiveItemType.Unknown,
    activate,
    deactivate,
    ref,
    source,
    items,
    position,
    direction = 'vertical',
}: TraversableInputOptions<T, TElement>) {
    const active = useActiveItem({
        source,
        type,
    });
    useKeys<HTMLElement>(
        'TraversableInput: traverse items',
        direction === 'vertical' ? 'up,down' : 'left,right',
        (keyboardEvent, hotsEvent) => {
            if (items.length === 0) {
                return;
            }
            const isFocused = document.activeElement === ref.current;
            const goingToEnd = ['down', 'right'].includes(hotsEvent.key);
            const goingToStart = ['up', 'left'].includes(hotsEvent.key);
            if (isFocused && !active) {
                /**
                 * From the input element to the list.
                 */
                switch (position) {
                    case 'start':
                        if (goingToEnd) {
                            activate(items[0]);
                        }
                        break;
                    case 'end':
                        if (goingToStart) {
                            activate(items[items.length - 1]);
                        }
                        break;
                    default:
                        return;
                }
            }
            // We are not actually traversing anything.
            if (!active) {
                return;
            }
            const index = items.findIndex((item) => item.id === active?.data.id);
            /**
             * Here we go away from the list back to the input element.
             */
            switch (position) {
                case 'start':
                    if (goingToStart && index - 1 < 0) {
                        deactivate();
                        ref.current?.focus();
                    }
                    break;
                case 'end':
                    if (goingToEnd && index + 1 >= items.length) {
                        deactivate();
                        ref.current?.focus();
                    }
                    break;
                default:
                    break;
            }
        },
        {
            enableOnInput: true,
            onKeyDown: true,
            onKeyUp: false,
        }
    );
}
