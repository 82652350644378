import {IUseBordRouteViewFactoryFragment} from '@growthbase/graphql';
import {BordenOnderdeelVan, BordRoutes} from '@growthbase/takenlijst/bord';
import {DefaultKoppelbaarItem, useCallbackRef} from '@growthbase/spa';
import {NavigateOptions} from '@growthbase/routing';

export const useBordRouteViewFactory = (koppelbaarItem?: BordenOnderdeelVan) => {
    const fallback = BordRoutes.view.useUrlFactory();
    const defaultItem = DefaultKoppelbaarItem.useValue();
    return useCallbackRef((props: IUseBordRouteViewFactoryFragment, viewOptions?: NavigateOptions) => {
        const {id} = props;
        const item: BordenOnderdeelVan = (koppelbaarItem ?? defaultItem) as BordenOnderdeelVan;
        if (item?.route) {
            return item?.route(props, viewOptions);
        }
        return fallback({bordId: id}, viewOptions);
    });
};
