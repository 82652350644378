import {useAsyncComboBox, useComboBoxContext} from '@growthbase/design-components';
import {useGebruikersComboboxDeelnemersZoekenQuery, useResolveConnections} from '@growthbase/graphql';
import {useCallbackRef} from '@growthbase/spa';
import React, {memo, useEffect, useMemo} from 'react';
import {GebruikersCombobox, ComboboxGebruiker, GebruikersMentionKey} from './GebruikersCombobox';
import {useGebruikersComboboxIgnoreIds} from './GebruikersComboboxIgnoreIdsContext';

interface GebruikersComboboxConnectedMemorizedProps {
    open: boolean;
    text: string | null;
    not: string[];
    setLoading: (loading: boolean) => void;
}

const GebruikersComboboxConnectedMemorized = memo(
    ({not, text, open, setLoading}: GebruikersComboboxConnectedMemorizedProps) => {
        const {data, loading} = useGebruikersComboboxDeelnemersZoekenQuery({
            variables: {
                fulltextSearch: text === '' ? null : text,
                not,
            },
            skip: !open,
        });

        useEffect(() => setLoading(loading), [loading, setLoading]);

        const nodes = useResolveConnections(data?.Gebruikers);
        const mapped: ComboboxGebruiker[] = useMemo(
            () =>
                nodes.map(
                    ({label, id, externalId, imageUrl}): ComboboxGebruiker => ({
                        id,
                        label,
                        externalId: externalId ?? undefined,
                        imgUrl: imageUrl ?? null,
                        disabled: false,
                    })
                ),
            [nodes]
        );
        return <GebruikersCombobox items={mapped} />;
    }
);

GebruikersComboboxConnectedMemorized.displayName = 'GebruikersComboboxConnectedMemorized';

export const GebruikersComboboxConnected = () => {
    const not = useGebruikersComboboxIgnoreIds();
    const comboboxContext = useComboBoxContext();
    const {text, open} = useAsyncComboBox(GebruikersMentionKey);
    return (
        <GebruikersComboboxConnectedMemorized
            not={not}
            text={text}
            open={open}
            setLoading={useCallbackRef((loading) => comboboxContext.setLoading(loading))}
        />
    );
};
