import {useContext} from 'react';
import {PopupControls, PopupControlsContext} from '../PopupControls';

export const useOptionalPopup = (): PopupControls | null => {
    const context = useContext(PopupControlsContext);
    if (context) {
        return context;
    }
    return null;
};
