import {CreateFieldFormOptions, useCreateStrictForm} from '@growthbase/design-components';

import {useCallback} from 'react';
import {EditFormDTO, useEditSchema} from './useEditSchema';

export interface UseEditFormOptions
    extends Pick<
        CreateFieldFormOptions<EditFormDTO>,
        'autoSubmit' | 'doNoWaitForSubmit' | 'createInitialValuesAfterSubmit'
    > {
    onEdit: (values: EditFormDTO) => Promise<void>;
    createInitialValues: () => EditFormDTO;
}

export const useEditForm = ({onEdit, createInitialValues, autoSubmit = false, ...rest}: UseEditFormOptions) =>
    useCreateStrictForm<EditFormDTO>({
        onSubmit: useCallback((values) => onEdit(values), [onEdit]),
        autoSubmit,
        schema: useEditSchema(),
        enableLockPopupAndRoutingWhenDirty: false,
        createInitialValuesAfterSubmit: true,
        createInitialValues,
        ...rest,
    });
