import React, {FC, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {
    BoxButton,
    DefaultFormModalFooter,
    Field,
    Loader,
    Modal,
    ModalContent,
    PopupHeader,
    Stack,
    StyledSelectInput,
    StyledSelectOption,
    useCreateStrictForm,
} from '@growthbase/design-components';
import {DeelnemersInputConnected} from '@growthbase/gebruikers';
import {DefaultGebruiker, DefaultKoppelbaarItem, useCallbackRef} from '@growthbase/spa';
import {KoppelButtonInputConnected, RenderKoppelButtonProps} from '@growthbase/taken';
import {
    createFormValue,
    CreateFormDTO,
    useBordtemplateAlsBordToevoegenFormSchema,
} from './BordtemplateAlsBordToevoegenFormSchema';

export interface BordtemplateAlsBordToevoegenFormProps {
    onSubmit: (values: CreateFormDTO) => Promise<void>;
    options: StyledSelectOption<string>[];
    optionsLoading: boolean;
    DeelnemersInput?: typeof DeelnemersInputConnected;
    OnderdeelVanComponent?: typeof KoppelButtonInputConnected;
}

export const BordtemplateAlsBordToevoegenForm: FC<BordtemplateAlsBordToevoegenFormProps> = ({
    onSubmit,
    options,
    optionsLoading,
    DeelnemersInput = DeelnemersInputConnected,
    OnderdeelVanComponent = KoppelButtonInputConnected,
}) => {
    const {t} = useTranslation('Bordtemplate.nl');
    const defaultItem = DefaultKoppelbaarItem.useValue(null);
    const defaultDeelnemer = DefaultGebruiker.useValue();

    const {
        formik,
        Form,
        Fields: {BordtemplateField, DeelnemersField, OnderdeelVanField},
        formProps,
    } = useCreateStrictForm<CreateFormDTO>({
        onSubmit,
        createInitialValuesAfterSubmit: true,
        createInitialValues: useCallbackRef(() => {
            const values = createFormValue();
            if (defaultItem) {
                values.onderdeelVan = defaultItem;
            }
            if (defaultDeelnemer) {
                values.deelnemers.push(defaultDeelnemer);
            }
            return values;
        }),
        schema: useBordtemplateAlsBordToevoegenFormSchema(),
    });

    const Bordtemplate = BordtemplateField.useComponent(StyledSelectInput);
    const Deelnemers = DeelnemersField.useComponent(DeelnemersInput);
    const OnderdeelVan = OnderdeelVanField.useComponent(OnderdeelVanComponent);

    const renderKoppelButton = useCallback(
        ({item, ...props}: RenderKoppelButtonProps) => (
            <BoxButton {...props} value={item?.label}>
                {t('gekoppeld')}
            </BoxButton>
        ),
        [t]
    );

    return (
        <Modal>
            <PopupHeader title={t('BordtemplateAlsBordToevoegenForm.PopupHeader')} />
            <ModalContent>
                {optionsLoading ? (
                    <>
                        <Loader small />
                    </>
                ) : (
                    <Form {...formProps}>
                        <Stack gap="small">
                            <Stack.Item>
                                <Bordtemplate
                                    options={options}
                                    label={t('BordtemplateAlsBordToevoegenForm.Helptext')}
                                    hideWarningOnFocusLost
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <Field label={t('deelnemers')} compact disableMargin>
                                    <Deelnemers />
                                </Field>
                            </Stack.Item>
                            <Stack.Item label="Koppelen">
                                <OnderdeelVan renderKoppelButton={renderKoppelButton} />
                            </Stack.Item>
                        </Stack>
                    </Form>
                )}
            </ModalContent>
            <DefaultFormModalFooter formik={formik} />
        </Modal>
    );
};
