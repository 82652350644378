import React, {FC, ReactNode} from 'react';
import {useRoutePopupControls} from '../Hook';
import {PopupControls, PopupControlsContext} from '../PopupControls';

export interface RoutePopupProviderProps {
    children: ((helpers: PopupControls) => ReactNode) | ReactNode;
}

export const RoutePopupProvider: FC<RoutePopupProviderProps> = ({children}) => {
    const helpers = useRoutePopupControls();
    return (
        <PopupControlsContext.Provider value={helpers}>
            {typeof children === 'function' ? children(helpers) : children}
        </PopupControlsContext.Provider>
    );
};
