import {ViewConfig} from '@growthbase/design-components';
import {
    ITakenVanLijstSwimlaneFragment,
    ITakenVanLijstSwimlaneKaartFragment,
    useTakenVanLijstSwimlaneQuery,
} from '@growthbase/graphql';
import {idFromIri, usePaginationSearchParams} from '@growthbase/routing';
import {Takenswimlane, TakenswimlaneConnected} from '@growthbase/taken';
import {usePollInterval} from '@growthbase/websockets';
import React from 'react';
import {OverviewType, TaakFilters} from '../../../../types';
import {useChangeOrder} from './Hook/useChangeOrder';
import {useEntityChanges} from './Hook/useEntityChanges';
import {useMaakTaakInLijstHandler} from './Hook/useMaakTaakInLijstHandler';

export interface SwimlaneConnectedProps extends ViewConfig {
    fragment: ITakenVanLijstSwimlaneFragment;
    filters: TaakFilters;
    header?: React.ReactNode;
    footer?: React.ReactNode;
    placeholder?: boolean;
}

export function TakenVanLijstSwimlaneConnected({
    fragment,
    filters,
    readonly,
    disabled,
    ...rest
}: SwimlaneConnectedProps) {
    const viewConfig = {disabled, readonly};
    const {
        id,
        availableActions: {kanTakenToevoegen},
    } = fragment;
    const {pagination, usePagination} = usePaginationSearchParams<ITakenVanLijstSwimlaneKaartFragment>('taak');
    const result = useTakenVanLijstSwimlaneQuery({
        pollInterval: usePollInterval(),
        variables: {
            ...pagination,
            fulltextSearch: filters.searchQuery,
            deelnemers: filters.deelnemers,
            gearchiveerd: filters.overviewType === OverviewType.Archief,
            voltooid:
                filters.overviewType === OverviewType.ToonAlles || filters.overviewType === OverviewType.Archief
                    ? undefined
                    : filters.overviewType === OverviewType.Voltooid,
            takenDieIkVolg: filters.overviewType === OverviewType.Volgend ? true : undefined,
            lijstId: idFromIri(id),
        },
    });

    const helpers = usePagination(result);

    useEntityChanges({
        lijstId: id,
        filters,
        result,
    });

    const handler = useMaakTaakInLijstHandler(id, result);

    return (
        <TakenswimlaneConnected<ITakenVanLijstSwimlaneKaartFragment>
            pagination={helpers}
            userData={fragment}
            footer={
                <Takenswimlane.Footer
                    form={
                        kanTakenToevoegen &&
                        filters.overviewType !== OverviewType.Archief && (
                            <Takenswimlane.Footer.VoegTaakToeForm {...viewConfig} onSubmit={handler} />
                        )
                    }
                />
            }
            {...viewConfig}
            {...rest}
            {...useChangeOrder(id, result)}
        />
    );
}
