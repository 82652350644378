import {StyledSelect, StyledSelectOption} from '@growthbase/design-components';
import {OverviewType} from '@growthbase/takenlijst-lijst';
import {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

export interface OverzichtSelectProps {
    value: OverviewType;
    onChange: (value: OverviewType) => void;
}

export const OverzichtSelect: FC<OverzichtSelectProps> = ({onChange, value}) => {
    const {t} = useTranslation('spa.nl');
    const options = useMemo(
        (): StyledSelectOption<OverviewType>[] => [
            {
                value: OverviewType.Overzicht,
                label: t('OverzichtSelect.Overzicht') ?? '',
            },
            {
                value: OverviewType.ToonAlles,
                label: t('OverzichtSelect.ToonAlles') ?? '',
            },
            {
                value: OverviewType.Volgend,
                label: t('OverzichtSelect.Volgend') ?? '',
            },
            {
                value: OverviewType.Voltooid,
                label: t('OverzichtSelect.Voltooid') ?? '',
            },
            {
                value: OverviewType.Archief,
                label: t('OverzichtSelect.Archief') ?? '',
            },
        ],
        [t]
    );
    return (
        <StyledSelect<OverviewType>
            disabled={false}
            readonly={false}
            value={value}
            onChange={onChange}
            options={options}
        />
    );
};
