import {ReactieFields, ReactieInhoudForm} from '../ReactieInhoudForm/ReactieInhoudForm';

export interface TaakReactieCreateFormProps {
    gebruiker?: {
        naam: string;
        image?: string | null | undefined;
    } | null;
    onSubmit: (value: ReactieFields) => Promise<void>;
}

export const TaakReactieCreateForm = ({gebruiker, onSubmit}: TaakReactieCreateFormProps) => (
    <ReactieInhoudForm
        value={{
            tekst: {
                elements: [
                    {
                        type: 'p',
                        children: [
                            {
                                text: '',
                            },
                        ],
                    },
                ],
            },
        }}
        onSubmit={onSubmit}
    />
);
