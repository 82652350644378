import {useTranslation} from 'react-i18next';
import {
    BaseButtonProps,
    ButtonHandler,
    Buttons,
    HoverButtons,
    HtmlLink as LinkDesignComponent,
    HtmlLink,
    useIsMobile,
} from '@growthbase/design-components';
import React from 'react';
import {KoppelbaarItem} from '@growthbase/taken';

export interface GekoppeldLinkProps extends BaseButtonProps {
    item: KoppelbaarItem;
    disabled?: boolean;
    readonly?: boolean;
    onEdit: ButtonHandler;
    onRemove: ButtonHandler;
    testId?: string;
    textSize?: 'small' | 'default';
    textColor?: 'default' | 'gray';
}

export const GekoppeldLink = ({
    item,
    onRemove,
    onEdit,
    disabled,
    readonly,
    testId,
    textSize,
    textColor,
    ref,
}: GekoppeldLinkProps) => {
    const {t} = useTranslation('spa_form.nl');
    const isMobile = useIsMobile();

    if (readonly) {
        return item.href ? (
            <HtmlLink target="_blank" href={item.href} size={textSize} color={textColor}>
                {item.label}
            </HtmlLink>
        ) : (
            <div>{item.label}</div>
        );
    }

    return (
        <HoverButtons
            testId={testId}
            buttons={
                <>
                    <Buttons.PencilIconOnly
                        testId="Bewerken"
                        disabled={disabled}
                        onClick={onEdit}
                        ariaLabel={t('item.wijzig')}
                        tooltip={t('item.wijzig')}
                        size={isMobile ? 'larger' : 'small'}
                        autoHeight
                    />

                    {isMobile && (
                        <Buttons.Cross
                            testId="Verwijderen"
                            disabled={disabled}
                            onClick={onRemove}
                            tooltip={t('item.ontkoppel')}
                            ariaLabel={t('item.ontkoppel')}
                            size="larger"
                            variant="iconOnlyDanger"
                            autoHeight
                        />
                    )}
                    {!isMobile && (
                        <Buttons.CrossCircleIconOnly
                            testId="Verwijderen"
                            disabled={disabled}
                            onClick={onRemove}
                            tooltip={t('item.ontkoppel')}
                            ariaLabel={t('item.ontkoppel')}
                            size="small"
                            variant="iconOnlyDanger"
                            autoHeight
                        />
                    )}
                </>
            }
        >
            {item.href ? (
                <LinkDesignComponent target="_blank" href={item.href}>
                    {item.label}
                </LinkDesignComponent>
            ) : (
                item.label
            )}
        </HoverButtons>
    );
};
