import {useEffect} from 'react';
import {usePlanningSetDate} from '@growthbase/planning';

export const PlanningDebug = () => {
    const setDate = usePlanningSetDate();

    useEffect(() => {
        window.addEventListener('testSetPlanningDate', ((event: CustomEvent) => {
            setDate({
                date: event.detail.date,
                localOnly: true,
            });
        }) as EventListener);
    }, [setDate]);

    return <></>;
};
