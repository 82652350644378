import {ReactNode} from 'react';
import {FloatingPortal} from '@floating-ui/react-dom-interactions';
import {useIsSSR} from '@growthbase/spa';

export interface SidenavPopupPortalProps {
    children: ReactNode;
}

export const SidenavPopupPortal = ({children}: SidenavPopupPortalProps) => {
    const isSSR = useIsSSR();
    if (isSSR) {
        return null;
    }
    return <FloatingPortal id="SidenavPopupPortal">{children}</FloatingPortal>;
};
