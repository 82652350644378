import {OpgemaakteTekst, OpgemaakteTekstElement} from '../Hook/useOpgemaakteTekstSchema';

const findElementByType = (type: string, value: OpgemaakteTekstElement): OpgemaakteTekstElement[] => {
    if ('text' in value) {
        return [];
    }
    if ('type' in value && value.type === type) {
        return [value];
    }
    if ('children' in value) {
        return value.children.flatMap((child) => findElementByType(type, child as OpgemaakteTekstElement));
    }
    return [];
};

export const opgemaakteTekstFindElementsByType = (type: string, value: OpgemaakteTekst): OpgemaakteTekstElement[] =>
    findElementByType(type, {
        type: 'p',
        children: value.elements,
    });
