import React, {ReactNode} from 'react';
import classNames from 'classnames/bind';
import styles from './ModalContentNegative.module.scss';

const cx = classNames.bind(styles);

export interface ModalContentNegativeProps {
    children: ReactNode;
}

export const ModalContentNegative = ({children}: ModalContentNegativeProps) => {
    const classes = cx('modalContentNegative');
    return <div className={classes}>{children}</div>;
};
