import {SchemaOf} from 'yup';
import {ClientApplicationConfigDTO, ClientApplicationConfigDTOSchema} from './ClientApplicationConfigDTO';
import {SpaVariablesDTO} from './SpaVariablesDTO';

declare const SPA_CONFIG: unknown;
declare const SPA_VARIABLES: unknown;
export interface DTOsDependencies<TVariables> {
    disableStrictMode?: boolean;
    isSSR?: boolean;
    variablesSchema?: SchemaOf<TVariables>;
}

export interface DTOsServices<TVariables extends SpaVariablesDTO = SpaVariablesDTO> {
    variables: TVariables;
    config: ClientApplicationConfigDTO;
}

/**
 * Variables defined inside "spa.html.twig"
 */
export const createDTOsFromGlobals = ({variablesSchema}: DTOsDependencies<SpaVariablesDTO>): DTOsServices => ({
    config: ClientApplicationConfigDTOSchema.validateSync(SPA_CONFIG),
    variables: variablesSchema ? variablesSchema.validateSync(SPA_VARIABLES) : (null as unknown as SpaVariablesDTO),
});
