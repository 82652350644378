import React, {FC, useCallback, useState} from 'react';
import {EventPopupTarget} from '@growthbase/design-components';
import {AgendaModuleType, PlanningContextMenuConnected} from '@growthbase/planning';
import {useSocketChanges} from '../../_hooks/useSocketChanges';
import {AgendaView} from './AgendaView';
import {EventPopupConnected} from '../../EventPopup/EventPopupConnected';
import {usePlanningData} from '../../_hooks/usePlanningData';
import {useOnEventCreate} from '../../_hooks/useOnEventCreate';
import {useExtendDefaultEvent} from '../../_hooks/useExtendDefaultEvent';

export interface AgendaViewConnectedProps {
    module?: AgendaModuleType;
}

export const AgendaViewConnected: FC<AgendaViewConnectedProps> = ({module}: AgendaViewConnectedProps) => {
    useSocketChanges();

    const {loading, onRequestData, normalizedData} = usePlanningData(false);

    const [popupTarget, setPopupTarget] = useState<EventPopupTarget | null>(null);

    const onShowPopupForEvent = useCallback((args: EventPopupTarget) => {
        if (args.externalEventId.indexOf('e-') === 0) {
            setPopupTarget(args);
        }
    }, []);

    const {onEventCreate, isCreatingEvent} = useOnEventCreate(normalizedData);
    const extendDefaultEventCallback = useExtendDefaultEvent();

    return (
        <>
            {popupTarget && <EventPopupConnected target={popupTarget} onClose={() => setPopupTarget(null)} />}
            <AgendaView
                module={module}
                onRequestData={onRequestData}
                data={normalizedData}
                loading={loading || isCreatingEvent}
                onEventCreate={onEventCreate}
                extendDefaultEvent={extendDefaultEventCallback}
                onShowPopupForEvent={onShowPopupForEvent}
                onClosePopupForEvent={() => setPopupTarget(null)}
                contextMenu={<PlanningContextMenuConnected />}
            />
        </>
    );
};
