import {ReactNode} from 'react';
import {Tab, Tabs, TabsRendererMarginLeft} from '@growthbase/design-components';
import {useTranslation} from 'react-i18next';

export interface TicketSidebarProps {
    dienstenTab?: ReactNode;
    agendaTab: ReactNode;
}

export const TicketSidebar = ({dienstenTab, agendaTab}: TicketSidebarProps) => {
    const {t} = useTranslation('spa.nl');

    return (
        <Tabs render={TabsRendererMarginLeft} query={{defaultValue: 'agendas', name: 'sbd'}}>
            <Tab title={t('planning.agendasTab')} value="agendas">
                {agendaTab}
            </Tab>
            {dienstenTab && (
                <Tab title={t('planning.dienstenTab')} value="diensten">
                    {dienstenTab}
                </Tab>
            )}
        </Tabs>
    );
};
