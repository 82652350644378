import {EventResource, IMedewerkerFragment, PlanningStoreState} from '@growthbase/planning';
import {MbscResource} from '@mobiscroll/react';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useAgendaIsActive} from '../useAgendaIsActive';

export type AgendaNormalizer = (medewerker: IMedewerkerFragment) => MbscResource;

export const useNormalizeTeams = (normalizeAgenda: AgendaNormalizer, teamsEnabled?: boolean) => {
    const urenRegistratiesEnabled = useSelector(
        (state: PlanningStoreState) => state.planning.urenRegistratiesInPlanning
    );
    const {t} = useTranslation('spa.nl');
    const showUrenRegistraties = useAgendaIsActive('time-registrations');

    return useMemo(
        () =>
            // <-- useMemo is used here
            (medewerkers: IMedewerkerFragment[]): EventResource[] => {
                let teams: EventResource[] = [
                    {
                        id: 'team-noteam',
                        name: 'Geen team',
                        children: [],
                        isParent: true,
                        eventCreation: false,
                    },
                ];

                medewerkers.forEach((medewerker) => {
                    const teamResource = medewerker.team;
                    if (teamResource) {
                        const team = teams.find((findTeam) => findTeam.id === `team-${teamResource.id}`);
                        if (!team) {
                            teams.push({
                                isParent: true,
                                eventCreation: false,
                                id: `team-${teamResource.id}`,
                                name: teamResource.name,
                                children: [],
                            });
                        }
                    }
                });

                teams.forEach((team: EventResource) => {
                    const medewerkersVanTeam: IMedewerkerFragment[] = medewerkers.filter(
                        (medewerker: IMedewerkerFragment): boolean =>
                            (medewerker.team ? `team-${medewerker.team.id}` : 'team-noteam') === team.id
                    );
                    team.children = medewerkersVanTeam.map(normalizeAgenda);
                });

                teams = teams.filter((team) => !team.children || team.children.length > 0);

                if (urenRegistratiesEnabled && showUrenRegistraties) {
                    teams.push({
                        id: 'my-calendars',
                        color: '#015b5a',
                        isParent: true,
                        eventCreation: false,
                        children: [
                            {
                                id: 'time-registrations',
                                name: t('planning.urenRegistraties').toString(),
                                color: '#015b5a',
                            },
                        ],
                    });
                }

                if (!teamsEnabled) {
                    return teams.flatMap((team) => team.children ?? []);
                }

                return teams;
            },
        [normalizeAgenda, t, teamsEnabled, urenRegistratiesEnabled, showUrenRegistraties]
    );
};
