import {FC, ReactNode} from 'react';
import classNames from 'classnames/bind';
import {capitalize} from 'lodash';
import styles from './FlexLayout.module.scss';
import {ShowOnRowHover} from '../../ShowOnRowHover';

// eslint-disable-next-line no-shadow
export enum PositionType {
    'center',
    'start',
    'end',
    'none',
    'showOnHooverEnd',
}

export type DividerType = 'right' | 'left';

export interface ItemProps {
    children?: ReactNode;
    center?: boolean;
    align?: PositionType;
    justify?: PositionType;
    divider?: DividerType;
    paddingTop?: boolean;
    paddingBottom?: boolean;
    toRight?: boolean;
    minWidth?: number;
    grow?: boolean;
    id?: string;
}

const cx = classNames.bind(styles);

/**
 * @deprecated use Stack instead
 */
export const Item: FC<ItemProps> = ({
    children,
    center,
    justify,
    align,
    divider,
    toRight,
    paddingTop,
    paddingBottom,
    minWidth,
    grow,
    id,
}) => {
    if (align === PositionType.none) {
        return <></>;
    }
    if (align === PositionType.showOnHooverEnd) {
        children = <ShowOnRowHover>{children}</ShowOnRowHover>;
        align = PositionType.end;
    }
    return (
        <div
            id={id}
            className={cx(
                'flex__item',
                align && `flex--align${capitalize(PositionType[align])}`,
                grow && 'flex--grow',
                justify && `flex--justify${capitalize(PositionType[justify])}`,
                {
                    flex: align && PositionType[align],
                    'flex__item--center': center,
                    'flex__item--toRight': toRight,
                    'flex__item--paddingTop': paddingTop,
                    'flex__item--paddingBottom': paddingBottom,
                    [`flex__item--divider${capitalize(divider)}`]: divider,
                }
            )}
            style={minWidth ? {minWidth: `${minWidth}px`} : {}}
        >
            {children}
        </div>
    );
};
