import {SchemaOf, object, array, string, number, boolean} from 'yup';
import {useTypedSearchParams, useTypedSearchParamsUrlFactory} from '@growthbase/routing';
import {useMemo} from 'react';
import {trim} from 'lodash';
import {useCallbackRef} from '@growthbase/spa';

export const useStateFromSearchParam = <T>(
    prefix: string,
    schema: SchemaOf<T>,
    defaults: T
): [T, (value: T) => void] => {
    const objSchema: SchemaOf<{value: T}> = useMemo(
        () =>
            object({
                value: schema,
            }),
        [schema]
    );

    const objDefaults: {value: T} = useMemo(() => ({value: defaults}), [defaults]);

    const [{value}, setValue] = useTypedSearchParams<{
        value: T;
    }>(prefix, objSchema, objDefaults, false);

    return [value === undefined || value == null ? defaults : value, (val: T) => setValue({value: val})];
};

export const useUrlFactoryForSearchParam = <T>(
    prefix: string,
    schema: SchemaOf<T>,
    urlFactory: () => string,
    defaults: T
): [(value: T) => string, (value: T) => void] => {
    const objSchema: SchemaOf<{value: T}> = useMemo(
        () =>
            object({
                value: schema,
            }),
        [schema]
    );
    const objDefaults: {value: T} = useMemo(() => ({value: defaults}), [defaults]);
    const [create, navigate] = useTypedSearchParamsUrlFactory<{
        value: T;
    }>(prefix, objSchema, urlFactory, objDefaults, false);
    return [
        useCallbackRef((value: T) => create({value})),
        useCallbackRef((value: T) => navigate({value})),
    ];
};

const StringsSchema = array(string().required());

export const useStateStringsFromSearchParam = (
    prefix: string,
    defaults: string[]
): [string[], (value: string[]) => void] => useStateFromSearchParam<string[]>(prefix, StringsSchema, defaults);

export const useStateStringFromSearchParam = (
    prefix: string,
    defaults?: string
): [string | undefined, (value: string | undefined) => void] => {
    const [value, setValue] = useStateFromSearchParam<string | undefined>(prefix, string().required(), defaults);

    return [value === undefined || value.trim().length === 0 ? defaults : value, setValue];
};

export const useStateStringUrlFactory = (prefix: string, urlFactory: () => string, defaults?: string) =>
    useUrlFactoryForSearchParam(prefix, string().required(), urlFactory, defaults);

export const useStateNumberFromSearchParam = (
    prefix: string,
    defaults?: number
): [number | undefined, (value: number | undefined) => void] =>
    useStateFromSearchParam<number | undefined>(prefix, number().required(), defaults);

export const useStateBooleanFromSearchParam = (
    prefix: string,
    defaults?: boolean
): [boolean | undefined, (value: boolean | undefined) => void] =>
    useStateFromSearchParam<boolean | undefined>(prefix, boolean() as SchemaOf<boolean | undefined>, defaults);

export const useMultilineStringSearchParam = (queryPrefix: string) => {
    const [value, setValue] = useStateStringFromSearchParam(queryPrefix);

    const messages = useMemo(() => {
        if (!value) {
            return [];
        }
        return value
            .split('\n')
            .map(trim)
            .filter((f) => f.length > 0);
    }, [value]);

    return [value, setValue, messages.length > 0 ? messages : undefined] as const;
};
