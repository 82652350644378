import {BaseButtonProps, Buttons, ModalFormButtonFormProps} from '@growthbase/design-components';
import {ILijstKopieerButtonFragment, useLijstKopieerButtonConnectedMutation} from '@growthbase/graphql';
import {FC, useCallback} from 'react';
import {v4} from 'uuid';
import {idFromIri} from '@growthbase/routing';
import {KopieerButton} from './KopieerButton';
import {KopieerForm, LijstKopierenFormValues} from '../KopieerForm/KopieerForm';

export interface KopieerButtonConnectedProps {
    fragment: ILijstKopieerButtonFragment;
    onCreated?: (id: string) => void;
    Button: FC<BaseButtonProps>;
}

export const KopieerButtonConnected: FC<KopieerButtonConnectedProps> = ({
    Button = Buttons.Copy,
    fragment,
    onCreated,
}) => {
    const [mutate] = useLijstKopieerButtonConnectedMutation();
    const renderForm = useCallback(
        ({onSubmit}: ModalFormButtonFormProps<LijstKopierenFormValues>) => (
            <KopieerForm titel={fragment.titel} onSubmit={onSubmit} />
        ),
        [fragment.titel]
    );
    const onSubmit = useCallback(
        async (values: LijstKopierenFormValues): Promise<void> => {
            const {bord} = fragment;
            if (!bord) {
                throw new Error('Mag geen lijst kopieren zonder bord');
            }
            const id = v4();
            const taakOpties = values.taak;
            await mutate({
                variables: {
                    input: {
                        id,
                        titel: values.title,
                        opties: {
                            copyId: id,
                            originalId: fragment.id,
                            metActiviteiten: values.activiteiten,
                        },
                        lijstOpties: {
                            bordId: idFromIri(bord.id),
                            taakOpties: {
                                ...taakOpties,
                                relatie: taakOpties.relatie ? {idVanGekopieerdeRelatieverwijzing: v4()} : null,
                            },
                            taakFilter: values.taakFilter,
                        },
                    },
                },
            });
            if (onCreated) {
                onCreated(id);
            }
        },
        [
            mutate,
            fragment,
            onCreated,
        ]
    );
    if (!fragment.availableActions.KanKopieren) {
        return null;
    }
    return <KopieerButton<LijstKopierenFormValues> Button={Button} renderForm={renderForm} onSubmit={onSubmit} />;
};
