import React from 'react';
import {useFocused, useSelected} from 'slate-react';
import {getHandler, getRootProps, StyledElementProps, TMentionElement, Value} from '@udecode/plate';
import classNames from 'classnames/bind';
import styles from './MentionInput.module.scss';
import {useComboBoxContext} from '../AsyncCombobox';
import {Icons} from '../../../icons';
import {IconSize} from '../../../Icon';

const cx = classNames.bind(styles);

// renderElement props
export interface MentionInputElementProps<V extends Value> extends StyledElementProps<V, TMentionElement> {
    prefix?: string;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClick?: (mentionNode: any) => void;

    renderLabel?: (mentionable: TMentionElement) => string;
}

export interface MentionInputElementStyleProps<V extends Value> extends MentionInputElementProps<V> {
    selected?: boolean;
    focused?: boolean;
}

export const MentionInput = <V extends Value>(props: MentionInputElementProps<V>) => {
    const {attributes, children, nodeProps, element, onClick} = props;
    const {loading} = useComboBoxContext();
    const rootProps = getRootProps(props);

    const selected = useSelected();
    const focused = useFocused();

    const classes = cx('mentionInput', {
        'mentionInput--selected': selected,
        'mentionInput--focused': focused,
        'mentionInput--loading': loading,
    });

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <span
            {...attributes}
            data-slate-value={element.value}
            onClick={getHandler(onClick, element)}
            {...rootProps}
            {...nodeProps}
            className={`${classes} ${rootProps.className}`}
        >
            {children}
            {loading && (
                <div className={styles.mentionInput__loader}>
                    <Icons.LoadingAnimated className={styles.mentionInput__loaderIcon} size={IconSize.SIZE_3} />
                </div>
            )}
        </span>
    );
};
