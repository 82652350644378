import * as yup from 'yup';
import {SchemaOf} from 'yup';

export interface GraphQLWarningDTO {
    type: string;
    message: string;
}

export const GraphQLWarningDTOSchema: SchemaOf<GraphQLWarningDTO> = yup
    .object({
        type: yup.string().required(),
        message: yup.string().required(),
    })
    .label('SymfonyWarningDTO')
    .required();

export const GraphQLWarningsDTOSchema = yup.array().of(GraphQLWarningDTOSchema).required();
