import React, {FC} from 'react';
import {ELEMENT_OL, ELEMENT_UL, getPluginType, usePlateEditorRef, ListToolbarButton} from '@udecode/plate';
import {useTranslation} from 'react-i18next';
import {Icons} from '../../../icons';

export const ListToolbarButtons: FC = () => {
    const {t} = useTranslation('spa.nl');
    const editor = usePlateEditorRef();
    if (editor === null) {
        return null;
    }

    return (
        <>
            <ListToolbarButton
                tooltip={{
                    content: t('ListToolbarButtons.unordered_list'),
                }}
                type={getPluginType(editor, ELEMENT_UL)}
                icon={<Icons.FormatUnorderedList />}
            />
            <ListToolbarButton
                tooltip={{
                    content: t('ListToolbarButtons.ordered_list'),
                }}
                type={getPluginType(editor, ELEMENT_OL)}
                icon={<Icons.FormatOrderedList />}
            />
        </>
    );
};
