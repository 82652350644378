import {useCallback} from 'react';
import {
    IBordViewLijstFragment,
    IBordViewQuery,
    IBordViewQueryVariables,
    useLijstDropMutation,
} from '@growthbase/graphql';
import {DropItemHandlerEvent} from '@growthbase/keys';
import {idFromIri} from '@growthbase/routing';
import {useLogger} from '@growthbase/spa';
import {useDropQueryCacheHandlers} from '@growthbase/design-components';
import {QueryResult} from '@apollo/client';
import {ViewData} from '../View';

export const useDrop = (result: QueryResult<IBordViewQuery, IBordViewQueryVariables>) => {
    const [mutation] = useLijstDropMutation();
    const logger = useLogger('useDrop lijst in borden');
    const onDrop = useCallback(
        (event: DropItemHandlerEvent<IBordViewLijstFragment, ViewData>) => {
            logger.info(event);
            const itemThatIsBeforeId = event.placement.itemThatIsBefore?.id;
            const itemThatIsAfterId = event.placement.itemThatIsAfter?.id;
            return mutation({
                variables: {
                    input: {
                        id: idFromIri(event.item.id),
                        bordId: idFromIri(event.overContainer.id),
                        lijstpositie: {
                            onderLijstId: itemThatIsBeforeId ? idFromIri(itemThatIsBeforeId) : undefined,
                            bovenLijstId: itemThatIsAfterId ? idFromIri(itemThatIsAfterId) : undefined,
                        },
                    },
                },
            }).then(() => undefined);
        },
        [mutation, logger]
    );
    return useDropQueryCacheHandlers({
        onDrop,
        edgeTypeName: 'LijstEdge',
        nodeTypeName: 'TakenInLijst',
        updateQuery: result.updateQuery,
    }).onDrop;
};
