import React, {FC, PropsWithChildren, useMemo} from 'react';
import {GeenToegangModal} from './GeenToegangModal';
import {GeenToegangContext} from './GeenToegangPaginaContext';
import {useGebruikerHeeftToegang} from './Hook/useGebruikerHeeftToegang';
import {useGebruikerHeeftToegangContextApi} from './Hook/useGebruikerHeeftToegangContextApi';

export type GeenToegangModalConnectedProps = PropsWithChildren;

/**
 * We cannot dismount the component because that would re-enable the page.
 */
export const GeenToegangModalConnected: FC<GeenToegangModalConnectedProps> = ({children}) => {
    const paginaHeeftToegang = useGebruikerHeeftToegang();
    const {denied, api} = useGebruikerHeeftToegangContextApi();
    const style = useMemo(() => ({display: denied ? 'none' : 'default'}), [denied]);
    return (
        <GeenToegangContext.Provider value={api}>
            <div style={style}>{children}</div>
            {denied && paginaHeeftToegang && <GeenToegangModal />}
        </GeenToegangContext.Provider>
    );
};
